(function () {
  'use strict';

  /**
   * 공용 factory
   *
   * 2016-05-13 : 남형진
   *
   */
  angular
    .module('gmpApp')
    .factory('commonSVC', commonSVC);

  function commonSVC($rootScope, $filter, $http, $q, $uibModal, localStorageService, md5, gettextCatalog, tokenSVC, settings) {
    const swalDefaultOpt = {
      width: 600,
      customClass: {
        container: 'my-swal2-container',
        header: 'my-swal2-header',
        actions: 'my-swal2-actions',
        popup: 'my-swal2-content',
        confirmButton: 'my-swal2-confirm',
        cancelButton: 'my-swal2-cancel',
      },
      confirmButtonColor: '#5C90D2',
      confirmButtonText: '확인',
      showClass: {
        popup: '',
        icon: '',
      },
      hideClass: {
        popup: '',
      },
    };
    const mySwal = Swal2.mixin(swalDefaultOpt);

    const mySwalConfirm = mySwal.mixin({
      showCancelButton: true,
      cancelButtonText: '취소',
    });

    const inko = new Inko();

    const __master = {

      /**
         * 권한 확인 2017-02-07 MatthewKim
         *
         * 지정한 메뉴 코드에 대한 권한이 있는지 확인
         *
         * ex) 재고관리의 예로 코드 확인
         * 읽기권한(접근 권한): stock
         * 쓰기권한(입력/수정 권한): stock+write
         * 삭제권한(어드민권한): stock+admin
         *
         * ex) 단일 권한 확인
         *
         * commonSVC.checkRole("stock") // 읽기 (접근) 권한 있는지 확인
         * commonSVC.checkRole("stock+write") // 쓰기 권한도 있는지 확인
         * commonSVC.checkRole("stock+admin") // 삭제 권한도 있는지 확인
         *
         * ex) 2개 이상의 멀티 권한 확인 (이 중 1개라도 있으면 OK)
         * commonSVC.checkRole(['stock+admin','order+admin'])
         *
         * @returns Boolean
         */
      checkRole: function (role_codes, userInfo) {
        // 체크할 값이 없으면 OK 처리
        if (!role_codes || role_codes == []) {
          return true;
        }
        if (!_.isArray(role_codes)) {
          role_codes = [role_codes];
        }
        // 체크 할 권한 목록이, 로그인 한 유저가 가지고 있는 권한과 공통 된 목록을 비교한다. (intersection)
        // 공통된게 하나도 없으면 결과 배열이 0이 나오므로 이 경우는 권한이 없는 것이고,
        // 공통된게 하나라도 나오면, 권한이 있는 것이다.
        // 2017-02-09 MatthewKim
        const df = _.intersection(role_codes, userInfo.roles_arr);

        return df.length > 0;
      },

      /**
         * 사용권한 확인
         * 상위권한이 없는경우 페이지 접근 자체가 인되겠지만 상세권환 체크시 상위권한도 체크해야함
         * 2019-01-03 rony
         */
      checkPermission: function(actType, permission, noToaster) {
        // 주문수집 권한
        let noUse = true;

        if (_.size(permission)) {
          const roles = actType.split('.');

          // 권한을 추가할 경우 기존 사용자는 해당 권한이 없으므로 기본으로 true 처리
          noUse = permission[roles[0]][roles[1]][roles[2]] === undefined ? true : permission[roles[0]][roles[1]][roles[2]];

          // 권한이 없는경우 에러 출력해줘야 함.
          if ((permission[roles[0]].use_yn === false || noUse === false) && ((noToaster === undefined) || (noToaster === false))) {
            this.PermissionDenyAlert();
          }
        }

        return noUse;
      },

      /**
         * 권한 오류 팝업창 통일
         * 2017-02-09 MatthewKim
         * @constructor
         */
      PermissionDenyAlert: function () {
        __master.showToaster(
          'error',
          gettextCatalog.getString('앗! 사용 권한이 없네요.'),
          gettextCatalog.getString('요청하신 기능은 사용 권한이 필요합니다. PLAYAUTO 2.0 관리자에게 문의해보세요!')
        );
      },

      /**
         * Alert 메세지 출력
         * @param title
         * @param contents
         * @param next
         * @param keyboard ESC 버튼 클릭시 모달 닫힘 여부 (미입력시 true)
         * @param outside 모달 외 화면 영역 클릭시 모달 닫힘 여부 (미입력시 true)
         */
      showMessage: (title, contents, next, keyboard = true, outside = true) => {

        // 쿼리문은 에러메세지에 노출되지 않도록 처리.
        if (contents?.includes('threadId') && contents?.includes('Query')) {
          contents = 'Error';
        }

        // confirm 창 이후 show message 뜨지 않는 문제처리
        const elm = document.querySelector('.sweet-alert');

        // sweet-alert 엘리먼트는 한번 생성되면 남아 있음 적용 안돼있을 땐 display: none
        // 초기 있으면 확인 처리 없으면 바로 swal 보여줌
        if (elm && elm.style.display !== 'none') {
          // 메세지창 뒤 검은 배경
          const overlay = document.querySelector('div.sweet-overlay');

          // 둘다 날림.
          elm.parentNode.removeChild(overlay);
          elm.parentNode.removeChild(elm);
        }

        if (next) {
          swalOpen({
            title: title,
            text: contents,
            confirmButtonColor: '#5C90D2',
            confirmButtonText: gettextCatalog.getString('확인'),
            allowEscapeKey: keyboard,
            allowOutsideClick: outside,
          }, next);
        } else {
          return new Promise(resolve => {
            swalOpen({
              title: title,
              text: contents,
              confirmButtonColor: '#5C90D2',
              confirmButtonText: gettextCatalog.getString('확인'),
              allowEscapeKey: keyboard,
              allowOutsideClick: outside,
            }, resolve);
          });
        }

      },

      /**
         * Alert 메세지 출력 (HTML)
         * @param title
         * @param contents
         */
      showMessageHtml: function (title, contents, callback, opt = {}) {
        // 쿼리문은 에러메세지에 노출되지 않도록 처리.
        if (contents?.includes('threadId') && contents?.includes('Query')) {
          contents = 'Error';
        }

        const { checkboxOpt, customClass, confirmButtonText, cancelButtonText, allowEscapeKey = true, showCancelButton = false, footerText } = opt;
        const swalOpt = {
          title: title,
          text: contents,
          confirmButtonColor: '#5C90D2',
          confirmButtonText: confirmButtonText || '확인',
          showCancelButton,
          cancelButtonText: cancelButtonText || '취소',
          html: true,
          allowEscapeKey,
          ...(customClass && { customClass })
        };

        function addFooter(type) {
          const container = $('.sa-button-container');
          if (type === 'checkbox') {
            container.prepend(`
                <span class="notShowAgain">
                    <label class="checkbox-inline" style="float: left; line-height: 50px;">
                        <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative"
                            onclick="localStorage.setItem('not_show_${checkboxOpt.key}_${checkboxOpt.m_no}', this.checked);">
                        다시보지 않기
                    </label>
                </span>
            `);
          } else {
            container.prepend(`<div class="confirm-footer-text">${footerText}</div>`);
          }
        }

        if (callback) {
          swalOpen(swalOpt, callback);
          if (checkboxOpt?.show || footerText) {
            addFooter(checkboxOpt?.show ? 'checkbox' : 'footer');
          }
        } else {
          return $q(function(resolve) {
            swalOpen(swalOpt, function() {
              resolve();
            });
            if (checkboxOpt?.show || footerText) {
              addFooter(checkboxOpt?.show ? 'checkbox' : 'footer');
            }
          });
        }
      },

      /**
         * Confirm 메세지 출력
         * @param title
         * @param contents
         * @param next
         */
      showConfirm: function (title, contents, next, keyboard = true, customClass) {
        const swalOpt = {
          title: title,
          text: contents,
          // type: "warning",
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          /// alert창의 확인 버튼임
          confirmButtonText: gettextCatalog.getString('확인'),
          /// alert창의 취소 버튼임
          cancelButtonText: gettextCatalog.getString('취소'),
          animation: false,
          allowEscapeKey: keyboard,
          ...(customClass && { customClass })
        };

        if (next) {
          swalOpen(swalOpt, next);
        } else {
          return $q(function(resolve) {
            swalOpen(swalOpt, function(confirm) {
              resolve(!!confirm);
            });
          });
        }
      },

      /**
         * Confirm 메세지 출력(sweetalert2 라이브러리 사용)
         * @param title
         * @param contents
         * @param next
         */
      showConfirm2: function (title, contents, next, keyboard = true, customClass) {
        const swalOpt = {
          title: title,
          text: contents,
          // type: "warning",
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          /// alert창의 확인 버튼임
          confirmButtonText: gettextCatalog.getString('확인'),
          /// alert창의 취소 버튼임
          cancelButtonText: gettextCatalog.getString('취소'),
          animation: false,
          allowEscapeKey: keyboard,
          ...(customClass && { customClass })
        };

        if (next) {
          swalOpen2(swalOpt, next);
        } else {
          return swalOpen2(swalOpt);
        }
      },

      /**
         * Confirm 메세지 출력 ( 타임아웃 )
         * @param title
         * @param contents
         * @param next
         */
      showConfirmTimer: function (title, contents, next) {
        const swalOpt = {
          title: title,
          text: contents,
          timer: 5000,
          // type: "warning",
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          /// alert창의 확인 버튼임
          confirmButtonText: gettextCatalog.getString('확인'),
          /// alert창의 취소 버튼임
          cancelButtonText: gettextCatalog.getString('취소'),
          animation: false
        };

        if (next) {
          swalOpen(swalOpt, next);
        } else {
          return $q(function(resolve) {
            swalOpen(swalOpt, function(confirm) {
              resolve(!!confirm);
            });
          });
        }
      },

      /**
         * Confirm 메세지 출력 커스텀
         * @param opt
         * @param next
         */
      showConfirmCustom: function (opt, next, checkboxOpt) {
        if (!opt.title) {
          console.error('title And text Are Required Data In First Parameter');

          return false;
        }

        const defaultOpt = {
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          animation: false,
          width: opt.width || 600,
          customClass: {}
        };

        if (opt.isHtml) {
          defaultOpt.html = opt.text;
        }

        const swalOpt = _.assign(defaultOpt, opt);

        if (next) {
          swalOpen(swalOpt, next);
          const container = $('.sa-button-container');

          if (opt.buttonClass) {
            for (const key in opt.buttonClass) {
              if (key === 'confirm') {
                $('.sa-confirm-button-container > .confirm').addClass(opt.buttonClass[key]);
              } else {
                $('.sa-button-container > .cancel').addClass(opt.buttonClass[key]);
              }
            }
          }

          if (opt.buttonCss) {
            container.css(opt.buttonCss);
          }

          if (checkboxOpt?.show) {
            container.prepend(`
              <span class="notShowAgain">
                <label class="checkbox-inline" style="float: left; line-height: 50px;">
                  <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative" onclick="localStorage.setItem('not_show_${checkboxOpt.key}_${checkboxOpt.m_no}', this.checked);">
                  다시보지 않기
                </label>
              </span>
            `);
          }
        } else {
          return $q(function (resolve) {
            swalOpen(swalOpt, function (confirm) {
              resolve(!!confirm);
            });
            const container = $('.sa-button-container');

            if (opt.buttonClass) {
              for (const key in opt.buttonClass) {
                if (key === 'confirm') {
                  $('.sa-confirm-button-container > .confirm').addClass(opt.buttonClass[key]);
                } else {
                  $('.sa-button-container > .cancel').addClass(opt.buttonClass[key]);
                }
              }
            }
            if (opt.buttonCss) {
              container.css(opt.buttonCss);
            }

            if (checkboxOpt?.show) {

              container.prepend(`
                <span class="notShowAgain">
                  <label class="checkbox-inline" style="float: left; line-height: 50px;">
                    <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative" onclick="localStorage.setItem('not_show_${checkboxOpt.key}_${checkboxOpt.m_no}', this.checked);">
                    다시보지 않기
                  </label>
                </span>
              `);
            }
          });
        }
      },

      /**
         * Confirm 메세지 출력 커스텀2
         * @param opt
         * @param next
         */
      showConfirmCheckboxCustom: function (opt, checkboxOpt, next) {
        if (!opt.title || !opt.text) {
          console.error('title And text Are Required Data In First Parameter');

          return false;
        }

        const defaultOpt = {
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          animation: false
        };

        const swalOpt = _.assign(defaultOpt, opt);

        if (next) {
          swalOpen(swalOpt, next);

          if (checkboxOpt?.show) {
            const tooltip = checkboxOpt?.tooltip ? `<i class="icon-help menu-tooltip" tooltip-value="${checkboxOpt?.tooltip}" uib-tooltip="${checkboxOpt?.tooltip}" tooltip-placement="right"></i>` : '';
            const container = $('.sa-button-container');

            container.prepend(`
              <div>
                <label class="checkbox-inline" style="float: left; line-height: 50px;">
                  <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative"
                    onclick="localStorage.setItem('custom_checkbox_${checkboxOpt.key}_${checkboxOpt.m_no}', this.checked);" ${checkboxOpt?.checked ? 'checked' : ''}>
                  ${checkboxOpt?.checkbox_name || '다시보지 않기'}
                  ${tooltip}
                  </label>
              </div>
            `);

            $(function() {
              let title_;

              $('.menu-tooltip').hover(function(e) {      // <a> hover 시 : mouseEnter
                title_ = $(this).attr('tooltip-value');     // tooltip-value 변수에 저장
                $(this).attr('tooltip-value', '');           // tooltip-value 속성 삭제( 기본 툴팁 기능 방지 )

                if (title_) {
                  $('body').append("<div id='tip'></div>");   // body 내부에 div#tip 생성

                  $('#tip').css('width', 'auto');
                  $('#tip').css('height', 'auto');
                  $('#tip').text(title_);

                  // 툴팁이 현재 hover 한 요소의 상단에 생성되도록 설정
                  const pageX = $(this).offset().left + 30;
                  const pageY = $(this).offset().top - 10;

                  $('#tip').css({ left: pageX + 'px', top: pageY + 'px' }).fadeIn(1);
                  $('#tip').css('z-index', '100000');
                }
              }, function() {                         // <a> hover 시 : mouseLeave
                $(this).attr('tooltip-value', title_);      // tooltip-value 속성 반환
                $('#tip').remove();                 // div#tip 삭제
              });

              $('.menu-tooltip').mousedown(function () {
                $(this).attr('tooltip-value', title_);      // tooltip-value 속성 반환
                $('#tip').remove();                 // div#tip 삭제
              });
            });
          }
        } else {
          return $q(function (resolve) {
            swalOpen(swalOpt, function (confirm) {
              resolve(!!confirm);
            });

            if (checkboxOpt?.show) {
              const container = $('.sa-button-container');

              container.prepend(`
                <span class="notShowAgain">
                  <label class="checkbox-inline" style="float: left; line-height: 50px;">
                    <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative"
                      onclick="localStorage.setItem('custom_checkbox_${checkboxOpt.key}_${checkboxOpt.m_no}', this.checked);">
                    ${checkboxOpt?.checkbox_name || '다시보지 않기'}
                  </label>
                </span>
              `);
            }
          });
        }
      },

      showConfirmCustomSwal2: function (opt, next) {
        const defaultOpt = {
          width: opt.width || 600,
          html: opt.text,
          confirmButtonText: opt.confirmButtonText || '확인',
          cancelButtonText: opt.cancelButtonText || '취소'
        };
        const swalOpt = _.assign(defaultOpt, { ...opt, customClass: { ...swalDefaultOpt.customClass, ...opt.customClass } });

        if (next) {
          mySwalConfirm.fire(swalOpt).then((confirm) => {
            next(confirm);
          });
        } else {
          return mySwalConfirm.fire(swalOpt);
        }
      },

      /**
         * Confirm 메세지 출력 (HTML)
         * @param title
         * @param contents
         * @param next
         */
      showConfirmHtml: function (title, contents, next, opt = {}) {
        const swalOpt = {
          title: title,
          text: contents,
          // type: "warning",
          showCancelButton: true,
          confirmButtonColor: '#5C90D2',
          // alert창의 확인 버튼임
          confirmButtonText: opt?.confirmButtonText || gettextCatalog.getString('확인'),
          // alert창의 취소 버튼임
          cancelButtonText: opt?.cancelButtonText || gettextCatalog.getString('취소'),
          animation: false,
          html: true,
          ...opt,
        };

        function addFooter(type) {
          const container = $('.sa-button-container');
          if (type === 'checkbox') {
            container.prepend(`
                <span class="notShowAgain">
                    <label class="checkbox-inline" style="float: left; line-height: 50px;">
                        <input type="checkbox" class="mr-5" style="display:inline-block; width:auto; box-shadow:0 0 #fff; position: relative; vertical-align: middle; position: relative"
                            onclick="localStorage.setItem('not_show_${opt.checkboxOpt.key}_${opt.checkboxOpt.m_no}', this.checked);">
                        다시보지 않기
                    </label>
                </span>
            `);
          } else {
            container.prepend(`<div class="confirm-footer-text">${opt.footerText}</div>`);
          }
        }

        if (next) {
          swalOpen(swalOpt, next);
          if (opt.checkboxOpt?.show || opt.footerText) {
            addFooter(opt.checkboxOpt?.show ? 'checkbox' : 'footer');
          }
        } else {
          return $q(function (resolve) {
            swalOpen(swalOpt, function (confirm) {
              resolve(!!confirm);
            });
            if (opt.checkboxOpt?.show || opt.footerText) {
              addFooter(opt.checkboxOpt?.show ? 'checkbox' : 'footer');
            }
          });
        }
      },

      /**
         * 2017-06-14 ally 버튼 2개 메세지
         * @param title
         * @param contents
         * @param buttons : [{name: 버튼이름 , next : callback 기능}]
         */
      showMultiButton: function (title, contents, buttons) {
        function swalFunction(deleteSwalElement) {
          swalOpen({
            title: title,
            text: contents,
            confirmButtonColor: '#5C90D2',
            showConfirmButton: false,
            html: true
          }, function(res) {
            if (res === false) {
              $('.swalExtendButton').hide();
            }
          }, deleteSwalElement);
        }

        const names = _.map(buttons, 'name');
        const next = _.map(buttons, 'next');

        swalExtend({
          swalFunction: swalFunction,
          hasCallback: true,
          hasCancelButton: true,
          buttonNum: buttons.length,
          buttonNames: names,
          clickFunctionList: next
        });
      },
      /**
         * Toaster 출력
         *
         * 2017-05-23 MatthewKim  빠르게 많이 알림이 표시되는것을 막기위해 _.debounde 사용함
         *
         * @param type
         * @param title
         * @param contents
         */
      showToaster: _.debounce(function (type, title, contents, opt) {

        // 쿼리문은 에러메세지에 노출되지 않도록 처리.
        if (contents?.includes('threadId') && contents?.includes('Query')) {
          contents = 'Error';
        }

        let icon = '';
        // var stack_bottom_right = {"dir1": "up", "dir2": "left", "firstpos1": 25, "firstpos2": 25};

        if (type === 'success') {
          icon = 'icon-checkmark3';
        } else if (type === 'error') {
          icon = 'icon-blocked';
        } else if (type === 'warning') {
          icon = 'icon-warning22';
        } else if (type === 'info') {
          icon = 'icon-info22';
        }

        const notice = new PNotify({
          title: title,
          text: contents,
          type: type,
          icon: icon,
          buttons: {
            closer: false,
            sticker: false
          },
          addclass: opt && opt.addclass ? opt.addclass : '',
          width: opt && opt.width ? opt.width : '300px',
          // 2017-03-14 matthew 옵션 일단 뺌 (우측 상단으로)
          //addclass: "stack-top-right",
          //stack: stack_bottom_right
        });

        // 2017-03-15 chris 알림 클릭시 닫기
        notice.get().click(function() {
          notice.remove();
        });

      }, 500),

      /**
         * 날짜 형식별 출력
         * @param date
         * @param format
         * @returns {string}
         */
      getDate: function (date, format) {

        // date 설정
        if (date == undefined) {
          date = new Date();
        }
        if (date == 'now') {
          date = new Date();
        }
        if (angular.isString(date)) {
          date = new Date(date);
        }

        // format 설정
        if (format == undefined) {
          format = 'yyyy-MM-dd HH:mm:ss';
        }

        // 결과
        let result = '';

        if (format == 'time') {
          result = date.getTime();
        } else {
          result = $filter('date')(date, format);
        }

        if (result == 'Invalid Date') {
          result = '';

        }

        return result;
      },

      /**
         * modal 오픈
         * @param size modal 크기 (sm: 스몰 lm: 라지)
         * @param data modal에 넘겨줄 data
         * @param ctrl modal controller
         * @param template modal template URL / path
         * @param backdrop modal 외부 클릭시 닫히는 여부(미입력시 false)
         * @param scrollable body 스크롤 여부(미입력시 true)
         * @param keyboard ESC 버튼 클릭시 모달 닫힘 여부 (미입력시 true)
         * @param from 모달 열린 위치
         * @returns {modalinstance } modalinstance
         */
      openModal: function (size, data, ctrl, template, backdrop, scrollable, keyboard, from) {
        if (data == '') {
          data = { data: {} };
        }
        backdrop = backdrop == undefined ? false : backdrop;
        scrollable = scrollable == undefined ? true : scrollable;
        keyboard = keyboard == undefined || $rootScope.adminMode ? true : keyboard;

        if (backdrop === false) {
          backdrop = 'static';
        }

        const openedClass = scrollable ? 'modal-open' : 'modal-open-noscroll';
        // 2018-04-24 chris 캐싱 방지용 임의의 글자추가
        const cacheBuster = Date.now().toString();
        const { backdropClass, windowClass, addClass } = data;

        if (template !== null && angular.isDefined(template) && angular.isString(template)) {
          template += (template.indexOf('?') === -1 ? '?' : '&');
          template += `v=${cacheBuster}`;
        }
        if (backdropClass || windowClass || addClass) {
          delete data.backdropClass;
          delete data.windowClass;
          delete data.addClass;
        }
        const modal = $uibModal.open({
          animation: false,
          templateUrl: template,
          controller: ctrl,
          size: size,
          openedClass: openedClass,
          windowClass: `${openedClass} ${windowClass || ''}`,
          backdrop: backdrop,
          backdropClass: backdropClass || '',
          resolve: data,
          keyboard: keyboard
        });

        if (data.data?.isHelpModal) {
          $rootScope.$broadcast('modalOpen', { isHelpModal: data.data.isHelpModal });
        }

        // VIP+ 결제 상담신청, 웨이크업 접속
        if (['pay_request', 'wakeup'].includes(from)) {
          data.data.from = from;
        }

        modal.closed.then(function() {
          $rootScope.$broadcast('modalClosed');
        });

        // 크롬 자동완성기능(autocomplete) 끄기
        modal.opened.then(function () {
          $('.' + openedClass).prepend(`
          <input style="display:none" aria-hidden="true">
          <input type="password" style="display:none" aria-hidden="true">`);
        });

        $('.modal-backdrop').show();

        modal.rendered.then(function () {
          if (!$.fn.dataTable) {
            return;
          }

          $.each($.fn.dataTable.tables(true), function (key, val) {
            if ($(val).closest('div.modal-dialog').length > 0) {
              $(val).DataTable().columns.adjust();
            }
          });

          // 주문/매출 리포트 모달의 경우 별도 모달로 그려야 해서 해당 처리 추가
          if (addClass) {
            $('.modal-content').addClass(addClass);
          }
        });

        return modal;
      },

      /**
         * AJAX 통신용
         * @param method
         * @param url
         * @param params
         * @param next
         * @returns {*}
         */
      sendUrl: function (method, url, params, next) {
        const clientToken = tokenSVC.getClientToken();
        const token = localStorageService.get('token');

        let isTokenError = false;
        // 현재 클라이언트와 토큰정보가 다를 시 로그아웃 처리
        if (clientToken !== token) {
          isTokenError = true;
          params = { localStorageToken: token, clientToken, url, method };
          url = `${settings.pa20ApiUrl}/app/common/send-token-error`;
          method = 'POST';
        }

        $http.defaults.headers.common.Authorization = token;

        const timeout = params && params.timeout ? params.timeout : 120000; // timeout 이 있으면 타임 아웃만큼보내고 없으면 기본 2분
        const callbackFlag = typeof (next) == 'function';
        const promise = $http({
          method,
          url,
          contentType: 'application/json',
          // angularjs의 $http에서 method가 DELETE일때 header에 아래와 같이 content-type을 넣어주지 않으면 data(body)값이 안넘어감
          headers: method == 'DELETE' ? { 'Content-Type': 'application/json' } : undefined,
          params: method == 'GET' ? params : undefined,
          data: params,
          timeout
        });

        if (isTokenError) {
          __master.showMessage('알림', '로그인 정보가 갱신되어 새로고침 됩니다.', () => { location.reload(); });

          return Promise.reject();
        }

        if (callbackFlag) {
          promise.then(function successCallback(response) {
            next('success', response.data);
          }, function errorCallback(response) {
            next('error', response);
          });
        } else {
          return promise;
        }
      },

      /**
         * AJAX 통신용 (File 업로드)
         * @param method
         * @param url
         * @param params
         * @param next
         * @returns {*}
         */
      sendUrlFile: function (method, url, params, next) {
        const callbackFlag = typeof (next) == 'function';

        const clientToken = tokenSVC.getClientToken();
        const token = localStorageService.get('token');

        // 현재 클라이언트와 토큰정보가 다를 시 로그아웃 처리
        if (clientToken !== token) {
          __master.showMessage('알림', '로그인 정보가 갱신되어 새로고침 됩니다.', () => { location.reload(); });

          return Promise.reject();
        }

        $http.defaults.headers.common.Authorization = token;

        const promise = $http({
          method: method,
          url: url,
          headers: {
            'Content-Type': undefined
          },
          data: params,
          transformRequest: function (data) {
            const formData = new FormData();

            angular.forEach(data, function (value, key) {
              if (angular.isObject(value)) {
                if (value.lastModified > 0 && value.size > 0) {
                  formData.append(key, value);
                } else {
                  formData.append(key, JSON.stringify(key === 'add_shop_id' ? value : __master.replaceNull(value)));
                }
              } else {
                formData.append(key, value);
              }
            });

            return formData;
          }
        });

        if (callbackFlag) {
          promise.then(function successCallback(response) {
            next('success', response);
          }, function errorCallback(response) {
            next('error', response);
          });
        } else {
          return promise;
        }
      },

      /**
         * PlayApi 통신용
         * @param method
         * @param url
         * @param params
         * @param next
         * @returns {*}
         */
      sendEngineUrl: function (method, url, params, next) {

        const clientToken = tokenSVC.getClientToken();
        const token = localStorageService.get('token');

        // 현재 클라이언트와 토큰정보가 다를 시 로그아웃 처리
        if (clientToken !== token) {
          __master.showMessage('알림', '로그인 정보가 갱신되어 새로고침 됩니다.', () => { location.reload(); });

          return Promise.reject();
        }

        $http.defaults.headers.common.Authorization = token;

        const callbackFlag = typeof (next) == 'function';
        const promise = $http({
          method: method,
          url: url,
          contentType: 'application/json',
          data: params
        });

        if (callbackFlag) {
          promise.then(function successCallback(response) {
            next('success', response);
          }, function errorCallback(response) {
            next('error', response);
          });
        } else {
          return promise;
        }
      },
      // /**
      //  * niceScroll
      //  * @param scope
      //  * @param element
      //  * @param attrs
      //  * @param option
      //  */
      // "niceScroll": function (scope, element, attrs, option) {
      //
      //   // var niceOption = scope.$eval(attrs.niceOption)
      //   var niceOption = {
      //     cursorcolor: '#90A4AE',
      //     cursorwidth: '10px'
      //   };
      //
      //   if (option) {
      //     _.extend(niceOption, option);
      //   }
      //
      //   var niceScroll = $(element).niceScroll(niceOption);
      //   var nice = $(element).getNiceScroll();
      //
      //   if (attrs.niceScrollObject)  $parse(attrs.niceScrollObject).assign(scope, nice);
      //
      //   // on scroll end
      //   niceScroll.onscrollend = function (data) {
      //     if (this.newscrolly >= this.page.maxh) {
      //       if (attrs.niceScrollEnd) scope.$evalAsync(attrs.niceScrollEnd);
      //
      //     }
      //     if (data.end.y <= 0) {
      //       // at top
      //       if (attrs.niceScrollTopEnd) scope.$evalAsync(attrs.niceScrollTopEnd);
      //     }
      //   };
      //
      //   scope.$on('$destroy', function () {
      //     if (angular.isDefined(niceScroll.version)) {
      //       niceScroll.remove();
      //     }
      //   })
      //
      // },

      /**
         * create md5
         * @param input
         */
      getMd5: function (input) {

        return md5.createHash(input);

      },

      /**
         * 선택한 자료를.. 처리시 적용 조건 공통 체크 함수
         *
         * @param name 검사할 모달창 이름
         * @param name_title 검사할 모달창 표시명
         * @param datatable 데이터테이블 인스턴스 ($scope.grid.dtInstance)
         * @param data 현재 데이터테이블 row 데이터 2017-05-10 chris : row 데이터 직접 넘기는걸로 수정
         * @param total_cnt_now 현재 검색한 결과의 총 건수
         * @param check_field 검사할 필드값 (status 등 검사하고자 하는 데이터 필드값)
         * @param allow_rules 허용할 Rule 정보
         * @param free_pass_names 검사하지 않을 모달창 이름
         * @param notOnly true시 ~만 처리가능 => ~는 처리 불가능
         * @returns reruenValue 변수
         *
         * 2017-03-14 MatthewKim
         */
      checkRulesAndConfirm: function (name,
        name_title,
        datatable,
        data,
        total_cnt_now,
        check_field,
        allow_rules,
        free_pass_names,
        notOnly) {

        // 선택 내용 추출
        if (!data) {
          return { isOpen: false };
        }
        const selected = data || [];

        // 변수 정의
        const allow_states = allow_rules || [];
        const what = name || '';
        /// 진행 하고자 했던 명령을 의미함 (출고지시.. 삭제.. 취소 등을 뜻하는 이번 작업)
        const what_title = name_title || gettextCatalog.getString('이 작업', {});

        free_pass_names = free_pass_names || [];
        check_field = check_field || '';
        total_cnt_now = total_cnt_now || 0;
        const returnValue = {
          isOpen: true,
          list: selected,
          list_count: selected.length,
          all_count: total_cnt_now,
          param: [
            {
              key: check_field,
              value: allow_states
            }
          ]
        };

        // 조건없이 열리는 모달들
        if (free_pass_names.indexOf(what) >= 0) {
          return returnValue;
        }

        // 선택한 데이터의 진행 가능 검사 리팩토링 2017-03-13 MatthewKim
        if (selected.length > 0) {

          // 선택한 배열에서 status 각 그룹별 갯수를 세어서 status:갯수 형태로 리턴
          let cnts = _.countBy(selected, check_field);

          cnts = _.forEach(cnts, function(v, k) {
            cnts[gettextCatalog.getString(k)] = v;
            if (k !== gettextCatalog.getString(k)) {
              delete cnts[k]; // 번역된 애들 제외하곤 삭제
            }
          });

          const cnts_html = _.template('</div><ul class="list-group"><% _.forEach(l, function(cnt,state) { %><li class="list-group-item"><span class="label"><%- cnt%></span> <%-  state%>(<%- cnt%>)</li><% }); %></ul>')({ l: cnts });

          // 선택된 주문상태들 -(빼기) 사용가능 상태들 해서 남은 차이를 구함 (우측 비교대상이 더 많은건 없어짐)
          // 이 결과가 존재하면 사용 불가능한 상태가 섞인 것임
          const diff = _.difference(_.keys(cnts), allow_states);

          // 차이가 있으면 사용 불가 상태가 섞인 상태로 알려주고 골라내야함
          if (diff.length > 0) {
            // 처리 가능, 불가능 나눔
            let title = '';

            if (notOnly) {
              title = gettextCatalog.getString('{{title}} 작업은 {{state}}은(는) 처리가 불가능 합니다.', { title: what_title, state: diff.join('/') });
            } else {
              title = gettextCatalog.getString('{{title}} 작업은 {{state}}만 처리가 가능 합니다.', { title: what_title, state: allow_states.join('/') });
            }

            swalOpen({
              title: title,
              text: cnts_html,
              html: true,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#5C90D2',
              confirmButtonText: gettextCatalog.getString('{{title}} 작업이 가능한 건만 선택해두기', { title: what_title }),
              cancelButtonText: gettextCatalog.getString('취소'),
              animation: false,
            }, function () {

              // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
              // 2017-03-13 MatthewKim
              const reselected_indexes = datatable.doSelectByFilter(function (r) {
                const dataTableStatus = gettextCatalog.getString(r[check_field]); //번역된 status로 allow_states 에서 찾기

                return allow_states.indexOf(dataTableStatus) > -1;
              }, true);

              if (reselected_indexes.length == 0) {
                __master.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('{{title}} 작업이 가능한 건이 없습니다.', { title: what_title }));
              }

            });

            returnValue.isOpen = false;

            return returnValue;
          }

        }

        return returnValue;
      }, // end function

      // addHyphen : 하이픈 추가 함수입니다.
      // str : 문자열
      // length : 문자열의 총 길이
      // firstHyphenIndex : 첫번째 하이픈을 추가할 인덱스번호
      // secondHyphenIndex : 두번째 하이픈을 추가할 인덱스번호
      // 2017. 10. 13 박현도
      addHyphen: function(str, length, firstHyphenIndex, secondHyphenIndex) {
        if (str.length !== length) {
          return str;
        } else {
          let regStr = str.replace(/\D/g, '');

          regStr = `${regStr.slice(0, firstHyphenIndex)}-${regStr.slice(firstHyphenIndex, secondHyphenIndex)}-${regStr.slice(secondHyphenIndex, str.length)}`;

          return regStr;
        }
      },
      // 사업자 번호 계산식
      // 2017-11-01 박현도
      checkingBizNo: function(bizNo) {

        // console.log(bizNo);
        // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
        if ((bizNo = (`${bizNo}`).match(/\d{1}/g)).length !== 10) {
          return false;
        }

        // 합 / 체크키
        let sum = 0, key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

        // 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에더합니다.
        for (let i = 0; i < 9; i++) {
          sum += (key[i] * Number(bizNo[i]));
        }

        // 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
        // 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.
        const isRightBizNo = (10 - ((sum + Math.floor(key[8] * Number(bizNo[8]) / 10)) % 10)) === Number(bizNo[9]);

        // console.log(isRightBizNo);
        return isRightBizNo;
      },

      /**
       * 2018-01-16 Daniel
       * 최소이미지 사이즈 리사이징
       */
      resizingImg: function(files, imgYn) {
        const promises = []; // $q.all을 위한 프로미스 배열

        // 환경설정 이미지 리사이징 체크시만 진행
        if (imgYn === 'Y') {
          _.each(files, function(file) {
            // 프로미스를 위해 $q를 사용
            const promise = $q(function (resolve) {
              let resizeFile = null;
              // 파일리더로 이미지 읽음
              const FR = new FileReader();

              FR.onload = function () {
                // 이미지 오브젝트로 만듬
                const image = new Image();

                image.onload = function () {
                  const width = image.width,
                        height = image.height;

                  // 가로 세로 길이가 600 이하일시 리사이징 해줌
                  if (width < 600 && height < 600) {
                    resizeFile = __master.imgResizeToFile(image, file.name, file.type);
                    // 최소사이즈 아닐시 원래파일로 다시 넣음
                  } else {
                    resizeFile = file;
                  }

                  resolve(resizeFile);
                };
                image.src = FR.result;
              };
              FR.readAsDataURL(file);
            });

            promises.push(promise);
          });

          // $q.all로 모든 프로미스가 완료시로 전달
          return $q.all(promises);
          // 아닐시 원본파일들 다시 전달
        } else {
          return $q(function(resolve) {
            resolve(files);
          });
        }
      },

      /**
         * 2018-01-18 Daniel
         * 이미지 객체 리사이징후 파일로 리턴
         */
      imgResizeToFile: function(image, name, type) {
        const width = image.width = 600,
              height = image.height = 600;

        // 캔버스에 600사이즈로 그린후 dataURL을 추출
        const canvas = document.createElement('canvas'),
              ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, 0, 0, width, height);

        const dataURL = canvas.toDataURL(type);

        // 파일 데이터로 재조합
        let arr = dataURL.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        const resizeFile = new File([u8arr], name, { type: mime });

        return resizeFile;
      },

      /**
         * 2018-03-12 Daniel
         * playapi function
         */
      requestPA: async function(userInfo, domain, action, addData, commonModel, shopAccountModel, no_decrypt = false, no_apikeyDecrypt = false) {

        if (!userInfo || !action || !domain) {
          console.error('domain and action require parameter');

          return false;
        }

        // API PARAMETER
        const param = {
          job_data: {
            account: {
              seller_id: userInfo.shop_id || '',
              seller_pw: userInfo.shop_pwd || '',
              etc1: userInfo.etc1 || '',
              etc2: userInfo.etc2 || '',
              etc3: userInfo.etc3 || '',
              etc4: userInfo.etc4 || '',
              etc5: userInfo.etc5 || '',
              etc6: userInfo.etc6 || '',
              etc7: userInfo.etc7 || '',
              seller_etc: userInfo.seller_etc || ''
            }
          },
          req_code: domain,
          req_action: action,
          res_type: 'application/json+emp',
          req_type: 'application/json+emp',
        };

        // 추가 파라미터 세팅
        if (addData) {
          _.each(addData, function (v, k) {
            param.job_data[k] = v;
          });
        }

        try {
          const { data: etcInfo } = await shopAccountModel.getShopEtcData({ shop_cd: userInfo.pa_shop_cd });

          // api_key 암호화 관련 처리
          if (userInfo.pa_shop_cd && userInfo.api_enc && etcInfo) {
            let sellerApikey = userInfo.api_enc;

            if (!no_apikeyDecrypt) {
              sellerApikey = userInfo.old_api_enc || userInfo.api_enc;
            }

            const api_field = Object.keys(etcInfo).find(o => etcInfo[o].api_enc);

            param.job_data.account.apikeyData = {
              value: sellerApikey,
              field: api_field,
              isEncrypt: !no_apikeyDecrypt
            };
          }

          if (domain == 'makeshop.co.kr' && (action == 'scrap_category_large' || action == 'scrap_category_list')) {
            return commonModel.pa4(param, no_decrypt, true);
          }

          // api 프로미스 반환
          return commonModel.pa4(param, no_decrypt);
        } catch (err) {
          __master.showToaster('error', '', '쇼핑몰 정보 조회에 실패했습니다.');

          return false;
        }
      },

      /**
         * 2018-04-02 Daniel
         * null -> ""
         */
      replaceNull: function(obj) {
        _.each(obj, function(v, k) {
          if (!v && v != 0) {
            v = '';
          }

          //JSON STRING 외 문자열만 null 제거
          if (v && typeof v === 'string') {
            try {
              JSON.parse(v);
            } catch (err) {
              obj[k] = v.replace(/(null|undefined)/gi, '');

              return false;
            }
          }

          obj[k] = v;
        });

        return obj;
      },

      /**
         * 2018-06-14 Daniel
         * 쇼핑몰 코드로 그쇼핑몰 리스트만 추출
         */
      getShopList: function(siteList, shop_cds) {
        return _.filter(siteList, function (s) {
          return _.indexOf(shop_cds, s.shop_cd) > -1;
        });
      },

      /**
         * 날짜계산
         */
      dateDiff: function (_date1, _date2) {
        let diffDate_1 = _date1 instanceof Date ? _date1 : new Date(_date1);
        let diffDate_2 = _date2 instanceof Date ? _date2 : new Date(_date2);

        diffDate_1 = new Date(diffDate_1.getFullYear(), diffDate_1.getMonth() + 1, diffDate_1.getDate());
        diffDate_2 = new Date(diffDate_2.getFullYear(), diffDate_2.getMonth() + 1, diffDate_2.getDate());
        let diff = diffDate_2.getTime() - diffDate_1.getTime();
        const mp = diff > 0 ? -1 : 1;

        diff = Math.abs(diff);
        diff = Math.ceil(diff / (1000 * 3600 * 24)) * mp;

        return diff;
      },

      /**
         * 2018-06-25 Gargamel
         * 환경설정 [쇼핑몰 계정 표기 방식] 설정에 따른 ID or 별칭 표기
         */
      getShopIdViewText: function(shop_id_view_type, seller_nick_info, shop_cd, shop_id) {
        let shop_info;  // 표기 정보
        let shop_tinfo; // 표기 정보(툴팁)

        // 환경설정 [쇼핑몰 계정 표기 방식] 처리
        if (shop_id_view_type == 'id') {
          shop_info = seller_nick_info[shop_cd + shop_id] != '' && !_.isUndefined(seller_nick_info[shop_cd + shop_id]) ? seller_nick_info[shop_cd + shop_id] : shop_id;
          shop_tinfo = shop_id;

        } else {
          shop_info = shop_id;
          shop_tinfo = seller_nick_info[shop_cd + shop_id] != '' && !_.isUndefined(seller_nick_info[shop_cd + shop_id]) ? seller_nick_info[shop_cd + shop_id] : shop_id;
        }

        return [shop_info, shop_tinfo];
      },

      /**
         * 2018-12-17 Daniel
         * 들어온 데이터가 string이면 JSON으로 파싱후 리턴하는 함수
         *
         * @property data - anyType
         */
      isStringJsonParse: function(data) {
        if (typeof data === 'string') {
          return JSON.parse(data);
        } else {
          return data;
        }
      },

      /**
         * 2019-09-11 Jackal
         * 객체를 auto-complete directive에 넣을 수 있도록 key값 변경
         */
      autoCompleteObject: (newObj, oldObj, valueKey, nameKey) => {
        Object.keys(oldObj).forEach((k) => {
          const v = oldObj[k];
          const x = {
            value: v[valueKey],
            name: v[nameKey]
          };

          newObj[k] = x;
        });
      },

      getByteLength: str => {
        let b, c, i;

        for (b = i = 0; c = str.charCodeAt(i++); b += c >> 11 ? 3 : c >> 7 ? 2 : 1) { }

        return b;
      },

      cutByByte: (str, maxByte, isUtf8) => {
        let b, c, i;

        for (b = i = 0; c = str.charCodeAt(i);) {
          if (isUtf8) {
            b += c >> 11 ? 3 : c >> 7 ? 2 : 1;
          } else {
            b += c >> 7 ? 2 : 1;
          }

          if (b > maxByte) {
            break;
          }

          i++;
        }

        return str.substring(0, i);
      },

      cutByLength: (str, maxLength) => {
        return str.substr(0, maxLength);
      },

      isEmailFormat(str) {
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str);
      },

      range: n => Array.from(Array(n).keys()),

      downloadAll(files) {
        if (!files.length) {
          return;
        }

        const file = files.pop();

        const theAnchor = document.createElement('a');

        theAnchor.setAttribute('href', file);
        theAnchor.setAttribute('download', '');

        document.body.appendChild(theAnchor);

        theAnchor.click();
        theAnchor.remove();

        setTimeout(() => {
          __master.downloadAll(files);
        }, 300);
      },

      /**
       * 주문 상세 검색창 쇼핑몰 리스트 추출
       * @param {*} channelList 전체 쇼핑몰 리스트
       * @returns 검색 대상 쇼핑몰
       */
      getSiteList: function(channelList) {
        return _.unionBy(channelList, o => o.pa_shop_cd == 'X099' ? o.pa_shop_cd : o.shop_cd);
      },

      /**
       * 상세 검색창 선택 쇼핑몰 대상에 맞는 ID 추출
       * @param {*} channelList 전체 쇼핑몰 리스트
       * @returns 검색 대상 쇼핑몰
       */
      getSiteIdList: function(channelList) {
        const channelMap = new Map();
        channelList.map(channel => {
          if (channel.shop_cd !== 'X099') {
            const key = `${channel.shop_cd}|${channel.shop_id}`;
            const list = channelMap.get(key);
            if (!list) {
              channelMap.set(key, { ...channel, search_shop_id: key, shop_cds: [channel.pa_shop_cd, channel.shop_cd] });
            } else {
              list.shop_cds.push(channel.shop_cd);
            }
          }
        });

        return Array.from(channelMap.values());
      },

      /**
       * tts 문자열 읽어주는 함수
       */
      tts: text => {
        const msg = new SpeechSynthesisUtterance();

        msg.text = text;
        msg.lang = 'ko-KR';
        window.speechSynthesis.speak(msg);
      },

      // 2020-07-24 Boris
      // 테이블 정렬 일원화
      // 좌측 정렬이 기본값이고, 중앙, 우측 정렬을 정해주는 부분
      dtAlign: {
        alignCenterColumns: [
          'widget',                     // 도구
          'ship_unable_reason',         // 출고가능여부
          'map_yn',                     // 매칭여부
          'sale_cnt',                   // 주문수량
          'ship_delay_yn',              // 배송지연여부
          'multi_bundle_yn',            // 멀티바디여부
          'ord_status',                 // 주문상태
          'pack_unit',                  // 건별출고수량
          'total_cnt',                  // 총 출고수량
          'as_status',                  // A/S상태
          'ebaydepot_link_status',      // 연동상태
          'ebaydepot_send_avail_yn',    // 전송가능
          'add_opt_pack_unit',          // 추가구매옵션 수량
          'global_invoice_print_time',  // 글로벌 - 해외 송장 출력 시간
          'weight',                     // 글로벌 - 무게
          'ord_curr_cd',                // 글로벌 - 기준통화
          'misc15',                     // 글로벌 - 제조국(원산지)
        ],
        alignRightColumns: [
          'sales',                      // 금액
          'ship_cost',                  // 배송비
          'shop_cost_price',            // 원가
          'shop_supply_price',          // 공급가
          'as_cost',                    // A/S비용
          'seller_discount',            // 판매자부담할인액
          'shop_discount',              // 쇼핑몰부담할인액
          'coupon_discount',            // 쿠폰할인액
          'point_discount',             // 포인트 할인액
          'sales_price',                // 쇼핑몰 판매금액
          'sales_unit',                 // 쇼핑몰 판매단가
          'pay_amt',                    // 실결제금액
          'discount_amt',               // 총할인금액
        ],
        // -- 정렬 제외 컬럼 --
        // 여기 정의된 컬럼 + 각 데이터테이블에 정의된 컬럼
        notSortingColumns: [
          'widget',                     // 도구
          'notice_msg',                 // 기타메세지
          'pack_unit',                  // 건별출고수량
          'ord_bundle_gift_terms_prod', // 규칙적용사은품
          'shop_ship_no',               // 배송번호
          'order_msg',                  // 추가메세지
          'model_no',                   // 모델번호
          'sales_price',                // 글로벌 - 쇼핑몰 판매금액
          'weight',                     // 글로벌 - 무게
          'misc15',                     // 글로벌 - 제조국(원산지)
          'tag_pack'                    // 사용자태그
        ],
        notResizingColumns: [
          'widget'
        ]
      },
      /**
       * 두개의 배열이 동일한지 비교
       */
      arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }

        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }

        return true;
      },

      /**
       * 한글 영문자로 치환
       * (ex. ㅁㄴㅇㄹ -> asdf)
       */
      ko2en (str) {
        if (str) {
          return inko.ko2en(str);
        }
      },

      /**
       * 마스킹 처리.
       */
      maskingByPosiotion (str, start) {
        str = str.trim();
        const end = str.length;
        let temp = '';

        temp = str.substring(0, start);
        for (let i = start; i < end; i++) {
          temp = `${temp}*`;
        }

        return temp;
      }
    }; // end __master;

    return __master;
  }

  function swalExtend(params) {
    params.classNames = params.classNames || [];
    if (params.swalFunction === undefined) {
      swalOpen('swalExtend', 'No sweetalert function specified.', 'error');

      return;
    }
    if (params.buttonNum < 1) {
      swalOpen('swalExtend', 'Need at least one more button. Got a number less than 1.', 'error');

      return;
    }
    if (params.buttonNames.length == 0) {
      swalOpen('swalExtend', 'No button names specified.', 'warning');

      return;
    }
    if (params.buttonNames.length != params.buttonNum) {
      swalOpen('swalExtend', 'Number of buttons wanted does not match button names length.', 'error');

      return;
    }
    if (params.classNames.length > params.buttonNames.length) {
      swalOpen('swalExtend', 'Number of classNames in list is longer that intended buttons', 'error');

      return;
    }
    if (params.hasCallback === undefined) {
      swalOpen('swalExtend', 'hasCallback property is not defined.', 'error');

      return;
    }
    params.swalFunction(true);

    $('.confirm').on('click', function() {
      $('.swalExtendButton').hide();
    });

    if (params.hasCancelButton) {
      var container = document.querySelector('.sa-button-container');
      var cancel = document.querySelector('.cancel');
      var c = container.removeChild(cancel);
    }

    for (var i = 0; i < params.buttonNum; i++) {
      // if(document.getElementsByClassName("confirm").length < params.buttonNum+1){
      var itm = document.getElementsByClassName('sa-confirm-button-container')[0];
      var cln = itm.cloneNode(true);

      document.getElementsByClassName('sa-button-container')[0].appendChild(cln);

      var t = document.getElementsByClassName('confirm')[i + 1];
      var div = document.createElement('div');

      div.className = t.className;
      div.style.cssText = t.style.cssText;
      div.innerHTML = t.innerHTML;
      t.parentNode.replaceChild(div, t);

      if (div != undefined) {
        div.innerHTML = params.buttonNames[i] || div.innerHTML;
        var cl = div.className;
        var add = params.classNames[i] == undefined ? '' : params.classNames[i];

        div.className = params.classNames[i] == undefined ? cl + ' divbutton ' + add + ' swalExtendButton' : 'confirm ' + add + ' divbutton swalExtendButton';
      }

      if (params.clickFunctionList[i]) {
        div.addEventListener('click', params.clickFunctionList[i]);
        div.addEventListener('click', function() {
          sweetAlert.close();
          $('.swalExtendButton').hide();
        });
        // }
      }
      if (params.hasCancelButton) {
        container.appendChild(c);
      }
    }
    params.swalFunction(false);
    $('.swalExtendButton').show();
  }

  function swalOpen(swalOpt, callback, deleteSwalElement = true) {
    // 이전에 열어놨던 sweetAlert 엘리먼트 제거
    if (deleteSwalElement && $('.sweet-alert').length) {
      $('.sweet-alert').remove();
      $('.sweet-overlay').remove();
    }

    swal(swalOpt, callback);
  }

  async function swalOpen2(swalOpt) {
    try {
      const result = await Swal2.fire(swalOpt);

      return result.isConfirmed;
    } catch (err) {
      return false;
    }
  }

})();