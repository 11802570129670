'use strict';

angular.module('gmpApp')
  .factory('P048categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      },
      
      // 진열매장
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      }

    };
  });
