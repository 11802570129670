'use strict';

angular.module('gmpApp')
  .controller('GiftDeleteCtrl', function ($scope, $uibModalInstance, $timeout, data, giftModel, commonSVC, gettextCatalog) {
    $scope.ord_gift_del = false;
    $scope.selectList = data.selectList;

    $scope.ok = async () => {
      const confirm = await commonSVC.showConfirm('사은품 삭제', '추가된 사은품을 삭제합니다.');
      if (!confirm) {
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);

        return;
      }

      try {
        const result = await giftModel.del({ selectList: $scope.selectList, ord_gift_del: $scope.ord_gift_del });
        if (result.data.results === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사은품 삭제에 성공하였습니다.'));
          $uibModalInstance.close('success');
        } else {
          throw new Error();
        }
      } catch (error) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사은품 삭제에 실패하였습니다.'));
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
