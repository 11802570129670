'use strict';

angular.module('gmpApp')
  .factory('categorySVC', function () {
    return {
      // 카테고리 추가 정보가 있는 지 여부
      checkAdditionalInfo: {
        A001: false,
        A004: true,
        A006: false,
        A011: true,
        A012: true,
        A017: true,
        A032: true,
        A083: true,
        A084: true,
        A085: true,
        A100: true,
        A112: true,
        A131: true,
        A524: true,
        A118: true,
        B005: true,
        B007: true,
        B051: true,
        B118: true,
        B394: true,
        B430: true,
        B502: true,
        B603: true,
        B614: true,
        B616: true,
        B687: true,
        B691: true,
        B877: true,
        B917: true,
        B959: true,
        P015: true,
        P020: true,
        A008: false,
        A027: false,
        A064: false,
        A077: false,
        B048: false,
        B378: false,
        B513: false,
        B579: false,
        B594: false,
        B688: false,
        B719: false,
        B851: false,
        B878: false,
        B956: false,
        B700: false,
      },
      // 다중 카테고리를 지원하는 쇼핑몰
      mutiCategoryShops: [
        'B005', // 아임웹
        'P012', // 브랜디
        'P013', // 하이버
        'A528', // 패션플러스
        'P017', // 플레어
        'B720', // 스포츠매니아
        'P050', // 알렛츠몰
        'A124', // 한샘
      ]
    };
  });
