'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P044-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);

    // 제작일수(일)
    $scope.godDlivyWaitTime = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
      '28', '29', '30', '60', '90', '120', '150', '180'];

    /**
     * 상품소재
     * "/"로 구분 최소1 개 최대 3개
     * M_CT: 면, M_KM: 기모, M_DN: 데님, M_FU: fur, M_CD: 코듀로이, M_SD: 스웨이드, M_FC: 플리스, M_AG: 앙고라, M_WF: 모직, M_GD: 구스다운,
     * M_RC: 라쿤, M_DD: 덕다운, M_AP: 알파카, M_VV: 벨벳, M_LN: 리넨, M_SK: 실크, M_WL: wool, M_CM: 캐시미어, M_CF: 시폰, M_SX: 스판덱스, M_SF: 합성섬유, M_LT: 가죽,
     * M_SL: 인조가죽, M_GT: 고어텍스, M_SP: 서플렉스, M_WS: 윈드스토퍼, M_MS: 매쉬, M_1: 기타
     */
    $scope.matrCd = [
      { name: '면', code: 'M_CT' },
      { name: '기모', code: 'M_KM' },
      { name: '데님', code: 'M_DN' },
      { name: 'fur', code: 'M_FU' },
      { name: '코듀로이', code: 'M_CD' },
      { name: '스웨이드', code: 'M_SD' },
      { name: '플리스', code: 'M_FC' },
      { name: '앙고라', code: 'M_AG' },
      { name: '모직', code: 'M_WF' },
      { name: '구스다운', code: 'M_GD' },
      { name: '라쿤', code: 'M_RC' },
      { name: '덕다운', code: 'M_DD' },
      { name: '알파카', code: 'M_AP' },
      { name: '벨벳', code: 'M_VV' },
      { name: '리넨', code: 'M_LN' },
      { name: '실크', code: 'M_SK' },
      { name: 'wool', code: 'M_WL' },
      { name: '캐시미어', code: 'M_CM' },
      { name: '시폰', code: 'M_CF' },
      { name: '스판덱스', code: 'M_SX' },
      { name: '합성섬유', code: 'M_SF' },
      { name: '가죽', code: 'M_LT' },
      { name: '인조가죽', code: 'M_SL' },
      { name: '고어텍스', code: 'M_GT' },
      { name: '서플렉스', code: 'M_SP' },
      { name: '윈드스토퍼', code: 'M_WS' },
      { name: '매쉬', code: 'M_MS' },
      { name: '기타', code: 'M_1' }
    ];

    // 컬러칩
    $scope.colorCd = [
      { selected: false, name: 'BLACK', code: 'BK', color: '#000000' },
      { selected: false, name: 'BEIGE', code: 'BG', color: '#CBAF6F' },
      { selected: false, name: 'BROWN', code: 'BR', color: '#9C5A28' },
      { selected: false, name: 'CORAL', code: 'CL', color: '#F98473' },
      { selected: false, name: 'EMERALD', code: 'ED', color: '#13B986' },
      { selected: false, name: 'GOLD', code: 'GD', color: '#DEC65C' },
      { selected: false, name: 'WHITE', code: 'WT', color: '#FFFFFF' },
      { selected: false, name: 'GREY', code: 'GY', color: '#858279' },
      { selected: false, name: 'BLUE', code: 'BL', color: '#0070C0' },
      { selected: false, name: 'NAVY', code: 'NY', color: '#002060' },
      { selected: false, name: 'GREEN', code: 'GN', color: '#00B050' },
      { selected: false, name: 'KHAKI', code: 'KK', color: '#5B5627' },
      { selected: false, name: 'MINT', code: 'MT', color: '#BFFFDC' },
      { selected: false, name: 'YELLOW', code: 'LY', color: '#FFFFAF' },
      { selected: false, name: 'ORANGE', code: 'OG', color: '#FF866F' },
      { selected: false, name: 'RED', code: 'RD', color: '#FF0305' },
      { selected: false, name: 'PINK', code: 'PK', color: '#FE9AEB' },
      { selected: false, name: 'SEPIA', code: 'SP', color: '#AD4207' },
      { selected: false, name: 'VLOLET', code: 'VL', color: '#9C1D0D' },
      { selected: false, name: 'WHITEBRASS', code: 'WB', color: '#C9AB29' },
      { selected: false, name: 'STRIPE', code: 'SR', color: 'linear-gradient(90deg, #ffffff 25%, #000000 25%, #000000 50%, #ffffff 50%, #ffffff 75%, #000000 75%, #000000 100%)' },
    ];

    // 사이즈조견표 항목
    const goods_size_item = [
      { name: '총길이', code: 'totLen', type: ['A', 'B', 'C'] },
      { name: '어깨너비', code: 'shdrWidth', type: ['A'] },
      { name: '가슴둘레', code: 'brstGrth', type: ['A'] },
      { name: '소매길이', code: 'slvLen', type: ['A'] },
      { name: '소매폭', code: 'slvWidth', type: ['A'] },
      { name: '소매통', code: 'slvBrrl', type: ['E'] },
      { name: '허리둘레', code: 'waistGrth', type: ['B', 'C', 'D'] },
      { name: '밑위', code: 'lowLen', type: ['B', 'D'] },
      { name: '밑단', code: 'lowPce', type: ['B', 'D'] },
      { name: '허벅지둘레', code: 'thghGrth', type: ['B'] },
      { name: '엉덩이둘레', code: 'hipGrth', type: ['B'] },
    ];

    // 실측구분 항목
    $scope.acmsrGrpSectCd = [
      { name: '여성원피스', value: '여성원피스', type: 'A' },
      { name: '여성상의', value: '여성상의', type: 'A' },
      { name: '여성니트', value: '여성니트', type: 'A' },
      { name: '남성상의', value: '남성상의', type: 'A' },
      { name: '남성셔츠', value: '남성셔츠', type: 'A' },
      { name: '여성하의', value: '여성하의', type: 'B' },
      { name: '여성스커트', value: '여성스커트', type: 'C' },
      { name: '남성하의', value: '남성하의', type: 'B' },
      { name: '아동상의', value: '아동상의', type: 'A' },
      { name: '아동하의', value: '아동하의', type: 'D' },
    ];

    // 필수값 넣어줌
    if (!$scope.row.set_info.key_matrCd_temp_add) {
      $scope.row.set_info.key_matrCd_temp_add = 'temp';
    }
    if (!$scope.row.set_info.key_goods_size_chart_type) {
      $scope.row.set_info.key_goods_size_chart_type = 'D';
    }
    if (!$scope.row.set_info.key_colorCd) {
      $scope.row.set_info.key_colorCd = '';
    }

    if ($scope.row.set_info.key_colorCd) {
      $scope.colorCd.forEach(color => {
        color.selected = color.code === $scope.row.set_info.key_colorCd;
      });
    }

    // 실측구분 타입에 맞는 사이즈조견표 항목 가져오는 함수
    $scope.get_goods_size_items = [];
    $scope.acmsr_gubun = { type: '', name: '' };

    $scope.setSizeChart = (value) => {
      $scope.acmsr_gubun = $scope.acmsrGrpSectCd.find(acmsr => acmsr.value === value);
      $scope.get_goods_size_items = goods_size_item.filter(chart => chart.type.includes($scope.acmsr_gubun.type));
    };

    // 상품에서 사이즈 or size 옵션값을 가져오는 함수
    $scope.product_size = [];
    $scope.getProductSize = () => {
      const model_opt = data.prodOpt.opt;
      $scope.product_size = model_opt[0].attr.split(',');
    };

    // 컬러칩 변경 함수
    $scope.changeColor = (index, code) => {
      $scope.colorCd.forEach((item, idx) => {
        item.selected = idx === index ? !item.selected : false;
      });

      $scope.row.set_info.key_colorCd = code;
    };

    $scope.goodsSizeChartDetail = (type) => {
      $scope.row.set_info.key_sizeLktbHtml = type === 'D' ? '상품상세 참조' : '';
    };

    $scope.row.set_info.key_notice_start_time_hour = moment($scope.row.set_info.key_godNotiDspBegDt).hour() ?? $scope.row.set_info.key_notice_start_time_hour ?? '';
    $scope.row.set_info.key_notice_start_time_min = moment($scope.row.set_info.key_godNotiDspBegDt).minute() ?? $scope.row.set_info.key_notice_start_time_min ?? '';
    $scope.row.set_info.key_notice_end_time_hour = moment($scope.row.set_info.key_godNotiDspEndDt).hour() ?? $scope.row.set_info.key_notice_end_time_hour ?? '';
    $scope.row.set_info.key_notice_end_time_min = moment($scope.row.set_info.key_godNotiDspEndDt).minute() ?? $scope.row.set_info.key_notice_end_time_min ?? '';

    $scope.row.set_info.key_godNotiDspBegDt = $scope.row.set_info.key_godNotiDspBegDt?.replace(/ .*$/g, '') ?? '';
    $scope.row.set_info.key_godNotiDspEndDt = $scope.row.set_info.key_godNotiDspEndDt?.replace(/ .*$/g, '') ?? '';

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.row.set_info.key_matrCd_temp_add === 'add' && !$scope.row.set_info.key_matrCd) {
        commonSVC.showMessage('소재는 필수 입니다.');

        return false;
      }

      const martCd = [$scope.row.set_info.key_matrCd, $scope.row.set_info.key_matrCd2, $scope.row.set_info.key_matrCd3].filter(Boolean);
      const martCd2 = new Set(martCd);

      if (martCd.length !== martCd2.size) {
        commonSVC.showMessage('동일한 소재를 선택할 수 없습니다.');

        return false;
      }

      if ($scope.row.set_info.key_goods_size_chart_type === 'H' && !$scope.row.set_info.key_sizeLktbHtml) {
        commonSVC.showMessage('사이즈조견표를 입력해주세요.');

        return false;
      }

      if ($scope.row.set_info.key_godNotiDspBegDt && !$scope.row.set_info.key_newColumn2) {
        commonSVC.showMessage('상품공지사항을 작성하여주세요.');

        return false;
      }

      if ($scope.row.set_info.key_godNotiDspBegDt > $scope.row.set_info.key_godNotiDspEndDt) {
        commonSVC.showMessage('상품공지사항 시작 시간은 종료 시간보다 작을 수 없습니다.');

        return false;
      }

      $scope.row.set_info.key_godNotiDspBegDt = `${$scope.row.set_info.key_godNotiDspBegDt} ${[$scope.row.set_info.key_notice_start_time_hour, $scope.row.set_info.key_notice_start_time_min].filter(Boolean).join(':')}`;
      $scope.row.set_info.key_godNotiDspEndDt = `${$scope.row.set_info.key_godNotiDspEndDt} ${[$scope.row.set_info.key_notice_end_time_hour, $scope.row.set_info.key_notice_end_time_min].filter(Boolean).join(':')}`;

      $scope.row.set_info.key_goods_size = [];
      // 사이즈조견표 테이블데이터 json 형식으로 만드는 함수
      const setProductSizeChartData = () =>
        $scope.product_size.forEach((size, idx) =>
          $scope.row.set_info.key_goods_size.push($scope.get_goods_size_items.reduce((size_item, item) => {
            size_item = size_item || '';

            return { optValCd: size, ...{ ...size_item, acmsrGrpSectCd: $scope.acmsr_gubun.name, [item.code]: document.getElementById(`${item.code}_${idx}`).value } };
          }
          , {}))
        );

      if ($scope.row.set_info.key_goods_size_chart_type === 'C') {
        setProductSizeChartData();
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });