'use strict';

/**
 * 온라인 상품 번역
 */
angular.module('gmpApp')
  .controller('prodTranslate', function ($scope, $rootScope, $uibModalInstance, $filter, data, userInfo, settings, commonSVC, commonModel, onlineProductModel) {
    $scope.waitBtnDisableFlag = false;
    $scope.useCount = 0;
    $scope.saveSettings = {};
    $scope.tranLang = 'eng';
    $scope.selectedAll = false;
    $scope.oriData = data;
    $scope.tranList = { shop_sale_name: false, options: false, model: false, brand: false, made: false, detail_desc: false };
    $scope.optText = ''; // 옵션명/옵션값 총합
    $scope.freeFlag = true;
    $scope.submitFlag = false; // 번역 항목 선택 전까지는 비활성화
    $scope.loading = false;

    $scope.changeTranList = (type) => {
      if (!type) {
        Object.keys($scope.tranList).filter(key => key === 'options' ? $scope.optText?.length : $scope.oriData[key]?.length).forEach(key => $scope.tranList[key] = $scope.selectedAll);
      } else {
        $scope.selectedAll = Object.keys($scope.tranList).every(key => $scope.tranList[key]);
      }

      // 차감 예정 카운트 (상세설명만 카운트 차감)
      // $scope.useCount = $scope.tranList.detail_desc ? $scope.oriData.detail_desc.length : 0;

      // 번역하기 버튼 활성화 플래그: 잔여 카운트 없거나 번역 선택 항목이 없을 경우 비활성화
      // 잔여 카운트가 차감 예정 카운트보다 적을 경우 비활성화
      $scope.submitFlag = (Object.keys($scope.tranList).some(key => $scope.tranList[key]) && !$scope.useCount) || ($scope.totalCount && Object.keys($scope.tranList).some(key => $scope.tranList[key]) && $scope.totalCount >= $scope.useCount);
      // $scope.freeFlag = !$scope.useCount && Object.keys($scope.tranList).some(key => $scope.tranList[key]);
    };

    $scope.submit = async () => {
      try {
        const items = {};

        Object.keys($scope.tranList).forEach(key => {
          if (!$scope.tranList[key]) {
            return;
          }

          if (key === 'options') {
            items.options = $scope.optData;
          } else {
            items[key] = $scope.oriData[key];
          }
        });

        $scope.loading = true;

        const { data: res } = await onlineProductModel.translate({ items });

        $uibModalInstance.close(res);
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data.messages[0]);
      } finally {
        $scope.loading = false;
      }
    };

    const init = async () => {
      // 잔여 count 확인
      $scope.totalCount = (await commonModel.getServiceCnt()).data.trnslCnt.avail || 0;

      // 영어로만 이루어진 데이터는 제외
      for (const key of Object.keys($scope.oriData)) {
        const regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

        switch (key) {
          case 'options':
            if ($scope.oriData.options?.length) {

              const optArr = $scope.oriData.options.map(opt => {
                const attr = {
                  ...(opt.attr1 && regExp.test(opt.attr1) && { 1: opt.attr1 }),
                  ...(opt.attr2 && regExp.test(opt.attr2) && { 2: opt.attr2 }),
                  ...(opt.attr3 && regExp.test(opt.attr3) && { 3: opt.attr3 })
                };

                return attr;
              });

              $scope.optData = [[$scope.oriData.options[0].name1, $scope.oriData.options[0].name2, $scope.oriData.options[0].name3], optArr];
              $scope.optText = $scope.oriData.options
                .flatMap(opt => [opt.attr1, opt.attr2, opt.attr3])
                .filter(attr => attr && regExp.test(attr))
                .join('');
            }
            break;
          case 'detail_desc':
            break;
          default:
            if (!regExp.test($scope.oriData[key])) {
              $scope.oriData[key] = '';
            }
            break;
        }
      }

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    init();
  });
