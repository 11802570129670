'use strict';

angular.module('gmpApp')
  .service('shipmentModel', function ($rootScope, settings, commonSVC, shipmentSVC) {
    const commonAPI = {
      /**
       * 주문리스트
       * */
      list: function(param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/list`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },
      /**
       * 전체 주문리스트
       * */
      listSms: function(param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/listSms`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },

      /**
       * 2017-10-15 ally
       * 주문 상세 수정
       */
      update: function (param, next) {
        commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/order`, param, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      updateOrder: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/list-info`;

        commonSVC.sendUrl('PATCH', url, param, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 2017-10-15 ally
       * 주문건별입력
       */
      setManualOrder: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * 2017-10-15 ally
       * 합포장
       */
      makeBundle: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/combine`;
        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 2017-10-15 ally
       * 분할 해제
       */
      divideBundle: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/divide`;

        commonSVC.sendUrl('PATCH', url, param, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 2018-11-21 Jacob
       * shop_ord_no 로 ord uniq 불러오기
       */
      getOrdUniq: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/order/uniq/${params.shop_ord_no}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 2017-10-15 ally
       * 주문 상세 불러오기
       * */
      load: function (param, next) {
        const params = param;
        const url = `${settings.pa20ApiUrl}/app/order/detail`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            if (next) {
              next(state, data);
            }
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * 2018-03-06 Daniel
       * 주문로그
       */
      orderWorklog: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/order/work-logs`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * 주문삭제
       * */
      delete: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/order`;

        if (next) {
          commonSVC.sendUrl('DELETE', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('DELETE', url, params);
        }
      },

      /**
       * 교환재발송
       */
      copy: function (param) {
        let url;

        if (param.isResend) {
          url = `${settings.pa20ApiUrl}/app/order/claim/re-send-exchanges`;
        } else {
          url = `${settings.pa20ApiUrl}/app/order/copy`;
        }

        return commonSVC.sendUrl('POST', url, param);
      },

      /**
       * 2018-05-11 ally
       * 주문 일괄 변경
       * */
      orderSetCollective: function(data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/collectives`;

        commonSVC.sendUrl('PATCH', url, data, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 출고지시
       */
      deliveryInstruction: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/unstoring/instruction`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 이전 문의내역
       * */
      getBeforeCS: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/order/previous-inquiries/${params.uniq}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 출고보류
       */
      orderHold: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/hold`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }

      },
      /**
       * 출고보류 해제
       */
      orderUnhold: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/unhold`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }

      },

      /**
       * 출고완료
       */
      orderFinish: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/unstoring/complete`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 송장 전송용 쇼핑몰 아이디 리스트
       */
      sendDelivNoShopList: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice/shops`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 2018-01-04 ally
       * 주문상태 강제 변경
       * */
      setStatus: function(data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/setState`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 상품명 정리 규칙 조회
       */
      prodNameRuleDetail: function (ruleNo) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule/${ruleNo}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 상품명 정리 규칙 등록
       */
      prodNameRuleAdd: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 상품명 정리 규칙 수정
       */
      prodNameRuleEdit: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule`;

        return commonSVC.sendUrl('PUT', url, params);
      },

      /**
       * 상품명 정리 규칙 삭제
       */
      prodNameRuleRemove: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      /**
       * 상품명 정리 규칙 복사
       */
      prodNameRuleCopy: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule/copy`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 상품명 정리 규칙 적용
       */
      applyProdNameRule: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/productNameRule/apply`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 주문서 인쇄 양식 상세 조회
       */
      ordPrintFormDetail: function (templateNo) {
        const url = `${settings.pa20ApiUrl}/app/order/print-forms/${templateNo}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 주문서 인쇄 양식 추가
       */
      addOrdPrintForm: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/print-forms/new`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 주문서 인쇄 양식 수정
       */
      editOrdPrintForm: (param) => {
        const url = `${settings.pa20ApiUrl}/app/order/print-forms`;

        return commonSVC.sendUrl('PATCH', url, param);
      },

      /**
       * 주문서 인쇄 양식 복사
       */
      copyOrdPrintForm: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/print-forms/copy`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 주문서 인쇄 양식 삭제
       */
      deleteOrdPrintForm: function (templateNo) {
        const url = `${settings.pa20ApiUrl}/app/order/print-forms/${templateNo}`;

        return commonSVC.sendUrl('DELETE', url, {});
      },

      /**
       * 직접입력 쇼핑몰 양식 추가
       */
      addCustomShopExcelTemplate: data => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/order/custom-shop-excel-template`, data);
      },

      /**
       * 통합 엑셀 양식 복사
       */

      copyIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/copy`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 통합 엑셀 양식 추가
       */
      insertIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/add`;

        if (next) {
          return commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }

      },

      /**
       * 통합 엑셀 양식 수정
       */
      updateIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/edit`;

        if (next) {
          commonSVC.sendUrl('PUT', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PUT', url, data);
        }
      },

      /**
       * 통합 엑셀 양식 삭제
       */
      deleteIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/delete`;

        return commonSVC.sendUrl('DELETE', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 통합 엑셀 양식 불러오기
       */
      integratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/detail`;

        return commonSVC.sendUrl('GET', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU 통합 엑셀 양식 복사
       */

      copySkuIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/copy`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU 통합 엑셀 양식 추가
       */
      insertSkuIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/add`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU 통합 엑셀 양식 수정
       */
      updateSkuIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/edit`;

        if (next) {
          commonSVC.sendUrl('PUT', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PUT', url, data);
        }
      },

      /**
       * SKU 통합 엑셀 양식 삭제
       */
      deleteSkuIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/delete`;

        return commonSVC.sendUrl('DELETE', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU 통합 엑셀 양식 불러오기 (양식 상세)
       */
      skuIntegratedExcelTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/detail`;

        return commonSVC.sendUrl('GET', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 바코드출고매니저 상품리스트 조회
       */
      barcodeOrdProdList: (param) => {
        const url = `${settings.pa20ApiUrl}/app/order/barcode/listOfOrdProd`;

        return commonSVC.sendUrl('POST', url, param);
      },

      /**
       * 판매금액관리매니저 규칙 검수
       */
      checkDupSupplyPriceRule: (param) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/price-rules/checkDuplicate`, param);
      },

      /**
       * 판매금액관리매니저 규칙 추가
       */
      addSupplyPriceRule: (param) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/price-rules/add`, param);
      },

      /**
       * 판매금액관리매니저 규칙 수정
       */
      editSupplyPriceRule: (param) => {
        return commonSVC.sendUrl('PUT', `${settings.pa20ApiUrl}/app/price-rules`, param);
      },

      /**
       * 판매금액관리매니저 규칙 삭제
       */
      deleteSupplyPriceRule: (param) => {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/price-rules`, param);
      },

      /**
       * 판매금액관리매니저 규칙 적용
       */
      applySupplyPriceRule: (param) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/price-rules/apply`, param);
      },

      /**
       * 판매금액관리매니저 규칙 적용 복구
       */
      restoreSupplyPriceRule: (param) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/price-rules/restore`, param);
      },

      /**
       * 판매금액관리매니저 규칙 상세
       */
      supplyPriceRuleDetail: (supplyPriceRuleNo) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/price-rules/${supplyPriceRuleNo}`, {});
      },

      /**
       * 주문수량분할에 필요한 추가구매옵션 불러오기
       */
      getAddOptList: function (param) {
        const url = `${settings.pa20ApiUrl}/app/order/add-opt`;

        return commonSVC.sendUrl('GET', url, param);
      },

      /**
       * 사본있는지 여부
       */
      checkHasCopy: function(param) {
        const url = `${settings.pa20ApiUrl}/app/order/check-copy`;

        return commonSVC.sendUrl('POST', url, param);
      },

      /**
       * 배송정보 업로드 템플릿 추가
       */
      addInvoiceExcelUploadTemplate: param => {
        const url = `${settings.pa20ApiUrl}/app/order/invoiceExcelUploadTemplate/add`;

        return commonSVC.sendUrl('POST', url, param);
      },

      /**
       * 배송정보 업로드 템플릿 수정
       */
      editInvoiceExcelUploadTemplate: param => {
        const url = `${settings.pa20ApiUrl}/app/order/invoiceExcelUploadTemplate/edit`;

        return commonSVC.sendUrl('PUT', url, param);
      },

      /**
       * 배송정보 업로드 템플릿 삭제
       */
      deleteInvoiceExcelUploadTemplate: param => {
        const url = `${settings.pa20ApiUrl}/app/order/invoiceExcelUploadTemplate/delete`;

        return commonSVC.sendUrl('DELETE', url, param);
      },

      /**
       * 배송정보 업로드 템플릿 복사
       */
      copyInvoiceExcelUploadTemplate: param => {
        const url = `${settings.pa20ApiUrl}/app/order/invoiceExcelUploadTemplate/copy`;

        return commonSVC.sendUrl('POST', url, param);
      }
    };

    const solAPI = {

      /**
       * G마켓물류 주문리스트
       */
      ebaydepotList: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/list`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },

      /**
       * G마켓물류 주문리스트
       */
      ebaydepotListSms: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/listSms`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },

      /**
       * 상품 엑셀 다운로드
       */
      downExcel: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/order/excelDown`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },
      /**
       * SKU상품 매칭
       */
      matchingProd: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/matching/base`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 규칙사은품 삭제
       */
      matchingDeleteGift: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/gift/matching`;
        if (next) {
          commonSVC.sendUrl('DELETE', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('DELETE', url, data);
        }

      },
      /**
       * 규칙사은품 삭제
       */
      giftUsedCntList: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/gift/cnt-list`;

        return commonSVC.sendUrl('GET', url, data);
      },

      /**
       * 선택한 주문옵션에 매칭된 상품 리스트 불러오기
       */
      searchMappingProd: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/matching-product-code-list`;

        commonSVC.sendUrl('POST', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 매칭규칙수정
       */
      modifyMappingRule: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/matching-rules`;

        commonSVC.sendUrl('PATCH', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 매칭규칙삭제
       */
      delMappingRule: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/matching-rules`;

        commonSVC.sendUrl('DELETE', url, data, function(state, data) {
          next(state, data);
        });
      },

      /*
       * 배송조회 url 조회
       */
      shipmentTrackingView: function(params, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/order/tracking-url/${params.carr_no}`, {}, function(state, data) {
          next(state, data);
        });
      },

      /*
       * G마켓물류주문 sku5개 초과일시 추가구매옵션 분리
       */
      divideEbaydepotOrderAddOpt: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/divideByAddOpt`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 주문 분할하기
       */

      divideOrder: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/divide/quantity`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * G마켓물류 연동상태 수정
       */
      updateEbaydepotStatus: (data) => {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/update`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 재고 다중 매칭된 주문의 재고 리스트 불러오기
       */
      listOfMultiBase: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/matching-multi-base-list`;

        commonSVC.sendUrl('POST', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 알림 뱃지 신규 수집 주문 리스트(카운트) 읽음 처리
       */
      readAllNewOrdList: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/read-all-new-ord-list`;

        commonSVC.sendUrl('PATCH', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 패키지 합치기
       */
      addPackage: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/package/add`;
        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 패키지 생성
       */
      combinePackage: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/package/combine`;
        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }

      },

      /**
       * 패키지 분리
       */
      dividePackage: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/package/divide`;
        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 패키지 삭제
       */
      deletePackage: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/package/delete`;
        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }

      },

      /**
       * 국내 송장 출력을 위한 기본 정보 저장
       */
      saveDomesticAddressSetting: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/domesticAddressSetting/save`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 국내 송장 출력을 위한 기본 정보 불러오기
       */
      getDomesticAddressSetting: function () {
        const url = `${settings.pa20ApiUrl}/app/order/domesticAddressSetting`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 국내 송장 발행 주소 리스트 조회
       */
      addressTemplateList: function () {
        const url = `${settings.pa20ApiUrl}/app/order/addressTemplate/list`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 국내 송장 발행 주소 추가/수정
       */
      addressTemplateSave: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/addressTemplate/save`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 국내 송장 발행 주소 삭제
       */
      addressTemplateDelete: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/addressTemplate/delete`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 해외주문 프린트 완료 처리
       */
      globalOrderPrintComplete: function (data) {
        const url = `${settings.pa20ApiUrl}/app/order/globalOrderPrintComplete`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /*
       * G마켓물류 발송정보 전송 시 묶음번호 확인
       */
      checkBundleNo: (data) => {
        const url = `${settings.pa20ApiUrl}/app/order/checkBundleNo`;

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * 발주 관리 서비스 시작
       */
      startOrdManagement: () => {
        const url = `${settings.pa20ApiUrl}/app/order/manage/start`;

        return commonSVC.sendUrl('GET', url);

      },

      /**
       * 발주서 메일 템플릿 조회
       */
      loadOrderMailTemplate: () => {
        const url = `${settings.pa20ApiUrl}/app/order/manage/template-detail`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 발주서 메일 템플릿 등록
       */
      saveOrderMailTemplate: (data) => {
        const url = `${settings.pa20ApiUrl}/app/order/manage/template-save`;

        return commonSVC.sendUrl('POST', url, data);

      },

      /**
       * 발송 내역 파일 삭제
       */
      deleteOrderFileLogs: (data, next) => {
        const url = `${settings.pa20ApiUrl}/app/order/manage/delete-logs`;

        commonSVC.sendUrl('DELETE', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 발주서 전송
       */
      sendOrderEmail: (data, next) => {
        const url = `${settings.pa20ApiUrl}/app/order/manage/mail-send`;

        commonSVC.sendUrl('POST', url, data, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 발주서 전송 주문
       */
      sendOrderList: function(param, next) {
        const url = `${settings.pa20ApiUrl}/app/order/manage/order-list`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },

      /**
       * 지급 예정일 일괄 적용 및 삭제(OSSE)
       */
      updateSettDueDate: function(param) {
        const url = `${settings.pa20ApiUrl}/app/order/depot-sett-plan-date`;

        return commonSVC.sendUrl('PATCH', url, param);
      },

      /**
       * 중복의심 하제
       */
      releaseOfDuplicateSuspicion: function(param) {
        const url = `${settings.pa20ApiUrl}/app/order/suspicious/release`;

        return commonSVC.sendUrl('PATCH', url, param);
      }
    };

    const returnAPI = _.assignIn({}, commonAPI, solAPI);

    return returnAPI;
  });
