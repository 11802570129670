/**
 * 희망배송일 변경
 */
'use strict';

angular.module('gmpApp')
  .controller('updateHopeDateCtrl', function ($scope, data, workSVC, commonSVC, delaySVC, $uibModalInstance, $timeout) {
    $scope.delayDate = moment().format('YYYY-MM-DD');
    $scope.selectList = data.selectList || [];
    $scope.fromPage = data.fromPage;
    $scope.reasons = delaySVC.hopeDateDelayReason;
    $scope.selected_reason_value = null;

    $scope.save = function() {
      if (!$scope.selected_reason_value) {
        commonSVC.showMessage('변경사유를 선택해 주세요.');

        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
      } else {
        if ($scope.form1.$valid) {
          const params = {
            numbers: _.map(_.filter($scope.selectList, function(ord) { return ord.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(ord.misc19); }), 'uniq'),
            delay_code: $scope.selected_reason_value,
            delay_reason: $scope.reasons.find(r => r.value === $scope.selected_reason_value).label,
            delay_date: $scope.delayDate
          };

          // 작업 추가
          workSVC.addWorkSelect('UPDATE_HOPE_DATE', params);

          $uibModalInstance.close('success');
        }
      }

    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
