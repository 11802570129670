'use strict';

angular.module('gmpApp')
  .controller('SettingsSupplierAddCtrl', function ($uibModalInstance, $scope, supplierSVC, commonSVC, errorSVC, supplierModel) {

    $scope.supplierModel = {};
    $scope.supplier = {};
    $scope.supplier.use_yn = true;
    $scope.tab_active = 0; // 탭 활성화 설정
    $scope.validTab = [];

    init();

    /**
     * 초기 셋팅
     */
    function init() {
      // $scope.supplier = supplierModel;                               // model data (거래처 정보)
      // $scope.bank_list = supplierSVC.bank_list;                      // 은행 리스트
      $scope.business_type_list = supplierSVC.business_type_list;       // 사업자 리스트
      $scope.settlement_cycle_list = supplierSVC.settlement_cycle_list; // 정산주기 리스트
    }

    /**
     * 매입처 등록
     */
    $scope.onSubmit = async function() {
      if ($scope.form1.$valid) {
        try {
          const re = await supplierModel.insert($scope.supplier);

          if (re.data.results === 'success') {
            commonSVC.showToaster('success', '', '매입처 등록에 성공하였습니다.');
            $uibModalInstance.close('success');
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', errorSVC.getError('stock', err.data.message));
        }
      } else {
        //console.log($scope.form1);
      }
    };

    /**
     * 모달창 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
