/**
 * 2018-03-30 Daniel
 * 주의사항
 */
'use strict';

angular.module('gmpApp')
  .factory('cautionSVC', function () {
    return {
      searchData: {
        searchWord: '',
      }
    };
  });
