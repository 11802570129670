'use strict';

angular.module('gmpApp')
  /**
   * SKU(세트)상품 바코드 출력 모달 컨트롤러
   */
  .controller('PrintBarcodeCtrl',
    function ($uibModalInstance, data, $scope, $rootScope, $timeout, settings, productSVC, commonSVC, productModel, gettextCatalog, supplierModel, warehouseModel, systemModel, userInfo) {
      $scope.selectedProdList = angular.copy(data.prodList);

      let oldDefault;
      let templateList;
      let skuList;

      $scope.type = data.type;
      $scope.selectedTemplate;
      $scope.allCount;
      $scope.requiredPrintCount = false;
      $scope.printSetting = {
        emptyCnt: 0,
        sku: 'none'
      };

      $scope.searchForm = angular.copy(productSVC.searchForm);
      $scope.labelView = 'views/prod/product/modals/barcode_label.html';

      // 검색영역 데이터
      $scope.searchData = {};
      $scope.tableBtns = [
        {
          label: gettextCatalog.getString('삭제'),
          add_class: 'btn-danger',
          action: () => {
            $scope.deleteTemplate();
          },
        },
        {
          label: gettextCatalog.getString('수정'),
          add_class: 'btn-default',
          action: () => {
            $scope.editTemplate();
          },
        },
        {
          label: gettextCatalog.getString('복사'),
          add_class: 'btn-default',
          action: () => {
            $scope.copyTemplate();
          },
        },
        {
          label: gettextCatalog.getString('추가'),
          add_class: 'btn-default',
          action: () => {
            $scope.addTemplate();
          },
        }
      ];

      // 바코드 양식 데이터 테이블
      $scope.template = {};
      $scope.template.methods = {};
      $scope.template.options = {
        modal: true,
        gridHeight: 250,
        selectOptions: {
          enableRowSelection: true,
          multiSelect: false
        },
        enableRowSelection: true,
        paging: false,
        enablePaginationControls: false,
        enableScrollbars: true,
        enableHorizontalScrollbar: 0,
        enableVerticalScrollbar: 1,
        pinningColumns: [],
        defaultSortingColumns: ['mdate'],
        notMovingColumns: 'ALL',
        notSortingColumns: ['select', 'paper', 'defaultYn'],
        alignCenterColumns: ['select', 'mdate', 'template_name', 'paper', 'defaultYn'],
        notResizingColumns: [],
        notVisibleColumns: [],
        multiSort: true,
        initPagingSize: 0,
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/stock/barcode/templates`,
          requestWillAction: function(data) {
            data.length = 99999;

            return data;
          },
          requestDidAction: function(result) {
            if (result.results.length) {
              result.results.forEach((row) => {
                if (row.template_data) {
                  row.template_data = JSON.parse(row.template_data);
                  if (row.template_data.defaultYn) {
                    row.isSelected = true;
                  }
                }
              });

              templateList = angular.copy(result.results);

              // 기본양식 초기 선택 템플릿 지정
              $scope.selectedTemplate, oldDefault = result.results[0].template_no;
            }

            return result.results;
          }
        },
        columns: [
          {
            key: 'select',
            title: '출력양식 선택',
            width: 130,
            template: (row) => {
              return `<input type="radio" name="selectedTemplate" id="radio_${row.template_no}" ng-model="grid.appScope.selectedTemplate" ng-value="${row.template_no}" >`;
            }
          },
          {
            key: 'mdate',
            title: '최종수정일',
            width: 250,
          },
          {
            key: 'template_name',
            title: '양식명',
            width: 350
          },
          {
            key: 'paper',
            title: '출력용지',
            width: 200,
            template: (row) => {
              return row.template_data.paperType;
            }
          },
          {
            key: 'defaultYn',
            title: '기본양식 설정',
            width: 150,
            template: () => {
              return '<pa-ui-switch ng-model="row.entity.template_data.defaultYn" fn="grid.appScope.setDefault(row.entity)"></pa-ui-switch>';
            }
          },
        ]
      };

      //바코드 출력매수 입력(선택된 sku(세트)상품) 데이터 테이블
      $scope.prod = {};
      $scope.prod.methods = {};
      $scope.prod.options = {
        modal: true,
        gridHeight: 400,
        selectOptions: {
          checkbox: false,
          enableRowSelection: false,
          multiSelect: false
        },
        paging: false,
        enablePaginationControls: false,
        enableScrollbars: true,
        enableHorizontalScrollbar: 0,
        enableVerticalScrollbar: 1,
        pinningColumns: [],
        defaultSortingColumns: ['sku_cd'],
        notMovingColumns: 'ALL',
        notSortingColumns: data.type === 'set' ? ['widget', 'attri', 'depot_name', 'stock_cnt', 'print_cnt', 'barcode', 'stock_cd'] : ['widget', 'attri', 'depot_name', 'stock_cnt', 'print_cnt'],
        alignCenterColumns: ['widget', 'sku_cd'],
        alignRightColumns: [ $scope.type === 'set' ? 'sale_cnt_limit' : 'stock_cnt', 'print_cnt'],
        notVisibleColumns: [],
        notResizingColumns: ['widget', 'print_cnt'],
        multiSort: true,
        initPagingSize: 0,
        externalRequestOptions: {
          requestUrl: $scope.type === 'set' ? `${settings.pa20ApiUrl}/app/stock/set/list` : `${settings.pa20ApiUrl}/app/stock/base/list`,
          requestWillAction: function(d) {
            const data = angular.merge({}, d, $scope.searchForm);

            data.length = 99999;

            data.multi_type = $scope.type === 'set' ? 'set_cd' : 'sku_cd';
            data.multi_search_word = $scope.selectedProdList.join('\n');

            return data;
          },
          requestDidAction: function(result) {
            const today = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            result.results.forEach((sku) => {
              if (data.type !== 'set') {
                sku.cost_price = sku.cost_price.toLocaleString();
                sku.supply_price = sku.supply_price.toLocaleString();
                sku.label_print_date = today;
              } else {
                sku.depot_name = sku.delivery_vendor_name;
              }
              sku.sale_price = sku.sale_price.toLocaleString();
              sku.madein_name = sku.madein_name || sku.madein_etc;
              sku.printCnt = 1;
            });

            skuList = result.results;

            return result.results;
          },
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 50,
            template: function(row) {
              const click = $scope.type === 'set' ? `grid.appScope.showSetDetails('${row.set_no}','${row.depot_no}')` : `grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')`;

              return `<button class="btn btn-default btn-xxs mr-5" ng-click=${click}>상세</button>`;
            }
          },
          {
            key: 'sku_cd',
            title: 'SKU(세트)코드',
            width: 120
          },
          {
            key: 'prod_name',
            title: 'SKU(세트)상품명',
            width: 230
          },
          {
            key: 'attri',
            title: '속성',
            width: 50,
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 100,
          },
          {
            key: 'barcode',
            title: '바코드',
            width: 50,
          },
          {
            key: 'stock_cd',
            title: '재고관리코드',
            width: 50,
          },
          {
            key: 'stock_cnt',
            title: '실재고',
            width: 60,
          },
          {
            key: 'print_cnt',
            title: '출력매수',
            width: 100,
            template: function() {
              return '<input type="text" class="form-control input-xs-btnxxs fl-r" style="width: 50px; text-align: right; padding:0 5px;" ptgui-regex-mask="onlyNum" ng-model="row.entity.printCnt" ng-change="grid.appScope.checkMaxValue(row)"/>';
            }
          },
        ]
      };

      $scope.prodSet = angular.copy($scope.prod);
      $scope.prodSet.options.columns = [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function(row) {
            const click = $scope.type === 'set' ? `grid.appScope.showSetDetails('${row.set_no}','${row.depot_no}')` : `grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')`;

            return `<button class="btn btn-default btn-xxs mr-5" ng-click=${click}>상세</button>`;
          }
        },
        {
          key: 'set_cd',
          title: 'SKU(세트)코드',
          width: 120
        },
        {
          key: 'set_name',
          title: 'SKU(세트)상품명',
          width: 230
        },
        {
          key: 'delivery_vendor_name',
          title: '배송처',
          width: 100,
        },
        {
          key: 'sale_cnt_limit',
          title: '세트판매수량',
          width: 60,
        },
        {
          key: 'print_cnt',
          title: '출력매수',
          width: 100,
          template: function() {
            return '<input type="text" class="form-control input-xs-btnxxs fl-r" style="width: 50px; text-align: right; padding:0 5px;" ptgui-regex-mask="onlyNum" ng-model="row.entity.printCnt" ng-change="grid.appScope.checkMaxValue(row)"/>';
          }
        },
      ];

      /**
       *  출력매수 최대값 제한
       */
      $scope.checkMaxValue = function(row) {
        if (row.entity.printCnt > 1000) {
          row.entity.printCnt = 1000;
        }
      };

      /**
       * 출력매수 일괄입력
       */
      $scope.setAllCount = function() {
        if (!$scope.allCount) {
          $scope.requiredPrintCount = true;
        } else {
          $scope.requiredPrintCount = false;
        }

        skuList.map(sku => {
          sku.printCnt = Number($scope.allCount);
        });
      };

      // row 선택 변경 감지(라디오버튼 체크 및 출력설정 영역 노출여부 처리)
      $scope.$watch('template.methods.selectedData("all")[0]', function(newSelectedRow, oldSelectedRow) {
        if (newSelectedRow !== oldSelectedRow) {
          if (newSelectedRow) {
            $scope.selectedTemplate = newSelectedRow.template_no;
            if (newSelectedRow.template_data.paperType === 'A4용지') {
              $scope.showPrintSettingArea = true;
            } else {
              $scope.showPrintSettingArea = false;
            }
            setTemplate();
          } else {
            $scope.selectedTemplate = null;
          }
        }
      }, true);

      $scope.searchFn = {
        searchDo: (type) => {
          $scope.searchDo(type, true, true);
        }
      };

      /**
       * 테이블 검색
       */
      $scope.searchDo = (type, refresh = true, noDelay) => {
        $scope[type].methods.reloadData(null, refresh, noDelay);
      };

      /**
       * 바코드 출력양식 삭제
       */
      $scope.deleteTemplate = () => {
        const template = $scope.template.methods.selectedData('all')[0];
        if (!template) {
          commonSVC.showMessage('삭제할 양식을 선택해 주세요.');

          return false;
        }

        if (template.template_data.defaultYn) {
          commonSVC.showMessage('기본양식은 삭제할 수 없습니다.');

          return false;
        }

        try {
          commonSVC.showConfirm('양식을 삭제 하시겠습니까?', '', async function() {
            const re = await productModel.deleteBarcodeTemplate({ template_no: template.template_no });
            if (re.data.results[0] === 'success') {
              commonSVC.showToaster('success', '성공', '바코드 출력양식 삭제 성공');
              $scope.searchDo('template');
            } else {
              throw new Error();
            }
          });

        } catch (err) {
          commonSVC.showToaster('error', '실패', '바코드 출력양식 삭제 실패');
        }

      };

      /**
       * 바코드 출력양식 복사
       */
      $scope.copyTemplate = async () => {
        const template = $scope.template.methods.selectedData('all')[0];
        if (!template) {
          commonSVC.showMessage('복사할 양식을 선택해 주세요.');

          return false;
        }

        try {
          const re = await productModel.addBarcodeTemplate({ template_name: template.template_name, template_data: template.template_data, type: 'copy' });

          if (re.data.results[0] === 'success') {
            commonSVC.showToaster('success', '성공', '바코드 출력양식 복사 성공');
            $scope.searchDo('template');
          } else {
            throw new Error();
          }

        } catch (err) {
          commonSVC.showToaster('error', '실패', '바코드 출력양식 복사 실패');
        }
      };

      /**
       * 바코드 출력양식 추가
       */
      $scope.addTemplate = async () => {
        const resolve = {
          data: {
            type: 'add'
          }
        };
        const addModal = await commonSVC.openModal('xg', resolve, 'BarcodeTemplateCtrl', 'views/prod/product/modals/print_barcode_template.html');

        if (await addModal.result === 'success') {
          commonSVC.showToaster('success', '성공', '바코드 출력양식 추가 성공');
          $scope.searchDo('template');
        }
      };

      /**
       * 바코드 출력양식 수정
       */
      $scope.editTemplate = async () => {
        const template = $scope.template.methods.selectedData('all')[0];
        if (!template) {
          commonSVC.showMessage('수정할 양식을 선택해 주세요.');

          return false;
        }

        const resolve = {
          data: {
            type: 'edit',
            template: template
          }
        };

        const editModal = await commonSVC.openModal('xg', resolve, 'BarcodeTemplateCtrl', 'views/prod/product/modals/print_barcode_template.html');

        if (await editModal.result === 'success') {
          commonSVC.showToaster('success', '성공', '바코드 출력양식 수정 성공');
          $scope.searchDo('template');
        }
      };

      /**
       * 기본양식 변경
       */
      $scope.setDefault = async function(row) {
        try {
          if (oldDefault !== row.template_no) {
            const oldTemplate = templateList.find((template) => template.template_no === oldDefault);
            row.template_data.defaultYn = true;
            oldTemplate.template_data.defaultYn = false;

            const results = await Promise.all([productModel.updateBarcodeTemplate({ template_no: oldTemplate.template_no, template_name: oldTemplate.template_name, template_data: oldTemplate.template_data }), productModel.updateBarcodeTemplate({ template_no: row.template_no, template_name: row.template_name, template_data: row.template_data })]);

            if (results.every(result => result.data.results[0] === 'success')) {
              commonSVC.showToaster('success', '', '기본양식 변경 성공');
              $scope.searchDo('template');
            } else {
              throw new Error();
            }

          } else {
            commonSVC.showMessage('기본 양식은 해제할 수 없으며 변경만 가능합니다.');

            return false;
          }
        } catch (error) {
          commonSVC.showToaster('error', '', '기본양식 변경 실패');

          return false;
        }
      };

      /**
       * 선택한 출력 양식 적용
       */
      const setTemplate = function() {
        // 양식 선택 유무 확인
        if (!$scope.selectedTemplate) {
          commonSVC.showMessage('바코드 출력 양식을 선택해 주세요.');

          return false;
        }

        const template = templateList.find((template) => template.template_no === $scope.selectedTemplate);
        const paperSet = template.template_data.paperSetData;

        if (template.template_data.paperType === 'A4용지') {
          $('#label-html').css('width', '210mm');

        } else {
          $('#label-html').css('width', `${parseFloat(paperSet.labelSize.width * paperSet.countPerPage.width) + parseFloat(paperSet.labelSpace.right * (paperSet.countPerPage.width - 1))}mm`);
        }

        return template;
      };

      /**
       * 미리보기 / 출력하기 버튼 클릭 시 실행
       */
      $scope.print = async function() {
        const template = setTemplate();
        const paperSet = template.template_data.paperSetData;
        const printArea = $('#label-html');
        const onePageCnt = paperSet.countPerPage.width * paperSet.countPerPage.height;

        let printItems = angular.copy(template.template_data.printItems);
        let colNo = 0;
        let labelNo = 0;

        if (data.type === 'set') {
          printItems = printItems.filter(item => ['sku_set_cd', 'prod_set_name', 'depot_name', 'sale_price', 'custom_text_1', 'custom_text_2', 'custom_text_3', 'custom_text_4'].includes(item.skuKey));
        }

        const barcodeItems = printItems.filter(item => item.isBarcode && item.detail.printBarcode);

        function addBr() {
          printArea.append('<br style="clear:both" />');
          colNo = 0;
        }

        function addPageBreak() {
          printArea.append('<br style="clear:both" /><div class="page-break" style="page-break-after:always;height:0;line-height:0;clear:both"></div>');
          colNo = 0;
          labelNo = 0;
        }

        //a4용지일 경우 출력 앞에 몇칸 비우고 인쇄때문에 빈칸 추가
        if (template.template_data.paperType === 'A4용지' && $scope.printSetting.emptyCnt) {
          for (let cnt = 0; cnt < $scope.printSetting.emptyCnt; cnt++) {
            // 1. 열, 라벨 번호 증가
            colNo++;
            labelNo++;

            // 2. 라벨과 똑같은 크기의 div 추가
            printArea.append(`
                <div class="no-margin no-padding a4-label-wrapper" style="width: ${paperSet.labelSize.width}mm; height: ${paperSet.labelSize.height}mm; margin: 0 ${colNo == paperSet.countPerPage.width ? 0 : paperSet.labelSpace.right}mm ${paperSet.labelSpace.bottom}mm 0; float: left;">
              </div>
            `);

            // 만약 열개수가 페이지 셋팅 가로개수면 줄바꿈 추가하고 열번호 초기화
            if (colNo == paperSet.countPerPage.width) {
              addBr();
            }
          }
        }

        showSpinner('printSpinner', '바코드 생성중');

        try {
          // skuList에서 sku별로 순회하면서 작업
          skuList.forEach((sku, idx, arr) => {
            const idNo = data.type === 'set' ? sku.set_no : sku.prod_no;
            let innerHTML = '';
            printItems.forEach((item) => {
              const stringStyle = Object.keys(item.detail.style)
                .map(key => `${key}:${item.detail.style[key]}`)
                .join(';');
              if (item.isBarcode && item.detail.printBarcode) {
                innerHTML += `
                  <div style="${stringStyle}; position:absolute;" class="img-wrapper">
                    <svg class="${item.skuKey}" viewBox="0 0 100 100" display="block" preserveAspectRatio="none">
                    </svg>
                  </div>
                `;
              } else {
                item.skuKey = item.skuKey === 'prod_set_name' ? data.type === 'set' ? 'set_name' : 'prod_name' : item.skuKey === 'sku_set_cd' ? data.type === 'set' ? 'set_cd' : 'sku_cd' : item.skuKey;
                innerHTML += `
                  <div style="${stringStyle};overflow:hidden;text-overflow:ellipsis;color:black;">
                  ${item.isPrice && item.detail.priceUnit !== '원' ? item.detail.priceUnit : ''}${item.isInputText ? item.detail.inputText : sku[item.skuKey] ? sku[item.skuKey] : ''}${item.isPrice && item.detail.priceUnit === '원' ? item.detail.priceUnit : ''}
                  </div>
                `;
              }
            });

            // 출력매수만큼 반복추가해야함
            for (let i = 0; i < sku.printCnt; i++) {
              const uniqId = `label-${idNo}-${i}`;
              // 1. 개수 추가
              colNo++;
              labelNo++;

              // 2. 요소 추가
              printArea.append(`
                <div class="no-padding label-wrapper" id="${uniqId}" style="width:${paperSet.labelSize.width}mm; height:${paperSet.labelSize.height - 1}mm; margin:0 ${colNo == paperSet.countPerPage.width ? 0 : paperSet.labelSpace.right}mm ${paperSet.labelSpace.bottom}mm 0; float:left; position:relative; ">
                ${innerHTML}
              </div>
            `);

              // 바코드 생성항목 있으면 바코드 생성 -> 중간에 실패나면 일괄실패
              if (barcodeItems.length) {
                barcodeItems.forEach((item) => {
                  let prop = item.skuKey;
                  if (item.skuKey === 'sku_set_cd') {
                    prop = $scope.type === 'set' ? 'set_cd' : 'sku_cd';
                  }
                  if (sku[prop]) {
                    try {
                      JsBarcode(`#${uniqId} .${item.skuKey}`, `${sku[prop]}`, {
                        margin: 0,
                        textMargin: 0,
                        format: 'CODE128',
                        width: 3,
                        displayValue: item.detail.printText,
                        fontOptions: 'bold',
                        fontSize: 30,
                        font: 'Nanum Gothic Coding'
                      });
                      $(`#${uniqId} .${item.skuKey}`)[0].setAttribute('width', '100%');
                      $(`#${uniqId} .${item.skuKey}`)[0].setAttribute('height', '100%');
                    } catch (err) {
                      commonSVC.showMessage('바코드를 생성할 수 없습니다.', '• 바코드 생성 항목 중 한글, 특수문자가 포함된 상품이 있습니다.');

                      throw err;
                    }
                  }

                });
              }

              // 3. 마지막 요소가 아니라면 용지, 출력 설정에 따라 페이지 나눔or 줄바꿈 처리필요하여 분기
              if (idx < arr.length && i < sku.printCnt) {
                if (template.template_data.paperType === '라벨전용지') { // 라벨전용지일경우
                // 가로개수 채워지면 page-break추가
                  if (colNo == paperSet.countPerPage.width) {
                    addPageBreak();
                  }
                } else { // a4용지일경우 sku별 출력설정에 따른 분기
                  if ($scope.printSetting.sku === 'none') { // 설정안함인 경우
                    if (labelNo === onePageCnt) { // 총개수 다 찼으면 페이지 브레이크 추가
                      addPageBreak();
                    } else if (colNo == paperSet.countPerPage.width) { // 가로개수만 다 찼으면 줄바꿈만 추가
                      addBr();
                    }

                  } else if ($scope.printSetting.sku === 'line') { // sku별 줄바꿈인경우
                  // 출력매수 마지막카운트인경우 줄바꿈 추가하고 라벨 번호도 빈칸만큼 추가해줌
                    if (i === sku.printCnt - 1 && colNo !== paperSet.countPerPage.width) {
                      labelNo += paperSet.countPerPage.width - colNo;
                      addBr();
                    } else if (colNo == paperSet.countPerPage.width) { // 가로개수만 다 찼으면 줄바꿈만 추가
                      addBr();
                    }
                    // 만약한페이지 총 개수 다 채웠으면 페이지브레이크 추가
                    if (labelNo === onePageCnt) {
                      addPageBreak();
                    }

                  } else if ($scope.printSetting.sku === 'page') { //sku별 페이지 나눔인경우
                  // 출력매수 마지막카운트인경우 또는 총개수가 다 찬 경우 페이지브레이크 추가
                    if (i === sku.printCnt - 1 || labelNo === onePageCnt) {
                      addPageBreak();
                    } else if (colNo == paperSet.countPerPage.width) { // 가로개수만 다 찼으면 줄바꿈만 추가
                      addBr();
                    }
                  }
                }
              }
            }
          });

          // pdf 출력
          $timeout(async () => {
            try {
              const re = await productModel.exportBarcodePdf({ htmlContent: document.getElementById('label-html').innerHTML, template: template.template_data });

              if (re.data.results[0]) {
                commonSVC.showToaster('success', '성공', '바코드 pdf 출력 성공');
                window.open(re.data.results[0], '_blank');
                hideSpinner('printSpinner');
                printArea.html('');
              } else {
                throw new Error();
              }

            } catch (err) {
              commonSVC.showToaster('error', '실패', '바코드 pdf 출력 실패');
              hideSpinner('printSpinner');
              printArea.html('');

              return false;
            }
          }, 1000);
        } catch (err) {
          hideSpinner('printSpinner');
          printArea.html('');

          return false;
        }
      };

      /**
       * 상세페이지 모달창
       */
      $scope.showDetail = function(show, sku_cd, prod_number) {
        if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
          return false;
        }

        const resolve = {
          data: {
            sku: sku_cd,
            number: prod_number,
            detailType: 'edit'
          }
        };

        resolve.supplierList = function() {
          return supplierModel.ListAll({ use_yn: true });
        };
        resolve.warehouseList = function() {
          return warehouseModel.ListAll({ use_yn: true });
        };
        resolve.madeinList = function() {
          return productModel.getMadein();
        };
        resolve.systemList = function() {
          return systemModel.load();
        };
        const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

        modal.result.then(function () {

          //리스트 유지한채 데이터 업데이트
          if ($scope.type === 'set') {
            $scope.prodSet.methods.reloadData(function() {}, false);
          } else {
            $scope.prod.methods.reloadData(function() {}, false);
          }

        });
      };

      /**
       * 세트 상세보기
       */
      $scope.showSetDetails = function(set_no, depot_no) {
      // 세트상품 수정시도 생성권한으로 보기
        if (commonSVC.checkPermission('stock.roles.addSet', userInfo.permission)) {
          const resolve = {
            data: { from: '수정', set_no: set_no, depot_no: depot_no }
          };

          const modal = commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');

          modal.result.then(function () {
            $scope.grid.methods.reloadData(function() {}, false);
          });
        }
      };

      // 로딩스피너 보이기
      function showSpinner(id, text) {
        $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
        $(`#${id}`).removeClass('hidden');
      }

      // 로딩스피너 숨기기
      function hideSpinner(id) {
        $(`#${id}`).addClass('hidden');
      }

      $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  )

  /**
   * 바코드 출력양식 추가/수정 모달 컨트롤러
   */
  .controller('BarcodeTemplateCtrl', function($uibModalInstance, data, $scope, $timeout, settings, productSVC, commonSVC, productModel) {
    $scope.type = data.type;
    $scope.labelPreview = 'views/prod/product/modals/barcode_label.html';
    $scope.currentItem = '';
    $scope.showDetailTable = false;
    $scope.validation = {
      labelSize: true,
      countPerPage: true,
      selectItem: true,
      input_custom_text_1: true,
      input_custom_text_2: true,
      input_custom_text_3: true,
      input_custom_text_4: true,
      input_custom_text_5: true,
    };
    $scope.paperType = '라벨전용지';
    $scope.paperSetName = '라벨전용지';
    let a4SetName = '3 X 6 (18개)';
    let isInit = true;

    $scope.paperSettings = {
      '라벨전용지': {
        labelSize: { width: 50, height: 30 },
        labelSpace: { right: 0, bottom: 0 },
        countPerPage: { width: 1 },
        paperSpace: { top: 0 },
      },
      '직접설정': {
        labelSize: { width: 0, height: 0 },
        labelSpace: { right: 0, bottom: 0 },
        countPerPage: { width: 0, height: 0 },
        paperSpace: { top: 0 },
      },
      '3 X 6 (18개)': {
        labelSize: { width: 63.5, height: 45 },
        labelSpace: { right: 2.5, bottom: 1 },
        countPerPage: { width: 3, height: 6 },
        paperSpace: { top: 10, left: 7 }
      },
      '3 X 7 (21개)': {
        labelSize: { width: 63.5, height: 38 },
        labelSpace: { right: 2.5, bottom: 1 },
        countPerPage: { width: 3, height: 7 },
        paperSpace: { top: 18, left: 8 }
      },
      '3 X 8 (24개)': {
        labelSize: { width: 64, height: 33 },
        labelSpace: { right: 2.5, bottom: 2 },
        countPerPage: { width: 3, height: 8 },
        paperSpace: { top: 14, left: 7 }

      },
      '3 X 9 (27개)': {
        labelSize: { width: 62.6, height: 30.1 },
        labelSpace: { right: 2.5, bottom: 1 },
        countPerPage: { width: 3, height: 9 },
        paperSpace: { top: 13, left: 9 }
      },
      '4 X 10 (40개)': {
        labelSize: { width: 47, height: 26.9 },
        labelSpace: { right: 2.5, bottom: 1 },
        countPerPage: { width: 4, height: 10 },
        paperSpace: { top: 16, left: 7 }
      },
      '4 X 21 (84개)': {
        labelSize: { width: 46, height: 11 },
        labelSpace: { right: 4.5, bottom: 2.45 },
        countPerPage: { width: 4, height: 21 },
        paperSpace: { top: 17, left: 6 }
      },
    };

    const originalSettings = angular.copy($scope.paperSettings);

    $scope.fontSizes = [...Array(31).keys()].slice(6).map(i => i + 'pt'); // 변경 가능한 폰트 크기 리스트

    $scope.printItems = {
      prod_set_name: {
        isCheck: false,
        name: '상품명',
        detail: {}
      },
      sku_set_cd: {
        isCheck: false,
        name: 'SKU(세트)코드',
        isBarcode: true,
        detail: {}
      },
      attri: {
        isCheck: false,
        name: '속성',
        detail: {}
      },
      depot_name: {
        isCheck: false,
        name: '배송처',
        detail: {}
      },
      supp_name: {
        isCheck: false,
        name: '매입처',
        detail: {}
      },
      model: {
        isCheck: false,
        name: '모델명',
        detail: {}
      },
      barcode: {
        isCheck: false,
        name: '바코드',
        isBarcode: true,
        detail: {}
      },
      stock_cd: {
        isCheck: false,
        name: '재고관리코드',
        isBarcode: true,
        detail: {}
      },
      supply_price: {
        isCheck: false,
        name: '공급가',
        isPrice: true,
        detail: {}
      },
      cost_price: {
        isCheck: false,
        name: '원가',
        isPrice: true,
        detail: {}
      },
      brand: {
        isCheck: false,
        name: '브랜드',
        detail: {}
      },
      sale_price: {
        isCheck: false,
        name: '판매가',
        isPrice: true,
        detail: {}
      },
      maker_eng: {
        isCheck: false,
        name: '영문제조사',
        detail: {}
      },
      maker: {
        isCheck: false,
        name: '제조사',
        detail: {}
      },
      style_cd: {
        isCheck: false,
        name: '스타일코드',
        detail: {}
      },
      madein_name: {
        isCheck: false,
        name: '원산지',
        detail: {}
      },
      made_date: {
        isCheck: false,
        name: '제조일자',
        detail: {}
      },
      label_print_date: {
        isCheck: false,
        name: '라벨출력일자',
        detail: {}
      },
      memo_content: {
        isCheck: false,
        name: '메모',
        detail: {}
      },
      expire_date: {
        isCheck: false,
        name: '유효일자',
        detail: {}
      },
      custom_text_1: {
        isCheck: false,
        name: '직접입력 텍스트1',
        isInputText: true,
        detail: {
          inputText: '직접입력 텍스트1'
        }
      },
      custom_text_2: {
        isCheck: false,
        name: '직접입력 텍스트2',
        isInputText: true,
        detail: {
          inputText: '직접입력 텍스트2'
        }
      },
      custom_text_3: {
        isCheck: false,
        name: '직접입력 텍스트3',
        isInputText: true,
        detail: {
          inputText: '직접입력 텍스트3'
        }
      },
      custom_text_4: {
        isCheck: false,
        name: '직접입력 텍스트4',
        isInputText: true,
        detail: {
          inputText: '직접입력 텍스트4'
        }
      },
    };

    $scope.template = {
      name: '',
      data: {
        defaultYn: false,
        paperType: '',
        paperSetName: '',
        paperSetData: {},
        printItems: []
      }
    };

    const detail = {
      printBarcode: true,
      printText: false,
      isBold: false,
      priceUnit: '원',
      inputText: '',
      style: {
        'position': 'absolute',
        'text-align': 'left',
        'font-size': '9pt',
        'font-weight': '400',
      }
    };

    $scope.changePaperType = function() {
      if ($scope.paperType === '라벨전용지') {
        $scope.paperSetName = '라벨전용지';
      } else {
        if (isInit) {
          isInit = false;
          $scope.paperSetName = '3 X 6 (18개)';

        } else {
          $scope.paperSetName = a4SetName;
        }
      }
      validatePaperSetting('labelSize');
      validatePaperSetting('countPerPage');
    };

    $scope.changeA4Setting = function() {
      a4SetName = angular.copy($scope.paperSetName);
      validatePaperSetting('labelSize');
      validatePaperSetting('countPerPage');
    };

    $scope.checkChangeBarcodeSetting = function(type) {
      const item = $scope.printItems[$scope.currentItem].detail;

      if (item.printText && item.printText) {
        $scope.removeItem($scope.currentItem);
      }

      //바코드 출력, 문자출력 둘다 해제되는건 불가함
      if (!item.printBarcode && !item.printText) {
        item[type] = true;

        return false;
      }
      $scope.editItem('barcodePrintSetting');
    };

    // 미리보기 요소 수정
    $scope.editItem = function(type) {
      const itemData = $scope.printItems[$scope.currentItem];
      const elm = document.getElementById(`preview-data-${$scope.currentItem}`);

      if (type === 'fontSize') {
        const originFontSize = parseInt(itemData.detail.style['font-size']);
        elm.style.fontSize = `${originFontSize * 3}pt`;
      }

      if (type === 'isBold') {
        if (itemData.detail.isBold) {
          elm.style.fontWeight = itemData.detail.style['font-weight'] = '600';
        } else {
          elm.style.fontWeight = itemData.detail.style['font-weight'] = '400';
        }
      }

      if (type === 'textAlign') {
        elm.style.textAlign = itemData.detail.style['text-align'];
      }

      if (type === 'barcodePrintSetting') {
        if (itemData.detail.printBarcode) {
          JsBarcode(`.barcode-${$scope.currentItem}`, `${$scope.currentItem}`, {
            format: 'CODE128',
            margin: 0,
            textMargin: 0,
            width: 3,
            displayValue: itemData.detail.printText,
            fontOptions: 'bold',
            fontSize: 30,
            font: 'Nanum Gothic Coding'
          });

        } else {
          if (itemData.detail.printText) {
            $scope.addItem($scope.currentItem);
          }
        }
      }

      if (type === 'inputText') {
        elm.firstChild.replaceData(0, -1, itemData.detail.inputText);
        if (!itemData.detail.inputText) {
          $scope.validation[`input_${$scope.currentItem}`] = false;
        } else {
          $scope.validation[`input_${$scope.currentItem}`] = true;
        }
      }
    };

    $scope.addItem = function(key, mode) {
      const item = $scope.printItems[key];
      let elm;

      if (item.isBarcode && item.detail.printBarcode) {
        elm = document.getElementById(`barcode-wrapper-${key}`);
        JsBarcode(`.barcode-${key}`, `${key}-123`, {
          margin: 0,
          textMargin: 0,
          format: 'CODE128',
          width: 3,
          displayValue: item.detail.printText,
          fontOptions: 'bold',
          fontSize: 30,
          font: 'Nanum Gothic Coding'
        });
      } else {
        // div 생성
        elm = document.createElement('div');
        elm.innerText = item.name;
        elm.setAttribute('preview-data', key);
        elm.setAttribute('class', 'preview-items');
        elm.setAttribute('id', `preview-data-${key}`);

        // 미리보기 영역에 child로 위에서 생성한 div(=elm) 추가
        document.querySelector('#label-background').appendChild(elm);
      }

      if (mode === 'edit') {
        item.detail.style.top = `${changeSizeUnit(parseFloat(item.detail.style.top), 'px')}px`;
        item.detail.style.left = `${changeSizeUnit(parseFloat(item.detail.style.left), 'px')}px`;
        item.detail.style.width = `${changeSizeUnit(parseFloat(item.detail.style.width), 'px')}px`;
        item.detail.style.height = `${changeSizeUnit(parseFloat(item.detail.style.height), 'px')}px`;

        // 직접입력 텍스트있으면 미리보기 텍스트에도 적용
        if (item.detail.inputText) {
          elm.innerText = item.detail.inputText;
        }
        Object.assign(elm.style, item.detail.style);

        elm.style.fontSize = `${parseInt(item.detail.style['font-size']) * 3}pt`;
      } else {
        elm.style.cssText = 'position:absolute; top:0; left:0; width:30mm; height:15mm; font-size: 27pt; font-weight:400;';

        if (item.isBarcode) {
          elm.style.width = `${$('#label-background').width() * 0.8}px`;
          elm.style.height = '25mm';
        }

      }

      // 클릭 이벤트 시 포커싱
      elm.addEventListener('mousedown', event => { clickEvent(event.target); });

      $('#label-background .preview-items').resizable({
        handles: 'n, e, s, w, ne, se, sw, nw' // 모든 방향에 핸들을 추가하여 범위를 넓힘
      }).draggable();

      $timeout(() => {
        clickEvent(elm);
      });
    };

    /**
     * px <-> mm 변환
     */
    function changeSizeUnit (unit, to) {
      const pxLabelHeight = $('#label-background').height();
      const mmLabelHeight = $scope.paperSettings[$scope.paperSetName].labelSize.height;

      if (to === 'mm') {
        return ((mmLabelHeight * Number(unit)) / pxLabelHeight).toFixed(2);
      } else {
        return ((Number(unit) * pxLabelHeight) / mmLabelHeight).toFixed(2);
      }
    }

    $scope.removeItem = function(key) {
      document.querySelector(`#label-background #preview-data-${key}`)?.remove();
    };

    /**
     * 미리보기 DOM요소 클릭이벤트
     */
    const clickEvent = function (elm) {
      const element = elm.closest('div.preview-items');
      const prop = element.getAttribute('preview-data');
      const selected = document.querySelector('.preview-items.selected-item');
      $scope.currentItem = prop;

      if (selected) {
        selected.classList.remove('selected-item');
      }

      element.classList.add('selected-item');
      $scope.showDetailTable = true;

      $timeout();
    };

    /**
     * A4용지 디폴트값 변경했는지 확인하면서 유효성 검사
     */
    $scope.checkChangePaperSet = function(key, value) {
      if ($scope.paperType === 'A4용지' && $scope.paperSetName !== '직접설정' && originalSettings[$scope.paperSetName][key][value] !== $scope.paperSettings[$scope.paperSetName][key][value]) {
        $scope.paperSettings['직접설정'] = angular.copy($scope.paperSettings[$scope.paperSetName]);
        $scope.paperSettings[$scope.paperSetName] = angular.copy(originalSettings[$scope.paperSetName]);
        $scope.paperSetName = '직접설정';
        a4SetName = '직접설정';
      }

      validatePaperSetting(key);
    };

    const validatePaperSetting = function(key) {
      if (key === 'labelSize') {
        const settingsObj = $scope.paperSettings[$scope.paperSetName].labelSize;
        if (settingsObj.width < 10 || settingsObj.height < 5) {
          $scope.validation.labelSize = false;
        } else if (settingsObj.width > 170 || settingsObj.height > 140) {
          $('#validation-label-size').text('가로 170mm 세로 140mm 이하로 입력해 주세요.');
          $scope.validation.labelSize = false;
        } else {
          $scope.validation.labelSize = true;
          changePreviewLabelSize();
        }
      }

      if (key === 'countPerPage') {
        const settingsObj = $scope.paperSettings[$scope.paperSetName].countPerPage;
        if (($scope.paperType === 'A4용지' && settingsObj.height < 1) || settingsObj.width < 1) {
          $scope.validation.countPerPage = false;
        } else {
          $scope.validation.countPerPage = true;
        }
      }
    };

    const changePreviewLabelSize = function() {
      const labelBackground = document.getElementById('label-background');
      if (labelBackground) {
        const { width, height } = $scope.paperSettings[$scope.paperSetName].labelSize;
        let PX_WIDTH = 590;
        let PX_HEIGHT = 400;
        const TEMP_HEIGHT = height * PX_WIDTH / width;

        if (TEMP_HEIGHT > PX_HEIGHT) {
          PX_WIDTH = PX_WIDTH * PX_HEIGHT / TEMP_HEIGHT;

        } else {
          PX_HEIGHT = height * PX_WIDTH / width;
        }

        labelBackground.style.width = `${PX_WIDTH}px`;
        labelBackground.style.height = `${PX_HEIGHT}px`;
      }
    };

    /**
     * 현재 선택된 항목 업데이트
     */
    $scope.updateCurrentItem = function(key) {
      // 선택된 항목 최소 1개라 유효성 검사 플래그 확인
      const hasCheckedItem = Object.values($scope.printItems).some(obj => obj.isCheck);

      if (hasCheckedItem) {
        $scope.validation.selectItem = true;
      } else {
        $scope.validation.selectItem = false;
      }

      // 선택했을 경우 현재 아이템 업데이트 및 항목에따라 불필요한 프로퍼티 삭제
      if ($scope.printItems[key].isCheck) {
        $scope.currentItem = key;
        detail.inputText = $scope.printItems[key].name;
        $scope.printItems[key].detail = angular.copy(detail);
        if (!$scope.printItems[key].isBarcode) {
          delete $scope.printItems[key].detail.printBarcode;
          delete $scope.printItems[key].detail.printText;
        }
        if (!$scope.printItems[key].isPrice) {
          delete $scope.printItems[key].detail.priceUnit;
        }
        if (!$scope.printItems[key].isInputText) {
          delete $scope.printItems[key].detail.inputText;
        }

        // 미리보기 항목 추가 함수 실행
        $scope.addItem(key);
        // 항목 상세 설정 테이블 플래그 변환
        $scope.showDetailTable = true;
      } else {
        $scope.removeItem(key);
        $scope.showDetailTable = false;
        if ($scope.printItems[key].isInputText) {
          $scope.validation[`input_${key}`] = true;
        }
      }
    };

    $scope.printItemsKeys = Object.keys($scope.printItems);

    /**
     * 양식 저장
     */
    $scope.save = async function() {
      try {
        const template = $scope.template.data;

        template.paperType = $scope.paperType;
        template.paperSetName = $scope.paperSetName;
        template.paperSetData = $scope.paperSettings[$scope.paperSetName];

        // 출력할 항목들만 양식데이터에 추가
        for (const item in $scope.printItems) {
          if ($scope.printItems[item].isCheck) {
            $scope.printItems[item].skuKey = item;

            template.printItems.push($scope.printItems[item]);
          }
        }

        // 출력항목 선택유무 확인
        if (!template.printItems.length) {
          $scope.validation.selectItem = false;
        } else {
          $scope.validation.selectItem = true;
        }

        // 유효성 검사 플래그 체크
        for (const valid of Object.values($scope.validation)) {
          if (!valid) {
            throw new Error;
          }
        }

        // A4용지일 경우 라벨크기 + 여백 값이 A4용지를 초과할 경우 alert - 확인 클릭시 저장 진행
        // A4용지 크기: 210mm x 297mm
        if (template.paperType === 'A4용지') {
          const { labelSize, labelSpace, paperSpace, countPerPage } = template.paperSetData;
          // 가로 총 합: A4용지 왼쪽 여백 + (라벨 가로 크기*개수) + (라벨 오른쪽 여백*(가로 개수 - 1))
          const totalWidth = +paperSpace.left + (+labelSize.width * +countPerPage.width) + (+labelSpace.right * (+countPerPage.width - 1));
          // 세로 총 합: A4용지 상단 여백 + (라벨 세로 크기*개수) + (라벨 하단 여백*(세로 개수 - 1))
          const totalHeight = +paperSpace.top + (+labelSize.height * +countPerPage.height) + (+labelSpace.bottom * (+countPerPage.height - 1));

          if (totalWidth > 210 || totalHeight > 297) {
            const confirm = await commonSVC.showConfirmHtml('설정값이 용지 크기를 초과하였습니다.<br/>저장하시겠습니까?', '• 설정값이 용지를 초과한 경우 라벨이 잘려서 출력될 수 있습니다.');

            if (!confirm) {
              return false;
            }
          }
        }

        // 미리보기 요소 포지션 단위 환산해서 저장
        template.printItems.forEach((item) => {
          const elm = $(`div[preview-data=${item.skuKey}]`)[0];
          const style = {
            top: `${changeSizeUnit(parseInt($(elm).css('top')), 'mm')}mm`,
            left: `${changeSizeUnit(parseInt($(elm).css('left')), 'mm')}mm`,
            width: `${changeSizeUnit(parseInt($(elm).css('width')), 'mm')}mm`,
            height: `${changeSizeUnit(parseInt($(elm).css('height')), 'mm')}mm`,
          };

          Object.assign(item.detail.style, style);
        });

        const params = {
          template_name: $scope.template.name,
          template_data: template,
          type: 'add'
        };

        let re;

        if ($scope.type === 'edit') {
          params.template_no = data.template.template_no;
          re = await productModel.updateBarcodeTemplate(params);
        } else {
          re = await productModel.addBarcodeTemplate(params);
        }
        if (re.data.results[0] === 'success') {
          document.removeEventListener('keydown', deletePreviewBoxByKey);
          $uibModalInstance.close('success');
        } else {
          throw new Error();
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', `바코드 출력양식 ${$scope.type === 'edit' ? '수정' : '저장'} 실패`);
        $scope.template.data.printItems = [];
      }
    };

    const init = function() {
      if (data.type === 'edit') {
        const original = angular.copy(data.template);

        original.template_data.printItems.forEach((item) => {
          $scope.printItems[item.skuKey] = item;
        });

        $scope.template = {
          name: original.template_name,
          data: { ...original.template_data, printItems: [] }
        };

        $scope.paperType = original.template_data.paperType;
        $scope.paperSetName = original.template_data.paperSetName;
        $scope.paperSettings[$scope.paperSetName] = original.template_data.paperSetData;

        // 수정모드일때 미리보기 셋팅
        $timeout(() => {
          changePreviewLabelSize();
          original.template_data.printItems.forEach((item) => {
            $scope.addItem(item.skuKey, 'edit');
          });
        });
      }
    };

    init();

    function deletePreviewBoxByKey(e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        if (e.target.nodeName !== 'INPUT') {
          $scope.printItems[$scope.currentItem].isCheck = false;
          $scope.updateCurrentItem($scope.currentItem);
          $timeout();
        }
      }
    }

    document.addEventListener('keydown', deletePreviewBoxByKey);

    /**
     * 닫기(취소)
     */
    $scope.close = function() {
      document.removeEventListener('keydown', deletePreviewBoxByKey);
      $uibModalInstance.close();
    };
  });
