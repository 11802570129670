'use strict';

angular.module('gmpApp')
  .controller('MemoTemplateCtrl', function ($scope, $uibModalInstance, userInfo, data, commonSVC, memoModel) {
    $scope.isEdit = data.isEdit;
    $scope.TYPES = [
      { label: '선택', value: '' },
      { label: '주문', value: 'ord' },
      { label: '쇼핑몰 상품', value: 'ol_shop' },
      ...(userInfo.user.sol_stock > 0 ? [{ label: 'SKU상품', value: 'prod' }, { label: '세트상품', value: 'depot_set' }] : []),
      ...(userInfo.user.sol_ser.includes('ord_as') ? [{ label: 'AS(수선)', value: 'mend' }] : []),
      { label: '공통', value: 'all' },
    ];
    $scope.template = {
      bookmark_yn: true,
      memo_type: '',
      template_name: '',
      template_data: ''
    };

    /**
     * 저장(수정)
     */
    $scope.onSubmit = async() => {
      if (!$scope.template.memo_type) {
        commonSVC.showMessage('구분은 필수값 입니다.');

        return false;
      }
      if (!$scope.template.template_name) {
        commonSVC.showMessage('템플릿명은 필수값 입니다.');

        return false;
      }

      const params = { ...$scope.template };

      try {
        if (data.isEdit) {
          params.template_no = data.template.template_no;

          await memoModel.editTemplate(params);
        } else {
          await memoModel.addTemplate(params);
        }
        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', `${$scope.MODE} 실패`, `메모 템플릿 ${$scope.MODE}에 실패하였습니다.`);
      }
    };

    /**
     * 닫기(취소)
     */
    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    const init = () => {
      if (data.isEdit) {
        const { bookmark_yn, memo_type, template_name, template_data } = data.template;

        $scope.template.bookmark_yn = bookmark_yn;
        $scope.template.memo_type = memo_type;
        $scope.template.template_name = template_name;
        $scope.template.template_data = template_data;
      }
    };

    init();
  });
