/**
 * Created by Rony 2017-10-18
 */

'use strict';

angular.module('gmpApp')
  .factory('paySVC', function (commonSVC, userInfo) {

    let searchPeriod = userInfo.user.search_date;
    switch (searchPeriod) {
      case '1':
        searchPeriod = '1MONTH';
        break;
      case '3':
        searchPeriod = '3MONTH';
        break;
      case '6':
        searchPeriod = '6MONTH';
        break;
      case '12':
        searchPeriod = '1YEARS';
        break;
      default:
        searchPeriod = 'ALL';
        break;
    }

    /**
     * 서비스 제한버전
     * - 장기 결제 안됨
     * - 추가구매카운트 구매안됨
     * - 쿠폰 적용 안됨
     * - 채팅 안됨
     */
    const limitedVersion = ['ZERO', 'LITE', 'SLIM'];

    // 무료 버전
    const freeVersion = ['ZERO', 'LITE'];

    // 정기결제만 가능
    const onlyAutoPayVersion = ['SLIM'];

    return {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(userInfo.user.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        cd_name: '',          // 결제상태
        only_parent: true,     // 최상위 결제내리스트만 보여줄지 여부.
        page: 'pay',
        selectDate: searchPeriod
      },
      searchForm_svc: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(userInfo.user.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        send_type: '',
        only_parent: true,     // 최상위 결제내리스트만 보여줄지 여부.
        page: 'service',
        selectDate: searchPeriod
      },
      searchForm_cpn: {
        search_key: 'dc_name',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        cd_name: '',          // 결제상태
        only_parent: true,     // 최상위 결제내리스트만 보여줄지 여부.
        page: 'coupon',
        selectDate: searchPeriod
      },
      searchForm_refund: {
        search_key: 'email',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(userInfo.user.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        cd_name: '',          // 결제상태
        only_parent: true,     // 최상위 결제내리스트만 보여줄지 여부.
        page: 'refund',
        selectDate: searchPeriod
      },
      searchForm_trnsl: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(userInfo.user.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        page: 'translate',
        selectDate: searchPeriod
      },
      searchForm_benefit: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(userInfo.user.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        page: 'benefit',
        benefitType: '',
        selectDate: searchPeriod
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '전체', value: 'all' },
        ],
        search_date_type: [{ label: '결제일', value: 'wdate' }]
      },
      searchData_refund: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '요청자ID', value: 'email' }
        ],
        search_date_type: [
          { label: '환불요청일', value: 'wdate' },
          { label: '환불완료일', value: 'mdate' },
        ]
      },
      searchData_svc: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '발송자', value: 'from_m' },
          { label: '상세내용', value: 'contents' }
        ],
        search_date_type: [
          { label: '발송일', value: 'wdate' },
        ]
      },
      searchData_cpn: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '쿠폰명', value: 'dc_name' }
        ]
      },
      searchData_trnsl: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '사용자ID', value: 'email' },
          { label: '원본내용', value: 'before_content' },
          { label: '번역내용', value: 'after_content' }
        ],
        search_date_type: [
          { label: '번역일', value: 'wdate' },
        ]
      },
      searchData_benefit: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,
        showCount: 25,
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '지급내용', value: 'title' }
        ],
        search_date_type: [
          { label: '지급일', value: 'wdate' },
        ]
      },
      searchDetail: [
        {
          title: '결제상태 전체',
          search_name: 'pay_status_cd',
          item_list: [{ key: '결제완료', value: 'P001' }, { key: '입금대기', value: 'P002' }, { key: '환불신청', value: 'P003' }, { key: '환불완료', value: 'P004' }, { key: '환불거부', value: 'P005' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      searchDetail_cpn: [
        {
          title: '부가서비스',
          search_name: 'ben_type',
          item_list: [{ key: 'E-mail', value: 'Email' }, { key: 'SMS', value: 'SMS' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }, {
          title: '상태',
          search_name: 'status',
          item_list: [{ key: '사용 가능', value: 'y' }, { key: '사용 불가', value: 'n' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      searchDetail_refund: [
        {
          title: '처리상태 전체',
          search_name: 'refund_status',
          item_list: [{ key: '환불신청', value: '환불신청' }, { key: '처리중', value: '처리중' }, { key: '환불거부', value: '환불거부' }, { key: '환불완료', value: '환불완료' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      searchDetail_svc: [
        {
          title: '상태',
          search_name: 'msg_cd',
          item_list: [{ key: '성공', value: 'LD01' }, { key: '실패', value: 'LD02' }, { key: '발송 요청 완료', value: 'LD03' }, { key: '발송 요청 실패', value: 'LD04' }, { key: '발송 완료', value: 'LD05' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      searchDetail_trnsl: [
        {
          title: '무료여부 선택',
          search_name: 'free_yn',
          item_list: [{ key: '무료', value: '1' }, { key: '유료', value: '0' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ],
      payExcelFieldList: [
        { header: '결제번호', key: 'pay_no', select: true },
        { header: '결제일', key: 'wdate', select: true },
        { header: '결제ID', key: 'email', select: true },
        { header: '상세내역', key: 'title', select: true },
        { header: '결제금액', key: 'pay_amt', select: true },
        { header: '결제수단', key: 'pay_method', select: true },
        { header: '결제상태', key: 'cd_name', select: true }
      ],
      countOlExcelFieldList: [
        { header: '작업진행일', key: 'wdate', select: true },
        { header: '작업종류', key: 'job_name', select: true },
        { header: '쇼핑몰/계정', key: 'shop_name,shop_id', select: true },
        { header: '판매자관리코드', key: 'c_sale_cd', select: true },
        { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true }

      ],
      countOrdExcelFieldList: [
        { header: '작업진행일', key: 'wdate', select: true },
        { header: '작업종류', key: 'job_name', select: true },
        { header: '쇼핑몰/계정', key: 'shop_name,shop_id', select: true },
        { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true }
      ],
      refundExcelFieldList: [
        { header: '환불요청일', key: 'wdate', select: true },
        { header: '환불완료일', key: 'mdate', select: true },
        { header: '회원ID', key: 'email', select: true },
        { header: '환불은행', key: 'bank_name', select: true },
        { header: '예금주명', key: 'acct_holder_name', select: true },
        { header: '계좌번호', key: 'acct_no', select: true },
        { header: '환불사유', key: 'refund_req_reason', select: true }
      ],

      serviceExcelFieldList: [
        { header: '발송일', key: 'wdate', select: true },
        { header: '발송자', key: 'from_m', select: true },
        { header: '발신번호', key: 'from_tel', select: true },
        { header: '발송건수', key: 'count', select: true },
        { header: '발송구분', key: 'send_type', select: true },
        { header: '상세내용', key: 'contents', select: true },
        { header: '상태', key: 'status', select: true },
        { header: '실패사유', key: 'msg', select: true },
        { header: '무료여부', key: 'pay_yn', select: true }
      ],

      translateExcelFieldList: [
        { header: '번역일', key: 'wdate', select: true },
        { header: '사용자 ID', key: 'email', select: true },
        { header: '항목', key: 'item', select: true },
        { header: '원본언어', key: 'src_lang_cd', select: true },
        { header: '번역언어', key: 'tar_lang_cd', select: true },
        { header: '글자수', key: 'source_char_length', select: true },
        { header: '원본 내용', key: 'before_content', select: true },
        { header: '번역 내용', key: 'after_content', select: true },
      ],

      translateExcelFieldList: [
        { header: '번역일', key: 'wdate', select: true },
        { header: '사용자 ID', key: 'email', select: true },
        { header: '항목', key: 'item', select: true },
        { header: '원본언어', key: 'src_lang_cd', select: true },
        { header: '번역언어', key: 'tar_lang_cd', select: true },
        { header: '글자수', key: 'source_char_length', select: true },
        { header: '원본 내용', key: 'before_content', select: true },
        { header: '번역 내용', key: 'after_content', select: true },
      ],

      sendType: {
        '전체': '',
        'SMS': 'sms',
        'LMS': 'lms',
        'E-mail': 'email'
      },

      // 결제 정보
      payData: {
        alarmTalkCnt: '0',
        smsCnt: '0',
        emailCnt: '0',
        alarmTalkPrice: '0',
        smsPrice: '0',
        emailPrice: '0',
        sumPrice: '0',
        discountPrice: '0',
        totalPrice: '0',
        autoPay: false,
        payType: '',
        extendTerm: '1',
        sol_no: '',
        version: '',
        oldVersion: '',
        oldEdate: '',
        expireDate: '30',
        firstOrdYN: false,      // 첫결제여부

        Moid: '',
        PayMethod: '',
        Amt: '1',
        GoodsName: '',
        GoodsCnt: '1',
        BuyerName: '',
        BuyerTel: '',
        UserIP: '',
        VbankExpDate: '',
        BuyerEmail: '',
        TransType: '0',
        EncodeParameters: '',
        EdiDate: commonSVC.getDate(new Date(), 'yyyyMMddHHmmss'),
        EncryptData: '',
        TrKey: '',

        autoPayPrice: '0',    // 자동결제 금액 ( 자동결제가 가능한경우 지불될 수 있는 금액 )
        pay_no: ''
      },

      limitedVersion,
      freeVersion,
      onlyAutoPayVersion,

      benefitType: {
        '전체': '',
        'SMS': 'sms',
        '알림톡': 'atalk',
        'E-mail': 'email'
      }
    };
  });
