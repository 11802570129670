/**
 * Main Tab Menu
 * 2017-03-06 chris
 */
'use strict';

angular.module('gmpApp')
  .directive('tabMenu', function ($rootScope, $state, $compile, $timeout, localStorageService, commonSVC, userInfo) {
    return {
      restrict: 'A',
      scope: {
        menuData: '=',
      },
      link: function (scope) {
        // 탭 DOM초기화 시간
        let memorySaverTimeout = 1000 * 60 * 30;
        if (userInfo.user.sol_no === 2702) {
          memorySaverTimeout = 1000 * 30;
        }

        let prevTab = null;
        const notMemorySaveState = ['main.online_product_add', 'main.setForm_template_add', 'main.order_customer_inquiry_list'];

        const $chromeTabsShell = $('.chrome-tabs-shell');

        /**
         * 2017-03-07 chris 마우스 우클릭 드랍 다운
         * @type {*[]}
         */
        scope.menuOptions = [
          {
            html: '<a href="#">탭 닫기</a>',
            enabled: function ($itemScope, $event) {
              // MAIN 탭 닫기 금지
              let closeMenuEnabled = true;

              if ($($event.currentTarget).attr('tab-id') == 'tab_home') {
                closeMenuEnabled = false;
              }

              return closeMenuEnabled;
            },
            click: function ($itemScope, $event) {
              setTimeout(function () {
                closeTab($($event.currentTarget).attr('tab-id'));
              }, 0);
            }
          },
          {
            html: '<a href="#">다른 탭 닫기</a>',
            enabled: function () {
              let closeMenuEnabled = true;

              const now_tab_length = $('.chrome-tab').length;

              // MAIN탭만 있거나 MAIN탭과 선택한 탭만 남았을시
              if (now_tab_length <= 2) {
                closeMenuEnabled = false;
              }

              return closeMenuEnabled;

            },
            click: function ($itemScope, $event) {

              setTimeout(function () {
                // 현재 탭 ID
                const now_tab_id = $($event.currentTarget).attr('tab-id');

                $('.chrome-tab').each(function () {
                  // 즐겨찾기 탭도 닫히지 않도록 추가 2018-03-30 rony
                  // if (now_tab_id != $(this).attr('tab-id')) {
                  if (now_tab_id != $(this).attr('tab-id') && !$(this).find('.chrome-tab-favicon').hasClass('active')) {
                    closeTab($(this).attr('tab-id'));
                  }
                });
              }, 0);

            }
          },
          {
            html: '<a href="#">우측 탭 닫기</a>',
            enabled: function ($itemScope, $event) {
              let closeMenuEnabled = true;

              const now_tab_length = $('.chrome-tab').length;
              const target_index = $($event.currentTarget).index() + 1;

              // 선택한탭이 가장오른쪽 탭일시
              if (now_tab_length == target_index) {
                closeMenuEnabled = false;
              }

              return closeMenuEnabled;
            },
            click: function ($itemScope, $event) {
              const now_tab_id = $($event.currentTarget).attr('tab-id');
              const now_idx = $('.chrome-tab').index($(`.chrome-tab[tab-id='${now_tab_id}']`));

              $('.chrome-tab').each(function () {
                // 즐겨찾기 탭은 닫히지 않도록 처리 2018-03-30 rony
                // if ($(".chrome-tab").index($(this)) > now_idx) {
                if ($('.chrome-tab').index($(this)) > now_idx && !$(this).find('.chrome-tab-favicon').hasClass('active')) {
                  closeTab($(this).attr('tab-id'));
                }
              });
            }
          }
        ];

        // 탭 선택시 처리
        $chromeTabsShell.bind('chromeTabRender', function () {
          const $currentTab = $chromeTabsShell.find('.chrome-tab-current');

          if ($currentTab.length) {
            const tabId = $currentTab.attr('tab-id'),
                  tab = getTabById(tabId);

            // 맨처음 빈화면으로 나오는 오류
            // 맨처음 로딩중에 브라우저 다른탭을로 이동후 로딩후 돌아오면 메인에서 if체크를 먼저하는거 같아서 추가함
            if (!scope.$$phase && !scope.$root.$$phase) {
              scope.$apply();
            }

            $state.go(tab.sref, tab.params);

            // 현재탭 타이머 제거와 동시에 DOM렌더링
            tab.memorySaver = false;
            clearTimeout(tab.memorySaverTimer);

            // 이전탭 타이머 재설정
            if (prevTab && !_.includes(notMemorySaveState, prevTab.sref)) {
              setTimer(prevTab);
            }

            prevTab = tab;
          }
        });

        // 탭 삭제시 처리
        $chromeTabsShell.bind('chromeTabRemoved', function (evt, tabid) {
          if (tabid) {
            // 탭 데이터 삭제
            _.remove(scope.menuData, { id: tabid });

            if (!scope.$$phase && !scope.$root.$$phase) {
              scope.$apply();
            }

            // 변경 사항 저장
            storeTabs();
          }
        });

        // 탭 이동시 처리 2017-03-09 MatthewKim
        $chromeTabsShell.bind('chromeTabMoved', function () {
          storeTabs();
        });

        // 2017-03-16 chris 즐겨찾기 설정
        $chromeTabsShell.bind('chromeBookmarkTab', function () {
          storeTabs();
        });

        // 2017-02-27 chris 탭 메뉴 노출 안할 state
        const no_tab_menus = [
          'main',
          // "main.home",

          // 탭고유값 확인을 중간 메뉴로 하므로 하지 않아도됨 2017-03-07 MatthewKim
          // "settings.channel.channelSub",
          // "settings_category.categoryDetailPanel",
          // "online.template_list.channelSub"
        ];

        // 2017-09-25 chris 탭 오픈 제한 최대 10개
        scope.$on('$stateChangeStartGlobal', function (evt, originEvt, toState) {
          // 스테이트 명에서 탭 ID 추출
          const id = getTabId(toState.name);

          // 삭제할 탭 아이디
          let del_id = '';

          $('.chrome-tab').each(function () {
            const $el = $(this);

            // 홈 탭이 아니고 즐겨찾기 안한 탭부터 닫기
            if ($el.attr('tab-id') != 'tab_home' && !$el.find('.chrome-tab-favicon').hasClass('active') && del_id == '') {
              del_id = $el.attr('tab-id');

              return;
            }
          });

          if (_.find(scope.menuData, { id: id }) === undefined && $('.chrome-tab').length > 10) {
            if (del_id != '') {
              closeTab(del_id);
            } else {
              commonSVC.showToaster('warning', '', '사용하지 않는 탭을 닫은 후, 재시도 하시기 바랍니다.\n탭은 최대 10개까지 열 수 있습니다.', { addclass: 'pnotify-top-center', width: '400px' });
              evt.preventDefault();
            }
          }
        });

        // state 이동시 탭 복구 및 감지
        scope.$on('$stateChangeSuccessGlobal', function (evt, originEvt, toState, toParams, fromState) {
          // var obj = {};
          // if(toState.name === "main.stock_unstoring_list"){
          //   obj.type = "unstoring";
          // }else{
          //   obj.type = "order";
          // }
          //
          // $rootScope.$emit("updateCount",obj);

          // 직전 state 정보 있으면 저장
          if (fromState) {
            scope.last_prev_state = fromState;
          }

          // 탭 노출 필요없는 state 는 아무것도 안함
          if (_.includes(no_tab_menus, toState.name)) {
            // 모든 탭 선택 해제
            $chromeTabsShell.find('.chrome-tab').removeClass('chrome-tab-current');

            return;
          }

          // 스테이트 명에서 탭 ID 추출
          // 탭 고유값 (하위 페이지여도 1뎁스가 같으면 같은 탭으로 인식 해야함 2017-03-07 MatthewKim)
          const id = getTabId(toState.name);

          // 열려있는 탭인지 확인
          const openedTab = scope.menuData.find(v => v.id === id);
          if (openedTab && JSON.stringify(openedTab.params) !== JSON.stringify(toParams)) {
            // 열려있는 탭 중, parmas와 동일하지 않은 경우, menuData 갱신처리
            scope.menuData.forEach(v => {
              if (v.id === id) {
                v.params = toParams;
              }
            });
          }

          // 안열린 탭은 새로 생성
          if (!openedTab) {
            const item = {
              id: id,
              sref: toState.name, // 2017-03-06 MatthewKim
              name: toState.data.pageTitle,
              params: toParams, // 2017-03-07 MatthewKim
              bookmark: false  // 2017-03-16 chris
            };

            scope.menuData.push(item);
          }
          // 열려있는 탭은 선택 시킴
          else {
            showTab(toState.name);
          }

        });

        // 2017-06-14 ally 외부에서 탭 닫을때
        scope.$on('$closeTabGlobal', function (evt, originEvt, tabAttr) {
          closeTab(tabAttr);
        });

        /**
         * 메인 사이드에 포함된 링크 목록 (메인 사이드바 메뉴의 링크가 처음 열릴때는 맨 우측으로 열기 위함)
         * 2017-03-09 MatthewKim
         * @type {any}
         */
        // var mainSideBarLinks = $('.sidebar-main.sidebar-gmp').find('li,a[ui-sref]').map(function () {
        //   return $(this).attr('ui-sref');
        // }).get();
        // console.log(mainSideBarLinks.length);

        /**
         * menuData 를 감시하여 변경됐을때, 추가된 탭을 자동 추가하고 로컬스토리지에 저장
         */
        let firstRun = true;

        scope.$watchCollection('menuData', function (newValue, oldValue) {
          // console.log("watch call");
          if (newValue === oldValue && firstRun === false) {
            return;
          }

          // 새로 생긴탭 추가
          const diff = _.differenceBy(newValue, firstRun == true ? [] : oldValue, 'id');

          // console.log(diff);
          if (diff !== undefined && diff.length > 0) {
            _.each(diff, function (row) {
              // console.log(row);
              // console.log(mainSideBarLinks.indexOf(row.sref));
              chromeTabs.addNewTab($chromeTabsShell, {
                // favicon: 'assets/images/bookmark_off.png',
                bookmark: row.bookmark || false,
                title: row.name,
                data: row
              },
              !firstRun,
              false //!(mainSideBarLinks.indexOf(row.sref) > -1) && !firstRun
              );
            });

            // 닫기 버튼 활성화
            // if(scope.menuData.length > 1){
            //   $(".chrome-tab").find(".chrome-tab-close").show();
            //   closeMenuEnabled = true;
            // }

          } else {
            return false;
          }

          // 첫 실행 (새로고침)시에는 탭 추가 다 한다음에 탭 선택
          // 그래야만 마지막 탭이 자동 선택되는것을 막을수 있음
          if (firstRun == true) {
            showTab($state.current.name);
            firstRun = false;
          } else {
            // 2017-03-16 chris
            // 처음 불러오자마자 탭 저장을 하게되어 첫 실행이 아닌 경우에만 탭 저장
            storeTabs();
          }

        }, false);

        /**
         * stateName 또는 ID값을 받아 분석하여 맞는 ID로 리턴
         * @param v
         * @returns {string}
         */
        function getTabId(stateNameOrTabId) {
          let id = '';

          if (stateNameOrTabId.indexOf('tab') < 0) {
            id = `tab_${_.first(stateNameOrTabId.replace('main.', '').split('.'))}`;
          } else {
            id = stateNameOrTabId;
          }

          return id;
        }

        /**
         * 해당하는 탭을 선택 시킴 2017-03-07 MatthewKim
         */
        function showTab(stateNameOrTabId) {
          // 2017-08-17 ally 맵핑완료후 신규주문 페이지가면 무조건 띄어줌
          if (stateNameOrTabId === 'main.order_shipment_order_list' && $rootScope.isOrderMappingProd) {
            $rootScope.$emit('$reloadTableForMappingResult', { from: 'mapping' });
          }

          const id = getTabId(stateNameOrTabId);

          // 현재 탭과 요청한 탭이 같으면 무시함 (중복 실행됨) 2017-05-19 MatthewKim
          const $currentTab = $chromeTabsShell.find('.chrome-tab-current');

          if ($currentTab.length) {
            const dd = $currentTab.data('tabData').data;

            if (dd.id == id) {
              return;
            }
          }

          // 아니면 현재탭 셋팅
          chromeTabs.setCurrentTab(
            $chromeTabsShell,
            $chromeTabsShell.find(`.chrome-tab[tab-id="${id}"]`)
          );
        }

        /**
         * 해당하는 탭을 닫음 2017-03-07 MatthewKim
         */
        function closeTab(stateNameOrTabId) {
          // 2017-11-20 chris 홈탭 닫기 금지 추가
          if (stateNameOrTabId == 'tab_home') {
            return false;
          }
          const id = getTabId(stateNameOrTabId);

          chromeTabs.closeTab(
            $chromeTabsShell,
            $chromeTabsShell.find(`.chrome-tab[tab-id="${id}"]`)
          );
        }

        /**
         * 탭 상태 저장
         */
        function storeTabs() {
          $timeout(function () {
            // 최종 구성된 탭 목록을 로컬 스토리지에 저장 2017-03-06 MatthewKim
            // 중복탭은 제거하고 저장함
            // 다음 로그인시 초기화 되므로 권한 문제는 상관없음
            // menuData 저장은 순서가 저장이 안되어서 탭 메뉴에서 추출해서 저장 2017-03-09 MatthewKim
            //console.log($chromeTabsShell.find('.chrome-tab').length);
            const save_items = $chromeTabsShell.find('.chrome-tab').map(function () {
              // 2017-05-12 ally 활성화가 안되어있는 탭들을 닫을 시 $chromeTabsShell.find('.chrome-tab') 한박자 늦게 갱신되어
              // scope.menuData 와 비교해 retunr해 줌  [GMPJAPAN-264] 이슈
              // 2017-09-26 chris 탭 확인시 id로만 찾기
              if (_.findIndex(scope.menuData, { id: $(this).data('tabData').data.id }) > -1) {
                return $(this).data('tabData').data;
              }
            }).get();

            localStorageService.set('tab_menus', _.uniqBy(save_items, 'id'));
          }, 0, false);

        }

        /**
         * 탭 복구
         */
        function init() {
          let menuData = _.uniqBy(localStorageService.get('tab_menus'), 'id');

          // 저장된 탭 없거나 홈탭이 없으면 홈생성후 홈으로 이동
          if (!menuData.length || !_.find(menuData, { id: 'tab_home' })) {
            menuData = [
              {
                id: 'tab_home',
                sref: 'main.home',
                name: 'MAIN',
                params: {},
                bookmark: true
              },
              {
                id: 'tab_pay_list',
                sref: 'main.pay_list',
                name: '결제관리',
                params: {},
                bookmark: false
              }
            ];
            $state.go($state.params === 'pay' ? 'main.pay_list' : 'main.home');
          }
          // 로컬 스토리지에 저장된 메인 메뉴 복구
          else {
            // 현재 열려있는 탭이 10개 이상인 경우 10개까지 자름
            if (menuData.length >= 10) {
              menuData = _.slice(menuData, 0, 10);
            }

            // 2017-11-12 Daniel 스테이트가 홈 또는 메인이 아니고 스테이트는 변경되었지만 탭추가가 안됬을시 탭 추가
            const nowStateId = `tab_${$state.current.name.replace('main.', '')}`;

            // 2017-12-19 william 스테이트가 하위뷰일경우 탭추가 하지않도록 수정
            if (!_.includes(['.', 'tab_main', 'tab_home'], nowStateId) && !_.find(menuData, { id: nowStateId })) {
              const item = {
                id: nowStateId,
                sref: $state.current.name,
                name: $state.current.data.pageTitle,
                params: {},
                bookmark: false
              };

              menuData.push(item);
            }
          }

          _.each(menuData, function(menu, idx) {
            // 메모리 세이버 없을시 삽입
            menu.memorySaver = true;

            if (menu.name === 'MAIN') {
              menuData[idx].name = '<i class="picon-home"></i>';
            }

            // 지금 페이지는 타이머 안넣음
            if (menu.sref === $state.current.name) {
              return;
            }
          });

          scope.menuData = menuData;

          // 크롬탭 라이브러리 초기화
          chromeTabs.init({
            $shell: $chromeTabsShell,
            minWidth: 45,
            maxWidth: 160,
            $compile: $compile,
            scope: scope,
          });

          /**
           * 탭 전환 단축키 등록
           */
          // hotkeys.bindTo(scope)
          //   .add({
          //     combo: 'shift+tab',
          //     description: '마지막 선택한 탭 2개간의 전환',
          //     callback: toggleLastTab
          //   })
          //   .add({
          //     combo: 'shift+right',
          //     description: '다음 탭',
          //     callback: function () {
          //       moveTab('next');
          //     }
          //   })
          //   .add({
          //     combo: 'shift+left',
          //     description: '이전 탭',
          //     callback: function () {
          //       moveTab('prev');
          //     }
          //   })
          //   .add({
          //     combo: 'shift+1',
          //     description: '1번 탭으로 이동',
          //     callback: function () {
          //       moveTab(1);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+2',
          //     description: '2번 탭으로 이동',
          //     callback: function () {
          //       moveTab(2);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+3',
          //     description: '3번 탭으로 이동',
          //     callback: function () {
          //       moveTab(3);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+4',
          //     description: '4번 탭으로 이동',
          //     callback: function () {
          //       moveTab(4);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+5',
          //     description: '5번 탭으로 이동',
          //     callback: function () {
          //       moveTab(5);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+6',
          //     description: '6번 탭으로 이동',
          //     callback: function () {
          //       moveTab(6);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+7',
          //     description: '7번 탭으로 이동',
          //     callback: function () {
          //       moveTab(7);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+8',
          //     description: '8번 탭으로 이동',
          //     callback: function () {
          //       moveTab(8);
          //     }
          //   })
          //   .add({
          //     combo: 'shift+9',
          //     description: '마지막 탭으로 이동',
          //     callback: function () {
          //       moveTab('last');
          //     }
          //   });
          // .add({combo: 'shift+w', description: '현재탭 닫기', callback: function() {
          //   var now_tab = $chromeTabsShell.find('.chrome-tab-current');
          //   closeTab(now_tab.attr('tab-id'));
          // }})
        }

        /**
         * 아이디로 탭데이터 얻기
         */
        function getTabById(id) {
          return _.find(scope.menuData, { id: id });
        }

        /**
         * 탭 메모리세이버 타이머 설정
         */
        function setTimer(tab) {
          // memorySaverTimer 시간지날때까지 탭안열면 DOM초기화
          tab.memorySaverTimer = setTimeout(function () {
            tab.memorySaver = true;

            if (!scope.$$phase && !scope.$root.$$phase) {
              scope.$apply();
            }
          }, memorySaverTimeout);
        }

        /**
         * 탭 간 이동 관련 메소드 정의
         * @type {null}
         */
        // 직전 탭
        scope.last_prev_state = null;

        // 직전 탭과 전환 하기
        function toggleLastTab() {
          if (scope.last_prev_state) {
            showTab(scope.last_prev_state.name);
          }
        }

        // 탭 이동 열기
        function moveTab(arrowOrNo) {
          const now_tab = $chromeTabsShell.find('.chrome-tab-current');

          if (_.isInteger(arrowOrNo)) {
            const tabNo = arrowOrNo - 1;

            showTab($('.chrome-tab').eq(tabNo).attr('tab-id'));
          } else if (arrowOrNo == 'last') {
            showTab($('.chrome-tab:last').attr('tab-id'));
          } else {
            const target_idx = $('.chrome-tab').index(now_tab) + (arrowOrNo == 'next' ? 1 : -1);

            if ((arrowOrNo == 'next' && target_idx < $('.chrome-tab').length) ||
              (arrowOrNo != 'next' && target_idx > -1)) {
              showTab($('.chrome-tab').eq(target_idx).attr('tab-id'));
            }
          }
        }

        // 초기화
        init();
      }
    };
  });
