/**
 *  Theme 코어 라이브러리
 *
 *  2016-09-30 남형진
 *  2017-03-13 MatthewKim 대폭 수정
 *
 *  Jquery 함수를 미리 호출해야 한다면 아래의 코드중
 *
 *  (1) 새로고침 후 1번만 실행하면 되는 경우 : intTemplete 함수 내부에 정의
 *      on click 등 현재 열려진 화면의 엘리먼트에만 반영
 *  (2) 매 뷰가 새로 열릴때마다 실행해야 하는 경우 : jquerySVC 의 refresh 에 정의
 */

'use strict';
window.App = (function () {

  // IE mode
  let isRTL = false;
  let isIE8 = false;
  let isIE9 = false;
  let isIE10 = false;

  const resizeHandlers = [];

  // initializes main settings
  function handleInit() {

    if ($('body').css('direction') === 'rtl') {
      isRTL = true;
    }

    isIE8 = !!navigator.userAgent.match(/MSIE 8.0/);
    isIE9 = !!navigator.userAgent.match(/MSIE 9.0/);
    isIE10 = !!navigator.userAgent.match(/MSIE 10.0/);

    if (isIE10) {
      $('html').addClass('ie10'); // detect IE10 version
    }

    if (isIE10 || isIE9 || isIE8) {
      $('html').addClass('ie'); // detect IE10 version
    }
  }

  // runs callback functions set by App.addResponsiveHandler().
  function _runResizeHandlers() {
    // reinitialize other subscribed elements
    for (let i = 0; i < resizeHandlers.length; i++) {
      const each = resizeHandlers[i];

      each.call();
    }
  }

  // handle the layout reinitialization on window resize
  function handleOnResize() {
    let resize;

    if (isIE8) {
      let currheight;

      $(window).resize(function () {
        if (currheight === document.documentElement.clientHeight) {
          return; //quite event since only body resized not window.
        }
        if (resize) {
          clearTimeout(resize);
        }
        resize = setTimeout(function () {
          _runResizeHandlers();
        }, 50); // wait 50ms until window resize finishes.
        currheight = document.documentElement.clientHeight; // store last body client height
      });
    } else {
      $(window).resize(function () {
        if (resize) {
          clearTimeout(resize);
        }
        resize = setTimeout(function () {
          _runResizeHandlers();
        }, 50); // wait 50ms until window resize finishes.
      });
    }
  }

  // Fix input placeholder issue for IE8 and IE9
  function handleFixInputPlaceholderForIE() {
    //fix html5 placeholder attribute for ie7 & ie8
    if (isIE8 || isIE9) { // ie8 & ie9
      // this is html5 placeholder fix for inputs, inputs with placeholder-no-fix class will be skipped(e.g: we need this for password fields)
      $('input[placeholder]:not(.placeholder-no-fix), textarea[placeholder]:not(.placeholder-no-fix)').each(function () {
        const input = $(this);

        if (input.val() === '' && input.attr('placeholder') !== '') {
          input.addClass('placeholder').val(input.attr('placeholder'));
        }

        input.focus(function () {
          if (input.val() == input.attr('placeholder')) {
            input.val('');
          }
        });

        input.blur(function () {
          if (input.val() === '' || input.val() == input.attr('placeholder')) {
            input.val(input.attr('placeholder'));
          }
        });
      });
    }
  }

  // handle group element heights
  function handleHeight() {
    $('[data-auto-height]').each(function () {
      const parent = $(this);
      const items = $('[data-height]', parent);
      let height = 0;
      const mode = parent.attr('data-mode');
      const offset = parseInt(parent.attr('data-offset') ? parent.attr('data-offset') : 0);

      items.each(function () {
        if ($(this).attr('data-height') == 'height') {
          $(this).css('height', '');
        } else {
          $(this).css('min-height', '');
        }

        const height_ = (mode == 'base-height' ? $(this).outerHeight() : $(this).outerHeight(true));

        if (height_ > height) {
          height = height_;
        }
      });

      height = height + offset;

      items.each(function () {
        if ($(this).attr('data-height') == 'height') {
          $(this).css('height', height);
        } else {
          $(this).css('min-height', height);
        }
      });

      if (parent.attr('data-related')) {
        $(parent.attr('data-related')).css('height', parent.height());
      }
    });
  }

  /**
   * Jquery 용 테마 플러그인 초기화 (새로고침 시 1회)
   * 2017-03-13 MatthewKim
   **/
  function intTemplete() {

    // Navbar navigation
    // -------------------------

    // Prevent dropdown from closing on click
    $(document).on('click', '.dropdown-content', function (e) {
      e.stopPropagation();
    });

    // Disabled links
    $('body').on('click', '.navbar-nav .disabled a', function (e) {
      e.preventDefault();
      e.stopPropagation();
    });

    // Panels
    $('body').on('click', '.panel [data-action=close]', function (e) {
      e.preventDefault();
      const $panelClose = $(this).parent().parent().parent().parent().parent();

      $panelClose.slideUp(1, function () {
        $(this).remove();
      });
    });

    // Toggle mini sidebar
    $('.sidebar-main-toggle').on('click', function (e) {
      e.preventDefault();
      // Toggle min sidebar class
      $('body').toggleClass('sidebar-xs');
    });

    // Sidebar controls
    // -------------------------

    // Disable click in disabled navigation items
    $(document).on('click', '.navigation .disabled a', function (e) {
      e.preventDefault();
    });

    $(window).on('resize', function () {
      setTimeout(function () {

        if ($(window).width() <= 768) {

          $('body').addClass('sidebar-xs-indicator');
          miniSidebar();

          $('.menu-list').removeAttr('style').removeAttr('tabindex');

          // Add mouse events for dropdown submenus
          $('.dropdown-submenu').on('mouseenter', function () {
            $(this).children('.dropdown-menu').addClass('show');
          }).on('mouseleave', function () {
            $(this).children('.dropdown-menu').removeClass('show');
          });

        } else {

          // Remove mini sidebar indicator
          $('body').removeClass('sidebar-xs-indicator');
          miniSidebar();

          // Remove all mobile sidebar classes
          $('body').removeClass('sidebar-mobile-main sidebar-mobile-secondary sidebar-mobile-opposite');

          // Remove visibility of heading elements on desktop
          $('.page-header-content, .panel-heading, .panel-footer').removeClass('has-visible-elements');
          $('.heading-elements').removeClass('visible-elements');

          // Disable appearance of dropdown submenus
          $('.dropdown-submenu').children('.dropdown-menu').removeClass('show');
        }
      }, 100);
    }).resize();

    // 2017-03-03 MatthewKim Fixed 사이드바의 경우 축소 시킬 경우 아래 코드로 대응해야함
    // 마우스 진입시 자동 펼침, 마우스 나갈시 자동 접힘

    // ----------- 사이드바 처리 시작

    // 사이드바 축소/펼침 시 카운트 메뉴 보이기/가리기
    function miniSidebar() {
      const body = $('body');
      const cat1 = false;

      // 축소 상태 라면
      if (body.hasClass('sidebar-xs')) {

        // 2017-03-14 chris 사이드바 축소시 상태별 건수 노출안함
        $('.sidebar-user').addClass('hide').css('min-height', '0px');

        // 사이드바 축소된 상태에서 마우스 접근/아웃시 메뉴 자동 펼침 / 줄이기
        // cat1 값으로 초기 로딩시 한번만 on 이벤트 등록 하도록 수정
        if (cat1 == false) {
          $('.sidebar-main.sidebar-fixed .sidebar-content')
            // 사이드바에 마우스 들어오면 메뉴 임시로 보이기
            .on('mouseenter', function () {
              if (body.hasClass('sidebar-xs')) {
                $('.menu-search,.menu-show-btn').show();
                body.removeClass('sidebar-xs').addClass('sidebar-fixed-expanded');
              }
            })
            // 사이드바에서 마우스 나가면 메뉴 다시 줄이기
            .on('mouseleave', function () {
              if (body.hasClass('sidebar-fixed-expanded')) {
                $('.menu-search,.menu-show-btn').hide();
                // Collapse fixed navbar
                body.removeClass('sidebar-fixed-expanded').addClass('sidebar-xs');
              }
            });
        }

      } else {

        // 2017-03-14 chris 사이드바 펼침시 상태별 건수 노출
        $('.sidebar-user').removeClass('hide').css('min-height', '198px');

      }
    }

    // 한번 호출
    miniSidebar();

    // 사이드바 토글 메뉴에 기능 입힘
    $('.sidebar-main-toggle').on('click', function () {
      miniSidebar();
    });

  }

  //* END:CORE HANDLERS *//

  return {

    //main function to initiate the theme
    init: function () {

      //Core handlers
      handleInit(); // initialize core variables
      handleOnResize(); // set and handle responsive

      //Handle group element heights
      this.addResizeHandler(handleHeight); // handle auto calculating height on window resize

      // Hacks
      handleFixInputPlaceholderForIE(); //IE8 & IE9 input placeholder issue fix
    },

    //main function to initiate core javascript after ajax complete
    initAjax: function () {

    },

    //init main components
    initComponents: function () {
      intTemplete();
    },

    //public function to add callback a function which will be called on window resize
    addResizeHandler: function (func) {
      resizeHandlers.push(func);
    },

    //public functon to call _runresizeHandlers
    runResizeHandlers: function () {
      _runResizeHandlers();
    },

    // wrApper function to scroll(focus) to an element
    scrollTo: function () {
    },

    // function to scroll to the top
    scrollTop: function () {
      App.scrollTo();
    },

    startPageLoading: function () {
    },

    stopPageLoading: function () {
    },

    //public helper function to get actual input value(used in IE9 and IE8 due to placeholder attribute not supported)
    getActualVal: function (el) {
      el = $(el);
      if (el.val() === el.attr('placeholder')) {
        return '';
      }

      return el.val();
    },

    //public function to get a paremeter by name from URL
    getURLParameter: function (paramName) {
      let searchString = window.location.search.substring(1),
          i, val, params = searchString.split('&');

      for (i = 0; i < params.length; i++) {
        val = params[i].split('=');
        if (val[0] == paramName) {
          return unescape(val[1]);
        }
      }

      return null;
    },

    // check for device touch support
    isTouchDevice: function () {
      try {
        document.createEvent('TouchEvent');

        return true;
      } catch (e) {
        return false;
      }
    },

    // To get the correct viewport width based on  http://andylangton.co.uk/articles/javascript/get-viewport-size-javascript/
    getViewPort: function () {
      let e = window,
          a = 'inner';

      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }

      return {
        width: e[`${a}Width`],
        height: e[`${a}Height`]
      };
    },

    getUniqueID: function () {
      return `prefix_${Math.floor(Math.random() * (new Date()).getTime())}`;
    },

    // check IE8 mode
    isIE8: function () {
      return isIE8;
    },

    // check IE9 mode
    isIE9: function () {
      return isIE9;
    },

    //check RTL mode
    isRTL: function () {
      return isRTL;
    },

    // check IE8 mode
    isAngularJsApp: function () {
      return typeof angular != 'undefined';
    },

    getResponsiveBreakpoint: function (size) {
      // bootstrap responsive breakpoints
      const sizes = {
        xs: 480,     // extra small
        sm: 768,     // small
        md: 992,     // medium
        lg: 1200     // large
      };

      return sizes[size] ? sizes[size] : 0;
    }
  };

}());

jQuery(document).ready(function () {
  // 도큐먼트 로딩시 첫 처리는 1회 함 2017-03-13 MatthewKim
  App.init();
});
