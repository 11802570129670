/*
 * 2017-09-09 ally
 */

'use strict';

angular.module('gmpApp')
  .directive('countMenu', function (logHistorySVC, $state, $rootScope, commonSVC, settings, userInfo) {
    return {
      templateUrl: 'views/tpls/countmenu.html',
      restrict: 'E',
      replace: true,
      link: function ($scope) {
        const showStatus = [];

        // 더보기 열고닫기
        $scope.visible = { default: true, more: false };

        // 집계 클릭시
        $scope.settingStatus = function (i) {
          // 2018-01-19 chris 권한에 따라 링크 가능여부 체크
          let role = 'order.shipment';

          if ($scope.arr[i].title == '신규문의' || $scope.arr[i].title == '긴급메세지') {
            role = 'order.customer';
          }
          // 2018-01-19 chris 권한 있는경우에만 이동
          if (commonSVC.checkRole(role, userInfo)) {
            Object.assign($rootScope.side_search, $scope.arr[i].action);
            $rootScope.side_search.search = true;
            $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
            $rootScope.side_search.status = [$scope.arr[i].title];
            $rootScope.side_search.selectCount = $scope.arr[i].cName;
            $state.go($scope.arr[i].link);
            $rootScope.$broadcast(`sidebarSearch_${$scope.arr[i].action.page}`, $scope.side_search);
          }
        };
      }
    };
  });
