'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B612-Ctrl', function ($scope, $timeout, commonSVC, B612categorySVC, categoryModel, commonModel, $compile, siteInfo, shopAccountModel) {
    $scope.sel_category = ['', '', '', ''];
    $scope.cate = [[], [], [], []];
    $scope.lotteon_sel_category = ['', '', '', ''];
    $scope.lotteon_cate = [[], [], [], []];
    $scope.CateData = angular.copy(B612categorySVC.category_data);
    $scope.dispCtg = angular.copy(B612categorySVC.dispCtg);
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    $scope.dispCatList = [];
    $scope.selectDisplayCateIndex = new Set();

    let save_flag = false;    //카테고리 선택여부
    let save_flag_lm = false; //롯데온 카테고리 선택 여부

    $scope.pa5_job_data = {
      get_sub_category: {
        load: false,
        data: []
      },
      get_lm_category: {
        load: false,
        data: []
      },
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    /**
     * 저장정보 로드
     */
    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;

      if ($scope.CateData.detailData.category_code) {
        await setCategory($scope.CateData.detailData.category_code);
      }

      if ($scope.CateData.detailData.key_std_cat_cd) {
        await setLotteonCategory($scope.CateData.detailData.key_std_cat_cd);
      }

      if ($scope.CateData.detailData.key_disp_cat_cd.length) {
        for (const cate of $scope.CateData.detailData.key_disp_cat_cd) {
          const displayCate = cate.split('_');

          const displayCateTargetIndex = $scope.dispCatList.findIndex(array => {
            if (displayCate.length !== array.length) {
              return false;
            }

            for (let i = 0; i < array.length; i++) {
              if (array[i].code !== displayCate[i]) {
                return false;
              }
            }

            return true;
          });

          if (displayCateTargetIndex !== -1) {
            $scope.selectDisplayCateIndex.add(displayCateTargetIndex);
          }
        }
      }

      if ($scope.CateData.detailData.key_lm_cate.length) {
        $scope.CateData.detailData.key_lm_cate.forEach(lm_cate_cd => {
          $scope.lm_category_list.find(lm_cate => lm_cate.lm_code === lm_cate_cd).isSelect = true;
        });
      }

    };

    /**
     * 카테고리 선택
     */
    $scope.select = async (idx, type) => {
      const cate = (() => {
        switch (type) {
          case '일반':
            return $scope.sel_category[idx];
          case '롯데온':
            return $scope.lotteon_sel_category[idx];
        }
      })();

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');
      let lotteon_res = [];

      if (type.includes('롯데온')) {
        lotteon_res = await fetchCate((idx + 2), cate.code);
        cate.end_yn = save_flag_lm = !lotteon_res.length;
        $scope.lm_category_list = [];
      } else {
        save_flag = cate.end_yn;
      }

      if (!cate.end_yn) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          switch (type) {
            case '일반':
              $scope.cate[i] = [];
              $scope.sel_category[i] = '';
              break;
            case '롯데온':
              $scope.lotteon_cate[i] = [];
              $scope.lotteon_sel_category[i] = '';
              break;
          }
        }

        const res = !type.includes('롯데온') ? await categoryModel.getScrapCategoryByDepth(cate) : lotteon_res;

        await new Promise(resolve => {
          $timeout(() => {
            switch (type) {
              case '일반':
                $scope.cate[idx + 1] = res.data.result;
                break;
              case '롯데온':
                $scope.lotteon_cate[idx + 1] = res;
                break;
            }
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {

        if (type.includes('롯데온')) {
          await fetchDispCate(cate.code);
          await run_pa5_jobs('get_lm_category', '롯데마트 전시 카테고리 조회', { std_cat_cd: cate.code });
          $scope.lm_category_list = $scope.pa5_job_data.get_lm_category.data.map(lm_cate => ({ isSelect: false, ...lm_cate }));
        } else {
          await run_pa5_jobs('get_sub_category', '카테고리 세분류 조회', { category_id: cate.shop_cate_see_cd });
          $scope.sub_category_list = $scope.pa5_job_data.get_sub_category.data || [];
          if ($scope.sub_category_list.length) {
            $scope.CateData.detailData.key_sub_category = $scope.sub_category_list.find(sub_cate => sub_cate.L4_CD === $scope.CateData.detailData.key_sub_category)?.L4_CD || '';
          } else {
            commonSVC.showMessage('세분류 카테고리가 없어 사용이 불가능합니다.');
            $scope.select(idx - 1, type);
          }
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return;
      }

      if (!$scope.CateData.detailData.key_sub_category) {
        commonSVC.showMessage('실패', '세분류 카테고리를 설정해주세요. ');

        return false;
      }

      if (!save_flag_lm) {
        commonSVC.showMessage('실패', '롯데온 카테고리를 설정해주세요.  ');

        return false;
      }

      if (!$scope.selectDisplayCateIndex.size) {
        commonSVC.showMessage('실패', '롯데온 전시 카테고리를 선택해 주세요.');

        return;
      }

      const lm_category_list = $scope.lm_category_list.filter(lm_cate => lm_cate.isSelect);

      if (!lm_category_list.length) {
        commonSVC.showMessage('저장 실패', '롯데마트 전시카테고리는 한개이상 필수로 입력해주셔야 합니다.');

        return;
      }

      let category_code = '';
      let std_cat_cd = '';

      for (let i = 0; i < 4; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }

        $scope.CateData.detailData[`lotteon_cate${i}`] = '';
        if ($scope.lotteon_sel_category[i].code) {
          const pre = $scope.CateData.detailData[`lotteon_cate${i - 1}`] || '';

          std_cat_cd = $scope.CateData.detailData[`lotteon_cate${i}`] = pre ? `${pre}_${$scope.lotteon_sel_category[i].code}` : $scope.lotteon_sel_category[i].code;
        }
      }

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = $scope.CateData.detailData.key_category_code = category_code;

      //category_code 초기화
      $scope.CateData.detailData.key_std_cat_cd = std_cat_cd;

      // $scope.CateData.detailData.

      $scope.CateData.detailData.key_disp_cat_cd = [];
      $scope.CateData.detailData.key_disp_cat_nm = [];

      for (const idx of $scope.selectDisplayCateIndex) {
        $scope.CateData.detailData.key_disp_cat_cd.push($scope.dispCatList[idx].map(item => item.code).join('_'));
        $scope.CateData.detailData.key_disp_cat_nm.push($scope.dispCatList[idx].map(item => item.name).join('_'));
      }

      if ($scope.form1.$valid) {
        const param = {
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: {
            ...$scope.CateData.detailData,
            key_lm_cate: lm_category_list.map(lm_cate => lm_cate.lm_code)
          },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    /**
     * 카테고리 선택
     */
    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth - 1 === idx
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, '일반') : false;

        idx++;
      }
    };

    /**
     * 롯데온 카테고리 선택
     */
    const setLotteonCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.lotteon_sel_category[idx] = $scope.lotteon_cate[idx].find(cate => (
          cate.code === code[idx]
        )) || '';

        // 선택처리
        hasNext = $scope.lotteon_sel_category[idx] ? await $scope.select(idx, '롯데온') : false;

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    /**
     * 초기화
     */
    const init = async () => {
      // 대분류 카테고리 조회
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'B612', std_ol_yn: 0, shop_cate_see_type: '전시', shop_cate_see_cd: null });

      // 대분류 전시카테고리 조회

      const lotteon_res = await fetchCate('1').then(result => result);

      $timeout(async () => {
        $scope.cate[0] = res.data.result;

        $scope.lotteon_cate[0] = lotteon_res;
        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          await matchDetailDataLoad(typeof $scope.matchingData.shop_cate_no_add_info === 'string' ? JSON.parse($scope.matchingData.shop_cate_no_add_info) : $scope.matchingData.shop_cate_no_add_info);
        } else if (!$scope.matchingData.sol_cate_shop_add_info) {
          await matchDetailDataLoad(angular.copy(B612categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });
    };

    // /**
    //  * 전시카테고리 추가
    //  */
    // $scope.DispCateAdd = async () => {
    //   const current_select_index = $scope.disp_select_category.filter(o => o.shop_cate_see_cd).length - 1;

    //   if (!$scope.disp_select_category[current_select_index].end_yn) {
    //     commonSVC.showMessage('전시카테고리를 끝까지 선택해 주십시오.');

    //     return false;
    //   }

    //   const names = [];

    //   for (let idx = 0; idx < 4; idx++) {
    //     if ($scope.disp_select_category[idx]) {
    //       names.push($scope.disp_select_category[idx].shop_cate_see_name);
    //     }
    //   }

    //   const name = names.join(' > ');
    //   const code = $scope.disp_select_category[current_select_index].shop_cate_see_cd;

    //   if (!code) {
    //     return false;
    //   }

    //   const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList.filter(o => o.name);

    //   for (const thisVal of dispCtgSelectList) {
    //     if (thisVal.code === code) {
    //       commonSVC.showMessage('이미 등록된 매장입니다.');

    //       return false;
    //     }
    //   }

    //   $scope.dispCtg.dispCtgSelectList.push({ code, name });

    //   if ($scope.dispCtg.dispCtgSelectList.length === 1) {

    //     $timeout(async () => {
    //       await run_pa5_jobs('get_sub_category', '카테고리 세분류 조회', { category_id: $scope.dispCtg.dispCtgSelectList[0].code });
    //       $scope.sub_category_list = $scope.pa5_job_data.get_sub_category.data;
    //       $scope.CateData.detailData.key_sub_category = '';
    //     });

    //   }

    //   const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${code}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn dispCtg badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

    //   $('.list-group.dispCtg').append(aElement);
    //   $compile(aElement)($scope);
    // };

    // /**
    //  * 전시카테고리 삭제
    //  */
    // $(document).on('click', '.dispCtgSelectRemoveBtn', async (e) => {
    //   const dispType = e.target.classList[1];
    //   const dispCtgSelectList = $scope[dispType].dispCtgSelectList;
    //   const dispCtgSelectListLen = dispCtgSelectList.length;
    //   const item = $(e.target).parent();
    //   const code = item.attr('data-dispCtgCd');
    //   const sub_category_code = dispCtgSelectList[0].code;

    //   for (let i = 0; i < dispCtgSelectListLen; i++) {
    //     const thisVal = dispCtgSelectList[i];

    //     if (thisVal == code) {
    //       dispCtgSelectList.splice(i, 1);
    //     }
    //   }

    //   item.remove();

    //   $scope[dispType].dispCtgSelectList = $scope[[dispType]].dispCtgSelectList.filter(o => o.code !== code);

    //   $timeout(async () => {
    //     if ($scope[dispType].dispCtgSelectList[0] && sub_category_code !== $scope[dispType].dispCtgSelectList[0].code) {
    //       await run_pa5_jobs('get_sub_category', '카테고리 세분류 조회', { category_id: $scope[dispType].dispCtgSelectList[0].code });
    //       $scope.sub_category_list = $scope.pa5_job_data.get_sub_category.data;
    //       $scope.CateData.detailData.key_sub_category = '';
    //     } else if (!$scope[dispType].dispCtgSelectList[0]) {
    //       $scope.sub_category_list = [];
    //       $scope.CateData.detailData.key_sub_category = '';
    //     }
    //   });

    // });

    function fetchCate(depth, lastCateCode) {
      const param = {
        depth: depth
      };

      if (lastCateCode) {
        param.category_code = lastCateCode;
      }

      return new Promise((resolve) => {
        try {
          requestPA('etc.get_standardCategory', param).then((result) => {
            resolve(result.data.data.items.map((item) => {
              return {
                code: item.data.std_cat_id,
                name: item.data.std_cat_nm
              };
            }));
          }).catch (() => {
            resolve([]);
          });
        } catch (err) {
          commonSVC.showToaster('error', '실패', '카테고리 로딩 실패하였습니다.');
          resolve([]);
        }
      });
    }

    /**
     * 전시카테고리 조회
     */
    function fetchDispCate(lastCateCode) {
      return new Promise((resolve, reject) => {
        try {
          $('#disp-spinner').removeClass('hidden');

          requestPA('etc.get_displayCategory', { category_code: lastCateCode }).then((result) => {
            save_flag = true;

            if (result.data.data.status === 200 && result.data.data.items === '전시카테고리가 없습니다.') {
              // commonSVC.showMessage('전시카테고리가 없습니다.');
              return;
            }

            result.data.data.items.forEach(item => {
              item.forEach(item => {
                item.code = item.disp_cat_id;
                item.name = item.disp_cat_nm;
              });

              item.sort((a, b) => {
                return parseInt(a.depth_no) - parseInt(b.depth_no);
              });
            });

            $scope.dispCatList = result.data.data.items;

            $scope.displayCateAllChecked(false);

            $('#disp-spinner').addClass('hidden');
            resolve();
          }).catch((error) => {
            commonSVC.showToaster('error', '실패', '전시카테고리 로딩 실패하였습니다.');
            $('#disp-spinner').addClass('hidden');
            reject(error);
          });
        } catch (error) {
          commonSVC.showToaster('error', '실패', '전시카테고리 로딩 실패하였습니다.');
          $('#disp-spinner').addClass('hidden');
          reject(error);
        }
      });
    }

    $scope.getDisplayNameToString = function(item) {
      return item.map(obj => obj.name).join(' > ');
    };

    $scope.displayCateAllChecked = function(checked) {
      if (checked) {
        for (let i = 0; i < $scope.dispCatList.length; i++) {
          $scope.selectDisplayCateIndex.add(i);
          $(`#display-cate-${i}`)[0].selected = true;
        }
      } else {
        $scope.selectDisplayCateIndex.clear();
      }
    };

    $scope.selectDisplayOption = function(event, index) {
      const oldChecked = $scope.selectDisplayCateIndex.has(index);

      if (oldChecked === false) {
        $scope.selectDisplayCateIndex.add(index);
      } else {
        $scope.selectDisplayCateIndex.delete(index);
      }

      for (const idx of $scope.selectDisplayCateIndex) {
        $(`#display-cate-${idx}`)[0].selected = true;
      }

      event.stopPropagation();
      event.preventDefault();
    };

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const domain = siteInfo.A524.domain;
      const param = addData ? addData : { lvl: 1, code: '' };

      return commonSVC.requestPA($scope.userInfo, domain, action, param, commonModel, shopAccountModel);
    }

    // 계정정보 선 로딩후 카테고리 로딩
    shopAccountModel.load({ shop_cd: $scope.shop_cd, shop_id: $scope.shop_id })
      .then(function (res) {
        $scope.userInfo = res.data.results;

        init();
      });
  });
