/**
 * img태그 onError처리
 *
 * 2017-08-18 Roy
 */
'use strict';

angular.module('gmpApp')

  .directive('onErrorSrc', function() {
    return {
      link: function(scope, element, attrs) {
        element.bind('error', function() {
          if (attrs.src != attrs.onErrorSrc) {
            attrs.$set('src', attrs.onErrorSrc);
          }
        });
      }
    };
  });
