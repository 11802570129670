'use strict';

angular.module('gmpApp')
  .controller('AltalkSendGroupCtrl', function(
    $scope, $uibModalInstance, settings, commonSVC, data
  ) {
    const search = {
      searchForm: {},
      searchData: {
        totalCount: 0,
        showCount: 25,
        selectCount: 0
      }
    };
    $scope.type = data.type;
    $scope.grid = {};
    $scope.searchData = search.searchData;
    $scope.searchForm = search.searchForm;
    $scope.grid.searchData = angular.copy(search.searchData);
    $scope.grid.searchForm = angular.copy(search.searchForm);
    $scope.grid.methods = {};
    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: true,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['mdate'],
      alignCenterColumns: ['widget', 'mdate', 'group_name', 'cnt'],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'group_name', 'cnt'],
      notVisibleColumns: [],
      notMovingColumns: ['widget'],
      notResizingColumns: ['widget'],
      initPagingSize: 25,
      emptyText: '추가된 그룹이 없습니다.',   // 옵션에 추가하면 데이터 없을 때 노출 텍스트 커스텀 가능
      selectOptions: {
        checkbox: true,
        multiSelect: true
      },

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/atalk/group-list`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d);

          return data;
        },
        requestDidAction: function (result) {
          // $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function (row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>`;
          }
        },
        {
          key: 'group_name',
          title: '그룹명',
          width: 150
        },
        {
          key: 'mdate',
          title: '업데이트',
          width: 150,
          filter: 'dateValid'
        },
        {
          key: 'cnt',
          title: '그룹 인원 수',
          width: 130
        }
      ]
    };

    /**
     * 그룹 추가
     */
    $scope.addGroup = () => {
      const modal = commonSVC.openModal('md', {}, 'AddSendGroupCtrl', 'views/alimtalk/modals/add_send_group.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };

    /**
     * 주문 선택 후 회원 추가
     */
    $scope.add = async () => {
      const userList = _.uniqBy(data.ord, 'order_htel').map(ord => ({ uniq: ord.uniq, bundle_no: ord.bundle_no, name: ord.order_name, htel: ord.order_htel, memo: '' }));
      const emptyArr = [];
      data.ord.forEach(ord => {
        if (!ord.order_name || !ord.order_htel) {
          emptyArr.push(ord.bundle_no);
        }
      });

      if (userList.length !== data.ord.length) {
        return commonSVC.showMessage('중복된 휴대폰 번호가 있습니다.', '선택하신 주문에 동일한 휴대폰 번호가 존재합니다.\n동일한 휴대폰 번호가 있는지 확인 후 추가해 주세요.');
      }
      if (emptyArr.length) {
        return commonSVC.showMessage('회원 추가에 실패하였습니다.', '회원명 또는 전화번호가 없는 주문이 존재합니다.');
      }

      if (userList.some(user => user.htel.includes('*') || user.name.includes('*'))) {
        return commonSVC.showMessage('회원 추가에 실패하였습니다.', '개인정보 보호법에 따라 3개월이 지나 마스킹 처리된\n주문은 회원 추가가 불가합니다.');
      }

      try {
        const result = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/atalk/group/mem`, { user: userList, custgroup_no: $scope.grid.methods.selectedData('uniq').toString() });

        if (result.data.results === 'success') {
          commonSVC.showToaster('success', '성공', '회원 추가 성공하였습니다.');
          $scope.grid.methods.reloadData(function () {}, true, true);

          return;
        }
      } catch (err) {
        const errMsg = err.data.messages[0].includes('Duplicate') ? '선택하신 그룹에 이미 동일 휴대폰 번호가 존재합니다.\n이미 추가된 회원의 경우 추가가 불가하며 확인 후 추가해 주세요.' : '회원 추가에 실패하였습니다.';

        return commonSVC.showMessage(`${err.data.messages[0].includes('Duplicate') ? '중복된 회원이 있습니다.' : '회원 추가 실패'}`, errMsg);
      }

    };

    /**
     * 그룹 삭제
     */
    $scope.deleteGroup = async () => {
      const selectedGroupList = $scope.grid.methods.selectedData().length > 0 ? $scope.grid.methods.selectedData().map(v => v.uniq) : [];

      const deleteGroup = await commonSVC.showConfirmCustom({
        title: '선택한 그룹을 삭제하시겠습니까?',
        text: '추가한 그룹 인원도 모두 삭제되며 복구되지 않습니다.<div><span style="color: rgb(7,57,141);">삭제 전 해당 그룹으로 등록한 알림톡 발송 예약건은 발송</span>됩니다.</div>발송을 희망하지 않을 경우 전체발송내역 > 상세에서 삭제해 주세요.',
        html: true,
        showCancelButton: true,
        confirmButtonText: '삭제'
      });
      if (deleteGroup) {
        try {
          const result = await commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/atalk/group`, { uniqList: selectedGroupList });

          if (result.data.results === 'success') {
            commonSVC.showToaster('success', '성공', '발송 그룹 삭제 성공');
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '그룹 삭제에 실패하였습니다.');
        }

        $scope.grid.methods.reloadData(function () {}, true);
      }
    };

    /**
     * ui-grid 노출 건수 설정
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
     * 상세페이지 보여주기
     */
    $scope.showDetail = function(uniq) {
      const resolve = {
        data: {
          uniq
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'AltalkSendGroupDetailCtrl', 'views/alimtalk/modals/send_group_detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };
  });