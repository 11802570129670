'use strict';
angular.module('gmpApp')
  .controller('SettingsDeliveryCtrl', function ($rootScope, $scope, $state, shopAccountModel, commonSVC, deliverySVC, userInfo, deliveryModel) {

    init();

    function init() {

      // 택배사 선택시 택배관리 하위에 상세화면이 열리기는데 이게 탭이 2개 열렸다고 인식되어 페이지 새로 고침시 택배관리 탭이 하나 더 생성됨.
      // 탭이 하나 더 열리는걸 방지하기 위해 택배관리 init 시에 택배상세 탭은 삭제하고 시작하도록 설정 2020-10-20 rony
      $rootScope.$emit('$closeTab', 'tab_settings_delivery.detail');
      $scope.carrList = deliverySVC.carrList;

      $scope.deliveryList = [];                              // 사용자 택배사 리스트

      $scope.deliveryDetailOpen = false;                     // 상세보기 추가화면 노출여부
      $scope.searchText = '';

      deliveryModel.deliveryAllList(function (state, data) {
        $scope.deliveryList = data.results;
      });
    }

    /**
     * 사용중 택배사 정보 불러오기
     */
    $scope.loadDeliveryList = function() {
      deliveryModel.deliveryAllList(function(state, data) {
        $scope.deliveryList = data.results;
      });
    };

    // 택배관리 왼쪽 탭 높이 수정위한 이닛
    $scope.sidebarInit = function () {
      const wHeight = $(window).height();

      $('.sidebar-container').height(wHeight - 246);
    };

    // /**
    //  * 택배사 추가
    //  */
    // $scope.selectDelivery = function() {
    //   if(!$scope.selectedCarrNo) {
    //     $scope.deliveryDetailOpen = false;
    //   } else {
    //     var params = {
    //       biz_reg_no: userInfo.user.biz_reg_no,
    //       carr_no: $scope.selectedCarrNo
    //     };

    //     // data.result 코드
    //     // 1 : GMP(O), confirm_yn(1) - 이미 연동중인 택배사입니다.
    //     // 2 : GMP(O), confirm_yn(0) - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
    //     // 2 : GMP(X), EMP(승인접수)    - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
    //     // 3 : GMP(X), EMP(승인완료) - 기존 EMP 연동정보 추가
    //     // 4 : GMP(X), EMP(X)     - 신규 연동신청
    //     deliveryModel.checkCarrierLinked(params, function (state, data) {
    //       if(state === 'success') {
    //         if(data.result === '4') {
    //           // commonSVC.showMessage("연동 신청하기를 통해 연동신청을 해주시기 바랍니다.");
    //         } else if(data.result === '3') {
    //             $scope.deliveryDetailOpen = true;
    //             $state.go("main.settings_delivery.detail", { linkType: data.result, pageType: 'add', carr_no: $scope.selectedCarrNo, carr_id: null });
    //             $scope.selectedCarrNo = '';
    //         } else if(data.result === '1') {
    //             commonSVC.showMessage("이미 연동중인 택배사입니다.");
    //           $scope.selectedCarrNo = '';
    //         } else if(data.result === '2') {
    //             commonSVC.showMessage("연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.");
    //             $scope.selectedCarrNo = '';
    //         }
    //       } else {
    //         commonSVC.showToaster("error", "실패", "택배사 연동정보 조회 실패");
    //         $scope.selectedCarrNo = '';
    //         $scope.deliveryDetailOpen = false;
    //       }
    //     });
    //   }
    // };

    /**
     * 택배사 추가
     */
    $scope.addDelivery = function() {
      // data.result 코드
      // 1 : GMP(O), confirm_yn(1) - 이미 연동중인 택배사입니다.
      // 2 : GMP(O), confirm_yn(0) - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
      // 2 : GMP(X), EMP(승인접수)    - 연동신청상태입니다. 택배사 측의 연동승인 후 이용가능합니다.
      // 3 : GMP(X), EMP(승인완료) - 기존 EMP 연동정보 추가
      // 4 : GMP(X), EMP(X)     - 신규 연동신청
      const templatePath = 'views/settings/delivery/detail/delivery_link.html';
      const resolve = {
        data: {
          linkType: '',
          pageType: 'add',
          carr_no: '',
          carr_id: null
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'SettingsDeliveryLinkRegCtrl', templatePath, false, true, false);

      modal.result.then(function () {
        $scope.loadDeliveryList();
      });
    };

    /**
    * 택배사 수정
    */
    $scope.editDelivery = function(deli) {
      // 승인완료 건만 수정가능
      if (deli.contract_yn == true && deli.reg_confirm_yn == true) {
        $scope.deliveryDetailOpen = true;
        $state.go('main.settings_delivery.detail', { linkType: '3', pageType: 'edit', carr_no: deli.carr_no, carr_id: deli.carr_id, carr_id_sub: deli.carr_id_sub });
        $scope.selectedCarrNo = deli.carr_no.toString();
      }
    };

    /**
     * 택배사 삭제
     */
    $scope.deleteDelivery = params => {
      commonSVC.showConfirm('택배사 계정을 삭제하시겠습니까?', '', function () {
        const deleteData = { carr_no: params.carr_no, carr_id: params.carr_id, carr_id_sub: params.carr_id_sub };

        deliveryModel.DeleteDelivery(deleteData, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '택배사 계정 삭제에 성공하였습니다.');
            $scope.deliveryDetailOpen = false;
            $scope.loadDeliveryList();
          } else {
            commonSVC.showToaster('error', '실패', '택배사 계정 삭제에 실패하였습니다.');
          }
        });
      });
    };

    /**
     * 검색 필터
     */
    $scope.filterFunction = function(element) {
      if ($scope.searchText == '') {
        return true;
      }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.searchText})`, 'i'));
    };

    /**
     * 검색내용 초기화, 재검색
     */
    $scope.resetDo = function() {
      $scope.searchText = '';
      $scope.loadDeliveryList();
    };
  });
