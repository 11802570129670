/**
 * 2018-06-27 Daniel
 * sku 가져오기
 */

'use strict';

angular.module('gmpApp')
  .controller('scrapSkuCtrl', function ($scope, $uibModalInstance, $q, data, commonSVC, workSVC) {
    $scope.ebaydepotAccounts = data.ebaydepotAccounts;
    $scope.selectedAll = false;
    $scope.scrap = {
      waitingProd: false
    };

    $scope.checkboxClick = function (idx) {
      if (idx === 'all') {
        _.each($scope.ebaydepotAccounts, function (account) {
          account.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.ebaydepotAccounts[idx].selected)) {
          $scope.selectedAll = false;
        }
      }
    };

    $scope.ok = function () {

      const selected = _.filter($scope.ebaydepotAccounts, { selected: true });

      if (!selected.length) {
        commonSVC.showMessage('한개 이상의 계정을 선택해주세요.');

        return false;
      }

      const addWorkProms = [];
      let success_cnt = 0;
      let error_cnt = 0;

      _.each(selected, function (v) {
        // 작업 추가
        const param = {
          site: {
            site_code: 'ESMP',
            site_name: 'G마켓 물류',
            site_id: v.ebaydepot_id,
            site_pwd: v.ebaydepot_pwd,
            playapi_runner_os: 'AWS',
            uuid: v.uuid
          },
          data: {
            scrapWaitingProd: $scope.scrap.waitingProd
          }
        };

        addWorkProms.push(workSVC.addEbaydepotWork('ScrapSku', param, true)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              success_cnt++;
            } else {
              error_cnt++;
            }
          })
          .catch(function () {
            error_cnt++;
          }));
      });

      $q.all(addWorkProms)
        .then(function() {
          commonSVC.showToaster('success', '작업등록 완료', `성공 : ${success_cnt}, 실패 : ${error_cnt}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
