/**
 *  ptgui-number-only 컴퍼넌트
 *  2016-07-08 최은서
 */

(function () {
  'use strict';

  /**
   * < Attributes >
   *   - ptgui-number-only : required
   *   - ng-model : required
   *   - type : "text" (type을 "number"로 쓰면 안됩니다.)
   *   - min : 최소값 (기본 0)
   *   - max : 최대값 (기본 없음)
   */

  var NUMBER_REGEXP = /^\s*(\d+)\s*$/;

  angular.module('ptgui.numberonly', [])
    .directive('ptguiNumberOnly', function () {

      function pre(scope, el, attrs, ngModelCtrl) {
        // angular 자체적으로 validation 체크 시 빈값일 경우
        // undefined 처리되어 아래와 같이 처리.
        // ngModelCtrl.$$setOptions({
        //   allowInvalid: true,
        //   updateOnDefault: true
        // });
        ngModelCtrl.$options.$$options.allowInvalid = true;
        ngModelCtrl.$options.$$options.updateOnDefault = true;
      }

      function link(scope, el, attrs, ngModelCtrl) {
        var minVal, maxVal, lastValidViewValue;
        var isDefined = angular.isDefined;
        var isUndefined = angular.isUndefined;
        var isNumber = angular.isNumber;

        function changeViewValue(value) {
          ngModelCtrl.$viewValue = value;
          ngModelCtrl.$commitViewValue();
          ngModelCtrl.$render();
        }

        ngModelCtrl.$parsers.push(function (value) {
          if (ngModelCtrl.$isEmpty(value)) {
            lastValidViewValue = value;
            return "";
          }

          if (_.isString(value) && value.indexOf('-') === 0) {
            changeViewValue(lastValidViewValue);
            return lastValidViewValue;
          }

          if (NUMBER_REGEXP.test(value)) {
            // 올바른 값일 경우
            lastValidViewValue = value;
            return value;
          } else {
            // 처음 값이 없는 경우 undefined -> "" 로 변경해서 리턴
            if (lastValidViewValue == undefined) lastValidViewValue = "";

            // 올바르지 않은 값일 경우 마지막 값으로 render
            changeViewValue(lastValidViewValue);
            return lastValidViewValue;
          }
        });

        // 최소값(min) 검사
        ngModelCtrl.$validators.min = function (value) {
          return ngModelCtrl.$isEmpty(value) || isUndefined(minVal) || _.toSafeInteger(value) >= minVal;
        };
        if (isDefined(attrs.min) || attrs.ngMin) {
          attrs.$observe('min', function(val) {
            if (isDefined(val) && !isNumber(val)) {
              val = parseFloat(val, 10);
            }
            minVal = isNumber(val) && !isNaN(val) ? val : undefined;
            ngModelCtrl.$validate();
          });
        } else {
          minVal = 0;
        }

        // 최대값(max) 검사
        if (isDefined(attrs.max) || attrs.ngMax) {
          ngModelCtrl.$validators.max = function(value) {
            return ngModelCtrl.$isEmpty(value) || isUndefined(maxVal) || _.toSafeInteger(value) <= maxVal;
          };
          attrs.$observe('max', function(val) {
            if (isDefined(val) && !isNumber(val)) {
              val = parseFloat(val, 10);
            }
            maxVal = isNumber(val) && !isNaN(val) ? val : undefined;
            ngModelCtrl.$validate();
          });
        }

        el.bind('blur', function () {
          var modelValue = _.toSafeInteger(ngModelCtrl.$modelValue);

          // 2016-09-05 남형진 type=number number 타입으로 리턴
          if(el.type == "number"){
            changeViewValue(modelValue);
          }else{
            modelValue = modelValue == 0 ? "" : modelValue;
            changeViewValue(_.toString(modelValue));
          }
        });

      }

      return {
        restrict: 'A',
        require: 'ngModel',
        compile: function(element){
          return {
            pre: pre,
            post: link
          }
        }
      };

    });

})();