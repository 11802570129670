'use strict';

angular.module('gmpApp')
  .service('shopAccountSVC', function () {

    return {
      /**
       * 기본 Vdata
       */
      dafaultVdata: {
        shop_cd: '',
        shop_name: '',
        shop_id: '',
        shop_id_sub: '',
        shop_pwd: '',
        seller_nick: '',
        etc1: '',
        etc2: '',
        etc3: '',
        etc4: '',
        etc5: '',
        etc6: '',
        etc7: '',
        new_pwd: ''
      },

      //로그인체크 안해도되는 쇼핑몰
      noneLoginCheck: ['A131', 'B513', 'B603', 'B614', 'U000'],

    };

  });
