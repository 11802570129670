'use strict';

const showToaster = _.debounce(function (type, title, contents, opt) {

  let icon = '';

  if (type === 'success') {
    icon = 'icon-checkmark3';
  } else if (type === 'error') {
    icon = 'icon-blocked';
  } else if (type === 'warning') {
    icon = 'icon-warning22';
  } else if (type === 'info') {
    icon = 'icon-info22';
  }

  const notice = new PNotify({
    title: title,
    text: contents,
    type: type,
    icon: icon,
    buttons: {
      closer: false,
      sticker: false
    },
    addclass: opt && opt.addclass ? opt.addclass : '',
    width: opt && opt.width ? opt.width : '300px',
  });

  // 2017-03-15 chris 알림 클릭시 닫기
  notice.get().click(function() {
    notice.remove();
  });

}, 500);

(function() {
  var $, animationStyle, chromeTabs, defaultNewTabData, tabTemplate;

  $ = jQuery;

  if (document.body.style['-webkit-mask-repeat'] !== void 0) {
    $('html').addClass('cssmasks');
  } else {
    $('html').addClass('no-cssmasks');
  }

  tabTemplate = '<div class="chrome-tab" context-menu="menuOptions">\n  <div class="chrome-tab-favicon"></div>\n  <div class="chrome-tab-title"></div>\n  <div class="chrome-tab-close"></div>\n  <div class="chrome-tab-curves">\n    <div class="chrome-tab-curves-left-shadow"></div>\n    <div class="chrome-tab-curves-left-highlight"></div>\n    <div class="chrome-tab-curves-left"></div>\n    <div class="chrome-tab-curves-right-shadow"></div>\n    <div class="chrome-tab-curves-right-highlight"></div>\n    <div class="chrome-tab-curves-right"></div>\n  </div>\n</div>';

  defaultNewTabData = {
    title: 'New Tab',
    favicon: '',
    data: {}
  };

  animationStyle = document.createElement('style');

  chromeTabs = {
    init: function(options) {
      $.extend(options.$shell.data(), options);
      options.$shell.prepend(animationStyle);
      options.$shell.find('.chrome-tab').each(function() {
        return $(this).data().tabData = {
          data: {}
        };
      });
      function render(mode) {
        return chromeTabs.render(options.$shell, mode);
      }

      // 브라우저 사이즈 조절시 탭 크기조절만 하도록 수정
      $(window).resize(_.debounce(function () {
        return render('resize');
      }, 300));
    },
    render: function($shell, mode) {
      // 2017-05-19 matthew
      fastdom.measure(function () {
        chromeTabs.fixTabSizes($shell);

        // title 이 태그로 잡혀있는경우 마우스 오버시 태그 노출되기 때문에 타이틀을 변경해주어야 함.
        // 그냥 변경해버리는경우 아래의 width 변경등이 적용안되기 때문에 아이디 넣어줌. 2019-07-05 rony
        $shell.children('div.chrome-tabs').children('[title *= picon-home]').prop('id', 'tab_home').prop('title', 'HOME');

        // HOME버튼 사이즈 변경 width 값 탭 가로 사이즈 변경
        $shell.children('div.chrome-tabs').children('[id=tab_home]').css({ width: 50 }).children('.chrome-tab-favicon').remove();
        // font-size HOME 이미지 사이즈
        $shell.children('div.chrome-tabs').children('[id=tab_home]').children('.chrome-tab-title').children('i').css({ 'font-size': 16 });

        if (mode === 'resize') { return; }
        chromeTabs.fixZIndexes($shell);
        chromeTabs.setupEvents($shell);
        chromeTabs.setupSortable($shell);

        return $shell.trigger('chromeTabRender');
      });
    },
    setupSortable: function($shell) {
      var $tabs = $shell.find('.chrome-tabs');

      // 드래그앤 드롭은 jquery.ui 의 sortable 사용 2017-03-07 MatthewKim
      // http://api.jqueryui.com/sortable/
      return $tabs.sortable({
        axis: 'x',
        tolerance: 'pointer',
        cancel: '.chrome-tab-close',
        delay: 150,
        // 드래그시 불필요한 처리라 제외함 2017-03-07 MatthewKim
        // start: function(e, ui) {
        //   ui.item.addClass('ui-sortable-draggable-item');
        //   $shell.addClass('chrome-tabs-sorting');
        //   chromeTabs.setupTabClones($shell, ui.item);
        //   chromeTabs.fixZIndexes($shell);
        //   if (!$(ui.item).hasClass('chrome-tab-current')) {
        //     return $tabs.sortable('option', 'zIndex', $(ui.item).data().zIndex);
        //   } else {
        //     return $tabs.sortable('option', 'zIndex', $tabs.length + 40);
        //   }
        // },
        // 2017-03-09 MatthewKim 드래그 종료시 이벤트는 살리고, 탭 이동 완료 이벤트 트리거 추가 (순서변경 저장을 위해)
        stop: function(e, ui) {
          $('.ui-sortable-draggable-item').removeClass('ui-sortable-draggable-item');
          $shell.removeClass('chrome-tabs-sorting');
          chromeTabs.cleanUpTabClones($shell);
          $shell.trigger('chromeTabMoved'); // 2017-03-09 MatthewKim

          return chromeTabs.setCurrentTab($shell, $(ui.item));
        },
        change: function(e, ui) {
          var placeholderIndex = ui.placeholder.index();

          if (ui.helper.index() <= placeholderIndex) {
            placeholderIndex -= 1;
          }

          return chromeTabs.animateSort($shell, placeholderIndex);
        }
      });
    },
    animateSort: function($shell, newPlaceholderIndex) {
      var $clone, $placeholder, delta, placeholderIndex;

      $clone = $shell.find('.chrome-tabs.chrome-tabs-clone');
      $placeholder = $clone.find('.ui-sortable-placeholder');
      placeholderIndex = $placeholder.index();
      delta = newPlaceholderIndex - placeholderIndex;
      if (delta === -1) {
        if (newPlaceholderIndex - 1 < 0) {
          return $clone.prepend($placeholder);
        } else {
          return $($clone.find('.chrome-tab').get(newPlaceholderIndex - 1)).after($placeholder);
        }
      } else if (delta === 1) {
        return $($clone.find('.chrome-tab').get(newPlaceholderIndex)).after($placeholder);
      }
    },
    // setupTabClones: function($shell) {
    //   var $clone, $lastClone, $tabsContainer;
    //   $lastClone = $shell.find('.chrome-tabs.chrome-tabs-clone');
    //   $tabsContainer = $shell.find('.chrome-tabs').first();
    //   $clone = $tabsContainer.clone().addClass('chrome-tabs-clone');
    //   $clone.find('.ui-sortable-helper, .ui-sortable-draggable-item').remove();
    //   $clone.find('.chrome-tab').css('position', '');
    //   if ($lastClone.length) {
    //     return $lastClone.replaceWith($clone);
    //   } else {
    //     return $tabsContainer.after($clone);
    //   }
    // },
    cleanUpTabClones: function($shell) {
      return $shell.find('.chrome-tabs.chrome-tabs-clone').remove();
    },
    fixTabSizes: function($shell) {
      var $tabs, margin, width;

      $tabs = $shell.find('.chrome-tab');
      margin = (parseInt($tabs.first().css('marginLeft'), 10) + parseInt($tabs.first().css('marginRight'), 10)) || 0;
      width = $shell.width() - 50;
      width = (width / $tabs.length) - margin;
      width = Math.max($shell.data().minWidth, Math.min($shell.data().maxWidth, width));
      $tabs.css({
        width: width
      });

      return setTimeout(function() {
        return chromeTabs.setupAnimationStyles($shell);
      });
    },
    setupAnimationStyles: function($shell) {
      var $tabs = $shell.find('.chrome-tabs:not(.chrome-tabs-clone) .chrome-tab'),
          offsetLeft = $shell.find('.chrome-tabs').offset().left,
          styleHTML = '';

      $tabs.each(function(i) {
        var $tab, left;

        $tab = $(this);
        left = $tab.offset().left - offsetLeft - parseInt($tabs.first().css('marginLeft'), 10);

        return styleHTML += '.chrome-tabs-clone .chrome-tab:nth-child(' + (i + 1) + ') {\n  left: ' + left + 'px\n}';
      });

      return animationStyle.innerHTML = styleHTML;
    },
    fixZIndexes: function($shell) {
      var $tabs = $shell.find('.chrome-tab');

      return $tabs.each(function(i) {
        var $tab = $(this),
            zIndex;

        zIndex = $tabs.length - i;
        if ($tab.hasClass('chrome-tab-current')) {
          zIndex = $tabs.length + 40;
        }
        $tab.css({
          zIndex: zIndex
        });

        return $tab.data({
          zIndex: zIndex
        });
      });
    },
    setupEvents: function($shell) {
      // 2017-03-07 chris
      // $shell.unbind('dblclick').bind('dblclick', function() {
      //   return chromeTabs.addNewTab($shell);
      // });
      $shell.unbind('mouseup').bind('mouseup', function(e) {
        if (e.which === 2 && e.target.className !== 'chrome-tab-title') {
          return chromeTabs.addNewTab($shell);
        }
      });

      return $shell.find('.chrome-tab').each(function() {
        var $tab = $(this);

        $tab.unbind('click').click(function() {
          return chromeTabs.setCurrentTab($shell, $tab);
        });
        $tab.unbind('mouseup').mouseup(function(e) {
          if (e.which === 2) {
            return chromeTabs.closeTab($shell, $tab);
          }
        });

        // 2017-03-16 chris 즐겨찾기 이벤트 추가
        $tab.find('.chrome-tab-favicon ').unbind('click').click(function() {
          if (!$tab.find('.chrome-tab-favicon').hasClass('active') && $('.chrome-tab-favicon.active').length > 7) {
            showToaster('warning', '', '즐겨찾기 탭은 최대 8개까지 지정할 수 있습니다.', { addclass: 'pnotify-top-center', width: '400px' });

            return;
          }

          return chromeTabs.bookmarkTab($shell, $tab);
        });

        return $tab.find('.chrome-tab-close').unbind('click').click(function() {
          return chromeTabs.closeTab($shell, $tab);
        });
      });
    },
    addNewTab: function($shell, newTabData, setCurrent, openBesideCurrent) {
      var $newTab, tabData;
      var notOpen = ['카테고리 상세'];

      if (notOpen.includes(newTabData.title)) {
        return false;
      }

      // 2017-03-07 chris $compile 추가
      $newTab = $shell.data().$compile(tabTemplate)($shell.data().scope);

      //2017-09-25 chris 메인탭 삭제 금지
      if (newTabData.data.id == 'tab_home') {
        $newTab.find('.chrome-tab-close').hide();
      }

      // 2017-03-09 MatthewKim 현재 보고 있는 탭 옆으로 탭이 추가되도록 수정
      // 다만, 새로고침 등으로 처음에 구성될때는 이 옵션이 false 로 넘어온다.
      if (openBesideCurrent) {
        var now_idx = $shell.find('.chrome-tab-current').index();

        if (now_idx >= 0) {
          $newTab.insertAfter($shell.find('.chrome-tab-current'));
        } else {
          $shell.find('.chrome-tabs').append($newTab);
        }
      }
      else {
        // 2017-10-28 Pada 공지사항 상세보기 추가시 탭 추가 안함
        // if (newTabData.data.id !== 'tab_notice_detail' && newTabData.data.id !== 'tab_shop_notice_detail') {

        // }
        $shell.find('.chrome-tabs').append($newTab);
      }

      tabData = $.extend(true, {}, defaultNewTabData, newTabData);

      chromeTabs.updateTab($shell, $newTab, tabData);
      // 2017-03-07 MatthewKim setCurrent 인자 추가 false 로 보내면 탭 추가만 하고 현재탭으로 선택 안함
      setCurrent = setCurrent == undefined ? true : setCurrent;
      if (setCurrent) {
        return chromeTabs.setCurrentTab($shell, $newTab);
      } else {
        return true;
      }
    },
    setCurrentTab: function($shell, $tab) {
      $shell.find('.chrome-tab-current').removeClass('chrome-tab-current');
      $tab.addClass('chrome-tab-current');

      return chromeTabs.render($shell);
    },
    closeTab: function($shell, $tab) {
      // home tab은 삭제 안되도록 강제 처리
      const tab_id = $tab.data()?.tabData.data.id;
      if (tab_id === 'tab_home') {
        return;
      }

      if ($tab.hasClass('chrome-tab-current')) {
        if ($tab.prev().length) {
          chromeTabs.setCurrentTab($shell, $tab.prev());
        } else if ($tab.next().length) {
          chromeTabs.setCurrentTab($shell, $tab.next());
        }
      }

      setTimeout(function() {
        if (tab_id) {
          $shell.trigger('chromeTabRemoved', [tab_id]); // 2017-03-06 MatthewKim
        }
      }, 50);
      $tab.remove();

      // 2017-05-19 MatthewKim 제거
      //return chromeTabs.render($shell);
    },
    updateTab: function($shell, $tab, tabData) {
      $tab.attr({ 'tab-id': tabData.data.id, 'test-id': 'navi-' + tabData.data.id, 'title': tabData.title });
      $tab.find('.chrome-tab-title').html(tabData.title);
      // $tab.find('.chrome-tab-favicon').css({
      //   backgroundImage: "url('" + tabData.favicon + "')"
      // });

      // 2017-03-16 chris 즐겨찾기 기능으로 변경
      var bookmark_class = 'icon-bookmark2';

      if (tabData.bookmark) {
        $tab.find('.chrome-tab-favicon').addClass('active');
        bookmark_class = 'icon-bookmark2';
      } else {
        $tab.find('.chrome-tab-favicon').removeClass('active');
      }
      $tab.find('.chrome-tab-favicon').append('<i class="' + bookmark_class + '"></i>');

      return $tab.data().tabData = tabData;
    },
    // 2017-03-16 chris 즐겨찾기 설정
    bookmarkTab: function($shell, $tab) {
      $tab.find('.chrome-tab-favicon').toggleClass('active');
      if ($tab.find('.chrome-tab-favicon').hasClass('active')) {
        $tab.data('tabData').data.bookmark = true;
        $tab.find('.chrome-tab-favicon i').removeClass('icon-bookmark2');
        $tab.find('.chrome-tab-favicon i').addClass('icon-bookmark2');
      } else {
        $tab.data('tabData').data.bookmark = false;
        $tab.find('.chrome-tab-favicon i').removeClass('icon-bookmark2');
        $tab.find('.chrome-tab-favicon i').addClass('icon-bookmark2');
      }
      $shell.trigger('chromeBookmarkTab');

      return false;
    }

  };

  window.chromeTabs = chromeTabs;

}).call(this);
