'use strict';

angular.module('gmpApp')
  .controller('PaySolExpiredController', function ($scope, $rootScope, $uibModalInstance, userInfo, $window) {
    const dataLayer = $window.dataLayer = $window.dataLayer || [];

    // 잔여일 재확인
    $scope.leftDay = moment(userInfo.user.sol_edate).endOf('day').diff(moment().endOf('day'), 'days') + 1;

    // 만료문구
    $scope.expiredMsg = $scope.leftDay > 0 ? `${$scope.leftDay}일 뒤 만료됩니다` : '만료되었습니다';

    // GA4 label
    $scope.label = $scope.leftDay > 0 ? 'a' : 'b';

    let label;

    const getPayMethod = (str) => {
      switch (str) {
        case '카드': return '신용카드 결제';
        case '가상계좌': return '가상계좌 이체';
      }

      return '무통장 입금';
    };

    // 최근 결제수단
    $scope.last_pay_method = userInfo.user.last_pay_method ? getPayMethod(userInfo.user.last_pay_method) : '-';

    // 결제하기
    $scope.payRequest = (setAutoPay = false) => {
      $rootScope.openPayReq({ paySetting: true, setAutoPay, label });
      $uibModalInstance.close();
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    // 오늘 하루 보지 않기
    $scope.notShowNoticeExpired = () => {
      localStorage.setItem(`notice_expired_${userInfo.user.m_no}`, moment().format('YYYYMMDD'));
      $scope.close();
    };

    $(document).on('click', '.repayment_button', function() {
      label = $(this).attr('label');
      dataLayer.push({
        event: 'Button Click',
        category: 'Button Click',
        label,
        action: 'click_repayment_button'
      });
    });
  });
