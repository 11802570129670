'use strict';

angular.module('gmpApp')
  .factory('questionModel', function ($rootScope, settings, commonSVC) {
    return {
      /**
       * 카운팅 집계 2017-11-1 pada
       * @param data
       * @param next
       */
      countList: function(data, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/inquiries/count-list`, data, function(state, data) {
          next(state, data);
        });
      },
      /**
       * 1:1 문의 글 등록 2017-11-1 pada
       * @param data
       * @param next
       */
      frontInsertQuestion: function(data) {
        const resolve = data;

        if (data.attach_file != null) {
          // 재문의 업로드 파일
          angular.forEach(data.attach_file, function(v, k) {
            resolve[`re_file${k}`] = v;
          });
        }
        delete resolve.file;
        delete resolve.attach_file;

        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/inquiries/add`, resolve);
      },
      /**
       * 1:1문의 상세보기 11-1 pada
       * @param data
       * @param next
       */
      frontCsDetail: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/inquiries/${params.cs_no}`, {});
      },

      /**
       * 1:1문의 수정
       * 2019-01-09 Amelia
       * @param data
       * @param next
       */
      modifyCs: function (data, next) {
        const resolve = {
          cs_no: data.cs_no,
          title: data.title,
          content: data.content,
          board_status: data.board_status,
          board_type: data.board_type,
          name: data.name,
          reply_staff_no: data.reply_staff_no
        };

        _.forEach(data.file_array, function(file, idx) {
          resolve[`file${idx}`] = file;
        });

        commonSVC.sendUrlFile('PATCH', `${settings.pa20ApiUrl}/app/inquiries`, resolve, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 1:1문의 삭제
       * 2019-01-09 Amelia
       * @param data
       * @param next
       */
      deleteCs: function (data, next) {
        commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/inquiries`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 답변지연 정보 조회
       */
      getDelayInfo: (params) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/inquiries/delay-info?cs_no=${params.cs_no}`, {});
      },

      /**
       * 재문의 리스트 11-2 pada
       * @param data
       * @param next
       */
      reQuestionList: async params => {
        if (params.pa_cs_no) {
          const { data: questions } = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/inquiries/re-questions?pa_cs_no=${params.pa_cs_no}`, {});

          questions.forEach((question) => {
            if (typeof question.attach_file !== 'object') {
              question.attach_file = question.attach_file.split('#').filter(file => file).map(file => JSON.parse(file));
            }

            if (typeof question.reply_attach_file !== 'object') {
              question.reply_attach_file = question.reply_attach_file.split('#').filter(file => file).map(file => JSON.parse(file));
            }
          });

          return questions;
        } else {
          return [];
        }
      },
      /**
       * 과거 문의 유형 처리
       * Author: Lucas
       * Date: 21.05.27
       */
      pastNameConversion: csBoard => {
        switch (csBoard) {
          case '가입상담':
          case '제휴/미팅':
            return '가입';
          case '결제관련':
            return '결제';
          case 'SKU상품관리':
            return '재고';
          case '기타관리':
            return '기타';
          case '문의관리':
            return '문의';
          case '온라인상품관리단일':
          case '온라인상품관리일반':
            return '상품';
          case '작업결과':
            return '작업';
          case '재고관리':
            return '재고';
          case '정산관리':
            return '정산';
          case '주문관리':
            return '주문';
          default:
            return csBoard;
        }
      }
    };
  });
