'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentGiftListCtrl',
    function (
      // common
      $scope, $rootScope, gettextCatalog,
      // info
      settings, $filter,
      // SVC
      localStorageService, commonSVC,
      // Model
      giftModel, systemModel, shipmentModel,
      // List
      useSystemList, useWarehouseList
    ) {
      // 사은품 자동 적용 여부
      $scope.auto_gift_new_yn = $rootScope.user_profile.auto_gift_new_yn || 'N';

      $scope.auto_gift = [
        { value: 'Y', label: '자동' },
        { value: 'N', label: '수동' },
      ];

      // Description 칸
      $scope.showDesc = localStorageService.get('giftListDescYn') || 'Y';

      /**
       * 설명 Div 접기 변경
       */
      $scope.changeShowDesc = () => {
        $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
        localStorageService.set('giftListDescYn', $scope.showDesc);
      };

      // 별칭정보 담기
      const seller_nick_info = [];

      const channelList = angular.copy($rootScope.use_channel_list);

      _.forEach(channelList, function (chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: 'all',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(useSystemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_cd: '',
          shop_id: '',
          use_yn: '' // 사용여부
        },
        searchData: {
          all_checked: false, // 전체선택 여부
          selectCount: 0,     // 선택 건수
          totalCount: 0,      // 검색전체 건수
          search_key_items: [ // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('규칙명'), value: 'a.gift_rule_name' },
            { label: gettextCatalog.getString('사은품명'), value: 'prod_name' },
            { label: gettextCatalog.getString('사은품 SKU'), value: 'sku_cd' },
          ],
          search_date_type: [
            { label: gettextCatalog.getString('규칙생성일'), value: 'wdate' },
            { label: gettextCatalog.getString('규칙수정일'), value: 'mdate' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd',
            item_list: _.unionBy(channelList, 'shop_cd'),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('계정 선택'),
            search_name: 'shop_id',
            item_list: channelList,
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function (option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          },
          {
            // 규칙금액
            title: gettextCatalog.getString('규칙금액 조건'),
            search_name: 's_sales',
            item_list: [{ text: '모든금액포함', value: 'all' }, { text: '일정금액이상', value: 'over' }, { text: '금액범위', value: 'between' }],
            item_key: 'text',
            item_value: 'value',
            select_value: ''
          },
          {
            // 규칙수량
            title: gettextCatalog.getString('규칙수량 조건'),
            search_name: 's_sale_cnt',
            item_list: [{ text: '사용안함', value: 'none' }, { text: '일정수량이상', value: 'over' }, { text: '수량범위', value: 'between' }],
            item_key: 'text',
            item_value: 'value',
            select_value: ''
          },
          {
            // 선택상품
            title: gettextCatalog.getString('선택상품 기준'),
            search_name: 'add_rule_type',
            item_list: [{ text: '사용안함', value: 'none' }, { text: '배송처', value: 'depot' }, { text: 'SKU코드', value: 'prod_depot' }, { text: '쇼핑몰 상품코드', value: 'shop_sale' }],
            item_key: 'text',
            item_value: 'value',
            select_value: ''
          },
          {
            // 사용여부
            title: gettextCatalog.getString('사용여부'),
            search_name: 'use_yn',
            item_list: [{ text: '사용', value: '1' }, { text: '미사용', value: '0' }],
            item_key: 'text',
            item_value: 'value',
            select_value: ''
          }
        ]
      };

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('사은품 규칙 신규 등록')}</span>`,
            small_label: '신규등록',
            test_id: 'btn-gift-add',
            btn_type: 'button',
            add_class: 'col-xs-12',
            perm_only: ['order.order+write', 'order.unstoring+write'],
            action: function () {
              $scope.showDetail();
            }
          }
        ],
        table_actions: [
          {
            label: gettextCatalog.getString('선택 삭제'),
            test_id: 'btn-gift-delete',
            add_class: 'btn-default',
            action: function () {
              $scope.delete();
            },
            perm_only: ['order.order+write', 'order.unstoring+write']
          },
        ]
      };

      $scope.searchFn = { // 데이터 테이블 관련기능 바인딩
        searchDo: function () {
          $scope.searchDo(true, true);
        },
        resetDo: function () {
          $scope.resetDo();
        },
        changeLen: function (count) {
          $scope.changeCount(count);
        }
      };

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['gift_no'],
        alignCenterColumns: ['widget', 'gift_no', 'sdate', 's_sales', 's_sale_cnt', 'add_rule_type', 'use_yn', 'dist_method', 'gift_used_cnt_limit', 'gift_used_cnt'],
        notSortingColumns: ['widget', 's_sales', 's_sale_cnt', 'prod_name'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: ['wdate', 'mdate'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/gift/list`,
          requestWillAction: function (data) {
            return Object.assign(data, $scope.searchForm);
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 60,
            template: function (row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(${row.gift_no})">상세</button>`;
            }
          },
          {
            key: 'gift_no',
            title: 'No',
            width: 60
          },
          {
            key: 'gift_rule_name',
            title: '사은품 규칙명',
            width: 200
          },
          {
            key: 'rule_type',
            title: '적용 쇼핑몰',
            width: 100,
            notCompile: true,
            template: function (row) {
              const directinput_ord_apply = row.directinput_ord_apply_yn ? '직접입력' : '';
              const shopList = (() => {
                switch (row.rule_type) {
                  case 'shop_cd': return row.gift_shop_name || '';
                  case 'shop_id': return row.gift_shop_id || '';
                }

                return '전체 쇼핑몰';
              })();

              return [shopList, directinput_ord_apply].filter(Boolean).join(', ');
            }
          },
          {
            key: 'edate',
            title: '기간',
            width: 200,
            notCompile: true,
            template: ({ sdate, edate, stime, etime }) => {
              const formatted_stime = giftModel.formatTime(stime), formatted_etime = giftModel.formatTime(etime);
              const formatted_time = (stime === '00:00:00' && etime === '23:59:59') ? '' : `/ ${formatted_stime} ~ ${formatted_etime}`;

              return `${sdate} ~ ${edate} ${formatted_time}`;
            }
          },
          {
            key: 's_sales',
            title: '금액범위',
            width: 170,
            notCompile: true,
            template: ({ s_sales, e_sales }) => {
              if (s_sales && e_sales == 99999999) {
                // 일정금액이상
                return `${$filter('currency')(s_sales, '', 0)} 원 이상`;
              } else if (s_sales && e_sales) {
                // 구간금액
                return `${$filter('currency')(s_sales, '', 0)} 원 ~ ${$filter('currency')(e_sales, '', 0)} 원`;
              }

              return '모든금액';
            }
          },
          {
            key: 's_sale_cnt',
            title: '수량범위',
            width: 100,
            notCompile: true,
            template: ({ s_sale_cnt, e_sale_cnt }) => {
              if (s_sale_cnt && e_sale_cnt == 60000) {
                // 일정수량이상
                return `${$filter('currency')(s_sale_cnt, '', 0)} 건 이상`;
              } else if (s_sale_cnt && e_sale_cnt) {
                // 구간수량
                return `${$filter('currency')(s_sale_cnt, '', 0)} 건 ~ ${$filter('currency')(e_sale_cnt, '', 0)} 건`;
              }

              return '-';
            }
          },
          {
            key: 'add_rule_type',
            title: '선택상품 규칙적용',
            width: 150,
            notCompile: true,
            template: function (row) {
              switch (row.add_rule_type) {
                case 'depot': return '배송처';
                case 'prod_depot': return 'SKU코드';
                case 'shop_sale': return '쇼핑몰 상품코드';
              }

              return '-';
            }
          },
          {
            key: 'dist_method',
            title: '분배방법',
            width: 80,
            template: function (row) {
              switch (row.dist_method) {
                case 'all':
                  return '지정';
                case 'rand':
                  return '랜덤';
                case 'firstcome':
                  return '소진시';
              }

              return '-';
            }
          },
          {
            key: 'gift_used_cnt_limit',
            title: '총 잔여수량',
            tooltip: '제한수량을 설정한 사은품의 총 잔여수량입니다.',
            width: 100,
            template: function (row) {
              return row.gift_used_cnt_limit !== '-' ? `<a ng-click="grid.appScope.openGiftUsedCntList('limit', ${row.gift_no})">${row.gift_used_cnt_limit}</a>` : row.gift_used_cnt_limit;
            }
          },
          {
            key: 'gift_used_cnt',
            title: '총 출고수량',
            width: 100,
            template: function (row) {
              return `<a ng-click="grid.appScope.openGiftUsedCntList('used', ${row.gift_no})">${row.gift_used_cnt}</a>`;
            }
          },
          {
            key: 'prod_name',
            title: '사은품 상품명(속성)',
            width: 300
          },
          {
            key: 'wdate',
            title: '규칙 생성일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'mdate',
            title: '규칙 수정일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'use_yn',
            width: 90,
            title: '사용여부',
            template: function (row) {
              row.use_yn = !!row.use_yn;

              return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
            }
          }
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function () {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';

        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세
       */
      $scope.showDetail = function (gift_no) {
        const resolve = {
          warehouseList: function () {
            return useWarehouseList;
          },
          giftList: function () {
            return !gift_no ? giftModel.list({}) : null;
          },
          data: { gift_no }
        };

        const modal = commonSVC.openModal('xxg', resolve, 'GiftDetailCtrl', 'views/order/shipment/modals/gift_detail.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo();
          }
        });
      };

      /**
       * 리스트에서 사용여부 변경시
       */
      $scope.changeDo = async function ({ gift_no, use_yn }) {
        try {
          await giftModel.save({ mode: 'edit', gift_no, use_yn, change_use_yn: true });
          commonSVC.showToaster('success', '', '사은품 사용여부 수정에 성공하였습니다.');
        } catch (error) {
          commonSVC.showToaster('error', '', '사은품 사용여부 수정에 실패하였습니다.');

          return false;
        }
      };

      /**
       * 사은품 규칙 삭제
       */
      $scope.delete = async function () {
        const selectList = $scope.grid.methods.selectedData();

        if (!selectList.length) {
          commonSVC.showMessage('선택된 사은품 규칙이 없습니다.');

          return false;
        }

        const modal = commonSVC.openModal('', { data: { selectList } }, 'GiftDeleteCtrl', 'views/order/shipment/modals/gift_delete.html');
        modal.result.then(function () {
          $scope.searchDo();
        });
      };

      /**
       * 규칙 적용방식(자동/수동) 수정
       */
      $scope.changeAuto = async() => {
        systemModel.set({ change_val: { auto_gift_new_yn: $scope.auto_gift_new_yn } }, (re) => {
          if (re === 'success') {
            commonSVC.showToaster('success', '', '사은품 규칙 적용방식 수정에 성공하였습니다.');
            $rootScope.user_profile.auto_gift_new_yn = $scope.auto_gift_new_yn;
          } else {
            commonSVC.showToaster('fail', '', '사은품 규칙 적용방식 수정에 실패하였습니다.');
          }
        });
      };

      // 사은품 규칙 잔여수량 / 출고수량 모달 열기
      $scope.openGiftUsedCntList = async function(type, giftNo) {
        const resolve = {
          data: {
            usedCntList: await shipmentModel.giftUsedCntList({ giftNo }),
            type
          }
        };

        commonSVC.openModal('lm', resolve, 'giftUsedCntListCtrl', 'views/order/shipment/modals/gift_used_cnt_list_modal.html');
      };
    });
