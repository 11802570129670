'use strict';

/**
 *  2017-12-13 ally
 *  원하는 키값에서 원하는 단어 찾아냄
 */
angular.module('gmpApp')
  .filter('search', function() {
    return function (list, query, fields) {

      if (!query) {
        return list;
      }

      query = query.toLowerCase().split(' ');

      if (!angular.isArray(fields)) {
        fields = [fields.toString()];
      }

      return list.filter(function (item) {
        return query.every(function (needle) {
          return fields.some(function (field) {
            let content = item[field] != null ? item[field] : '';

            if (!angular.isString(content)) {
              content = `${content}`;
            }

            return content.toLowerCase().indexOf(needle) > -1;
          });
        });
      });
    };
  });
