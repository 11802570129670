'use strict';

/**
 * Created by pada on 2017. 11. 1..
 * 1:1 문의하기 컨트롤러
 */

angular.module('gmpApp')
  .controller('QuestionInquiryCtrl', function ($scope, $rootScope, $uibModalInstance, $timeout, settings, userInfo, localStorageService, commonSVC, commonModel, questionModel, shopAccountModel, helpModel, cautionModel, data) {
    const USER_KEY = `${userInfo.user.sol_no}_${userInfo.user.m_no}`;
    const INQUIRY_KEY = `${USER_KEY}_inquiry_temp`;

    const placeholder = '문의 접수 시 아래 주요정보 기재 및 파일을 첨부해주시면 빠른 문제 파악 및 정확한 답변이 가능합니다.\n\n1. 운영체제: 예) 윈도우, 맥 등 \n2. 인터넷 사용 브라우저: 예) IE11, 크롬 \n3. 문의사항:\n* 오류 발생 시 캡처를 첨부해주세요.\n* 문의 처리 시 테스트 여부를 체크해주시면 좀 더 빠른 처리를 도와드릴 수 있습니다.';
    const joinPlaceholder = '문의 접수 시 아래 주요정보 기재 및 파일을 첨부해주시면 빠른 문제 파악 및 정확한 답변이 가능합니다.\n\n1. 솔루션 사용여부:\n2. 월 평균 쇼핑몰 상품등록 건수/ 주문수집 건수:\n3. 문의사항:';
    const reQuestionPlaceholder = '재문의는 이전문의와 관련된 내용을 작성해 주세요.\n재문의에서의 문의자 정보나, 문의 유형등의 정보는 변경 할 수 없습니다.';

    const maxFileLength = 5;

    // 1:1문의 모달창 열림 여부
    $rootScope.questionModalOpen = true;

    $scope.isReQuestion = !!data.user_info;

    $scope.contentPlaceholder = !$scope.isReQuestion ? placeholder : reQuestionPlaceholder;

    $scope.pre = data;
    $scope.shows = false;
    $scope.showData = '답변완료';
    $scope.filename_array = [];

    $scope.title_cnt = 0;
    $scope.comment_cnt = 0;
    $scope.fold = false;

    $scope.inquiry_data = {
      board_type: '',

      content: $scope.join_help,

      shop_select: 'all',
      del_shop_index: [],
      del_comment: '',

      file: [],
      file_array: []
    };

    // VIP+ 결제 상담신청 시 문의유형, 머리말 자동입력
    if (data.from === 'pay_request') {
      $scope.inquiry_data.board_type = '가입';
      $scope.inquiry_data.title = '[VIP+] 영업 신청';
    } else if (data.from === 'wakeup') {
      $scope.inquiry_data.board_type = '웨이크업';
    }

    let shopDataSet = new Set();

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '<p class="text-size-small text-grey-300"><span class="text-grey">파일 검색 또는 끌어다놓기</span><br />최대 <span class="text-blue-400">5개</span>, 개 당 용랑 <span class="text-blue-400">20MB</span> 까지 등록가능</p>';
      $scope.fileData = [];
    };

    const setTempData = () => {
      if ($scope.isReQuestion || (!$scope.inquiry_data.title && !$scope.inquiry_data.content && !$scope.inquiry_data.file_array.length)) {
        return;
      }

      localStorageService.set(INQUIRY_KEY, JSON.stringify($scope.inquiry_data));
    };

    const removeTempData = () => {
      if ($scope.isReQuestion) {
        return;
      }

      localStorageService.remove(INQUIRY_KEY);
    };

    $scope.onSubmit = function () {
      // 문의유형 필수로 선택하도록 수정 2018-10-29 rony
      if (_.isUndefined($scope.inquiry_data.board_type) || $scope.inquiry_data.board_type == '') {
        commonSVC.showMessage('문의유형을 선택하여 주십시오.');

        return false;
      }

      // 테스트동의 여부 검사.
      if (_.isUndefined($scope.inquiry_data.test_agree_yn)) {
        commonSVC.showMessage('테스트 동의여부를 체크해 주십시오.', '테스트여부 동의시 좀더 빠른 문제 파악 및 정확한 답변이 가능합니다.');

        return false;
      }

      if ($scope.inquiry_data.board_type === '주문파기확인서') {
        if (!$scope.inquiry_data.del_sdate || !$scope.inquiry_data.del_edate) {
          commonSVC.showToaster('error', '실패', '삭제 기간을 입력해주세요.');

          return false;
        }

        if ($scope.inquiry_data.shop_select === 'certain' && !$scope.inquiry_data.del_shop_index.length) {
          commonSVC.showMessage('쇼핑몰을 선택해주세요.');

          return false;
        }

        const shop_list = [];
        let shop_content = [];

        // 주문파기 선택 쇼핑몰 값 변경
        if ($scope.inquiry_data.shop_select === 'all') {
          shop_content = '전체 쇼핑몰';
        } else {
          _.map($scope.inquiry_data.del_shop_index, function(v) {
            shop_list.push({
              shop_cd: $scope.use_engines[v].shop_cd,
              shop_id: $scope.use_engines[v].shop_id,
            });
            shop_content.push(`${$scope.use_engines[v].shop_name}(${$scope.use_engines[v].shop_id})`);
          });
          shop_content = shop_content.join(', ');
        }
        $scope.inquiry_data.del_shop = shop_list;
        $scope.inquiry_data.content = `기간 : ${$scope.inquiry_data.del_sdate} ~ ${$scope.inquiry_data.del_edate}\n`
                                    + `쇼핑몰 : ${shop_content}\n`
                                    + `코멘트 : ${$scope.inquiry_data.del_comment}`;
      }

      if (!$scope.inquiry_data.content) {
        commonSVC.showMessage('문의내용을 입력해 주십시오.');

        return false;
      }

      // 백엔드에서 처리
      if (data.inquiry_data) {
        $scope.inquiry_data.excel_file = data.inquiry_data;
      }

      const pattern = new RegExp('^(?=.*[a-zA-Z0-9가-힣])');

      if (pattern.test($scope.inquiry_data.title) && $scope.inquiry_data.title != undefined) {
        return questionModel.frontInsertQuestion($scope.inquiry_data)
          .then(function (res) {
            if (res.data === 'success' || (res.data && res.data.result === 'success')) {
              removeTempData();

              commonSVC.showToaster('success', '성공', '등록에 성공하였습니다.');
              $uibModalInstance.close('success');
            } else {
              // 에러 result.statusText,result.data.error
              commonSVC.showToaster('error', '', '필수 값을 입력해야합니다.');

              return false;
            }
          })
          .catch(function (err) {
            commonSVC.showToaster('error', '', err.data.message || '문의 등록에 실패하였습니다.');

            return false;
          });
      } else {
        commonSVC.showToaster('error', '', '제목을 입력해야합니다.');

        return false;
      }
    };

    $scope.$watchCollection('inquiry_data.board_type', function() {
      if ($scope.isReQuestion) {
        return;
      }

      $scope.contentPlaceholder = $scope.inquiry_data.board_type === '가입' ? joinPlaceholder : placeholder;
    });

    // 모달 축소
    $scope.folding = function() {
      $scope.fold = !$scope.fold;

      const width = $scope.fold ? '300px' : '1000px';

      $('#modal-question').parent().parent().parent().css({ width: width });
    };

    const params = data;

    $rootScope.$on('questionFoldOpen', () => {
      if ($scope.fold) {
        $scope.folding();
      }
    });

    /**
     * 바이트 체크
     */
    $scope.byteCheck = function(str) {
      const str_byte = str.byte2();

      return str_byte;
    };

    async function init() {
      resetInput();

      cautionModel.getCaution(function(res) {
        $scope.newCautionCount = res.data.results;
      });

      $scope.use_engines = $rootScope.useChannelList({ site_action: 'SyncProdState' });

      if ($scope.isReQuestion) {
        const [
          { status, data: { results: [cs] } },
          reQuestions
        ] = await Promise.all([
          questionModel.frontCsDetail(params),
          questionModel.reQuestionList({ pa_cs_no: params.cs_no || 0 })
        ]);

        if (status === 200) {
          const convertFileString = fileStrings => (fileStrings || '').split('#').filter(fileString => fileString).map(fileString => JSON.parse(fileString));

          $scope.inquiry_data = {
            ...cs,
            pa_cs_no: cs.cs_no,

            board_type: questionModel.pastNameConversion(cs.board_type) || '가입',
            board_status: '접수대기',
            test_agree_yn: !!cs.test_agree_yn,

            title: `Re) ${cs.title}`,
            content: '',

            file_array: [],
            attach_file: convertFileString(cs.attach_file)
          };

          $scope.title_cnt = $scope.byteCheck($scope.inquiry_data.title);
        }

        // 이전문의 내역을 보여줌
        $scope.reQuestionsGroup = reQuestions.reduce((previous, current, index) => {
          const key = !index ? '이전문의' : moment(current.reply_time).format('YYYY-MM-DD');

          return {
            ...previous,
            [key]: [...(previous[key] || []), current]
          };
        }, {});

        // 이전문의는 미리 노출되어 있음
        $scope.reQuestionsCount = Object.values($scope.reQuestionsGroup).flat().length - 1;
      } else if (data.from === 'work') {
        $scope.inquiry_data.board_type = '';
        $scope.inquiry_data.title = data.title;
        $scope.inquiry_data.content = data.content;
      } else {
        const tempData = localStorageService.get(INQUIRY_KEY);

        if (tempData) {
          const confirm = await commonSVC.showConfirmCustom({
            title: '확인',
            text: '이전에 작성 중에 취소된 1:1 문의가 있습니다.\n이어서 작업하시겠습니까?',
            showCancelButton: true,
            confirmButtonText: '이어서 작성',
            cancelButtonText: '새로작성',
            animation: false,
          });

          if (confirm) {
            $scope.inquiry_data = JSON.parse(tempData);
            shopDataSet = new Set($scope.inquiry_data.del_shop_index);
          } else {
            removeTempData();
          }
        }
      }

      $scope.inquiry_data.board_status = '접수대기';
      $scope.inquiry_data.m_no = $rootScope.user_profile.m_no;
      $scope.inquiry_data.email = $rootScope.user_profile.email;
      $scope.inquiry_data.htel = $rootScope.user_profile.htel;
      $scope.inquiry_data.tel = $rootScope.user_profile.tel;
      $scope.inquiry_data.name = $rootScope.user_profile.user_name;

      /**
       * 2018-06-20 Daniel
       * 인기검색어 조회
       */
      helpModel.getPopWord()
        .then(function(res) {
          $scope.wordList = res.data.results;
        });

      // select2
      $scope.$watch('$viewContentLoaded', function() {
        $('.select-fixed-multiple').select2({
          minimumResultsForSearch: Infinity,
          width: '573',
          placeholder: '선택 또는 직접입력(엔터로 구분)',
          language: {
            noResults: function() {
              return '쇼핑몰 조회 결과가 없습니다.';
            }
          },
        });
        $('input.select2-search__field').css('width', 573);
      });

      if ($rootScope.helpModalOpen) {
        $timeout(() => { $rootScope.draggableModalZindex('question'); });
      }
      if ($rootScope.shopModalOpen) {
        $timeout(() => { $rootScope.draggableModalZindex('shop'); });
      }
    }

    $scope.fileSelect = async files => {
      const availFiles = files.slice(0, maxFileLength - $scope.inquiry_data.file_array.length);

      const { data: { results: tempFiles } } = await commonModel.tempUpload(availFiles);

      $scope.inquiry_data.file_array = $scope.inquiry_data.file_array.concat(tempFiles);

      $scope.$apply();
    };

    $scope.fileDeSelect = fileIndex => {
      $scope.inquiry_data.file_array = $scope.inquiry_data.file_array.filter((file, i) => i !== fileIndex);
    };

    $scope.subtractElement = function (value, size) {
      $(`#file${value}`).remove();
      _.some($scope.inquiry_data.file_array, function (v) {
        if (v.size == size) {
          const index = $scope.inquiry_data.file_array.indexOf(v);

          if (index > -1) {
            $scope.inquiry_data.file_array.splice(index, 1);
          }

          return true;
        }
      });
    };

    /**
     * 문의유형 선택
     */
    $scope.changeInquiryType = function () {
      if ($scope.inquiry_data.content === $scope.join_help || $scope.inquiry_data.content === $scope.else_help) {
        $scope.inquiry_data.content = $scope.inquiry_data.board_type === '가입' ? $scope.join_help : $scope.else_help;
      }

      // 문의 유형에 따른 테스트 동의여부 처리.
      switch ($scope.inquiry_data.board_type) {
        case '':
        case '주문파기확인서':
          $scope.viewTestAgree = false;
          break;
        case '주문':
          $scope.viewTestAgreeText = '- 문의하신 내용을 확인하기 위해 문의 내용을 바탕으로 주문수집, 전송, 동기화 등의 테스트를 진행할 수 있습니다.';
          $scope.viewTestAgree = true;
          break;
        case '문의':
          $scope.viewTestAgreeText = '- 문의하신 내용을 확인하기 위해 문의 내용을 바탕으로 문의수집, 전송 등의 테스트를 진행할 수 있습니다.';
          $scope.viewTestAgree = true;
          break;
        case '상품':
          $scope.viewTestAgreeText = '- 문의하신 내용을 확인하기 위해 문의 내용을 바탕으로 상품 및 SKU상품 등록, 전송, 수정, 동기화 등의 테스트를 진행할 수 있습니다.';
          $scope.viewTestAgree = true;
          break;
        default:
          $scope.viewTestAgree = true;
          $scope.viewTestAgreeText = '- 문의하신 내용을 확인하기 위해 문의내용을 바탕으로 테스트를 진행할 수 있습니다.';
          break;
      }
    };

    $scope.cate_shop_match = function(cate) {
      const cateFlag = _.filter($scope.use_shops, function(row) { return cate.shop_id == row.shop_id && cate.shop_cd == row.shop_cd; });

      if (cateFlag.length) {
        return true;
      }
      else { return false; }
    };

    $scope.handleShopChange = (index) => {
      if ($scope.inquiry_data.shop_select === 'all') {
        return;
      }

      const indexString = index.toString();

      if (!shopDataSet.has(indexString)) {
        shopDataSet.add(indexString);
      } else {
        shopDataSet.delete(indexString);
      }

      $scope.inquiry_data.del_shop_index = [...shopDataSet];
    };

    /**
     * 취소
     */
    $scope.cancel = function () {
      setTempData();

      $uibModalInstance.close();
    };

    // 3분마다 자동저장
    const tempInterval = setInterval(() => setTempData, (1000 * 60 * 3));

    $uibModalInstance.closed.then(() => {
      $rootScope.questionModalOpen = false;

      clearInterval(tempInterval);
    });

    init();

    // 주문파기확인서에서 사용하는 textArea에 대해 엔터 입력을 지원하지 않도록 처리함
    $(document).on('keypress', 'textarea.noEnter', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  });
