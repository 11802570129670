'use strict';

angular.module('gmpApp')
  .factory('warehouseModel', function (settings, commonSVC) {
    let _oldData = {};

    return {

      setOld: function (old) {
        _oldData = old;
      },

      getOld: function () {
        return _oldData;
      },

      /**
       * 신규 창고 등록
       */
      insert: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/add`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);//promise 로 되어있는거 콜백형식으로 변경
        });
      },

      /**
       * 배송처 복사
       * @param numbers
       */
      copy: function (warehouse, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/copy`;
        const params = { depot_list: warehouse };

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 창고 삭제
       * @param code
       */
      delete: function (numbers, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot`,
              params = { depot_no_list: numbers };

        commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 창고 단일 수정
       * @param number DB 번호
       * @param changeData 수정 데이터
       * @returns {*}
       */
      editOne: function (number, changeData) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/use-yn`,
              params = {
                depot_no: number,
                use_yn: changeData
              };

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 창고 정보 로드
       * @param number DB 번호
       */
      load: function (number, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/${number}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 창고 정보 수정
       * @param next
       */
      update: function (data) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot`;

        return commonSVC.sendUrl('PATCH', url, data);
      },

      /**
       * 창고 리스트
       * @param next
       */
      warehouseList: function () {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/list/all`;

        return commonSVC.sendUrl('POST', url, {});
      },

      /**
       * 창고 특정 리스트 조회
       * @param data 조회데이터 (use_yn)
       * @return supplierListPromise 전체리스트 promise 객체
       */
      ListAll: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/list/all`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * 기본 배송처 업데이트
       */
      setDefaultWareHouse: function (new_default_no) {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/default`;

        const data = {
          depot_no: new_default_no
        };

        return commonSVC.sendUrl('PATCH', url, data);
      },

      /**
       * 기본 배송처 정보 가져오기
       */
      getDefaultWareHouse: function () {
        const url = `${settings.pa20ApiUrl}/app/stock/depot/default`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * ESM Master ID 연동 중복계정 확인
       */
      checkEsmMasterId: function(id, pw, depot_no, type) {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/check-id`;
        const data = {
          id,
          pw,
          type,
          depot_no
        };

        return commonSVC.sendUrl('POST', url, data);
      }
    };
  });
