
'use strict';

/**
 * 2018-04-19 ally
 * Hmall
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A131-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, A131categorySVC, errorSVC, commonSVC, settings, siteInfo) {
    const svc = A131categorySVC;

    $scope.CateList = angular.copy(svc.category_list);
    $scope.CateData = angular.copy(svc.category_data);
    $scope.CtgArr = angular.copy(svc.CtgArr);
    $scope.DispList = angular.copy(svc.dispCtg_list);
    $scope.DispArr = angular.copy(svc.dispCtgArr);
    $scope.Cate = {};

    const selectData = { cate: [], disp: [] };
    const lastSubData = { cate: {}, disp: {} };

    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.detailData = addInfo;

      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;
      $scope.CateData.display_data = [ ...addInfo.display_data ] || [];

      if ($scope.CateData.category_names) {
        $timeout(() => {
          $scope.cateSelect(0, 'cate', addInfo.category_names);
        });
      }
    };

    init();

    // 초기화
    function init() {
      // 로딩중
      loading('0', false); // 표준카테고리 로딩중
      // loading('1', false); // 전시카테고리 로딩중

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(async (res) => {
          $scope.userInfo = res.data.results;

          const cate = await requestPA('cate');

          const data = cate.data.results[0].data;

          if (!data) {
            commonSVC.showMessage('로딩실패');
            loading('0', true); // 표준카테고리 로딩중
            // loading('1', true); // 전시카테고리 로딩중
          } else {
            $scope.CtgArr[0].result = data;
            loading('0', true); // 표준카테고리 로딩
          }

          lastSubData.CtgArr = [ ...data ];

          // const dispCate = await requestPA('dsip');

          // data = dispCate.data.results[0].data;

          // if (!data) {
          //   commonSVC.showMessage('로딩실패');
          //   loading('1', true); // 전시카테고리 로딩중

          //   return false;
          // } else {
          //   $scope.DispArr[0].result = data;
          //   $scope.DispArr[0].last = false;
          //   loading('1', true); // 전시카테고리 로딩
          // }

          // lastSubData.DispArr = [ ...data ];

          /**
           * 2018-03-09 roy
           * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
          */
          if ($scope.matchingData.shop_cate_no_add_info) {
            if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
              matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
            } else {
              matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
            }
          } else if (!$scope.matchingData.sol_cate_shop_add_info) {
            matchDetailDataLoad(angular.copy(A131categorySVC.category_data));
          } else {
            matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
          }

        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        });
    }

    // 카테고리 선택 이벤트
    $scope.cateSelect = async function (index, type, category_names) {
      const typ = type === 'cate' ? 'CateList' : 'DispList';
      const cateType = $scope[typ][index];      // 선택 카테고리 타입
      let cateCd = '';

      if (!$scope.CateData[cateType] && !index && $scope.CateData.category_code.split('|')[0]) {
        cateCd = $scope.CateData[cateType] = $scope.CateData.category_code.split('|')[0];
      } else {
        cateCd = $scope.CateData[cateType];
      }

      // 전시카테고리 인경우 항목별로 마지막 항목 여부가 다르므로 cateCds 값에 code|Y 등으로 선택된 항목의 마지막 여부를 받아와 처리하게 끔 변경 2018-09-28 rony
      // if (type === 'disp') {
      //   const cateCds = cateCd.split('|');

      //   cateCd = cateCds[0];
      //   $scope.DispArr[(parseInt(index) + 1)].last = cateCds[1] == 'Y';
      // }
      const loadingIdx = type === 'cate' ? '0' : '1';

      cateSetting(index, cateCd, type);

      // 마지막 분류이거나, 하위분류없을시
      if (!category_names && (cateCd === 'noSub' || !cateCd || (type === 'cate' && index == 3) || $scope.DispArr[(parseInt(index) + 1)].last)) {
        return false;
      }

      loading(loadingIdx, false); // 표준카테고리 로딩
      clearOpt(index, type);

      selectData[type][index] = cateCd;

      try {
        if (!category_names || category_names.split('>').length > 1) {
          await setCateSub(index, type, cateCd);
          loading(loadingIdx, true);
        }
      } catch (err) {
        commonSVC.showMessage('로딩실패');

        loading(loadingIdx, false); // 표준카테고리 로딩
      }

      if (category_names) {
        const categoryNames = category_names.split('>')
          .map((v) => v.trim());

        if (categoryNames.length > 1) {
          const idx = parseInt(index) + 1;
          const itemCsfCd = $scope.CtgArr[idx].result.find(object => object.name === categoryNames[1]).code;

          $scope.CateData[$scope.CateList[idx]] = itemCsfCd;
          await $scope.cateSelect(`${idx}`, 'cate', categoryNames.slice(1).join('>'));
        } else {
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();

            cateSetting(index, cateCd, type);
          }
          loading(loadingIdx, true);

          return;
        }
      }

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      delete $scope.Cate[key];
    };

    // 전시카테고리 설정
    // $scope.setDispCtgList = function () {
    //   if ($scope.CateData.display_data.length === 5) {
    //     commonSVC.showMessage('전시카테고리는 최대 5개까지 가능합니다.');

    //     return false;
    //   }
    //   if ($scope.CateData.display_data_temp.tlndYn !== 'Y' && $scope.CateData.display_data_temp.has_subdata === 'N') {
    //     commonSVC.showMessage('사용할 수 없는 카테고리입니다.');
    //   } else if ($scope.CateData.display_data_temp.tlndYn !== 'Y') {
    //     commonSVC.showMessage('최종단계까지 선택해주세요');
    //   } else if ($scope.CateData.display_data.find(display_data => display_data.sectId === $scope.CateData.display_data_temp.sectId && display_data.sectNmPath === $scope.CateData.display_data_temp.sectNmPath)) {
    //     commonSVC.showMessage('동일한 전시카테고리가 존재합니다.');
    //   } else {
    //     $scope.CateData.display_data.push($scope.CateData.display_data_temp);
    //   }
    // };

    // 전시카테고리 삭제
    // $scope.delDispCtgList = function (index) {
    //   $scope.CateData.display_data.splice(index, 1);
    // };

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', (event, param, check) => {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      const cateCd = [];
      let flag = false;

      for (let i = 0; i < 4; i++) {
        const v = $scope.CateList[i];

        if (!$scope.CateData[v] && $scope.CtgArr[i].result.length) {
          flag = true;

          break;
        }
      }

      if (flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      _.each($scope.CateList, function(v) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }

        if (!value) {
          return false;
        }

        cateCd.push(value);
      });

      $scope.Cate[cateCd.join('|')] = $scope.CateData.category_names;
      const cateKey = Object.keys($scope.Cate);

      if (cateKey.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      _.forEach($scope.CateData.display_data, function (data, idx) {
        $scope.CateData.detailData[`key_sectIdNm${idx}`] = data.sectNmPath;
        $scope.CateData.detailData[`key_sectIdCd${idx}`] = data.sectId;
      });

      detailCate();

      //category_names 초기화,  category_code 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      $scope.CateData.detailData.category_code = cateKey[0];
      $scope.CateData.detailData.display_data = [ ...$scope.CateData.display_data ];
      // 카테고리 리스트 저장
      $scope.CateData.detailData.Cate = $scope.Cate;

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'A131',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.detailData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);

            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    // 카테고리 이름
    function cateSetting(index, cateCd, type) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const id = type === 'cate' ? `#CateMenu_${i}` : `#DispMenu_${i}`;
        const name = $(id).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }

      if (type === 'cate') {
        $scope.CateData.category_names = nameArr.join(' > ');
        $scope.CateData.detailData = _.find(lastSubData.CtgArr, { code: cateCd });
      } else {
        $scope.CateData.display_data_temp = _.find(lastSubData.DispArr, { sectId: +cateCd });
      }
    }

    // 하위 카테고리 가져오기
    function setCateSub(index, type, cateCd) {
      index = index * 1;
      const param = type === 'cate' ? { code: selectData.cate[index], lvl: index + 2 } : { code: selectData.disp[index] };

      return requestPA(type, param)
        .then(function(res) {
          const typ = type === 'cate' ? 'CtgArr' : 'DispArr';
          const data = res.data.results[0].data;

          lastSubData[typ] = data;
          if (!data || data[0]?.tlndYn === 'Y') {
            $scope[typ][parseInt(index) + 1].result = data;
            $scope[typ][parseInt(index) + 1].last = false; // 하위카테고리 없을시 출력
            $scope[typ][(parseInt(index) + 2)].result = { noSub: '하위 카테고리가 없습니다' }; // 하위카테고리 없을시 출력
            $scope[typ][(parseInt(index) + 2)].last = true;// 하위카테고리 없을시 출력
          } else if (!data.length && typ === 'DispArr') {
            /**
             * data가 빈배열로 들어올때가 있음 그때는 사용할 수 없는 카테고리라고 alert 띄워줌
             */
            lastSubData[typ] = $scope.DispArr[parseInt(index)]?.result;
            lastSubData[typ].find(({ sectId }) => sectId === +cateCd).has_subdata = 'N';
            $scope[typ][(parseInt(index) + 1)].result = { noSub: '하위 카테고리가 없습니다' };
            $scope[typ][(parseInt(index) + 1)].last = true;
          } else {
            $scope[typ][(parseInt(index) + 1)].result = data;
            $scope[typ][(parseInt(index) + 1)].last = false;
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index, type) {
      const start = parseInt(index) + 1;
      const listType = type === 'cate' ? 'CateList' : 'DispList';

      for (let i = start; i < 4; i++) {
        const key = $scope[listType][i];
        const arr = type === 'cate' ? 'CtgArr' : 'DispArr';

        $scope[arr][i].result = {};
        $scope.CateData[key] = '';
      }
    }

    function detailCate() {
      let cnt = 1;

      // 카테고리 저장
      _.each($scope.Cate, function (v, k) {
        const cntType = cnt === 1 ? '' : cnt;

        $scope.CateData.detailData[`key_cate_name${cntType}`] = v;
        $scope.CateData.detailData[`key_cate_code${cntType}`] = k;

        cnt++;
      });
    }

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    /**
     * playapi function
     */
    function requestPA(type, addData) {
      // const domain = siteInfo.A131.domain;
      const action = type === 'cate' ? 'get_categories' : 'get_view_categories';
      const param = addData ? addData : { lvl: 1, code: '' };

      return commonModel.pa5(`/channel_accounts/{uuid}/etc/${action}/sync`, param, $scope.matchingData.site_code, $scope.matchingData.site_id);
    }

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

  });
