'use strict';

/**
 * 2017-07-27 chris
 * 온라인 상품등록 카테고리 매칭 모달
 */
angular.module('gmpApp')
  .controller('OnlineCategoryMatchCtrl', OnlineCategoryMatchCtrl);

function OnlineCategoryMatchCtrl ($scope, commonSVC, $uibModalInstance, data, $compile, $timeout, onlineProductSVC, siteInfo) {
  $scope.shop_cd = data.shop_cd;
  $scope.shop_id = data.shop_id;
  $scope.shop_name = siteInfo[data.shop_cd].name;
  $scope.save_ok = true;
  // checkbox가 ng-model에 binding되지 않아 object에 담아줌
  // https://stackoverflow.com/questions/18642371/checkbox-not-binding-to-scope-in-angularjs
  $scope.check = {
    set_all_shop: false
  };

  // 온라인 상품 상세페이지에는 카테고리 일괄적용 기능 노출안함
  if (data.path != 'ol_edit') {
    $scope.view_all_shop = true;
  } else {
    $scope.view_all_shop = false;
  }

  let esm = data.esm;

  const setEvent = {
    esm_opt_size: esm.esm_opt_size,
    esm_opt_color: esm.esm_opt_color
  };

  // const sol_cate_no = data.category_code; 사용안하는듯
  const cateEdit = data.cateEdit;       // 카테고리 수정한 것을 카테고리 관리에 반영할 것인지 여부

  if (!esm) { esm = { flag: false }; }

  /**
   * 채널에 맞는 카테고리 속성 페이지 출력
   */
  $timeout(function() {
    const origin_shop_cd = data.shop_cd;
    //열기 일 경우 쇼핑몰 매칭정보 html 새로 그리기
    const url = angular.element(`<div data-ng-include='"views/settings/category/shopDetail/${data.pa_shop_cd}.html"' class='row blue-bg border-bottom border-bottom-grey-100 p-20 shopListShow'></div>`);

    angular.element('#categorydiv').html(url);

    $compile(url)($scope);

    data.shop_cd = data.pa_shop_cd;

    $scope.matchingData = {
      isOnlineAdd: true,
      categoryId: data.category_code,        // 카테고리 저장이 1027 로 되어있으므로 원래 shop_cd 로 보냄.
      site_code: origin_shop_cd,
      site_id: data.shop_id,
      shop_cate_no_add_info: data.shop_cate_no_add_info
    };
  });

  // 저장
  $scope.ok = saveCategory;
  // 카테고리 저장후 콜백 & 모달 닫기
  $scope.returnAct = returnAct;
  // 취소
  $scope.close = close;

  // -------------------- FUNCTIONS -----------------------

  async function saveCategory () {

    // cateEdit 변수로 카테고리에 수정 반영할 것인치 확인
    if (data.path !== 'ol_edit') {
      const confirm = await commonSVC.showConfirmCustom({
        title: '선택한 솔루션 카테고리에도 변경한 카테고리 정보를 저장하시겠습니까?',
        text: '아니오 클릭 시, 해당 상품에만 변경한 정보를 반영하고 솔루션 카테고리에는 저장하지않습니다.',
        confirmButtonText: '예',
        cancelButtonText: '아니오'
      });

      setEvent.type = confirm ? '' : 'online';

      if (esm.flag) {
        $scope.$broadcast('shopInfoSave', setEvent);
      } else {
        $scope.$broadcast('shopInfoSave', setEvent, $scope.check);
      }
    } else {
      if (data.pa_shop_cd === 'A077') {
        commonSVC.showConfirmCustom({
          title: '경고',
          text: '기존 등록된 카테고리와 옵션 지원 내용이 다른 카테고리입니다.\n간편옵션을 사용하는 경우 기존에 상품정보에 입력하셨던 옵션이 초기화 됩니다.\n카테고리를 수정하시겠습니까?',
          confirmButtonText: '예',
          cancelButtonText: '아니오'
        }, function (confirm) {
          if (confirm === true) {
            setEvent.type = esm.flag ? 'online' : cateEdit == false ? 'online' : '';

            $scope.$broadcast('shopInfoSave', setEvent);
          }
        });

        return;
      }

      setEvent.type = esm.flag ? 'online' : cateEdit == false ? 'online' : '';

      $scope.$broadcast('shopInfoSave', setEvent);
    }
  }

  function returnAct (result) {
    result.pa_shop_cd = data.pa_shop_cd;
    result.set_all_shop = $scope.check.set_all_shop; // 등록 선택한 동일한 쇼핑몰에 일괄 적용
    result.setEvent = setEvent;
    $uibModalInstance.close(result);
  }

  function close () {
    // dismiss 로 처리될경우 창이 닫힐때 catch 로 빠지므로 close 로 변경 2018-11-06 rony
    $uibModalInstance.dismiss('cancel');
  }
}
