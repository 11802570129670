/**
 * 상품집계 트리
 */

'use strict';

angular.module('gmpApp')

  .directive('leftContentTree', function () {

    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentTree.html',
      link: function () {}
    };
  }

  );
