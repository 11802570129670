/**
 * 입금대기 상태의 결제건 청구서 출력 페이지
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayBillPrintCtrl', function ($scope, $uibModalInstance) {

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
