'use strict';

angular.module('gmpApp')
  .controller('CustomCalculateExcelTemplateCtrl', function ($scope, $uibModalInstance, data, userInfo, commonSVC, stateSVC, stateModel) {
    $scope.mode = data.mode;
    $scope.shop_name = data.shop_name;
    $scope.shop_id = data.shop_id;

    // 날짜 항목명 입력 시 select box 활성화, 항목명 공란 시 option value 초기값으로 롤백
    $scope.updateInputFocus = function(field) {
      if (!field) {
        return;
      }

      // [공통] 항목명 삭제 시 placeholder 제거, 입력 시 placeholder 원복
      $(`.${field.key} input`).attr('placeholder', field.userHeader.length ? '' : field.placeholder);

      // [날짜정보] 입력된 항목명 제거 시 세부설정 초기화
      field.isInputEmpty = !!(field.userHeader.length);
      if (!field.isInputEmpty) {
        field.dateForm = '';
      }
    };

    // 중복기준 개수 체크
    $scope.selectedCriteria = function (field) {
      const count = $scope.orderChunkFields
        .flatMap(chunk => chunk.filter(item => item.isCriteria))
        .length;

      if (count < 2 && !field.isCriteria) {
        commonSVC.showMessage('중복기준 안내', '데이터 중복 체크 기준은 2개 항목 이상 체크 되어야 합니다.');
        field.isCriteria = true;
      }

      field.required = field.isCriteria;
    };

    $scope.dateForms = stateSVC.dateFormat;

    const sett_uniq_criteria = data.sett_uniq_criteria.split('\n'), // 정산 중복기준
          sett_excel_field_cdn = data.sett_excel_field_cdn.split('\n'); // 엑셀 커스텀필드

    // 주문정보 엑셀 양식 필드
    const orderFields = angular.copy(stateSVC.customOrderExcelFields);
    // 매출 및 정산정보
    const salesFields = angular.copy(stateSVC.customSalesExcelFields);
    // 날짜정보
    const datesFields = angular.copy(stateSVC.customDatesExcelFields);
    // 부가정보
    const addFields = angular.copy(stateSVC.addDatesExcelFields);

    // 필드 두개로 나눈 배열 (화면 보여주는용)
    $scope.orderChunkFields = [];
    $scope.salesChunkFields = [];
    $scope.datesChunkFields = [];
    $scope.addChunkFields = [];

    function processFields(fields) {
      return new Promise((resolve) => {
        fields.forEach(async (field) => {
          const userField = sett_excel_field_cdn.find(e => e.split('=')[0] === field.key);
          field.isCriteria = field.enableCriteria && sett_uniq_criteria.includes(field.criteriaKey);
          field.required = field.required ?? field.isCriteria;
          field.userHeader = userField ? userField.split('=')[1] : [];

          // 날짜 포맷
          if (userField?.split('=')[3]) {
            const userFieldDateForm = userField.split('=')[3];
            field.dateForm = $scope.dateForms.includes(userFieldDateForm) && userFieldDateForm !== '직접입력' ? userFieldDateForm : '직접입력';
            field.manualForm = field.dateForm === '직접입력' ? userFieldDateForm : undefined;
          }
        });

        resolve();
      });
    }

    async function init () {
      await processFields(orderFields);
      await processFields(salesFields);
      await processFields(datesFields);
      await processFields(addFields);

      // 필드배열 두개로 분리
      $scope.salesChunkFields = [salesFields.slice(0, Math.ceil(salesFields.length / 2)), salesFields.slice(Math.ceil(salesFields.length / 2))];
      $scope.orderChunkFields = [orderFields.slice(0, Math.ceil(orderFields.length / 2)), orderFields.slice(Math.ceil(orderFields.length / 2))];
      $scope.addChunkFields = [addFields.slice(0, Math.ceil(addFields.length / 2)), addFields.slice(Math.ceil(addFields.length / 2))];

      // 필드 분리하지 않음(날짜정보)
      $scope.datesChunkFields = [datesFields];
    }

    init();

    /**
     * 양식&중복기준 저장
     */
    $scope.save = async () => {
      const allFields = [
        ...$scope.orderChunkFields[0],
        ...$scope.orderChunkFields[1],
        ...$scope.salesChunkFields[0],
        ...$scope.salesChunkFields[1],
        ...$scope.datesChunkFields[0],
        ...$scope.addChunkFields[0],
        ...$scope.addChunkFields[1]
      ];

      // 필수 입력 체크
      if (allFields.some(item => item.required && !item.userHeader.length)) {
        commonSVC.showConfirmHtml('필수입력 안내', `필수입력 항목은 업로드 엑셀 내 항목명을 필수로 입력해 주셔야 합니다.<br><span class="text-warning">(${allFields
          .filter(item => item.required && !item.userHeader.length)
          .map(item => item.header)
          .join(', ')})</span>`); // 미입력 된 필수항목명 표기

        return false;
      }

      if (isRequiredFieldEmpty(allFields)) {
        commonSVC.showConfirmHtml('필수입력 안내', `필수입력 항목은 업로드 엑셀 내 항목명을 필수로 입력해 주셔야 합니다.<br><span class="text-warning">(${allFields.filter(item => item.required && !item.userHeader.length).map(item => item.header).join(', ')})</span>`);

        return false;
      }

      if (isDateInfoIncomplete(allFields)) {
        commonSVC.showConfirmHtml('필수입력 안내', `날짜정보 항목명 입력 시 세부설정은 필수로 선택해 주셔야 합니다.<br><span class="text-warning">(${allFields.filter(item => ['sett_com_date', 'sett_plan_date', 'refund_date', 'pay_confirm_date', 'pay_time'].includes(item.key) && item.userHeader.length && !item.dateForm || (item.dateForm === '직접입력' && !item.manualForm)).map(item => item.header).join(', ')})</span>`);

        return false;
      }

      if ($scope.mode !== 'add' && orderFields.filter(({ isCriteria }) => isCriteria).map(({ criteriaKey }) => criteriaKey).join('\n') !== data.uniq_criteria) {
        await commonSVC.showMessage('', '중복기준을 변경하였습니다.\n엑셀 업로드 시 데이터가 중복으로 업로드될 수 있으니 업로드 후 확인해 주세요.');
      }

      function isRequiredFieldEmpty(fields) {
        return fields.some(item => item.required && !item.userHeader.length);
      }

      function isDateInfoIncomplete(fields) {
        return fields.some(item => ['sett_com_date', 'sett_plan_date', 'refund_date', 'pay_confirm_date', 'pay_time'].includes(item.key) && item.userHeader.length && !item.dateForm || (item.dateForm === '직접입력' && !item.manualForm));
      }

      try {
        const params = {
          shop_cd: data.shop_cd,
          sett_uniq_criteria: orderFields.filter(field => field.enableCriteria && field.isCriteria).map(({ criteriaKey }) => criteriaKey).join('\n'),
          sett_excel_field_cdn: allFields.map(field => {
            return `${field.key}=${field.userHeader}=${field.header}${field.dateForm?.length && field.dateForm !== '직접입력' ? `=${field.dateForm}` : field.dateForm === '직접입력' ? `=${field.manualForm}` : ''}`;
          }).join('\n'),
        };
        const userInfoShops = userInfo.user.manualSettShops.filter(({ shop_cd }) => shop_cd === data.shop_cd);

        await stateModel.uploadSettExcelTemplate(params);

        // userInfo에 있는 반자동 쇼핑몰 데이터 업데이트
        for (const shop of userInfoShops) {
          shop.sett_uniq_criteria = params.sett_uniq_criteria;
          shop.sett_excel_field_cdn = params.sett_excel_field_cdn;
        }

        commonSVC.showToaster('success', '성공', `엑셀 양식 ${$scope.mode === 'add' ? '추가' : '수정'}에 성공했습니다.`);

        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', `엑셀 양식 ${$scope.mode === 'add' ? '추가' : '수정'}에 실패했습니다.`);
      }
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
