/**
 *  ptgui-decimal 컴퍼넌트
 *  2016-07-08 최은서
 */

(function () {
  'use strict';

  /**
   * < Attributes >
   *   - ptgui-decimal : required
   *   - ng-model : required
   *   - type : "text" (type을 "number"로 쓰면 안됩니다.)
   *   - min : 최소값 (기본 0)
   *   - max : 최대값 (기본 없음)
   *   - precision : 소수점 자릿수 (기본 2)
   *   - inputchk : 소수점 자릿수 입력 자체를 막을 것인지 여부 (기본 true) [true:입력막음 / false:validation으로 막음]
   */

  var DEFAULT_PRECISION = 2;
  var NUMBER_REGEXP = /^\s*(\d+|(\d*(\.\d*)))\s*$/;

  angular.module('ptgui.decimal', [])
    .directive('ptguiDecimal', ["$parse", function ($parse) {

      function pre(scope, el, attrs, ngModelCtrl) {
        // angular 자체적으로 validation 체크 시 빈값일 경우
        // undefined 처리되어 아래와 같이 처리.
        // ngModelCtrl.$$setOptions({
        //   allowInvalid: true,
        //   updateOnDefault: true
        // });
        ngModelCtrl.$options.$$options.allowInvalid = true;
        ngModelCtrl.$options.$$options.updateOnDefault = true;
      }

      function link(scope, el, attrs, ngModelCtrl) {
        var minVal, maxVal, precision, lastValidViewValue;
        var isDefined = angular.isDefined;
        var isUndefined = angular.isUndefined;
        var isNumber = angular.isNumber;

        /**
         * Returns a rounded number in the precision setup by the directive
         * @param  {Number} num Number to be rounded
         * @return {Number}     Rounded number
         */
        function round(num) {
          var d = Math.pow(10, precision);
          return Math.round(num * d) / d;
        }

        /**
         * Returns a string that represents the rounded number
         * @param  {Number} value Number to be rounded
         * @return {String}       The string representation
         */
        function formatPrecision(value) {
          return parseFloat(value).toFixed(precision);
        }

        function isPrecisionValid() {
          return !isNaN(precision) && precision > -1;
        }

        function isValueValid(value) {
          return angular.isNumber(value) && !isNaN(value);
        }

        function changeViewValue(value) {
          ngModelCtrl.$viewValue = value;
          ngModelCtrl.$commitViewValue();
          ngModelCtrl.$render();
        }

        // precision 지정한 경우 해당 값으로 처리.
        if (isDefined(attrs.precision)) {
          attrs.$observe('precision', function (value) {
            precision = parseInt(value, 10);
          });
        } else {
          precision = DEFAULT_PRECISION;
        }


        // 입력 시 검사
        ngModelCtrl.$parsers.push(function (value) {
          if (ngModelCtrl.$isEmpty(value)) {
            lastValidViewValue = value;
            return "";
          }

          // 소수점 자릿수 검사
          if (!isDefined(attrs.inputchk) || attrs.inputchk == "true") {
            var cipher = 0;
            var arr_value = value.toString().split(".");

            if (arr_value.length > 1) {
              cipher = arr_value[1].length;
            }

            if (cipher > precision) {
              changeViewValue(lastValidViewValue);
              return lastValidViewValue;
            }
          }

          if (NUMBER_REGEXP.test(value)) {
            lastValidViewValue = value;
            return value;
          } else {
            // 처음 값이 없는 경우 undefined -> "" 로 변경해서 리턴
            if (lastValidViewValue == undefined) lastValidViewValue = "";

            // Render the last valid input in the field
            changeViewValue(lastValidViewValue);
            return lastValidViewValue;
          }
        });


        // precision validation
        if (isDefined(attrs.inputchk) && attrs.inputchk == "false") {
          ngModelCtrl.$validators.precision = function (value) {
            var cipher = 0;

            if (!ngModelCtrl.$isEmpty(value)) {
              var arr_value = value.toString().split(".");

              if (arr_value.length > 1) {
                cipher = arr_value[1].length;
              }
            }

            return ngModelCtrl.$isEmpty(value) || cipher <= precision;
          };
        }


        // pattern validation
        ngModelCtrl.$validators.pattern = function (value) {
          return ngModelCtrl.$isEmpty(value) || (value.toString().indexOf('.') !== 0 && value.toString().indexOf('.') !== value.toString().length - 1);
        };


        // Min validation
        ngModelCtrl.$validators.min = function (value) {
          return ngModelCtrl.$isEmpty(value) || isUndefined(minVal) || value >= minVal;
        };
        if (isDefined(attrs.min) || attrs.ngMin) {
          attrs.$observe('min', function (val) {
            if (isDefined(val) && !isNumber(val)) {
              val = parseFloat(val, 10);
            }
            minVal = isNumber(val) && !isNaN(val) ? val : undefined;
            ngModelCtrl.$validate();
          });
        } else {
          minVal = 0;
        }


        // Max validation
        if (isDefined(attrs.max) || attrs.ngMax) {
          ngModelCtrl.$validators.max = function (value) {
            return ngModelCtrl.$isEmpty(value) || isUndefined(maxVal) || value <= maxVal;
          };

          attrs.$observe('max', function (val) {
            if (isDefined(val) && !isNumber(val)) {
              val = parseFloat(val, 10);
            }
            maxVal = isNumber(val) && !isNaN(val) ? val : undefined;
            ngModelCtrl.$validate();
          });
        }

        ngModelCtrl.$formatters.push(function (value) {
          if (isDefined(value)) {
            return isPrecisionValid() && isValueValid(value) ?
              formatPrecision(value) : value;
          } else {
            return '';
          }
        });

        // Auto-format precision on blur
        el.bind('blur', function () {
          ngModelCtrl.$commitViewValue();
        });
      }

      return {
        restrict: 'A',
        require: 'ngModel',
        compile: function(element){
          return {
            pre: pre,
            post: link
          }
        }
      };
    }]);

})();