'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B691-Ctrl', function ($scope, $timeout, $rootScope, $q, $sce, userInfo, siteInfo, shopAccountModel, onlineProductModel, categoryModel, commonModel, commonSVC, gettextCatalog, settings, onlineProdSVC, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = _.cloneDeep(data.data.detailType);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 브랜드 데이터
    $scope.brandData = {
      searchCd: '',
      searchList: [],
      selectVal: ''
    };

    // 관련상품 검색 데이터
    $scope.relationData = {
      type: 'goodsNm',
      word: ''
    };

    $scope.prodListEdit = false;
    $scope.searchWordType = angular.copy(onlineProdSVC.B691.searchWordType);

    const limit = 10;

    const shop_data = $scope.shop_data;

    // 브랜드 검색 이벤트
    $scope.setBrandList = function () {
      const brand_Cd = $scope.brandData.searchCd;

      requestPA('etc.get_brand', { cateCd: brand_Cd })
        .then(function (res) {
          const data = res.data.data !== null ? res.data.data.items : [];

          $scope.brandData.searchList = data;
        });
    };

    // 브랜드 선택 이벤트
    $scope.setBrand = function() {
      if ($scope.brandData.selectVal) {
        const data = $scope.brandData.selectVal.split('||');

        $scope.row.set_info.key_brand_Cd = data[0];
        $scope.row.set_info.key_brand_Nm = data[1];
      }
    };

    // 브랜드 삭제 이벤트
    $scope.clearBrand = function() {
      $scope.row.set_info.key_brand_Cd = '';
      $scope.row.set_info.key_brand_Nm = '';
    };

    // 관련상품 검색
    $scope.searchProd = function () {
      requestPA('etc.get_relation', { type: $scope.relationData.type, word: $scope.relationData.word })
        .then(function (res) {
          initProd(res.data.data.items);
        });
    };

    // 관련상품 체크박스 선택시 저장
    $scope.selectRelation = function($event, no) {
      if ($($event.target).is(':checked')) {
        $scope.row.set_info.relationGoodsNos.push(no);
      } else {
        const idx = $scope.row.set_info.relationGoodsNos.indexOf(no);

        $scope.row.set_info.relationGoodsNos.splice(idx, 1);
      }

      $scope.row.set_info.relationGoodsNo = $scope.row.set_info.relationGoodsNos.join('||');
    };

    // 관련상품 리스트 세팅
    $scope.setRelProd = function(page) {
      $scope.page = page;
      const purePage = page - 1;

      const start = purePage * limit;
      const end = start + limit;

      $scope.relationProd = _.slice($scope.relationProdList, start, end);
    };

    $scope.prodListEdit = function() {
      $scope.detailType = 'add';
      $scope.row.set_info.relationGoodsNos = $scope.row.set_info.relationGoodsNo.split('||');
    };

    // 관련상품 초기화
    function initProd(items) {
      $scope.row.set_info.relationGoodsNos = [];
      $scope.pageArr = [];

      $scope.relationProdList = items;
      if ($scope.detailType === 'edit') {
        $scope.selectProdList = _.filter($scope.relationProdList, function (o) {
          return $scope.row.set_info.relationGoodsNo.indexOf(o.goodsNo) >= 0;
        });
      }
      setPage();
      $scope.setRelProd(1);
    }

    // 관련상품 페이지 세팅
    function setPage() {
      const len = Math.ceil($scope.relationProdList.length / limit);

      for (let i = 1; i <= len; i++) {
        $scope.pageArr.push(i);
      }
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo,
            domain = siteInfo.B691.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    function init() {
      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = shop_data.shop_cd;
      const shop_id = shop_data.shop_id;
      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 콜백함수를 프로미스로 넘김
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;
          if ($scope.row.set_info.key_relationFl) {
            $scope.searchProd();
          }
        });
    }

    init();
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
