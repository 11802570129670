'use strict';

/**
 *  중복 값 제거
 *  17-03-07 ally
 */
angular.module('gmpApp')
  .filter('unique', function() {
    return function(collection, keyname) {
      const output = [],
            keys = [];

      angular.forEach(collection, function(item) {
        if (item[keyname]) {
          const key = item[keyname];

          // keys 배열에 해당 key 값이 존재하지 않을 때만 출력해준다
          if (keys.indexOf(key) === -1) {
            keys.push(key);
            output.push(item);
          }
        } else {
          output.push(item);
        }

      });

      return output;
    };
  });