/**
 * 수집 템플릿 신규 템플릿으로 추가
 */
'use strict';

angular.module('gmpApp')
  .controller('ScrapTemplateCopyCtrl', function ($scope, $uibModalInstance, commonSVC, data, templateModel) {
    const { shop_cd, shop_id, shop_name, registed_template_info, token, channel_account_id, ol_shop_no } = data;

    $scope.viewShopName = `${shop_name}(${shop_id})`;
    $scope.templateData = {
      name: '',
      shop_cd,
      shop_id,
      template_data: registed_template_info,
      token,
      channel_account_id,
      ol_shop_no
    };

    /**
     * 등록
     */
    $scope.onSubmit = async () => {
      if (!$scope.templateData.name.trim()) {
        commonSVC.showMessage('템플릿명을 입력해주십시오.');

        return false;
      }

      if ($scope.scrapTemplateCopyForm.$valid) {
        const result = await templateModel.insert($scope.templateData);

        if (result.data?.result === 'success') {
          commonSVC.showToaster('success', '', '템플릿 등록 성공');
          $uibModalInstance.close({
            result: 'success',
            template_no: result.data.number,
            template_name: $scope.templateData.name
          });
        } else {
          commonSVC.showToaster('error', '', '템플릿 등록 실패');
        }
      }
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
