'use strict';
/**
 * 메세지 서비스
 *
 * 저장되는 메세지 서비스로 메세지 발송 요청
 * 채팅 메세지 전달 등.
 *
 * 2017-01-13 : Matthew
 */
angular.module('gmpApp')
  .factory('MessageSVC', function (commonSVC, settings, $rootScope, userInfo, gettext) {

    /**
     * 번역 대상 멘트 지정
     */
    gettext('작업완료');

    // 화면에 바인딩 되어야 할 변수들은 $rootScope 에 셋팅 해서 어디서든 쓸수 있게 함
    $rootScope.pusher = {
      messages: [], // 메세지 목록 데이터,
      get_messages_where: [], // 가져올 메세지 조건
      get_messages_limit: 30, // 한번에 가져올 메세지 최대 갯수
      unread_cnt: 0, // 읽지 않은 메세지 갯수
      members: null, // 푸셔 현재 접속자 목록 바인딩
      // 2020-03-05 Boris 메모 알림배지용 변수
      new_memos: [], // 신규/처리완료 된 메모
      new_memos_cnt: 0, // 새로운 메모 갯수
      unread_localOrd_cnt: 0, // 신규 주문 수집 갯수(국내)
      unread_globalOrd_cnt: 0, // 신규 주문 수집 갯수(해외)
    };

    // 서비스 리턴
    const __master = {

      // 현재 알림창 리스트가 열려있는지 여부
      isTimeLineState: function() {
        return $('#messages_dropdown').attr('aria-expanded') == 'true';
      },

      // 메세지 카운트 새로고침
      refreshMessages: function() {
        let unread_cnt = 0;

        // 메세지 카운트만 조회하게 수정
        // 과거 메세지 받아오기 2017-01-19 MatthewKim
        return __master.getMessageCnt($rootScope.pusher.get_messages_where, $rootScope.pusher.get_messages_limit || 30)
          .then(function(re) {
            if (re.status == 200 && re.data.length > 0) {
              unread_cnt += re.data.length > 0 ? re.data[0].unread_cnt : 0;
            }

            return true;
          })
          .then(function() {
            // 쇼핑몰 공지사항 불러옴
            return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/today`);
          })
          .then(function(re) {
            let noti_unread_cnt = 0;

            if (re.status == 200 && re.data.results.length > 0) {
              _.each(re.data.results, function(v) {
                if (!localStorage.getItem(`notice_read${v.noti_no}`)) {
                  noti_unread_cnt++;
                }
              });
            }

            // 타임라인 창이 열려있으면 받자마자 메세지 읽기 완료 처리
            if (__master.isTimeLineState()) {
              __master.readAllMessages(true);
            } else {
              $rootScope.pusher.unread_cnt = unread_cnt + noti_unread_cnt;
            }
          });
      },

      // 신규 메모 카운트 새로고침
      refreshNewMemos: function() {
        return __master.getNewMemosCnt()
          .then(function(re) {
            if (re.status == 200 && re.data.results.length > 0) {
              $rootScope.pusher.new_memos_cnt = re.data.results.length > 0 ? re.data.results[0].cnt : 0;
            }
          });
      },

      /**
       * 메세지 리스트 조회
       */
      getMessageList: function() {
        let messages = [];

        // 메세지 불러옴
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/msgs`, {})
          .then(function(re) {
            if (re.status == 200 && re.data.results.length) {
              _.each(re.data.results, function(msg) {
                messages.push(msg);
              });
            }

            return true;
          })
          .then(function() {
            // 쇼핑몰 공지사항 불러옴
            return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/today`);
          })
          .then(function(re) {
            if (re.status == 200 && re.data.results.length > 0) {
              _.each(re.data.results, function(v) {
                messages.push(v);
              });
            }

            // 등록일로 정렬
            messages = _.sortBy(messages, [function(o) {
              return o.wdate;
            }]).reverse();

            return messages;
          });
      },

      /**
       * 메세지 리스트 조회
       */
      getNewMemoList: function() {
        // 메세지 불러옴
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/memos/pre`, {})
          .then(function(re) {
            if (re.status == 200) {
              return re.data.results || [];
            }
          });
      },

      // 메세지 전송
      addMessage: function (to_id, title, data_json) {
        const data = {};

        data.to_id = to_id || '__ALL__';
        data.title = title;
        data.data = data_json;

        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/msgs`, data);
      },

      // 메세지 목록 수신
      getMessageCnt: function (where, limit) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/msgs/count`, {
          where,
          limit: limit || $rootScope.pusher.get_messages_limit
        });
      },

      // 신규 메모 갯수 수신
      getNewMemosCnt: function () {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/memos/pre/count`, {});
      },

      // 메세지 읽기 처리
      readMessages: function (msg_numbers) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/msgs`, {
          numbers: msg_numbers.filter(o => o)
        });
      },

      // 모든 메세지 읽기 완료 처리
      readAllMessages: function(noRefresh) {
        if ($rootScope.pusher.messages.length > 0) {
          return __master.readMessages($rootScope.pusher.messages.map(function(d) {
            return d.msg_no;
          }))
            .then(function() {
              if (!noRefresh) {
                __master.refreshMessages();
              }

              return true;
            });
        }
      },

      // 모든 신규 메모 읽기 완료 처리
      readAllNewMemos: function() {
        // 신규 메모 미리보기는 m_no 단위로 본 사람의 DB를 전부 지워주면 되서 반복문 없이 일괄처리로 구현
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/memos/pre`, {});
      },

    };

    return __master;
  });
