
/**
 * 2018-01-24  yellow_id SMS
 * Daniel
 */
'use strict';

angular.module('gmpApp')
  .controller('ConfigsSms', function ($rootScope, $scope, $uibModalInstance, commonSVC, userModel, settings, data, userInfo, gettextCatalog, shipmentModel, securityModel, $timeout, $state, commonModel) {
    //let sDataCnt = data.rows.length;
    //let tDataCnt = data.totalCount;
    let sendType;// = sDataCnt > 0; // true: 선택주문, false: 전체주문
    const sendDatas = []; // 발송데이터
    let lastRange;

    $scope.isAs = data.isAs;

    $scope.auto_use_yn = false; // sms 자동발송 설정여부
    $scope.from_tel = []; // SMS 발신번호 리스트

    // LMS인지 여부
    $scope.isLMS = {
      general: false, // 일반발송
      reserve: false // 예약발송
    };

    // 발송설정 데이터
    $scope.data = {
      range: sendType ? 'select' : 'all',   // 발송 범위
      receiveType: 'order_htel',              // 수신자 타입
      receive: '',                          // 수신자
      content: '',                          // 내용
      number: '',                           // 발송 번호
      ref: ''
    };

    if ($scope.isAs) {
      $scope.data.receiveType = 'req_htel';
    }

    // 예약발송 데이터
    $scope.reserData = {
      content: '',                          // 내용
      receiveType: '1',              // 수신자
      ref: '',
      from_tel: ''
    };

    if ($scope.isAs) {
      $scope.refData = {
        req_name: '[요청자명]',
        to_name: '[수령자명]',
        to_addr: '[배송주소]',
      };

      $scope.reserData.asState = '1';
    } else {
      $scope.refData = {
        'order_name': '[주문자명]',
        'to_name': '[수령자명]',
        'to_addr': '[배송주소]',
        'shop_sale_name': '[상품명]',
        'shop_name': '[쇼핑몰명]',
        'seller_nick': '[쇼핑몰별칭]',
        'invoice_no': '[운송장번호]',
        'carr_name': '[택배사명]',
        'carr_name | invoice_no': '[택배사명 | 운송장번호]'
      };

      $scope.reserData.orderState = '1';
    }

    // sms 데이터
    $scope.smsData = {
      m_cnt: 0,         // 차감예정 개수
      h_cnt: 0          // 충전 개수
    };

    $scope.numChange = function(type) {
      $scope.data.number = userInfo.user[type].replace(/-/gi, '');
    };

    $scope.changeSelectRow = function(rows) {
      sendDatas.splice(0);

      // sms 차감 카운트 계산용 배열 생성 및 sendData 세팅
      rows.forEach((row) => {
        // to_addr에 공백을 붙인 이유
        // - 백엔드에서 실제 sms 발송할 때는 주문조회 프로시저 호출 후 to_addr값을 불러서 사용
        // - 프론트에서는 to_addr1만 기존에 치환해서 글자수 체크
        // - ord_s프로시저에서 to_addr 필드는 to_addr1필드와 공백을 더해주고 조회시키는 것 확인
        // - 백엔드에서도 글자수 체크 다시돌린 후 실제 카운트 차감하는데 이 때 프론트와 한 글자(공백)이 차이나서
        // - 1건예정으로 보였지만 실제 2건 차감돼서 해당 처리 추가
        const pushRow = {
          to_htel: row.to_htel,
          to_name: row.to_name,
          to_addr: row.to_addr1 + ' ',
          uniq: row.uniq
        };

        if (!$scope.isAs) {
          pushRow.shop_sale_name = row.shop_sale_name;
          pushRow.order_htel = row.order_htel;
          pushRow.uniq = row.uniq;
          pushRow.order_name = row.order_name;
        } else {
          pushRow.mend_no = row.mend_no;
          pushRow.req_htel = row.req_htel;
          pushRow.req_name = row.req_name;
          pushRow.as_sale_name = row.as_sale_name;
        }

        sendDatas.push(pushRow);
      });
    };

    $scope.templateSelect = function() {
      const modal = commonSVC.openModal('lg', {}, 'ConfigsSmsTemplate', 'views/alimtalk/modals/sms_template.html');

      modal.result.then(function(re) {
        const msg = JSON.parse(re.template_data.replace(/\n/g, '\\n')).content;

        if ($scope.tabActive === 0) {
          $scope.data.content = msg;
        } else {
          $scope.reserData.content = msg;
        }
        $scope.mCntChange();
      });
    };

    /**
     * 2018-03-15 Daniel
     * 참조값 적용
     */
    function setReference(content, row) {
      _.each($scope.refData, function (v, k) {
        content = content.replace(new RegExp(v.replace(/\[/, '\\[').replace(/\]/, '\\]'), 'gi'), row[k]);
      });

      return content;
    }

    /**
     * LMS여부 체크
     * (90byte초과시 LMS로 변경)
     */
    $scope.checkLMS = () => {
      const type = $scope.tabActive === 0 ? 'general' : 'reserve';

      if (type === 'general') {
        $scope.isLMS.general = $scope.data.content.byte2() > 90;
      } else {
        $scope.isLMS.reserve = $scope.reserData.content.byte2() > 90;
      }
    };

    /**
     * 차감예정 SMS 건수 카운트
     */
    $scope.mCntChange = function() {
      if ($scope.data.range === 'select') {
        if (data.rows <= 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택한 건 수가 없습니다.'));
          $scope.data.range = lastRange;

          return;
        }

        $scope.changeSelectRow(data.rows);
      } else if ($scope.data.range === 'all') {
        $scope.changeSelectRow(data.totalRows);
      }

      lastRange = $scope.data.range;

      // LMS여부 체크
      $scope.checkLMS();

      let smsList = [...sendDatas];

      // 참조값 적용
      smsList.forEach(data => {
        data.contentRef = setReference($scope.data.content, data);
      });

      // 수신자 선택
      if ($scope.data.receiveType === 'ot_htel') {
        // 주문자번호, 수신자번호 각각을 배열로 뽑아서 합침
        smsList = smsList.map(({ order_htel, contentRef }) => ({ order_htel, contentRef }))
          .concat(smsList.map(({ to_htel, contentRef }) => ({ to_htel, contentRef })));
      } else if ($scope.data.receiveType === 'rt_htel') {
        smsList = smsList.map(({ to_htel, contentRef }) => ({ to_htel, contentRef }))
          .concat(smsList.map(({ req_htel, contentRef }) => ({ req_htel, contentRef })));
      }

      if (['select', 'all'].includes($scope.data.range)) {
        const uniqSms = _.uniqBy(smsList, sms => {
          let key = '';

          if ($scope.data.receiveType === 'ot_htel') {
            key = sms.order_htel || sms.to_htel;
          } else if ($scope.data.receiveType === 'rt_htel') {
            key = sms.to_htel || sms.req_htel;
          } else {
            key = sms[$scope.data.receiveType];
          }

          return `${key}/${sms.contentRef}`;
        });

        $scope.smsData.m_cnt = uniqSms.reduce((acc, cur) => {
          return acc + (cur.contentRef.byte2() > 90 ? 2 : 1); // LMS면 2건 차감
        }, 0);
      } else {
        $scope.smsData.m_cnt = $scope.data.receive ? _.uniqBy($scope.data.receive.split('\n')).length * ($scope.isLMS.general ? 2 : 1) : 0;
      }
    };

    $scope.refChange = function(type) {
      $scope[type].content = $scope[type].content + (_.isUndefined($scope.refData[$scope[type].ref]) ? '' : $scope.refData[$scope[type].ref]);
      $scope[type].ref = '';

      $scope.mCntChange();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    $scope.onSubmit = async () => {
      let url, param;
      let vdata;

      if ($scope.data.content.byte2() > 2000 || $scope.reserData.content.byte2() > 2000) {
        commonSVC.showMessage('SMS 내용은 2000byte를 초과할 수 없습니다.');

        return false;
      }

      let checkOrder = false;
      let type = '';

      if ($scope.data.range) {
        switch ($scope.data.receiveType) {
          case 'to_htel':
          case 'order_htel':
            type = $scope.data.receiveType === 'to_htel' ? '수령자' : '주문자';
            checkOrder = sendDatas.some(ord => !ord[$scope.data.receiveType]);
            break;
          case 'ot_htel':
            type = '주문자 / 수령자';
            checkOrder = sendDatas.some(ord => !ord.to_htel || !ord.order_htel);
            break;
        }

        if (checkOrder) {
          commonSVC.showMessage(`${type} 핸드폰 번호가 존재하지 않는 주문이 존재합니다.`);

          return false;
        }
      }

      switch ($scope.tabActive) {
        // 일반발송
        case 0:
          if (!$scope.data.content || !$scope.data.number) {
            commonSVC.showMessage('전부 입력해주세요');

            return false;
          }

          if ($scope.smsData.m_cnt === 0) {
            commonSVC.showMessage('발송할 메세지가 없습니다');

            return false;
          }

          if ($scope.smsData.m_cnt > $scope.smsData.h_cnt) {
            commonSVC.showMessage('발송건수가 보유건수를 초과했습니다');

            return false;
          }

          vdata = $scope.data;
          vdata.refData = $scope.refData;

          if ($scope.isAs) {
            url = vdata.range ? `${settings.pa20ApiUrl}/app/as/send-sms` : `${settings.pa20ApiUrl}/app/as/send-sms-receive`;
          } else {
            url = vdata.range ? `${settings.pa20ApiUrl}/app/order/sendSms` : `${settings.pa20ApiUrl}/app/order/sendSmsReceive`;
          }

          param = {
            options: vdata,
            searchForm: data.searchForm,
            uniqs: sendDatas.map(({ uniq, mend_no }) => $scope.isAs ? mend_no : uniq)
          };

          break;

        // 예약발송
        case 1:
          if (!$scope.reserData.content) {
            commonSVC.showMessage('전부 입력해주세요');

            return false;
          }

          if ($scope.isAs) {
            return false; // isAs 는 일반만 지원함
          } else {
            url = `${settings.pa20ApiUrl}/app/order/sms/auto-send`;
          }

          param = $scope.reserData;

          break;
      }

      // 일반발송이고 전체주문 선택인경우 경고 팝업창 한번 더 출력해줌 2019-01-21 rony
      if ($scope.tabActive === 0 && $scope.data.range === 'all') {
        let showMsg = '현재 검색된 모든 주문자에게 문자가 발송됩니다.';

        switch ($scope.data.receiveType) {
          case 'ot_htel':
            showMsg = '현재 검색된 모든 주문자 / 수령자 에게 문자가 발송됩니다.';
            break;
          case 'to_htel':
            showMsg = '현재 검색된 모든 수령자 에게 문자가 발송됩니다.';
            break;
        }

        const confirm = await commonSVC.showConfirm(showMsg, '그대로 진행하시겠습니까?');

        if (confirm) {
          return sendSMS(url, param);
        }
      } else {
        return sendSMS(url, param);
      }
    };

    /**
     * 문자전송
     */
    async function sendSMS(url, param) {
      if (data.page) {
        param.page = data.page;
      }

      try {
        const re = await commonSVC.sendUrl('POST', url, param);

        if (re.status === 200) {
          const msg = !$scope.tabActive
            ? 'SMS 발송 요청에 성공하였습니다.\n결제관리 > 부가서비스 사용내역에서 상태 동기화 후 결과를 확인하실 수 있습니다.'
            : '설정에 성공하였습니다.';

          commonSVC.showToaster('success', '성공', msg);

          $uibModalInstance.dismiss();
        } else {
          commonSVC.showToaster('error', '실패', (!$scope.tabActive ? 'SMS 발송 요청에 실패하였습니다.\n1:1 문의 부탁드립니다.' : '설정에 실패하였습니다.'));
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', (!$scope.tabActive ? 'SMS 발송 요청에 실패하였습니다.\n1:1 문의 부탁드립니다.' : '설정에 실패하였습니다.'));
      }
    }

    async function init() {
      const param = { type: 'sms' };
      // init후에 getSmsAuthList가 실행되는 경우가 있어 수정
      // SMS 발신번호 리스트 조회
      try {
        const result = await securityModel.getSmsAuthList();
        const smsList = result.data?.result;

        $scope.from_tel = smsList
          .filter(sms => sms.auth_status.split('_')[1] === '인증완료')
          .map(row => row.from_tel);

        // 등록된 SMS 발신번호가 없으면 등록 후 사용하라고 안내
        if (!$scope.from_tel.length) {
          commonSVC.showMessage(gettextCatalog.getString('SMS 발신번호 사전등록 후 이용해주시기 바랍니다'), '', function() {
            $rootScope.$emit('$closeTab', 'tab_configs_admin');
            $timeout(function() {
              $state.go('main.configs_admin', { flag: 'sms' });
            }, 100);
          });

          $scope.cancel();
        }

        // 발신번호 리스트 중 첫번째 데이터로 발신번호 기본 설정
        $scope.data.number = $scope.from_tel[0];
      } catch (err) {
        commonSVC.showToaster('error', 'SMS 발신번호 리스트 조회에 실패하였습니다.');
      }

      // 선택한 rows 주문자번호, 수신자번호 '-' 제거
      data.rows.forEach((data) => {
        data.to_htel = String(data.to_htel).replace(/-/g, '');

        if ($scope.isAs) {
          data.req_htel = String(data.req_htel).replace(/-/g, '');
        } else {
          data.order_htel = String(data.order_htel).replace(/-/g, '');
        }
      });

      // 총 rows 주문자번호, 수신자번호 '-' 제거
      data.totalRows.forEach((data) => {
        data.to_htel = String(data.to_htel).replace(/-/g, '');
        data.order_htel = String(data.order_htel).replace(/-/g, '');
      });

      // 전역변수에 값 업데이트
      sendType = data.rows.length > 0; // true: 선택주문, false: 전체주문
      $scope.data.range = sendType ? 'select' : 'all';
      lastRange = $scope.data.range;

      if ($scope.data.range === 'select') {
        $scope.changeSelectRow(data.rows);
      } else if ($scope.data.range === 'all') {
        $scope.changeSelectRow(data.totalRows);
      }

      // 차감될 SMS카운트 건수 계산
      $scope.smsData.m_cnt = sendType ? _.uniqBy(sendDatas, 'order_htel').length : 0;

      commonModel.getServiceCnt()
        .then((res) => {
          $scope.smsData.h_cnt = res.data.smsCnt?.remain < 0 ? 0 : res.data.smsCnt?.remain;

          if ($scope.isAs) {
            return true;
          } else {
            return userModel.getOrderSmsAutoSetting(param);
          }

        })
        .then((res) => {

          if ($scope.isAs) {
            return true;
          } else {
            const data = res.data.result;

            if (data.length > 0) {
              $scope.auto_use_yn = data[0].auto_use_yn;
              $scope.autoContent = data[0].auto_content;
              $scope.reserData = JSON.parse(data[0].auto_content.replace(/\n/g, '\\n'));
              $scope.isLMS.reserve = $scope.reserData.content.byte2() > 90;
              $scope.reserData.from_tel = $scope.from_tel.find(tel => tel === $scope.reserData.from_tel) || $scope.from_tel[0];
            }
          }
        });

      calcAllCnt();
    }
    $scope.tabClick = (tab) => {
      $scope.tabActive = tab;
      if (tab === 1 && $scope.autoContent) {
        try {
          JSON.parse($scope.autoContent.replace(/\n/g, '\\n'));
        } catch (err) {
          commonSVC.showMessageHtml('SMS 내용 조회 실패', 'SMS 내용에 입력 불가한 문자가 포함되어 불러오기에 실패되었습니다.<br/>입력 불가한 문자는 제거하신 후 이용해 주시기 바랍니다.<br/>입력불가한 문자: <code><b>이모지</b></code>');
        }
      }
    };
    init();
    /**
     * sms 자동발송 사용여부 설정
     */
    $scope.autoUse = () => {
      const url = `${settings.pa20ApiUrl}/app/order/sms/auto-send-yn`;
      const param = {
        type: 'sms',
        autoUseYn: $scope.auto_use_yn
      };

      commonSVC.sendUrl('PATCH', url, param, (state, result) => {
        if (state == 'success' || result.results === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('설정에 성공하였습니다.'));
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('설정에 실패하였습니다.'));
          $scope.auto_use_yn = !$scope.auto_use_yn;
        }
      });
    };

    /**
     * sms 차감 예정 카운트 계산 (전체주문)
     */
    function calcAllCnt() {
      const params = Object.assign({}, data.searchForm);

      params.length = 1000000; // 전체주문일시 100만건까지 조회

      $scope.mCntChange();
    }
  });
