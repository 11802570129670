'use strict';

angular.module('gmpApp')
  .controller('MasAddcontentRuleCtrl', function ($scope, $uibModalInstance, commonSVC, onlineProductSVC, onlineProductModel, data) {
    // 타입
    $scope.addcontentTypes = onlineProductSVC.addcontentTypes;

    let actionType = 'addcontentAdd';
    $scope.detailType = '등록';

    // 적용 쇼핑몰 셀렉트 데이터
    $scope.shop = {
      shopName: '',
      shopId: ''
    };

    // 등록데이터
    $scope.data = {
      target: '상세설명',
      type: '',
      name: '',
      headerContent: '',
      footerContent: '',
    };

    $scope.onSubmit = function () {
      if ($scope.form1.$valid) {
        const data = $scope.data;
        let applyTargetType;

        if (!data.type) {
          commonSVC.showMessage('템플릿 저장 실패', '템플릿 구분은 필수값입니다.');

          return false;
        }

        if (!data.name) {
          commonSVC.showMessage('템플릿 저장 실패', '템플릿명은 필수값입니다.');

          return false;
        }

        if (!data.headerContent && !data.footerContent) {
          commonSVC.showMessage('템플릿 저장 실패', '머리말 혹은 꼬리말을 입력해주세요.');

          return false;
        }

        sendData(false, applyTargetType);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };

    function sendData(editYn, applyTargetType) {
      $scope.data.applyTargetType = applyTargetType;
      $scope.data.editYn = editYn;
      $scope.data.mas_yn = 1;

      onlineProductModel[actionType]($scope.data)
        .then(function (res) {
          if (res.status === 200) {
            commonSVC.showToaster('success', '성공', `${$scope.detailType}에 성공했습니다.`);
            $uibModalInstance.close($scope.data);
          } else {
            commonSVC.showToaster('error', '실패', `${$scope.detailType}에 실패했습니다.`);
          }
        })
        .catch(function () {
          commonSVC.showToaster('error', '실패', `${$scope.detailType}에 실패했습니다.`);
        });
    }

    function init () {
      if (data.addcontentNo) {
        actionType = 'addcontentEdit';
        $scope.detailType = '수정';
        $scope.data.no = data.addcontentNo;

        const params = {
          no: data.addcontentNo
        };

        onlineProductModel.addcontentDetail(params)
          .then(function (res) {
            const data = res.data;

            $scope.data.applyYn = data.aply_yn;
            $scope.data.type = data.addcontent_type;
            $scope.data.name = data.addcontent_name;
            $scope.data.headerContent = data.header_content;
            $scope.data.footerContent = data.footer_content;
            $scope.data.sub_off_avail_yn = data.sub_off_avail_yn;
          });
      }
    }

    init();
  });
