'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A131-Ctrl', function ($scope, $rootScope, $timeout, commonModel, commonSVC, shopAccountModel, onlineProductModel, categoryModel, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.openTemplate = _.cloneDeep(data.openTemplate);
    let userInfo = {};

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      const set_info = $scope.row.set_info;

      if (set_info.key_childItemYn !== 'N') {
        if (!set_info.key_safeCertClasGbCd || (set_info.key_safeCertTypeGbcd === '03' && !set_info.key_safeCertNo) || !set_info.key_safeCertTypeGbcd) {
          commonSVC.showMessage('안전인증유형을 확인하세요');

          return false;
        }
      }

      if (set_info.key_sel_Info === 'Y' && !set_info.key_safeCertLawGbcd) {
        commonSVC.showMessage('안전인증법을 확인하세요.');

        return false;
      }

      if ($scope.row.set_info.brndCd && $scope.row.set_info.key_brndCd != $scope.row.set_info.brndCd) {
        $scope.row.set_info.key_brndCd = $scope.row.set_info.brndCd;
      }

      if ($scope.row.set_info.mkcoCd && $scope.row.set_info.key_mkcoCd != $scope.row.set_info.mkcoCd) {
        $scope.row.set_info.key_mkcoCd = $scope.row.set_info.mkcoCd;
      }

      if ($scope.row.set_info.key_prmrOrgCnryCd) {
        const matchCnry = $scope.add_data.cnryList.find(({ octyCnryCd }) => octyCnryCd === $scope.row.set_info.key_prmrOrgCnryCd);
        $scope.row.set_info.key_prmrOrgCnryNm = matchCnry.octyCnryNm;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.row.sol_cate_shop_add_info = typeof $scope.row.sol_cate_shop_add_info === 'string' ? JSON.parse($scope.row.sol_cate_shop_add_info) : $scope.row.sol_cate_shop_add_info;

    $scope.add_data = {};
    init();
    getCnry();

    // 이미지 업로드
    $scope.uploadOptFile = function(file, index) {
      const files = [file];

      // # S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function(res) {
          if (index === 1) {
            $scope.row.set_info.key_safeCertImgNm = res.data.results[0].url;
          } else {
            $scope.row.set_info.key_safeCertImgNm2 = res.data.results[0].url;
          }
        });
    };

    //추가구매옵션들 가져오기
    $scope.getAddData = function(action, v, etc1, etc2) {
      // API PARAMETER
      const param = { type: $scope.row.set_info[`key_find_${v}_type`], keyword: $scope.row.set_info[`key_find_${v}_keyword`] };

      if (etc1 || etc2) {
        param[etc1] = $scope.row.set_info[etc1];
        param[etc2] = $scope.row.set_info[etc2];
      }

      return commonModel.pa5(`/channel_accounts/{uuid}/etc/${action}/sync`, param, data.shop_data.shop_cd, data.shop_data.shop_id)
        .then(res => {
          if (res.status === 200) {
            $scope.add_data[`${v}List`] = res.data.results[0].data;
          } else {
            commonSVC.showMessage('추가항목 조회 실패');
          }
        })
        .catch(() => {
          commonSVC.showMessage('추가항목 조회 실패');
        });
    };

    $scope.resetAddData = function(v) {
      $scope.row.set_info[`key_${v}Cd`] = '';
      $scope.row.set_info[`key_${v}Nm`] = '';
    };

    //브랜드, 제조사 셋팅
    $scope.setBrandMakerData = function(key_name, key_code, data_code, list) {
      $scope.row.set_info[`key_${key_name}`] = _.find($scope.add_data[list], function(d) {
        return d[data_code] === $scope.row.set_info[key_code];
      })[key_name];
    };

    //제조국, 주원료 원산지 세팅
    $scope.setOriginData = function(key_name, key_code, data_name, data_code, list) {
      $scope.row.set_info[`key_${key_name}`] = _.find($scope.add_data[list], function(d) {
        return d[data_code] === $scope.row.set_info[`key_${key_code}`];
      })[data_name];
    };

    // 카테고리정보를  set_info에 넣어줌
    function init() {
      $scope.row.set_info.brndCd = '';
      $scope.row.set_info.mkcoCd = '';

      $scope.row.set_info.key_find_brnd_keyword = '';
      $scope.row.set_info.key_find_mkco_keyword = '';

      userInfo = _.find($rootScope.use_channel_list, function (o) {
        return o.shop_cd == $scope.row.shop_cd && o.shop_id == $scope.row.shop_id;
      });

      if ($scope.row.sol_cate_shop_add_info) {
        setInitData();
      } else {
        //사이트 계정별 카테고리 조회
        const categoryParams = {
          shop_cds: [ $scope.row.shop_cd ],
          shop_ids: [ $scope.row.shop_id ],
          sol_cate_no: $scope.data.sol_cate_no
        };

        categoryModel.shopCategorysLoad(categoryParams, function(state, data) {
          if (state === 'success') {
            $scope.row.sol_cate_shop_add_info = data[0]?.sol_cate_shop_add_info;
            setInitData();
          } else {
            commonSVC.showMessage('카테고리 기본정보 조회 실패');
          }
        });
      }
    }

    //필요한 데이터 넣어주기
    function setInitData() {
      //일반 카테고리
      $scope.row.set_info.key_cate_code = $scope.row.sol_cate_shop_add_info?.key_cateCd;
      $scope.row.set_info.key_cate_name = $scope.row.sol_cate_shop_add_info?.key_cateNm;
      $scope.row.set_info.key_childItemYn = $scope.row.set_info.key_childItemYn || $scope.row.sol_cate_shop_add_info?.childItemYn;
      $scope.row.set_info.key_frdlvYn = $scope.row.sol_cate_shop_add_info?.frdlvYn;

      //전시카테고리
      for (let i = 0; i < 5; i++) {
        $scope.row.set_info[`key_sectIdCd${i}`] = $scope.row.sol_cate_shop_add_info?.[`key_sectIdCd${i}`] || '';
        $scope.row.set_info[`key_sectIdNm${i}`] = $scope.row.sol_cate_shop_add_info?.[`key_sectIdNm${i}`] || '';
      }

      // 주원료, 화장품 카테고리 추가항목 여부
      $scope.foodYn = ($scope.row.sol_cate_shop_add_info?.etc?.foodYn === 'Y') || ($scope.data.shop_cate_no_add_info?.etc?.foodYn === 'Y');
      $scope.mnfcSellCoMndrYn = ($scope.row.sol_cate_shop_add_info?.mnfcSellCoMndrYn === 'Y') || ($scope.data.shop_cate_no_add_info?.mnfcSellCoMndrYn === 'Y');
    }

    //국가 가져오기
    function getCnry() {
      userInfo.pa_shop_cd = $scope.row.shop_cd;

      return commonModel.pa5('/channel_accounts/{uuid}/etc/get_country/sync', {}, data.shop_data.shop_cd, data.shop_data.shop_id)
        .then(res => {
          if (res.status === 200) {
            $scope.add_data.cnryList = res.data.results[0].data;
          } else {
            commonSVC.showMessage('추가항목 조회 실패');
          }
        })
        .catch(() => {
          commonSVC.showMessage('추가항목 조회 실패');
        });
    }
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
