'use strict';

angular.module('gmpApp')
  .controller('wakeUpCtrl', function (
    $rootScope, $scope, $window, $sce, $uibModalInstance, $state,
    localStorageService, tokenSVC, commonSVC,
    userModel, userInfo, detectBrowser, data, settings) {
    let wakeUpToken = '';

    // 로컬여부
    $scope.isLocal = window.location.host.includes('localhost');

    const init = async () => {
      wakeUpToken = tokenSVC.getWakeUpToken();

      // 웨이크업용 토큰 ALL타입으로 새로 발급
      if (!wakeUpToken || data.type === 'ALL') {
        const result = await userModel.getWakeupToken({
          email: userInfo.user.email,
          solType: userInfo.user.aff_no === 999 ? 'COUPANG' : (userInfo.user.aff_no === 998 ? 'WEMAKEPRICE' : (userInfo.user.aff_no === 998 ? 'ST11' : 'ALWAYZ')),
          ...detectBrowser
        });

        wakeUpToken = result.data.token;
        tokenSVC.setWakeUpToken(wakeUpToken);
      }

      // 웨이크업 모달 페이지 url
      let url = `${settings.wakeupUrl}/token-login?token=${wakeUpToken}`;

      // 웨이크업 작업번호
      if (localStorageService.get('wakeUpJobId')) {
        url += `&jobId=${localStorageService.get('wakeUpJobId')}`;
        // 1회성이므로 한번 사용 후 로컬스토리지에서 제거
        localStorageService.remove('wakeUpJobId');
      }

      // 웨이크업에서 선택한 11번가 출고지/반품지 정보 전달 (웨이크업 요청)
      if (localStorageService.get('wakeUp11stInfo')) {
        const { sendAddressSeq, returnAddressSeq } = localStorageService.get('wakeUp11stInfo');

        url += `&returnAddressSeq=${returnAddressSeq}&sendAddressSeq=${sendAddressSeq}`;
        localStorageService.remove('wakeUp11stInfo');
      }

      // 셀러타입 추가 (쿠팡용)
      if (localStorageService.get('wakeUpSellerType')) {
        const sellerType = localStorageService.get('wakeUpSellerType');

        url += `&sellerType=${sellerType}`;
        localStorageService.remove('wakeUpSellerType');
      }

      if (data.isFirstLogin) {
        url += '&isFirstLogin=true';
      }

      $scope.wakeUpUrl = $sce.trustAsResourceUrl(url.replace(/&amp;/g, '&'));
    };

    init();

    /**
     * 테스트용 이벤트 생성
     * (웨이크업 화면에서 이벤트 발생시키는데 로컬에서는 못받아와서 버튼 추가)
     */
    $scope.test = type => {
      if (type === 'shops') {
        $window.postMessage({ shops: [{ shop_cd: 'A112', shop_id: 'playauto' }, { shop_cd: 'B719', shop_id: 'wmpp53070' }] }, 'http://localhost:9000');
      } else if (type === 'question') {
        $window.postMessage({ action: 'question' }, 'http://localhost:9000');
      } else if (type === 'pay') {
        $window.postMessage({ action: 'pay' }, 'http://localhost:9000');
      } else if (type === 'close') {
        $window.postMessage({ action: 'close' }, 'http://localhost:9000');
      }
    };

    async function wakeupListener(event) {
      if (event.data?.shops) {
        // 작업 등록 완료 모달 확인버튼 클릭시(웨이크업 화면) 마이크로페이지 랜덤 오픈
        // const eventPage = ['discountEvent', 'quickPlayEvent'];
        // $rootScope.openTabEventPage(eventPage[Math.floor(Math.random() * 2)]);

        // A안으로 고정
        $rootScope.openTabEventPage('discountEvent');

        $scope.close();
      } else if (event.data?.action) {
        $scope.close();
        if (event.data?.action === 'question') {
          // 1:1 문의페이지로 이동 & 문의하기 모달 오픈
          $state.go('main.question_list', { openQuestionModal: true });
          $state.reload();
        } else if (event.data?.action === 'pay') {
          // 결제관리로 이동
          $state.go('main.pay_list');
        }
      }
    }

    // 이벤트 리스너 등록
    $window.addEventListener('message', wakeupListener);

    /**
     * 창 닫기
     */
    $scope.close = () => {
      // 이벤트 리스너 제거
      $window.removeEventListener('message', wakeupListener);

      $rootScope.startWakeUpYN = false;
      localStorageService.remove('startWakeUpYN');
      $uibModalInstance.dismiss('cancel');
    };
  });
