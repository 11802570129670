'use strict';

// ak몰
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A017-Ctrl', function ($scope, $rootScope, $compile, $timeout, errorSVC, categoryModel, templateModel, $stateParams, A017categorySVC,
    siteInfo, commonSVC, commonModel) {
    $scope.sel_category = [];
    $scope.cate = [];
    $scope.totalCate = []; // 전체 카테고리
    $scope.CateList = angular.copy(A017categorySVC.category_list);
    $scope.CtgArr = angular.copy(A017categorySVC.CtgArr);
    $scope.notice_class = angular.copy(A017categorySVC.notice_class);
    $scope.CateData = angular.copy(A017categorySVC.category_data);
    $scope.dispCtg_list = angular.copy(A017categorySVC.dispCtg_list);
    $scope.dispCtgArr = angular.copy(A017categorySVC.dispCtgArr);
    $scope.dispCtg = angular.copy(A017categorySVC.dispCtg);
    $scope.MD = angular.copy(A017categorySVC.MD);
    $scope.category_code = '';
    $scope.CatOption = [{}, {}, {}, {}];
    $scope.DispCatOption = [{}, {}, {}, {}, {}];
    $scope.max_depth = 5;
    $scope.disp_cate = [];
    $scope.sel_disp_category = ['', '', '', '', ''];

    for (let i = 0; i < $scope.max_depth; i += 1) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
      $scope.totalCate.push([]);
    }

    let save_flag = false;    //카테고리 선택여부

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);
        if (!re.data.has_error) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    //전시카테고리 선택 여부
    $scope.pa5_job_data = {
      get_categories: {
        load: false,
        data: []
      },
    };
    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = { ...shop_cate_info };
      // 카테고리 불러오기(위에 이름)
      $scope.category_names = $scope.CateData.detailData.category_names || '';
      $scope.category_code = $scope.CateData.detailData.category_code || '';

      await setCategory($scope.category_code);

      // 전시카테고리 불러오기 (아래리스트)
      let dispCtgIdx = 0;
      let dispTags = '';
      let value, name;
      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (!$scope.CateData.detailData[`key_dispCtg_Nm${dispCtgIdx}`]) {
          break;
        }

        value = $scope.CateData.detailData[`key_dispCtg_Cd${dispCtgIdx}`];
        name = $scope.CateData.detailData[`key_dispCtg_Nm${dispCtgIdx}`];
        delete $scope.CateData.detailData[`key_dispCtg_Cd${dispCtgIdx}`];
        delete $scope.CateData.detailData[`key_dispCtg_Nm${dispCtgIdx}`];

        dispCtgSelectList.push(value);

        dispTags += `<li class="list-group-item" data-dispCtgCd="${value}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`;
        dispCtgIdx++;
      }
      $('#dispCtgList').append(dispTags);
      $compile(dispTags)($scope);
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];
      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }
      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;
      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }
        await new Promise(resolve => {
          $timeout(() => {
            $scope.totalCate[idx + 1].map((childCate) => {
              if (childCate.parent_code === cate.shop_cate_see_cd) {
                $scope.cate[idx + 1].push(childCate);
              }
            });
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });
        $scope.setMd(cate, idx + 1);

        return false;
      }
    };

    // 전시카테고리 세팅
    $scope.disSelect = async (idx) => {
      const cate = $scope.sel_disp_category[idx];
      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_disp_category[i].shop_cate_see_name);
      }
      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.disp_cate[i] = [];
          $scope.sel_disp_category[i] = '';
        }

        $scope.CateData.detailData.display_categories = [];
        await new Promise(resolve => {
          $timeout(() => {
            $scope.totalCate[idx + 1].map((childCate) => {
              if (childCate.parent_code === cate.shop_cate_see_cd) {
                $scope.disp_cate[idx + 1].push(childCate);
              }
            });
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.disp_cate[i] = [];
          $scope.sel_disp_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    $scope.removeSelectedDispCate = (index) => {
      $scope.savedDispCateList.splice(index, 1);
    };

    const setCategory = async (code) => {
      code = code.split('_');
      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';
        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, '일반') : false;
        idx++;
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      let category_code = '';

      for (let i = 0; i < $scope.max_depth; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;
      //전시카테고리
      $scope.CateData.detailData.display_categories = $scope.dispCtg.dispCtgSelectList;
      // 표준분류용 카테고리
      $scope.CateData.detailData.key_CateCd = _.last(category_code.split('_'));

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };
        DispCtgList(param.detailData);

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    $scope.changeMainCate = (mainDispCate) => {
      $scope.mainDispCate = mainDispCate;
      $scope.savedDispCateList.forEach(cate => {
        cate.is_represent = cate.code === mainDispCate;
      });
    };

    /**
     * 전시카테고리 선택이벤트
     */
    $scope.setDispCtgList = () => {
      const sel_disp_cate = Object.values($scope.sel_disp_category).filter(Boolean).pop().shop_cate_see_cd;
      let flag = true;
      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList;

      for (let i = 4; i >= 0; i--) {
        if (Object.keys($scope.disp_cate[i]).length) {
          if (!$scope.sel_disp_category[i]) {
            flag = false;

            break;
          }
        }
      }

      if (!flag) {
        commonSVC.showMessage('실패', '최종분류까지 선택해주세요');

        return false;
      }

      let value = '';

      for (let i = 4; i >= 0; i--) {
        value = $scope.disp_cate[i];
        if (value.length) {
          break;
        }
      }
      if (dispCtgSelectList.includes(sel_disp_cate)) {
        commonSVC.showMessage('이미 추가된 전시카테고리 입니다');

        return false;
      }

      const listGroup = $('.list-group');
      const nameArr = [];
      let selectedName;

      for (let k = 0; k < 5; k++) {
        selectedName = $(`.DispCatMenu_${k}`).find('option:selected').text();

        // 세분류 없을시 이름에 넣지않음
        if (selectedName == '') {
          break;
        }

        nameArr.push(selectedName);
      }
      const name = nameArr.join(' > ');
      dispCtgSelectList.push(sel_disp_cate);
      const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${sel_disp_cate}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

      listGroup.append(aElement);
      $compile(aElement)($scope);
    };

    /**
     * 전시카테고리 삭제
     */
    $(document).on('click', '.dispCtgSelectRemoveBtn', function(e) {
      const dispCtgSelectList = $scope.sel_disp_category;
      const dispCtgSelectListLen = dispCtgSelectList.length;

      const item = $(e.target).parent();
      const value = item.attr('data-dispCtgCd');

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        const thisVal = dispCtgSelectList[i];

        if (thisVal?.shop_cate_see_cd == value) {
          dispCtgSelectList.splice(i, 1);
        }
      }

      $scope.dispCtg.dispCtgSelectList = $scope.dispCtg.dispCtgSelectList.filter(cateCd => cateCd !== value);

      item.remove();
    });

    /**
     * 전시카테고리 저장
     */
    function DispCtgList(param) {
      const disp = [];
      $('.list-group .list-group-item').each(function(e) {
        const name = $(this).find('.dispCtgNm').text();
        const code = $(this).attr('data-dispctgcd');
        param[`key_dispCtg_Nm${e}`] = name;
        param[`key_dispCtg_Cd${e}`] = code;

        disp.push({ name: name, code: code });
      });

      param.disp = [ ...disp ];
    }

    // MD
    $scope.setMd = (md) => {
      $scope.CateData.detailData.key_buyer_id = md.etc.buyer_id;
      $scope.CateData.detailData.key_buyerNm = md.etc.buyer_name;
      // 상품 분류 고시
      $scope.CateData.detailData.key_notice_class_id = md.etc.notice_class_id;
    };

    const init = async () => {

      await run_pa5_jobs('get_categories', '카테고리', {}, (data) => {
        data.map((cate) => {
          $scope.totalCate[cate.depth - 1].push({
            end_yn: cate.is_end,
            shop_cate_see_cd: cate.code,
            shop_cate_see_depth: cate.depth,
            shop_cate_see_name: cate.name,
            shop_cate_see_type: cate.type,
            parent_code: cate.parent_code,
            etc: cate.etc
          });
        }
        );
      });

      $timeout(async () => {
        $scope.cate[0] = $scope.totalCate[0].filter(cate => cate.shop_cate_see_type === '일반');
        $scope.disp_cate[0] = $scope.totalCate[0].filter(disp_cate => disp_cate.shop_cate_see_type === '전시');

        // 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else if (typeof $scope.matchingData.shop_cate_no_add_info === 'object' && Object.keys($scope.matchingData.shop_cate_no_add_info).length === 0) {
            matchDetailDataLoad(angular.copy(A017categorySVC.category_data));
          } else {
            matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if ($scope.matchingData.sol_cate_shop_add_info) {
          matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        } else {
          matchDetailDataLoad(angular.copy(A017categorySVC.category_data));
        }
      });
    };

    $scope.removeSelectedDispCate = (index) => {
      $scope.savedDispCateList.splice(index, 1);
    };

    init();

  });