'use strict';

angular.module('gmpApp')
  .service('templateModel', function (settings, commonSVC) {
    const self = {};

    self.name = '';
    self.type = '';
    self.site_code = '';
    self.site_name = '';
    self.site_id = '';
    self.site_pw = '';
    self.auth_key = '';
    self.bendor_code = '';
    self.all_channel_list = [];
    self.use_channel_list = [];

    return {
      insert: function (data, next) {

        const url = `${settings.pa20ApiUrl}/app/product/template`;
        const vdata = {
          name: data.name,
          shop_name: data.shop_name,
          shop_cd: data.shop_cd,
          shop_id: data.shop_id,
          type: data.type,
          // template_no : data.template_no,
          template_data: data,
          token: data.token,
          channel_account_id: data.channel_account_id,
          ol_shop_no: data.ol_shop_no
        };

        if (data.token) {
          delete data.token;
          delete data.channel_account_id;
          delete data.ol_shop_no;
        }

        if (next) {
          commonSVC.sendUrl('POST', url, vdata, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, vdata);
        }
      },

      edit: function (templateData, summary_obj, next) {
        const url = `${settings.pa20ApiUrl}/app/product/template`;
        const vdata = {
          template_name: templateData.item.data.name,
          shop_cd: templateData.item.data.site_code,
          shop_id: templateData.item.data.site_id,
          template_no: templateData.item.template_code,
          etc1: templateData.item.data.key_send_code, //출하지 코드
          etc2: templateData.item.data.key_selDelivery, // 배송방법
          etc3: templateData.item.data.key_DeliveryFeeTemplateNo, // ESM1.0 묶음 배송비 정책
          etc4: templateData.item.data.key_DeliveryFeeTemplateNo2, // ESM2.0 묶음 배송비 정책
          template_data: summary_obj
        };

        commonSVC.sendUrl('PATCH', url, vdata, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 온라인템플릿 즐겨찾기 단일 수정
       * @param number DB 번호
       * @param changeData 수정 데이터
       * @param logData 로그 데이터
       * @returns {*}
       */
      setBookmark: function (params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/template/set-bookmark`, params);
      },

      delete: function (number, next) {
        const params = { number: number };
        const url = `${settings.pa20ApiUrl}/app/product/template`;

        commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          next(state, data);
        });
      },

      copy: function (copyDatas, next) {
        const params = { numbers: copyDatas };
        const url = `${settings.pa20ApiUrl}/app/product/template/copy`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      list: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/product/template/list`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      set: function (data, next) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/template/set-template`, data, function (state, data) {
          next(state, data);
        });
      },

      editGroup: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/product/template-group`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      // 템플릿 그룹에 저장된 템플릿 목록 로드
      loadGroup: function (data) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/template-group`, data);
      },

      // 템플릿 그룹 리스팅
      listGroup: function (data) {
        const url = `${settings.pa20ApiUrl}/app/product/template-group/list`;

        return commonSVC.sendUrl('POST', url, data);
      },

      // 템플릿 그룹 복사
      copyGroup: function (data) {
        const url = `${settings.pa20ApiUrl}/app/product/template-group/copy`;

        return commonSVC.sendUrl('POST', url, data);
      },

      // 템플릿 그룹 삭제
      deleteGroup: function (data) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/template-group`, data);
      },

      templateOnlineProduct: function (data) {
        const url = `${settings.pa20ApiUrl}/app/product/template/list-of-shop`;

        return commonSVC.sendUrl('POST', url, data);
      },

      detail: function (data, next) {
        const params = {
          url: data.url,
        };
        const url = `${settings.pa20ApiUrl}/app/product/template`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          commonSVC.sendUrl('GET', url, params);
        }
      },

      templateApi: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/product/template/api`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }

      },

      createToken: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/product/template/create-token`;

        commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      }
    };
  });
