'use strict';

angular.module('gmpApp')
  .controller('LogMapHistoryCtrl',
    function (// common
      $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog,
      // info
      settings,
      // SVC
      commonSVC, historySVC,
      // Model
      shipmentModel,
      // List
      warehouseList, systemList
    ) {
      // 별칭정보 담기
      const seller_nick_info = [];

      let prodList = {};
      _.forEach($rootScope.use_channel_list, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      let logList = [];
      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: 'all',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_cd: '',
          shop_id: '',
          map_type: '', //매칭구분
          multi_search_word: '', //멀티서치워드
          multi_type: 'shop_sale_no' //멀티서치 타입
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectCount: 0,       // 검색전체 건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('작업번호'), value: 'work_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('옵션명'), value: 'ord_opt_name' },
            { label: gettextCatalog.getString('SKU코드, 세트코드'), value: 'sku_cd,set_cd' },
            { label: gettextCatalog.getString('SKU상품명, 세트상품명'), value: 'prod_name,set_name' },
            { label: gettextCatalog.getString('속성명'), value: 'attr' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문수집일'), value: 'wdate' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('매칭코드'), value: 'map_cd' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            test_id: 'drop-select-shop',
            search_name: 'shop_cd',
            item_list: commonSVC.getSiteList($rootScope.use_channel_list),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('계정 선택'),
            test_id: 'drop-select-account',
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList($rootScope.use_channel_list),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 매칭구분
            title: gettextCatalog.getString('매칭 구분'),
            search_name: 'map_type',
            item_list: [{ value: 'A', key: '자동' }, { value: 'M', key: '수동' }, { value: 'U', key: '수정' }, { value: 'F', key: '실패' }],
            item_key: 'key',
            item_value: 'value',
            select_value: ''
          }
        ]
      };

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);

      $scope.searchBtn = {
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('매칭수정'),
                action: function () {
                  $scope.ruleModify();
                },
                perm_only: ['order.mapping+write']
              }
            ]
          },
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            test_id: 'drop-excel',
            id: 'drop-excel',
            item_list: [{
              label: gettextCatalog.getString('전체엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all');
              },
              test_id: 'btn-all-excel',
              perm_only: ['log.mapping']
            }, {
              label: gettextCatalog.getString('선택엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select');
              },
              test_id: 'btn-select-excel',
              perm_only: ['log.mapping']
            }]
          },
        ],

        // 검색 영역 선택한 데이터 버튼
        actions_left: [
          [
            {
              label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
              btn_type: 'button',
              action: function () {
                $scope.excelDown('all');
              }
            },
            {
              label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
              btn_type: 'button',
              action: function () {
                $scope.excelDown('select');
              }
            }
          ]
        ],

      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        }
      };

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        alignCenterColumns: ['widget', 'ord_status', 'map_type', 'matching_cd'],
        defaultSortingColumns: ['work_no'],
        alignRightColumns: [],
        notSortingColumns: ['widget', 'matching_cd', 'matching_prod_name', 'attri', 'depot_seq'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: [],

        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/history/autoMatching`,
          requestWillAction: function (data) {
            if ($rootScope.work_no) {
              $scope.searchForm.search_key = 'work_no';
              $scope.searchForm.search_word = JSON.stringify($rootScope.work_no);
              $scope.searchData.search_key_name = '작업번호';
            }
            data = angular.merge({}, data, $scope.searchForm);

            // 엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotal;
            logList = result.results;
            prodList = result.prodList;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 140,
            template: function(row) {
              return (
                `${'' +
                  '<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(\''}${
                  row.uniq
                }', '${row.pa_shop_cd}')">주문상세</button>` +
                `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.ruleModify('${row.uniq}',${row.ord_opt_seq}, '${row.pa_shop_cd}')">매칭수정</button>`
              );
            }
          },
          { key: 'work_no', title: '작업번호', width: 80 },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 150,
            filter: 'dateValid'
          },
          {
            key: 'shop_cd',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '<div class="text-center">직접입력</div>';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 150
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 120,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰상품코드',
            width: 150
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 200,
            filter: 'whiteSpace'
          },
          {
            key: 'ord_opt_name',
            title: '옵션 및 수량',
            width: 250,
            notCompile: true,
            template: function(row) {
              const type = row.shop_opt_name
                ? row.ord_opt_type === '자동'
                  ? `[옵션]${row.shop_opt_name}`
                  : `[추가구매옵션]${row.ord_opt_name}`
                : row.shop_sale_name;

              return `${type}:${row.opt_sale_cnt}개`;
            }
          },
          {
            key: 'map_type',
            title: '매칭 구분',
            width: 80,
            notCompile: true,
            tooltip: '[자동] : 매칭 규칙으로 인해 자동으로 SKU 매칭된 주문 건입니다.\n[수동] : 매칭 안된 주문 건에 직접 SKU를 매칭 한 주문 건입니다.\n[수정] : 매칭된 주문 건의 SKU를 수정한 주문 건입니다.\n[실패] : 자동 매칭이 실패된 주문 건입니다.',
            template: function(row) {
              return row.map_type === '수동' && row.map_modify_yn === 1
                ? '수정'
                : row.map_type === '미매칭'
                  ? '<span class="text-danger">실패</span>'
                  : row.map_type === '자동'
                    ? '<span style="color: green">자동</span>'
                    : '<span style="color: blue">수동</span>';
            }
          },
          {
            key: 'matching_cd',
            title: '매칭코드',
            width: 180,
            template: function(row) {
              if (row.set_no) {
                return '<span class="label label-default bg-slate-400 mr-5">SET</span>' + row.set_no;
              } else {
                const uniqValue = `${row.uniq}${row.ord_opt_seq}`;

                return prodList[uniqValue].length > 1 ?
                  `<span class="bg-white border-warning-400 text-warning-400 label label-default mr-5">다중</span> <a ng-click="grid.appScope.showMultiSKUDetail('${uniqValue}')">${prodList[uniqValue][0].sku_cd} 외 ${prodList[uniqValue].length - 1}건</a>`
                  : prodList[uniqValue].length && prodList[uniqValue][0].prod_no ?
                    `<span class="label label-default bg-orange-800 mr-5">SKU</span>${prodList[uniqValue][0].sku_cd}`
                    : '';
              }
            }
          },
          {
            key: 'matching_prod_name',
            title: '매칭상품명',
            width: 160,
            notCompile: true,
            template: function(row) {
              const name = row.set_name ? row.set_name : row.prod_name;

              return name;
            }
          },
          {
            key: 'attri',
            title: '속성',
            width: 100,
            filter: 'isNullHyphen'
          },
          {
            key: 'depot_seq',
            title: '출고수량',
            width: 100,
            name: 'bundle_no',
            template: function(row) {
              const uniqValue = `${row.uniq}${row.ord_opt_seq}`;

              return (!row.set_name && prodList[uniqValue].length > 1) ? (prodList[uniqValue].reduce((prev, curr) => prev + curr.pack_unit, 0)) : row.pack_unit || '';
            }
          },
          {
            key: 'mdate',
            title: '매칭수정일',
            width: 150,
            filter: 'dateValid'
          }
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $rootScope.work_no = null;
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 'total';
        $rootScope.work_no = null;
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 매칭수정
       * */
      $scope.ruleModify = function(uniq, seq, pa_shop_cd) {
        const mapList = uniq ? [_.find(logList, { uniq: uniq, ord_opt_seq: seq })] : $scope.grid.methods.selectedData('all');

        if (!mapList.length) {
          commonSVC.showMessage('수정할 매칭을 선택해주세요');

          return false;
        }

        const resolve = {};

        resolve.data = {
          from: '선택한 주문내역',
          pack_unit: true,
          warehouseList: warehouseList.data.result || [],
          isMulti: !$scope.user_profile.pa_sol_no,
          isMultiProd: true,
        };
        const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

        redata.result.then(function (re) {

          // 해외 주문 건인 경우 g마켓 물류서비스 지원 X
          if (pa_shop_cd && pa_shop_cd.startsWith('X') || $scope.grid.methods.selectedData('all').some(map => map.pa_shop_cd.startsWith('X'))) {
            if ((re.isSet && re.data.prod.some(re => re.ebaydepot_yn)) || (!re.isSet && re.some(re => re.ebaydepot_yn))) {
              commonSVC.showMessage('해외 주문은 G마켓 물류서비스 배송처 선택이 불가합니다.');

              return false;
            }
          }

          // 다중 매칭인 경우 배송처, 합포장 여부 확인
          if (!re.isSet && re.length > 1) {
            if (re.some(r => r.depot_no !== re[0].depot_no)) {
              commonSVC.showMessage('매칭실패', '다중 매칭은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

              return false;
            }
            if (re.some(r => !r.bundle_avail_yn)) {
              commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

              return false;
            }
          }

          const modal = commonSVC.openModal('', { data: { from: 'mappingModify', cnt: mapList.length } }, 'orderMappingProdCtrl', 'views/order/shipment/modals/mapping_prod.html');

          modal.result.then(function(data) {
            const params = {
              matchType: re.isSet ? 'set' : 'prod',
              orders: mapList,
              bases: re.isSet ? [re.data.set] : re,
              sameOrderMatchYn: !data.isOnly,
              sameWorkNo: true,
              saveRuleYn: data.ruleModify
            };

            shipmentModel.matchingProd(params, function(state, data) {
              if (state === 'success') {
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 {{count}}건이 SKU상품 매칭에 성공하였습니다.', { count: (data.result.change + 1) || 1 }));
                $scope.searchDo(false);
              } else {
                let err;

                switch (data.data.error) {
                  case 'STOCK':
                    err = '매칭할 SKU상품의 재고가 부족합니다.\nSKU상품 재고를 확인하시기 바랍니다.';
                    break;
                  case 'DEPOT_DIFFERENT':
                    err = '배송처가 동일한 상품만 매칭이 가능합니다.\n매칭할 상품의 배송처를 확인하시기 바랍니다.';
                    break;
                  default:
                    err = 'SKU상품 매칭에 실패하였습니다.';
                    break;
                }

                commonSVC.showToaster('error', gettextCatalog.getString('실패'), err);
              }
            });
          });
        });
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq, pa_shop_cd) {
        const resolve = {
          data: {
            fromPage: 'integrated',
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };

        let modal;

        if (pa_shop_cd?.startsWith('X')) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_order_list') {
          const $wrapper = $('#order_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      /*
      * 다중 매칭된 SKU 조회 모달
      */
      $scope.showMultiSKUDetail = function(uniq_value) {
        const resolve = {
          data: { prodList: prodList[uniq_value], isMappingRule: true }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      /**
       * 2018-02-21 Daniel
       * 작업 완료시 새로고침
       * [주문동기화, 발주전 주문수집, 발주확인, 발주확인후주문수집, 배송지연]
       */
      // _.each(["SyncOrderState", "ScrapOrder", "ScrapOrderConfirmDo", "ScrapOrderAndConfirmDoit", "DelayedOrderDo", "DelayedOrderPaymentDo"], function (value) {
      //   $scope.$on(value, function () {
      //     $scope.searchDo(true);
      //   });
      // });

      // 2018-01-11 chris 등록 성공시 리스트 새로고침 추가
      $scope.$on('reloadShipmentOrderList', function() {
        $scope.searchDo(true);
      });

      /**
        * 엑셀 다운 모달창
        **/
      $scope.excelDown = function (type) {
        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: angular.copy(historySVC.matchingExcelFieldList),
          title: '주문SKU매칭내역',
          url: '/app/history/autoMatching/excelDown',
          searchForm: $scope.pageData,
          page: 'map_history'
        };

        if (type === 'all') {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
        } else {
          const selectList = $scope.grid.methods.selectedData('all').map((row) => {
            return {
              work_no: row.work_no,
              uniq: row.uniq,
              ord_opt_seq: row.ord_opt_seq
            };
          });

          if (!selectList.length) {
            commonSVC.showMessage('선택된 내역이 없습니다.');

            return false;
          } else {
            resolve.data.select_list = selectList;
            resolve.data.count = selectList.length;
            resolve.data.isAll = false;
          }
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };
    });
