/**
 * Created by Ally on 2016-11-24.
 */
'use strict';

angular.module('gmpApp')
  .service('deliveryModel', function (settings, commonSVC) {

    return {
      bookMarkdeliveryInfoList: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/bookmark-info?domestic=${!!data?.domestic}`;

        return commonSVC.sendUrl('GET', url, {}, next);
      },

      /**
       * 택배 정보 로드
       */
      Load: function (data, next) {
        // 택배 상세정보
        const url = `${settings.pa20ApiUrl}/app/settings/delivery?carr_no=${data.carr_no}&carr_id=${data.carr_id}&carr_id_sub=${data.carr_id_sub}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 택배 정보 수정
       * @param data delivery 데이타
       */
      editDelivery: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery`;

        if (data.carr_pwd_new) {
          data.carr_pwd = data.carr_pwd_new;
        }

        data.log_contents = '택배사가 수정되었습니다.';

        return commonSVC.sendUrl('PATCH', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 택배사 삭제
       * @param data DB 번호
       */
      DeleteDelivery: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery?carr_no=${data.carr_no}&carr_id=${data.carr_id}&carr_id_sub=${data.carr_id_sub}`;

        return commonSVC.sendUrl('DELETE', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 전체 택배사 리스트
       * */
      deliveryAllList: function (next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/all`;

        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }
      },

      /**
       * 택배사 바로가기 가능한 carr_no
       * */
      deliveryTrackingList: function () {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/tracking`;

        return commonSVC.sendUrl('GET', url, {});
      },
      /**
       * 택배사 양식 로드
       * */
      DeliveryTemplateList: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates/list`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 택배사 양식 로드
       * */
      ClaimTemplateList: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates/claim/list`;
        data = _.pickBy(data, _.identity);

        return commonSVC.sendUrl('GET', url, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 송장 양식 추가
       */
      InsertDeliveryTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 송장 양식 수정
       */
      UpdateDeliveryTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates`;

        if (next) {
          return commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 송장 양식 수정
       */
      UpdateClaimDeliveryTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates/claim`;

        if (next) {
          return commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 송장 양식 삭제
       */
      DeleteDeliveryTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice-templates`;

        return commonSVC.sendUrl('DELETE', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       *  택배사 저장 정보
       * */
      deliveryInfoList: function (next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/info`;
        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }
      },

      /**
         * 택배 송장번호 생성
         * @param uniqList 주문 번호 리스트
         * @param templateData 송장 템플릿 데이터
         */
      issueInvoice: function (data) {
        let url = `${settings.pa20ApiUrl}/app/order/invoice/issue`;

        if (data.url) {
          url = `${settings.pa20ApiUrl}/app/order/invoice/${data.url}`;
        }

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
         * 반송 송장번호 생성
         * @param uniqList 주문 번호 리스트
         * @param templateData 송장 템플릿 데이터
         */
      claimInvoiceIssue: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice/claim-invoice-issue`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 반송 송장 취소
       * @param uniqList 주문 번호 리스트
       * @param templateData 송장 템플릿 데이터
       */
      cancelClaimInvoice: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice/cancel-claim-invoice`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 운송장번호 업데이트
       * 2019-01-15 rony
       * 2019-08-09 update jacob
       */
      setDelivInfo: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/delivery/setInfo`;

        if (next) {
          return commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 택배 정보 추가
       * @param data delivery 데이타
       */
      addDelivery: function (data, next) {

        if (data.carr_pwd_ori) {
          data.carr_pwd = data.carr_pwd_ori;
        } else {
          data.carr_pwd = '';
        }
        const url = `${settings.pa20ApiUrl}/app/settings/delivery`;

        data.log_contents = '택배사가 등록되었습니다.';

        if (next) {
          return commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * 운송장 엑셀 양식 로드
       * */
      DeliveryExcelTemplateList: function (next) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/order/invoice-excel-template`, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 운송장 엑셀 양식 추가
       */
      InsertDeliveryExcelTemplate: function (data, next) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/order/invoice-excel-template`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 운송장 엑셀 양식 수정
       */
      UpdateDeliveryExcelTemplate: function (data, next) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/order/invoice-excel-template`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 운송장 엑셀 양식 삭제
       */
      DeleteDeliveryExcelTemplate: function (data, next) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/order/invoice-excel-template`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 취급카테고리 리스트 조회
       */
      sellerCategoryListAll: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/category`;

        return commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 택배사 연동여부 조회
       */
      checkCarrierLinked: data => {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/link?carr_no=${data.carr_no}&carr_id=${data.carr_id}&biz_reg_no=${data.biz_reg_no}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 택배사 아이디 존재여부 확인
       */
      checkCarrierIdExist: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/delivery/id`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 운임정보 확인
       * 2018-11-29 rony
       */
      checkDHParams: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice/check-DH-params`;

        return commonSVC.sendUrl('POST', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 카카오T당일배송 배송가능지역 조회
       */
      checkDelivRegion: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/order/invoice/check-delivery-region`;

        if (next) {
          return commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * CU편의점택배/이마트24편의점 동기화 (운송장번호 가져오기 및 출고완료 처리)
       */
      syncByIssue: function (data) {
        let url = `${settings.pa20ApiUrl}/app/order/invoice/syncData`;

        if (data.url) {
          url = `${settings.pa20ApiUrl}/app/order/invoice/${data.url}`;
        }

        return commonSVC.sendUrl('POST', url, data);
      },

      /**
       * CU편의점택배/이마트24편의점 접수취소
       */
      cancelInvoice: function (data) {
        let url = `${settings.pa20ApiUrl}/app/order/invoice/cancelInvoice`;

        if (data.url) {
          url = `${settings.pa20ApiUrl}/app/order/invoice/${data.url}`;
        }

        return commonSVC.sendUrl('POST', url, data);
      }
    };
  });
