'use strict';

angular.module('gmpApp')
  .controller('deliveryInfoEditCtrl', function ($scope, data, selectList, deliveryModel, commonSVC, $uibModalInstance, commonModel, workSVC) {

    data.searchForm.status = ['출고완료'];
    $scope.modalData = data;
    $scope.modalData.count = selectList.data.recordsTotal;
    $scope.templateList = [];
    $scope.editTemplateData = {};
    $scope.excelField = {
      bundle_no: '',
      carr_no: '',
      invoice_no: ''
    };

    function loadTemplate() {
      deliveryModel.DeliveryExcelTemplateList(function (state, data) {
        if (state === 'success') {
          $scope.templateList = data.results || [];
          $scope.editTemplateData = {};
        } else {
          commonSVC.showToaster('error', '실패', '양식 조회 실패');
        }
      });
    }

    loadTemplate();

    /**
     * 양식 선택
     * */
    $scope.templateSelect = function(temp) {
      if (temp === 'default') {
        const defaultTemplateData = {
          rename_yn: 0,
          columns_origin: [{ header: '묶음번호', key: 'bundle_no', select: false }, { header: '택배사', key: 'carr_name', select: false }, { header: '운송장번호', key: 'invoice_no', select: false }, { header: '출고상태', key: 'ord_status', select: true }, { header: '출고지시일', key: 'out_order_time', select: true }, { header: 'SKU코드', key: 'sku_cd', select: true }, { header: '수령자명', key: 'to_name', select: true }, { header: '발송예정일', key: 'ship_plan_date', select: true }, { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true }, { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true }, { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true }, { header: '상품명', key: 'shop_sale_name', select: true }, { header: '옵션', key: 'shop_opt_name', select: true }, { header: '수량', key: 'sale_cnt', select: true }, { header: '추가구매옵션', key: 'shop_add_opt_name', select: true }, { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true }, { header: '수령자 주소', key: 'to_addr1', select: true }, { header: '수령자 휴대폰번호', key: 'to_htel', select: true }, { header: '국가', key: 'to_ctry_cd', select: true }, { header: '배송처', key: 'depot_name', select: true }, { header: '규칙적용사은품', key: 'gift_prod_name', select: true }, { header: '사은품', key: 'gift_name', select: true }, { header: '배송방법', key: 'ship_method', select: true }, { header: '배송비', key: 'ship_cost', select: true }],
          file_type: 'xlsx',
          name: '기본 양식',
          template_no: 'default'
        };

        $scope.editTemplateData = defaultTemplateData;
        $('#template_default').prop('checked', true);
      } else {
        $(`#template${temp.template_no}`).prop('checked', true);
        $scope.editTemplateData = temp;
      }
    };

    /**
     * 엑셀 다운로드
     * */
    $scope.download = function () {
      const apiUrl = data.url;

      if (!$scope.editTemplateData.template_no) {
        commonSVC.showMessage('양식을 선택하세요.');

        return false;
      }
      const downList = $scope.editTemplateData.rename_yn ? $scope.editTemplateData.columns_rename : $scope.editTemplateData.columns_origin;
      const idx = _.findIndex(downList, { key: 'shop_name' });

      if (idx != -1) {
        downList[idx].header = '쇼핑몰';
        downList.splice(idx + 1, 0, { header: '계정', key: 'shop_id', select: true });
      }
      let params = {
        down_list: downList,
        file_ext: $scope.editTemplateData.file_type,
        sheet_name: $scope.editTemplateData.sheet_name || '배송관리',
        is_all: data.isAll
      };

      if (data.searchForm) {
        params = angular.merge(params, data.searchForm);
      }
      // 선택 다운로드
      if (!data.isAll) {
        params.uniq = data.uniq;
      }

      commonModel.excelDownload(apiUrl, params, function (state, result) {
        if (state == 'success') {
          commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
          window.open(result.url, '');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');
        }
      });

    };

    // 엑셀 드래그앤드랍 검증
    $scope.fileSelect = (files) => {
      // 노출용 파일명 세팅, 파일 개수 제한
      $scope.fileData = [];
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileData = files[0];
      }
    };

    /**
     * 엑셀 업로드
     * */
    $scope.upload = function () {
      if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : $scope.fileData.name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
        $scope.fileData = [];

        return false;
      } else if ($scope.fileData) {
        const params = {
          field: $scope.excelField,
          type: 'delivery',
          overwrite: true
        };

        workSVC.addExcelWork('UploadDeliveryInfoExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
          });
      } else {
        commonSVC.showMessage('파일을 선택하세요.');

        return false;
      }
    };

    $scope.downResult = function () {
      window.open($scope.resultFileUrl, '');
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
