'use strict';

angular.module('gmpApp')
  .factory('A109categorySVC', function () {

    return {

      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        category_names: '',
        category_codes: '',
        key_displayCategoryCode: ''
      }
    };
  });
