'use strict';

angular.module('gmpApp')
  .controller('ProdProductExcelUploadCtrl', function ($rootScope, $scope, $uibModalInstance, $compile, settings, commonSVC, workSVC, productSVC, productModel, gettextCatalog, data) {
    $scope.resultFileUrl = '';
    $scope.uploadStatus = 0;  // 업로드 진행률
    $scope.showProgressbar = false;
    $scope.mode = data.mode;
    $scope.specialChars = 'fail';

    // 대표 SKU상품 정보 자동 세팅 (환경설정값)
    $scope.prod_auto_set_yn = data.prod_auto_set_yn === 'Y';
    // 대표 SKU상품 정보 자동 세팅 (모달 설정값)
    $scope.prod_online_set_yn = false;

    let act;

    switch ($scope.mode) {
      case 'add':
        act = 'RegistProductExcel';
        break;

      case 'edit':
        act = 'EditProductExcel';
        break;
    }

    // ============================== 데이터 테이블 ============================== //
    const search = {
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,      // 선택한 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
      },
      searchForm: {
        orderby: 'bookmark_yn desc',   //정렬
        search_key: 'template_name',
        search_word: '',
      }
    };

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    /**
       * 엔티키로 테이블 검색
       */
    $scope.enterKeySearch = function (e) {
      if ((e && (e.keyCode === 13 || e.which === 13))) {
        $scope.searchFn.searchDo();
      }
    };

    /**
       * 테이블 검색
       */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
       * 테이블 검색 초기화
       */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDo();
    };

    $scope.grid = {};
    $scope.grid.methods = {};

    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['mdate', 'template_type', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['bookmark_yn'],
      notSortingColumns: ['template_type'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        multiSelect: false,
        checkbox: true
      },
      gridHeight: 250,
      initPagingSize: 5,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/upload-excel-template/list`,
        requestWillAction: function (d) {
          const data = angular.merge({}, $scope.searchForm, d);

          return JSON.stringify(data);
        },
        requestDidAction: function (json) {
          $scope.searchData.totalCount = json.recordsTotal;

          return json.results;
        }
      },
      columns: [
        // 구분
        {
          key: 'template_type',
          title: '구분',
          width: 100,
          notCompile: true,
          template: function (row) {
            return `<span>${row.template_no === 'default' ? '기본양식' : '사용자 양식'}</span>`;
          }
        },
        // 양식명
        {
          key: 'template_name',
          title: '양식명',
          width: 300,
          notCompile: true,
          template: function (row) {
            return `<span>${row.template_name}</span>`;
          }
        },
        // 작성자
        {
          key: 'name',
          title: '작성자',
          width: 150,
          notCompile: true,
          template: function (row) {
            return `<span uib-tooltip="${row.name}" tooltip-placement="bottom">${row.name}</span>`;
          }
        },
        // 최종수정일
        {
          key: 'mdate',
          title: '최종수정일',
          width: 180
        },
        // 즐겨찾기
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 80,
          template: function (row) {
            if (row.template_no === 'default') {
              return '-';
            } else {
              row.bookmark_yn = !!row.bookmark_yn;

              return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.bookmarkChange(row.entity)"></pa-ui-switch>';
            }
          }
        },
      ]
    };
    // ============================== 데이터 테이블 ============================== //

    /**
       * 엑셀 업로드 양식 삭제
       * 2019-06-10 정상협
       */
    $scope.templateRemove = function () {
      const [ template ] = $scope.grid.methods.selectedData('all');

      if (!template) {
        commonSVC.showMessage('실패', '삭제할 양식을 선택해 주세요.');

        return false;
      }

      if (template.template_no === 'default') {
        commonSVC.showMessage('실패', '기본 양식은 삭제할 수 없습니다.');

        return false;
      }

      const params = {
        template_no: template.template_no,
      };

      commonSVC.showConfirm('양식을 삭제 하시겠습니까?', '', function () {
        productModel.baseExcelTemplateDelete(params, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('업로드 양식 삭제에 성공했습니다.'));
            $scope.searchDo();
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('업로드 양식 삭제에 실패했습니다.'));
          }
        });
      });
    };

    /**
       * 엑셀 업로드 양식 복사
       * 2019-06-10 정상협
       */
    $scope.templateCopy = function () {
      const [ template ] = $scope.grid.methods.selectedData('all');

      if (!template) {
        commonSVC.showMessage('실패', '복사할 양식을 선택해 주세요.');

        return false;
      }

      const params = {
        template_no: template.template_no,
      };

      if (template.template_no === 'default') {
        params.template_no = 0;
        params.template_data = JSON.stringify(angular.copy(productSVC.basicFieidInfo));
      }

      productModel.baseExcelTemplateCopy(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('업로드 양식 복사에 성공했습니다.'));
          $scope.searchDo();
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('업로드 양식 복사에 실패했습니다.'));
        }
      });
    };

    /**
       * 엑셀 업로드 양식 즐겨찾기
       * 2019-06-13 정상협
       */
    $scope.bookmarkChange = function () {
      const [ template ] = $scope.grid.methods.selectedData('all');
      if (template.template_no === 'default') {
        return false;
      }
      const params = {
        template_no: template.template_no,
        bookmark_yn: template.bookmark_yn ? 1 : 0,
      };

      productModel.baseExcelTemplateEdit(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('즐겨찾기 설정에 성공했습니다.'));
          $scope.searchDo();
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('즐겨찾기 설정에 실패했습니다.'));
        }
      });
    };

    /**
       * 엑셀 업로드 양식 다운로드
       * 2019-07-02 정상협
       */
    $scope.templateDown = function () {
      const [ template ] = $scope.grid.methods.selectedData('all');

      if (!template) {
        commonSVC.showMessage('다운로드할 양식을 선택하세요.');

        return false;
      }
      if (template.template_no === 'default') {
        const url = $rootScope.osse_sol ? 'https://cloud.plto.com/sampleExcel/Sample_product/Sample_product_OSSE_v1.xlsx' : 'https://cloud.plto.com/sampleExcel/Sample_product/Sample_product_v14.xlsx';
        window.open(url, '');
        commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('양식 다운로드에 성공했습니다.'));

        return false;
      }

      const params = {
        template_no: template.template_no,
      };

      productModel.baseExcelTemplateDown(params, function (state, data) {
        if (state === 'success') {
          window.open(data, '');
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('양식 다운로드에 성공했습니다.'));
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString(data.data.error));
        }
      });
    };

    /**
     * 엑셀 업로드 양식 모달 - 추가/수정
     * @param {('add'|'edit')} type
     */
    $scope.openExcelTemplateForm = function (type) {
      const [ template ] = $scope.grid.methods.selectedData('all');

      if (type === 'edit' && !template) {
        commonSVC.showMessage('실패', '수정할 양식을 선택해 주세요.');

        return false;
      }

      if (type === 'edit' && template?.template_no === 'default') {
        commonSVC.showMessage('실패', '기본 양식은 수정할 수 없습니다.\n 양식을 다운로드 하시면 상세 내용을 확인할 수 있습니다.');

        return false;
      }

      const resolve = {
        data: {
          type: type,
          template_no: template?.template_no
        }
      };

      const modal = commonSVC.openModal('xg', resolve, 'ProdProductExcelUploadTemplateCtrl', 'views/prod/product/modals/excel_upload_template.html');

      modal.result.then(function () {
        // 엑셀 업로드 후 새로고침 추가 2018-01-16 rony
        $scope.searchDo(true);
      });
    };

    $scope.fileSelect = (files) => {
      // 노출용 파일명 세팅, 파일 개수 제한
      $scope.fileData = [];
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
       * 엑셀 업로드
       * */
    $scope.upload = function () {
      const selectRow = $scope.grid.methods.selectedData('template_no');

      if (!selectRow.length) {
        commonSVC.showMessage('엑셀작업 등록실패', '양식을 선택해주세요.');

        return false;
      }

      if ($scope.fileData) {
        const params = {
          mode: $scope.mode,
          specialChars: $scope.specialChars,
          template_no: selectRow[0],
          prod_online_set_yn: $scope.prod_online_set_yn
        };

        workSVC.addExcelWork(act, params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
      }
    };

    $scope.downResult = function () {
      window.open($scope.resultFileUrl, '');
    };

    /**
       * 창 닫기.
       */
    $scope.cancel = function() {
      // dismiss 로 처리할경우 부모창에서 창닫히는 결과값을 캐치할수가 없어 close 로 변경 2018-01-16 rony
      // $uibModalInstance.dismiss('cancel');
      $uibModalInstance.close('cancel');
    };

  });