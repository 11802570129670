'use strict';

angular.module('gmpApp')
  .controller('ProdAddToGroupCtrl', function ($scope, $rootScope, data, $uibModalInstance, commonSVC, settings, userInfo, productSVC, gettextCatalog, $timeout, $compile,
    supplierModel, warehouseModel, productModel, systemModel, systemList) {

    let dataChanged = false;

    // 업체 설정 기본값
    const defaultObj = {
      // 배송처
      default_warehouse: systemList.data.default_depot_no ? String(systemList.data.default_depot_no) : '',
      // 안전재고
      safe_stock_default: systemList.data.safe_stock_default_YN == 'Y' && systemList.data.safe_stock_default > 0 ? systemList.data.safe_stock_default : 0, // 안전재고 기본값
    };

    $scope.warehouseList = data.warehouseList;
    $scope.new = false;
    $scope.prodData = angular.copy(productSVC.product); //SVC에서 가져오기
    $scope.prodData.opt_multi_reg = false;
    $scope.prodData.skuOverlabChecked = false;
    $scope.prodData.skuAuto = true;
    $scope.prodData.sku_cd = '';
    $scope.prodData.delivery_vendor = data.depot_no;
    $scope.prodData.depot_name = data.depot_name;
    $scope.prodData.madein_no = data.madein_no;
    $scope.prodData.madein_etc = data.madein_etc;
    $scope.prodData.tax_type = data.tax_type;
    $scope.prodData.prod_name = data.prod_name;
    $scope.prodData.prod_info = '{}';
    $scope.prodData.style_no = data.style_no;
    $scope.prodData.style_cd = data.style_cd;
    $scope.prodData.prodDepotArray = [{ depot_no: defaultObj.default_warehouse, depot_name: '', real_stock: 0, safe_stock: defaultObj.safe_stock_default }];
    $scope.prodData.main_depot_no = defaultObj.default_warehouse;

    $scope.attri_types = [];
    // { 속성명 : true ] object
    if (data.attri_type) {
      $scope.attri_types = data.attri_type.split(',');
    } else {
      $scope.attri_types = [];
    }
    $scope.opt_yn = _.zipObject(angular.copy($scope.attri_types), _.fill(angular.copy($scope.attri_types), true));
    // $scope.opt_etc_key = _.pullAll(angular.copy($scope.attri_types), ['색상', '사이즈']); // 직접입력 옵션명

    $scope.prodLogInfo = {};
    $scope.prodList = {};

    const searchData = {
      detailSearchFlag: true,
      haveBoxContent: false,
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수
      search_key_items: [  // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('SKU코드'), value: ['sku_cd'] },
        { label: gettextCatalog.getString('SKU상품명'), value: ['prod_name'] },
        { label: gettextCatalog.getString('브랜드'), value: ['brand'] },
        { label: gettextCatalog.getString('모델명'), value: ['model'] },
        { label: gettextCatalog.getString('속성명'), value: ['attri'] },
        { label: gettextCatalog.getString('바코드'), value: ['barcode'] }
      ],
      search_date_type: [
        { label: gettextCatalog.getString('등록일'), value: 'wdate' },
        { label: gettextCatalog.getString('수정일'), value: 'mdate' }
      ]
    };
    const searchForm = {
      sdate: moment(userInfo.user.user_wdate).format('YYYY-MM-DD'),   // 상품쪽은 user가 가입한 날짜가 처음 default
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      date_type: 'wdate',                                             //검색기간 구분
      search_key: 'all',                                              //검색구분
      search_word: '',                                                //검색어
      delivery_vendor: data.depot_no                                  // 배송처
    };

    $scope.searchData = angular.copy(searchData);
    $scope.searchForm = angular.copy(searchForm);
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      gridHeight: 400,
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'wdate', 'sku_cd', 'prod_name', 'attri', 'brand', 'model',
        'barcode', 'depot_name', 'mdate'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/list`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.prodList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 90,
          template: function (row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true, '${row.sku_cd}', '${row.prod_no}')">상세</button>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 120,
          template: function(row) {
            return `<a href=#><span id=${row.prod_no}>${row.sku_cd}</span></a>`;
          }
        },
        {
          key: 'prod_name',
          title: '이미지+상품명',
          width: 270,
          template: function (row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 100
        },
        {
          key: 'brand',
          title: '브랜드',
          width: 100
        },
        {
          key: 'model',
          title: '모델명',
          width: 100
        },
        {
          key: 'barcode',
          title: '바코드',
          width: 100
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 100
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 120,
          filter: 'dateValid'
        }
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.searchData = angular.copy(searchData);
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchDo(true, true);
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };

      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
    };

    /**
     * 선택상품 그룹에 추가
     */
    $scope.addToGroup = function () {
      const prodNoArr = $scope.grid.methods.selectedData('prod_no');
      const depotNo = _.uniq($scope.grid.methods.selectedData('depot_no'));
      const attriTypeArr = $scope.grid.methods.selectedData('attri_type');

      if (depotNo.length < 1) {
        commonSVC.showMessage('상품을 선택하세요.');

        return false;
      } else if (depotNo.length > 1) {
        commonSVC.showMessage('같은 배송처의 상품만 추가 가능합니다.');

        return false;
      } else {
        // 속성 검사
        let attriChecked = true;

        _.forEach(attriTypeArr, function (type) {
          const type_arr = type ? type.split(',') : [];

          if (_.difference($scope.attri_types, type_arr).length > 0) {
            attriChecked = false;

            return true;
          }
        });

        // 그룹에 추가하기
        if (attriChecked) {
          const params = { prod_no: prodNoArr, style_no: data.style_no };

          commonSVC.showConfirm('선택한 SKU상품을 스타일상품에 추가하시겠습니까?', '정확한 스타일상품을 관리하기 위해 동일 상품에 속성만 다른 SKU상품들을 추가하시기 바랍니다.\n' +
            '전혀 다른 상품의 SKU상품을 추가하여 온라인상품에 등록할 경우 상품정보가 잘못 등록될 수 있습니다.', function () {
            productModel.styleJoin(params, function (state, data) {
              if (state === 'success') {
                commonSVC.showToaster('success', '', '스타일상품에 추가되었습니다.');
                $scope.searchDo(false);
                dataChanged = true;
              } else {
                commonSVC.showToaster('error', '실패', '스타일상품에 추가하기 실패');
              }
            });
          });
        } else {
          commonSVC.showMessage('같은 속성의 상품만 추가 가능합니다.');

          return false;
        }

      }
    };

    /**
     * 스타일코드 SKU상품 리스트 조회
     */
    function styleList() {
      const params = { style_no: data.style_no };
      // const params = { style_cd: data.style_cd };

      productModel.styleList(params, function (state, data) {
        if (data.status === 'success') {
          $scope.gatherProdList = [];
          // 옵션 설정
          _.forEach(data.results, function (prod) {
            const opt_obj = _.zipObject(prod.attri_type ? prod.attri_type.split(',') : '', prod.attri ? prod.attri.split(',') : '');

            $scope.gatherProdList.push(_.assignIn({}, prod, opt_obj));
          });

        } else {
          commonSVC.showToaster('error', '실패', 'SKU상품 조회 실패');
        }
      });
    }
    styleList();

    // SKU 중복체크
    $scope.overlabCheck = function (obj) {
      const sku = obj.sku_cd.trim();
      const regx = new RegExp(/^[a-zA-Z0-9,./_+=()-]*$/);

      if (!regx.exec(sku)) {
        commonSVC.showMessage('', 'SKU 코드는 영문, 숫자, 특수문자 ( ) - _ = + . / , 입력이 가능합니다.');
      } else if (!sku) {
        commonSVC.showMessage('', 'SKU코드를 입력해주시기 바랍니다.');
      } else {
        productModel.skuOverlabCheck({ code: sku }, function (state, result) {
          if (result.data.result) {
            commonSVC.showMessage('', '이미 등록된 SKU코드입니다.');
            obj.skuOverlabChecked = false;
          } else {
            commonSVC.showMessage('', '등록 가능한 SKU코드입니다.');
            obj.skuOverlabChecked = true;
          }
        });
      }
    };

    $scope.addProduct = function () {
      if ($scope.prodGroupAddform.$valid) {
        if ($scope.prodData.opt1) {
          $scope.prodData.opt1_type = $scope.attri_types[0];
        }
        if ($scope.prodData.opt2) {
          $scope.prodData.opt2_type = $scope.attri_types[1];
        }
        if ($scope.prodData.opt3) {
          $scope.prodData.opt3_type = $scope.attri_types[2];
        }

        // 실재고 < 안전재고일경우 등록되지 않도록 처리
        if ($scope.prodData.prodDepotArray.find(o => o.safe_stock * 1 > o.real_stock * 1)) {
          commonSVC.showMessage('상품 등록 실패', '안전재고는 실재고를 초과할 수 없습니다');

          return;
        }

        if ($scope.prodData.skuAuto || $scope.prodData.skuOverlabChecked) { // SKU코드 자동생성 했을때
          productModel.insert($scope.prodData, function (state) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', 'SKU상품이 추가되었습니다.');
              styleList();
              dataChanged = true;
            } else {
              commonSVC.showToaster('error', '실패', 'SKU상품 등록에 실패 했습니다.');
            }
          });
        } else if (!$scope.prodData.skuOverlabChecked) { // SKU코드 자동생성 안하고 중복확인 안해봤을 때
          commonSVC.showMessage('', 'SKU코드 중복확인을 하셔야 등록이 가능합니다.');
        }
      }
    };

    /**
     * 모달 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.close(dataChanged);
    };

    /**
     * 툴팁 그려주기
     */
    $scope.$watch('new', function (nv) {
      if (nv) {
        $timeout(function () {
          $('[data-popup="tooltip"]').tooltip();
        }, 100);
      }
    });

    /**
     * 배송처 추가, 삭제 (1개 등록)
     *
     * @param {string} depot_no 현재 선택된 배송처 번호
     * @param {'plus'|'minus'} calMode 추가인지 삭제인지
     * @param {number} idx 삭제할 인덱스
     */
    $scope.calculateDepot = function (depot_no, calMode, idx = 0) {
      if (calMode === 'plus') {
        $scope.prodData.prodDepotArray.push({ depot_no: '', real_stock: 0, safe_stock: defaultObj.safe_stock_default, depot_name: '' });
      } else {
        if ($scope.prodData.main_depot_no == depot_no) {
          commonSVC.showMessage('배송처 삭제 실패 안내', '대표 배송처는 삭제 불가능합니다.');

          return false;
        }

        const currentProdDepotInfo = $scope.prodData.prodDepotArray.find(o => o.depot_no === depot_no);

        if (currentProdDepotInfo.link_ord_yn === 'Y' || currentProdDepotInfo.link_prod_yn === 'Y') {
          commonSVC.showMessageHtml('배송처 삭제 실패 안내', `
            <br>
            <b>해당 배송처에 연동된 주문 또는 상품이 존재합니다.</b><br><br>
            <ul>
              <li>
                <b>미출고 주문에 매칭된 경우</b><br>
                해당 주문이 출고가 완료되거나<br>
                주문에서 매칭을 해제 하시면 배송처를 삭제할 수 있습니다.
              </li>
              <li>
                <b>온라인 상품 또는 세트 상품에 매칭된 경우</b><br>
                상품 정보에서 매칭을 해제 하시면 배송처를 삭제할 수 있습니다.
              </li>
            </ul>
          `);

          return false;
        }

        $scope.prodData.prodDepotArray.splice(idx, 1);
      }
    };

    /**
     * 대표 배송처 설정
     *
     * @param {string} depot_no 선택한 배송처번호
     */
    $scope.changeMainDepotNo = (depot_no) => {
      if (!_.isNullOrEmpty(depot_no)) {
        $scope.prodData.main_depot_no = depot_no;
      } else {
        commonSVC.showMessage('배송처를 선택해주십시오.');
        $(`input:radio[name='main_depot_no']:radio[value='${$scope.prodData.main_depot_no}']`).prop('checked', true);
      }
    };

    /**
     * 배송처 선택시 배송처이름 갱신
     *
     * @param {string} depot_no 배송처번호
     */
    $scope.changeDepotInfo = (depot_no) => {
      const selectDepotInfo = $scope.warehouseList.find(o => o.code.toString() === depot_no);

      $scope.prodData.prodDepotArray.find(o => o.depot_no === depot_no).depot_name = selectDepotInfo.warehouse_name;

      // 배송처가 하나인데 변경하는경우 대표배송처도 수정해줘야 함.
      if ($scope.prodData.prodDepotArray.length === 1) {
        $scope.prodData.main_depot_no = depot_no;
      }
    };

    /**
     * 이미 선택한 배송처인지 확인 함수.
     *
     * @param {number} depot_no 배송처번호
     * @param {string} currentDepot 현재 select 에서 선택되어있는 배송처 번호
     * @returns Boolean
     */
    $scope.optionDisabledCheck = (depot_no, currentDepot) => {
      const result = _.cloneDeep($scope.prodData.prodDepotArray)
        .filter(o => o && o.depot_no !== currentDepot)
        .map(o => o.depot_no)
        .includes(depot_no.toString());

      return result;
    };

  });
