'use strict';

angular.module('gmpApp')
  .controller('StockReAllocationRuleCtrl', function ($scope, $rootScope, $timeout, $compile, data, $uibModalInstance, commonSVC, gettextCatalog, inventoryModel, settings) {
    $scope.use_yn = data.alloc_type === 're_alloc'; // 재고재할당 사용여부
    $scope.alloc_type_last_modify_time = data.alloc_type_last_modify_time; // 사용여부 최종수정일

    const search = {
      searchData: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        showCount: 10,       // 한 페이지에 보이는 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('SKU코드'), value: 'd.sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'd.prod_name' },
        ],
        search_key_name: 'SKU코드',
        showDetailSearchArea: false,
        showSearchArea: false,
        showSearchDiv: false,
      },
      searchForm: {
        search_word: '',        //검색어
        search_key: 'd.sku_cd', //검색구분
        orderby: 'd.prod_no desc'   //정렬
      }
    };

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);

    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      // $scope.searchData = angular.copy(search.searchData);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'real_stock', 'stock_cnt', 'link_channel_cnt', 'nokey'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['widget'],
      notSortingColumns: [
        'widget', 'sku_cd', 'prod_name', 'attri', 'real_stock',
        'stock_cnt', 'link_channel_cnt', 'nokey'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/alloc/list-of-shop-base`,
        requestWillAction: function (data) {
          data.re_alloc_yn = true;
          data.isProd = true;

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          result.results = _.reject(result.results, (data) => !data.prod_no);

          // 집계카운트
          $scope.countList = {
            total: result.results.length,
          };

          return result.results;
        }
      },
      modal: true,
      columns: [
        {
          key: 'widget',
          width: 80,
          title: '설정',
          template: function (row) {
            return `
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.setRule(${row.prod_no}, ${row.depot_no}, ${row.real_stock}, ${row.stock_cnt})">수정</button>
                <button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.deleteRule(${row.prod_no}, ${row.depot_no})">삭제</button>
              </div>`;
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 123
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 207
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 100
        },
        {
          key: 'attri',
          title: '속성',
          width: 108
        },
        {
          key: 'real_stock',
          title: '실재고',
          width: 60
        },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          width: 60
        },
        {
          key: 'link_channel_cnt',
          title: '연동상품',
          width: 57
        },
        {
          key: 'nokey',
          width: 50,
          title: '적용',
          template: function (row) {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeRuleUse(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    /**
     * 도움말 모달 오픈
     */
    $scope.openHelp = function() {
      commonSVC.openModal('xxg', { data: {} }, 'reallocHelpCtrl', 'views/stock/inventory/realloc_help.html');
    };

    /**
     * 규칙사용여부 변경
     */
    $scope.changeRuleUse = async function({ prod_no, use_yn, depot_no }) {
      try {
        const params = {
          prod_no,
          depot_no,
          use_yn,
          re_alloc_yn: true
        };

        await inventoryModel.stockAllocSetRuleUse(params);

        commonSVC.showToaster('success', '성공', '적용여부 수정 성공');
      } catch (error) {
        commonSVC.showToaster('error', '실패', '적용여부 수정 실패');

        return false;
      }
    };

    /**
     * 규칙추가 모달
     */
    $scope.addRule = function() {
      const modal = commonSVC.openModal('xxg', { data: {} }, 'AddStockReAllocRuleCtrl', 'views/stock/inventory/add_stock_reallocation_rule.html');

      modal.result.then(function () {
        $scope.searchDo(false);
      });
    };

    /**
     * 재고할당 수정 (SKU상품별 할당)
     */
    $scope.setRule = function(prod_no, depot_no, real_stock, stock_cnt) {
      const data = {
        prod_no,
        depot_no,
        real_stock,
        stock_cnt
      };

      commonSVC.openModal('xxg', { data: data }, 'SetStockReAllocChannelRuleCtrl', 'views/stock/inventory/set_stock_reallocation_channel_rule.html');
    };

    /**
     * 재고재할당 사용여부 수정
     */
    $scope.changeUse = function(use_yn) {
      // 이미 사용중인데 사용함 선택 or 사용안함인데 사용안함 선택시 아무것도 안함
      if ((use_yn && data.alloc_type === 're_alloc') || (!use_yn && data.alloc_type !== 're_alloc')) {
        return false;
      }

      data.alloc_type = use_yn ? 're_alloc' : null;

      $scope.use_yn = use_yn;

      $scope.alloc_type_last_modify_time = moment().format('YYYY-MM-DD HH:mm:ss');

      const params = {
        alloc_type: use_yn ? 're_alloc' : null,
        date: $scope.alloc_type_last_modify_time
      };

      inventoryModel.stockAllocSetType(params, function(state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '재고재할당 사용여부 변경 성공');
        } else {
          commonSVC.showToaster('success', '실패', '재고재할당 사용여부 변경 실패');
        }
      });
    };

    /**
     * 규칙 삭제
     */
    $scope.deleteRule = function(prod_no, depot_no) {
      let ruleList = [];

      if (prod_no) {
        ruleList.push({ prod_no, depot_no });
      } else {
        ruleList = $scope.grid.methods.selectedData('all').map(o => {
          return {
            prod_no: o.prod_no,
            depot_no: o.depot_no
          };
        });
      }

      // 선택한 row가 없을때
      if (!ruleList.length) {
        commonSVC.showMessage('삭제할 규칙을 선택해주세요');

        return false;
      }

      commonSVC.showConfirm('삭제하시겠습니까?', '', function() {
        inventoryModel.stockAllocDeleteRule({ ruleList: ruleList, from: 're_alloc' }, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '재고할당 규칙 삭제 성공');
            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '재고할당 규칙 삭제 실패');
          }
        });
      });
    };

    /**
     * 재고재할당 수동 적용
     */
    $scope.applyReAlloc = function() {
      if (!$scope.countList.total) {
        commonSVC.showMessage('재고재할당 규칙이 없습니다.');

        return false;
      }
      commonSVC.openModal('full', { data: { is_all: true, alloc_type: 're_alloc' } }, 'StockAllocationChannelCtrl', 'views/stock/inventory/stock_allocation_channel.html');
    };

    /**
     * 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
