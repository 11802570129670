
'use strict';

/**
 * 208-03-26 Gargamel
 * 카페24(신)
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B616-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B691categorySVC, commonSVC, errorSVC, settings, siteInfo, B616categorySVC) {
    const svc = B616categorySVC;

    let category_code = [], category_names = [];

    $scope.CateList = angular.copy(svc.category_list);      // 카테고리 리스트(select)
    $scope.shopCate = angular.copy(svc.shop_category_data); // 사이트 카테고리 리스트

    // var load_cate = [];

    let siteCateList = [];

    $scope.CateData = angular.copy(svc.category_data);     // 카테고리 데이터
    $scope.Cate = {};
    let load_cate = [];

    // $scope.Cate = {};                                   // 추가한 카테고리
    // $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      const cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      // cateCd 가 빈값일때는 이름 설정 안함 (설정하면 카테고리가 .. > .. > 하위 카테고리가 없습니다 로 등록됨 )
      if (cateCd) {
        cateSetting(index, cateCd);
      }

      if (index === '3' || cateCd === 'noSub' || !cateCd) {
        return false;
      } // 마지막 분류이거나, 하위분류없을시

      $scope.loading = true;

      clearCate(index);

      setCateSub(index, cateCd);
    };

    // 카테고리 추가
    $scope.addCate = function() {
      const cateCd = [];

      if (Object.keys($scope.Cate).length === 5) {
        commonSVC.showMessage('카테고리는 최대 5개까지 선택할수 있습니다');

        return false;
      }

      // 카페24는 대분류만 선택해도 저장이 되어야 하기 때문에 해당 로직 주석.
      // let flag = false;

      // for (let i = 0; i < 4; i++) {
      //   if (!$scope.CateData[$scope.CateList[i]] && Object.keys($scope.shopCate[i]).length) {
      //     flag = true;

      //     break;
      //   }
      // }

      // if (flag) {
      //   commonSVC.showMessage('카테고리를 선택해주세요.');

      //   return false;
      // }

      _.each($scope.CateList, function(v) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }

        if (!value) {
          return false;
        }

        cateCd.push(value);
      });

      $scope.Cate[cateCd.join('>')] = $scope.CateData.category_names;
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      delete $scope.Cate[key];
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

    // 하위 카테고리 가져오기
    function setCateSub(index, cateCd) {
      const idx = parseInt(index) + 1;

      if (!$scope.shopCate[idx]) {
        $scope.shopCate[idx] = [];
      }

      siteCateList.forEach(function(v) {
        if (parseInt(cateCd) == v.category_no_parent && v.category_depth == parseInt(index) + 2) {
          $scope.shopCate[idx][v.category_no] = v.category_name;
        }
      });
    }

    // 카테고리 초기화
    function clearCate(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 4; i++) {
        const key = $scope.CateList[i];

        $scope.shopCate[i] = {};
        $scope.CateData[key] = '';
      }
    }

    function detailCate() {
      let cnt = 0;

      // 카테고리 저장
      _.each($scope.Cate, function (v, k) {
        const cntType = cnt === 0 ? '' : cnt;

        $scope.CateData.detailData[`key_cpm_cate_name${cntType}`] = v;
        $scope.CateData.detailData[`key_cpm_cate_code${cntType}`] = k;

        cnt++;
      });
    }

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);
    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      const cate = Object.keys($scope.Cate ? $scope.Cate : $scope.Cate = {});

      if (cate.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      detailCate();

      const categoryNames = cate.length > 1 ? `${$scope.Cate[cate[0]]} 외 ${cate.length - 1}개` : $scope.Cate[cate[0]];

      //category_names 초기화
      $scope.CateData.detailData.category_names = categoryNames;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cate[0];
      // 카테고리 리스트 저장
      $scope.CateData.detailData.Cate = { ...$scope.Cate };

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B616',
          site_id: $scope.matchingData.site_id,
          category_code: cate[0],
          category_names: categoryNames,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          //대분류 카테고리 로드
          return requestPA('product.category.scrap', {})
            .then(function(res) {
              siteCateList = res.data.data.items;

              if (!siteCateList || !siteCateList.length) {
                commonSVC.showMessage('등록된 카테고리 정보가 없습니다.\n쇼핑몰에서 카테고리 생성 후 다시 시도해 주세요.');

                return false;
              } else {
                siteCateList.forEach(function(v) {
                  if (v.category_depth == 1) {
                    $scope.shopCate[0][v.category_no] = v.category_name;
                  }
                });

                if ($scope.matchingData.shop_cate_no_add_info) {
                  if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
                    matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
                  } else {
                    matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
                  }
                } else if (!$scope.matchingData.sol_cate_shop_add_info) {
                  matchDetailDataLoad(angular.copy(B616categorySVC.category_data));
                } else {
                  matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
                }

              }
            });
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        });
    }

    function categoryLoad() {
      $timeout(function () {
        if (load_cate[0]) {
          $(`#CatMenu_0 option[value='${load_cate[0].category_code}']`).attr('selected', 'selected');
        }

        if (load_cate[1]) {
          $(`#CatMenu_1 option[value='${load_cate[1].category_code}']`).attr('selected', 'selected');
        }

        if (load_cate[2]) {
          $(`#CatMenu_2 option[value='${load_cate[2].category_code}']`).attr('selected', 'selected');
        }

        if (load_cate[3]) {
          $(`#CatMenu_3 option[value='${load_cate[3].category_code}']`).attr('selected', 'selected');
        }

        if (load_cate[4]) {
          $(`#CatMenu_4 option[value='${load_cate[4].category_code}']`).attr('selected', 'selected');
        }
      }, 500);
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo;

      return commonSVC.requestPA(userInfo, 'cafe24.com', action, addData, commonModel, shopAccountModel);
    }

    const matchDetailDataLoad = async (addInfo) => {

      if (!addInfo.category_code) {
        $scope.loading = false;

        return false;
      }

      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;

      $scope.Cate = { ...addInfo.Cate };
      category_code = $scope.CateData.category_code.split('>');
      category_names = $scope.CateData.category_names.split(' > ');

      //카테고리 이름 앞뒤 공백제거
      angular.forEach(category_names, function(row) {
        row.trim();
      });

      load_cate = [];

      for (let i = 0; i < category_code.length; i++) {
        load_cate.push({ category_code: category_code[i], category_name: category_names[i] });
      }

      for (let i = 0; i < load_cate.length; i++) {
        $scope.CateData[$scope.CateList[i]] = load_cate[i].category_code;
        setCateSub(i, load_cate[i].category_code);
      }

      //등록된 카테고리 셋팅
      categoryLoad();
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
