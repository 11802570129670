'use strict';

/**
 * 원가 템플릿 모달 컨트롤러
 */
angular.module('gmpApp')
  .controller('ProdCostTemplateManageCtrl', function($uibModalInstance, $scope, settings, commonSVC, $compile, $rootScope) {
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'template_create',
        sdate: '2001-01-01',
        edate: moment().format('YYYY-MM-DD'),
        flag: 'gmp'
      },
      searchData: {
        totalCount: 0,
        showCount: 10,
        selectCount: 0,
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '템플릿 코드', value: 'template_no' },
          { label: '원가명', value: 'template_name' },
        ]
      }
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.grid = {};
    $scope.grid.methods = {};

    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.grid.searchData = angular.copy(search.searchData);
    $scope.grid.searchForm = angular.copy(search.searchForm);
    $scope.grid.searchDetail = '';

    $scope.grid.options = {
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      enablePaginationControls: true,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['created_at'],
      alignCenterColumns: ['widget', 'mdate', 'template_no', 'template_name'],
      notSortingColumns: ['widget'],
      notVisibleColumns: [],
      notMovingColumns: ['widget'],
      notResizingColumns: [],
      initPagingSize: 10,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/prod-cost-templates`,
        requestWillAction: function (d) {
          const data = angular.merge({ sol_no: $rootScope.user_profile.sol_no }, d, $scope.searchForm, $scope.searchData);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 150,
          template: function (row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.template_no}')" >상세</button><button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.copy('${row.template_no}')" >복사</button><button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.delete('${row.template_no}')" >삭제</button>`;
          }
        },
        {
          key: 'template_no',
          title: '템플릿 코드',
          width: 80,
        },
        {
          key: 'template_name',
          title: '원가명',
          width: 130
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 130,
          filter: 'dateValid'
        },
      ]
    };

    /**
   * ui-grid 노출 건수 설정
   */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
   * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
   */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $scope.searchDo = function (refresh, noDelay) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    $scope.resetDo = function() {
      search.searchData.showCount = $scope.searchData.showCount;
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData = angular.copy(search.searchData);

      $scope.searchDo(true, true);
    };

    $scope.deleteTemplate = () => {
      const templateList = $scope.grid.methods.selectedData();
      const filterTemplate = templateList.filter(t => !['TEMP', 'REQ', 'REJ'].includes(t.inspection_status)); // 임시저장, 심사중, 반려 상태 템플릿만 삭제
      if (!templateList.length) {
        commonSVC.showMessage('선택된 템플릿이 없습니다.', '삭제하실 템플릿을 선택해주세요.');

        return;
      } else if (filterTemplate.length) {
        commonSVC.showMessage('템플릿 삭제 불가', '승인 완료된 템플릿은 삭제할 수 없습니다.');

        return;
      } else {
        commonSVC.showConfirm('삭제 하시겠습니까?', '삭제 시 해당 템플릿은 복구되지 않습니다.', async (isConfirm) => {
          if (isConfirm) {
            try {
              await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/deleteTemplate`, { list: templateList });

              commonSVC.showToaster('success', '성공', '템플릿 삭제 성공');
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              $scope.grid.methods.reloadData(() => {}, true);
            } catch (err) {
              commonSVC.showToaster('error', '실패', err.data.error);
            }
          }
        }
        );
      }
    };

    /**
   * 상세페이지 보여주기
   */
    $scope.showDetail = async (id) => {
      try {
        const result = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/stock/prod-cost/template?template_no=${id}`);

        if (result.data?.results) {
          const template_data = result.data?.results?.template_data;

          for (const key in template_data) {
            template_data[key] = Number(template_data[key]);
          }
          const resolve = {
            data: {
              mode: 'edit',
              template_data: { ...template_data, name: result.data?.results?.template_name, template_no: id }
            }
          };

          const modal = commonSVC.openModal('sg2', resolve, 'ProdCostTemplateDetailCtrl', 'views/prod/product/modals/prod_cost_template_detail.html');

          modal.result.then((re) => {
            if (re === 'success') {
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              $scope.grid.methods.reloadData(function () {}, true);
            }
          });
        } else {
          throw Error('원가 템플릿 조회에 실패하였습니다.');
        }
      } catch (err) {
        return commonSVC.showToaster('error', '조회 실패', err.message || err.data?.messages?.[0]);
      }
    };

    /**
   * 원가 템플릿 복사
   */
    $scope.copy = async (id) => {
      return commonSVC.showConfirm('템플릿 복사', '선택한 원가 템플릿을 복사하시겠습니까?', async (confirm) => {
        if (confirm) {
          try {
            const result = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/stock/prod-cost-template/copy?template_no=${id}`);

            if (result.data?.results === 'success') {
              commonSVC.showToaster('success', '복사 완료', '원가 템플릿 복사에 성공하였습니다.');
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              $scope.grid.methods.reloadData(function () {}, true);
            } else {
              throw Error('원가 템플릿 복사에 실패하였습니다.');
            }
          } catch (err) {
            return commonSVC.showToaster('error', '복사 실패', err.message || err.data?.messages?.[0]);
          }
        }

        return;
      });
    };

    /**
   * 원가 템플릿 삭제
   */
    $scope.delete = async (id) => {
      commonSVC.showConfirm('템플릿 삭제', '선택한 원가 템플릿을 삭제하시겠습니까?', async (confirm) => {
        if (confirm) {
          try {
            const result = await commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/stock/prod-cost-template?template_no=${id}`);

            if (result.data?.results === 'success') {
              commonSVC.showToaster('success', '삭제 완료', '원가 템플릿 삭제에 성공하였습니다.');
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              $scope.grid.methods.reloadData(function () {}, true);
            } else {
              throw Error('원가 템플릿 삭제에 실패하였습니다.');
            }
          } catch (err) {
            return commonSVC.showToaster('error', '삭제 실패', err.message || err.data?.messages?.[0]);
          }
        }

        return;
      });
    };

    /**
   * 원가 템플릿 적용
   */
    $scope.apply = () => {
      if (!$scope.grid.methods.selectedData()?.length) {
        return commonSVC.showMessage('적용 실패', '선택된 템플릿이 없습니다.');
      }
      $uibModalInstance.close({ status: 'success', data: $scope.grid.methods.selectedData()[0] });
    };

    /**
   * 원가 템플릿 등록 모달
   */
    $scope.openCreateModal = () => {
      const modal = commonSVC.openModal('sg2', { data: { mode: 'add' } }, 'ProdCostTemplateDetailCtrl', 'views/prod/product/modals/prod_cost_template_detail.html');
      modal.result.then((re) => {
        if (re === 'success') {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };
  });