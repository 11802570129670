'use strict';

angular.module('gmpApp')
  .service('asSVC', function(commonSVC, userInfo) {
    return {
      // 컬럼 추가 가능 리스트
      asData: {
        req_time: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        acpt_time: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),

        as_sale_name: '',
        as_opt_name: '',
        mend_content: '',
        mender_name: '',
        as_cost: '',
        sku_cd: '',
        prod_no: '',

        ship_method: '',
        ship_cost: '',
        ship_msg: '',

        req_name: '',
        req_email: '',
        req_tel: '',
        req_htel: '',

        to_name: '',
        to_email: '',
        to_tel: '',
        to_htel: '',
        to_zipcd: '',
        to_addr1: '',
        to_addr2: '',
      },
      deliMethodList: ['무료배송', '선결제', '착불'],
      // 버트 활성화 상태
      activBtn: {
        req: ['AS접수', 'AS가능', 'AS불가', 'AS완료', 'AS검수'],
        acpt: ['AS요청', 'AS가능', 'AS불가', 'AS완료', 'AS검수'],
        possible: ['AS접수', 'AS불가', 'AS완료', 'AS검수'],
        impossible: ['AS접수', 'AS가능'],
        complete: ['AS접수', 'AS가능', 'AS불가', 'AS검수'],
        check: ['AS완료', 'AS출고'],
        out: ['AS검수'],
      },
      logField: {
        req_time: 'A/S요청일',
        acpt_time: 'A/S접수일',
        as_com_time: 'A/S완료일',
        review_time: 'A/S심의일',
        chk_time: 'A/S검수일',

        mend_content: 'A/S내용',
        mender_name: 'A/S담당자',
        as_cost: 'A/S비용',
        sku_cd: 'SKU코드',
        depot_name: '배송처',
        as_sale_name: 'A/S상품영',
        as_opt_name: 'A/S옵션명',

        ship_method: '배송방법',
        ship_cost: '배송비',
        ship_msg: '배송메세지',

        req_name: '요청자명',
        req_email: '요청자 이메일',
        req_tel: '요청자 전화번호',
        req_htel: '요청자 휴대폰번호',

        to_name: '수령자명',
        to_email: '수령자 이메일',
        to_tel: '수령자 전화번호',
        to_htel: '수령자 핸드폰번호',
        to_zipcd: '수령자 우편번호',
        to_addr1: '수령자 주소',
        sale_cnt: '수량'
      },
      excelFieldList: [
        { header: '묶음번호', key: 'bundle_no', select: true, width: 30 },
        { header: '쇼핑몰(계정)', key: 'shop', select: false, width: 15 },
        { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: false, width: 15 },
        { header: 'A/S상태', key: 'as_status', select: false, width: 15 },
        { header: '요청자명', key: 'req_name', select: false, width: 15 },
        { header: '요청자전화번호', key: 'req_tel', select: false, width: 20 },
        { header: '요청자휴대폰번호', key: 'req_htel', select: false, width: 20 },
        { header: '수령자명', key: 'to_name', select: true, width: 15 },
        { header: '수령자 전화번호', key: 'to_tel', select: true, width: 20 },
        { header: '수령자 휴대폰번호', key: 'to_htel', select: true, width: 20 },
        { header: '수령자 우편번호', key: 'to_zipcd', select: true, width: 15 },
        { header: '수령자 주소', key: 'to_addr', select: true, width: 45 },
        { header: '배송메세지', key: 'ship_msg', select: true, width: 30 },
        { header: 'A/S비용', key: 'as_cost', select: false, width: 15 },
        { header: 'A/S상품명', key: 'as_sale_name', select: true, width: 40 },
        { header: 'A/S옵션명', key: 'as_opt_name', select: true, width: 30 },
        { header: '수량', key: 'sale_cnt', select: true, width: 10 },
        { header: 'A/S내용', key: 'mend_content', select: true, width: 35 },
        { header: 'A/S담당자', key: 'mender_name', select: false, width: 15 },
        { header: '배송방법', key: 'ship_method', select: true, width: 15 },
        { header: '배송비', key: 'ship_cost', select: true, width: 15 },
        { header: '택배사', key: 'carr_name', select: true, width: 15, },
        { header: '운송장번호', key: 'invoice_no', select: true, width: 15, },
        { header: 'A/S요청일', key: 'req_time', select: false, width: 20, },
        { header: 'A/S접수일', key: 'acpt_time', select: false, width: 20, },
        { header: 'A/S심의일', key: 'review_time', select: false, width: 20, },
        { header: 'A/S완료일', key: 'as_com_time', select: false, width: 20, },
        { header: 'A/S검수일', key: 'chk_time', select: false, width: 20, },
        { header: 'A/S출고일', key: 'as_out_time', select: false, width: 20, },
        { header: '메모', key: 'memo', select: false, width: 10 }
      ]
    };
  });
