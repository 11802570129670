/**
 * 2018-03-30 Daniel
 * 도움말
 */
'use strict';

angular.module('gmpApp')
  .factory('helpSVC', function () {

    return {
      helpTypes: {
        ALL: { title: '전체', count: 0 },
        L101: { title: '상품관리', count: 0 },
        L102: { title: '주문관리', count: 0 },
        L104: { title: '재고관리', count: 0 },
        L105: { title: '설정', count: 0 },
        ANO: { title: '기타', count: 0 }
      },

      searchData: {
        searchWord: '',
        searchListAll: [],
        searchListView: [],
        searchYn: false,
        pageLength: 5,
        page: 0
      },

      detailData: {
        guideNo: '',
        detailYn: false,
        data: {}
      }
    };
  });
