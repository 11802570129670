
/**
 * 2018-01-24  yellow_id Email
 * Pada
 */
'use strict';
angular.module('gmpApp')
  .controller('ConfigsEmail', function ($scope, $uibModalInstance, commonSVC, settings, $rootScope) {

    function init() {
      $scope.emailData = {};
      $scope.emailData.senderEmail = $rootScope.user_profile.email;
      $scope.emailData.content = '';
      $scope.emailData.title = '';
      $scope.emailData.receiverEmail = '';
      checkEmail();
    }
    init();
    function checkEmail() {
      const url = `${settings.pa20ApiUrl}/app/order/get-email-cnt`;

      commonSVC.sendUrl('GET', url, {}, function(state, result) {
        if (state == 'success') {
          $scope.emailData.emailBalance = result[0].sol_ser_remain_cnt;
        }
      });
    }
    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };
    $scope.onSubmit = function() {
      if ($scope.form1.$valid) {
        if ($scope.emailData.emailBalance <= 0) {
          commonSVC.showMessage('잔여건수부족', '이메일 잔여 건수가 부족합니다. 충전 후 사용해주세요');

          return false;
        }

        const emailReg = /\w@\w+\.\w+/;

        const receiveEmails = $scope.emailData.receiverEmail.split('\n');
        let emailCheck = false;

        _.each(receiveEmails, function (e) {
          if (!emailReg.test(e)) {
            emailCheck = true;

            return false;
          }
        });

        if (emailCheck) {
          commonSVC.showMessage('수신자 이메일 주소가 올바르지 않습니다');

          return false;
        }

        if (!emailReg.test($scope.emailData.senderEmail)) {
          commonSVC.showMessage('발신자 이메일 주소가 올바르지 않습니다');

          return false;
        }

        let url = `${settings.pa20ApiUrl}/app/order/send-email`;
        const input = {
          to: $scope.emailData.receiverEmail,
          content: $scope.emailData.content,
          subject: $scope.emailData.title,
          from: $scope.emailData.senderEmail
        };

        commonSVC.sendUrl('POST', url, input, function (state) {
          if (state == 'success') {
            // # 이메일 차감
            const input = {
              cnt: $scope.emailData.receiverEmail.split('\n').length
            };

            url = `${settings.pa20ApiUrl}/app/order/set-email-cnt`;
            commonSVC.sendUrl('PATCH', url, input)
              .then(function() {
                commonSVC.showToaster('success', '성공', '이메일 발송에 성공하셨습니다', '');
                $uibModalInstance.close('success');
              });
          } else {
            commonSVC.showToaster('error', '실패', '이메일 발송에 실패하셨습니다', '');

            return false;
          }
        });
      } else {
        commonSVC.showMessage('모든정보를 입력해주세요');
      }
    };
  });
