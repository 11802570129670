/**
 * 결제내역 그리드 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayGridCtrl', function ($scope, $filter, $location, $state, $timeout, $compile, commonSVC, paySVC, payModel, userInfo, settings, gettextCatalog) {
    const search = $scope.search.pay;

    // 검색영역 폼
    $scope.searchData = search.searchData;
    $scope.searchForm = search.searchForm;

    // 데이터 테이블 관련기능
    search.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      },
      resetDo: function() {
        $scope.resetDo();
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.search[$scope.gridType].searchForm = angular.copy(paySVC.searchForm);
      $scope.search[$scope.gridType].searchDetail = angular.copy(paySVC.searchDetail);
      $scope.searchDo(true, true);
    };

    search.searchBtn = angular.copy($scope.searchBtn);
    $scope.searchBtn.table_actions = [{
      label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
      btn_type: 'dropdown',
      add_class: 'btn-default',
      item_list: [{
        label: gettextCatalog.getString('전체엑셀 다운로드'),
        action: function () {
          $scope.excelDown('all');
        }
      }, {
        label: gettextCatalog.getString('선택엑셀 다운로드'),
        action: function () {
          $scope.excelDown('select');
        }
      }]
    }];

    /**
     * 결제내역 DataTable 설정
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['pay_method', 'cd_name', 'widget'],
      alignRightColumns: ['pay_amt'],
      defaultSortingColumns: ['pay_no'],
      notSortingColumns: ['email', 'title', 'pay_method', 'cd_name', 'widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/list`,
        requestWillAction: function (data) {
          const result = userInfo.permission?.pay?.use_yn !== false ? Object.assign(data, search.searchForm) : {};

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(result);

          // 솔루션정보 조회
          $scope.getSolutionInfo();

          return result;
        },
        requestDidAction: function(result) {
          $scope.payList = result.results;
          if (userInfo.permission?.pay?.use_yn !== false) {
            search.searchData.totalCount = result.recordsTotal;
            $scope.rowTotalCount = result.rowsTotal;

            return result.results;
          }
        }
      },
      columns: [
        {
          key: 'pay_no',
          title: 'No',
          width: 50
        },
        {
          key: 'cash_time',
          title: '결제일',
          width: 110
        },
        {
          key: 'email',
          title: '결제ID',
          width: 150
        },
        {
          key: 'title',
          title: '상세내역',
          width: 200,
          template: function (row) {
            return `
              <a
                href='javascript:void(0);'
                ng-click='grid.appScope.payDetail("${row.pay_no}", "${row.cd_name}", "${row.title}", "${row.email}")'
              >
                ${row.title}
              </a>`;
          }
        },
        {
          key: 'pay_amt',
          title: '결제금액',
          width: 100,
          template: function (row) {
            // 결제금액 자체에서 쿠폰할인금액은 빠지기 떄문에 쿠폰 금액을 뺄 필요가 없음
            return `${$filter('number')(row.pay_amt + row.pay_amt_tax)} 원`;
          }
        },
        {
          key: 'pay_method',
          title: '결제수단',
          width: 100
        },
        {
          key: 'cd_name',
          title: '상태',
          width: 70,
          template: function (row) {
            // 입금대기 상태가 아닌경우(환불 상태) 결제완료로 보여줌.
            return row.cd_name !== '입금대기' ? '결제완료' : row.cd_name;
          }
        },
        {
          key: 'widget',
          title: '도구',
          width: 100,
          tooltip: '전자 세금계산서는 입금하신 날로부터 영업일 기준 1~2일 내에 발행됩니다.',
          template: function (row) {
            //도구
            const payStatus = row.cd_name !== '결제완료';

            return `
              <button
                class="btn btn-primary btn-xxs"
                ng-disabled="${payStatus}"
                ng-click="grid.appScope.taxBillRequest('${row.pay_method}','${row.pg_tid}','${row.tax_invoice_date}','${row.pay_no}');"
              >
                세금계산서 인쇄
              </button>`;
          }
        }
      ]
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length(search.searchData.showCount);
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh = true, noDelay) {
      $scope.viewPayStatus = ['입금대기', '결제완료'];
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(paySVC.payExcelFieldList),
        title: '결제내역',
        url: '/app/pay/excel-down',
        searchForm: $scope.pageData
      };

      if (type === 'all') {
        resolve.data.count = search.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const payList = $scope.grid.methods.selectedData('pay_no');

        if (payList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 결제정보가 없습니다.'));

          return false;
        } else {
          resolve.data.select_list = payList;
          resolve.data.count = payList.length;
          resolve.data.isAll = false;
        }
      }

      resolve.data.searchForm.downType = 'pay';

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 결제 상세 내역
     */
    $scope.payDetail = function (pay_no, pay_status, pay_title, pay_email) {
      // sms 잔여카운트
      let remain_sms_cnt = 0;
      // email 잔여카운트
      let remain_email_cnt = 0;
      // 알림톡 잔여카운트
      let remain_atalk_cnt = 0;
      // 번역 잔여카운트
      let remain_trnsl_cnt = 0;

      _.forEach($scope.solSerInfo, function(o) {
        if (o.sol_ser === 'sms') {
          remain_sms_cnt = o.sol_ser_remain_cnt;
        } else if (o.sol_ser === 'email') {
          remain_email_cnt = o.sol_ser_remain_cnt;
        } else if (o.sol_ser === '알림톡') {
          remain_atalk_cnt = o.sol_ser_remain_cnt;
        } else if (o.sol_ser === '번역') {
          remain_trnsl_cnt = o.sol_ser_remain_cnt;
        }
      });

      const params = {
        pay_no: pay_no,
        pay_status: pay_status,
        pay_title: pay_title,
        closeFlag: true,
        remain_sms_cnt: remain_sms_cnt,               // SMS 잔여카운트 넘김
        remain_email_cnt,                             // EMAIL 잔여카운트 넘김
        remain_atalk_cnt,                             // 알림톡 잔여카운트 넘김
        remain_trnsl_cnt,                             // 번역 잔여카운트 넘김
        dayPrice: $scope.solutionInfo.dayPrice,       // 해당버전의 일 금액
        pay_email: pay_email,
        pay_list: $scope.payList,
        solutionProductInfo: $scope.solutionProductInfo
      };

      const modal = commonSVC.openModal('lg', { data: params }, 'PayDetailCtrl', 'views/pay/modals/pay_detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * 세금계산서 발행
     */
    $scope.taxBillRequest = function (pay_method, pg_tid, wdate_paper, pay_no) {
      if (pay_method === '카드') {
        // commonSVC.showMessage 로 팝업을 띄울경우 팝업이 뜨기전에 새창이 열리기 때문에 아래와같이 처리.
        commonSVC.showMessage('카드결제의 경우는 카드영수증 전표가 세금계산서를 대체합니다.', '',
          function () {
          // 나이스페이 주소 변경으로 수정.
          // window.open('https://pg.nicepay.co.kr/issue/IssueLoader.jsp?TID=' + pg_tid + '&type=0');
            window.open(`https://npg.nicepay.co.kr/issue/IssueLoader.do?TID=${pg_tid}&type=0`);
          });
      } else if (pay_method === '가상계좌') {
        commonSVC.showMessage(gettextCatalog.getString('가상계좌 결제시 신청한 국세청 현금영수증이 세금계산서를 대체합니다.'));
      } else {
        if (wdate_paper === null || wdate_paper === 'null') {
          commonSVC.showMessage('세금계산서 인쇄 안내',
            '입금 확인이 완료되어 전자 세금계산서 발송을 준비중입니다.\n\n입금 내역 확인 후 약 1일(영업일 기준) 이내에 전자세금계산서를 발행해 드리고 있습니다.\n(전자세금계산서는 결제완료 내역 또는 전자 세금계산서 담당자의 이메일에서 확인 가능)\n\n1일 이내에 전자 세금계산서가 확인되지 않을 경우, 1:1문의로 접수해주세요.'
          );
        } else {

          // 프리빌 인증키 받아오기.
          payModel.getFreebillAuthkey('', function (status, data) {
            // 세금계산서 조회페이지 주소 조회.
            // http://www.freebill.co.kr/web/api/GETVIEWURL/d8bce031463a5495efbf9d05c5780109/256566
            const params = {
              authkey: data.split('|')[1],
              pay_no: `GMP_${pay_no}`
            };

            payModel.getUrlFreebill(params, function (status, retUrl) {
              if (status === 'success') {
                window.open(retUrl, '');
              } else {
                commonSVC.showMessage(gettextCatalog.getString(retUrl.data.error || retUrl.data));
              }
            });
          });
        }
      }
    };
  });