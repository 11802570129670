'use strict';

angular.module('gmpApp')
  .controller('ConfigsExRateCalcCtrl', function ($scope, $uibModalInstance) {
    $scope.close = $uibModalInstance.close;
    $scope.rate_value_first = '0';
    $scope.rate_value_second = '0.00';
    $scope.calc = calc;
    $scope.code_value_first = 'us';
    $scope.code_value_second = 'kr';

    $scope.rate_list = [
      { code: 'kr', name: '대한민국 KRW' },
      { code: 'us', name: '미국 USD' },
      { code: 'jp', name: '일본 JPY' }
    ];

    $scope.textStyle = { color: 'black' };
    function calc() {
      if ($scope.rate_value_first == 0 || $scope.rate_value_first == null) {
        $scope.textStyle = { color: 'black' };
      } else {
        $scope.textStyle = { color: 'red' };
      }

      const str = String($scope.rate_value_first * 1000);

      $scope.rate_value_second = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    }

    // $scope.$on('rate_value_first', function(event, args) {
    //   $log.debug(event);
    // });
  });
