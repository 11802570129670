/**
 *  어플리케이션 전역 설정
 */
'use strict';

angular.module('static.settings', ['environment'])
  .factory('settings', function($log, $location, environment) {

    // console.log('----------------environment----------------');
    // console.log(environment);

    // 이미지 절대 경로
    const imagePath = '../images';

    // 터널링 로컬 데몬 여부
    const is_tunnel = $location.$$host.indexOf('matthew.co.kr') > -1;
    const backend_url = is_tunnel ? `http://${$location.$$host.replace(/^(.+)00/, '')}:${$location.$$port}` : `http://${$location.$$host}:8081`;

    // 2017-05-19 MatthewKim local dist serve 포트도 추가
    window.debug = !!(is_tunnel || $location.$$port === 9000 || $location.$$port === 9002 || $location.$$port === 8088);
    $log.warn('debug_mode: %s', debug);
    // console.trace();

    // 전역 settings 변수 설정
    const settings = {

      layout: {
        pageSidebarClosed: false, // sidebar menu state
        pageContentWhite: true, // set page content layout
        pageBodySolid: true, // solid body color state
        pageAutoScrollOnLoad: 1000 // auto scroll to top on page load
      },
      debug: debug,
      env: environment.ENV,

      globalPath: `${imagePath}/global`,
      layoutPath: `${imagePath}/layouts`,

      apiUrl: debug ? backend_url : environment.API_URL,
      pa20ApiUrl: environment.PA20_API_URL,
      adminApiUrl: environment.ADMIN_API_URL,
      engineApiUrl: debug ? 'https://kr.playapi.playauto.org' : environment.PLAYAPI_URL,
      wwwUrl: environment.WWW_URL,
      wwwApiUrl: environment.WWW_API_URL,

      appUrl: environment.APP_URL,
      socketUrl: environment.SOCKET_URL,
      wakeupUrl: environment.WAKEUP_URL,
      wakeupApiUrl: environment.WAKEUP_API_URL,

      chrome_app_id: environment.CHROME_APP_ID, // 익스텐션 ID
      playapi_ws: 'wss://kr-ws.playapi.playauto.org', // 2018-04-03 matthew 작업관리 실시간 수신 소켓 서버
      ebayTokenUrl: `${environment.TOKEN_API.EBAY_API_URL}?client_id=${environment.TOKEN_API.EBAY_CLIENT_ID}&response_type=code&redirect_uri=${environment.TOKEN_API.EBAY_RECT_URI}&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly`,
      lazadaTokenUrl: `${environment.TOKEN_API.LAZADA_TOKEN_URL}${environment.APP_URL}/action.html&client_id=${environment.TOKEN_API.LAZADA_CLIENT_ID}`,
      buymaTokenUrl: `${environment.TOKEN_API.BUYMA_API_URL}?response_type=code&client_id=${environment.TOKEN_API.BUYMA_CLIENT_ID}&redirect_uri=${environment.APP_URL}/action.html?mode=buymaToken&state=qwerty`,
      wishTokenUrl: `${environment.TOKEN_API.WISH_API_URL}?client_id=${environment.TOKEN_API.WISH_CLIENT_ID}`,

      // APP 아닌경우에 셋폼 URL 주소 변경
      setFormUrl: 'app.playauto.io' != window.location.host ? 'https://setform.playapi.playauto.org' : 'https://setform.playapi.io',

      // 언어 목록 설정 2017-03-22 MatthewKim
      langs: {
        ja: {
          can_use: true, // 사용 가능 여부
          lang: 'ja',     // (iso639) 언어 코드 https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes#Partial_ISO_639_table
          lang_name: 'Japanese', // 언어코드의 영문이름
          lang_name_orig: '日本語', // 언어코드의 자국언어 이름
          i18n: 'ja_JP',  // (i18n) 언어_문화권 코드
          country: 'jp',  // (iso3166) 국가 코드 https://www.iso.org/obp/ui/#search alpha-2
          timezone: 'Asia/Tokyo', // (Timezone) 타임존 코드 https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        },
        ko: {
          can_use: true, // 사용 가능 여부
          lang: 'ko',
          lang_name: 'Korean',
          lang_name_orig: '한국어',
          i18n: 'ko_KR',
          country: 'kr',
          timezone: 'Asia/Seoul',
        },
        en: {
          can_use: true, // 사용 여부
          lang: 'en',
          lang_name: 'English',
          lang_name_orig: 'English',
          i18n: 'en',
          country: 'us',
          timezone: 'EST',
        },
      },

      // 폰트 목록 설정 2017-03-22 MatthewKim
      fonts: {

        // 한국어
        ko: {
          default: {
            code: 'default', // 폰트 고유 코드 (키명과 일치시키세요)
            can_use: true, // 폰트 목록 노출 여부
            font_name: '기본(윈도우즈)', // 폰트 이름
            style: `
              @charset "utf-8";
              /*@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);*/
              @import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
              
              body, html, .sweet-alert{
                  font-family: 'Nanum Gothic', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: bold;
              }
            `
          },
          mac: {
            code: 'mac',
            can_use: true,
            font_name: '기본(맥)',
            style: `
              @charset "utf-8";
              /*@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);*/
              /*@import url(//fonts.googleapis.com/earlyaccess/jejugothic.css);*/
              
              body, html, .sweet-alert{
                  font-family: sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }            
            `
          },
          nanumgothic: {
            code: 'nanumgothic',
            can_use: true,
            font_name: '나눔고딕',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);

              body, html, .sweet-alert{
                  font-family: 'Nanum Gothic', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          nanumgothiccoding: {
            code: 'nanumgothiccoding',
            can_use: true,
            font_name: '나눔고딕코딩(고정폭)',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/nanumgothiccoding.css);

              body, html, .sweet-alert{
                  font-family: 'Nanum Gothic Coding', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          nanummyeongjo: {
            code: 'nanummyeongjo',
            can_use: true,
            font_name: '나눔명조',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);

              body, html, .sweet-alert{
                  font-family: 'Nanum Myeongjo', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          notosanskr: {
            code: 'notosanskr',
            can_use: true,
            font_name: 'Noto Sans KR',
            style: `
              @charset "utf-8";
              @import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

              body, html, .sweet-alert{
                  font-family: 'Noto Sans KR', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          spoqahansans: {
            code: 'spoqahansans',
            can_use: true,
            font_name: 'Spoqa Han Sans',
            style: `
              @charset "utf-8";
              @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
              
              body, html, .sweet-alert{
                  font-family: 'Spoqa Han Sans', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          jeju: {
            code: 'jeju',
            can_use: true,
            font_name: '제주고딕',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/jejugothic.css);
              
              body, html, .sweet-alert{
                  font-family: 'Jeju Gothic', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          jejuhallasan: {
            code: 'jejuhallasan',
            can_use: true,
            font_name: '제주한라산',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/jejuhallasan.css);

              body, html, .sweet-alert{
                  font-family: 'Jeju Hallasan', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          kopubbatang: {
            code: 'kopubbatang',
            can_use: true,
            font_name: 'KoPub바탕',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/kopubbatang.css);

              body, html, .sweet-alert{
                  font-family: 'KoPub Batang', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          hanna: {
            code: 'hanna',
            can_use: true,
            font_name: '한나체',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/hanna.css);

              body, html, .sweet-alert{
                  font-family: 'Hanna', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          }
        },

        // 일본어
        ja: {
          default: {
            code: 'default',
            can_use: true,
            font_name: '基本(windows)',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);

              body, html, .sweet-alert{
                  font-family: 'Noto Sans JP', sans-serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          mac: {
            code: 'mac',
            can_use: true,
            font_name: '基本(mac)',
            style: `
              @charset "utf-8";
              /*@import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);*/
              
              body, html, .sweet-alert{
                  font-family: sans-serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          mplus1p: {
            code: 'mplus1p',
            can_use: true,
            font_name: 'Mplus 1p',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/mplus1p.css);

              body, html, .sweet-alert{
                  font-family: 'Mplus 1p', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          hannari: {
            code: 'hannari',
            can_use: true,
            font_name: 'Hannari',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/hannari.css);

              body, html, .sweet-alert{
                  font-family: 'Hannari', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          notosansjp: {
            code: 'notosansjp',
            can_use: true,
            font_name: 'Noto Sans Japanese',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

              body, html, .sweet-alert{
                  font-family: 'Noto Sans Japanese', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          spoqahansans: {
            code: 'spoqahansans',
            can_use: true,
            font_name: 'Spoqa Han Sans',
            style: `
              @charset "utf-8";
              @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);

              body, html, .sweet-alert{
                  font-family: 'Spoqa Han Sans JP', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          sawarabigothic: {
            code: 'sawarabigothic',
            can_use: true,
            font_name: 'Sawarabi Gothic (さわらびゴシック)',
            style: `
              @charset "utf-8";
              @import url(//fonts.googleapis.com/earlyaccess/sawarabigothic.css);

              body, html, .sweet-alert{
                  font-family: 'Sawarabi Gothic', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          }
        },
        // 영어
        en: {
          default: {
            code: 'default',
            can_use: true,
            font_name: 'Default (Windows)',
            style: `
              @charset "utf-8";
              /*@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);*/
              @import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);

              body, html, .sweet-alert{
                  font-family: 'Nanum Gothic', "Apple SD Gothic Neo", "malgun gothic", "맑은 고딕", dotum, "돋움", sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          },
          mac: {
            code: 'mac',
            can_use: true,
            font_name: 'Default (Mac)',
            style: `
              @charset "utf-8";

              body, html, .sweet-alert{
                  font-family: sans-serif, serif !important;
                  font-size: 14px;
                  color: #333333 !important;
              }
              b, strong {
                  font-weight: 500;
              }
            `
          }
        }

      },

      // 현재 언어
      current_lang: {},

      // 현재 폰트
      current_font: {},
    };

    return settings;

  });
