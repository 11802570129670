/**
 * Created by ally on 2017. 2. 9..
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderCustomerInquiryForewordCtrl', function ($scope, data, $uibModalInstance, commonSVC, customerInquiryModel) {
    $scope.data = data.data;
    /**
     * 취소
     */
    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 수정
     */
    $scope.saveForeword = function() {
      customerInquiryModel.saveForeword($scope.data)
        .then(function() {
          commonSVC.showToaster('success', '성공', '머리말/꼬리말 수정 완료');
          $uibModalInstance.close();
        })
        .catch(function(err) {
          commonSVC.showToaster('error', '실패', '머리말/꼬리말 수정 실패.');

          return err;
        });
    };

  });