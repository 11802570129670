'use strict';

angular.module('gmpApp')
  .factory('B502categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });