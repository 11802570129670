/**
 * 2018-03-30 Daniel
 * 도움말
 */
'use strict';

angular.module('gmpApp')
  .service('helpModel', function (settings, commonSVC) {
    const Url = `${settings.pa20ApiUrl}/app/guide`;

    return {
      /**
       * 추천도움말 조회
       */
      getRcmdHelp: function (data) {
        const url = `${Url}/help`;

        const params = {
          col: [
            'guide_no',
            'title'
          ],
          where: [
            {
              Col: 'rcmd_yn',
              Val: '1',
              Type: 'AND'
            },
            {
              Col: 'guide_type',
              Val: "'help'"
            }
          ],
          order: 'mdate DESC',
          limit: '5'
        };

        if (data) {
          params.where.unshift({
            Col: 'guide_type_sub_cd',
            Val: data,
            NotEqual: true,
            Type: 'AND'
          });
        }

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 자주묻는질문 조회
       */
      getPvHelp: function (data, like = '') {
        const url = `${Url}/help`;
        const params = {
          col: [
            'guide_no',
            'guide_type_sub_cd',
            'title'
          ],
          where: [
            {
              Col: 'guide_type',
              Val: "'help'",
              Type: 'AND'
            },
            {
              Col: 'title',
              Val: `LIKE '%${like}%'`,
              NotEqual: true,
              Type: 'OR'
            },
            {
              Col: 'reply_content',
              Val: `LIKE '%${like}%'`,
              NotEqual: true,
              Type: 'OR'
            },
            {
              Col: 'keyword',
              Val: `LIKE '%${like}%'`,
              NotEqual: true
            }
          ],
          order: 'pv_cnt DESC',
          limit: '5',
        };

        if (data) {
          params.where.unshift({
            Col: 'guide_type_sub_cd',
            Val: data,
            NotEqual: true,
            Type: 'AND'
          });
        }

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 도움말 검색
       */
      getSearchHelp: function (data) {
        const url = `${Url}/help`;
        const params = {
          col: [
            'guide_no',
            'guide_type_sub_cd',
            'title'
          ],
          where: [
            {
              Col: 'guide_type',
              Val: "'help'",
              Type: 'AND'
            },
            {
              Col: 'title',
              Val: `LIKE '%${data}%'`,
              NotEqual: true,
              Type: 'OR'
            },
            {
              Col: 'reply_content',
              Val: `LIKE '%${data}%'`,
              NotEqual: true,
              Type: 'OR'
            },
            {
              Col: 'keyword',
              Val: `LIKE '%${data}%'`,
              NotEqual: true
            }
          ]
        };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 도움말 상세
       */
      getHelpDetail: function (guideNo) {
        const url = `${Url}/help/${guideNo}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 인기검색어 조회
       */
      getPopWord: function () {
        const url = `${Url}/help`;
        const params = {
          col: [
            'title'
          ],
          where: [
            {
              Col: 'guide_type',
              Val: "'help_keyword'"
            }
          ]
        };

        return commonSVC.sendUrl('POST', url, params);
      },
    };
  });
