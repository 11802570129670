'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A124-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, commonModel, shopAccountModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.openTemplate = _.cloneDeep(data.openTemplate);
    $scope.detailType = _.cloneDeep(data.data.detailType);
    $scope.gdsMginPct = '';
    $scope.pa5_job_data = {
      get_commission: {
        load: false,
        data: []
      },
      get_middle_category_filter: {
        load: false,
        data: []
      }
    };

    // 확인 버튼
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      $scope.row.set_info.key_videoCloudId = $scope.videoList.map(({ videoValue }) => videoValue);

      $scope.row.set_info.key_usrDefiArtiNm = $scope.artiDataList.map(({ artiTitle }) => artiTitle);
      $scope.row.set_info.key_usrDefiArtiVal = $scope.artiDataList.map(({ artiValue }) => artiValue);

      if ($scope.row.set_info.tmp_launchYN === 'N') {
        $scope.row.set_info.key_launchYYMM = null;
      } else if ($scope.row.set_info.key_launchYYMM === 'Y') {
        if (!$scope.row.set_info.key_launchYYMM) {
          commonSVC.showMessage('출시일자를 선택해 주세요.');

          return false;
        }
      }

      if ($scope.row.set_info.key_usrDefiArtiYN === '1' && !$scope.artiDataList.length) {
        commonSVC.showMessage('추가노출항목 \'사용\'시 항목을 1개 이상 입력해야 합니다.');

        return false;
      }

      if (!$scope.row.set_info.key_sel_gdsSize) {
        commonSVC.showMessage('사이즈를 선택해 주세요.');

        return false;
      }

      const middleDataList = $scope.middleCateFilter;
      const result = {};

      try {
        middleDataList.map((middleData, index) => {
          result[middleData.key] = [];

          if (middleData.fieldtype === 'SEL') {
            for (const [key, value] of Object.entries(
              middleData.key_middleCateValue
            )) {
              result[middleData.key].push(value);
            }
          } else {
            middleData.option.forEach((filtered) => {
              if (filtered.checked) {
                result[middleData.key].push(filtered.value);
              }
            });
          }
        });
      } catch (e) {
        commonSVC.showMessage('중카테고리 필터를 확인하세요');

        return false;
      }

      $scope.row.set_info.key_middleCate = result;

      const ctgColorList = $scope.row.set_info.key_CtgFilter_404_1.filter(v => v !== '');
      const setColor = new Set(ctgColorList);
      if (ctgColorList.length !== setColor.size) {
        commonSVC.showMessage('중복된 컬러계열이 있습니다.');

        return false;
      }

      const ctgEcoList = $scope.row.set_info.key_CtgFilter_404_3.filter(v => v !== '');
      const setEco = new Set(ctgEcoList);
      if (ctgEcoList.length !== setEco.size) {
        commonSVC.showMessage('중복된 자재등급이 있습니다.');

        return false;
      }

      if (ctgColorList.includes('') || !$scope.row.set_info.key_CtgFilter_404_2 || ctgEcoList.includes('')) {
        commonSVC.showMessage('공통필터 정보를 확인하세요');

        return false;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    // 닫기 버튼
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    //추가노출 항목
    $scope.artiDataList = [];

    $scope.addArti = () => {
      $scope.artiDataList.push({
        artiTitle: '',
        artiValue: ''
      });
    };
    $scope.removeArti = (p0) => {
      $scope.artiDataList.splice(p0, 1);
    };

    //동영상id 항목
    $scope.videoList = [];

    $scope.addVideo = () => {
      $scope.videoList.push({
        videoValue: ''
      });
    };
    $scope.removeVideo = (p0) => {
      $scope.videoList.splice(p0, 1);
    };

    //공통필터 > 컬러계열 항목
    const colorList = [
      { value: '1', name: '화이트계열' },
      { value: '2', name: '베이지계열' },
      { value: '3', name: '그레이계열' },
      { value: '4', name: '브라운계열' },
      { value: '5', name: '블랙계열' },
      { value: '6', name: '노랑계열' },
      { value: '7', name: '오크계열' },
      { value: '8', name: '월넛계열' },
      { value: '9', name: '해당없음' },
      { value: '10', name: '메이플계열' },
      { value: '11', name: '초록계열' },
      { value: '12', name: '파랑계열' },
      { value: '13', name: '보라계열' },
      { value: '14', name: '빨강계열' },
      { value: '16', name: '아이보리계열' },
      { value: '17', name: '핑크계열' },
      { value: '18', name: '내추럴계열' },
      { value: '19', name: '네이비계열' },
    ];
    $scope.ctgDataList_color_arr = [];
    if ($scope.row.set_info.key_CtgFilter_404_1?.length) {
      $scope.row.set_info.key_CtgFilter_404_1.map(() =>
        $scope.ctgDataList_color_arr.push(colorList)
      );
    }

    $scope.addColor = () => {
      if ($scope.ctgDataList_color_arr.length > colorList.length - 1) {
        commonSVC.showMessage('등록된 속성 개수까지만 추가가 가능합니다.');

        return false;
      } else {
        $scope.ctgDataList_color_arr.push(colorList);
        $scope.row.set_info.key_CtgFilter_404_1.push('');
      }
    };
    $scope.removeColor = (p0) => {
      if ($scope.ctgDataList_color_arr.length === 1) {

        return false;
      }
      $scope.ctgDataList_color_arr.splice(p0, 1);
      $scope.row.set_info.key_CtgFilter_404_1.pop();
    };

    //공통필터 > 자재등급 항목
    const ecoGrade = [
      { value: '2', name: 'E0' },
      { value: '3', name: 'E1' },
      { value: '4', name: 'E2' },
      { value: '5', name: '해당없음' },
    ];

    $scope.ctgDataList_eco_arr = [];
    if ($scope.row.set_info.key_CtgFilter_404_3?.length) {
      $scope.row.set_info.key_CtgFilter_404_3.map(() =>
        $scope.ctgDataList_eco_arr.push(ecoGrade)
      );
    }

    $scope.addEco = () => {
      if ($scope.ctgDataList_eco_arr.length > ecoGrade.length - 1) {
        commonSVC.showMessage('등록된 속성 개수까지만 추가가 가능합니다.');

        return false;
      } else {
        $scope.ctgDataList_eco_arr.push(ecoGrade);
        $scope.row.set_info.key_CtgFilter_404_3.push('');
      }
    };

    $scope.removeEco = (p0) => {
      if ($scope.ctgDataList_eco_arr.length === 1) {

        return false;
      }
      $scope.ctgDataList_eco_arr.splice(p0, 1);
      $scope.row.set_info.key_CtgFilter_404_3.pop();
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.getCommission = async () => {
      await run_pa5_jobs('get_commission', '수수료', {}, (data) => {
        $scope.gdsMginPct = data.saleChnlGdsMginPct;

        return data;
      });
    };

    $scope.getMiddleCateList = async () => {
      $('.box-loading').removeClass('hidden'); //로딩중
      const match_cate_code = $scope.data.shop_cate_cd ? $scope.data.shop_cate_cd : ($scope.shop_data?.match_cate_code || $scope.data.sol_cate_shop_add_info?.category_code);

      if (!match_cate_code) {
        commonSVC.showMessage('카테고리 정보가 없습니다.');

        return;
      }
      const category_number = match_cate_code.split('_')[1];

      await run_pa5_jobs('get_middle_category_filter', '중카테고리 필터', { category_number }, (data) => {
        $scope.middleCateFilter = data;

        return data;
      });
    };
    const init = async () => {
      try {
        await $scope.getCommission();
        await $scope.getMiddleCateList();

      } catch (e) {
        commonSVC.showMessage('실패', '품목코드 정보 로드를 실패했습니다.');
      }

      if ($scope.row.set_info.key_videoCloudId) {
        for (let i = 0; i < $scope.row.set_info.key_videoCloudId.length; i += 1) {
          $scope.videoList.push({
            videoValue: $scope.row.set_info.key_videoCloudId[i]
          });
        }
      }

      if ($scope.row.set_info.key_launchYYMM) {
        $scope.row.set_info.tmp_launchYN = 'Y';
      }

      if ($scope.row.set_info.key_usrDefiArtiNm) {
        for (let i = 0; i < $scope.row.set_info.key_usrDefiArtiNm.length; i += 1) {
          $scope.artiDataList.push({
            artiTitle: $scope.row.set_info.key_usrDefiArtiNm[i],
            artiValue: $scope.row.set_info.key_usrDefiArtiVal[i]
          });
        }
      }

      if ($scope.row.set_info.key_Question) {
        for (let i = 0; i < $scope.row.set_info.key_Question.length; i += 1) {
          $scope.qnaDataList.push({
            qnaTitle: $scope.row.set_info.key_Question[i],
            qnaValue: $scope.row.set_info.key_Answer[i]
          });
        }
      }

      // 선택된 필터 체크 처리
      if (!Array.isArray($scope.row.set_info.key_middleCate)) {
        const select = $scope.row.set_info.key_middleCate;
        const dataList = $scope.middleCateFilter;

        for (const [key, selectedVal] of Object.entries(select)) {
          const findIndex = dataList.findIndex((data) => data.key === key);

          dataList[findIndex] = {
            ...dataList[findIndex],
            key_middleCateValue: {},
          };
          for (const value of Object.values(selectedVal)) {
            if (dataList[findIndex].fieldtype === 'SEL') {
              dataList[findIndex].key_middleCateValue[findIndex] = value;
            } else {
              dataList[findIndex].option.forEach((filtered => {
                selectedVal.map((selected) => {
                  if (selected === filtered.value) {
                    filtered.checked = true;
                  }
                });
              }));
            }
          }
        }
      }
    };

    init();

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {};

      resolve.data = {
        systemList: systemList,
        shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
        ol_shop_no: $scope.data.ol_shop_no,
        shop_cd: $scope.data.shop_cd
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });