'use strict';

angular.module('gmpApp')
  .controller('LogDelHistoryCtrl', function ($state, $rootScope, $scope, $compile, $filter, $timeout, $log, settings, systemList, userInfo, gettextCatalog, commonSVC, historySVC, stockExceptionSVC) {
    /**
     * 2018-02-27 Daniel
     * 삭제 내역 관리
     */
    $scope.tabs = [
      {
        type: '',
        name: '전체'
      },
      {
        type: 'prod',
        name: '재고',
        viewYn: $rootScope.user_profile.sol_stock > 0
      },
      {
        type: 'ol',
        name: '상품'
      },
      {
        type: 'ord',
        name: '주문'
      },
      {
        type: 'mapping_rule',
        name: '매칭규칙'
      },
      {
        type: 'inquiry',
        name: '문의'
      },
      {
        type: 'sol_shop',
        name: '쇼핑몰계정'
      },
      {
        type: 'ord_auto_del',
        name: '주문 자동삭제'
      },
      {
        type: 'work',
        name: '작업'
      },
      {
        type: 'mem',
        name: '사용자'
      },
      {
        type: 'gift',
        name: '사은품 규칙'
      },
      {
        type: 'company_charge',
        name: '이메일'
      },
      {
        type: 'sett',
        name: '정산'
      }
    ];
    $scope.tabActive = 0; // {0: 전체 , 1: 재고, 2: 상품, 3: 주문, 4: 매칭규칙, 5: 문의, 6: 쇼핑몰계정, 7: 주문 자동삭제, 8: 작업, 9: 정산 }

    /**
     * 서치바 관련 변수
     */
    const search = {
      searchArr: null,
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        del_type: '',
        del_type_sub: ''
      },
      searchData: {
        all_checked: false,   // 전체선택 여부
        totalCount: 0,        // 검색전체 건수
        selectCount: 0,       // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '코드', value: 'del_cd' },
          { label: '쇼핑몰ID', value: 'shop_id' },
          { label: '작업자', value: 'wm_name' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('날짜'), value: 'wdate' }
        ]
      },
      searchDetail: [
        {
          // 작업
          title: '작업 전체',
          search_name: 'del_type',
          item_list: [
            { key: '재고', value: 'prod' },
            { key: '상품', value: 'ol' },
            { key: '주문', value: 'ord' },
            { key: '매칭규칙', value: 'mapping_rule' },
            { key: '문의', value: 'inquiry' },
            { key: '쇼핑몰계정', value: 'sol_shop' },
            { key: '주문 자동삭제', value: 'ord_auto_del' },
            { key: '작업', value: 'work' },
            { key: '사용자', value: 'mem' },
            { key: '사은품 규칙', value: 'gift' },
            { key: '이메일', value: 'company_charge' },
            { key: '정산', value: 'sett' }
          ],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 구분
          title: '구분 전체',
          search_name: 'del_type_sub',
          item_list: historySVC.division,
          item_key: 'key',
          item_value: 'value',
          select_value: '',
          filter: function (option) {
            // 주문 자동삭제도 주문과 구분이 같기때문에 조건 걸어줌 - 2019-10-22 Alvin
            if (option.type === 'ord' && $scope.searchForm.del_type === 'ord_auto_del') {
              return true;
            } else {
              return option.type === $scope.searchForm.del_type;
            }
          }
        }
      ]
    };

    const searchDetailConfig = {
      work: {
        title: '작업 전체',
        search_name: 'del_type',
        item_list: [
          { key: '재고', value: 'prod' },
          { key: '상품', value: 'ol' },
          { key: '주문', value: 'ord' },
          { key: '매칭규칙', value: 'mapping_rule' },
          { key: '문의', value: 'inquiry' },
          { key: '쇼핑몰계정', value: 'sol_shop' },
          { key: '주문 자동삭제', value: 'ord_auto_del' },
          { key: '작업', value: 'work' },
          { key: '사용자', value: 'mem' },
          { key: '사은품 규칙', value: 'gift' },
          { key: '이메일', value: 'company_charge' },
          { key: '정산', value: 'sett' }
        ],
        item_key: 'key',
        item_value: 'value',
        select_value: ''
      },
      division: {
        title: '구분 전체',
        search_name: 'del_type_sub',
        item_list: historySVC.division,
        item_key: 'key',
        item_value: 'value',
        select_value: '',
        filter: function(option) {
          // 주문 자동삭제도 주문과 구분이 같기때문에 조건 걸어줌 - 2019-10-22 Alvin
          if (option.type === 'ord' && $scope.searchForm.del_type === 'ord_auto_del') {
            return true;
          } else {
            return option.type === $scope.searchForm.del_type;
          }
        }
      }
    };

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(search.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(search.searchData);

    // 검색영역 상세검색 설정
    $scope.searchDetail = [angular.copy(searchDetailConfig.work), angular.copy(searchDetailConfig.division)];

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all');
              },
              perm_only: ['online.product']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['online.product']
            }
          ]
        }
      ],

      // 검색 영역 선택한 데이터 버튼
      actions_left: [
        // [
        //   {
        //     label: gettextCatalog.getString("내역 삭제"),
        //     btn_type: "button",
        //     action: function () {
        //       del_history_del();
        //     }
        //   }
        // ],

        // 엑셀다운로드
        [
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('all');
            }
          },
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('select');
            }
          }
        ]
      ],

      // 검색 영역 오른쪽 버튼
      actions_right: []
    };

    /**
     * 작업관리 검색 데이터 테이블 관련기능 바인딩
     */
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    // 검색시 실행
    $scope.searchDo = function (refresh = true, noDelay) {
      // 검색시 새로고침
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.selectCount = $scope.tabActive = 0;
      $scope.searchDo(true, true);
    };

    /**
    * 데이터테이블 pageLength 조절
    */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.grid = {};
    $scope.grid.methods = {};

    /**
     * grid 옵션
     * @type {{colReorder: boolean, serverSide: boolean, processing: boolean, searching: boolean, ajax: $scope.grid.options."ajax", rowId: string, buttons: *[], columns: *[]}}
     */
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['wm_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['del_log_no'], //3 1
      notSortingColumns: 'ALL',
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/delete`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },

      buttons: [],
      columns: [
        {
          key: 'del_log_no',
          title: 'NO.',
          width: 10
        },
        {
          key: 'wdate',
          title: '날짜',
          width: 40
        },
        {
          key: 'del_type_sub',
          title: '구분',
          width: 40,
          notCompile: true,
          template: function (row) {
            switch (row.del_type) {
              case 'prod':
                if (row.del_type_sub === 'set') {
                  return '세트상품';
                } else {
                  return 'SKU상품';
                }
              case 'depot': return '배송처';
              case 'supp': return '매입처';
              case 'sol_cate': return '카테고리';
              case 'template': return '템플릿';
              case 'mem': return '사용자';
              case 'ol':
                if (row.del_type_sub === '단일') {
                  return '쇼핑몰 상품(단일)';
                } else if (row.del_type_sub === '일반') {
                  return '쇼핑몰 상품(일반)';
                } else {
                  return '쇼핑몰 상품';
                }
              default: return row.del_type_sub;
            }

          } },
        {
          key: 'content',
          title: '상세',
          width: 150
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 40
        },
      ]
    };

    // 탭 변경
    $scope.changeDelType = function(idx, type) {
      // '정산'탭이면 상세검색 내 '구분'박스 제거
      type === 'sett' ? $scope.searchDetail = [searchDetailConfig.work] : $scope.searchDetail = [angular.copy(searchDetailConfig.work), angular.copy(searchDetailConfig.division)];
      $scope.tabActive = idx;

      // 탭이동시 상세검색 - 작업 자동선택
      $scope.searchForm.del_type = type;
      $scope.searchDetail[0].select_value = type;

      $scope.searchDo(true, true);
    };

    // 엑셀다운
    $scope.excelDown = function (type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(historySVC.excelFieldList),
        title: '삭제 내역',
        url: '/app/history/delete/excelDown',
        searchForm: $scope.pageData,
        page: 'del_history'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    // 2018-04-13 Daniel
    // 재고관리제외버전 추가
    stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);
  });
