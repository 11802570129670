'use strict';

angular.module('gmpApp')
  .controller('SettingsDeliveryLinkedCtrl', function ($scope, $window, $stateParams, commonModel, deliverySVC, deliveryModel, userInfo, commonSVC, gettextCatalog, $rootScope, $timeout, carrierInfo) {

    $scope.pageType = $stateParams.pageType;  // add | edit

    // 보안정보 수정
    $scope.encryptData = {
      password: {
        isEdit: false,
        placeHolder: '수정버튼을 눌러 PW 변경',
        buttonText: '수정'
      }
    };

    let deliveryDataDefault = {};

    init();
    function init() {
      if ($scope.pageType === 'add') {
        initData();
      } else if ($scope.pageType === 'edit') {
        deliveryModel.Load({ carr_no: $stateParams.carr_no, carr_id: $stateParams.carr_id, carr_id_sub: $stateParams.carr_id_sub }, function (state, data) {
          if (state === 'success') {
            deliveryDataDefault = data?.data;
            deliveryDataDefault.carr_id_org = deliveryDataDefault.carr_id;
            deliveryDataDefault.carr_id_sub = deliveryDataDefault.carr_id_sub || '';
            deliveryDataDefault.ori_carr_id_sub = deliveryDataDefault.carr_id_sub || '';

            initData();
          } else {
            commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
          }
        });
      }
    }

    function initData() {
      if ($scope.pageType === 'add') {
        $scope.deliveryData = {
          carr_no: $stateParams.carr_no,
          carr_id: $stateParams.carr_id,
          carr_id_org: $stateParams.carr_id,
          carr_id_sub: '',
          carr_name: carrierInfo[$stateParams.carr_no].carr_name || '',
          emp_yn: 1,
          reg_type: '기존',
          contract_yn: 1,
          reg_confirm_yn: 1,
          idChecked: false
        };

      } else if ($scope.pageType === 'edit') {
        $scope.deliveryData = angular.copy(deliveryDataDefault);
        $scope.deliveryData.idChecked = true;
      }
    }

    // 업체 로고 파일 임시 업로드
    $scope.uploadFile = function (files, name1, name2) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          $scope.deliveryData[name1] = res.data.results[0].url;
          $scope.deliveryData[name2] = res.data.results[0].origFileName;
        });
    };

    $scope.fileClear = function (name1, name2) {
      delete $scope.deliveryData[name1];
      delete $scope.deliveryData[name2];
    };

    // 아이디 확인
    $scope.checkCarrId = function () {
      if (!$scope.deliveryData.carr_id) {
        commonSVC.showMessage('ID를 입력해주세요.');
      } else {
        const params = {
          carr_no: $scope.deliveryData.carr_no.toString(),
          carr_id: $scope.deliveryData.carr_id,
          carr_pwd: $scope.deliveryData.carr_pwd_ori || ''
        };

        deliveryModel.checkCarrierIdExist(params, function (state, data) {
          if (state === 'success') {
            if (data?.data.toUpperCase() === 'Y') {
              commonSVC.showMessage('계정이 확인되었습니다.');
              $scope.deliveryData.idChecked = true;
            } else if (!data?.data.includes('FALSE') && data?.data.includes('Y')) {  //로젠택배
              commonSVC.showMessage('계정이 확인되었습니다.');
              $scope.deliveryData.idChecked = true;
            } else {
              commonSVC.showMessage('입력하신 정보로 계정이 조회되지 않습니다.');
              $scope.deliveryData.idChecked = false;
            }
          } else {
            commonSVC.showToaster('error', '실패', '계정 확인 실패');
            $scope.deliveryData.idChecked = false;
          }
        });
      }
    };

    // 저장
    $scope.submit = function () {
      if (!$scope.deliveryData.idChecked) {
        commonSVC.showMessage('[계정 확인]작업을 진행해주시기 바랍니다.');
      } else if ($scope.deliveryForm.$valid) {
        if ($scope.deliveryData.carr_no === 956) {
          if ($scope.deliveryData.misc2 === 'Y' && !$scope.deliveryData.misc3) {
            commonSVC.showMessage('업체 로고 이미지를 등록해주세요.');

            return false;
          } else if ($scope.deliveryData.misc2 === 'N') {
            $scope.deliveryData.misc3 = '';
          }
        }

        if ($scope.pageType === 'add') {
          insert();
        } else if ($scope.pageType === 'edit') {
          update();
        }
      }
    };

    /**
     * 보안정보 수정 버튼 클릭
     */
    $scope.editEncryptData = (type) => {
      $scope.encryptData[type].isEdit = !$scope.encryptData[type].isEdit;

      if (!$scope.encryptData[type].isEdit) {
        $scope.encryptData[type].placeHolder = `수정버튼을 눌러 ${type} 변경`;
        $scope.encryptData[type].buttonText = '수정';
      } else {
        $scope.encryptData[type].placeHolder = `변경할 ${type}를 입력해주세요`;
        $scope.encryptData[type].buttonText = '취소';
        $(`#new_${type}`).focus();
      }
    };

    // 등록
    async function insert() {
      try {
        await deliveryModel.addDelivery($scope.deliveryData);
        commonSVC.showToaster('success', '성공', '택배사가 등록되었습니다.');
        $scope.loadDeliveryList();
        init();
      } catch (err) {
        commonSVC.showToaster('error', '실패', '택배사 등록 실패');
      }
    }

    // 수정
    function update() {
      deliveryModel.editDelivery($scope.deliveryData, function(state) {
        if (state === 'success') {
          commonSVC.showMessage('성공', '택배사가 수정되었습니다.\n택배사 정보 업데이트를 위해 솔루션이 새로고침 됩니다.', () => {
            $window.location.reload();
          });
        } else {
          commonSVC.showToaster('error', '실패', '택배사 수정 실패');
        }
      });
    }

    // 등록 후 액션
    // function addDeliveryAfterAction() {
    //   var title = '택배사 등록 성공';
    //   var contents = '택배사가 정상 등록 되었습니다. 이후 작업을 선택해주세요';
    //   var buttons = [
    //     {
    //       name: '초기화',
    //       next: function() {
    //         $timeout(function () {
    //           initData();
    //           $scope.loadDeliveryList();
    //         });
    //       }
    //     },
    //     {
    //       name: '데이터 유지',
    //       next: function() {
    //         $timeout(function () {
    //           $scope.loadDeliveryList();
    //         });
    //       }
    //     }
    //   ];

    //   commonSVC.showMultiButton(title, contents, buttons);
    // }

    $scope.cancel = initData;

  });
