'use strict';

angular.module('gmpApp').controller('SettingCateMatching', function ($scope, data, commonSVC, $uibModalInstance, categoryModel) {

  // "sol_cate_no" : $scope.categoryData.code,
  //         "categoryId":$scope.categoryData.code

  //카테고리 아이디, 솔카테넘
  $scope.data = {
    sol_cate_no: data.sol_cate_no,
    categoryId: data.categoryId
  };

  function test(param, flag) {
    const params = {
      code: param,
      flag: flag
    };

    categoryModel.playauto_category(params, function(data) {
      $scope.Lcate = data;
    });
  }

  test('', '');

  //표준카테고리 대분류 선택
  $scope.Lselect = function() {
    delete $scope.p_cate_cd; // 필수값검사를 위해 지움
    const params = {
      Lcode: $scope.categoryData.L,
      flag: 'L'
    };

    categoryModel.playauto_category(params, function(data) {
      $scope.Mcate = data;
    });
  };

  //표준카테고리 중분류 선택
  $scope.Mselect = function() {
    delete $scope.p_cate_cd; // 필수값검사를 위해 지움
    const params = {
      Lcode: $scope.categoryData.L,
      Mcode: $scope.categoryData.M,
      flag: 'M'
    };

    categoryModel.playauto_category(params, function(data) {
      $scope.Scate = data;
    });
  };

  //표준카테고리 소분류 선택
  $scope.Sselect = function() {
    delete $scope.p_cate_cd; // 필수값검사를 위해 지움
    const params = {
      Lcode: $scope.categoryData.L,
      Mcode: $scope.categoryData.M,
      Scode: $scope.categoryData.S,
      flag: 'S'
    };

    categoryModel.playauto_category(params, function(data) {
      $scope.p_cate_cd = data[0].p_cate_cd;
      if (data.length == 1) {
        $scope.categoryData.XS = '00';
        // $("#XSselect").css("display","none")
      }
      $scope.XScate = data;
    });
  };

  $scope.cateSelect = function() {
    if (!$scope.p_cate_cd) {
      commonSVC.showMessage('수정할 카테고리를 선택하세요.');

      return false;
    }

    commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다1.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
      if (confirm) {
        const params = {
          code: $scope.p_cate_cd,
          sol_cate_no: data.sol_cate_no,
          categoryId: data.categoryId
        };

        // 표준카테고리 수정시 체크박스 선택시 이름 변경
        if ($scope.data.p_name_yn) {
          let name = [];

          for (let i = 1; i <= 4; i++) {
            if (i === 4 && $scope.categoryData.XS === '00') {
              break;
            }

            name.push($(`.p_cate${i}`).find('option:selected').text());
          }
          name = name.join(' > ');
          $scope.categoryUpdate(name, data.categoryId);
        }

        categoryModel.playauto_category_mapping(params, function (state, data) {
          if (data == 'error') {
            commonSVC.showToaster('error', '실패', '카테고리 정보 매칭에 실패하였습니다.');
          } else {
            commonSVC.showToaster('success', '성공', '카테고리 정보 매칭에 성공하였습니다.');
            $uibModalInstance.close('success');
          }
        });
      }
    });
  };

  // 카테고리명 수정
  $scope.categoryUpdate = function (name, id) {
    const data = {
      reviseName: name,
      categoryId: id
    };

    categoryModel.categoryNameUpdate(data, function (state, data) {

      if (state == 'error') {
        if (data.data.error == 'ER_DUP_ENTRY') {
          commonSVC.showToaster('error', '', '중복된 이름입니다.');
        } else {
          commonSVC.showToaster('error', '', '이름 변경에 실패하였습니다.');
        }
      } else {
        if (data.results == 'ER_DUP_ENTRY') {
          commonSVC.showToaster('error', '', '중복된 이름입니다.');
        } else {
          commonSVC.showToaster('success', '성공', '이름 변경에 성공하였습니다.');
        }
      }
    });
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

});
