(function () {
  'use strict';

  angular.module('gmpApp')
    .controller('OrderSameCustomerInquiryListCtrl', OrderSameCustomerInquiryListCtrl);

  function OrderSameCustomerInquiryListCtrl ($scope, commonSVC, inq, settings, userInfo, $uibModalInstance, customerInquiryModel) {
    $scope.showPreviousInquiry = false;

    // 창닫기
    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    /*
     * 이전문의내역 노출 여부
     */
    $scope.changeShowPreviousInquiry = function(dataCount) {
      if (dataCount) {
        $scope.showPreviousInquiry = true;
      }
    };

    // 초기화 함수
    async function init() {
      if (inq.inq_id || inq.inq_name) {
        const params = {
          sol_no: userInfo.user.sol_no,
          inq_name: inq.inq_name,
          inq_id: inq.inq_id,
          inq_uniq: inq.inq_uniq
        };

        try {
          const res = await customerInquiryModel.inquiryPrevious(params);
          $scope.inquiryData = [inq, ...res.data.results];
        } catch (err) {
          $scope.inquiryData = [];
          commonSVC.showToaster('error', '실패', '이전문의내역 조회 실패');
        }
      }
    }

    init();
  }
})();