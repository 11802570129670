'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B669-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, commonModel, shopAccountModel, onlineProductModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.cate_code = data.data.cate_cd;
    $scope.data = _.cloneDeep(data.data);
    $scope.data = {
      attribute: []
    };
    $scope.pa5_job_data = {
      get_attributes: {
        load: false,
        data: []
      }
    };

    let prod_name = '';

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      $scope.row.set_info.shop_sale_name = prod_name;
      $scope.data.attribute.forEach(attr => $scope.row.set_info.key_attribute_list[attr.code] = [...attr.applied_list, ...attr.select_list]);
      $scope.row.set_info.key_attribute_list.etc = $scope.drag_attributes;
      $scope.row.set_info.key_point_tmp = Number($scope.row.set_info.point_tmp + '00');
      $scope.row.set_info.key_cupon_price_tmp = Number($scope.row.set_info.cupon_price_tmp + '00');

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.getAttributeList = async () => {
      $('.box-loading').removeClass('hidden'); //로딩중
      await run_pa5_jobs('get_attributes', '상품 속성 검색', { title: prod_name, category_code: data.data.cate_cd }, (job_data) => {
        $scope.data.attribute = [];
        $scope.drag_attributes = job_data.drag_attributes;
        job_data.attributes.forEach(attr => {
          $scope.data.attribute.push ({
            name: attr.name,
            code: attr.code,
            applied_list: attr.value,
            attr_list: angular.copy(job_data.drag_attributes),
            select_list: [],
            select: ''
          });
        });
      });
    };

    // 상품 속성 > 속성 제거
    $scope.removeAttr = (attr, listIdx, idx) => {
      $scope.data.attribute[listIdx].select_list.splice(idx, 1);
      $scope.data.attribute[listIdx].attr_list.push(attr);
      $scope.drag_attributes.push(attr);
    };

    // 상품 속성 > 속성 선택
    $scope.select_attr = (code, select) => {
      const attribute = $scope.data.attribute.find(attr => attr.code === code);

      if (attribute.applied_list.includes(select)) {
        return false;
      }

      if ((attribute.select_list.length + attribute.applied_list.length) > 2) {
        commonSVC.showMessage('실패', '상품 속성은 3개까지 선택 가능합니다.');
        attribute.select = '';

        return false;
      }

      $scope.data.attribute.forEach((attr => {
        const i = attr.select_list.findIndex(s => s === select);

        if (i !== -1) {
          attr.select_list.splice(i, 1);
          attr.attr_list.push(select);
        }
      }));

      let index = $scope.drag_attributes.findIndex(attr => attr === select);
      if (index !== -1) { $scope.drag_attributes.splice(index, 1); }

      attribute.select_list.push(select);
      index = attribute.attr_list.findIndex(attr => attr === select);
      attribute.attr_list.splice(index, 1);
      attribute.select = '';
    };

    const init = async () => {
      prod_name = data.data.shop_sale_name || data.data.prodall.name;
      // 상품속성 조회
      await $scope.getAttributeList();

      // 상품명이 변함 없을 시 기존 값 불러옴
      if ($scope.row.set_info.shop_sale_name === prod_name) {
        Object.keys($scope.row.set_info.key_attribute_list).forEach(key => {

          if (key !== 'etc' && $scope.row.set_info.key_attribute_list[key].length) {
            $scope.row.set_info.key_attribute_list[key].forEach(li => $scope.select_attr(key, li));
          }
        });
      }

    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    init();
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });