/**
 * 주문 선택 분할
 * 2022-10-06 Denver
 */

'use strict';

angular.module('gmpApp')
  .controller('DivideBundleCtrl', function ($scope, data, $uibModalInstance, $timeout, commonSVC, shipmentModel) {

    $scope.ordList = data.ordList;
    $scope.totalDivideList = [];
    // 묶음 기준 판별 배열
    $scope.mainBundle = [];

    $scope.dealList = _.chain($scope.ordList)
      .groupBy(o =>
        !['X099'].includes(o.pa_shop_cd) || !o.misc9 // 샤피 이외 쇼핑몰이거나.. o.misc9 가 비어있을경우
          ? o.uniq
          : o.misc9.length < 5 // 번들딜 체크
            ? o.misc9
            : o.misc20 // 애드온딜 체크
              ? o.misc20
              : o.uniq
      )
      .map(orders => ({
        filterUniq: orders[0].uniq,
        orders: orders,
      }))
      .value();

    const checkShopeeOrd = $scope.ordList.some(ord => ord.pa_shop_cd === 'X099');
    const multiSelectOpt = {
      includeSelectAllOption: true,
      hideButtonTitle: true,
      nonSelectedText: '<span>분할하고 싶은 주문 건을 선택해 주세요.(쇼핑몰상품명 <span style="color:blue;font-weight:700;">/</span> 옵션 <span style="color:blue;font-weight:700;">/</span> 주문수량)</span>',
      buttonWidth: '700px',
      optionClass: () => {
        return 'width-700 pl-20 child-width-full divide-opt';
      }
    };

    $timeout(() => {
      const options = $scope.dealList.map(pkg => {
        $('.multiselect-selected-text').addClass('fa fa-check-square-o fa-6');

        return {
          label: pkg.orders.map(ord => `<span>${ord.shop_sale_name}<span style="color:blue;font-weight:700;"> / </span>${ord.shop_opt_name}<span style="color:blue;font-weight:700;"> / </span>${ord.sale_cnt}</span>`).join('\n '),
          title: pkg.orders.map(ord => ord.uniq).join(),
          value: `${pkg.orders[0].bundle_no}|${pkg.filterUniq}|${pkg.orders.map(ord => ord.shop_sale_name).join('\n ').trim()}|${pkg.orders.map(ord => ord.shop_opt_name).join('\n').trim()}|${pkg.orders.map(ord => ord.sale_cnt).join('\n').trim()}`
        };
      });

      $('#ord_multi_select').multiselect(multiSelectOpt);
      $('#ord_multi_select').multiselect('dataprovider', options);
    });

    // 묶음 기준 선택 (checkbox-> 라디오버튼처럼 사용하기 위해 미선택값 false로 바꿈)
    $scope.selectMainBundle = (index) => {

      for (const idx in $scope.mainBundle) {
        if (index !== idx) {
          $scope.mainBundle[idx] = false;
        }
      }
    };

    // 분할 묶음 리스트 삭제 시 리스트 새로 만들어줌
    $scope.deleteBundle = (type, index, paIndex) => {
      if (type === 'bundle') {
        makeMainBundle(index);

        $scope.totalDivideList.splice(index, 1);
      } else {
        $scope.totalDivideList[paIndex].splice(index, 1);

        if (!$scope.totalDivideList[paIndex].length) {
          $scope.totalDivideList.splice(paIndex, 1);

          makeMainBundle(paIndex);
        }
      }
      const options = ($scope.dealList.filter(pkg => !$scope.totalDivideList.flat().find(divOrd => divOrd.split('|')[1] === pkg.filterUniq)) || []).map(o => ({
        label: o.orders.map(ord => `<span>${ord.shop_sale_name}<span style="color:blue;font-weight:700;"> / </span>${ord.shop_opt_name}<span style="color:blue;font-weight:700;"> / </span>${ord.sale_cnt}</span>`).join('\n '),
        title: o.orders.map(ord => ord.uniq).join(),
        value: `${o.orders[0].bundle_no}|${o.filterUniq}|${o.orders.map(ord => ord.shop_sale_name).join('\n').trim()}|${o.orders.map(ord => ord.shop_opt_name).join('\n').trim()}|${o.orders.map(ord => ord.sale_cnt).join('\n').trim()}`
      }));

      $timeout(() => {
        $('#ord_multi_select').multiselect('destroy');
        $('#ord_multi_select').multiselect(multiSelectOpt);

        $('#ord_multi_select').multiselect('dataprovider', options);
      });
    };

    $scope.divideBundleList = (divideList) => {
      if (!divideList || !$scope.divideList.length) { return false; }
      if (!$scope.totalDivideList.length) {
        $scope.mainBundle.push(true);
      } else {
        $scope.mainBundle.push(false);
      }
      $scope.totalDivideList.push(divideList);
      $scope.divideList = [];

      // 첫 선택이 전체 선택일 경우 분할 주문 건 X
      if ($scope.totalDivideList.length === 1 && ($scope.totalDivideList[0].length === $scope.dealList.length)) {
        $scope.totalDivideList = [];
        $scope.mainBundle.pop();
        commonSVC.showMessage('분할할 주문 건이 없습니다.');
      }

      const options = ($scope.dealList.filter(pkg => !$scope.totalDivideList.flat().find(divOrd => divOrd.split('|')[1] === pkg.filterUniq)) || []).map(o => ({
        label: o.orders.map(ord => `<span>${ord.shop_sale_name}<span style="color:blue;font-weight:700;"> / </span>${ord.shop_opt_name}<span style="color:blue;font-weight:700;"> / </span>${ord.sale_cnt}</span>`).join('\n '),
        title: o.orders.map(ord => ord.uniq).join(),
        value: `${o.orders[0].bundle_no}|${o.filterUniq}|${o.orders.map(ord => ord.shop_sale_name).join('\n').trim()}|${o.orders.map(ord => ord.shop_opt_name).join('\n').trim()}|${o.orders.map(ord => ord.sale_cnt).join('\n').trim()}`
      }));

      $timeout(() => {
        $('#ord_multi_select').multiselect('dataprovider', options);

        if (!options.length) {
          $('#ord_multi_select').multiselect('destroy');
          $('#ord_multi_select').multiselect({
            buttonWidth: '700px',
            hideButtonTitle: true,
            nonSelectedText: '<span>분할하고 싶은 주문 건을 선택해 주세요.(쇼핑몰상품명 <span style="color:blue;font-weight:700;">/</span> 옵션 <span style="color:blue;font-weight:700;">/</span> 주문수량)</span>',
            templates: {
              li: '<li class="width-700 text-center text-grey-300" style="height: 30px;padding-top: 5px !important;">분할할 주문 건이 없습니다.</li>',
            }
          });
          $('#ord_multi_select').append('<option value=""></option>');

          $('#ord_multi_select').multiselect('rebuild');
        }
      });
    };

    $scope.save = function () {
      let divideList = [];

      if ($scope.totalDivideList.flat().length !== $scope.dealList.length) {
        commonSVC.showMessage('분할되지 않은 주문 건이 있습니다.', '모든 주문 건을 분할해 주세요.');

        return;
      }

      commonSVC.showConfirm('설정한 묶음으로 주문을 분할하시겠습니까?')
        .then((confirm) => {
          if (confirm) {
            if (checkShopeeOrd) {
              commonSVC.showToaster('success', '성공', '작업이 등록되었습니다.');
            }

            divideList = $scope.totalDivideList.map(bundleList => {
              return bundleList.map(bundle => {
                const findUniq = $scope.dealList.find(pkg => bundle.split('|')[1] === pkg.filterUniq);
                if (findUniq) {
                  return findUniq.orders.map(ord => ({
                    bundle_no: ord.bundle_no,
                    uniq: ord.uniq
                  }));
                }
              }).flat();
            });

            // 묶음 기준 주문 건은 분할 시 G마켓 물류 상태 변경 처리 위해 배열 맨 앞으로 변경
            divideList.forEach((value, idx) => {
              if ($scope.mainBundle[idx]) {
                divideList.splice(idx, 1);
                divideList.unshift(value);
                value[0].main = true;
              }
            });
            const params = {
              divideList: divideList
            };

            shipmentModel.divideBundle(params, function (state, data) {
              if (state === 'success') {
                commonSVC.showToaster('success', '', '주문 선택분할이 성공하였습니다.');

                $uibModalInstance.close('success');
              } else {
                commonSVC.showToaster('error', '', `주문 선택분할이 실패하였습니다.\n${data.data?.message || ''}`);

                return false;
              }
            });
          }
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    const makeMainBundle = (idx) => {
      if ($scope.mainBundle[idx]) {
        if (idx) {
          $scope.mainBundle[idx] = false;
          $scope.mainBundle[idx - 1] = true;
        }
        $scope.mainBundle.pop();

      } else {
        $scope.mainBundle.splice(idx, 1);
      }
    };
  });
