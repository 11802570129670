'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B851-Ctrl', function ($scope, $rootScope, $timeout, userInfo, siteInfo, commonModel, commonSVC, onlineProdSVC, data, systemList, templateList, $uibModalInstance, shopAccountModel, onlineProductModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.openTemplate = _.cloneDeep(data.openTemplate);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      const set_info = $scope.row.set_info;
      const row = $scope.row;

      if (set_info.key_titleDecoration_type == 'direct' && !set_info.key_titleDecoration) {
        commonSVC.showMessage('상품 홍보 제목을 확인하세요.');

        return false;
      }
      if (row.match_cate_name.search('뷰티') == 0 || row.match_cate_name.search('생활·주방') == 0 || row.match_cate_name.search('식품·건강') == 0 || row.match_cate_name.search('출산·유아동') == 0 || row.match_cate_name.search('가전·디지털') == 0) {
        if (!set_info.key_requiredDoc) {
          commonSVC.showMessage('법적 허가/신고대상 상품을 확인하세요.');

          return false;
        }
      }
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.row.match_cate_name = $scope.row.match_cate_name || $scope.data.match_cate_name;

    $scope.info_data_price_source = onlineProdSVC.B851.info_data_price_source; // 정상가 근거
    $scope.info_data_baby_age = onlineProdSVC.B851.info_data_baby_age; // 출산 유아동 출산유아 상품연령
    $scope.info_data_baby_age_req = true; // 출산 유아동 출산유아 상품연령 필수값 체크
    $scope.required_doc = onlineProdSVC.B851.required_doc; // 법적허가/신고대상 상품
    $scope.title_decoration = onlineProdSVC.B851.title_decoration;

    /**
     * 이미지 초기화
     */
    $scope.priceBaseFiles = [ { files: '' } ];
    if ($scope.row.set_info.php_priceBaseFiles && $scope.row.set_info.php_priceBaseFiles.length > 0) {
      $scope.priceBaseFiles = [];
      _.each($scope.row.set_info.php_priceBaseFiles, function(v) {
        if (v != '') {
          $scope.priceBaseFiles.push({ files: v });
        }
      });
      $scope.priceBaseFiles.push({ files: '' });
    } else {
      $scope.row.set_info.php_priceBaseFiles = angular.copy(onlineProdSVC.B851.set_info.php_priceBaseFiles);
    }

    $scope.adConfirmFiles = [ { files: '' } ];
    if ($scope.row.set_info.php_adConfirmFiles && $scope.row.set_info.php_adConfirmFiles.length > 0) {
      $scope.adConfirmFiles = [];
      _.each($scope.row.set_info.php_adConfirmFiles, function(v) {
        if (v != '') {
          $scope.adConfirmFiles.push({ files: v });
        }
      });
      $scope.adConfirmFiles.push({ files: '' });
    } else {
      $scope.row.set_info.php_adConfirmFiles = angular.copy(onlineProdSVC.B851.set_info.php_adConfirmFiles);
    }

    $scope.kcConfirmFiles = [ { files: '' } ];
    if ($scope.row.set_info.php_kcConfirmFiles && $scope.row.set_info.php_kcConfirmFiles.length > 0) {
      $scope.kcConfirmFiles = [];
      _.each($scope.row.set_info.php_kcConfirmFiles, function(v) {
        if (v != '') {
          $scope.kcConfirmFiles.push({ files: v });
        }
      });
      $scope.kcConfirmFiles.push({ files: '' });
    } else {
      $scope.row.set_info.php_kcConfirmFiles = angular.copy(onlineProdSVC.B851.set_info.php_kcConfirmFiles);
    }

    /**
     * 이미지 업로드
     */
    $scope.tmImageUpload = function(mode, files, idx) {
      // S3 temp 업로드 후 임시 url 등록
      commonModel.tempUpload(files)
        .then(function(res) {
          if (mode == 'price') {
            $scope.priceBaseFiles.push({ files: '' });
            $scope.row.set_info.php_priceBaseFiles[idx] = res.data.results[0].url;
          } else if (mode == 'ad') {
            $scope.adConfirmFiles.push({ files: '' });
            $scope.row.set_info.php_adConfirmFiles[idx] = res.data.results[0].url;
          } else if (mode == 'kc') {
            $scope.kcConfirmFiles.push({ files: '' });
            $scope.row.set_info.php_kcConfirmFiles[idx] = res.data.results[0].url;
          }
        });
    };

    /**
     * 이미지 삭제
     */
    $scope.tmImageDel = function (mode, idx) {
      if (mode == 'price') {
        $scope.priceBaseFiles.splice(idx, 1);
        $scope.row.set_info.php_priceBaseFiles.splice(idx, 1);
      } else if (mode == 'ad') {
        $scope.adConfirmFiles.splice(idx, 1);
        $scope.row.set_info.php_adConfirmFiles.splice(idx, 1);
      } else if (mode == 'kc') {
        $scope.kcConfirmFiles.splice(idx, 1);
        $scope.row.set_info.php_kcConfirmFiles.splice(idx, 1);
      }
    };

    /**
     * 이미지 삭제
     */
    $scope.kcConfirmAdd = function (mode, idx) {
      if (mode == 'plus') {
        $scope.row.set_info.php_kcConfirm.push({ v: '' });
      } else {
        $scope.row.set_info.php_kcConfirm.splice(idx, 1);
      }
    };

    $scope.babyAgeChange = function() {
      $scope.row.set_info.php_babyFilterNos = [];
      $scope.info_data_baby_age_req = true;
      _.each($scope.row.set_info.babyFilterNos, function(v, k) {
        if (v) {
          $scope.row.set_info.php_babyFilterNos.push(k);
          $scope.info_data_baby_age_req = false;
        }
      });
    };
    $scope.babyAgeChange();

    // /**
    //  * 이미지파일 원본보기 모달.
    //  * @param {이미지파일} img
    //  */
    // $scope.imgModal = function (img) {
    //   var resolve = {}
    //   resolve.data = {
    //     file:img
    //   };
    //   var modal = commonSVC.openModal('lg',resolve,'olImgModal','views/online/product/olProdImg.html');
    //   modal.result.then(function(result){
    //   });
    // };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
