'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-C009-Ctrl', function ($scope, $ocLazyLoad, commonSVC, C009categorySVC, categoryModel, templateModel, settings, openMarketCateSVC) {

    if (settings.debug == true) {
      $ocLazyLoad.load(['scripts/controllers/settings/category/shopDetail/categorize/A006.js'], { cache: false })
        .then(function() {
          openMarketCateSVC.setCategoryList(document.all('CatMenu_0'), document.all('CatMenu_1'), document.all('CatMenu_2'), document.all('CatMenu_3'));
          openMarketCateSVC.resetLargeCategory();
          openMarketCateSVC.setInitCategory('0', '0', '0', '0');
        });
    } else {
      $ocLazyLoad.load(['scripts/oclazyload/category/shopDetail/categorize/A006.js'], { cache: false })
        .then(function() {
          openMarketCateSVC.setCategoryList(document.all('CatMenu_0'), document.all('CatMenu_1'), document.all('CatMenu_2'), document.all('CatMenu_3'));
          openMarketCateSVC.resetLargeCategory();
          openMarketCateSVC.setInitCategory('0', '0', '0', '0');
        });
    }

    $scope.CateData = angular.copy(C009categorySVC.category_data);
    $scope.cert_type = angular.copy(C009categorySVC.cert_type);
    $scope.category_names = ''; //노출될 카테고리 분류

    init();

    $scope.select1 = function () {
      $scope.category_names = $('#CatMenu_0 option:selected').text();
      openMarketCateSVC.SelectLarge($('#CatMenu_0').val());
    };

    $scope.select2 = function () {
      $scope.category_names = $('#CatMenu_0 option:selected').text();
      $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
      openMarketCateSVC.resetSmallCategory($('#CatMenu_1').val());
      openMarketCateSVC.resetDetailCategory();
    };

    $scope.select3 = function () {
      $scope.category_names = $('#CatMenu_0 option:selected').text();
      $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
      $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
      $("select[name='key_cate_attr'] option").remove();
      $("select[name='key_cate_attr_value'] option").remove();
      openMarketCateSVC.SelectSmall($('#CatMenu_2').val());
      // 특수문자 제거
      const cate_code = $('#CatMenu_2').val().replace(/_/g, '');

      /**
       * 카테고리 속성
       */
      const vdata = {
        site_code: 'A006',
        site_id: $scope.matchingData.site_id,
        mode: 'GetGoodsClassAttributes',
        cate_code: cate_code
      };

      templateModel.templateApi(vdata, function(state, data) {
        if (data != 0) {
          $('#cate_attr_div').show();

          let rows = '';

          angular.forEach(data, function(k, v) {
            rows = $(`<option value='${v}'>${k.ClassAttrName}</option>`);
            $('#key_cate_attr').append(rows);
          });
          // cate_attr_chkYN = true;
        } else if (data == '') {
          $('#cate_attr_div').hide();
        }
      });
    };

    $scope.select4 = function () {
      if ($('#CatMenu_3').val() != '0_0_0_0') {
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_3 option:selected').text()}`;
      }

      openMarketCateSVC.SelectDetail(4);

      const cate_code = $('#CatMenu_2').val().replace(/_/g, '');

      /**
       * 카테고리 속성
       */
      const vdata = {
        site_code: 'A006',
        site_id: $scope.matchingData.site_id,
        mode: 'GetGoodsClassAttributes',
        cate_code: cate_code
      };

      templateModel.templateApi(vdata, function(state, data) {

        if (data != 0) {
          $("select[name='key_cate_attr'] option").remove();
          $("select[name='key_cate_attr_value'] option").remove();
          openMarketCateSVC.SelectSmall($('#CatMenu_2').val());
          //특수문자 제거
          const cate_code = $('#CatMenu_2').val().replace(/_/g, '');

          /**
           * 카테고리 속성
           */
          const vdata = {
            site_code: 'A006',
            site_id: $scope.matchingData.site_id,
            mode: 'GetGoodsClassAttributes',
            cate_code: cate_code
          };

          templateModel.templateApi(vdata, function(state, data) {
            if (data != 0) {
              $('#cate_attr_div').show();

              let rows = '';

              angular.forEach(data, function(k, v) {
                rows = $(`<option value='${v}'>${k.ClassAttrName}</option>`);
                $('#key_cate_attr').append(rows);

              });
              // cate_attr_chkYN = true;
            }
          });
        }
      });
    };

    $scope.ShopCateChange = function() {
      if ($scope.CateData.key_ShopCateUseYn) {
        //여기서 서브
        const vdata = {
          site_code: 'A006',
          site_id: $scope.matchingData.site_id,
          mode: 'ShopCate',
        };

        templateModel.templateApi(vdata, function(state, data) {
          if (data != 0) {
            if (data.ShopCateUseN == 'Array') {
              commonSVC.showMessage('없습니다');
            } else {
              $('#shopCateView').css('display', 'block');
            }
          }
        });
      } else {
        $('#shopCateView').css('display', 'none');
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = function (event) {

      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
        if (confirm) {
          $scope.shopInfoSave(event);
        }
      });
    };

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = function(event) {
      $scope.CateData.detailData.cate0 = $('#CatMenu_0 option:selected').val();
      $scope.CateData.detailData.cate1 = $('#CatMenu_1 option:selected').val();
      $scope.CateData.detailData.cate2 = $('#CatMenu_2 option:selected').val();
      $scope.CateData.detailData.cate3 = $('#CatMenu_3 option:selected').val();

      $scope.CatMenu_name_0 = $('#CatMenu_0 option:selected').text();
      $scope.CatMenu_name_1 = $('#CatMenu_1 option:selected').text();
      $scope.CatMenu_name_2 = $('#CatMenu_2 option:selected').text();
      $scope.CatMenu_name_3 = $('#CatMenu_3 option:selected').text();

      let category_code = '';

      if ($scope.CateData.detailData.cate3 == undefined) {
        category_code = $scope.CateData.detailData.cate2;
      } else if ($scope.CateData.detailData.cate3 != '0_0_0_0') {
        category_code = $scope.CateData.detailData.cate3;
      } else {
        category_code = $scope.CateData.detailData.cate2;
      }

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: 'C009',
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: $scope.CateData.detailData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else {
          categoryModel.shopInfoDetailEdit(param, function (state) {
            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              if ($scope.matchingData.isOnlineAdd === true) {
                param.shop_cate_no = data;

                $scope.returnAct(param);
              } else {
                $scope.infoLoad();
              }
            } else {
              commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
            }
          });
        }
      }
    };

    function matchDetailDataLoad(param_input) {
      $scope.CateData.detailData = param_input;
      //카테고리코드를 _ 기준으로 나눈다
      const code = result[0].category_code.split('_');
      const cate_code = result[0].category_code.replace(/\_/g, '');

      $(`#CatMenu_0 option[value=${code[0]}]`).attr('selected', 'selected');
      $scope.select1();

      $(`#CatMenu_1 option[value=${code[0]}_${code[1]}]`).attr('selected', 'selected');
      $scope.select2();

      $(`#CatMenu_2 option[value=${code[0]}_${code[1]}_${code[2]}]`).attr('selected', 'selected');
      $scope.select3();

      $(`#CatMenu_3 option[value=${code[0]}_${code[1]}_${code[2]}_${code[3]}]`).attr('selected', 'selected');
      $scope.select4();

      /**
       * 카테고리 속성
       */
      const vdata = {
        site_code: 'A006',
        site_id: $scope.matchingData.site_id,
        mode: 'GetGoodsClassAttributes',
        cate_code: cate_code
      };

      templateModel.templateApi(vdata, function(state, data) {
        if (data != 0) {
          $('#cate_attr_div').show();
          let rows = '';

          angular.forEach(data, function(k, v) {
            rows = $(`<option value='${v}'>${k.ClassAttrName}</option>`);
            $('#key_cate_attr').append(rows);
          });
        }
      });
    }
    function init() {

      /**
       * 2018-03-09 roy
       * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
       */
      if ($scope.matchingData.shop_cate_no_add_info) {
        const param_sol_cate_shop_add_info = JSON.parse($scope.matchingData.shop_cate_no_add_info);

        matchDetailDataLoad(param_sol_cate_shop_add_info);
      } else {
        const param = {
          categoryId: $scope.matchingData.categoryId,
          shop_cd: 'C009',
          shop_id: $scope.matchingData.site_id
        };

        $scope.CateData.detailData = angular.copy(C009categorySVC.category_data);
        categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
          if (state == 'success') {
            if (result.length) {
              matchDetailDataLoad(result[0].sol_cate_shop_add_info);
            }
          } else {
            commonSVC.showMessage('데이터 로드 실패');
          }
        });
      }
    }

    // div 보여줄까? 말까?
    function show_hide(val, showYN) {
      $(`.${showYN}`).each(function(di) {
        const tid = $(`.${showYN}`).eq(di).attr('viewid');

        if (tid == `${showYN}_${val}`) {
          $(`[viewid='${tid}']`).show();
        } else {
          $(`[viewid='${tid}']`).hide();
        }
      });
    }

    // 카테고리 필수속성 속성명 선택
    $("[name='key_cate_attr']").change(function() {
      $("select[name='key_cate_attr_value'] option").remove();

      //속성값 호출
      //
      // $.getJSON( sub + "&mode=GetGoodsClassAttributeValues&classAttrNo="+this.value, function(data) {
      //
      //   $("[name='key_cate_attr_value']").removeOption(/./);
      //   $("[name='key_cate_attr_value']").addOption(data,false);
      //
      //   if ( mode == "edit" && vs.fill.key_cate_attr_value )	$("[name='key_cate_attr_value']").val(vs.fill.key_cate_attr_value);
      //   else $("[name='key_cate_attr_value'] option:eq(0)").attr("selected","selected");
      // });
      const vdata = {
        site_code: 'A006',
        site_id: $scope.matchingData.site_id,
        mode: 'GetGoodsClassAttributeValues',
        classAttrNo: $('#key_cate_attr option:selected').val()
      };

      templateModel.templateApi(vdata, function(state, data) {
        if (data != 0) {

          angular.forEach(data, function(k, v) {
            // $(".cateAttrList").append(rows.join(''));
            const rows = $(`<option value='${v}'>${k}</option>`);

            $('#key_cate_attr_value').append(rows);
          });

          //
          // $("[name='key_cate_attr_value']").removeOption(/./);
          // $("[name='key_cate_attr_value']").addOption(data,false);

        }
      });

      // 카테고리 필수속성값 추가
      $('#cate_attr_plus').click(function() {

        let cate_attr1 = '';
        const add_opt = [];

        cate_attr1 = $("[name='key_cate_attr']").val();
        if (cate_attr1 == '' || cate_attr1 == null) {
          alert('속성명을 선택해 주시기 바랍니다.');

          return false;
        }
        const cate_attr1_text = $("[name='key_cate_attr'] option:selected").text();
        const cate_attr1_multi = $("[name='key_cate_attr'] option:selected").prop('multiis');

        let cate_attr2 = '';

        cate_attr2 = 	$("[name='key_cate_attr_value']").val();

        if (cate_attr2 == '' || cate_attr2 == null) {
          alert('속성값을 선택해 주시기 바랍니다.');

          return false;
        }

        let save_cate_attr_value = '';
        let save_cate_attr_text = '';

        $.each(cate_attr2, function(k) {
          if (cate_attr1_multi == 'false' && $("[name='key_cate_attr_list_hidden']").val().indexOf(`${cate_attr1}|||`) != -1)	{
            alert(`[${cate_attr1_text}]의 속성명은 복수선택할 수 없습니다.`);

            return false;
          }

          if ($("[name='key_cate_attr_value'] option:selected").eq(k).val() == '')	{
            return true;
          }

          const v = $("[name='key_cate_attr_value'] option:selected").eq(k).text();

          save_cate_attr_value = `${cate_attr1}|||${$("[name='key_cate_attr_value'] option:selected").eq(k).val()}`;
          save_cate_attr_text = `${cate_attr1_text} -> ${v}`;

          const save_cate_attr_multi = `${cate_attr1}|||${$("[name='key_cate_attr_value'] option:selected").eq(k).prop('multiis')}`;

          const saved_cate_attr_value_arr = $("[name='key_cate_attr_list_hidden']").val().split('^^^');

          let cate_attr_equal = false;

          $.each(saved_cate_attr_value_arr, function(k, v) {
            if (`${save_cate_attr_value}@@@${save_cate_attr_text}` == v)	{
              cate_attr_equal = true;

              return false;
            }
          });

          if (cate_attr_equal == false)	{
            if (saved_cate_attr_value_arr[saved_cate_attr_value_arr.length - 1] == '')	{
              saved_cate_attr_value_arr[saved_cate_attr_value_arr.length - 1] = `${save_cate_attr_value}@@@${save_cate_attr_text}`;
            }	else	{
              saved_cate_attr_value_arr[saved_cate_attr_value_arr.length] = `${save_cate_attr_value}@@@${save_cate_attr_text}`;
            }
            add_opt[save_cate_attr_value] = save_cate_attr_text;

            $('#key_cate_attr_list').append(`<option value='${save_cate_attr_value}'>${save_cate_attr_text}</option>`);

            $("[name='key_cate_attr_list_hidden']").val(saved_cate_attr_value_arr.join('^^^'));
          }
          const sort_cate_attr =	$("[name='key_cate_attr_list']>option").sort(function (a, b) {
            return a.value > b.value ? 1 : -1;
          });

          $("[name='key_cate_attr_list']").empty();
          $("[name='key_cate_attr_list']").append(sort_cate_attr);

          $("[name='key_cate_attr_list'] option:selected").removeAttr('selected');
          $("[name='key_cate_attr_list']").css('width', '459px');
        });
      });

      // 카테고리 선택항목 삭제
      $('#cate_attr_del').click(function() {
        const select = $("[name='key_cate_attr_list'] option:selected");
        const new_list = [];

        // $("[name='key_cate_attr_list']").removeOption($("[name='key_cate_attr_list']").val());
        $(`#key_cate_attr_list option:eq(${select[0].index})`).remove();
        $("[name='key_cate_attr_list'] option").each(function(k) {
          new_list[k] = `${this.value}@@@${this.text}`;
        });
        $("[name='key_cate_attr_list_hidden']").val(new_list.join('^^^'));
      });

      $('.category_list').change(function() {
        $("[name='key_cate_attr']").removeOption(/./);
        $("[name='key_cate_attr_value']").removeOption(/./);
        $("[name='key_cate_attr_list']").empty();
        $("[name='key_cate_attr_list_hidden']").val('');
      });

      // shop 카테고리
      if ($("[name='key_ShopCateUseYn']:checked").val() == 'Y') {
        openMarketCateSVC.ShopCate_List();
      }

      show_hide($("[name='key_ShopCateUseYn']:checked").val(), 'groupIsShopCate_view');
      $("[name='key_ShopCateUseYn']").change(function() {
        const selitem = $(this).val();

        show_hide(selitem, 'groupIsShopCate_view');

        if (selitem == 'Y') {
          openMarketCateSVC.ShopCate_List();
        }
      });
    });
  });

// var uniq_site_key = $scope.totalInfo ? $scope.totalInfo.site_id + $scope.totalInfo.site_code : ''; // true is 'NEW'
// var viewType =  $scope.categoryData ? 'edit' : 'new';           // true is 'EDIT'
//
// $scope.tab_active = 0;                                          // default select Tab
// $scope.default_cate_text = "로딩중..";                            // default select message
// $scope.submitData = {                                           // 나중에 emit 으로 보낼 데이터
//   category: {}, // 매핑카테고리
//   property: {}, // 속성
//   hscode : ''   // hscode
// };
//
// $scope.cate = {                                                 // 맵핑 카테고리 select box list
//   step1 : { list: {} , names : [] },                            // list : object , names : 카테고리명
//   step2 : { list: {} , names : [] },
//   step3 : { list: {} , names : [] },
//   step4 : { list: {} , names : [] }
// };
//
// /**
//  * get Site Category List
//  */
// categoryModel.getSiteCate({site_code: "A001", mode: "kr"})
//   .then(function(re){
//     if(re.status == 200) {
//
//       if(viewType === 'edit'){
//         $scope.submitData = {
//           category: $scope.categoryData.category ? angular.copy($scope.categoryData.category) : {},
//           property: {},
//           hscode : $scope.categoryData.hscode
//         };
//       }
//
//       $scope.default_cate_text = "선택";
//
//       $scope.cate['step1'].list = re.data.results;
//       angular.forEach($scope.cate['step1'].list, function(value,key) {
//         $scope.cate['step1'].names.push(key);
//       });
//
//       if(viewType === 'edit' && $scope.categoryData.category){
//         if($scope.submitData.category.step1){ $scope.selectCate(1); }
//         if($scope.submitData.category.step2){ $scope.selectCate(2); }
//         if($scope.submitData.category.step3){ $scope.selectCate(3); }
//         if($scope.submitData.category.step4){ $scope.selectCate(4); }
//       }
//     }
//   });
//
// /**
//  * send SubmitData
//  */
// $scope.$on('getSubmitData' + uniq_site_key, function(event, data) { //사이트코드추가
//   if(data){ // new 의 경우 site 정보를 넘겨줌
//     $scope.submitData.engines = data;
//   }
//   $scope.$emit('sendSubmitData' , $scope.submitData);
// });
//
// /**
//  * 카테고리 선택시
//  */
// $scope.selectCate = function (step, init) {
//   var current_step = 'step' + step;                             // 현재 선택한 카테고리 스텝
//   var next_step = 'step' + (step + 1);                          // 다음 스텝
//   var current_name = $scope.submitData.category[current_step]; // 현재 선택한 카테고리 네임
//
//   if(init){
//     for(var i = (step + 1); i < 5; i++){
//       $scope.cate['step' + i] = { list: {} , names : [] };
//       $scope.submitData.category['step' + i] = "";
//     }
//   }
//
//   if(current_name === ''){
//     // selectList 에서 '선택' 을 선택할시 설정해주었던 카테고리 코드, 이름 초기화
//     $scope.submitData.category.categoryCode = '';
//     $scope.submitData.category.categoryName = '';
//     return;
//   }
//
//   if($scope.cate[current_step].list[current_name].ccode){
//     // ccode 가 있는경우 카테고리Code, Name 설정하는곳으로
//     setCate($scope.cate[current_step].list[current_name],step);
//   }else{
//     // ccode 가 없는경우 다음 카테고리 리스트 정의
//     $scope.cate[next_step].list = $scope.cate[current_step].list[current_name];
//     angular.forEach($scope.cate[next_step].list, function(value, cateName) {
//       $scope.cate[next_step].names.push(cateName);
//     });
//   }
// };
//
// /**
//  * 카테고리 값 세팅
//  */
// var setCate = function(data, dep){
//   $scope.submitData.category.categoryCode = data.ccode;
//   if(dep === 3){
//     $scope.submitData.category.categoryName = data.dc1_nm + " > "+data.dc2_nm + " > "+data.dc3_nm;
//     $scope.submitData.category.categoryCode = data.ccode + "_00";
//   }else{
//     $scope.submitData.category.categoryName = data.dc1_nm + " > "+data.dc2_nm + " > "+data.dc3_nm + " > "+data.dc4_nm;
//   }
// };

// var _submitData = {};
// $scope.default_cate_text = "로딩중..";
//
// $scope.$on('categorySomeEvent', function(event, data) {
//   $scope.totalInfo = data;
//   _submitData = angular.copy($scope.totalInfo.submitData);
// });
//
// $scope.submitData = {};
// if(! $scope.categoryData){
//   $scope.submitData.categoryName = "";
//   $scope.submitData.categoryCode = "";
//   $scope.submitData.step1 = "";
//   $scope.submitData.step2 = "";
//   $scope.submitData.step3 = "";
//   $scope.submitData.step4 = "";
//   $scope.submitData.selectedList_size = [];
//   $scope.submitData.selectedList_ratio = [];
//   $scope.submitData.selectedList_resolution = [];
//
//   $scope.submitData = $scope.totalInfo.submitData;
// }
//
// /**
//  * 사이트 카테고리 가져오기 (예전)
//  */
// $scope.categorys = [];
// $scope.categorys2 = [];
// $scope.categorys3 = [];
// $scope.categorys4 = [];
// var category_data = [];
// categoryModel.getSiteCate({site_code: "A001", mode: "kr"})
//   .then(function(re){
//     $scope.default_cate_text = "선택";
//     if(re.status == 200) {
//       category_data = re.data.results;
//
//       var category = [];
//       _.forEach(category_data, function(v, k) {
//         category.push(k);
//       });
//
//       $scope.categorys = category;
//       $scope.submitData = angular.copy(_submitData);
//       if($scope.submitData.step1){ $scope.step1_update(); }
//       if($scope.submitData.step2){ $scope.step2_update(); }
//       if($scope.submitData.step3){ $scope.step3_update(); }
//       if($scope.submitData.step4){ $scope.step4_update(); }
//     }
//   });

// /**
//  * get Site Category List (1.3)
//  */
// categoryModel.getSiteCate({site_code: "A001", mode: "kr"})
//   .then(function(re){
//     $scope.default_cate_text = "선택";
//     if(re.status == 200) {
//       var category = [];
//       category_data = re.data.results;
//       angular.forEach((category_data), function(v, k) {
//         category.push(k);
//       });
//
//       $scope.categorys = category;
//
//       if(viewType === 'edit' && $scope.categoryData.category){
//         if($scope.submitData.category.step1){ $scope.step1_update(); }
//         if($scope.submitData.category.step2){ $scope.step2_update(); }
//         if($scope.submitData.category.step3){ $scope.step3_update(); }
//         if($scope.submitData.category.step4){ $scope.step4_update(); }
//       }
//     }
//   });
//
// /**
//  * 대분류 변경
//  */
// $scope.step1_update = function(init_mode){
//   $scope.categorys2 = [];
//   $scope.categorys3 = [];
//   $scope.categorys4 = [];
//
//   if(init_mode){
//     $scope.submitData.category.step2 = "";
//     $scope.submitData.category.step3 = "";
//     $scope.submitData.category.step4 = "";
//   }
//
//   var category = [];
//   angular.forEach(category_data[$scope.submitData.category.step1], function(v, k) {
//     category.push(k);
//   });
//   $scope.categorys2 = category;
// };
//
// /**
//  * 중분류 변경
//  */
// $scope.step2_update = function(init_mode){
//   $scope.categorys3 = [];
//   $scope.categorys4 = [];
//
//   if(init_mode){
//     $scope.submitData.category.step3 = "";
//     $scope.submitData.category.step4 = "";
//   }
//
//   var category = [];
//   angular.forEach(category_data[$scope.submitData.category.step1][$scope.submitData.category.step2], function(v, k) {
//     category.push(k);
//   });
//   $scope.categorys3 = category;
// };
//
// /**
//  * 소분류 변경
//  */
// $scope.step3_update = function(init_mode){
//   $scope.categorys4 = [];
//
//   if(init_mode){
//     $scope.submitData.category.step4 = "";
//   }
//
//   var category = [];
//   if(!category_data[$scope.submitData.category.step1][$scope.submitData.category.step2][$scope.submitData.category.step3].ccode){
//     angular.forEach(category_data[$scope.submitData.category.step1][$scope.submitData.category.step2][$scope.submitData.category.step3], function(v, k) {
//       category.push(k);
//     });
//   }else{
//
//     setCate(category_data[$scope.submitData.category.step1][$scope.submitData.category.step2][$scope.submitData.category.step3],3);
//
//   }
//   $scope.categorys4 = category;
// };
//
// /**
//  * 세분류 변경
//  */
// $scope.step4_update = function(){
//   setCate(category_data[$scope.submitData.category.step1][$scope.submitData.category.step2][$scope.submitData.category.step3][$scope.submitData.category.step4],4);
// };
