/**
 * Created by Harry on 2017-08-02
 */
'use strict';

angular.module('gmpApp')
  .service('commonModel', function (settings, commonSVC) {
    return {
      // 사용자 인증 선 작업
      getAuthPre: (params) => {
        const url = `${settings.pa20ApiUrl}/app/common/auth-pre`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 사용자 인증
      getAuth: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/common/auth`, params);
      },

      // 중복 로그인 확인
      checkDuplicateLogin: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/users/check-duplicate-login`, params);
      },

      // 접속자 로그아웃
      logoutOtherUser: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/users/logout-other-user`, params);
      },

      /**
       * 엑셀 다운로드
       */
      excelDownload: (apiUrl, params, next) => {
        const url = settings.pa20ApiUrl + apiUrl;

        if (next) {
          commonSVC.sendUrl('POST', url, params, (state, data) => {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * playapi v4 proxy
       * 백엔드에서 playapi v4 로 요청을 중계 하여 준다.
       * 2018-06-19 lucas : playapi의 경우 기본적으로 캐시한 결과를 넘겨준다.
       * 캐시되지 않은 결과가 필요할 경우 no_cache = true를 넘겨주면 된다.(필수값 아님)
       */
      pa4: (payload, no_decrypt, no_cache, next) => {

        const url = `${settings.pa20ApiUrl}/app/common/pa4`;
        const params = {
          payload: payload,
          no_decrypt: no_decrypt,
          no_cache: no_cache
        };

        if (next) {
          commonSVC.sendUrl('POST', url, params, (state, data) => {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * playapi v5
       * 백엔드에서 playapi v5 로 요청을 중계 하여 준다.
       */
      pa5: (act_url, data, shop_cd, shop_id, protocol) => {
        const url = `${settings.pa20ApiUrl}/app/common/pa5`;
        const params = {
          act_url,
          shop_cd,
          shop_id,
          data,
          protocol
        };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 데이터 테이블 설정 로딩
       */
      getGridInfo: (id) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/data-table`, { setName: id })
          .then(function(res) {
            return res.data.result;
          });
      },

      // 테이블 상태 저장
      setTableState: ({ name, state }) => {
        const url = `${settings.pa20ApiUrl}/app/common/table-state`;

        return commonSVC.sendUrl('POST', url, { name, state: JSON.stringify(state) });
      },

      // 테이블 상태 조회
      getTableState: (name) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/datatable-menu`, { name });
      },

      /**
       * 내 공인 IP 가져오기
       */
      getIp: () => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/current-ip`, {});
      },

      // 어드민 여부 조회
      getIsAdmin: () => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/users/is-admin`, {});
      },

      /**
       * playapi
       */
      requestPlayApi: (params, next) => {
        const url = 'https://engines.playapi.io/run';

        params.cache = 300; // 완전히 같은 성공된 요청은 300초(5분)간 캐시하도록 수정 (최근 playapi 에 추가된 옵션)

        // 문제가 되어 일단 원복 2018-03-22 rony
        // // PLAYAPI 팀요청. req_Action, req_code 값이 변경될 수 있으므로 해당 주소로 먼저 값을 호출하여 출력된 값을 사용하라고 함.
        // // 2018-03-21 rony
        // // https://status.playapi.io/endpoints?req_code=auction.co.kr&req_action=load_addr
        // var confirmURL = "https://status.playapi.io/endpoints?req_code="+params.req_code+"&req_action="+params.req_action;
        // commonSVC.sendUrl("get", confirmURL, "", function (state, ret) {
        //   if(ret.status != true){
        //     next(state, ret);
        //   }else{
        //     params.req_action = ret.req_action;
        //     params.req_code = ret.req_code;

        if (next) {
          commonSVC.sendUrl('POST', url, params, (state, data) => {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
        //   }
        // });
      },

      /**
       * 이미지 S3 임시 업로드
       */
      tempUpload: (files) => {
        try {
          const params = {};

          _.each(files, (obj, index) => {
            params[`file_${index}`] = obj instanceof File ? new File([obj], encodeURIComponent(obj.name), { type: obj.type }) : obj;
          });

          return commonSVC.sendUrlFile('POST', `${settings.pa20ApiUrl}/app/common/temp-s3`, params);
        } catch (e) {
          console.log(e);
        }
      },

      solutionUseInfo: (params, next) => {
        const url = `${settings.pa20ApiUrl}/app/home/solutionUseInfo`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            if (state === 'success') {
              next(data);
            } else {
              commonSVC.showMessage('실패', '솔루션 사용정보 로딩 실패');
            }
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * 토큰 갱신
       */
      resetToken: (param, next) => {
        const url = `${settings.pa20ApiUrl}/app/users/refresh-token`;

        if (next) {
          commonSVC.sendUrl('POST', url, param, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, param);
        }
      },

      // 2차 인증번호 전송
      sendSubAuth: (params) => {
        const url = `${settings.pa20ApiUrl}/app/www/send-sub-auth`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 2차 인증번호 인증
      verifySubAuth: (params) => {
        const url = `${settings.pa20ApiUrl}/app/www/verify-sub-auth`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 서브유져 리스트
      subUserList: (params) => {
        const url = `${settings.pa20ApiUrl}/app/common/sub-info-by-user`;

        return commonSVC.sendUrl('GET', url, params);
      },

      /**
       * 웨이크업사용자 토큰을 사용해 계정정보 가져오기
       */
      getInfoByToken: (wakeupToken, next) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/users/convert-wakeup-tokens`, { wakeupToken }, (state, data) => {
          next(state, data);
        });
      },

      /**
       * 솔루션 부가서비스(SMS,EMAIL,알림톡) 정보 조회
       */
      getServiceCnt: () => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/additional-service-cnt`);
      },

      /**
       * 접속 로그
       */
      setConnectLog: (params) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/users/setConnectLog`, params);
      }
    };
  });
