/**
 * 2018-02-13 Daniel
 * 카테고리관리 개선(카테고리 추가)
 */
'use strict';

angular.module('gmpApp')
  .controller('CategoryAddCtrl', function ($scope, $uibModalInstance, $q, commonSVC, categoryModel, data, $rootScope) {
    // 카테고리 추가후 바로 열림을 위해 추가
    const node = {
      state: '',
      bookmark: 0,
      depth: '2',
      hscode: undefined,
      id: null,
      parents_id: null,
      title: null,
      parents_title: null
    };

    //새 분류 생성 체크
    $scope.new = false;
    $scope.addCateFlag = !!data.cateData;
    $scope.cateData = {
      categoryClassName: '',
      categoryClassCode: data.id || '',
    };

    const PaType = ['L', 'M', 'S', 'XS'];

    // 표준카테고리 객체
    $scope.PaCate = {};
    // 표준카테고리 데이터
    $scope.PaCateData = {};
    // 세분류 체크 변수
    $scope.XSChk = true;

    // 플레이오토 표준카테고리 가져오기
    $scope.setPaCate = function (type, nextType) {
      const flag = {
        flag: type
      };

      const param = _.merge(flag, $scope.PaCateData);

      categoryModel.playauto_category(param, function (data) {
        // 선택 초기화
        _.eachRight(PaType, function(type) {
          $scope.PaCate[`${type}cate`] = {};
          $scope.PaCateData[`${type}code`] = '';

          if (type === nextType) {
            return false;
          }
        });

        // 세분류 체크
        if (nextType === 'XS' && data.length === 1 && data[0].p_cate4_cd === '00') {
          $scope.XSChk = false;
          $scope.setDefaultCateName();

        } else {
          $scope.XSChk = true;
        }

        $scope.PaCate[`${nextType}cate`] = data;
      });
    };

    /**
     * 추가 버튼 누를시
     */
    $scope.onSubmit = async function () {
      // 분류 검사
      if (!$scope.cateData.categoryClassCode && !$scope.cateData.categoryClassName) {
        commonSVC.showMessage('분류를 선택 또는 입력하세요.');

        return false;
      }

      // 카테고리명 검사
      if (!$scope.cateData.categoryName) {
        commonSVC.showMessage('카테고리명을 입력하세요.');

        return false;
      }

      //카테고리 선택 여부 변수
      let rtn = false;

      //필수 제외 2017-04-09 Gargamel (https://playautogmpproject.atlassian.net/browse/GMPKR-2629)
      // 필수 포함 2018-10-23 Alvin (https://playautogmpproject.atlassian.net/browse/GMPKR-4924)
      // 카테고리 검사
      _.each(PaType, function(v) {
        const paCateList = $scope.PaCate[`${v}cate`];
        const paCateData = $scope.PaCateData[`${v}code`];

        if (paCateList && !paCateData) {
          // 세분류는 없을 시 검사안함
          if (paCateList.length !== 1 && paCateList[0].p_cate4_cd === '00') {
            rtn = true;
          }

          return false;
        }
      });

      if (rtn) {
        commonSVC.showMessage('생성할 카테고리를 선택하세요.');

        return false;
      }

      try {
        let res = {};

        // 새로운 분류로 생성시 분류 추가후 카테고리 추가
        if ($scope.new) {
          /**
           * 2018-03-05 Daniel
           * 분류명, 카테고리명 . 만있는경우를 막음
           */
          if ($scope.cateData.categoryClassName.replace(/\./gi, '') === '') {
            commonSVC.showMessage('유효하지 않은 분류명 입니다');

            return false;
          }

          const param = [{
            parents_id: 0,
            name: $scope.cateData.categoryClassName,
            depth: null
          }];

          res = await categoryModel.categoryAdd(param);
        }

        // 분류 등록후에는 분류 등록아이디 선택시에는 선택한아이디로 등록
        const id = res.data ? res.data.results.insertId : $scope.cateData.categoryClassCode;

        node.parents_id = id;
        node.parents_title = $scope.cateData.categoryClassName || $('#categoryClassCode').find('option:selected').text();

        const param = [{
          parents_id: id,
          name: $scope.cateData.categoryName,
          depth: null
        }];

        node.title = $scope.cateData.categoryName;

        res = await categoryModel.categoryAdd(param);

        // 2018-07-25 chris 카테고리 등록 후 마스터 디비에서 슬레이브 디비에 반영되는 시간때문에 딜레이줌
        setTimeout(() => {
          // 등록한 카테고리에 표준카테고리 매칭
          const id = res.data.results.insertId;
          let code = '';

          node.id = id;

          _.each(PaType, function(type) {
            code += $scope.PaCateData[`${type}code`] || '00';
          });

          const param = {
            code: code,
            sol_cate_no: id,
            categoryId: id,
            addYn: true
          };

          // 카테고리는 필수가 아니므로 없으면 건너띔
          if (code == '00000000') {
            return 'SUCCESS';
          }

          categoryModel.playauto_category_mapping(param);
        }, 300);

        const sol_cate_no = res.data.results.insertId;

        // 개별 카테고리 저장 정보 있는 경우
        if (sol_cate_no && data.cateData) {
          data.cateData.categoryData.forEach(cate => cate.categoryId = sol_cate_no);

          await categoryModel.shopInfoDetailEditAll(data.cateData);
        }

        // 결과처리
        if (res !== 'SUCCESS' && res.data.results === 'error') {
          commonSVC.showToaster('error', '실패', '카테고리 등록에 실패하였습니다.');
          node.state = 'failed';
        } else {
          data.cateData && commonSVC.showToaster('success', '성공', '카테고리 등록에 성공하였습니다.');
          node.state = 'success';
          node.code = sol_cate_no;

          $uibModalInstance.close(node);
        }
      } catch (err) {
        switch (err.data.error) {
          case 'ER_DUP_ENTRY':
            commonSVC.showToaster('error', '', '중복된 이름입니다.');
            break;

          case 'ER_DUP_ENTRY1':
            commonSVC.showToaster('error', '', '중복된 분류명 입니다.');
            break;

          default:
            commonSVC.showToaster('error', '', '카테고리 추가에 실패 했습니다.');
            break;
        }

        node.state = 'failed';
      }
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 초기화 함수
    function init() {
      $scope.categoryClassList = data.list;

      $scope.setPaCate('', 'L');
    }
    // 초기화
    init();

    /**
     * 새 분류 만들기 선택
     */
    $scope.addCategoryClass = () => {
      $scope.new = true;
    };

    // 카테고리명 기본 값 처리
    $scope.setDefaultCateName = function () {
      const name = [];

      $('#PaCateTable').find('select').each(function() {
        const t = $(this).find('option:selected').text();

        if ($scope.XSChk == false && t == '세분류') {
          return;
        }

        name.push(t);
      });

      $scope.cateData.categoryName = name.join(' > ');
    };

    /**
     * 모든 모달 닫기 명령이 들어온경우 처리.
     */
    $scope.$on('openedModalClose', function () {
      $uibModalInstance.dismiss('cancel');
    });
  });
