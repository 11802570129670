'use strict';

angular.module('gmpApp')
  .service('supplierSVC', function (commonSVC, settings, gettextCatalog) {
    return {
      /**
       * 폼 영역 변수 설정
       */
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        stock_type: 'real',
        s_stock: '',
        e_stock: '',
        use_yn: '',
        date_type: 'wdate',
      },

      searchData: {
        //showSearchArea : false,     // 검색어 노출여부
        showDetailSearchArea: true,  // 상세 검색어 노출여부
        showDateSearch: false,       // 상세 검색 날짜 검색 여부
        all_checked: false,          // 전체선택 여부
        totalCount: 0,               // 검색전체 건수
        selectCount: 0,              // 선택한 건수
        showCount: 25,
        search_key_items: [
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('매입처코드'), value: 'supp_no' },
          { label: gettextCatalog.getString('매입처명'), value: 'supp_name' },
          { label: gettextCatalog.getString('담당자'), value: 'charge_name' },
          { label: gettextCatalog.getString('주소'), value: 'addr1,addr2' }
        ]
      },

      /**
       * 기본 Vdata
       */
      defaultVdata: {
        use_yn: true,               // 사용여부
        supplier_name: '',          // 업체명
        business_type: '',          // 사업자
        company_number: '',         // 사업자 번호
        corporate_number: '',       // 법인(주민) 번호
        ceo_name: '',               // 대표자명
        company_tel: '',            // 대표번호
        zip_code: '',               // 우편번호
        address1: '',                // 주소
        address2: '',                // 상세주소
        business_form: '',          // 업태
        business_category: '',      // 종목
        supplier_yn: false,         // 매입처 (거래구분)
        delivery_company_yn: false, // 배송처 (거래구분)
        company_fax: '',            // 팩스번호
        email: '',                  // 이메일
        delivery_form: '',          // 운송정보
        return_zip_code: '',        // 반품 우편번호
        return_address1: '',         // 반품 주소
        return_address2: '',         // 반품 상세주소

        // 담당자 정보 ------------------------------
        charge_name: '',      // 이름
        charge_position: '',  // 직위
        charge_tel: '',       // 전화번호 (직통번호)
        charge_cellphone: '', // 핸드폰
        charge_email: '',     // 이메일
        charge_memo: '',      // 메모

        // 결제&정산 정보 ------------------------------
        settlement_cycle: '',       // 정산주기
        negotiation_commission: '', // 매입수수료
        bank: '',                   // 은행
        depositor: '',              // 예금주
        account_number: '',         // 계좌번호
        settlement_name: '',        // 정산담당자
        settlement_position: '',    // 직위
        settlement_tel: '',         // 전화번호 (직통번호)
        settlement_cellphone: '',   // 핸드폰
        settlement_email: '',       // 이메일
        settlement_memo: '',        // 메모
        log_contents: '',           // 로그 데이터
      },

      business_type_list: [
        '법인', '일반', '간이'
      ],
      // 정산 주기 리스트 (결제&정산 정보)
      settlement_cycle_list: [
        '수시', '선결제', '주결제', '월결제'
      ],
      // 은행 리스트 (결제&정산 정보)
      bank_list: [
        '광주은행', '경남은행', '국민은행', '기업은행', '농협은행', '대구은행', '부산은행', '산업은행', '수출입은행',
        '수협', '신한은행', '씨티은행', '외환은행', '우리은행', '우체국', '전북은행', '하나은행', 'SC은행'
      ],
      // 로그 처리 필드
      log_field_list: {
        use_yn: '사용여부',
        supplier_name: '업체명',
        supplier_yn: '거래구분',
        delivery_company_yn: '거래구분',
        ceo_name: '대표자명',
        business_type: '사업자',
        company_number: '사업자번호',
        corporate_number: '법인(주민)번호',
        business_form: '업태',
        business_category: '종목',
        company_tel: '대표번호',
        company_fax: '팩스번호',
        email: '이메일',
        delivery_form: '운송정보',
        zip_code: '업체주소(우편번호)',
        address1: '업체주소',
        address2: '업체상세주소',
        return_zip_code: '반품 배송지(우편번호)',
        return_address1: '반품 배송지',
        return_address2: '반품 상세배송지',
        charge_name: '담당자 정보(이름)',
        charge_position: '담당자 정보(직위)',
        charge_tel: '담당자 정보(전화번호)',
        charge_cellphone: '담당자 정보(핸드폰)',
        charge_email: '담당자 정보(이메일)',
        charge_memo: '담당자 정보(메모)',
        settlement_cycle: '결제&정산 정보(정산주기)',
        negotiation_commission: '결제&정산 정보(매입수수료)',
        bank: '결제&정산 정보(은행)',
        depositor: '결제&정산 정보(예금주)',
        account_number: '결제&정산 정보(계좌번호)',
        settlement_name: '결제&정산 정보(정산담당자)',
        settlement_position: '결제&정산 정보(직위)',
        settlement_tel: '결제&정산 정보(전화번호)',
        settlement_cellphone: '결제&정산 정보(핸드폰)',
        settlement_email: '결제&정산 정보(이메일)',
        settlement_memo: '결제&정산 정보(메모)'
      },

      excelFieldList: [
        { header: '등록일', key: 'wdate', select: true },
        { header: '매입처코드', key: 'supp_no', select: true },
        { header: '매입처명', key: 'supp_name', select: true },
        { header: '담당자', key: 'charge_name', select: true },
        { header: '직통번호', key: 'charge_tel', select: true },
        { header: '주소', key: 'distance', select: true },
        { header: '거래상품수', key: 'trade_prod_cnt', select: true },
        { header: '사용여부', key: 'use_yn', select: true }
      ]

    };
  });
