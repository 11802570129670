'use strict';

angular.module('gmpApp')
  .controller('QuickCollectCtrl', function ($scope, $rootScope, $uibModalInstance, localStorageService, commonSVC, workSVC, userInfo, shopAccountModel, $timeout, settings) {
    $scope.selectedAll = true;
    $scope.mall = [];
    $scope.workListCheck = angular.copy(workSVC.quickCollectJobListCheck);
    $scope.workListRadio = angular.copy(workSVC.quickCollectJobListRadio);
    $scope.isSelected = localStorageService.get(`quickCollectNew_${userInfo.user.m_no}`) ? localStorageService.get(`quickCollectNew_${userInfo.user.m_no}`).split(',').length : 0;
    $scope.auth = {
      order: userInfo.user.auth_type.includes('관리자') || (userInfo.permission.order?.use_yn && userInfo.permission.order?.roles.scrapOrder),
      inquiry: userInfo.user.auth_type.includes('관리자') || (userInfo.permission.inquiry?.use_yn && userInfo.permission.inquiry?.roles.scrapCs),
      online: userInfo.user.auth_type.includes('관리자') || (userInfo.permission.online?.use_yn && userInfo.permission.online?.roles.scrapProd),
    };
    $scope.sendParams = localStorageService.get(`quickCollect_selectedMall_${userInfo.user.m_no}`) ? localStorageService.get(`quickCollect_selectedMall_${userInfo.user.m_no}`).data : [];
    $scope.isMallSelected = !!$scope.sendParams.length;
    $scope.selectedShopType = 'all';
    $scope.mallCnt = {
      all: 0,
      domestic: 0,
      global: 0
    };

    const initWorkList = (workListArr, config, configList) => {
      workListArr.forEach(work => {
        if (configList.includes(work.job_type)) {
          work.selected = true;
        }
        // 퀵수집 설정이 있을경우 설정에 없는 작업은 selected false로 변경
        if (config !== null && !configList.includes(work.job_type)) {
          work.selected = false;
        }
      });
    };

    const init = () => {
      shopAccountModel.shopAccountUseList({ site_action: 'ScrapOrder' })
        .then(function (data) {
          $scope.selectedAll = false;
          $scope.mall = commonSVC.getSiteIdList(data.data.results).map(mall => ({ ...mall, selected: false, isShow: true }));
          $scope.mallTotalCnt = localStorageService.get(`quickCollect_selectedMall_${userInfo.user.m_no}`)?.data.length || $scope.mall.length;

          const compareMallList = $scope.sendParams;

          if (compareMallList.length === $scope.mall.length) {
            $scope.selectedAll = true;
            $scope.mall.forEach(mall => {
              mall.selected = true;
            });
            $scope.mallTotalCnt = $scope.mall.length;
            // 선택된 쇼핑몰 갯수 계산
            $scope.calcSelectedShops();
          }
          else {
            $scope.selectedAll = compareMallList.length === $scope.mall.length;
            $scope.mall.forEach(mall => {
              mall.selected = !!compareMallList.find(m => mall.shop_id === m.shop_id && mall.shop_cd === m.shop_cd);
            });
            $scope.mallTotalCnt = $scope.mall.filter(({ selected }) => selected).length;
            // 선택된 쇼핑몰 갯수 계산
            $scope.calcSelectedShops();
          }
        });
      const config = localStorageService.get(`quickCollectNew_${userInfo.user.m_no}`);
      const configList = config?.split(',').filter(job => job !== 'ScrapProd') || [];
      initWorkList($scope.workListCheck, config, configList);
      initWorkList($scope.workListRadio, config, configList);

    };

    init();

    /**
     * 체크박스 선택된 쇼핑몰 갯수
     */
    $scope.calcSelectedShops = () => {
      $scope.mallCnt.all = $scope.mall.filter(shop => shop.selected).length;
      $scope.mallCnt.domestic = $scope.mall.filter(shop => !shop.pa_shop_cd?.startsWith('X') && shop.selected).length;
      $scope.mallCnt.global = $scope.mall.filter(shop => shop.pa_shop_cd.startsWith('X') && shop.selected).length;
    };

    /**
     * 쇼핑몰 타입 선택 (국내, 해외)
     */
    $scope.selectShopType = (shopType) => {

      if (shopType === 'all') {
        $scope.mall.forEach(shop => {
          shop.isShow = true;
        });
      } else if (shopType === 'global') {
        $scope.mall.forEach(shop => {
          shop.isShow = shop.pa_shop_cd.startsWith('X');
        });
      } else {
        $scope.mall.forEach(shop => {
          shop.isShow = !shop.pa_shop_cd?.startsWith('X');
        });
      }

      // 활성화된 탭 전체사이트 여부 체크.
      const unSelectedOrder = _.filter($scope.mall, function(o) {
        return o.isShow && !o.selected;
      });
      $scope.selectedAll = !unSelectedOrder.length;
    };

    $scope.setCollectOpt = (type, isSelect) => {
      $scope.workListRadio.forEach((work, idx) => {
        if (work.job_type === type) {
          $scope.workListRadio[idx] = { ...work, selected: !isSelect };
        } else {
          $scope.workListRadio[idx] = { ...work, selected: false };
        }
      });
      const isOptNullCheck = !!$scope.workListRadio.some(({ selected }) => selected);
      if (!isOptNullCheck) {
        $scope.selectedCntOpt = 0;
      } else {
        $scope.selectedCntOpt += 1;
      }

      const selectedCnt = $scope.workListCheck.filter(({ selected }) => selected).length;
      $scope.isSelected = $scope.workListRadio.filter(({ selected }) => selected).length + selectedCnt;
    };

    /**
     * 설정 저장
     */
    $scope.save = async () => {
      if (!$scope.mall.some(({ selected }) => selected)) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

        return false;
      }

      if ($scope.workListRadio.filter((work) => work.job_type === 'ScrapOrderConfirmList' && work.selected).length) {
        $scope.workListRadio.push({ workName: '신규주문 가져오기', job_type: 'ScrapOrder', selected: true, tooltip: '주문 확인이 완료된 주문을 신규주문으로 가져옵니다.', auth: 'order' });
      }

      const sendMall = $scope.mall.filter(v => v.selected);
      const jobAllList = workSVC.quickCollectJobListCheck.concat(workSVC.quickCollectJobListRadio).concat({ workName: '신규주문 가져오기', job_type: 'ScrapOrder', selected: true, tooltip: '주문 확인이 완료된 주문을 신규주문으로 가져옵니다.', auth: 'order' }); // 퀵수집 작업리스트 전체
      let jobList = $scope.workListCheck.filter(({ selected }) => selected).map(({ job_type }) => job_type).concat($scope.workListRadio.filter(({ selected }) => selected).map(({ job_type }) => job_type));

      // 사용자계정일 경우 설정된 권한에 따라 작업 필터링
      if (!userInfo.user.auth_type.includes('관리자')) {
        jobList = jobList.filter(job => {
          const jobAuth = jobAllList.find(({ job_type }) => job_type === job)?.auth,
                hasAuth = userInfo.permission[jobAuth]?.use_yn && userInfo.permission[jobAuth].roles[jobAuth === 'order' ? 'scrapOrder' : jobAuth === 'online' ? 'scrapProd' : 'scrapCs'];

          return hasAuth;
        });
      }

      if (!jobList.length) {
        commonSVC.showConfirm('설정된 작업이 없습니다.\n퀵수집 버튼 옆 설정 버튼을 통해 작업을 선택해주세요.');

        return;
      }

      $timeout(function () {
        $scope.waitBtnDisableFlag = true;
      });

      localStorageService.set(`quickCollect_selectedMall_${userInfo.user.m_no}`, { data: sendMall.map(v => ({ shop_cd: v.shop_cd, shop_id: v.shop_id })) });
      localStorageService.set(`quickCollectNew_${userInfo.user.m_no}`, jobList.join());

      commonSVC.showConfirmCustom({
        title: '퀵수집 저장 완료',
        text: '퀵수집 실행 항목 저장이 완료되었습니다.<br />퀵수집을 바로 실행하시겠습니까?<br />(\'판매 중 상품 가져오기\' 작업은 [확인] 클릭 후 바로 실행하는 경우에만 진행합니다.)',
        html: true,
      }, (isConfirm) => {
        if (isConfirm) {
          $rootScope.quickCollect();
        } else {
          localStorageService.set(`quickCollectNew_${userInfo.user.m_no}`, jobList.filter(job => job !== 'ScrapProd').join());
        }
        $uibModalInstance.close('success');
      });
    };

    /**
     * 설정 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.mallCheckboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.mall, function (mall) {
          if (mall.isShow) {
            mall.selected = $scope.selectedAll;
          }
        });
      } else {
        $scope.mall[type].selected = !$scope.mall[type].selected;
        $scope.selectedAll = !$scope.mall.some(mall => !mall.selected);
      }

      $scope.isMallSelected = $scope.mall.some(mall => mall.selected);
      // 선택된 쇼핑몰 갯수 계산
      $scope.calcSelectedShops();
    };

    $scope.collectCheckboxClick = (type, isCheck) => {
      $scope.workListCheck = $scope.workListCheck.map(job => {
        if (type === job.job_type) {
          return { ...job, selected: !isCheck };
        }

        return job;
      });
      const selectedCnt = $scope.workListRadio.filter(({ selected }) => selected).length;
      $scope.isSelected = $scope.workListCheck.filter(({ selected }) => selected).length + selectedCnt;

      return !isCheck;
    };

    $scope.changeTypeAndIsSelect = () => {
      $scope.mallTotalCnt = $scope.mall.length;
      $scope.collectType = 'total';
    };
  });
