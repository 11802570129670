/*
 *  log-Table
 *  2016-05-23 김민영
 */

'use strict';

angular.module('gmpApp')
  .directive('logTable', ['logHistorySVC', '$state', function(logHistorySVC) {
    return {
      templateUrl: 'views/tpls/logtable.html',
      restrict: 'E',
      replace: true,
      link: function ($scope, element, attrs) {
        $scope.check = false;

        const location = attrs.location;
        let log_data;
        let count = 0;
        let count_end = 7;

        const data = {
          location: location,
          number: '',
          sol_no: attrs.sol,
        };

        if (location === 'user') {
          data.number = $scope.m_no;
        } else if (location === 'online_product') {
          data.number = $scope.data.ol_shop_no;
        } else if (location === 'order_shipment') {
          data.number = $scope.uniq;
        } else if (location === 'as') {
          data.number = $scope.mend_no;
        } else if (location === 'atalk_auto_reg') {
          data.number = $scope.uniq;
        } else {
          data.number = $scope.number;
        }

        $scope.historyList = [];

        //로그 로드
        logHistorySVC.getLog(location, data, function(error, data) {
          log_data = angular.copy(data);
          // ol 에 한하여 로그 메세지가 250자가 넘을 경우 맨 끝의 3자를 잘라내고 ...를 붙임
          if (location === 'online_product') {
            _.forEach(log_data, function (item, idx) {
              if (item.note.length >= 250) {
                log_data[idx].note = `${item.note.slice(0, -3)}...`;
              }
            });
          }

          // if(log_data.length <= count_end){
          //   count_end = log_data.length-1;
          // }
          // count_end = log_data.length-1;

          for (; count < log_data.length; count++) {
            $scope.historyList.push(log_data[count]);
          }
        });

        function loadMore() {
          if (log_data) {
            //리스트와 로그데이터 수가 같은경우
            if ($scope.historyList.length === log_data.length) {
              return false;
            }

            count = $scope.historyList.length;
            if ($scope.historyList.length + 7 >= log_data.length - 1) {
              count_end = log_data.length - 1;
            } else {
              count_end = $scope.historyList.length + 7;
            }

            for (; count <= count_end; count++) {
              $scope.historyList.push(log_data[count]);
            }

            $scope.$apply();
          }
        }

        const raw = $('.modal-body:eq(0)');

        $('.modal-body').bind('scroll', function () {
          if (raw[0].scrollTop + raw[0].offsetHeight > raw[0].scrollHeight - 1) {
            const text = $('.active > a').text();

            if (text === 'LOG') {
              loadMore();
            }
          }
        });
      }
    };
  }]);
