'use strict';

angular.module('gmpApp')
  .controller('CategorySearchHscodeCtrl', function ($scope, data, $compile, settings, commonSVC, $uibModalInstance, productModel) {
    $scope.tabActive = 0;
    $scope.searchData = {};
    //검색 값 지정
    $scope.defaultData = {
      keyword: data.name,
      search_key: 'all',
      only_bookmark: false
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['hscd', 'default_tax_rate', 'select', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['bookmark_yn'],
      notSortingColumns: ['hscd', 'select'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      gridHeight: 390,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/hscode`,
        requestWillAction: function (data) {
          data = { ...data, ...$scope.defaultData };
          data.length = 25;

          return data;
        },
        requestDidAction: function (result) {
          return result.results;
        }
      },
      rowHeight: 35,
      columns: [
        {
          key: 'hscd',
          title: 'HS코드',
          width: 20
        },
        {
          key: 'item_kor',
          title: '상세 정보',
          width: 60,
          template: function (row) {
            return `<span class='text-nowrap-normal' style='white-space: normal;'>${row.item_kor}</span>`;
          }
        },
        {
          key: 'default_tax_rate',
          title: '기준세율',
          width: 10,
          template: function(row) {
            return row.default_tax_rate > 0 ? `${row.default_tax_rate * 100} %` : '';
          }
        },
        {
          key: 'select',
          title: '선택',
          width: 10,
          template: function (row) {
            return $compile(`<button style="padding: 0 10px" class="btn btn-default btn-sm" ng-click="gridSelect('${row.hscd}')">선택</button>`)($scope);
          }
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 10,
          template: function (row) {
            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    $scope.searchstart = function () {
      $scope.grid.methods.reloadData(function() { }, true);
    };

    $scope.gridSelect = function (hscodeNum) {
      $uibModalInstance.close(hscodeNum);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.clickTab = function (index) {
      $scope.tabActive = index;
      $scope.defaultData.only_bookmark = index;
      $scope.searchstart();
    };

    $scope.changeDo = async function ({ hscd }) {
      try {
        await productModel.editHscodeBookmark({ hscd });

        commonSVC.showToaster('success', '성공', '즐겨찾기 수정 성공');
        $scope.searchstart(); //즐겨 찾기 수정후 리스트재정렬 위해 새로고침
      } catch (error) {
        commonSVC.showToaster('error', '실패', '즐겨찾기 수정 실패');

        return false;
      }
    };

    $scope.reset = function () {
      $scope.defaultData = {
        keyword: '',
        search_key: 'all',
        only_bookmark: false
      };
      $scope.searchstart();
    };
  });