/**
 * 이용료 결제.
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayRequestCtrl', function (paySVC, $scope, data, commonSVC, $uibModalInstance, gettextCatalog, $filter, payModel, $rootScope, userInfo, commonModel, localStorageService, adminModel, cardSVC, $window, $timeout) {
    // 현제 솔루션 정보
    $scope.solutionInfo = data.solutionInfo;
    // 추천 솔루션 정보
    $scope.recommendVersion = data.recommendVersion;
    // 솔루션 상품별 가격정보
    const solutionProductInfo = angular.copy(data.solutionProductInfo);
    // 가입비 기존 결제내역
    const payedJoinPrice = data.payedJoinPrice;
    // 결제 셋팅 정보
    const paymentSetupInfo = data.paymentSetupInfo || {};

    let solPrice = 0;
    let solOlCnt = 0;
    let solOrdCnt = 0;
    let pay_method = '';

    const dataLayer = $window.dataLayer = $window.dataLayer || [];
    // 닫힘 버튼 확성화 여부
    $scope.closeFlag = data.closeFlag;

    // 다운그레이드는 한달에 한번으로 제한하므로 한달이내에 다운그레이드 기록이 있는지 체크.
    let enableDowngrade = true;
    payModel.checkDowngrade('', function (status, checkRes) {
      if (checkRes[0].cnt > 0) {
        enableDowngrade = false;
      }
    });

    const indv_pay_amt = $rootScope.indvPayInfo.indv_pay_amt; // 개별설정 월사용요금
    const indv_ol_charge_cnt_limit = $rootScope.indvPayInfo.ol_charge_cnt_limit; // 개별설정 월상품전송 카운트
    const indv_ord_charge_cnt_limit = $rootScope.indvPayInfo.ord_charge_cnt_limit; // 개별설정 월주문수집 카운트

    // SLIM 버전 프로모션
    const slimPromotion = moment().format('YYYY-MM-DD') < '2030-01-01' ? 40000 : 0;

    // 무료사용자 여부
    $scope.freeUser = data.firstOrdYN || paySVC.freeVersion.includes($scope.solutionInfo.sol_version);

    // SLIM 버전 결제가능 여부
    $scope.paySlim = $scope.freeUser || $scope.solutionInfo.sol_version == 'SLIM';

    // 선불카운트 구매가능여부
    $scope.useAdvanceCnt = !$scope.freeUser && !paySVC.limitedVersion.includes($scope.solutionInfo.sol_version);

    // 정기결제만 가능한지 여부
    $scope.onlyAutoPayVersion = (version) => paySVC.onlyAutoPayVersion.includes(version);

    // 정기결제 기본값
    $scope.setAutoPay = $scope.onlyAutoPayVersion($scope.solutionInfo.sol_version);

    $scope.coupon_list = [];
    $scope.coupon_list1 = [];
    $scope.coupon_list2 = [];
    $scope.dupl_coupon_list = []; // 중복 쿠폰 리스트

    // 자동결제, 환불 저장 정보
    $scope.setAutoDatas = {
      card_no: '',                                  // 카드번호
      card_info: '등록된 카드가 없습니다.',           // 카드번호
      card_effect_term: '',                         // 카드 유효기간
      card_type: 'company',                         // 카드종류 (개인, 법인)
      birth_num: '',                                // 개인카드인경우 주민번호 앞 6자리
      cardPw: '',                                   // 카드 비밀번호 앞 2자리
      biz_reg_no: userInfo.user.biz_reg_no,         // 사업자 등록번호 넘김
      use_yn: 'N'                                   // 자동결제 적용여부
    };

    payModel.getUserCouponList({}, function(status, data) {
      $scope.coupon_list = data.results
        .filter(coupon => coupon.use_type !== '즉시혜택' && ['미사용', '사용대기'].includes(coupon.cpn_status))
        .sort((a, b) => a.dupl_dc_yn - b.dupl_dc_yn);
    });

    /**
     * 자동 결제정보 및 환불정보 조회.
     */
    payModel.getAutoPayInfo('', function (status, data) {
      if (status == 'success') {
        // 저장된 자동결제정보가 있는경우
        if (data.autoPayInfo.length) {
          $scope.setAutoDatas.autopay_term = data.autoPayInfo[0].autopay_term;

          if (data.autoPayInfo[0].card_no) {
            const cardNos = data.autoPayInfo[0].card_no.split('-');

            $scope.setAutoDatas.biz_reg_no = data.autoPayInfo[0].card_biz_reg_no || userInfo.user.biz_reg_no;
            $scope.setAutoDatas.card_no = data.autoPayInfo[0].card_no;
            $scope.setAutoDatas.card_info = `${cardNos[0]}-${cardNos[1]}-${commonSVC.maskingByPosiotion(cardNos[2], 0)}-${commonSVC.maskingByPosiotion(cardNos[3], 0)}`;
            $scope.setAutoDatas.card_effect_term = data.autoPayInfo[0].card_effect_term;
            $scope.setAutoDatas.card_type = data.autoPayInfo[0].card_type;
            $scope.setAutoDatas.birth_num = data.autoPayInfo[0].birth_num || '';

            // 카드사 조회
            const cardNumber = $scope.setAutoDatas.card_no.replace(/-/g, '');
            let companyName = cardSVC.getCompanyName(cardNumber.substring(0, 6));

            // 카드사가 조회되지 않을 시 개인카드 or 법인카드로 표기
            switch (companyName) {
              case '카드':
                companyName = ($scope.setAutoDatas.card_type === 'personal') ? '개인카드' : '법인카드';
                break;
              default:
                break;
            }

            // 등록된 카드가 없을경우 카드사 공란처리
            $scope.setAutoDatas.companyName = !cardNumber ? '' : companyName;
          }
        }

        // 세금계산서 선발행 여부
        if (data.tax_invoice_before_add_yn) {
          $scope.payData.PayMethod = 'BANK';
        }
      }
    });

    /**
     * 자동 결제정보 입력 창 열기.
     */
    let selectedType = '';

    $scope.autoPayInfo = async function (type) {
      selectedType = type;

      const res = await adminModel.load({ c_no: userInfo.user.c_no, onlyCompany: true });
      const company = res.data.results.company;

      const params = {
        type: 'card',
        card_biz_reg_no: $scope.setAutoDatas.biz_reg_no || company.biz_reg_no,
        card_no: $scope.setAutoDatas.card_no,
        card_effect_term: $scope.setAutoDatas.card_effect_term,
        card_type: $scope.setAutoDatas.card_type,
        birth_num: $scope.setAutoDatas.birth_num,
        setAutoPayInfo: true,
      };
      const { re, setData } = await commonSVC.openModal('md', { data: params }, 'AutoPayInfoCtrl', 'views/pay/modals/autopay_info.html').result;
      if (re !== 'success') {
        return false;
      }

      // 카드번호
      const card_no = `${setData.card_no1}-${setData.card_no2}-${setData.card_no3}-${setData.card_no4}`;

      // 카드사 조회
      const cardNumber = card_no.replace(/-/g, '');
      let companyName = cardSVC.getCompanyName(cardNumber.substring(0, 6));

      // 카드사가 조회되지 않을 시 개인카드 or 법인카드로 표기
      if (companyName == '카드') {
        companyName = setData.card_type === 'personal' ? '개인카드' : '법인카드';
      }

      // 등록된 카드가 없을경우 카드사 공란처리
      $scope.setAutoDatas.companyName = !cardNumber ? '' : companyName;

      $scope.setAutoDatas.card_no = card_no;
      $scope.setAutoDatas.card_info = `${setData.card_no1}-${setData.card_no2}-${commonSVC.maskingByPosiotion(setData.card_no3, 0)}-${commonSVC.maskingByPosiotion(setData.card_no4, 0)}`;
      $scope.setAutoDatas.card_effect_term = `${setData.card_effect_term1}-${setData.card_effect_term2}`;
      $scope.setAutoDatas.card_type = setData.card_type;
      $scope.setAutoDatas.birth_num = setData.birth_num;
      $scope.setAutoDatas.biz_reg_no = setData.biz_reg_no || company.biz_reg_no;
      $scope.setAutoDatas.cardPw = setData.cardPw;

      let message = '';

      switch (selectedType) {
        case 'card_add':
          message = '결제정보 등록 성공';
          break;
        case 'card_edit':
          message = '카드정보 수정 성공';
          break;
        default:
      }
      commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString(message));

      return true;
    };

    $scope.changePayMethod = async (type) => {
      if (['VBANK', 'BANK'].includes(type)) {
        const res = await adminModel.load({ c_no: userInfo.user.c_no, onlyCompany: true });
        const company = res.data.results.company;
        const format_invalid = ['name', 'ceo_name', 'addr1', 'uptae', 'upjong'].some(key => commonSVC.isEmailFormat(company[key]));

        if (!company.biz_reg_url || !company.addr1
          || (company.c_type !== '해외사업자' && (!company.zipcd || !company.uptae || !company.upjong)) || format_invalid) {
          if (!userInfo.user.auth_type.includes('관리자') && (!userInfo.permission.setting?.use_yn || !userInfo.permission.setting?.roles.company)) {
            commonSVC.showMessage('결제 시 사업자정보는 필수로 입력되어 있어야 합니다.', '회사 정보 관리 메뉴에 접근 권한이 없습니다.\n사내 관리자에게 요청하여 사업자정보 저장 후 다시 진행해 주세요.');

            $scope.payData.PayMethod = pay_method;
          } else {
            commonSVC.showConfirm('가상계좌, 무통장 입금 결제 시 사업자정보는 필수로 입력되어 있어야 하며, 개인 회원인 경우 신용카드 결제로 진행 가능합니다.', '사업자 정보를 등록하시겠습니까?')
              .then((isConfirm) => {
                if (isConfirm) {
                  const modal = commonSVC.openModal('xg', { data: company }, 'EditUserInfoModalCtrl', 'views/pay/modals/editUserInfo.html');

                  modal.result.then(function (re) {
                    if (re === 'success') {
                      commonSVC.showToaster('success', '성공', '사업자정보 저장 성공');
                      $scope.payData.PayMethod = type;
                      pay_method = $scope.payData.PayMethod;
                    } else {
                      $scope.payData.PayMethod = pay_method;
                    }
                  });
                } else {
                  $scope.payData.PayMethod = pay_method;
                }
              });
          }
        } else {
          $scope.payData.PayMethod = type;
          pay_method = $scope.payData.PayMethod;

          $timeout(() => {});
        }
      } else {
        if ($scope.payData.PayMethod === 'CARD') {
          $scope.setAutoPay = false;
        }
        $scope.payData.PayMethod = type;
        pay_method = $scope.payData.PayMethod;
      }
    };

    /**
     * 정기결제 신청 체크여부
     */
    $scope.chkAutoPayInfo = async () => {
      if ($scope.setAutoPay) {
        $scope.payData.PayMethod = 'AUTOCARD';
        if (!$scope.setAutoDatas.card_no) {
          await commonSVC.showMessage('등록된 카드가 없습니다.', '• 카드정보를 등록해 주세요.');
          const re = await $scope.autoPayInfo('card_add');
          if (!re) {
            if (!$scope.onlyAutoPayVersion($scope.payData.version)) {
              $scope.setAutoPay = false;
            }

            return false;
          }
        } else {
          commonSVC.showMessage('등록된 카드정보로 결제를 진행합니다.');
        }
      } else {
        const hasAutoPayCoupon = $scope.coupon_list.some(coupon => {
          return (
            coupon.use_type === '정기결제할인' &&
            (coupon.cpn_no === +$scope.payData.coupon.cpn_cd1 || coupon.cpn_no === +$scope.payData.coupon.cpn_cd3)
          );
        });
        if (hasAutoPayCoupon) {
          const confirmCheck = await commonSVC.showConfirmCustom({ title: '정기결제 신청을 해제하시겠습니까?\n정기결제 신청 해제 시 정기결제 쿠폰 적용이 해제됩니다.', text: '• 확인을 클릭 하시면 정기결제 신청과 적용된 정기결제 쿠폰이 해제됩니다.\n• 사용 가능한 쿠폰으로 다시 적용해 주세요.' });
          if (confirmCheck) {
            deSelectCouponList(); // 선택된 쿠폰 해제
          } else {
            $scope.setAutoPay = true;

            return false;
          }
        }
      }
      filterCouponLists();

      $timeout(() => {
        $scope.calculateDcPrice();
      });

      return true;
    };

    // 장기결제 할인율 (3개월: 5%, 6개월: 10%, 12개월: 20%, 24개월: 30%)
    const longTermPayDscntRate = {
      3: 0.05,
      6: 0.1,
      12: 0.2,
      24: 0.3
    };

    // 결제 정보
    $scope.payData = {
      atalkCnt: '0',
      smsCnt: '0',
      emailCnt: '0',
      trnslCnt: '0',
      atalkPrice: '0',
      smsPrice: '0',
      emailPrice: '0',
      trnslPrice: '0',
      sumPrice: '0',
      discountPrice: '0',
      totalPrice: '0',
      dcPrice: 0,
      finalPrice: 0,
      autoPay: false,
      payType: '',
      extendTerm: '1',
      sol_no: $scope.solutionInfo.sol_no,
      m_no: userInfo.user.user_m_no,
      version: $scope.solutionInfo.sol_version,
      oldVersion: $scope.solutionInfo.sol_version,
      oldEdate: $scope.solutionInfo.sol_edate,
      expireDate: '30',
      firstOrdYN: data.firstOrdYN,      // 첫결제여부

      Moid: '',
      PayMethod: '',
      Amt: '1',
      GoodsName: '',
      GoodsCnt: '1',
      BuyerName: userInfo.user.company_name,
      BuyerTel: userInfo.user.htel !== '' ? userInfo.user.htel : userInfo.user.tel,
      UserIP: userInfo.user.remote_ip,
      VbankExpDate: '',
      BuyerEmail: userInfo.user.email,
      TransType: '0',
      EncodeParameters: '',
      EdiDate: commonSVC.getDate(new Date(), 'yyyyMMddHHmmss'),
      EncryptData: '',
      TrKey: '',

      autoPayPrice: '0',    // 자동결제 금액 ( 자동결제가 가능한경우 지불될 수 있는 금액 )
      pay_no: '',
      coupon: {
        cpn_contents: '',
        cpn_cd1: '', // 일반 쿠폰
        cpn_amt1: '',
        cpn_cd2: '', // 가입비 쿠폰
        cpn_amt2: '',
        cpn_cd3: '', // 중복 쿠폰
        cpn_amt3: '',
        cpn_ben: [], // 사용 기간 추가 쿠폰 데이터
      },
      deposit_name: userInfo.user.company_name.substr(0, 10),          // 무통장입금일때 입금자명(기본값: 업체명) 2019-01-22 rony
      advanceCnt: {                                                    // 선불카운트
        type: 'ORD',
        cnt: '0',
        price: 0
      }
    };

    /**
     * 결제 테이블 GRID
     */
    $scope.payGrid = [];

    /**
     * 1:1 문의 모달 열기
     */
    $scope.question = function () {
      // 1:1 문의 모달만 esc로 닫히지 않게 설정 (1:1문의 하기 모달이 닫힐 때 나오는 컨펌창을 esc로 꺼버리면 같이 적용되기 때문) - Alvin
      const modal = commonSVC.openModal('xg', '', 'QuestionInquiryCtrl', 'views/question/modals/inquiry.html', false, true, false, 'pay_request');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * TABLE ROW 추가.
     */
    $scope.item = [];

    $scope.addRow = async function (type, version) {
      const item = [];

      let extendMsg = `${$scope.payData.extendTerm}개월 연장`;

      // 만료일 유지는 잔여일이 있고 버전변경인 경우에만 가능.
      if (type === 'version' && $scope.payData.extendTerm === '0') {
        if ($scope.freeUser) {
          commonSVC.showMessage(gettextCatalog.getString('첫결제인경우 한달이상 결제만 가능합니다.'));

          return false;
        } else if ($scope.payData.oldVersion === version) {
          commonSVC.showMessage(gettextCatalog.getString('만료일유지는 버전변경시에만 가능합니다.'));

          return false;
        } else if ($scope.solutionInfo.leftDay < 1) {
          commonSVC.showMessage(gettextCatalog.getString('만료일 유지는 잔여일이 1일 이상인경우 가능합니다.'));

          return false;
        }

        extendMsg = '만료일유지';
      }

      // 버전변경시에는 카운트 추가구매 불가 (버전별 카운트 금액이 상이 하기 떄문에 버전변경시에 문제가 발생 될 소지가 있으므로 막음.)
      if ((type === 'advanceCnt' && $scope.payData.version != $scope.payData.oldVersion) || (type === 'version' && $scope.payData.version != $scope.payData.oldVersion && _.filter($scope.payGrid, function(o) { return o.content.indexOf('카운트 추가구매') > -1; }).length)) {
        commonSVC.showMessage(gettextCatalog.getString('버전 변경시에는 선불카운트 구매가 불가능합니다.'));

        return false;
      }

      // 가입비
      let joinPrice = [];

      // 이미 추가된 결제내역인지 확인
      let already = false;
      let conpareType = type;

      for (let i = 0; i < $scope.payGrid.length; i++) {
        if ($scope.payGrid[i].type === 'upgrade' || $scope.payGrid[i].type === 'downgrade') {
          conpareType = 'version';
        } else {
          conpareType = $scope.payGrid[i].type;
        }
        if (conpareType === type) {
          already = true;
          break;
        }
      }

      if (already === true) {
        commonSVC.showMessage(gettextCatalog.getString('이미 추가된 결제내역 입니다.'));

        return false;
      }

      // 결제 작업 타입
      $scope.payData.payType = type;

      item.type = type;
      if (type === 'atalk') {
        if ($scope.payData.atalkCnt === '0') {
          commonSVC.showMessage(gettextCatalog.getString('알림톡 충전 건수를 선택해 주십시오.'));

          return false;
        }
        item.prod_no = '13';
        item.sale_cnt = $scope.payData.atalkCnt;
        item.division = '부가서비스';
        item.content = '알림톡 충전';
        item.contentDetail = `${$filter('number')($scope.payData.atalkCnt)} 건`;
        item.price = $scope.payData.atalkPrice;
        item.price_pre = $scope.payData.atalkPrice;
      } else if (type === 'sms') {
        if ($scope.payData.smsCnt === '0') {
          commonSVC.showMessage(gettextCatalog.getString('SMS 충전 건수를 선택해 주십시오.'));

          return false;
        }
        item.prod_no = '12';
        item.sale_cnt = $scope.payData.smsCnt;
        item.division = '부가서비스';
        item.content = 'SMS 충전';
        item.contentDetail = `${$filter('number')($scope.payData.smsCnt)} 건`;
        item.price = $scope.payData.smsPrice;
        item.price_pre = $scope.payData.smsPrice;
      } else if (type === 'email') {
        if ($scope.payData.emailCnt === '0') {
          commonSVC.showMessage(gettextCatalog.getString('Email 충전 건수를 선택해 주십시오.'));

          return false;
        }
        item.prod_no = '14';
        item.sale_cnt = $scope.payData.emailCnt;
        item.division = '부가서비스';
        item.content = 'Email 충전';
        item.contentDetail = `${$filter('number')($scope.payData.emailCnt)} 건`;
        item.price = $scope.payData.emailPrice;
        item.price_pre = $scope.payData.emailPrice;
      } else if (type === 'trnsl') {
        if ($scope.payData.trnslCnt === '0') {
          commonSVC.showMessage(gettextCatalog.getString('번역 카운트 충전 건수를 선택해 주십시오.'));

          return false;
        }
        item.prod_no = '102';
        item.sale_cnt = $scope.payData.trnslCnt;
        item.division = '부가서비스';
        item.content = '번역 카운트 충전';
        item.contentDetail = `${$filter('number')($scope.payData.trnslCnt)} 건`;
        item.price = $scope.payData.trnslPrice;
        item.price_pre = $scope.payData.trnslPrice;
      } else if (type === 'advanceCnt') {
        if ($scope.payData.advanceCnt.cnt === '0') {
          commonSVC.showMessage(gettextCatalog.getString('카운트 충전 건수를 선택해 주십시오.'));

          return false;
        }

        const compareTitle = $scope.payData.advanceCnt.type === 'PROD' ? '상품전송수(월)_선불' : '주문수집수(월)_선불';

        item.prod_no = _.filter(solutionProductInfo, function(o) { return o.prod_name === compareTitle && o.prod_opt === $scope.payData.oldVersion; })[0].prod_no;
        item.sale_cnt = $scope.payData.advanceCnt.cnt;
        item.division = '부가서비스';
        item.content = '카운트 추가구매';
        item.contentDetail = `(${$scope.payData.oldVersion})${$scope.payData.advanceCnt.type === 'PROD' ? '상품' : '주문'}카운트${$filter('number')($scope.payData.advanceCnt.cnt)} 건`;
        item.price = $scope.payData.advanceCnt.price;
        item.price_pre = $scope.payData.advanceCnt.price;
        item.type = compareTitle;
      }
      // 솔루션 버전 결제.
      else {
        let currentIdx = 0;
        let nextIdx = 0;

        // 기존, 결제하려는 버전의 일 금액.
        let newVerDayPrice = 0;
        const oldVerDayPrice = $scope.solutionInfo.dayPrice;

        for (let ii = 0; ii < solutionProductInfo.length; ii++) {
          if (solutionProductInfo[ii].prod_opt === '1개월') {
            if (solutionProductInfo[ii].prod_name === $scope.solutionInfo.sol_version) {
              currentIdx = ii;
            }
            if (solutionProductInfo[ii].prod_name === version) {
              nextIdx = ii;
              // 설정된 월사용금액이 있다면 솔루션 버전별 사용금액 말고 설정된 월사용금액으로 금액적용됨 (백오피스 업체정보에서 설정가능)
              solPrice = indv_pay_amt || solutionProductInfo[ii].price;
              solOlCnt = indv_ol_charge_cnt_limit;
              solOrdCnt = indv_ord_charge_cnt_limit;
              item.prod_no = solutionProductInfo[ii].prod_no;
              item.prod_group_no = solutionProductInfo[ii].prod_group_no;

              // 자동결제시 지불될 금액 및 카운트
              // 자동결제는 한달 솔루션 금액으로 ( 결제개월 이후 적용 )
              $scope.payData.autoPayPrice = solPrice;
              $scope.payData.olChargeCntLimit = solOlCnt;
              $scope.payData.ordChargeCntLimit = solOrdCnt;
            }
          }
          // 해당 결제하려는 버전의 일금액 구함.
          else if (solutionProductInfo[ii].prod_name === version && solutionProductInfo[ii].prod_opt === '1일') {
            newVerDayPrice = solutionProductInfo[ii].price;
          }
        }

        if (indv_pay_amt && nextIdx !== currentIdx) {
          commonSVC.showMessage('개별설정 월사용요금으로 이용중', '현재 버전에서 개별 설정된 월사용요금으로 이용중입니다.\n이에 따라 버전 변경이 불가능하오니 버전 변경을 원하실경우 1:1문의를 통해 문의해주시기 바랍니다.');

          return false;
        }

        if (indv_ol_charge_cnt_limit && nextIdx !== currentIdx) {
          commonSVC.showMessage('개별설정 월주문수집 카운트로 이용중', '현재 버전에서 개별 설정된 월주문수집 카운트로 이용중입니다.\n이에 따라 버전 변경이 불가능하오니 버전 변경을 원하실경우 1:1문의를 통해 문의해주시기 바랍니다.');

          return false;
        }

        if (indv_ord_charge_cnt_limit && nextIdx !== currentIdx) {
          commonSVC.showMessage('개별설정 월상품전송 카운트로 이용중', '현재 버전에서 개별 설정된 월상품전송 카운트로 이용중입니다.\n이에 따라 버전 변경이 불가능하오니 버전 변경을 원하실경우 1:1문의를 통해 문의해주시기 바랍니다.');

          return false;
        }

        // 다운그레이드시 변경될 버전에서 사용가능한 쇼핑몰 아이디 갯수가 현재 등록된 계정의 쇼핑몰 아이디 갯수보다 작은경우 에러
        if (nextIdx < currentIdx) {
          const nextVersionShopAccountCnt = userInfo.user.availableShopAccountCntByVersion.find(o => o.prod_name.toUpperCase() === version.toUpperCase());

          if (Number(userInfo.user.shopAccountCnt) > Number(nextVersionShopAccountCnt.versionCnt)) {
            commonSVC.showMessage('쇼핑몰 이용 개수 초과', '현재 솔루션에 등록된 쇼핑몰 개수가 다운그레이드 대상 버전의 쇼핑몰ID 사용 개수를 초과하였습니다.\n이에 따라 버전 변경이 불가능하오니, 버전 변경을 원하실 경우 쇼핑몰 개수를 조정해 주시기 바랍니다.\n(경로 : 설정 > 쇼핑몰 계정 관리 > 쇼핑몰 선택 > [삭제])');

            return false;
          }

          // 변경될 버전에서 사용가능한 유저 아이디 갯수가 현재 등록된 아이디 갯수보다 작은경우 에러.
          const nextVersionUserAccountCnt = userInfo.user.availableUserAccountCntByVersion.find(o => o.prod_name.toUpperCase() === version.toUpperCase());

          if (nextVersionUserAccountCnt.versionCnt !== '무제한' && Number(userInfo.user.userAccountCnt) > Number(nextVersionUserAccountCnt.versionCnt)) {
            commonSVC.showMessage('사용자 등록갯수 초과', '현재 솔루션에 등록된 사용자 갯수가 다운그레이드 대상 버전의 사용자 사용갯수를 초과하였습니다.\n이에 따라 버전 변경이 불가능하오니 버전 변경을 원하실경우 1:1문의를 통해 문의해주시기 바랍니다.');

            return false;
          }
        }

        $scope.payData.version = version;
        $scope.payData.payType = 'expire';
        item.division = 'PLAYAUTO 2.0';
        let remainDaysPrice = 0;

        if (nextIdx > currentIdx) {
          // 버전업그레이드인경우 추가결제금액 계산.  / 원단위절삭
          // (변경되는 버전 일금액 * 잔여일) - (이전버전 일금액 * 잔여일)
          // 첫결제인경우는 무조건 한달이상 결제가 가능하고 기존 잔여일은 무료로 추가되기 때문에 계산할 필요 없음.
          if (!$scope.freeUser && parseInt($scope.solutionInfo.leftDay) > 0) {
            remainDaysPrice = Math.floor(((newVerDayPrice * $scope.solutionInfo.leftDay) - (oldVerDayPrice * $scope.solutionInfo.leftDay)) / 10) * 10;
          }

          item.type = 'upgrade';
          item.content = 'PLAYAUTO 2.0 버전변경';
          item.contentDetail = `${$scope.solutionInfo.sol_version} > ${version} 버전 업그레이드 (${extendMsg})`;
          if (remainDaysPrice > 0) {
            item.addContentDetail = `기존만료일 버전변경 비용계산 : (${newVerDayPrice}원 X ${$scope.solutionInfo.leftDay}일) - (${oldVerDayPrice}원 X ${$scope.solutionInfo.leftDay}일)`;
          }

          // commonSVC.showMessage('PLAYAUTO 2.0 솔루션 버전 변경 주의사항', '버전 업그레이드시 기존 버전에서 사용중이던 잔여상품, 주문 카운트는 새로운 버전으로 이전되지 않고 소멸됩니다.');
        } else if (nextIdx < currentIdx) {

          // 다운그레이드 기록이 있는경우 추가 다운그레이드 불가.
          if (enableDowngrade === false) {
            commonSVC.showMessage(gettextCatalog.getString('다운그레이드는 한달에 한번만 가능합니다.'));

            return false;
          }

          if (!$scope.payData.firstOrdYN && $scope.solutionInfo.leftDay > 0) {
            const addDays = Math.ceil((oldVerDayPrice * $scope.solutionInfo.leftDay) / newVerDayPrice);

            item.addContentDetail = `추가만료일 계산 : (${oldVerDayPrice}원 X ${$scope.solutionInfo.leftDay}일) / ${newVerDayPrice}원 => ${addDays}일 추가`;
          }

          if ($scope.payData.extendTerm === '0') {
            extendMsg = '만료일유지';
          }

          item.type = 'downgrade';
          item.content = 'PLAYAUTO 2.0 버전변경';
          item.contentDetail = `${$scope.solutionInfo.sol_version} > ${version} 버전 다운그레이드 (${extendMsg})`;

          // commonSVC.showMessage('PLAYAUTO 2.0 솔루션 버전 변경 주의사항', '버전 다운그레이드시 기존 버전에서 사용중이던 잔여상품, 주문 카운트는 새로운 버전으로 이전되지 않고 소멸됩니다.');
        } else {
          item.content = 'PLAYAUTO 2.0 연장';
          item.contentDetail = `${$scope.solutionInfo.sol_version} ${$scope.payData.extendTerm} 개월 연장`;
        }

        item.sale_cnt = $scope.payData.extendTerm;
        const payPrice = solPrice * parseInt($scope.payData.extendTerm) + remainDaysPrice;

        // 6개월 10%, 12개월 20% 할인적용 2018-12-17 rony
        let longTermPayDiscount = 0;

        if (!indv_pay_amt) {
          longTermPayDiscount = payPrice * (longTermPayDscntRate[$scope.payData.extendTerm] || 0);
        }

        // 할인 전 금액
        item.price_pre = payPrice * 1.1;
        // 할인적용 금액
        item.price = (payPrice - longTermPayDiscount) * 1.1;

        // 현재버전에 맞는 가입비 확인
        const checkJoinPrice = angular.copy(_.filter(solutionProductInfo, function(o) {
          return o.prod_name === '가입비' && $scope.payData.version === o.prod_opt;
        }));

        // 기존결제 가입비 확인.
        let old_joinPrice = 0;

        if (!_.isUndefined(payedJoinPrice.note)) {
          const old_joinPrice_ver = payedJoinPrice.note.split('(')[1].replace(')', '');
          const payed_joinPrice_info = angular.copy(_.filter(solutionProductInfo, function(o) {
            return o.prod_name === '가입비' && old_joinPrice_ver.trim() === o.prod_opt.trim();
          }));

          old_joinPrice = payed_joinPrice_info[0].price;
          // var cal_price = checkJoinPrice[0].price - payed_joinPrice_info[0].price;
        }

        // 첫결제 이거나 결제버전이 CLASSIC 이상이고 기존에 결제한 가입비가 현제 결제해야할 가입비보다 적은경우 추가 가입비 내야함.
        if (checkJoinPrice.length > 0) {
          if (checkJoinPrice[0].price - old_joinPrice > 0) {
            checkJoinPrice[0].price = checkJoinPrice[0].price - old_joinPrice;
            joinPrice = checkJoinPrice;
          }
        }

        if ($scope.onlyAutoPayVersion(version)) {
          await commonSVC.showMessageHtml('SLIM버전은 1개월 정기결제로 구매 가능합니다.<br />정기결제 진행 시 매월 3% 추가 할인 혜택이 제공됩니다.', `
          <ul><li>정기결제는 카드정보 등록이 필수입니다.</li>
          <li>매월 등록된 카드로 정기결제됩니다.</li>
          <li>정기결제는 언제든지 해지 가능합니다.</li></ul>`);

          $scope.setAutoPay = true;
          await $scope.chkAutoPayInfo();
        }
      }

      $scope.item = item;
      $scope.payGrid.push(item);

      // 솔루션 결제건이 없는 경우 정기결제 신청 체크박스 비활성화
      updateUseAutoPayYn();

      // 총결제금액 계산
      //$scope.payData.sumPrice += item.price;
      $scope.payData.totalPrice = parseInt($scope.payData.totalPrice) + parseInt(item.price_pre);
      //$scope.payData.GoodsCnt = item.sale_cnt;

      if (type === 'version') {
        filterCouponLists(); // 쿠폰리스트 노출
      }

      // 가입비가 있는경우 그리드에 더함.
      if (joinPrice.length > 0) {
        const joinItem = [];
        joinItem.type = '가입비';
        joinItem.prod_no = joinPrice[0].prod_no;
        joinItem.prod_group_no = joinPrice[0].prod_group_no;
        joinItem.sale_cnt = 1;
        joinItem.division = '가입비';
        joinItem.content = `PLAYAUTO 2.0 가입비 (${$scope.payData.version})`;
        joinItem.contentDetail = '';
        joinItem.price_pre = joinPrice[0].price + (joinPrice[0].price * 0.1);
        joinItem.price = joinPrice[0].price + (joinPrice[0].price * 0.1);
        $scope.payGrid.push(joinItem);
        $scope.payData.totalPrice = parseInt($scope.payData.totalPrice) + parseInt(joinItem.price_pre);

      }

      // 스크롤 하단으로 이동
      $('.modal-body').animate({ scrollTop: $('.modal-body').height() }, 'slow');
      $scope.calculateDcPrice();
    };

    // 쿠폰 리스트
    function filterCouponLists() {
      const todayDate = moment().format('YYYY-MM-DD');
      const todayTime = moment().format('YYYY-MM-DD HH:mm:ss');

      // 슬림버전 쿠폰사용 가능하도록 임시적용
      const slimCouponDate = todayDate >= '2024-10-22' && todayDate <= '2024-11-08';

      // 쿠폰 사용조건 확인
      const isAvailCoupon = (coupon) => (
        // 결제금액 확인
        $scope.item.price_pre > 0
        // 사용기한 확인
        && coupon.cpn_edate >= todayDate
        // 사용여부 확인
        && (!coupon.use_time || coupon.use_time >= todayTime)
        // 사용조건-버전 확인
        && (!coupon.prod_group_nos || coupon.prod_group_nos.includes($scope.item.prod_group_no))
        // 사용조건-결제개월 확인
        && (!coupon.pay_month || coupon.pay_month === +$scope.item.sale_cnt)
        // 사용조건-첫결제여부 확인
        && (!coupon.pay_sol_type || ((coupon.pay_sol_type == '무료') == $scope.payData.firstOrdYN))
        // 서비스 제한버전
        && (!(paySVC[slimCouponDate ? 'freeVersion' : 'limitedVersion'].includes($scope.payData.version) && ['결제할인', '정기결제할인'].includes(coupon.use_type)))
      );

      $scope.coupon_list1 = [];      // 결제할인 쿠폰 리스트
      $scope.coupon_list2 = [];      // 가입비 쿠폰 리스트
      $scope.dupl_coupon_list = [];  // 중복 쿠폰 리스트

      $scope.coupon_list.forEach(coupon => {
        if (!coupon.dupl_dc_yn && isAvailCoupon(coupon) && (!$scope.setAutoPay || coupon.use_type == '정기결제할인')) {
          if (coupon.ben_type !== '가입비') {
            $scope.coupon_list1.push(coupon);
          } else {
            $scope.coupon_list2.push(coupon);
          }
        } else if (!!coupon.dupl_dc_yn && isAvailCoupon(coupon) && (!$scope.setAutoPay || coupon.use_type == '정기결제할인')) {
          $scope.dupl_coupon_list.push(coupon);
        }
      });
    }

    /**
     * TABLE ROW 삭제
     */
    $scope.removeRow = function (idx) {
      $scope.payData.totalPrice = parseInt($scope.payData.totalPrice) - parseInt($scope.payGrid[idx].price_pre);

      // 솔루션 결제건 삭제시 가입비가 있는경우도 같이 삭제되어야 함.
      if ($scope.payGrid[idx].division == 'PLAYAUTO 2.0') {
        const idxxx = _.findIndex($scope.payGrid, function(o) {
          return o.division == '가입비';
        });

        if (idxxx > 0) {
          $scope.payData.totalPrice = parseInt($scope.payData.totalPrice) - parseInt($scope.payGrid[idxxx].price_pre);
          $scope.payGrid.splice(idxxx, 1);
          $scope.payData.coupon.cpn_cd2 = '';
          $scope.payData.coupon.cpn_amt2 = '';
        }

        $scope.payData.coupon.cpn_cd1 = '';
        $scope.payData.coupon.cpn_amt1 = '';
        $scope.payData.coupon.cpn_cd3 = '';
        $scope.payData.coupon.cpn_amt3 = '';
        $scope.payData.dcPrice = 0;
        $scope.payData.coupon.cpn_contents = [];
        $scope.payData.version = $scope.payData.oldVersion;
      }

      $scope.payGrid.splice(idx, 1);

      // 솔루션 결제건이 없는 경우 정기결제 신청 체크박스 비활성화
      updateUseAutoPayYn();

      $scope.calculateDcPrice();
    };

    updateUseAutoPayYn();
    function updateUseAutoPayYn() {
      const versions = $scope.payGrid.find(item => item.division === 'PLAYAUTO 2.0');

      // 버전결제가 없거나, 제한버전으로 결제시 비활성화
      $scope.disabledAutoPay = !versions || $scope.onlyAutoPayVersion($scope.payData.version);

      // 버전결제가 없을때만 자동결제 해지
      if ($scope.setAutoPay && $scope.disabledAutoPay && !versions) {
        $scope.setAutoPay = false;
      }
    }

    /**
     * 결제 GOGO
     */
    $scope.ok = async() => {
      // 정기결제 신청 체크박스 초기화
      $scope.payData.autoPay = !!$scope.setAutoPay;

      $scope.payData.setAutoDatas = $scope.setAutoDatas;
      if ($scope.payGrid.length < 1) {
        commonSVC.showMessage(gettextCatalog.getString('결제할 내역을 선택해 주십시오.'));

        return false;
      } else if ($scope.payData.PayMethod === '') {
        commonSVC.showMessage(gettextCatalog.getString('결제수단을 선택해 주십시오.'));

        return false;
      } else if ($scope.payData.PayMethod === 'BANK' && $scope.payData.deposit_name === '') {
        commonSVC.showMessage(gettextCatalog.getString('무통장입금인경우 입금자명은 필수입니다.'));

        return false;
      } else if ($scope.payData.PayMethod === 'AUTOCARD' && !$scope.setAutoDatas.card_no) {
        commonSVC.showMessage('등록된 카드가 없습니다.', '• 카드정보를 등록해 주세요.', function () {
          $scope.autoPayInfo('card_add');
        });

        return false;
      }

      const versions = $scope.payGrid.find(item => item.division === 'PLAYAUTO 2.0');

      // 첫결제 업그레이드인경우 컨펌창 띄움.
      if ($scope.freeUser && versions) {
        const confirmCheck = await commonSVC.showConfirm(
          '',
          '결제 시 무료 사용 기간은 종료되며, 결제완료 시점부터 유료 버전이 즉시 적용됩니다.\n계속 진행하시겠습니까?'
        );

        if (!confirmCheck) {
          return false;
        }
      }

      // 정기결제 시 장기할인, 정기할인만 적용한 금액으로 설정
      if ($scope.payData.autoPay) {
        $scope.payData.autoPayPrice = $scope.payData.totalPrice - $scope.payData.autoPayDcPrice;
        $scope.payData.GoodsName = `[정기결제] PLAYAUTO 2.0 ${$scope.payGrid[0].contentDetail}`;
      }

      // 만료일 유지 다운그레이드인경우 결제금액이 0원이기 때문에 결제수단 선택 창을 띄우지 않고 confirm 창으로 최종 확인을 받은 후 바로 적용.
      if ($scope.payGrid.length === 1 && $scope.payGrid[0].type === 'downgrade' && $scope.payGrid[0].price === 0) {
        $scope.payData.GoodsName = $scope.payData.GoodsName || $scope.payGrid[0].contentDetail;
        $scope.payData.PayMethod = '';

        // 값을 배열로 넘기면 데이터가 안넘어가므로 오브젝트로 변경해주어야 함. ㅠ.,ㅜ
        const params = {
          setAutoDatas: $scope.setAutoDatas,
          payData: $scope.payData,
          payGrid: [{
            type: $scope.payGrid[0].type,
            content: $scope.payGrid[0].content,
            contentDetail: $scope.payGrid[0].contentDetail,
            division: $scope.payGrid[0].division,
            price: Math.round($scope.payGrid[0].price),
            price_pre: Math.round($scope.payGrid[0].price_pre),
            prod_no: $scope.payGrid[0].prod_no,
            sale_cnt: $scope.payGrid[0].sale_cnt
          }]
        };
        payModel.completeProcess(params, function (state) {
          if (state === 'success') {
            commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
              localStorageService.set('token', `${'Token' + ' '}${result}`);
            });

            commonSVC.showMessageHtml(`${$scope.payGrid[0].contentDetail} 결제에 성공하였습니다.`, '기존 버전에서 사용한 카운트가 변경하는 버전의 카운트 제한을 초과하는 경우 부가서비스에서 카운트 구매 후 이용할 수 있습니다.<br />※ 경로: 결제 > 이용료 결제 > 부가서비스 > 상품 또는 주문의 카운트 구매', function() {
              $uibModalInstance.close('success');
            });
            // GA4 dataLayer에 결제 정보 전달 스크립트 추가
            const items = $scope.payGrid.map(item => ({ item_id: String(item.prod_no), item_name: item.content, price: String(Math.floor(item.price * (10 / 11))), quantity: String(item.sale_cnt) }));
            dataLayer.push({
              event: 'purchase',
              user_id: String($scope.payData.m_no),
              ecommerce: {
                transaction_id: String($scope.payData.pay_no),
                currency: 'KRW',
                value: String($scope.payData.totalPrice),
                items
              }
            });
          } else {
            commonSVC.showMessage(`${$scope.payGrid[0].contentDetail} 결제에 실패하였습니다`);
          }
        });
      } else {
        // 결제수단 선택 모달 열어줌.
        const sendParams = {
          payData: $scope.payData,
          payGrid: $scope.payGrid,
          coupon_list: $scope.coupon_list
        };

        const modal = commonSVC.openModal('md', { data: sendParams }, 'PayMethodCtrl', 'views/pay/modals/pay_method.html', false, true, true, 'pay_request');

        modal.result.then(function (re) {
          if (re === 'success') {
            $uibModalInstance.close('success');
            // GA4 dataLayer에 결제 정보 전달 스크립트 추가
            const items = $scope.payGrid.map(item => ({ item_id: String(item.prod_no), item_name: item.content, price: String(Math.floor(item.price * (10 / 11))), quantity: String(item.sale_cnt) }));
            dataLayer.push({
              event: 'purchase',
              user_id: String($scope.payData.m_no),
              ...(paymentSetupInfo.label && { label: paymentSetupInfo.label }),
              autoPay: $scope.payData.autoPay,
              ecommerce: {
                transaction_id: String($scope.payData.pay_no),
                currency: 'KRW',
                value: String($scope.payData.totalPrice),
                items
              }
            });
          }
        });
      }
    };

    /**
     * 가격계산
     */
    $scope.calculatePrice = function (type) {
      if (type === 'atalk') {
        $scope.payData.atalkPrice = $scope.payData.atalkCnt * 12;
        $scope.payData.atalkPrice = $scope.payData.atalkPrice + ($scope.payData.atalkPrice * 0.1);
      } else if (type === 'sms') {
        $scope.payData.smsPrice = $scope.payData.smsCnt * 25;
        $scope.payData.smsPrice = $scope.payData.smsPrice + ($scope.payData.smsPrice * 0.1);
      } else if (type === 'advanceCnt') {
        // 해당 버전의 선불 카운트 금액 확인.
        const cntPrice = _.filter(solutionProductInfo, function(o) {
          const compareTitle = $scope.payData.advanceCnt.type === 'PROD' ? '상품전송수(월)_선불' : '주문수집수(월)_선불';

          return o.prod_name === compareTitle && o.prod_opt === $scope.payData.oldVersion;
        });
        // 신성통상(7278)만 추가구매 카운트 가격 6원으로 설정
        if ($scope.solutionInfo.sol_no === 7278) {
          $scope.payData.advanceCnt.price = Math.round($scope.payData.advanceCnt.cnt * 6 * 1.1);
        } else {
          $scope.payData.advanceCnt.price = Math.round($scope.payData.advanceCnt.cnt * cntPrice[0].price * 1.1);
        }
      } else if (type === 'email') {
        $scope.payData.emailPrice = $scope.payData.emailCnt * 10;
        $scope.payData.emailPrice = $scope.payData.emailPrice + ($scope.payData.emailPrice * 0.1);
      } else if (type === 'trnsl') {
        $scope.payData.trnslPrice = $scope.payData.trnslCnt * 0.05;
        $scope.payData.trnslPrice = $scope.payData.trnslPrice + ($scope.payData.trnslPrice * 0.1);
      }
    };

    /**
     * 할인가격계산
     */
    $scope.autoPayExtendTerm = 1;
    $scope.calculateDcPrice = async function () {
      $scope.payData.autoPayDcPrice = 0; // 정기결제 전용 할인가(쿠폰할인 제외)
      $scope.payData.dcPrice = 0;
      $scope.payData.finalPrice = $scope.payData.totalPrice;
      $scope.payData.autoPayPrice = solPrice;

      let dc_info;
      let dcPrice = 0;
      const contents = [];
      const cpn_ben = [];
      const joinPirceReset = false;
      let payItem = $scope.payGrid.find(function(o) {
        return o.division == 'PLAYAUTO 2.0';
      });

      // 이용료 금액 원복
      if (payItem && payItem.price != payItem.price_pre) {
        payItem.price = payItem.price_pre;
      }
      let extendTerm = 1;

      // 결제대상에 버전이 포함되어있는경우에만 연장개월 처리되도록 함.
      if (payItem) {
        extendTerm = parseInt($scope.payData.extendTerm);
        $scope.autoPayExtendTerm = extendTerm || 1;
      }

      let prevDcPrice = 0;

      if (payItem && slimPromotion && $scope.payData.version == 'SLIM') {
        contents.push(`SLIM 프로모션 (-${slimPromotion}원 할인)`);
      }

      // 장기결제 할인
      if (longTermPayDscntRate[extendTerm] && !indv_pay_amt) {
        applyDiscount(longTermPayDscntRate[extendTerm], false, prevDcPrice);
        prevDcPrice = $scope.payData.dcPrice;
      }

      // 정기결제 할인
      if ($scope.setAutoPay && !indv_pay_amt) {
        applyDiscount(0.03, true, prevDcPrice);
      }

      // 일반할인 / 중복할인 / 쿠폰할인 (장기할인 > 정기할인 > 쿠폰할인 순으로 적용)
      if ($scope.payData.coupon.cpn_cd1 || $scope.payData.coupon.cpn_cd3) {

        const dc_list = $scope.coupon_list.filter(
          o => o.cpn_no == $scope.payData.coupon.cpn_cd1 || o.cpn_no == $scope.payData.coupon.cpn_cd3
        );

        if (dc_list.some(o => o.use_type == '정기결제할인' && !$scope.setAutoPay)) {
          const confirm = await commonSVC.showConfirmCustom({
            title: '정기결제 시 사용 가능한 쿠폰입니다.\n정기결제를 신청 하시겠습니까?',
            text: '•[확인] 클릭 시 정기결제 신청에 체크되며, 카드 정보를 등록해야 합니다.\n•선택한 조건으로 솔루션 정기결제를 신청합니다. (부가서비스 제외)\n•현재 사용 중인 정기결제 쿠폰에 할인 횟수가 남아있을 경우 신규 결제 시 소멸되며 복구할 수 없습니다.',
          });

          if (confirm) {
            $scope.setAutoPay = true; // 정기결제 신청 체크
            const re = await $scope.chkAutoPayInfo();
            if (!re) {
              deSelectCouponList(); // 선택되어 있는 정기결제 쿠폰 해제
            }
          } else {
            deSelectCouponList(); // 선택되어 있는 정기결제 쿠폰 해제
          }

          return;
        }

        const cpnDcPrice = applyCouponDiscount(dc_list);

        $scope.payData.finalPrice = Math.max(0, $scope.payData.finalPrice - cpnDcPrice);

        // 할인금액 담는 처리 추가
        if (payItem) {
          payItem.price = payItem.price_pre - $scope.payData.dcPrice;
        }
      }

      // 가입비 면제
      if ($scope.payData.coupon.cpn_cd2) {
        dc_info = $scope.coupon_list2.find(function(o) {
          return o.cpn_no == $scope.payData.coupon.cpn_cd2;
        });

        dcPrice = $scope.payGrid.find(function(o) {
          return o.type == '가입비';
        }).price_pre;
        $scope.payData.dcPrice += dcPrice;

        $scope.payData.coupon.cpn_amt2 = dcPrice;

        // 총결제금액에 가입비가 제외되어있지않은경우에만 가입비 뺴줌.
        if (!joinPirceReset) {
          $scope.payData.finalPrice = $scope.payData.finalPrice < dcPrice ? 0 : $scope.payData.finalPrice - dcPrice;
        }

        contents.push(`${dc_info.dc_name} - 가입비 무료`);

        // 할인금액 담는 처리 추가
        if ($scope.payGrid) {
          payItem = $scope.payGrid.find(function(o) {
            return o.division == '가입비';
          });
          if (payItem) {
            payItem.price = payItem.price_pre - dcPrice;
          }
        }
      } else {
        // 할인금액 원복
        if ($scope.payGrid) {
          payItem = $scope.payGrid.find(function(o) {
            return o.division == '가입비';
          });
          if (payItem) {
            payItem.price = payItem.price_pre;

            // 최종결제금액에 가입비 더해주어야 함.
            if (joinPirceReset) {
              $scope.payData.finalPrice = $scope.payData.finalPrice + payItem.price_pre;
            }
          }
        }
      }

      $scope.payData.coupon.cpn_contents = contents;
      $scope.payData.coupon.cpn_ben = cpn_ben;

      /**
       * 함수 목록
       */
      function applyDiscount(discountRate, isAutoPay, prevDcPrice) {
        solPrice = $scope.payGrid.find(o => o.division === 'PLAYAUTO 2.0').price_pre;
        const dcPrice = calculateDiscount(solPrice - prevDcPrice, discountRate, isAutoPay);

        $scope.payData.dcPrice += Math.round(dcPrice * 1.1);
        $scope.payData.autoPayDcPrice += Math.round(dcPrice * 1.1);
        $scope.payData.finalPrice = ($scope.payData.finalPrice / 11 * 10) < dcPrice ? 0 : Math.round(($scope.payData.finalPrice - Math.round(dcPrice * 1.1)));

        if (payItem) {
          payItem.price = payItem.price_pre - $scope.payData.dcPrice;
          const addDcMsg = ` (-${dcPrice}원 할인)`;
          if (isAutoPay) {
            dcPrice && contents.push(`정기결제 3%${addDcMsg}`);
          } else {
            if (payItem.sale_cnt === '3') {
              contents.push(`${payItem.sale_cnt} 개월 장기결제 5%${addDcMsg}`);
            } else if (payItem.sale_cnt === '6') {
              contents.push(`${payItem.sale_cnt} 개월 장기결제 10%${addDcMsg}`);
            } else if (payItem.sale_cnt === '12') {
              contents.push(`${payItem.sale_cnt} 개월 장기결제 20%${addDcMsg}`);
            } else if (payItem.sale_cnt === '24') {
              contents.push(`${payItem.sale_cnt} 개월 장기결제 30%${addDcMsg}`);
            }
          }
        }
      }

      function applyCouponDiscount(dc_list) {
        let cpnDcPrice = 0; // 쿠폰 총 할인금액 (부가세 포함)

        dc_list.forEach(dc_info => {
          if (dc_info.ben_type == '이용료') {
            let addCon = '';
            let dcPrice = 0;

            switch (dc_info.ben_sub_type) {
              case '정액':
                dcPrice = parseInt(dc_info.dc_qty);
                break;
              case '정률':
                addCon = ` ${dc_info.dc_qty}%`;
                dcPrice = calculateDiscount(
                  $scope.payGrid.find(o => o.division === 'PLAYAUTO 2.0').price,
                  dc_info.dc_qty * 0.01,
                  false
                );
                break;
            }
            contents.push(`${dc_info.dc_name + addCon} (-${dcPrice}원 할인)`);

            cpnDcPrice += parseInt(dcPrice * 1.1);
            $scope.payData.dcPrice += parseInt(dcPrice * 1.1);

            // 각 쿠폰의 할인 금액 저장
            if (dc_info.cpn_no == $scope.payData.coupon.cpn_cd1) {
              $scope.payData.coupon.cpn_amt1 = parseInt(dcPrice * 1.1);
            } else if (dc_info.cpn_no == $scope.payData.coupon.cpn_cd3) {
              $scope.payData.coupon.cpn_amt3 = parseInt(dcPrice * 1.1);
            }
          } else if (dc_info.ben_type == '사용기간' && dc_info.ben_sub_type === '월') {
            cpn_ben.push({ dc_qty: dc_info.dc_qty });
            contents.push(`${dc_info.dc_name} (사용기간 ${dc_info.dc_qty}개월 연장)`);
          }
        });

        return cpnDcPrice;
      }
    };

    function calculateDiscount(solPrice, discountRate, isAutoPay) {
      let dcPrice = (solPrice / 11 * 10) * discountRate;

      if (isAutoPay) {
        dcPrice = (solPrice / 11 * 10) * 0.03;
      }

      return Math.round(dcPrice);
    }

    function deSelectCouponList() {
      // 정기결제 체크박스 해제 시 선택되어있는 정기결제 쿠폰 해제 처리
      for (let i = 0; i < $scope.coupon_list.length; i++) {
        const coupon = $scope.coupon_list[i];

        if (
          coupon.use_type === '정기결제할인' &&
          (coupon.cpn_no === +$scope.payData.coupon.cpn_cd1 || coupon.cpn_no === +$scope.payData.coupon.cpn_cd3)
        ) {
          if (coupon.cpn_no === +$scope.payData.coupon.cpn_cd1) {
            $scope.payData.coupon.cpn_cd1 = '';
          }
          if (coupon.cpn_no === +$scope.payData.coupon.cpn_cd3) {
            $scope.payData.coupon.cpn_cd3 = '';
          }
        }
      }
    }

    $scope.viewPriceTable = function() {
      let viewStr = '<center>';

      viewStr += '<table class="table table-striped table-bordered" style="text-align:center;">';
      viewStr += '<tr><td>솔루션버전</td><td>상품</td><td>주문</td></tr>';
      viewStr += '<tr><td>BASIC</td><td>50원</td><td>50원</td></tr>';
      viewStr += '<tr><td>CLASSIC</td><td>40원</td><td>40원</td></tr>';
      viewStr += '<tr><td>SPECIAL</td><td>20원</td><td>20원</td></tr>';
      viewStr += '<tr><td>PLATINUM</td><td>20원</td><td>20원</td></tr>';
      viewStr += '<tr><td>VIP</td><td>20원</td><td>20원</td></tr>';
      viewStr += '</table></center>';

      commonSVC.showMessageHtml('버전 별 추가 카운트 1건 당 이용 금액', viewStr);
    };

    function toggleLoading (shouldShow) {
      const spinner = document.getElementById('spinner');
      if (shouldShow && spinner.classList.contains('hidden')) {
        spinner.classList.remove('hidden');
      } else if (!shouldShow && !spinner.classList.contains('hidden')) {
        spinner.classList.add('hidden');
      }
    }

    /**
     * ZERO 버전 사용하기
     */
    $scope.goToZero = async() => {
      await $rootScope.goToZeroVersion(toggleLoading);
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 정기결제 유도 모달 오픈
     */
    $scope.openModal = async () => {
      // GA4 dataLayer에 정기결제 유도 정보 전달 스크립트 추가
      dataLayer.push({
        event: 'Button Click',
        category: 'Button Click',
        label: '1',
        action: 'click_autopay_info_button'
      });

      return commonSVC.openModal('w560', { data: {} }, 'PayAutoInduceMentCtrl', 'views/pay/modals/regular_payment_a.html');
    };

    $timeout(async function() {
      if (paymentSetupInfo.paySetting) {
        $scope.addRow('version', userInfo.user.sol_version);
        $scope.changePayMethod('AUTOCARD');

        // onlyAutoPayVersion 사용자는 addRow 안에서 chkAutoPayInfo() 실행됨
        if (!$scope.onlyAutoPayVersion(userInfo.user.sol_version) && paymentSetupInfo.setAutoPay) {
          $scope.setAutoPay = true;
          await $scope.chkAutoPayInfo();
        }
      }
    }, 1000);
  });