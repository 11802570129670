/**
 * 로그기록 factory
 *
 * 2016-05-12 : 김민영
 *
 */
'use strict';

angular.module('gmpApp')
  .factory('logHistorySVC', function (commonSVC, settings, userInfo, $rootScope, columnSVC) {
    return {
      /**
       * compObj : 비교 할 key(컬럼명) : value(contents에 들어갈 내용)
       * oldData : 수정 전 Data 값
       * data : 수정 된 Data 값
       * logLocation : 스토리지서비스 key값명
       */
      compare: function (compObj, oldData, data) {
        const contents = [];
        let log = '';

        for (const key in compObj) {
          const od = oldData[key];
          const d = data[key];
          // var co = compObj[key];

          if (((!_.isUndefined(od) && !_.isNull(od)) || (!_.isUndefined(d) && !_.isNull(d))) && od != d) {
            contents.push(compObj[key]);
          }
        }

        if (contents.length) {
          log = _.uniq(contents).join(',');
        }

        return log;
      },

      /**
       * compObj : 비교 할 key(컬럼명) : value(contents에 들어갈 내용)
       * oldData : 수정 전 Data 값
       * data : 수정 된 Data 값
       * logLocation : 스토리지서비스 key값명
       */
      compareReturnObj: function (compObj, oldData, data) {
        const log = {};

        for (const key in compObj) {
          const od = oldData[key];
          const d = data[key];

          if (((!_.isUndefined(od) && !_.isNull(od)) || (!_.isUndefined(d) && !_.isNull(d))) && od != d) {
            log[key] = compObj[key];
          }
        }

        if (data.additional_images.length) {
          data.additional_images.forEach((addImg) => {
            if (addImg.files !== addImg.old_files) {
              log[addImg.en_name] = `${addImg.name}:변경내용 생략`;
            }
          });
        }

        return log;
      },

      compareValue: function (compObj, oldData, data) {
        const contents = [];
        let log = '';

        for (const key in compObj) {
          // SMS, 이메일 수신 여부 문자 치환 (opt_in_sms, opt_in_mail)
          const od = key.includes('opt_in_') ? oldData[key] ? '동의' : '동의안함' : oldData[key];
          const d = key.includes('opt_in_') ? data[key] ? '동의' : '동의안함' : data[key];

          if (((!_.isUndefined(od) && !_.isNull(od)) || (!_.isUndefined(d) && !_.isNull(d))) && od != d) {
            contents.push(`${compObj[key]}: (${od} -> ${d})`);
          }
        }

        if (contents.length) {
          log = _.uniq(contents);
        }

        return log;
      },

      compareReturnValue: function (compObj, oldData, data) {
        const contents = [];
        let isMatchUpdated = false;
        let isAddOptMatchUpdated = false;
        let log = '';

        for (const key in compObj) {
          let tempLog = '';

          if ((key != 'opt_sale_cnt') && oldData[key] != data[key]) {

            // 기준주문 새로 매칭시 배송처 및 건별출고수량 로그에 기록x
            if (key == 'sku_pack') {
              isMatchUpdated = true;
              if (!data[key]) {
                data[key] = '삭제';
              }
            }
            if ((key == 'depot_no' || key == 'depot_name' || key == 'pack_unit') && isMatchUpdated) {
              continue;
            }

            // 추가구매옵션 새로 매칭시 추가구매옵션 명칭 로그에 기록x
            if (key == 'prod_add_opt_sku_code') {
              isAddOptMatchUpdated = true;
              if (!data[key]) {
                data[key] = '삭제';
              }
            }
            if (key == 'shop_add_opt_name' && isAddOptMatchUpdated) {
              continue;
            }

            // 기준주문 건별출고수량 로그기록위해 추가 2018-06-23 Amelia
            if (key == 'pack_unit') {
              tempLog = `(${compObj.sku_pack}(${data.sku_pack}) ${compObj[key]} : `;
              tempLog += oldData[key] ? `${oldData[key]}->${data[key]})` : `${data[key]})`;
              contents.push(tempLog);
            } else {
              // 숫자가 0에서 다른 수로 바뀌었을때 olData[key]가 false이기 때문에 String으로 바꿔줌
              tempLog += String(oldData[key]) ? `${oldData[key]}->${data[key]}` : data[key];
              contents.push(`(${compObj[key]} : ${tempLog})`);
            }
          }
        }

        // 추가구매옵션 주문수량/건별출고수량 로그기록위해 추가 2018-06-23 Amelia
        const keyList = ['pack_unit', 'opt_sale_cnt'];

        _.each(data.prod_add_opt_list, function(prod) {
          let log = '';

          _.each(oldData.prod_add_opt_list, function(oldProd) {
            if (prod.sku_cd == oldProd.sku_cd && prod.ord_opt_seq == oldProd.ord_opt_seq) {
              _.each(keyList, function(key) {
                if (prod[key] != oldProd[key]) {
                  log = '(';
                  // sku_cd 로그는 재고관리 사용일때만.
                  if ($rootScope.user_profile.sol_stock > 0) {
                    log += `${compObj.prod_add_opt_sku_code}(${prod.sku_cd}) `;
                  }
                  // 관련 값이 null 로 출력되는경우가 있어 치환
                  log += `${compObj[key]} : ${oldProd[key] || 0}->${prod[key] || 0})`;
                  contents.push(log);
                }
              });
            }
          });
        });

        columnSVC.addColList.ord.forEach((row) => {

          if (oldData.addColData && oldData.addColData[row.col_type]) {
            oldData.addColData[row.col_type].forEach((col, idx) => {
              if ((col.col_val || data.addColData[row.col_type][idx].col_val) && col.col_val !== data.addColData[row.col_type][idx].col_val) {
                contents.push(`(${columnSVC.setColumnName(col.col_name, 'GET')} : ${col.col_val || ''}->${data.addColData[row.col_type][idx].col_val || ''})`);
              }
            });
          }
        });

        if (contents.length) {
          log = _.uniq(contents);
        }

        return log;
      },

      asLogReturnVal: function(compObj, oldData, data) {
        const contents = [];

        for (const key in compObj) {
          let log = '';

          if ((oldData[key] != data[key]) && (oldData[key] || data[key])) {
            if (key == 'depot_no' || key == 'depot_name') {
              continue;
            }

            // 숫자가 0에서 다른 수로 바뀌었을때 olData[key]가 false이기 때문에 String으로 바꿔줌
            log += oldData[key] && String(oldData[key]) ? `${oldData[key]}->${data[key]}` : data[key];
            contents.push(`(${compObj[key]} : ${log})`);
          }
        }

        return contents.length ? _.uniq(contents) : '';
      },

      /**
       * 변경사항 비교 및 로그
       */
      getDifferentLogs: function(logField, oldData, newData, mainFrefix = '') {
        const logs = [];

        for (const [
          key, {
            difference = (oldData, newData, key) => {
              return oldData[key] != newData[key];
            },
            differenceWith = null,
            prefix = null,
            suffix = null,
            suffixRenderer = null,
            format = value => value || ''
          }
        ] of Object.entries(logField)) {
          /** Array 추가/삭제 데이터로 비교시 */
          if (differenceWith) {
            const { add = [], del = [] } = differenceWith(oldData, newData, key);

            if (add.length || del.length) {
              const addStr = add.map(o => format(o)).join();
              const delStr = del.map(o => format(o)).join();

              logs.push(`${mainFrefix}${prefix} : ${add.length ? `추가(${addStr})` : ''}${add.length && del.length ? ', ' : ''}${del.length ? `삭제(${delStr})` : ''}`);
            }
            /** 데이터가 변경됐을시 */
          } else if (difference(oldData, newData, key)) {
            const oldSuffixStr = suffixRenderer ? suffixRenderer(oldData) : suffix;
            const newSuffixStr = suffixRenderer ? suffixRenderer(newData) : suffix;

            logs.push(`${mainFrefix}${prefix} : ${format(oldData[key])}${oldSuffixStr || ''} -> ${format(newData[key])}${newSuffixStr || ''}`);
          }
        }

        return logs;
      },

      getLog: function (location, data, next) {
        // 업체가 호출한지 GMP에서 호출한지 구분하기 위해 처리 2018-05-24 Gargamel
        data.usrapi = false;

        // API 전송
        if (next) {
          commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/log`, data, function(result, data) {
            angular.forEach(data.rows, function(v, k) {
              data.rows[k].wdate = commonSVC.getDate(v.wdate, 'yyyy-MM-dd HH:mm:ss');
            });
            next(result, data.rows);
          });
        } else {
          return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/log`, data);
        }
      }
    };
  });
