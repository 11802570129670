/**
 *  상품관리 서비스
 *  2017-05-23 ally
 */

'use strict';

angular.module('gmpApp')
  .service('productSVC', function(commonSVC, productModel, gettext, gettextCatalog, userInfo, $rootScope) {
    return {
      /**
       * SKU상품 데이터
       */
      product: {
        skuAuto: true,

        category: '',
        sku_code: '',
        sku_cd: '', // 상세에서 사용..
        stock_cd: '',
        prod_name: '',
        madein_type: '기타',
        madein_etc: '',
        localGroup: '',
        globalGroup: '',
        localDetail: '',
        globalDetail: '',
        etcMadein: '',
        madein_group: '',
        madein_name: '',
        multi_madein_yn: false,
        tax_type: '일반과세',
        brand: '',
        model: '',
        maker: '',
        maker_eng: '',
        made_date: '',
        expiration_month: '52',
        expire_date: '',

        bundle_avail_yn: 1,
        width: 0,
        height: 0,
        depth: 0,
        weight: 0,
        in_unit: 'EA',
        in_unit_cnt: '',
        product_price: 0,
        cost_price: 0,
        supply_price: 0,
        purchase_cost: 0,
        in_ship_cost: 0,
        ship_cost: 0,
        adv_cost: 0,
        other_cost: 0,

        real_stock: 0,
        safe_stock: 0,

        barcode_type: '',
        global_barcode: '',
        isbn: '',
        barcode: '',
        hscd: '',
        supplier_vendor: '',
        delivery_vendor: '',
        use_age: '',
        prod_img: false,
        contents: '',
        prod_info: {}
      },
      /**
       * 유통기한 개월수
       */
      expirationMonth: 51,
      taxTypes: ['일반과세', '간이과세', '면세', '영세', '비과세'],
      /**
       * 포장단위 항목 리스트
       */
      packUnits: [
        'PALLET(PLT)', 'CARTON(C/T)', 'CASE(C/S)', 'BOX(BOX)', 'DRUM(D/M)',
        'COIL', 'CAN', 'BAG(B/G)', 'BUNDLE(BDL)', 'ROLL(R/L)', 'BALE'
      ],
      /**
       * 판매채널 항목리스트
       */
      channels: ['미지정', '온라인', '오프라인', '온/오프라인'],
      /**
       * 글로벌 바코드 구분리스트(UPC/EAN/ISBN)
       */
      barcodeTypes: ['UPC', 'EAN'],
      /**
       * 사용연령 항목리스트
       */
      useAges: ['전체', '0~3세', '4~6세', '7~12세', '13~15세', '16~19세', '20세이상'],
      /**
       * 성별구분 항목리스트
       */
      sexualTypes: ['남녀공용', '남성용', '여성용'],
      /**
       * 속성 항목리스트
       */
      optTypes: ['직접입력', '색상', '사이즈'],

      /**
       * 상세검색 부분
       */
      searchForm: {
        //sdate: commonSVC.getDate(userInfo.user.user_wdate,'yyyy-MM-dd'), // 상품쪽은 user가 가입한 날짜가 처음 default
        //2017-09-07 roy IE, safari 호환을 위해 변경
        sdate: moment(userInfo.user.sol_sdate).format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        date_type: 'wdate',                                             //검색기간 구분
        search_word: '',                                                //검색어
        search_key: 'all',                                              //검색구분
        search_type: 'partial',                                             //부분일치, 완전일치
        category: 'all',                                                //카테고리
        supplier_vendor: 'all',                                         //매입처
        delivery_vendor: '',                                         //배송처
        linkage_flag: 'all',                                            //연동상품여부
        tax_type: 'all',                                                 //과세여부
        recently_prod: 'all',                                             //신규상품(최근 일주일)
        safe_stock_alert: false,                                         //재고부족
        real_stock_none: false,                                           //판매불가
        group_prod: false,                                                //그룹상품
        multi_search_word: '', //멀티서치워드,
        multi_type: 'sku_cd', //멀티서치 타입
        multi_deliver_vendor: '',
        multi_supplier_vendor: ''
      },

      searchData: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('브랜드'), value: 'brand' },
          { label: gettextCatalog.getString('속성명'), value: 'attri' },
          { label: gettextCatalog.getString('바코드'), value: 'barcode' },
          { label: gettextCatalog.getString('UPC,EAN코드'), value: 'global_barcode' }, // barcode_type + global_barcode
          { label: gettextCatalog.getString('HS코드'), value: 'hscd' },
          { label: gettextCatalog.getString('ISBN코드'), value: 'isbn' },
          { label: gettextCatalog.getString('모델명'), value: 'model' },
          { label: gettextCatalog.getString('스타일상품'), value: 'style_cd' },
          { label: gettextCatalog.getString('메모'), value: 'memo' }
        ],

        search_date_type: [
          { label: gettextCatalog.getString('등록일'), value: 'wdate' },
          { label: gettextCatalog.getString('수정일'), value: 'mdate' },
          { label: gettextCatalog.getString('상태변경일'), value: 'stock_status_mdate' }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          { label: gettextCatalog.getString('배송처'), value: 'depot' },
          { label: gettextCatalog.getString('매입처'), value: 'supp' }
        ]
      },

      searchData_set: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수

        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
          { label: gettextCatalog.getString('세트상품명'), value: 'set_name' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('메모'), value: 'memo' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('생성일'), value: 'wdate' },
          { label: gettextCatalog.getString('수정일'), value: 'mdate' }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' }
        ]
      },

      searchFormSub: {
        //sdate: commonSVC.getDate(userInfo.user.user_wdate,'yyyy-MM-dd'), // 상품쪽은 user가 가입한 날짜가 처음 default
        //2017-09-07 roy IE, safari 호환을 위해 변경
        sdate: moment(userInfo.user.sol_sdate).format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        date_type: 'wdate',                                             //검색기간 구분
        search_word: '',                                                //검색어
        search_key: 'all',                                              //검색구분
        search_type: 'partial',                                         //부분일치, 완전일치
        multi_search_word: '', //멀티서치워드,
        multi_type: $rootScope.affName === 'LG전자' ? 'model_no' : 'sku_cd', //멀티서치 타입
        search_sol_no: '',
        stock_status: '',
        linkage_flag: '',
      },

      searchDataSub: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('상품명'), value: 'prod_name' },
          ...($rootScope.affName === 'LG전자' && [{ label: gettextCatalog.getString('모델번호'), value: 'model_no' }]) || [],
          { label: gettextCatalog.getString('브랜드'), value: 'brand' },
        ],

        search_date_type: [
          { label: gettextCatalog.getString('등록일'), value: 'wdate' },
          { label: gettextCatalog.getString('수정일'), value: 'mdate' },
          ...($rootScope.affName === '에픽카' && [{ label: gettextCatalog.getString('상태변경일'), value: 'stock_status_mdate' }]) || []
        ],
        search_multi_items: [
          ...($rootScope.affName === 'LG전자' && [{ label: gettextCatalog.getString('모델번호'), value: 'model_no' }]) || [],
          ...($rootScope.affName === '에픽카' &&
            [
              { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
              { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
              { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
              { label: gettextCatalog.getString('부품사명'), value: 'sub_sol_no' }
            ]) || [],
        ]
      },

      excelFieldList: [
        { header: '등록일', key: 'wdate', select: true },
        { header: '카테고리', key: 'sol_cate_name', select: true },
        { header: 'SKU코드', key: 'sku_cd', select: true },
        { header: '재고관리코드', key: 'stock_cd', select: true },
        { header: 'SKU상품명', key: 'prod_name', select: true },
        { header: '속성', key: 'attri', select: true },
        { header: '브랜드', key: 'brand', select: true },
        { header: '단가', key: 'sale_price', select: true },
        { header: '실재고', key: 'stock_cnt', select: true },
        { header: '안전재고', key: 'stock_cnt_safe', select: true },
        { header: '연동상품', key: 'link_yn', select: true },
        //{header: '미출고', key: '', select: false},
        { header: '원산지', key: 'madein', select: true },
        { header: '과세여부', key: 'tax_type', select: true },
        { header: '원가', key: 'cost_price', select: true },
        { header: '공급가', key: 'supply_price', select: true },
        { header: '매입처', key: 'supp_name', select: true },
        { header: '배송처', key: 'depot_name', select: true }
      ],

      setExcelFieldList: [
        { header: '등록일', key: 'wdate', select: true },
        { header: '세트코드', key: 'set_cd', select: true },
        { header: '세트 상품명', key: 'set_name', select: true },
        { header: '세트구성 SKU상품', key: 'set_sku_cds', select: true },
        { header: 'SKU상품명', key: 'set_prod_names', select: true },
        { header: '(세트)원가', key: 'set_cost_price', select: true },
        { header: '(세트)공급가', key: 'set_supply_price', select: true },
        { header: '출고수량', key: 'set_pack_units', select: true },
        { header: '대표 SKU상품', key: 'main_sku_cd', select: true },
        { header: '배송처코드', key: 'depot_no', select: true },
        { header: '배송처명', key: 'delivery_vendor_name', select: true },
        { header: '세트 판매가', key: 'sale_price', select: true },
        { header: '판매수량', key: 'sale_cnt_limit', select: true },
        { header: '상태', key: 'set_status', select: true },
        { header: '수정일', key: 'mdate', select: true },
        { header: '대표이미지', key: 'set_img', select: true },
      ],

      excelUploadTemplate: [
        { header: 'SKU코드', key: 'sku_cd', custom: '', use_yn: true, required: true },
        { header: '재고관리코드', key: 'stock_cd', custom: '재고관리코드', use_yn: false, required: false },
        { header: 'SKU상품명', key: 'prod_name', custom: '', use_yn: true, required: true },
        { header: '배송처', key: 'depot_no', custom: '', use_yn: true, required: true },
        { header: '실재고', key: 'inout_cnt', custom: '', use_yn: true, required: true },
        { header: '속성', key: 'opt', custom: '속성', use_yn: false, required: false },
        { header: '합포장 여부', key: 'bundle_avail_yn', custom: '합포장 여부', use_yn: false, required: false },
        { header: '원산지', key: 'madein', custom: '원산지', use_yn: false, required: false },
        { header: '복수원산지', key: 'multi_madein_yn', custom: '복수원산지', use_yn: false, required: false },
        { header: '과세여부', key: 'tax_type', custom: '과세여부', use_yn: false, required: false },
        { header: '매입처', key: 'supp_no', custom: '매입처', use_yn: false, required: false },
        { header: '원가', key: 'cost_price', custom: '원가', use_yn: false, required: false },
        { header: '매입원가', key: 'purchase_cost', custom: '매입원가', use_yn: false, required: false },
        { header: '매입운송비', key: 'in_ship_cost', custom: '매입운송비', use_yn: false, required: false },
        { header: '택배발송비', key: 'ship_cost', custom: '택배발송비', use_yn: false, required: false },
        { header: '광고비', key: 'adv_cost', custom: '광고비', use_yn: false, required: false },
        { header: '기타비', key: 'other_cost', custom: '기타비', use_yn: false, required: false },
        { header: '공급가', key: 'supply_price', custom: '공급가', use_yn: false, required: false },
        { header: '판매가', key: 'sale_price', custom: '판매가', use_yn: false, required: false },
        { header: '브랜드', key: 'brand', custom: '브랜드', use_yn: false, required: false },
        { header: '모델명', key: 'model', custom: '모델명', use_yn: false, required: false },
        { header: '제조사', key: 'maker', custom: '제조사', use_yn: false, required: false },
        { header: '영문제조사', key: 'maker_eng', custom: '영문제조사', use_yn: false, required: false },
        { header: '제조일자', key: 'made_date', custom: '제조일자', use_yn: false, required: false },
        { header: '유효일자', key: 'expire_date', custom: '유효일자', use_yn: false, required: false },
        { header: '바코드', key: 'barcode', custom: '바코드', use_yn: false, required: false },
        { header: 'ISBN코드', key: 'isbn', custom: 'ISBN코드', use_yn: false, required: false },
        { header: 'HS코드', key: 'hscd', custom: 'HS코드', use_yn: false, required: false },
      ],

      skuExcel: [
        { header: 'SKU코드', key: 'sku_cd', select: true, variable: '#SKU코드', key_settings: '0', width: 15, isDomestic: true, isGlobal: false },
        { header: '재고관리코드', key: 'stock_cd', select: true, variable: '#재고관리코드', key_settings: '0', width: 15, isDomestic: true, isGlobal: false },
        { header: 'SKU상품명', key: 'prod_name', select: true, variable: '#SKU상품명', key_settings: '0', width: 40, isDomestic: true, isGlobal: false },
        { header: '원산지', key: 'madein', select: true, variable: '#원산지', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '복수원산지 여부', key: 'multi_madein_yn', select: true, variable: '#복수원산지 여부', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '과세여부', key: 'tax_type', select: true, variable: '#과세여부', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '판매가', key: 'sale_price', select: true, variable: '#판매가', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '실재고', key: 'inout_cnt', select: true, variable: '#실재고', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '판매가능재고', key: 'stock_cnt', select: true, variable: '#판매가능재고', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '배송처', key: 'depot_no', select: true, variable: '#배송처', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '배송처명', key: 'depot_name', select: false, variable: '#배송처명', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '대표배송처', key: 'main_depot_no', select: true, variable: '#대표배송처', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '대표배송처명', key: 'main_depot_name', select: false, variable: '#대표배송처명', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '속성', key: 'attri', select: true, variable: '#속성', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '브랜드', key: 'brand', select: true, variable: '#브랜드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '모델명', key: 'model', select: true, variable: '#모델', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '제조사', key: 'maker', select: true, variable: '#제조사', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '영문제조사', key: 'maker_eng', select: true, variable: '#영문제조사', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '제조일자', key: 'made_date', select: true, variable: '#제조일자', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '유효일자', key: 'expire_date', select: true, variable: '#유효일자', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '합포장 여부', key: 'bundle_avail_yn', select: true, variable: '#합포장 여부', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '규격', key: 'prod_size', select: true, variable: '#규격', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: !$rootScope.osse_sol ? '입고단위' : '낱본 수량', key: 'in_unit_info', select: true, variable: !$rootScope.osse_sol ? '#입고단위' : '낱본 수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '원가', key: 'cost_price', select: true, variable: '#원가', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '매입원가', key: 'purchase_cost', select: true, variable: '#매입원가', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '매입운송비', key: 'in_ship_cost', select: true, variable: '#매입운송비', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '택배발송비', key: 'ship_cost', select: true, variable: '#택배발송비', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '광고비', key: 'adv_cost', select: true, variable: '#광고비', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '기타비', key: 'other_cost', select: true, variable: '#기타비', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '공급가', key: 'supply_price', select: true, variable: '#공급가', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '안전재고', key: 'stock_cnt_safe', select: true, variable: '#안전재고', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: 'UPC/EAN코드', key: 'global_barcode', select: true, variable: '#UPC/EAN코드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: 'HS코드', key: 'hscd', select: true, variable: '#HS코드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: 'ISBN코드', key: 'isbn', select: true, variable: '#ISBN코드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '바코드', key: 'barcode', select: true, variable: '#바코드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '매입처', key: 'supp_no', select: true, variable: '#매입처', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '매입처명', key: 'supp_name', select: false, variable: '#매입처명', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '사용연령', key: 'use_age', select: true, variable: '#사용연령', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '대표이미지', key: 'prod_img_url', select: true, variable: '#대표이미지', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '상세설명', key: 'contents', select: true, variable: '#상세설명', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '상품분류코드', key: 'prod_info_cd', select: true, variable: '#상품분류코드', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '상품정보제공고시', key: 'prod_info', select: true, variable: '#상품정보제공고시', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '상태', key: 'stock_status', select: false, variable: '#상태', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적입고수량', key: 'in_cnt_accum', select: false, variable: '#누적입고수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적교환수량', key: 'exchange_cnt_accum', select: false, variable: '#누적교환수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적출고수량', key: 'out_cnt_accum', select: false, variable: '#누적출고수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '출고예정수량', key: 'out_scheduled', select: false, variable: '#출고예정수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적구매수량', key: 'sale_cnt_accum', select: false, variable: '#누적구매수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적취소수량', key: 'cancel_cnt_accum', select: false, variable: '#누적취소수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '누적반품수량', key: 'return_cnt_accum', select: false, variable: '#누적반품수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '미출고', key: 'unavail_out_cnt', select: false, variable: '#미출고', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '연동주문', key: 'link_ord_yn', select: false, variable: '#연동주문', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '등록일', key: 'wdate', select: false, variable: '#등록일', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
      ],

      // SKU상품 엑셀 템플릿 사용 안하는 항목
      excelDisableFields: ['규격', '안전재고', 'UPC/EAN코드', '사용연령', '대표이미지', '상세설명', '상품분류코드', '상품정보제공고시 1', '상품정보제공고시 2', '상품정보제공고시 3', '상품정보제공고시 4', '상품정보제공고시 5', '상품정보제공고시 6', '상품정보제공고시 7', '상품정보제공고시 8', '상품정보제공고시 9', '상품정보제공고시 10', '상품정보제공고시 11', '상품정보제공고시 12', '상품정보제공고시 13', '상품정보제공고시 14', '상품정보제공고시 15', '상품정보제공고시 16', '상품정보제공고시 17', '상품정보제공고시 18', '상품정보제공고시 19', '상품정보제공고시 20', '상품정보제공고시 21', '상품정보제공고시 22'],

      // 엑셀 업로드 2.0 기본 양식
      basicFieidInfo: [
        { header: 'SKU코드', key: 'sku_cd', custom: 'SKU코드', use_yn: true, required: true },
        { header: '재고관리코드', key: 'stock_cd', custom: '재고관리코드', use_yn: true, required: false },
        { header: 'SKU상품명', key: 'prod_name', custom: 'SKU상품명', use_yn: true, required: true },
        { header: '배송처', key: 'depot_no', custom: '배송처', use_yn: true, required: true },
        { header: '실재고', key: 'inout_cnt', custom: '실재고', use_yn: true, required: true },
        { header: '속성', key: 'opt', custom: '속성', use_yn: true, required: false },
        { header: '합포장 여부', key: 'bundle_avail_yn', custom: '합포장 여부', use_yn: true, required: false },
        { header: '원산지', key: 'madein', custom: '원산지', use_yn: true, required: false },
        { header: '복수원산지', key: 'multi_madein_yn', custom: '복수원산지', use_yn: true, required: false },
        { header: '과세여부', key: 'tax_type', custom: '과세여부', use_yn: true, required: false },
        { header: '매입처', key: 'supp_no', custom: '매입처', use_yn: true, required: false },
        { header: '원가', key: 'cost_price', custom: '원가', use_yn: true, required: false },
        { header: '공급가', key: 'supply_price', custom: '공급가', use_yn: true, required: false },
        { header: '판매가', key: 'sale_price', custom: '판매가', use_yn: true, required: false },
        { header: '속성', key: 'opt', custom: '속성', use_yn: true, required: false },
        { header: '브랜드', key: 'brand', custom: '브랜드', use_yn: true, required: false },
        { header: '모델명', key: 'model', custom: '모델명', use_yn: true, required: false },
        { header: '제조사', key: 'maker', custom: '제조사', use_yn: true, required: false },
        { header: '영문제조사', key: 'maker_eng', custom: '영문제조사', use_yn: true, required: false },
        { header: '제조일자', key: 'made_date', custom: '제조일자', use_yn: true, required: false },
        { header: '유효일자', key: 'expire_date', custom: '유효일자', use_yn: true, required: false },
        { header: '바코드', key: 'barcode', custom: '바코드', use_yn: true, required: false },
        { header: 'ISBN코드', key: 'isbn', custom: 'ISBN코드', use_yn: true, required: false },
        { header: 'HS코드', key: 'hscd', custom: 'HS코드', use_yn: true, required: false },
      ],

      /**
       * 2017-12-05 harry
       * 옵션 조합
       */
      setOptionsTable: function (opts, defaultObj) {
        // SKU상품 등록시 속성 조합 + - 버튼으로 추가/삭제하는걸로 변경해서 opts에 값이 없는경우 체크 2018-07-03 Amelia
        // var opt_attr1 = opts[0].attr.split(',');
        // var opt_attr2 = opts[1].attr.split(',');
        // var opt_attr3 = opts[2].attr.split(',');
        const opt_attr1 = opts[0] ? opts[0].attr.split(',') : [''];
        const opt_attr2 = opts[1] ? opts[1].attr.split(',') : [''];
        const opt_attr3 = opts[2] ? opts[2].attr.split(',') : [''];
        const options_table = [];
        let optIdx = 0;

        _.forEach(opt_attr1, function (attr1) {
          _.forEach(opt_attr2, function (attr2) {
            _.forEach(opt_attr3, function (attr3) {
              if (!(opts[0].name === '' && (_.isUndefined(opts[1]) || opts[1].name === '') && (_.isUndefined(opts[2]) || opts[2].name === ''))) {
                const default_obj = {
                  checked_opt: false, // 일반옵션체크
                  checked_depot: false, // depot 체크
                  skuAuto: true,
                  sku_cd: '', // SKU코드
                  barcode: '', // 바코드
                  real_stock: 0, // 실재고
                  safe_stock: defaultObj.safe_stock_default, // 안전재고
                  delivery_vendor: defaultObj.default_warehouse, // 배송처(출고처)
                  model: '', // 모델명
                  model_no: '',
                  prod_img: '',
                  stock_cd: '',  // 재고관리코드
                  optIdx,
                  depotIdx: 0,
                  depotLength: 1,
                  main_depot_no: defaultObj.default_warehouse
                };

                // SKU상품 등록시 속성 조합 + - 버튼으로 추가/삭제하는걸로 변경해서 opts에 값이 없는경우 체크 2018-07-03 Amelia
                if (opts[0] && opts[0].name) {
                  default_obj.opt1_type = opts[0].name;
                  default_obj.opt1 = attr1;
                }
                if (opts[1] && opts[1].name) {
                  default_obj.opt2_type = opts[1].name;
                  default_obj.opt2 = attr2;
                }
                if (opts[2] && opts[2].name) {
                  default_obj.opt3_type = opts[2].name;
                  default_obj.opt3 = attr3;
                }
                options_table.push(default_obj);

                optIdx++;
              }
            });
          });
        });

        return options_table;
      },
    };

  });
