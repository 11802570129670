'use strict';

/**
 * 이제너두 카테고리
 * 2018-04-03 rony
 */
angular.module('gmpApp')
  .factory('B603categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_lcate',
        1: 'key_mcate',
        2: 'key_scate',
        3: 'key_dcate'
      },

      // 옵션 repeat data
      category_opt: [
        {},
        {},
        {},
        {}
      ],

      //카테고리 data
      category_data: {
        // 표준분류
        key_lcate: '',
        key_mcate: '',
        key_scate: '',
        key_dcate: '',

        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      },

      // 전시 카테고리 repeat data
      dispCtg_list: {
        0: 'key_dispLcate',
        1: 'key_dispMcate',
        2: 'key_dispScate',
        3: 'key_dispDcate',
        4: 'key_dispFcate'
      },

      // 전시카테고리 배열
      dispCtg_opt: [
        {}, {}, {}, {}, {}
      ],

      // 전시카테고리
      dispCtg: {
        // 전시카테고리
        key_dispLcate: '',
        key_dispMcate: '',
        key_dispScate: '',
        key_dispDcate: '',
        key_dispFcate: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      }
    };
  });
