'use strict';

angular.module('gmpApp')
  .controller('WorkCountAlarmModalCtrl', function ($scope, localStorageService, data, $rootScope, $uibModalInstance, adminModel, payModel, userInfo, commonSVC, $filter, paySVC) {
    $scope.foreverCloseYn = false;

    let solutionProductInfo;
    let payedJoinPrice;

    $scope.openCountPayModal = async () => {
      // 카운트 부족 추천 버전은 무조건 상위버전
      const recommendVersion = (() => {
        if ($scope.solutionInfo.sol_version == 'VIP+') { return 'VIP+'; }

        return $rootScope.recommendVersions[$rootScope.recommendVersions.findIndex(o => o === $scope.solutionInfo.sol_version) + 1];
      })();

      const params = {
        solutionInfo: $scope.solutionInfo,
        solutionProductInfo: solutionProductInfo,
        payedJoinPrice: payedJoinPrice,
        firstOrdYN: !$scope.solutionInfo.first_pay_no,
        closeFlag: true,
        recommendVersion
      };

      const modal = commonSVC.openModal('x3g', { data: params }, 'PayRequestCtrl', 'views/pay/modals/pay_request.html');

      modal.result.then(function (re) {
        const showWorkCount = {
          foreverCloseYn: $scope.foreverCloseYn,
          closeYn: true,
        };

        localStorageService.set('showWorkCountModal', JSON.stringify(showWorkCount));

        $uibModalInstance.close();
        location.reload();
      });
    };

    $scope.close = () => {
      const showWorkCount = {
        foreverCloseYn: $scope.foreverCloseYn,
        closeYn: true,
      };

      localStorageService.set('showWorkCountModal', JSON.stringify(showWorkCount));
      $rootScope.showWorkCountModal.closeYn = true;

      // 공지사항 모달 떠있는 경우 backdrop 클래스 공지사항용으로 교체해줌
      if ($('.notice-modal-window').length) {
        $('.count-modal-backdrop').addClass('notice-modal-backdrop').removeClass('count-modal-backdrop');
      }

      $uibModalInstance.close();
    };

    const init = () => {
      // orderCheck가 false면 주문 카운트 수량이 10% 미만, productCheck가 false이면 상품 카운트 수량이 10% 미만
      $scope.countCheck = {
        order: _.isUndefined(data.orderCheck) ? true : data.orderCheck,
        product: _.isUndefined(data.productCehck) ? true : data.productCheck
      };

      payModel.getSolInfo(userInfo.user.m_no, function (status, data) {
        if (status === 'success') {

          $scope.solutionInfo = data.results.solutionInfos;
          solutionProductInfo = data.results.solutionProductInfo;
          payedJoinPrice = data.results.payedJoinPrice;    // 결제된 가입비 정보

          // 다음 카운트 갱신일
          const solEdate = moment($scope.solutionInfo.sol_edate);
          const today = moment();
          const usingChargeEdate = moment($scope.solutionInfo.using_charge_edate);

          if (solEdate.isAfter(today, 'day')) {
            $scope.solutionInfo.using_charge_edate = usingChargeEdate.add(1, 'days').format('YYYY-MM-DD');
          } else {
            $scope.solutionInfo.using_charge_edate = '없음';
          }

          // 남은일자
          $scope.solutionInfo.leftDay = moment($scope.solutionInfo.sol_edate).endOf('day').diff(moment(), 'days');

          // 현재 사용중인 솔루션의 월금액.
          for (let i = 0; i < solutionProductInfo.length; i++) {
            // 사용중인 솔루션의 일금액. ( 환불시 필요 )
            if (solutionProductInfo[i].prod_name === $scope.solutionInfo.sol_version && solutionProductInfo[i].prod_opt === '1일') {
              $scope.solutionInfo.dayPrice = solutionProductInfo[i].price;
            }
          }
        }
      });
    };

    init();
  });