'use strict';

angular.module('gmpApp')
  .controller('addListCtrl', function ($scope, $uibModalInstance) {

    /**
     * 추가
     */
    $scope.onSubmit = function () {
      if ($scope.form1.$valid) {
        const data = $('#add_name').val();

        $uibModalInstance.close({ re: 'success', type: 'add', data: data });
      }
    };
    /**
     * 취소
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  .controller('renameListCtrl', function ($rootScope, $scope, $uibModalInstance) {

    $scope.name = $scope.$resolve[0].data;
    /**
     * 수정
     */
    $scope.onSubmit = function () {
      if ($scope.form1.$valid) {
        const newdata = $('#rename').val();

        $uibModalInstance.close({ re: 'success', type: 'rename', olddata: $scope.name, newdata: newdata });
      }
    };
    /**
     * 취소
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });