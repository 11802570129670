'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentEbaydepotSyncDelivInfoCtrl', function ($scope, $uibModalInstance, $q, ebaydepotAccounts, commonSVC, workSVC) {
    ebaydepotAccounts = ebaydepotAccounts.data.results;

    $scope.data = {
      sdate: moment().subtract(4, 'day').format('YYYY-MM-DD'),
      edate: moment().format('YYYY-MM-DD'),
      dateYn: false
    };

    $scope.submit = function () {
      if ($scope.data.dateYn && moment($scope.data.sdate).diff($scope.data.edate, 'day') > 0) {
        commonSVC.showMessage('동기화 실패', '날짜를 확인해주세요');

        return false;
      }

      let addWorkProms = [],
          success_cnt = 0,
          error_cnt = 0;

      _.each(ebaydepotAccounts, function (account) {
        const param = {
          site: {
            site_code: 'ESMP',
            site_name: 'G마켓 물류',
            site_id: account.ebaydepot_id,
            site_pwd: account.ebaydepot_pwd,
            playapi_runner_os: 'AWS',
            uuid: account.uuid
          },
          data: $scope.data
        };

        addWorkProms.push(workSVC.addEbaydepotWork('SyncDelivInfoAPI', param, true)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              success_cnt++;
            } else {
              error_cnt++;
            }
          })
          .catch(function () {
            error_cnt++;
          })
        );
      });

      $q.all(addWorkProms)
        .then(function () {
          commonSVC.showToaster('success', '작업등록 완료', `성공 : ${success_cnt}, 실패 : ${error_cnt}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
