'use strict';

angular.module('gmpApp')
  .factory('P020categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false,  //상품적용여부
        detailData: {
          display_categories: [],
          ec_category: {
            lotte_wing: [],
            lotte_on: []
          }
        }
      },
      // 전시카테고리
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      },
      // 슈퍼윙전시카테고리
      wingDispCtg: {
        dispCtgLoad: false,
        dispCtgSelectList: []
      },
      // 롯데온전시카테고리
      lotteOnDispCtg: {
        dispCtgLoad: false,
        dispCtgSelectList: []
      }
    };
  });