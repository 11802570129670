/**
 * 중복확인 factory
 *
 * 2016-05-25 : 김민영
 *
 */
'use strict';

angular.module('gmpApp')
  .factory('overlapSVC', function (settings, commonSVC) {
    return {
      Check: function (location_eng, checkValue, next) {
        let return_result;

        if (checkValue == '' || checkValue == undefined) {
          return_result = undefined;
          next(return_result);
        } else {
          const data = {};

          data.value = checkValue;
          data.location = location_eng;

          commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/users/check-duplicate`, data, function(result, data) {
            if (data.result == 'y') {
              return_result = 'y';
            } else if (data.result == 'n') {
              return_result = 'n';
            } else {
              return_result = 'error';
            }
            next(return_result);
          });
        }
      }
    };
  });
