'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-P058-Ctrl', function ($scope, $timeout, P058categorySVC, errorSVC, commonSVC, categoryModel, commonModel) {
    $scope.CateData = angular.copy(P058categorySVC.category_data);

    let save_flag = false;          // (일반) 최하위 카테고리 선택여부
    const CODE_DELIMITER = '_';
    const MAX_LENGTH = 4;           // (일반) 카테고리 최대 길이
    $scope.sel_category = [];       // (일반) 선택한 카테고리 리스트
    $scope.cate = [];               // (일반) 카테고리 리스트
    $scope.category_names = '';     // (일반) 선택시 상위 노출될 카테고리 분류

    let disp_save_flag = false;           // (전시) 최하위 카테고리 선택여부
    const CODE_DELIMITER_FOR_DISP = '_';
    const IS_REQUIRED_FOR_DISP = true;   // (전시) 카테고리 필수 여부
    const MAX_LENGTH_FOR_DISP = 3;        // (전시) 카테고리 최대 길이
    $scope.sel_disp_category = [];        // (전시) 선택한 카테고리 리스트
    $scope.disp_cate = [];                // (전시) 카테고리 리스트
    $scope.savedDispCateList = [];        // (전시) 다중 카테고리 저장하는 배열
    $scope.selectDispLimit = -1;          // (전시) 카테고리 선택 제한 개수(-1은 무한대)

    $scope.pa5_job_data = {
      get_display_categories: {
        load: false,
        data: []
      },
    };

    for (let index = 0; index < MAX_LENGTH; index++) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
    }
    for (let index = 0; index < MAX_LENGTH_FOR_DISP; index++) {
      $scope.sel_disp_category.push('');
      $scope.disp_cate.push([]);
    }

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, fn) => {
      try {
        const response = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);
        if (!response.data.has_error) {
          $scope.pa5_job_data[job_name].data = fn ? fn(response.data.results[0].data) : response.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.\n${response.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.`);
      }
    };

    const getDisplayCategoriesByParentCode = (parent_code) => $scope.pa5_job_data.get_display_categories.data.filter(data => data.pa_shop_cate_see_cd === parent_code);

    /**
     * (일반) 카테고리 선택
     */
    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      save_flag = cate.end_yn;

      // 하위 카테고리 초기화
      for (let i = idx + 1; i < MAX_LENGTH; i++) {
        $scope.cate[i] = [];
        $scope.sel_category[i] = '';
      }

      // 최하위 카테고리가 아닐 때
      if (!save_flag) {

        // 카테고리 명 ex) "대분류 > 중분류 > 소분류"
        $scope.category_names = $scope.sel_category.map(sel => sel.shop_cate_see_name).filter(Boolean).join(' > ') + ' >';

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await $timeout(() => {
          $scope.cate[idx + 1] = res.data.result;
          $scope.loading = true;
        });
      }
      // 최하위 카테고리 일 때
      else {
        $scope.category_names = $scope.sel_category.map(sel => sel.shop_cate_see_name).filter(Boolean).join(' > ');
      }

      return true;
    };

    /**
     * (전시) 카테고리 선택
     */
    $scope.selectDisp = async (idx) => {
      const cate = $scope.sel_disp_category[idx];

      if (!cate) {
        return false;
      }

      disp_save_flag = cate.end_yn;

      // 하위 카테고리 초기화
      for (let i = idx + 1; i < MAX_LENGTH_FOR_DISP; i++) {
        $scope.disp_cate[i] = [];
        $scope.sel_disp_category[i] = '';
      }

      // 최하위 카테고리가 아닐 때
      if (!disp_save_flag) {
        await $timeout(() => {
          $scope.disp_cate[idx + 1] = getDisplayCategoriesByParentCode(cate.shop_cate_see_cd ?? '');
          $scope.disp_loading = true;
        });

        return true;
      }
      // 최하위 카테고리 일 때
      else {
        const disp_category_name = $scope.sel_disp_category.map(data => data.shop_cate_see_name).filter(Boolean).join(' > ');
        const disp_category_code = $scope.sel_disp_category.map(data => data.shop_cate_see_cd).filter(Boolean).join(CODE_DELIMITER_FOR_DISP);

        if ($scope.selectDispLimit > 0 && $scope.selectDispLimit <= $scope.savedDispCateList.length) {
          commonSVC.showMessage('실패', `카테고리를 ${$scope.selectDispLimit}개 초과로 선택하실 수 없습니다.`);

          return false;
        } else if ($scope.savedDispCateList.some(({ code }) => code == disp_category_code)) {
          commonSVC.showMessage('실패', '이미 선택된 카테고리입니다.');

          return false;
        } else if (await commonSVC.showConfirm(`최하단 카테고리입니다. 추가하시겠습니까?\n선택된 카테고리:\n${disp_category_name}`)) {
          $scope.savedDispCateList.push({
            name: disp_category_name,
            code: disp_category_code
          });

          return true;
        }

        return false;
      }
    };

    /**
     * (전시) 다중 카테고리 삭제
     */
    $scope.removeSelectedDispCate = (index) => {
      $scope.savedDispCateList.splice(index, 1);
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function (event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    /**
     * 확인 버튼
     */
    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     */
    $scope.shopInfoSave = async (event) => {
      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      // (일반) 카테고리 마지막 분류까지 선택여부
      if (save_flag === false) {
        commonSVC.showMessage('실패', '표준 카테고리를 최하위 분류 까지 모두 선택해 주세요.');

        return false;
      }

      if (IS_REQUIRED_FOR_DISP && $scope.savedDispCateList.length === 0) {
        commonSVC.showMessage('실패', '웹사이트 카테고리를 선택해주세요.');

        return false;
      }

      const category_code = $scope.sel_category.map(data => data.shop_cate_see_cd).filter(Boolean).join(CODE_DELIMITER);

      $scope.CateData.detailData.category_names = $scope.category_names;
      $scope.CateData.detailData.category_code = category_code;
      // (전시) 카테고리
      $scope.CateData.detailData.display_categories = $scope.savedDispCateList;

      if ($scope.form1.$valid) {
        /**
         * 2018-03-09 Roy
         * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
         */
        const param = {
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);

            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    /**
     * 2018-03-09 Roy
     * 상품 등록, 수정 구분을 사용을 위해 함수로 뺌
     * 상품 수정의 경우에 솔루션 카테고리 정보를 수정하지 말아야함
     */
    const matchDetailDataLoad = async (param_info) => {
      if (typeof (param_info) === 'string') {
        $scope.CateData.detailData = JSON.parse(param_info);
      } else {
        $scope.CateData.detailData = param_info;
      }

      if (!$scope.CateData.detailData.category_code) {
        return false;
      }

      //전시 카테고리
      $scope.savedDispCateList = param_info.display_categories || $scope.savedDispCateList;

      await setCategory($scope.CateData.detailData.category_code);
    };

    /**
     * 초기화
     */
    const init = async () => {
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'P058', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });
      await run_pa5_jobs('get_display_categories', '웹사이트 카테고리', data => data.map(each_data => ({
        shop_cd: 'P058',
        shop_cate_see_cd: each_data.code,
        std_ol_yn: 0,
        pa_shop_cate_see_cd: each_data.parent_code,
        shop_cate_see_depth: each_data.depth,
        shop_cate_see_name: each_data.name,
        shop_cate_see_type: each_data.type,
        etc: null,
        end_yn: each_data.is_end ? 1 : 0,
        shop_cate_see_detail: {
          shop_cate_see_cd_detail: each_data.code,
          shop_cate_see_name_detail: each_data.name,
        }
      })));

      await $timeout(async () => {
        // 초기 카테고리 가지고 오기
        $scope.cate[0] = res.data.result;
        $scope.disp_cate[0] = getDisplayCategoriesByParentCode('');
        /**
         * 2018-03-09 roy
         * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
         */
        if ($scope.matchingData.shop_cate_no_add_info) {
          await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
        } else if ($scope.matchingData.sol_cate_shop_add_info) {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        } else {
          await matchDetailDataLoad(angular.copy(P058categorySVC.category_data));
        }
      });
    };

    const setCategory = async (category_code) => {
      const code = category_code.split(CODE_DELIMITER);

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => cate.shop_cate_see_cd === code[idx]) || '';
        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
