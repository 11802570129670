'use strict';

/**
 * SKU상품 매칭 검색
 */
angular.module('gmpApp')
  .controller('prodProductSearch', function ($scope, $rootScope, $uibModalInstance, $filter, productModel, supplierModel, warehouseModel, systemModel, commonSVC, productSVC, data, userInfo, settings) {
    $scope.from = data.from || '온라인 상품';
    $scope.showSet = ['사은품', 'A/S주문'].indexOf(data.from) === -1;
    $scope.searchData = angular.copy(productSVC.searchData);
    $scope.searchData.showCount = 10;
    $scope.searchForm = angular.copy(productSVC.searchForm);
    $scope.searchForm.sdate = '2001-01-01';
    $scope.tabActive = 0;
    $scope.search_key = 'all';
    $scope.set_search_key = 'all';
    $scope.parent_search_key = 'all';
    $scope.warehouseKey = 'all';
    $scope.warehouseList = data.warehouseList;
    $scope.isAddOpt = data.isAddOpt; //추가구매옵션 여부
    $scope.flag = {};
    // $scope.flag.changeOptName = false;  //추가구매옵션인 경우 이름 변경 여부
    $scope.flag.newMappingRule = false;   //매칭규칙생성(수정)여부
    $scope.isManual = data.isManual === true; // 직접입력 주문 여부
    $scope.productInit = true;
    $scope.setInit = false;
    $scope.parentProductInit = false;
    $scope.data = {
      detailSearchFlag: false
    };
    $scope.isMultiProd = data.isMultiProd;

    // LG custom 처리.
    $scope.viewLgCustom = data.modal_type === 'lg_custom';
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    const isMulti = data.isMulti || false; // 멀티셀렉트 여부
    const isSetMulti = data.isSetMulti || false; // 세트 멀티셀렉트 여부
    const is_smileProd = data.modal_type === 'smileProd'; // 스마일 상품 여부
    $scope.searchForm.is_smileProd = is_smileProd;

    let selectProdList = [];
    $scope.selectedCount = 0;

    /**
     * 탭 변경
     */
    $scope.tabChange = (tab) => {
      $scope.tabActive = tab;
      $scope[`${['product', 'set', 'parentProduct'][tab]}Init`] = true;
    };

    if ($scope.viewLgCustom || $scope.viewModelNo) {
      $scope.productInit = false;
      $scope.tabChange(2);
    }

    /**
     *  SKU상품 데이터 테이블
     */
    $scope.productGrid = {};
    $scope.productGrid.methods = {};
    $scope.productGrid.options = {
      modal: true,
      selectOptions: {
        multiSelect: isMulti
      },
      pinningColumns: [],
      alignCenterColumns: [
        'output_cnt', 'wdate', 'sku_cd', 'prod_name', 'attri',
        'sale_price', 'stock_cnt', 'stock_status', 'depot_name'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['sku_cd'],
      notSortingColumns: ['output_cnt', 'stock_cd', 'sale_price', 'stock_cnt', 'stock_status', 'depot_name', 'bundle_avail_yn'],
      notResizingColumns: ['output_cnt', 'wdate'],
      notMovingColumns: [],
      notVisibleColumns: ['stock_cd'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/matching-list-of-base`,
        requestWillAction: function (data) {
          $scope.searchForm.orderby && delete $scope.searchForm.orderby;

          // 배송처일 때 해당 배송처 내용만 검색되도록 수정 #gmpkr-7591 2019-09-18 Jacob
          if (userInfo.user.auth_type === '배송처') {
            data.delivery_vendor = userInfo.user.depot_no;
          }

          // 선택된 항목에 있지만 선택 해제한 row 처리
          selectProdList = selectProdList.filter(prod => {
            if ($scope.prodList.find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no)) {
              return $scope.productGrid.methods.selectedData('all').find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no);
            } else {
              return true;
            }
          });

          // 현재 선택된 row selectProdList에 추가
          selectProdList.push(... $scope.productGrid.methods.selectedData('all'));

          // selectProdList 중복 제거
          selectProdList = selectProdList.filter((prod, idx, arr) => arr.findIndex(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no) === idx);

          return Object.assign($scope.searchForm, data);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          result.results.forEach((data) => data.pack_unit = 1);
          $scope.prodList = result.results;

          // 선택된 row 선택 처리
          result.results.forEach(row => {
            const matchProd = selectProdList.find(prod => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no);

            row.isSelected = !!matchProd;
            row.pack_unit = matchProd?.pack_unit || 1;
          });

          // 현재 페이지에 있는 row 제거
          selectProdList = selectProdList.filter(prod => !result.results.find(row => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no));
          // 현재 페이지에 없는 선택된 row의 수
          $scope.selectedCount = selectProdList.length;

          return result.results;
        }
      },
      columns: [
        {
          key: 'output_cnt',
          title: '출고수량',
          width: 80,
          template: function (row) {
            const idx = _.findIndex($scope.prodList, { prod_no: row.prod_no, depot_no: row.depot_no });

            return `<input type="text" comma min="1" max="9999" class="form-control input-xs2" name="pack_unit" value="0" ng-model="grid.appScope.prodList[${idx}].pack_unit">`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'sku_cd',
          title: 'SKU 코드',
          width: 110
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 110
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 270,
          template: function (row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'><a ng-click="grid.appScope.showDetail(0,0,'${row.sku_cd}','${row.prod_no}' )">${row.prod_name}</a></span>`;
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 80
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 80
        },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          tooltip: {
            text: '기존에 판매하고 있는 제품을 사은품으로 등록하시는 경우, 판매하고 있는 사은품 상품의 주문 상황에 따라&#10;사은품 설정 수량을 남기지 않고 판매되는 경우가 발생할 수 있습니다.\n그 경우, 사은품 규칙이 적용된 주문은 출고 불가 표시가 됩니다.',
            placement: 'bottom',
            forView: $scope.from === '사은품',
            appendToBody: true
          },
          width: 80,
          template: function (row) {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        {
          key: 'stock_status',
          title: 'SKU상품상태',
          width: 80,
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 110
        },
        {
          title: '합포장 가능여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        }
      ]
    };

    // 사은품 모달에서 열리는경우 출고수량 미노출
    if ($scope.from === '사은품') {
      $scope.productGrid.options.columns = $scope.productGrid.options.columns.filter(o => o.key !== 'output_cnt');
    }

    /**
     * 세트상품 데이터 테이블
     */
    $scope.setProductGrid = {};
    $scope.setProductGrid.methods = {};
    $scope.setProductGrid.options = {
      modal: true,
      selectOptions: {
        multiSelect: isSetMulti
      },
      pinningColumns: [],
      alignCenterColumns: [
        'output_cnt', 'wdate', 'set_cd', 'set_status',
        'set_name', 'sale_price', 'delivery_vendor_name'
      ],
      alignRightColumns: ['sale_cnt_limit'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['output_cnt', 'delivery_vendor_name'],
      notResizingColumns: ['output_cnt', 'wdate'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/set/list`,
        requestWillAction: function (data) {
          $scope.searchForm.orderby && delete $scope.searchForm.orderby;

          if (userInfo.user.auth_type === '배송처') {
            data.delivery_vendor = userInfo.user.depot_no;
          }

          return Object.assign($scope.searchForm, data);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          result.results.forEach((data) => data.pack_unit = 1);
          $scope.setList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'output_cnt',
          title: '출고수량',
          width: 80,
          template: function (row) {
            const idx = _.findIndex($scope.setList, { set_no: row.set_no });

            return `<input type="text" comma min="1" class="form-control input-xs2" name="set_pack_unit" value="0" ng-model="grid.appScope.setList[${idx}].pack_unit">`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 80
        },
        {
          key: 'set_status',
          title: '상태',
          width: 60
        },
        {
          key: 'set_name',
          title: '세트 상품명',
          width: 400,
          template: (row) => {
            return `<a ng-click="grid.appScope.setShowDetail('${row.set_no}')">${row.set_name}</a>`;
          }
        },
        {
          key: 'sale_price',
          title: '세트 판매가',
          width: 100,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        {
          key: 'sale_cnt_limit',
          title: '판매수량',
          width: 100,
          template: function(row) {
            return (row.use_sale_cnt_limit_yn && row.sale_cnt_limit) || '미사용';
          }
        },
        {
          key: 'delivery_vendor_name',
          title: '배송처',
          width: 150
        }
      ]
    };

    /*
     *  LG전자 SKU상품 데이터 테이블
     */
    $scope.parentProductGrid = {};
    $scope.parentProductGrid.methods = {};
    $scope.parentProductGrid.options = {
      modal: true,
      selectOptions: {
        multiSelect: isMulti
      },
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'wdate', 'sku_cd', 'prod_name', 'attri', 'stock_status', 'sale_price'],
      alignRightColumns: [],
      defaultSortingColumns: ['sku_cd'],
      notSortingColumns: ['widget', 'stock_status', 'real_stock', 'stock_cnt_safe', 'sale_price', 'model_no'],
      notResizingColumns: ['widget', 'wdate'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/matching-list-of-base`,
        requestWillAction: function (data) {
          $scope.searchForm.orderby && delete $scope.searchForm.orderby;

          // 배송처일 때 해당 배송처 내용만 검색되도록 수정 #gmpkr-7591 2019-09-18 Jacob
          if (userInfo.user.auth_type === '배송처') {
            data.delivery_vendor = userInfo.user.depot_no;
          }

          // 슈퍼 계정일 경우에는 본인 SKU상품 조회
          if ($scope.tabActive === 2 && !$rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
            $scope.searchForm.custom = 'LG';
          }

          return Object.assign($scope.searchForm, data);
        },
        requestDidAction: function (result) {
          $scope.searchForm.custom = '';
          $scope.searchData.totalCount = result.recordsTotal;

          result.results.forEach((data) => data.pack_unit = 1);
          $scope.parentProdList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 60,
          template: function (row) {
            return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.showDetail(0,0,'${row.sku_cd}','${row.prod_no}','${$rootScope.user_profile.pa_sol_no}' )">상세</button>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'sku_cd',
          title: 'SKU 코드',
          width: 110
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 270
        },
        {
          key: 'attri',
          title: '속성',
          width: 80
        },
        {
          key: 'stock_status',
          title: '상태',
          width: 80
        },
        {
          key: 'real_stock',
          title: '실재고',
          width: 80
        },
        {
          key: 'stock_cnt_safe',
          title: '안전재고',
          width: 80,
          template: function (row) {
            return row.stock_cnt_safe || 0;
          }
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 80
        },
        {
          key: 'model_no',
          title: '모델번호',
          width: 150
        }
      ]
    };

    // 모델번호 컬럼 추가
    if ($scope.viewModelNo) {
      $scope.productGrid.options.columns.push({
        key: 'model_no',
        title: '모델번호',
        width: 150
      });
      $scope.setProductGrid.options.columns.push({
        key: 'model_no',
        title: '모델번호',
        width: 150
      });
    }

    /**
   * 세트 상세보기
   */
    $scope.setShowDetail = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };
      const modal = commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');

      modal.result.then(function() {
        $scope.searchDo();
      });
    };

    /**
   * 검색
   */
    $scope.searchDo = function (refresh, noDelay) {
      if ($scope.tabActive == 0) {
        if ($scope.search_key == 'all') { $scope.searchForm.search_key = 'all'; }
        else { $scope.searchForm.search_key = [$scope.search_key]; }
        $scope.productGrid.methods.reloadData(function() {}, refresh, noDelay, true);
      } else if ($scope.tabActive == 1) {
        if ($scope.set_search_key == 'all') { $scope.searchForm.search_key = 'all'; }
        else { $scope.searchForm.search_key = [$scope.set_search_key]; }
        $scope.setProductGrid.methods.reloadData(function() {}, refresh, noDelay, true);
      } else if ($scope.tabActive == 2) {
        if ($scope.parent_search_key == 'all') { $scope.searchForm.search_key = 'all'; }
        else { $scope.searchForm.search_key = [$scope.parent_search_key]; }
        $scope.parentProductGrid.methods.reloadData(function() {}, refresh, noDelay, true);
      }
    };

    /**
   * 배송처 검색
   */
    $scope.warehouse = function(delivery_vendor) {

      $scope.searchForm.delivery_vendor = delivery_vendor;
      $scope.warehouseKey = delivery_vendor;

      if ($scope.tabActive == 0) {
        $scope.productGrid.methods.reloadData(function() { }, true, true);
      } else if ($scope.tabActive == 1) {
        $scope.setProductGrid.methods.reloadData(function() {}, true, true);
      }
    };

    // 건수 별 데이터 리셋
    $scope.changeLen = () => {
      let dtKey = '';

      switch ($scope.tabActive) {
        case 0:
          dtKey = 'productGrid';
          break;
        case 1:
          dtKey = 'setProductGrid';
          break;
        case 2:
          dtKey = 'parentProductGrid';
          break;
      }

      $scope[dtKey].methods.length($scope.searchData.showCount);
      $scope[dtKey].methods.reloadData(function() { }, true, true);
    };

    /**
   * 검색초기화
   */
    $scope.resetDo = function() {
      $scope.searchData = angular.copy(productSVC.searchData);
      $scope.searchData.showCount = 10;
      $scope.searchForm = angular.copy(productSVC.searchForm);
      $scope.searchForm.is_smileProd = is_smileProd;
      $scope.search_key = 'all';
      $scope.set_search_key = 'all';
      $scope.parent_search_key = 'all';
      $scope.warehouseKey = 'all';

      // 더스킨 요청으로 인해 초기화인 경우만 선택항목 초기화(문의번호 : 339507)
      if ($scope.tabActive == 0) {
        // 선택값 초기화
        selectProdList = [];
        $scope.selectedCount = 0;
        $scope.productGrid.methods.unSelectByFilter(function (r) {
          return true;
        }, false);
      }

      $scope.searchDo(true);
    };

    /**
   * 상세페이지 모달창
   */
    $scope.showDetail = function(rowIndex, show, sku_cd, prod_number, pa_sol_no = '') {

      // SKU상품 수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      if (pa_sol_no && pa_sol_no !== 'null' && !$rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
        resolve.data.sol_no = pa_sol_no || $rootScope.user_profile.pa_sol_no;
        resolve.data.nonEdit = true;
      }

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        $scope.productGrid.methods.reloadData(function() {}, false);
      });
    };

    $scope.apply = async function() {
      // 2018-01-14 chris SKU상품 선택 로직 수정
      const selectedProduct = [];
      const flag = true;

      //SKU상품 선택
      if ([0, 2].includes($scope.tabActive)) {
        const no_depot_no = '';

        if (!['사은품', '조건'].includes($scope.from)) {
          const depotErrList = [], packUnitErrList = [];

          let prod_list = [];
          if ($scope.tabActive === 0) {
            // 현재페이지 선택값 + 기존 선택값
            prod_list = $scope.isMultiProd ? selectProdList.concat($scope.productGrid.methods.selectedData('all')) : $scope.productGrid.methods.selectedData('all');
          } else {
            prod_list = $scope.parentProductGrid.methods.selectedData('all');
          }

          prod_list.forEach((element) => {

            if (!element.depot_no) {
              depotErrList.push(element);
            } else if (element.pack_unit < 1 || element.pack_unit > 9999) {
              packUnitErrList.push(element);
            } else {

              const prod = $scope[$scope.tabActive === 0 ? 'prodList' : 'parentProdList'].concat(selectProdList).find(({ prod_no, depot_no }) => prod_no === element.prod_no && depot_no === element.depot_no);

              element.newMappingRule = $scope.flag.newMappingRule;

              if ($scope.isAddOpt) {
                element.opt_prod_name = element.prod_name;
                element.prod_name = data.ord_opt_name;
              }

              element.pack_unit = prod.pack_unit;
              element.model_no = prod.model_no;

              selectedProduct.push(element);
            }
          });

          if (!selectedProduct.length) {
            commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');

            return;
          }
          if (depotErrList.length) {
            commonSVC.showMessage(`SKU코드\n${depotErrList.map(d => d.sku_cd).join('\n')}\n상품은 배송처가 지정되지 않은 상품 입니다.`);

            return;
          }
          if (packUnitErrList.length) {
            commonSVC.showMessage(`출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.\nSKU코드\n${packUnitErrList.map(p => p.sku_cd).join('\n')}\n상품의 출고수량을 확인해주세요.`);

            return;
          }
          $uibModalInstance.close(data.isMultiProd ? selectedProduct : selectedProduct[0]);
        } else {
          const selectedData = $scope.productGrid.methods.selectedData('all');

          if (!selectedData.length) {
            commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');

            return;
          }

          $uibModalInstance.close(selectedData);

          return;
        }

        if (no_depot_no != '') {
          commonSVC.showMessage(`SKU코드 ${no_depot_no} 상품은 배송처가 지정되지 않은 상품 입니다.`, '배송처를 지정 후 사용하시기 바랍니다.');
        }
        // SKU상품 선택이 1개 미만일 때
        if (selectedProduct.length < 1 && flag) {
          commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');
        }
      }
      //세트상품 선택
      else if ($scope.tabActive === 1) {
        angular.forEach($('#set_product_grid .ui-grid-render-container.ui-grid-render-container-body ' +
                        'div.ui-grid-viewport > div > div.ui-grid-row.ui-grid-row-selected.ui-grid-row-focused'), function(element) {
          const index = $(element).find('.ui-grid-row-inner').attr('row-index');

          selectedProduct.push($scope.setList[index]);
        });

        if (selectedProduct.length < 1) {
          commonSVC.showMessage('매칭하실 세트상품을 선택 해주세요.');

          return false;
        }
        const usedFlag = _.filter(selectedProduct, function(row) { return row.set_status == '사용불가'; });

        if (usedFlag.length) {
          commonSVC.showMessage('', '배송처가 다르거나 합포장이 불가한 상품이 세트로 구성된 경우\n해당 세트상품은 사용이 불가합니다.\n\n세트 상품의 상세 페이지에서 구성 상품의 정보를 변경하신 후\n다시 매칭 작업을 진행해 주세요.');

          return false;
        }

        if (selectedProduct.some(e => e.pack_unit < 1 || e.pack_unit > 9999)) {
          commonSVC.showMessage('출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.');

          return false;
        }

        if (isSetMulti) {
          $uibModalInstance.close([{ isSet: true, data: $scope.setProductGrid.methods.selectedData('all') }]);

          return;
        }

        productModel.setDetails({ set_no: selectedProduct[0].set_no }, function(state, resultData) {
          const idx = _.findIndex($scope.setList, { set_no: selectedProduct[0].set_no });

          resultData.data.set.pack_unit = $scope.setList[idx].pack_unit;
          resultData.data.set.set_pack_unit = $scope.setList[idx].pack_unit;

          resultData.data.set.newMappingRule = $scope.flag.newMappingRule;
          if ($scope.isAddOpt) {
            resultData.data.set.opt_prod_name = resultData.data.set.set_name;
            resultData.data.set.set_name = data.ord_opt_name;
          }

          // 대표 SKU상품이 0번 인덱스로 오도록 수정 2020-05-21 Tim
          const main_prod = resultData.data.prod.splice(resultData.data.prod.findIndex(p => p.prod_no === resultData.data.set.main_prod_no), 1);

          resultData.data.prod = main_prod.concat(resultData.data.prod);

          const rData = { isSet: true, data: resultData.data };

          $uibModalInstance.close(rData);
        });
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
   * 세트상품생성 모달열기
   * 2018-05-17 rony
   * */
    $scope.openProdSet = function() {
      const modal = commonSVC.openModal('full', { data: {} }, 'addSetProdCtrl', 'views/prod/set/modals/add_set_prod.html');

      modal.result.then(function() {
        $scope.searchDo();
      });
    };

    /**
   * SKU상품 등록 모달열기
   * 2018-05-17 rony
   * */
    $scope.openProdAdd = function() {

      // SKU상품 등록 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
        // sku : sku_cd,
        // number : prod_number,
          detailType: 'add',
          openModal: true
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('full', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        $scope.searchDo();
      });
    };
  });
