'use strict';

angular.module('gmpApp')
  .controller('wakeUpAddInfoCtrl', function (
    $scope, $uibModalInstance, $timeout, $q, $state,
    commonSVC, workSVC, localStorageService,
    commonModel, shopAccountModel, systemModel,
    siteInfo, data, userInfo) {
    $scope.shopInfos = _.cloneDeep(data.addShops); // 계정 정보
    $scope.shopInfos.sort(function(prev, curr) {
      return curr.pa_shop_cd < prev.pa_shop_cd ? 1 : -1;
    });

    // pa5 로그인확인
    const loginURLs_pa5 = ['A112', 'A077', 'B378', 'B719'];

    // 로그인확인 액션
    const loginActions = {
      B378: 'etc.check_login',
    };

    $timeout(() => {
      showSpinner('loginSpinner', '계정정보 조회중');
    });

    /**
     * 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.close();
    };

    async function init() {
      try {
        // 솔루션에 저장된 계정정보로 초기화
        $scope.shopInfos.forEach(shopInfo => {
          shopInfo.new_pwd = shopInfo.shop_pwd;
          shopInfo.new_api_enc = shopInfo.api_enc;

          // 스마트스토어
          if (shopInfo.pa_shop_cd === 'A077') {
            shopInfo.storeType = shopInfo.etc2 ? 'smartstore' : 'personal';
            shopInfo.commerce_id = {
              view: moment(shopInfo.wdate) < moment('2022-08-23 00:00:00'),
              use: !!shopInfo.etc6
            };
          }
        });

        $timeout(() => {
          hideSpinner('loginSpinner');
        }, 100);
      } catch (err) {
        commonSVC.showToaster('error', '', '계정정보 조회에 실패했습니다.');
      }
    }

    /**
     * get request body for action
     */
    function getRequestBody(action, shopInfo) {
      const requestBody = {
        job_data: {
          account: {
            seller_id: shopInfo.shop_id,
            seller_pw: shopInfo.new_pwd
          }
        },
        req_code: shopInfo.domain,
        req_action: action,
        req_type: 'application/json+emp',
        res_type: 'application/json+emp'
      };

      for (let i = 1; i <= 7; i++) {
        if (`etc${i}` in shopInfo) {
          requestBody.job_data.account[`etc${i}`] = shopInfo[`etc${i}`];
        }
      }

      return requestBody;
    }

    /**
     * 로딩스피너 보이기
     */
    function showSpinner(id, text) {
      $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
      $(`#${id}`).removeClass('hidden');
    }

    /**
     * 로딩스피너 숨기기
     */
    function hideSpinner(id) {
      $(`#${id}`).addClass('hidden');
    }

    /**
     * 로그인확인
     */
    $scope.checkLogin = async shopInfo => {
      // 아이디, 패스워드 확인처리 추가 2018-03-21 rony
      const reqBody = getRequestBody(loginActions[shopInfo.pa_shop_cd] || 'check_login', shopInfo);

      if (reqBody.job_data.account.seller_id == '' || reqBody.job_data.account.seller_pw == '') {
        commonSVC.showMessage('아이디, 또는 비밀번호를 입력하여 주십시오.');

        return false;
      }

      // 스마트스토어
      if (shopInfo.pa_shop_cd === 'A077') {
        if (!shopInfo.etc4) {
          commonSVC.showMessage('필수 항목을 모두 입력하여 주십시오.');

          return false;
        }
        if ((shopInfo.storeType === 'smartstore' && !shopInfo.etc2) || (shopInfo.storeType === 'personal' && !shopInfo.etc5)) {
          commonSVC.showMessage('스토어URL을 입력해주세요.');

          return false;
        }
        if (shopInfo.storeType === 'personal' && shopInfo.etc5) {
          const regx = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/);

          if (!regx.exec(shopInfo.etc5)) {
            commonSVC.showMessage('스토어URL이 올바르지 않습니다.', '도메인 형식에 맞게 입력하여 주십시오.');

            return false;
          }
        }
      }

      shopInfo.loginChecked = false;

      showSpinner('loginSpinner', '로그인 확인중');

      // PA5 로그인체크
      if (loginURLs_pa5.includes(shopInfo.pa_shop_cd)) {
        try {
          const pa_cd = siteInfo[shopInfo.pa_shop_cd].playapi_shop_cd;
          const pa = {
            shop_id: `${userInfo.user.sol_no}`,
            market_code: pa_cd,
            market_username: reqBody.job_data.account.seller_id,
            id: shopInfo.uuid?.split('|')[0] || '',
            etc: {}
          };

          if (shopInfo.new_pwd !== shopInfo.shop_pwd) {
            pa.no_decrypt = shopInfo.new_pwd;
          } else {
            pa.market_password = reqBody.job_data.account.seller_pw;
          }

          const { data: etcInfo } = await shopAccountModel.getShopEtcData({ shop_cd: shopInfo.pa_shop_cd });

          // pa5 로그인체크시에는 etc값을 치환해서 넘겨줌
          if (etcInfo) {
            Object.keys(etcInfo).forEach(k => {
              // apikey 암호화 관련 처리
              if (etcInfo[k].api_enc) {
                const no_apikeyDecrypt = shopInfo.new_api_enc !== shopInfo.api_enc;

                pa.apikeyData = {
                  value: !no_apikeyDecrypt ? shopInfo.api_enc : shopInfo.new_api_enc,
                  field: etcInfo[k].field,
                  isEncrypt: !no_apikeyDecrypt
                };
              } else {
                pa.etc[etcInfo[k].field] = reqBody.job_data.account[k];
              }
            });
          }

          const data = await commonModel.pa5('/channel_accounts/verify_account', pa);
          const er = data.data;

          hideSpinner('loginSpinner');

          if (er.engine_result) {
            if (er.engine_result.has_error) {
              commonSVC.showMessage('로그인 실패', er.engine_result.results ? er.engine_result.results[0].site_error : '알수없는 오류로 실패하였습니다. 잠시 후 재시도 해주세요.');
            } else {
              shopInfo.loginChecked = true;
              shopInfo.uuid = er.engine_result.channel_account_id;
              commonSVC.showMessage('로그인 성공', '입력하신 계정정보로 로그인이 확인되었습니다.\n하단의 [더 많은 기능 사용하기] 버튼을 눌러 계정 추가를 완료해주세요.');

              // 배송처 받기위해 임시로 웹소켓 연결
              workSVC.pa5_job_connect(shopInfo.uuid);
            }
          } else {
            throw Error();
          }
        } catch (err) {
          if (err.status == 400) {
            switch (err.data.error) {
              case 'Bad Request':
                commonSVC.showMessage('로그인 실패', err.data.message);
                break;
              default:
                commonSVC.showMessage('로그인 실패', '입력하신 계정정보로 로그인이 불가능합니다.\n쇼핑몰 판매자페이지에서 로그인정보를 확인해주시기 바랍니다.');
                break;
            }
            hideSpinner('loginSpinner');
          }
        } finally {
          $scope.shipInfo = {};
          $scope.addrList = {};
          $scope.tmp = {};

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        }
      } else {
        const no_decrypt = shopInfo.new_pwd !== shopInfo.shop_pwd ? shopInfo.new_pwd : false;

        commonModel.pa4(reqBody, no_decrypt, true)
          .then(function (data) {
            hideSpinner('loginSpinner');

            if (data.data.data.status == 200) {
              $scope.shipInfo = {};
              $scope.addrList = {};
              $scope.tmp = {};
              shopInfo.loginChecked = true;
              commonSVC.showMessage('로그인 성공', '입력하신 계정정보로 로그인이 확인되었습니다.\n페이지 하단의 [저장]버튼을 눌러 계정 추가를 완료해주세요.');
            } else {
              throw data.data.data.status;
            }
          })
          .catch(function (err) {
            hideSpinner('loginSpinner');

            $scope.shipInfo = {};
            $scope.addrList = {};
            $scope.tmp = {};
            shopInfo.loginChecked = false;

            switch (err) {
              case 4001:
                commonSVC.showMessage('로그인 실패', '비밀번호가 틀렸습니다. 비밀번호를 확인해주시기 바랍니다.');
                break;
              case 4002:
                commonSVC.showMessage('로그인 실패', '판매자 계정이 아닙니다. 계정을 확인해주시기 바랍니다.');
                break;
              case 4003:
                commonSVC.showMessage('로그인 실패', '공급업체를 먼저 확인해주시기 바랍니다.');
                break;
              case 4008:
                commonSVC.showMessage('로그인 실패', '로그인 불가, 인터파크 판매자페이지에 직접 접속하여 확인바랍니다.');
                break;
              case 4012:
                commonSVC.showMessage('로그인 실패', '복수 스토어 계정은 연동 스토어 여부를 Y으로 변경한 뒤 연동 스토어를 선택해 주세요.');
                break;
              case 5010:
              case 5011:
              case 5012:
                commonSVC.showMessage('로그인 실패', 'API ID를 재확인하시어 입력해 주시기 바랍니다.\n경로 : 스마트스토어 쇼핑파트너 센터 > 스토어 전시관리 > 스토어 관리 > API 정보\n또는 도움말을 참고하시어 API ID를 재확인해 주시기 바랍니다.');
                break;
              case 5013:
                commonSVC.showMessage('로그인 실패', '쇼핑몰 보안 정책에 의한 로그인 확인 실패.\n보호조치를 해제 하신 후 다시 시도해 주시기 바랍니다.');
                break;
              case 5014:
                commonSVC.showMessage('로그인 실패', '없는 계정이거나, 비밀번호가 틀립니다.\nID/PW 를 확인해주시기 바랍니다.');
                break;
              default:
                commonSVC.showMessage('로그인 실패', '입력하신 계정정보로 로그인이 불가능합니다.\n쇼핑몰 판매자페이지에서 로그인정보를 확인해주시기 바랍니다.');
                break;
            }
          });
      }
    };

    /**
     * 계정정보 저장 및 주문수집
     */
    $scope.openQuickCollect = async () => {
      const checkShops = $scope.shopInfos.filter(o => !o.loginChecked).map(o => `${o.shop_name}(${o.shop_id})`);
      if (checkShops.length) {
        commonSVC.showMessage('로그인 확인을 해주세요.', checkShops.join(', '));

        return false;
      }

      const promiseArr = [];
      let errCnt = 0;

      // 계정 추가정보 저장
      for (const shopInfo of $scope.shopInfos) {
        if (shopInfo.pa_shop_cd == 'A077') {
          if (shopInfo.storeType === 'smartstore') {
            shopInfo.etc5 = '';
          } else if (shopInfo.storeType === 'personal') {
            shopInfo.etc2 = '';
          }
        }

        shopInfo.ship_info_default = $scope.shipInfo ? JSON.stringify($scope.shipInfo) : '{}';

        if (['B719', 'A032'].includes(shopInfo.pa_shop_cd)) {
          shopInfo.uuidEtc = { api_access_key: shopInfo.etc1 };
        } else if (shopInfo.pa_shop_cd === 'B378') {
          shopInfo.uuidEtc = {
            api_access_key: shopInfo.etc1,
            api_secret_key: shopInfo.etc2,
            api_etc_key: shopInfo.etc3
          };
        }

        // 비밀번호 변경된 경우에만 값 전달
        shopInfo.shop_pwd = shopInfo.new_pwd !== shopInfo.shop_pwd ? shopInfo.new_pwd : '';

        // apikey 변경된 경우에만 값 전달
        shopInfo.api_enc = shopInfo.api_enc !== shopInfo.new_api_enc ? shopInfo.new_api_enc : '';

        const tmpPromise = shopAccountModel.update(shopInfo, function(state, data) {
          if (data.results === 'success') {
            // 계정정보 변경시 로컬스토리지에 저장된 셋폼토큰 재발급 필요하므로 제거
            localStorage.removeItem(`gmp_setForm_token_${shopInfo.shop_cd}_${shopInfo.shop_id}_${$scope.sol_no}`);
          } else {
            errCnt++;
            commonSVC.showToaster('error', '', `${shopInfo.shop_name} 계정 정보 저장에 실패하였습니다.`);
          }
        });

        promiseArr.push(tmpPromise);
      }

      $q.all(promiseArr)
        .then(async re => {
          if (!errCnt) {
            commonSVC.showToaster('success', '', '계정 정보가 수정 되었습니다.');

            $uibModalInstance.close('success');
          }
        })
        .catch(() => {
          commonSVC.showToaster('error', '', '계정 정보 저장에 실패했습니다.');
        });
    };

    init();
  });
