'use strict';

angular.module('gmpApp')
  .factory('A133categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false,  //상품적용여부
        detailData: {
          key_rsptMdCd: '',
          etc: {}
        }
      },
      // 진열매장
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      },
    };
  });