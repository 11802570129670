/**
 * Created by harry on 2017. 6. 28..
 */

'use strict';

angular.module('gmpApp')
  .controller('CalculateCalendarCtrl', function ($scope, commonSVC, settings, $state, gettext, gettextCatalog, $log,
    $uibModalInstance) {

    $scope.uiConfig = {
      calendar: {
        height: 'auto',
        editable: false,
        header: {
          left: 'today prev',
          center: 'title',
          right: 'next'
        },
        lang: 'ko',
        eventClick: $scope.alertEventOnClick,
        eventDrop: $scope.alertOnDrop,
        eventResize: $scope.alertOnResize,
        eventRender: function(event, element) {
          element.find('span.fc-title').css('white-space', 'normal'); // 타이틀이 긴 경우 줄바꿈처리
        }
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.eventSources = [];
    commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/calculate/calendar`, {}, function(state, data) {
      if (state === 'success') {
        const eventSource = {
          events: data.rows,
          color: 'transparent',
          textColor: 'black'
        };
        $scope.eventSources.push(eventSource);
      }
    });

  });

