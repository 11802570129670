'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B666-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, commonModel, commonSVC, shopAccountModel, onlineProductModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    // pa5로 갖고와야하는 메타데이터 정의
    const pre_jobs = [
      {
        name: 'get_colors',
        kor_name: '컬러',
        push_data: {},
        temp_saver: (data) => {
          return data.map((d) => ({
            ...d,
            img: `https://bizest.musinsa.com/po/application/views/images/color_${d.code.toLowerCase()}.jpg`,
            checked: $scope.row.set_info.key_color == d.code
          }));
        }
      },
      {
        name: 'get_size_infos',
        kor_name: '사이즈'
      }
    ];

    // disabled된 항목 있는지 여부
    let hasDisabled = false;

    $timeout(() => {
      hasDisabled = !!$('div[ng-include] input[ng-model][ng-disabled], div[ng-include] select[ng-model][ng-disabled], div[ng-include] button[ng-model][ng-disabled]')
        .filter((idx, selector) => $(selector).attr('disabled') === 'disabled').length;
    }, 500);

    // 컬러 선택
    $scope.set_color = (cd) => {
      if ($scope.row.set_info.key_color) {
        if ($scope.row.set_info.key_color == cd) {
          $scope.pa5_job_data.get_colors.data.forEach((v) => {
            v.checked = false;
          });

          $scope.row.set_info.key_color = '';
        } else {
          $scope.pa5_job_data.get_colors.data.forEach((v) => {
            v.checked = cd == v.code;
          });

          $scope.row.set_info.key_color = cd;
        }
      } else {
        $scope.pa5_job_data.get_colors.data.forEach((v) => {
          v.checked = cd == v.code;
        });

        $scope.row.set_info.key_color = cd;
      }
    };

    $scope.opt_list = data.prodOpt.opt.filter(({ name }) => name && (name.trim() == '사이즈' || name.trim().toLowerCase() == 'size'));

    // 만일 옵션을 자동선택하지 못할 때 처리
    if ($scope.row.set_info.option && !$scope.opt_list.find(({ name }) => $scope.row.set_info.option == name)) {
      commonSVC.showMessage('경고', '옵션 정보가 변경되었습니다. 사이즈 > 옵션명 값을 다시 선택해주세요.');
      $timeout(() => {
        $scope.row.set_info.option = null;
      });
    }

    // 사이즈 테이블 생성
    $scope.makeSizeTable = () => {
      const size_data = $scope.pa5_job_data.get_size_infos.data.find(({ code }) => $scope.row.set_info.key_size_type == code);
      const opt_data = $scope.opt_list.find(({ name }) => $scope.row.set_info.option == name);

      $scope.size_data_inside = size_data.data.split(',');
      $scope.opt_data_attr = opt_data.attr.split(',');

      $scope.size_table_data = {};
      $scope.opt_data_attr.forEach(a => {
        $scope.size_table_data[a] = {};
        $scope.size_data_inside.forEach(b => {
          $scope.size_table_data[a][b] = '';
        });
      });
    };

    // 사이즈 테이블 정보가 있을때 생성하는 로직
    if ($scope.row.set_info.key_size_info) {
      $scope.size_data_inside = [];
      $scope.opt_data_attr = [];
      $scope.size_table_data = {};
      $scope.row.set_info.key_size_info.split('|').forEach(a => {
        const tmp = a.split(',');

        if (!$scope.opt_data_attr.find(b => b == tmp[0])) {
          $scope.opt_data_attr.push(tmp[0]);
        }
        if (!$scope.size_data_inside.find(b => b == tmp[1])) {
          $scope.size_data_inside.push(tmp[1]);
        }

        if (!$scope.size_table_data.hasOwnProperty(tmp[0])) {
          $scope.size_table_data[tmp[0]] = {};
        }
        $scope.size_table_data[tmp[0]][tmp[1]] = tmp[2];
      });
    }

    // 테이블 초기화
    $scope.resetSizeTable = () => {
      $scope.row.set_info.key_size_type = null;
      $scope.row.set_info.key_size_info = null;
      $scope.row.set_info.option = null;
      $scope.size_table_data = null;
    };

    $scope.pa5_job_data = {};
    pre_jobs.forEach(({ name }) => $scope.pa5_job_data[name] = { load: false, data: [] });

    const resizeEditTextarea = (id) => {
      const textarea = $(id);

      textarea.height('auto');
      const scrollHeight = textarea.prop('scrollHeight');

      if (scrollHeight < 54) {
        textarea.height(54);
      } else {
        textarea.height(scrollHeight);
      }
    };

    $timeout(function() {
      resizeEditTextarea('#key_spec_desc');
      $('#key_spec_desc').on('input', function() {
        resizeEditTextarea('#key_spec_desc');
      });
    }, 300);

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const init = async () => {

      try {
        Promise.race(pre_jobs.map(({ name, kor_name, push_data, temp_saver }) => run_pa5_jobs(name, kor_name, push_data, temp_saver)));
      } catch (e) {
        commonSVC.showMessage('실패', '메타정보 로딩에 실패하였습니다.');
      }
    };

    init();

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.size_table_data) {
        if (!Object.keys($scope.size_table_data).every(k1 => Object.keys($scope.size_table_data[k1]).every(k2 => $scope.size_table_data[k1][k2]))) {
          commonSVC.showMessage('실패', '사이즈 실측값을 모두 입력해주세요.');

          return;
        }
        $scope.row.set_info.key_size_info = Object.keys($scope.size_table_data).map(k1 => Object.keys($scope.size_table_data[k1]).map(k2 => `${k1},${k2},${$scope.size_table_data[k1][k2]}`).join('|')).join('|');
      }
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        if (hasDisabled) {
          $scope.row.set_info_template_no = '';
          commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

          return false;
        }

        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType,
          hasDisabled
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
          hasDisabled
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
