'use strict';

/**
 * Created by harry on 2017. 10. 31..
 * object array key에 해당하는 값들 총합 리턴
 */
angular.module('gmpApp')
  .filter('sumBy', function() {
    return function(data, key) {

      if (typeof (data) === 'undefined' || typeof (key) === 'undefined') {
        return 0;
      }

      return _.sumBy(data, key);
    };
  });
