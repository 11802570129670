'use strict';

angular.module('gmpApp')
  .service('delaySVC', function () {
    return {
      /**
       * 희망일배송 지연 사유 목록
       */
      hopeDateDelayReason: [
        { label: '구매자 요청으로 인한 날짜 변경', value: 'DATE_CHANGED_BY_BUYER' },
        { label: '고객부재/배송정보 오류', value: 'INVALID_ADDRESS' },
        { label: '배송 물량 초과', value: 'CAPACITY_EXCEEDED' },
        { label: '물류사/배송사 이슈', value: 'LOGISTICS_ISSUE' },
        { label: '상품 불량 및 재고 부족', value: 'SOLD_OUT' },
        { label: '판매자 출고 지연', value: 'DELAYED' },
        { label: '상품 설치 작업 중 이슈', value: 'INSTALLATION_ISSUE' },
        { label: '천재지변 ', value: 'NATURAL_DISASTER' },
      ],
      // 희망일배송 지연 사유 메시지
      updateHopeDateMessage(isLg) {
        return {
          info: `<b>아래 조건에 해당하는 경우에만 희망배송일 변경 처리가 적용됩니다.</b>
            <br />- 스마트스토어의 '판매자 희망일 도착보장' 상품 주문
            <br />- 스마트스토어의 '희망일배송' 상품 주문
            <br />  <span style="font-size:0.9em;margin-left:10px">(상품명 앞의 [희망일배송] 문구로 확인 가능)</span>
            <br />- 스마트스토어에서 결제완료 ~ 배송중 상태에 해당하는 주문
            <br />- 신규주문, 주문보류, 출고대기, 출고보류, 운송장출력, 출고완료, 배송중인 주문
            ${isLg ? '<br />* NewBest에서 전송 후 실패된 주문은 본 화면에서 선택 시 다시 전송 할 수 있습니다.</span>' : ''}`,
          fail: '희망배송일 변경 작업이 가능한 주문이 아닙니다.'
        };
      }
    };
  });