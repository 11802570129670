/**
 * Created by Ally on 2016-11-29.
 */

'use strict';

angular.module('gmpApp')
  .factory('csSVC', function (gettextCatalog) {

    return {
      templateType: [
        { label: gettextCatalog.getString('전체'), value: '' },
        { label: gettextCatalog.getString('상품문의'), value: '상품문의' },
        { label: gettextCatalog.getString('긴급메세지'), value: '긴급메세지' },
        { label: gettextCatalog.getString('상품평'), value: '상품평' }
      ],

      excelFieldList: [
        { header: '문의접수일', key: 'inq_time', select: true },
        { header: '문의수집일', key: 'wdate', select: true },
        { header: '답변전송일', key: 'send_time', select: true },
        { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
        { header: '문의 유형', key: 'inq_type', select: true },
        { header: '문의 상태', key: 'inq_status', select: true },
        { header: '주문번호', key: 'shop_ord_no', select: true },
        { header: '문의제목', key: 'inq_title', select: true, s: { alignment: { wrapText: true, vertical: 'middle' } } },
        { header: '문의내용', key: 'inq_content', select: true, s: { alignment: { wrapText: true, vertical: 'middle' } } },
        { header: '문의자ID', key: 'inq_id', select: true },
        { header: '답변제목', key: 'ans_title', select: true, s: { alignment: { wrapText: true, vertical: 'middle' } } },
        { header: '답변내용', key: 'ans_content', select: true, s: { alignment: { wrapText: true, vertical: 'middle' } } },
        { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
        { header: '온라인 상품명', key: 'shop_sale_name', select: true },
        { header: '판매자 상품코드', key: 'c_sale_cd', select: true },
      ],
      csContentPlaceholder: {
        A032: {
          상품문의: '\n답변 작성 시 최소 10자, 최대 1000자 까지 입력 가능합니다.',
        },
        A083: {
          상품문의: '\n답변 작성 시 최소 10자, 최대 1000자 까지 입력 가능합니다.',
        },
        A084: {
          상품문의: '\n답변 작성 시 최소 10자, 최대 1000자 까지 입력 가능합니다.',
        },
        A085: {
          상품문의: '\n답변 작성 시 최소 10자, 최대 1000자 까지 입력 가능합니다.',
        },
        B719: {
          상품문의: '\n답변 작성 시 최소 10자, 최대 1500자 까지 입력 가능합니다.',
        },
      },

      csContentPlaceholderFn: function (data, inq_type) {
        let returnMsg = '답변내용';

        if (this.csContentPlaceholder[data.selectData.shop_cd]) {
          returnMsg += this.csContentPlaceholder[data.selectData.shop_cd][inq_type];
        }

        return returnMsg;
      }

    };
  });
