'use strict';

angular.module('gmpApp')
  .directive('materialAddEdit', function () {
    return {
      restrict: 'E',
      templateUrl: 'views/online/product/channel/material_add_edit.html',
      replace: true,
      scope: {
        materials: '=',
        maxProdCount: '=?',
        maxMaterialCount: '=?'
      },
      controller: function($scope) {
        $scope.maxProdCount = angular.isDefined($scope.maxProdCount) ? $scope.maxProdCount : 10;
        $scope.maxMaterialCount = angular.isDefined($scope.maxMaterialCount) ? $scope.maxMaterialCount : 5;
      },
      link: function($scope) {
        const initMaterial = () => {
          return {
            name: '',
            origin: '',
            content: ''
          };
        };
        const initProd = () => {
          return {
            name: '',
            materials: [
              initMaterial()
            ]
          };
        };

        $scope.prodCount = `${$scope.materials.length}`;

        $scope.init = () => {
          $scope.materials.splice(0);
          $scope.materials.push(initProd());

          $scope.prodCount = `${$scope.materials.length}`;
        };

        $scope.range = (min, max, step = 1) => {
          const arr = [];

          for (let i = min; i <= max; i += step) {
            arr.push(i);
          }

          return arr;
        };

        $scope.addMaterial = (prodIndex) => {
          if ($scope.materials[prodIndex].materials.length >= $scope.maxMaterialCount) {
            alert('더 이상 추가할 수 없습니다.');

            return;
          }

          $scope.materials[prodIndex].materials.push(initMaterial());
        };

        $scope.deleteMaterial = (prodIndex, materialIndex) => {
          $scope.materials[prodIndex].materials.splice(materialIndex, 1);
        };

        /**
         * 함량이 변경 될 때 마다 호출 됨
         *
         * @param {Number}
         * @param {Number}
         *
         * @return {void}
         */
        $scope.changeMaterialContent = (prodIndex, materialIndex) => {
          // 소수점 받지 않음
          $scope.materials[prodIndex].materials[materialIndex].content =
            parseInt($scope.materials[prodIndex].materials[materialIndex].content);

          // 100 이상이면 100 0보다 작아지면 0으로 변경 함
          if ($scope.materials[prodIndex].materials[materialIndex].content < 0 ||
            !$scope.materials[prodIndex].materials[materialIndex].content) {
            $scope.materials[prodIndex].materials[materialIndex].content = 0;
          } else if ($scope.materials[prodIndex].materials[materialIndex].content > 100) {
            $scope.materials[prodIndex].materials[materialIndex].content = 100;
          }
        };

        $scope.$watch('prodCount', (newValue, oldValue) => {
          newValue = parseInt(newValue);
          oldValue = parseInt(oldValue);

          if (newValue > $scope.maxProdCount) {
            alert(`상품 종류는 ${$scope.maxProdCount}이하이어야 합니다.`);

            return;
          }

          if (newValue < oldValue) {
            $scope.materials.splice(newValue, $scope.materials.length);
          } else {
            for (let i = oldValue; i < newValue; i++) {
              $scope.materials.push(initProd());
            }
          }
        });

        if ($scope.materials.length === 0) {
          $scope.init();
        }
      }
    };
  });