/**
 * 해외송장출력 모달
 */
'use strict';

angular.module('gmpApp')
  .controller('printInvoiceGlobalCtrl', function (// common
    $state, $rootScope, $scope, $timeout, $filter, $uibModalInstance, gettextCatalog, data, commonSVC, workSVC, localStorageService, globalCarrList, shipmentModel
  ) {
    const INVOICE_TYPE_TEMPLATES = {
      T030: 'views/order/shipment/invoice_type/global/T030.html'
    };

    $scope.getDeliveryName = function(carr_no) {
      return globalCarrList.find(carr => carr.carr_no == carr_no).carr_name;
    };

    function init() {
      Object.assign($scope, data); // order_list, type, mode, carr_no added to scope
      if ($scope.mode === 'reprint') { // 재출력일 때는 출력할 때 선택한 해외택배사끼리 groupby로 묶음
        $scope.order_list_group = _.mapValues(_.groupBy($scope.order_list, 'global_carr_no'), values => ({ order_list: values }));
        $scope.order_list_group = _.mapValues($scope.order_list_group, values => ({ ...values, bundle_count: Object.keys(_.groupBy(values, 'bundle_no')).length }));
      } else { // 출력할 때 선택한 택배사로 출력
        $scope.order_list_group = { [$scope.carr_no]: { order_list: $scope.order_list, bundle_count: Object.keys(_.groupBy($scope.order_list, 'bundle_no')).length } };
      }

      // 출력값 기본 설정
      Object.keys($scope.order_list_group).forEach(carr_no => {
        $scope.order_list_group[carr_no].printOpt = localStorageService.get('globalPrintOpt')?.[carr_no] || {
          print_type: 'label-3',
          sale_name: 'shop_sale_name',
          print_sku_all: false,
          shipper: ''
        };
      });
    }
    init();

    /**
     * 해당 주문의 운송장을 발급받습니다.
     */
    $scope.printInvoice = async function (carr_no) {
      // 해외주문 해외운송장 출력 시 최종 출력 세팅값을 localstorage에 저장
      localStorageService.set('globalPrintOpt', { ...localStorageService.get('globalPrintOpt'), [carr_no]: $scope.order_list_group[carr_no].printOpt });
      let print_yn = false;

      try {
        $('#spinner').removeClass('hidden');

        switch (carr_no) {
          case 944: // Shopee SLS
            print_yn = !!(await getShopeeCustomLabel($scope.order_list_group[carr_no].printOpt));

            break;
        }
      } finally {
        $('#spinner').addClass('hidden');
        $scope.order_list_group[carr_no].print_yn = print_yn;
      }
    };

    async function getShopeeCustomLabel(printOpt) {
      const workResult = await Promise.all(
        _.map(
          _.groupBy($scope.order_list_group[944].order_list, order => `${order.shop_cd}||${order.shop_id}`),
          async orders => {
            try {
              const act = $scope.mode === 'reprint' ? 'GetShopeeCustomLabel' : 'GetTrackingNo';
              const params = {
                site_code: orders[0].shop_cd,
                site_id: orders[0].shop_id,
                data: {
                  bundleList: orders.map(order => order.bundle_no),
                },
              };
              const result = await workSVC.addWorkSync(act, params);

              if (result.data.Result === 'Failed') {
                return { data: { errMsg: result.data.state_msg }, orders };
              }

              return { data: result.data.data, orders };
            } catch (err) {
              return { data: { errMsg: err.data.error }, orders };
            }
          }
        )
      );

      const bundleOrderGroup = new Map();
      workResult.map(({ data, orders }) => {
        if (Array.isArray(data)) {
          // 작업성공
          data.forEach(result => {
            const additionalData = result.status != 200 ? { errMsg: result.errMsg, orders: [] } : { orders: [] };
            bundleOrderGroup.set(result.bundle_no || orders[0].bundle_no, Object.assign(result.resultData || {}, additionalData));
          });
          orders.forEach(order => {
            bundleOrderGroup.get(order.bundle_no.toString()).orders.push(order);
          });
        } else {
          // 작업실패
          orders.forEach(order => {
            bundleOrderGroup.set(order.bundle_no, { errMsg: data.errMsg });
          });
        }
      });

      const bundleOrders = Array.from(bundleOrderGroup.values());
      const successOrders = bundleOrders.filter(function (o) {
        return !o.errMsg;
      });
      const failedOrders = bundleOrders.filter(function (o) {
        return o.errMsg;
      });

      if (!successOrders.length) {
        commonSVC.showToaster('error', gettextCatalog.getString('에러'), gettextCatalog.getString('라벨출력 실패'));

        return;
      }

      if (bundleOrders.length !== failedOrders.length) {
        const printPage = await shopeeLabelPrint(successOrders, _.cloneDeep(printOpt));
        printPage.focus();
        printPage.print();
        printPage.close();
        try {
          await shipmentModel.globalOrderPrintComplete({ bundle_nos: [...new Set(successOrders.map(order => order.orders[0].bundle_no))] });
          commonSVC.showToaster(
            'success',
            gettextCatalog.getString('결과'),
            `${successOrders.length}건 성공 ${failedOrders.length}건 실패`
          );

          return true;
        } catch (error) {
          commonSVC.showToaster('error', gettextCatalog.getString('에러'), gettextCatalog.getString('라벨출력 실패'));
        }
      } else {
        commonSVC.showToaster('error', gettextCatalog.getString('에러'), gettextCatalog.getString('라벨출력 실패'));
      }
    }

    async function shopeeLabelPrint(bundleOrders, printOpt) {
      return new Promise(resolve => {
        $scope.views = [];
        $scope.orderListBundle = bundleOrders.map(bundle => bundle.shopee_tracking_number);
        $scope.views.push(INVOICE_TYPE_TEMPLATES.T030);

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }

        $('.print-wrapper,img').imagesLoaded(function () {
          $timeout(function () {
            const styles = document.getElementsByName('invoice-print-style');
            const features =
              'menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=3000,height=3000,left=300,top=300';

            const printPage = window.open('about:blank', 'shipmentPrint', features); // 새창을 열어줌

            if (!printPage || printPage.closed || typeof printPage.closed == 'undefined') {
              commonSVC.showToaster('error', gettextCatalog.getString('송장출력 실패'), '팝업 허용 후 다시한번 출력 작업을 해주시기 바랍니다.');

              return resolve();
            }

            const styleInnerText = [];
            angular.forEach(styles, function (style) {
              styleInnerText.push(style.innerText);
            });

            printPage.document.open();
            printPage.document.write(
              '<html><head>' + '<style>' + styleInnerText.join('') + '</style>' + '</head>\n<body>'
            );
            const extraPage = printOpt.print_type === 'label-6';
            const tableLimit =
              printOpt.print_type === 'label-6'
                ? {
                  first: 326,
                  default: 392,
                }
                : {
                  first: 98,
                  default: 164,
                };

            // 새 페이지로 넘어갈 때, 사용할 div를 미리 선언
            const breakHere = `<div class="print-content-example">
            <div class="breakhere"></div>
            <div class="print-wrapper${extraPage ? '-extra' : ''}" style="margin: 2.67mm 4mm 2.67mm 4mm;">
              <div class="print-wrapper-T030">`;

            bundleOrders.map((bundle, bundleIdx) => {
              // 바코드 먼저 생성해줌.
              JsBarcode(`.barcode.key_T030_${bundle.shopee_tracking_number}`, bundle.shopee_tracking_number, {
                format: 'CODE128',
                width: (2.2 * 15) / bundle.shopee_tracking_number.length,
                height: 44,
                font: 'sans-serif',
                fontSize: 9,
              });

              // 주문 선가공 처리
              const orders = bundle.orders.map((order, orderIdx) => {
                let item_type = 'shop_sale_name';
                let item_name = order.shop_sale_name;
                let opt_name = order.shop_opt_name ? 'Option: ' + order.shop_opt_name : '';
                let sku_cd = '';
                // 출력되는 상품명/옵션명 세팅
                if (printOpt.sale_name === 'shop_opt_name' && order.shop_opt_name) {
                  item_type = printOpt.sale_name;
                  item_name = order.shop_opt_name;
                  opt_name = '';
                } else if (printOpt.sale_name === 'prod_name' && (order.set_name || order.prod_name)) {
                  item_type = printOpt.sale_name;
                  item_name = order.set_cd ? order.set_name : order.prod_name;
                  opt_name = '';
                }
                // 출력되는 SKU코드/세트코드 세팅
                if (order.shop_sku_cds) {
                  if (printOpt.print_sku_all) {
                    sku_cd = `[SKU#: ${order.shop_sku_cds.map(obj => obj.shop_sku_cd).join(', ')}]`;
                  } else if (order.set_cd) {
                    sku_cd = `[SET#: ${order.set_cd}]`;
                  } else {
                    sku_cd = `[SKU#: ${order.shop_sku_cds[0].shop_sku_cd}]`;
                  }
                }

                return {
                  ord_idx: orderIdx + 1,
                  item_type,
                  item_name,
                  opt_name,
                  sku_cd,
                  qty: order.sale_cnt,
                };
              });

              let tableLength = 0;
              let tableHeight = tableLimit.first;
              let ordQty = 0;
              const totalQty = orders.reduce((total, order) => total + order.qty, 0);
              const now_time = moment(bundle.orders[0].now_time).format('YYYY.MM.DD HH:mm');
              const hasCodeData =
                bundle.first_mile_name &&
                bundle.last_mile_name &&
                bundle.lane_code &&
                bundle.service_code !== 'no-code';

              const barcode = document.getElementById(bundle.shopee_tracking_number).innerHTML;

              // header에는 주문 기초정보, table 기본 양식이 들어감
              const header = (isFirstPage) => `
                  <div class="p-tb f-9 fb" style="top: 1mm; left: 3mm">
                    Order ID:
                    <span>${bundle.id.split('|')[0]}</span>
                  </div>
                  ${
  isFirstPage
    ? '<div class="more">'
    : `${hasCodeData ? '<div class="shopCode">' : ''}
                    <!-- 바코드 -->
                    <div class="p-tb barcode-position">${barcode}</div>
                    `
}
                    <!-- 배송정보 -->
                    <div class="p-tb f-9 fb">
                    ${
  !isFirstPage && hasCodeData
    ? `
                      <div class="p-tb shopCode flex-space-around" style="left: 4mm; top: 22mm; width: 94mm;">
                        <div class="flex">
                          <div class="border-box p-2">${bundle.first_mile_name}</div>
                          <div class="border-box p-2" style="border-left: none;">
                            ${bundle.last_mile_name}
                          </div>
                        </div>
                        <div class="p-2 f-12">${bundle.goods_to_declare ? 'T' : 'P'}</div>
                        <div class="border-box p-2 f-10">${bundle.lane_code}</div>
                        <div class="p-2 f-12">${bundle.service_code}</div>
                      </div>
                      `
    : ''
}
                    <div class="p-tb e01" style="width: 94mm">
                      <!-- 수취인 -->
                      <div>Ship to: ${bundle.receiver_information.receiver_name}</div>
                      <!-- 발송인 -->
                      <div>Shipper: ${printOpt.shipper}</div>
                      ${isFirstPage || hasCodeData ? '' : `<div>Shipping carrier: ${bundle.carrier_name}</div>`}
                    </div>
                  ${isFirstPage || hasCodeData ? '</div>' : ''}
                  </div>
                  ${
  isFirstPage
    ? `<div class="moreCon p-tb tc f-10">
                    연 속
                    <div>(${bundle.shopee_tracking_number})</div>
                  </div>`
    : ''
}
                  <div class="${isFirstPage ? 'moreCon' : ''} orderList p-tb" style="width: 94mm">
                  `;

              // HTML 변수들 선언 (함수로 선언하는 이유는, ordQty 등 가변 값을 보여주기 위해서임)
              const tableHeader = `<div class="table-header f-8">
                <div class="num">No.</div>
                <div class="itemName">Item / SKU#</div>
                <div class="qty">Qty</div>
              </div>`;

              // footer에는 page넘버, Qty등의 값이 들어감.
              const footer = () => `</div>
              <div class="p-tb fb f-8 tc page-count${extraPage ? '-extra' : ''}">
                ${bundleIdx + 1}/${bundleOrders.length}</div>
                <div class="p-tb tc fb print-count${extraPage ? '-extra' : ''}">
                  <div class="f-10">Total qty: ${ordQty}/${totalQty}</div>
                  <div class="f-5">Printed at ${now_time}</div>
                </div>
              </div></div></div>`;

              // page 입력 시작
              printPage.document.write(breakHere);
              printPage.document.write(header(false));
              printPage.document.write(tableHeader);
              let break_next = false;
              orders.map(order => {
                const rowHtml = `<div
                class="table-row f-10"
                ng-repeat="order in bundle.orders"
                id="order-row-${bundleIdx}-${order.ord_idx}"
              >
                <div class="num">${order.ord_idx}</div>
                <div class="itemName f-8">
                  ${order.item_name}
                  <br ng-if="order.item_type === 'shop_sale_name' && order.opt_name" />
                  ${order.opt_name} ${order.sku_cd}
                </div>
                <div class="qty">${order.qty}</div>
              </div>`;
                printPage.document.write(rowHtml);
                const tableRow = printPage.document.getElementById(`order-row-${bundleIdx}-${order.ord_idx}`);
                tableLength += tableRow.offsetHeight;
                // row를 추가했는데 만약 페이지가 넘치면 해당 row 삭제 후 페이지 넘김 처리
                // 만약에 단일 row가 limit보다 줄이 길다면 그냥 출력하도록 함.
                if (tableLength > tableHeight && tableLength !== tableRow.offsetHeight) {
                  tableRow.parentNode.removeChild(tableRow);
                  tableLength = 0;
                  tableHeight = tableLimit.default;
                  printPage.document.write(footer());
                  printPage.document.write(break_next ? breakHere : breakHere.replace('class="breakhere"', ''));
                  break_next = !break_next;
                  printPage.document.write(header(true));
                  printPage.document.write(tableHeader);
                  printPage.document.write(rowHtml);
                  tableLength += printPage.document.getElementById(`order-row-${bundleIdx}-${order.ord_idx}`)
                    .offsetHeight;
                }
                ordQty += order.qty;
              });
              printPage.document.write(footer());
            });
            printPage.document.write('</body></html>');
            printPage.document.close();

            $(printPage.document.getElementsByClassName('print-wrapper')).imagesLoaded(function () {
              resolve(printPage);
            });
          }, 3000);
        });
      });
    }

    $scope.close = function () {
      $uibModalInstance.close();
    };
  });