/**
 * 아주 기본적인 지시자만 이 파일에 모은다.
 *
 * 2016-04-13 : 매튜
 *
 */
'use strict';

angular.module('gmpApp')

  // Handle global LINK click
  .directive('a', function() {
    return {
      restrict: 'E',
      link: function(scope, elem, attrs) {
        if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
          elem.on('click', function(e) {
            e.preventDefault(); // prevent link click for above criteria
          });
        }
      }
    };
  });
