'use strict';

/**
 * 주문복사 컨트롤러
 */
angular.module('gmpApp')
  .controller('OrderShipmentOrderCopy', function ($scope, $uibModalInstance, data, settings, $rootScope, commonSVC, shipmentModel, gettextCatalog, $timeout) {
    $scope.copy = { type: data.selectedShopType !== 'domestic' };
    $scope.ebaydepot_yn = data.ebaydepot_yn;
    $scope.selectList = data.selectList;
    $scope.selectedShopType = data.selectedShopType || 'domestic';
    $scope.isResend = data.isResend;
    $scope.makeBundle = { type: false };

    /** 복사 */
    $scope.ok = async () => {
      data.keepSiteCode = $scope.copy.type;
      data.makeBundle = $scope.makeBundle.type;

      try {
        const res = await shipmentModel.copy(data);

        if (res.data && res.data.result == 'success') {
          commonSVC.showToaster('success', '성공', '주문 복사에 성공하였습니다.\n신규주문 메뉴에서 확인하시기 바랍니다.');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '주문 복사에 실패하였습니다.');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data?.messages[0] || '주문 복사에 실패하였습니다.');

        // 스피너 계속 돌게 처리되어 있어서 스피너 해제처리 추가
        $scope.waitBtnDisableFlag = false;
        $timeout(() => {});
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });