'use strict';

angular.module('gmpApp')
  .controller('CateApplyEachProductCtrl', function ($scope, data, $uibModalInstance, categoryModel, commonSVC) {
    $scope.onSubmit = _.debounce(async () => {
      try {
        $scope.waitBtnDisableFlag = true;
        await categoryModel.applyBatchEachProduct(data);
        $scope.waitBtnDisableFlag = false;
        commonSVC.showToaster('success', '성공', '카테고리 수정에 성공하였습니다.');

        $uibModalInstance.dismiss();
      } catch (err) {
        $scope.waitBtnDisableFlag = false;
        commonSVC.showToaster('error', '실패', '카테고리 수정에 실패하였습니다.');
      }
    }, 3000);

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
