'use strict';

angular.module('gmpApp')
  .controller('templateCtrl', function ($scope, $rootScope, $timeout, $state, $stateParams, $window, $uibModalInstance, commonSVC, templateSVC, templateModel, onlineProductModel, settings, data) {

    $rootScope.initView = false;    //템플릿관리 등록 초기화면 여부
    $scope.saveEventData = {};  // 템플릿 저장 데이터
    let confirm_modal = true;   //저장 후 컨펌모달 여부
    const modal_type = data.type;   //템플릿 상세, 온라인상품 템플릿 상세 구분 값
    const userInfo = $rootScope.user_profile; // 사용자 정보
    let updated = false;

    loading(false);

    function setformListener(e) {

      if (e.data.updated === false) {
        updated = true;
      }

      $('.box-loading').addClass('hidden'); //로딩중

      $('#setForm').css('height', event.data.height + 200);

      //저장, 수정 버튼 누를시 데이터 저장
      if (event.data.action == 'saved') {
        $scope.saveEventData = event.data;
        const param = {
          //셋폼 url
          url: event.data.result
        };

        //template 테이블에 insert한후 등록된 number를 가져온다.
        templateModel.insert($scope.TemplateData)
          .then(function () {
            return templateModel.detail(param);
          })
          .then(function (state, param) {
            if (state !== 'success') {
              return;
            }

            const templateData = JSON.parse(param.results.rows);
            const summaryData = templateSVC.getSummaryObj($stateParams.shop_cd, templateData);

            // 템플릿 수정인경우에만 confirm 모달 띄움
            if ($stateParams.type == 'add') {
              prodStatusUpdate(templateData, summaryData, false);
            } else if (!updated) {
              prodStatusUpdate(templateData, summaryData, false);
            } else {
              const title = '템플릿 변경내용 확인';
              const contents = "템플릿에 변경된 내역이 있습니다.<br>해당 템플릿이 설정된 온라인 상품을 '수정대기' 상태로 변경하시겠습니까?<br>수정대기로 변경된 온라인 상품은 수정전송하여야 실제 쇼핑몰에 수정된 정보로 반영됩니다.";
              const buttons = [
                {
                  name: '예',
                  next: function () {
                    // 템플릿이 적용된 상품 수정대기로 변경후 템플릿 정보 수정.
                    $timeout(function () {
                      prodStatusUpdate(templateData, summaryData, true);
                    });
                  }
                },
                {
                  name: '아니요',
                  next: function () {
                    // 템플릿 정보 수정.
                    $timeout(function () {
                      prodStatusUpdate(templateData, summaryData, false);
                    });
                  }
                }
              ];

              commonSVC.showMultiButton(title, contents, buttons);
            }
          });
      }

      //모달 닫기시
      if (event.data.action == 'updated') {
        $uibModalInstance.close('success');
        $window.removeEventListener('message', setformListener, false);
      }
    }

    //템플릿 등록
    if ($stateParams.type == 'add') {
      const param = {
        shop_id: $stateParams.shop_id,
        shop_cd: $stateParams.shop_cd,
        shop_name: $stateParams.shop_name
      };

      makeSetForm('', $stateParams.type, param);
    }
    //템플릿 수정
    else {
      makeSetForm(data.uuid, $stateParams.type, data);
    }

    //모달 닫기
    $scope.close = function () {
      //2018-03-14 ally 상세보기용일때 컨펌창 없애기위해 추가
      if (data.isEdit === false) {
        $uibModalInstance.dismiss('cancel');
      } else {
        $('#setForm')[0].contentWindow.postMessage({
          action: 'updated'
        }, '*');
      }
    };

    /**
     * 온라인상품 상태 업데이트
     */
    function prodStatusUpdate(jsonobj, summary_obj, prodUpdateYN) {
      if (prodUpdateYN) {
        const parmas = { template_no: jsonobj.item.template_code };

        onlineProductModel.setStateByTemplate(parmas)
          .then(function () {
            updateTemplate(jsonobj, summary_obj);
          })
          .catch(function () {
            commonSVC.showToaster('error', '실패', '상태변경에 실패하였습니다');

            return false;
          });
      } else {
        updateTemplate(jsonobj, summary_obj);
      }
    } // END function

    /**
     * 템플릿 업데이트
     */
    function updateTemplate(jsonobj, summary_obj) {
      templateModel.edit(jsonobj, summary_obj, function (state, data) {
        if (state == 'success') {
          if ($stateParams.type == 'add') {
            let save_type = '';

            if ($scope.saveEventData.save_type == 'saved') {
              save_type = '등록';
            } else if ($scope.saveEventData.save_type == 'updated') {
              save_type = '수정';
            }

            if ($stateParams.type2 == 'ol') {
              commonSVC.showToaster('success', '', `템플릿이 ${save_type} 되었습니다.`);

              //온라인상품 템플릿 리스트 새로고침
              $rootScope.$broadcast('templateRefresh');

              commonSVC.showConfirm(`${save_type}에 성공했습니다.쇼핑몰 상품 등록으로 이동하시겠습니까?`, '', function (confirm) {
                if (confirm) {
                  $scope.initView = true;

                  $state.go('main.online_product_add');
                  $window.removeEventListener('message', setformListener, false);
                }
              });
            } else {
              commonSVC.showToaster('success', '', `템플릿이 ${save_type} 되었습니다.`);

              commonSVC.showConfirm(`${save_type}에 성공했습니다.\n템플릿 목록으로 이동하시겠습니까?`, '', function (confirm) {
                if (confirm) {
                  const param = {
                    value: 'true'
                  };

                  $scope.initView = true;
                  $state.go('main.setForm_template_list', param);
                  $state.reload();
                  $window.removeEventListener('message', setformListener, false);
                }
              });
            }
          } else {
            if (modal_type == 'olProd') {
              commonSVC.showToaster('success', '', '템플릿이 수정 되었습니다.');
              commonSVC.showConfirm('정보가 수정되었습니다.', '', function (confirm) {
                if (confirm) {
                  $uibModalInstance.close('success');
                  $window.removeEventListener('message', setformListener, false);
                } else {
                  return false;
                }
              });
            } else {
              commonSVC.showToaster('success', '', '템플릿이 수정 되었습니다.');
              if (confirm_modal == true) {
                commonSVC.showConfirm('수정에 성공했습니다.\n템플릿 목록으로 이동하시겠습니까?', '', function (confirm) {
                  if (confirm) {
                    $uibModalInstance.close('success');
                    $window.removeEventListener('message', setformListener, false);
                  } else {
                    return false;
                  }
                  confirm_modal == false;
                });
              } else {
                confirm_modal = false;
                $uibModalInstance.close('success');
                $window.removeEventListener('message', setformListener, false);
              }
            }
          }
        } else {
          $scope.submitDisabled = false;
          commonSVC.showToaster('error', '', data);
        }
      });
    } // END function

    /*
     * 셋폼 생성
     * 2018-09-18 - Alvin
     * @param number, type, param
     */
    function makeSetForm(number, type, param) {
      // 로컬스토리지 셋폼 키
      const localKey = `gmp_setForm_token_${data.shop_cd}_${data.shop_id}_${userInfo.sol_no}`;

      // 토큰이 없거나 유효시간이 지난 경우 재발급 함
      if (!localStorage[localKey] || JSON.parse(localStorage[localKey]).timestamp <= new Date().getTime()) {
        // 셋폼 토큰 발급
        templateModel.createToken(param, function (state, data) {
          if (state === 'success') {
            //2018-03-14 ally 바로 열려질 탭 추가
            if (type != 'add') {
              data.tab = data.tab ? data.tab : 0;
            }
            const setFormToken = data.token;
            const timeSet = 1000 * 60 * 60 * 24; // 24시간 설정
            const record = { value: setFormToken, timestamp: new Date().getTime() + (timeSet - 10000) };

            localStorage.setItem(localKey, JSON.stringify(record));

            setFormLoad(number, param, localKey);

          } else {
            commonSVC.showToaster('error', '', '토큰생성 실패');
          }
        });
      } else {
        setFormLoad(number, param, localKey);
      }
    }

    /*
     * 셋폼 url 추가
     * 2018-09-18 - Alvin
     * @param number, param
     */
    function setFormLoad(number, param, localKey) {
      const setFormToken = JSON.parse(localStorage[localKey]).value;
      const url = `${settings.setFormUrl}/client/1/siteform?token=${setFormToken}&site_code=${param.shop_cd}&id=${number}&title=` + '템플릿 등록' + '&submit=' + '등록하기' + '&save_popup=off' + `&site_name=${param.shop_name}`;

      setTimeout(function () {
        $('#setForm').removeClass('hide');
        $('#setForm').attr('src', url);
      }, 0);
    } // END function

    /*
     * 로딩이미지
     * 2018-09-18 - Alvin
     * @param complete
     */
    function loading(complete) {
      if (complete) {
        $('.box-loading').addClass('hidden'); //로딩중
      } else {
        $('.box-loading').removeClass('hidden'); // 로딩완료
      }
    } // END function

    //셋폼 이벤트 리스너
    $window.addEventListener('message', setformListener);
  });
