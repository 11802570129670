/**
 * Created by playauto on 2017-06-15. Jean
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderCustomerInquiryAnswerCtrl', function ($scope, data, template, $uibModalInstance, customerInquiryModel, commonSVC, workSVC, $q, $timeout, userInfo) {

    //------------------------
    // 변수 설정
    //------------------------
    //$scope.numbers = data.number; //선택한 문의 number 배열
    $scope.type = data.type;
    $scope.templateList = template?.data.results || null; // 답변 템플릿 List
    $scope.selectedTemplate = '0'; // 선택한 template index
    $scope.inq_uniq = data.inq_uniq;
    $scope.cnt = $scope.inq_uniq.length || 0;
    $scope.answerAndSend = false; // 답변 저장과 동시에 전송
    $scope.answerData = {
      inq_uniq: data.inq_uniq,
      ans_title: 'Re:문의제목',
      ans_content: ''
    };
    $scope.title = data.title;
    $scope.btnTitle = '답변 전송';

    switch ($scope.type) {
      case 'delete':
        $scope.btnTitle = '삭제';
        break;
      case 'answer':
        $scope.btnTitle = '답변 작성 완료';
        break;
      case 'confirm':
        $scope.btnTitle = '답변 확인 완료';
        break;
    }

    /**
    * 답변 템플릿 선택시
    * */
    $scope.changeTemplate = function (template_no) {
      const template = _.find($scope.templateList, function (t) {
        return t.template_no === template_no;
      });

      $scope.answerData.ans_title = template.template_name;
      $scope.answerData.ans_content = template.template_data;
    };

    /**
    * 답변 템플릿 관리 버튼 클릭시
    * */
    $scope.answerTemplate = function () {
      const resolve = {};
      const modal = commonSVC.openModal('lg', resolve, 'OrderCustomerInquiryAnswerTempListCtrl', 'views/order/customer_inquiry/answer_template_list.html');

      modal.result.then(function () {
        customerInquiryModel.loadAnswerTemplate().then(function(data) {
          $scope.templateList = data.data.results;
        }).catch(() => {
          commonSVC.showMessage('', '새로운 템플릿 리스트를 불러오지 못했습니다.');
        });
      });
    };

    // 전송
    const sendAnswer = async () => {

      // 답변 전송 권한 확인
      if (commonSVC.checkPermission('inquiry.roles.sendCs', userInfo.permission) === false) {
        return false;
      }

      const params = {
        numbers: data.inq_uniq
      };

      try {
        const anHttpPromise = workSVC.addWorkSelect('SEND_CS', params)
          .then(function(result) {
            return result;
          })
          .catch(function(err) {
            return err;
          });

        await $q.all(anHttpPromise);
        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', '답변 전송 실패');
      }
    };

    // 확인 처리
    const confirmAnswer = async () => {
      try {
        await customerInquiryModel.apply({ inq_uniq: data.inq_uniq });
        commonSVC.showToaster('success', '성공', 'CS 확인에 성공하였습니다.');
        $uibModalInstance.close();
      } catch (e) {
        commonSVC.showToaster('error', '실패', 'CS 확인에 살패하였습니다.');
      }
    };

    // 삭제
    const deleteAnswer = async () => {
      try {
        await customerInquiryModel.delete({ inqList: data.inq_uniq });
        commonSVC.showToaster('success', '성공', '삭제 처리 성공');
        $uibModalInstance.close();
      } catch (e) {
        commonSVC.showToaster('fail', '실패', '삭제 처리 실패');
      }
    };

    // 답변처리
    const answer = () => {
      if ($scope.answerForm.$valid) {
        // 답변 등록

        try {
          commonSVC.showConfirm('저장', '답변내용을 저장하시겠습니까?\n 답변은 전송전까지 개별로 수정 가능합니다.', function (confirm) {
            if (confirm) {
              customerInquiryModel.answer($scope.answerData)
                .then(function () {
                  if ($scope.answerAndSend) {
                    $timeout(() => {
                      sendAnswer();
                    }, 500);
                  }
                }).then(function() {
                  commonSVC.showToaster('success', '성공', '답변을 성공적으로 저장하였습니다.');
                }).catch(() => {
                  commonSVC.showToaster('error', '실패', '답변 저장에 실패하였습니다.');
                });
              $uibModalInstance.close();
            }
          });
        } catch (e) {
          commonSVC.showToaster('error', '실패', '답변 저장에 실패하였습니다.');
        }
      }
    };
    $scope.submit = () => {
      switch ($scope.type) {
        case 'answer': {
          answer();

          break;
        }
        case 'confirm': {
          confirmAnswer();

          break;
        }
        case 'delete': {
          deleteAnswer();

          break;
        }
        case 'send': {
          sendAnswer();

          break;
        }
      }
    };

    $scope.checkChange = function() {
      $scope.answerAndSend = !$scope.answerAndSend;
    };

    /**
    * 취소
    */
    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

  });
