'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B878-Ctrl', function ($scope, $rootScope, siteInfo, shopAccountModel, onlineProductModel, commonModel, onlineProductSVC, commonSVC, $timeout, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.openTemplate = _.cloneDeep(data.openTemplate);

    $scope.byteCheck = onlineProductSVC.byteCheck;
    $scope.setInfoList = [];
    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo;
      const domain = siteInfo.B878.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel);
    }

    function init() {
      shopAccountModel.load({ shop_cd: 'B878', shop_id: $scope.shop_data.shop_id })
        .then(function (res) {
          $scope.userInfo = res.data.results;
          $scope.data.etc1 = $scope.userInfo.etc1;
          $scope.row.set_info.key_describeTool = $scope.row.set_info.key_describeTool || [];

          requestPA('etc.load_describe_code_list')
            .then(function(res) {
              res.data.data.forEach(data => {
                $scope.setInfoList.push({
                  code: data.describeCode,
                  title: data.describeTitle,
                  value: $scope.row.set_info[`key_describeCode_${data.describeCode}`] || ''
                });
              });

              $timeout(function() {
                $scope.setInfoList.forEach(i => {
                  resizeEditTextarea(`#shinsegae_key_${i.code}`);
                  $(`#shinsegae_key_${i.code}`).on('input', function() {
                    resizeEditTextarea(`#shinsegae_key_${i.code}`);
                  });
                });
              }, 100);
            });
        });
    }

    $scope.mobileMNChange = (nm) => {
      if (onlineProductSVC.byteCheck($scope.row.set_info[`${nm}`]) > 20) {
        $scope.row.set_info[`${nm}`] = commonSVC.cutByByte($scope.row.set_info[`${nm}`], 20);
      }
    };

    const resizeEditTextarea = (id) => {
      const textarea = $(id);
      textarea.height('auto');
      const scrollHeight = textarea.prop('scrollHeight');

      if (scrollHeight < 54) {
        textarea.height(54);
      } else {
        textarea.height(scrollHeight);
      }
    };

    $scope.goodsNMChange = (nm) => {
      if ($scope.row.set_info[`${nm}`].length > 50) {
        $scope.row.set_info[`${nm}`] = $scope.row.set_info[`${nm}`].substr(0, 50);
      }
    };

    init();

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      // 사용할 때 주의사항 추가항목에서 처리

      $scope.setInfoList.forEach(data => {
        $scope.row.set_info[`key_describeCode_${data.code}`] = data.value;
        $scope.row.set_info.key_describeTool.push(data.code);
      });

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
