'use strict';

/**
 * 샤피 픽업코드 발급 모달
 */
angular.module('gmpApp')
  .controller('ShopeeCreatePickupController', function ($rootScope, $scope, data, commonSVC, workSVC, $uibModalInstance, gettextCatalog) {

    $scope.addrTemplateList = data.addrTemplateList;
    $scope.matchShopAddrList = data.matchShopAddrList;
    $scope.pickupAddrInfo = data.pickupAddrInfo;
    $scope.printType = data.printType;

    $scope.orderList = data.orderList;
    const pa_shop_cd = $scope.orderList[0].pa_shop_cd;

    $scope.model = {
      pickup_date: '',
      pickup_from_location: '', // 샤피 픽업 주소
      pickup_account: ''
    };

    async function init() {
      $scope.shopee_channel_list = data.channelList.filter(item => item.pa_shop_cd === pa_shop_cd);
      $scope.model.pickup_account = $scope.shopee_channel_list.find(channel => channel.shop_cd === $scope.pickupAddrInfo.shop_cd && channel.shop_id === $scope.pickupAddrInfo.shop_id);
      $scope.model.pickup_from_location = formatAddressOption($scope.pickupAddrInfo.fromAddr);
    }

    init();

    function formatAddressOption(address) {
      return `${address.template_name} / ${getCtryName(address.ctry_cd)} / ${address.tel} / (${address.zipcd}) / ${address.addr1} ${address.addr2 || ''}`;
    }

    function getCtryName(ctry_cd) {
      return $rootScope.countryList.find(ctry => ctry.ctry_cd.toUpperCase() === ctry_cd.toUpperCase())?.ctry_name || '';
    }

    $scope.submit = function () {
      let data = {};
      let action = '';

      $scope.isLoading = true;
      $('#spinner').removeClass('hidden');

      if ($scope.printType === 'reprint') {
        action = 'GetPickupLabel';

        data = {
          site_code: $scope.model.pickup_account.shop_cd,
          site_id: $scope.model.pickup_account.shop_id,
          data: {
            selectList: $scope.orderList.map(order => order.pa_bundle_no),
            isReprint: true
          }
        };
      } else {
        if (!$scope.model.pickup_date) {
          commonSVC.showMessage('픽업 요청일을 선택해 주세요.');

          $scope.isLoading = false;

          return;
        }

        action = 'GetPickupCode';

        const { template_name, ctry_cd, zipcd, tel, state, city, addr1, addr2 } = $scope.pickupAddrInfo.fromAddr;

        const arr = [];

        if (addr2) {
          arr.push(addr2);
        }
        if (addr1) {
          arr.push(addr1);
        }
        if (state) {
          arr.push(state);
        }
        if (city) {
          arr.push(city);
        }
        if (ctry_cd) {
          arr.push(ctry_cd.toUpperCase());
        }

        $('#spinner').removeClass('hidden');

        data = {
          site_code: $scope.model.pickup_account.shop_cd,
          site_id: $scope.model.pickup_account.shop_id,
          data: {
            pickup_date: $scope.model.pickup_date,
            selectList: $scope.orderList.map(order => +order.pa_bundle_no),
            pickup_information: {
              name: template_name,
              address: arr.join(', '),
              zipcode: zipcd,
              phone: tel
            }
          }
        };
      }

      workSVC.addWorkSync(action, data).then(res => {
        const result = res.data;

        if (result.Result === 'Complete') {
          const numFail = result.state_msg.match(/\d+/g)[1];
          commonSVC.showToaster(+numFail ? 'error' : 'success', '결과', result.state_msg);

          if (result.pickup_label) {
            const newWin = window.open(result.pickup_label);
            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              return commonSVC.showToaster('error', gettextCatalog.getString('팝업 차단을 해제해주세요.'), '');
            }
          }

        }
      }).finally(() => {
        $scope.isLoading = false;
        $('#spinner').addClass('hidden');
        $uibModalInstance.close();
      });

    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });