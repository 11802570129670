'use strict';

/**
 * Created by ally on 2017. 6. 21..
 */
angular.module('gmpApp')
  .controller('memoCtrl', function ($scope, $rootScope, $uibModalInstance, data) {
    $scope.data = data;

    // 모달 닫음
    $scope.cancel = function () {
      const btnElem = $(`button[ng-click="grid.appScope.openOrderCS('${data.uniq}')"]`).length
        ? $(`button[ng-click="grid.appScope.openOrderCS('${data.uniq}')"]`) : $(`button[ng-click="openOrderCS('${data.uniq}')"]`);

      //부모창 리스트에 해당 주문 메모 class 변경
      if ($rootScope.memoCount > 0) {
        btnElem.removeClass('btn-default');
        btnElem.addClass('bg-orange-300');
      } else {
        btnElem.removeClass('bg-orange-300');
        btnElem.addClass('btn-default');
      }

      $uibModalInstance.close('cancel');
    };

  });
