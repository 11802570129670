'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A027-Ctrl', function ($scope, $timeout, commonSVC, A027categorySVC, categoryModel, errorSVC) {
    // 카테고리 정보 www 정보로 불러오는걸로 수정. 2018-02-19 rony
    $scope.CateData = angular.copy(A027categorySVC.category_data);
    $scope.cert_type = angular.copy(A027categorySVC.cert_type);
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    $scope.sel_category = ['', '', '', ''];
    $scope.cate = [[], [], [], []];

    let save_flag = false;    //카테고리 선택여부

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await $timeout(() => {
          $scope.cate[idx + 1] = res.data.result;
          $scope.loading = true;
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await $timeout(() => {
          $scope.loading = true;
        });

        return false;
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      let category_code = '';

      //카테고리 마지막 분류까지 선택여부
      if (save_flag == false) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      } else {
        $scope.CateData.detailData.cate0 = '';

        for (let i = 0; i < 4; i++) {
          $scope.CateData.detailData[`cate${i}`] = '';

          if ($scope.sel_category[i].shop_cate_see_cd) {
            const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

            category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
          }
        }

        //detailData에 수정된 데이터를 넣기위해
        //category_names 초기화
        $scope.CateData.detailData.category_names = $scope.category_names;
        //category_code 초기화
        $scope.CateData.detailData.category_code = category_code;

        if ($scope.form1.$valid) {
          const param = {
            // "hscode": $stateParams.hscode,
            site_code: $scope.matchingData.site_code,
            site_id: $scope.matchingData.site_id,
            category_code: category_code,
            category_codes: 'test',
            category_names: $scope.category_names,
            categoryId: $scope.matchingData.categoryId,
            detailData: { ...$scope.CateData.detailData },
            country: 'kr',
            //국내/해외
            type: 0,
            set_all_shop: $scope.check.set_all_shop
          };

          /**
           * 2018-03-09 Roy
           * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
           */
          if (event && event.type === 'online') {
            $scope.returnAct(param);
          } else if ($scope.matchingData.isOnlineAdd === true) {
            await $timeout(async () => {
              try {
                const res = await categoryModel.shopInfoDetailEdit(param);

                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);
              } catch (err) {
                commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
              }
            });
          } else {
            param.isApply = true;

            $scope.applyCate(param);
          }
        }
      }
    };

    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.detailData = addInfo;

      if (!$scope.CateData.detailData.category_code) {
        return false;
      }

      await setCategory($scope.CateData.detailData.category_code);
    };

    const init = async () => {
      // 카테고리 0뎁스 가져오기
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A027', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      $timeout(async () => {
        $scope.cate[0] = res.data.result;

        //상품에 매칭된 shop_cate가 있을경우
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A027categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
