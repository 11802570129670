'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P037-Ctrl', function ($scope, $rootScope, $timeout, data, systemList, templateList, $uibModalInstance, commonSVC, shopAccountModel, onlineProductModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.cate_cd = data.data.cate_cd;
    $scope.formHolder = {};

    // 환경부담금 필요 카테고리
    $scope.isManufactureCate = ['2984', '3955', '3956', '3957', '3418', '3958', '3582',
      '3301', '3973', '3974', '3976', '3979', '2980', '3236', '3545', '3546', '2060',
      '2061', '3426', '2064', '2065', '3547', '2068', '3015', '3016', '3017', '3018',
      '3076', '3445', '3446', '3447', '2787', '2788', '2789', '2957', '3470', '3278',
      '3731', '2891', '3477', '3302', '2797', '2575', '2587', '2591', '2588', '2597',
      '2593', '2589', '3237', '3511', '4417', '2592', '3803', '2020', '2021', '3284',
      '3195', '3196', '3197', '2897', '2009', '3205', '4388', '2576', '2577', '2647',
      '2578', '2581', '2939', '2962', '3454', '3583', '4148', '4270', '3488', '3486',
      '3489', '3490', '3513', '3802', '3491', '3492', '3085', '2769', '3493', '3494',
      '3283', '3496', '3498', '3497', '3609', '3610', '2623', '2621', '3605', '2622',
      '4329', '4328', '2915', '3474', '2049', '3473', '4155', '4258', '2048', '2050',
      '3471', '3509', '3460', '4424', '4425', '4422', '4423', '3314', '3510', '2075',
      '3863', '3874', '2085', '3864', '3865', '3867', '3868', '2080', '2081', '2144',
      '2163', '3949', '3950', '3951', '3514', '2981', '3953', '2732', '2998', '3993',
      '3589', '2999', '2741', '3228', '2733', '2740', '2987', '2743', '3980', '3981',
      '3982', '3983', '2996', '3291', '3292', '3959', '3960', '3961', '3962', '3963',
      '4042', '2131', '3296', '2995', '4334', '2994', '3298', '3181', '2416', '3184',
      '4390', '3584', '4044', '4050', '3259', '2997'];

    // 포장상태 필요 카테고리
    $scope.packBoxCate = ['1012', '1013', '1148'];

    const initCheck = () => {
      $scope.vgaCheck = $scope.cate_cd.includes('4_1016');
      $scope.cpuCheck = $scope.cate_cd.includes('4_1020_2054');
      $scope.oddCheck = $scope.cate_cd.includes('4_1147') && !$scope.cate_cd.includes('3238');
      $scope.referCheck = $scope.cate_cd.includes('89_1440');
      $scope.printCheck = $scope.cate_cd.includes('11_1055');

      if ($scope.referCheck) {
        $scope.row.set_info.key_ResaleType = $scope.row.set_info?.key_ResaleType || 'R';
      }
    };

    initCheck();

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if (!$scope.formHolder.form1.$valid) {
        commonSVC.showMessage('필수값을 모두 입력해주세요.');

        return false;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });