'use strict';

angular.module('gmpApp')
  .controller('addOrdPrintFormCtrl', function ($scope, $rootScope, data, $uibModalInstance, commonSVC, orderPrintFormSVC, shipmentModel, $timeout) {

    $scope.fieldIndex = null;
    $scope.cell = { title: '' };
    $scope.isEdit = false;
    $scope.isMemoEdit = false;
    // $scope.selectedFields = [];

    $scope.selectedList = { ord: [], ol_shop: [] };
    $scope.selectedColumns = { ord: [], ol_shop: [] };
    $scope.deselectedColumns = { ord: [], ol_shop: [] };

    $scope.isOpened = { ord: true, ol_shop: false };
    $scope.form = { template_data: { add_opt1: false, add_opt2: false, add_opt3: false, dup_ship_msg: false, memo_print_type: 'recently' } };
    $scope.type = data.type;
    $scope.ordColumn = [];

    const fieldListIdx = { ord: {}, ol_shop: {} },
          selectedListIdx = { ord: {}, ol_shop: {} };
    let line_break_idx = 0;

    /**
     * 멀티 셀렉 & 드래그 앤 드랍 적용
     */
    $(document).ready(function() {
      $('.field-list').selectable({
        cancel: '.dis-select',
        filter: 'li',
        selecting: function(event, ui) { // shift키로 멀티 셀렉 가능하도록 처리
          const type = $(this).closest('.div-container').attr('id');
          const curr = $(ui.selecting.tagName, event.target).index(ui.selecting);

          if (event.shiftKey) {
            if (!fieldListIdx[type].startIndex) { fieldListIdx[type].startIndex = curr; }

            fieldListIdx[type].endIndex = curr;

            $(`.field-list .${type} li`).each((index, item) => {
              if ((index >= fieldListIdx[type].startIndex && index <= fieldListIdx[type].endIndex) || (index <= fieldListIdx[type].startIndex && index >= fieldListIdx[type].endIndex)) {
                $(item).addClass('ui-selected');
              }
            });
          } else {
            fieldListIdx[type].startIndex = curr;
          }
        },
        start: function() {
          $scope.selectedColumns[$(this).closest('.div-container').attr('id')] = [];
        },
        stop: function() {
          const type = $(this).closest('.div-container').attr('id');

          $(`.field-list .${type} .ui-selected`).each(function() {
            $scope.selectedColumns[type].push($(this).children('.div-context').attr('value'));
          });

          $scope.$apply();
        }
      }).disableSelection();

      $('.selected-list').selectable({
        cancel: '.dis-select',
        filter: 'li',
        selecting: function(event, ui) { // shift키로 멀티 셀렉 가능하도록 처리
          const type = $(this).closest('.div-container').attr('id');
          const curr = $(ui.selecting.tagName, event.target).index(ui.selecting);

          if (event.shiftKey) {
            if (!selectedListIdx[type].startIndex) { selectedListIdx[type].startIndex = curr; }

            selectedListIdx[type].endIndex = curr;

            $(`.selected-list .${type} li`).each((index, item) => {
              if ((index >= selectedListIdx[type].startIndex && index <= selectedListIdx[type].endIndex) || (index <= selectedListIdx[type].startIndex && index >= selectedListIdx[type].endIndex)) {
                $(item).addClass('ui-selected');
              }
            });
          } else {
            selectedListIdx[type].startIndex = curr;
          }
        },
        start: function() {
          $scope.deselectedColumns[$(this).closest('.div-container').attr('id')] = [];
        },
        stop: function() {
          const type = $(this).closest('.div-container').attr('id');

          $(`.selected-list .${type} .ui-selected`).each(function(index, item) {
            $scope.deselectedColumns[type].push($(this).children('.div-context').attr('value'));
          });

          if ($scope.deselectedColumns[type].length > 1) {
            $scope.isEdit = false;
            $scope.isMemoEdit = false;
          }

          $scope.$apply();
        }
      }).disableSelection();

      $('.selected-list').sortable({
        axis: 'y',
        cursorAt: { top: 10, left: 25 },
        placeholder: 'li-placeHolder',
        connectWith: '.selected-list .ul-list',
        items: 'ul>li',
        handle: '.div-sortablehandle',
        helper: function (event, item) {
          const type = $(this).closest('.div-container').attr('id');

          if (!item.hasClass('ui-selected')) {
            $(`.selected-list .${type}`).find('.ui-selected').removeClass('ui-selected');
            item.addClass('ui-selected');
          }

          const selected = $(`.selected-list .${type} .ui-selected`).clone();

          item.data('multidrag', selected);
          $(`.selected-list .${type} .ui-selected`).not(item).remove();

          return $('<li class="transporter" />').append(selected);
        },
        stop: async function (event, ui) {
          const type = $(this).closest('.div-container').attr('id');
          const selected = ui.item.data('multidrag');

          ui.item.after(selected);
          ui.item.remove();

          const selectedList = [];
          const sortBase = [];

          selected.each(function() {
            selectedList.push($(this).children('.div-context').attr('value'));
            $scope.cell.index = $('li', event.target).index($(this));
          });

          $(this).find('li .div-context').each((index, item) => {
            sortBase.push($(item).attr('value'));
          });

          const list = angular.copy($scope.selectedList[type]);
          list.sort((a, b) => {
            return sortBase.findIndex(f => f === (a.title || a.value)) - sortBase.findIndex(f => f === (b.title || b.value));
          });

          $scope.selectedList[type] = list;

          // 주문 내역 필드 수정시 미리보기 수정
          type === 'ord' && setPreviewData();
          $scope.$apply();

          // 중복 요소 생겨나면 수동으로 제거
          if ($(`.selected-list .${type} li`).length !== $scope.selectedList[type].length) {
            const dupList = [];

            $(`.selected-list .${type} li`).each(function() {
              if (dupList.includes($(this).children('.div-context').attr('value'))) {
                this.remove();
              } else {
                dupList.push($(this).children('.div-context').attr('value'));
              }
            });
          }

          $(`.selected-list .${type} .ui-selected`).removeClass('ui-selected');

          // 이동된 요소에 selected 클래스 추가
          $(`.selected-list .${type} li`).each(function() {
            if (selectedList.includes($(this).children('.div-context').attr('value'))) {
              $(this).addClass('ui-selected');
            }
          });
        }
      });
    });

    /**
     * 아코디언 탭 여닫기 함수
     */
    $scope.openTab = (type) => {
      // 탭 펼칠 때 다른 탭 열려 있으면 닫아줘야 함
      if (!$scope.isOpened[type]) {
        $scope.isOpened[Object.keys($scope.isOpened).find(name => name !== type)] = false;
      }

      $scope.isEdit = false;
      $scope.isMemoEdit = false;
      $scope.columnListSearch = '';
      $scope.isOpened[type] = !$scope.isOpened[type];
    };

    /**
     * 선택항목에 추가
     */
    $scope.select = (type, flag) => {
      if (!(flag === 'all' || $scope.selectedColumns[type].length)) {
        return;
      } else if (type === 'ol_shop' && $scope.selectedList[type].length + $scope.selectedColumns[type].length > 9) {
        commonSVC.showMessage('항목은 최대 9개까지만 선택 가능합니다.');

        return;
      }

      const selectList = flag === 'all' ? angular.copy($scope.fieldList[type]).map(f => f.title) : $scope.selectedColumns[type];

      let i = 0;

      while (i < selectList.length && (type === 'ol_shop' ? $scope.selectedList[type].length < 9 : true)) {
        const isDuplicated = $scope.selectedList[type].findIndex(f => { return f.title === selectList[i]; }) !== -1;

        if (isDuplicated) {
          flag !== 'all' && commonSVC.showMessage('이미 선택된 항목이 있습니다.');
          i++;

          continue;
        } else {
          const idx = $scope.fieldList[type].findIndex(f => f.title === selectList[i]);
          $scope.selectedList[type].push(angular.copy($scope.fieldList[type][idx]));
          i++;
        }
      }

      // 주문 내역 필드 수정시 미리보기 수정
      type === 'ord' && setPreviewData();
    };

    $scope.selectClick = (type, field) => {
      if (type === 'ol_shop' && $scope.selectedList[type].length >= 9) {
        commonSVC.showMessage('항목은 최대 9개까지만 선택 가능합니다.');

        return;
      }

      const idx = $scope.fieldList[type].findIndex(f => f.title === field);

      if ($scope.selectedList[type].findIndex(f => { return f.title === $scope.fieldList[type][idx].title; }) !== -1) {
        commonSVC.showMessage('이미 선택된 항목이 있습니다.');

        return false;
      } else {
        $scope.selectedList[type].push(angular.copy($scope.fieldList[type][idx]));
      }

      // 주문 내역 필드 수정시 미리보기 수정
      type === 'ord' && setPreviewData();
    };

    // 컬럼 클릭(변경)
    $scope.changeColumn = function (column, type) {
      const index = $scope.selectedList[type].findIndex(f => (f.title || f.value) === (column.title || column.value));

      // 컬럼 단일 선택시에만 변경란 띄우기
      if (!($scope.deselectedColumns[type].length === 1 && column.item_cd)) {
        $scope.isEdit = false;
        $scope.isMemoEdit = true;

        return false;
      } else {
        $scope.isEdit = true;
        if (type === 'ol_shop') {
          $scope.isMemoEdit = column.value === 'memo';
        }
      }

      if (!($scope.fieldIndex === index && $scope.cell.title === column.title)) {
        $scope.cell.title = column.title;
        $scope.cell.mod_item = column.mod_item;
        $scope.cell.index = index;

        if ($scope.deselectedColumns[type].length === 1) {
          $scope.fieldIndex = index;
        }
      }
    };

    /**
     * 줄바꿈 추가
     */
    $scope.line_break = () => {
      // 선택 항목 있을 경우 항목 바로 아래, 없을 경우 최하단에 추가
      const idx = $scope.deselectedColumns.ord.length ? $scope.selectedList.ord.findIndex(f => (f.title || f.value) === $scope.deselectedColumns.ord[$scope.deselectedColumns.ord.length - 1]) + 1 : $scope.selectedList.ord.length;
      $scope.selectedList.ord.splice(idx, 0, { value: `line_break_${line_break_idx++}` });

      // 주문 내역 필드 수정시 미리보기 수정
      setPreviewData();
    };

    /**
     * 순서 변경
     */
    $scope.changeSeq = (type, calcul, position) => {
      if ($scope.deselectedColumns[type].length === 0) {
        commonSVC.showMessage('항목을 선택해주세요.');

        return;
      }

      const index = [];
      const toIndex = [];

      let sTemp = [];

      if (position === 'end') {
        $scope.deselectedColumns[type].forEach(filedName => {
          index.push($scope.selectedList[type].findIndex(f => filedName === (f.title || f.value)));
        });

        if (calcul === 'down') {
          if (_.isEqual($scope.selectedList[type].slice($scope.selectedList[type].length - index.length, $scope.selectedList[type].length).map(f => f.title), $scope.selectedList[type])) {
            return false;
          }

          $scope.fieldIndex = $scope.selectedList[type].length - 1;
          sTemp = _.pullAt($scope.selectedList[type], index);

          $scope.selectedList[type] = [...$scope.selectedList[type], ...sTemp];

          index.forEach((n, idx) => toIndex.push($scope.selectedList[type].length - idx - 1));
        } else {
          if (_.isEqual($scope.selectedList[type].slice(0, index.length).map(f => f.title), $scope.deselectedColumns[type])) {
            return false;
          }

          $scope.fieldIndex = 0;
          sTemp = _.pullAt($scope.selectedList[type], index);

          $scope.selectedList[type] = [...sTemp, ...$scope.selectedList[type]];

          index.forEach((n, idx) => toIndex.push(idx));
        }
      } else {
        $scope.deselectedColumns[type].forEach(filedName => {
          const i = $scope.selectedList[type].findIndex(f => filedName === (f.title || f.value));

          if (calcul === 'down') {
            index.unshift(i);

            if (i !== $scope.selectedList[type].length - 1 && toIndex.indexOf(i + 1) === -1) {
              toIndex.unshift(i + 1);
            } else if ($scope.selectedList[type].length - 1 && toIndex.indexOf(i) === -1) {
              toIndex.unshift(i);
            } else {
              toIndex.unshift(i - 1);
            }

            $scope.fieldIndex++;
          } else {
            index.push(i);
            if (i !== 0 && toIndex.indexOf(i - 1) === -1) {
              toIndex.push(i - 1);
            } else {
              toIndex.push(i);
            }

            $scope.fieldIndex--;
          }
        });

        if (_.isEqual(_.sortBy(toIndex), _.sortBy(index))) {
          commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

          return false;
        }

        index.forEach((idxV, i) => {
          const sTemp = $scope.selectedList[type][idxV];

          $scope.selectedList[type][idxV] = $scope.selectedList[type][toIndex[i]];
          $scope.selectedList[type][toIndex[i]] = sTemp;
        });
      }

      $scope.cell.index = $scope.fieldIndex;

      // 주문 내역 필드 수정시 미리보기 수정
      type === 'ord' && setPreviewData();

      $timeout(function() {
        // 버튼 클릭으로 인덱스 변경 시 클래스가 이동하지 않아 수동으로 클래스 적용
        $(`.selected-list .${type} .ui-selected`).removeClass('ui-selected');

        toIndex.forEach (idx => {
          $(`.selected-list .${type} li`).eq(idx).addClass('ui-selected');
        });
      });
    };

    /**
     * 주문 내역 항목명 변경시 미리보기에 반영
     */
    $scope.changeTitle = (index) => {
      let idx = 0;
      const field = $scope.selectedList.ord[index];

      do {
        const column = $scope.ordColumn[idx].find(c => c.title === field.title);

        if (column) {
          column.mod_item = field.mod_item;
          break;
        } else {
          idx++;
        }
      } while (idx < $scope.ordColumn.length);
    };

    /**
     * 인쇄할 항목에서 제거
     */
    $scope.removeField = (field, type) => {
      $scope.selectedList[type].splice($scope.selectedList[type].findIndex(f => (f.title || f.value) === field), 1);

      $scope.isEdit = false;

      // 주문 내역 필드 수정시 미리보기 수정
      type === 'ord' && setPreviewData();
    };

    /**
     * 인쇄 가능 전체 항목 검색
     */
    $scope.columnListFilter = (field) => {
      return $scope.columnListSearch ? field.title.indexOf($scope.columnListSearch) >= 0 : true;
    };

    /**
     * 인쇄 가능 전체 항목 검색 초기화
     */
    $scope.clearFilter = () => {
      $scope.columnListSearch = '';
    };

    $scope.submit = async () => {
      if (!$scope.form.template_name) {
        commonSVC.showMessage('양식명을 입력해주세요.');

        return false;
      }

      if (!['ord', 'ol_shop'].some(type => $scope.selectedList[type].filter(f => f.title).length)) {
        commonSVC.showMessage('인쇄할 항목을 선택해주세요.', '선택된 항목이 없습니다. 1개 이상 항목을 필수로 선택해주세요.');

        return false;
      }

      const result = await commonSVC.showConfirm(`주문서 인쇄 양식을 ${data.type === 'add' ? '추가' : '수정' }하시겠습니까?`);

      if (result) {
        // 사용자가 설정한 인덱스 데이터로 넣어줌
        $scope.selectedList.ord.forEach((field, index) => field.seq = index);
        $scope.selectedList.ol_shop.forEach((field, index) => field.seq = index + $scope.selectedList.ord.length);

        const formData = {
          template_no: $scope.form.template_no,
          template_name: $scope.form.template_name,
          template_data: JSON.stringify($scope.form.template_data),
          fieldList: [...$scope.selectedList.ord, ...$scope.selectedList.ol_shop]
        };

        try {
          if (data.type === 'add') {
            await shipmentModel.addOrdPrintForm(formData);
          } else {
            await shipmentModel.editOrdPrintForm(formData);
          }

          $uibModalInstance.close('success');
        } catch (err) {
          if (err.data.error.includes('Duplicate')) {
            commonSVC.showMessage('다른 양식명으로 입력해주세요.', '동일한 양식명이 있습니다.');
          } else {
            commonSVC.showToaster('error', '실패', `주문서 인쇄 양식 ${data.type === 'add' ? '추가' : '수정' } 실패`);
          }
        }
      }
    };

    /**
     * 미리보기 데이터 설정
     */
    const setPreviewData = async () => {
      // 커스텀 양식에 맞게 주문 테이블 배치
      const columnList = [];
      let sum = 0;
      let arr = [];

      if (!$scope.selectedList.ord.filter(f => f.title).length) {
        $scope.ordColumn = [];

        return; // 줄바꿈 요소만 있을 경우 return
      }

      $scope.selectedList.ord.forEach((obj, idx) => {
        const filed = angular.copy(obj);

        // 줄 바꿈 요소일 경우 다음 필드로 넘어감
        if (!(idx === $scope.selectedList.ord.length - 1 || filed.item_cd)) { return; }

        // 컬럼 넓이의 합이 6행을 넘거나 줄바꿈 컬럼과 만나면 다음 행으로 이동
        if (!$scope.selectedList.ord[idx + 1]?.item_cd && $scope.selectedList.ord.length !== idx + 1) {
          // 수동 줄바꿈시 해딩 컬럼의 width 확장
          // 남은 width가 컬럼 기본 width보다 작으면 자동 줄바꿈
          if ((sum + (filed.col * 1)) > 6) {
            arr.length && columnList.push(arr);
            arr = [];
            sum = filed.col = 6;
          } else {
            filed.col = 6 - sum;
            sum = 6;
          }
        } else if ((sum + (filed.col * 1)) > 6) {
          sum < 6 && arr.push({ col: 6 - sum + 1 });
          columnList.push(arr);
          arr = [];
          sum = filed.col * 1;
        } else {
          sum += (filed.col * 1);
        }
        filed.item_cd && arr.push(filed);

        if (idx === $scope.selectedList.ord.length - 1) { columnList.push(arr); }
      });

      $scope.ordColumn = columnList;
    };

    $scope.changeMemoPrintType = () => {
      const memoField = $scope.selectedList.ol_shop.find(ol => ol.value === 'memo');

      if ($scope.form.template_data.memo_print_type === 'all') {
        memoField.data = '메모입력3 / 메모입력2 / 메모입력1';
      } else {
        memoField.data = '메모입력3';
      }

      $timeout(() => {});
    };

    const init = async () => {
      $scope.fieldList = angular.copy(orderPrintFormSVC.fieldList);

      if (!$rootScope.user_profile.sol_stock) {
        $scope.fieldList.ol_shop = $scope.fieldList.ol_shop.filter(f => !f.no_stock);
      }

      if (data.type === 'edit') {
        $scope.customForm = (await shipmentModel.ordPrintFormDetail(data.template_no)).data.results;
        $scope.form.template_no = $scope.customForm[0].template_no;
        $scope.form.template_name = $scope.customForm[0].template_name;
        $scope.form.template_data = JSON.parse($scope.customForm[0].template_data);

        if (!$rootScope.user_profile.sol_stock) {
          $scope.customForm = $scope.customForm.filter(f => !!$scope.fieldList.ol_shop.find(field => field.title === f.cd_name));
          $scope.form.template_data.add_opt3 = false;
        }

        if (!$scope.form.template_data.memo_print_type) {
          $scope.form.template_data.memo_print_type = 'recently';
        }

        $scope.customForm.forEach(customField => {
          const oriField = $scope.fieldList[customField.type || 'ord'].find(field => field.item_cd === customField.item_cd);

          if (oriField.value === 'memo' && $scope.form.template_data.memo_print_type === 'all') {
            oriField.data = '메모입력3 / 메모입력2 / 메모입력1';
          }

          $scope.selectedList[customField.type || 'ord'].push({ ...(oriField || { value: `line_break_${line_break_idx++}` }), ...customField });
        });
      } else {
        $scope.selectedList.ord = $scope.fieldList.ord.filter(f => f.select);
        $scope.selectedList.ol_shop = $scope.fieldList.ol_shop.filter(f => f.select);
      }

      setPreviewData();

      $timeout(() => {});
    };

    init();

    /**
     * 취소
     */
    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
