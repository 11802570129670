'use strict';

angular.module('gmpApp')
  .controller('CustomShopExcelTemplateCtrl', function ($scope, $uibModalInstance, data, userInfo, commonSVC, shipmentSVC, shipmentModel) {
    $scope.mode = data.mode;
    $scope.shop_name = data.shop_name;
    $scope.shop_id = data.shop_id;

    const uniq_criteria = data.uniq_criteria.split('\n'), // 주문 중복기준
          excel_field_cdn = data.excel_field_cdn.split('\n'); // 엑셀 커스텀필드

    // 엑셀 양식 필드
    const fields = angular.copy(shipmentSVC.customShopExcelFields);

    // 필드 두개로 나눈 배열 (화면 보여주는용)
    $scope.chunkFields = [];

    function init () {
      fields.forEach(field => {
        const userField = excel_field_cdn.find(e => e.startsWith(field.key));

        // 설정한 커스텀 항목명이 있는 경우 값 추가
        field.userHeader = userField && userField.split('=')[1] !== field.header ? userField.split('=')[1] : '';

        // 사용자가 설정한 중복기준으로 세팅
        field.isCriteria = field.enableCriteria && uniq_criteria.includes(field.criteriaKey);

        const extractedValues = excel_field_cdn
          .filter(item => item.startsWith('ship_cost=') || item.startsWith('shop_ord_no='))
          .map(item => {
            const matches = item.match(/=([01])$/);

            return matches ? matches[1] : null;
          });

        field.isShipCost = extractedValues[0] === '1';
        field.isOrdNo = extractedValues[1] === '1';

      });

      // 필드배열 두개로 분리
      $scope.chunkFields = [fields.slice(0, Math.floor(fields.length / 2)), fields.slice(Math.floor(fields.length / 2))];
    }

    init();

    /**
     * 양식&중복기준 저장
     */
    $scope.save = async () => {
      const fields = $scope.chunkFields[0].concat($scope.chunkFields[1]);

      if (fields.every(({ isCriteria }) => !isCriteria)) {
        commonSVC.showMessage('중복기준을 1개 이상 선택해주시기 바랍니다.');

        return false;
      }

      if ($scope.mode !== 'add' && fields.filter(({ isCriteria }) => isCriteria).map(({ criteriaKey }) => criteriaKey).join('\n') !== data.uniq_criteria) {
        await commonSVC.showMessage('', '중복기준을 변경하였습니다.\n엑셀 업로드 시 주문이 중복으로 업로드될 수 있으니 업로드 후 확인해 주세요.');
      }

      try {
        const params = {
          shop_cd: data.shop_cd,
          uniq_criteria: fields.filter(field => field.enableCriteria && field.isCriteria).map(({ criteriaKey }) => criteriaKey).join('\n'),
          excel_field_cdn: fields.map(field => {
            let suffix = '';

            if (field.key === 'ship_cost' || field.key === 'shop_ord_no') {
              suffix = field.key === 'ship_cost' ? (field.isShipCost ? '=1' : '=0') : (field.isOrdNo ? '=1' : '=0');
            }

            return `${field.key}=${field.userHeader?.length ? field.userHeader.join('✝') : field.header}=${field.header}${suffix}`;
          }).join('\n')
        };
        const userInfoShops = userInfo.user.manualOrderShops.filter(({ shop_cd }) => shop_cd === data.shop_cd);

        await shipmentModel.addCustomShopExcelTemplate(params);

        // userInfo에 있는 반자동 쇼핑몰 데이터 업데이트
        for (const shop of userInfoShops) {
          shop.uniq_criteria = params.uniq_criteria;
          shop.excel_field_cdn = params.excel_field_cdn;
        }

        commonSVC.showToaster('success', '성공', `엑셀 양식 ${$scope.mode === 'add' ? '추가' : '수정'}에 성공했습니다.`);

        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', `엑셀 양식 ${$scope.mode === 'add' ? '추가' : '수정'}에 실패했습니다.`);
      }
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
