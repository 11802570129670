/**
 * 2018-08-22 Daniel
 * 머리말/꼬리말 관리
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineProductAddcontentCtrl', function ($rootScope, $scope, $uibModalInstance, $timeout, $compile, $q, settings, commonSVC, onlineProductSVC, shopAccountModel, onlineProductModel, data) {

    $scope.returnYn = data.returnYn;
    $scope.target = data.target;

    if (!$scope.returnYn) {
      var selectProds = data.result;
      var olShopParams = data.olShopParams;
      const prodCounts = data.prodCounts;

      $scope.readOnly = !selectProds.length;
      $scope.prodCount = $scope.readOnly ? (olShopParams.std_ol_type === 'normal' ? prodCounts.std_ol_n : prodCounts.std_ol_y) : selectProds.length;
    }
    $scope.applyYnList = {};

    /* 검색 및 버튼 관련 변수 */
    const searchForm = {
      search_key: '',
      search_word: '',
      site_id: '',
      site_code: '',
      status: '',
      status_sub: '',
      page: 'order',
      addcontentTarget: $scope.target || '',
    };
    $scope.searchForm = angular.copy(searchForm);

    const search = {
      searchData: {
        all_checked: false,                                                         // 전체선택 여부
        selectCount: 0,                                                             // 검색전체 건수
        totalCount: 0,                                                              // 검색전체 건수
        showDetailSearchArea: false,
        searchAreaClass: 'col-xs-10',
        searchNoMargin: true,
        search_key_items: [                                                         // 검색영역 키값
          { label: '전체', value: '' },
          { label: '템플릿코드', value: 'addcontent_no' },
          { label: '머리말/꼬리말 템플릿명', value: 'addcontent_name' }
        ]
      },
      searchDetail: [
        {
          title: '전체',
          search_name: 'addcontentTarget',
          item_list: [{ name: '상품명' }, { name: '상세설명' }],
          item_key: 'name',
          item_value: 'name',
          select_value: $scope.searchForm.addcontentTarget,
          disabled: () => !!$scope.target,
        },
        {
          title: '템플릿 유형 전체',
          search_name: 'addcontentType',
          item_list: onlineProductSVC.addcontentTypes,
          item_key: 'name',
          item_value: 'name',
          select_value: '',
          ngIfFunc: () => !!$scope.searchForm.addcontentTarget,
          filter: (item) => {
            if (!$scope.searchForm.addcontentTarget) { return false; }

            return item.view.includes($scope.searchForm.addcontentTarget);
          }
        },
        {
          title: '템플릿 항목 전체',
          search_name: 'viewPosition',
          item_list: onlineProductSVC.addcontentPositions,
          item_key: 'name',
          item_value: 'name',
          select_value: '',
          ngIfFunc: () => !!$scope.searchForm.addcontentTarget,
          filter: (item) => {
            if (!$scope.searchForm.addcontentTarget) { return false; }

            return item.view == $scope.searchForm.addcontentTarget;
          }
        },
      ],
      searchBtn: {
        actions_left: [],
        actions_right: [
          {
            label: '머리말/꼬리말 등록',
            btn_type: 'button',
            action: function () {
              $scope.addcontentRule();
            }
          }
        ],
        actions_right_bottom: []
      }
    };
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchDetail = angular.copy(search.searchDetail);
    $scope.searchBtn = angular.copy(search.searchBtn);

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'addcontent_no', 'addcontent_type', 'addcontent_name', 'addcontent_no', 'view_position', 'mdate', 'apply_cnt'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['addcontent_no'],
      notSortingColumns: [
        'widget', 'view_position', 'apply_cnt'
      ],
      notResizingColumns: ['addcontent_no'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/add-contents/list`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;
          result.results = result.rows;

          return result.results;
        }
      },
      selectOptions: {
        multiSelect: false
      },
      modal: true,
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 80,
          template: function (row) {
            return `
							<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentRule(${row.addcontent_no})">상세</button>
							<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentAR(${row.addcontent_no}, false)">해제</button>
							<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentCopy(${row.addcontent_no})">복사</button>
							<button class="btn btn-default btn-xxs" ng-click="grid.appScope.addcontentDelete(${row.addcontent_no})">삭제</button>`;
          }
        },
        {
          key: 'addcontent_no',
          title: '템플릿 코드',
          width: 60
        },
        {
          key: 'addcontent_type',
          title: '템플릿 유형',
          width: 80
        },
        {
          key: 'addcontent_name',
          title: '머리말/꼬리말 템플릿명',
          width: 250
        },
        {
          key: 'view_position',
          title: '템플릿 항목',
          width: 100,
          template: function (row) {
            let viewPosition;

            if (row.header_content && row.footer_content) {
              viewPosition = '전체';
            } else if (row.header_content) {
              viewPosition = '머리말';
            } else {
              viewPosition = '꼬리말';
            }

            return `${row.addcontent_target} ${viewPosition}`;
          }
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 100
        },
        {
          key: 'apply_cnt',
          title: '적용상품',
          width: 80,
          template: function (row) {
            return (row.apply_cnt && `<a ng-click="grid.appScope.addcontentApplyProds(${row.addcontent_no})">${row.apply_cnt}</a>`) || 0;
          }
        }
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'total';
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function (number) {
      $scope.searchData.showCount = number;
      $scope.grid.methods.length(number);
    };

    /**
     * 템플랏 등록/수정
     */
    $scope.addcontentRule = function (addcontentNo) {
      const size = 'lg-md',
            ctrl = 'OnlineProductAddcontentRuleCtrl',
            html = 'views/online/product/modals/addcontent_rule.html',
            resolve = {
              data: {
                addcontentNo: addcontentNo,
                target: $scope.target || ''
              }
            };

      const modal = commonSVC.openModal(size, resolve, ctrl, html, false);

      modal.result.then(function () {
        $scope.searchDo(true);
      });
    };

    /**
     * 템플릿 복사
     */
    $scope.addcontentCopy = function (addcontentNo) {
      commonSVC.showConfirm('템플릿 복사', '선택한 머리말/꼬리말 템플릿을 복사하시겠습니까?', function (confirm) {
        if (confirm) {
          const params = {
            no: addcontentNo
          };

          onlineProductModel.addcontentCopy(params)
            .then(function (res) {
              if (res.status === 200) {
                commonSVC.showToaster('success', '성공', '머리말/꼬리말 복사에 성공했습니다.');
                $scope.searchDo(true);
              } else {
                commonSVC.showToaster('error', '실패', '머리말/꼬리말 복사에 실패했습니다.');
              }
            })
            .catch(function () {
              commonSVC.showToaster('error', '실패', '머리말/꼬리말 복사에 실패했습니다.');
            });
        }
      });
    };

    /**
     * 템플릿 삭제
     */
    $scope.addcontentDelete = function (addcontentNo) {
      const addcontent = $scope.grid.methods.filteredData({ addcontent_no: addcontentNo }),
            applyYn = addcontent.apply_cnt > 0;

      if (applyYn) {
        const resolve = {
          data: {
            type: '삭제',
            title: '선택한 템플릿은 현재 적용 중인 상품이 있습니다.<br>선택한 머리말/꼬리말 템플릿을 <span class="text-orange">삭제</span>하시겠습니까?',
            target: addcontent.addcontent_target
          }
        };

        $rootScope.$broadcast('addContentChanged', { type: '삭제', addContentNo: addcontentNo });

        result = commonSVC.openModal('md', resolve, 'OnlineProductAddcontentConfirmCtrl', 'views/online/product/modals/addcontent_confirm.html').result;
      } else {
        result = $q(function (resolve) {
          commonSVC.showConfirm('머리말/꼬리말 템플릿 삭제', '선택한 머리말/꼬리말 템플릿을 삭제하시겠습니까?', function (confirm) {
            if (confirm) {
              $rootScope.$broadcast('addContentChanged', { type: '삭제', addContentNo: addcontentNo });

              resolve({ editYn: false });
            }
          });
        });
      }

      let result;

      result
        .then(function ({ editYn }) {
          const params = {
            no: addcontentNo,
            editYn: editYn
          };

          return onlineProductModel.addcontentDelete(params)
            .then(function (res) {
              if (res.status === 200) {
                commonSVC.showToaster('success', '성공', '머리말/꼬리말 삭제에 성공했습니다.');
                $scope.searchDo(true);
              } else {
                commonSVC.showToaster('error', '실패', '머리말/꼬리말 삭제에 실패했습니다.');
              }
            })
            .catch(function (err) {
              if (err.data.error === 'addWorkFailed') {
                commonSVC.showToaster('error', '실패', '머리말/꼬리말 삭제 성공,\n상품 작업에 실패하였습니다.');
              } else {
                commonSVC.showToaster('error', '실패', '머리말/꼬리말 삭제에 실패했습니다.');
              }
            });
        });
    };

    /**
		 * 템플릿 적용
		 */
    $scope.addcontentApply = function () {
      const addcontent = $scope.grid.methods.selectedData('all')[0];

      if (!addcontent) {
        commonSVC.showMessage('적용 실패', '선택된 템플릿이 없습니다.');

        return false;
      }

      const no = addcontent.addcontent_no,
            name = `[${addcontent.addcontent_type}]${addcontent.addcontent_name}`;

      if ($scope.returnYn) {
        const result = {
          no: no,
          name: name,
          target: addcontent.addcontent_target
        };

        $uibModalInstance.close(result);
      } else {
        $scope.addcontentAR(no, true);
      }
    };

    /**
     * 템플릿 적용 / 해제
     */
    $scope.addcontentAR = function (addcontentNo, applyYn) {
      const addcontent = $scope.grid.methods.filteredData({ addcontent_no: addcontentNo });

      const params = {
        no: addcontentNo,
        applyYn: applyYn
      };

      if (applyYn) {
        params.olShopNos = selectProds.length ? _.map(selectProds, 'ol_shop_no') : null;
        params.olShopParams = olShopParams;
      }

      // 해제시에 적용상품 없을시 막음
      if (!applyYn && !addcontent.apply_cnt) {
        commonSVC.showMessage('해제 실패', '머리말/꼬리말 템플릿이 적용된 상품이 없습니다.', function () {
          $scope.searchDo(false);
        });

        return false;
      }

      const resolve = {
        data: {
          type: '',
          title: '',
          target: addcontent.addcontent_target
        }
      };

      if (params.applyYn) {
        resolve.data.type = '적용';
        resolve.data.title = '선택한 머리말/꼬리말 템플릿을 <span class="text-orange">적용</span>하시겠습니까?';
      } else {
        resolve.data.type = '해제';
        resolve.data.title = '선택한 템플릿이 적용된 모든 상품의 머리말/꼬리말을 <span class="text-orange">해제</span>하시겠습니까?';
      }

      const modal = commonSVC.openModal('md', resolve, 'OnlineProductAddcontentConfirmCtrl', 'views/online/product/modals/addcontent_confirm.html');

      modal.result
        .then(function ({ editYn }) {
          const type = resolve.data.type;

          params.editYn = editYn;

          // 밖에서 캐치하면 dismiss도 잡혀서 안으로 넣음
          return onlineProductModel.addcontentApply(params)
            .then(function (res) {
              if (res.status === 200) {
                commonSVC.showToaster('success', '성공', `머리말/꼬리말 ${type}에 성공했습니다.`);
                $uibModalInstance.close('refresh');
              } else {
                commonSVC.showToaster('error', '실패', `머리말/꼬리말 ${type}에 실패했습니다.`);
              }
            })
            .catch(function (err) {
              if (err.data.error === 'addWorkFailed') {
                commonSVC.showToaster('error', '실패', `머리말/꼬리말 ${type} 성공,\n상품 작업에 실패하였습니다.`);
              } else {
                commonSVC.showToaster('error', '실패', `머리말/꼬리말 ${type}에 실패했습니다.`);
              }
            });
        });
    };

    // 적용 상품 리스트
    $scope.addcontentApplyProds = function (addcontentNo) {
      const addcontent = $scope.grid.methods.filteredData({ addcontent_no: addcontentNo });

      if (!addcontent.apply_cnt) {
        commonSVC.showMessage('실패', '적용중인 상품이없습니다.');

        return false;
      }

      const size = 'lg',
            ctrl = 'OnlineProductAddcontentApplyProdsCtrl',
            html = 'views/online/product/modals/addcontent_apply_prods.html',
            resolve = {
              data: {
                warehouseList: data.warehouseList,
                addcontent: addcontent
              }
            };

      commonSVC.openModal(size, resolve, ctrl, html, false);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });
