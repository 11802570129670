'use strict';

angular.module('gmpApp')
  .controller('OrderMultiSort', function ($uibModalInstance, $scope, $rootScope, data, localStorageService, commonSVC) {
    // 기본 정렬기준
    const gridSortDefailt = [
      {
        key: 'wdate',
        title: '주문수집일',
        sort: 'desc'
      }
    ];

    $scope.data = data;

    // 정렬기준
    $scope.selectSortDatas = data.grid_options.columns.filter((o) => {
      return o.key !== 'widget' && !o.key.includes('pa_addcol') && !data.grid_options.notSortingColumns?.includes(o.key) && !commonSVC.dtAlign.notSortingColumns?.includes(o.key);
    });

    // 기존 정렬 조건 테이터
    $scope.gridOrderDatas = angular.copy(gridSortDefailt);

    $scope.stete = localStorageService.get(`uiGrid_${data.grid_id}_${$rootScope.user_profile.sol_no}${$rootScope.user_profile.m_no}`);

    $scope.stete.columns.forEach((col) => {
      if (col.sort?.priority || col.sort?.priority == 0) {
        $scope.gridOrderDatas[col.sort?.priority] = {
          key: col.name,
          sort: col.sort.direction
        };
      }
    });

    const preData = angular.copy($scope.gridOrderDatas);

    // 초기화
    $scope.resetSort = () => {
      $scope.gridOrderDatas = angular.copy(gridSortDefailt);
    };

    // 조건 추가
    $scope.addSort = () => {
      $scope.gridOrderDatas.push(
        {
          key: '',
          title: '',
          sort: 'asc'
        }
      );
    };

    $scope.changeTitle = (idx) => {
      $scope.gridOrderDatas[idx].title = $scope.selectSortDatas.find(o => o.key === $scope.gridOrderDatas[idx].key)?.title;
    };

    // 삭제
    $scope.deleteSort = (idx) => {
      $scope.gridOrderDatas.splice(idx, 1);
    };

    // 변경 확인
    $scope.changeCheck = () => {
      let changeCheck = false;

      preData.forEach((row, idx) => {
        if (preData.length !== $scope.gridOrderDatas.length || row.key !== $scope.gridOrderDatas[idx].key || row.sort !== $scope.gridOrderDatas[idx].sort) {
          changeCheck = true;

          return;
        }
      });

      return changeCheck;
    };

    // 저장
    $scope.save = () => {
      for (const row of $scope.gridOrderDatas) {
        if (!row.key) {
          commonSVC.showMessage('안내', '정렬 기준이 설정되지 않았습니다. \n 조건을 설정하거나 설정하지 않을 조건을 삭제 하신 후 적용할 수 있습니다.');

          return;
        }
      }

      const keys = $scope.gridOrderDatas.map(o => o.key);

      if (keys.length !== [...new Set(keys)].length) {
        commonSVC.showMessage('안내', '중복된 정렬 기준이 있습니다. \n 조건을 변경하거나 중복된 조건을 삭제 하신 후 적용할 수 있습니다.');

        return;
      }

      $scope.stete.columns.forEach((col, idx) => {
        const findidx = $scope.gridOrderDatas.findIndex(row => {
          return col.name === row.key;
        });

        if (findidx > -1) {
          $scope.stete.columns[idx].sort = { priority: findidx, direction: $scope.gridOrderDatas[findidx].sort };
        } else {
          $scope.stete.columns[idx].sort = {};
        }
      });

      localStorageService.set(`uiGrid_${data.grid_id}_${$rootScope.user_profile.sol_no}${$rootScope.user_profile.m_no}`, $scope.stete);

      $uibModalInstance.close($scope.gridOrderDatas);
    };

    // 취소
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
