'use strict';

angular.module('gmpApp')
  .service('shopAccountModel', function (settings, commonSVC, templateModel) {
    const shopAccountPa20ApiUrl = `${settings.pa20ApiUrl}/app/settings/shop-account`;

    return {
      shopAccountUseList: function (params) {
        params = params || {};

        return commonSVC.sendUrl('GET', `${shopAccountPa20ApiUrl}/usable-shop`, params);
      },

      subShopList: function (params) {
        const url = `${settings.pa20ApiUrl}/app/common/sub-shop-list`;

        return commonSVC.sendUrl('GET', url, params);
      },

      shopAccountNewAddList: params => {
        const url = `${shopAccountPa20ApiUrl}/list-of-new-add`;

        return commonSVC.sendUrl('GET', url, params);
      },

      checkLimit: function () {
        const url = `${shopAccountPa20ApiUrl}/check-limit`;

        return commonSVC.sendUrl('GET', url);
      },

      checkDuplicate: function (params, next) {
        const url = `${shopAccountPa20ApiUrl}/check-duplicate`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      insert: function (vdata, next) {
        // 2017-11-09 harry 암호화 후 전송
        const params = angular.copy(vdata);

        if (next) {
          commonSVC.sendUrl('POST', shopAccountPa20ApiUrl, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', shopAccountPa20ApiUrl, params);
        }
      },

      update: function (vdata, next) {
        const params = angular.copy(vdata);

        if (next) {
          commonSVC.sendUrl('PATCH', shopAccountPa20ApiUrl, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', shopAccountPa20ApiUrl, params);
        }
      },

      delete: function (vdata, next) {
        commonSVC.sendUrl('DELETE', shopAccountPa20ApiUrl, vdata, function (state, data) {
          next(state, data);
        });
      },

      load: function (params, next) {
        const url = `${shopAccountPa20ApiUrl}/${params.shop_cd}/${encodeURIComponent(params.shop_id)}`;

        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }
      },

      /**
       * 쇼핑몰 계정 리스트 순번 업데이트
       */
      editShopListSeq: function (params, next) {
        const url = `${shopAccountPa20ApiUrl}/edit-seq`;

        return commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 사용중인 쇼핑몰 주문 자동수집 여부 업데이트
       * 2017-09-18 서상권
       */
      setAutoScrap: function (params, next) {
        const url = `${shopAccountPa20ApiUrl}/set-auto-scrap`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 출하지 로드
       */
      loadOutAddr: function (shop_cd, shop_id, next) {
        const params = { shop_cd: shop_cd, shop_id: shop_id };

        switch (shop_cd) {
          case 'A001': params.mode = 'addr_list'; break;
          case 'A006': params.mode = 'addr_list'; break;
          case 'A027': break;
          case 'A077': params.mode = 'load_addr'; break;
          case 'A112': params.mode = 'load_outAddr';
            params.values = { memNO: '10000013', addrCD: '02', memTypCD: '02' };
            break;
        }
        templateModel.templateApi(params, next);
      },

      /**
       * 반품지 로드
       */
      loadInAddr: function (shop_cd, shop_id, next) {
        const params = { shop_cd: shop_cd, shop_id: shop_id };

        switch (shop_cd) {
          case 'A001': params.mode = 'deliv_addr_list'; break;
          case 'A006': params.mode = 'deliv_addr_list'; break;
          case 'A027': params.mode = 'load_addr'; break;
          case 'A077': break;
          case 'A112': params.mode = 'load_inAddr';
            params.values = { memNO: '10000013', addrCD: '03', memTypCD: '02' };
            break;
        }
        templateModel.templateApi(params, next);
      },

      /**
       * 인터파크 공급업체 로드
       */
      loadProvider: function (params, next) {
        const url = 'https://engines.playapi.io/run';

        params.cache = 300; // 완전히 같은 성공된 요청은 300초(5분)간 캐시하도록 수정 (최근 playapi 에 추가된 옵션)
        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * esm master계정 조회
       */
      getEbaydepotAccounts: function () {
        const url = `${settings.pa20ApiUrl}/app/order/ebaydepot/users`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 글로벌 쇼핑몰 로그인 체크
       * @param {object} params : 글로벌 로그인 체크에 필요한 데이터
       */
      checkLoginValid: function (params) {

        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/global/checkLogin`, params);
      },

      /**
       * 쇼핑몰별 etc필드 조회
       */
      getShopEtcData: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/shop/getShopEtcData`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      /**
       * 메이크샵 SSO 로그인을 위한 authCode 발급
       */
      getMakeshopSsoAuthCode: (params) => {
        return commonSVC.sendUrl('GET', `${settings.wwwApiUrl}/api/oauth/code`, params);
      }
    };

  });
