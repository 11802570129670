'use strict';

angular.module('gmpApp')
  .factory('cardSVC', function() {
    const map = {
      3560: 'BC카드',
      356296: '신한카드',
      356297: '신한카드',
      3563: 'BC카드',
      356316: 'NH농협카드',
      356317: 'NH농협카드',
      356407: 'KB국민카드',
      356415: 'KB국민카드',
      356416: 'KB국민카드',
      356417: 'KB국민카드',
      356418: 'NH농협카드',
      356516: 'NH농협카드',
      356543: '하나카드',
      356545: '하나카드',
      3565: 'BC카드',
      356820: '우리카드',
      356900: '신한카드',
      356901: '신한카드',
      356902: '신한카드',
      356910: 'KB국민카드',
      356911: 'KB국민카드',
      356912: 'KB국민카드',
      356914: '롯데카드',
      356915: '롯데카드',
      356916: '롯데카드',
      3616: '현대카드',
      371001: '하나카드',
      371002: '하나카드',
      371003: '하나카드',
      371092: '하나카드',
      374722: '하나카드',
      374723: '하나카드',
      374724: '하나카드',
      375144: 'KB국민카드',
      375987: '삼성카드',
      376250: '롯데카드',
      376272: '롯데카드',
      376277: '롯데카드',
      376364: 'KB국민카드',
      376365: 'KB국민카드',
      377969: '하나카드',
      377973: '롯데카드',
      377981: '신한카드',
      377982: '신한카드',
      379983: '신한카드',
      377988: '신한카드',
      377989: '삼성카드',
      379183: '삼성카드',
      379192: '하나카드',
      379193: '하나카드',
      377971: '삼성카드',
      377900: '삼성카드',
      377972: '삼성카드',
      377910: '삼성카드',
      377975: '삼성카드',
      356907: '신한카드',
      356908: '신한카드',
      356878: '신한카드',
      377178: '신한카드',
      377983: '신한카드',
      379971: '하나카드',
      361653: '현대카드',
      370299: '현대카드',
      370300: '현대카드',
      356003: '기업은행',
      356023: 'SC제일은행',
      356323: 'SC제일은행',
      356523: 'SC제일은행',

      409085: 'SC제일은행',
      448123: 'SC제일은행',
      404803: '기업은행',
      405203: '기업은행',
      419803: '기업은행',
      427203: '기업은행',
      447703: '기업은행',
      490603: '기업은행',
      496603: '기업은행',
      402017: '현대카드',
      402072: '현대카드',
      403302: '현대카드',
      404577: '현대카드',
      404947: '현대카드',
      413526: '현대카드',
      418143: '현대카드',
      421810: '현대카드',
      421923: '현대카드',
      435661: '현대카드',
      438265: '현대카드',
      480074: '현대카드',
      486230: '현대카드',
      489016: '현대카드',
      490610: '현대카드',
      492752: '현대카드',
      493020: '현대카드',
      493046: '현대카드',
      400978: '하나카드',
      416206: '하나카드',
      416207: '하나카드',
      416262: '하나카드',
      420189: '하나카드',
      420707: '하나카드',
      421134: '하나카드',
      448125: '하나카드',
      450596: '하나카드',
      461771: '하나카드',
      465306: '하나카드',
      465583: '하나카드',
      490625: '하나카드',
      400912: '삼성카드',
      401762: '현대카드',
      401787: '현대카드',
      402367: '하나카드',
      402857: '현대카드',
      403290: 'KB국민카드',
      4045: '현대카드',
      404678: '신한카드',
      404825: 'BC카드',
      405753: '신한카드',
      4061: '우리카드',
      4062: '우리카드',
      4063: '우리카드',
      406357: '씨티카드',
      4064: '우리카드',
      4065: '우리카드',
      407916: 'NH농협카드',
      407917: 'NH농협카드',
      408966: '하나카드',
      409216: 'NH농협카드',
      409217: 'NH농협카드',
      410109: '씨티카드',
      411900: '하나카드',
      411904: '하나카드',
      411905: '하나카드',
      414025: 'BC카드',
      414710: '롯데카드',
      4162: '하나카드',
      418123: '현대카드',
      418236: '하나카드',
      421417: '수협중앙회',
      421418: '수협중앙회',
      421420: '우리카드',
      421468: '롯데카드',
      422155: '신한카드',
      422190: '수협중앙회',
      422727: '씨티카드',
      430972: '신한카드',
      432445: '수협중앙회',
      433028: '현대카드',
      433290: 'KB국민카드',
      434975: '신한카드',
      436420: '신한카드',
      438654: '롯데카드',
      438676: '신한카드',
      443233: '구.LG카드',
      440025: '하나카드',
      440446: '씨티카드',
      447320: '우리카드',
      4481: 'BC카드',
      449914: '신한카드',
      451245: '삼성카드',
      451281: '현대카드',
      451842: '신한카드',
      451845: '신한카드',
      4553: 'BC카드',
      455306: 'KB국민카드',
      455437: '하나카드',
      457047: '하나카드',
      457972: 'KB국민카드',
      457973: 'KB국민카드',
      458532: '삼성카드',
      459900: '하나카드',
      459912: '케이뱅크',
      459930: '하나카드',
      459950: '하나카드',
      459952: '광주은행',
      460561: '신한카드',
      461954: '신한카드',
      462890: '씨티카드',
      463916: '제주은행',
      464942: '삼성카드',
      464959: '롯데카드',
      4653: '하나카드',
      4655: '하나카드',
      4656: '케이뱅크',
      465887: '신한카드',
      465889: '수협카드',
      467007: '롯데카드',
      467008: '롯데카드',
      4673: 'KB국민카드',
      467309: 'KB국민카드',
      470587: '삼성카드',
      470588: '삼성카드',
      470616: 'NH농협카드',
      470617: 'NH농협카드',
      474360: '씨티카드',
      483543: '인피니트카드',
      485462: '케이뱅크(체크)',
      485479: 'NH농협카드',
      485480: 'NH농협카드',
      487033: '신한카드',
      488972: '롯데카드',
      489023: '신한카드',
      490220: '우리카드',
      4906: 'BC카드',
      490611: 'NH농협카드',
      490612: 'NH농협카드',
      493455: '하나카드',
      400913: '삼성카드',
      442660: '삼성카드',
      463732: '삼성카드',
      4689: '삼성카드',
      4705: '삼성카드',
      474289: '삼성카드',
      400278: '신한카드',
      400772: '신한카드',
      403965: '신한카드',
      429612: '신한카드',
      431236: '신한카드',
      434976: '신한카드',
      445090: '신한카드',
      454478: '신한카드',
      472175: '신한카드',
      472246: '신한카드',
      472429: '신한카드',
      478892: '신한카드',
      486678: '신한카드',
      404720: '우리카드',
      408320: '우리카드',
      412020: '우리카드',
      406107: '우리카드',
      447820: '우리카드',
      401898: '씨티카드',
      440445: '씨티카드',
      452013: '씨티카드',
      474361: '씨티카드',
      407407: '바로비씨',
      455320: '바로비씨',
      416339: '경남은행',
      411171: '우체국은행',
      411471: '우체국은행',
      459951: '제주은행',
      465613: '케이뱅크',
      453261: '케이뱅크',
      427352: '새마을금고',

      546465: '토스카드',
      536886: '수협은행',
      521176: 'SC제일은행',
      523527: 'SC제일은행',
      536323: 'SC제일은행',
      552123: 'SC제일은행',
      559123: 'SC제일은행',
      517548: '부산은행',
      538832: '부산은행',
      552132: '부산은행',
      552139: '경남은행',
      538803: '기업은행',
      538903: '기업은행',
      531194: '기업은행',
      552103: '기업은행',
      558903: '기업은행',
      537720: '바로비씨',
      519892: '씨티카드',
      527810: '씨티카드',
      540388: '씨티카드',
      558453: '씨티카드',
      512114: '현대카드',
      514645: '현대카드',
      520215: '현대카드',
      520217: '현대카드',
      523930: '현대카드',
      529942: '현대카드',
      532793: '현대카드',
      550347: '현대카드',
      552376: '현대카드',
      558813: '현대카드',
      523040: '현대카드',
      525872: '현대카드',
      511845: '하나카드',
      523830: '하나카드',
      538661: '하나카드',
      541707: '하나카드',
      552125: '하나카드',
      552133: '하나카드',
      552323: '하나카드',
      514043: '우리카드',
      536320: '우리카드',
      537102: '우리카드',
      553820: '우리카드',
      521189: '신한카드',
      528638: '신한카드',
      535180: '신한카드',
      537943: '신한카드',
      538413: '신한카드',
      549840: '신한카드',
      589807: '신한카드',
      502123: 'KB국민카드',
      502928: '하나카드',
      510737: '신한카드',
      511187: '신한카드',
      512360: '삼성카드',
      512462: '롯데카드',
      512635: '롯데카드',
      513791: '롯데카드',
      513792: '롯데카드',
      513894: '롯데카드',
      512365: '삼성카드',
      518831: '삼성카드',
      536125: '삼성카드',
      536148: '삼성카드',
      536648: '삼성카드',
      536181: '삼성카드',
      552014: '삼성카드',
      514876: '씨티카드',
      515594: '신한카드',
      515954: '우리카드',
      516453: 'KB국민카드',
      516574: '하나카드',
      517134: '신한카드',
      518185: '하나카드',
      520504: 'KB국민카드',
      522971: 'KB국민카드',
      523612: 'KB국민카드',
      524040: 'NH농협카드',
      524041: 'NH농협카드',
      524144: '씨티카드',
      524242: '하나카드',
      524333: '현대카드',
      524335: '하나카드',
      524353: '하나카드',
      527289: 'KB국민카드',
      528937: '롯데카드',
      531070: '삼성카드',
      531838: '하나카드',
      532092: '하나카드',
      532750: '토스뱅크',
      534292: '롯데카드',
      535020: '우리카드',
      536326: 'KB국민카드',
      536510: '카카오뱅크',
      537120: '우리카드',
      537145: '우리카드',
      537706: 'KB국민카드',
      538159: 'BC카드',
      538720: '우리카드',
      5388: 'BC카드',
      538811: 'NH농협카드',
      538812: 'NH농협카드',
      538920: '우리카드',
      540447: '삼성카드',
      540926: 'KB국민카드',
      540947: 'KB국민카드',
      542158: '신한카드',
      542586: 'NH농협카드',
      542416: 'NH농협카드',
      542879: '신한카드',
      543000: 'NH농협카드',
      543017: 'NH농협카드',
      543333: '현대카드',
      5441: '하나카드',
      546111: 'NH농협카드',
      546112: 'NH농협카드',
      546252: '하나카드',
      548020: '우리카드',
      552070: '삼성카드',
      552220: '우리카드',
      552111: 'NH농협카드',
      552112: 'NH농협카드',
      552136: '롯데카드',
      552290: '현대카드',
      552377: '현대카드',
      552576: '신한카드',
      553177: '하나카드',
      554346: 'KB국민카드',
      554959: 'KB국민카드',
      557042: 'KB국민카드',
      558526: 'KB국민카드',
      559410: '신한카드',
      559438: 'NH농협카드',
      559434: 'NH농협카드',
      559924: '현대카드',
      588644: '한국씨티은행',
      5898: 'Maestro카드',
      589808: 'KB국민카드',
      551431: '대구은행',
      514473: '전북은행',
      518669: '전북은행',
      528268: '전북은행',
      515193: '광주은행',

      621249: '중한통카드',
      621640: '수협은행',
      625243: '수협은행',
      655616: '제주은행',
      657316: '제주은행',
      621023: 'SC제일은행',
      655623: 'SC제일은행',
      626275: '우체국은행',
      624414: '우체국은행',
      621031: '대구은행',
      657331: '대구은행',
      655632: '부산은행',
      621032: '부산은행',
      625132: '부산은행',
      657332: '부산은행',
      625120: '바로비씨',
      624368: '현대카드',
      621025: '하나카드',
      621079: '하나카드',
      624435: '하나카드',
      624436: '하나카드',
      605609: '신한카드',
      621078: '신한카드',
      624331: '신한카드',
      625178: '신한카드',
      604803: '기업은행',
      604806: '국민은행',
      604811: '농협은행',
      604812: '농협은행',
      604820: '우리카드',
      604823: 'SC제일은행',
      604825: '하나SK카드',
      604827: '씨티은행',
      604878: '신한카드',
      606045: '신한카드',
      6210: 'BC카드',
      621011: 'NH농협은행',
      621012: 'NH농협은행',
      623358: '롯데카드',
      623374: 'KB국민카드',
      624348: '신한카드',
      624363: 'NH농협은행',
      6244: '하나카드',
      624449: 'NH농협은행',
      624450: 'NH농협은행',
      625006: 'KB국민카드',
      625104: '롯데카드',
      625111: 'NH농협은행',
      625112: 'NH농협은행',
      6253: 'BC카드',
      625804: 'KB국민카드',
      625817: '삼성카드',
      625904: '롯데카드',
      626261: '하나카드',
      624411: '삼성카드',
      636093: 'NH농협은행',
      636094: '우리은행',
      636189: '하나카드',
      653711: 'NH농협은행',
      653712: 'NH농협은행',
      6541: '비씨카드',
      6556: '비씨카드',
      657020: '우리카드',
      605615: '우리카드',
      621020: '우리카드',
      623393: '우리카드',
      625320: '우리카드',
      655620: '우리카드',
      621003: '기업은행',
      624303: '기업은행',
      625103: '기업은행',
      655603: '기업은행',
      655639: '경남은행',
      657339: '경남은행',

      9000: '직불카드',
      940010: '한국씨티은행',
      940701: '수협중앙회',
      940702: '수협중앙회',
      940915: '롯데카드',
      940919: '롯데카드',
      940951: '롯데카드',
      940983: '롯데카드',
      9410: 'BC카드',
      941009: '삼성카드',
      941029: '삼성카드',
      941034: '삼성카드',
      941061: '신한카드',
      941083: '신한카드',
      941085: '삼성카드',
      941088: '삼성카드',
      941090: '삼성카드',
      941098: '신한카드',
      942084: '삼성카드',
      942101: '삼성카드',
      941116: 'NH농협카드',
      941117: 'NH농협카드',
      941161: '신한카드',
      941181: '하나카드',
      9420: 'BC카드',
      942011: 'NH농협카드',
      942012: 'NH농협카드',
      942061: '신한카드',
      942090: '삼성카드',
      942150: '우체국카드',
      942520: '우리카드',
      9430: 'BC카드',
      943016: 'NH농협카드',
      943017: 'NH농협카드',
      943116: 'NH농협카드',
      943117: 'NH농협카드',
      943150: 'KDB산업은행',
      943645: 'KB국민카드',
      943646: 'KB국민카드',
      943666: 'KB국민카드',
      943667: 'KB국민카드',
      9440: '하나카드',
      944011: 'NH농협카드',
      944012: 'NH농협카드',
      944111: 'NH농협카드',
      944112: 'NH농협카드',
      944116: 'NH농협카드',
      944117: 'NH농협카드',
      944403: 'IBK카드',
      944420: '우리카드',
      944422: '우리카드',
      944520: '우리카드',
      944541: 'KB국민카드',
      944542: 'KB국민카드',
      944547: 'KB국민카드',
      944557: 'KB국민카드',
      944603: 'IBK카드',
      944711: 'NH농협카드',
      944712: 'NH농협카드',
      9460: 'BC카드',
      9461: 'BC카드',
      946316: 'NH농협카드',
      949013: '현대카드',
      949019: '현대카드',
      949028: '현대카드',
      949088: '현대카드',
      949094: 'KB국민카드',
      949097: '현대카드',
      949098: '알파원카드',
      949099: '카카오뱅크',
      949103: '카카오뱅크',
      949108: 'PAYCO카드',
      949113: '코나카드',
      949130: '나마네카드',
      949133: '동백전카드',
      949136: '토스유스카드',
      953003: '광주은행',
      941080: '신한카드',
      941082: '신한카드',
      941190: '신한카드',
      942062: '신한카드',
      942073: '신한카드',
      942078: '신한카드',
      944078: '신한카드',
      943520: '우리카드',
      947124: '우리카드',
      941051: '하나카드',
      941581: '하나카드',
      942025: '하나카드',
      944025: '하나카드',
      944081: '하나카드',
      943133: '현대카드',
      943135: '현대카드',
      943160: '현대카드',
      949015: '현대카드',
      949018: '현대카드',
      949085: '현대카드',
      940090: '씨티카드',
      940327: '씨티카드',
      940388: '씨티카드',
      943020: '바로비씨',
      944155: '바로비씨',
      944002: '산업은행',
      942003: '기업은행',
      944003: '기업은행',
      970003: '기업은행',
      970039: '경남은행',
      944032: '부산은행',
      944031: '대구은행',
      946031: '대구은행',
      970031: '대구은행',
      935071: '우체국은행',
      944071: '우체국은행',
      935023: 'SC제일은행',
      941696: '케이뱅크',
      944096: '케이뱅크',
      944696: '케이뱅크',
      941648: '신협은행',
      944648: '신협은행',
      944948: '신협은행',
      944949: '신협은행',
      949048: '신협은행',
      949049: '신협은행',
      944045: '새마을금고',
      970045: '새마을금고',
      941691: 'NH투자증권',
      944691: 'NH투자증권',
      944063: 'SK증권',
      944009: '유안타증권',
      944079: '동부증권',
      944061: '교보증권',
      944080: '유진투자증권',
      941643: '한국투자증권',
      944030: '미래에셋증권',
      944095: '상호저축은행',
      941642: '토스카드',
      944192: '카카오페이카드',
      954049: '차이카드',
      930144: '코나카드',
      944044: '코나카드',
      946044: '코나카드',
      946144: '코나카드',
      946544: '코나카드',
      946644: '코나카드',
      930037: '전북은행',
      947137: '전북은행',
    };

    return {
      /**
       * 카드회사 정보 조회.
       */
      getCompanyName: function(cardNumber) {
        let companyName = map[cardNumber.substring(0, 6)];
        if (!companyName) {
          companyName = map[cardNumber.substring(0, 4)];
        }

        return companyName || '카드';
      }
    };
  });
