'use strict';

angular.module('gmpApp')
  .service('asModel', function (settings, commonSVC) {
    const PA20Url = `${settings.pa20ApiUrl}/app/as`;

    return {
      // AS 주문 건별 등록
      add: function (params) {
        return commonSVC.sendUrl('POST', `${PA20Url}/add`, params);
      },
      // AS 상세
      detail: function (params) {
        return commonSVC.sendUrl('POST', `${PA20Url}/detail`, params);
      },
      // AS 수정
      edit: function (params) {
        return commonSVC.sendUrl('PUT', `${PA20Url}`, params);
      },
      // 상태 변경
      setStatus: function (params) {
        return commonSVC.sendUrl('PATCH', `${PA20Url}/status`, params);
      },
      // as 삭제
      delete: function (params) {
        return commonSVC.sendUrl('DELETE', `${PA20Url}`, params);
      },
      // as 복사
      copy: function (params) {
        return commonSVC.sendUrl('POST', `${PA20Url}/copy`, params);
      },
      // as 요청
      request: function (params) {
        return commonSVC.sendUrl('POST', `${PA20Url}/request`, params);
      },
      // as 합포장
      combine: function (params) {
        return commonSVC.sendUrl('PATCH', `${PA20Url}/combine`, params);
      },
      // as 주문분할
      divideBundle: function (params) {
        return commonSVC.sendUrl('PATCH', `${PA20Url}/divide`, params);
      },
      // 운송장출력
      issue: function (params) {
        return commonSVC.sendUrl('POST', `${PA20Url}/invoice-issue`, params);
      },
      //운송장 출력 후 변경데이터 저장
      setStateByIssue: function (params) {
        return commonSVC.sendUrl('PATCH', `${PA20Url}/invoice-state`, params);
      }
    };
  });
