'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentDeliveryListCtrl',
    function (// common
      $state, $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog,
      // info
      settings, userInfo,
      // SVC
      workSVC, commonSVC, shipmentSVC, onlineProductSVC, columnSVC, deliverySVC, delaySVC,
      // Model
      shipmentModel, commonModel, columnModel, atalkModel, systemModel,
      // List
      warehouseList, giftList, systemList, gridInfo, deliveryAllList, countryList, solCarrList, globalCarrList, supplierList, tagList, memoTemplateList
    ) {
      $scope.deliveryList = $rootScope.deliveryInfoList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];

      $scope.test = window.location.host.includes('localhost') || window.location.host.includes('staging');

      // 주문 엑셀 수정 사용 가능여부
      const excelEditUseYn = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;

      $scope.list_name = 'delivery';

      // 디버그 모드에서 주문분할이 불가능한 경우
      const noneDivideStatus = ['출고완료', '배송중', '배송완료', '구매결정', '판매완료'];
      // 디버그 모드가 아닐 시에도 클레임 주문은 주문분할해줘야 함
      const claimStatus = ['취소요청', '취소완료', '반품요청', '반품진행', '반품회수완료', '반품완료', '교환요청', '교환진행', '교환회수완료', '교환완료', '맞교환요청', '맞교환완료', '주문재확인'];

      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;
      $scope.bundle_group = {};
      let orderby; // 리스트 정렬기준 (주문서인쇄 정렬용)

      $scope.loadDataTable = gridInfo;
      $scope.loadShopData = {
        domestic: false,
        global: !$rootScope.accessGlobalYN
      };

      $scope.access = {
        global: $rootScope.accessGlobalYN
      };

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      // var $scope.bundle_group = null; // 묶음 코드
      // var invoiceSendAvailCount = 0; // 검색된 주문 건 중 운송장전송 가능 상태

      $scope.totalCount = 0;
      $scope.isManageStock = $rootScope.user_profile.sol_stock > 0 ? 'y' : 'n'; // 재고관리여부
      $scope.countList = { total: 0, decision: 0, completed: 0, shipping: 0, finished: 0 }; // 우측 아이콘 카운트
      $scope.selectCount = 'total';
      $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';

      let orderList = {};
      let prodList = {};    // SKU상품명
      let addProdList = {}; // 추가구매옵션명

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.delivery);

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return ['건별출고수량', 'SKU코드', 'SKU상품명', '매입처', '바코드', '총출고수량'].indexOf(f.header) === -1;
        });
      }

      // 대시보드에서 넘어오는 경우 설정이 제대로 안되는 경우가 있음
      if ($rootScope.side_search.page === 'delivery' && $rootScope.side_search.search) {
        $scope.selectedShopType = $rootScope.side_search.selectedShopType;
      }
      // 엑셀 일괄 수정 사용시 uniq 필요하여 필드목록에 추가함
      if (excelEditUseYn) {
        $scope.excelFieldList.splice(1, 0, { header: '주문고유번호', key: 'uniq', select: true });
      }

      /* 검색 및 버튼 관련 변수 */
      const domesticSearchTemplate = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          shopType: '',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          status: ['출고완료', '배송중', '배송완료', '구매결정', '판매완료'],
          ord_status_stat: ['출고완료', '배송중', '배송완료', '구매결정', '판매완료'],
          recent_month: 1,
          page: 'delivery',
          bundle_yn: true,
          delivery_vendor: '',        // 배송처
          // to_country_code: '',     // 수령자 국가
          gift_prod_use_yn: '',       // 규칙사은품 사용여부
          gift_prod_name: '',         // 규칙적용사은품
          multi_search_word: '',      // 멀티서치워드,
          multi_type: 'shop_sale_no', // 멀티서치 타입
          // excel_down_yn: '',
          memo_yn: '',  // 메모여부
          delay_status: '', // 배송지연 여부
          multi_shop_id: '',
          multi_deliver_vendor: '',
          multi_supplier_vendor: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,       // 검색전체 건수
          selectBundleCnt: 0,  // 선택 묶음건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송번호'), value: 'shop_ship_no' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('배송 희망일'), value: 'ship_hope_time' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'ord_confirm_time' },
            { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
            { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
            { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' },
            { label: gettextCatalog.getString('송장 입력일'), value: 'invoice_time' },
            { label: gettextCatalog.getString('배송 완료일'), value: 'ship_com_time' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('운송장 번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송처'), value: 'depot' },
            { label: gettextCatalog.getString('매입처'), value: 'supp' },
            { label: gettextCatalog.getString('사용자태그'), value: 'tag_no' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 글로벌 쇼핑몰 여부 확인
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('계정 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 매입처 선택
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'supp_vendor',
            item_list: supplierList.data.result || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: ''
          },
          // {
          //   // 국가
          //   title: gettextCatalog.getString('국가 선택'),
          //   search_name: 'to_country_code',
          //   item_list: countryList.data || [],
          //   item_key: 'ctry_name',
          //   item_value: 'ctry_cd',
          //   select_value: ''
          // },
          {
            // 규칙사은품규칙 구분
            title: gettextCatalog.getString('규칙사은품 사용여부'),
            search_name: 'gift_prod_use_yn',
            item_list: [{ text: '전체', value: 'all' }, { text: '사용', value: '1' }, { text: '미사용', value: '0' }],
            item_key: 'text',
            item_value: 'value',
            select_value: $scope.searchForm ? $scope.searchForm.gift_prod_use_yn : ''
          },
          {
            // 규칙적용 사은품
            title: gettextCatalog.getString('규칙적용사은품 선택'),
            search_name: 'gift_prod_name',
            item_list: giftList.data.results || [],
            item_key: 'gift_rule_name',
            item_value: 'gift_no',
            select_value: '',
            filter: function(option) {
              if ($scope.searchForm.gift_prod_use_yn === 'all') {
                return true;
              } else {
                return option.use_yn === Number($scope.searchForm.gift_prod_use_yn) && $scope.searchForm.gift_prod_use_yn;
              }
            }
          },
          // {
          //   // 엑셀다운 O/X
          //   title: gettextCatalog.getString('엑셀다운 O/X'),
          //   search_name: 'excel_down_yn',
          //   item_list: [{ key: 1, value: '엑셀다운 O' }, { key: 0, value: '엑셀다운 X' }],
          //   item_key: 'value',
          //   item_value: 'key',
          //   select_value: ''
          // },
          // {
          //   // 교환여부 O/X
          //   title: gettextCatalog.getString('교환여부 O/X'),
          //   search_name: 'exchange_ord_yn',
          //   item_list: [{ key: 1, value: '교환여부 O' }, { key: 0, value: '교환여부 X' }],
          //   item_key: 'value',
          //   item_value: 'key',
          //   select_value: ''
          // },
          {
            // 배송지연 여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 매칭여부
            title: gettextCatalog.getString('매칭 여부'),
            search_name: 'map_yn',
            item_list: [{ key: 1, value: '매칭 됨' }, { key: 0, value: '매칭 안됨' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 합포장여부
            title: gettextCatalog.getString('합포장 여부'),
            search_name: 'multi_bundle_yn',
            item_list: [{ key: 1, value: '합포장' }, { key: 0, value: '합포장 아님' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 사용자태그여부
            title: gettextCatalog.getString('사용자태그 여부'),
            search_name: 'tag_yn',
            item_list: [{ key: 1, value: '사용자태그 적용' }, { key: 0, value: '사용자태그 미적용' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: '',
            filter: function(option) {
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X') && !option.shop_cd.startsWith('X');
            }
          },
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'multi_deliver_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            search_word: ''
          },
          {
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'multi_supplier_vendor',
            item_list: supplierList.data.result || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            search_word: ''
          },
          {
            title: gettextCatalog.getString('사용자태그 선택'),
            search_name: 'multi_tag',
            item_list: tagList.data.results || [],
            item_key: 'tag_name',
            item_value: 'tag_no',
            search_word: ''
          }
        ]
      };

      const globalSearchTemplate = angular.copy(domesticSearchTemplate);

      // 글로벌 서치바의 경우 2.0과 다른부분이 있어 별도처리
      globalSearchTemplate.searchData.search_key_items = [
        { label: gettextCatalog.getString('전체'), value: '' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호1'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호2'), value: 'misc13' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('패키지번호'), value: 'pa_bundle_no' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
        { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
        { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
        { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
        { label: gettextCatalog.getString('주문자, 수령자 이메일'), value: 'order_email,to_email' },
        { label: gettextCatalog.getString('주문자, 수령자 주소'), value: 'to_addr1,to_addr2' },
        { label: gettextCatalog.getString('메모'), value: 'memo' },
        { label: gettextCatalog.getString('배송메세지'), value: 'ship_msg' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
      ];

      globalSearchTemplate.searchData.search_date_type = [
        { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
        { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
        { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
        { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
        { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
        { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
        { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' }
      ];

      globalSearchTemplate.searchData.search_multi_items = [
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('운송장(트래킹)번호'), value: 'invoice_no' },
        { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
        { label: gettextCatalog.getString('배송처'), value: 'depot' },
        { label: gettextCatalog.getString('사용자태그'), value: 'tag_no' }
      ];

      globalSearchTemplate.searchDetail.splice(3, 0, ...[
        {
          title: gettextCatalog.getString('국내택배사 선택'),
          search_name: 'carr_no',
          item_list: solCarrList.data.results.length ? solCarrList.data.results : $scope.deliveryList,
          item_key: 'carr_name',
          item_value: 'carr_no',
          select_value: ''
        },
        {
          title: gettextCatalog.getString('해외택배사 선택'),
          search_name: 'global_carr_no',
          item_list: globalCarrList,
          item_key: 'carr_view_name',
          item_value: 'carr_no',
          select_value: '',
          filter: function (option) {
            return option.carr_no !== -1;
          },
        },
        {
          title: gettextCatalog.getString('송장 출력여부'),
          search_name: 'invoice_print_time_yn',
          item_list: [
            { name: '출력완료', code: 1 },
            { name: '출력대기', code: 0 },
          ],
          item_key: 'name',
          item_value: 'code',
          select_value: '',
        },
      ]);

      globalSearchTemplate.searchDetail = globalSearchTemplate.searchDetail.filter(item => !['gift_prod_use_yn', 'gift_prod_name', 'delay_status', 'ship_method'].includes(item.search_name));

      let domesticSearch = angular.copy(domesticSearchTemplate), globalSearch = angular.copy(globalSearchTemplate);

      function setSearch(search) {
        $scope.searchData = search.searchData;
        $scope.searchForm = search.searchForm;
        $scope.searchDetail = search.searchDetail;
        $scope.searchDetailMulti = search.searchDetailMulti;
        $scope.searchDetailMultiSelect = search.searchDetailMultiSelect;
      }

      function resetSearch() {
        const showCount = $scope.searchData?.showCount;
        let search;
        if ($scope.selectedShopType === 'global') {
          search = globalSearch = angular.copy(globalSearchTemplate);
        } else {
          search = domesticSearch = angular.copy(domesticSearchTemplate);
        }
        if (showCount) {
          search.searchData.showCount = showCount;
        }
      }

      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="picon-get"></i><span> ${gettextCatalog.getString('주문 수집')}</span>`,
            small_label: '주문수집',
            test_id: 'btn-order-collect',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.delivery+write'],
            action: function() {
              $scope.scrapOrderConfirmList();
            }
          },
          {
            label: `<i class="icon-sync worksvc-spinner-orderSync order"></i><span> ${gettextCatalog.getString('주문 동기화')}</span>`,
            small_label: '주문동기화',
            test_id: 'btn-order-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.delivery+write'],
            action: function() {
              $scope.orderSync();
            }
          }
        ],
        table_actions_top: [
          {
            label: gettextCatalog.getString('SKU상품매칭'),
            test_id: 'btn-product-match',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-shapes',
            action: function () {
              $scope.mappingProd();
            },
            perm_only: ['order.order+write'],
            ngIfFunc: function () {
              return $scope.isManageStock;
            }
          },
          {
            label: gettextCatalog.getString('주문서인쇄'),
            test_id: 'btn-order-print',
            icon_class: 'pui-description',
            action: function () {
              $scope.orderPrint();
            },
            perm_only: ['order.delivery']
          },
          {
            label: gettextCatalog.getString('운송장 전송'),
            test_id: 'btn-delivery-post',
            icon_class: 'pui-send',
            action: function () {
              $scope.invoiceSender();
            },
            perm_only: ['order.delivery+write'],
            ngIfFunc: function () {
              return $rootScope.user_profile.sol_no !== 2244 ? 'y' : 'n';
            }
          },
          {
            label: gettextCatalog.getString('운송장재출력'),
            test_id: 'btn-delivery-reprint',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-order_play',
            action: function () {
              $scope.printInvoice();
            },
            ngIfFunc: function () {
              return ['total', 'finished', 'shipping'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.delivery+write']
          },
          {
            label: gettextCatalog.getString('배송일 변경'),
            test_id: 'btn-delivery-reprint',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-calendar',
            tooltip: "'희망일배송'이 가능한 스마트스토어 상품 주문의 배송희망일을 변경할 수 있습니다.",
            action: function () {
              $scope.updateHopeDate();
            },
            perm_only: ['order.order+write'],
            // 스마트스토어 계정 자체를 안가지고 있으면 해당 버튼 안보여줌
            ngIfFunc: () => {
              return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === 'A077') && $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          }
        ],
        table_states_top: [
          {
            label: '전체',
            test_id: 'btn-state-total',
            icon_class: 'pui-storefront',
            count_name: 'total'
          },
          {
            label: '출고완료',
            test_id: 'btn-state-finished',
            icon_class: 'pui-file_download_done',
            count_name: 'finished'
          },
          {
            label: '배송중',
            test_id: 'btn-state-shipping',
            icon_class: 'pui-local_shipping',
            count_name: 'shipping'
          },
          {
            label: '배송완료',
            test_id: 'btn-state-completed',
            icon_class: 'pui-package',
            count_name: 'completed'
          },
          {
            label: '구매결정',
            test_id: 'btn-state-decision',
            icon_class: 'pui-assignment_turned',
            count_name: 'decision'
          },
          {
            label: '판매완료',
            test_id: 'btn-state-end',
            icon_class: 'pui-task_alt',
            count_name: 'end'
          }
        ],
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                test_id: 'btn-order-copy',
                action: function () {
                  $scope.copyOrder();
                },
                perm_only: ['order.delivery+write']
              }, {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                action: function () {
                  $scope.deleteOrder();
                },
                perm_only: ['order.delivery+admin']
              }, {
                label: `${gettextCatalog.getString('판매금액 복구')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)" tooltip-placement="right">`,
                menu_tooltip: `판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)`,
                action: function () {
                  $scope.restoreSupplyPrice();
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('SMS 발송'),
                test_id: 'btn-send-sms',
                action: function () {
                  $scope.sendModal('sms');
                },
                perm_only: ['order.order+admin'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('알림톡 발송'),
                action: function () {
                  $scope.altalkSend();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('알림톡 그룹 회원 추가'),
                action: function () {
                  $scope.altalkGroupMemAdd();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
              {
                label: `${gettextCatalog.getString(`주문분할${$rootScope.adminMode ? '(DEV)' : ''}`)}<i class="icon-help menu-tooltip fl-r" uib-tooltip="묶음 주문을 한 건씩 일괄 분할 합니다." tooltip-placement="right">`,
                menu_tooltip: '묶음 주문을 한 건씩 일괄 분할 합니다.',
                test_id: 'btn-order-split',
                action: function () {
                  $scope.divideBundle();
                },
                perm_only: ['order.delivery+write'],
                ngIfFunc: () => {
                  return ($rootScope.adminMode || !!$scope.grid.methods.selectedData('ord_status').find(s => claimStatus.includes(s))) ? 'y' : 'n';
                }
              }
            ]
          },
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            test_id: 'drop-excel',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
                menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
                test_id: 'btn-excel-intergrate',
                action: function () {
                  $scope.downloadIntegratedExcel();
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: gettextCatalog.getString('배송정보 업로드'),
                test_id: 'btn-delivery-upload',
                action: function () {
                  $scope.deliveryInfoEdit();
                },
                perm_only: ['order.delivery+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('엑셀 일괄 수정'),
                action: function () {
                  $scope.excelUpload('edit');
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' && excelEditUseYn ? 'y' : 'n';
                },
                perm_only: ['order.delivery+write']
              }
            ]
          },
          {
            label: '<span class="pl-5">추가 기능</span>' + '<span class="caret"></span>',
            test_id: 'btn-etc',
            btn_type: 'dropdown',
            add_class: 'a-btn-blue',
            item_list: [
              {
                label: gettextCatalog.getString('판매금액 관리'),
                action: function () {
                  $scope.supplyPriceManager();
                },
                perm_only: ['order.order+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
            ],
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            }
          },
        ],
        table_actions_btn: [

          {
            label: gettextCatalog.getString('배송완료'),
            test_id: 'btn-delivery-post',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.sendDeliveryEnd();
            },
            perm_only: ['order.delivery+write'],
            ngIfFunc: function () {
              return $rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no && ['total', 'shipping'].includes($scope.selectCount) ? 'y' : 'n';
            }
          }

        ]
      };
      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function () {
          $scope.searchDo(true, true);
        },
        resetDo: function () {
          $scope.resetDo();
        },
        changeLen: function (count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      const contextList = [
        {
          label: gettextCatalog.getString('배송중으로 상태변경'),
          action: function() {
            $scope.userChangeStatus('배송중');
          },
          filter: function(row) {
            // 복사생성한 주문이거나 반자동주문인경우 배송중으로 수동변경 가능해야함
            const isCopied = !_.isNull(row.ori_uniq);
            const ismanualOrderShop = userInfo.user.manualOrderShops.some(o => o.shop_cd === row.shop_cd);

            return (
              ['출고완료'].includes(row.ord_status) &&
              ((row.shop_cd === 'A000' || isCopied || ismanualOrderShop) ||
              !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no))
            );
          }
        }, {
          label: gettextCatalog.getString('배송완료로 상태변경'),
          action: function() {
            $scope.userChangeStatus('배송완료');
          },
          filter: function(row) {
            return ['출고완료', '배송중', '배송완료'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }, {
          label: gettextCatalog.getString('구매결정으로 상태변경'),
          action: function() {
            $scope.userChangeStatus('구매결정');
          },
          filter: function(row) {
            return ['배송중', '배송완료', '구매결정'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }, {
          label: gettextCatalog.getString('판매완료로 상태변경'),
          action: function() {
            $scope.userChangeStatus('판매완료');
          },
          filter: function(row) {
            return ['배송중', '배송완료', '구매결정'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }, {
          label: gettextCatalog.getString('취소요청으로 상태변경'),
          action: function() {
            $scope.userChangeStatus('취소요청');
          },
          filter: function(row) {
            return ['출고완료', '취소요청'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }, {
          label: gettextCatalog.getString('반품요청으로 상태변경'),
          action: function() {
            $scope.userChangeStatus('반품요청');
          },
          filter: function(row) {
            return ['출고완료', '배송중', '배송완료', '반품요청'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }, {
          label: gettextCatalog.getString('교환요청으로 상태변경'),
          action: function() {
            $scope.userChangeStatus('교환요청');
          },
          filter: function(row) {
            return ['출고완료', '배송중', '배송완료', '교환요청'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        }];

      if ($rootScope.adminMode) {
        contextList.push({
          label: gettextCatalog.getString('출고완료로 상태변경(DEV)'),
          action: function() {
            $scope.userChangeStatus('출고완료');
          },
          filter: function(row) {
            return ['출고완료', '배송중', '배송완료', '구매결정'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        });
        contextList.push({
          label: gettextCatalog.getString('운송장출력으로 상태변경(DEV)'),
          action: function() {
            $scope.userChangeStatus('운송장출력');
          },
          filter: function(row) {
            return ['출고완료', '배송중', '배송완료', '구매결정'].includes(row.ord_status) && (row.shop_cd === 'A000' || !(userInfo.user.agency_c_no === 208224 && row.invoice_no && row.carr_no));
          }
        });
      }

      $scope.customContext = [{
        isSubMenu: true,
        line: true,
        subMemuName: '상태 수동변경',
        contextList: contextList
      }];

      // 이노서브 더존 양식 다운 메뉴 추가
      if ([1143, 1].includes($rootScope.user_profile.sol_no)) {
        const excelAction = $scope.searchBtn.table_actions.find(({ label }) => label.includes('엑셀'));

        excelAction.item_list.splice(3, 0, {
          label: '더존 양식 다운',
          isSubMenu: true,
          item_list: [
            {
              label: gettextCatalog.getString('전체 주문 다운로드'),
              action: function() {
                $scope.excelDown('all', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            },
            {
              label: gettextCatalog.getString('선택 주문 다운로드'),
              action: function() {
                $scope.excelDown('select', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            }
          ]
        });
      }

      // A/S(수선) 권한
      if ($rootScope.userProfileCheck('sol_ser', 'ord_as', 'like')) {
        $scope.searchBtn.table_actions[0].item_list.push(
          {
            label: gettextCatalog.getString('A/S 요청'),
            test_id: 'btn-send-sms',
            action: function () {
              $scope.asRequest();
            },
            perm_only: ['order.order+admin'],
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            }
          }
        );
      }

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      // // 묶음순번 확인변수
      // var preBundleNo = '';
      // var viewRowNum = 1;

      /* 데이터 테이블 */
      $scope.domestic = {};
      $scope.domestic.methods = {};
      $scope.domestic.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: ['notice_msg', 'ord_time', 'pay_time', 'ord_status_mdate', 'ord_confirm_time', 'out_time', 'depot_name', 'order_tel', 'order_htel', 'to_tel', 'to_htel', 'c_sale_cd', 'sku_cd', 'stock_cd', 'prod_name', 'pack_unit', 'total_cnt', 'ship_method', 'shop_cost_price', 'ship_delay_yn', 'ship_plan_date', 'last_exceldown_time', 'shop_ship_no', 'model_no', 'seller_discount', 'shop_discount', 'coupon_discount', 'point_discount', 'ship_hope_time', 'order_msg', 'shop_ord_no_real', 'invoice_time', 'tag_pack', 'ship_com_time', 'prod_name,attri'],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'uniq'
        },
        page: 'delivery',
        addColGrid: !$rootScope.user_profile.pa_sol_no, // LG 대리점 계정은 컬럼추가 작업 불가능
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
          requestWillAction: function(data) {
            $scope.searchForm.shopType = 'domestic';

            if ($rootScope.order_search.page === 'delivery' && $rootScope.order_search.search) {
              $scope.searchForm.search_word = $rootScope.order_search.input;
              $scope.searchForm.search_key = $rootScope.order_search.key;
              $scope.searchData.search_key_name = $rootScope.order_search.name;
              $rootScope.order_search.search = false;
            }

            if ($rootScope.side_search.page === 'delivery' && $rootScope.side_search.search) {
              $scope.searchForm.date_type = 'wdate';
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;
              $scope.searchForm.status = $rootScope.side_search.status;
              $scope.selectCount = $rootScope.side_search.selectCount;
              $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
              $scope.delay_status = $rootScope.side_search.delay_status;
              $rootScope.side_search.search = false;
            }

            data = angular.merge({}, data, $scope.searchForm);

            orderby = data.orderby;

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            // SKU상품 정보 처리
            prodList = {};
            addProdList = {};
            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) {
                  addProdList[subRow.uniq] = [];
                }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) {
                  prodList[subRow.uniq] = [];
                }

                prodList[subRow.uniq].push(subRow);
              }
            });

            // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
            const timer = setInterval(async () => {
              if ($scope.selectedShopType !== 'domestic' || $scope.loadShopData.global) {
                $scope.searchData.totalCount = result.recordsTotal;
                $scope.totalCount = result.recordsTotalCount;
                $scope.domesticTotalCount = result.recordsTotalCount;
                // invoiceSendAvailCount = json.availCount['운송장전송_avail_cnt'];

                // 합포장 분리시 필요
                $scope.bundle_group = _.groupBy(result.results, 'bundle_no');

                // 합포장 갯수
                $scope.bundleCnt = result.recordsTotal;

                orderList = result.results;

                columnModel.setOld(_.cloneDeep(result.results), 'delivery');

                init(result.statusCount);

                // 전체 주문 조회 시 해당 탭 주문 건 없을 경우 다른 탭으로 조회 하여 보여줌
                if ($rootScope.accessGlobalYN && ($rootScope.order_search.page === 'delivery' ||
                $rootScope.side_search.page === 'delivery')
                && !$scope.domesticTotalCount && $scope.checkNoneOrd) {
                  $scope.selectShopType('global');

                  $scope.checkNoneOrd = false;
                }

                // 추가 컬럼 숫자 변경
                columnSVC.addColIntSet('all', result.results);

                $scope.loadShopData.domestic = true;
                clearInterval(timer);
                $timeout(() => {});
              }
            }, 100);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            template: function(row) {
              const divideAdmin = $rootScope.adminMode ? !noneDivideStatus.includes(row.ord_status) : claimStatus.includes(row.ord_status);

              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
                <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>
                <button class="btn btn-default btn-xxs" ng-if="${$rootScope.adminMode ? true : divideAdmin}" ng-click="${divideAdmin} && grid.appScope.divideBundle('${row.bundle_no}','${row.uniq}', '${row.ord_status}')" ng-class="{'btn-default-no': ${divideAdmin}}" ng-disabled="${!divideAdmin} || grid.appScope.bundle_group[${row.bundle_no}].length < 2">주문분할</button>`;
            }
          },
          {
            key: 'bundle_no',
            title: '묶음번호',
            width: 170
          },
          {
            key: 'map_yn',
            title: '매칭여부',
            requireStock: true,
            width: 80,
            template: function(row) {
              return row.map_yn
                ? `<button class="btn btn-default btn-xxs" ng-disabled="${$rootScope.osse_sol}" ng-click="grid.appScope.mappingProd('${
                  row.uniq
                }','${
                  row.wdate
                }')" >수정</button>`
                : `<button class="btn bg-white border-warning-300 text-warning-300 btn-xxs" ng-click="grid.appScope.mappingProd('${
                  row.uniq
                }','${
                  row.wdate
                }')" >미매칭</button>`;
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
                return (
                  `<span>
                  <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                  <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${
                  row.shop_cd
                  }','${
                    row.shop_id
                  }','${
                    sale_no
                  }')"></i></span>`
                );
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            notCompile: true,
            template: function(row) {
              //이미지+상품명
              const exchangeSpan =
                row.exchange_ord_yn == 1 || row.exchange_yn == 1
                  ? '<span class="label label-default bg-blue">교환주문</span> '
                  : '';
              // 클레임 주문에 대한 사본주문은 플래그 노출
              const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
              let giftSpan = '';
              let arrivalGuaranteed = '';
              let shipHopeDate = '';

              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가 및 도착보장 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
                arrivalGuaranteed = ['true', 'is_arrival_guaranteed'].includes(row.misc17) ? '<span><img ng-src="/assets/images/naver_flagX2.png" width="75" height="25" class="mr-5" alt="" src="/assets/images/naver_flagX2.png"></span>' : '';
                shipHopeDate = ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(row.misc19) ? '<span class="label bg-delivery">희망일배송</span> ' : '';
              }

              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return (
                  `<span>${
                    exchangeSpan
                  }${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}<img src='${
                    img
                  }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                    filteredShopSaleName
                  }</span>`
                );
              } else {
                return `${exchangeSpan}${arrivalGuaranteed}${shipHopeDate}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {
              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'shop_add_opt_name',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function(row) {
              // "추가구매옵션1=2;추가구매옵션2=3"
              let return_value = '';
              const retVals = [];

              if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

                _(addProdList[row.uniq])
                  .groupBy('ord_opt_seq')
                  .map(arr => {
                    retVals.push(`${arr[0].ord_opt_name}-${arr[0].opt_sale_cnt}개`);
                  })
                  .value();

                return_value = retVals.join(', ');
              }

              return return_value;
            }
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 120
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
              const carr_no = row.carr_no;

              if (
                carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
              ) {
                return (
                  `<span>${
                    invoice_no
                  }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                    carr_no
                  }','${
                    invoice_no.replace(/-/gi, '')
                  }')"></i></span>`
                );
              } else {
                return row.invoice_no;
              }
            }
          },
          {
            key: 'invoice_time',
            title: '송장입력일',
            tooltip: '운송장번호를 처음 입력한 일시',
            width: 130,
            template: function(row) {
              return !row.invoice_time ? '-' : moment(row.invoice_time).format('YYYY-MM-DD HH:mm:ss');
            }
          },
          {
            key: 'invoice_send_time',
            title: '송장 전송일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'to_name',
            title: '수령자명',
            name: 'bundle_no',
            width: 100
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'to_addr1',
            title: '주소',
            width: 300,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.to_addr1;

              if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value += row.to_addr2;
              }

              return return_value;
            }
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'shop_ship_no',
            title: '배송번호',
            width: 140
          },
          {
            key: 'ord_bundle_gift_terms_prod',
            title: '규칙적용사은품',
            tooltip: '솔루션에서 설정한 규칙이 적용된 사은품입니다.\n묶음 주문은 적용된 규칙 사은품이 각 주문 별로 모두 출력됩니다.',
            name: 'gift_prod_name',
            width: 150,
            template: function (row) {
              // 사은품명1#SKU코드1#재고코드1#속성1#개수1#사은품명1(sku명X)#사은품규칙명1#바코드1|사은품명2#SKU코드2#재고코드2#속성2#개수2#사은품명2(sku명X)#사은품규칙명2#바코드2
              let gift_prod_name = '';

              if (row.gift_pack) {
                const regex = /([^|]*?(?:[^#]*#){7}[^#]*?)(?=\|)/g;

                // 사은품명에 특수문자 '|' 포함한 경우가 있어서 #가 7번 반복된 이후 나오는 '|' 기준으로 사은품 쪼개기
                const giftList = [];
                let match;
                let prevIdx = 0;
                let idx = 0;

                while ((match = regex.exec(row.gift_pack))) {
                  giftList.push(idx ? match[0].substring(1) : match[0]);
                  prevIdx = regex.lastIndex;
                  idx++;
                }

                giftList.push(prevIdx ? row.gift_pack.substring(prevIdx).substring(1) : row.gift_pack);

                gift_prod_name = giftList.map(gift => {
                  const vals = gift.split('#'); // [0]: 사은품명, [1]: SKU코드, [4]: 사은품출고개수

                  return `${vals[0]}-${vals[4]}개`;
                }).join(', ');
              }

              return gift_prod_name;
            }
          },
          {
            key: 'gift_name',
            title: '사은품',
            width: 100
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_status_mdate',
            title: '상태변경일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_confirm_time',
            title: '주문확인일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'out_time',
            title: '출고완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ship_com_time',
            title: '배송완료일',
            tooltip: `[주문 동기화] 기능 또는 직접 상태를 변경하여 주문의 상태가 “배송완료”로 변경된 일시입니다.
실제로 배송이 완료된 시점과 다를 수 있으니 참고용으로 활용해 주세요.`,
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            name: 'bundle_no',
            width: 150
          },
          {
            key: 'tag_pack',
            title: '사용자태그',
            tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
            width: 130,
            template: function(row) {
              // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
              return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 120
          },
          {
            key: 'sku_cd',
            title: 'SKU코드',
            requireStock: true,
            width: 160,
            template: function(row) {
              if (row.set_no && prodList[row.uniq]) {
                return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq].length > 1 ?
                  `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                  : prodList[row.uniq][0].sku_cd;
              } else {
                return '미매칭 출고';
              }
            }
          },
          {
            key: 'stock_cd',
            title: '재고관리코드',
            tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
            requireStock: true,
            width: 160,
            template: (row) => {
              if (row.set_no && prodList[row.uniq]) {
                const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

                return findList ? findList.stock_cd : '';
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq][0].stock_cd || '';
              } else {
                return '';
              }
            }
          },
          {
            key: 'prod_name',
            title: 'SKU상품명',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'attri',
            title: 'SKU속성',
            width: 130,
            notCompile: true,
            template: function(row) {
              return prodList[row.uniq]?.filter(prod => prod.attri).map(prod => prod.attri).join(',') || '';
            }
          },
          {
            key: 'prod_name,attri',
            title: 'SKU상품명_속성',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'pack_unit',
            title: '건별출고수량',
            tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
            requireStock: true,
            width: 110,
            notCompile: true
          },
          {
            key: 'total_cnt',
            title: '총 출고수량',
            tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
            width: 100,
            template: function(row) {
              return row.out_cnt;
            }
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120
          },
          {
            key: 'ship_delay_yn',
            title: '배송지연여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.ship_delay_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'multi_bundle_yn',
            title: '묶음주문여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.multi_bundle_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'ship_plan_date',
            title: '발송예정일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'last_exceldown_time',
            title: '엑셀 다운일시',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'order_msg',
            title: '추가메세지',
            width: 200
          },
          {
            key: 'supp_name',
            title: '매입처',
            width: 130,
            notCompile: true,
            template: row => {
              return prodList[row.uniq] ? (prodList[row.uniq].map(prod => prod.supp_no ? prod.supp_name : '없음')) : '';
            }
          },
          {
            key: 'ship_hope_time',
            title: '희망배송일',
            width: 130,
            filter: 'dateValid'
          },
        ],
        initShowCount: function(showCount) {
          setTimeout(() => {
            domesticSearch.searchData.showCount = showCount;
          }, 100);
          if ($scope.selectedShopType !== 'domestic') {
            setTimeout(() => {
              $scope.searchData.showCount = globalSearch.searchData.showCount;
            }, 200);
          }
        }
      };

      $scope.global = angular.copy($scope.domestic);
      $scope.global.options.notVisibleColumns = ['shop_add_opt_name', 'notice_msg', 'ord_time', 'pay_time', 'ord_status_mdate', 'gprivate_no', 'order_tel', 'order_htel', 'to_tel', 'to_email', 'stock_cd', 'prod_name', 'attri', 'pack_unit', 'total_cnt', 'ship_method', 'shop_supply_price', 'last_exceldown_time', 'order_msg', 'bundle_avail_yn', 'supp_name', 'misc10', 'misc14', 'misc15', 'barcode', 'out_time', 'weight', 'order_email', 'madein', 'pa_bundle_no', 'supply_price', 'tag_pack', 'prod_name,attri'];
      $scope.global.options.externalRequestOptions = {
        requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
        requestWillAction: function(data) {
          $scope.searchForm.shopType = 'global';

          if ($rootScope.order_search.page === 'delivery' && $rootScope.order_search.search) {
            $scope.searchForm.search_word = $rootScope.order_search.input;
            $scope.searchForm.search_key = $rootScope.order_search.key;
            $scope.searchData.search_key_name = $rootScope.order_search.name;
            $rootScope.order_search.search = false;
          }

          if ($rootScope.side_search.page === 'delivery' && $rootScope.side_search.search) {
            $scope.searchForm.date_type = 'wdate';
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.searchForm.status = $rootScope.side_search.status;
            $scope.selectCount = $rootScope.side_search.selectCount;
            $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
            $scope.delay_status = $rootScope.side_search.delay_status;
            $rootScope.side_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          orderby = data.orderby;

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          // SKU상품 정보 처리
          prodList = {};
          addProdList = {};
          result.results_prod.forEach(function(subRow) {
            if (subRow.add_opt_yn == 1) {
              if (!addProdList[subRow.uniq]) {
                addProdList[subRow.uniq] = [];
              }

              addProdList[subRow.uniq].push(subRow);

            } else {
              if (!prodList[subRow.uniq]) {
                prodList[subRow.uniq] = [];
              }

              prodList[subRow.uniq].push(subRow);
            }
          });

          // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
          const timer = setInterval(async () => {
            if ($scope.selectedShopType !== 'global' || $scope.loadShopData.domestic) {
              $scope.searchData.totalCount = result.recordsTotal;
              $scope.totalCount = result.recordsTotalCount;
              $scope.globalTotalCount = result.recordsTotalCount;
              // invoiceSendAvailCount = json.availCount['운송장전송_avail_cnt'];

              // 합포장 분리시 필요
              $scope.bundle_group = _.groupBy(result.results, 'bundle_no');

              // 합포장 갯수
              $scope.bundleCnt = result.recordsTotal;

              orderList = result.results;

              columnModel.setOld(_.cloneDeep(result.results), 'delivery');

              init(result.statusCount);

              // 추가 컬럼 숫자 변경
              columnSVC.addColIntSet('all', result.results);

              $scope.loadShopData.global = true;
              clearInterval(timer);
              $timeout(() => {});
            }
          }, 100);

          return result.results;
        }
      };
      $scope.global.options.columns = [
        {
          key: 'widget',
          title: '도구',
          width: $rootScope.adminMode ? 150 : 100,
          template: function(row) {
            const divideBtnYN = !noneDivideStatus.includes(row.ord_status);
            const divideButton = `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.divideBundle('${row.bundle_no}','${row.uniq}', '${row.ord_status}')" ng-disabled="${!divideBtnYN} || grid.appScope.bundle_group[${row.bundle_no}].length < 2">주문분할</button>`;

            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}', true)" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>
              ${$rootScope.adminMode ? divideButton : ''}`;
          }
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 170
        },
        {
          key: 'map_yn',
          title: '매칭여부',
          requireStock: true,
          width: 80,
          template: function(row) {
            return row.map_yn
              ? `<button class="btn btn-default btn-xxs" ng-disabled="${$rootScope.osse_sol}" ng-click="grid.appScope.mappingProd('${
                row.uniq
              }','${
                row.wdate
              }')" >수정</button>`
              : `<button class="btn bg-white border-warning-300 text-warning-300 btn-xxs" ng-click="grid.appScope.mappingProd('${
                row.uniq
              }','${
                row.wdate
              }')" >미매칭</button>`;
          }
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호1',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'misc13',
          title: '쇼핑몰 주문번호2',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function(row) {
            if (row.shop_cd === 'A011') {
              row.shop_sale_no = row.misc16;
            }

            let sale_no = row.shop_sale_no || '';

            if (row.shop_cd === 'B378') { // 쿠팡
              sale_no = row.misc5;
              if (row.misc9) {
                sale_no += `?vendorItemId=${row.misc9}`;
              }
            } else if (row.shop_cd === 'B118') { // 메이크샵
              sale_no = row.misc3;
            } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
              sale_no += `?sitmNo=${row.misc17}`;
            }

            if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
              return (
                `<span>
                <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${
                row.shop_cd
                }','${
                  row.shop_id
                }','${
                  sale_no
                }')"></i></span>`
              );
            } else {
              return row.shop_sale_no;
            }
          }
        },
        {
          key: 'shop_sale_name',
          title: '온라인 상품명',
          width: 300,
          notCompile: true,
          template: function(row) {
            //이미지+상품명
            const exchangeSpan =
              row.exchange_ord_yn == 1 || row.exchange_yn == 1
                ? '<span class="label label-default bg-blue">교환주문</span> '
                : '';
            let giftSpan = '';
            // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가
            if (['A077', 'A000'].includes(row.pa_shop_cd)) {
              giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
            }            // 클레임 주문에 대한 사본주문은 플래그 노출
            const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
            const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
            const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return (
                `<span>${
                  exchangeSpan
                }${giftSpan}${claimCopyOrderSpan}<img src='${
                  img
                }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                  filteredShopSaleName
                }</span>`
              );
            } else {
              return `${exchangeSpan}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}`;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'misc17',
          title: '옵션코드',
          width: 200,
          filter: 'isNullHyphen'
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60,
          template: (row) => {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : row.sale_cnt;
          }
        },
        {
          key: 'shop_add_opt_name',
          title: '추가구매옵션',
          width: 150,
          notCompile: true,
          template: function(row) {
            // "추가구매옵션1=2;추가구매옵션2=3"
            let return_value = '';
            const retVals = [];

            if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

              _(addProdList[row.uniq])
                .groupBy('ord_opt_seq')
                .map(arr => {
                  retVals.push(`${arr[0].ord_opt_name}-${arr[0].opt_sale_cnt}개`);
                })
                .value();

              return_value = retVals.join(', ');
            }

            return return_value;
          }
        },
        {
          key: 'weight',
          title: '무게(g)',
          width: 80,
          template: function(row) {
            return row.misc16;
          }
        },
        {
          key: 'invoice_send_time',
          title: '송장 전송일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          notCompile: true,
          template: function(row) {
            //속성
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          name: 'bundle_no',
          width: 100
        },
        {
          key: 'ship_msg',
          title: '배송메세지',
          width: 200
        },
        {
          key: 'notice_msg',
          title: '기타메세지',
          width: 200
        },
        {
          key: 'to_addr1',
          title: '주소',
          width: 300,
          notCompile: true,
          template: function(row) {
            //속성
            let return_value = row.to_addr1;

            if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }
            if (row.to_addr2) {
              return_value += row.to_addr2;
            }

            return return_value;
          }
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(Number(row.sales).toFixed(2), '', 0);
          }
        },
        {
          key: 'ship_cost',
          title: '배송비',
          width: 100,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(Number(row.ship_cost).toFixed(2), '', 0);
          }
        },
        {
          key: 'global_carr_name',
          title: '해외택배사',
          width: 140
        },
        {
          key: 'global_invoice_no',
          title: '트래킹번호',
          width: 140
        },
        {
          key: 'carr_name',
          title: '국내택배사',
          width: 120
        },
        {
          key: 'invoice_no',
          title: '국내운송장번호',
          width: 150,
          template: function(row) {
            const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
            const carr_no = row.carr_no;

            if (
              carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
            ) {
              return (
                `<span>${
                  invoice_no
                }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                  carr_no
                }','${
                  invoice_no.replace(/-/gi, '')
                }')"></i></span>`
              );
            } else {
              return row.invoice_no;
            }
          }
        },
        {
          key: 'pa_bundle_no',
          title: '패키지번호',
          width: 130
        },
        {
          key: 'global_invoice_print_time',
          title: '송장 출력여부',
          width: 80,
          template: function(row) {
            return row.global_invoice_print_time ? '<span class="text-grey-300">출력완료</span>' : '<span class="text-success-600">출력대기</span>';
          }
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'pay_time',
          title: '결제완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_status_mdate',
          title: '상태변경일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_curr_cd',
          title: '기준통화',
          width: 50,
        },
        {
          key: 'out_time',
          title: '출고완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'order_tel',
          title: '주문자 전화번호',
          width: 150
        },
        {
          key: 'order_htel',
          title: '주문자 휴대폰번호',
          width: 150
        },
        {
          key: 'order_email',
          title: '주문자 이메일',
          width: 150
        },
        {
          key: 'to_tel',
          title: '수령자 전화번호',
          width: 150
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          name: 'bundle_no',
          width: 150
        },
        {
          key: 'tag_pack',
          title: '사용자태그',
          tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
          width: 130,
          template: function(row) {
            // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
            return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
          }
        },
        {
          key: 'to_email',
          title: '수령자 이메일',
          width: 150
        },
        {
          key: 'to_ctry_cd',
          title: '수령자 국가',
          width: 80,
          template: function(row) {
            return countryList.find(country => country.ctry_cd === row.to_ctry_cd)?.ctry_name || '';
          }

        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          requireStock: true,
          width: 160,
          template: function(row) {
            if (row.set_no && prodList[row.uniq]) {
              return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq].length > 1 ?
                `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                : prodList[row.uniq][0].sku_cd;
            } else {
              return '미매칭 출고';
            }
          }
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
          requireStock: true,
          width: 160,
          template: (row) => {
            if (row.set_no && prodList[row.uniq]) {
              const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

              return findList ? findList.stock_cd : '';
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq][0].stock_cd || '';
            } else {
              return '';
            }
          }
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'attri',
          title: 'SKU속성',
          width: 130,
          notCompile: true,
          template: function(row) {
            return prodList[row.uniq]?.filter(prod => prod.attri).map(prod => prod.attri).join(',') || '';
          }
        },
        {
          key: 'prod_name,attri',
          title: 'SKU상품명_속성',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'pack_unit',
          title: '건별출고수량',
          tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
          requireStock: true,
          width: 110,
          notCompile: true
        },
        {
          key: 'total_cnt',
          title: '총 출고수량',
          tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
          width: 100,
          template: function(row) {
            return row.out_cnt;
          }
        },
        {
          key: 'ship_method',
          title: '배송방법',
          width: 120,
          template: function (row) {
            return row.misc12;
          }
        },
        {
          key: 'sales_price',
          title: '쇼핑몰 판매금액',
          tooltip: '할인금액 * 수량 (Voucher 등의 장바구니 할인 제외)',
          template: function(row) {
            return Number(row.sales_unit * row.sale_cnt).toFixed(2);
          },
          width: 120,
        },
        {
          key: 'sales_unit',
          title: '쇼핑몰 판매단가',
          tooltip: '개당 할인금액 (Voucher 등의 장바구니 할인 제외)',
          width: 120,
          template: function(row) {
            return Number(row.sales_unit).toFixed(2);
          }
        },
        {
          key: 'multi_bundle_yn',
          title: '묶음주문여부',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.multi_bundle_yn
              ? '<i class="icon-primitive-dot text-success-600"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'misc15',
          title: '제조국(원산지)',
          width: 80
        },
        {
          key: 'misc14',
          title: 'HS코드',
          width: 80
        },
        {
          key: 'ship_plan_date',
          title: '발송예정일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'misc10',
          title: '발송기한',
          width: 130,
          filter: 'dateValid',
          template: function(row) {
            return moment(row.misc10).format('YYYY-MM-DD hh:mm:ss');
          }
        },
        {
          key: 'last_exceldown_time',
          title: '엑셀 다운일시',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'order_msg',
          title: '추가메세지',
          width: 200
        },
        {
          key: 'supp_name',
          title: '매입처',
          width: 130,
          notCompile: true,
          template: row => {
            return prodList[row.uniq] ? (prodList[row.uniq].map(prod => prod.supp_no ? prod.supp_name : '없음')) : '';
          }
        },
        {
          key: 'bundle_avail_yn',
          title: '합포장 가능여부',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'shop_supply_price',
          title: '공급가',
          width: 100,
          template: function (row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(Number(row.shop_supply_price).toFixed(2), '', 0);
          }
        },
        {
          key: 'gprivate_no',
          title: '개인통관번호',
          width: 150
        },
        {
          key: 'barcode',
          width: 80,
          title: '바코드'
        },
      ];
      $scope.global.options.initShowCount = function(showCount) {
        setTimeout(() => {
          globalSearch.searchData.showCount = showCount;
        }, 100);
        if ($scope.selectedShopType !== 'global') {
          setTimeout(() => {
            $scope.searchData.showCount = domesticSearch.searchData.showCount;
          }, 200);
        }
      };
      // 초기 탭 그리드 세팅
      $scope.grid = $scope[$scope.selectedShopType];

      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) {
        addColumnSet();
      }
      resetSearch();
      setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);

      async function addColumnSet() {
        const domesticParams = {
          gridOption: $scope.domestic.options,
          readOnly: false,
          addcolType: 'all',
          search_key_items: domesticSearchTemplate.searchData.search_key_items
        };
        const globalParams = {
          gridOption: $scope.global.options,
          readOnly: false,
          addcolType: 'all',
          search_key_items: globalSearchTemplate.searchData.search_key_items
        };

        // LG 대리점 사용계정인 경우 추가처리
        if ($rootScope.user_profile.pa_sol_no) {
          // 읽기만 가능한 컬럼으로 추가
          domesticParams.readOnly = true;
          globalParams.readOnly = true;
          // enum의 첫번째 값 디폴트로 사용
          domesticParams.useEnumFirstValDefault = true;
          globalParams.useEnumFirstValDefault = true;
        }

        columnSVC.columnsSet(domesticParams);
        columnSVC.columnsSet(globalParams);

        // LG 대리점 사용계정인 경우 커스텀컬럼 작업 불가능하게 처리
        if (!$rootScope.user_profile.pa_sol_no) {
          await columnSVC.saveBtnSet({
            grid: $scope.domestic,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.domestic.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: domesticSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          });
          await columnSVC.saveBtnSet({
            grid: $scope.global,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.global.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: globalSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'global' ? 'y' : 'n';
            },
          });
        }
      }

      // LG custom -> sub user 인경우 노출항목 추가.
      if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
        columnSVC.addColumns_LG($scope.domestic.options);
        columnSVC.addColumns_LG($scope.global.options);
      }

      /**
       * openapi 사용업체는 api 호출시간 필드 출력
       */
      if ($rootScope.userProfileCheck('sol_ser', 'openapi', 'like')) {
        columnSVC.addColumns_API($scope.domestic.options);
        columnSVC.addColumns_API($scope.global.options);
      }

      /**
       * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
       */
      $scope.$on('OnColumnChange', function(event, data) {
        _.each(data, function(row) {
          $compile(row)($scope);
        });
      });

      /**
       * 판매금액 관리 규칙 매니저
       */
      $scope.supplyPriceManager = () => {
        const modal = commonSVC.openModal('full', { data: { uniqList: $scope.grid.methods.selectedData('uniq'), shop_id_view_type: systemList.data.shop_id_view_type } }, 'SupplyPriceManagerCtrl', 'views/order/shipment/modals/supply_price_manager.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 판매금액 관리 규칙 적용복구
       */
      $scope.restoreSupplyPrice = async () => {
        const selectList = $scope.grid.methods.selectedData('bundle_no');

        if (!selectList.length) {
          commonSVC.showMessageHtml('선택된 주문이 없습니다.', '<ul style="margin-top:20px;"><li>복구할 주문을 선택하신 후 다시 진행해 주세요.</li></ul>');

          return false;
        }

        const confirm = await commonSVC.showConfirm('판매금액을 복구 하시겠습니까?',
          '· 판매금액 복구는 규칙을 적용 이 전의 상태로 되돌려 놓습니다.\n'
      + '· 판매금액 복구는 규칙이 적용된 주문에만 적용이 가능하며,\n'
      + '  특정항목만 복구는 불가합니다.(ex. 공급가만 복구 불가)\n'
      + '· 복구된 주문은 다시 규칙을 적용할 수 있으나, 단순히 복구 이 전의 상태로\n'
      + '  되돌려 놓는것은 불가능하니 적용 전에 충분히 고려해 주세요.\n'
      + '· 판매금액 관리 규칙 내 조건이 삭제되었거나 규칙자체가 삭제된 주문은 복구되지 않으며, 이런 경우 주문 [상세]에서 직접 수정할 수 있습니다.');

        if (confirm) {
          try {
            const re = await shipmentModel.restoreSupplyPriceRule({ bundleNoList: $scope.grid.methods.selectedData('bundle_no') });

            if (re.data.results === 'success') {
              $scope.searchDo(true, true);
              commonSVC.showToaster('success', '', '데이터 복구가 완료되었습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          } catch (err) {
            if (err.data.messages[0] === 'no restore data') {
              commonSVC.showToaster('error', '', '복구 가능한 데이터가 없습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          }
        }
      };

      /**
       * 2018-02-12 ally
       * SKU상품 매칭
       * */
      $scope.mappingProd = function(uniq, wdate) {

        // 주문재고매칭권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.mappingProd', userInfo.permission) === false) {
          return false;
        }

        const uniqList = uniq ? [uniq] : $scope.grid.methods.selectedData('uniq');

        if (uniqList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }
        const firstWdate = wdate ? [wdate] : _.sortBy($scope.grid.methods.selectedData('wdate'), function(o) {
          return new moment(o);
        });

        const resolve = {
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //선택한 uniqList
          selectList: { uniq: uniqList, wdate: firstWdate[0], isGlobal: $scope.selectedShopType === 'global' }
        };

        const redata = commonSVC.openModal('full', resolve, 'OrderShipmentMappingCtrl', 'views/order/shipment/modals/mapping.html');

        redata.result.then(function(isMapping) {
          if (isMapping) {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        resetSearch();
        setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);
        $scope.isMultiSearch = false;
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq, isGlobal) {
        const resolve = {
          data: {
            fromPage: domesticSearchTemplate.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        let modal;
        if (isGlobal) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }
        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
        // $scope.isDetailShow = show;
        // var selectRow = $scope.isDetailShow && rowIndex > -1 ? rowIndex : null; // 상세보기 누른 row index
        // $scope.grid.methods.ChangeColMode(show, [ 0, 2, 4, 10 ], selectRow);
        // if(show){
        //   $state.go("main.order_shipment_delivery_list.detail", {fromPage: 'delivery', rowIndex: rowIndex , uniq: uniq, ord_date: ord_date});
        //   $scope.grid.methods.autoSelect(true);
        // }else{
        //   $scope.grid.methods.autoSelect(false);
        // }
      };

      $scope.showSetDetail = function (setNo) {
        const resolve = {
          data: { from: '수정', set_no: setNo }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      // 다중 매칭된 SKU 조회 모달
      $scope.showMultiSKUDetail = function (uniq) {
        const resolve = {
          data: { prodList: prodList[uniq] }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      // btn event..
      /**
       * 카운팅에 맞는 검색 결과
       */
      $scope.countSearch = function (type) {
        // if ($scope.countList[type] !== 0 ) {
        const sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;

        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchForm.sdate = sdate;
        $scope.searchForm.edate = edate;
        switch (type) {
          case 'total':
            $scope.searchForm.status = ['출고완료', '배송중', '배송완료', '구매결정', '판매완료'];
            break;
          case 'finished':
            $scope.searchForm.status = ['출고완료'];
            break;
          case 'shipping':
            $scope.searchForm.status = ['배송중'];
            break;
          case 'completed':
            $scope.searchForm.status = ['배송완료'];
            break;
          case 'decision':
            $scope.searchForm.status = ['구매결정'];
            break;
          case 'end':
            $scope.searchForm.status = ['판매완료'];
            break;
        }

        $scope.searchDo(true, true);
        $scope.selectCount = type;
        // }
      };

      /**
       * 주문복사
       * */
      $scope.copyOrder = function() {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          selectList: selectList,
          selectedShopType: $scope.selectedShopType
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
            commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
          }
        });
      };

      /**
       * 주문삭제
       * */
      $scope.deleteOrder = function(number, bundle_code, uniq) {

        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          uniqList: param,
          fromPage: 'delivery'
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderDelete', 'views/order/shipment/modals/order_delete.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 발주전 주문수집
       */
      $scope.scrapOrderConfirmList = function() {
        // 주문수집 권한 체크 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.scrapOrder', userInfo.permission)) {
          commonSVC.openModal('md', { data: { work_type: 'scrap' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
        }
      };

      /**
       * 주문 동기화
       */
      $scope.orderSync = function() {
        //  sync2: GMP (sync: EBAY)
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
       * 운송장 전송
       *
       * 1초에 한번씩 작업 가능하도록 쓰로틀 추가
       */
      $scope.invoiceSender = _.throttle(function () {

        // 운송장 전송 권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.sendDelivNo', userInfo.permission) === false) {
          return false;
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length) {
          // 묶음건중에 하나라도 출고건이 있는경우 전송되어야 하므로 groupBy 로 조건 변경함 2018-07-11 rony
          // var dataNone = _.filter($scope.grid.methods.selectedData('all'), function (d) {
          const dataNone = _(selected)
            .groupBy('bundle_no')
            .filter(function (arr_d) {
              const imposCondition = _.some(arr_d, function (o) {
                // o.ori_uniq가 있어도 o.misc_etc?.[0]?.is_claim_copy가 없거나 1인 경우는 작업가능
                // is_claim_copy가 없으면 원주문, 1이면 쇼핑몰에서 교환요청 건에 대한 자체생성 신규주문, 0이면 수동생성 사본주문
                return o.ord_status !== '출고완료' || o.shop_cd === 'A000' || (o.shop_ord_no.includes('_copy') && o.ori_uniq) || o.shop_cd.startsWith('U') || (o.ori_uniq && o.misc_etc?.[0]?.is_claim_copy === 0);
              });

              // return d.ord_status != '출고완료' || d.shop_cd === 'A000' || d.shop_ord_no.indexOf('_copy') > -1;
              return imposCondition;
            })
            .value();

          const addText = [];
          // 롯데백화점(롯데닷컴) - 중동 - 네이버 운송장 전송 예외처리 추가 lucas
          if (dataNone.length || selected.some(ord => ord.sol_no === 2747 && ord.shop_cd === '1009' && ord.etc2 === '1')) {
            addText.push('쇼핑몰에서 수집한 주문건 중 \'출고완료\' 상태의 주문 및 복사되지 않은 주문만 전송이 가능합니다.');
          }
          // 롯데ON 주문 중 [교환주문] 플래그가 붙은 주문은 전송 불가
          if (selected.some(ord => ord.exchange_ord_yn === 1 && ord.shop_cd === 'A524')) {
            addText.push('롯데ON 주문 중 [교환주문] 플래그가 붙은 주문은 전송 불가능합니다');
          }

          if (addText.length) {
            const opt = {
              title: '운송장 전송 실패',
              text: `${addText.join('\n\n')}`,
              confirmButtonText: '운송장 전송 가능 상태 주문만 조회'
            };

            commonSVC.showConfirmCustom(opt, function () {
              const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                const rowFilter = r.ord_status === '출고완료' && r.shop_cd !== 'A000' && !r.shop_ord_no.includes('_copy') && !r.shop_cd.startsWith('U') && (r.shop_cd === 'A524' ? r.exchange_yn !== 1 : true) && !r.ori_uniq && !(r.sol_no === 2747 && r.shop_cd === '1009' && r.etc2 === '1');

                // dataNone 배열에 포함된 데이터는 false 리턴
                if (dataNone.some(arr_d => arr_d.includes(r))) {
                  return false;
                }

                return rowFilter;
              }, true);

              if (!reselected_indexes.length) {
                commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('운송장 전송이 가능한 건이 없습니다.'));
              }
            });

            return false;
          } else {
            // 묶음건중 출고완료건만 전송해야 하기때문에 출고완료건만 걸러냄 2018-07-11 rony
            const uniqs = _(selected)
              .filter(function (o) {
                return o.ord_status === '출고완료';
              })
              .map('uniq')
              .value();

            var params = { numbers: uniqs };

            commonSVC.showConfirmHtml('운송장 전송', `<b> 선택한 출고 완료 주문 (${selected.length})건의 운송장 정보를 쇼핑몰로 전송 합니다. </b> ` + '<br>전송 후 \'배송중\' 상태로 변경되며, 수정전송은 불가능하므로 주의바랍니다.', function (state) {
              if (state) {
                workSVC.addWorkSelect('SEND_INVOICE', params);
              }
            });
          }
        } else {
          params = {
            sdate: $scope.pageData.sdate,
            edate: $scope.pageData.edate,
            ebaydepotYn: 0,
            searchForm: $scope.pageData
          };

          shipmentModel.sendDelivNoShopList(params)
            .then(function (result) {
              const shopList = result.data;

              if (!shopList.length) {
                commonSVC.showMessage(gettextCatalog.getString('운송장 전송 가능한 주문이 없습니다.'));

                return false;
              }

              const resolve = {
                data: {
                  shopList: shopList
                }
              };

              commonSVC.openModal('lg', resolve, 'OrderShipmentSendInvoiceCtrl', 'views/order/shipment/modals/send_invoice.html');
            });
        }
      }, 1000);

      /**
       * 배송완료 전송
       */
      $scope.sendDeliveryEnd = async () => {
        const selectedData = $scope.grid.methods.selectedData('all');

        if (!selectedData.length) {
          commonSVC.showMessage('선택된 주문이 없습니다.');

          return false;
        }

        // GSSHOP, SSG통합, 홈앤쇼핑 && 배송중 && 원주문만 작업 가능
        if (selectedData.some(({ shop_cd, ori_uniq, ord_status }) => !['A011', 'A032', 'B614'].includes(shop_cd) || ori_uniq || ord_status !== '배송중')) {
          const opt = {
            title: '배송완료 전송 실패',
            text: 'GSSHOP, SSG(통합), 홈앤쇼핑 [배송중] 주문만 배송완료 전송 가능합니다.\n(복사주문건 제외)',
            type: 'warning',
            confirmButtonText: '배송완료 전송 가능한 주문만 선택'
          };

          commonSVC.showConfirmCustom(opt, function () {
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(ord => {
              return ['A011', 'A032', 'B614'].includes(ord.shop_cd) && !ord.ori_uniq && ord.ord_status === '배송중';
            }, true);

            if (!reselected_indexes.length) {
              commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('배송완료 작업이 가능한 건이 없습니다.'));
            }
          });

          return false;
        }

        const confirm = await commonSVC.showConfirmHtml('배송완료 전송', `선택한 배송중 주문 ${selectedData.length}건을 쇼핑몰에 '배송완료' 처리 전송합니다.<br><br> <div class="text-primary" style="font-size:15px">전송 후 '배송완료' 상태로 변경되며, 수정 전송은 불가능하므로 주의 바랍니다.</div>`);

        // 작업 등록
        if (confirm) {
          workSVC.addWorkSelect('DELIVERY_END', { numbers: selectedData.map(({ uniq }) => uniq) });
        }
      };

      /**
       * 통합 엑셀 다운로드
       */
      $scope.downloadIntegratedExcel = function () {
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const selectedData = $scope.grid.methods.selectedData('all');
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          title: '배송 관리 주문',
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData,
          page: 'delivery',
          visibleTable: visibleTable,
          isGlobal: $scope.selectedShopType === 'global'
        };

        if (!selectedData.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';
        } else {
          const uniq = $scope.grid.methods.selectedData('uniq');

          resolve.data.type = 'select';
          resolve.data.uniq = uniq;
          resolve.data.count = uniq.length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
      };

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = (type, isDouzone) => {

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: $scope.excelFieldList,
          title: '배송관리',
          url: '/app/order/excelDown',
          searchForm: { ...$scope.pageData, shopType: $scope.selectedShopType },
          page: 'delivery',
          visibleTable: visibleTable,
          progress: true
        };

        // 더존양식 여부
        if (isDouzone) {
          resolve.data.isDouzone = true;
        }

        if (type === 'all') {
          // 묶음이 아닌 전체 주문카운트 노출되도록 수정 2018-11-15 rony
          // resolve.data.count = $scope.searchData.totalCount;
          resolve.data.count = $scope.totalCount;
          resolve.data.isAll = true;

          if (resolve.data.count === 0) {
            commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 주문이 없습니다.'));

            return false;
          }
        } else {
          const uniqList = $scope.grid.methods.selectedData('uniq');

          if (uniqList.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          } else {
            resolve.data.uniq = uniqList;
            resolve.data.count = uniqList.length;
            resolve.data.isAll = false;
          }
        }

        const modal = commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            // 엑셀 다운로드 후 새로고침 안함 (https://playautogmpproject.atlassian.net/browse/GMPKR-5462)
            // 2018-10-24 rony
            // $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 업로드 모달창
       */
      $scope.excelUpload = function (mode) {
        const modal = commonSVC.openModal('', { data: { mode } }, 'OrderShipmentExcelUploadCtrl', 'views/order/shipment/modals/excel_upload.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });

      };

      /**
       * 배송정보 수정
       * */
      $scope.deliveryInfoEdit = function () {
        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const modal = commonSVC.openModal('lg', { data: { type: 'delivery' } }, 'invoiceExcelUploadCtrl', 'views/order/shipment/modals/invoice_excel_upload.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 주문 상태 강제변경
       * */
      $scope.userChangeStatus = function (status) {
        const uniqs = $scope.grid.methods.selectedData('all').filter(o => o.ord_status !== status).map(o => o.uniq);

        if (!uniqs.length) {
          commonSVC.showMessage(`${status} 상태로 변경가능한 주문이 없습니다.`);

          return false;
        } else {
          //유니코드로 마지막 글자의 받침 여부 확인
          const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

          commonSVC.showConfirm('상태를 변경하시겠습니까?', `${status}${isConsonant ? '으로' : '로'} 상태를 강제로 변경 하시겠습니까?`, function (re) {
            if (re) {
              shipmentModel.setStatus({ status: status, uniqList: uniqs }, function (state, res) {
                if (state == 'success' && res === 'success') {
                  commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                  $scope.searchDo(false);
                } else {
                  commonSVC.showToaster('error', gettextCatalog.getString('실패'), res.data.error.includes('BIGINT UNSIGNED value is out of range in') ? gettextCatalog.getString('차감할 재고가 부족합니다. 매칭된 SKU 재고수량을 확인해주세요.') : gettextCatalog.getString('주문 상태변경에 실패하였습니다'));
                }
              });
            }
          });
        }
      };

      /**
       * 주문분리
       * */
      $scope.divideBundle = function(bundle_no, uniq, ord_status) {
        const divideList = [];
        let title, msg;
        let checkNotBundle = false;

        if (!bundle_no) { // 주문 분할 버튼 눌렀을 때
          const selectList = $scope.grid.methods.selectedData('all');
          const checkShopeeOrd = selectList.some(ord => ord.pa_shop_cd === 'X099');

          if (selectList.length < 1) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          }

          if ($scope.selectedShopType === 'global') {
            if (selectList.some(ord => ord.misc2) && checkShopeeOrd) {
              commonSVC.showMessage('주문을 합포장 후 다시 분할해 주세요.', '- Shopee의 분할한 주문은 재 분할이 불가능합니다.\n- 다시 분할하려면 합포장 후 분할해 주세요.');

              return false;
            }

            selectList.forEach(ord => {

              // 샤피 번들딜, 애드온딜 체크
              // ord.misc9 !== ord.api_id.spilt('|')[1] : bundle-deal
              // ord.misc20 > 0 : add-on-deal
              if (ord.pa_shop_cd === 'X099' && (ord.misc9 !== ord.api_id.split('|')[1] || +ord.misc20)) {

                checkNotBundle = true;
              }
            });

            if (checkNotBundle) {
              commonSVC.showMessage('분할 할 수 없는 주문이 있습니다.', '- Shopee의 Bundle Deal,Add-on Deal 주문은 분할이 불가합니다.');

              return false;
            }

            title = `선택한 주문 <span class="text-primary">${selectList.length}</span>건을 분할 하시겠습니까?`;
            msg = '- 선택된 주문을 묶음주문에서 개별주문으로 일괄 분할합니다.<br/>- 선택한 모든 주문의 묶음번호가 변경됩니다.<br>- 선택한 모든 주문의 해외택배사명, 해외운송장번호가 초기화 됩니다.';
          } else {
            title = '주문 분할을 하시겠습니까?';
            msg = `선택한 ${selectList.length}건의 주문${$rootScope.adminMode ? '' : `중 클레임 주문 ${selectList.filter(({ ord_status }) => claimStatus.includes(ord_status)).length}건`}을 현재 묶음 배송 주문에서 개별 배송 주문으로 분리합니다. <br> - 주문에 적용된 사은품 규칙이 삭제됩니다. 묶음 분리/합포장 처리 후 사은품 규칙 모달에서 재적용하시기 바랍니다. <br> - 주문 분할 시 묶음번호가 변경되니 주의바랍니다.`;
          }

          if ($rootScope.adminMode && !selectList.find(({ ord_status }) => !noneDivideStatus.includes(ord_status))) {
            commonSVC.showMessage(gettextCatalog.getString('클레임 상태인 주문건들만 주문분할이 가능 합니다.'));

            return false;
          }

          _.each(selectList, (order) => {
            if ($scope.bundle_group[order.bundle_no].length > 1 && !noneDivideStatus.includes(order.ord_status)) {
              divideList.push([{ bundle_no: order.bundle_no, uniq: order.uniq }]);
            }
          });
        } else { //(개별)분할 아이콘 눌를때
          if ($scope.bundle_group[bundle_no].length === 2) { // 합이 2개면 둘다 분할 처리해야하니까
            _.each($scope.bundle_group[bundle_no], function(v) {
              divideList.push([{ bundle_no: bundle_no, uniq: v.uniq, ord_status: v.ord_status }]);
            });
          } else {
            divideList.push([{ bundle_no: bundle_no, uniq: uniq, ord_status: ord_status }]);
          }
          if ($scope.selectedShopType === 'global') {

            const checkDivOrd = $scope.bundle_group[bundle_no].find(ord => ord.uniq === uniq);
            const checkShopeeOrd = checkDivOrd.some(ord => ord.pa_shop_cd === 'X099');

            if (checkDivOrd.misc2 && checkShopeeOrd) {
              commonSVC.showMessage('주문을 합포장 후 다시 분할해 주세요.', '- Shopee의 분할한 주문은 재 분할이 불가능합니다.\n- 다시 분할하려면 합포장 후 분할해 주세요.');

              return false;
            }

            // 샤피 번들딜, 애드온딜 체크
            // ord.misc5 !== ord.api_id.spilt('|')[1] : bundle-deal
            // ord.misc20 > 0 : add-on-deal
            if (checkDivOrd.pa_shop_cd === 'X099' && (checkDivOrd.misc9 !== checkDivOrd.api_id.split('|')[1] || +checkDivOrd.misc20)) {

              checkNotBundle = true;
            }

            if (checkNotBundle) {
              commonSVC.showMessage('분할 할 수 없는 주문이 있습니다.', '- Shopee의 Bundle Deal,Add-on Deal 주문은 분할이 불가합니다.');

              return false;
            }

            title = '주문을 분할 하시겠습니까?';
            msg = '- 선택된 주문을 묶음주문에서 분할합니다.<br>- 선택하신 주문은 묶음주문에서 분할되며 해당 주문은 묶음번호가 변경됩니다.<br>- 묶음번호가 변경된 주문은 해외택배사명, 해외운송장번호가 초기화됩니다.';

          } else {

            title = '주문 분할을 하시겠습니까?';
            msg = `선택한 ${divideList.length}건의 주문${$rootScope.adminMode ? '' : `중 클레임 주문 ${divideList.filter(([ord]) => claimStatus.includes(ord.ord_status)).length}건`}을 현재 묶음 배송 주문에서 개별 배송 주문으로 분리합니다. <br> - 주문에 적용된 사은품 규칙이 삭제됩니다. 묶음 분리/합포장 처리 후 사은품 규칙 모달에서 재적용하시기 바랍니다. <br> - 주문 분할 시 묶음번호가 변경되니 주의바랍니다.`;
          }
        }

        commonSVC.showConfirmHtml(title, msg, function () {
          const send_data = {
            divideList: divideList
          };

          shipmentModel.divideBundle(send_data, function (state, data) {
            if (state === 'success') {
              commonSVC.showToaster('success', '', '합포장 분할해제에 성공하였습니다.');
              $timeout(function() {
                $scope.searchDo(false);
              }, 500);
            } else {
              commonSVC.showToaster('error', '', `합포장 분할해제에 실패 했습니다.\n${data.data?.message || ''}`);
            }
          });
        });
      };

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
       * 국내, 해외 선택
       */
      $scope.selectShopType = (shopType) => {
        $scope.selectedShopType = shopType;

        if (shopType === 'domestic') {
          setSearch(domesticSearch);
        } else {
          setSearch(globalSearch);
        }

        $scope.grid = $scope[$scope.selectedShopType];
        $scope.countSearch('total');
      };

      /**
       * 테이블에서 선택 변경 시
       */
      // $scope.$on('OnSelectChange', function (event, data) {
      //   if ($scope.isDetailShow) {
      //     $state.go("main.order_shipment_delivery_list.detail", {
      //       fromPage: 'delivery',
      //       rowIndex: data.selectIndex,
      //       uniq: data.selectData.uniq,
      //       number: data.selectData.number
      //     });
      //   }
      // });

      $scope.$on('OnSelectChangeBefore', function (event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-04-27 MatthewKim todo. 이거는 좀더 빠르게 바꿀 필요가 있음
        // 2017-05-10 MatthewKim $timeout 이용하지 않고 다이렉트로 처리함 (속도 개선)
        // 2017-05-10 MatthewKim 선택 발생시 타겟 전체를 클래스로 받아 빠르게 처리함
        $(data.targetClasses).addClass('selected');
      });

      /**
       * 테이블에서 선택해제시
       */
      $scope.$on('OnDeSelected', function (event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-05-10 MatthewKim $timeout 제거 하고 처리 위치 제일 먼저 위로 올림
        // 2017-05-10 MatthewKim 선택 해제 발생시 타겟 전체를 클래스로 받아 처리함
        $(data.targetClasses).removeClass('selected');
      });

      /**
       * 상세보기 닫기 눌렀을 시에
       */
      // $scope.$on('OnCloseDetail', function (event, data) {
      //   $scope.showDetail(null, false);
      //   // 2017-03-07 MatthewKim $state.go 옵션을 추가해서 파라미터 상속 false, 알림 false 로 이동하여 화면 이동을 시키게 함
      //   $state.go("main.order_shipment_delivery_list", null, {inherit: false, notify: false});
      // });

      /**
       * 주문 전체보기에서 주문코드 눌렀을 때
       */
      $scope.$on('integratedSearch_delivery', function() {
        $scope.selectCount = 'total';
        $scope.searchForm.search_type = 'exact';
        $scope.searchForm.status = ['출고완료', '배송중', '배송완료', '구매결정'];
        $scope.searchDo(true, true);
      });

      /**
       * 사이드바 집계 클릭시
       */
      $scope.$on('sidebarSearch_delivery', function() {
        let tabIdx = 1;
        $timeout(() => {
          if ($rootScope.side_search.selectedShopType === 'domestic') {
            tabIdx = 1;
          } else {
            tabIdx = 2;
          }

          $(`a[href='#content-tabs-${tabIdx}']`).trigger('click');
          $scope.searchDo(true, true);
        });
      });

      /**
       * 주문 요약정보 열기
       */
      // $scope.$on('openOrderInfoDetail', function(event, vdata) {
      //   // 주문 요약정보 매칭 정보
      //   _.forEach($scope.orderList, function (order) {
      //     if (prodList[order.uniq]) {
      //       order.sku_pack = prodList[order.uniq][0].sku_cd;
      //       order.prod_name_pack = prodList[order.uniq][0].prod_name;
      //     }
      //   });

      //   vdata.row.child($compile('<detail-preview number=' + vdata.row[0][0] + ' type="order"></detail-preview>')($scope)).show();
      // });

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function (evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_delivery_list') {
          const $wrapper = $('#delivery_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      // SMS, EMAIL 모달
      $scope.sendModal = async (type) => {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const t = angular.copy($scope.pageData);

        delete t.start;
        t.length = 1000000;

        const totalRows = (await shipmentModel.listSms(t)).data.results;

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };
      /**
       * 2018-02-21 Daniel
       * 작업 완료시 새로고침
       * [주문동기화, 송장전송]
       */
      // _.each(["SyncOrderState", "SendDelivNo"], function (value) {
      //   $scope.$on(value, function () {
      //     $scope.searchDo(true);
      //   });
      // });

      /**
       * 운송장 출력
       * 재출력 추가 (printType === 'reprint')
       */
      $scope.printInvoice = function() {
        if ($scope.selectedShopType === 'global') {
          return $scope.globalReprintInvoice();
        }
        const selected = $scope.grid.methods.selectedData('all');

        if (deliveryAllList.data.results.length === 0) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $state.go('main.settings_delivery');
          });

          return;
        }

        const resolve = {};

        resolve.data = {
          solCarrList: deliveryAllList.data.results
        };

        if (selected.length === 0) {

          commonSVC.showMessage('실패', '주문을 선택해주세요.');

          return false;
        } else {
          // 재출력은 다음의 택배사인경우에만 지원.
          const checkCarr = selected.filter((o) => {
            return ![4, 5, 6, 7, 8, 944, 956].includes(o.carr_no);
          });

          if (checkCarr.length) {
            commonSVC.showMessage('운송장 재출력은 다음의 택배사만 가능합니다.', 'CJ대한통운, 한진택배, 우체국택배, 롯데택배, 카카오T당일배송');

            return false;
          }

          resolve.totalCount = $scope.countList.finished;
          const unAvailList = selected.filter(ord => !['운송장출력', '출고완료', '배송중'].includes(ord.ord_status)
            || !['무료배송', '선결제', '착불', '조건부배송', '기타'].includes(ord.ship_method));

          if (unAvailList.length) {
            commonSVC.showMessage('운송장 출력이 불가능한 주문건이 포함되어 있습니다.', '[출력 가능 조건]\n주문상태: 운송장출력, 출고완료, 배송중 / 배송방법: 일반 택배발송\n(배송방법: 방문수령, 퀵배송, 일반우편, 설치배송 출력 불가)');

            return false;
          }

          // 송장재출력 유효성 확인
          const carr_no = _.map(selected, 'carr_no');
          const invoice_no = _.map(selected, 'invoice_no');

          if (_.uniq(carr_no).length > 1) {
            commonSVC.showMessage('실패', '택배사가 다른 주문이 있습니다.');

            return false;
          } else if (!_.compact(carr_no).length || !_.compact(invoice_no)) {
            commonSVC.showMessage('실패', '택배사 정보가 없습니다.');
          }

          // 재출력할 택배사 확인
          resolve.data.carr_no = _.compact(carr_no)[0];
          resolve.data.invoice_no = _.compact(invoice_no)[0];

          resolve.data.type = 'select';
          resolve.selectList = function () {
            return { data: { results: selected, recordsTotal: selected.length } };
          };
          resolve.solCarrList = deliveryAllList;
        }
        const modal = commonSVC.openModal('lg', resolve, 'printInvoiceCtrl', 'views/order/shipment/modals/print_invoice.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 해외 운송장 재출력 함수
       */
      $scope.globalReprintInvoice = () => {
        const deliveryUseList = solCarrList.data.results;
        const selected = $scope.grid.methods.selectedData('all');
        if (selected.length === 0) {
          return commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));
        }

        const availShops = channelList.map(o => (o.pa_shop_cd_use_yn ? o.pa_shop_cd : o.shop_cd));
        const availList = [];
        selected.forEach(row => {
          const { ord_status, shop_cd, global_invoice_time } = row;

          if (
            (ord_status === '운송장출력' || ord_status === '출고완료' || ord_status === '배송중') &&
            global_invoice_time &&
            availShops.includes(shop_cd)
          ) {
            availList.push(row);
          }
        });

        const msg = `
        <ul style="list-style:none; padding:0; font-size: 15px;">
          <li style="position:relative;padding: 15px 0 0 15px;"><span style="position:absolute; left:0;">-</span>
          주문상태가 '운송장출력','출고완료','배송중' 중 출력여부가 '출력완료'인 주문건만 재출력 가능합니다.</li>
          <li style="position:relative;padding: 15px 0 0 15px;"><span style="position:absolute; left:0;">-</span>
          <span class="text-warning">묶음주문 내 클레임 주문이 있을 경우 운송장 재출력이 불가합니다.</span></li>
        </ul>
        `;

        if (!availList.length) {
          return commonSVC.showMessageHtml('운송장 재출력이 가능한 건이 없습니다.', msg);
        }

        // 직배송 캐리어
        const dropoffCarr = deliverySVC.carrList.filter(o => !o.pa_shop_cd).map(o => o.carr_no);
        if (!deliveryUseList.length && availList.find(o => dropoffCarr.includes(o.global_pa_carr_no || o.global_carr_no))) {
          return commonSVC.showConfirm(
            '배송사 연동정보가 없습니다.',
            '운송장 출력기능은 배송사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 배송사 연동 관리 페이지로 이동합니다.',
            function () {
              $state.go('main.settings_delivery');
            }
          );
        }

        // 불가능 주문건이 있을경우
        if (selected.length != availList.length) {
          const opt = {
            title: '운송장 재출력이 불가능한 주문건이 포함되어 있습니다.',
            html: msg,
            icon: 'warning',
            confirmButtonText: '운송장재출력이 가능한 주문만 선택',
          };

          const exceptRows = _.difference(selected, availList);

          commonSVC.showConfirmCustom(opt, function () {
            $scope.grid.methods.unSelectByFilter(function (row) {
              return _.find(exceptRows, o => o.bundle_no === row.bundle_no);
            }, true);

            if ($scope.grid.methods.selectedData('all').length == 0) {
              return commonSVC.showToaster(
                'error',
                gettextCatalog.getString('자동 선택 불가'),
                gettextCatalog.getString('운송장재출력이 가능한 건이 없습니다.')
              );
            }
          });
        }
        const resolve = {
          data: {
            order_list: selected,
            type: 'select',
            mode: 'reprint'
          }
        };
        resolve.globalCarrList = function () {
          return globalCarrList;
        };
        commonSVC.openModal('xxg', resolve, 'printInvoiceGlobalCtrl', 'views/order/shipment/modals/printInvoiceGlobal.html');
      };

      /**
       * 희망배송일 변경
       * */
      $scope.updateHopeDate = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        let dataNone;
        const isLg = $rootScope.affName === 'LG전자';
        const lgCondition = selected.filter(ord => ord['pa_addcol_희망배송일 전송 작업'] === '실패' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(ord.misc19) && ord.pa_shop_cd === 'A077').length && isLg;

        if (lgCondition) {
          dataNone = Object.values(_.groupBy(selected, 'bundle_no')).reduce((aur, curr) => {
            let disableBundleNo = false;

            curr.forEach((d) => {
              if (d.pa_shop_cd !== 'A077' || (d.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19)) || (d.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19) && d['pa_addcol_희망배송일 전송 작업'] !== '실패') || d.shop_ord_no.indexOf('_copy') > -1 || d.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(d.ord_status)) {
                disableBundleNo = true;
              }
            });

            return disableBundleNo ? [...aur, ...curr] : aur;
          }, []);
        } else {
        // 스마트스토어가 아니고 혹은 스마트스토어인데 misc19에 특정 값이 없을 경우 혹은 copy 주문인 경우
          dataNone = Object.values(_.groupBy(selected, 'bundle_no')).reduce((aur, curr) => {
            let disableBundleNo = false;
            curr.forEach((d) => {
              if (d.pa_shop_cd !== 'A077' || (d.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19)) || d.shop_ord_no.indexOf('_copy') > -1 || d.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(d.ord_status)) {
                disableBundleNo = true;
              }
              if (isLg && (d.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(d.misc19) && d['pa_addcol_희망배송일 전송 작업'] === '성공')) {
                disableBundleNo = true;
              }
            });

            return disableBundleNo ? [...aur, ...curr] : aur;
          }, []);
        }

        // 희망배송일 [변경가능]+[변경불가] 조합의 주문 선택
        if (dataNone.length > 0 && selected.length !== dataNone.length) {
          commonSVC.showConfirmCustom({
            title: gettextCatalog.getString('희망배송일 변경'),
            text: delaySVC.updateHopeDateMessage(isLg).info,
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: '희망배송일 변경 작업이 가능한 건만 선택해두기',
            cancelButtonText: gettextCatalog.getString('취소'),
            animation: false,
          }, function () {

            // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
            // 2017-03-13 MatthewKim
            let reselected_indexes;

            if (lgCondition) {
              reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                return r.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19) && r['pa_addcol_희망배송일 전송 작업'] === '실패' && ['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(r.ord_status);
              }, true);
            } else {
              reselected_indexes = $scope.grid.methods.unSelectByFilter(function (r) {
                if (isLg && (r.pa_shop_cd === 'A077' && ['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19) && r['pa_addcol_희망배송일 전송 작업'] === '성공')) {
                  return true;
                }

                return r.pa_shop_cd !== 'A077' || (r.pa_shop_cd === 'A077' && !['HOPE', 'HOPE_SELLER_GUARANTEE'].includes(r.misc19)) || r.shop_ord_no.indexOf('_copy') > -1 || r.shop_ord_no.indexOf('_d') > -1 || !['신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중'].includes(r.ord_status);
              }, true);
            }

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', delaySVC.updateHopeDateMessage(isLg).fail);
            }
          });

          return false;
        } else if (dataNone.length > 0 && selected.length === dataNone.length) {
          // 희망배송일 [변경불가]한 주문 선택 시
          commonSVC.showMessageHtml(delaySVC.updateHopeDateMessage(isLg).fail, delaySVC.updateHopeDateMessage(isLg).info);

          return false;
        }

        const resolve = {
          systemList: function () {
            return systemModel.load();
          }
        };

        resolve.data = {
          fromPage: 'order',
          selectList: selected
        };

        // 엘지면서 희망배송일 전송작업이 실패한 주문이 있으면 직접 "희망배송일 전송작업"을 실행해
        if (lgCondition) {
          const grouped = _.groupBy(selected.filter(ord => ord['pa_addcol_희망배송일 전송 작업'] === '실패'), (ord) => [ord.ship_hope_time, ord['pa_addcol_희망배송 지연 사유']]);
          const hope_date_group = [];

          for (const key of _.keys(grouped)) {
            hope_date_group.push({
              hope_date: key.split(',')[0],
              reason_code: key.split(',')[1],
              uniqs: _.map(grouped[key], 'uniq')
            });
          }

          if (hope_date_group.length < 1) {
            // 작업을 걸 수 있는게 없다는거여서 실패처리
            commonSVC.showMessageHtml(delaySVC.updateHopeDateMessage(isLg).fail, delaySVC.updateHopeDateMessage(isLg).info);

            return false;
          }

          const params = hope_date_group.length === 1 ? {
            numbers: hope_date_group[0].uniqs,
            delay_code: hope_date_group[0].reason_code,
            delay_date: hope_date_group[0].hope_date
          } : { hope_date_group, numbers: [] };

          workSVC.addWorkSelect('UPDATE_HOPE_DATE', params);
        } else {
          commonSVC.openModal('', resolve, 'updateHopeDateCtrl', 'views/order/shipment/modals/update_hope_date.html');
        }
      };

      $scope.asRequest = () => {
        const ordList = $scope.grid.methods.selectedData('all');

        if (!ordList.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const findStatus = ordList.find((ord) => {
          return ['배송중', '배송완료', '구매결정'].findIndex((o) => {
            return o === ord.ord_status;
          }) === -1;
        });

        if (findStatus) {
          commonSVC.showMessage(gettextCatalog.getString('A/S요청 가능한 상태가 아닙니다. \n배송중, 배송완료, 구매결정 상태만 변경 가능합니다'));

          return false;
        }

        const resolve = {
          data: {
            rows: ordList,
          }
        };

        commonSVC.openModal('xxg', resolve, 'ordAsList', 'views/order/shipment/modals/delivery_as_modal.html');

      };

      // 메모 첫 변경시 메모버튼 색 변경
      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };
      /**
       * 알림톡 발송
       */
      $scope.altalkSend = async () => {
        const plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
        const resolve = {
          data: {
            open: 'ord',
            type: 'direct',
            plusIdList: plusIdList.data?.result,
            ord: $scope.domestic.methods.selectedData() || []
          }
        };
        commonSVC.openModal('x3g', resolve, 'AltalkSendCtrl', 'views/alimtalk/modals/altalk_send.html');
      };

      /**
       * 알림톡 그룹 회원 추가
       */
      $scope.altalkGroupMemAdd = () => {
        if (!$scope.domestic.methods.selectedData().length) {
          return commonSVC.showMessage('추가할 회원이 없습니다.', '주문리스트에서 주문을 먼저 선택해 주세요.');
        }
        commonSVC.openModal('lg', { data: { type: 'add_mem', ord: $scope.domestic.methods.selectedData() || [] } }, 'AltalkSendGroupCtrl', 'views/alimtalk/modals/altalk_send_group.html');
      };

      /**
       * 주문서 인쇄
       */
      $scope.orderPrint = function () {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return;
        }

        const selectBundle = _.uniqBy(selectList.map(o => o.bundle_no));
        const isBundle = !!selectBundle.find(bundle_no => $scope.bundle_group[bundle_no].length > 1);

        const data = {
          isBundle: isBundle,
          ordCount: selectBundle.length,
          orderby: orderby,
          uniqList: $scope.grid.methods.selectedData('uniq')
        };

        commonSVC.openModal('', { data: data }, 'OrderBundlePrintCtrl', 'views/order/shipment/modals/order_bundle_print.html');
      };

      // 리스트와 별개로 초기에 세팅되어야하는 값
      async function init(data) {
        $scope.selectRowUniqList = [];
        $scope.countList = {
          total: data['배송관리_전체'] ? data['배송관리_전체'][0].cnt : 0,
          finished: data['출고완료'] ? data['출고완료'][0].cnt : 0,
          shipping: data['배송중'] ? data['배송중'][0].cnt : 0,
          completed: data['배송완료'] ? data['배송완료'][0].cnt : 0,
          decision: data['구매결정'] ? data['구매결정'][0].cnt : 0,
          end: data['판매완료'] ? data['판매완료'][0].cnt : 0
        };

        $rootScope.listColorObj.delivery = {};
      }

      // 초기 선택이 안된 탭에도 검색기간 초기화 적용
      setTimeout(() => {
        const destSearchForm = $scope.selectedShopType === 'global' ? domesticSearch.searchForm : globalSearch.searchForm;
        destSearchForm.search_key = $scope.searchForm.search_key || $scope.searchData.search_key_items[0].value;
        destSearchForm.change_sdate = false;
        destSearchForm.change_edate = false;
        destSearchForm.selectDate = angular.copy($scope.searchForm.selectDate);
      });

      // 2018-04-06 Daniel
      // 재고관리제외버전 추가
      // stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);
    });
