'use strict';

angular.module('gmpApp')
  .controller('ConfigsUserEditCtrl', function ($uibModalInstance, $scope, $timeout, $filter, commonModel, userModel, userSVC, logHistorySVC, commonSVC, data, userInfo, gettextCatalog, $rootScope) {
    $scope.m_no = data.m_no;

    $scope.warehouseList = data.warehouseList; // 2018-01-22 Daniel 배송처 추가

    $scope.tab_active = 0; // 탭 활성화 설정

    const logField = {
      user_name: '이름',
      user_tel: '직통번호',
      department: '소속',
      position: '직급',
      opt_in_mail: 'Email 수신 동의여부',
      opt_in_sms: 'SMS 수신 동의여부',
      allow_ip: '접속 가능 IP',
      m_status: '상태',
      auth_type: '구분'
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.userData = {};
    $scope.use_channel_sorted_lists = {};      // 쇼핑몰별로 분류된 계정명 노출용 변수
    let auth_type = '';

    /**
     * 추가권한 설정 셋팅
     * 2018-12-28 rony
     */
    $scope.setAddPermissions = function(type, shop_data = null, typeSub = null) {
      // 선택사항이 바로 반영되지않아 timeout 줘야 함.
      $timeout(function() {
        // 관리자 선택시 모든 항목 체크.
        if (type === 'master') {
          if ($scope.userData.addPermissions.master.use_yn) {
            Object.keys($scope.userData.addPermissions).forEach((key) => {
              $scope.userData.addPermissions[key].use_yn = true;
              Object.keys($scope.userData.addPermissions[key].roles).forEach(obj_k => {
                $scope.userData.addPermissions[key].roles[obj_k] = true;
              });
            });

            // 쇼핑몰 분류도 전부 활성화
            Object.values($scope.use_channel_sorted_lists).forEach((v) => {
              v.use_yn = true;
              v.shops.forEach(({ value }) => {
                $scope.userData.addPermissions.channel.roles[value] = true;
              });
            });
          }
        } else if (type) {
          Object.keys($scope.userData.addPermissions[type].roles).forEach(obj_k => {
            $scope.userData.addPermissions[type].roles[obj_k] = $scope.userData.addPermissions[type].use_yn;
          });

          if (typeSub) {
            $scope.userData.addPermissions[typeSub].use_yn = $scope.userData.addPermissions[type].use_yn;
            Object.keys($scope.userData.addPermissions[typeSub].roles).forEach(obj_k => {
              $scope.userData.addPermissions[typeSub].roles[obj_k] = $scope.userData.addPermissions[type].use_yn;
            });
          }
        }

        // 쇼핑몰이 체크되면 하위 계정들을 전부 체크하거나 전부 체크해제함
        if (shop_data) {
          $scope.use_channel_sorted_lists[shop_data].shops.forEach(e => {
            $scope.userData.addPermissions.channel.roles[e.value] = $scope.use_channel_sorted_lists[shop_data].use_yn;
          });
        }

        // 하나라도 체크가 안된 항목이 있으면 관리자 체크 해제.
        let all_check = true;

        Object.keys($scope.userData.addPermissions).some((o_k) => {
          if (o_k !== 'master') {
            if ($scope.userData.addPermissions[o_k].use_yn !== true) {
              all_check = false;
            }
            if (all_check) {
              Object.keys($scope.userData.addPermissions[o_k].roles).some((obj_k) => {
                if ($scope.userData.addPermissions[o_k].roles[obj_k] !== true) {
                  all_check = false;
                }

                return !all_check;
              });
            }
          }

          return !all_check;
        });

        if (type !== 'master' && all_check === true) {
          $scope.userData.addPermissions.master.use_yn = true;
        } else if ($scope.userData.addPermissions.master.use_yn === true && all_check === false) {
          $scope.userData.addPermissions.master.use_yn = false;
        }
      }, 100);
    };

    /**
     * 사용자 정보 불러오기
     */
    userModel.load($scope.m_no, function(state, result) {
      if (state != 'success') {
        commonSVC.showMessage(gettextCatalog.getString('존재하지 않는 사용자 정보입니다.'));
        $scope.cancel();
      } else {
        $scope.userData = result;
        auth_type = $scope.userData.auth_type;

        // allow_ip가 왜이선지 null로 나와서 빈 문자열로 수정
        $scope.userData.allow_ip_use_yn = $scope.userData.allow_ip ? 1 : 0;
        if (!$scope.userData.allow_ip) {
          $scope.userData.allow_ip = '';
        }

        // 권한설정
        const useChannels = [];

        $scope.userData.addPermissions = _.cloneDeep($scope.userData.auth_type == '배송처' ? userSVC.depotPermission : userSVC.permissions);

        if ($scope.userData.permission) {
          $scope.userData.addPermissions = _.merge($scope.userData.addPermissions, $scope.userData.permission);

          // 2020-02-25 Boris 환경설정 권한 이전버전이랑 호환되게, 이후 저장하면 새로운 방식으로 통일되게 변경
          if ('config' in $scope.userData.addPermissions.setting.roles) {
            // 만약에 비활성화라면
            if (!$scope.userData.addPermissions.setting.roles.config) {
              // 새로운 환경설정 권한도 비활성화로 변경(기본값은 true)
              $scope.userData.addPermissions.config.use_yn = false;
            }
            // 기존에 사용되던 환경설정 권한 제거
            delete $scope.userData.permission.setting.roles.config;
            delete $scope.userData.addPermissions.setting.roles.config;
          }
        } else {
          $scope.userData.permission = _.cloneDeep($scope.userData.addPermissions);
        }

        // 채널 쇼핑몰별로 분류
        commonSVC.getSiteIdList($rootScope.use_channel_list).forEach (function(o) {
          if (!$scope.use_channel_sorted_lists[`${o.shop_name}`]) {
            $scope.use_channel_sorted_lists[`${o.shop_name}`] = {
              shops: [],
              use_yn: false
            };
          }
          $scope.use_channel_sorted_lists[`${o.shop_name}`].shops.push({
            value: `${o.shop_cd}_${o.shop_id}`,
            username: `${o.shop_id}`
          });

          if ($scope.userData.addPermissions.channel.roles[`${o.shop_cd}_${o.shop_id}`]) {
            $scope.use_channel_sorted_lists[`${o.shop_name}`].use_yn = true;
          }

          // 저장된 권한이 없거나 '관리자'인 경우 쇼핑몰 권한 체크박스 체크처리 2020-03-22 aiden
          if ($scope.userData.addPermissions.master.use_yn) {
            $scope.use_channel_sorted_lists[`${o.shop_name}`].use_yn = true;
            $scope.userData.addPermissions.channel.roles[`${o.shop_cd}_${o.shop_id}`] = true;
          }

          useChannels.push(`${o.shop_cd}_${o.shop_id}`);
        });

        // 사용하는 채널만 노출되도록 함.
        $scope.userData.addPermissions.channel.roles = _.pick($scope.userData.addPermissions.channel.roles, useChannels);

        // 총괄관리자 전체권한 설정
        if ($scope.userData.auth_type == '총괄관리자') {
          $scope.userData.addPermissions.master.use_yn = true;
          $scope.setAddPermissions('master');
        } else if (!$scope.userData.addPermissions.master.use_yn) {
          Object.keys($scope.userData.addPermissions).forEach((key) => {
            // 상위권한이 없으면 하위권한 체크해제
            if (key != 'channel' && !$scope.userData.addPermissions[key].use_yn) {
              Object.keys($scope.userData.addPermissions[key].roles).forEach(obj_k => {
                $scope.userData.addPermissions[key].roles[obj_k] = false;
              });

              // 설정 권한없으면 환경설정 권한도 체크해제
              if (key == 'setting') {
                $scope.userData.addPermissions.config.use_yn = false;
                Object.keys($scope.userData.addPermissions.config.roles).forEach(obj_k => {
                  $scope.userData.addPermissions.config.roles[obj_k] = false;
                });
              }
            }
          });
        }

        $scope.userData.depot_no = $scope.userData.depot_no ? $scope.userData.depot_no.toString() : '';

        userModel.setOld(_.cloneDeep($scope.userData));
      }
    });

    $scope.changeStatus = (prev) => {
      if ($scope.userData.m_status === '정상') {
        if (userInfo.user.userVersionCnt !== '무제한' && userInfo.user.userAccountCnt >= userInfo.user.userVersionCnt) {
          commonSVC.showMessage('실패', `이용중인 버전에서 사용할 수 있는 사용자 ID 수가 부족합니다.\n추가 사용을 원하실 경우 이용버전을 업그레이드 하시기 바랍니다.\n현재 버전 ${userInfo.user.sol_version} : 사용자 ID - ${userInfo.user.userVersionCnt}개 사용 가능`);
          $scope.userData.m_status = prev;

          return false;
        }

        if (userInfo.user.superUserAccountCnt >= 3 && $scope.userData.auth_type == '총괄관리자') {
          commonSVC.showMessage('총괄 관리자는 3명만 설정할 수 있습니다.', '현재 총괄 관리자를 사용자로 변경 하신 후 이용해 주세요.');
          $scope.userData.m_status = prev;

          return false;
        }
      }

      if ($scope.userData.m_status === '비활성') {

        const showMsg = userInfo.user.solAccountCnt > 1 ? ['※ 주의 ※', `<font color="red">사용자 비활성화 시 플토2.0에서만 비활성화됩니다.</font>
        <br>플토Global의 접속권한을 비활성화하고 싶으신 경우
        <br>플토Global에서 비활성화로 변경해 주시기 바랍니다.
        <br>사용자 비활성화 시 해당 사용자는 솔루션에서 로그아웃 됩니다.
        <br>플토2.0에서 선택한 사용자를 비활성화 하시겠습니까?`] : ['선택한 사용자를 비활성화 하시겠습니까?', ''];

        commonSVC.showConfirmHtml(...showMsg, function (re) {
          if (!re) {
            $timeout(() => {
              $scope.userData.m_status = prev;
            }, 500);
          }
        });

      }
    };

    $scope.changeAuthType = (prev) => {
      if ($scope.userData.m_status === '정상' && userInfo.user.superUserAccountCnt >= 3 && $scope.userData.auth_type == '총괄관리자') {
        commonSVC.showMessage('총괄 관리자는 3명만 설정할 수 있습니다.', '현재 총괄 관리자를 사용자로 변경 하신 후 이용해 주세요.');
        $scope.userData.auth_type = prev;

        return false;
      }
    };

    /**
     * 사용자 정보 수정
     */
    $scope.onSubmit = async() => {
      // 배송처 사용자이면서 담당 배송처가 없는 경우 실패 처리
      if ($scope.userData.auth_type === '배송처' && $scope.userData.depot_no === '') {
        commonSVC.showMessage('실패', '배송처를 등록해 주세요');

        return false;
      }

      if ($scope.userData.allow_ip_use_yn === 1) {
        let ip_validation = true;

        const ip_arr = [];

        $scope.userData.allow_ip.split(',').some((ip) => {
          const new_ip = ip.replace(/\s/gi, '');
          const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

          if (!regex.test(new_ip)) {
            ip_validation = false;

            return true;
          }

          ip_arr.push(new_ip);
        });

        if (!ip_validation) {
          commonSVC.showMessage(gettextCatalog.getString('IP 주소를 확인해주세요.'));

          return false;
        }

        $scope.userData.allow_ip = ip_arr.join(',');
      }

      // 1. 사용자구분을 변경했거나
      // 2. 수정 전/후가 모두 관리자 권한일때를 제외하고 (=관리자 권한을 줬을지라도 쇼핑몰 계정이 추가나 삭제되어 변경사항으로 잡힐수 있음)
      // 3. 권한에 변경사항이 확인될 경우
      $scope.userData.permission_edit = false;
      if (auth_type != $scope.userData.auth_type || (
        !($scope.userData.addPermissions.master.use_yn && $scope.userData.addPermissions.master.use_yn) &&
        !_.isEqual($scope.userData.permission, $scope.userData.addPermissions)
      )) {
        const confirm = await commonSVC.showConfirmHtml('사용자 권한 변경 안내', `
        설정된 권한을 변경할 경우 해당 사용자 계정은 자동으로 로그아웃 됩니다. <br>
        해당 계정으로 다시 로그인 하시면 변경된 권한으로 적용됩니다. <br><br>
        설정을 변경하시겠습니까?`);

        if (!confirm) {
          return false;
        }

        $scope.userData.permission_edit = true;
      }

      const log_content = logHistorySVC.compareValue(logField, userModel.getOld(), $scope.userData);

      $scope.userData.permission = $scope.userData.auth_type == '총괄관리자' ? null : JSON.stringify($scope.userData.addPermissions);

      userModel.update($scope.userData, log_content, function (state) {

        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('사용자 정보가 수정 되었습니다.'));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));
          $scope.cancel();
        }
      });
    };

    // 내 IP 가져오기
    $scope.getMyIp = function () {
      commonModel.getIp().then(function (result) {
        if (result.status === 200) {
          $scope.myIp = result.data.result;
        }
      });
    };
  });
