'use strict';

/**
 *  메인 어플리케이션 로직
 *  2016-09-28 남형진
 */
angular.module('gmpApp',

  /**
   * include angularjs 모듈 선언
   */
  [
    'gmpApp.settings', // 사용중
    'ui.router', // 사용중
    'ui.bootstrap', // 사용중
    'ui.calendar', // 사용중
    'ui.mask', // 사용중
    'ui.select', // 사용중
    'ui.bootstrap.contextMenu', // 2017-03-06 chris 마우스 우클릭 메뉴 https://github.com/Templarian/ui.bootstrap.contextMenu
    'ui.grid',
    'ui.grid.pagination',
    'ui.grid.selection',
    'ui.grid.resizeColumns',
    'ui.grid.moveColumns',
    'ui.grid.saveState',
    'oc.lazyLoad', // 사용중
    'ngDraggable', // 2017-05-23 MatthewKim 사용중으로 파악
    // 'timer', // 2017-05-23 MatthewKim 사용안하는 것으로 파악해서 삭제함
    'treeControl', // 사용중
    'ptgui', // 사용중
    'angular-bind-html-compile', // 사용중
    'angular-clipboard', // 2017-05-23 MatthewKim 사용중으로 파악
    'angular-md5', // 2017-05-23 MatthewKim 사용중으로 파악
    'angularMoment', // 2017-01-13 MatthewKim https://github.com/urish/angular-moment
    'angular-web-notification', // 2017-01-19 MatthewKim https://github.com/sagiegurari/angular-web-notification
    'gettext', // 2017-02-02 MatthewKim 다국어 지원을 위해 추가 https://angular-gettext.rocketeer.be/dev-guide/install/
    'permission', // 2017-02-03 MatthewKim 프론트 권한 관리를 위해 추가 https://github.com/Narzerus/angular-permission
    'permission.ui', // 위 기능을 ui.router 와 연동하기 위한 추가 모듈
    'ngSanitize', //17-02-10 ally ng-bind-html 위해 추가
    // 'cfp.hotkeys', // 2017-03-07 MatthewKim 단축키지원 모듈 https://github.com/chieffancypants/angular-hotkeys/
    'ngIdle', // 사용중
    'angularLazyImg', // 2017-04-12 MatthewKim 이미지 지연 로딩 모듈 추가 bower home angular-lazy-img
    'ngWebworker', // 2017-05-22 MatthewKim 작업관리 새로고침에 비동기 쓰레드 사용을 위해 추가
    'ui.tree', //사용중
    'ui.sortable', // 2018-05-11 Daniel 앵귤러용 sortable
    'infinite-scroll',
    'ngFileUpload', // 2019-12-9 엑셀 드래그 앤 드랍용 Boris
    'detect-browser',
    'ngTouch',
    'angular-carousel',
    'ngSentry'
  ])

  /**
   * SSL 접속 확인
   */
  .run(function ($location, settings) {

    // SSL 강제 리다이렉트 일단 제거 2017-02-27 MatthewKim
    // 2017-01-25 MatthewKim 추가
    if (!settings.debug && $location.protocol() != 'https' && $location.absUrl().search('staging') < 0) {
      location.href = location.href.replace('http://', 'https://');
    }

  })

// /**
//  * 언어 설정
//  * 2017-02-03 MatthewKim
//  */
// .run(function (langSVC, userInfo) {
//   langSVC.setCurrent(userInfo.user.sol_type);
// })

// 2018-01-30 chris 무조건 실행안하고 환경설정에 따라 메인컨트롤러에서 설정
// /**
//  * 화면 잠금 기능 설정
//  */
// .run(function(Idle) {
//   Idle.watch();
// })

  /**
   * 세션 타임 아웃 관련 설정
   * https://github.com/HackedByChinese/ng-idle/wiki/Idle-and-Idleprovider
   * https://github.com/HackedByChinese/ng-idle/wiki/Keepalive-and-Keepaliveprovider
   */
  .config(['KeepaliveProvider', 'IdleProvider', function (KeepaliveProvider, IdleProvider) {

    // 로컬에 저장되어있는 화면잠금 옵션 가져오기
    // 2017-03-15 MatthewKim 로컬스토리지에 값 없을때 에러처리 안되어 있어서 수정함
    const system_lock_time = localStorage.getItem('ls.system_lock_time', '');
    const number = system_lock_time?.replace(/[^0-9]/g, '').replace('6', '4') || '3';
    const secondTime = number * 3600;

    // var secondTime = number*10;
    // secondTime 만큼 미사용시 화면 잠금
    IdleProvider.idle(secondTime);
    // idle 발동후 15초 후 화면잠김
    IdleProvider.timeout(15);
  }])

  /**
   * 세션 타임 아웃시 타이틀 설정 관련
   * https://github.com/HackedByChinese/ng-idle/wiki/Title
   * 2017-03-11 matthew
   */
  .run(function (Title, gettextCatalog) {
    Title.timedOutMessage(gettextCatalog.getString('장시간 사용 하지 않아 화면이 잠겼습니다.', {}));
    Title.idleMessage('(장시간 미사용 잠김 예정 {{seconds}} 초 남음)', {});
  })

// .config(['$ocLazyLoadProvider', function($ocLazyLoadProvider) {
//   $ocLazyLoadProvider.config({
//     // 전역 설정을 여기에 입력
//   });
// }])

// /**
//  * 컨트롤러 설정
//  */
// .config(function ($controllerProvider) {
//   $controllerProvider.allowGlobals();
// })

/**
   * $log.error, $log.debug 등의 앵귤러 로그 출력의 ON/OFF 처리
   *
   * 9000 포트로 접속시 디버그 모드로 인식하고 활성화, 아니면 표시 안함
   * .config 에서는 $location, settings 등의 타 모듈 로딩 불가함
   * 2017-02-11 matthew
   */

  // $log.debug 제거
  .config(function ($logProvider) {
    $logProvider.debugEnabled(location.port == 9000);
  })

  // $log.log,warn,info,error 제거
  .config(['$provide', function ($provide) {
    // $log 객체를 덮어쓰는 방식
    $provide.decorator('$log', ['$delegate',
      function ($delegate) {
        let $log, enabled = (location.port == 9000);

        $log = {
          debugEnabled: function (flag) {
            enabled = !!flag;
          }
        };

        ['log', 'warn', 'info', 'error', 'debug'].forEach(function (methodName) {
          $log[methodName] = function () {
            if (!enabled) {
              return;
            }
            const logger = $delegate;

            logger[methodName].apply(logger, arguments);
          };
        });

        return $log;
      }
    ]);
  }])

  /**
   * HTML 캐싱 방지
   * 뷰 페이지주소 뒤에 임의의 글자추가
   * 2018-04-24 chris
   * https://stackoverflow.com/questions/23589843/disable-template-caching-in-angularjs-with-ui-router?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
   */
  .config(['$provide', function($provide) {
    function templateFactoryDecorator($delegate) {
      const fromUrl = angular.bind($delegate, $delegate.fromUrl);

      $delegate.fromUrl = function (url, params) {
        // Set a suffix outside the decorator function
        const cacheBuster = Date.now().toString();

        if (url !== null && angular.isDefined(url) && angular.isString(url)) {
          url += (url.indexOf('?') === -1 ? '?' : '&');
          url += `v=${cacheBuster}`;
        }

        return fromUrl(url, params);
      };

      return $delegate;
    }
    $provide.decorator('$templateFactory', ['$delegate', templateFactoryDecorator]);
  }])

  /**
   * 성능 개선을 위해 디버그/Info 모드 해제
   *
   * 런타임 시에 디버깅 모드를 켜고 끌지 정하는 속성
   * 이 메소드의 디폴트 값이 켜져 있기 때문에 따로 설정하지 않는다면 실제 서비스 환경에서도 디버깅 모드로 동작하게 됨
   * 디버깅 모드에서는 DOM을 조작하는 경우(class 변수 수정 등)가 많이 발생하기 때문에 성능저하를 일으키게 됨
   *
   * 2017-05-19 MatthewKim
   */
  .config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(false);
  }])

  /**
   * AngularJS v 1.6.0 버전업 수정
   * Possibly unhandled rejection 에러 처리
   * 2017-02-07 chris
   */
  .config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }])

/**
   * Angular HotKey 설정
   */
// .config(function (hotkeysProvider) {

//   // ? 키 누르면 나오는 자동 키보드 단축키 안내 기능 사용 여부
//   hotkeysProvider.includeCheatSheet = true;
//   hotkeysProvider.templateHeader = '<div class="my-own-header">...</div>';
//   hotkeysProvider.templateFooter = '<div class="my-own-footer">...</div>';
//   //hotkeysProvider.cheatSheetDescription = '이 단축키 메뉴 보기/닫기';

//   // ngRoute 해제
//   hotkeysProvider.useNgRoute = false;

// })

  /**
   * uib-tooltip appendToBody 전체적용
   */
  .config(['$uibTooltipProvider', function ($uibTooltipProvider) {
    $uibTooltipProvider.options({
      appendToBody: true
    });
  }])

  /**
   * WebWorker 셋업
   *
   * 2017-05-23 MatthewKim
   */
  .config(function (WebworkerProvider) {

    // WebworkerProvider.setHelperPath("/base/src/worker_wrapper.js");
    // WebworkerProvider.setUseHelper(false);

    // 워커와 부모쓰레드간에 통신에 바이너리를 사용하여 웹워커 성능 향상
    WebworkerProvider.setTransferOwnership(true);
  });

/**
   * 루트 스콥 전역 설정은 AppController.js 로 이동
   * 2017-02-10 MatthewKim
  */
