/**
 * 사용자태그 관리
 */
'use strict';

angular.module('gmpApp')
  .controller('TagDetailCtrl', function ($scope, commonSVC, $timeout, $rootScope, $filter, $uibModalInstance, settings, gettextCatalog, tagModel, tagSVC) {
    $scope.showDetail = 'N';
    $scope.updateLabel = '등록';

    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchData: {
        selectCount: 0,       // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
        showCount: 10,
      }
    };

    $scope.searchData = angular.copy(search.searchData);

    $scope.tag_colors = angular.copy(tagSVC.tag_color());

    $scope.searchBtn = {
      table_actions: [
        {
          label: gettextCatalog.getString('추가'),
          test_id: 'btn-tag-add',
          add_class: 'btn-default btn-xxs border-radius',
          action: function () {
            $scope.tagDetail();
          },
          perm_only: ['order.unstoring+write']
        },
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      emptyText: '태그를 등록해주세요.',
      modal: true,
      isNavigatorCenter: true,
      pinningColumns: [],
      defaultSortingColumns: ['tag_no'],
      alignCenterColumns: [
        'tag_no', 'widget', 'tag_name', 'tag_color'
      ],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'tag_color'],
      notResizingColumns: ['tag_no', 'widget', 'tag_name', 'tag_color'],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      gridHeight: 350,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/tag/list`,
        requestWillAction: function (data) {
          const assignData = Object.assign(data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(assignData);

          return Object.assign(assignData, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.searchData.viewCount = result.recordsFiltered;
          $scope.tag_list = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'tag_no',
          title: 'No',
          width: 65
        },
        {
          key: 'widget',
          title: '도구',
          width: 95,
          template: function (row) {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.tagDetail(${row.tag_no})" >상세</button>
              <button
                class="btn btn-default btn-xxs"
                ng-click="grid.appScope.delete(${row.tag_no})">
                삭제
              </button>
            `;
          }
        },
        {
          key: 'tag_name',
          title: '태그',
          width: 211,
          template: function (row) {
            return `<span><b>${row.tag_name}</b></span>`;
          }
        },
        {
          key: 'tag_color',
          width: 53,
          title: '색상',
          template: function(row) {
            return `<span style="background-color: ${tagSVC.tag_color().find(({ name }) => name === row.tag_color)?.code}; position: absolute; height: 20px; width: 20px; right: 17px;"></span>`;
          }
        }
      ]
    };

    /**
		 * 테이블 검색
		 */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
		 * 테이블 pageLength 조절
		 */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 태그 추가 취소
     */
    $scope.tagCancel = function() {
      $scope.showDetail = 'N';
      $scope.tag = angular.copy(tagSVC.tag); // 초기화
    };

    /**
     * 취소
     */
    $scope.cancel = function() {
      $uibModalInstance.close('success');
    };

    /**
     * 태그 삭제
     */
    $scope.delete = async (tag_no) => {
      let message;
      if ($scope.tag_list.some(v => v.tag_no === tag_no && v.rule_terms_concat)) {
        message = '해당 태그를 사용하는 조건은 사용안함으로 변경되며<br>기존 주문에 적용된 태그도 삭제됩니다.<br>태그를 삭제하시겠습니까?';
      } else {
        message = '기존 주문에 적용된 태그도 삭제됩니다.<br>태그를 삭제하시겠습니까?';
      }

      // const isDelete = await commonSVC.showConfirm(message, '');
      const confirm = await commonSVC.showConfirmHtml(message, '', '', { confirmButtonText: '삭제' });

      if (confirm) {
        try {
          const result = await tagModel.del({ tagNo: tag_no });
          if (result.data.results === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사용자태그 삭제에 성공하였습니다.'));
            $scope.searchDo();
          } else {
            throw new Error();
          }
        } catch (error) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사용자태그 삭제에 실패하였습니다.'));
        }
      }
    };

    $scope.tagDetail = async (tag_no) => {
      $scope.showDetail = 'Y';
      if (tag_no) {
        $scope.updateLabel = '수정';
        $scope.tag = $scope.tag_list.find(v => v.tag_no === tag_no);
      } else {
        $scope.updateLabel = '등록';
        $scope.tag = angular.copy(tagSVC.tag);
      }
    };

    $scope.tagUpdate = async () => {
      try {
        if (!$scope.tag.tag_name) {
          throw Error('태그를 입력해주세요.');
        }
        $scope.tag.mode = $scope.updateLabel === '수정' ? 'edit' : 'add';

        if ($scope.tag.mode === 'edit') {
          const confirm = await commonSVC.showConfirmHtml('기존 주문에 적용된 태그도 수정됩니다.<br>태그를 수정하시겠습니까?', '', '', { confirmButtonText: '수정' });
          confirm && await saveTag();
        } else {
          await saveTag();
        }
      } catch (err) {
        commonSVC.showMessage(err.message || err.data.message, '');
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
      }
    };

    async function saveTag() {
      const result = await tagModel.save($scope.tag);
      const toasterTxt = $scope.tag.mode === 'edit' ? '수정' : '등록';

      if (result.data.result === 'success') {
        commonSVC.showToaster('success', '', `태그 ${toasterTxt}에 성공하였습니다.`);
      } else {
        commonSVC.showToaster('error', '', `태그 ${toasterTxt}에 실패하였습니다.`);
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
        throw Error(result.data.message);
      }
      $scope.showDetail = 'N'; // 등록,수정 영역 닫기
      $scope.searchDo();
    }
  });
