'use strict';

angular.module('gmpApp')
  .controller('ProdProductListCtrl', function ($scope, $rootScope, $filter, $compile, $timeout, $state,
    commonSVC, settings, productSVC,
    supplierList, warehouseList, ebaydepotAccounts,
    productModel, supplierModel, systemModel, warehouseModel, inventoryModel, commonModel,
    gettextCatalog, systemList, userInfo, memoTemplateList) {
    $scope.countList = { total: 0, real_stock_none: 0, recently_prod: 0, safe_stock_alert: 0, group_prod: 0, group_prod_cnt: 0 };
    $scope.selectCount = 'total';
    $scope.prodLogInfo = {};
    $scope.isDepot = $rootScope.user_profile.auth_type === '배송처';
    $scope.isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');
    $scope.currentTab = 0;
    $scope.memoTemplateList = memoTemplateList.data.results || [];

    $scope.prodInit = true;
    $scope.gatherInit = false;

    // 초기화 시 날짜검색 키 버튼이 선택되지 않아서 해당 값으로 선택처리하기 위해 객체 생성
    const searchKeyNames = {
      1: '1MONTH',
      3: '3MONTH',
      6: '6MONTH',
      12: '1YEARS'
    };
    // 엘지 커스텀용 모델번호 노출 여부
    const viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    let dataList = [];

    $scope.ebaydepotAccounts = ebaydepotAccounts.data.results;

    if (!supplierList.data.result.find(o => o.supp_name === '매입처없음')) {
      supplierList.data.result.unshift({ supp_no: 'null', supp_name: '매입처없음' });
    }
    if (!warehouseList.data.result.find(o => o.warehouse_name === '배송처없음')) {
      warehouseList.data.result.unshift({ code: 'null', warehouse_name: '배송처없음' });
    }

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(productSVC.searchData);
    $scope.searchData.search_date_key_names = searchKeyNames[systemList.data.search_date] || '전체'; // 초기화 시 날짜검색 키 버튼이 선택되지 않아서 해당 값으로 선택처리
    $scope.searchForm = angular.copy(productSVC.searchForm);
    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    // 값이 있는경우에만 설정기한 사용하도록 함. 기본은 솔루션 신청일. 2018-03-07 rony
    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }
    $scope.searchForm.search_key = localStorage.getItem(`prod_search_key_${userInfo.user.m_no}`) || 'all';

    const searchDetail = [
      /*
      {
        title:gettextCatalog.getString("카테고리 선택"),
        search_name:"category",
        item_list: categoryList.data.results.all || [],
        item_key:"name",
        item_value:"id",
        select_value:""
      },
      */
      {
        title: gettextCatalog.getString('매입처 선택'),
        search_name: 'supplier_vendor',
        item_list: supplierList.data.result || [],
        item_key: 'supp_name',
        item_value: 'supp_no',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'delivery_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('연동상품여부 선택'),
        search_name: 'linkage_flag', item_list: [{
          code: 'Y', name: '연동상품 있음' }, {
          code: 'N', name: '연동상품 없음' }],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('연동주문여부 선택'),
        search_name: 'link_ord_yn', item_list: [{
          code: 'Y', name: '연동주문 있음' }, {
          code: 'N', name: '연동주문 없음' }],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('과세여부 선택'),
        search_name: 'tax_type',
        item_list: [
          { code: '일반과세', name: '일반과세' },
          { code: '간이과세', name: '간이과세' },
          { code: '면세', name: '면세' },
          { code: '영세', name: '영세' },
          { code: '비과세', name: '비과세' }
        ],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      },
      {
        title: gettextCatalog.getString('합포장여부 선택'),
        search_name: 'bundle_avail_yn',
        item_list: [
          { code: '1', name: '가능' },
          { code: '0', name: '불가(개별배송)' }
        ],
        item_key: 'name',
        item_value: 'code',
        select_value: ''
      }
    ];
    const searchDetailMulti = [
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'multi_deliver_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('매입처 선택'),
        search_name: 'multi_supplier_vendor',
        item_list: supplierList.data.result || [],
        item_key: 'supp_name',
        item_value: 'supp_no',
        search_word: ''
      }
    ];
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
    if ($scope.isDepot) {
      let searchIndex = _.findIndex(searchDetail, { search_name: 'delivery_vendor' });

      if (searchIndex > -1) {
        searchDetail.splice(searchIndex, 1);
      }

      searchIndex = _.findIndex(searchDetail, { search_name: 'supplier_vendor' });

      if (searchIndex > -1) {
        searchDetail.splice(searchIndex, 1);
      }
    }

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchBtn = {      // 상세조건 버튼
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-work',
          item_list: [
            {
              label: gettextCatalog.getString('상품 복사'),
              test_id: 'btn-product-copy',
              action: function() {
                $scope.copyProds();
              },
              perm_only: ['prod.product+write']
            },
            {
              label: gettextCatalog.getString('상품 삭제'),
              test_id: 'btn-product-del',
              action: function () {
                $scope.deleteProds();
              },
              perm_only: ['prod.product+admin']
            }
          ]
        }, {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-excel',
          item_list: [
            {
              label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
[SKU상품관리], [SKU재고현황]메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
              menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
[SKU상품관리], [SKU재고현황]메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
              test_id: 'btn-excel-intergrate',
              action: function () {
                $scope.downloadIntegratedExcel();
              },
              perm_only: ['prod.product+write']
            }
          ]
        },
        {
          label: gettextCatalog.getString('정보 일괄 수정'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-info',
          action: function () {
            $scope.modifyProdModal();
          },
          perm_only: ['prod.product+write']
        },
        {
          label: gettextCatalog.getString('안전 재고 수정'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-safetyproduct',
          action: function() {
            $scope.modifyStockModal('safe');
          },
          perm_only: ['prod.product+write']
        },
        {
          label: gettextCatalog.getString('바코드 출력'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-safetyproduct',
          action: function() {
            $scope.printBarcode();
          },
          perm_only: ['prod.product+write']
        },
        ...($rootScope.osse_sol && [
          {
            label: gettextCatalog.getString('포장 형태 설정'),
            add_class: 'a-btn-blue',
            test_id: 'btn-fix-prodPkgBox',
            action: function() {
              $scope.setProdPkgBox();
            },
            perm_only: ['prod.product+write']
          }]) || []
      ],
      actions_right: [
        {
          label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('SKU상품 등록')}</span><span class="caret"></span>`,
          small_label: '상품등록',
          sub_label: '',
          add_class: `${$scope.isSmile ? 'col-xs-4' : 'col-xs-12'}`,
          test_id: 'dropdown-basic-prod',
          btn_type: 'dropdown',
          item_list: [
            {
              label: gettextCatalog.getString('SKU상품 등록'),
              hotkey: '',
              test_id: 'btn-self-add-prod',
              perm_only: ['prod.product'],
              action: function() {
                // SKU상품등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission)) {
                  $state.go('main.prod_product_add');
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 등록'),
              hotkey: '',
              test_id: 'btn-add-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // SKU상품등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission)) {
                  $scope.excelUpload('add');
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 수정'),
              hotkey: '',
              test_id: 'btn-update-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // SKU상품등록 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission)) {
                  $scope.excelUpload('edit');
                }
              }
            }
          ]
        }
      ],
      actions_right_bottom: []
    };

    if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      $scope.searchBtn.actions_right.find(o => o.small_label == '상품등록').item_list.push({
        label: gettextCatalog.getString('LG전자 상품 등록'),
        hotkey: '',
        perm_only: ['prod.product+write'],
        action: function() {
          // TODO 본사 상품 가져오기\
          const params = {
            data: {
              warehouseList: function() {
                return warehouseModel.ListAll({ use_yn: true });
              },
              type: 'prod',
              result: [{
                prod_no: 111111
              }]
            }
          };

          const modal = commonSVC.openModal('full', params, 'ScrapSuperProdCtrl', 'views/prod/product/modals/scrap_super_prod.html', false);

          modal.result.then((re) => {
            if (re === 'success') {
              $scope.searchDo(true, true);
            }
          });
        }
      });
    }

    // Ebay 배송처 인경우 SKU상품 등록 불가.
    if ($scope.isDepot && $rootScope.user_profile.ebaydepot_yn) {
      $scope.searchBtn.actions_right = [];
    }

    /**
     * G마켓 물류 스마일배송전용 버튼추가 - Daniel
     */
    if (($scope.isSmile && !$scope.isDepot)
      || ($scope.isDepot && $rootScope.user_profile.ebaydepot_yn)) {
      $scope.searchBtn.actions_right.unshift(
        {
          label: `<i class="icon-sync worksvc-spinner-orderSync order"></i> ${gettextCatalog.getString('SKU 동기화')}`,
          small_label: 'SKU동기화',
          test_id: 'btn-sku-reset',
          btn_type: 'button',
          add_class: 'col-xs-4',
          perm_only: ['prod.product+write'],
          action: function () {
            $scope.syncSku();
          }
        },
        {
          label: `<i class="picon-get"></i> ${gettextCatalog.getString('SKU 가져오기')}`,
          small_label: 'SKU수집',
          test_id: 'btn-sku-bring',
          btn_type: 'button',
          add_class: 'col-xs-4',
          perm_only: ['prod.product+write'],
          action: function () {
            $scope.scrapSku();
          }
        }
      );
    }

    // 버튼 개수 변경시 크기 안맞아서 맞추는 처리 추가
    _.forEach($scope.searchBtn.actions_right, (btn) => {
      btn.add_class = `col-xs-${12 / $scope.searchBtn.actions_right.length} ${btn.add_class.replace(/col-xs-[0-9]{1,2}/g, '')}`;
    });

    $scope.searchBtnGather = {      // 상세조건 버튼
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-work',
          item_list: [
            {
              label: '상품 복사',
              test_id: 'btn-product-copy',
              action: function() {
                $scope.copyProds();
              },
              perm_only: ['prod.product+write']
            },
            {
              label: '상품 삭제',
              test_id: 'btn-product-del',
              action: function () {
                $scope.deleteProds();
              },
              perm_only: ['prod.product+admin']
            }
          ]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
[SKU상품관리], [SKU재고현황]메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
              menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
[SKU상품관리], [SKU재고현황]메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
              test_id: 'btn-excel-intergrate',
              action: function () {
                $scope.downloadIntegratedExcel();
              },
              perm_only: ['prod.product+write']
            }
          ]
        }, {
          label: '스타일상품에서 제외',
          add_class: 'a-btn-blue',
          action: function() {
            $scope.styleException();
          },
          perm_only: ['prod.product+write']
        }
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (prod_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(prod_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ prod_no }) => prod_no);
      $scope.$apply();
    }, 200));

    /**
     *  데이터 테이블 탭1
     */
    $scope.grid = $scope.productGrid = {};
    $scope.productGrid.methods = {};
    $scope.productGrid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'stock_status', 'link_yn', 'link_ord_yn', 'unavail_out_cnt', 'cost_price', 'bundle_avail_yn', 'style_cd', 'pkg_name'],
      alignRightColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'supply_price', 'supp_name', 'in_unit_info', 'max_ea_cnt'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'model_no', 'in_unit_info', ...($rootScope.osse_sol && (['pkg_name', 'max_ea_cnt'])) || []],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['unavail_out_cnt', 'madein', 'tax_type', 'cost_price', 'model_no', ...(!$rootScope.osse_sol && (['pkg_name', 'max_ea_cnt'])) || []],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/list`,
        requestWillAction: function (data) {
          if ($rootScope.memo_etc_search.page === 'product' && $rootScope.memo_etc_search.search) {
            const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

            $scope.searchForm = {
              ...$scope.searchForm,
              date_type,
              sdate,
              edate,
              search_key,
              search_word,
              search_type: 'exact'
            };
            $rootScope.memo_etc_search.search = false;
          }

          if ($rootScope.side_search.page === 'product' && $rootScope.side_search.search) {
            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.selectCount = $rootScope.side_search.selectCount;
            $rootScope.side_search.search = false;
          }

          if ($rootScope.multi_search && $rootScope.multi_search.productList) {
            angular.merge($scope.searchForm, $rootScope.multi_search.productList);

            delete $rootScope.multi_search.productList;
          }

          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.sol_no;

          if ($scope.isDepot) {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          return data;
        },
        requestDidAction: function (result) {
          dataList = result.results;
          $scope.searchData.totalCount = result.recordsTotal;

          // 집계카운트
          $scope.countList = {
            total: result.total,
            real_stock_none: result.real_stock_none,
            recently_prod: result.recently_prod,
            safe_stock_alert: result.safe_stock_alert,
            group_prod: result.group_prod,
            group_prod_cnt: result.group_prod_cnt
          };

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function (row) {
            //row.uniq 교체해야함
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')">상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled(${row.prod_no})" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2);">메모</button>`;
          }
        },
        // 등록일
        {
          title: '등록일',
          key: 'wdate',
          width: 130,
          filter: 'dateValid',
        },
        // 스타일코드
        {
          title: '스타일코드',
          key: 'style_cd',
          width: 100
        },
        //SKU_CODE
        {
          title: 'SKU코드',
          key: 'sku_cd',
          width: 120,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary" id=${row.prod_no}>${row.sku_cd}</span>`;
          }
        },
        // 재고관리코드
        {
          title: '재고관리코드',
          key: 'stock_cd',
          width: 120
        },
        //이미지+상품명
        {
          title: 'SKU상품명',
          key: 'prod_name',
          width: 330,
          notCompile: true,
          template: function (row) {
            const img = row.prod_img || '/assets/images/upload.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name.replace(/\ /g, '&nbsp')}</span>`;
          }
        },
        //속성
        {
          title: '속성',
          key: 'attri',
          width: 160
        },
        //브랜드
        {
          title: '브랜드',
          key: 'brand',
          width: 100
        },
        // 판매가
        {
          title: '판매가',
          key: 'sale_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        //실재고
        {
          title: '실재고',
          key: 'real_stock',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.real_stock < 0 ? 0 : row.real_stock;
          }
        },
        //판매가능재고
        {
          title: '판매가능재고',
          key: 'stock_cnt',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        //안전재고
        {
          title: '안전재고',
          key: 'stock_cnt_safe',
          width: 100,
          notCompile: true,
          template: function (row) {
            return parseInt(row.stock_cnt_safe) || 0;
          }
        },
        //상태
        {
          title: '상태',
          key: 'stock_status',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_status;
          }
        },
        //합포장여부
        {
          title: '합포장여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        //연동상품
        {
          title: '연동상품',
          key: 'link_yn',
          width: 100,
          template: function (row) {
            const linkIcon = row.link_yn || row.link_set_yn ? `<a ng-click="grid.appScope.linkageModalOpen('${row.sku_cd}', '${row.prod_no}', ${!!row.link_yn}, ${!!row.link_set_yn})">보기</a>` : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        // 연동주문
        {
          title: '연동주문',
          key: 'link_ord_yn',
          width: 90,
          template: function (row) {
            const linkIcon = row.link_ord_yn ? `<a ng-click="grid.appScope.openOrderList('${row.sku_cd}')">보기</a>` : '<span class="text-grey-100">보기</span>';

            return linkIcon;
          }
        },
        //미출고
        {
          title: '미출고',
          key: 'unavail_out_cnt',
          width: 100,
          template: function (row) {
            // if(row.unavail_out_cnt > 0){
            //   //미출고 - 현재 재고
            //   var unrel_stock = row.unavail_out_cnt - row.stock_cnt;
            //   // return "<a ng-click='unreportedModalOpen(\""+row.sku_cd+"\")'>"+unrel_stock+"</a>";
            //   return Math.abs(unrel_stock);
            // }else{
            //   return "0";
            // }
            return row.unavail_out_cnt;
          }
        },
        //원산지
        {
          title: '원산지',
          key: 'madein',
          width: 100,
          notCompile: true,
          template: function (row) {
            // 원산지가 기타일 때
            if (!row.madein_group && (row.madein_type === 'null' || row.madein_type === null || row.madein_type === '')) {
              return row.madein_etc || '';
            } else {
              return `${row.madein_group} ${row.madein_name}`;
            }
          }
        },
        //과세여부
        {
          title: '과세여부',
          key: 'tax_type',
          width: 100
        },
        //입고단위
        {
          title: !$rootScope.osse_sol ? '입고단위' : '낱본 수량',
          key: 'in_unit_info',
          width: 100,
          template: function (row) {
            if (row.in_unit_cnt) {
              return !$rootScope.osse_sol ? row.in_unit_cnt + row.in_unit : row.in_unit_cnt;
            } else {
              return '';
            }
          }
        },
        //원가
        {
          title: '원가',
          key: 'cost_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.cost_price, '', 0);
          }
        },
        //공급가
        {
          title: '공급가',
          key: 'supply_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.supply_price, '', 0);
          }
        },
        // 매입처
        {
          title: '매입처',
          key: 'supp_name',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.supp_name || '없음';
          }
        },
        // 배송처
        {
          title: '배송처',
          key: 'depot_name',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.depot_name || '없음';
          }
        },
        // 모델명
        {
          title: '모델명',
          key: 'model',
          width: 100
        },
        // 바코드
        {
          title: '바코드',
          key: 'barcode',
          width: 100
        },
        // 제조일자
        {
          title: '제조일자',
          key: 'made_date',
          width: 100,
          filter: 'dateValid'
        },
        // 유효일자
        {
          title: '유효일자',
          key: 'expire_date',
          width: 100,
          filter: 'dateValid'
        },
        // 수정일
        {
          title: '수정일',
          key: 'mdate',
          width: 130,
          filter: 'dateValid'
        },
        ...($rootScope.osse_sol && ([
          // 포장 형태
          {
            title: '포장 형태',
            key: 'pkg_name',
            width: 100,
          },
          // 합포장 가능 최대 수량
          {
            title: '합포장 가능 최대 수량',
            key: 'max_ea_cnt',
            width: 110
          }
        ])) || []
      ]
    };

    if (viewModelNo) {
      $scope.productGrid.options.columns.push({
        key: 'model_no',
        title: '모델번호',
        width: 100
      });
    }

    /**
    //  * 배송처 권한 매입처, 배송처 컬럼 삭제
    //  */
    if ($scope.isDepot) {
      $scope.grid.options.columns = $scope.grid.options.columns.filter(col => {
        return !['매입처', '배송처'].includes(col._title);
      });
    }

    /**
     * G마켓 물류 스마일배송전용 필드추가 - Daniel
     */
    if ($scope.isSmile) {
      $scope.grid.options.columns.splice(13, 0,
        {
          title: '합포장여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn === 1 ? '가능' : '불가(개별포장)';
          }
        }
      );
    }

    /**
     *  데이터 테이블 탭2
     */
    $scope.gatherGrid = {};
    $scope.gatherGrid.methods = {};
    $scope.gatherGrid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'bundle_avail_yn', 'link_yn', 'link_ord_yn', 'unavail_out_cnt', 'cost_price'],
      alignRightColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'cost_price', 'supply_price', 'supp_name', 'in_unit_info'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'real_stock', 'in_unit_info'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'cost_price', 'supply_price', 'supp_name'],
      groupOptions: {
        groupCountKey: 'selectStyleCnt',
        groupExceptionKey: 'groupRowYn',
        groupDataKey: 'style_cd',

        groupRowHeaderTemplate: (row, index) => {
          return `
            <div style="margin-left: 5px;">
              <i class="fa fa-folder mr-5" style="color: #F8D79F"></i>${row.style_cd}
              <ul class="icons-list pl-5" style="position: absolute; z-index: 100; display: inline-block;">
                <li uib-dropdown dropdown-append-to-body>
                  <a href="#" id="simple-dropdown" uib-dropdown-toggle>
                    <i class="fa fa-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="simple-dropdown">
                    <li>
                      <a ng-click="grid.appScope.addToGroupModal(${index},${row.style_cd})">스타일상품에 상품 추가</a>
                    </li>
                    <li>
                      <a ng-click="grid.appScope.styleRelease(${row.style_cd})">스타일상품 해제</a>
                    </li>
                    <li>
                      <a ng-click="grid.appScope.styleGroupCopy(${row.style_cd})">스타일그룹 복사</a>
                    </li>
                    <li>
                      <a ng-click="grid.appScope.styleGroupDelete(${row.style_cd})">스타일그룹 삭제</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          `;
        },
        groupRowTemplate: () => {
          return '';
        }
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/list`,
        requestWillAction: function (data) {
          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.sol_no;

          if ($scope.isDepot) {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          return data;
        },
        requestDidAction: function (result) {
          dataList = result.results;
          result.recordsTotal = result.group_prod_cnt;
          result.recordsFiltered = result.group_prod_cnt;
          $scope.searchData.totalCount = result.group_prod;

          // 집계카운트
          $scope.countList = {
            total: result.total,
            real_stock_none: result.real_stock_none,
            recently_prod: result.recently_prod,
            safe_stock_alert: result.safe_stock_alert,
            group_prod: result.group_prod,
            group_prod_cnt: result.group_prod_cnt
          };

          return result.results;
        }
      },
      columns: [
        //도구
        {
          title: '도구',
          key: 'widget',
          width: 100,
          template: function (row) {
            // row.uniq 수정필요
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')" >상세</button>
              <button ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
          }
        },
        // 등록일
        {
          title: '등록일',
          key: 'wdate',
          width: 130,
          filter: 'dateValid',
        },
        //SKU_CODE
        {
          title: 'SKU코드',
          key: 'sku_cd',
          width: 120,
          notCompile: true,
          template: function (row) {
            return `<span class="text-primary" id=${row.prod_no}>${row.sku_cd}</span>`;
          }
        },
        // 재고관리코드
        {
          title: '재고관리코드',
          key: 'stock_cd',
          width: 120
        },
        //이미지+상품명
        {
          title: 'SKU상품명',
          key: 'prod_name',
          width: 330,
          notCompile: true,
          template: function (row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        //속성
        {
          title: '속성',
          key: 'attri',
          width: 160
        },
        //브랜드
        {
          title: '브랜드',
          key: 'brand',
          width: 100
        },
        // 판매가
        {
          title: '판매가',
          key: 'sale_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        //실재고
        {
          title: '실재고',
          key: 'real_stock',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.real_stock < 0 ? 0 : row.real_stock;
          }
        },
        //판매가능재고
        {
          title: '판매가능재고',
          key: 'stock_cnt',
          width: 100,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        //안전재고
        {
          title: '안전재고',
          key: 'stock_cnt_safe',
          width: 100,
          notCompile: true,
          template: function (row) {
            return parseInt(row.stock_cnt_safe) || 0;
          }
        },
        //연동상품
        {
          title: '연동상품',
          key: 'link_yn',
          width: 100,
          template: function (row) {
            const linkIcon = row.link_yn || row.link_set_yn ? `<i class="icon-primitive-dot text-success-600 cursor-pointer" ng-click="grid.appScope.linkageModalOpen('${row.sku_cd}', '${row.prod_no}', ${!!row.link_yn}, ${!!row.link_set_yn})"></i>` : '<i class="icon-primitive-dot text-grey-100"></i>';

            return linkIcon;
          }
        },
        // 연동주문
        {
          title: '연동주문',
          key: 'link_ord_yn',
          width: 90,
          template: function (row) {
            const linkIcon = row.link_ord_yn ? `<i class="icon-primitive-dot text-success-600 cursor-pointer" ng-click="grid.appScope.openOrderList('${row.sku_cd}')"></i>` : '<i class="icon-primitive-dot text-grey-100"></i>';

            return linkIcon;
          }
        },
        //미출고
        {
          title: '미출고',
          key: 'unavail_out_cnt',
          width: 100,
          template: function (row) {
            // 케이요청으로 미출고 카운트 변경 2018-12-07 rony (https://playautogmpproject.atlassian.net/browse/GMPKR-6247)
            // if(row.unavail_out_cnt > 0){
            //   //미출고 - 현재 재고
            //   var unrel_stock = row.unavail_out_cnt - row.stock_cnt;
            //   // return "<a ng-click='unreportedModalOpen(\""+row.sku_cd+"\")'>"+unrel_stock+"</a>";
            //   return Math.abs(unrel_stock);
            // }else{
            //   return "0";
            // }
            return row.unavail_out_cnt;
          }
        },
        //원산지
        {
          title: '원산지',
          key: 'madein',
          width: 100,
          notCompile: true,
          template: function (row) {
            if (!row.madein_group && (row.madein_type === 'null' || row.madein_type === null || row.madein_type === '')) {
              return row.madein_etc || '';
            } else {
              return `${row.madein_group} ${row.madein_name}`;
            }
          }
        },
        //과세여부
        {
          title: '과세여부',
          key: 'tax_type',
          width: 100 },
        //입고단위
        {
          title: !$rootScope.osse_sol ? '입고단위' : '낱본 수량',
          key: 'in_unit_info',
          width: 100,
          template: function (row) {
            if (row.in_unit_cnt) {
              return row.in_unit_cnt + row.in_unit;
            } else {
              return '';
            }
          }
        },
        //원가
        {
          title: '원가',
          key: 'cost_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.cost_price, '', 0);
          }
        },
        //공급가
        {
          title: '공급가',
          key: 'supply_price',
          width: 100,
          notCompile: true,
          template: function (row) {
            return $filter('currency')(row.supply_price, '', 0);
          }
        },
        // 매입처
        {
          title: '매입처',
          key: 'supp_name',
          width: 100
        },
        // 배송처
        {
          title: '배송처',
          key: 'depot_name',
          width: 100
        },

        // 모델명
        {
          title: '모델명',
          key: 'model',
          width: 100
        },
        // 바코드
        {
          title: '바코드',
          key: 'barcode',
          width: 100
        },
        // 제조일자
        {
          title: '제조일자',
          key: 'made_date',
          width: 100,
          filter: 'dateValid',
        },
        // 유효일자
        {
          title: '유효일자',
          key: 'expire_date',
          width: 100,
          filter: 'dateValid',
        },
        // 수정일
        {
          title: '수정일',
          key: 'mdate',
          width: 130,
          filter: 'dateValid',
        }
      ],
    };

    /**
     * G마켓 물류 스마일배송전용 필드추가 - Daniel
     */
    if ($scope.isSmile) {
      $scope.gatherGrid.options.columns.splice(12, 0,
        {
          title: '합포장여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn === 1 ? '가능' : '불가(개별포장)';
          }
        }
      );
    }

    // 현재 노출중인 컬럼수 체크해서 gatherGrid 의 그룹 td 에 colspan 적용해주어야 함.
    // 이거 안해주면 테이블 필드 길이가 달라짐짐
    // 2018-02-20 rony
    $scope.$on('bundleRowChange', function(event, data) {
      setTimeout(function() {
        $('.bundleColspan').attr('colspan', data);
      }, 0);
    });

    $scope.initSelectRowUniqList = () => {
      $scope.selectRowUniqList = [];
    };

    /**
     * 카운트 검색
     * */
    $scope.countSearch = function(type) {
      $scope.initSelectRowUniqList();
      $scope.searchForm.safe_stock_alert = false;
      $scope.searchForm.real_stock_none = false;
      $scope.searchForm.recently_prod = false;
      $scope.searchForm.group_prod = false;

      // 그룹 선택시에는 스타일코드 관리 탭열리도록.
      // 나머지 선택시에는 개별SKU상품 탭 열리도록 처리함 2018-03-26 rony
      switch (type) {
        case 'safe_stock_alert':
          $scope.selectTab(false, 'status');
          $scope.searchForm.safe_stock_alert = true;
          break;
        case 'real_stock_none':
          $scope.selectTab(false, 'status');
          $scope.searchForm.real_stock_none = true;
          break;
        case 'recently_prod':
          $scope.selectTab(false, 'status');
          $scope.searchForm.recently_prod = true;
          break;
        case 'group_prod':
          $scope.selectTab(true, 'status');
          $scope.searchForm.group_prod = true;
          $scope.gatherInit = true;
          break;
        default:
          $scope.selectTab(false, 'status');
          break;
      }

      $scope.searchDo(true, true);
      $scope.selectCount = type;
    };

    /**
     * 통합 엑셀 다운로드
     */
    $scope.downloadIntegratedExcel = function () {
      if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
        return false;
      }

      const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터

      const selectedData = $scope.grid.methods.selectedData('all');
      const resolve = {};

      resolve.data = {
        excelFieldList: $scope.excelFieldList,
        title: 'SKU상품 관리',
        url: '/app/stock/base/downIntegrated',
        searchForm: $scope.pageData,
        page: 'sku_product',
        visibleTable: visibleTable,
        isGlobal: $scope.selectedShopType === 'global',
        progress: true
      };

      if (!selectedData.length) {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
        resolve.data.type = 'all';
      } else {
        const skuCodeList = $scope.currentTab == 0 ? $scope.grid.methods.selectedData('sku_cd') : $scope.gatherGrid.methods.selectedData('sku_cd');

        resolve.data.sku_code = skuCodeList;
        resolve.data.count = skuCodeList.length;
        resolve.data.isAll = false;
      }

      commonSVC.openModal('lg', resolve, 'SkuExcelDownIntegratedCtrl', 'views/prod/product/modals/sku_excel_down_integrated.html');
    };

    /**
     * 엑셀 업로드 모달창
     * */
    $scope.excelUpload = function (mode) {
      const resolve = {
        data: {
          mode: mode,
          prod_auto_set_yn: systemList.data.prod_auto_set_yn
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'ProdProductExcelUploadCtrl', 'views/prod/product/modals/excel_upload.html');

      modal.result.then(function () {
        // 엑셀 업로드 후 새로고침 추가 2018-01-16 rony
        $scope.searchDo(true);
      });
    };

    /**
     * ebay 스마일배송 전용 / sku 동기화
     */
    $scope.syncSku = function () {
      const selected = $scope.grid.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('한개 이상의 SKU를 선택해주세요.');

        return false;
      }

      if (_.filter(selected, { ebaydepot_yn: 0 }).length) {
        commonSVC.showMessage('G마켓 물류서비스 배송처인 SKU만 가능합니다.');

        return false;
      }

      const resolve = {
        data: {
          selected: selected
        }
      };

      commonSVC.openModal('', resolve, 'syncSkuCtrl', 'views/prod/product/modals/sync_sku.html');
    };

    /**
     * ebay 스마일배송 전용 / sku 가져오기
     */
    $scope.scrapSku = function() {
      const resolve = {
        data: {
          ebaydepotAccounts: $scope.ebaydepotAccounts
        }
      };

      commonSVC.openModal('', resolve, 'scrapSkuCtrl', 'views/prod/product/modals/scrap_sku.html');
    };

    /**
     * 연동상품 모달창
     * */
    $scope.linkageModalOpen = function (sku_code, prod_no, link_yn, link_set_yn) {
      const resolve = {};

      resolve.data = {
        code: sku_code,
        prod_no: prod_no,
        link_yn,
        link_set_yn
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      commonSVC.openModal('xxg', resolve, 'ProdProductLinkageCtrl', 'views/prod/product/modals/product_linkage.html');
    };

    // 주문리스트 모달 열기
    $scope.openOrderList = function(sku_code) {
      const templatePath = 'views/stock/inventory/order_list.html';
      const resolve = {
        resolve: {
          sku_code: sku_code
        }
      };

      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.systemList = function(systemModel) {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'StockInventoryOrderListCtrl', templatePath);

    };

    /**
     * 재고 일괄 수정
     * */
    $scope.modifyStockModal = function (type) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const prodNoList = $scope.grid.methods.selectedData('prod_no');

      if (prodNoList.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      inventoryModel.stockAllocGetType({}, function(state, data) {
        const resolve = {
          prodNoList: $scope.grid.methods.selectedData('prod_no'),
          prodList: $scope.grid.methods.selectedData('all'),
          type: type,
          skuCodeList: $scope.grid.methods.selectedData('sku_cd'),
          alloc_type: data.result[0].allocation_type,
          stock_edit_all: true   // 해당 sku 의 모든 배송처의 재고 수정여부
        };

        const size = type === 'real' ? 'xl' : '';
        const modal = commonSVC.openModal(size, { data: resolve }, 'ProdProductStockCtrl', 'views/prod/product/modals/modify_stock.html');

        modal.result.then(function () {
          $scope.searchDo(false);
        });
      });
    };

    /**
     * 상품 정보 일괄 수정
     * */
    $scope.modifyProdModal = function () {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const selected = $scope.grid.methods.selectedData('all');
      const prodNoList = _.map(selected, 'prod_no');
      const depotNoList = _.map(selected, 'depot_no');
      const dupCheckList = selected.map(s => ({ prod_no: s.prod_no, depot_no: s.depot_no }));
      const ebaydepotExist = !!_.filter(selected, { ebaydepot_yn: 1 }).length; // G마켓물류 배송처 SKU상품 존재여부

      if (selected.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const resolve = {
        madeinList: function(productModel) {
          return productModel.getMadein();
        },
        supplierList: function(supplierModel) {
          return supplierModel.ListAll({ use_yn: true });
        },
        warehouseList: function(warehouseModel) {
          return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
        }
      };

      resolve.data = {
        prodNoList: prodNoList,
        depotNoList: depotNoList,
        ebaydepotExist: ebaydepotExist,
        prod_auto_set_yn: systemList.data.prod_auto_set_yn,
        dupCheckList
      };

      if (ebaydepotExist) {
        commonSVC.showMessage('주의', 'G마켓 물류서비스에서 가져온 SKU는 정보 수정 시 솔루션에서만 적용되며 G마켓 물류서비스에 반영되지 않습니다. (배송처 정보는 수정이 불가합니다.)');
      }

      const modal = commonSVC.openModal('xg', resolve, 'ProdProductEditCtrl', 'views/prod/product/modals/modify_prod.html');

      modal.result.then(function () {
        $scope.searchDo(false);
      });
    };

    /**
     * 검색
     */
    $scope.searchDo = (refresh, noDelay) => {
      localStorage.setItem(`prod_search_key_${userInfo.user.m_no}`, $scope.searchForm.search_key);

      $scope.grid.methods.reloadData(null, refresh, noDelay, true);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = () => {
      $scope.searchForm = angular.copy(productSVC.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'total';
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.searchForm.search_key = localStorage.getItem(`prod_search_key_${userInfo.user.m_no}`) || 'all';

      $scope.countSearch('total');
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      // 2018-08-13 chris 탭에 맞는 그리드 갱신
      $scope.selectCount === 'group_prod' ? $scope.gatherGrid.methods.length($scope.searchData.showCount) : $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 2018-06-28 Daniel
     * G마켓 물류 SKU 재고현황 바로가기
     */
    $scope.ebaySkuScm = function (account) {
      const mall = {
        shop_cd: 'ESMP',
        pa_shop_cd: 'ESMP',
        shop_id: account.ebaydepot_id,
        shop_pwd: account.ebaydepot_pwd,
        admin_url: 'https://www.esmplus.com/Member/SignIn/LogOn?ReturnUrl=%2FHome%2FHome%23HTDM366',
        scm_login_script: '$("#Id").val("{{shop_id}}");$("#Password").val("{{shop_pwd}}");$("#btnLogOn")[0].click();'
      };

      $rootScope.sellerCenter_login(mall);
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {

        //리스트 유지한채 데이터 업데이트
        $scope.grid.methods.reloadData(function() {}, false);

      });
    };

    /**
     * 상품 복사
     */
    $scope.copyProds = function () {
      // SKU상품복사 권한 확인 - 등록
      if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
        return false;
      }
      const selectList = $scope.grid.methods.selectedData('all');

      if (selectList.length == 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      // G마켓 물류 배송처인 SKU상품은 복사불가
      if (_.filter(selectList, { ebaydepot_yn: 1 }).length) {
        commonSVC.showMessage('복사 실패', 'G마켓 물류서비스에서 가져온 SKU는 상품복사가 불가합니다.');

        return false;
      }

      const resolve = {
        data: {
          selectList: selectList,
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'ProdProductCopyCtrl', 'views/prod/product/modals/copy_modal.html');

      modal.result.then(function (e) {
        if (e === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    /**
     * 상품 삭제
     */
    $scope.deleteProds = function (prods) {

      // SKU상품삭제 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('stock.roles.deleteStock', userInfo.permission) === false) {
        return false;
      }

      const deleteProds = prods || $scope.grid.methods.selectedData('all');

      if (!deleteProds.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      let isLinked = false, isSetLinked = false, isMapping = false;

      deleteProds.every(prod => {
        isMapping = prod.mapping_prod > 0;
        isLinked = prod.link_yn === 1;
        isSetLinked = prod.link_set_yn === 1;

        return !(isLinked || isSetLinked || isMapping);
      });

      if (isLinked || isSetLinked) {
        // 선택한 상품 중 대표상품은 포함 안되었지만 연동상품이 포함되어있는 경우
        commonSVC.showMessageHtml('실패', `
        삭제하려는 SKU상품 중 온라인상품 또는 세트상품에 매칭된 상품이 있습니다. <br/>
        매칭해제 후 삭제하여 주시기 바랍니다. <br/>
        <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=12477" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>
      `);

        return false;
      } else if (isMapping) {
        commonSVC.showMessageHtml('실패', `
        삭제하려는 SKU상품 중 매칭된 주문이 출고 전 상태에 있는 SKU상품이 있어 삭제가 불가합니다. <br/>
        해당 주문이 모두 출고완료된 이후 SKU상품 삭제가 가능합니다. <br/>
        <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=12477" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>
      `);

        return false;
      }

      let text = '';

      // G마켓 물류 배송처인 SKU상품일 경우
      if (_.filter(deleteProds, { ebaydepot_yn: 1 }).length) {
        text = 'G마켓 물류서비스에서 가져온 SKU는 솔루션에서만 삭제됩니다.\nG마켓 물류서비스에서 SKU가 정상, 품절, 출고중지 상태인 경우 SKU 가져오기 작업시 재수집됩니다.\n';
      }

      const params = {
        prod_no: deleteProds.map(prod => prod.prod_no),
        sku_code: deleteProds.map(prod => prod.sku_cd)
      };

      commonSVC.showConfirm('삭제 하시겠습니까?', text, function () {
        productModel.delete(params, function (state) {
          if (state == 'success') {
            commonSVC.showToaster('success', '성공', `상품 삭제 ${deleteProds.length}건에 성공하였습니다`);
            $scope.searchDo(true);
          } else {
            commonSVC.showToaster('error', '실패', '상품 삭제에 실패하였습니다');
          }
        });
      });
    };

    // SKU상품 수정시 리스트에서 바뀐 정보 보여주기 위해 다시 그려줌
    $scope.$on('prodModifySuccess', function() {
      $scope.searchDo(false);
      $scope.grid.methods.autoSelect(true);
    });

    // 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.prod_product_list') {
        const $wrapper = $('#prod_prodlist_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    /**
     * 탭 변경
     */
    $scope.selectTab = function (yn) {
      if (yn === true) {
        $scope.currentTab = 1;
        $scope.selectCount = 'group_prod';
        $scope.searchForm.group_prod = true;
        $scope.grid = $scope.gatherGrid;
      } else {
        $scope.currentTab = 0;
        $scope.selectCount = 'total';
        $scope.searchForm.group_prod = false;
        $scope.grid = $scope.productGrid;
      }

      // x건씩 보기 그리드별 세팅
      if ($scope.grid.methods.setShowCountByGridPageSize) {
        $scope.grid.methods.setShowCountByGridPageSize();
      }
    };

    /**
     * 그룹에서 제외
     */
    $scope.styleException = function () {
      const prodNoArr = $scope.gatherGrid.methods.selectedData('prod_no');

      if (prodNoArr.length < 1) {
        commonSVC.showMessage(gettextCatalog.getString('상품을 선택해주세요.'));

        return false;
      } else {
        const params = { prod_no: prodNoArr };

        commonSVC.showConfirm('선택한 상품을 스타일상품에서 제외하시겠습니까?', '', function () {
          productModel.styleException(params, function (state, result) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', '스타일상품에서 제외하기 성공');
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', '실패', '스타일상품에서 제외하기 실패');
            }
          });
        });
      }
    };

    /**
     * 그룹 해제
     */
    $scope.styleRelease = function (style_cd) {
      const params = { style_cd: style_cd };

      commonSVC.showConfirm('선택한 스타일상품을 해제하시겠습니까?', '', function () {
        productModel.styleRelease(params, function (state, result) {
          if (state === 'success') {
            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '스타일상품 해제하기 실패');
          }
        });
      });
    };

    /**
     * 그룹에 상품 추가
     */
    $scope.addToGroupModal = function (index, group) {
      const row = dataList[index];
      const resolve = {};

      resolve.data = {};
      resolve.data.style_no = row.style_no;
      resolve.data.depot_no = row.depot_no;
      resolve.data.depot_name = row.depot_name;
      resolve.data.madein_no = row.madein_no;
      resolve.data.madein_etc = row.madein_etc;
      resolve.data.tax_type = row.tax_type;
      resolve.data.prod_name = row.prod_name.split('_')[0];
      resolve.data.attri_type = row.attri_type;
      resolve.data.style_cd = group;
      resolve.systemList = function() {
        return systemModel.load();
      };

      const warehouseListCopy = angular.copy(warehouseList.data.result);

      warehouseListCopy.shift();
      resolve.data.warehouseList = warehouseListCopy;

      const modal = commonSVC.openModal('full', resolve, 'ProdAddToGroupCtrl', 'views/prod/product/modals/add_to_group.html');

      modal.result.then(function (re) {
        if (re) {
          $scope.searchDo(false);
        }
      });
    };

    // 스타일 그룹 복사
    $scope.styleGroupCopy = function (style_cd) {
      const params = {
        style_cd
      };

      commonSVC.showConfirm('선택한 스타일그룹을 복사 하시겠습니까?', '스타일그룹 복사시 SKU코드는 모두 자동생성 됩니다.', function () {
        productModel.styleCopy(params, function (state, result) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '스타일그룹 복사 성공');

            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '스타일그룹 복사 실패');
          }
        });
      });
    };

    // 스타일 그룹 삭제
    $scope.styleGroupDelete = function(style_cd) {
      const rows = $scope.grid.methods.filteredData({ style_cd }, 'filter');

      $scope.deleteProds(rows);
    };

    // 2018-04-27 william 상세 수정후 리스트 유지 위해 주석처리
    // 2018-01-11 chris 상품 등록 & 수정 성공시 리스트 새로고침 추가
    // $scope.$on('reloadProdProductList', function(event, data) {
    //   $scope.searchDo();
    // });

    $scope.dennis = function() {

      commonSVC.showConfirmHtml('주문확인', '<div class="row">' +
        '<div class="col-xs-3 text-center">' +
        '<i class="sweet-alert-i pe-is-ec-cart-refresh"></i>' +
        '</div>' +
        '<div class="col-xs-9">' +
        '<h6>수집된 주문의 상태를 쇼핑몰 기준으로 동기화 합니다.</h6>' +
        '<ul class="textbox">' +
        '<li>클레임 건으로 동기화된 주문은 클레임 관리에서 확인 가능합니다.</li>' +
        '</ul>' +
        '</div>' +
        '</div>', function () {
      });
    };

    // 작업관리에서 SKU상품으로 넘어오는 경우
    $scope.$on('multiSearchWorkGlobal', function () {
      $scope.searchFn.searchDo(false);
    });

    // 메모 첫 변경시 메뉴 버튼 색상 변경
    $scope.setMemoBtnActive = function (key, value, off) {
      const index = _.findIndex(dataList, (obj) => {
        return obj[key] == value;
      });

      dataList[index].memo_yn = off ? 0 : 1;

      $scope.grid.methods.reDraw();
    };

    // 메모 전부완료시 메뉴 버튼 색상 변경
    $scope.setMemoCompleteActive = function (key, value, off) {
      const index = _.findIndex(dataList, (obj) => {
        return obj[key] == value;
      });

      dataList[index].memo_complete_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    // 메모관리에서 클릭시 검색
    $scope.$on('memoEtcLink_prod', function() {
      const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

      $scope.searchForm = {
        ...productSVC.searchForm,
        date_type,
        sdate,
        edate,
        search_key,
        search_word,
        search_type: 'exact'
      };
      $rootScope.memo_etc_search.search = false;
      $scope.searchDo(true, true);
    });

    /**
     * 바코드 출력 모달
     */
    $scope.printBarcode = function() {
      const selected = $scope.grid.methods.selectedData('sku_cd');

      if (!selected.length) {
        commonSVC.showMessage('바코드 출력할 상품을 선택해 주세요.');

        return false;
      }

      const resolve = {
        data: {
          prodList: selected,
          type: 'default',
        }
      };

      commonSVC.openModal('xg', resolve, 'PrintBarcodeCtrl', 'views/prod/product/modals/print_barcode.html');

    };

    /**
     * 포장 형태 설정
     */
    $scope.setProdPkgBox = function () {
      const resolve = {};

      resolve.data = {
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      commonSVC.openModal('lg', resolve, 'prodPackageBoxCtrl', 'views/prod/product/modals/prod_package_box.html');
    };

  });