'use strict';

angular.module('gmpApp')
  .controller('AltalkAutoSendUpdateModalCtrl', function(
    $scope, $rootScope, data, commonSVC, settings, $timeout, $uibModalInstance
  ) {
    const init = async () => {
      // 대체 문구 추출 위한 정규표현식
      const regex = /#\{([^}]+)\}/g;

      // DB에서 문구 변수 데이터 조회(2.0은 type이 주문인 데이터만 조회)
      const vars = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/variableList`)).data.result.filter(v => v.type === '주문');

      // 발송타입
      $scope.type = data.type;

      // 변수 리스트 (해당 변수 기반으로 발송 모달에서 대체 문구 input 생성 - 수정 모달 진입 시 사용)
      $scope.variableList = data.params.template.content.match(regex) ? vars.filter(v => Array.from(new Set((data.params.template.content.match(regex).map(v => v.match(/#\{([^}]+)\}/)[1])).filter(Boolean))).includes(v.content)) : [];

      // 대체 문구 변수 생성(수정 모달에서 사용)
      $scope.alternative_phrases = {};

      $scope.variableList.forEach(v => {
        $scope.alternative_phrases[v.name] = '';
      });

      // 수정 데이터
      $scope.template_info = angular.copy(data.params);

      // 템플릿 변경안할 시 대체변수 불러오기 처리
      if (!data.params.log_contents.some(log => log.includes('템플릿'))) {
        $scope.replaceVariable = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/replace-variables`, { task_id: data.params.task_id })).data?.result[0]?.variable;
        $scope.replaceVariable.forEach(v => {
          if (Object.prototype.hasOwnProperty.call($scope.alternative_phrases, v.name)) {
            $scope.alternative_phrases[v.name] = v.replace_txt;
          }
        });

        $scope.origin_alternative = angular.copy($scope.alternative_phrases);
      }

      // 자동 발송 수정 모달에 표시될 메시지
      $scope.sendInfoMsg = data.sendInfoMsg;
      $timeout(() => {});
    };

    init();

    $scope.close = () => {
      $uibModalInstance.close();
    };

    $scope.checkPayAmt = (event, type) => {
      if (type === 'pay_amt') {
        const charCode = event.which || event.keyCode;

        if ((charCode < 48 || charCode > 57) && charCode !== 8) {
          event.preventDefault();
        }
      }
    };

    $scope.payAmtComma = (type) => {
      if (type === 'pay_amt') {
        const newPayAmt = $scope.alternative_phrases[type].replace(/,/g, '');
        $scope.alternative_phrases[type] = newPayAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    };

    $scope.register = async () => {
      try {
        // 알림톡 content내 변수 존재 시 대체문구 넣는 처리
        $scope.variableList.forEach((v) => {
          v.replace_txt = $scope.alternative_phrases[v.name];

          if (v.name === 'pay_amt') {
            v.replace_txt = $scope.alternative_phrases[v.name].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        });
        $scope.template_info.template.varList = $scope.variableList;

        // 대체변수 체크
        if ($scope.alternative_phrases && Object.values($scope.alternative_phrases).some(v => !v)) {
          $scope.wait = false;
          $('#spinner').addClass('hidden');

          return commonSVC.showMessage('변수 대체 문구를 모두 입력해 주세요.');
        }
        // 대체변수 중 변경된 사항 로그 기록 처리
        if (!data.params.log_contents.some(log => log.includes('템플릿'))) {
          const compareVariable = Object.keys($scope.origin_alternative);

          for (const variable of compareVariable) {
            if ($scope.origin_alternative[variable] !== $scope.alternative_phrases[variable]) {
              $scope.template_info.log_contents.push(`템플릿 대체 변수[${$scope.variableList.filter(v => v.name === variable)[0].content}]: ${$scope.origin_alternative[variable]} -> ${$scope.alternative_phrases[variable]}`);
            }
          }

        }

        const url = `${settings.adminApiUrl}/api/marketing/atalk/updateAutoTask`;
        const result = await commonSVC.sendUrl('POST', url, $scope.template_info);

        if (result.data.result === 'success') {
          commonSVC.showMessage('자동 발송 등록 수정 완료', '수정 전 발송한 내역도 수정된 발송명, 템플릿명으로 함께 변경됩니다.', (isConfirm) => {
            if (isConfirm) {
              commonSVC.showToaster('success', '성공', '수정 성공');
              $uibModalInstance.close('success');

              return;
            }
          });
        }
      } catch (err) {
        if (err.data.error.includes('Duplicate Task')) {
          return commonSVC.showMessage('자동 발송 수정 실패', `동일 발송명 또는 동일 템플릿과 발송대상으로 등록된 자동 발송 건이 있습니다.\n발송명: ${err.data.error.split(':')[1]}건을 확인해 주세요.`);
        }
      }
    };
  });