
'use strict';

/**
 * 2018-03-30 harry
 * 위메프
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B430-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B430categorySVC, commonSVC, errorSVC, settings, siteInfo, $q) {

    $scope.CateList = angular.copy(B430categorySVC.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(B430categorySVC.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(B430categorySVC.category_data);  // 카테고리 데이터
    $scope.Cate = {};                                               // 추가한 카테고리
    $scope.DispCateList = angular.copy(B430categorySVC.disp_category_list);   // 전시카테고리 리스트
    $scope.DispCateOpt = angular.copy(B430categorySVC.disp_category_opt);     // 전시카테고리 리스트 옵션

    const CateAction = angular.copy(B430categorySVC.category_action);      // 카테고리 액션 이름
    const DispCateAction = angular.copy(B430categorySVC.disp_category_action);      // 카테고리 액션 이름

    $scope.cateSelect = cateSelect; // 카테고리 선택함수

    // requestPA 파라미터
    const userInfo = {};

    init();
    function init() {
      // 로딩중
      loading('0', false); // 표준카테고리 로딩중
      loading('1', false); // 전시카테고리 로딩중

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;
          userInfo.shop_id = $scope.userInfo.shop_id;
          userInfo.shop_pwd = $scope.userInfo.shop_pwd;

          // 표준카테고리, 전시카테고리 로딩
          const promises = _.map([CateAction['0'], DispCateAction['0']], function (action) {
            return commonSVC.requestPA(userInfo, siteInfo.B430.domain, action, {}, commonModel, shopAccountModel);
          });

          return $q.all(promises);
        })
        .then(function (res) {
          const data = res[0].data.data.items;
          const dispData = res[1].data.data.items;

          $scope.CateOpt[0] = data.length > 0 ? data : [];
          $scope.DispCateOpt[0] = dispData.length > 0 ? dispData : [];

          if (data.length > 0) {
            const param = {
              categoryId: $scope.matchingData.categoryId,
              shop_cd: shop_cd,
              shop_id: shop_id
            };

            // 수정확인
            categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
              if (state === 'success') {
                if (result.length > 0) { // 수정
                  $scope.CateData = result[0].sol_cate_shop_add_info;
                  // $scope.CateData.category_code = data.category_code;
                  // $scope.CateData.category_names = data.category_names;
                }
              }
              loading('0', true);
              loading('1', true);
            });

          } else {
            loading('0', true);
            loading('1', true);

            return false;
          }
        })
        .catch(function (err) {
          commonSVC.showMessage('로딩실패');
          loading('0', true); // 표준카테고리 로딩완료
          loading('1', true); // 전시카테고리 로딩완료

          return false;
        });
    }

    /**
     * 카테고리 선택 이벤트
     */
    function cateSelect(type, index) {
      const cateType = type === '0' ? $scope.CateList[index] : $scope.DispCateList[index]; // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      if (type === '0') {
        cateSetting(index);
      }        // 카테고리 이름 설정

      // 마지막 분류이거나, 하위분류없을시
      if ((type === '0' && index === '3') || cateCd === 'noSub' || !cateCd) {
        return false;
      }
      if ((type === '1' && index === '1') || cateCd === 'noSub' || !cateCd) {
        return false;
      }

      loading(type, false);
      clearOpt(type, index);

      setCateSub(type, index, cateCd)
        .then(function() {
          cateCd = 0;
          loading(type, true);
        })
        .catch(function(err) {
          cateCd = 0;
          loading(type, true);
          commonSVC.showMessage('로딩실패');
        });
    }

    /**
     * 카테고리 이름
     */
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];
      const codeArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const selectedCate = _.find($scope.CateOpt[i], { value: $scope.CateData[$scope.CateList[i]] });

        if (!selectedCate.value) {
          break;
        }
        nameArr.push(selectedCate.name);
        codeArr.push(selectedCate.value);
      }
      $scope.CateData.category_names = nameArr.join(' > ');
      $scope.CateData.category_code = codeArr.join('_');
    }

    /**
     * 옵션 초기화
     */
    function clearOpt(type, index) {
      const start = parseInt(index) + 1;

      if (type === '0') {
        for (let i = start; i < 4; i++) {
          var key = $scope.CateList[i];

          $scope.CateOpt[i] = [];
          $scope.CateData[key] = '';
        }
      } else {
        var key = $scope.DispCateList[1];

        $scope.DispCateOpt[1] = [];
        $scope.CateData[key] = '';
      }
    }

    /**
     * 하위 카테고리 가져오기
     */
    function setCateSub(type, index, cateCd) {
      index = index * 1;
      const action = type === '0' ? CateAction[index + 1] : DispCateAction[index + 1];

      commonSVC.requestPA(userInfo, siteInfo.B430.domain, action, { parent_category: cateCd }, commonModel, shopAccountModel)
        .then(function(res) {
          const data = res.data.data.items;

          if (data.length > 0) {
            if (type === '0') {
              $scope.CateOpt[index + 1] = data;
            } else {
              $scope.DispCateOpt[index + 1] = data;
            }
          } else {
            // 하위카테고리 없을시 출력
            if (type === '0') {
              $scope.CateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
            } else {
              $scope.DispCateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
            }
          }
        });
    }

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      const cateCd = [];
      let valid = true;

      _.forEach($scope.CateList, function(v, k) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }
        if (!value) {
          valid = false;

          return false;
        }
        cateCd.push(value);
      });

      $scope.Cate[cateCd.join('>')] = $scope.CateData.category_names;

      const cateKey = Object.keys($scope.Cate);

      if (cateKey.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B430',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: JSON.stringify(angular.copy($scope.CateData)),
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              if ($scope.matchingData.isOnlineAdd === true) {
                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);
              } else {
                $scope.infoLoad();
              }
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        }
      }
    };

  });
