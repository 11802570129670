'use strict';

angular.module('gmpApp')
  .controller('StockAllocationRuleCtrl', function ($scope, $rootScope, $timeout, $compile, settings, gettextCatalog, $uibModalInstance, commonSVC, data, inventoryModel) {
    $scope.batchRate; // 할당률

    $scope.rule = {
      mall: [], // 사용 쇼핑몰 목록 (w/ 우선순위, 할당률)
      clicked: {
        alloc_type: '', // 재고할당 타입 (shop/prod)
        alloc_method: '' // 재고할당 방법 (rate/priority)
      },
      use_yn: {
        shop: false,
        prod: false
      }
    };

    $scope.alloc_type_last_modify_time = data.alloc_type_last_modify_time; // 할당타입 최종수정일

    let neverSet = false; // 한번도 설정한적이 없는지 (채널별할당)
    const alloc_data = data.alloc_type ? data.alloc_type.split('_') : null;

    const fromHistory = data.fromHistory;

    $scope.prod_depot_shop_no = data.prod_depot_shop_no;
    $scope.highlight = data.fromHistory;

    // 재고할당방법 버튼 값 세팅
    if (data.alloc_type === 're_alloc' || !alloc_data) {
      $scope.rule.clicked.alloc_type = 'shop';
      $scope.rule.clicked.alloc_method = 'rate';
    } else {
      $scope.rule.clicked.alloc_type = alloc_data[0];
      $scope.rule.clicked.alloc_method = alloc_data[0] === 'prod' ? 'rate' : alloc_data[1];
      $scope.rule.use_yn[alloc_data[0]] = true;
    }

    // 재고할당내역에서 넘어온거면 채널별할당 선택해서 보여줌
    if (fromHistory) {
      $scope.rule.clicked.alloc_type = 'shop';
    }

    /**
     * 재고할당방법 업데이트
     */
    function updateStockAllocType() {
      // DB에 저장할 재고할당타입 가공
      const selected_allocation_type = !$scope.rule.use_yn.shop && !$scope.rule.use_yn.prod ? null
        : ($scope.rule.clicked.alloc_type === 'prod' ? 'prod_shop_rate' : `shop_${$scope.rule.clicked.alloc_method}`);

      const params = {
        alloc_type: selected_allocation_type,
        date: $scope.alloc_type_last_modify_time
      };

      // 한번도 설정한적 없고 채널별할당 선택했을경우 저장버튼 누르지 않아도 기본 할당율 DB 저장해줌
      if (neverSet && $scope.rule.clicked.alloc_type === 'shop') {
        params.neverSet = true;
        params.mall_list = $scope.rule.mall;
      }

      inventoryModel.stockAllocSetType(params, function(state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '재고할당방법 변경 성공');
          // 최초 설정시에 채널별할당 규칙을 자동으로 세팅해주므로 중복 저장되지 않게 모달 닫음
          if (neverSet) {
            $scope.close();
          }
        } else {
          commonSVC.showToaster('fail', '실패', '재고할당방법 변경 실패');
        }
      });
    }

    /**
     * 쇼핑몰 정렬
     */
    function mallOrderBy () {
      // 1. SKU상품별 할당
      if ($scope.rule.clicked.alloc_type === 'prod') {
        $scope.rule.mall = _.orderBy($scope.rule.mall, 'prod_depot_shop_no');
      }
      // 2. 채널별 할당
      else if ($scope.rule.clicked.alloc_type === 'shop') {
        // 2-1. 일반할당

        // 미할당인 쇼핑몰은 리스트 제일 뒤로 보내기위해 따로 분리
        const malls = _.reject($scope.rule.mall, ['prod_depot_shop_no', '미할당']);
        const nonAllocMalls = _.filter($scope.rule.mall, ['prod_depot_shop_no', '미할당']);

        if ($scope.rule.clicked.alloc_method === 'rate') {
          // 일반적인 쇼핑몰 노출기준대로 정렬
          $scope.rule.mall = _.orderBy(malls, ['shopCd', 'wdate'], ['asc', 'desc']);
          $scope.rule.mall = $scope.rule.mall.concat(_.orderBy(nonAllocMalls, ['shopCd', 'wdate'], ['asc', 'desc']));
        }
        // 2-2. 우선순위할당.
        else {
          $scope.rule.mall = _.orderBy($scope.rule.mall, 'priority');
          // 채널 삭제되는경우 우선순위 번호가 안맞을 수 있어서 우선순위 재입력
          _.forEach($scope.rule.mall, function(m, idx) {
            m.priority = idx + 1;
          });
        }
      }
      // 3. 둘다 선택 x
      else {
        $scope.rule.mall = _.orderBy($scope.rule.mall, ['shopCd', 'wdate'], ['asc', 'desc']);
      }
    }

    // 쇼핑몰 리스트 불러오기
    inventoryModel.stockAllocShopBaseList({ isProd: false, re_alloc_yn: false }, function (state, data) {
      if (state === 'success') {
        $scope.rule.mall = data.results;

        // 초기화 필요 여부 (규칙번호가 존재하는지)
        neverSet = !_.find($scope.rule.mall, 'prod_depot_shop_no');

        // 설정값이 없을경우 기본 세팅 (채널별할당)
        if (neverSet) {
          _.each($scope.rule.mall, function (m, idx) {
            m.alloc_rate = m.alloc_rate || Math.round(100 / $scope.rule.mall.length);
            m.priority = idx + 1;
          });
        } else {
          // 규칙설정 안된 일부 쇼핑몰들 기본세팅 (채널별할당)
          _.forEach($scope.rule.mall, (mall) => {
            if (!mall.prod_depot_shop_no) {
              mall.prod_depot_shop_no = '미할당';
              mall.alloc_rate = 0;
              mall.priority = _.maxBy($scope.rule.mall, 'priority').priority + 1;
            }
          });
        }

        mallOrderBy();

        // ui-sortable 우선순위 할당일때만 활성화
        if ($scope.rule.clicked.alloc_method === 'rate') {
          $scope.sortableOptions.disabled = true;
        }

        // 재고할당 내역에서 모달 열었을 경우 해당 row 하이라이트
        if (fromHistory) {
          // 하이라이트 2초뒤 해제
          $timeout(function() {
            $scope.highlight = false;
          }, 2000);
        }
      } else {
        commonSVC.showToaster('error', '실패', '재고할당 규칙 리스트 조회 실패');
        $scope.close();
      }
    });

    /**
     * 드래그앤드롭 열 순서변경
     */
    $scope.sortableOptions = {
      update: function () {
        // 변경한 순서가 바로 반영이 안돼서 timeout
        $timeout(function() {
          _.forEach($scope.rule.mall, function(m, idx) {
            m.priority = idx + 1;
          });
        }, 100);
      },
      disabled: false
    };

    /**
     * 재고할당 타입 변경
     */
    $scope.changeAllocType = function (type) {
      // 같은 값 선택시 아무것도 X
      if ($scope.rule.clicked.alloc_type === type) {
        return false;
      }

      $scope.rule.clicked.alloc_type = type;
      $scope.rule.clicked.alloc_method = $scope.rule.clicked.alloc_method || 'rate';

      // ui-sortable 우선순위 할당일때만 활성화
      if ($scope.rule.clicked.alloc_type === 'shop' && $scope.rule.clicked.alloc_method === 'rate') {
        $scope.sortableOptions.disabled = true;
      } else {
        $scope.sortableOptions.disabled = false;
      }

      // 쇼핑몰 정렬
      mallOrderBy();
    };

    /**
     * 규칙 사용여부 변경
     */
    $scope.changeUse = function (bool) {
      // 같은 값 선택시 아무것도 X
      if ($scope.rule.use_yn[$scope.rule.clicked.alloc_type] === bool) {
        return false;
      }

      $scope.rule.use_yn[$scope.rule.clicked.alloc_type] = bool;

      // 채널/SKU상품별 할당 중 한쪽 사용함 선택시 다른 한쪽은 사용안함 자동선택
      if (bool) {
        $scope.rule.use_yn[$scope.rule.clicked.alloc_type === 'shop' ? 'prod' : 'shop'] = false;
      }

      $scope.alloc_type_last_modify_time = moment().format('YYYY-MM-DD HH:mm:ss');

      updateStockAllocType();
    };

    /**
     * 재고할당방법 변경
     */
    $scope.changeAllocMethod = function (method) {
      $scope.rule.clicked.alloc_method = method;

      // 채널별할당 && 사용함일때 값 DB에 업데이트해줌
      if ($scope.rule.clicked.alloc_type === 'shop' && $scope.rule.use_yn.shop) {
        $scope.alloc_type_last_modify_time = moment().format('YYYY-MM-DD HH:mm:ss');
        updateStockAllocType();
      }

      // ui-sortable 우선순위 할당일때만 활성화
      if ($scope.rule.clicked.alloc_method === 'rate') {
        $scope.sortableOptions.disabled = true;
      } else {
        $scope.sortableOptions.disabled = false;
      }

      // 쇼핑몰 정렬
      mallOrderBy();
    };

    /**
     * 할당률 일괄입력
     */
    $scope.setRate = function(rate) {
      if (!rate && rate !== 0) {
        commonSVC.showMessage(gettextCatalog.getString('할당률을 입력해주세요.'));

        return false;
      }

      _.each($scope.rule.mall, function(mall) {
        mall.alloc_rate = rate;
      });
    };

    /**
     * 도움말 모달 오픈
     */
    $scope.openHelp = function(isChannel) {
      commonSVC.openModal('xxg', { data: { isChannel: isChannel } }, 'allocHelpCtrl', 'views/stock/inventory/alloc_help.html');
    };

    /**
     * 저장
     */
    $scope.save = function () {
      const params = {
        mall_list: $scope.rule.mall
      };

      inventoryModel.stockAllocEditRule(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '재고할당 규칙 설정 성공');
          $uibModalInstance.close('success');
        } else {
          $timeout(function () { $scope.waitBtnDisableFlag = false; });
          commonSVC.showToaster('error', '실패', '재고할당 규칙 설정 실패');
        }
      });
    };

    // ============================== SKU상품별 할당 START ============================== //

    const search = {
      searchData: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        showCount: 10,       // 한 페이지에 보이는 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('SKU코드'), value: 'd.sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'd.prod_name' },
        ],
        search_key_name: 'SKU코드',
        showDetailSearchArea: false,
        showSearchArea: false,
        showSearchDiv: false,
      },
      searchForm: {
        search_word: '',        //검색어
        search_key: 'd.sku_cd', //검색구분
        orderby: 'wdate desc'   //정렬
      }
    };

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);

    $scope.searchBtn = {      // 상세조건 버튼
      actions_right: [
        [
          {
            label: gettextCatalog.getString('자동 할당'),
            small_label: '자동할당',
            btn_type: 'button',
            hot_key: '',
            action: function() {
              $scope.autoAlloc();
            },
          },
        ]
      ],
    };

    $scope.searchFn = {
      searchDo: function() {
        // SKU상품별 할당이 선택됐을때만 검색
        if ($scope.rule.clicked.alloc_type === 'prod') {
          $scope.searchDo(true, true);
        }
      },
      resetDo: function() {
        // SKU상품별 할당이 선택됐을때만 검색
        if ($scope.rule.clicked.alloc_type === 'prod') {
          $scope.resetDo();
        }
      },
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      // $scope.searchData = angular.copy(search.searchData);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      gridHeight: 350,
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'real_stock', 'stock_cnt', 'link_channel_cnt', 'nokey'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['widget'],
      notSortingColumns: [
        'widget', 'sku_cd', 'prod_name', 'attri', 'real_stock',
        'stock_cnt', 'link_channel_cnt', 'nokey'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/alloc/list-of-shop-base`,
        requestWillAction: function (data) {
          data.re_alloc_yn = false;
          data.isProd = true;

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          result.results = _.reject(result.results, (data) => !data.prod_no);

          // 집계카운트
          $scope.countList = {
            total: result.results.length,
          };

          return result.results;
        }
      },
      modal: true,
      columns: [
        {
          key: 'widget',
          width: 100,
          title: '설정',
          template: function (row) {
            return `
              <div class="btn-group">
                <button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.setRule(${row.prod_no}, ${row.depot_no}, ${row.real_stock}, ${row.stock_cnt})">수정</button>
                <button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.deleteRule(${row.prod_no}, ${row.depot_no})">삭제</button>
              </div>`;
          }
        },
        {
          // product-log prod
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 200
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 100
        },
        {
          key: 'attri',
          title: '속성',
          width: 108
        },
        {
          key: 'real_stock',
          title: '실재고',
          width: 55
        },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          width: 85
        },
        {
          key: 'link_channel_cnt',
          title: '연동상품',
          width: 57
        },
        {
          key: 'nokey',
          title: '적용',
          width: 50,
          template: function (row) {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeRuleUse(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    /**
     * 규칙사용여부 변경
     */
    $scope.changeRuleUse = async function({ prod_no, use_yn, depot_no }) {
      try {
        const params = {
          prod_no,
          depot_no,
          use_yn,
          re_alloc_yn: false
        };

        await inventoryModel.stockAllocSetRuleUse(params);

        commonSVC.showToaster('success', '성공', '적용여부 수정 성공');
      } catch (error) {
        commonSVC.showToaster('error', '실패', '적용여부 수정 실패');

        return false;
      }
    };

    /**
     * 규칙추가 모달
     */
    $scope.addRule = function() {
      const modal = commonSVC.openModal('xxg', { data: {} }, 'AddStockAllocationRuleCtrl', 'views/stock/inventory/add_stock_allocation_rule.html');

      modal.result.then(function () {
        $scope.searchDo(false);
      });
    };

    /**
     * 재고할당 수정 (SKU상품별 할당)
     */
    $scope.setRule = function(prod_no, depot_no, real_stock, stock_cnt) {
      const data = {
        prod_no,
        depot_no,
        real_stock,
        stock_cnt
      };

      commonSVC.openModal('xxg', { data: data }, 'SetStockAllocationChannelRuleCtrl', 'views/stock/inventory/set_stock_allocation_channel_rule.html');
    };

    /**
     * 규칙 삭제
     */
    $scope.deleteRule = function(prod_no, depot_no) {
      let ruleList = [];

      if (prod_no) {
        ruleList.push({ prod_no, depot_no });
      } else {
        ruleList = $scope.grid.methods.selectedData('all').map(o => {
          return {
            prod_no: o.prod_no,
            depot_no: o.depot_no
          };
        });
      }

      // 선택한 row가 없을때
      if (!ruleList.length) {
        commonSVC.showMessage('삭제할 규칙을 선택해주세요');

        return false;
      }

      commonSVC.showConfirm('삭제하시겠습니까?', '', function() {
        inventoryModel.stockAllocDeleteRule({ ruleList: ruleList }, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '재고할당 규칙 삭제 성공');
            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '재고할당 규칙 삭제 실패');
          }
        });
      });
    };

    // ============================== SKU상품별 할당 END ============================== //

    /**
     * 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
