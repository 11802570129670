'use strict';

angular.module('gmpApp').controller('CalculateCollectAllCtrl', function ($scope, data, $uibModalInstance, commonSVC, workSVC, errorSVC, $q) {

  //------------------------
  // 변수 설정
  //------------------------
  $scope.channel_list = data; // 채널 리스트

  //------------------------
  // 함수
  //------------------------

  /**
   * 취소
   */
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  /**
   * 정산 수집 버튼 클릭 시
   */
  $scope.calculateCollect = function() {

    const reCnt = {
      success: 0,
      error: 0,
      errSite: [],
      errReason: []
    };
    const promiseArr = [];

    angular.forEach($scope.channel_list, function(v) {
      const params = {
        site_code: v.shop_cd,
        site_id: v.shop_id
      };
      const anHttpPromise = workSVC.addWork('ScrapPayBack', params)
        .then(function(result) {
          if (result.status === 200) {
            reCnt.success++;
          } else {
            reCnt.error++;
            reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
            reCnt.errReason.push(result.data.error || '');
          }

          return result;
        })
        .catch(function(err) {
          reCnt.error++;
          reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
          reCnt.errReason.push('');

          return err;
        });

      promiseArr.push(anHttpPromise);
    });

    $q.all(promiseArr)
      .then(async re => {
        if (!reCnt.success) {
          throw re;
        } else {
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          $scope.cancel();
        }
      })
      .catch(function(err) {
        const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
        commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
      });
  };

});
