'use strict';

angular.module('gmpApp')
  .controller('ConfigsUserAddCtrl', function ($scope, $uibModalInstance, data, overlapSVC, userModel, commonSVC, userSVC, commonModel, userInfo, gettextCatalog, $rootScope, $timeout) {
    $scope.warehouserList = data.warehouserList;
    $scope.alerts = [];
    $scope.overlapResult = null;
    $scope.myIp = '';

    $scope.data = {
      user_email: '',
      user_name: '',
      m_type: '사용자',
      auth_type: '',
      depot_no: '',
      allow_ip_use_yn: 0,
      allow_ip: '',
      addPermissions: null
    };

    $scope.isTestUser = !!userInfo.user.test_aply_time;
    $scope.use_channel_sorted_lists = {};      // 쇼핑몰별로 분류된 계정명 노출용 변수

    // 채널 쇼핑몰별로 분류
    $rootScope.use_channel_list.forEach((o) => {
      if (!$scope.use_channel_sorted_lists[`${o.shop_name}`]) {
        $scope.use_channel_sorted_lists[`${o.shop_name}`] = {
          shops: [],
          use_yn: true
        };
      }
      $scope.use_channel_sorted_lists[`${o.shop_name}`].shops.push({
        value: `${o.shop_cd}_${o.shop_id}`,
        username: `${o.shop_id}`
      });
    });

    /**
     * 사용자 구분 변경
     */
    $scope.change_auth_type = function (auth_type) {
      switch (auth_type) {
        case '배송처':
          $scope.data.addPermissions = _.cloneDeep(userSVC.depotPermission);

          $rootScope.use_channel_list.forEach(({ shop_cd, shop_id }) => {
            $scope.data.addPermissions.channel.roles[`${shop_cd}_${shop_id}`] = false;
          });
          break;

        case '사용자':
          $scope.data.addPermissions = _.cloneDeep(userSVC.permissions);  // 권한설정 - 관리자가 기본

          $rootScope.use_channel_list.forEach(({ shop_cd, shop_id }) => {
            $scope.data.addPermissions.channel.roles[`${shop_cd}_${shop_id}`] = true;
          });
          break;
      }
    };

    /**
     * 중복확인
     */
    $scope.overlapCheck = function(location) {
      if (!$scope.data.user_email) {
        commonSVC.showMessage('이메일을 입력해 주세요.');

        return false;
      }

      // 이메일 형식이 아닐경우 검사 X
      const regx = new RegExp(/^[0-9a-zA-Z]([-_.0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]*$/i);

      if (!regx.exec($scope.data.user_email)) {
        commonSVC.showMessage('잘못된 형식의 이메일입니다.');

        return false;
      }

      overlapSVC.Check(location, $scope.data.user_email, function(result) {
        if (result == 'y') {
          $scope.alerts = { msg: gettextCatalog.getString('사용가능한 이메일(ID) 입니다.') };
          $scope.overlapResult = result;
        } else {
          $scope.alerts = { msg: gettextCatalog.getString('중복된 이메일(ID) 입니다.') };
          $scope.overlapResult = result;
        }
      });
    };

    $scope.$watchCollection('data.user_email', function() {
      $scope.overlapResult = null;

      return false;
    });

    /**
     * 사용자 추가
     */
    $scope.submit_flag = false;
    $scope.emailDo = function(isMakeTestUser = false) {
      if ($scope.data.allow_ip_use_yn === 1) {
        let ip_validation = true;

        const ip_arr = [];

        $scope.data.allow_ip.split(',').some((ip) => {
          const new_ip = ip.replace(/\s/gi, '');
          const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

          if (!regex.test(new_ip)) {
            ip_validation = false;

            return true;
          }

          ip_arr.push(new_ip);
        });

        if (!ip_validation) {
          commonSVC.showMessage(gettextCatalog.getString('IP 주소를 확인해주세요.'));

          return false;
        }

        $scope.data.allow_ip = ip_arr.join(',');
      }

      $scope.data.permission = $scope.data.auth_type == '총괄관리자' ? null : JSON.stringify($scope.data.addPermissions);

      if ($scope.form1.$valid) {
        if ($scope.overlapResult != 'y') {
          commonSVC.showMessage(gettextCatalog.getString('이메일(ID) 중복확인을 해주세요.'));
          $scope.alerts = [{ type: 'danger', msg: gettextCatalog.getString('이메일(ID) 중복확인을 해주세요.') }];
        } else if ($scope.data.auth_type === '배송처' && !$scope.data.depot_no) {
          commonSVC.showMessage(gettextCatalog.getString('배송처를 선택해주세요.'));
        } else {
          $scope.submit_flag = true;
          const next = (state, result) => {
            $scope.submit_flag = false;

            if (state == 'success') {
              $uibModalInstance.close('success');
              commonSVC.showMessage(gettextCatalog.getString(isMakeTestUser ? '사용자가 추가되었습니다.\n비밀번호는 1111 입니다.' : '신규 사용자 이메일로 인증메일이 발송되었습니다.\n이메일 인증 후 사용가능합니다.'));
            } else {
              if (result.data.message == 'limit_error') {
                commonSVC.showMessage(
                  gettextCatalog.getString('실패'),
                  gettextCatalog.getString(`이용중인 버전에서 사용할 수 있는 사용자 ID 수가 부족합니다.\n추가 사용을 원하실 경우 이용버전을 업그레이드 하시기 바랍니다.\n현재 버전 ${userInfo.user.sol_version} : 사용자 ID - ${result.data.userLimit}개 사용 가능`)
                );
              } else if (result.data.message == 'super_limit_error') {
                commonSVC.showMessage(
                  gettextCatalog.getString('실패'),
                  gettextCatalog.getString('총괄 관리자는 3명만 설정할 수 있습니다.\n현재 총괄 관리자를 사용자로 변경 하신 후 이용해 주세요.')
                );
              } else {
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사용자 신규 등록이 실패 하였습니다.'));
              }
            }
          };

          const params = {
            ...$scope.data,
            isMakeTestUser
          };
          userModel.insert(params, next);
        }
      } else {
        commonSVC.showMessage('필수 항목을 입력해 주세요.');
      }
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 추가권한 설정 셋팅
     * 2018-12-28 rony
     */
    $scope.setAddPermissions = function(type, shop_data = null, typeSub = null) {
      // 선택사항이 바로 반영되지않아 timeout 줘야 함.
      $timeout(function() {
        // 관리자 선택시 모든 항목 체크.
        if (type === 'master') {
          if ($scope.data.addPermissions.master.use_yn) {
            Object.keys($scope.data.addPermissions).forEach((key) => {
              $scope.data.addPermissions[key].use_yn = true;
              Object.keys($scope.data.addPermissions[key].roles).forEach((k) => {
                $scope.data.addPermissions[key].roles[k] = true;
              });
            });

            // 쇼핑몰 분류도 전부 활성화
            Object.values($scope.use_channel_sorted_lists).forEach((v) => {
              v.use_yn = true;
            });
          }
        } else if (type) {
          Object.keys($scope.data.addPermissions[type].roles).forEach(obj_k => {
            $scope.data.addPermissions[type].roles[obj_k] = $scope.data.addPermissions[type].use_yn;
          });

          if (typeSub) {
            $scope.data.addPermissions[typeSub].use_yn = $scope.data.addPermissions[type].use_yn;
            Object.keys($scope.data.addPermissions[typeSub].roles).forEach(obj_k => {
              $scope.data.addPermissions[typeSub].roles[obj_k] = $scope.data.addPermissions[type].use_yn;
            });
          }
        }

        // 쇼핑몰이 체크되면 하위 계정들을 전부 체크하거나 전부 체크해제함
        if (shop_data) {
          $scope.use_channel_sorted_lists[shop_data].shops.forEach(e => {
            $scope.data.addPermissions.channel.roles[e.value] = $scope.use_channel_sorted_lists[shop_data].use_yn;
          });
        }

        // 하나라도 체크가 안된 항목이 있으면 관리자 체크 해제.
        let all_check = true;

        Object.keys($scope.data.addPermissions).some((o_k) => {
          if (o_k !== 'master') {
            if ($scope.data.addPermissions[o_k].use_yn !== true) {
              all_check = false;
            }
            if (all_check) {
              Object.keys($scope.data.addPermissions[o_k].roles).some((obj_k) => {
                if ($scope.data.addPermissions[o_k].roles[obj_k] !== true) {
                  all_check = false;
                }

                return !all_check;
              });
            }

            return !all_check;
          }
        });

        if (type !== 'master' && all_check === true) {
          $scope.data.addPermissions.master.use_yn = true;
        } else if ($scope.data.addPermissions.master.use_yn === true && all_check === false) {
          $scope.data.addPermissions.master.use_yn = false;
        }
      }, 100);
    };

    // 내 IP 가져오기
    $scope.getMyIp = function () {
      commonModel.getIp().then(function (result) {
        if (result.status === 200) {
          $scope.myIp = result.data.result;
        }
      });
    };

  });
