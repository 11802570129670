'use strict';

angular.module('gmpApp')
  .factory('B378categorySVC', function () {

    return {
      // 옵션 repeat data
      category_opt: [[], [], [], [], [], []],

      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        category_names: '',
        category_codes: '',
        key_displayCategoryCode: '',
        is_possible_fulfillment: true,
        isExpirationDateRequiredForRocketGrowth: false
      }
    };
  });
