'use strict';

// 셀러허브 이지셀
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A526-Ctrl', function ($scope, $timeout, $ocLazyLoad, A526categorySVC, errorSVC, commonSVC, commonModel, shopAccountModel, categoryModel) {
    // 최하위 카테고리 선택여부
    let save_flag = false;

    // 카테고리 최대 길이
    const MAX_LENGTH = 4;
    // 선택한 카테고리 리스트
    $scope.sel_category = [];
    $scope.cate = [];
    for (let index = 0; index < MAX_LENGTH; index++) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
    }

    $scope.CateData = angular.copy(A526categorySVC.category_data);
    // 선택시 상위 노출될 카테고리 분류
    $scope.category_names = '';

    $scope.pa5_job_data = {
      get_categories: {
        load: false,
        data: []
      },
    };

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, fn) => {
      try {
        const response = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);
        if (!response.data.has_error) {
          $scope.pa5_job_data[job_name].data = fn ? fn(response.data.results[0].data) : response.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.\n${response.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      // 카테고리 명 ex) "대분류 > 중분류 > 소분류"
      $scope.category_names = $scope.sel_category.filter(Boolean).map(sel => sel.shop_cate_see_name).join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < MAX_LENGTH; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await $timeout(() => {
          $scope.cate[idx + 1] = getCategoriesByParentCode(cate.shop_cate_see_cd ?? '');
          $scope.loading = true;
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < MAX_LENGTH; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      let category_code = '';

      //카테고리 마지막 분류까지 선택여부
      if (save_flag == false) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      } else {
        if (typeof ($scope.CateData.detailData) == 'string') {
          $scope.CateData.detailData = JSON.parse($scope.CateData.detailData);
        }

        for (let i = 0; i < MAX_LENGTH; i++) {
          $scope.CateData.detailData[`cate${i}`] = '';

          if ($scope.sel_category[i].shop_cate_see_cd) {
            const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

            category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
          }
        }

        //detailData에 수정된 데이터를 넣기위해
        //category_names 초기화
        $scope.CateData.detailData.category_names = $scope.category_names;
        //category_code 초기화
        $scope.CateData.detailData.category_code = category_code;

        if ($scope.form1.$valid) {
          /**
           * 2018-03-09 Roy
           * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
           */
          const param = {
            // "hscode": $stateParams.hscode,
            site_code: 'A526',
            site_id: $scope.matchingData.site_id,
            category_code: category_code,
            category_codes: 'test',
            category_names: $scope.category_names,
            categoryId: $scope.matchingData.categoryId,
            detailData: { ...$scope.CateData.detailData },
            set_all_shop: $scope.check.set_all_shop,
            country: 'kr',
            //국내/해외
            type: 0
          };

          if (event && event.type === 'online') {
            $scope.returnAct(param);
          } else if ($scope.matchingData.isOnlineAdd === true) {
            await $timeout(async () => {
              try {
                const res = await categoryModel.shopInfoDetailEdit(param);

                $scope.loading = true;
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);

              } catch (err) {
                $scope.loading = true;

                commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
              }
            });
          } else {
            $timeout(() => {
              param.isApply = true;

              $scope.applyCate(param);
            });
          }
        }
      }
    };

    /**
     * 2018-03-09 Roy
     * 상품 등록, 수정 구분을 사용을 위해 함수로 뺌
     * 상품 수정의 경우에 솔루션 카테고리 정보를 수정하지 말아야함
     */
    const matchDetailDataLoad = async (param_info) => {
      $scope.CateData.detailData = param_info;
      // string 로 넘어오는경우 object 변환을 해줘야 함. 2018-10-17 rony

      if (!$scope.CateData.detailData.category_code) {
        return false;
      }

      await setCategory($scope.CateData.detailData.category_code);
    };

    const getCategoriesByParentCode = (parent_code) => $scope.pa5_job_data.get_categories.data.filter(data => data.pa_shop_cate_see_cd === parent_code);

    const init = async () => {
      await run_pa5_jobs('get_categories', '카테고리', data => data.map(each_data => ({
        shop_cd: 'A526',
        shop_cate_see_cd: each_data.code,
        std_ol_yn: 0,
        pa_shop_cate_see_cd: each_data.parent_code,
        shop_cate_see_depth: each_data.depth,
        shop_cate_see_name: each_data.name,
        shop_cate_see_type: each_data.type,
        etc: null,
        end_yn: each_data.is_end ? 1 : 0,
        shop_cate_see_detail: {
          shop_cate_see_cd_detail: each_data.code,
          shop_cate_see_name_detail: each_data.name,
        }
      })));

      await $timeout(async () => {
        // 초기 카테고리 가지고 오기
        $scope.cate[0] = getCategoriesByParentCode('');

        /**
         * 2018-03-09 roy
         * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
         */
        if ($scope.matchingData.shop_cate_no_add_info) {
          await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
        } else if ($scope.matchingData.sol_cate_shop_add_info) {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        } else {
          await matchDetailDataLoad(angular.copy(A526categorySVC.category_data));
        }
      });
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
