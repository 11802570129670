/**
 * 2021-03-10 Lucas
 * 옵션 체크박스 다중 선택 기능
 */
'use strict';

angular.module('gmpApp')
  .directive('smartCheckbox', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
      },
      link: function(scope, element, attrs, ngModel) {
        $(document).ready(() => {
          let lastChecked = null;

          $(element).on('click', 'td:has(>input[type="checkbox"])', function ({ shiftKey }) {
            const selectElement = element.querySelectorAll('td > input[type="checkbox"]').parent();

            ngModel.$viewValue[selectElement.index(this)].checked = !ngModel.$viewValue[selectElement.index(this)].checked;

            if (shiftKey) {
              const start = selectElement.index(this);
              const end = selectElement.index(lastChecked);

              ngModel.$viewValue.map((e, i) => {
                if (Math.min(start, end) <= i && i <= Math.max(start, end)) {
                  e.checked = true;
                }
              });
            }

            lastChecked = this;
          });
        });
      }
    };
  });
