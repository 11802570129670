/**
 * Created by ally on 2017. 6. 8..
 */

'use strict';

angular.module('gmpApp')
  .controller('OrderCustomerInquiryExcelDownCtrl', function ($scope, data, $uibModalInstance, commonSVC, customerInquiryModel, gettextCatalog) {
    $scope.fieldList = data.excelFieldList;

    $scope.down = function(fileExt) {
      const downList = _.filter($scope.fieldList, 'select');

      if (downList.length) {
        const params = angular.merge({ down_list: downList, file_ext: fileExt }, data);

        customerInquiryModel.downExcel(params, function (state, result) {
          if (state == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('엑셀 다운로드에 성공하였습니다.'));
            window.open(result.url, '');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('엑셀 다운로드에 실패하였습니다.'));
          }
        });
      } else {
        commonSVC.showMessage(gettextCatalog.getString('다운받을 항목를 선택해주세요.'));
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
