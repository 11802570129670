/**
 * 카테고리 엑셀 일괄 등록
 */
'use strict';

angular.module('gmpApp')
  .controller('CategoryExcelUploadCtrl', function ($scope, $uibModalInstance, $timeout, commonSVC, workSVC) {
    $scope.downWithStandardCategory = false; // 표준카테고리 코드표 엑셀 함께 다운 여부

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;

      if (!Array.isArray($scope.fileData)) {
        workSVC.addExcelWork('RegistCategoryExcel', {}, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    /**
     * 샘플 엑셀 다운로드
     */
    $scope.sampelExcelDown = () => {
      const downUrls = ['https://cloud.plto.com/sampleExcel/Sample_standard_category/Sample_standard_category_v2.xlsx'];

      if ($scope.downWithStandardCategory) {
        // 표준카테고리 코드표 엑셀
        downUrls.push('https://cloud.plto.com/sampleExcel/Sample_standard_category/Standard_category_list.xlsx');
      }
      commonSVC.downloadAll(downUrls);
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  });
