/**
 * Created by harry on 2017. 6. 23..
 */

'use strict';

angular.module('gmpApp')
  .service('stateSVC', function(commonSVC, gettextCatalog) {
    /**
     * 정산 수동 엑셀 업로드용 중복기준 키
     */
    const criteriaKey = {
      order_name: 'oname',
      sale_cnt: 'scnt',
      shop_opt_name: 'popt',
      shop_sale_name: 'pname',
      shop_sale_no: 'pcode',
      sett_type: 'stype',
      ord_pay_no: 'opno',
      ori_shop_ord_no: 'ocoder',
      shop_ord_no: 'ocode'
    };

    return {

      searchForm: {
        search_hide: true,
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        multi_search_word: '', //멀티서치워드,
        multi_type: 'shop_ord_no' //멀티서치 타입
      },

      searchData: {
        showSearchArea: true,
        showCount: 100,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
          { label: gettextCatalog.getString('구매자명'), value: 'order_name' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('주문수집일'), value: 'wdate' },
          { label: gettextCatalog.getString('결제완료일'), value: 'pay_time' },
          { label: gettextCatalog.getString('정산완료일'), value: 'sett_com_date' }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('구매자명'), value: 'order_name' }
        ]

      },

      excelDownList: [
        { header: '쇼핑몰', key: 'shop_name' },
        { header: '계정', key: 'shop_id' },
        { header: '주문번호', key: 'shop_ord_no' },
        { header: '구분', key: 'sett_type' },
        { header: '온라인 상품명', key: 'shop_sale_name' },
        { header: '구매자명', key: 'order_name' },
        { header: '정산상태', key: 'sett_status' },
        { header: '정산완료일', key: 'sett_com_date' },
        { header: '판매금액', key: 'sales' },
        { header: '공제금액', key: 'deduct_price' },
        { header: '정산금액', key: 'sett_price' }
      ],
      // 주문정보
      customOrderExcelFields: [
        {
          header: '주문번호',
          placeholder: '주문번호,순번',
          key: 'shop_ord_no',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.shop_ord_no, // ocode
          tooltip: '주문의 고유번호이며, 주문번호1, 주문번호2, 순번 등으로 구분되어 있는 경우 콤마(,)로 구분하여 입력합니다.\nex) 주문번호1,주문번호2,순번'
        },
        {
          header: '원주문번호',
          placeholder: '원주문번호',
          key: 'ori_shop_ord_no',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.ori_shop_ord_no, // ocoder
          tooltip: '반품이나 교환등의 주문번호가 별도로 생성되어 최초 결제 시 원주문번호가 있을 경우 설정합니다.'
        },
        {
          header: '결제번호',
          placeholder: '결제번호',
          key: 'ord_pay_no',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.ord_pay_no, // opno
          tooltip: '배송ID, 배송번호, 장바구니번호 등 주문번호 외에 중복을 구분할 수 있는 고유번호가 있을 경우 설정합니다.'
        },
        {
          header: '정산구분',
          placeholder: '매출 구분',
          key: 'sett_type',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.sett_type, // stype
          tooltip: '매출, 배송비, 반품 등의 구분값이 별도로 제공되는 경우 설정하며, 설정 시 중복기준에도 체크합니다.'
        },
        {
          header: '쇼핑몰상품코드',
          placeholder: '상품번호',
          key: 'shop_sale_no',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.shop_sale_no // pcode
        },
        {
          header: '상품명',
          placeholder: '상품명',
          key: 'shop_sale_name',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.shop_sale_name // pname
        },
        {
          header: '옵션명',
          placeholder: '옵션1,옵션2',
          key: 'shop_opt_name',
          isCriteria: false,
          enableCriteria: true,
          criteriaKey: criteriaKey.shop_opt_name // popt
        },
        {
          header: '수량',
          placeholder: '구매수량',
          key: 'sale_cnt',
          isCriteria: false,
          enableCriteria: false,
          criteriaKey: criteriaKey.sale_cnt // scnt
        },
        {
          header: '구매자명',
          placeholder: '수령인',
          key: 'order_name',
          isCriteria: false,
          criteriaKey: criteriaKey.order_name, // oname
          enableCriteria: true
        },
      ],

      // 매출 및 정산정보
      customSalesExcelFields: [
        {
          header: '판매단가',
          placeholder: '단가',
          key: 'sales_unit',
          tooltip: '수량이 곱해지지 않은 상품의 판매단가'
        },
        {
          header: '총판매가',
          placeholder: '판매가',
          key: 'sales',
          tooltip: '수량이 곱해진 상품의 판매금액',
          required: true,
        },
        {
          header: '실결제금액',
          placeholder: '결제금액',
          key: 'pay_amt',
          tooltip: '고객이 할인 등을 적용받아 실제로 결제한 금액',
        },
        {
          header: '배송비',
          placeholder: '배송비',
          key: 'ship_cost',
          tooltip: '일반 배송비'
        },
        {
          header: '추가배송비',
          placeholder: '도서산간배송비',
          key: 'ship_cost2',
          tooltip: '도서산간 배송비, 설치배송비 등 추가로 결제된 배송비'
        },
        {
          header: '환불배송비',
          placeholder: '환불배송비',
          key: 'refund_ship_cost',
          tooltip: '반품, 교환 등으로 발생한 배송비'
        },
        {
          header: '환불금액',
          placeholder: '취소금액',
          key: 'claim_refund_sales',
          tooltip: '취소, 반품 등으로 발생한 환불금액'
        },
        {
          header: '판매수수료',
          placeholder: '수수료합계',
          key: 'sales_fee',
          tooltip: '정산 시 쇼핑몰에 지급하는 수수료'
        },
        {
          header: '판매수수료율',
          placeholder: '수수료율',
          key: 'fee_rate',
          tooltip: '정산 시 쇼핑몰에 지급하는 수수료율'
        },
        {
          header: '정산예정금액',
          placeholder: '정산예정금',
          key: 'sett_plan_price',
          tooltip: '정산일이 확정되지 않은 예정 금액'
        },
        {
          header: '정산금액',
          placeholder: '판매정산금액',
          key: 'sett_price',
          tooltip: '정산 지급이 완료된 금액',
          required: true,
        },
        {
          header: '공급가',
          placeholder: '공급가',
          key: 'supply_price',
          tooltip: '결제 완료 시점의 예상 정산금액입니다.',
        },
        {
          header: '공제금액',
          placeholder: '공제금액',
          key: 'deduct_price',
          tooltip: '정산 지급 금액에서 공제되는 금액'
        },
      ],

      /**
       * 날짜 형식
       */
      dateFormat: ['YYYY-MM', 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM', 'YYYY.MM.DD', 'YYYY.MM.DD HH:mm', 'YYYY.MM.DD HH:mm:ss', '직접입력'],

      // 날짜정보
      customDatesExcelFields: [
        {
          header: '결제완료일',
          placeholder: '주문일자',
          key: 'pay_time',
          tooltip: '고객이 상품 구매 시 결제한 일자'
        },
        {
          header: '매출확정일',
          placeholder: '구매결정일',
          key: 'pay_confirm_date',
          tooltip: '구매확정 등으로 정산일자가 확정되는 기준일자'
        },
        {
          header: '환불일',
          placeholder: '반품일자',
          key: 'refund_date',
          tooltip: '주문이 취소되거나 반품되어 환불된 일자'
        },
        {
          header: '정산예정일',
          placeholder: '정산지급 예정일',
          key: 'sett_plan_date',
          tooltip: '정산금 지급이 예정된 일자'
        },
        {
          header: '정산완료일',
          placeholder: '송금일자',
          key: 'sett_com_date',
          tooltip: '정산금 지급 일자',
          required: true,
        },
      ],

      // 부가정보
      addDatesExcelFields: [
        {
          header: '쇼핑몰할인금액',
          placeholder: '쇼핑몰 할인',
          key: 'shop_burden_discount_amt',
          tooltip: '쇼핑몰이 부담하는 할인 금액'
        },
        {
          header: '판매자할인금액',
          placeholder: '판매자즉시할인,쿠폰할인금액',
          key: 'seller_burden_discount_amt',
          tooltip: '판매자가 부담하는 할인 금액'
        },
        {
          header: '광고비금액',
          placeholder: '광고비',
          key: 'adv_fee',
          tooltip: '광고비 집행 금액'
        },
        {
          header: '판매채널',
          placeholder: '판매점포',
          key: 'shop_channel',
          tooltip: '판매채널이 구분되어 있는 쇼핑몰인 경우 설정 (ex. 온라인, TV쇼핑 등)'
        },
        {
          header: '사업자번호',
          placeholder: '사업자번호',
          key: 'biz_reg_no',
          tooltip: '해당 항목이 제공되는 경우 설정'
        },
        {
          header: '기타',
          placeholder: '결제수단',
          key: 'misc_etc',
          tooltip: '제공되는 항목 외 정산 데이터와 함께 출력되어야 하는 기타 정보가 있을 경우 설정'
        },
      ],
    };
  });
