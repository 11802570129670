'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A084-Ctrl', function ($scope, $timeout, $rootScope, $compile, categoryModel, templateModel, $stateParams, A084categorySVC, errorSVC,
    siteInfo, commonSVC, commonModel, shopAccountModel, gettextCatalog) {
    $scope.CateList = angular.copy(A084categorySVC.category_list);
    $scope.CateData = angular.copy(A084categorySVC.category_data);
    $scope.dispCtg = angular.copy(A084categorySVC.dispCtg);
    $scope.spDispCtg = angular.copy(A084categorySVC.spDispCtg);
    $scope.site_list = angular.copy(A084categorySVC.site_list);
    $scope.category_code = '';
    let isSsg = false;

    init();

    //초기화
    function init() {
      loading(false); // 로딩중
      // 매입형태 옵션 저장 변수
      $scope.selectedFrm;

      // 2017-12-07 Daniel
      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;
      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 콜백함수를 프로미스로 넘김
      shopAccountModel.load(param, function (state, data) {
      // cateInfoDetail 에서 불러온 DB DATA 저장
        $scope.userInfo = data.results; // 유저데이터 API로 요청

        // 표준카테고리 API 호출
        requestPA('standard_category')
          .then(function (data) {
            setArrCategory(data.data.data.items);
            selectClear(0);

            /**
             * 2018-03-09 roy
             * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
             */
            if ($scope.matchingData.shop_cate_no_add_info) {
              if (typeof $scope.matchingData.shop_cate_no_add_info == 'string') {
                matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
              } else if (typeof $scope.matchingData.shop_cate_no_add_info === 'object' && Object.keys($scope.matchingData.shop_cate_no_add_info).length === 0) {
                matchDetailDataLoad(angular.copy(A084categorySVC.category_data));
              } else {
                matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
              }
            } else if ($scope.matchingData.sol_cate_shop_add_info) {
              matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
            } else {
              matchDetailDataLoad(angular.copy(A084categorySVC.category_data));
            }

            loading(true);
          });
      });

      $('.list-group').sortable();
    }

    function matchDetailDataLoad(shop_info) {
      // string 형태로 들어오면 에러가 나기 때문에 경우에 따라 파싱
      if (shop_info && typeof shop_info === 'string') {
        $scope.CateData.detailData = JSON.parse(shop_info);
      } else {
        $scope.CateData.detailData = shop_info;
      }

      // 카테고리 불러오기
      $scope.category_names = $scope.CateData.detailData.category_names;
      $scope.category_code = $scope.CateData.detailData.category_code;

      // 불러온 데이터에서 매입형태의 키를 찾아 자동적으로 매칭시킴
      $scope.selectedFrm = _.filter($scope.tmpFrm[$scope.CateData.detailData.key_Dctg], function (o) {
        return o.code === $scope.CateData.detailData.key_buyFrmCd_Cd;
      })[0];
      $scope.changeFrm();
      const code = $scope.CateData.detailData.category_code.split('_');
      const codeLen = code.length;

      for (let i = 0; i < codeLen; i++) {
        const cd = i === 0 ? null : code[(i - 1)];

        setCategory(i, cd);
        $scope.CateData.detailData[$scope.CateList[i]] = code[i];
      }

      // 사이트 불러오기
      const siteList = $scope.CateData.detailData.php_SiteNo || $scope.CateData.php_SiteNo;
      const siteListLen = siteList.length;

      for (let k = 0; k < siteListLen; k++) {
        const site = siteList[k];

        $(`.site${site}`).attr('checked', 'checked');
      }

      // 장시카테고리 불러오기
      const dispTypeArr = ['dispCtg', 'spDispCtg'];

      _.each(dispTypeArr, function(dispType) {
        let dispCtgIdx = 0;
        let dispTags = '';
        let value, name;
        const NKey = `key_${dispType}_Nm`;
        const CKey = `key_${dispType}_Cd`;

        while (true) {
          if (!$scope.CateData.detailData[NKey + dispCtgIdx]) {
            break;
          }

          value = $scope.CateData.detailData[CKey + dispCtgIdx];
          name = $scope.CateData.detailData[NKey + dispCtgIdx];

          delete $scope.CateData.detailData[CKey + dispCtgIdx];
          delete $scope.CateData.detailData[NKey + dispCtgIdx];

          $scope[dispType].dispCtgSelectList.push(value);

          dispTags += `<li class="list-group-item" data-dispCtgCd="${value}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`;
          dispCtgIdx++;
        }

        $(`.list-group.${dispType}`).html('').append(dispTags);
        $compile(dispTags)($scope);
      });
    }

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(complete) {
      if (complete) {
        $('#spinner').addClass('hidden'); //로딩중
      } else {
        $('#spinner').removeClass('hidden'); // 로딩완료
      }
    }

    /**
     * 카테고리 배열 세팅
     * @param data
     */
    function setArrCategory(data) {
      const CtgArr = [
        { type: 'stdCtgGrp', result: {} }, // 그룹분류
        { type: 'stdCtgLcls', result: {} }, // 대분류
        { type: 'stdCtgMcls', result: {} }, // 중분류
        { type: 'stdCtgScls', result: {} }, // 소분류
        { type: 'stdCtgDcls', result: {} } // 세분류
      ];
      const CtgArrLength = CtgArr.length;
      // 임시 매입형태 저장 변수
      const tmpFrmCd = {};

      let PrevCode; // 이전 카테고리코드명

      for (const i in data) {
        const Item = data[i];

        for (let k = 0; k < CtgArrLength; k++) {
          const GrpChk = k === 0; // 그룹분류 체크
          const Obj = CtgArr[k].result; // 분류 객체
          const Type = CtgArr[k].type; // 분류 타입

          const Name = Item[`${Type}Nm`]; // 카테고리명
          const Code = Item[Type + (GrpChk ? 'Cd' : 'Id')]; // 카테고리코드

          if (k < 4) {
            const NextObj = CtgArr[(k + 1)].result; // 다음객체

            if (!NextObj[Code]) {
              NextObj[Code] = new Object(); // 다음객체에 이번객체와 연결시킬 배열 생성
            }
          } else {
            // 키 값이 중복되는 경우가 있어 리스트 형태로 변경
            if (!tmpFrmCd[Code]) {
              tmpFrmCd[Code] = [];
            }
            // 신세계몰 필터링
            if (Item.siteNo !== 6004) {
              tmpFrmCd[Code].push({ code: Item.buyFrmCd, name: Item.buyFrmNm });
            }
          }

          if (GrpChk) {
            Obj[Name] = Code; // 분류는 배열없이 객체만 추가
          } else {
            Obj[PrevCode][Name] = Code;
          }

          PrevCode = Code;
        }
      }

      $scope.tmpFrm = tmpFrmCd;
      $scope.Category = CtgArr;
    }

    // 옵션 변경 된 후 적용
    $scope.changeFrm = function () {
      $scope.buyFrm = $scope.selectedFrm;
    };

    /**
     * 카테고리 리스트 출력
     * @param idx
     * @param key
     */
    function setCategory(idx, key) {
      let Result;
      let Tags = '';

      if (key === null) {
        Result = $scope.Category[idx].result;
      } else {
        Result = $scope.Category[idx].result[key];
      }

      try {
        for (const k in Result) {
          const Item = Result[k];

          Tags += getOptionTag(Item, k);
        }
      } catch (e) {
        console.error(e);
      }

      Tags = angular.element(Tags);
      $(`#CatMenu_${idx}`).append(Tags);
      $compile(Tags)($scope);
    }

    /**
     * 옵션태그 리턴함수
     * @param value
     * @param name
     * @returns {string}
     */
    function getOptionTag(value, name) {
      return `<option value='${value}'>${name}</option>`;
    }

    /**
     * 셀렉트박스 비우기
     * @param idx
     */
    function selectClear(idx) {
      for (let i = idx; i <= 4; i++) {
        $(`#CatMenu_${i}`).empty();
      }
    }

    /**
     * 카테고리 선택 이벤트
     * @param idx
     * @constructor
     */
    $scope.CtgSelect = function(idx) {
      idx = parseInt(idx);

      const key = $(`#CatMenu_${idx}`).find('option:selected').val();

      if (key.indexOf('?') > -1) {
        commonSVC.showMessage('선택할 수 없는 항목입니다');

        return false;
      }

      const index = idx + 1;

      if (idx < 4) {
        // 전시카테고리 초기화
        $scope.dispCtg.dispCtgType = '';

        selectClear(index);
        setCategory(index, key);
      }
      setCateName(index);
    };

    /**
     * 카테고리 네임 이벤트
     */
    function setCateName(index) {
      const CateArr = [];
      const CateValueArr = [];

      for (let i = 0; i < index; i++) {
        CateArr.push($(`#CatMenu_${i}`).find('option:selected').text());
        CateValueArr.push($(`#CatMenu_${i}`).find('option:selected').val());
      }

      $scope.category_names = CateArr.join(' > ');
      $scope.category_code = CateValueArr.join('_');
    }

    // /**
    //  * 등록사이트 데이터 사용안함
    //  */
    // $scope.setSiteNo = function(index, $event) {
    //   const target = $(`#siteCheckbox${index}`);
    //   const value = target.val();
    //   const isChecked = target.is(':checked');

    //   try {
    //     var siteArray = $scope.CateData.detailData.php_SiteNo;
    //   } catch (e) {
    //     commonSVC.showMessage(gettextCatalog.getString('로딩중입니다'));
    //     $event.preventDefault();

    //     return false;
    //   }

    //   if (isChecked) {
    //     siteArray.push(value);
    //   } else {
    //     for (const i in siteArray) {
    //       const siteValue = siteArray[i];

    //       if (siteValue === value) {
    //         siteArray.splice(i, 1);
    //       }
    //     }
    //   }
    // };

    /**
     * 전시카테고리 조회
     */
    function getDispCtg(params, dispCtg) {
      const type = params.type;

      let action, addData;

      switch (type) {
        case 0:
          if (!$scope.CateData.detailData.key_Dctg) {
            dispCtg.dispCtgType = '';

            return '세분류를 선택해주세요';
          }

          action = 'exh_category';
          addData = {
            stdCtgDclsId: $scope.CateData.detailData.key_Dctg
          };
          break;

        case 1:
          if (!dispCtg.searchValue) {
            return '검색어를 입력해주세요';
          }

          action = 'exh_category_all';
          addData = {
            dispCtgNm: dispCtg.searchValue,
            dispCtgId: '',
            siteNo: ''
          };
          break;

        case 2:
          action = 'exh_category_all';
          addData = {
            dispCtgNm: '',
            dispCtgId: '',
            dispCtgClsCd: '20',
            siteNo: '6001'
          };
          break;
      }

      // 전시카테고리 API 호출
      return requestPA(action, addData);
    }

    $scope.setDispCtg = function(type, dispType, clsCd) {
      const dispCtg = $scope[dispType];

      if (!$scope.CateData.detailData) {
        commonSVC.showMessage(gettextCatalog.getString('로딩중 입니다'));
        dispCtg.dispCtgType = '';

        return false;
      }

      dispCtg.dispCtgLoad = true;

      const param = {
        type: type
      };

      const dispCtgType = dispCtg.dispCtgType;
      const dispPromise = getDispCtg(param, dispCtg);

      if (dispPromise) {
        if (typeof dispPromise === 'string') {
          commonSVC.showMessage(gettextCatalog.getString(dispPromise));
          dispCtg.dispCtgLoad = false;
        } else {
          dispPromise.then(function (data) {
            const items = data.data.data.items;
            const itemsLen = items.length;
            let Tags = getOptionTag('', '선택');
            let optionNm;
            let optionVal;

            try {
              for (let i = 0; i < itemsLen; i++) {
                const item = items[i];
                const siteNo = type == 0 ? item.siteNo.toString() : item.aplSiteNo.toString();

                // SSG닷컴 카테고리 유무
                if (siteNo === '6005') {
                  isSsg = true;
                }

                if (item.dispCtgClsCd != clsCd) {
                  continue;
                }
                if (siteNo != '6001' && siteNo != '6005' && $.inArray(siteNo, $scope.CateData.detailData.php_SiteNo) < 0) {
                  continue;
                }

                if (type == 0) {
                  optionNm = `[${item.dispCtgSclsId}] ${dispCtg.siteNo[siteNo]} : ${item.dispCtgLclsNm} > ${item.dispCtgMclsNm} > ${item.dispCtgSclsNm}`;
                  optionVal = item.dispCtgSclsId;

                  if (item.dispCtgDclsId) {
                    optionNm = `${optionNm} > ${item.dispCtgDclsNm}`;
                    optionVal = item.dispCtgDclsId;
                  }

                  if (!item.dispCtgSclsNm) {
                    optionNm = `[${item.dispCtgMclsId}] ${dispCtg.siteNo[siteNo]} : ${item.dispCtgLclsNm} > ${item.dispCtgMclsNm}`;
                    optionVal = item.dispCtgMclsId;
                  }

                } else {
                  optionNm = `[${item.dispCtgId}] ${item.aplSiteNoNm} : ${item.dispCtgPathNm}`;
                  optionVal = item.dispCtgId;
                }

                optionVal = `${siteNo}-${optionVal}`;

                Tags += getOptionTag(optionVal, optionNm);
              }

              if (Tags == getOptionTag('', '선택' && !$scope.CateData.detailData)) {
                commonSVC.showMessage(gettextCatalog.getString('조회된 카테고리가 없습니다'));
              }

              Tags = angular.element(Tags);
              $(`#${dispType}${dispCtgType}`).empty().append(Tags);
              $compile(Tags)($scope);
            } catch (err) {
              console.error(err);
            }

            dispCtg.dispCtgLoad = false;
          });
        }
      } else {
        commonSVC.showMessage(gettextCatalog.getString('조회된 카테고리가 없습니다'));
      }
    };

    /**
     * 전시카테고리 선택이벤트
     */
    $scope.setDispCtgList = function(idx, dispType) {
      const dispCtgSelectList = $scope[dispType].dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;

      const target = $(`#${dispType}${idx}`);
      const listGroup = $(`.list-group.${dispType}`);

      const value = target.find('option:selected').val();
      const name = target.find('option:selected').text();

      if (value == '') {
        return false;
      }

      let thisVal;

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        thisVal = dispCtgSelectList[i];

        if (thisVal == value) {
          return false;
        }
      }

      dispCtgSelectList.push(value);
      const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${value}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

      listGroup.append(aElement);
      $compile(aElement)($scope);
    };

    /**
     * 전시카테고리 삭제
     */
    $(document).on('click', '.dispCtgSelectRemoveBtn', function(e) {
      const dispType = e.target.classList[0].split('Select')[0];
      const dispCtgSelectList = $scope[dispType].dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;

      const item = $(e.target).parent();
      const value = item.attr('data-dispCtgCd');

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        const thisVal = dispCtgSelectList[i];

        if (thisVal == value) {
          dispCtgSelectList.splice(i, 1);
        }
      }

      item.remove();
    });

    /**
     * 전시카테고리 저장
     */
    function DispCtgList(param) {
      const dispTypeArr = ['dispCtg', 'spDispCtg'];

      // 초기화 후 넣어줌
      _.each($scope.CateData.detailData, function(v, k) {
        if (k.search('key_dispCtg_') >= 0 || k.search('key_spDispCtg_') >= 0) {
          $scope.CateData.detailData[k] = undefined;
          param[k] = undefined;
        }
      });

      _.each(dispTypeArr, function(dispType) {
        const disp = [];

        $(`.list-group.${dispType} .list-group-item`).each(function (e) {
          const name = $(this).find('.dispCtgNm').text();
          const code = $(this).attr('data-dispctgcd');

          param[`key_${dispType}_Nm${e}`] = name;
          param[`key_${dispType}_Cd${e}`] = code;

          disp.push({ name: name, code: code });
        });

        param[dispType] = [...disp];
      });
    }

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = $scope.category_code;
      // 세분류
      $scope.CateData.detailData.key_stdct_Cd = $scope.CateData.detailData.key_Dctg;

      const param = {
        site_code: 'A084',
        site_id: $scope.matchingData.site_id,
        category_code: $scope.category_code,
        category_codes: 'test',
        category_names: $scope.category_names,
        categoryId: $scope.matchingData.categoryId,
        cateAttr: $scope.CateData.cateAttr,
        detailData: { ...$scope.CateData.detailData },
        set_all_shop: $scope.check ? $scope.check.set_all_shop : false,
        country: 'kr',
        //국내/해외
        type: 0
      };

      DispCtgList(param.detailData);

      if (!$scope.form1.$valid) {
        $timeout(() => {
          commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');
        }, 300);

        return;
      }

      // SSG닷컴 카테고리가 있고, 선택을 안한 경우 에러 처리
      if (isSsg && !param.detailData.dispCtg.filter(cate => cate.code.match(/^6005/g)).length) {
        $timeout(() => {
          commonSVC.showMessage('실패', 'SSG.COM 전시카테고리가 있는 경우 반드시 추가하여 주시기 바랍니다.');
        }, 300);

        return false;
      }

      if (!$scope.buyFrm) {
        $timeout(() => {
          commonSVC.showMessage('실패', '매입형태를 선택해주세요');
        }, 300);

        return false;
      } else if (!param.detailData.key_dispCtg_Cd0) {
        $timeout(() => {
          commonSVC.showMessage('실패', '메인매장은 1개이상 선택해주세요');
        }, 300);

        return false;
      }

      param.detailData.buyFrm = $scope.buyFrm;
      param.detailData.key_buyFrmCd_Cd = $scope.buyFrm.code;
      param.detailData.key_buyFrmCd_Nm = $scope.buyFrm.name;

      if ((event && event.type === 'online') || $scope.matchingData.isOnlineAdd === true) {
        $timeout(() => {
          commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.')
            .then(async (confirm) => {
              if (confirm) {
                /**
           * 2018-03-09 Roy
           * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
           */

                if (event && event.type === 'online') {
                  $scope.returnAct(param);
                } else {
                  try {
                    const res = await categoryModel.shopInfoDetailEdit(param);

                    $scope.loading = true;
                    commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                    param.shop_cate_no = res.data.shop_cate_no;

                    $scope.returnAct(param);
                  } catch (err) {
                    $scope.loading = true;

                    commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
                  }
                }
              } else {
                return;
              }
            });
        }, 300);
      } else {
        $timeout(() => {
          param.isApply = true;

          $scope.applyCate(param);
        });
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo,
            domain = siteInfo.A084.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }
  });
