'use strict';

angular.module('gmpApp')
  .service('customerInquiryModel', function ($q, commonSVC, settings) {

    return {
      /**
       * CS 삭제
       * @param number DB 번호
       */
      delete: function (params) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/delete`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * CS 답변 등록
       * @param data
       */
      answer: function (data) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/addAnswer`;

        return commonSVC.sendUrl('PUT', url, { inquirys: [data] });
      },

      apply: function (data) {

        const url = `${settings.pa20ApiUrl}/app/inquiries/apply`;

        return commonSVC.sendUrl('PATCH', url, data);
      },

      /**
       * 답변템플릿 불러오기
       */
      loadAnswerTemplate: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/answerTemplate/list`;

        if (next) {
          commonSVC.sendUrl('POST', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, data);
        }
      },

      /**
       * 답변템플릿 등록하기
       */
      addAnswerTemplate: function (data) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/answerTemplate`,
              params = data;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 답변템플릿 수정하기
       */
      editAnswerTemplate: function (data) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/answerTemplate`,
              params = data;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 답변템플릿 삭제하기
       */
      delAnswerTemplate: function (data) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/answerTemplate`;

        return commonSVC.sendUrl('DELETE', url, data);
      },

      inquiryCount: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/getCnt`;
        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 문의리스트조회.
       */
      list: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/inquiries`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      /**
       * 이전문의리스트 조회
       */
      inquiryPrevious: async function(params) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/previous/customer`;
        const res = await commonSVC.sendUrl('POST', url, params);

        return res;
      },

      /**
       * 수동 문의상태 변경
       * */
      setStatus: async function(data) {
        const url = `${settings.pa20ApiUrl}/app/inquiries/setStatus`;

        return await commonSVC.sendUrl('PATCH', url, data);
      }

    };
  });
