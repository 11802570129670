(function () {
  'use strict';
  /**
   * 2017-10-25 Pada
   * 1:1문의 상세보기 컨트롤러
   */
  angular
    .module('gmpApp')
    .controller('QuestionDetailsCtrl', QuestionDetailsCtrl);

  function QuestionDetailsCtrl ($scope, $rootScope, data, commonSVC, questionModel, $uibModalInstance, $timeout) {

    $scope.detail_data = {};
    $scope.detail_data.attach_file = [];

    $scope.showData = '답변완료';
    $scope.flags = {
      isInquiryRecvState: false, // 접수대기 상태인지
      isModifying: false // 수정버튼을 눌렀는지
    };
    $scope.title_cnt = 0;

    const params = {
      user_info: $rootScope.user_profile,
      cs_no: data.cs_no,
      pa_cs_no: (data.pa_cs_no || 0),
    };

    $scope.pre = params.user_info;
    $scope.isReQuestionShow = false;
    $scope.view_testAgree = '';   // 테스트동의여부

    // 재문의
    $scope.reQuestion = reQuestion;
    $scope.byteCheck = byteCheck;
    // 문의삭제
    $scope.deleteInquiry = deleteInquiry;
    // 문의수정
    $scope.modify = modify;
    // 문의저장
    $scope.save = save;
    // 파일첨부
    $scope.fileAdd = fileAdd;
    // 파일삭제
    $scope.subtractElement = subtractElement;
    // 답변지연사유 확인
    $scope.replyDelayInfo = replyDelayInfo;

    $scope.openQuestionModal = openQuestionModal;
    $scope.cancel = cancel;
    // ------------------- FUNCTIONS -------------------

    async function init() {
      const [
        { status, data: { results: [cs] } },
        reQuestions
      ] = await Promise.all([
        questionModel.frontCsDetail(params),
        questionModel.reQuestionList(params)
      ]);

      if (status === 200) {
        const rnToBr = text => text.replace(/(?:\r\n|\r|\n)/g, '<br />');
        const hideSec = dateTime => dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm') : '-';
        const convertFileString = fileStrings => (fileStrings || '').split('#').filter(fileString => fileString).map(fileString => JSON.parse(fileString));
        const mapStatus = status => (['접수완료', '처리완료', '결재반려'].includes(status) || (cs.charge_dept_no && ['접수대기', '접수취소'].includes(status))) ? '처리중' : (status === '답변지연' ? '추가검토중' : status);

        $scope.detail_data = {
          ...cs,

          content: rnToBr(cs.content),
          board_status: mapStatus(cs.board_status),

          cs_wdate: hideSec(cs.cs_wdate),
          reply_time: hideSec(cs.reply_time),

          file: [],
          attach_file: convertFileString(cs.attach_file),
          reply_attach_file: convertFileString(cs.reply_attach_file)
        };

        // 처리중 상태인지
        $scope.flags.isInquiryRecvState = ['처리중', '추가검토중'].includes($scope.detail_data.board_status);

        // 테스트 동의여부
        if (!_.isUndefined($scope.detail_data.test_agree_yn) && $scope.detail_data.test_agree_yn !== null) {
          $scope.view_testAgree = $scope.detail_data.test_agree_yn ? '동의함' : '동의안함';
        }
      }

      //이전문의 내역을 보여줌
      $scope.reQuestionsGroup = reQuestions.reduce((pre, cur) => {
        const key = moment(cur.reply_time).format('YYYY-MM-DD');

        return {
          ...pre,
          [key]: [...(pre[key] || []), cur]
        };
      }, {});

      $scope.reQuestionsCount = Object.values($scope.reQuestionsGroup).flat().length;

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    }
    init();

    function reQuestion() {
      // 모달이 이미 열려있을 경우
      if ($rootScope.questionModalOpen) {
        $rootScope.draggableModalZindex('question');
        $rootScope.$broadcast('questionFoldOpen');
      } else {
        openQuestionModal();
      }
    }

    /**
     * 바이트 체크
     */
    function byteCheck (str) {
      return str.byte2();
    }

    /**
     * 문의 삭제
     */
    function deleteInquiry () {
      commonSVC.showConfirm('', '문의가 삭제됩니다.\n 삭제하시겠습니까?', function (re) {
        if (re) {
          questionModel.deleteCs({ cs_no: data.cs_no }, function(state) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', '삭제에 성공하였습니다.');
              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '', '삭제에 실패하였습니다.');
            }
          });
        }
      });
    }

    /**
     * 문의 '수정' 클릭
     */
    function modify () {
      commonSVC.showConfirm('', '이미 처리중인 문의 입니다. 수정하시겠습니까?\n <확인> 시 내용 작성이 가능하며 저장한 내용은 담당자에게 전달 됩니다.\n <취소> 시 내용 수정이 철회 됩니다.', function (re) {
        if (re) {
          $timeout(function() {
            $scope.flags.isModifying = true;
            $scope.title_cnt = byteCheck($scope.detail_data.title);
            $scope.detail_data.content = $scope.detail_data.content.replace(/<br \/>/g, '\n');
          }, 300);
        }
      });
    }

    /**
     * 문의 수정사항 저장
     */
    function save () {
      const params = {
        cs_no: data.cs_no,
        title: $scope.detail_data.title,
        content: $scope.detail_data.content,
        board_status: $scope.detail_data.board_status,
        file_array: $scope.detail_data.attach_file,
        board_type: $scope.detail_data.board_type,
        name: $scope.detail_data.name,
        reply_staff_no: $scope.detail_data.reply_staff_no
      };

      questionModel.modifyCs(params, function(state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '수정에 성공하였습니다.');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '', '수정에 실패하였습니다.');
        }
      });
    }

    /**
     * 파일 첨부
     */
    function fileAdd (value) {
      const max_fields = 5; // 최대 업로드 가능 개수
      const attach_file_len = $scope.detail_data.attach_file.length;

      if (value && attach_file_len < max_fields) {
        $scope.detail_data.attach_file.push(value);
      }
    }

    /**
     * 파일 삭제
     */
    function subtractElement (index) {
      $scope.detail_data.attach_file.splice(index, 1);
    }

    function openQuestionModal () {
      commonSVC.openModal('xg', { data: params }, 'QuestionInquiryCtrl', 'views/question/modals/inquiry.html', '', '', false);

      $uibModalInstance.close();
    }

    async function replyDelayInfo () {
      const { data: delayInfo } = await questionModel.getDelayInfo({ cs_no: data.cs_no });

      commonSVC.openModal('md', { data: delayInfo }, 'replyDelayInfoCtrl', 'views/question/modals/replyDelayInfo.html', '', '', false);
    }

    $scope.scrollToTop = ($event) => {
      angular.element($event.target).parents('.modal-body').animate({ scrollTop: 0 });
    };

    /**
     * 창닫기
     */
    function cancel () {
      $uibModalInstance.dismiss();
    }
  }
})();
