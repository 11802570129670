'use strict';

angular.module('gmpApp')
  .controller('SetStockAllocationChannelRuleCtrl', function ($scope, $timeout, data, $uibModalInstance, commonSVC, inventoryModel, gettextCatalog) {
    $scope.mall = {};
    $scope.real_stock = data.real_stock;
    $scope.stock_cnt = data.stock_cnt;
    $scope.alloc_type;
    $scope.isAssigned = false;

    const fromHistory = data.fromHistory;

    $scope.prod_depot_shop_no = data.prod_depot_shop_no;
    $scope.highlight = data.fromHistory;

    // 연동된 온라인상품 재고할당 리스트 가져오기
    inventoryModel.stockAllocBaseShopList({ prod_no: +data.prod_no, depot_no: +data.depot_no, re_alloc_yn: false })
      .then((result) => {
        if (result.status === 200) {
          $scope.alloc_type = result.data.results[0].alloc_type || 'normal';
          $scope.mall = result.data.results;
          $scope.isAssigned = _.filter($scope.mall, function(mall) { return mall.prod_depot_shop_no; }).length;

          $scope.mall = _.sortBy($scope.mall, 'priority');

          _.forEach($scope.mall, function(mall, idx) {
            // 옵션명1,2,3 합침
            mall.opt_name = mall.opt_name1;
            mall.opt_name += mall.opt_name2 ? `,${mall.opt_name2}` : '';
            mall.opt_name += mall.opt_name3 ? `,${mall.opt_name3}` : '';

            // 중간 순번 비는 경우가 있어 우선순위 재입력
            mall.priority = idx + 1;

            // 할당률 없을경우 기본값 할당
            mall.alloc_rate = !$scope.isAssigned ? Math.round(100 / $scope.mall.length) : (mall.alloc_rate || 0);
          });

          // 우선순위할당이 아닐경우 드래그앤드롭 비활성화
          if ($scope.alloc_type !== 'priority') { $scope.sortableOptions.disabled = true; }

          // 재고할당 내역에서 모달 열었을 경우 해당 row 하이라이트
          if (fromHistory) {
            // 하이라이트 2초뒤 해제
            $timeout(function() {
              $scope.highlight = false;
            }, 2000);
          }
        } else {
          commonSVC.showToaster('error', '실패', '채널 연동 상품별 재고할당 리스트 조회에 실패했습니다');
        }
      });

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 드래그앤드롭 열 순서변경
     */
    $scope.sortableOptions = {
      update: function () {
        // 변경한 순서가 바로 반영이 안돼서 timeout
        $timeout(function() {
          _.forEach($scope.mall, function(m, idx) {
            m.priority = idx + 1;
          });
        }, 100);
      },
      disabled: false
    };

    /**
     * 할당률 일괄입력
     */
    $scope.setRate = function(rate) {
      if (!rate && rate !== 0) {
        commonSVC.showMessage(gettextCatalog.getString('할당률을 입력해주세요.'));

        return false;
      }

      _.each($scope.mall, function(mall) {
        mall.alloc_rate = rate;
      });
    };

    /**
     * 규칙추가
     */
    $scope.add = function () {
      const params = {
        alloc_type: $scope.alloc_type,
        mall_list: $scope.mall,
        re_alloc_yn: false
      };

      inventoryModel.stockAllocAddBaseShopRule(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', '성공', '재고할당 규칙 설정 성공');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '재고할당 규칙 설정 실패');
        }
      });
    };
  });
