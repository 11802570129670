/**
 * 반송 운송장 출력 접수 취소
 */
'use strict';

angular.module('gmpApp')
  .controller('cancelClaimInvoiceCtrl', function ($scope, $timeout, data, commonSVC, $uibModalInstance, deliveryModel) {
    const selected = data.cancelList;
    const carrInfo = data.carrInfo;

    $scope.title = data.claimType === 'return' ? '반품' : '교환';
    $scope.selectListCount = selected.length;
    $scope.wait = false;

    /**
    * 취소
    */
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    /**
    * 하단 버튼 클릭
    */
    $scope.cancelClaim = _.throttle(async () => {
      $scope.wait = true;
      // 운송장 출력시 로딩 추가.
      $('#spinner').removeClass('hidden');

      const params = {
        cancelList: selected,
        claimType: data.claimType,
        carrInfo,
      };

      deliveryModel.cancelClaimInvoice(params, async (status, returnData) => {
        if (status === 'success') {
          const errOrds = returnData.result.filter((o, idx) => o.publishErrMsg && returnData.result.findIndex(re => re.return_invoice_no === o.return_invoice_no) === idx);

          const addMsg = errOrds.length ? errOrds.reduce((prev, curr) => prev + `\n송장번호 - ${curr.return_invoice_no}: ${curr.publishErrMsg}`, '아래의 송장은 에러발생으로 반송취소접수에 실패되었으니 확인해주십시오.\n') : '';

          if (returnData.result.filter(o => !o.publishErrMsg).length) {
            commonSVC.showMessage(`선택한 [${$scope.title}진행] 상태 주문 ${$scope.selectListCount}건이 ${$scope.title}접수취소 되었습니다.`, addMsg);
          } else {
            const failMsg = errOrds.reduce((prev, curr) => prev + `\n송장번호 - ${curr.return_invoice_no}: ${curr.publishErrMsg}`, '');
            commonSVC.showMessage(`${$scope.title}접수취소에 실패하였습니다.`, failMsg);
          }

          $uibModalInstance.close('success');
        } else if (status == 'error') {
          $timeout(() => {
            commonSVC.showMessage('실패', returnData.data.error);
          }, 500);
          $scope.wait = false;
        }
        $('#spinner').addClass('hidden');
      });
    }, 1500, { trailing: false });
  });