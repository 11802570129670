/**
 *  상품관리 상품 모델
 *  2016-05-30 권윤학
 */

'use strict';

angular.module('gmpApp')
  .factory('productModel', function ($rootScope, settings, commonSVC) {
    let _oldData = {};

    return {
      setOld: function (old) {
        _oldData = old;
      },

      getOld: function () {
        return _oldData;
      },
      /**
       * 상품 모델 파싱
       * @param obj 파싱할 데이터
       */
      parse: function(obj) {
        _.extend(self, obj);
      },

      /**
       * SKU상품 조회용 정보 리스트
       * @param sku_code
       */
      list: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/list`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 상품 정보조회
       * @param sku_code
       */
      detail: function ({ sku_code, sol_no }, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/detail`;

        commonSVC.sendUrl('GET', url, { sku_code, sol_no }, function (state, data) {
          if (next) {
            next(state, data);
          }
        });
      },

      /**
       * 원산지 국가 리스트 조회
       * @param madeinList
       */
      getMadein: function() {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/madein`, '');
      },

      /**
       * 상품관리 SKU코드 중복체크
       * @param skuCode sku코드
       * @returns {bools} true/false
       */
      skuOverlabCheck: function(code, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/check-sku-overlab`;

        if (next) {
          commonSVC.sendUrl('GET', url, code, function (state, data) {

            next(state, { data });
          });
        } else {
          return commonSVC.sendUrl('GET', url, code, {});
        }
      },

      /**
       * 모델번호 중복체크
       */
      modelnoOverlabCheck: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/modelno-overlab-check`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(state, { data });
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }

      },

      /**
       * 상품등록
       * @returns {promise} 등록 결과
       */
      insert: function(data, next) {
        commonSVC.sendUrlFile('POST', `${settings.pa20ApiUrl}/app/stock/base`, data, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 상품수정
       * @returns {promise} 수정 결과
       */
      update: function(data, next) {
        commonSVC.sendUrlFile('PUT', `${settings.pa20ApiUrl}/app/stock/base`, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 상품 삭제
       */
      delete: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base`;

        commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 상품 복사
       */
      copy: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/copy`;
        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }

      },

      /**
       * 재고 일괄 수정
       */
      setStock: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 재고 이동
       */
      setStock_transfer: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/set-transfer`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 상품 일괄 수정
       */
      changeProdInfo: function (params, next) {
        commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/stock/base-batch`, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 스타일코드 그룹에서 제거
       */
      styleException: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/style/exception`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 스타일코드 그룹 해제
       */
      styleRelease: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/style/release`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 스타일그룹 복사
       */
      styleCopy: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/style/copy`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 스타일코드 업데이트
       */
      styleJoin: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/style/addBase`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 스타일코드 SKU상품 리스트 조회
       */
      styleList: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/style/list/${params.style_no}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 세트상품 추가
       */
      addSet: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/set/add`;

        commonSVC.sendUrl('POST', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 세트상품 수정
       */
      editSet: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/set`;

        commonSVC.sendUrl('PATCH', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 세트상품 삭제
       */
      deleteSet: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/set`;

        commonSVC.sendUrl('DELETE', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 세트 상세 정보
       */
      setDetails: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/set/detail`;

        commonSVC.sendUrl('POST', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 매칭되어 있으나 배송처가 없는 경우 매칭된 SKU상품(추가구매옵션)의 배송처 불러오기
       * 2018-12-03 Jacob
       */
      getDepotNo: function (prod_no, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/depot-no/${prod_no}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 상세 불러오기
       */
      baseExcelTemplateDetail: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/detail/${params.template_no}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 양식 복사
       */
      baseExcelTemplateCopy: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/copy`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 양식 삭제
       */
      baseExcelTemplateDelete: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/delete`;

        commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 양식 수정
       */
      baseExcelTemplateEdit: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/edit`;

        commonSVC.sendUrl('PUT', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 양식 추가
       * 2019-07-02 Alvin
       */
      baseExcelTemplateAdd: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/add`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 엑셀 업로드 양식 다운로드
       */
      baseExcelTemplateDown: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/upload-excel-template/down/${params.template_no}`;

        commonSVC.sendUrl('GET', url, {}, function (state, data) {
          next(state, data);
        });
      },

      /**
       * SKU상품 가져오기
       */
      scrapProd: function (params) {
        const url = `${settings.pa20ApiUrl}/app/stock/base/scrap-prods`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * HS코드 유효성 체크
       */
      hscodeValid: function (params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/hscode`, params);
      },

      /**
       * HS코드 즐겨찾기 수정
       */
      editHscodeBookmark: function (params) {
        return commonSVC.sendUrl('PUT', `${settings.pa20ApiUrl}/app/product/hscode?hscd=${params.hscd}`, params);
      },

      /**
       * 바코드 출력 양식 조회
       */
      getBarcodeTemplate: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/barcode/templates`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 바코드 출력 양식 추가(생성&복사)
       */
      addBarcodeTemplate: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/barcode/templates/add`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 바코드 출력 양식 수정
       */
      updateBarcodeTemplate: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/barcode/templates`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 바코드 출력 양식 삭제
       */
      deleteBarcodeTemplate: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/barcode/templates`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      /**
       * 바코드 pdf 출력
       */
      exportBarcodePdf: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/barcode/export`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 선택 가능한 포장형태 리스트 조회
       */
      prodPkgBoxTypeList: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/pkgbox/type`;

        return commonSVC.sendUrl('GET', url, params);
      },

      /**
       * 포장형태 추가
       */
      addProdPkgBox: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/pkgbox/add`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 포장형태 수정
       */
      editProdPkgBox: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/stock/pkgbox`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 포장형태 삭제
       */
      deleteProdPkgBox: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/pkgbox`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      /**
       * 포장형태 리스트 조회
       */
      prodPkgBoxList: function(params) {
        const url = `${settings.pa20ApiUrl}/app/stock/pkgbox/list`;

        return commonSVC.sendUrl('POST', url, params);
      }
    };

  });
