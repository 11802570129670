'use strict';

angular.module('gmpApp')
  .controller('StockTransferCtrl', function ($rootScope, $scope, data, settings, $uibModalInstance, commonSVC, productModel, inventoryModel, inventorySVC, gettextCatalog, warehouseList) {
    $scope.searchData = angular.copy(inventorySVC.searchData);
    $scope.prodDepotData = data.prod_nos;
    $scope.warehouseList = warehouseList.data.result ? warehouseList.data.result.sort() : [];
    const searchform = data.sendSearchForm;

    // 재고 입력 시 수정 후 실재고 계산
    $scope.editStock = (row) => {
      row.after_edit_cnt = row.stock_cnt_real - +row.edit_stock_cnt;
    };

    // 일괄 수정 처리
    $scope.allEdit = (key, value) => {
      const selectedData = $scope.grid.methods.selectedData('all');

      if (!selectedData.length) {
        commonSVC.showMessage(gettextCatalog.getString('선택된 상품이 없습니다.'));

        return false;
      }

      if (!key) {
        commonSVC.showMessage('일괄입력할 항목을 선택해주십시오.');

        return false;
      } else if (!value) {
        commonSVC.showMessage('일괄입력할 값을 선택해주십시오.');

        return false;
      }

      selectedData.forEach(item => {
        if (key === 'target_depot') {
          if (item.match_depots.split(',').includes(value) && item.depot_no.toString() !== value) {
            item[key] = value;
          }
        } else {
          item[key] = value;
        }

        if (key === 'edit_stock_cnt') {
          $scope.editStock(item);
        }
      });
    };

    /**
     * 저장
     */
    $scope.save = _.throttle(function() {
      $scope.form1.$valid = !$scope.prodDepotData.some(prod => !prod.edit_stock_cnt || !prod.target_depot);

      if ($scope.form1.$valid) {
        if ($scope.prodDepotData.find(o => +o.edit_stock_cnt > +o.stock_cnt_real)) {
          commonSVC.showMessage('재고이동 실패', '실재고보다 작거나 같은 수만 이동할 수 있습니다.');

          return false;
        }

        const origin_depotProd = $scope.prodDepotData.map(o => {
          return {
            prod_no: +o.prod_no,
            depot_no: +o.depot_no,
            edit_stock_cnt: -o.edit_stock_cnt,
            inout_memo: o.inout_memo,
            sku_cd: o.sku_cd,
            prod_name: o.prod_name
          };
        });
        const target_depotProd = $scope.prodDepotData.map(o => {
          return {
            prod_no: +o.prod_no,
            depot_no: +o.target_depot,
            edit_stock_cnt: +o.edit_stock_cnt,
            inout_memo: o.inout_memo,
            sku_cd: o.sku_cd,
            prod_name: o.prod_name
          };
        });

        const params = {
          prodDepotList: origin_depotProd.concat(target_depotProd)
        };

        productModel.setStock_transfer(params, function (state, result) {
          if (state == 'success') {
            commonSVC.showToaster('success', '성공', '재고이동에 성공하였습니다.');

            // 실재고 할당 && 재고할당 설정 있으면 재고할당진행
            // 배송처일경우 진행 X
            if (data.alloc_type && $rootScope.user_profile.auth_type !== '배송처') {
              const alloc_type = data.alloc_type;
              const prod_list = params.prodDepotList;

              // 유효한 재고할당 규칙이 있을경우
              if (prod_list.length) {
                inventoryModel.stockAllocCheckRuleExists({ prod_list, alloc_type }, function(state, data) {
                  if (data.result) {
                    const stockCnt = {};
                    prod_list.map(o => {
                      stockCnt[`${o.prod_no}_${o.depot_no}`] = o.edit_stock_cnt;
                    });
                    const alloc_params = {
                      prod_list,
                      re_alloc_yn: alloc_type === 're_alloc',
                      alloc_type,
                      from: 'stock_transfer',
                      stock_cnt: stockCnt
                    };

                    commonSVC.openModal('full', { data: alloc_params }, 'StockAllocationChannelCtrl', 'views/stock/inventory/stock_allocation_channel.html');
                  } else {
                    return true;
                  }
                });
              }
            }

            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', result.data.error || '재고이동에 실패하였습니다.');
          }
        });
      } else {
        commonSVC.showMessage('재고이동 실패', '이동할 배송처및 이동 수량은 필수로 입력해주셔야 합니다.');
      }
    }, 1000);

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
    * 데이터 테이블
    */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: false,
      gridHeight: 400,
      pinningColumns: [],
      alignCenterColumns: [
        'sku_cd',
        'depot_name'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: [
        'edit_stock_cnt', 'after_edit_cnt', 'inout_memo', 'target_depot'
      ],
      alignRightColumns: [ 'stock_cnt_real', 'stock_cnt', 'edit_stock_cnt', 'after_edit_cnt' ],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/transfer`,
        requestWillAction: (data) => {
          data = {
            ...data,
            prod_nos: $scope.prodDepotData,
            orderby: data.orderby || searchform.orderby,
          };

          return data;
        },
        requestDidAction: (result) => {
          $scope.prodDepotData = result.results;
          $scope.prodDepotData.forEach(row => {
            row.after_edit_cnt = row.stock_cnt_real;
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 90
        },
        {
          key: 'prod_name',
          title: 'SKU상품명_속성',
          width: 310,
          template: function(row) {
            return row.attri ? `${row.prod_name}_${row.attri}` : row.prod_name;
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 90
        },
        {
          key: 'stock_cnt_real',
          title: '실재고',
          width: 70
        },
        {
          key: 'target_depot',
          title: '이동할 배송처',
          width: 200,
          required: true,
          template: function(row) {
            const idx = $scope.prodDepotData.findIndex(o => o.prod_no === row.prod_no && o.depot_no === row.depot_no);
            const matchOptions = $scope.warehouseList
              .filter(o => row.match_depots.split(',').includes(o.code.toString()))
              .map(o => {
                return `<option value="${o.code}" ${o.code === row.depot_no ? 'disabled' : ''}>${o.warehouse_name}</option>`;
              });

            return `
              <select class="form-control full-width no-padding recheck" style="height: 22px;" ng-model="grid.appScope.prodDepotData[${idx}].target_depot" required>
                <option value="">배송처 선택</option>
                ${matchOptions.join('')}
              </select>`;
          }
        },
        {
          key: 'edit_stock_cnt',
          title: '이동 수량',
          width: 70,
          required: true,
          template: function(row) {
            const idx = $scope.prodDepotData.findIndex(o => o.prod_no === row.prod_no && o.depot_no === row.depot_no);

            return `
              <input type="text" class="form-control ng-pristine ng-valid ng-empty recheck" style="text-align: right; border: 1px solid #d4d4d4" name="grid.appScope.prodDepotData[${idx}].sku_cd"
                ng-model="grid.appScope.prodDepotData[${idx}].edit_stock_cnt" 
                ng-change="grid.appScope.editStock(grid.appScope.prodDepotData[${idx}])" 
                maxlength="6"
                ptgui-regex-mask="positiveNum"
                required
              />
            `;
          }
        },
        {
          key: 'after_edit_cnt',
          title: '이동 후 재고',
          width: 90,
          template: function(row) {
            const idx = $scope.prodDepotData.findIndex(o => o.prod_no === row.prod_no && o.depot_no === row.depot_no);

            return `<div ng-bind="grid.appScope.prodDepotData[${idx}].after_edit_cnt"></div>`;
          }
        },
        {
          key: 'inout_memo',
          title: '사유',
          width: 270,
          tooltip: {
            text: '간단한 메모를 등록할 수 있으며, 등록된 사유는 입/출고 내역에서 확인할 수 있습니다.',
            placement: 'top-right',
          },
          template: function(row) {
            const idx = $scope.prodDepotData.findIndex(o => o.prod_no === row.prod_no && o.depot_no === row.depot_no);

            return `
              <div class="input-group full-width">
                <input type="text" class="form-control" ng-model="grid.appScope.prodDepotData[${idx}].inout_memo" max-bytes="60"></input>
                <span class="input-group-btn">
                  <span class="help-inline no-margin-top"> <span class="str_bytes">0</span> / 60byte</span>
                </span>
              </div>`;
          }
        }]
    };
  });
