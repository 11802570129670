'use strict';

angular.module('gmpApp')
  .controller('AddMatchingRuleCTRL', function ($scope, $uibModalInstance, commonSVC, workSVC) {
    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = (files) => {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
		 * 엑셀 업로드
		 * */
    $scope.upload = () => {
      if ($scope.fileData) {
        const params = {};

        workSVC.addExcelWork('UploadMatchingRuleExcel', params, $scope.fileData)
          .finally(() => {
            $uibModalInstance.close();
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');

        return false;
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    resetInput();
  });
