'use strict';

/**
 * WARNING 이거 안쓰는것같음. 삭제예정
 */

angular.module('gmpApp')
  .controller('OrderShipmentOrderCS', function ($scope, $rootScope, $uibModalInstance, data, settings, commonSVC, gettextCatalog, memoModel) {
    $scope.uniq = data.uniq;
    getOrderCs();

    /**
   * 주문 CS 등록
   * */
    $scope.orderCS = {};
    $scope.orderCS.contents = '';
    $scope.orderCS.suc_yn = false;
    $scope.user_profile = $rootScope.user_profile;
    $scope.submitCS = function() {
      $scope.orderCS.contents = $scope.orderCS.contents.replace(/\n/gi, '<br>');

      if ($scope.orderCS.contents == '') {
        commonSVC.showMessage(gettextCatalog.getString('내용을 적어주세요'));

        return false;
      }

      const data = $scope.orderCS;

      data.uniq = $scope.uniq;

      memoModel.add(data, function (state, data) {
        if (data.result == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('주문CS가 등록 되었습니다.'));
          $scope.orderCS.contents = '';
          getOrderCs();
        }
      });
    };

    /**
   * 주문 CS 삭제
   * */
    $scope.deleteCS = function(number) {
      const data = { number: [number] };

      commonSVC.showConfirm(gettextCatalog.getString('삭제하시겠습니까?'), '', function () {
        memoModel.delete(data, function (state, data) {
          if (data.result == 'success') {
            commonSVC.showToaster('success', '', gettextCatalog.getString('주문CS가 삭제 되었습니다.'));
            getOrderCs();
          }
        });
      });
    };

    /**
   * 주문 CS 불러오기
   * */
    function getOrderCs() {
      const data = {};

      data.uniq = $scope.uniq;
      memoModel.list(data, function (state, data) {
        if (state == 'success') {
          if (data.results != false) {
            $scope.orderCsList = _.mapValues(data.results, function(val, key) {
              return _.extend(val, { checked: false });
            });
          } else {
            $scope.orderCsList = [];
          }
        }
      });
    }

    // 모달 닫음
    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

  });
