'use strict';

// 락앤락몰
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-P056-Ctrl', function ($scope, $timeout, $ocLazyLoad, commonSVC, P056categorySVC, categoryModel, commonModel,templateModel) {
    $scope.sel_category = [];
    $scope.cate = [];
    $scope.totalCate = []; // 전체 카테고리
    $scope.CateData = angular.copy(P056categorySVC.category_data);
    $scope.cert_type = angular.copy(P056categorySVC.cert_type);
    $scope.max_depth = 4;
    $scope.category_names = ''; // 노출될 카테고리 분류
    $scope.requireAttr = ''; // 카테고리 속성 노출 안되게
    $scope.categories = {};

    for (let i = 0; i < $scope.max_depth; i += 1) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
      $scope.totalCate.push([]);
    }

    let save_flag = false;    // 카테고리 선택여부

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.pa5_job_data = {
      get_categories: {
        load: false,
        data: []
      }
    };

    $scope.delCate = function(key) {
      delete $scope.categories[key];
    };

    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;
      $scope.categories = { ...shop_cate_info.categories };

      await setCategory($scope.CateData.detailData.category_code);
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
          $scope.totalCate[i] = [];
        }

        loading('0', false);
        await run_pa5_jobs('get_categories', '카테고리', { code: cate.search_id }, (data) => {
          data.map((cate) => {
            $scope.totalCate[cate.depth - 1].push({
              $$hashkey: cate.$$hashkey,
              end_yn: cate.is_end,
              shop_cate_see_cd: cate.code,
              shop_cate_see_depth: cate.depth,
              shop_cate_see_name: cate.name,
              shop_cate_see_type: cate.type,
              parent_code: cate.parent_code,
              search_id: cate.etc.id,
            });
          });

          return data;
        });

        await new Promise(resolve => {
          $timeout(() => {
            $scope.cate[idx + 1] = $scope.totalCate[idx + 1];
            $scope.loading = true;
            resolve();
          });
        });
        loading('0', true);

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
          $scope.totalCate[i] = [];
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    $scope.addCate = function() {
      const category_code = $scope.sel_category.map(category => category.shop_cate_see_cd).filter(Boolean).join('_');
      if ($scope.categories[category_code]) {
        commonSVC.showMessage('이미 선택한 카테고리 입니다.');

        return false;
      }
      $scope.categories[category_code] = $scope.category_names;
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      const category_codes = Object.keys($scope.categories ? $scope.categories : {});
      const category_names = Object.values($scope.categories ? $scope.categories : {});

      if (category_codes.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      const category_name = category_codes.length > 1 ? `${$scope.categories[category_codes[0]]} 외 ${category_codes.length - 1}개` : $scope.categories[category_codes[0]];

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names_full = category_names.join(', ');
      $scope.CateData.detailData.category_names = category_name;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_codes.join(',');
      // 카테고리 리스트 저장
      $scope.CateData.detailData.categories = { ...$scope.categories };

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_codes.join(','),
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const init = async () => {
      loading('0', false);
      await run_pa5_jobs('get_categories', '카테고리', {}, (data) => {
        data.map((cate) => {
          $scope.totalCate[cate.depth - 1].push({
            $$hashkey: cate.$$hashkey,
            end_yn: cate.is_end,
            shop_cate_see_cd: cate.code,
            shop_cate_see_depth: cate.depth,
            shop_cate_see_name: cate.name,
            shop_cate_see_type: cate.type,
            parent_code: cate.parent_code,
            search_id: cate.etc.id,
          });
        });

        return data;
      });
      loading('0', true);

      $timeout(async () => {
        $scope.cate[0] = $scope.totalCate[0];

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(P056categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });

    };

    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    init();
  });