/**
 * 2018-01-24  sms template add
 * Daniel
 */
'use strict';
angular.module('gmpApp')
  .controller('ConfigsSmsTemplateAdd', function ($rootScope, $scope, $uibModalInstance, $timeout, $compile, commonSVC, settings) {
    $scope.temp_data = {
      name: '',
      data: {
        content: '',
      },
      ref: ''
    };

    // 참조값
    $scope.refData = {
      'order_name': '[주문자명]',
      'to_name': '[수령자명]',
      'to_addr': '[배송주소]',
      'shop_sale_name': '[상품명]',
      'shop_name': '[쇼핑몰명]',
      'seller_nick': '[쇼핑몰별칭]',
      'invoice_no': '[운송장번호]',
      'carr_name': '[택배사명]',
      'carr_name | invoice_no': '[택배사명 | 운송장번호]'
    };

    $scope.refChange = function() {
      $scope.temp_data.data.content = $scope.temp_data.data.content + $scope.refData[$scope.temp_data.ref];
      $scope.temp_data.ref = '';
    };

    /**
     * 저장
     */
    $scope.onSubmit = function () {
      if ($scope.form1.$valid) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/order/smsTemplate`, $scope.temp_data, function (state, re) {
          if (re.results[0] === 'SUCCESS') {
            commonSVC.showToaster('success', '성공', 'SMS 템플릿 저장에 성공하였습니다', '');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', 'SMS 템플릿 저장에 실패하였습니다', '');
          }
        });
      } else {
        commonSVC.showMessage('전부입력해주세요');
      }
    };

    /**
     * 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };
  });
