'use strict';

angular.module('gmpApp')
  .controller('SimpleModalCtrl', function ($scope, data, $uibModalInstance) {
    $scope.setData = { ...data };

    // 버튼클릭
    $scope.btn_click = function (clickType) {
      $uibModalInstance.close(clickType);
    };

    $scope.cancel = function () {
      if ($scope.setData.successCnt) {
        $uibModalInstance.close('cancel');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };
  });