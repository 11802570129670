'use strict';

angular.module('gmpApp')
  //========================================
  // 패스워드 유효성 검사 directive
  //========================================
  .directive('validPassword', function () {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ngModel) {
        scope.$watch(attrs.ngModel, function () {
          validate();
        });
        attrs.$observe('equals', function () {
          validate();
        });

        function validate() {
          const val1 = ngModel.$viewValue;
          const val2 = attrs.equals;

          ngModel.$setValidity('equals', !val1 || !val2 || val1 === val2);
        }
      }
    };
  })

//========================================
  // 휴대폰 인증 Modal controller
  //========================================
  .controller('SmsAuthCtrl', function ($scope, data, $timeout, $uibModalInstance, commonSVC, securityModel, gettextCatalog, $rootScope) {

    // 기본값처리
    $scope.phone = data;
    $scope.alerts = [];

    $scope.authType = ''; //인증타입
    $scope.auth_key = ''; //인증번호

    $scope.requestAuthFlag = false; // 휴대폰 문자 인증 요청 여부
    $scope.requestAuthIng = false;  // 여러번 전송막기위한 Flag
    $scope.fileReqFlag = false;     // 서류심사일 경우 필수 값 Flag

    // 취소 버튼 처리
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 휴대폰 문자 인증번호 발송
     */
    $scope.sendSms = async function() {
      $scope.requestAuthIng = true;
      try {
        const result = await securityModel.smsAuthGet($scope.phone);
        $timeout(() => {
          $scope.requestAuthIng = false;
          if (result.data !== 'SUCCESS') {
            $scope.alerts = [{ type: 'danger', msg: result.data }];
          } else {
            $scope.alerts = [];
            $scope.requestAuthFlag = true;
          }
        }, 500);
      } catch (error) {
        $scope.requestAuthIng = false;
        commonSVC.showToaster('error', '실패', !$rootScope.backend_user ? error.data.messages[0] : error.data.error);
        $scope.alerts = [{ type: 'danger', msg: !$rootScope.backend_user ? error.data.messages[0] : error.data.error }];
      }
    };

    /**
     * 인증번호 확인
     */
    $scope.onSubmit = function() {
      const params = {
        tel: $scope.phone,
        auth_key: $scope.auth_key
      };

      $scope.requestAuthIng = true;

      if (!$scope.auth_key) {
        commonSVC.showMessage(gettextCatalog.getString('인증번호를 입력해주세요.'));

        return false;
      }

      securityModel.smsAuthCheck(params, function(state, result) {
        if (state === 'success') {
          result = result.result;

          $scope.requestAuthIng = false;

          if (result == 'OK') {
            $uibModalInstance.close('success');
          } else {
            let msg = '';

            if (result == 'LIMIT') {
              // 시간 초과시
              msg = gettextCatalog.getString('인증시간이 초과되었습니다. 인증번호를 재요청하시기 바랍니다.');
            } else if (result == 'NOMATCH') {
              // 조회된게 없는 경우
              msg = gettextCatalog.getString('잘못된 인증번호입니다.');
            } else {
              // 그 외에 임시 처리
              msg = gettextCatalog.getString('실패 다시 한번 재시도하여 주시기 바랍니다.');
            }

            $scope.alerts = [{ type: 'danger', msg: msg }];
          }
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('SMS발신번호 인증에 실패하였습니다.'));
        }
      });
    };
  })

//========================================
  // SMS 발신번호 사전등록 Modal controller
  //========================================
  .controller('SmsDocumentationReqCtrl', function ($scope, $compile, $timeout, $uibModalInstance, data, commonSVC, securityModel, gettextCatalog) {

    // 기본값처리
    $scope.phone = '';
    $scope.alerts = [];

    $scope.data = [{
      tel_type: 'nationwideNum', // 발신번호 타입; nationwideNum: 전국 대표 번호, generalNum: 일반번호
      tel: [], // 발신번호
      file1: '', // 통신서비스 이용 증명원
      file2: '', // 사업자 등록증
      returnTel: [] // 인증결과 문자 알림 휴대폰 번호
    }];

    $scope.authType = ''; //인증타입

    $scope.requestAuthFlag = false; // 휴대폰 문자 인증 요청 여부
    $scope.requestAuthIng = false;  // 여러번 전송막기위한 Flag
    $scope.fileReqFlag = false;     // 서류심사일 경우 필수 값 Flag

    // 취소 버튼 처리
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 서류심사 정보 등록
     */
    $scope.onSubmit = function() {
      const params = [];
      const duplicatedTelList = _.filter($scope.data, function(row) {
        return _.includes(data.tel_list, row.tel.join('-'));
      });

      if ($scope.smsDocForm.$error.maxSize) {
        commonSVC.showMessage('5MB 이하의 파일만 등록 가능합니다.');

        return false;
      }

      if (duplicatedTelList.length) {
        commonSVC.showMessage(`이미 존재하는 번호입니다 (번호: ${duplicatedTelList[0].tel.join('-')})`);

        return false;
      }

      _.forEach($scope.data, function(data, idx) {
        const obj = {
          from_tel: data.tel.join('-'),
          auth_type: '서류',
          auth_file1: document.getElementById(`file${idx}_1`).files[0],
          auth_file2: document.getElementById(`file${idx}_2`).files[0],
          result_to_tel: data.returnTel.join('-')
        };

        params.push(obj);
      });

      securityModel.smsAuthAdd(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('서류심사 요청에 성공하였습니다.'));
          $timeout(function() {
            $uibModalInstance.close('success');
          }, 200);
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('서류심사 요청에 실패하였습니다.'));
        }
      });
    };

    $scope.sameReturnTel = function(idx) {
      const tel0 = $scope.data[0].returnTel[0],
            tel1 = $scope.data[0].returnTel[1],
            tel2 = $scope.data[0].returnTel[2];

      $scope.data[idx].returnTel[0] = $scope.data[idx].sameReturnTelChecked ? tel0 : '';
      $scope.data[idx].returnTel[1] = $scope.data[idx].sameReturnTelChecked ? tel1 : '';
      $scope.data[idx].returnTel[2] = $scope.data[idx].sameReturnTelChecked ? tel2 : '';
    };

    $scope.formIdx = 0; // 입력란 인덱스
    /**
		 * 입력란 추가하기
		 */
    $scope.addForm = function() {
      const preForm = `form${$scope.formIdx++}`;
      const newForm = `<table class="table modal-table mb-20" id="form${$scope.formIdx}">`
				+ '<colgroup>'
					+ '<col width="3%">'
					+ '<col width="20%">'
					+ '<col width="77%">'
				+ '</colgroup>'
				+ '<tbody>'
				+ '<tr>'
					+ `<th rowspan="4">${$scope.formIdx + 1}</th>`
					+ '<th>발신번호</th>'
					+ '<td>'
						+ '<div class="radio-inline">'
							+ `<label><input type="radio" class="" ng-model="data[${$scope.formIdx}].tel_type" value="nationwideNum"> 전국 대표 번호</label>`
						+ '</div>'
						+ '<div class="radio-inline">'
							+ `<label><input type="radio" class="" ng-model="data[${$scope.formIdx}].tel_type" value="generalNum"> 일반번호</label>`
						+ '</div>'
						+ '<div class="row">'
							+ `<div class="col-xs-auto no-padding-right"><input type="text" class="form-control input-xs" ng-model="data[${$scope.formIdx}].tel[0]" style="width: 90px;" max-bytes="4" ptgui-regex-mask="onlyNum" required></div>`
							+ '<div class="col-xs-auto pt-8">-</div>'
							+ `<div class="col-xs-auto no-padding"><input type="text" class="form-control input-xs" ng-model="data[${$scope.formIdx}].tel[1]" style="width: 90px;" max-bytes="4" ptgui-regex-mask="onlyNum" required></div>`
							+ `<div class="col-xs-auto pt-8" ng-if="data[${$scope.formIdx}].tel_type == 'generalNum'">-</div>`
							+ `<div class="col-xs-auto no-padding" ng-if="data[${$scope.formIdx}].tel_type == 'generalNum'"><input type="text" class="form-control input-xs" ng-model="data[${$scope.formIdx}].tel[2]" style="width: 90px;" max-bytes="4" ptgui-regex-mask="onlyNum" required></div>`
						+ '</div>'
						+ `<div class="help-block" ng-if="data[${$scope.formIdx}].tel_type == 'nationwideNum'">지역번호를 제외한 나머지 번호를 입력하세요</div>`
					+ '</td>'
				+ '</tr>'
				+ '<tr>'
					+ '<th>통신서비스 <br> 이용 증명원 첨부</th>'
					+ '<td>'
						+ `<input type="file" files-input id="file${$scope.formIdx}_1" ng-model="data[${$scope.formIdx}].file1" ngf-accept="'image/*'" ngf-pattern="'*.jpg,*.jpeg,*.gif,*.png'" ngf-max-size="5MB" ngf-select class="form-control input-file" name="file${$scope.formIdx}_1" required>`
						+ `<div class="validation-error-label" ng-show="smsDocForm.file${$scope.formIdx}_1.$error.maxSize">5MB이하의 파일만 첨부가능합니다. </div>`
						+ '<div class="help-block">5MB 이하의 파일만 등록 가능합니다.</div>'
					+ '</td>'
				+ '</tr>'
				+ '<tr>'
					+ '<th>사업자 등록증</th>'
					+ '<td>'
						+ `<input type="file" files-input id="file${$scope.formIdx}_2" ng-model="data[${$scope.formIdx}].file2" ngf-accept="'image/*'" ngf-pattern="'*.jpg,*.jpeg,*.gif,*.png'" ngf-max-size="5MB" ngf-select class="form-control input-file" name="file${$scope.formIdx}_2" required>`
						+ `<div class="validation-error-label" ng-show="smsDocForm.file${$scope.formIdx}_2.$error.maxSize">5MB이하의 파일만 첨부가능합니다. </div>`
						+ '<div class="help-block">5MB 이하의 파일만 등록 가능합니다.</div>'
					+ '</td>'
				+ '</tr>'
				+ '<tr>'
					+ '<th>인증 결과 문자 알림 <br> 휴대폰 번호</th>'
					+ '<td>'
						+ '<div class="row">'
							+ `<div class="col-xs-auto no-padding-right"><input type="text" class="form-control" ng-model="data[${$scope.formIdx}].returnTel[0]" max-bytes="3" ptgui-regex-mask="onlyNum" style="width: 60px;" required></div>`
							+ '<div class="col-xs-auto no-padding pl-5 pr-5 pt-8">-</div>'
							+ `<div class="col-xs-auto no-padding"><input type="text" class="form-control" ng-model="data[${$scope.formIdx}].returnTel[1]" max-bytes="4" ptgui-regex-mask="onlyNum" style="width: 60px;" required></div>`
							+ '<div class="col-xs-auto no-padding pl-5 pr-5 pt-8">-</div>'
							+ `<div class="col-xs-auto no-padding"><input type="text" class="form-control" ng-model="data[${$scope.formIdx}].returnTel[2]" max-bytes="4" ptgui-regex-mask="onlyNum" style="width: 60px;" required></div> <label class="checkbox-inline pr-10 ml-10" uib-tooltip="1번에 입력한 번호가 입력 됩니다" tooltip-placement="bottom"><input type="checkbox" ng-model="data[${$scope.formIdx}].sameReturnTelChecked" ng-change="sameReturnTel(${$scope.formIdx})"><translate>상동</translate></label>`
						+ '</div>'
					+ '</td>'
				+ '</tr>'
				+ '</tbody>'
				+ '</table>';

      $scope.data.push({
        tel_type: 'nationwideNum',
        tel: [],
        file2: '',
        file1: '',
        returnTel: []
      });

      $compile($(newForm).insertAfter($(`#${preForm}`)))($scope);
    };

    /**
     * 입력란 제거하기
     */
    $scope.deleteForm = function() {
      $(`#form${$scope.formIdx}`).remove();
      $scope.data.pop();
      $scope.formIdx--;
    };
  });