'use strict';

angular.module('gmpApp')
  .controller('simpleGuideCtrl', function ($scope, $rootScope, $uibModalInstance, simpleGuideSVC, localStorageService, data) {

    $scope.guideList = simpleGuideSVC.guideLink;
    $scope.simpleGuideNotShow = localStorageService.get('simpleGuideNotShow');

    /**
     * 창 닫기
     */
    $scope.close = function () {
      localStorageService.set('simpleGuideNotShow', $scope.simpleGuideNotShow);

      if (data.isAuto) {
        $rootScope.showNoticeModals();
      }

      $uibModalInstance.dismiss('cancel');
    };

    $scope.openGuide = (link) => {
      window.open(`https://youtu.be/${link}`, '_blank');
    };
  });