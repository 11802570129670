'use strict';

angular.module('gmpApp')
  .controller('AltalkTemplateCtrl', function($scope, $uibModalInstance, data, commonSVC, commonModel, $timeout, $rootScope, settings, atalkModel) {
    function init () {
      $timeout(async () => {
        $scope.template = {
          type: 'basic',
        };
        $scope.template.use_add_channel_yn = false;
        $scope.btnGroup = [];
        $scope.btnType = '';
        $scope.plusIdList = data.plusIdList || [];

        if ($scope.plusIdList.length) {
          $scope.plusIdList = $scope.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
          $scope.plusIdCheck = true;
          $scope.template.plus_friend_id = $scope.plusIdList[0].id;
        } else {
          $scope.plusIdCheck = false;
        }
        $scope.variableList = ((await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/variableList`)).data.result).filter(variable => variable.type === '주문');

        $scope.top_emphasis_type = 'NONE';
        $scope.disabledCheck = true;
        $timeout(() => {});
      });
    }

    init();

    $scope.close = () => {
      $uibModalInstance.dismiss({ re: 'cancel' });
    };

    /**
     * 미리보기 적용
     */
    $scope.textPreview = () => {
      const dummyVariableMatch = {
        '#{쇼핑몰명}': '플레이오토',
        '#{주문자명}': '김플토',
        '#{수령자명}': '김플토',
        '#{배송주소}': '(12345) 서울특별시 구로구 디지털로26길 123 지플러스타워 플레이오토',
        '#{상품명}': '푹신푹신 이불',
        '#{운송장번호}': '123456789',
        '#{택배사명}': '우체국택배',
        '#{금액}': '10,000',
        '#{주문번호}': '1234567890',
        '#{발송예정일}': '2000-00-00',
        '#{주문일}': '2000-00-00',
        '#{SKU코드}': 'POKSIN-256',
        '#{SKU상품명}': '정말 폭신한 이불',
      };

      const templateContent = $('#template-content-box').val();
      const previewBox = document.getElementById('preview_box');

      // 정규 표현식을 사용하여 모든 dummyVariableMatch의 키값을 찾아서 대체
      const replacedContent = templateContent.replace(/#{[^}]+}/g, match => dummyVariableMatch[match] || match);

      previewBox.innerHTML = replacedContent.split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');

      $scope.checkInputs();
    };

    /**
     * 부가 정보 미리보기
     */
    $scope.textPreview_add_info = () => {
      document.getElementById('preview_add_box').innerHTML = $('#template-add-content-box').val() ? $('#template-add-content-box').val().split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('') : '<p>문의전화: 02-1234-4567</p>';
    };

    /**
     * 부가 정보 여부 변경 시 동작 함수
     */
    $scope.addInfoChange = () => {
      if (!$scope.template.add_info_yn) {
        document.getElementById('preview_add_box').innerHTML = '';
        $scope.template.add_content = '';
      } else {
        document.getElementById('preview_add_box').innerHTML = '<p>문의전화: 02-1234-4567</p>';
      }
    };

    /**
     * 강조 유형 설정
     */
    $scope.setEmphasisType = (type) => {
      if (type === 'TEXT') {
        document.getElementById('emphasis_phrase').innerHTML = '보조문구';
        document.getElementById('emphasis_title').innerHTML = '강조제목';
      }
    };

    /**
     * 강조 제목 미리보기
     */
    $scope.previewEmphasisTitle = () => {
      document.getElementById('emphasis_title').innerHTML = $('#emphasis_title_box').val();
    };

    /**
     * 보조 문구 미리보기
     */
    $scope.previewEmphasisPhrase = () => {
      document.getElementById('emphasis_phrase').innerHTML = $('#emphasis_phrase_box').val();
    };

    /**
     * 강조 이미지 업로드
     */
    $scope.previewEmphasisImg = (row) => {
      $scope.file = row;
      const preview = document.getElementById('emphasis_img');
      const imageSrc = URL.createObjectURL(row);
      const currentStyle = preview.getAttribute('style') || '';
      preview.setAttribute('style', `${currentStyle} background-image: url('${imageSrc}'); background-size: 100%;`);
      $timeout(() => {});
    };
    /**
     * 변수 적용
     */
    $scope.insertVar = (varName) => {
      const elm = $('#template-content-box');
      const idx = elm[0].selectionStart;

      elm[0].value = elm[0].value.slice(0, idx) + `#{${varName}}` + elm[0].value.slice(idx);
      elm.focus()[0].setSelectionRange(idx + varName.length + 3, idx + varName.length + 3);
      elm.trigger('change');

      $scope.textPreview();
    };

    /**
     * 필수항목 체크
     */
    $scope.checkInputs = () => {
      if ($scope.template?.name && $scope.template?.plus_friend_id && $scope.template?.content) {
        $scope.disabledCheck = false;
      } else {
        $scope.disabledCheck = true;
      }
    };

    /**
     * 알림톡 배송조회 지원 택배사 알럿으로 노출
     */
    $scope.alertSupportCarrList = () => {
      commonSVC.showMessageHtml('지원 택배사',
        `로젠택배, 우체국택배, KGB택배, 일양로지스, 한진택배<br/>
           천일택배, 롯데택배, 농협택배, 경동택배, 합동택배<br/>
           GTX로지스, Fedex, 호남택배, 대신택배, 건영택배<br/>
           CU 편의점택배, CVSnet편의점택배, TNT Express, USPS<br/>
           EMS, DHL, 대신택배, 한덱스`);
    };

    /**
     * 템플릿 형태 변경 시 작성한 추가 정보 초기화 함수
     */
    $scope.clearAddInfo = () => {
      // 버튼 정보 초기화
      $scope.btnGroup = [];
      $scope.template.emphasis_phrase = '';
      $scope.template.emphasis_title = '';
      $scope.top_emphasis_type = 'NONE';
      $scope.template.use_add_channel_yn = false;
      $scope.template.add_content = '';
      $scope.template.add_info_yn = '';
    };

    /**
     * 버튼 추가
     */
    $scope.addBtn = () => {
      if ($scope.btnGroup.length === 5) {
        commonSVC.showMessage('버튼의 수는 최대 5개입니다.');

        return false;
      }
      $scope.btnGroup.push({ name: '', type: '', url_mobile: '', url_pc: '' });
    };

    /**
     * 채널 추가 사용
     */
    $scope.useChannelAdd = () => {
      if (!$scope.template.use_add_channel_yn) {
        $scope.deleteBtn(0);
      } else {
        $scope.btnGroup.unshift({ name: '채널 추가', type: 'AC', url_mobile: '', url_pc: '' });
      }
    };

    /**
     * 버튼 삭제
     */
    $scope.deleteBtn = (idx) => {
      if ($scope.btnGroup[idx].type === 'AC') {
        $scope.template.use_add_channel_yn = false;
      }
      $scope.btnGroup.splice(idx, 1);
    };

    $scope.setBtnType = (idx, v) => {
      $scope.btnGroup[idx].type = v;

      if (v === 'DS') {
        $scope.btnGroup[idx].name = '배송 조회하기';
      } else {
        $scope.btnGroup[idx].name = '';
      }
    };

    function handleResult(result, status) {
      if (result.data.result === 'success') {
        const message = status === 'TEMP' ? '임시저장 성공' : '승인 요청 완료';
        const additionalMessage = status !== 'TEMP' ? '템플릿 승인은 업무일 기준 2~3일 소요됩니다.' : '';
        commonSVC.showMessage(message, additionalMessage);
        $uibModalInstance.close('success');
      } else if (result.data?.error) {
        handleError(result.data.error);
      }
    }

    function handleError(error, status) {
      if (error.includes('이미 등록된 템플릿명입니다')) {
        commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '동일한 템플릿 명이 있습니다.');
      } else if (error.includes('Duplicate Friend Error')) {
        commonSVC.showMessage('실패', '플러스친구 ID에 등록된 동일 템플릿 명이 존재합니다. 다른 템플릿 명을 입력해 주세요.');
      } else if (error === '글자수가 1,000자를 초과합니다.') {
        return commonSVC.showMessage('승인 요청 실패', '템플릿 내용, 부가정보 내용의 총 글자수 합이 1,000자 초과하였습니다.');
      } else {
        commonSVC.showMessage('실패', error);
      }
    }

    $scope.onSubmit = async (status) => {
      let reqCheck = true;     // 부가정보, 버튼, 강조 유형 데이터 유효성 체크 변수
      let template_message_type;

      // MI(복합형) - 부가정보(필수), 채널추가
      if (($scope.template.add_info_yn && $scope.template.add_content)
        && ($scope.template.use_add_channel_yn && $scope.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'MI';
      // EX(부가정보형) - 부가 정보 필수
      } else if (($scope.template.add_info_yn && $scope.template.add_content)
        && !($scope.template.use_add_channel_yn && $scope.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'EX';
      // AD(채널추가형) - 채널추가 버튼 필수
      } else if (!($scope.template.add_info_yn && $scope.template.add_content)
        && ($scope.template.use_add_channel_yn && $scope.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'AD';
      // BA(기본형) - 부가정보, 채널추가 없는 템플릿
      } else {
        template_message_type = 'BA';
      }

      const data = {
        type: $scope.template.type,
        name: $scope.template.name,
        plus_friend_id: $scope.template.plus_friend_id || '1',
        content: $scope.template.content,
        buttons: $scope.btnGroup ? $scope.btnGroup : [],
        user_name: $rootScope.user_profile.user_name,
        status,
        sol_no: $rootScope.user_profile.sol_no,
        template_type: template_message_type,
        template_emphasize_type: $scope.top_emphasis_type,
        ...($scope.template.add_info_yn && { template_extra: $scope.template.add_content }),
        template_sub_title: $scope.template.emphasis_phrase,
        template_title: $scope.template.emphasis_title,
        template_img: $scope.file
      };

      // 상단 영역 강조 유형이 선택안함인 경우 강조 유형 데이터 없으면 유효성 에러
      if ($scope.top_emphasis_type === 'TEXT') {
        if (!($scope.template.emphasis_title && $scope.template.emphasis_phrase)) {
          reqCheck = false;
        }
      }
      if ($scope.top_emphasis_type === 'IMAGE') {
        if (!$scope.file) {
          reqCheck = false;
        }
      }
      if ($scope.template.type === 'complex' && ($scope.template.add_info_yn && !$scope.template.add_content)) {
        reqCheck = false;
      }

      if ($scope.btnGroup.length) {
        for (const btn of $scope.btnGroup) {
          if (!['AC', 'DS'].includes(btn.type) && (!btn.url_mobile && !btn.url_pc && !btn.name)) {
            reqCheck = false;

            break;
          }
        }
      }

      if (!reqCheck) {
        return commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '버튼명, 이미지 첨부, 강조형 문구 선택 시 해당 항목 입력은 필수값입니다.');
      }

      try {
        let result;
        const isComplexTemplate = $scope.template.type === 'complex';
        const hasValidAddInfo = isComplexTemplate && (($scope.template.add_info_yn && $scope.template.add_content) || ($scope.template.use_add_channel_yn && $scope.btnGroup.some(btn => btn.type === 'AC')));

        if ($scope.file) {
          data.template_img = $scope.file;
        }

        if (isComplexTemplate && !hasValidAddInfo) {
          commonSVC.showConfirm('부가정보, 채널 추가 두 항목 미 입력 시 기본형 형태로 템플릿이 저장됩니다.', '', async (isConfirm) => {
            if (!isConfirm) { return; }

            result = await atalkModel.templateAdd(data, $scope.file);
            handleResult(result, status);
          });
        } else {
          result = await atalkModel.templateAdd(data, $scope.file);
          handleResult(result, status);
        }

      } catch (err) {
        if (err.data?.error.includes('이미 등록된 템플릿명입니다')) {
          return commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '동일한 템플릿 명이 있습니다.');
        } else if (err.data?.error.includes('Duplicate Friend Error')) {
          return commonSVC.showMessage('실패', '플러스친구 ID에 등록된 동일 템플릿 명이 존재합니다. 다른 템플릿 명을 입력해 주세요.');
        } else if (err.data?.error === '글자수가 1,000자를 초과합니다.') {
          return commonSVC.showMessage('승인 요청 실패', '템플릿 내용, 부가정보 내용의 총 글자수 합이 1,000자 초과하였습니다.');
        }

        return commonSVC.showMessage('실패', err.data?.error);
      }

    };
  });