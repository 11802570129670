'use strict';

/**
 * Created by ally on 2018. 5. 16..
 */

angular.module('gmpApp')
  .controller('OrderPackageCombineCtrl', function ($uibModalInstance, $scope, data, $rootScope, commonSVC, shipmentModel) {

    $scope.packageList = data.data.map(ord => {
      return {
        ...ord,
        carr_name: $rootScope.deliveryInfoList.find(carr => ord.carr_no === carr.carr_no)?.carr_name
      }; }
    );
    $scope.select = { idx: 0 };

    $scope.ok = async () => {
      await shipmentModel.combinePackage({ pa_bundle_codes: $scope.packageList.map(shop => shop.pa_bundle_no), main_bundle_no: $scope.packageList[$scope.select.idx].pa_bundle_no }, (state, data) => {
        if (data === 'success') {
          commonSVC.showToaster('success', '성공', '패키지 합치기에 성공하였습니다.');

          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '패키지 합치기에 실패하였습니다.');
          $uibModalInstance.close('error');
        }
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });

