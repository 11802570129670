'use strict';

angular.module('gmpApp')
  .service('logExcelSVC', function () {
    /**
     * 2018-07-16 Noah
     * 주문 엑셀 다운로드 로그
     */

    return {
      /**
       * 엑셀 모달창 필드 리스트
       */
      excelFieldList: [
        { header: 'NO.', key: 'no', select: true },
        { header: '날짜', key: 'wdate', select: true },
        { header: '상세', key: 'note', select: true },
        { header: '작업자', key: 'wm_name', select: true },
        { header: '접속IP', key: 'ip', select: true },
        { header: '사유', key: 'reason', select: true },
      ],

      userConnectExcelFieldList: [
        { header: '접속일', key: 'wdate', select: true },
        { header: '이메일', key: 'email', select: true },
        { header: '이름', key: 'name', select: true },
        { header: '접속IP', key: 'ip', select: true },
        { header: '접속서비스', key: 'service', select: true },
      ]
    };
  });
