'use strict';

angular.module('gmpApp')
  .controller('NotSaleDashboardCtrl', function ($rootScope, $scope, data, settings, $uibModalInstance, commonSVC, productModel, inventoryModel, inventorySVC, gettextCatalog) {
    $scope.prodData = data.prodData;
    $scope.divideTypeData = {
      2: {
        notsale_typeKor: '불량',
        notsale_type_cnt: 0
      },
      3: {
        notsale_typeKor: '파손',
        notsale_type_cnt: 0
      },
      4: {
        notsale_typeKor: '판매기한 경과',
        notsale_type_cnt: 0
      },
      5: {
        notsale_typeKor: '판매기한 임박',
        notsale_type_cnt: 0
      },
      6: {
        notsale_typeKor: '폐기 대상',
        notsale_type_cnt: 0
      },
      999: {
        notsale_typeKor: '기타',
        notsale_type_cnt: 0
      }
    };
    init();

    function init() {
      $scope.prodData.notsale_type.forEach(type => {
        for (const [key, value] of Object.entries(type)) {
          $scope.divideTypeData[key].notsale_type_cnt = value;
        }
      });
      $scope.divideTypeData = Object.values($scope.divideTypeData);
    }
    $scope.ok = function() {
      $uibModalInstance.close();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
