'use strict';

angular.module('gmpApp')
  .controller('MemoListConfirm', function ($scope, data, $uibModalInstance) {
    $scope.status = data.status;
    $scope.memo_cnt = data.cnt;

    $scope.ok = function() {
      $uibModalInstance.close();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });