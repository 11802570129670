export const module = {};

/**
 * Jquery 함수 추가
 * 인풋에 빨간색 에러 박스 남기며,
 * 인풋의 값이 변화하거나, 키보드를 입력하면 빨간색 에러 박스를 제거
 */
jQuery.fn.extend({
  emitInputError() {
    this.addClass('empty-input-box');
    const err_msg = $('<label id="-error" class="validation-error-label2" for="">필수 항목입니다.</label>');
    this.parent().append(err_msg);
    this.one('change', () => {
      this.removeInputError(err_msg);
    });
    this.one('keydown', () => {
      this.removeInputError(err_msg);
    });
  },
  removeInputError(err_msg) {
    this.removeClass('empty-input-box');
    err_msg.remove();
  },
});
