/**
 * 부가서비스 사용내역 그리드 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('TrnslGridCtrl', function ($scope, commonSVC, paySVC, workSVC, onlineProductSVC, settings, gettextCatalog) {
    const search = $scope.search.translate;

    // 검색영역 폼
    $scope.searchData = search.searchData;
    $scope.searchForm = search.searchForm;

    // 데이터 테이블 관련기능
    search.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      },
      resetDo: function() {
        $scope.resetDo();
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.search[$scope.gridType].searchForm = angular.copy(paySVC.searchForm_trnsl);
      $scope.search[$scope.gridType].searchDetail = angular.copy(paySVC.searchDetail_trnsl);

      $scope.searchDo(true, true);
    };

    search.searchBtn = angular.copy($scope.searchBtn);
    $scope.searchBtn.table_actions = [
      {
        label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
        btn_type: 'dropdown',
        add_class: 'btn-default',
        item_list: [
          {
            label: gettextCatalog.getString('전체엑셀 다운로드'),
            action: function () {
              $scope.excelDown('all');
            }
          },
          {
            label: gettextCatalog.getString('선택엑셀 다운로드'),
            action: function () {
              $scope.excelDown('select');
            }
          }
        ]
      }
    ];

    /**
     * 번역카운트 사용내역 DataTable 설정
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['wdate', 'email', 'item', 'src_lang_cd', 'tar_lang_cd', 'source_char_length', 'free_yn'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['source_char_length', 'before_content', 'after_content'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/translate-list`,
        requestWillAction: function (data) {
          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(Object.assign(data, search.searchForm));

          return data;
        },
        requestDidAction: function(result) {
          search.searchData.totalCount = result.recordsTotal;
          $scope.rowTotalCount = result.rowsTotal;
          $scope.result = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '번역일',
          width: 80,
          template: (row) => {
            const date = moment(row.wdate).format('YYYY-MM-DD HH:mm:ss');

            return date != 'Invalid date' ? date : '-';
          }
        },
        {
          key: 'email',
          title: '사용자 ID',
          width: 100
        },
        {
          key: 'item',
          title: '항목',
          width: 40
        },
        {
          key: 'src_lang_cd',
          title: '원본언어',
          width: 40
        },
        {
          key: 'tar_lang_cd',
          title: '번역언어',
          width: 40
        },
        {
          key: 'source_char_length',
          title: '글자수',
          width: 40,
          template: (row) => { return `${row.source_char_length} 자`; }
        },
        {
          key: 'before_content',
          title: '원본 내용',
          width: 200
        },
        {
          key: 'after_content',
          title: '번역 내용',
          width: 200
        },
        {
          key: 'free_yn',
          title: '무료여부',
          width: 30,
          template: (row) => { return row.free_yn ? '무료' : '유료'; }
        }
      ]
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length(search.searchData.showCount);
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh = true, noDelay) {
      $scope.viewPayStatus = ['환불신청', '처리중', '환불완료', '환불거부'];
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 부가서비스(메세징) 발송내역 모달
     */
    $scope.msgListView = function (send_type, tran_pr, wdate) {
      const msgListData = {
        send_type: send_type,
        tran_pr: tran_pr,
        wdate: wdate
      };

      commonSVC.openModal('md', { data: msgListData }, 'MsgListCtrl', 'views/pay/modals/msg_list.html');
    };

    /**
     * 부가서비스(이메일) 발송내역 모달
     */
    $scope.emailListView = function (send_type, tran_pr) {
      const emailListData = {
        send_type,
        tran_pr: +tran_pr
      };
      commonSVC.openModal('md', { data: emailListData }, 'EmailListCtrl', 'views/pay/modals/email_list.html');
    };

    // 부가서비스(sms) 발송 요청 상태 동기화
    $scope.syncSmsState = async () => {
      if (!(
        await commonSVC.showConfirm('주의', 'SMS 발송 요청 상태 동기화는 작업으로 추가 됩니다.\n작업관리 > 솔루션 작업 탭에서 확인 하실 수 있으며,\n하루에 한번 새벽에 자동으로 실행됩니다.')
      )) {
        return;
      }

      await workSVC.addSolWork('SyncSmsState');
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {

      const resolve = {};
      resolve.data = {
        type: type,
        excelFieldList: angular.copy(paySVC.translateExcelFieldList),
        title: '번역카운트 사용내역',
        url: '/app/pay/excel-down/translate',
        searchForm: $scope.pageData
      };

      if (type === 'all') {
        resolve.data.count = search.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const trnslList = $scope.grid.methods.selectedData('all');
        resolve.data.select_list = trnslList;
        resolve.data.count = trnslList.length;
        resolve.data.isAll = false;
      }

      if (resolve.data.count === 0) {
        commonSVC.showMessage(gettextCatalog.getString('다운로드할 사용내역이 없습니다.'));

        return false;
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 환불거부사유 출력
     */
    $scope.rejectReason = function (str) {
      commonSVC.showMessage(gettextCatalog.getString(str));

      return false;
    };
  });