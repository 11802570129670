/**
 * Created by ally on 2017. 4. 6..
 */

'use strict';

angular.module('gmpApp')
  .directive('countryPicker', function() {
    return {
      templateUrl: 'views/tpls/countryPicker.html',
      restrict: 'E',
      replace: true,
      require: 'ngModel',
      scope: {
        pickerDisable: '=',
        id: '@'
      },
      link: function ($scope) {

        $(`.selectpicker#${$scope.id}`).selectpicker();

        // ngModel.$render = function() {
        //   // if(ngModel.$viewValue){
        //   //   $('.selectpicker#'+ $scope.id).val(ngModel.$viewValue);
        //   //   $('.selectpicker#'+ $scope.id).prop('disabled', $scope.pickerDisable);
        //   // }
        //
        //   if(ngModel.$viewValue === undefined){
        //     ngModel.$viewValue = 'JP';
        //   }
        //
        //   $('.selectpicker#'+ $scope.id).val(ngModel.$viewValue);
        //   $('.selectpicker#'+ $scope.id).prop('disabled', $scope.pickerDisable);
        //
        //   $('.selectpicker#'+ $scope.id).selectpicker('render');
        // };
        //
        // $scope.$watch('pickerDisable',function (nv,ov) {
        //   if(nv !== ov){
        //     $('.selectpicker#'+ $scope.id).prop('disabled', nv);
        //     $('.selectpicker#'+ $scope.id).selectpicker('refresh');
        //   }
        // });
      }
    };
  });
