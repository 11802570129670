'use strict';

angular.module('gmpApp')
  .controller('CalculateExcelUploadCtrl', function ($scope, $rootScope, data, $uibModalInstance, commonSVC, workSVC, userInfo) {
    $scope.param = {
      shop: '',
    };
    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'ScrapPayBack', all_use_yn: true });

    // 사용중인 쇼핑몰, 아이디 가져오기
    $scope.mall = userInfo.user.manualSettShops.filter(manualSettShop => {
      const matchedShop = channelList.find(item =>
        item.use_yn === 0 && item.shop_cd === manualSettShop.shop_cd && item.shop_id === manualSettShop.shop_id && !_.startsWith(item.pa_shop_cd, 'X')
      );

      if (matchedShop) {
        manualSettShop.view_id = (data.shop_id_view_type === 'nickname') ? matchedShop.seller_nick : matchedShop.shop_id;
        manualSettShop.view_id = manualSettShop.view_id ? `(${manualSettShop.view_id})` : '';

        return true;
      } else {
        return false;
      }
    });

    $scope.selectedMall = '';
    $scope.data = {
      overwrite: false // 이미 등록된 내역 있을 경우 정보 덮어쓰기 여부
    };

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    /**
     * 엑셀 양식 추가/수정 (직접입력 쇼핑몰)
     */
    $scope.setExcelField = shop => {
      const resolve = {
        data: {
          mode: shop.sett_excel_field_cdn ? 'edit' : 'add',
          shop_cd: shop.shop_cd,
          shop_id: shop.shop_id,
          shop_name: shop.shop_name,
          sett_uniq_criteria: shop.sett_uniq_criteria,
          sett_excel_field_cdn: shop.sett_excel_field_cdn,
        }
      };
      const modal = commonSVC.openModal('xg', resolve, 'CustomCalculateExcelTemplateCtrl', 'views/calculate/modals/custom_calculate_excel_template.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
		 * 엑셀 업로드
		 * */
    $scope.upload = function () {
      if (!$scope.selectedMall) {
        commonSVC.showMessage('업로드 실패 안내', '쇼핑몰 선택 후 업로드해주세요.');

        return false;
      }

      if (Array.isArray($scope.fileData) && !$scope.fileData.length) {
        commonSVC.showMessage('업로드 실패 안내', '첨부된 엑셀 파일이 없습니다.\n초록색 영역을 클릭하여 파일 첨부 또는 해당 영역에 파일을 끌어다 놓으세요.');

        return false;
      }

      if (!$scope.selectedMall.sett_excel_field_cdn) {
        commonSVC.showMessage('업로드 실패 안내', '[엑셀 양식 추가] 버튼을 눌러 양식을 추가해 주세요.');

        return false;
      }

      const params = {
        shop: `${$scope.selectedMall.shop_cd}|${$scope.selectedMall.shop_id}|${$scope.selectedMall.shop_name}`,
        overwrite: $scope.data.overwrite
      };

      workSVC.addExcelWork('UploadShopSettExcel', params, $scope.fileData)
        .finally(function () {
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    resetInput();
  });
