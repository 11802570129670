/**
 *  로그인 이후 로더
 */

'use strict';
angular.module('gmpApp.settings',
  [
    'static.settings',
    'LocalStorageModule',
    'ngCookies'
  ])

// 셋팅 서비스
  .service('SettingsService', function ($http, settings) {
    return {

      // 로그인 유저 정보
      getInfo: function () {
        return $http.get(`${settings.pa20ApiUrl}/app/users/info`, {});
      },

      // 택배사 정보
      getCarrier: function () {
        return $http.get(`${settings.pa20ApiUrl}/app/settings/carriers`, {});
      },

      // 사용 가능 채널 정보
      getShopInfo: function () {
        return $http.get(`${settings.pa20ApiUrl}/app/shop/shops`, {});
      },

      // 정기점검 여부
      getMaintenanceInfo: function () {
        return $http.get(`${settings.pa20ApiUrl}/app/common/maintenance`, {});
      },

      // 전체 택배사 정보
      loadDeliveryInfoList: function (isAll) {
        return $http.get(`${settings.pa20ApiUrl}/app/settings/delivery/info?isAll=${isAll}`);
      },

      // 국가정보
      getCountryList: function () {
        return $http.get(`${settings.pa20ApiUrl}/app/common/countries`, {});
      },

      // 커스텀칼럼 조회
      getAddColumnList: function () {
        return $http.post(`${settings.pa20ApiUrl}/app/order/column/list`, { addcol_type: 'all' });
      },

      // 제휴처 리스트 조회
      getAffInfo: () => {
        return $http.get(`${settings.pa20ApiUrl}/app/settings/aff-info`, {});
      },
    };
  })

  // REST API 400 에러 핸들러
  .factory('gmpHttpErrorHandler', function($q, $location, $rootScope, $log, localStorageService) {
    const logout = () => {
      localStorageService.remove('token');
      localStorageService.remove('wakeUpToken');
      localStorageService.remove('startWakeUpYN');
      localStorageService.remove('mas_contract_yn');
      location.replace('/login.html');
    };

    return {
      responseError: function(rejection) {
        $log.debug(rejection);

        // 400 에러이면서 jwt 만료 오류는 에러코드 포함해서 오류 처리 2017-01-20 MatthewKim
        if (rejection.status == 400) {

          // 토큰 없음
          if (['Authorization Header Not Set', 'Invalid Authorization Header'].includes(rejection.data.error) ||
            rejection.data?.messages?.[0].includes('헤더에 인증토큰이 누락')
          ) {
            location.href = `${'/login' + '.html?e=301&p='}${$location.$$path}`;

            return;
          }
          else if (['DUPLICATE_LOGIN'].includes(rejection.data.error)) {
            if ($('.sweet-alert').length) {
              $('.sweet-alert').remove();
              $('.sweet-overlay').remove();
            }

            swal({
              title: '다른 환경에서 이미 접속 중 이므로 로그아웃 처리됩니다.',
              text: '',
              confirmButtonColor: '#5C90D2',
              confirmButtonText: '확인'
            }, logout);
          }
          else if (['PASSWORD_UPDATED'].includes(rejection.data.error)) {
            if ($('.sweet-alert').length) {
              $('.sweet-alert').remove();
              $('.sweet-overlay').remove();
            }

            swal({
              title: '비밀번호가 변경되어 로그아웃 처리됩니다.',
              text: '',
              confirmButtonColor: '#5C90D2',
              confirmButtonText: '확인'
            }, logout);
          }
          // 505 : 2017-02-09 MatthewKim 권한 없음 (permission)
          else if (rejection.data.error === 'you don\'t have right permission.') {
            // 권한 오류 이벤트 발생 시킴 2017-02-10 MatthewKim
            $rootScope.$emit('$stateChangePermissionDenied', '', '', rejection.data.error);
          }
          // 기타 모두는 로그인 페이지로 이동
          else {
            // 2017-01-25 chris
            // 백앤드에서 에러 콜백시( next(err) )에도 로그인페이지로 이동하여 일단 제외
            // location.href = '/login' + '.html?p=' + $location.$$path;
          }

          if (
            rejection.data.error == 'jwt expired' ||
            rejection.data?.messages?.[0].includes('토큰의 유효기간이 만료되었습니다.')
          ) {
            // 2018-08-10 chris 로그인 토큰 만료시 로그인 모달 오픈
            if ($rootScope.lockScreen) {
              $rootScope.lockScreen('jwt_expired');

              return $q.reject(rejection);
            } else {
              location.href = `${'/login' + '.html?e=302&p='}${$location.$$path}`;

              return;
            }
          }

          if (
            rejection.data.error == 'invalid signature' ||
            rejection.data?.messages?.[0].includes('인증토큰 정보가 올바르지 않습니다.')
          ) {
            location.href = `${'/login' + '.html?e=302&p='}${$location.$$path}`;

            return;
          }

          if (typeof rejection.data.error == 'string' && rejection.data.error.indexOf('wrong database') >= 0) {
            location.href = `${'/login' + '.html?e=303&p='}${$location.$$path}`;

            return;
          }

        }
        // 502 Bad Gateway : 2017-01-20 MatthewKim node.js 데몬이 죽은 상태로 서버 오류 표시 해야함
        else if (rejection.status == 502) {
          location.href = `${'/login' + '.html?e=502p='}${$location.$$path}`;

          return;
        }

        // 여기까지 오면 리젝 처리
        return $q.reject(rejection);

      }
    };
  })

  // 상기 핸들러 주입
  .config(['$httpProvider', function($httpProvider) {

    // 2017-05-19 MatthewKim 모든 AJAX 요청에 에러 핸들러 추가
    $httpProvider.interceptors.push('gmpHttpErrorHandler');

    // 2017-05-19 MatthewKim
    /**
     * 간단히 요약하면 $http 서비스에서는 HTTP request의 응답이 올 때에 $digest를 호출
     * 이 속성을 켜면 동시에 많은 HTTP request를 보낼 때에 묶어서 $digest를 1번만 보내게 하므로 $digest 호출 수를 줄여서 성능 개선
     */
    $httpProvider.useApplyAsync(true);

  }])

  // REST API 헤더에 로그인 토큰 장착 시키는 전역 설정
  .run(['$http', 'localStorageService', '$cookies', '$log', function($http, localStorageService, $cookies, settings) {

    // 17-04-03 ally 쿠키가 있으면 대체
    // 2017-06-13 MatthewKim www 웹사이트 등에서 넘어올때 사용하게 됨
    const cookies = $cookies.get('token');

    if (cookies) {

      // 2017-06-13 MatthewKim 쿠키 검증 해서 올바른 토큰 쿠기 일때만 사용
      // 검증 없이 사용하니 아무값이든 있기만 하면 쓰게 되서 영구 로그인 불가 사태 발생
      if (cookies.indexOf('Token ') >= 0) {
        localStorageService.set('token', cookies);
      }

      // 한번 사용한 쿠키는 쓰던 안쓰던 제거
      $cookies.remove('token', { domain: '.hirogete.com' });

    }

    // -- 여기까지
    const tk = localStorageService.get('token');

    if (tk) {
      $http.defaults.headers.common.Authorization = tk;
    }

    // ---------------------------------------------------------------------

  }]);

// gmpApp.settings 모듈을 gmpApp 에서 사용할 수 있도록 반영 시키는 작업
window.angularInitLoader.init({
  element: document,
  module: 'gmpApp',
  injectorModules: 'gmpApp.settings',
  resolve: {
    // 각 채널 정보
    siteInfo: ['SettingsService', function (SettingsService) {
      return SettingsService.getShopInfo();
    }],

    // 각 배송사 정보
    carrierInfo: ['SettingsService', function (SettingsService) {
      return SettingsService.getCarrier();
    }],

    // 정기점검 정보
    maintenanceInfo: ['SettingsService', function (SettingsService) {
      return SettingsService.getMaintenanceInfo()
        .then(function (result) {
          return result.data;
        });
    }],

    // 커스텀 칼럼 조회
    addColumnList: ['SettingsService', function (SettingsService) {
      return SettingsService.getAddColumnList()
        .then(function (result) {
          return {
            data: result
          };
        });
    }],

    // 로그인 유저 정보
    userInfo: ['SettingsService', '$q', '$window', function (SettingsService, $q, $window) {
      return SettingsService.getInfo('')
        .then(function (re) {
          const result = {};
          const userData = re.data;

          // $log.debug(userData);
          // 유저 정보 정리
          try {
            const shopVersionCnt = (userData.availableShopAccountCntByVersion.find(o => o.prod_name.toUpperCase() === userData.sol_version.toUpperCase())).versionCnt;
            const userVersionCnt = (userData.availableUserAccountCntByVersion.find(o => o.prod_name.toUpperCase() === userData.sol_version.toUpperCase())).versionCnt;
            const dataLayer = $window.dataLayer = $window.dataLayer || [];

            // 유저 정보
            const userinfo = {
              domain: userData.domain,
              pa_sol_no: userData.pa_sol_no,
              sol_no: userData.sol_no,
              account_type_prefix: userData.sol_type,
              sol_type: userData.sol_type,
              sol_sub_type: userData.sol_sub_type,
              sol_version: userData.sol_version,
              sol_country: userData.ctry_cd,
              sol_stock: userData.sol_stock,
              sol_sdate: userData.sol_sdate,
              sol_edate: userData.sol_edate,
              sol_ser: userData.sol_ser ? userData.sol_ser.split(',') : [],
              search_date: userData.search_date,
              user_wdate: userData.wdate,
              user_hash: userData.user_hash,
              user_id: userData.m_no,
              user_name: userData.name,
              user_grade: userData.m_type,
              company_name: userData.company_name,
              remote_ip: userData.remote_ip,
              m_no: userData.m_no,
              user_m_no: userData.mas_m_no,
              depot_no: userData.depot_no,
              default_template_group_no: userData.default_template_group_no,
              c_no: userData.c_no,
              email: userData.email,
              tel: userData.tel,
              htel: userData.htel,
              biz_reg_no: userData.biz_reg_no,
              zipcd: userData.zipcd,
              addr1: userData.addr1,
              addr2: userData.addr2,
              email_yn: userData.email_yn,
              sms_yn: userData.sms_yn,
              auth_type: userData.auth_type,
              c_tel: userData.c_tel,
              ebaydepot_yn: userData.ebaydepot_yn,            // 배송처 ebay 전용 배송처인지 여부 (자체배송인 경우 0)
              adminMode: userData.adminMode,                  // 2019-09-18 liam
              charged_yn: !!userData.first_pay_no,            // 첫결제 청구서 번호
              auto_map_yn: userData.auto_map_yn,              // 자동매칭 여부
              availableShopAccountCntByVersion: userData.availableShopAccountCntByVersion,
              availableUserAccountCntByVersion: userData.availableUserAccountCntByVersion,
              availableOrdCntByVersion: userData.availableOrdCntByVersion,
              availableOlCntByVersion: userData.availableOlCntByVersion,
              sol_expired: userData.sol_expired,
              shopVersionCnt,                                     // 사용중인 버전의 최대 쇼핑몰 개수
              userVersionCnt,                                     // 사용중인 버전의 최대 사용자 개수
              shopAccountCnt: userData.shopAccountCnt,            // 현재 등록된 쇼핑몰 개수
              userAccountCnt: userData.userAccountCnt,            // 현재 등록된 사용자 개수
              superUserAccountCnt: userData.superUserAccountCnt,  // 현재 등록된 총괄관리자 개수
              solAccountCnt: userData.solAccountCnt,              // 현재 사용중인 솔루션 개수
              shopAccount_exceeded: shopVersionCnt !== '무제한' && userData.shopAccountCnt > Number(shopVersionCnt),
              shopAccount_exceeded_zero: userData.sol_version === 'ZERO' && userData.shopAccountCnt > 2, // front 에서만 확인하는 정보
              userAccount_exceeded: userVersionCnt !== '무제한' && userData.userAccountCnt > Number(userVersionCnt),
              aff_no: userData.aff_no,
              manualOrderShops: userData.manualOrderShops,
              manualSettShops: userData.manualSettShops,
              auto_gift_new_yn: userData.auto_gift_new_yn, // (신)사은품 규칙 자동적용여부
              barcode_out_conf: userData.barcode_out_conf, // 바코드 출고매니저 설정
              report_time: userData.report_time,
              report_out_yn: userData.report_out_yn,
              first_pay_no: userData.first_pay_no,
              etc: userData.etc,
              test_aply_time: userData.test_aply_time,      // 운영계정 여부
              emailCnt: userData.emailCnt,
              mas_contract_yn: userData.mas_contract_yn,    // 위수탁계약 동의 여부
              sales_name: userData.sales_name || '',        // 영업담당자
              ordManage_info: userData.ordManage_info,      // 발주 관리 서비스 사용 정보
              first_visit_time: userData.first_visit_time,  // 첫 방문 시간
              delShopLogYn: userData.delShopLogYn,          // 쇼핑몰 삭제이력 여부
              first_add_shop_cds: userData.first_add_shop_cds, // 최초접속자 선택 쇼핑몰 리스트
              agency_c_no: userData.agency_c_no,            // API 대행사 코드
              agency_name: userData.agency_name,            // API 대행사 이름
              last_pay_method: userData.last_pay_method,    // 최근 결제수단
            };

            // GA4 dataLayer user_id 추가
            dataLayer.push({
              user_id: String(userinfo.m_no)
            });
            // 결과 정리

            // 솔루션 계정의 사용가능한 모듈 2017-02-07 MatthewKim
            result.modules = userData.module_permission || '';

            // 로그인 유저의 모든 권한
            result.roles_arr = userData.roles_arr || [];

            // 사용자 정보
            result.user = userinfo;

            // 채널톡 연동 정보
            result.channelIOData = userData.channelIOData;

            // 마지막 주문 동기화 시간
            result.last_order_sync = userData.last_order_sync;

          } catch (e) {
            console.error(e);
          }

          // 추가권한 2019-01-02 rony
          result.permission = userData.permission ? userData.permission : {};

          // 에러가 나도 리턴
          return $q(function(resolve) {
            resolve(result);
          });

        });
    }],
    // 사용 택배사 리스트
    deliveryInfoList: ['SettingsService', function (SettingsService) {
      return SettingsService.loadDeliveryInfoList(false)
        .then(function (result) {
          return result.data?.results;
        });
    }],
    // 전체
    deliveryInfoListAll: ['SettingsService', function (SettingsService) {
      return SettingsService.loadDeliveryInfoList(true)
        .then(function (result) {
          return result.data?.results;
        });
    }],
    // 전체 국가 리스트
    countryList: ['SettingsService', function (SettingsService) {
      return SettingsService.getCountryList()
        .then(function (result) {
          return result.data;
        });
    }],

    // 제휴처 정보
    affList: ['SettingsService', (SettingsService) => {
      return SettingsService.getAffInfo();
    }],
  }
});
