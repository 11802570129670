/**
 * 주문수량분할
 * 2019-02-09 Jacob
 */

'use strict';

angular.module('gmpApp')
  .controller('DivideOrderCTRL', function ($scope, data, $uibModalInstance, shipmentModel, commonSVC, warehouseList, systemList) {
    $scope.ordList = data.ordList;   // 수량분할 리스트
    $scope.addOptList = {};
    $scope.showAddOptList = {};            // 추가구매옵션 탭 활성화 여부
    // $scope.isOrdAll = false;               // 주문 전체선택 여부
    $scope.isAddOptAll = false;            // 추가구매옵션 전체선택 여부
    $scope.count = {};                      // 테이블 no 를 위한 카운트
    $scope.orderShow = false;              // 단계적 절차를 따르기 위해 ng-show에 추가되는 부분
    $scope.addOptShow = false;             // 단계적 절차를 따르기 위해 ng-show에 추가되는 부분
    $scope.referer = data.referer;         // 어느 페이지에서 호출되었는지 여부

    // step2 활성화
    $scope.step2 = false;

    // 주문 상세보기
    $scope.orderDetail = function (uniq) {
      const resolve = {
        data: {
          fromPage: 'order',
          uniq: uniq,
          warehouseList: warehouseList.data.result || [],
          systemList: systemList.data || []
        }
      };
      const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    // 수량 입력시 분할수량 체크
    $scope.checkCnt = function (obj, type) {
      obj.disabled1 = parseInt(obj.divideCnt.trim() === '' ? 0 : obj.divideCnt) > (type === 'ord' ? obj.sale_cnt : obj.opt_sale_cnt);
      obj.disabled2 = parseInt(obj.divideCnt.trim() === '' ? 0 : obj.divideCnt) < 2;
    };

    // 수량 입력시 합 계산
    $scope.sumCnt = function (obj) {
      obj.sum = _.sumBy(obj.dividedList, function (cnt) {

        return parseInt(cnt === '' ? 0 : cnt);
      });

      return obj;
    };

    // 스텝 2가 열릴지 판단.
    $scope.checkStep2 = function (type, index, val) {
      let flag = false;
      let ordFlag = false;
      let addOptFlag = false;

      if (!val) {
        if (type === 'ord') {
          $scope.ordList[index].divideCnt = '';
          $scope.ordList[index].dividedList = [];
          $scope.ordList[index].divided = false;
        } else {
          $scope.addOptList[type][index].divided = false;
          $scope.addOptList[type][index].dividedList = [];
          $scope.addOptList[type][index].divideCnt = '';
        }
      }

      ordFlag = $scope.ordList.some(ord => ord.divideYN && ord.divided);

      $scope.ordList.forEach(ord => {
        flag = ($scope.addOptList[ord.uniq]?.length && $scope.addOptList[ord.uniq].some(addOpt => (addOpt.divided && addOpt.divideYN)) || (ord.divideYN && ord.divided));
        addOptFlag = $scope.addOptList[ord.uniq]?.length && $scope.addOptList[ord.uniq].some(addOpt => addOpt.divided && addOpt.divideYN);
      });

      $scope.step2 = flag;
      $scope.orderShow = ordFlag;
      $scope.addOptShow = addOptFlag;
    };

    $scope.toggleOpt = function (uniq, flag) {    // 전체선택 처리
      _.forEach($scope.addOptList[uniq], function (addOpt) {
        addOpt.divideYN = addOpt.opt_sale_cnt === 1 ? false : flag;
      });

      if (!flag) {
        $scope.divideAddOptYn = flag;
      }
    };

    // 주문 수량 균등 분할
    $scope.equalDivide = function (ord, type) {
      if (type === 'ord') {
        const quotient = Math.floor(ord.sale_cnt / ord.dividedList.length);
        const remainder = ord.sale_cnt % ord.dividedList.length;

        ord.dividedList = ord.dividedList.map(() => quotient);

        if (remainder) {
          ord.dividedList[ord.dividedList.length - 1] += remainder;
        }

        $scope.sumCnt(ord);
      } else {
        $scope.addOptList[ord.uniq].filter(opt => opt.divideYN).forEach(opt => {
          const quotient = Math.floor(opt.opt_sale_cnt / opt.dividedList.length);
          const remainder = opt.opt_sale_cnt % opt.dividedList.length;

          opt.dividedList = opt.dividedList.map(() => quotient);

          if (remainder) {
            opt.dividedList[opt.dividedList.length - 1] += remainder;
          }

          $scope.sumCnt(opt);
        });
      }
    };

    // 주문 수량 분할
    $scope.divideOrder = function () {
      if ($scope.ordList.filter(ord => ord.disabled1).length) {
        commonSVC.showMessage('실패', '나누는 수가 주문 수량보다 같거나 작아야 합니다.');

        return false;
      }
      if ($scope.ordList.filter(ord => ord.disabled2).length) {
        commonSVC.showMessage('실패', '분할 수량은 2 이상의 수이어야 합니다.');

        return false;
      }

      $scope.ordList.forEach(ord => {
        if ($scope.addOptList[ord.uniq]?.filter(opt => opt.disabled1)?.length) {
          commonSVC.showMessage('실패', '나누는 수가 주문 수량보다 같거나 작아야 합니다.');

          return false;
        }
        if ($scope.addOptList[ord.uniq]?.filter(opt => opt.disabled2)?.length) {
          commonSVC.showMessage('실패', '분할 수량은 2 이상의 수이어야 합니다.');

          return false;
        }

        let count = 1;

        // 추가구매옵션 처리
        $scope.addOptList[ord.uniq]?.filter(opt => opt.divideYN)?.forEach(opt => {
          if (!opt.divideCnt?.trim()) {
            opt.divideYN = false;

            return false;
          }
          const divideCnt = parseInt(opt.divideCnt.trim() === '' ? 0 : opt.divideCnt);

          opt.divided = true;

          if (!opt.dividedList) {
            opt.dividedList = [];
          }

          const length = opt.dividedList.length;

          // 숫자 변경 후 다시 분할 클릭했을 때 개수 처리
          if (divideCnt > length) {
            for (let idx = 0; idx < divideCnt - length; idx++) {
              opt.dividedList.push('');
            }
          } else {
            opt.dividedList.splice(divideCnt);
          }

          // 주문수량과 분할값이 동일하면 수량 기본값 1로 자동 출력
          if (opt.opt_sale_cnt === divideCnt) {
            opt.dividedList = opt.dividedList.map(() => '1');
          }

          if (opt.dividedList) {
            opt.noCount = [];
            while (opt.noCount.length !== opt.dividedList.length) {
              opt.noCount.push(count);
              count++;
            }
          }

          $scope.step2 = true;
          $scope.addOptShow = true;
          $scope.divideAddOptYn = true;

          $scope.sumCnt(opt);
        });

        if (!ord.divideCnt.trim()) {
          ord.divideYN = false;

          return false;
        }

        $scope.count[ord.uniq] = parseInt(ord.divideCnt.trim() === '' ? 0 : ord.divideCnt);
        const length = ord.dividedList.length;
        if ($scope.count[ord.uniq] > length) {                 // 분할수 숫자만 변경후 분할 다시 클릭했을 때 처리
          for (let idx = 0; idx < $scope.count[ord.uniq] - length; idx++) {
            ord.dividedList.push('');
          }
        } else {
          ord.dividedList.splice($scope.count[ord.uniq]);
        }

        // 주문수량과 분할값이 동일하면 수량 기본값 1로 자동 출력
        if (ord.sale_cnt === $scope.count[ord.uniq]) {
          ord.dividedList = ord.dividedList.map(() => '1');
        }

        ord.divided = true;

        $scope.step2 = true;
        $scope.orderShow = true;

        $scope.sumCnt(ord);
      });

      const divideYN = $scope.ordList.some(ord => {
        return ord.divideYN || ($scope.addOptList[ord.uniq]?.length && $scope.addOptList[ord.uniq].some(opt => opt.divideYN));
      });

      if (!divideYN) {
        commonSVC.showMessage('실패', '분할 수량을 입력해주세요.');
      }
    };

    $scope.save = _.throttle(async function () {                      // 최종저장

      const ordList = {};
      const addOptList = {};
      let flag = true;
      let ordChange = true;
      let addOptChange = true;
      let vieErrwMsg = '';

      _.forEach($scope.ordList, function (ord) {
        if (ord.divideYN && ord.divided) { // 수량체크

          if (ord.sum !== ord.sale_cnt) {
            vieErrwMsg = '나누어진 주문들 수량의 합이 원 주문의 수량과 일치하지 않습니다.';
            flag = false;

            return false;
          }

          if (_.filter(ord.dividedList, function(o) { return o === '' || o === '0'; }).length) {
            vieErrwMsg = '수량을 1개 이상 입력해주십시오.';
            flag = false;

            return false;
          }

          ordList[ord.uniq] = ord.dividedList;
        }

        if (ord.divideYN && !ord.divided) {
          ordChange = false;
        }
      });

      if (!flag) {
        commonSVC.showMessage('실패', vieErrwMsg);

        return false;
      }

      _.forEach($scope.addOptList, function (addOpts, uniq) {
        _.forEach(addOpts, function (addOpt, index) { // 수량 체크
          if (addOpt.divideYN && addOpt.divided) {
            if (addOpt.sum !== addOpt.opt_sale_cnt) {
              vieErrwMsg = '나누어진 추가구매옵션 수량의 합이 추가구매옵션 원본의 수량과 일치하지 않습니다.';
              flag = false;

              return false;
            }

            if (_.filter(addOpt.dividedList, function(o) { return o === '' || o === '0'; }).length) {
              vieErrwMsg = '수량을 1개 이상 입력해주십시오.';
              flag = false;

              return false;
            }

            if (!addOptList[uniq]) {
              addOptList[uniq] = [];
            }

            addOptList[uniq][index] = addOpt.dividedList;
          }

          if (addOpt.divideYN && !addOpt.divided) {
            addOptChange = false;
          }
        });

        if (!flag) {
          return false;
        }
      });

      if (!ordChange || !addOptChange || (_.isEqual(addOptList, {}) && _.isEqual(ordList, {}))) {
        commonSVC.showMessage('실패', '변경사항이 없거나 제대로 입력되지 않았으니 확인하여 주시기 바랍니다.');

        return false;
      }

      if (flag) {
        try {
          const confirm = await commonSVC.showConfirm2('분할', '정말로 주문을 분할하시겠습니까? ');
          if (confirm) {
            const result = await shipmentModel.divideOrder({ ordList: ordList, addOptList: addOptList });

            if (!result) {
              return false;
            } else if (result.data.result === 'success') {
              commonSVC.showToaster('success', '성공', '주문수량분할에 성공했습니다.');
              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '실패', '주문수량분할에 실패하였습니다.');

              return false;
            }
          } else {
            return false;
          }
        } catch (error) {
          commonSVC.showToaster('error', '실패', error.data.messages[0] || '주문수량분할에 실패하였습니다.');

          return false;
        }
      } else {
        commonSVC.showMessage('실패', vieErrwMsg);

        return false;
      }

    }, 500);

    $scope.showAddOpt = function (uniq) {
      $scope.showAddOptList[uniq] = !$scope.showAddOptList[uniq];
      $scope.isAddOptAll = true;
      $scope.toggleOpt(uniq, $scope.isAddOptAll);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    async function init() {
      // 추가구매옵션 불러오기. (order_list에서는 set정보가 넘어오지 않아서다시...)
      const re = await shipmentModel.getAddOptList({ uniq: _.map($scope.ordList, 'uniq') });

      if (re.data.results.length) {
        $scope.addOptList = _.groupBy(re.data.results, 'uniq');
      }

      _.forEach($scope.ordList, function (ord) {  // 초기화
        ord.divided = false;  //나눠진 여부
        ord.divideYN = ord.sale_cnt !== 1; //나눌 여부
        ord.divideCnt = '';   //분할 수량
        ord.dividedList = []; //수량만큼의 배열 (각 개수)
        ord.disabled1 = false; // 분할 수량 초과 플래그
        ord.disabled2 = false; // 분할 수량 2 이하 플래그
        $scope.showAddOptList[ord.uniq] = ord.sale_cnt === 1;

        if (ord.sale_cnt === 1) {
          $scope.isAddOptAll = true;
          $scope.toggleOpt(ord.uniq, $scope.isAddOptAll);

          $scope.addOptList[ord.uniq].forEach(opt => {
            opt.divideYN = opt.opt_sale_cnt !== 1;
            opt.disabled1 = false;
            opt.disabled2 = false;
          });
        }
      });
    }

    init();
  });
