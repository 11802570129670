/**
 * Created by MatthewKim on 2017. 2. 10
 *
 * angular-permission에 permission 디렉티브를 커스텀한 짧은 이름의 perm 디렉티브를 확장 하는 모듈
 *
 * 추가한 기능 및 수정 한 사항
 *
 * - 기본은 권한 없을 때 해당 엘리먼트를 숨김
 *
 * - perm-disabled="true" attr 을 추가하여서 권한이 없을때 비활성화 시킴
 *   비활성화 할때 단순히 disabled 만 추가하지 않으며, 하위의 모든 a, button, input 태그에도 링크 기능을 무력화 함
 *
 * - perm-only-start-with="" attr 을 추가하여서 특정 키워드로 시작하는 권한은 모두 OK 해줄수 있음
 */

(function (window, angular, undefined) {
  'use strict';
  PermDirective.$inject = ['$log', '$injector', 'PermPermissionMap', 'PermPermStrategies'];
  let permission = angular.module('permission');

  if (typeof module !== 'undefined' && typeof exports !== 'undefined' && module.exports === exports) {
    module.exports = permission.name;
  }

  let PermPermStrategies = function($rootScope) {
    return {
      enableElement: function ($element) {

        // 자체 엘리먼트 살리기
        $element
          .removeAttr('disabled')
          .css('cursor', 'pointer')
          .removeClass('disabled')
          .removeClass('ng-disabled');

        // 하위의 모든 a , button , input 살리기
        // 그냥 두면 됨

      },
      disableElement: function ($element) {

        // 자체 엘리먼트 무력화
        $element
          // disabled 자체는 넣지 않는다.
          // 못쓰는것처럼 보이게만 한다. 클릭 시 권한 거부 기능 실행키 위함
          //.attr('disabled', 'disabled')
          .css('cursor', 'not-allowed')
          .addClass('disabled')
          // 기존 클릭 기능을 무력화 하고
          .off('click')
          // 권한 오류 클릭 기능을 대신 넣는다.
          .on('click', function() {
            $rootScope.$emit('$stateChangePermissionDenied', '', '', $element.attr('perm-only'));
          });

        // 하위의 모든 click 무력화 하고 권한 거부 이벤트 발생으로 변경
        angular.forEach($element.children(), function (childElem) {
          if (childElem.outerHTML.indexOf('click') > -1) {
            angular.element(childElem).off('click');
            angular.element(childElem).on('click', function () {
              $rootScope.$emit('$stateChangePermissionDenied', '', '', $element.attr('perm-only'));
            });
          }
        });

      },
      showElement: function ($element) {
        $element.removeClass('ng-hide');
      },
      hideElement: function ($element) {
        $element.addClass('ng-hide');
      }
    };
  };

  angular
    .module('permission')
    .factory('PermPermStrategies', PermPermStrategies);

  function PermDirective($log, $injector, PermPermissionMap, PermPermStrategies) {
    'ngInject';

    return {
      restrict: 'A',
      bindToController: {
        sref: '=?permSref',
        only: '=?permOnly',
        except: '=?permExcept',
        disabled: '=?permDisabled', // 2017-02-10 matthew
        onlyStartWith: '=?permOnlyStartWith', // 2017-02-10 matthew
        onAuthorized: '&?permOnAuthorized',
        onUnauthorized: '&?permOnUnauthorized'
      },
      controllerAs: 'perm',
      controller: ['$scope', '$element', function ($scope, $element) {
        let perm = this;

        $scope.$watchGroup(['perm.only', 'perm.except', 'perm.sref', 'perm.onlyStartWith'],
          function () {
            try {
              if (isSrefStateDefined()) {
                let PermStateAuthorization = $injector.get('PermStateAuthorization');

                PermStateAuthorization
                  .authorizeByStateName(perm.sref)
                  .then(function () {
                    onAuthorizedAccess();
                  })
                  .catch(function () {
                    onUnauthorizedAccess();
                  });
              }

              // 2017-02-10 matthew
              // 특정 퍼미션 문자열로 시작되는 경우 허용할 법칙 (상위 메뉴 표시 등을 위해)
              else if (perm.onlyStartWith) {

                let PermPermissionStore = $injector.get('PermPermissionStore');
                let perms = _.keys(PermPermissionStore.getStore());
                // $log.debug(perms);

                if (_.findIndex(perms, function(o) {
                  // onlyStartWith가 배열로 된 경우, 갖고 있는 모든 퍼미션중 입력한 문자열로 시작하는 퍼미션이 1개라도 있으면
                  if (_.isArray(perm.onlyStartWith)) {
                    return (_.findIndex(perm.onlyStartWith, function(onlyStartOne) {
                      return _.startsWith(o, onlyStartOne);
                    }) >= 0);
                  }
                  // 문자열로 된 경우 바로 검사
                  else {
                    return _.startsWith(o, perm.onlyStartWith);
                  }
                }) >= 0) {
                  onAuthorizedAccess();
                }
                else {
                  onUnauthorizedAccess();
                }
              }

              // 기타 only / except 퍼미션 처리
              else {

                let PermAuthorization = $injector.get('PermAuthorization');
                let permissionMap = new PermPermissionMap({
                  only: perm.only,
                  except: perm.except
                });

                PermAuthorization
                  .authorizeByPermissionMap(permissionMap)
                  .then(function () {
                    onAuthorizedAccess();
                  })
                  .catch(function () {
                    // $log.debug(permissionMap);
                    // $log.debug("FAILED");
                    onUnauthorizedAccess();
                  });
              }
            } catch (e) {
              onUnauthorizedAccess();
              // $log.error(e.message);
            }
          });

        function isSrefStateDefined() {
          return $injector.has('$state') && perm.sref;
        }

        function onAuthorizedAccess() {
          if (angular.isFunction(perm.onAuthorized)) {
            perm.onAuthorized()($element);
          } else {
            if (perm.disabled == true)
              {PermPermStrategies.enableElement($element);}
            else
              {PermPermStrategies.showElement($element);}
          }
        }

        function onUnauthorizedAccess() {
          if (angular.isFunction(perm.onUnauthorized)) {
            perm.onUnauthorized()($element);
          } else {
            if (perm.disabled == true)
              {PermPermStrategies.disableElement($element);}
            else
              {PermPermStrategies.hideElement($element);}
          }
        }
      }]
    };
  }

  angular
    .module('permission')
    .directive('perm', PermDirective);

}(window, window.angular));
