/**
 * Created by ally on 2017. 12. 5..
 */
'use strict';
angular.module('gmpApp')
  .controller('ProdProductCopyCtrl', function ($scope, $rootScope, $uibModalInstance, commonSVC, gettextCatalog, data, productModel) {
    const selectList = data.selectList;

    $scope.data = {
      model_no: '',
      sku_cd: '',
      input_model: false,
      modelnoOverlabChecked: false,
      model_overlab_txt: '',
      model_overlab_fail: false,
    };
    $scope.overlab_txt = '';
    $scope.overlab_fail = false;
    $scope.autoSku = true;
    $scope.select_count = selectList.length;

    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    // SKU 중복체크
    $scope.overlabCheck = function () {
      const sku = $scope.data.sku_cd.trim();

      if (!sku) {
        $scope.overlab_fail = true;
        $scope.overlab_txt = 'SKU코드를 입력해주시기 바랍니다.';
      } else {
        productModel.skuOverlabCheck({ code: sku }, function (state, result) {
          if (result.data.result) {
            $scope.overlab_fail = true;
            $scope.overlab_txt = '이미 등록된 SKU코드입니다.';
            $scope.isCheckingOverlab = false;
          } else {
            $scope.overlab_fail = false;
            $scope.overlab_txt = '등록 가능한 SKU코드입니다.';
            $scope.isCheckingOverlab = true;
          }
        });
      }
    };

    /**
     * 모델번호 중복 체크
     */
    $scope.modelnoOverlabCheck = async () => {
      const regx = new RegExp(/^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9-_.]*$/);
      const model_no = $scope.data.model_no;

      if (!model_no) {
        commonSVC.showMessage('모델번호를 입력해주세요.');

        return false;
      } else if (!regx.exec(model_no)) {
        commonSVC.showMessage('모델명 입력 오류', "모델번호는 한글/영문/숫자/특수문자('-' '_' '.')만 입력이 가능합니다.");

        return false;
      }

      const checkResult = await productModel.modelnoOverlabCheck({ prod_no: 0, model_no });

      if (checkResult.data.result) {
        commonSVC.showMessage('이미 등록된 모델번호 입니다.');
        $scope.data.modelnoOverlabChecked = false;

      } else {
        commonSVC.showMessage('등록 가능한 모델번호 입니다.');
        $scope.data.modelnoOverlabChecked = true;
      }
    };

    // sku_cd수정시 다시 중복체크 해야하기때문에
    $scope.changeSkuCode = () => {
      $scope.overlab_txt = '';
      $scope.isCheckingOverlab = false;
    };

    $scope.changeModelNo = () => {
      $scope.data.model_overlab_txt = '';
      $scope.data.model_overlab_fail = false;
      $scope.data.modelnoOverlabChecked = false;
    };

    $scope.copyDo = () => {
      if (!$scope.autoSku && !$scope.isCheckingOverlab) {
        $scope.overlab_fail = true;
        $scope.overlab_txt = 'SKU코드 중복확인을 하셔야 복사가 가능합니다.';

        return false;
      }

      if ($scope.viewModelNo && $scope.data.input_model && !$scope.data.modelnoOverlabChecked) {
        $scope.data.model_overlab_txt = '모델번호 중복확인을 하셔야 복사가 가능합니다.';
        $scope.data.model_overlab_fail = true;

        return false;
      }

      const param = {
        selectList: selectList,
        new_sku_cd: $scope.autoSku ? '' : $scope.data.sku_cd,
        new_model_no: $scope.data.input_model ? $scope.data.model_no : '',
      };

      productModel.copy(param, function (state, result) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('상품 복사 {{count}}건에 성공하였습니다', { count: selectList.length }));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('상품 복사에 실패하였습니다'));
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
