/**
 * neRepeat 종료 이벤트
 *
 * 2017-11-02 Roy
 */
'use strict';

angular.module('gmpApp')

  .directive('ngRepeatEndWatch', function () {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element, attrs) {
        if (attrs.ngRepeat) {
          if (scope.$parent.$last) {
            if (attrs.ngRepeatEndWatch !== '') {
              if (typeof scope.$parent.$parent[attrs.ngRepeatEndWatch] === 'function') {
                scope.$parent.$parent[attrs.ngRepeatEndWatch]();
              } else {
                scope.$parent.$parent[attrs.ngRepeatEndWatch] = true;
              }
            } else {
              scope.$parent.$parent.ngRepeatEnd = true;
            }
          }
        } else {
          throw 'ngRepeatEndWatch: `ngRepeat` Directive required to use this Directive';
        }
      }
    };
  });
