'use strict';

/**
 * 2017-12-21 Gargamel
 * 상품가져오기
 * */
angular.module('gmpApp')
  .controller('ProdProductScrapCtrl', function ($scope, $rootScope, $state, $uibModalInstance, $timeout, $q, workSVC, shopAccountModel, commonSVC, errorSVC, userInfo, data) {

    $scope.scrap_type = 'all';
    $scope.mall = [];
    $scope.select_mall;
    $scope.auto_mapping = 'no_matching';   // 상품자동매칭 등록 옵션
    $scope.viewDescription = true;
    $scope.checked = {
      scrap_date: _.cloneDeep(String(data.prod_scrap_date)),
      custom_sdate: moment(new Date()).subtract('1', 'M').format('YYYY-MM-DD'),
      custom_edate: moment(new Date()).format('YYYY-MM-DD'),
      channelSelectAll: true,
      selectedAll: false,
      auto_mapping: 'N',          // 자동매칭여부
      auto_regist: false          // 매칭되지않은 상품 일괄등록여부
    };

    $scope.canEsmMasterList = []; // ESM마스터상품 가능한 쇼핑몰 리스트
    $scope.esmMatchShops = []; // ESM마스터상품 수집 매칭한 쇼핑몰 리스트
    $scope.esmIdCheck = false;
    $scope.isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');   // 스마일배송 사용여부

    // 사용중인 쇼핑몰, 아이디 가져오기
    const init = () => {
      const temp_mall = [];
      const not_available_shop_cd = ['B083'];             //특정상품 가져오기 지원하지 않는 shop_cd
      const not_available_all = ['B700', 'P018', 'A538'];         //전체상품 가져오기 지원하지 않는 shop_cd
      // 엔진팀 계정일땐 모든 작업 가능하게
      const list = angular.copy($rootScope.useChannelList({ site_action: 'ScrapProd', func: shop => !!shop.prod_use_yn }));

      _.forEach(list, function(row) {
        if (not_available_shop_cd.indexOf(row.pa_shop_cd) > -1) {
          return true;
        }

        if (!['B502', 'B719', 'P020', 'A093', 'A092', 'A001', 'A006'].includes(row.pa_shop_cd)) {
          row.std_ol_yn = 0;
          temp_mall.push(_.cloneDeep(row));
        }

        if (row.std_ol_avail_yn == 1) {
          row.std_ol_yn = 1;
          temp_mall.push(_.cloneDeep(row));
        }

        // 물류배송 상품
        if ($scope.isSmile && ['A001', 'A006'].includes(row.pa_shop_cd)) {
          temp_mall.push({
            ..._.cloneDeep(row),
            std_ol_yn: 2
          });
        }
      });
      $scope.select_mall = temp_mall;
      $scope.mall = list.filter(({ pa_shop_cd }) => !not_available_all.includes(pa_shop_cd));
      $scope.canEsmMasterList = list.filter(shop => ['A001', 'A006'].includes(shop.shop_cd) && shop.etc7);

      if ($scope.canEsmMasterList.length) {
        Object.values(_.groupBy($scope.canEsmMasterList, 'etc7')).forEach(shopList => {
          if (shopList.some(shop => shop.shop_cd === 'A001') && shopList.some(shop => shop.shop_cd === 'A006')) {

            const esmMasterShop = {
              ...shopList.find(shop => shop.shop_cd === 'A006'),
              pa_shop_cd: 'ESMM',
              shop_cd: 'ESMM',
              shop_name: 'ESM마스터상품',
              shop_id: shopList[0].etc7,
              seller_nick: ''
            };

            $scope.mall.unshift(esmMasterShop);
            $scope.select_mall.unshift(esmMasterShop);

            if ($scope.isSmile) {
              $scope.select_mall.unshift({
                ...esmMasterShop,
                std_ol_yn: 2
              });
            }
          }
        });
      }
    };

    init();

    $scope.$watch('$viewContentLoaded', function () {
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: '쇼핑몰(계정)선택',
        closeOnSelect: false
      });

      // 쇼핑몰 선택 시 액션
      $('.reg-channel-select').on('change.select2', function () {
        let va = $(this).val() || [];
        const site_list = [];
        const esm_site_list = [];

        if (va[0] == 'all') {
          $scope.checked.channelSelectAll = false;
          va = [];
          for (let i = 0; i < $scope.select_mall.length; i++) {
            va.push(i);
          }
          $(this).val(va).trigger('change');
          $(this).select2('close');

          return;
        }

        if (va[0] == 'del') {
          $scope.checked.channelSelectAll = true;
          $scope.site_list = [];
          $scope.esmMatchShops = [];

          $('.reg-channel-select').val([]).trigger('change');
          $(this).select2('close');

          return;
        }

        _.forEach(va, function (v) {
          const idx = parseInt(v);
          const row = $scope.select_mall[idx];
          const siteIndex = _.findIndex($scope.site_list, { shop_cd: row.shop_cd, shop_id: row.shop_id, std_ol_yn: row.std_ol_yn });

          if (row.shop_cd === 'ESMM') {
            if (siteIndex < 0) {
              esm_site_list.push({
                gmarket: '',
                auction: '',
                esmMasterId: row.etc7
              });
            } else {
              esm_site_list.push($scope.esmMatchShops.find(shop => shop.esmMasterId === row.etc7));
            }
          }

          if (siteIndex < 0) {
            site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id, shop_name: row.shop_name, std_ol_yn: row.std_ol_yn });
          } else {
            site_list.push($scope.site_list[siteIndex]);
          }
        });

        $scope.site_list = site_list;
        $scope.esmMatchShops = esm_site_list;

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      });
    });

    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.mall, function (mall) {
          mall.selected = $scope.checked.selectedAll;
          mall.ol = $scope.checked.selectedAll;
          mall.std_ol = $scope.checked.selectedAll;
          mall.smile_ol = $scope.isSmile && ['ESMM', 'A001', 'A006'].includes(mall.pa_shop_cd) ? $scope.checked.selectedAll : false;
        });
      } else {
        $scope.mall[type].ol = $scope.mall[type].selected;
        $scope.mall[type].std_ol = $scope.mall[type].selected;
        $scope.mall[type].smile_ol = $scope.isSmile && ['ESMM', 'A001', 'A006'].includes($scope.mall[type].pa_shop_cd) ? $scope.mall[type].selected : false;

        if ($scope.checked.selectedAll && !($scope.mall[type].selected)) {
          $scope.checked.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.mall) {
            if (!$scope.mall[i].selected) {
              flag = false;

              break;
            }
          }
          $scope.checked.selectedAll = flag;
        }
      }
    };

    // ESM 마스터 상품 생성 조합 option 출력
    // 옥션 : 선택된 지마켓의 마스터 ID와 동일한 옥션 계정 리스트
    // 지마켓 : 중복 선택되지 않은 마스터 ID 리스트
    $scope.filteredEsmId = (shop_cd, index) => {
      return $scope.canEsmMasterList.filter(data => data.shop_cd === shop_cd && (data.etc7 === $scope.esmMatchShops[index].esmMasterId));
    };

    //상품 유형 선택
    $scope.selectType = function(row, type) {
      if (!row.ol && !row.std_ol && !row.smile_ol) {
        row.selected = false;
      } else {
        row.selected = true;
      }
    };

    $scope.ok = async () => {
      if (!$scope.newForm.$valid) {
        commonSVC.showMessage('입력값이 유효하지 않거나 필수 입력값이 누락되었습니다.');
        $scope.esmIdCheck = true;
        $timeout(() => { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      let addWorkSite = [];

      if ($scope.scrap_type == 'all') {
        const filterMall = _.filter($scope.mall, { selected: true });

        if (!filterMall.length) {
          commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');
          $timeout(() => { $scope.waitBtnDisableFlag = false; });

          return false;
        }

        _.forEach(filterMall, function(list) {
          if (list.shop_name === '11번가') {
            if (list.ol) {
              list.std_ol_yn = 0;
              addWorkSite.push(_.cloneDeep(list));
            }
            if (list.std_ol) {
              list.std_ol_yn = 1;
              addWorkSite.push(_.cloneDeep(list));
            }
            // 스마일배송 상품
            if (list.smile_ol) {
              list.std_ol_yn = 2;
              addWorkSite.push(_.cloneDeep(list));
            }
          } else if (['ESM마스터상품', '옥션', '지마켓'].includes(list.shop_name)) {
            if (list.std_ol) {
              list.std_ol_yn = 1;
              addWorkSite.push(_.cloneDeep(list));
            }
            // 스마일배송 상품
            if (list.smile_ol) {
              list.std_ol_yn = 2;
              addWorkSite.push(_.cloneDeep(list));
            }
          } else {
            addWorkSite.push(list);
          }
        });
      } else {
        if (!$scope.site_list || !$scope.site_list.length) {
          commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');
          $timeout(() => { $scope.waitBtnDisableFlag = false; });

          return false;
        }
        const check_shop_sale = _.filter($scope.site_list, function (row) {
          return !row.shop_sale;
        });

        if (check_shop_sale.length) {
          commonSVC.showMessage('쇼핑몰 상품코드를 확인해 주세요.');
          $timeout(() => { $scope.waitBtnDisableFlag = false; });

          return false;
        }
        addWorkSite = $scope.site_list;
      }

      const promiseArr = [];
      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      const addWorkParams = addWorkSite.map(site => {
        let esmMatchShops = '';

        if (site.shop_cd === 'ESMM' && $scope.scrap_type !== 'all') {
          const matchEsmId = $scope.esmMatchShops.find(shop => shop.esmMasterId === site.shop_id);

          esmMatchShops = [`A001||${matchEsmId.auction}`, `A006||${matchEsmId.gmarket}`];
        }

        // 작업 추가
        const param = {
          site_code: site.shop_cd,
          site_id: site.shop_id,
          std_ol_yn: site.std_ol_yn,
          codeScrapYn: $scope.scrap_type != 'all',
          auto_mapping: $scope.checked.auto_mapping,
          auto_regist: $scope.checked.auto_regist,
          ...(site.shop_cd === 'ESMM' && $scope.scrap_type !== 'all' && esmMatchShops.length && { esmMatchShops })
        };

        if ($scope.scrap_type == 'all') {
          param.ProdScrapDate = $scope.checked.scrap_date;
          // 직접입력 사용시 들어갈 date range ex) '2024-09-01 ~ 2024-09-23'
          param.ProdScrapCustomDate = $scope.checked.scrap_date === 'custom' ? { custom_sdate: $scope.checked.custom_sdate, custom_edate: $scope.checked.custom_edate } : null;
        } else {
          const findSite = _.find($scope.site_list, { shop_cd: site.shop_cd, shop_id: site.shop_id, std_ol_yn: site.std_ol_yn });

          param.prod_codes = findSite.shop_sale.split(',');

          for (let i = 0; i < param.prod_codes.length; i++) {
            param.prod_codes[i] = param.prod_codes[i].trim();
          }
        }

        return param;
      });

      if ($scope.isAutoSetting) {
        await workSVC.addSolWork('AutoSetting', { scrapProdList: addWorkParams });

        closeModal();
      } else {
        angular.forEach(addWorkParams, function(param) {

          const anHttpPromise = workSVC.addWork('ScrapProd', param)
            .then(result => {
              if (result.status == 200) {
                reCnt.success++;
              } else if ((typeof result.data.error) !== 'string' || !result.data.error.includes('not_supported_act')) {
                reCnt.error++;
                reCnt.errSite.push(`${param.shop_name}(${param.shop_id})`);
                reCnt.errReason.push(result.data.error || '');
              }
            })
            .catch(err => {
              reCnt.error++;
              reCnt.errSite.push(`${param.shop_name}(${param.shop_id})`);
              reCnt.errReason.push('');

              return err;
            });

          promiseArr.push(anHttpPromise);
        });

        return $q.all(promiseArr)
          .then(async re => {
            if (!reCnt.success) {
              throw re;
            } else {
              const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
              commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);

              closeModal();
            }
          })
          .catch(err => {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');

            commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);

            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      }
    };

    const closeModal = () => {
      const LOCALSTORAGE_KEY = 'from_product_scrap_to_work';
      const showGuide = localStorage.getItem(`not_show_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`);
      if (showGuide !== 'true') {
        commonSVC.showConfirmCustom(
          {
            title: ' ',
            text: '상품 가져오기 작업 진행률과 결과는 [작업] 페이지에서 확인할 수 있습니다. 이동하시겠습니까?',
            html: true,
            confirmButtonText: '이동'
          },
          function(confirm) {
            if (confirm) {
            // 작업관리로 이동
              $state.go('main.work');
              $scope.cancel();
            } else {
              $scope.cancel();
            }
          },
          { show: true, key: LOCALSTORAGE_KEY, m_no: userInfo.user.m_no }
        );
      } else {
        $scope.cancel();
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
