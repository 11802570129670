'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A011-Ctrl', function ($scope, $rootScope, $timeout, $ocLazyLoad, siteInfo, commonSVC, A011categorySVC, categoryModel, templateModel, commonModel, shopAccountModel) {
    $scope.sel_category = ['', '', '', ''];
    $scope.disp_sel_category = ['', '', '', ''];
    $scope.cate = [[], [], [], []];
    $scope.disp_cate = [[], [], [], []];
    $scope.tempCate = [];
    $scope.totalCate = [];
    $scope.CateData = angular.copy(A011categorySVC.category_data);
    $scope.DispCateData = angular.copy(A011categorySVC.disp_category_data);
    $scope.MD = angular.copy(A011categorySVC.MD);
    $scope.MdCodeList = {};
    $scope.MdNameList = {};
    $scope.DispCateLi = [];
    $scope.selected = [];

    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.disp_category_names = ''; //노출될 매장 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    let save_flag = false;    //카테고리 선택여부

    $scope.pa5_job_data = {
      get_md: {
        load: false,
        data: []
      },
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = Object.keys(shop_cate_info).length ? shop_cate_info : angular.copy(A011categorySVC.category_data);

      if (!$scope.CateData.detailData.detailData) {
        $scope.CateData.detailData.detailData = angular.copy(A011categorySVC.category_data.detailData);
      }

      if ($scope.CateData.detailData.category_code) {
        await setCategory($scope.CateData.detailData.category_code);
      }

      // MD 설정
      $scope.MD = $scope.CateData.detailData.MD;
      $scope.MdNameList = $scope.MdCodeList.find(md_code => md_code.code === $scope.MD.code)?.md_list;

      // 카테고리 설정
      const cate_code_list = $scope.CateData.detailData.category_code.split('>');

      let cate_code = '';
      for (let i = 0; i < 4; i++) {
        cate_code += cate_code_list[i];
        $scope.CateData[i] = cate_code;
      }

      // 진열매장 설정
      if ($scope.CateData.detailData.DispCateLi.length > 0) {
        await setDispCategory($scope.CateData.detailData.DispCateLi[0].value);
      }

      $scope.DispCateLi = $scope.CateData.detailData.DispCateLi || [];
      $scope.DispCateLiShop = $scope.CateData.detailData.DispCateLiShop?.toString();
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];
      if (!cate) {
        return false;
      }

      const category_codes = [];
      const category_names = [];
      $scope.tempCate = [];
      save_flag = false;

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
        category_codes.push(i === 0 ? $scope.sel_category[i].shop_cate_see_cd : $scope.sel_category[i].shop_cate_see_cd.slice(-2));
      }

      $scope.category_names = category_names.join(' > ');
      $scope.category_code = category_codes.join('>');

      save_flag = cate.end_yn;

      if (idx < 3) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }
        if (idx < 2) {
          delete cate.etc;
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            $scope.cate[idx + 1] = res.data.result;
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        save_flag = true;
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    $scope.dispSelect = async (idx) => {
      const cate = $scope.disp_sel_category[idx];
      if (!cate) {
        return false;
      }

      const category_names = [];
      $scope.temp_disp_cate = [];
      save_flag = false;

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.disp_sel_category[i].shop_cate_see_name);
      }

      $scope.disp_category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (idx < 4) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.disp_cate[i] = [];
          $scope.disp_sel_category[i] = '';
        }
        if (idx < 3) {
          delete cate.etc;
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            $scope.disp_cate[idx + 1] = res.data.result;
            $scope.disp_loading = true;
            resolve();
          });
        });

        return true;
      } else {
        save_flag = true;
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          $scope.disp_cate[i] = [];
          $scope.disp_sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.disp_loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    $scope.dispCateAdd = () => {
      const data = $scope.disp_sel_category;
      const reg = /\(Y\)/;

      const liLen = $scope.DispCateLi.length;
      let YChk = true;
      const nameArr = [];
      const valueArr = [];

      if (liLen > 0) {
        commonSVC.showMessage('진열매장은 1개만 설정 가능합니다.');

        return false;
      }

      _.each(data, function(v) {
        const name = v.shop_cate_see_name;
        const code = v.shop_cate_see_cd;

        if (name) {
          nameArr.push(name);
          valueArr.push(code);
        }

        if (reg.test(name)) {
          YChk = false;

          $scope.DispCateLi.push({
            name: nameArr.join('>'),
            value: valueArr.join('>')
          });

          $scope.DispCateLi = _.uniqBy($scope.DispCateLi, 'value');
          if ($scope.DispCateLi.length === liLen) {
            commonSVC.showMessage('이미 등록된 매장입니다.');
          }

          return false;
        }
      });

      if (YChk) {
        commonSVC.showMessage('(Y)가 포함된 분류까지 선택해주세요.');
      }
    };

    /**
     * 2018-10-01 Jacob
     * 전시매장 선택박스 추가
     */
    $scope.selectRow = function (row) {
      if ($scope.selected.indexOf(row) == -1) {
        $scope.selected.push(row);
      } else {
        $scope.selected.splice($scope.selected.indexOf(row), 1);
      }
    };

    /**
     * 2018-10-01 Jacob
     * 전시매장 삭제기능 추가
     */
    $scope.dispCateDel = function () {
      // 진열매장 개수 수정에 따라 기본매장도 삭제되게 변경 2020-05-04 Tim
      if ($scope.selected.includes(parseInt($scope.DispCateLiShop))) {
        commonSVC.showConfirm('주의', '선택하신 진열매장은 기본매장입니다.\n삭제하시겠습니까?', (confirm) => {
          if (confirm) {
            cateDel();
            $scope.$apply();
          }
        });
      } else {
        cateDel();
      }
    };

    const cateDel = () => {
      if ($scope.selected.length > 1) {
        $scope.selected.sort(function (s, l) {
          return l - s;
        });
      }
      _.each($scope.selected, function (v) {
        $scope.DispCateLi.splice(v, 1);
      });
      $scope.selected = [];
      // $scope.DispCateLi = $scope.DispCateLi;
      commonSVC.showToaster('success', '성공', '진열매장 삭제 성공');
    };

    $scope.changeDispCateLiShop = function (value) {
      $scope.DispCateLiShop = value;
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {
      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if ($scope.DispCateLi.length !== 1) {
        commonSVC.showConfirm('1개의 진열매장이 선택 되어야 합니다.');

        return false;
      }

      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return;
      }

      let category_code = '';
      for (let i = 0; i < 4; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;
      $scope.CateData.detailData.MD = $scope.MD; // 정보고시 유형 저장

      if ($scope.form1.$valid) {
        const junsi = [];

        _.each($scope.DispCateLi, function(v) {
          junsi.push(v.value);
        });

        // 진열매장 필수선택 추가
        if ($scope.DispCateLi.length < 1) {
          commonSVC.showMessage('실패', '진열매장을 설정해 주세요.');

          return;
        }

        const detailData = {
          key_cate_code: $scope.category_code,
          category_code: $scope.category_code,
          category_names: $scope.category_names,
          key_copy_junsi: junsi.join('^^'),
          key_empListMain_code: $scope.MD.name,
          key_mdId_code: $scope.MD.code,
          DispCateLi: $scope.DispCateLi,
          DispCateLiShop: parseInt($scope.DispCateLiShop),
          MD: { ...$scope.MD }
        };

        const param = {
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: $scope.category_code.split('>').join('_'),
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    const setCategory = async (code) => {
      const codes = [];
      for (let i = 0; i < 4; i++) {
        codes[i] = code.split('>').slice(0, i + 1).join('');
      }

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === codes[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';
        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    const setDispCategory = async (code) => {
      const codes = code.split('>');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.disp_sel_category[idx] = $scope.disp_cate[idx].find(cate => (
          cate.shop_cate_see_cd === codes[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';
        // 선택처리
        hasNext = $scope.disp_sel_category[idx] ? await $scope.dispSelect(idx) : false;

        idx++;
      }
    };

    $scope.setMd = function () {
      if ($scope.MD.code) {
        $scope.MdNameList = $scope.MdCodeList.find(md_code => md_code.code === $scope.MD.code).md_list;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const init = async () => {
      const cates = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A011', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });
      const disp_cates = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A011', std_ol_yn: 0, shop_cate_see_type: '전시', shop_cate_see_cd: null });
      await run_pa5_jobs('get_md', 'MD', {}, data => {
        $scope.MdCodeList = data;
        return data;
      });

      $timeout(async () => {
        $scope.cate[0] = cates.data.result;
        $scope.disp_cate[0] = disp_cates.data.result;

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A011categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });
    };

    init();
  });
