'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A133-Ctrl', function ($scope, $rootScope, siteInfo, commonModel, shopAccountModel, onlineProductModel, commonSVC, data, systemList, templateList, $uibModalInstance, $timeout) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = _.cloneDeep(data.data.detailType);
    $scope.cosmeticMaker = {
      datas: []
    };

    // 카테고리 정보
    let solCateShopAddInfo = $scope.data.sol_cate_shop_add_info;

    if (!solCateShopAddInfo) {
      solCateShopAddInfo = $scope.data.site_list.filter(o => o.shop_cd === $scope.shop_data.shop_cd && o.shop_id === $scope.shop_data.shop_id)[0].sol_cate_shop_add_info;
    }

    let siteCategoryCodes;

    if ($scope.data.shop_cate1_cd && $scope.data.shop_cate1_cd !== '0') {
      siteCategoryCodes = [$scope.data.shop_cate1_cd, $scope.data.shop_cate2_cd, $scope.data.shop_cate3_cd, $scope.data.shop_cate4_cd];
    } else if (solCateShopAddInfo) {
      siteCategoryCodes = solCateShopAddInfo.category_code.split('_');
    } else {
      siteCategoryCodes = $scope.shop_data.match_cate_code?.split('_');
    }

    // 안전인증 태그여부는 인증번호로 설정
    // 추후 태그 지원 요청이 있는경우 태그 추가 ( 1. 인증번호, 2. 인증태그 )
    $scope.row.set_info.key_safeCertExpsGbcd = '1';
    $scope.row.set_info.key_giftItemYn = $scope.row.set_info.key_giftItemYn || 'N';
    $scope.row.set_info.key_ordAddReqnYn = $scope.row.set_info.key_ordAddReqnYn || 'N';
    $scope.row.set_info.key_ordReq = $scope.row.set_info.key_ordReq || [{
      key_addReqnTitl: '',
      key_addReqnEx: ''
    }];
    $scope.row.set_info.key_meatHisYn = $scope.row.set_info.key_meatHisYn || 'N';
    $scope.row.set_info.key_prmrOrgCnryGbcd = $scope.row.set_info.key_prmrOrgCnryGbcd || '';
    $scope.row.set_info.key_prmrOrgCnryNm = $scope.row.set_info.key_prmrOrgCnryNm || '';
    $scope.row.set_info.key_childItemYn = $scope.row.set_info.key_childItemYn || 'Y';
    $scope.safeCertTypeGbs = [
      { code: '', name: '선택' },
      { code: '01', name: '안전인증' },
      { code: '02', name: '안전확인' },
      { code: '03', name: '공급자적합성확인' },
      { code: '04', name: '안전기준준수대상' }
    ];

    // 카테고리에 안전인증유형구분코드 값이 있는경우 해당 값 사용.
    if (solCateShopAddInfo?.etc?.safeCertTypeGbcd) {
      $scope.row.set_info.key_safeCertTypeGbcd = solCateShopAddInfo.etc.safeCertTypeGbcd;
      $scope.safeCertTypeGbs = $scope.safeCertTypeGbs.filter(o => o.code === $scope.row.set_info.key_safeCertTypeGbcd);
    }

    /**
     * 저장
     */
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      let errMessage = '';
      const set_info = $scope.row.set_info;

      // 안전인증정보는 safeCertTypeGbcd 값이 없고 13세 이상인경우에만 비필수.
      if (!(!solCateShopAddInfo?.etc?.safeCertTypeGbcd && $scope.row.set_info.key_childItemYn === 'N')) {
        if (solCateShopAddInfo?.etc?.safeCertTypeGbcd && !set_info.key_safeCertTypeGbcd) {
          errMessage = '안전인증정보 > 안전확인유형을 선택해주십시오.';
        }

        // 안전 확인 유형에 따라 인증정보 필수값 확인이 달라져 조건 추가
        // 13세 이상, 안전확인유형값이 있으면 => 안전확인유형 따라감(01, 02: 인증항목, 인증번호/03, 04: 없음)
        // 13세 이상, 안전확인유형값이 없으면 => 모두 필수 아님
        // 13세 미만, 안전확인유형값이 있으면 => 안전확인유형 따라감(13세 이상과 동일)
        // 13세 미만, 안전확인유형값이 없으면 => 안전확인유형 필수(01, 02, 03, 04 중 선택), 나머지 필수항목은 선택한 안전확인유형을 따라감(01, 02: 인증항목, 인증번호/03, 04: 없음)
        if (!['03', '04'].includes(set_info.key_safeCertTypeGbcd)) {
          if (!set_info.key_safeCertClasGbcd) {
            errMessage = '안전인증정보 > 인증항목을 선택해주십시오.';
          } else if (!set_info.key_safeCertNo) {
            errMessage = '안전인증정보 > 인증번호을 입력해주십시오.';
          }
        }
      }

      if (set_info.key_giftItemYn === 'Y') {
        if (!set_info.key_giftCntn) {
          errMessage = '사은품상품 설정시 사은품설명은 필수 입니다.';
        } else if (!set_info.key_giftEvntStrtDtm) {
          errMessage = '사은품상품 설정시 이벤트시작일은 필수 입니다.';
        } else if (!set_info.key_giftEvntEndDtm) {
          errMessage = '사은품상품 설정시 이벤트종료일은 필수 입니다.';
        }
      }

      if (!set_info.key_totBcdVal) {
        errMessage = '브랜드 바코드/품번 정보는 필수 입니다.';
      }

      // 특정엠디는 품질표시 TAG 필수임.
      if (['1001', '1013'].includes(solCateShopAddInfo?.key_rsptMdCd)) {
        errMessage = '품질표시TAG를 입력해주십시오';
      }

      // 화장품 카테고리인경우 화장품제조사 정보 필수.
      if (siteCategoryCodes && ['R501'].includes(siteCategoryCodes[1]) && !set_info.key_csmtMkcoSeq) {
        errMessage = '화장품 카테고리인 경우 화장품 제조판매업자는 필수 입니다.';
      }

      // 식품 카고인경우 주원료원산지, 이력번호표시제품은 필수
      if (siteCategoryCodes && siteCategoryCodes[0] === 'S5') {
        if (!set_info.key_prmrOrgCnryGbcd || set_info.key_meatHisYn !== 'Y') {
          errMessage = '카테고리가 식품일 경우 주원료원산지, 이력번호표시제품은 필수 입니다.';
        }
        if (set_info.key_prmrOrgCnryGbcd === '9999' && !set_info.key_prmrOrgCnryNm) {
          errMessage = '주원료원산지 직접입력시 주원료원산지명을 입력해주셔야 합니다.';
        }
      }

      if (errMessage) {
        commonSVC.showMessage(errMessage);

        return false;
      }

      if (!set_info.key_prmrOrgCnryGbcd) {
        $scope.row.set_info.key_prmrOrgCnryNm = '';
      } else if (set_info.key_prmrOrgCnryGbcd !== '9999') {
        $scope.row.set_info.key_prmrOrgCnryNm = $('select[name="key_prmrOrgCnryGbcd"] option:checked').text();
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 이미지 업로드
    $scope.uploadOptFile = function(file) {
      const files = [file];

      // # S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function(res) {
          $scope.row.set_info.key_giftImgNm = res.data.results[0].url;
        });
    };

    /**
     * 주문추가요청 추가 삭제
     */
    $scope.calculateOption = function (calMode, idx) {
      if (calMode === 'plus') {
        const maxCount = 5;

        if ($scope.row.set_info.key_ordReq.length < maxCount) {
          $scope.row.set_info.key_ordReq.push({
            key_addReqnTitl: '',
            key_addReqnEx: ''
          });
        }
      } else {
        $scope.row.set_info.key_ordReq.splice(idx, 1);
      }
    };

    /**
     * 화장품 제조판매업자 조회.
     */
    $scope.getCosmeticData = async (type) => {
      if (type !== 'init' && !$scope.row.set_info.key_csmtMkcoName) {
        commonSVC.showMessage('검색어를 입력해주십시오.');

        return false;
      }

      if ($scope.row.set_info.key_csmtMkcoName) {
        try {
          const re = await commonModel.pa5('/channel_accounts/{uuid}/etc/get_cosmetic_seller/sync', { name: $scope.row.set_info.key_csmtMkcoName }, $scope.shop_data.pa_shop_cd, $scope.shop_data.shop_id);

          if (re.data.has_error == false) {
            $scope.cosmeticMaker.datas = re.data.results[0].data;

            $timeout(() => {});
          } else {
            commonSVC.showMessage('실패', `화장품 제조판매업자정보 조회에 실패하였습니다.\n${re.data.exception_message}`);
          }
        } catch (e) {
          commonSVC.showMessage('실패', '제조판매업자정보 조회에 실패하였습니다.');
        }
      }
    };

    // 추가항목 템플릿 리스트에서 넘어오는 경우 데이터 조회에 시간이 걸려 timeout처리
    $timeout(() => {
      $scope.getCosmeticData('init');
    }, data.from === 'templateList' ? 500 : 0);

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
        $scope.getCosmeticData('init');
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
        $scope.getCosmeticData('init');
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
        $scope.getCosmeticData('init');
      });
    };
  });
