/**
 * Created by ally on 2017. 6. 22..
 */
'use strict';

angular.module('gmpApp')
  .directive('memo', function($filter, $rootScope, gettext, gettextCatalog, commonSVC, settings, $timeout, memoModel) {
    return {
      restrict: 'EA',
      scope: {
        code: '=',
        type: '=',
        uniq: '=',
        update: '='
      },
      templateUrl: 'views/tpls/memo.html',
      link: function ($scope, element, attrs) {

        // 2017. 10. 18 박현도 수정

        // 기존 코드
        $scope.memo = {};
        $scope.memo.content = '';
        $rootScope.memoCount = 0;
        $scope.memo.suc_yn = false;
        $scope.user_profile = $rootScope.user_profile;
        $scope.sol_no = attrs.sol;
        const uniq = $scope.uniq;
        const code = $scope.code;
        const type = $scope.type;

        getMemo();

        /**
         * 메모 등록
         * */
        $scope.submitCS = () => {
          $scope.memo.content = $scope.memo.content.replace(/\n/gi, '<br>');

          if ($scope.memo.content == '') {
            commonSVC.showMessage(gettextCatalog.getString('내용을 적어주세요'));

            return false;
          }

          const data = $scope.memo;

          data.uniq = uniq;
          data.memo_type = type;
          data.memo_code = code;

          memoModel.add(data)
            .then(({ status }) => {
              if (status === 200) {
                $scope.update = true;
                commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 등록 되었습니다.'));
                $scope.memo.content = '';
                $timeout(function () {
                  getMemo();
                }, 500);
              }
            });
        };

        /**
         * 메모 삭제
         * */
        $scope.deleteCS = function(number) {
          const data = { memo_no: [number] };

          commonSVC.showConfirm(gettextCatalog.getString('삭제하시겠습니까?'), '', function () {
            memoModel.delete(data)
              .then(({ status }) => {
                if (status === 200) {
                  $scope.update = true;
                  commonSVC.showToaster('success', '', gettextCatalog.getString('메모가 삭제 되었습니다.'));
                  getMemo();
                }
              });
          });
        };

        /**
         * 메모 불러오기
         * */
        function getMemo() {
          const data = { uniq: uniq, memo_code: code, sol_no: attrs.sol };

          // 메모 저장 후 바로 메모 불러오면 조회 안되는 현상이 일어나서 타임아웃 걸어줌 - 2018-09-14 Alvin
          setTimeout(function() {
            memoModel.list(data)
              .then(({ data, status }) => {
                if (status === 200) {
                  if (data.results.length) {
                    $rootScope.memoCount = data.results.length;
                    $scope.memoList = _.mapValues(data.results, function(val) {
                      return _.extend(val, { checked: false });
                    });
                  } else {
                    $rootScope.memoCount = 0;
                    $scope.memoList = [];
                  }
                }
              });
          }, 500);
        }

      }
    };
  });

