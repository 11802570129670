/**
 * 사용자태그 관리
 */
'use strict';

angular.module('gmpApp')
  .controller('TagListCtrl', function ($scope, commonSVC, $timeout, $rootScope, $filter, $uibModalInstance, settings, gettextCatalog, tagModel, tagSVC, localStorageService, $sce) {
    $scope.showDesc = localStorageService.get('tagShowDescYn') || 'Y'; // Description 칸
    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchData: {
        selectCount: 0,       // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
        showCount: 25,
      }
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchBtn = {
      table_actions: [
        {
          label: '조건추가',
          add_class: 'btn-default mr-3',
          perm_only: ['order.unstoring+write'],
          action: () => {
            $scope.showDetail();
          }
        }
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      emptyTemplate: `
        <div>조건을 설정하여 사용자태그를 사용해보세요</div>
        <div class="btn-group a-btn-group">
          <button type="button" class="btn btn-default" ng-click="showDetail()">
            조건 추가
          </button>
        </div>`,
      modal: true,
      isNavigatorCenter: true,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      alignCenterColumns: [
        'widget', 'tag_no', 'tag_name', 'wdate', 'mdate', 'use_yn'
      ],
      alignRightColumns: [],
      notSortingColumns: ['widget', 'terms'],
      notResizingColumns: ['widget'],
      notMovingColumns: ['widget'],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/tag/terms-list`,
        requestWillAction: function (data) {
          const assignData = Object.assign(data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(assignData);

          return Object.assign(assignData, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.searchData.viewCount = result.recordsFiltered;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 93,
          template: function (row) {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(${row.ordterm_no})" >상세</button>
              <button
                class="btn btn-default btn-xxs"
                ng-click="grid.appScope.delete(${row.ordterm_no})">
                삭제
              </button>
            `;
          }
        },
        {
          key: 'terms',
          title: '조건',
          width: 700,
          template: function (row) {
            const termsArray = [];

            const formCurrency = (value) => $filter('currency')(value, '', 0);

            // SKU 상품 조건 추가
            if (row.sku_cd || row.set_cd) {
              // 매칭된 SKU상품 + 세트상품 총 개수
              termsArray.push(`SKU상품(${((row.sku_cd && row.sku_cd.split('\n').length) || 0) + ((row.set_cd && row.set_cd.split('\n').length) || 0)})`);
            }

            // 판매금액
            if (row.s_sales) {
              if (row.e_sales === 99999999) {
                termsArray.push(`판매금액 (${formCurrency(row.s_sales)}원 이상)`);
              } else if (row.e_sales) {
                termsArray.push(`판매금액 (${formCurrency(row.s_sales)}원~${formCurrency(row.e_sales)}원)`);
              }
            }

            // 수량
            if (row.s_sale_cnt) {
              if (row.e_sale_cnt == 60000) {
                termsArray.push(`수량(${formCurrency(row.s_sale_cnt)}개 이상)`);
              } else if (row.e_sale_cnt) {
                termsArray.push(`수량(${formCurrency(row.s_sale_cnt)}개~${formCurrency(row.e_sale_cnt)}개)`);
              }
            }

            //휴대폰번호, 키워드
            const translateRuleTerm = (ruleTerm) => {
              const termsMap = {
                order_htel: '주문자',
                to_htel: '수령자',
                to_zipcd: '우편번호',
                to_addr1: '주소',
                shop_sale_name: '상품명',
                shop_opt_name: '옵션명',
                shop_add_opt_name: '추가구매옵션',
                ship_msg: '배송메시지'
              };

              return termsMap[ruleTerm] || '';
            };

            if (row.rule_terms_concat) {
              const telTypes = { order_htel: '휴대폰번호-주문자', to_htel: '휴대폰번호-수령자' };

              Object.entries(telTypes).forEach(([type, label]) => {
                const count = row.rule_terms_concat.filter(v => v.rule_terms === type).length;
                if (count) {
                  termsArray.push(`${label}(${count})`);
                }
              });

              const groupedTerms = row.rule_terms_concat
                .filter(({ rule_terms: ruleTerm }) => !Object.keys(telTypes).includes(ruleTerm))
                .reduce((acc, { terms: term, rule_terms: ruleTerm }) => {
                  if (!acc[ruleTerm]) {
                    acc[ruleTerm] = [];
                  }
                  acc[ruleTerm].push(term);

                  return acc;
                }, {});

              Object.entries(groupedTerms).forEach(([ruleTerm, terms]) => {
                const rule = translateRuleTerm(ruleTerm);
                const joinedTerms = terms.join(', ');
                termsArray.push(`키워드-${rule}(${joinedTerms})`);
              });
            }

            return termsArray.join(', ') || '';
          }
        },
        {
          key: 'tag_name',
          title: '태그',
          width: 130,
          template: function (row) {
            return row.tag_no ? `<span style="color: ${tagSVC.tag_color().find(({ name }) => name === row.tag_color)?.code};">${row.tag_name}</span>` : '-';
          }
        },
        {
          key: 'use_yn',
          width: 80,
          title: '사용여부',
          template: function(row) {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    /**
     * 리스트에서 사용여부 변경시
     */
    $scope.changeDo = async (row) => {
      if (!row.tag_no) {
        commonSVC.showMessage('태그를 선택하지 않은 경우 조건을 사용할 수 없습니다.\n조건 상세화면에서 태그를 선택해주세요.', '');
        row.use_yn = !row.use_yn;
        $rootScope.$applyAsync();

        return;
      }
      const { ordterm_no, use_yn } = row;
      try {
        await tagModel.terms.save({ mode: 'edit', ordterm_no, use_yn, change_use_yn: true });

        commonSVC.showToaster('success', '', '사용자태그 조건 사용여부 수정에 성공하였습니다.');
      } catch (error) {
        commonSVC.showToaster('error', '', '사용자태그 조건 사용여부 수정에 실패하였습니다.');

        return false;
      }
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('tagShowDescYn', $scope.showDesc);
    };

    /**
		 * 테이블 검색
		 */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
		 * 테이블 pageLength 조절
		 */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 취소
     */
    $scope.cancel = function() {
      $uibModalInstance.close('success');
    };

    /**
     * 사용자태그 조건 삭제
     */
    $scope.delete = async (ordterm_no) => {
      const isDelete = await commonSVC.showConfirm('조건에 따라 기존 주문에 적용된 태그도 삭제됩니다.\n조건을 삭제하시겠습니까?', '');

      if (isDelete) {
        try {
          const result = await tagModel.terms.del({ termNo: ordterm_no });
          if (result.data.results === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사용자태그 조건 삭제에 성공하였습니다.'));
            $scope.searchDo();
          } else {
            throw new Error();
          }
        } catch (error) {

          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사용자태그 조건 삭제에 실패하였습니다.'));
        }
      }
    };

    /**
     * 사용자태그 적용
     */
    $scope.tagApply = async () => {
      const confirm = await commonSVC.showConfirm('사용자태그를 적용하시겠습니까?', '');

      if (confirm) {
        try {
          const apply_res = await tagModel.terms.apply({ ordterm_no: $scope.grid.methods.selectedData('ordterm_no') });
          if (apply_res.data.results === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사용자태그가 적용되었습니다.'));
            $uibModalInstance.close('success');
          } else {
            throw new Error();
          }
        } catch (error) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사용자태그 적용에 실패하였습니다.'));
          $timeout(function () {
            $scope.waitBtnDisableFlag = false;
          }, 1000);
        }
      } else {
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 0);

        return;
      }
    };

    /**
     * 미사용 태그 선택여부 확인
     */
    $scope.checkUseYn = function() {
      return !$scope.searchData.selectCount || $scope.grid.methods.selectedData('use_yn').includes(false);
    };

    /**
     * 상세페이지 보여주기
     */
    $scope.showDetail = function(ordterm_no) {
      const resolve = {
        data: {
          mode: ordterm_no ? 'edit' : 'add',
          ordterm_no
        }
      };
      const modal = commonSVC.openModal('xg', resolve, 'TagTermCtrl', 'views/order/shipment/modals/tag_term.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };

    /**
     * 태그 관리
     */
    $scope.showTag = function() {
      const modal = commonSVC.openModal('mini', {}, 'TagDetailCtrl', 'views/order/shipment/modals/tag_detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };
  });
