'use strict';

angular.module('gmpApp')
  .filter('whiteSpace', function () {
    return function (str) {
      // 띄어쓰기가 두번 이상일때 &nbsp; 로 치환해줌
      // white-space: pre-wrap을 사용하지 않은 이유는 상품명등이 길어지면 뒤에 ... 으로 표시(white-space: nowrap (기존에 이렇게 되어있음))되어야 하는데 그게 안됨
      const regex = /\s{2,}/g;

      if (!str) {
        return str;
      }

      return str.replace(regex, function(match) {
        return '&nbsp;'.repeat(match.length);
      });
    };
  });