'use strict';

/**
 * 주문삭제
 */
angular.module('gmpApp')
  .controller('OrderShipmentOrderDelete', function ($scope, $uibModalInstance, data, settings, commonSVC, shipmentModel, gettextCatalog) {
    $scope.from = data.fromPage;
    $scope.stock_rollback = false;
    $scope.uniqList = data.uniqList;

    $scope.ok = function () {
      data.stockRollback = $scope.stock_rollback;
      shipmentModel.delete(data, function (state, result) {
        if (state == 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 삭제에 성공하였습니다.'));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('주문 삭제에 실패하였습니다.'));
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });