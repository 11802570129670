/**
 * ptgui-tel 컴퍼넌트
 * 2016-06-01 권윤학
 */
"use strict";

angular.module('ptgui.tel',[])
  .directive('ptguiTel', ['$log', '$window', function($log, $window) {
    function clearValue(value) {
      if (!value) {
        return value;
      }
      return value.replace(/([^0-9|+])/g, '');
    }

    function applyPhoneMask(value, region) {
      var phoneMask = value;
      try {
        var countryCode = region || 'kr';
        phoneMask = $window.phoneUtils.formatAsTyped(value, countryCode);
      }
      catch (err) {
        $log.debug(err);
      }
      return phoneMask;
    }

    return {
      restrict: 'A',
      require: '?ngModel',
      scope: {
        countryCode: '=?',
        nonFormatted: '=?'
      },
      link: function(scope, element, attrs, ctrl) {
        var el = element[0];

        scope.$watch('countryCode', function() {
          ctrl.$modelValue = ctrl.$viewValue + ' ';
        });

        //placeholder가 없는 경우 강제지정
        if(!attrs.placeholder) {
          // $(el).attr("placeholder", "전화번호");
        }

        //maxlength가 없는 경우도 강제지정 국제표준으로 전화번호는 15자리까지임
        if(!attrs.maxlength) {
          $(el).attr("maxlength", 15);
        }
        //국가 지정이 안되어있는경우 한국전화번호
        scope.countryCode = scope.countryCode || 'kr';

        ctrl.$formatters.push(formatter);
        ctrl.$formatters.push(validator);
        ctrl.$parsers.push(parser);
        ctrl.$parsers.push(validator);

        function clean(value) {
          var cleanValue = clearValue(value);
          scope.nonFormatted = cleanValue;
          var formattedValue = '';
          if (cleanValue && cleanValue.length > 1) {
            formattedValue = applyPhoneMask(cleanValue, scope.countryCode);
          }
          else {
            formattedValue = cleanValue;
          }
          return formattedValue.trim();
        }

        function formatter(value) {
          if (ctrl.$isEmpty(value)) {
            return value;
          }
          return applyPhoneMask(clearValue(value), scope.countryCode);
        }

        function parser(value) {
          if (ctrl.$isEmpty(value)) {
            scope.nonFormatted = '';
            return value;
          }

          var formattedValue = clean(value);
          if (formattedValue === value) {
            return value;
          }
          var start = el.selectionStart;
          var end = el.selectionEnd + formattedValue.length - value.length;

          if (value.length < formattedValue.length) {

            start = start + (formattedValue.length - value.length);
          }
          if (value.length > formattedValue.length + 1) {
            start = start - (formattedValue.length - value.length);
          }

          ctrl.$setViewValue(formattedValue);
          ctrl.$render();

          el.setSelectionRange(start, end);

          return clearValue(formattedValue);
        }

        function validator(value) {

          if(value){
            var isValidForRegion = false;
            try {
              isValidForRegion = $window.phoneUtils.isValidNumberForRegion(value, scope.countryCode);
            }
            catch (err) {
              $log.debug(err);
            }
            var valid = ctrl.$isEmpty(value) || isValidForRegion;
            ctrl.$setValidity('tel', valid);
            return value;
          }else
            return value;
        }


      }
    };

  }]);
