/**
 *  재고현황 서비스
 *  2016-12-14 김승호
 */

'use strict';

angular.module('gmpApp')
  .service('inventorySVC', function(commonSVC) {
    return {
      /**
       * 폼 영역 변수 설정
       */
      searchForm: {
        deli_method: '',
        warehouse_code: '',
        delivery_code: '',
        supplier_code: '',
        brand_code: '',
        category: 'all',
        supplier_vendor: '',                                         //매입처
        delivery_vendor: '',                                         //배송처
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        multi_search_word: '', //멀티서치워드
        multi_type: 'sku_cd' //멀티서치 타입
      },
      // 입/출고내역 폼 영역 변수
      searchForm_inout: {
        warehouse_code: '',
        delivery_code: '',
        category: 'all',
        delivery_vendor: '',                                         //배송처
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        notsale_type: '',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        multi_search_word: '', //멀티서치워드
        multi_type: 'sku_cd', //멀티서치 타입
        multi_deliver_vendor: '',
        multi_supplier_vendor: '',
        pageFrom: 'inout'
      },
      /**
       * 서치바 관련 변수
       */
      // 검색영역 데이터
      searchData: {
        //showSearchArea : false,     // 검색어 노출여부
        showDetailSearchArea: true, // 상세 검색어 노출여부
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: 'SKU코드', value: 'sku_cd' },
          { label: '재고관리코드', value: 'stock_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '속성명', value: 'attri' },
          { label: '메모', value: 'memo' },
          { label: '브랜드', value: 'brand' },
          { label: '제조사', value: 'maker' },
          { label: '바코드', value: 'barcode' }
        ],
        search_date_type: [
          { label: '등록일', value: 'wdate' },
          { label: '수정일', value: 'mdate' },
          { label: '상태변경일', value: 'stock_status_mdate' }
        ],
        search_multi_items: [
          { label: 'SKU코드', value: 'sku_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '재고관리코드', value: 'stock_cd' },
          { label: '배송처', value: 'depot' },
          { label: '매입처', value: 'supp' }
        ]
      },
      // 입출고내역 검색영역 데이터
      searchData_inout: {
        //showSearchArea : false,     // 검색어 노출여부
        showDetailSearchArea: true, // 상세 검색어 노출여부
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: 'SKU코드', value: 'sku_cd' },
          { label: '재고관리코드', value: 'stock_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '속성명', value: 'attri' },
          { label: '사유', value: 'content' },
          { label: '브랜드', value: 'brand' },
          { label: '제조사', value: 'maker' },
          { label: '바코드', value: 'barcode' },
        ],
        search_date_type: [
          { label: '전체', value: 'wdate' },
          { label: '입고일', value: 'indate' },
          { label: '출고일', value: 'outdate' },
          { label: '판매불가 처리일', value: 'notsaledate' }
        ],
        search_multi_items: [
          { label: 'SKU코드', value: 'sku_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '재고관리코드', value: 'stock_cd' },
        ]
      },

      excelFieldList: [
        { header: '상태', key: 'stock_status', select: true },
        { header: 'SKU코드', key: 'sku_cd', select: true },
        { header: '재고관리코드', key: 'stock_cd', select: false },
        { header: '배송처코드', key: 'depot_no', select: false },
        { header: '배송처', key: 'depot_name', select: false },
        { header: 'SKU상품명', key: 'prod_name', select: false },
        { header: '속성', key: 'attri', select: true },
        { header: '판매가능재고', key: 'stock_cnt', select: true },
        { header: '실재고', key: 'stock_cnt_real', select: true },
        { header: '안전재고', key: 'stock_cnt_safe', select: true },
        { header: '누적입고수량', key: 'in_cnt_accum', select: true },
        { header: '누적교환수량', key: 'exchange_cnt_accum', select: true },
        { header: '누적출고수량', key: 'out_cnt_accum', select: true },
        { header: '출고예정수', key: 'out_scheduled', select: true },
        { header: '누적구매수량', key: 'sale_cnt_accum', select: true },
        { header: '누적반품수량', key: 'return_cnt_accum', select: true },
        { header: '미출고', key: 'unavail_out_cnt', select: true },
        { header: '연동주문', key: 'link_ord_yn', select: true, type: 'boolean' },
        { header: '브랜드', key: 'brand', select: false },
        { header: '제조사', key: 'maker', select: false },
        { header: '판매가', key: 'sale_price', select: true },
        { header: '원가', key: 'cost_price', select: true },
        { header: '공급가', key: 'supply_price', select: true },
        { header: '매입처', key: 'supp_name', select: true },
        { header: '바코드', key: 'barcode', select: false },
        { header: '제조일자', key: 'made_date', select: false },
        { header: '유효일자', key: 'expire_date', select: false },
        { header: '등록일', key: 'wdate', select: true }
      ],
      excelFieldList_inout: [
        { header: '등록일', key: 'wdate', width: 135, select: true },
        { header: '구분', key: 'inout_type', width: 60, select: true },
        { header: 'SKU코드', key: 'sku_cd', width: 140, select: true },
        { header: 'SKU상품명', key: 'prod_name', width: 410, select: true },
        { header: '속성', key: 'attri', width: 115, select: true },
        { header: '배송처', key: 'depot_name', width: 120, select: true },
        { header: '입고', key: 'in_cnt', width: 75, select: true },
        { header: '출고', key: 'out_cnt', width: 75, select: true },
        { header: '판매불가', key: 'notsale_type_cnt', width: 75, select: true },
        { header: '실재고', key: 'stock_cnt_real', width: 100, select: true },
        { header: '사유', key: 'content', width: 150, select: true },
        { header: '작업자', key: 'wm_name', width: 110, select: true },
        { header: '제조사', key: 'maker', width: 100, select: true },
        { header: '브랜드', key: 'brand', width: 100, select: true },
        { header: '바코드', key: 'barcode', width: 100, select: true },
        { header: '재고관리코드', key: 'stock_cd', width: 90, select: true },
        { header: '작업구분', key: 'work_name', width: 90, select: true },
      ]
    };
  });
