/*
 **  시스템 모델
 *   2016-06-30 김민영
 */

'use strict';

angular.module('gmpApp')
  .factory('systemModel', function ($http, $q, settings, commonSVC) {

    // var self = {};
    //
    //
    //
    // self.opt_order_link = "";//주문 자동 동기화 설정1
    // self.invoice_auto = "";//송장 전송 자동화 설정
    // self.work_auto_delete = "";//작업관리 자동 삭제
    // self.order_auto_delete = "";//주문 DB 자동 삭제
    // self.search_date = "";//리스트 기본 조회 기간1
    // self.order_delete = "";//주문 삭제 허용
    // self.work_complate_alert = "";//작업 완료시 알림
    // self.language_setting = "";//언어 설정

    return {
      /**
       * 시스템 정보 업데이트
       */
      set: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/system`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, (state, data) => {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }
      },

      /**
       * 시스템 정보 조회
       */
      load: function (next) {
        const url = `${settings.pa20ApiUrl}/app/settings/system-detail`;
        const method = 'GET';

        if (next) {
          commonSVC.sendUrl(method, url, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl(method, url, '');
        }
      },

      /**
       * 사용 택배사 수정
       * 2019-09-11 Jackal
       */
      editUseCarr: (data, next) => {
        const url = `${settings.pa20ApiUrl}/app/settings/use-carrier-info`;

        commonSVC.sendUrl('PATCH', url, data, (state, data) => {
          next(state, data);
        });
      },

      /**
       * 최초접속자 선택 쇼핑몰 리스트 수정
       */
      editFirstAddShop: param => {
        const url = `${settings.pa20ApiUrl}/app/settings/first-add-shops`;

        return commonSVC.sendUrl('PATCH', url, param);
      },

      /**
       * 주문/매출 리포트 대상자 조회
       */
      checkReportUser: function () {
        const url = `${settings.pa20ApiUrl}/app/settings/report-info`;

        return commonSVC.sendUrl('GET', url, {});
      },

      /**
       * 주문/매출 리포트 대상자 추가
       */
      addReportUser: function (param) {
        const url = `${settings.pa20ApiUrl}/app/settings/report-info`;

        return commonSVC.sendUrl('POST', url, param);
      },

      /**
       * 주문/매출 리포트 거부
       */
      rejectReport: function (param) {
        const url = `${settings.pa20ApiUrl}/app/settings/report-info`;

        return commonSVC.sendUrl('DELETE', url, param);
      },
    };
  });
