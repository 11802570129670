'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A524-Ctrl', function ($scope, shopAccountModel, $ocLazyLoad, siteInfo, $timeout, commonSVC, commonModel, A524categorySVC, errorSVC, categoryModel) {
    let save_flag = false;

    $scope.maxCate = [1, 4, 5, 6];
    $scope.cateList = [];
    $scope.selectedDispList = [];

    function init() {
      fetchCate('1').then((result) => {
        $scope.cateList[0] = result;

        $scope.CateData = angular.copy(A524categorySVC.category_data);
        $scope.CateData.detailData = angular.copy(A524categorySVC.category_data);
        $scope.cert_type = angular.copy(A524categorySVC.cert_type);
        $scope.category_names = ''; //노출될 카테고리 분류
        $scope.requireAttr = ''; //카테고리 속성 노출 안되게

        $scope.dispCateList = [];

        //상품에 매칭된 shop_cate가 있을경우
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) {
          matchDetailDataLoad(angular.copy(A524categorySVC.category_data));
        } else {
          matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });
    }

    $scope.cateSelect = async function(selectNo) {
      $('#cate-spinner').removeClass('hidden');

      save_flag = false;
      $scope.dispCateList.splice(0);
      $scope.cateList.splice(selectNo);

      await $scope[`select${selectNo}`]();

      $('#cate-spinner').addClass('hidden');
    };

    $scope.select1 = async function () {
      $('.cateAttrList').html('');
      $scope.category_names = $('#CatMenu_0 option:selected').text().trim();

      await fetchCate('2', $('#CatMenu_0').val()).then((result) => {
        $scope.cateList[1] = result;
        reRender();
      });
    };

    $scope.select2 = async function () {
      if ($('#CatMenu_1').val() != null) {
        $('.cateAttrList').html('');
        $scope.category_names = $('#CatMenu_0 option:selected').text().trim();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text().trim()}`;

        await fetchCate('3', $('#CatMenu_1').val()).then(async (result) => {
          $scope.cateList[2] = result;
          reRender();

          if (!$('#CatMenu_2 option').length) {
            await fetchDispCate($('#CatMenu_1 option:selected').val());
          }
        });
      } else {
        return false;
      }
    };

    $scope.select3 = async function () {
      if ($('#CatMenu_2').val() != null) {
        $scope.category_names = $('#CatMenu_0 option:selected').text().trim();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text().trim()}`;
        $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text().trim()}`;
        //로딩이미지
        $('#spinner').removeClass('hidden');

        $('#spinner').addClass('hidden');

        await fetchCate('4', $('#CatMenu_2').val()).then(async (result) => {
          $scope.cateList[3] = result;
          reRender();

          if (!$('#CatMenu_3 option').length) {
            $('#disp-spinner').addClass('hidden');
            await fetchDispCate($('#CatMenu_2 option:selected').val());
          }
        });
      } else {
        return false;
      }
    };

    $scope.select4 = async function () {
      if ($('#CatMenu_3').val() != '0_0_0_0' && $('#CatMenu_3').val() != null) {
        if ($('#CatMenu_3').val() != '0_0_0_0' && $('#CatMenu_3').val() != null) {
          $scope.category_names = $('#CatMenu_0 option:selected').text().trim();
          $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text().trim()}`;
          $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text().trim()}`;
          $scope.category_names += ` > ${$('#CatMenu_3 option:selected').text().trim()}`;
        }
        await fetchDispCate($('#CatMenu_3 option:selected').val());
      } else {
        return false;
      }
    };

    function reRender() {
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    }

    function fetchCate(depth, lastCateCode) {
      const param = {
        depth: depth
      };

      if (lastCateCode) {
        param.category_code = lastCateCode;
      }

      return new Promise((resolve) => {
        try {
          requestPA('etc.get_standardCategory', param).then((result) => {
            resolve(result.data.data.items.map((item) => {
              return {
                code: item.data.std_cat_id,
                name: item.data.std_cat_nm
              };
            }));
          }).catch (() => {
            resolve([]);
          });
        } catch (err) {
          commonSVC.showToaster('error', '실패', '카테고리 로딩 실패하였습니다.');
          resolve([]);
        }
      });
    }

    /**
     * 전시카테고리 조회
     */
    function fetchDispCate(lastCateCode) {
      return new Promise((resolve, reject) => {
        try {
          $('#disp-spinner').removeClass('hidden');

          requestPA('etc.get_displayCategory', { category_code: lastCateCode }).then((result) => {
            save_flag = true;

            if (result.data.data.status === 200 && result.data.data.items === '전시카테고리가 없습니다.') {
              $scope.dispCateList[0] = [];

              for (let i = 0; i < 4; i++) {
                const code = $(`#CatMenu_${i} option:selected`).val();
                const name = $(`#CatMenu_${i} option:selected`).text().trim();

                if (code) {
                  $scope.dispCateList[0].push({ code, name });
                }
              }
            } else {
              result.data.data.items.forEach(item => {
                item.forEach(item => {
                  item.code = item.disp_cat_id;
                  item.name = item.disp_cat_nm;
                });

                item.sort((a, b) => {
                  return parseInt(a.depth_no) - parseInt(b.depth_no);
                });
              });

              $scope.dispCateList = result.data.data.items;

              $scope.displayCateAllChecked(false);
            }

            $('#disp-spinner').addClass('hidden');
            $timeout(() => {});

            resolve();
          }).catch((error) => {
            commonSVC.showToaster('error', '실패', '전시카테고리 로딩 실패하였습니다.');
            $('#disp-spinner').addClass('hidden');
            reject(error);
          });
        } catch (error) {
          commonSVC.showToaster('error', '실패', '전시카테고리 로딩 실패하였습니다.');
          $('#disp-spinner').addClass('hidden');
          reject(error);
        }
      });
    }

    $scope.getDisplayCodeToString = function(item) {
      return item.map(obj => obj.code).join('_');
    };

    $scope.getDisplayNameToString = function(item) {
      return item.map(obj => obj.name).join(' > ');
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    $scope.displayCateAllChecked = function(checked) {
      if (checked) {
        for (let i = 0; i < $scope.dispCateList.length; i++) {
          $scope.selectedDispList.push(i);
          $(`#display-cate-${i}`)[0].selected = true;
        }
      } else {
        $scope.selectedDispList = [];
      }
    };

    $scope.selectDisplayOption = function(event) {
      const value = event.currentTarget.value;
      if ($scope.selectedDispList.includes(value)) {
        const index = $scope.selectedDispList.indexOf(value);
        $scope.selectedDispList.splice(index, 1);
      } else {
        $scope.selectedDispList.push(value);
      }
      $scope.selectedDispList = $scope.selectedDispList.sort();

      $timeout(() => {
        $('#dispCateSelect').val('');
        if (event.target.id.includes('display-cate')) {
          $scope.selectedDispList = $scope.selectedDispList.filter(item => item);
          for (const i of $scope.selectedDispList) {
            $(`#display-cate-${i}`)[0].selected = 'selected';
          }
        }
      }, 100);
    };

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      //카테고리 마지막 분류까지 선택여부
      if (save_flag == false) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }
      if (!$scope.selectedDispList.length) {
        commonSVC.showMessage('전시 카테고리를 선택해 주세요.');

        return false;
      }

      $scope.CateData.detailData.cate0 = $('#CatMenu_0 option:selected').val();
      $scope.CateData.detailData.cate1 = $('#CatMenu_1 option:selected').val();
      $scope.CateData.detailData.cate2 = $('#CatMenu_2 option:selected').val();
      $scope.CateData.detailData.cate3 = $('#CatMenu_3 option:selected').val();

      $scope.CatMenu_name_0 = $('#CatMenu_0 option:selected').text().trim();
      $scope.CatMenu_name_1 = $('#CatMenu_1 option:selected').text().trim();
      $scope.CatMenu_name_2 = $('#CatMenu_2 option:selected').text().trim();
      $scope.CatMenu_name_3 = $('#CatMenu_3 option:selected').text().trim();

      $scope.CateData.detailData.key_display_cate = [];
      $scope.CateData.detailData.key_display_cate_nm = [];

      for (const idx of $scope.selectedDispList) {
        $scope.CateData.detailData.key_display_cate.push($scope.dispCateList[idx].map(item => item.code).join('_'));
        $scope.CateData.detailData.key_display_cate_nm.push($scope.dispCateList[idx].map(item => item.name).join('_'));
      }

      //카테고리 depth별 저장될 category_code
      let category_code = [];

      for (let i = 0; i < $scope.maxCate.length; i++) {
        category_code.push($scope.CateData.detailData[`cate${i}`]);
      }
      category_code = _.compact(category_code);

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      $scope.CateData.detailData.key_category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code.join('_');
      $scope.CateData.detailData.key_category_code = category_code.join('_');

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        /**
         * 2018-03-09 Roy
         * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
         */
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);

            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    async function matchDetailDataLoad(addInfo) {
      reRender();

      if (typeof addInfo === 'string') {
        addInfo = JSON.parse(addInfo);

        // 가끔 \" 가 붙어 두 번 해줘야 하는 상황이 있음
        if (typeof addInfo === 'string') {
          addInfo = JSON.parse(addInfo);
        }
      }

      $scope.CateData.detailData = addInfo;

      if (addInfo.key_category_code) {
        const category_code = addInfo.key_category_code.split('_');

        for (let i = 0; i < category_code.length; i++) {
          const item = category_code[i];

          $(`#CatMenu_${i} option[value=${item}]`).attr('selected', 'selected');

          await $scope.cateSelect(i + 1);
        }
      }

      if (addInfo.key_display_cate) {
        for (const cate of addInfo.key_display_cate) {
          const displayCate = cate.split('_');
          const displayCateTargetIndex = $scope.dispCateList.findIndex(array => {
            if (displayCate.length !== array.length) {
              return false;
            }

            for (let i = 0; i < array.length; i++) {
              if (array[i].code !== displayCate[i]) {
                return false;
              }
            }

            return true;
          });
          if (displayCateTargetIndex !== -1) {
            $scope.selectedDispList.push(displayCateTargetIndex.toString());
          }
        }
      }

      $timeout(() => {
        $('#dispCateSelect').val('');

        for (const i of $scope.selectedDispList) {
          $(`#display-cate-${i}`)[0].selected = 'selected';
        } }, 100);

      reRender();
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const domain = siteInfo.A524.domain;
      const param = addData ? addData : { lvl: 1, code: '' };

      return commonSVC.requestPA({ ...$scope.userInfo, pa_shop_cd: $scope.shop_cd }, domain, action, param, commonModel, shopAccountModel);
    }

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    // 계정정보 선 로딩후 카테고리 로딩
    shopAccountModel.load({ shop_cd: $scope.shop_cd, shop_id: $scope.shop_id })
      .then(function (res) {
        $scope.userInfo = res.data.results;

        init();
      });
  });