/**
 * 1:1 문의 목록 컨트롤러
 * 2017-10-25 Pada
 */

'use strict';

angular.module('gmpApp')
  .controller('QuestionListCtrl', function ($scope, settings, $timeout, $compile, $stateParams, commonSVC, questionSVC, questionModel, gettextCatalog, $rootScope) {

    //정렬 데이터
    let order_data;
    // 데이터테이블 정렬 초기화
    const init_order = true;
    let total_count = 0;
    let initYN = 0;
    let fromDashboard = false;

    $scope.selectCount = 'my_question';

    /**
   * 서치바 관련 변수
   */
    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(questionSVC.searchForm);
    // $scope.searchForm.dashboard = '';
    $scope.searchForm.m_no = $rootScope.user_profile.m_no;
    // 검색영역 데이터
    $scope.searchData = angular.copy(questionSVC.searchData);
    $scope.searchData.totalCount = 0;       // 검색전체 건수
    $scope.searchData.selectCount = 0;      // 선택한 건수 <- 작업예정
    $scope.searchData.showCount = 25;       // 한 페이지에 보이는 건수
    const searchDetail = [
      {
        title: gettextCatalog.getString('상태 선택'),
        search_name: 'board_status',
        item_list: [
          { key: '접수대기', name: '접수대기' },
          { key: '접수완료', name: '접수완료' },
          { key: '답변지연', name: '답변지연' },
          { key: '답변완료', name: '답변완료' }
        ],
        item_key: 'key', // 키
        item_value: 'name', // 벨류
        select_value: '' //기본 값
      },
      {
        title: gettextCatalog.getString('문의유형 선택'),
        search_name: 'inquiry_type',
        item_list: [
          { key: '탈퇴 ', name: '탈퇴' },
          { key: '가입', name: '가입' },
          { key: '결제', name: '결제' },
          { key: '주문', name: '주문' },
          { key: '통계', name: '통계' },
          { key: '문의', name: '문의' },
          { key: '상품', name: '상품' },
          { key: '재고', name: '재고' },
          { key: '정산', name: '정산' },
          { key: '연동', name: '연동' },
          { key: '기타', name: '기타' },
          { key: '작업', name: '작업' },
          { key: '교육', name: '교육' },
        ],
        item_key: 'key',
        item_value: 'name',
        select_value: ''
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      }
    };

    /**
   * 데이터테이블 pageLength 조절
   * @param number
   */
    $scope.changeCount = function (number) {
      $scope.searchData.showCount = number;
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
   * 검색
   */
    $scope.searchDo = function (refresh = true, noDelay, dashboard) {
      fromDashboard = dashboard;
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
   * 검색 초기화
   */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(questionSVC.searchForm);
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.selectCount = 'my_question';
      $scope.searchDo(true, true);
    };

    /**
   * 우측 상단 버튼
   */
    /////////////////////
    const question = gettextCatalog.getString('1:1 문의하기');

    // 검색영역 버튼 설정
    $scope.searchBtn = {
    // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-clipboard pl-10 text-size-25"></i> ${question}`,
          small_label: '문의하기',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-12',
          action: function() {
            $scope.question();
          }
        }
      ]
    };

    /**
   * 1:1 문의하기 버튼 모달 2017-Halloween pada
   */
    $scope.question = function () {
      // 모달이 이미 열려있을 경우
      if ($rootScope.questionModalOpen) {
        $rootScope.draggableModalZindex('question');
        $rootScope.$broadcast('questionFoldOpen');
      } else {
        $scope.openQuestionModal();

        // 쇼핑몰별 주의사항 모달 열려있으면 z-index 다시 조정
        if ($rootScope.shopModalOpen) {
          $timeout(() => {
            $rootScope.draggableModalZindex('question');
          }, 100);
        }
      }
    };

    $scope.openQuestionModal = (from) => {
      // 1:1 문의 모달만 esc로 닫히지 않게 설정 (1:1문의 하기 모달이 닫힐 때 나오는 컨펌창을 esc로 꺼버리면 같이 적용되기 때문) - Alvin
      const modal = commonSVC.openModal('xg', '', 'QuestionInquiryCtrl', 'views/question/modals/inquiry.html', false, true, false, from);

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
   * 1:1 문의리스트 상세 버튼 모달 2017-11-1 pada
   */
    $scope.detail = function(cs_no, pa_cs_no) {
      const input = {};

      input.data = {
        cs_no: cs_no,
        pa_cs_no: pa_cs_no
      };
      const modal = commonSVC.openModal('xg', input, 'QuestionDetailsCtrl', 'views/question/modals/detail.html');

      modal.result.then(function(e) {

        if (e === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
   * 카운팅에 맞는 검색 결과
   */
    $scope.countSearch = function (type) {
    // if ($scope.countList[type] !== 0 ) {
      const sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;

      // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
      // $scope.searchForm = angular.copy(questionSVC.searchForm);
      // $scope.searchDetail = angular.copy(searchDetail)
      $scope.searchForm.sdate = sdate;
      $scope.searchForm.edate = edate;
      switch (type) {
        case 'my_question':
          $scope.searchForm.dashboard = '';
          break;
        case 'waiting':
          $scope.searchForm.dashboard = '접수대기';
          break;
        case 'complete':
          $scope.searchForm.dashboard = '접수완료';
          break;
        case 'reply_postphone':
          $scope.searchForm.dashboard = '답변지연';
          break;
        case 'reply_complete':
          $scope.searchForm.dashboard = '답변완료';
          break;
      }

      $scope.searchDo(true, true, true);
      $scope.selectCount = type;
    // }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['cs_no', 'board_status', 'board_type', 'wname', 'depot_name', 'wdate', 'reply_time'],
      alignRightColumns: [],
      defaultSortingColumns: ['cs_no'],
      notSortingColumns: ['board_status', 'board_type', 'title'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/inquiries/list`,
        requestWillAction: function (data) {
          data = angular.merge({}, data, $scope.searchForm);

          if (!fromDashboard) {
            init();
          }

          return data;
        },
        requestDidAction: function (result) {
          total_count = result.recordsTotal;
          $scope.searchData.totalCount = total_count;

          return result.results;
        }
      },
      columns: [
        {
          key: 'cs_no',
          title: '문의 번호',
          width: 100
        },
        {
          key: 'board_status',
          title: '문의 상태',
          width: 132,
          template: function (row) {
            if (['접수취소', '접수완료', '처리완료', '결재반려'].includes(row.board_status) || (row.charge_dept_no && row.board_status === '접수대기')) {
              return '처리중';
            } else if (row.board_status === '답변지연') {
              return '추가검토중';
            }

            return row.board_status;
          }
        },
        {
          key: 'board_type',
          title: '문의 유형',
          width: 131
        },
        {
          key: 'title',
          title: '문의 제목',
          width: 300,
          template: function (row) {
            return `<p ng-click="grid.appScope.detail(${row.cs_no}, ${row.pa_cs_no})">${row.title}</p>`;
          }
        },
        {
          key: 'wname',
          title: '문의자',
          width: 131
        },
        {
          key: 'depot_name',
          title: '배송처명',
          width: 131,
          filter: 'isNullHyphen'
        },
        {
          key: 'wdate',
          title: '문의 일시',
          width: 160,
        },
        {
          key: 'reply_time',
          title: '답변일',
          width: 160
        }
      ]
    };

    /**
   * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
   */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    function init() {

      const data = {
        sdate: $scope.searchForm.sdate,
        edate: $scope.searchForm.edate,
        inquiry_type: $scope.searchForm.inquiry_type,
        board_status: $scope.searchForm.board_status,
        search_key: $scope.searchForm.search_key,
        search_word: $scope.searchForm.search_word,
        m_no: $scope.searchForm.m_no
      };

      questionModel.countList(data, function (state, data) {
        if (state === 'success') {
          $scope.countList = data.results[0];
          if (!initYN) {
            if ($stateParams.modal) { $scope.question(); }
            initYN++;
          }
        }
      });
    }

    // 1:1문의하기 모달 오픈
    if ($stateParams.openQuestionModal === 'true') {
      $timeout(() => {
        $scope.openQuestionModal('wakeup');
      }, 100);
    }

    /**
     * 알림에서 문의 클릭해서 넘어왔을 경우, 해당 문의 번호의 상세모달 띄워줌
     */
    if ($rootScope.question_no || $stateParams.cs_no) {
      $scope.detail($rootScope.question_no || $stateParams.cs_no);
      $rootScope.question_no = null;
    }

    // 알림에서 클릭시 처리 2019-03-25 rony
    $scope.$on('alarmSearch_question', function() {
      $scope.detail($rootScope.question_no);
      $rootScope.question_no = null;
    });
  });
