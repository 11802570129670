/**
 * 공지사항 상세보기 컨트롤러
 * 2017-10-25 Pada
 */

'use strict';

angular.module('gmpApp')
  .controller('NoticeDetailCtrl', function ($scope, $stateParams, $state, settings, $rootScope, commonSVC, gettextCatalog) {

    const coll_notice_no = $rootScope.noti_no ? $rootScope.noti_no : $stateParams.noti_no;
    const noticeType = $rootScope.noticeType ? $rootScope.noticeType : $stateParams.noticeType;

    $scope.detailData = {};

    function init(noti_no) {
      if (noticeType == 'shop' || noticeType == 'shop') { // # 쇼핑몰 공지사항
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/notice/shop/${noti_no}`, {}, function(state, data) {
          if (state === 'success') {
            $scope.detailData = data.result[0];
            $scope.renderHtml();
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('ERR'), gettextCatalog.getString('공지사항 상세보기에 실패하였습니다.'));
          }
        });
      } else if (noticeType == 'system') { // # 시스템 공지사항
        /**
         * 상세보기 내용
         * @type {string}
         */
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/notice/system/${noti_no}`, {}, function(state, data) {
          if (data.result.row[0].expose_yn == 0) { // 노출중에서 노출중ㅈㅣ으로 바뀐 공지사항일 때
          // commonSVC.showToaster("error", gettextCatalog.getString("ERR"), gettextCatalog.getString("공지사항 상세보기에 실패하였습니다."));
            $state.go('main.notice_list');
          }
          if (state === 'success') {
            // 이전글
            $scope.preDetailData = data.result.preDetailData.length > 0 ? data.result.preDetailData[0] : {};
            $scope.max_no = data.result.preDetailData.length > 0 ? $scope.preDetailData.noti_no : noti_no;

            // 다음글
            $scope.postDetailData = data.result.postDetailData.length > 0 ? data.result.postDetailData[0] : {};
            $scope.min_no = data.result.postDetailData.length > 0 ? $scope.postDetailData.noti_no : noti_no;

            $scope.detailData = data.result.row[0];
            //파일을 view에서 보여주기 위한 변환
            if ($scope.detailData.file.length > 0) {
              const json_file = $scope.detailData.file.split('#');
              const json = [];

              angular.forEach(json_file, function(v) {
                json.push(JSON.parse(v));
              });
              $scope.detailData.file = json;
            }
            $scope.renderHtml();
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('ERR'), gettextCatalog.getString('공지사항 상세보기에 실패하였습니다.'));
          }
        });
      } else if (noticeType == 'sub') {
        /**
         * 상세보기 내용
         * @type {string}
         */
        const url = `${settings.pa20ApiUrl}/app/notice/sub/${noti_no}`;
        commonSVC.sendUrl('GET', url, {}, function(state, data) {

          // if (data.result.row[0].expose_yn == 0) { // 노출중에서 노출중ㅈㅣ으로 바뀐 공지사항일 때
          //   // commonSVC.showToaster("error", gettextCatalog.getString("ERR"), gettextCatalog.getString("공지사항 상세보기에 실패하였습니다."));
          //   $state.go('main.notice_list');
          // }
          if (state === 'success') {
            // 이전글
            $scope.preDetailData = data.results.preDetailData.length > 0 ? data.results.preDetailData[0] : {};
            $scope.max_no = data.results.preDetailData.length > 0 ? $scope.preDetailData.noti_no : noti_no;

            // 다음글
            $scope.postDetailData = data.results.postDetailData.length > 0 ? data.results.postDetailData[0] : {};
            $scope.min_no = data.results.postDetailData.length > 0 ? $scope.postDetailData.noti_no : noti_no;

            $scope.detailData = data.results.row[0];
            //파일을 view에서 보여주기 위한 변환
            if ($scope.detailData.file.length > 0) {
              const json_file = $scope.detailData.file.split('#');
              const json = [];

              angular.forEach(json_file, function(v) {
                json.push(JSON.parse(v));
              });
              $scope.detailData.file = json;
            }
            $scope.renderHtml();
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('ERR'), gettextCatalog.getString('공지사항 상세보기에 실패하였습니다.'));
          }
        });
      }
    }
    init(coll_notice_no);

    $scope.renderHtml = function () {
      $('#notice-content').html($scope.detailData.content);
    };

    /**
   * 이전 다음 글
   */
    $scope.showDetail = function (noti_no) {
      init(noti_no);
    };

    /**
   * 공지사항 목록보기
   */
    $scope.showList = function() {
      if (noticeType == 'shop') {
        $rootScope.noticeListShop = true;
      }
      $state.go('main.notice_list');
    };
  });
