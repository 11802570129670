/**
 * Created by harry on 2017. 6. 23..
 */

'use strict';

angular.module('gmpApp')
  .service('stateModel', function ($q, commonSVC, settings) {
    return {

      stateExcelFieldList: function (params) {
        const url = `${settings.pa20ApiUrl}/app/calculate/state/getExcelField`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 정산 수동업로드 엑셀 양식 등록/수정
       */
      uploadSettExcelTemplate: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/calculate/state/excel-template`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 정산 상세내역 엑셀 다운로드
       */
      downExcel: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/calculate/state/detailExcelDown`;

        commonSVC.sendUrl('POST', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 정산 상세내역 삭제
       * @param number DB 번호
       */
      delete: function (params) {
        const url = `${settings.pa20ApiUrl}/app/calculate`;

        return commonSVC.sendUrl('DELETE', url, params);
      },
    };
  });
