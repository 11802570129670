'use strict';
/**
 * 다국어 언어 변경 서비스
 *
 * 2017-03-22 MatthewKim
 */
angular.module('gmpApp')
  .factory('langSVC', function (gettextCatalog, settings, amMoment, $rootScope, localStorageService, $cookies) {
    // 서비스 리턴
    var __master = {

      /**
       * 마지막 선택했던 언어 설정 값에 있는 것으로 랭기지 설정 (비동기)
       */
      setCurrentAsync: function(sol_type) {
        // 2017-05-10 ally 홈페이지에서 로그인해서 솔루션으로 넘어갈 시 홈페이지에서 설정한 언어 값으로 솔루션 언어 셋팅
        const cookies = $cookies.get('preSettingLang');

        if (cookies) {
          localStorageService.set('__lang', cookies);
          $cookies.remove('preSettingLang', { domain: '.hirogete.com' });
        }

        // 2017-08-09 ally 임시하드코딩
        let lang = '';

        if (sol_type == 'HIROGETE') {
          lang = 'ja';
        } else {
          lang = localStorageService.get('__lang');
        }

        // todo 기본값을 ko 고정이 아닌 접속한 브라우저의 언어를 파악하여 변경해야함 2017-03-22 MatthewKim
        if (!lang) {
          // 솔루션별 기본 언어설정함 2018-02-06 chris
          lang = sol_type == 'HIROGETE' ? 'ja' : 'ko';
        }

        return __master.changeLangAsync(lang, false);
      },

      /**
       * 지정한 랭기지 코드로 랭기지 변경
       * @param toLangCode
       */
      changeLangAsync: function(toLangCode, reloadPage) {

        // $log.debug(toLangCode);

        const toLang = settings.langs[toLangCode];

        if (!toLang) {
          return;
        }

        // 현재 언어 설정
        localStorageService.set('__lang', toLangCode);

        // 페이지 리로딩 옵션은 위에 스토리지에 보관하고 새로고침 한 후에 아래가 진행 되므로 여기까지만 하면 됨
        if (reloadPage) {
          location.reload();

          return;
        }

        // ----------------- 이후 처리
        settings.current_lang = toLang;

        // 저장된 언어별 폰트 설정 로딩
        let font = localStorageService.get('__font');

        // 저장된 폰트가 없으면 언어별 기본값 설정
        if (!font) {
          font = {};
          _.each(settings.langs, function(lang) {
            const key = __master.isMac() == true ? 'mac' : 'default';

            font[lang.lang] = settings.fonts[lang.lang][key];
          });
        }
        // $log.debug(font);
        settings.current_font = font;

        // 웹 폰트 변경
        __master.changeFont(settings.current_font[toLang.lang].code);

        // moment 언어 설정 2017-02-10 MatthewKim
        amMoment
          .changeLocale(toLang.lang);

        // moment 타임존 설정 2017-03-22 MatthewKim
        amMoment
          .changeTimezone(toLang.timezone);

        // angular-gettext 설정 2017-02-10 MatthewKim
        gettextCatalog.setCurrentLanguage(toLang.i18n.replace('_', '-'));

        return gettextCatalog.loadRemote(`/i18n/${toLang.i18n}/LC_MESSAGES/gmp.json`)
          .then(function() {

            jQuery.extend(jQuery.validator.messages, {
              required: gettextCatalog.getString('필수 항목입니다.', {}),
              remote: gettextCatalog.getString('이 필드를 해결하시기 바랍니다.'),
              email: gettextCatalog.getString('이메일 형식으로 입력해 주시기 바랍니다.'),
              url: gettextCatalog.getString('유효한 URL을 입력해 주시기 바랍니다.'),
              date: gettextCatalog.getString('유효한 날짜를 입력해 주시기 바랍니다.'),
              dateISO: gettextCatalog.getString('유효한 날짜 (ISO)를 입력하십시오.'),
              number: gettextCatalog.getString('숫자만 입력해 주시기 바랍니다.'),
              digits: gettextCatalog.getString('숫자 만 입력하십시오.'),
              creditcard: gettextCatalog.getString('유효한 신용 카드 번호를 입력하십시오.'),
              equalTo: gettextCatalog.getString('다시 동일한 값을 입력하십시오.'),
              accept: gettextCatalog.getString('유효한 확장자를 가진 값을 입력하십시오.'),
              maxlength: jQuery.validator.format(gettextCatalog.getString('{0}자 이하로 입력해 주시기 바랍니다.')),
              minlength: jQuery.validator.format(gettextCatalog.getString('{0}자 이상 입력해 주시기 바랍니다.')),
              rangelength: jQuery.validator.format(gettextCatalog.getString('{0}과 {1} 자 사이의 값을 입력하십시오.')),
              range: jQuery.validator.format(gettextCatalog.getString('{0}과 {1} 사이의 값을 입력 해주세요 .')),
              max: jQuery.validator.format(gettextCatalog.getString('최대 {0}까지 입력 가능 합니다.')),
              min: jQuery.validator.format(gettextCatalog.getString('최소 {0}부터 입력 가능 합니다.'))
            });

          });
        // $log.debug(gettextCatalog.getCurrentLanguage());
        // gettextCatalog.debug = true; // 이 옵션을 켜주면 앞에 [MISSING] 이 붙어서 찾기 쉽게 해줌

        // todo 추가로 언어 변경을 처리해야 하면 여기서 처리 2017-03-22 MatthewKim

      },

      /**
       * 마지막 선택했던 언어 설정 값에 있는 것으로 랭기지 설정
       */
      setCurrent: function(sol_type) {
        // 2017-05-10 ally 홈페이지에서 로그인해서 솔루션으로 넘어갈 시 홈페이지에서 설정한 언어 값으로 솔루션 언어 셋팅
        const cookies = $cookies.get('preSettingLang');

        if (cookies) {
          localStorageService.set('__lang', cookies);
          $cookies.remove('preSettingLang', { domain: '.hirogete.com' });
        }

        // 2017-08-09 ally 임시하드코딩
        let lang = 'ja';

        if (sol_type != 'HIROGETE') {
          // lang = localStorageService.get('__lang');
          lang = 'ko';
        }

        if (!lang) {
          lang = 'ja';
        } // todo 기본값을 ko 고정이 아닌 접속한 브라우저의 언어를 파악하여 변경해야함 2017-03-22 MatthewKim
        __master.changeLang(lang, false);
      },

      /**
       * 지정한 랭기지 코드로 랭기지 변경
       * @param toLangCode
       */
      changeLang: function(toLangCode, reloadPage) {
        // $log.debug(toLangCode);

        const toLang = settings.langs[toLangCode];

        if (!toLang) {
          return;
        }

        // 현재 언어 설정
        localStorageService.set('__lang', toLangCode);

        // 페이지 리로딩 옵션은 위에 스토리지에 보관하고 새로고침 한 후에 아래가 진행 되므로 여기까지만 하면 됨
        if (reloadPage) {
          location.reload();

          return;
        }

        // ----------------- 이후 처리
        settings.current_lang = toLang;

        // 저장된 언어별 폰트 설정 로딩
        let font = localStorageService.get('__font');

        // 저장된 폰트가 없으면 언어별 기본값 설정
        if (!font) {
          font = {};
          _.each(settings.langs, function(lang) {
            const key = __master.isMac() == true ? 'mac' : 'default';

            font[lang.lang] = settings.fonts[lang.lang][key];
          });
        }
        // $log.debug(font);
        settings.current_font = font;

        // 웹 폰트 변경
        __master.changeFont(settings.current_font[toLang.lang].code);

        // angular-gettext 설정 2017-02-10 MatthewKim
        gettextCatalog.setCurrentLanguage(toLang.i18n.replace('_', '-'));
        gettextCatalog.loadRemote(`/i18n/${toLang.i18n}/LC_MESSAGES/gmp.json`)
          .then(function() {

            jQuery.extend(jQuery.validator.messages, {
              required: gettextCatalog.getString('필수 항목입니다.', {}),
              remote: gettextCatalog.getString('이 필드를 해결하시기 바랍니다.'),
              email: gettextCatalog.getString('이메일 형식으로 입력해 주시기 바랍니다.'),
              url: gettextCatalog.getString('유효한 URL을 입력해 주시기 바랍니다.'),
              date: gettextCatalog.getString('유효한 날짜를 입력해 주시기 바랍니다.'),
              dateISO: gettextCatalog.getString('유효한 날짜 (ISO)를 입력하십시오.'),
              number: gettextCatalog.getString('숫자만 입력해 주시기 바랍니다.'),
              digits: gettextCatalog.getString('숫자 만 입력하십시오.'),
              creditcard: gettextCatalog.getString('유효한 신용 카드 번호를 입력하십시오.'),
              equalTo: gettextCatalog.getString('다시 동일한 값을 입력하십시오.'),
              accept: gettextCatalog.getString('유효한 확장자를 가진 값을 입력하십시오.'),
              maxlength: jQuery.validator.format(gettextCatalog.getString('{0}자 이하로 입력해 주시기 바랍니다.')),
              minlength: jQuery.validator.format(gettextCatalog.getString('{0}자 이상 입력해 주시기 바랍니다.')),
              rangelength: jQuery.validator.format(gettextCatalog.getString('{0}과 {1} 자 사이의 값을 입력하십시오.')),
              range: jQuery.validator.format(gettextCatalog.getString('{0}과 {1} 사이의 값을 입력 해주세요 .')),
              max: jQuery.validator.format(gettextCatalog.getString('최대 {0}까지 입력 가능 합니다.')),
              min: jQuery.validator.format(gettextCatalog.getString('최소 {0}부터 입력 가능 합니다.'))
            });
          });
        // $log.debug(gettextCatalog.getCurrentLanguage());
        // gettextCatalog.debug = true; // 이 옵션을 켜주면 앞에 [MISSING] 이 붙어서 찾기 쉽게 해줌

        // moment 언어 설정 2017-02-10 MatthewKim
        amMoment
          .changeLocale(toLang.lang);

        // moment 타임존 설정 2017-03-22 MatthewKim
        amMoment
          .changeTimezone(toLang.timezone);

        // todo 추가로 언어 변경을 처리해야 하면 여기서 처리 2017-03-22 MatthewKim

      },

      /**
       * Toaster 출력(commonsvc 사용시 login.html에 angular-init-loader 추가하면 무한호출되므로 따로 처리)
       *
       *
       * @param type
       * @param title
       * @param contents
       */
      showToaster: _.debounce(function (type, title, contents, opt) {

        let icon = '';
        // var stack_bottom_right = {"dir1": "up", "dir2": "left", "firstpos1": 25, "firstpos2": 25};

        if (type === 'success') {
          icon = 'icon-checkmark3';
        } else if (type === 'error') {
          icon = 'icon-blocked';
        } else if (type === 'warning') {
          icon = 'icon-warning22';
        } else if (type === 'info') {
          icon = 'icon-info22';
        }

        const notice = new PNotify({
          title: title,
          text: contents,
          type: type,
          icon: icon,
          buttons: {
            closer: false,
            sticker: false
          },
          addclass: opt && opt.addclass ? opt.addclass : '',
          width: opt && opt.width ? opt.width : '300px',
          // 2017-03-14 matthew 옵션 일단 뺌 (우측 상단으로)
          //addclass: "stack-top-right",
          //stack: stack_bottom_right
        });

        // 2017-03-15 chris 알림 클릭시 닫기
        notice.get().click(function() {
          notice.remove();
        });

      }, 1500),

      /**
       * 폰트 변경
       * @param toLangCode
       */
      changeFont: (() => {
        const ELEMENT_ID = 'playauto-font-stylesheet';

        return (toFontCode, toaster) => {
          const toFont = settings.fonts[settings.current_lang.lang][toFontCode];

          if (!toFont) {
            return;
          }

          settings.current_font[settings.current_lang.lang] = toFont;
          localStorageService.set('__font', settings.current_font);

          /* 이전 폰트 스타일 태그 */
          const oldFontElement = document.getElementById(ELEMENT_ID);

          /* 추가할 스타일 태그 */
          const cssElementText = `
            <style id="${ELEMENT_ID}" name="${toFont.font_name}" type="text/css">
              ${toFont.style}
            </style>
          `;

          /* 폰트가 변경되었을 경우에만 로딩 */
          if (oldFontElement) {
            const oldFontName = oldFontElement.getAttribute('name');
            const toFontName = toFont.font_name;
            if (oldFontName !== toFontName) {
              oldFontElement.parentElement.removeChild(oldFontElement);
              document.head.insertAdjacentHTML('beforeend', cssElementText);
            }
          } else {
            document.head.insertAdjacentHTML('beforeend', cssElementText);
          }

          // 환경설정에서 폰트 변경시
          if (toaster == true) {
            __master.showToaster('success', '', gettextCatalog.getString('변경사항이 저장되었습니다.'));
          }
        };
      })(),

      /**
       * 접속한 OS 구분
       *
       * 2017-03-22 MatthewKim
       * @returns {string}
       */
      detectOS: function() {
        let OSName = 'Unknown OS';

        try {
          if (navigator.appVersion.indexOf('Win') != -1) {
            OSName = 'windows';
          } else if (navigator.appVersion.indexOf('Mac') != -1) {
            OSName = 'mac';
          } else if (navigator.appVersion.indexOf('X11') != -1) {
            OSName = 'unix';
          } else if (navigator.appVersion.indexOf('Linux') != -1) {
            OSName = 'linux';
          }
        } catch (e) {
          console.log(e);
        }

        return OSName;
      },

      /**
       * 맥 인지 여부 검사
       *
       * 2017-03-22 MatthewKim
       * @returns {boolean}
       */
      isMac: function() {
        return (__master.detectOS() == 'mac');
      }

    };

    // 루트 스콥에 언어 변경 메소드 등록
    $rootScope.changeLang = __master.changeLang;
    $rootScope.changeFont = __master.changeFont;

    return __master;

  });
