'use strict';

angular.module('gmpApp')
  .factory('A118categorySVC', function () {

    return {

      //카테고리 data
      category_data: {
        set_all_shop: false, //상품적용여부
        product: '', //상품군
        certification: '0', //인증유형 여부
        cert_type: '', //인증유형
        detailData: {},
        key_DISP_CLSSF_CD: [] // 전시매장 정보
      }
    };
  });
