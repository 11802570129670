'use strict';

angular.module('gmpApp')
  .controller('HomeCalendarCtrl', function ($scope, commonSVC, settings, gettext, gettextCatalog, $rootScope, $timeout, uiCalendarConfig) {
    // 사이드바 active 처리
    // Layout.setSidebarMenuActiveLink('set', $('a[href="#/home"]'));
    // $log.debug($state.current);
    gettext('일정 관리');
    //변수 정의
    $scope.toggle = true;
    $scope.calendarData = {
      number: '',
      title: '',
      start: '',
      end: '',
      contents: '',
      location: '',
      attendee: '',
      color_index: '',
      // backgroundColor : ''
    };
    $scope.show = true;
    let tempVar = '';

    $scope.events = [];

    let mode = 'month';

    /* config object */
    $scope.uiConfig = {
      calendar: {
        eventRender: function(event, element) {
          if (event.isHoliday) {
            switch (mode) {
              case 'month':
                $('[data-date="' + event.curdate + '"]').addClass('fc-holiday');
                break;
              case 'basicWeek':
                $('.fc-day-header').eq($('[data-date="' + event.curdate + '"]')[0].cellIndex).addClass('fc-holiday');
                break;
              default:
                $('.fc-day-header').addClass('fc-holiday');
                break;
            }
          }

          element.find('div.fc-content').css('background-color', 'transparent');
          if (event.imageurl) {
            element.find('span.fc-title').prepend(`<i class="fa fa-asterisk" style="color:${event.background_color_rgb};font-size:5pt"></i>&nbsp;&nbsp;`);
          }

        },
        height: '100%',
        header: {
          left: 'today, prevYear,prev,next,nextYear, title',
          right: 'month,basicWeek,basicDay'
        },
        nextDayThreshold: '00:00:00',
        titleFormat: {
          month: gettextCatalog.getString('YYYY년 MM월'),
          week: gettextCatalog.getString('YYYY년 MM월 D일'),
          day: gettextCatalog.getString('YYYY년 MM월 D일')
        },
        allDaySlot: false,
        defaultView: 'month',
        editable: true,
        resizable: true,
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        buttonText: {
          today: gettextCatalog.getString('오늘'),
          month: gettextCatalog.getString('월별'),
          week: gettextCatalog.getString('주별'),
          day: gettextCatalog.getString('일별')
        },
        timeFormat: 'HH:mm',
        eventDurationEditable: true,
        dayClick: function(date) {
          //비어있는 달력 부분을 클릭시
          const resolve = {
            data: {
              date: date._d
            }
          };
          const modal = commonSVC.openModal('md', resolve, 'HomeCalendarAdd', 'views/home/calendar_add.html');

          modal.result.then(function(e) {
            if (e === 'success') {
              // 바로 조회시 조회가 안될떄가 있음. 0.5 초 타임아웃 걸음. 2019-02-25 rony
              $timeout(function () {
                init();
              }, 500);
            }
          });

          date.format();

          if (tempVar == '') {
            $(this).css('background-color', '#9cf');
            tempVar = this;
          } else {
            $(this).css('background-color', '#9cf');
            $(tempVar).css('background-color', 'white');
            tempVar = this;
          }
        },
        eventClick: function(calEvent, jsEvent) {
          if (!calEvent.isHoliday) {
            //일정을 클릭시 미리보기 레이어
            itemClick(jsEvent.originalEvent);
            eventPreview(calEvent.cal_no, calEvent.start._i);
          }
        },
        eventDrop: function(event) {
          eventEdit(event, 'drop');
        },
        eventResize: function(event) {
          eventEdit(event, 'resize');
        },
        viewRender: function (view, element) {
          mode = view.name;
          init(view);
        },
      }
    };

    //일정 미리보기
    function eventPreview(cal_no, start) {
      const url = `${settings.pa20ApiUrl}/app/home/calendar/preview`;
      const params = { start };

      commonSVC.sendUrl('GET', url, params, function (state, data) {
        //data는 조회의 결과
        if (state == 'success') {
          $scope.preview = data;
          $scope.cal_no = cal_no;
          $scope.start_date = start;
        }
      });
    }
    //미리보기 레이어 위치잡기////
    //기준을 잡아서 포지션 값을 임의로 넣는것
    function abspos(e) {
      e.view.x = e.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft) + 150;
      e.view.y = e.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);

      // x 좌표가 우측 화면 밖으로 나가는 문제가 있어 조건 추가
      if (e.view.x - document.body.clientWidth > 0 && e.view.x - document.body.clientWidth < 140) {
        e.view.x = document.body.clientWidth - 120;
      }

      return e.view;
    }
    //미리보기 창
    function itemClick(e) {
      const ex_obj = document.getElementById('calendarPreview');
      const obj = $('#calendarPreview');

      if (!e) {
        e = window.Event;
      }

      const pos = abspos(e);

      ex_obj.style.left = `${pos.x - 220}px`;
      ex_obj.style.top = `${pos.y - 113}px`;

      $('html').click(function(e) {
        if (!$(e.target).hasClass('fc-content') && !$(e.target).hasClass('fc-title')) {
          obj.css('display', 'none');
        }
      });

      ex_obj.style.display = ex_obj.style.display == 'none' ? 'block' : 'none';

    }
    /////END/////

    function eventEdit(event, type) {
      $scope.calendarData.number = event.cal_no;

      let start, end, check;

      if (type === 'drop') {
        start = `${event.start.format()} ${event.temp_time_start}`;

        if (!event.end) {
          end = `${event.start.format()} ${event.temp_time_end}`;
        } else {
          check = 1;
        }
      } else if (type === 'resize') {
        check = 1;
        start = `${event.start.format()} ${event.temp_time_start}`;
      }

      if (check === 1) {
        const a = moment(event.end.format());
        const b = a.subtract(1, 'days');
        const c = commonSVC.getDate(b.format(), 'yyyy-MM-dd');

        end = `${c} ${event.temp_time_end}`;
      }

      $scope.calendarData.start = start;

      if (event.end) {
        $scope.calendarData.end = end;

      }
      $scope.calendarData.title = event.title;
      $scope.calendarData.contents = event.contents;
      $scope.calendarData.location = event.location;
      $scope.calendarData.attendee = event.attendee;
      $scope.calendarData.color_index = event.color_index;
      $scope.calendarData.backgroundColor = event.background_color_rgb;

      const url = `${settings.pa20ApiUrl}/app/home/calendar/edit`;

      commonSVC.sendUrl('PUT', url, $scope.calendarData, function (state) {
        if (state == 'success') {
          commonSVC.showToaster('success', '', '수정이 완료 되었습니다.');
          init();
        }
      });

    }

    /* event sources array*/
    $scope.eventSources = [
      {
        events: []
      }
    ];

    function init(pageDate = null) {
      pageDate = pageDate || uiCalendarConfig.calendars.eventCal.fullCalendar('getView');

      setButtonHint();

      const url = `${settings.pa20ApiUrl}/app/home/calendar/list`;
      const searchRange = {
        start: pageDate.start.format('YYYY-MM-DD'),
        end: pageDate.end.format('YYYY-MM-DD')
      };

      commonSVC.sendUrl('GET', url, searchRange, function (state, data) {
        if (state == 'success') {
          $scope.eventSources[0].events.splice(0, $scope.eventSources[0].events.length);

          // 공휴일 일정 등록
          angular.forEach(data.results.holidays, function(e) {
            e.title = e.holiday_name;
            e.color = 'white';
            e.textColor = 'red';
            e.start = e.curdate,
            e.allDay = true;
            e.editable = false;
            e.isHoliday = true;
            $scope.eventSources[0].events.push(e);
          });

          // 사용자 일정 등록
          angular.forEach(data.results.schedule, function(e) {
            const allDay = e.start.split(' ')[1] === '00:00:00' && e.end.split(' ')[1] === '23:50:00';

            e.stick = true;
            let end = moment(e.end).add(1, 'day');

            e.temp_time_start = commonSVC.getDate(e.start, 'HH:mm:ss');
            e.temp_time_end = commonSVC.getDate(e.end, 'HH:mm:ss');
            e.start = commonSVC.getDate(e.start, 'yyyy-MM-dd');
            e.end = end.format('YYYY-MM-DD');

            //일정 라벨 색상 정하기
            const sdate = new Date(e.start).getTime(); //object
            const edate = new Date(moment(end).subtract(1, 'day').format('YYYY-MM-DD')).getTime();

            end = new Date(e.end).getTime();

            //하루
            if (sdate === edate && !allDay) {
              e.color = 'white';
              e.imageurl = true;
            } else { // 하루 이상
              e.backgroundColor = e.background_color_rgb;
            }

            //일정 텍스트 색상 정하기
            e.textColor = 'black';
            $scope.eventSources[0].events.push(e);
          });
        }
      });
    }

    function setButtonHint() {
      switch (mode) {
        case 'month':
          $('.fc-prev-button').attr('title', '전 달');
          $('.fc-next-button').attr('title', '다음 달');
          break;
        case 'basicWeek':
          $('.fc-prev-button').attr('title', '전 주');
          $('.fc-next-button').attr('title', '다음 주');
          break;
        default:
          $('.fc-prev-button').attr('title', '전날');
          $('.fc-next-button').attr('title', '다음날');
          break;
      }
      $('.fc-prevYear-button').attr('title', '이전 연도');
      $('.fc-nextYear-button').attr('title', '다음 연도');
    }

    $scope.$on('editGlobal', function () {
      init();
    });
  })

/*
     *  캘린더 내용 추가
     * */
  .controller('HomeCalendarAdd', function ($rootScope, $scope, $uibModalInstance, data, commonSVC, settings, gettextCatalog) {

    function init() {

      const sdate = commonSVC.getDate(data.date, 'yyyy-MM-dd 00:00:00');
      const edate = commonSVC.getDate(data.date, 'yyyy-MM-dd 23:50:00');

      $scope.calendarData = {
        title: '',
        contents: '',
        location: '',
        attendee: '',
        sdate: sdate,
        edate: edate,
        color_index: '',
        // backgroundColor : '',
        hour1: '00',
        hour2: '00',
        all_day: false,
        minute1: '00',
        minute2: '00'
      };

      $scope.collection = [
        { index: 0, color: 'rgba(84, 132, 237,0.5)' },
        { index: 1, color: 'rgba(164, 189, 252,0.5)' },
        { index: 2, color: 'rgba(70, 214, 219,0.5)' },
        { index: 3, color: 'rgba(122, 231, 191,0.5)' },
        { index: 4, color: 'rgba(81, 183, 73,0.5)' },
        { index: 5, color: 'rgba(251, 215, 91,0.5)' },
        { index: 6, color: 'rgba(255, 184, 120,0.5)' },
        { index: 7, color: 'rgba(255, 136, 124,0.5)' },
        { index: 8, color: 'rgba(220, 33, 39,0.5)' },
        { index: 9, color: 'rgba(219, 173, 255,0.5)' },
        { index: 10, color: 'rgba(225, 225, 225,0.5)' }
      ];

    }

    init();

    $scope.labelClick = labelClick;

    const border_style = 'border: 2px solid black';
    let temp_index;

    function labelClick(index) {
      if (temp_index != undefined) {
        $scope.collection[temp_index].border_style = '';
      }

      temp_index = index;

      //캘린더데이터 인덱스값 저장
      $scope.calendarData.color_index = index;
      $scope.calendarData.backgroundColor = $scope.collection[index].color;

      //border 색상 변경
      $scope.collection[index].border_style = border_style;
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function () {

      const ss = `${$scope.calendarData.sdate} ${$scope.calendarData.hour1}:${$scope.calendarData.minute1}:00`;
      const ee = `${$scope.calendarData.edate} ${$scope.calendarData.hour2}:${$scope.calendarData.minute2}:00`;
      const ssObject = moment(ss, 'YYYY-MM-DD A hh:mm:ss');
      const eeObject = moment(ee, 'YYYY-MM-DD A hh:mm:ss');
      const finSsObject = ssObject.format('YYYY-MM-DD HH:mm:ss');
      const finEeObject = eeObject.format('YYYY-MM-DD HH:mm:ss');

      $scope.calendarData.sdate = finSsObject;
      $scope.calendarData.edate = finEeObject;

      if ($scope.calendarData.all_day) {
        $scope.calendarData.start = commonSVC.getDate($scope.calendarData.sdate, 'yyyy-MM-dd 00:00:00');
        $scope.calendarData.end = commonSVC.getDate($scope.calendarData.edate, 'yyyy-MM-dd 23:50:00');

      } else {
        const start = $scope.calendarData.sdate;

        $scope.calendarData.start = start;
        const end = $scope.calendarData.edate;

        $scope.calendarData.end = end;

        if (start > end) {
          commonSVC.showMessage(gettextCatalog.getString('기간을 확인해주세요.'));

          return false;
        }
      }

      if (!$scope.calendarData.title) {
        commonSVC.showMessage(gettextCatalog.getString('일정명을 입력해 주세요'));

        return false;
      }

      if ($scope.calendarData.color_index === '') {
        commonSVC.showMessage(gettextCatalog.getString('라벨 색상을 선택해주세요.'));

        return false;
      }

      const url = `${settings.pa20ApiUrl}/app/home/calendar/add`;

      commonSVC.sendUrl('POST', url, $scope.calendarData, function (state) {
        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('등록이 완료되었습니다.'));
          $uibModalInstance.close('success');
        }
      });
    };
  })

/*
     *  캘린더 내용 수정
     * */
  .controller('HomeCalendarEdit', function ($rootScope, $scope, $uibModalInstance, data, commonSVC, settings, gettextCatalog) {
    $scope.userList = [];
    //data는 글 번호
    function init() {
      $scope.collection = [
        { index: 0, color: 'rgba(84, 132, 237,0.5)' },
        { index: 1, color: 'rgba(164, 189, 252,0.5)' },
        { index: 2, color: 'rgba(70, 214, 219,0.5)' },
        { index: 3, color: 'rgba(122, 231, 191,0.5)' },
        { index: 4, color: 'rgba(81, 183, 73,0.5)' },
        { index: 5, color: 'rgba(251, 215, 91,0.5)' },
        { index: 6, color: 'rgba(255, 184, 120,0.5)' },
        { index: 7, color: 'rgba(255, 136, 124,0.5)' },
        { index: 8, color: 'rgba(220, 33, 39,0.5)' },
        { index: 9, color: 'rgba(219, 173, 255,0.5)' },
        { index: 10, color: 'rgba(225, 225, 225,0.5)' }
      ];
      $scope.calendarData = {
        number: data,
        hour1: '00',
        hour2: '00',
        all_day: false,
        minute1: '00',
        minute2: '00'
      };
      // select2
      $scope.$watch('$viewContentLoaded', function() {

        $('.reg-channel-select1').change(function () {
          $scope.calendarData.attendee = $(this).val() || [];
          $scope.$apply();
        });
        $('.reg-channel-select2').change(function () {
          $scope.calendarData.attendee = $(this).val() || [];
          $scope.$apply();
        });
      });

      const url = `${settings.pa20ApiUrl}/app/home/calendar/detail/${data}`;

      commonSVC.sendUrl('GET', url, {}, function (state, data) {
        if (state == 'success') {
          const detail = data[0];
          $scope.calendarData.all_day = moment(detail.start).format('HHmm') === '0000' && moment(detail.end).format('HHmm') === '2350';
          $scope.calendarData.title = detail.title;
          $scope.calendarData.contents = detail.contents;
          $scope.calendarData.backgroundColor = detail.background_color_rgb;
          $scope.calendarData.attendee = detail.attendee;
          $scope.calendarData.location = detail.location;
          $scope.calendarData.color_index = detail.color_index;
          const start = commonSVC.getDate(detail.start, 'yyyy-MM-dd');
          const end = commonSVC.getDate(detail.end, 'yyyy-MM-dd');
          const hour1 = commonSVC.getDate(detail.start, 'HH');
          const hour2 = commonSVC.getDate(detail.end, 'HH');

          $scope.calendarData.sdate = start;
          $scope.calendarData.noon1 = commonSVC.getDate(detail.start, 'HH') > 12 ? '오후' : '오전';
          $scope.calendarData.hour1 = hour1.toString();
          $scope.calendarData.minute1 = commonSVC.getDate(detail.start, 'mm');

          $scope.calendarData.edate = end;
          $scope.calendarData.noon2 = commonSVC.getDate(detail.end, 'HH') > 12 ? '오후' : '오전';
          $scope.calendarData.hour2 = hour2.toString();
          $scope.calendarData.minute2 = commonSVC.getDate(detail.end, 'mm');

          $scope.collection[detail.color_index].border_style = border_style;
          temp_index = detail.color_index;

        }
      });
    }
    init();

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    var border_style = 'border: 2px solid black';
    let temp_index;

    $scope.labelClick = labelClick;
    function labelClick(index) {
      if (temp_index != undefined) {

        $scope.collection[temp_index].border_style = '';
      }

      temp_index = index;
      //캘린더데이터 인덱스값 저장
      $scope.calendarData.color_index = index;
      $scope.calendarData.backgroundColor = $scope.collection[index].color;
      //border 색상 변경
      $scope.collection[index].border_style = border_style;
    }

    $scope.ok = function () {
      const ss = `${$scope.calendarData.sdate} ${$scope.calendarData.hour1}:${$scope.calendarData.minute1}:00`;
      const ee = `${$scope.calendarData.edate} ${$scope.calendarData.hour2}:${$scope.calendarData.minute2}:00`;
      const ssObject = moment(ss, 'YYYY-MM-DD A hh:mm:ss');
      const eeObject = moment(ee, 'YYYY-MM-DD A hh:mm:ss');
      const finSsObject = ssObject.format('YYYY-MM-DD HH:mm:ss');
      const finEeObject = eeObject.format('YYYY-MM-DD HH:mm:ss');

      $scope.calendarData.sdate = finSsObject;
      $scope.calendarData.edate = finEeObject;

      if ($scope.calendarData.all_day) {
        $scope.calendarData.start = commonSVC.getDate($scope.calendarData.sdate, 'yyyy-MM-dd 00:00:00');
        $scope.calendarData.end = commonSVC.getDate($scope.calendarData.edate, 'yyyy-MM-dd 23:50:00');
      } else {
        const start = $scope.calendarData.sdate;

        $scope.calendarData.start = start;
        const end = $scope.calendarData.edate;

        $scope.calendarData.end = end;

        if (start > end) {
          commonSVC.showMessage(gettextCatalog.getString('기간을 확인해주세요.'));

          return false;
        }

      }

      //시작 날짜, a hh:mm

      const url = `${settings.pa20ApiUrl}/app/home/calendar/edit`;

      commonSVC.sendUrl('PUT', url, $scope.calendarData, function (state) {
        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('수정이 완료되었습니다.'));
          $uibModalInstance.close('success');
          $rootScope.$emit('edit');
          // $state.reload();
        }
      });
    };

    $scope.delete = function () {
      const url = `${settings.pa20ApiUrl}/app/home/calendar/delete`;

      commonSVC.sendUrl('DELETE', url, { number: data }, function (state) {
        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('삭제 완료'));
          $uibModalInstance.close('success');
          $rootScope.$emit('edit');
          // $state.reload();
        }
      });
    };

  });

