'use strict';
angular.module('gmpApp')
  .controller('CalculateDetailListCtrl', function ($scope, $rootScope, stateSVC, commonSVC, gettextCatalog, $compile,
    settings, $filter, stateModel, systemList, userInfo) {
    // 사용중인 쇼핑몰
    const channelListArr = $rootScope.useChannelList({ site_action: 'ScrapPayBack' });

    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    $scope.excelData = {};
    let selectedAllRowList = []; // 테이블에서 선택된 리스트

    /**
     * 서치바 관련 변수
     */
    // 검색영역 폼
    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'sett_com_date',
      sdate: '2001-01-01',
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      multi_search_word: '', //멀티서치워드,
      multi_type: 'shop_ord_no' //멀티서치 타입
    };
    $scope.searchForm = angular.copy(searchForm);

    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    // 검색영역 데이터
    const searchData = {
      showSearchArea: true,
      showDetailSearchArea: true,
      showCount: 500,
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수
      search_key_items: [  // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('구매자명'), value: 'order_name' }
      ],
      search_multi_items: [
        { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' }
      ],
      search_date_type: [
        { label: gettextCatalog.getString('정산완료일'), value: 'sett_com_date' }
      ]
    };

    $scope.searchData = angular.copy(searchData);

    //상세검색 데이터
    const searchDetail = [
      {
        // 쇼핑몰 선택
        title: gettextCatalog.getString('쇼핑몰 선택'),
        search_name: 'shop_cd',
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      {
        // 계정 선택
        title: gettextCatalog.getString('계정 선택'),
        search_name: 'shop_id',
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
        }
      },
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    // 데이터 테이블 관련기능
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    // 검색영역 버튼
    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: [
        {
          label: `<i class="picon-get"></i><span>${gettextCatalog.getString('정산 수집')}<span class="caret"></span>`,
          small_label: '정산수집',
          sub_label: '',
          btn_type: 'dropdown',
          add_class: 'col-xs-4',
          item_list: [
            {
              label: gettextCatalog.getString('정산 자동 수집'),
              hotkey: '',
              action: function () {
                // 정산수집 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateCollect();
                }
              }
            },
            {
              label: `${gettextCatalog.getString('정산 수동 업로드')}`,
              small_label: '정산 수동 업로드',
              hotkey: '',
              action: function () {
                if (commonSVC.checkPermission('calculate.roles.scrapCalculate', userInfo.permission)) {
                  $scope.calculateExcelUpload();
                }
              }
            }
          ]
        },
        {
          label: `<i class="picon-money-calendar"></i><span>${gettextCatalog.getString('정산 캘린더')}`,
          small_label: '정산캘린더',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-4',
          action: function() {
            $scope.calculateCalenderModal();
          }
        },
        {
          label: `<i class="picon-calculation"></i><span>${gettextCatalog.getString('통합 정산내역')}`,
          small_label: '정산내역',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-4',
          action: function () {
            $scope.excelDownSelect();
          }
        }
      ],
      table_actions: [
        {
          label: gettextCatalog.getString('삭제'),
          add_class: 'a-btn-red',
          test_id: 'btn-delete',
          style: 'width: 80px',
          id: 'btn-delete',
          action: function () {
            $scope.deleteSett();
          },
          perm_only: ['order.customer+write']
        }
      ]
    };

    $scope.grid = {
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['sett_type', 'sale_cnt'],
        alignRightColumns: [
          'sales_unit', 'sales', 'pay_amt', 'ship_cost',
          'ship_cost2', 'refund_ship_cost', 'claim_refund_sales',
          'sales_fee', 'fee_rate', 'sett_plan_price',
          'sett_price', 'deduct_price', 'shop_burden_discount_amt',
          'seller_burden_discount_amt', 'adv_fee'
        ],
        defaultSortingColumns: ['sett_com_date'], //3
        notSortingColumns: [],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [
          'wdate', 'pay_time', 'pay_confirm_date',
          'refund_date', 'sett_plan_date', 'ori_shop_ord_no',
          'ord_pay_no', 'shop_opt_name', 'sale_cnt',
          'sales_unit', 'pay_amt', 'ship_cost',
          'ship_cost2', 'refund_ship_cost', 'claim_refund_sales',
          'sales_fee', 'fee_rate', 'sett_plan_price',
          'shop_burden_discount_amt', 'seller_burden_discount_amt', 'adv_fee',
          'biz_reg_no', 'misc_etc', 'shop_sale_no', 'shop_channel'
        ],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/calculate/listOfDetail`,
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);
            doResetBtn();

            return data;
          },
          requestDidAction: function(result) {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          // 쇼핑몰(ID)
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              return `<span uib-tooltip="${row.shop_name}(${shop_info[0]})" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
            }
          },
          // 주문번호
          {
            key: 'shop_ord_no',
            title: '주문번호',
            width: 150
          },
          // 정산구분
          {
            key: 'sett_type',
            title: '정산구분',
            width: 100
          },
          // 상품명
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            filter: 'whiteSpace'
          },
          // 구매자명
          {
            key: 'order_name',
            title: '구매자명',
            width: 100
          },
          // 정산완료일
          {
            key: 'sett_com_date',
            title: '정산완료일',
            width: 120,
            filter: 'dateValid'
          },
          // 판매금액
          {
            key: 'sales',
            title: '판매금액',
            width: 150,
            tooltip: '할인이 적용되지 않은 판매가',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.sales, '', 0);
            }
          },
          // 공제금액
          {
            key: 'deduct_price',
            title: '공제금액',
            width: 150,
            tooltip: '공제금액으로 매칭된 값 또는 ‘판매금액’ - ‘정산금액’',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.deduct_price ? row.deduct_price : row.sales - row.sett_price, '', 0);
            }
          },
          // 정산금액
          {
            key: 'sett_price',
            title: '정산금액',
            width: 150,
            tooltip: '정산이 완료된 금액',
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.sett_price, '', 0);
            }
          },
          // 정산수집일
          {
            key: 'wdate',
            title: '정산수집일',
            width: 120,
            filter: 'dateValid'
          },
          // 결제완료일
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 120,
            filter: 'dateValid'
          },
          // 매출확정일
          {
            key: 'pay_confirm_date',
            title: '매출확정일',
            width: 120,
            filter: 'dateValid'
          },
          // 환불일
          {
            key: 'refund_date',
            title: '환불일',
            width: 120,
            filter: 'dateValid'
          },
          // 정산예정일
          {
            key: 'sett_plan_date',
            title: '정산예정일',
            width: 120,
            filter: 'dateValid'
          },
          // 원주문번호
          {
            key: 'ori_shop_ord_no',
            title: '원주문번호',
            width: 120,
          },
          // 결제번호
          {
            key: 'ord_pay_no',
            title: '결제번호',
            width: 120,
          },
          // 쇼핑몰상품코드
          {
            key: 'shop_sale_no',
            title: '쇼핑몰상품코드',
            width: 120,
          },
          // 옵션명
          {
            key: 'shop_ord_etc1',
            title: '옵션명',
            width: 120,
          },
          // 수량
          {
            key: 'sale_cnt',
            title: '수량',
            width: 70,
          },
          // 판매단가
          {
            key: 'sales_unit',
            title: '판매단가',
            width: 120,
          },
          // 실결제금액
          {
            key: 'pay_amt',
            title: '실결제금액',
            width: 120,
          },
          // 배송비
          {
            key: 'ship_cost',
            title: '배송비',
            width: 120,
          },
          // 추가배송비
          {
            key: 'ship_cost2',
            title: '추가배송비',
            width: 120,
          },
          // 환불배송비
          {
            key: 'refund_ship_cost',
            title: '환불배송비',
            width: 120,
          },
          // 환불금액
          {
            key: 'claim_refund_sales',
            title: '환불금액',
            width: 120,
          },
          // 판매수수료
          {
            key: 'sales_fee',
            title: '판매수수료',
            width: 120,
          },
          // 판매수수료율
          {
            key: 'fee_rate',
            title: '판매수수료율',
            width: 120,
          },
          // 정산예정금액
          {
            key: 'sett_plan_price',
            title: '정산예정금액',
            width: 120,
          },
          // 쇼핑몰할인금액
          {
            key: 'shop_burden_discount_amt',
            title: '쇼핑몰할인금액',
            width: 120,
          },
          // 판매자할인금액
          {
            key: 'seller_burden_discount_amt',
            title: '판매자할인금액',
            width: 120,
            notCompile: true,
          },
          // 광고비금액
          {
            key: 'adv_fee',
            title: '광고비금액',
            width: 120,
          },
          // 판매채널
          {
            key: 'shop_channel',
            title: '판매채널',
            width: 120,
          },
          // 사업자번호
          {
            key: 'biz_reg_no',
            title: '사업자번호',
            width: 120,
          },
          // 기타
          {
            key: 'misc_etc',
            title: '기타',
            width: 120,
            template: function(row) {
              return row.misc_etc && row.misc_etc.title;
            },
          },
        ]
      }
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    /********** 함수 목록 **********/

    /**
     * 일반 버튼 초기화
     */
    function doResetBtn() {
      $('#btn-delete').attr('disabled', true).html('삭제');
    }

    // 검색
    $scope.searchDo = function(refresh = true, noDelay) {
      doResetBtn();
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    // 검색 초기화
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchDetail = angular.copy(searchDetail);

      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }

      $scope.searchDo();
    };

    // 정산 캘린더 모달 열기
    $scope.calculateCalenderModal = function () {
      const templatePath = 'views/calculate/modals/calendar.html',
            resolve = {
            };

      commonSVC.openModal('x3g', resolve, 'CalculateCalendarCtrl', templatePath);
    };

    // 데이터테이블 pageLength 조절
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 엑셀 다운
    $scope.excelDownSelect = function () {

      // 정산 엑셀 다운로드 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('calculate.roles.excelCalculate', userInfo.permission) === false) {
        return false;
      }

      //excelData.down_list = angular.copy(stateSVC.excelDownList);

      const title = '통합 정산내역 다운로드';
      const contents = `정산완료일 ${$scope.searchForm.sdate} ~ ${$scope.searchForm.edate} 의 모든 정산 내역을 다운로드 합니다.`;
      const buttons = [
        {
          name: 'xlsx',
          next: function() {
            $scope.excelData.file_ext = 'xlsx';
            $scope.excelDown();
          }
        },
        {
          name: '취소',
          next: function() {
            return false;
          }
        }
      ];

      commonSVC.showMultiButton(title, contents, buttons);
    };

    $scope.excelDown = function() {
      $scope.excelData.is_all = true;
      $scope.excelData.down_list = angular.copy(stateSVC.excelDownList);
      $scope.excelData.sheet_name = '정산내역';
      $scope.excelData = _.merge($scope.excelData, $scope.pageData);

      stateModel.downExcel($scope.excelData, function (state, result) {
        if (result.results.length) {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('엑셀 다운로드에 성공하였습니다.'));
          window.open(result.results[0], '');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('엑셀 다운로드에 실패하였습니다.'));
        }
      });
    };

    /**
     * 정산 수집 선택 쇼핑몰 클릭 시
     */
    $scope.calculateCollect = function () {
      const templatePath = 'views/calculate/modals/collect.html';

      commonSVC.openModal('lm', {}, 'CalculateCollectCtrl', templatePath);
    };

    /**
     * 정산 수동 업로드 클릭 시
     */
    $scope.calculateExcelUpload = function () {
      const templatePath = 'views/calculate/modals/excel_upload.html';

      const modal = commonSVC.openModal('', { data: { shop_id_view_type: systemList.data.shop_id_view_type } }, 'CalculateExcelUploadCtrl', templatePath);
      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    // 삭제 버튼에 리스트 카운트 출력
    function drawBtn (list) {
      const deleteCnt = list.length;
      if (!deleteCnt) {
        doResetBtn();
      } else {
        $('#btn-delete').attr('disabled', false).html(`삭제(${deleteCnt})`);
      }
    }

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      selectedAllRowList = data;

      drawBtn(selectedAllRowList);
    }, 200));

    // 버튼 타입에 따른 작업
    $scope.deleteSett = async () => {
      const list = $scope.grid.methods.selectedData('all');

      try {
        // 선택된 것만 삭제 가능
        const modalInstance = await commonSVC.showConfirmHtml('정산 데이터 삭제 안내', `선택한 정산 데이터 <span class="text-danger" style="font-weight: bolder">${list.length}건</span> 삭제 시 주문에 매칭된 정산 금액도 삭제 됩니다.\n필요 시 정산 수집 메뉴에서 다시 수집해 주세요.<br><br>선택된 데이터를 삭제하시겠습니까?`);
        if (modalInstance) {
          try {
            await stateModel.delete({ settList: list });
            commonSVC.showToaster('success', '성공', '삭제 처리 성공');
            $scope.searchDo(false);
          } catch (e) {
            commonSVC.showToaster('fail', '실패', '삭제 처리 실패');
          }
        }
      } catch (err) {
        commonSVC.showMessage('정산 확인 실패');
      }
    };
  });
