'use strict';

// 샵바이
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A536-Ctrl', function ($scope, $timeout, $ocLazyLoad, commonSVC, A536categorySVC, categoryModel, commonModel, $compile) {
    $scope.sel_category = ['', '', '', ''];
    $scope.cate = [[], [], [], []];
    $scope.disp_select_category = ['', '', '', '', ''];
    $scope.disp_cate = [[], [], [], [], []];
    $scope.total_disp_cate = [[], [], [], [], []];
    $scope.CateData = angular.copy(A536categorySVC.category_data);
    $scope.dispCtg = angular.copy(A536categorySVC.dispCtg);
    $scope.max_depth = 4;
    $scope.max_disp_depth = 5;
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게

    let save_flag = false;    //카테고리 선택여부
    let disp_save_flag = false;    // 전시 카테고리 선택여부

    $scope.pa5_job_data = {
      get_display_categories: {
        load: false,
        data: []
      }
    };

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);
        if (!re.data.has_error) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;

      await setCategory($scope.CateData.detailData.category_code);

      // 전시카테고리
      if ($scope.CateData.detailData.display_categories.length) {
        $scope.dispCtg.dispCtgSelectList = $scope.CateData.detailData.display_categories;

        for (const disp of $scope.dispCtg.dispCtgSelectList) {
          if (disp && disp.code) {
            const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${disp.code}"><span class="dispCtgNm">${disp.name}</span> <span class="dispCtgSelectRemoveBtn dispCtg badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

            $('.list-group.dispCtg').append(aElement);
            $compile(aElement)($scope);
          }
        }
      }
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');
      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            $scope.cate[idx + 1] = res.data.result;
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    // 전시카테고리 세팅
    $scope.displaySelect = async (idx) => {
      const cate = $scope.disp_select_category[idx];

      if (!cate) {
        return false;
      }

      const disp_category_names = [];

      for (let i = 0; i <= idx; i++) {
        disp_category_names.push($scope.disp_select_category[i].shop_cate_see_name);
      }

      $scope.disp_category_names = disp_category_names.join(' > ');
      disp_save_flag = cate.end_yn;

      if (!disp_save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_disp_depth; i++) {
          $scope.disp_cate[i] = [];
          $scope.disp_select_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.total_disp_cate[idx + 1].map((childCate) => {
              if (childCate.parent_code === cate.shop_cate_see_cd) {
                $scope.disp_cate[idx + 1].push(childCate);
              }
            });
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_disp_depth; i++) {
          $scope.disp_cate[i] = [];
          $scope.disp_select_category[i] = '';
        }
        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    /**
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function (event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      let category_code = '';

      for (let i = 0; i < $scope.max_depth; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;

      if ($scope.form1.$valid) {
        const param = {
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: category_code,
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: {
            ...$scope.CateData.detailData,
            display_categories: $scope.dispCtg.dispCtgSelectList
          },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const init = async () => {
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A536', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      await run_pa5_jobs('get_display_categories', '전시카테고리', {}, (data) => {
        data.map((disp_cate) => {
          $scope.total_disp_cate[disp_cate.depth - 1].push({
            $$hashkey: disp_cate.$$hashkey,
            end_yn: disp_cate.is_end,
            shop_cate_see_cd: disp_cate.code,
            shop_cate_see_depth: disp_cate.depth,
            shop_cate_see_name: disp_cate.name,
            shop_cate_see_type: disp_cate.type,
            parent_code: disp_cate.parent_code
          });
        });

        return data;
      });

      $timeout(async () => {
        $scope.cate[0] = res.data.result;
        $scope.disp_cate[0] = $scope.total_disp_cate[0];

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A536categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });

    };

    /**
     * 전시카테고리 추가
     */
    $scope.DispCateAdd = () => {
      const current_select_index = $scope.disp_select_category.filter(o => o.shop_cate_see_cd).length - 1;

      if (!$scope.disp_select_category[0] || !$scope.disp_select_category[current_select_index].end_yn) {
        commonSVC.showMessage('최하위 전시카테고리까지 모두 선택해 주세요.');

        return false;
      }

      const names = [],
            codes = [];

      for (let idx = 0; idx < 4; idx++) {
        if ($scope.disp_select_category[idx]) {
          names.push($scope.disp_select_category[idx].shop_cate_see_name);
          codes.push($scope.disp_select_category[idx].shop_cate_see_cd);
        }
      }

      const name = names.join(' >');
      const code = codes.join('_');

      if (!code) {
        return false;
      }

      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList.filter(o => o.name);

      for (const thisVal of dispCtgSelectList) {
        if (thisVal.code === code) {
          commonSVC.showMessage('이미 등록된 전시카테고리 입니다.');

          return false;
        }
      }

      if ($scope.dispCtg.dispCtgSelectList.length >= 5) {
        commonSVC.showMessage('전시 카테고리는 최대 5개까지 등록이 가능합니다.');

        return false;
      } else {
        $scope.dispCtg.dispCtgSelectList.push({ code, name });
      }

      const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${code}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn dispCtg badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

      $('.list-group.dispCtg').append(aElement);
      $compile(aElement)($scope);
    };

    /**
     * 전시카테고리 삭제
     */
    $(document).on('click', '.dispCtgSelectRemoveBtn', function (e) {
      const dispType = e.target.classList[1];
      const dispCtgSelectList = $scope[dispType].dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;
      const item = $(e.target).parent();
      const code = item.attr('data-dispCtgCd');

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        const thisVal = dispCtgSelectList[i];

        if (thisVal == code) {
          dispCtgSelectList.splice(i, 1);
        }
      }

      item.remove();

      $scope[dispType].dispCtgSelectList = $scope[[dispType]].dispCtgSelectList.filter(o => o.code !== code);
    });

    init();
  });