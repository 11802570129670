/**
 *  ptgui-zipcode 컴퍼넌트(주소검색 관련)
 *  2016-05-13 권윤학
 */

'use strict';

angular.module('ptgui.zipcode', [])
  .directive('ptguiZipcode', ['$compile', 'commonSVC', function ($compile, commonSVC) {
    return {
      restrict: 'A',
      scope: {
        zipNo: '=?',
        roadAddr: '=?',
        reCheckClass: '=?', // 2017-01 ally 추가
        fullAddr: '=?', // 2017-11 william 추가
        bundle: '=?',
        uniq: '=?'
      },
      controller: function ($scope) {
        const templatePath = 'scripts/libs/ptgui/ptgui-zipcode.html';

        $scope.openZipCodeModal = function () {
          const modalInstance = commonSVC.openModal('', {}, 'ptguiZipcodeCtrl', templatePath);

          modalInstance.result.then(function (row) {
            $scope.zipNo = row.zipNo;
            $scope.roadAddr = row.roadAddr;
            $scope.fullAddr = `${row.zipNo} ${row.roadAddr}`;

            // 2017-01 ally 추가 (우편번호 입력 후 바로 valid check 하기위해서)
            if ($scope.reCheckClass) {
              setTimeout(function () {
                $('.check-zipcode').valid();
              }, 0);
            }

            $scope.$emit('changeZipcd', { addr: row.roadAddr, zipcd: row.zipNo, bundle: $scope.bundle, uniq: $scope.uniq });

          });
        };
      },
      link: function (scope, element, attrs) {
        element.on('click', function () {
          scope.openZipCodeModal();
        });
      }
    };
  }])
  .controller('ptguiZipcodeCtrl', function ($scope, $uibModalInstance, commonSVC, settings) {

    $scope.zipCodes = [];           // 검색된 주소 data
    $scope.keyword = '';            // 검색어
    $scope.oldKeyword = '';         // 검색결과 없을 경우 노출용
    $scope.searchFlag = false;      // 검색 여부
    $scope.resultLimitFlag = false; // 데이터양 체크용
    $scope.totalPages = 0;          // 전체 페이지 수
    $scope.countPerPage = 10;       // 페이지당 개수
    $scope.currentPage = 1;         // 현재 페이지
    $scope.totalItems = 0;          // 조회된 데이터 개수

    $scope.Search = Search;

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
    $scope.selectItem = function (row) {
      $uibModalInstance.close(row);
    };

    /**
     * 주소검색
     */
    function Search(resetPage) {
      $scope.totalItems = 0;
      $scope.searchFlag = true;
      $scope.searching = true;
      $scope.oldKeyword = $scope.keyword;
      if (resetPage) { $scope.currentPage = 1; }

      if ($scope.keyword === '') {
        $scope.zipCodes = [];
        $scope.searchFlag = false;
        $scope.searching = false;
        $scope.resultLimitFlag = false;
        $scope.keyword = '';

        return;
      } else {
        const url = `${settings.pa20ApiUrl}/app/common/address`;

        commonSVC.sendUrl('GET', url, {
          keyword: $scope.keyword,
          currentPage: $scope.currentPage,
          countPerPage: $scope.countPerPage
        }, function (result, data) {

          if (result == 'success') {
            $scope.zipCodes = data.results.data;
            $scope.totalItems = data.results.totalCount;
            $scope.totalPages = data.results.lastPage;

            if (data.results.totalCount > 20) {
              $scope.resultLimitFlag = true;
            } else {
              $scope.resultLimitFlag = false;
            }
          } else {
            $scope.zipCodes = [];
          }

          $scope.searching = false;
        });
      }
    }

  })
  /**
   * 페이지 버튼 템플릿
   */
  .run(['$templateCache', function ($templateCache) {
    $templateCache.put('ptgui/pager.html',
      '<ul class="pager ptgui-zip">\n' +
      "  <li ng-class=\"{disabled: noPrevious()||ngDisabled, previous: align}\"><a href ng-click=\"selectPage(page - 1, $event)\">{{::getText('previous')}}</a></li>\n" +
      '  <li ><span>{{page}}/{{totalPages}}</span></li>\n' +
      "  <li ng-class=\"{disabled: noNext()||ngDisabled, next: align}\"><a href ng-click=\"selectPage(page + 1, $event)\">{{::getText('next')}}</a></li>\n" +
      '</ul>\n' +
      '');
  }]);
