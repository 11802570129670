'use strict';

angular.module('gmpApp')
  .controller('OnlineProductProdInfoCtrl', function ($scope, $http, $uibModalInstance, commonSVC, onlineProductSVC, data, $log, $rootScope, settings, $timeout) {
    $scope.adminMode = $rootScope.adminMode;
    $scope.prod_noti_info = [{}, {}, {}];
    $scope.saveProdInfo = [{ isSave: false, templateName: '' }, { isSave: false, templateName: '' }, { isSave: false, templateName: '' }];
    $scope.certifyList = onlineProductSVC.certifyList;
    $scope.tabActive = data.tabActive || 0;
    const temp_list = angular.copy(data.temp_list);
    const submit_type = data.type || 'edit';

    /**
     * 상품분류 정의
     * **/
    const types = onlineProductSVC.newTypes;

    $scope.type_array = _.map(types, function(value) {
      return { key: value.code, name: value.name };
    });

    /**
     * 정보제공고시 정보 불러오기
     * **/
    $scope.changeType = function(idx, next) {
      angular.forEach($scope.type_array, function(v) {
        if (v.key == $scope.prod_noti_info[idx].infoCode) {
          $scope.prod_noti_info[idx].infoName = v.name;
        }
      });

      if (_.isUndefined($scope.prod_noti_info[idx].infoCode) || $scope.prod_noti_info[idx].infoCode == '') {
        $scope.prod_noti_info[idx] = {};

        // 상품분류를 선택하지 않은 경우 저장하려는 템플릿 초기화
        $scope.saveProdInfo[idx] = { isSave: false, templateName: '' };

        return false;
      }

      commonSVC.sendUrl('get', `/views/online/product/json/${$scope.prod_noti_info[idx].infoCode}.json`, {}, function(state, data) {
        if (state == 'success') {
          $scope.prod_noti_info[idx].infoDetail = data;
          next();
        } else {
          $log.debug(data);
        }
      });
    };

    if (temp_list.length) {
      for (const i in temp_list) {
        if (!temp_list[i]) {
          temp_list[i] = {};
        }
        $scope.prod_noti_info[i].infoCode = temp_list[i].infoCode;
        if (submit_type == 'add') {
          $scope.prod_noti_info[i] = temp_list[i];
        }
      }
    }

    /**
     * 상세설명참조 전체적용
     * **/
    $scope.allRefer = function(idx) {
      const infoCode = $scope.prod_noti_info[idx].infoCode;

      if (infoCode === '' || infoCode === null || infoCode === undefined) {
        commonSVC.showMessage('상품 분류를 선택 해주세요', '');

        return false;
      }

      angular.forEach($scope.prod_noti_info[idx].infoDetail, function(v) {
        angular.forEach(v.detail, function(v2) {
          if (v2.value != 'Y' && v2.value != 'N' && v2.value != '해당없음') { //라디오버튼 제외
            v2.value = '[상세설명참조]';
          }
        });
      });
    };

    /**
     * 상품 고시 자동입력 버튼 (디버그)
     */
    $scope.setProdInfoName = () => {
      const infoCode = $scope.prod_noti_info[$scope.tabActive].infoCode;

      if (infoCode === '' || infoCode === null || infoCode === undefined) {
        commonSVC.showMessage('상품 분류를 선택 해주세요', '');

        return false;
      }

      angular.forEach($scope.prod_noti_info[$scope.tabActive].infoDetail, function(v) {
        angular.forEach(v.detail, function(v2) {
          if (v2.value != 'Y' && v2.value != 'N' && v2.value != '해당없음') { //라디오버튼 제외
            v2.value = v2.title;
          }
        });
      });
    };

    /**
     * 상품정보제공고시 템플릿 불러오기
     */
    $scope.getProdInfoTemplate = () => {
      const modal = commonSVC.openModal('md', {}, 'OnlineProductProdInfoTemplateListCtrl', 'views/online/product/modals/prod_info_template_list.html');

      modal.result.then(function (re) {
        $scope.prod_noti_info[$scope.tabActive] = re.template_data;

        return false;
      });
    };

    /**
     * 등록
     * **/
    $scope.ok = async () => {
      if (!$scope.prod_noti_info[0].infoCode) {
        commonSVC.showMessage('상품분류를 선택해 주세요.', '');

        return false;
      }

      if ($scope.form1.$valid) {
        const prodNotiInfoValueCheck = $scope.prod_noti_info.some(row =>
          row.infoCode && row.infoDetail.some(({ detail }) =>
            detail.some(({ value }) => !value)
          )
        );

        if (prodNotiInfoValueCheck) {
          commonSVC.showMessage('세부항목을 모두 입력해주세요.', '');

          return false;
        } else {
          if ($scope.saveProdInfo.some(info => info.isSave)) {
            const { data: existNameList } = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/check-prod-noti-template-name`, { template_names: $scope.saveProdInfo.filter(info => info.isSave && info.templateName).map(temp => temp.templateName) });

            $scope.saveProdInfo.forEach((info, index) => {
              if (info.isSave) {
                if (!info.templateName) {
                  info.errMsg = '템플릿명을 입력해 주세요.';
                } else {
                  info.errMsg = existNameList.includes(info.templateName) ? '이미 등록되어 있는 템플릿명입니다.' : '';
                }

                if (index !== $scope.saveProdInfo.findIndex(({ templateName }) => info.templateName === templateName)) {
                  info.errMsg = '중복된 템플릿명입니다.';
                }
              }
            });

            const errIndex = $scope.saveProdInfo.findIndex(info => info.errMsg);

            if (errIndex !== -1) {
              $scope.tabActive = errIndex;
              $timeout(() => {});

              return false;
            }
          }

          const saveTemplateList = [];
          $scope.saveProdInfo.forEach((info, index) => {
            if (info.isSave) {
              saveTemplateList.push({
                templateName: info.templateName,
                templateData: $scope.prod_noti_info[index]
              });
            }
          });

          if (saveTemplateList.length) {
            try {
              await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/prod-noti-template`, { templateList: saveTemplateList });

              commonSVC.showToaster('success', '성공', '새로운 상품정보제공고시 템플릿을 저장했습니다.');
            } catch (err) {
              commonSVC.showToaster('error', '실패', '새로운 상품정보제공고시 템플릿 저장 실패');
            }
          }

          $uibModalInstance.close($scope.prod_noti_info);
        }
      }
    };

    //탭 선택
    $scope.clickTab = function(index) {
      // 상품정보제공고시의 공통입력(필수) 값이 없는 경우 다른 탭으로 이동 할 수 없도록 처리
      const prodNotiInfoValueCheck = index > 0
        && (!$scope.prod_noti_info[0].infoCode || $scope.prod_noti_info[0].infoDetail.some(({ detail }) =>
          detail.some(({ value }) => !value)
        ));
      if (prodNotiInfoValueCheck) {
        commonSVC.showMessage('필수 입력사항을 모두 입력한 후 추가 입력을 진행할 수 있습니다.', '');
      }
      $timeout(() => {
        $scope.tabActive = prodNotiInfoValueCheck ? 0 : index;
      });
    };

    /**
     * 취소
     * **/
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // 2018-01-18 roy
    // 저장된 상품 정보제공고시 로드
    if (submit_type == 'edit') {
      _.each(temp_list, function(noti_info, i) {
        $scope.changeType(i, function() {
          const noti_info_list = _.map(noti_info.infoDetail, function(value) {
            return value;
          });

          $scope.prod_noti_info[i].infoDetail.forEach(list => {

            list.detail.map(v => {
              v.value = noti_info.infoDetail[v.code];
            });
          });
        });
      });
    }
  });
