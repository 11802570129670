/**
 * 이메일 발송내역 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('EmailListCtrl', function ($scope, data, $uibModalInstance, settings, commonSVC, userInfo) {
    const getParams = { ...data };
    const emailListViewGrid_searchData = {
      totalCount: 0    // 전체 건수
    };
    $scope.searchData = angular.copy(emailListViewGrid_searchData);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      enablePaginationControls: true,
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['no', 'to_m', 'title', 'task_id', 'file_url', 'pay_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['no'],
      notSortingColumns: ['no', 'to_m', 'title', 'task_id', 'file_url'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/email-history`,
        requestMethod: 'GET',
        requestWillAction: function (d) {
          d.tran_pr = getParams.tran_pr;

          return JSON.stringify(d);
        },
        requestDidAction: function (json) {
          $scope.searchData.totalCount = json.total;

          return json.results;
        }
      },
      columns: [
        // 순번
        { key: 'no', title: '순번', width: 10 },
        // 수신자 메일 주소
        { key: 'to_m', title: '수신자 메일 주소', width: 40 },
        // 상태
        { key: 'task_id', title: '상태', width: 30, template: function(row) {
          const status = row.task_id == 'success' ? '성공' : '<span class="text-danger">실패</span>';

          return status;
        } },
        // 상세내용(메일 제목 출력 / 첨부파일 다운로드 시 상세내용 확인 가능)
        { key: 'title', title: '상세내용', width: 100 },
        // 첨부파일
        { key: 'file_url', title: '첨부파일', width: 50, tooltip: '첨부파일은 3주 동안 보관되며 3주 후 자동으로 삭제됩니다.\n삭제된 첨부파일은 복구할 수 없습니다.', template: function(row) {

          const downPermission = userInfo.permission.order.roles.excelOrder;

          return row.file_url ? `<a ${!downPermission ? "style='color: gray;'" : ''} href='${!downPermission ? '#' : row.file_url}'>첨부파일</a>` : '';
        } },
        // 무료여부
        { key: 'pay_yn', title: '무료여부', width: 50 }
      ]
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
