'use strict';

angular.module('gmpApp')
  .factory('giftModel', function ($rootScope, settings, commonSVC) {
    return {
      loadList: function () {
        const url = `${settings.pa20ApiUrl}/app/order/gift/all-list`;

        return commonSVC.sendUrl('GET', url);
      },

      // ---- 여기부터 신규칙
      list: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/gift/list`;

        return commonSVC.sendUrl('POST', url, params);
      },

      save: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/gift/save`;

        return commonSVC.sendUrl('POST', url, params);
      },

      detail: function(params) {
        const url = `${settings.pa20ApiUrl}/app/order/gift`;

        return commonSVC.sendUrl('GET', url, params);
      },

      matching: function(params) {
        const url = `${settings.pa20ApiUrl}/app/order/gift-new`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      del: function(params) {
        const url = `${settings.pa20ApiUrl}/app/order/gift`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      formatTime: function(time_string) {
        const time_in_minutes = moment(time_string, 'hh:mm:ss').diff(moment('00:00:00', 'hh:mm:ss'), 'minutes');

        return `${time_in_minutes / 60 >= 12 ? '오후' : '오전'} ${('00' + (Math.floor(time_in_minutes / 60 + 11) % 12 + 1)).slice(-2)}:${('00' + time_in_minutes % 60).slice(-2)}`;
      }
    };
  });
