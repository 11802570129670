'use strict';

angular.module('gmpApp')
  .controller('StockAllocHistoryCtrl', function ($rootScope, $scope, settings, systemList, inventoryModel, gettextCatalog, commonSVC, historySVC) {
    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'ScrapOrder' });

    const siteList = _.unionBy(channelList, 'shop_cd'),
          siteIdList = channelList;

    $scope.allocInit = true;
    $scope.re_allocInit = false;

    $scope.gridType = 'alloc';

    /**
     * 서치바 관련 변수
     */
    const search = {
      searchArr: null,
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        alloc_type: '',
        alloc_method: '',
        work_type: '',
        site_code: '',
        site_id: '',
        re_alloc_yn: $scope.gridType === 're_alloc',
      },
      searchData: {
        all_checked: false,   // 전체선택 여부
        totalCount: 0,        // 검색전체 건수
        selectCount: 0,       // 선택한 건수 <- 작업예정
        search_key_items: [
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
          { label: gettextCatalog.getString('속성명'), value: 'attri' },
          { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
          { label: gettextCatalog.getString('옵션명'), value: 'shop_opt_name' },
          { label: gettextCatalog.getString('작업자'), value: 'wm_name' },
        ],
        search_date_type: [
          { label: gettextCatalog.getString('날짜'), value: 'wdate' }
        ]
      },
      alloc_searchDetail: [
        {
          title: '할당 구분',
          search_name: 'alloc_type',
          item_list: [{ key: '채널', value: 'shop' }, { key: 'SKU상품', value: 'prod' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          title: '할당 방법',
          search_name: 'alloc_method',
          item_list: [{ key: '일반', value: 'rate' }, { key: '우선순위', value: 'priority' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          title: '작업 구분',
          search_name: 'work_type',
          item_list: [{ key: '입고', value: 'in' }, { key: '출고', value: 'out' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        },
        {
          // 채널 선택
          title: '쇼핑몰 선택',
          search_name: 'shop_cd',
          item_list: siteList,
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X');
          },
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: '쇼핑몰(계정) 선택',
          search_name: 'shop_id',
          item_list: siteIdList,
          item_key: 'shop_id',
          item_value: 'shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function (option) {
            // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
            return option.shop_cd === $scope.searchForm.shop_cd && option.shop_id;
          }
        },
      ],
      realloc_searchDetail: [
        {
          // 채널 선택
          title: '쇼핑몰 선택',
          search_name: 'shop_cd',
          item_list: siteList,
          item_key: 'shop_name',
          item_sub_key: 'shop_name_kr',
          item_value: 'shop_cd',
          select_value: '',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X');
          },
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: '쇼핑몰(계정) 선택',
          search_name: 'shop_id',
          item_list: siteIdList,
          item_key: 'shop_id',
          item_value: 'shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function (option) {
            // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
            return option.shop_cd === $scope.searchForm.shop_cd && option.shop_id;
          }
        },
      ]
    };

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(search.searchForm);

    // 검색영역 데이터
    $scope.searchData = angular.copy(search.searchData);

    // 검색영역 상세검색 설정
    $scope.searchDetail = angular.copy(search.alloc_searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all');
              },
              perm_only: ['online.product']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['online.product']
            }
          ]
        }
      ],

      // 검색 영역 선택한 데이터 버튼
      actions_left: [
        [
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('all');
            }
          },
          {
            label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
            btn_type: 'button',
            action: function () {
              $scope.excelDown('select');
            }
          }
        ]
      ],

      // 검색 영역 오른쪽 버튼
      actions_right: []
    };

    /**
     * 작업관리 검색 데이터 테이블 관련기능 바인딩
     */
    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    // 검색시 실행
    $scope.searchDo = function (refresh = true, noDelay) {
      // 검색시 새로고침
      $scope.searchForm.re_alloc_yn = $scope.gridType === 're_alloc';

      if ($scope.grid.methods.reloadData) {
        $scope.grid.methods.reloadData(null, refresh, noDelay);
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = $scope.gridType === 're_alloc' ? angular.copy(search.realloc_searchDetail) : angular.copy(search.alloc_searchDetail);
      $scope.selectCount = 0;

      $scope.searchDo(true, true);
    };

    /**
    * 데이터테이블 pageLength 조절
    * @param number
    */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /***************************
     * 재고할당 데이터테이블
     ***************************/
    $scope.grid = $scope.alloc = {};
    $scope.alloc.methods = {};
    $scope.alloc.options = {
      pinningColumns: [],
      alignCenterColumns: ['alloc_method'],
      defaultSortingColumns: ['wdate'],
      alignRightColumns: [],
      notSortingColumns: ['prod_depot_shop_no', 'alloc_type', 'alloc_method'],
      notResizingColumns: ['wdate'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/stockAlloc`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '재고할당일',
          width: 140
        },
        {
          key: 'prod_depot_shop_no',
          title: '재고할당규칙코드',
          width: 144,
          template: function(row) {
            return `<a ng-click="grid.appScope.openStockAllocRule('${row.allocation_type.split('_')[0]}',${row.prod_depot_shop_no},${row.prod_no},${row.depot_no},${row.real_stock},${row.stock_cnt})">${row.prod_depot_shop_no}</a>`;
          }
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 90
        },
        {
          key: 'alloc_type',
          title: '할당구분',
          width: 90
        },
        {
          key: 'alloc_method',
          title: '할당방법',
          width: 90
        },
        {
          key: 'work_type',
          title: '작업구분',
          width: 90
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 131
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 230
        },
        {
          key: 'attri',
          title: '속성명',
          width: 100
        },
        {
          key: 'ori_real_stock',
          title: '실재고(원본)',
          width: 100
        },
        {
          key: 'real_stock',
          title: '실재고(변동)',
          width: 100
        },
        {
          key: 'alloc_rate',
          title: '할당율',
          width: 90,
          notCompile: true,
          template: function(row) {
            return `<span class="text-danger">${row.alloc_rate}%` + '</span>';
          }
        },
        {
          key: 'shop_id',
          title: '쇼핑몰(계정)',
          width: 144,
          notCompile: true,
          template: function(row) {
            return `${row.shop_name}(${row.shop_id})`;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 114
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 118
        },
        {
          key: 'shop_sale_name',
          title: '온라인상품명',
          width: 275
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 124
        },
        {
          key: 'ori_sale_cnt_limit',
          title: '판매수량(원본)',
          width: 120,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary">${row.ori_sale_cnt_limit}</span>`;
          }
        },
        {
          key: 'sale_cnt_limit',
          title: '판매수량(할당)',
          width: 145,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary">${row.sale_cnt_limit}</span>`;
          }
        },
      ]
    };

    /***************************
     * 재고재할당 데이터테이블
     ***************************/
    $scope.re_alloc = {};
    $scope.re_alloc.methods = {};
    $scope.re_alloc.options = {
      pinningColumns: [],
      alignCenterColumns: ['prod_name'],
      defaultSortingColumns: ['wdate'],
      alignRightColumns: [],
      notSortingColumns: ['prod_depot_shop_no', 'sku_cd', 'prod_name'],
      notResizingColumns: ['wdate'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/stockAlloc`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '재고 재할당일',
          width: 140 },
        {
          key: 'prod_depot_shop_no',
          title: '재할당 규칙코드',
          width: 144,
          template: function(row) {
            return `<a ng-click="grid.appScope.openStockAllocRule('${row.allocation_type.split('_')[0]}',${row.prod_depot_shop_no},${row.prod_no},${row.depot_no},${row.real_stock},${row.stock_cnt})">${row.prod_depot_shop_no}</a>`;
          }
        },
        {
          key: 'wm_name',
          title: '작업자',
          width: 90
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 131
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 230
        },
        {
          key: 'attri',
          title: '속성명',
          width: 100
        },
        {
          key: 'ori_real_stock',
          title: '실재고(원본)',
          width: 100
        },
        {
          key: 'real_stock',
          title: '실재고(변동)',
          width: 100
        },
        {
          key: 'alloc_rate',
          title: '할당율',
          width: 90,
          notCompile: true,
          template: function(row) {
            return `<span class="text-danger">${row.alloc_rate}%` + '</span>';
          }
        },
        {
          key: 'shop_id',
          title: '쇼핑몰(계정)',
          width: 144,
          notCompile: true,
          template: function(row) {
            return `${row.shop_name}(${row.shop_id})`;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 114
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 118
        },
        {
          key: 'shop_sale_name',
          title: '온라인상품명',
          width: 275
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 124
        },
        {
          key: 'ori_sale_cnt_limit',
          title: '판매수량(원본)',
          width: 120,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary">${row.ori_sale_cnt_limit}</span>`;
          }
        },
        {
          key: 'sale_cnt_limit',
          title: '판매수량(재할당)',
          width: 145,
          notCompile: true,
          template: function(row) {
            return `<span class="text-primary">${row.sale_cnt_limit}</span>`;
          }
        },
      ]
    };

    // 탭 변경
    $scope.changeTab = function(type) {
      $scope.gridType = type;
      $scope.grid = $scope[type];
      $scope[`${type}Init`] = true;

      if ($scope.grid.methods.setShowCountByGridPageSize) {
        $scope.grid.methods.setShowCountByGridPageSize();
      }

      $scope.resetDo(true);
    };

    /**
     * 재고할당 설정 창 오픈
     */
    $scope.openStockAllocRule = function(alloc_type, prod_depot_shop_no, prod_no, depot_no, real_stock, stock_cnt) {
      const controller = $scope.gridType === 're_alloc' ? 'SetStockReAllocChannelRuleCtrl' : (alloc_type === 'prod' ? 'SetStockAllocationChannelRuleCtrl' : 'StockAllocationRuleCtrl');
      const template = $scope.gridType === 're_alloc' ? 'views/stock/inventory/set_stock_reallocation_channel_rule.html' : (alloc_type === 'prod' ? 'views/stock/inventory/set_stock_allocation_channel_rule.html' : 'views/stock/inventory/stock_allocation_rule.html');

      const resolve = {
        data: {
          fromHistory: true,
          prod_depot_shop_no
        }
      };

      if (alloc_type === 'shop') {
        inventoryModel.stockAllocGetType({}, function(state, data) {
          resolve.data.alloc_type = data.result[0].allocation_type;
          resolve.data.alloc_type_last_modify_time = data.result[0].alloc_type_last_modify_time;
        });
      } else {
        resolve.data.prod_no = prod_no;
        resolve.data.depot_no = depot_no;
        resolve.data.real_stock = real_stock;
        resolve.data.stock_cnt = stock_cnt;
      }

      commonSVC.openModal(alloc_type === 'shop' && $scope.gridType === 're_alloc' ? 'xmd' : 'xxg', resolve, controller, template);
    };

    // 엑셀다운
    $scope.excelDown = function(type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: $scope.gridType === 're_alloc' ? angular.copy(historySVC.stockAllocExcelFieldList.re_alloc) : angular.copy(historySVC.stockAllocExcelFieldList.alloc),
        title: $scope.gridType === 're_alloc' ? '재고재할당 내역' : '재고할당 내역',
        url: '/app/history/stockAlloc/excelDown',
        searchForm: $scope.pageData,
        page: 'stock_alloc_history'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (!selectList.length) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };
  });
