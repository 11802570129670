'use strict';

angular.module('gmpApp')
  .factory('B719categorySVC', function () {

    return {
      // 옵션 repeat data
      category_opt: [[], [], [], []],
      //카테고리 data
      category_data: {
        category_code: '',
        category_names: '',
        set_all_shop: false, //상품적용여부
      }
    };
  });
