'use strict';

angular.module('gmpApp')
  .controller('SetProductExcelUploadCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, $timeout, data) {
    $scope.mode = data.mode;

    let act;

    switch ($scope.mode) {
      case 'add':
        act = 'RegistSetProductExcel';
        break;

      case 'edit':
        act = 'EditSetProductExcel';
        break;
    }

    // 현재 수정될 세트상품이 매칭된 주문리스트에도 반영되는지 여부
    $scope.editMappingOrderListYn = false;

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = '';
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;
      if ($scope.fileData) {
        const params = { mode: $scope.mode };

        if ($scope.mode === 'edit') {
          params.editMappingOrderListYn = $scope.editMappingOrderListYn;
        }

        workSVC.addExcelWork(act, params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '업로드 작업에 필요한 엑셀 파일이 확인되지 않습니다.\n파일 첨부 후 다시 진행해 주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  });