'use strict';

angular.module('gmpApp')
  .controller('ProdProductProdInfoCtrl', function ($scope, $uibModalInstance, commonSVC, onlineProductSVC, data, $log) {

    let prod_type = '';       // 상품정보고시 상품군 코드
    let prod_type_name = '';  // 상품정보고시 상품군 이름

    $scope.certifyList = onlineProductSVC.certifyList;

    /**
     * 상품분류 정의
     * **/
    const types = onlineProductSVC.newTypes;

    $scope.type_array = _.map(types, function(value) {
      return { key: value.code, name: value.name };
    });

    /**
     * 정보제공고시 정보 불러오기
     * **/
    $scope.changeType = function(item) {

      prod_type = item.key;
      prod_type_name = item.name;
      $scope.prod_info = '';

      if (_.isUndefined(prod_type) || prod_type == '') {
        return false;
      }

      commonSVC.sendUrl('get', `/views/online/product/json/${prod_type}.json`, {}, function(state, data) {
        if (state == 'success') {
          $scope.prod_info = data;
        } else {
          $log.debug(data);
        }
      });
    };

    /**
     * 상세설명참조 전체적용
     * **/
    $scope.allRefer = function() {
      if (prod_type === '' || prod_type === null || prod_type === undefined) {
        commonSVC.showMessage('상품 분류를 선택 해주세요', '');

        return false;
      }

      angular.forEach($scope.prod_info, function(v) {
        angular.forEach(v.detail, function(v2) {
          if (v2.value != 'Y' && v2.value != 'N' && v2.value != '해당없음') { //라디오버튼 제외
            v2.value = '[상세설명참조]';
          }
        });
      });
    };

    /**
     * 등록
     * **/
    $scope.ok = function() {
      if ($scope.form1.$valid) {
        const return_value = {
          code: prod_type,
          name: prod_type_name,
          value: $scope.prod_info
        };

        $uibModalInstance.close(return_value);
      }
    };

    /**
     * 취소
     * **/
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 상품분류 불러오기
     * **/
    if (data.value) { // data.value값이 있는경우
      const array = typeof data.value === 'string' ? JSON.parse(data.value) : data.value;

      commonSVC.sendUrl('get', `/views/online/product/json/${array.infoCode}.json`, {}, function(state, data) {
        if (state == 'success') {

          // 상품분류 select 노출 2018-04-16 rony
          const selectedCode = _.filter($scope.type_array, function(o) {
            return o.key == array.infoCode;
          });

          if (selectedCode.length > 0) {
            $scope.prod_type = selectedCode[0];
          }

          prod_type = array.infoCode;
          $scope.prod_info = data;

          $scope.prod_info.forEach(list => {
            list.detail.map(v => {
              v.value = array.infoDetail[v.code];
            });
          });
        } else {
          $log.debug(data);
        }
      });

    }
  });
