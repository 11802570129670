'use strict';

angular.module('gmpApp')
  .controller('setFormTemplateListSubCtrl', function ($state, $scope, userInfo, settings, commonSVC, onlineProductSVC, $timeout, systemModel, templateModel, systemList, defaultTemplateGroup, gettextCatalog, warehouseModel, $rootScope) {
    // 사용중인 쇼핑몰
    let channelListArr = $rootScope.useChannelList({ site_action: 'SyncProdState', func: shop => !!shop.prod_use_yn });

    $scope.vdata = {};

    let defaultTemplateArray = defaultTemplateGroup.data.results;

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = {
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
      search_key_items: [  // 검색영역 키값
        { label: '전체', value: 'all' },
        { label: '템플릿코드', value: 'template_no' },
        { label: '템플릿명', value: 'template_name' }
      ],
      search_date_type: [
        { label: '등록일', value: 'wdate' },
        { label: '수정일', value: 'mdate' }
      ]
    };

    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelListArr, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    channelListArr = channelListArr.filter(list => list.pa_shop_cd !== 'A076');

    // 설정 안따라가고 항상 2001 년으로 설정
    const searchForm = {
      sdate: commonSVC.getDate(new Date('2001-01-01'), 'yyyy-MM-dd'),
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      date_type: 'wdate',                                             // 검색기간 구분
      search_word: '',                                                // 검색어
      search_key: 'all',                                              // 검색구분
      search_type: 'partial',                                         // 부분일치, 완전일치
      selectDate: 'ALL'
    };

    $scope.searchForm = angular.copy(searchForm);

    const searchDetail = [
      {
        title: '채널 선택',
        search_name: 'shop_cd',
        //리스트 추가 해야됨
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      {
        title: '채널계정 선택',
        search_name: 'shop_id',
        //리스트 추가 해야됨
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd;
        }
      },
      {
        title: '적용상품 유무 선택',
        search_name: 'apply_prod_yn',
        item_list: [{ value: 'Y', key: '적용상품 있음' }, { value: 'N', key: '적용상품 없음' }],
        item_key: 'key',
        item_value: 'value',
        select_value: ''
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    $scope.searchBtn = {      // 상세조건 버튼
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: '템플릿 복사',
              action: function () {
                $scope.copytemplate();
              },
              perm_only: ['online.template+write']
            }, {
              label: '템플릿 삭제',
              action: function () {
                $scope.deleteDo();
              },
              perm_only: ['online.template+write']
            }
          ]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          test_id: 'drop-excel',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              test_id: 'btn-excel-all',
              action: function () {
                $scope.excelDown('all');
              }
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              test_id: 'btn-excel-select',
              action: function () {
                $scope.excelDown('select');
              }
            }
          ]
        }
      ],
      actions_right: [
        {
          label: '<i class="icon-plus2"></i><span> ' + '템플릿 등록' + '</span>',
          small_label: '템플릿등록',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-12',
          // perm_only:['online.template+write'],
          action: function() {
            $timeout(() => {
              $state.go('main.setForm_template_add');
            });
          }
        }
      ],
      actions_right_bottom: []
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(false, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 조회
     */
    $scope.searchDo = function(refresh = false, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchData.search_key_name = '';
      $scope.grid.methods.reloadData(null, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {
      // 엑셀 다운로드 권한 체크
      if (
        (commonSVC.checkPermission('online.roles.excelProd', userInfo.permission) === false)
      ) {
        return false;
      }

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(onlineProductSVC.templateExcelFieldList),
        title: '템플릿',
        url: '/app/product/template/excel-down',
        searchForm: $scope.pageData,
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;

        if (resolve.data.count === 0) {
          commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 템플릿이 없습니다.'));

          return false;
        }
      } else {
        const templateNoList = $scope.grid.methods.selectedData('template_no');

        if (templateNoList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 템플릿이 없습니다.'));

          return false;
        } else {
          resolve.data.select_list = templateNoList;
          resolve.data.count = templateNoList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 삭제
     */
    $scope.deleteDo = function(number) {
      let numbers = [];

      if (number) { // 단일삭제
        numbers.push(number);
      } else { // 선택 일괄 삭제
        numbers = $scope.grid.methods.selectedData('template_no');
        if (numbers.length === 0) {
          commonSVC.showMessage('선택된 데이터가 없습니다.');

          return;
        }
      }
      commonSVC.showConfirm('삭제하시겠습니까?', '', function () {
        templateModel.delete(numbers, function (state, data) {

          if (state == 'success') {
            commonSVC.showToaster('success', '템플릿 관리', `템플릿 삭제 ${numbers.length}건 성공`);
            $scope.searchDo();
          } else {
            if (data.data.error || data.data.messages[0]) {
              commonSVC.showToaster('error', '템플릿 관리', data.data.messages[0] || data.data.error);
            } else {
              commonSVC.showToaster('error', '템플릿 관리', data.data.messages[0] || data.data);
            }
          }
        });
      });
    };

    /**
     * 템플릿 복사
     */
    $scope.copytemplate = function() {
      // 템블릿 복사시 사이트코드등의 정보도 필요하여 전체값 받는것으로 수정 2018-01-29 rony
      // var numbers = $scope.grid.methods.selectedData("template_no");
      const numbers = $scope.grid.methods.selectedData('all');

      if (numbers.length == 0) {
        commonSVC.showMessage('선택된 데이터가 없습니다.');

        return false;
      }

      const resolve = {
        data: { selectedList: numbers }
      };

      const modal = commonSVC.openModal('x3g', resolve, 'setFormCopyCtrl', 'views/online/setForm/setFormCopy.html', false, true, false);

      modal.result.then(function(re) {
        $timeout(function () {
          if (re === 'success') {
            $scope.searchDo();
          }
        }, 300);
      });
    };

    $scope.detail = function(template_no, shop_id, shop_cd, pa_shop_cd, shop_name) {
      let modal = '';
      const resolve = {};
      let type;

      resolve.data = {
        template_no: template_no,
        shop_cd: shop_cd,
        shop_id: shop_id,
        pa_shop_cd: pa_shop_cd,
        shop_name: shop_name
      };
      // views/online/setForm/' + pa_shop_cd + '.html
      modal = commonSVC.openModal('full', resolve, 'setFormTemplateCtrl', 'views/online/setForm/setform_detail.html');
      if (modal !== '') {
        modal.result.then(function (param) {
          if (param == 'success') {
            $scope.searchDo();
          } else {
            commonSVC.showToaster('error', '실패', `${type} 처리 실패`);
          }
        });
      }
      // $scope.isDetailShow = show;
      // var selectRow = $scope.isDetailShow && rowIndex > -1 ? rowIndex : null; // 상세보기 누른 row index
      // $scope.grid.methods.ChangeColMode(show, [ 0, 1, 5, 6 ], selectRow);
      //
      // if(show){
      //   $state.go("main.online_template_list.detail", {templateCode:code, siteCode:siteCode, siteId:siteId});
      //   $scope.grid.methods.autoSelect(true);
      // }else{
      //   $scope.grid.methods.autoSelect(false);
      // }
    };

    /**
     * 즐겨찾기 여부 수정
     */
    $scope.setBookmark = async function({ template_no, bookmark_yn }) {
      try {
        await templateModel.setBookmark({ templateNo: template_no, bookmarkYn: bookmark_yn });

        commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('즐겨찾기 수정에 성공하였습니다.'));
      } catch (error) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('즐겨찾기 수정에 실패하였습니다.'));

        return false;
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['detail', 'template_group', 'bookmark_yn'],
      defaultSortingColumns: ['template_no'],
      alignRightColumns: [],
      notSortingColumns: ['detail', 'count', 'wdate', 'mdate', 'template_group', 'bookmark_yn', 'shop_id'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      groupOptions: {
        groupExceptionKey: 'groupRowYn',
        groupDataKey: 'group_key',
        groupRowHeaderTemplate: () => {
          return (
            '<div><i class="fa fa-folder mr-5" style="color: #F8D79F; margin-left: 6px"></i></div>'
          );
        },
        groupRowTemplate: (row) => {
          return (
            `
              <div>
                <ul class="icons-list pl-5" style="position: absolute; z-index: 100; display: inline-block;">
                  <li uib-dropdown dropdown-append-to-body>
                    <a href="#" id="simple-dropdown" uib-dropdown-toggle style="margin-top: 3px;">
                    ${row.shop_name}(${row.shop_id})
                    </a>
                    <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="simple-dropdown">
                      <li>
                        <a ng-click="grid.appScope.copytemplate()">템플릿 복사</a>
                      </li>
                      <li>
                        <a ng-click="grid.appScope.deleteDo()">템플릿 삭제</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            `
          );
        }
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/template/list`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          let return_arr = [];

          angular.forEach(result.results, function (v) {
            v.group_key = `${v.shop_cd}_${v.shop_id}`;
            return_arr = return_arr.concat(v);
          });

          $scope.searchData.totalCount = result.recordsTotal;

          return return_arr;
        }
      },
      // "dom": "<'datatable-header no-padding-top'<'col-sm-3'i><'col-sm-5 text-right'f><'col-sm-4 text-right'B>><'table-responsive't><'datatable-footer'pr>",
      page: false,
      columns: [
        {
          key: 'detail',
          title: '상세',
          width: 70,
          template: function (row) {
            return `${'' +
              '<button class="btn btn-default btn-xxs" ng-click="grid.appScope.detail('}'${row.template_no}','${row.shop_id}','${row.shop_cd}','${row.pa_shop_cd}','${row.shop_name}')" uib-tooltip="상세보기" tooltip-placement="right">상세</button>`;
          }
        },
        {
          key: 'template_no',
          title: '템플릿코드',
          width: 80
        },
        {
          key: 'shop_id',
          title: '쇼핑몰계정',
          width: 100,
          template: function (row) {
            let img = '<div class="text-center">직접입력</div>';

            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">${shop_info[1]}</span>`;
            }

            return img;
          }
        },
        {
          key: 'template_name',
          title: '템플릿명',
          width: 220
        },
        {
          key: 'count',
          title: '적용상품',
          width: 90,
          template: function (row) {
            const applyOlProdCnt = row.apply_prod_ol_cnt > 0 ? `<a style="color:black;" ng-click="grid.appScope.linkageModalOpen('${row.template_no}')">${row.apply_prod_ol_cnt}</a>` : row.apply_prod_ol_cnt;

            return applyOlProdCnt;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 150,
          filter: 'dateValid'
        },
        {
          key: 'name',
          title: '등록자',
          width: 100
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 150,
          filter: 'dateValid'
        },
        {
          key: 'template_group',
          title: '기본 템플릿 그룹',
          width: 100,
          template: function (row) {
            const isDefault = !!_.find(defaultTemplateArray, ['template_no', row.template_no]);

            return `<input type="radio" name="${row.shop_id}|${row.shop_cd}" value="${row.depot_no}" ng-checked="${isDefault}" ng-click="grid.appScope.changeDefaultTemplateGroup(${row.template_no}, '${row.shop_cd}',` + ` '${row.shop_id}')">`;
          }
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 80,
          template: function (row) {
            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.setBookmark(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    // SKU상품 수정시 리스트에서 바뀐 정보 보여주기 위해 다시 그려줌
    $scope.$on('prodModifySuccess', function() {
      $scope.searchDo();
      $scope.grid.methods.autoSelect(true);
    });

    /**
     * 연동상품 모달창
     * */
    $scope.linkageModalOpen = function (template_no) {
      const resolve = {};

      resolve.data = {
        code: template_no,
        type: 'template'
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      commonSVC.openModal('xxg', resolve, 'ProdProductLinkageCtrl', 'views/prod/product/modals/product_linkage.html');
    };

    // 템플릿 그룹 관리 모달
    $scope.manageTemplateGroup = function () {
      const resolve = {
        data: {
          template_list: true
        },
        systemList: function () {
          return systemModel.load();
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'ManageTemplateGroupCtrl', 'views/online/product/modals/manage_template_group.html');

      modal.result
        .finally(function() {
          templateModel.loadGroup({ template_group_no: systemList.data.default_template_group_no })
            .then(function (res) {
              defaultTemplateArray = res.data.results;

              $scope.searchDo(false, true);
            });
        });
    },

    $scope.changeDefaultTemplateGroup = function (template_no, shop_cd, shop_id) {
      if (!systemList.data.default_template_group_no) {
        commonSVC.showMessage('실패', '기본 템플릿 그룹이 설정되어있지 않습니다.');
      }

      const update_data = [[template_no, shop_cd, shop_id]],
            param = {
              template_group_no: systemList.data.default_template_group_no,
              update_data: update_data
            };

      templateModel.editGroup(param, function (status, data) {
        if (status === 'success') {
          commonSVC.showToaster('success', '성공', '이 템플릿을 기본 템플릿 그룹에 추가했습니다.');
          $timeout(function () {
            templateModel.loadGroup({ template_group_no: systemList.data.default_template_group_no })
              .then(function (re) {
                $scope.searchDo(true, true);
                defaultTemplateArray = re.data.results;
              });
          }, 1000);
        } else {
          commonSVC.showMessage('실패', '이 템플릿을 기본 템플릿 그룹에 추가하지 못했습니다.');
        }
      });
    };

    //테이블컬럼 보이기 설정
    $scope.$on('bundleRowChange', function(event, data) {
      setTimeout(function() {
        $('.bundleColspan').attr('colspan', data);
      }, 0);
    });
  });
