'use strict';

angular.module('gmpApp')
  .directive('leftContentSummary', function ($rootScope, settings, commonSVC, userInfo) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentSummary.html',
      link: function ($scope) {
        $scope.orderInfo = [];
        $scope.$parent.tabOrderPreview = function (orderList, lastSelectedRowIndex) {
          $scope.orderInfo = order_preview(orderList, lastSelectedRowIndex);
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        };
        $scope.inquiryInfo = [];
        $scope.$parent.tabInquiryPreview = async function (inquiryList, lastSelectedRowIndex) {
          $scope.inquiryInfo = await inquiry_preview(inquiryList, lastSelectedRowIndex);
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        };
        // LG대리점 모델번호
        $scope.viewModelNo = $rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no;
        $scope.$parent.tabOrderPreviewInit = function () {
          $scope.orderInfo = [];
        };

        $scope.$parent.tabInquiryPreviewInit = function () {
          $scope.inquiryInfo = [];
        };
      } };

    /**
     * 주문 요약 정보 미리보기
     */
    function order_preview(orderList, number) {
      if (!orderList[number]) { return []; }
      const orderInfo = [];

      // prod_img => sale_img 로 변경 2018-04-10 rony
      // orderList[number].prod_img = orderList[number].prod_img || "/assets/images/upload.png";
      orderList[number].prod_img = orderList[number].sale_img || '/assets/images/noimage.png';
      orderList[number].ord_curr_cd = orderList[number].ord_curr_cd || 'KRW';

      orderInfo.push(orderList[number]);

      for (const item in orderList) {
        if (orderList[number].bundle_no && orderList[number].bundle_no == orderList[item].bundle_no && item != number) {
          // prod_img => sale_img 로 변경 2018-04-10 rony
          // orderList[item].prod_img = orderList[item].prod_img || "/assets/images/upload.png";
          orderList[item].prod_img = orderList[item].sale_img || '/assets/images/noimage.png';
          orderInfo.push(orderList[item]);
        }
      }

      return orderInfo;
    }

    // 문의 요약
    async function inquiry_preview(inquiryList, number) {
      if (!inquiryList[number]) { return []; }
      const inquiryInfo = [];
      if (inquiryList[number].sale_img_url) {
        inquiryList[number].img = '';
        inquiryList[number].img += inquiryList[number].sale_img_url || '';
        inquiryList[number].img += inquiryList[number].sale_img1 || '';
      } else {
        inquiryList[number].img = '/assets/images/noimage.png';
      }
      if (inquiryList[number].shop_ord_no) {
        const params = {
          sol_no: inquiryList[number].sol_no,
          shop_cd: inquiryList[number].shop_cd,
          misc5: inquiryList[number].misc5,
          shop_ord_no: inquiryList[number].shop_ord_no
        };

        const url = `${settings.pa20ApiUrl}/app/inquiries/summary`;
        try {
          const { data } = await commonSVC.sendUrl('POST', url, params);
          inquiryList[number].orderInfo = data.results[0];
          if (inquiryList[number].orderInfo && inquiryList[number].orderInfo.set_info) {
            inquiryList[number].orderInfo.set_info = inquiryList[number].orderInfo.set_info.split(',');
          }
          else if (inquiryList[number].orderInfo && inquiryList[number].orderInfo.prod_info) {
            inquiryList[number].orderInfo.prod_info = inquiryList[number].orderInfo.prod_info.split(',');
          }
          inquiryInfo.push(inquiryList[number]);

          return inquiryInfo;
        } catch (err) {
          commonSVC.showToaster('error', '실패', '문의주문 요약 조회 실패');
        }
      }
      else {
        inquiryInfo.push(inquiryList[number]);

        return inquiryInfo;
      }
    }
  });