'use strict';

// 한샘
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A124-Ctrl', function ($scope, $timeout, $ocLazyLoad, commonSVC, A124categorySVC, categoryModel, templateModel) {
    $scope.sel_category = [];
    $scope.cate = [];
    $scope.CateData = angular.copy(A124categorySVC.category_data);
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    $scope.max_depth = 4;
    $scope.savedCateList = []; //다중카테고리 저장하는 배열
    $scope.selectLimit = 10; // 카테고리 선택 제한 개수(-1은 무한대)

    for (let i = 0; i < $scope.max_depth; i += 1) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
    }

    let save_flag = false;    //카테고리 선택여부

    $scope.cateName = () => $scope.savedCateList.map(({ name }) => name).join(', ');

    $scope.removeSelectedCate = (index) => {
      $scope.savedCateList.splice(index, 1);
    };

    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;

      const code_list = $scope.CateData.detailData.category_code.split(',');
      const name_list = $scope.CateData.detailData.category_names_full.split(', ');
      code_list.forEach((code, idx) => {
        $scope.savedCateList.push({
          code, name: name_list[idx]
        });
      });

      for (let depth_idx = $scope.max_depth; depth_idx > 0; depth_idx -= 1) {
        const res = await categoryModel.getScrapParentCategory({ shop_cd: 'A124', std_ol_yn: 0, shop_cate_see_depth: depth_idx, shop_cate_see_type: '일반', shop_cate_see_cd: code_list[0] });
        if (res.data.result.length === 1) {
          await setCategory(res.data.result[0].shop_cate_see_detail.shop_cate_see_cd_detail);
          break;
        }
      }
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, true) : false;

        idx++;
      }
    };

    $scope.select = async (idx, first = false) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      const category_name = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            $scope.cate[idx + 1] = res.data.result;
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else if (!first) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        let category_code = '';
        let etc = {};
        for (let i = 0; i < $scope.max_depth; i++) {
          // 초기화
          if ($scope.sel_category[i].shop_cate_see_cd) {
            etc = { ...$scope.sel_category[i].etc };
          }
        }
        category_code = $scope.sel_category.map(({ shop_cate_see_cd }) => shop_cate_see_cd).join('_');
        if ($scope.selectLimit > 0 && $scope.savedCateList.length === $scope.selectLimit) {
          commonSVC.showMessage('실패', `카테고리는 최대 ${$scope.selectLimit}개 까지 선택 가능합니다.`);
        } else if ($scope.savedCateList.some(({ code }) => code == category_code)) {
          commonSVC.showMessage('실패', '이미 선택된 카테고리입니다.');
        } else if (await commonSVC.showConfirm('추가하시겠습니까?', `선택된 카테고리:\n${category_name}`)) {
          $scope.savedCateList.push({
            name: category_name,
            code: category_code,
            etc
          });

          for (let i = 1; i < $scope.max_depth; i++) {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          }
          $scope.sel_category[0] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    $scope.ShopCateChange = function () {
      if ($scope.CateData.key_ShopCateUseYn) {
        //여기서 서브
        const vdata = {
          site_code: 'A124',
          site_id: $scope.matchingData.site_id,
          mode: 'ShopCate',
        };

        templateModel.templateApi(vdata, function (state, data) {
          if (state != 'error') {
            if (data.ShopCateUseN == 'Array') {
              commonSVC.showMessage('없습니다');
            } else {
              $('#shopCateView').css('display', 'block');
            }
          }
        });
      } else {
        $('#shopCateView').css('display', 'none');
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function (event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }
      if ($scope.savedCateList.length === 0) {
        commonSVC.showMessage('카테고리를 한 개 이상 선택해주세요.');

        return false;
      }

      const category_code = $scope.savedCateList.map(({ code }) => code).join(',');

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names_full = $scope.cateName();
      $scope.CateData.detailData.category_names = `${$scope.savedCateList[0].name}` + ($scope.savedCateList.length > 1 ? ` 외 ${$scope.savedCateList.length - 1}개` : '');
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;
      $scope.CateData.detailData = $scope.savedCateList[0].etc ? { ...$scope.CateData.detailData, ...$scope.savedCateList[0].etc } : $scope.CateData.detailData;

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: 'test',
          category_names: $scope.CateData.detailData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const init = async () => {
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A124', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      $timeout(async () => {
        $scope.cate[0] = res.data.result;

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A124categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });

    };

    init();
  });