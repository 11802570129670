
'use strict';

/**
 * 2018-05-02 chris
 * 티몬 특가
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B851-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, commonSVC, errorSVC, settings, siteInfo, B851categorySVC) {
    const svc = B851categorySVC;
    let saveFlag = false;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터
    $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    /**
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      const cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd);

      clearOpt(index);

      const sel_val = _.find($scope.CateOpt[index], { id: cateCd });

      if (index === '3' || sel_val.leaf == true) {
        saveFlag = true;

        return false; // 마지막 분류이거나, 하위분류없을시
      } else {
        saveFlag = false;
      }
      $scope.loading = true;
      setCateSub(index, cateCd)
        .then(function() {
          $scope.loading = false;
        })
        .catch(function(err) {
          commonSVC.showMessage('로딩실패');
          $scope.loading = false;
        });
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

		 // 하위 카테고리 가져오기
		 function setCateSub(index, cateCd) {
			 const addParam = {
				 cate_data: {
					 depth: parseInt(index) + 1,
					 cate_id: cateCd,
					 leaf: false
				 }
			 };

      return requestPA('etc.scrap_category', addParam)
        .then(function (res) {

          const data = res.data.data;

          if (!data) {
            $scope.CateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
          } // 하위카테고리 없을시 출력
          else {
            $scope.CateOpt[(parseInt(index) + 1)] = data.items;
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 4; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      // var cateKey = Object.keys($scope.Cate)
      // if(cateKey.length === 0) {
      //   commonSVC.showMessage("한개 이상의 카테고리를 선택해주세요");
      //   return false;
      // }
      if (saveFlag == false) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }
      const cateCd = [];
      let valid = false;

      _.each($scope.CateList, function(v, k) {
        const value = $scope.CateData[v];
        const sel_val = _.find($scope.CateOpt[k], { id: value });

        cateCd.push(value);
        if (sel_val && sel_val.leaf == true) {
          valid = true;

          return false;
        }
      });

      if (!valid) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateCd.join('_');

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B851',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: $scope.CateData.detailData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              if ($scope.matchingData.isOnlineAdd === true) {
                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);
              } else {
                $scope.infoLoad();
              }
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          const addParam = {
            cate_data: {
              depth: '0',
              cate_id: '',
              leaf: ''
            }
          };

          return requestPA('etc.scrap_category', addParam);
        })
        .then(function (res) {
          const data = res.data.data.items;

          if (!data) {
            commonSVC.showMessage('로딩실패');
            $scope.loading = false;

            return false;
          } else {
            $scope.CateOpt[0] = data;
            //상품에 매칭된 데이터가 있으면 사용
            if ($scope.matchingData.shop_cate_no_add_info) {
              return {
                sol_cate_shop_add_info: $scope.matchingData.shop_cate_no_add_info
              };
            } else {
              const param = {
                categoryId: $scope.matchingData.categoryId,
                shop_cd: shop_cd,
                shop_id: shop_id
              };

              // 수정확인
              return categoryModel.shopInfoDetailDataLoad(param)
                .then(function (result) {
                  return result.data.data[0];
                });
            }
          }
        })
        .then(function (info) {
          if (info) { // 수정
            const data = info.sol_cate_shop_add_info;

            $scope.CateData.category_code = data.category_code;
            $scope.CateData.category_names = data.category_names;
            //카테고리 로드
            const cate_codes = data.category_code.split('_');

            $scope.CateData.key_lcate = cate_codes[0];

            return setCateSub(0, $scope.CateData.key_lcate)
              .then(function () {
                $scope.CateData.key_mcate = cate_codes[1];
                if (cate_codes.length > 2) {
                  return setCateSub(1, $scope.CateData.key_mcate);
                }
              })
              .then(function () {
                $scope.CateData.key_scate = cate_codes[2];
                if (cate_codes.length > 3) {
                  return setCateSub(2, $scope.CateData.key_scate);
                }

              })
              .then(function () {
                $scope.CateData.key_dcate = cate_codes[3];
                saveFlag = true;
              });
          }
        })
        .catch(function (err) {
          commonSVC.showMessage('로딩실패');
        })
        .finally(function () {
          $scope.loading = false;
        });
      $('#B851List').sortable();
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const user = $scope.userInfo;
      const domain = siteInfo.B851.domain;

      const userInfo = {
        shop_id: user.shop_id || '',
        shop_pwd: user.shop_pwd || '',
        etc1: user.etc1 || '',
        etc2: user.etc2 || '',
        etc3: user.etc3 || '',
      };

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    init();
  });
