'use strict';

angular.module('gmpApp')
  .controller('AddStockReAllocRuleCtrl', function ($scope, $rootScope, $compile, data, $uibModalInstance, commonSVC, gettextCatalog, inventoryModel, settings, $timeout) {
    $scope.use_yn = data.alloc_type === 're_alloc';

    let isAdded = false; // 재고할당 설정이 일어났는지

    const search = {
      searchData: {
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        showCount: 10,       // 한 페이지에 보이는 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: 'a.sku_cd' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'a.prod_name' },
        ],
      },
      searchForm: {
        search_word: '',      //검색어
        search_key: 'all',    //검색구분
        orderby: 'wdate desc', //정렬
        searchDetail: {},
      },
    };

    // 상세조건 검색
    const searchDetail = [
      {
        title: gettextCatalog.getString('재고 상태'),
        search_name: 'state',
        item_list:
          [
            { code: '1', name: '정상' },
            { code: '2', name: '품절' },
            { code: '3', name: '재고부족' }
          ],
        select_value: '1'
      },
      {
        title: gettextCatalog.getString('재고 재할당'),
        search_name: 'alloced_yn',
        item_list:
          [
            { code: 'all', name: '전체' },
            { code: true, name: '설정' },
            { code: false, name: '미설정' },
          ],
        select_value: 'all'
      },
    ];

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchDetail = angular.copy(searchDetail);

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      // $scope.searchData = angular.copy(search.searchData);
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: [
        'stock_status', 'stock_cnt', 'real_stock', 'link_channel_cnt'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: [
        'stock_status', 'sku_cd', 'prod_name', 'attri', 'stock_cnt',
        'real_stock', 'link_channel_cnt', 'nokey'
      ],
      alignRightColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/alloc/list-of-rule-base`,
        requestWillAction: (data) => {
          const result = Object.assign(data, $scope.searchForm);

          result.re_alloc_yn = true;

          // 검색 조건 전체일때
          if (result.search_word && result.search_key === 'all') {
            result.search_tables = $scope.searchData.search_key_items.map((element) => element.value);
            result.search_tables.splice(0, 1);
          }

          // 상세검색 조건
          $scope.searchDetail.forEach((search) => {
            result.searchDetail[search.search_name] = search.select_value;
          });

          return result;
        },
        requestDidAction: (result) => {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.prodList = result.results;

          // 집계카운트
          $scope.countList = {
            total: result.total
          };

          return result.results;
        }
      },
      columns: [
        {
          key: 'stock_status',
          title: '상태',
          width: 45
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 170
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 290
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 170
        },
        {
          key: 'attri',
          title: '속성',
          width: 160
        },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          width: 100,
          template: row => {
            return row.stock_cnt < 0 ? 0 : row.stock_cnt;
          }
        },
        {
          key: 'real_stock',
          title: '실재고',
          width: 100,
          template: row => {
            return row.real_stock < 0 ? 0 : row.real_stock;
          }
        },
        {
          key: 'link_channel_cnt',
          title: '연동상품',
          width: 97
        },
        {
          key: 'nokey',
          title: '재고할당',
          width: 74,
          template: row => {
            const btn = row.prod_depot_ol_shop_yn
              ? `<button
                  type="button"
                  ng-click="grid.appScope.setRule(${row.prod_no}, ${row.depot_no}, ${row.real_stock}, ${row.stock_cnt})"
                  class="btn btn-default btn-xxs">
                수정
                </button>`
              : `<button
                  type="button"
                  ng-disabled="${!row.link_channel_cnt}"
                  ng-click="grid.appScope.setRule(${row.prod_no}, ${row.depot_no}, ${row.real_stock}, ${row.stock_cnt})"
                  class="btn btn-primary btn-xxs">
                설정
                </button>`;

            return `
              <div class="btn-group-justified">
                <div class="btn-group">
                  ${btn}
                </div>
              </div>
            `;
          }
        },
      ]
    };

    /**
     * 재고재할당 설정/수정
     */
    $scope.setRule = function(prod_no, depot_no, real_stock, stock_cnt) {
      const data = {
        prod_no,
        depot_no,
        real_stock,
        stock_cnt
      };

      const modal = commonSVC.openModal('xxg', { data: data }, 'SetStockReAllocChannelRuleCtrl', 'views/stock/inventory/set_stock_reallocation_channel_rule.html');

      modal.result.then(function () {
        isAdded = true;
        $scope.searchDo(false);
      });
    };

    /**
     * 닫기
     */
    $scope.cancel = function () {
      if (isAdded) {
        $uibModalInstance.close('success');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };
  });
