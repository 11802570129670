'use strict';

import angular from 'angular';
import confetti from 'canvas-confetti';

angular.module('gmpApp')
  .controller('MainController', function(
    $scope, $rootScope, userInfo, Idle, $state, $timeout, commonSVC, MessageSVC, jquerySVC, workSVC, columnSVC, $location, socketIo, commonModel, shipmentSVC,
    cautionModel, settings, gettextCatalog, localStorageService, onlineProductModel, shipmentModel, systemModel, columnModel, $compile, maintenanceInfo,
    channelSVC, tokenSVC, channelList, addColumnList, deliveryModel, $interval, detectBrowser, $window, atalkModel, indvPayInfo, payModel, shopAccountModel) {

    // view 에서 사용할 변수
    $scope.userInfo = userInfo;
    // 해외 주문 연동 오픈 여부 (현재 판토스만 오픈, 추후 전체 오픈 시 해당 필드 및 사용한 곳 삭제 요망)
    $rootScope.accessGlobalYN = $scope.userInfo.user.sol_no === 596 || $scope.userInfo.user.aff_no === 1051 || $scope.userInfo.user.sol_no === 2745;
    // 판토스 협력사 계정 (판토스 특별처리 사용)
    $rootScope.pantosYN = $scope.userInfo.user.aff_no === 1051;
    $scope.userPermission = userInfo.permission;

    // topBanner
    $scope.topBanner = [];
    $scope.showBannerFlag = false;

    /**
     * 상단 노출 배너 리스트 조회
     */
    const bannerInit = () => {
      commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/promotion/banner/topList`, {}, (state, data) => {
        $scope.topBanner = data.filter(promo => {
          const showBanner = localStorageService.get(`topBannerShow_${promo.promo_no}`);

          return !showBanner || (parseInt(moment().format('YYYYMMDD')) > parseInt(moment(typeof showBanner === 'number' ? showBanner.toString() : showBanner).add(6, 'days').format('YYYYMMDD')));
        });

        if ($scope.topBanner.length) {
          $scope.showBannerFlag = true;
          // 배너바 슬라이드 설정
          $(function() {
            $('#carouselBanner').carousel({
              interval: 3000,
              pause: 'hover',
              wrap: true,
              keyboard: true
            });
          });
        }
      });
    };

    bannerInit();

    /**
     * 프로모션 조회수 업데이트
     */
    $scope.goToPromo = (banner, index) => {
      // 상단 띠배너용 dataLayer
      const dataLayer = $window.dataLayer = $window.dataLayer || [];

      if (!banner.promo_no) {
        dataLayer.push({
          event: 'Banner Click',
          category: 'Banner Click',
          label: `${index}_https://app.playauto.io/#!/pay_list/`,
          action: 'click_banner_top'
        });

        $rootScope.openPayReq();

        return;
      } else if (!banner.promo_url) {
        return;
      }

      if (banner.promo_no === 1133) {
        $scope.couponManagement();
      } else if (banner.promo_no === 1132) {
        $rootScope.openPayReq();
      }

      dataLayer.push({
        event: 'Banner Click',
        category: 'Banner Click',
        label: `${index}_${banner.promo_url}`,
        action: 'click_banner_top'
      });

      // 프로모션 카운트 수 업데이트
      commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/promotion/updateViewCnt`, { promo_no: banner.promo_no });

      if (![1133, 1132].includes(banner.promo_no)) {
        window.open('about:blank').location.href = banner.promo_url;
      }

    };

    /**
      * 상단 노출 배너 비노출 (특정 배너만 비노출)
    */
    $scope.bannerCancel = (del_banner, index) => {
      // 삭제 시 7일간 노출 안함
      const hideUntilDate = moment().add(7, 'days').format('YYYYMMDD');
      localStorageService.set(`topBannerShow_${del_banner.promo_no}`, hideUntilDate);

      $('.top-banner').eq(index).removeClass('active');

      if (index === $scope.topBanner.length - 1) {
        $('.top-banner').eq(0).addClass('active');
      } else {
        $('.top-banner').eq(index).addClass('active');
      }

      $scope.topBanner = $scope.topBanner.filter(banner => banner.promo_no !== del_banner.promo_no);

      if ($scope.topBanner.length === 0) {
        $scope.showBannerFlag = false;
      }
    };

    // ----------------------------------------------------------------------------------------------
    // 정기점검 처리.
    // 사무실및 로컬호스트에서는 점검페이지 안띄움.
    // 2019-05-03 rony
    if (maintenanceInfo && maintenanceInfo.cut_sdate && (!$rootScope.adminMode || userInfo.user.sol_sub_type === 'QA')) {
      const fixBefore30minutes = moment(maintenanceInfo.cut_sdate).add(-30, 'minute');
      const fixStartTimes = moment(maintenanceInfo.cut_sdate);

      if (fixBefore30minutes > moment()) {
        $timeout(function() {
          commonSVC.showMessage('점검 30분전입니다.', '- 30 분후 점검을 위해 솔루션이 자동 종료됩니다.\n- 진행중인 작업을 마무리해주십시오.');
        }, fixBefore30minutes.diff(moment()));
        maintenanceInfo = {};
      }

      if (fixStartTimes > moment()) {
        $timeout(function() {
          commonSVC.showMessage('점검으로 인해 로그아웃 처리됩니다.', '점검페이지의 공지내용을 확인해주세요.', function() {
            $rootScope.logout();
          }, false);
        }, fixStartTimes.diff(moment()));
      } else {
        commonSVC.showMessage('점검으로 인해 로그아웃 처리됩니다.', '점검페이지의 공지내용을 확인해주세요.', function() {
          $rootScope.logout();
        }, false);
      }
    }

    // ----------------------------------------------------------------------------------------------
    $scope.workList = [];

    $scope.tabIndex = 0;

    $scope.dashboardList = {
      결제완료: { link: 'main.order_shipment_payment_list', cName: 'total', action: { page: 'payment', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'default' },
      신규주문: { link: 'main.order_shipment_order_list', cName: 'total', action: { page: 'order', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'default' },
      출고대기: { link: 'main.order_shipment_unstoring_list', cName: 'waiting', action: { page: 'unstoring', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'default' },
      출고완료: { link: 'main.order_shipment_delivery_list', cName: 'finished', action: { page: 'delivery', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'default' },
      배송중: { link: 'main.order_shipment_delivery_list', cName: 'shipping', action: { page: 'delivery', date_type: 'mdate', sdate: moment().subtract(7, 'day').format('YYYY-MM-DD') }, pname: 'default' },
      취소요청: { link: 'main.order_shipment_claim_list', cName: 'cancel_req', action: { page: 'claim', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'more' },
      반품요청: { link: 'main.order_shipment_claim_list', cName: 'return_req', action: { page: 'claim', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'more' },
      교환요청: { link: 'main.order_shipment_claim_list', cName: 'exchange_req', action: { page: 'claim', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'more' },
      신규문의: { link: 'main.order_customer_inquiry_list', cName: 'total', action: { page: 'inquiry', inq_status: '신규문의', inq_type: '', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'more' },
      긴급메세지: { link: 'main.order_customer_inquiry_list', cName: 'emergency', action: { page: 'inquiry', inq_status: '신규문의', inq_type: '긴급메세지', date_type: 'wdate', sdate: moment().format('YYYY-MM-DD') }, pname: 'more' }
    };

    // 네 권한이 사라져 있으면 메모탭이 사라짐
    $scope.memoAccess = userInfo.user.auth_type.includes('관리자') || ($scope.userPermission.order.use_yn || $scope.userPermission.online.use_yn || $scope.userPermission.stock.use_yn || $scope.userPermission.shipping.use_yn);

    // 열려있는 창 확인
    $scope.isOpen = {
      order: false,
      claim: false,
      inquiry: false
    };

    // 솔루션 사용정보 모달
    $scope.solInfo = function () {

      const resolve = {
        settings: settings,
      };

      resolve.systemList = function () {
        return systemModel.load();
      };

      commonSVC.openModal('xg', resolve, 'SolutionUseInfoCtrl', 'views/home/modals/solution_use_info.html');
    };

    // 퀵수집 활성화 여부 (주문수집 or 문의수집 권한 있는경우)
    if (userInfo.user.auth_type.includes('관리자')) {
      $scope.quickCollectEnable = true;
    } else {
      $scope.quickCollectEnable = (
        ($scope.userPermission.order?.use_yn && $scope.userPermission.order?.roles.scrapOrder)
        || ($scope.userPermission.inquiry?.use_yn && $scope.userPermission.inquiry?.roles.scrapCs)
        || ($scope.userPermission.online?.use_yn && $scope.userPermission.online?.roles.scrapProd)
      ) && ($scope.userPermission.channel?.use_yn && Object.values($scope.userPermission.channel?.roles).some(v => v));
    }

    /**
     * 퀵수집 실행
     */
    $rootScope.quickCollect = _.throttle(async (isRequireCofig = false) => {
      if (localStorage.getItem(`isOnlineListLoading_${userInfo.user.sol_no}`) === 'true') {
        commonSVC.showMessageHtml('', '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');

        return false;
      }
      if (!$scope.quickCollectEnable) {
        return;
      }

      const jobAllList = [...workSVC.quickCollectJobListCheck, ...workSVC.quickCollectJobListRadio, { workName: '신규주문 가져오기', job_type: 'ScrapOrder', selected: true, tooltip: '주문 확인이 완료된 주문을 신규주문으로 가져옵니다.', auth: 'order' }];

      let jobList = localStorageService.get(`quickCollectNew_${userInfo.user.m_no}`)?.split(',') || [];
      const mallList = localStorageService.get(`quickCollect_selectedMall_${userInfo.user.m_no}`)?.data || [];

      // 사용자계정일 경우 설정된 권한에 따라 작업 필터링
      if (!userInfo.user.auth_type.includes('관리자')) {
        jobList = jobList.filter(job => {
          const jobAuth = jobAllList.find(({ job_type }) => job_type === job)?.auth,
                hasAuth = $scope.userPermission[jobAuth]?.use_yn && $scope.userPermission[jobAuth].roles[jobAuth === 'order' ? 'scrapOrder' : (jobAuth === 'inquiry' ? 'scrapCs' : 'scrapProd')];

          return hasAuth;
        });
      }

      if (!jobList.length || isRequireCofig) {
        $scope.quickCollectConfig();

        return;
      }

      $timeout(function () {
        $scope.waitBtnDisableFlag = true;
      });

      try {
        const { data: { result } } = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/work/quick-collect`, { config: jobList.join(), mallList });

        if (jobList.includes('ScrapProd')) {
          localStorageService.set(`quickCollectNew_${userInfo.user.m_no}`, jobList.filter(job => job !== 'ScrapProd').join());
        }
        if (!result.error) {
          commonSVC.showToaster('success', '성공', '작업이 등록되었습니다.');
        } else {
          commonSVC.showToaster('success', '작업등록 완료', `성공: ${result.success}, 실패: ${result.error}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
        }
      } catch (err) {
        if (err.data.error === 'check_charge') {
          commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
            if (confirm) {
              $state.go('main.pay_list');
              $state.reload();
            }
          });
        } else {
          commonSVC.showToaster('error', '실패', '퀵수집 작업 등록에 실패했습니다.');
        }
      } finally {
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        });
      }
    }, 1000, { trailing: false });

    /**
     * 퀵수집 설정 모달
     */
    $scope.quickCollectConfig = async () => {
      const permissions = {
        order: $scope.userPermission.order?.use_yn && $scope.userPermission.order?.roles.scrapOrder,
        inquiry: $scope.userPermission.inquiry?.use_yn && $scope.userPermission.inquiry?.roles.scrapCs,
        online: $scope.userPermission.online?.use_yn && $scope.userPermission.online?.roles.scrapProd
      };

      const authName = {
        order: '주문 수집',
        inquiry: '문의 수집',
        online: '상품 가져오기'
      };

      const hasAllPermissions = Object.values(permissions).every(v => v),
            isAdmin = userInfo.user.auth_type.includes('관리자');

      if (isAdmin || hasAllPermissions) {
        commonSVC.openModal('w1200', {}, 'QuickCollectCtrl', 'views/home/modals/quickCollect.html');

        return;
      }

      const missingPermissions = Object.entries(permissions)
        .filter(([, value]) => !value)
        .map(([key]) => authName[key])
        .join(', ');

      if (missingPermissions) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '퀵수집 권한 부족',
          text: `
            <div>
              <ul>
                <li>퀵수집을 실행할 수 있는 ${missingPermissions} 권한이 없습니다.</li>
                <li>실행 권한 부여를 원할 경우 마스터 계정 사용자에게 문의하세요.</li>
              </ul>
            </div>
          `,
          html: true,
          showCancelButton: false,
          confirmButtonText: '확인'
        });

        if (confirm) {
          return setTimeout(() => {
            commonSVC.openModal('w1200', {}, 'QuickCollectCtrl', 'views/home/modals/quickCollect.html');
          }, 100);
        }

        return;
      }
    };

    // 커스텀 컬럼 정보 입력.
    $rootScope.addColumnList = addColumnList;

    $scope.goDetail = function (status) {
      const role = (status === '신규문의' || status === '긴급메세지') ? 'order.customer' : 'order.shipment';

      if (commonSVC.checkRole(role, userInfo)) {
        Object.assign($rootScope.side_search, $scope.dashboardList[status].action);
        $rootScope.side_search.search = true;
        $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
        $rootScope.side_search.status = status;
        $rootScope.side_search.tabIndex = $scope.dashboardList[status].tabIndex;
        $rootScope.side_search.selectCount = $scope.dashboardList[status].cName;
        $state.go($scope.dashboardList[status].link);
        $rootScope.$broadcast(`sidebarSearch_${$scope.dashboardList[status].action.page}`, $scope.side_search);
      }
    };

    //2017-03-03 william 페이지 잠금기능
    $scope.started = false;

    // 2017-03-21 MatthewKim 작업 서비스 인포메이션 연결
    $scope.work_info = workSVC.info;

    // 2017-03-13 MatthewKim 메인 메뉴 로딩용 프레픽스 얻기
    $scope.main_menu_url = 'views/main_menu.html';

    $scope.use_channel_yn = $scope.userPermission.scm && !_.isUndefined($scope.userPermission.scm.use_yn) ? $scope.userPermission.scm.use_yn : true;

    // 2018-01-19 chris 레프트 메뉴의 주문검색 권한 체크 (전체주문 리스트 권한있는경우 오픈)
    $scope.isShowOrderSearch = commonSVC.checkRole('order.integrated', userInfo);

    // 2018-12-10 Alvin 1:1문의 모달창 열림 여부
    $rootScope.questionModalOpen = false;
    // 2020-10-14 Boris 도움말 모달창 열림 여부
    $rootScope.helpModalOpen = false;
    // 쇼핑몰별 주의사항 모달창 열림 여부
    $rootScope.shopModalOpen = false;

    $rootScope.draggableModalZindex = (p0) => {
      // 1:1문의 모달, 도움말 모달 호출에 관한 처리
      // 만일 이미 열려있는 모달이 있는데 클릭이 되었다면, z-index만 높혀주고 접혀있다면 펴줌
      const zIndexes = [1170, 1160, 1150],
            modalIds = ['modal-question', 'modal-help', 'modal-shop'];
      // z-index 선택된 모달, 높은순으로 정렬
      const orderedModalIds = modalIds
        .filter(id => $(`#${id}`).length)
        .sort((a, b) => {
          if (b.includes(p0)) {
            return 1;
          } else if (a.includes(p0)) {
            return -1;
          } else {
            return $(`#${b}`).parent().parent().parent().css('z-index') - $(`#${a}`).parent().parent().parent().css('z-index');
          }
        });

      for (const idx in orderedModalIds) {
        $(`#${orderedModalIds[idx]}`).parent().parent().parent().css('z-index', zIndexes[idx]);
      }
    };

    // 2018-05-17 chris 만료일이 지난경우 결제관리 페이지로 이동
    if (userInfo.user.sol_expired) {
      // 솔루션 사용불가 메세지 노출여부
      const showMsg = !$rootScope.isWakeupLogin;

      disableSolution('main.pay_list', showMsg);
    }
    // 쇼핑몰 계정이 버전에서 허용가능한 갯수보다 많이 등록된경우 쇼핑몰 계정 설정 페이지로 이동.
    else if (userInfo.user.shopAccount_exceeded) {
      disableSolution('main.settings_shop_account');
    }
    // 사용자 갯수가 현재버전에서 허용가능한 갯수보다 많이 등록된 경우 사용자 계정관리 페이지로 이동
    else if (userInfo.user.userAccount_exceeded) {
      disableSolution('main.configs_user');
    }
    // LG 이용약관 동의하지 않은 경우 메인 페이지로 이동 (약관동의 창 노출)
    else if ($rootScope.affName === 'LG전자' && userInfo.user.pa_sol_no && userInfo.user.pa_sol_no !== 26164 && userInfo.user.user_grade === '마스터' && !userInfo.user.etc?.LGTermsAgreeDate) {
      disableSolution('main.home');
    }
    // 위수탁계약 동의하지 않은 경우 메인 페이지로 이동 (약관동의 창 노출)
    else if (!userInfo.user.mas_contract_yn && (!$rootScope.adminMode || userInfo.user.sol_sub_type === 'QA')) {
      if (userInfo.user.user_grade === '마스터') {
        disableSolution('main.home');
      } else {
        // 스토리지 저장된 값 확인. 로그인 후 1회만 띄워주기 위한 처리
        const mas_contract_yn = localStorageService.get('mas_contract_yn');

        if (!mas_contract_yn) {
          $timeout(function() {
            localStorageService.set('mas_contract_yn', 1);

            commonSVC.showMessage('개인정보 처리 위수탁 계약 안내',
              '개인정보 처리 위수탁 계약 미동의 상태입니다.\n마스터 계정으로 접속 후 \'개인정보 처리 위수탁 계약\'에 동의해주시기 바랍니다.');
          }, 1500);
        }
      }
    }

    // 2018-01-14 chris 레프트 메뉴 링크 클릭 이벤트 처리
    $scope.mainlink = async (state, event, param) => {
      // 현재 페이지가 아닌 경우에만 페이지 이동
      if ($state.current.name != state) {
        if (state === 'main.notice_list') {
          // 공지사항 클릭 시 시스템 공지사항 부터 노출
          $rootScope.noticeCurrentTap = 'system';
        }
        if (columnSVC.addColStatus.findIndex(o => { return o === state; }) > -1) {
          $rootScope.addColumnList = await columnModel.addColumnList({ addcol_type: 'all' });
        }

        // 주문페이지 접속시 로그.
        if (shipmentSVC.connectLogPages.includes(state)) {
          commonModel.setConnectLog({ state, detectBrowser });
        }

        $state.go(state, param);
        $state.reload();
      } else {
        event.stopPropagation();
      }
    };

    /**
     * 알림톡 발송 모달 오픈
     */
    $scope.altalkSend = async () => {
      const plusIdList = (await atalkModel.accountList({ name: $rootScope.user_profile.sol_no })).data?.result;
      const resolve = {
        data: {
          open: 'top',
          isHelpModal: true,
          type: 'direct',
          plusIdList: plusIdList
        }
      };
      commonSVC.openModal('x3g', resolve, 'AltalkSendCtrl', 'views/alimtalk/modals/altalk_send.html');
    };

    /**
     * 채팅창 열기/닫기
     */
    $scope.openChat = () => {
      if ($rootScope.channel.isOpen) {
        channelSVC.hide();
      } else {
        channelSVC.show();
      }
    };

    function closeModals() {
      if ($scope.warning) {
        $scope.warning.close();
        $scope.warning = null;
      }

      if ($scope.timedout) {
        $scope.timedout.close();
        $scope.timedout = null;
      }
    }

    $scope.$on('IdleStart', function() {
      closeModals();
    });

    $scope.$on('IdleEnd', function() {
      closeModals();
    });

    $scope.$on('IdleTimeout', function() {
      closeModals();
      $rootScope.lockScreen('auto');
      // var params = {
      //   data: {viewType:"auto"}
      // };
      // $scope.timedout = commonSVC.openModal("", params, 'EtcLockSreenCtrl', "views/etc/lock_screen.html");
      // $scope.timedout.result.then(function (re){
      //   if(re === "success"){
      //     //유휴 상태 해제
      //     // 문서제목 PLAYAUTO 로 다시 바꺼줌. 2018-03-20 rony
      //     window.document.title = "PLAYAUTO";
      //     $rootScope.start();
      //   }
      // })
    });

    $rootScope.start = function() {
      closeModals();
      // unwatch 후에 watch 를 왜 해주나 싶은데.. 요게 없으면 idle 가 작동안함.
      Idle.unwatch();
      Idle.watch();
      $scope.started = true;
    };

    /**
     * 화면잠금 창 열기.
     */
    $rootScope.lockScreen = _.debounce(function(mode) {

      Idle.unwatch();
      closeModals();

      $scope.reCheckClass = {};
      $scope.lockSreenType = mode || 'manual';

      // 클라이언트 토큰도 초기화
      tokenSVC.setClientToken(null);

      // if(data.viewType == "jwt_expired"){
      //   $scope.modalTitle = "로그인 정보가 만료되었습니다.";
      // }else if(data.viewType == "manual"){
      //   $scope.modalTitle = "화면 잠그기";
      // }else{
      //   $scope.modalTitle = "장시간 동안 사용하지 않아, 화면이 잠겼습니다.";
      // }
      const lock_sreen = '' +
        '<form id="lock_sreen_form" class="form-horizontal" role="form" name="exModalForm" form-validation re-check-class="reCheckClass">' +
        '<div class="lock-wrap" ng-controller="EtcLockSreenCtrl">' +
        '    <div class="lock-wrap-box">' +
        '        <div class="lock-box">' +
        '            <img src="assets/images/ico-lockscreen.png" alt="">' +
        '            <h2>플레이오토</h2>' +
        '            <!-- 이메일/비밀번호 -->' +
        '            <div class="text-left" ng-if="lockSreenType == \'jwt_expired\'" style="margin-bottom: 10px">' +
        '                <input type="text" name="email" class="recheck" ng-model="lock_sreen_id" placeholder="이메일" ng-required="lockSreenType == \'jwt_expired\'" >' +
        '            </div>' +
        '            <!-- 비밀번호만 -->' +
        '            <div class="text-left">' +
        '              <input type="password" name="password" class="recheck" ng-model="lock_sreen_pass" required="required"' +
        '                     custom-required invalid-message="비밀번호를 입력해 주세요." placeholder="비밀번호">' +
        '            </div>' +
        '            <div ng-if="error != \'\'">' +
        '              <div class="alert bg-danger text-center mt-20 p-5" style="clear: both;">{{error}}</div>' +
        '            </div>' +
        '            <div class="btn-group-justified">' +
        '                <div class="btn-group">' +
        '                    <button class="btn btn-primary btn-lock"  ng-click="ok()">잠금해제</button>' +
        '                </div>' +
        '            </div>' +
        '        </div>' +
        '        <div class="lock-box-logout">' +
        '            <a href="#" ng-click="logout()">' +
        '                <img src="assets/images/ico-logout.png" alt=""><br>' +
        '                로그아웃' +
        '            </a>' +
        '        </div>' +
        '    </div>' +
        '</div>' +
        '</form>';

      $('body').append($compile(lock_sreen)($scope));

    }, 1000);

    $scope.$on('$viewContentLoaded', function() {
      // 재고미사용버전에 튜토리얼중이 아닌데 재고 탭 열려있을경우 닫기
      if (!$rootScope.user_profile.sol_stock) {
        if ($('.chrome-tab[tab-id="tab_prod_product_list"]').length) {
          $rootScope.$emit('$closeTab', 'tab_prod_product_list');
        }
        if ($('.chrome-tab[tab-id="tab_prod_product_add"]').length) {
          $rootScope.$emit('$closeTab', 'tab_prod_product_add');
        }
      }

      //2017-03-03 william idle 호출
      $rootScope.start();

      // 메인 사이드바 로딩 완료시 앱 테마 초기화 1회 실행
      // 2017-03-13 MatthewKim
      $scope.sidebarMainOnLoad = function() {
        $timeout(function() {
          App.initComponents();
          jquerySVC.OnViewChanged();
        });
      };

    });

    // 메인 탭 바인딩 변수 (이 변수가 tabmenu 디렉티브에 연결되서 사용됨)
    $scope.mainTab = [];

    $(window).resize(_.debounce(function () {
      // 2017-04-24 chris 브라우저 가로사이즈가 1280픽셀 미만인 경우 사이드바 접음
      if ($(window).innerWidth() < 1280) {
        $('body').addClass('sidebar-xs');
        $('.menu-search').hide();
        $('.menu-show-btn').hide();
        $('.sidebar-user').hide();
      } else {
        $('body').removeClass('sidebar-xs');
        if ($('.sidebar-kr').width() < 100) {
          $('.menu-search').hide();
          $('.menu-show-btn').hide();
          $('.sidebar-user').hide();
        } else {
          $('.menu-search').show();
          $('.menu-show-btn').show();
          $('.sidebar-user').show();
        }
      }

      // 2017-12-04 Daniel 리사이징시 이벤트발생막음
      // $rootScope.$broadcast('sidebarChange');
    }, 150));

    $scope.changeSidebar = function() {
      // 2018-01-03 chris hide 처리 먼저함
      $('.menu-search').hide();
      $('.menu-show-btn').hide();
      $timeout(function () {
        //2017-09-05 roy 사이드바 가로 사이즈가 40픽셀 미만인 경우 검색창 접음
        if ($('.sidebar-kr').width() < 100) {
          $('.menu-search').hide();
          $('.menu-show-btn').hide();
        } else {
          $('.menu-search').show();
          $('.menu-show-btn').show();
        }
        $rootScope.$broadcast('sidebarChange');
      }, 140);
    };

    // 작업관리 검색 2019-02-20 rony
    $rootScope.work_search = {};

    /**
     * 2017-08-02 ally
     * 사이드바 검색
     */
    $rootScope.side_search = {}; // 사이드 카운트
    $rootScope.order_search = {}; // 사이드바 검색
    $rootScope.order_search.type = 'order_code';
    $rootScope.order_search.input = '';
    $rootScope.order_search.key = 'shop_ord_no';
    $rootScope.order_search.name = '주문번호';
    $rootScope.order_search.search = false;
    $rootScope.order_search.cssValue = true;

    $rootScope.noti_search = {}; // 알림창의 연결기능으로 검색시

    $rootScope.memo_noti_search = {}; // 메모알림의 연결기능으로 검색시
    $rootScope.memo_etc_search = {}; // 메모관리 하이퍼링크 연결기능으로 검색시

    $scope.changeSearchOrder = function() {
      $rootScope.order_search.cssValue = true;
    };

    $scope.searchOrder = function() {
      if ($rootScope.order_search.input.length <= 0) {
        $rootScope.order_search.cssValue = false;

        return;
      }
      $rootScope.order_search.page = 'integrated';
      $rootScope.order_search.search = true;
      // $state.go("main.order_shipment_integrated_list", {s_type: $scope.order_search.type, s_input: $scope.order_search.input }, { inherit: false, notify: true });
      $state.go('main.order_shipment_integrated_list');

      $timeout(function() {
        $rootScope.$broadcast('sidebarSearch', $scope.order_search);
      }, 500);
    };

    $scope.changeOrderSearchKey = function() {
      switch ($scope.order_search.type) {
        case 'order_code':
          $scope.order_search.key = 'shop_ord_no';
          $scope.order_search.name = '주문번호';
          break;
        case 'reg_code':
          $scope.order_search.key = 'shop_sale_no';
          $scope.order_search.name = '쇼핑몰 상품코드';
          break;
        case 'deli_invoice':
          $scope.order_search.key = 'invoice_no';
          $scope.order_search.name = '운송장 번호';
          break;
        case 'order_name':
          $scope.order_search.key = 'order_name,order_id,to_name';
          $scope.order_search.name = '주문자명, 수령자명';
          break;
        case 'tel_list':
          $scope.order_search.key = 'tel';
          $scope.order_search_name = '주문자, 수령자 연락처';
          break;
        case 'shop_sale_name':
          $scope.order_search.key = 'shop_sale_name';
          $scope.order_search.name = '쇼핑몰 상품명';
          break;
      }
    };

    /**
     * 채널정보 갱신 이벤트
     */
    $scope.$on('channel_update', function(event, { data, mode }) {
      // 사용중인 쇼핑몰 리스트 갱신
      $rootScope.use_channel_list = data?.results || [];

      // 사용중인 쇼핑몰 카운트 갱신
      if (data?.channelLimit) {
        userInfo.user.shopAccountCnt = data.channelLimit.total;
      }

      // 솔루션 접속 후 채널 정보 불러올때 pa5 소켓 연결.
      // 채널구독을 위해서는 사용중인 채널정보를 알아야 하기 때문에 AppController 에 하지않고 여기에 처리.
      workSVC.pa5_job_connect();

      // 반자동 쇼핑몰 리스트 갱신
      if (data?.manualOrderShops) {
        userInfo.user.manualOrderShops = data.manualOrderShops;
      }

      // 판매자센터 로그인 메뉴 리스트, makeglob 은 한개만 반환
      // 라쿠텐/큐텐은 판매자센터 접속 미지원
      // KRSC 계정별 1개만 반환. 고정된 shop_cd 를 사용할수 없음
      $rootScope.use_channel_list_scm = $rootScope.use_channel_list.filter(channel => {
        switch (channel.pa_shop_cd) {
          case 'Z013':
            return channel.shop_cd === 'G013';
          case 'X097':
            return false;
          case 'X098':
            return false;
          case 'X099':
            return channel.shop_cd === channel.pa_shop_cd;
        }

        return true;
      });

      // 물류 배송 주문 사용 확인
      $rootScope.isFulfillment = !!$rootScope.use_channel_list.find(shop => ['A001', 'A006', 'A077'].includes(shop.pa_shop_cd) && shop.etc1 === 'Y');

      $rootScope.$applyAsync();

      // 쇼핑몰 아이디 갯수제한으로 인해 사용불가 상태에서 계정 삭제로 인한 정상화 시 브라우저 새로고침.
      if (userInfo.user.shopAccount_exceeded && Number(userInfo.user.shopVersionCnt) >= userInfo.user.shopAccountCnt) {
        location.reload();
      }
    });

    // 채널관련 정보 갱신을 위해 1회 호출
    $rootScope.$broadcast('channel_update', { data: channelList.data });

    /**
     * 조건에 해당하는 채널만 조회
     * {
     *    site_action: 'ScrapOrder',                        // use_yn=1 인 작업명. 기본 조건. act 조건이 없으면 func 만 보내도 됨
     *    all_use_yn : true,                                // 전체 쇼핑몰. site_action 함께 사용. 채널에 use_yn 값 대입해서 전달됨 (1,0,undefined)
     *    func       : shop => shop.pa_shop_cd == 'A001'    // func  : 함수형 조건. AND 조건임. 다양한 case 대응
     * }
     */
    $rootScope.useChannelList = function({ site_action, all_use_yn, func }) {
      // 지원종료된 쇼핑몰은 제외 return
      const getLiveChannel = ['ScrapOrder', 'SyncOrderState', 'ScrapCS', 'ScrapProd'].includes(site_action);

      let avail;

      return $rootScope.use_channel_list.filter(o => {
        if (!site_action) { avail = true; }
        else {
          o.use_yn = o.work_act_list?.find(w => w.work_act == site_action)?.use_yn;
          o.prod_use_yn = userInfo.user.sol_no == 2745 || (o.shop_type != 'ORD_M' && o.use_yn);

          avail = (all_use_yn || o.use_yn) && (!(getLiveChannel && o.sol_type.includes('TERMINATE')));
        }

        return avail && (!func || func(o));
      });
    };

    deliveryModel.deliveryTrackingList()
      .then(function (data) {
        $rootScope.possibeTrackingView = data.data.results;
      });

    try {
      // SocketIo 테스트
      if (!$rootScope.socket) {
        $rootScope.socket = socketIo.socketConnect();
        socketIo.init($rootScope.socket);
      }
    } catch (err) {
      commonSVC.showMessage('ERROR', err);
    }

    /**
     * GMP Chrome Extensions 연동 판매자센터 로그인
     * 2017-01-19 MatthewKim
     *
     * 실행시 설치 여부 검사하여 실행 시킴
     * @param mall
     */
    $rootScope.sellerCenter_login = async function(mall) {
      // 판매자센터접속 미지원 쇼핑몰 처리 2018-04-09 Gargamel
      switch (mall.pa_shop_cd) {
        case 'A012': // 롯데닷컴
        case 'B009': // 롯데백화점
          commonSVC.showMessageHtml('실패', '해당 쇼핑몰의 관리자 어드민은 Chrome 브라우저에서 최적화 되어있지 않아 판매자센터 자동 접속 기능을 지원하지 않습니다.');

          return;

        // 판매자센터 미지원이지만 로그인 페이지만 열어줌
        case 'A100': // 롯데아이몰
        case 'B959': // 오늘의집
        case 'B688': // 카카오톡스토어
        case 'A525': // LFmall
        case 'B700': // 카카오톡선물하기
        case 'A170': // 배민상회
        case 'A171': // 마켓컬리
        case 'A537': // 샵바이
        case 'A131': // Hmall
        case 'A093': // 알리익스프레스
        case 'P060': // 쇼피파이
          await commonSVC.showMessageHtml('안내', '해당 쇼핑몰은 판매자센터 접속 시 로그인 페이지까지 지원됩니다.<br />ID/PW는 직접 입력 후 로그인을 진행해 주세요.');
          window.open(mall.admin_url, '_blank');

          return;
      }

      // 메이크샵 SSO 로그인
      if (mall.shop_cd === 'P059') {
        $rootScope.openMakeshopSsoAdmin();

        return;
      }

      if (mall.shop_id && (mall.scm_login_script && !_.startsWith(mall.scm_login_script, '//'))) {
        detectExtension(settings.chrome_app_id, async (installed) => {
          if (installed == true) {

            /* 요청할 필드명, 쇼핑몰별로 따로 보내도 되고, 백엔드에서 알아서 줘도 무방 */
            const fields = ['sol.*'];

            /* 익스텐션에서 공개키 발급 */
            const publicKey = await new Promise(resolve => chrome.runtime.sendMessage(settings.chrome_app_id, {
              action: 'GET_PUBLIC_KEY'
            }, response => resolve(response)));

            /* 익스텐션에서 받은 공개키로 암호화한 데이터 수신 */
            const res = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/shop/encrypt-scm`, {
              pa_shop_cd: mall.pa_shop_cd,
              shop_cd: mall.shop_cd,
              shop_id: mall.shop_id,
              fields: fields,
              publicKey: publicKey
            });

            const encrypted = res.data.encrypted;

            /* 판매자센터 로그인 시도 */
            if (mall.pa_shop_cd === 'A077' && mall.pa_shop_cd !== mall.shop_cd) {
              await commonSVC.showMessageHtml('-- 안내 --', '<font size=\'3px\'>접속 후 화면 우상단의 \'스토어이동\'을 통해 원하는 스토어로 이동해주시기 바랍니다.</font>');
            }

            const result = await new Promise(resolve => chrome.runtime.sendMessage(settings.chrome_app_id, {
              action: 'SCM_SECURE_LOGIN',
              params: {
                encrypted: encrypted,
                publicKey: publicKey
              }
            }, response => resolve(response)));

            /* 실패 시 에러 분기 */
            switch (result) {
              /* 성공 시 종료 */
              case 'success':
                return;
              case 'no password':
                commonSVC.showToaster('error', '실패', '[설정 > 쇼핑몰 계정관리] 메뉴에서 비밀번호를 설정하여 주시기 바랍니다.');
                break;
              default:
                commonSVC.showToaster(
                  'error',
                  gettextCatalog.getString('실패'),
                  gettextCatalog.getString('알 수 없는 이유로 실패하였습니다. 관리자에게 문의해주세요.')
                );
                break;
            }
          } else {
            const chromeCheckBool = navigator.userAgent.toLowerCase().indexOf('chrome') === -1 && document.cookie.indexOf('ChromePanel=not') === -1;
            let subText, mainText, func;

            if (chromeCheckBool) {
              mainText = gettextCatalog.getString('크롬 전용기능입니다.', {});
              subText = gettextCatalog.getString('판매자센터 자동 접속은 크롬에서만 가능합니다.', {});
              func = $scope.open_google_chrome;
            } else {
              mainText = gettextCatalog.getString('크롬 확장 프로그램 설치가 필요합니다.', {}),
              subText = gettextCatalog.getString('이 기능을 이용하시려면 크롬 확장 프로그램 설치가 필요합니다. 지금 설치하시겠습니까? 설치 하신 후에 다시 시도하여 주십시오.', {});
              func = $scope.open_google_extention;
            }

            commonSVC.showConfirm(
              mainText,
              subText,
              func
            );
          }
        });

      } else {
        // 로그인 스크립트가 없는경우 안내메세지 출력 2018-04-27 rony
        commonSVC.showMessageHtml('-안내-', `[${mall.shop_name}] 사이트는 판매자센터 접속기능을 지원하지 않습니다.`);

        return;
      }
    };

    /**
     * 메이크샵 SSO 로그인
     */
    $rootScope.openMakeshopSsoAdmin = async () => {
      const res = await shopAccountModel.getMakeshopSsoAuthCode({ client_id: 'eaa6d811bc6fa7845750fdc3721ff49b' });
      const authCode = res.data?.authCode;

      if (!authCode) {
        commonSVC.showMessage('실패', '메이크샵 접속정보 설정에 실패하였습니다.');
      } else {
        window.open(`https://www.makeshop.co.kr/main/plto_login.html?code=${authCode}`, '_blank');
      }
    };

    /**
     * 구글 확장 설치 링크 추가
     * 2017-05-12 MatthewKim
     */
    $scope.open_google_extention = function() {
      window.open(`https://chrome.google.com/webstore/detail/${settings.chrome_app_id}`);
    };

    /**
     * 구글 크롬 설치 링크 추가
     * 2018-01-18 Daniel
     */
    $scope.open_google_chrome = function () {
      window.open('https://www.google.co.kr/chrome/browser/desktop/index.html');
    };

    /**
     * 크롬 확장 설치 여부 확인
     *
     * 2017-04-25 MatthewKim
     * @param extensionId
     * @param callback
     */
    function detectExtension(extensionId, callback) {
      try {
        chrome.runtime.sendMessage(extensionId, { action: 'PING' },
          function (reply) {
            if (reply == 'PONG') {
              callback(true);
            } else {
              callback(false);
            }
          });
      } catch (e) {
        callback(false);
      }

    }

    /**
     * 2018-01-04 Daniel
     * 작업알림
     */
    $scope.getWorkList = function($event) {
      // 클래스 추가전에 이벤트가 발생해서 open클래스가 있는게 닫힌거임
      if ($event.currentTarget.classList.contains('open')) {
        return;
      }

      const param = {
        start: 0,
        length: 10,
        orderby: 'wdate DESC'
      };

      commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/work/list`, param)
        .then(function (data) {
          const workList = data.data.results;
          const works = {};

          _.each(workList, function (work) {
            works[work.id] = setWork(work); // 작업세팅
          });

          $scope.workObject = works;
          $scope.workList = reverseWorkArr(works);
        });
    };

    function setWork(work) {
      const workData = {};

      if (commonSVC.getDate(work.started_at, 'yyyy-MM-dd') === commonSVC.getDate(Date.now(), 'yyyy-MM-dd')) {
        workData.started_at = commonSVC.getDate(work.started_at, 'HH:mm:ss');
      } else {
        workData.started_at = commonSVC.getDate(work.started_at, 'MM-dd HH:mm:ss');
      }

      workData.id = work.id;
      workData.site_name = work.site_name;
      workData.site_id = work.site_id;
      workData.job_name = work.job_name;
      workData.state = getWorkState(work.state);
      workData.stateType = getWorkState(work.state, 'class');
      workData.state_msg = work.state_msg;
      workData.progress = work.progress;
      workData.site_code = work.site_code;

      return workData;
    }

    function getWorkState(state, type) {
      switch (state) {
        case 'complete':
          type = type ? 'success' : '완료';
          break;

        case 'failed':
          type = type ? 'warning' : '오류';
          break;

        case 'active':
          type = type ? 'primary' : '진행';
          break;

        default:
          type = type ? 'grey' : '대기';
          break;
      }

      return type;
    }

    function reverseWorkArr(works) {
      const workList = [];

      _.eachRight(works, function (work) {
        workList.push(work);
      });

      return workList;
    }

    // 신규 도움말 개수
    cautionModel.getNewCautionCount()
      .then(function(res) {
        $scope.newCautionCount = res.data.results[0].count;
      });

    $rootScope.searchGuide = function (openValue, $event) {
      if (openValue && openValue.length >= 2 && (!$event || $event.keyCode === 13)) {
        $rootScope.openGuide(openValue);
      }
    };

    /**
     * 도움말
     */
    $rootScope.openGuide = function (openValue) {
      const templatePath = 'views/home/modals/guide.html';
      const resolve = {
        data: {
          count: $scope.newCautionCount,
          openValue: openValue,
          isHelpModal: true
        }
      };

      // 만일 이미 열려있다면
      if ($rootScope.helpModalOpen) {
        if (openValue) {
          $rootScope.$broadcast('questionSearch', resolve);
        }
        $rootScope.draggableModalZindex('help');
        $rootScope.$broadcast('helpFoldOpen');
      } else {
        commonSVC.openModal('', resolve, 'guideCtrl', templatePath);

        // 쇼핑몰별 주의사항 모달 열려있으면 z-index 다시 조정
        if ($rootScope.shopModalOpen) {
          $timeout(() => {
            $rootScope.draggableModalZindex('help');
          }, 100);
        }
      }
    };

    /**
     * 쇼핑몰별 주의사항
     */
    $rootScope.openShopCaution = function (shop_cd) {
      if ($rootScope.shopModalOpen) {
        $rootScope.draggableModalZindex('shop');
        $rootScope.$broadcast('shopFoldOpen', shop_cd);
      } else {
        commonSVC.openModal('', { data: { isHelpModal: true, shop_cd } }, 'shopCautionCtrl', 'views/home/modals/shopCaution.html');

        // 도움말 모달 열려있으면 z-index 다시 조정
        if ($rootScope.helpModalOpen) {
          $timeout(() => {
            $rootScope.draggableModalZindex('shop');
          }, 100);
        }
      }
    };

    /**
     * 제휴서비스 링크
     */
    $rootScope.openPltoAffiPage = function () {
      window.open('https://www.plto.com/affiliate/Affiliate/');
    };

    // 알림 오픈체크
    // $scope.msgOpen = false;
    /**
     * 알람초기화 2017-11-14 Daniel
     */
    $rootScope.pusher.get_messages_where.push();

    $rootScope.pusher.get_messages_limit = [0, 5];

    /**
     * 알림 읽기처리
     */
    $scope.msgRead = function() {
      $scope.show_messages_contents = false;

      // 알림창 열렸는지 체크
      if (MessageSVC.isTimeLineState()) {
        return false;
      }

      MessageSVC.getMessageList()
        .then(function(msg) {
          const msgNo = [];

          $rootScope.pusher.messages = msg;

          let msgItem;
          for (const i in msg) {
            msgItem = msg[i];
            // 알림 읽음 처리
            if (msgItem.msg_no) {
              msgNo.push(msgItem.msg_no);
            }
            // 공지사항 읽음 처리
            if (msgItem.noti_no) {
              localStorage.setItem(`notice_read${msgItem.noti_no}`, 'y');
            }
          }

          if (msgNo.length) {
            MessageSVC.readMessages(msgNo)
              .then(function() {
                return MessageSVC.refreshMessages();
              })
              .finally(function() {
                $scope.show_messages_contents = true;
              });
          } else {
            MessageSVC.refreshMessages()
              .finally(function() {
                $scope.show_messages_contents = true;
              });
          }
        });
    };

    /**
     * 신규 메모 읽기처리
     */
    $scope.newMemoRead = function() {
      $scope.show_new_memos_contents = false;

      // 2018-08-09 chris 알림내용 오픈여부
      const expanded = $('#new_memos_dropdown').attr('aria-expanded');

      // 열렸는지 체크
      if (expanded == 'true') {
        return false;
      }

      MessageSVC.getNewMemoList()
        .then(function(memos) {

          $rootScope.pusher.new_memos = memos;
          if (memos.length) {
            MessageSVC.readAllNewMemos()
              .then(function() {
                return MessageSVC.refreshNewMemos();
              })
              .finally(function() {
                $scope.show_new_memos_contents = true;
              });
          } else {
            MessageSVC.refreshNewMemos()
              .finally(function() {
                $scope.show_new_memos_contents = true;
              });
          }
        });
    };

    const domain = window.location.host;

    if (domain.search('playauto') >= 0) {
      $('head').append('<link rel="shortcut icon" href="/assets/images/playauto/favicon.ico">');
      $('head').append('<link rel="icon" href="/assets/images/playauto/favicon.png">');
    }
    // else {
    //   $("head").append('<link rel="shortcut icon" href="/assets/images/playauto/favicon.ico">');
    //   $("head").append('<link rel="icon" href="/assets/images/playauto/favicon.png">');
    // }

    /**
     * 쇼핑몰 상품번호 클릭시 상품페이지 이동
     */
    $rootScope.shopDetailView = function(shop_cd, shop_id, sale_no, sub_sol_no) {
      const params = { shop_cd, shop_id: encodeURI(shop_id), sub_sol_no };

      onlineProductModel.getShopUrlInfo(params, function(state, data) {
        const row = data.result[0];
        let url = row.detail_url;

        // 복수계정때문에 pa_shop_cd로 변경 2018-07-03 Amelia
        shop_cd = row.pa_shop_cd;

        // 상품보기 URL 이 없는경우 알림 팝업 띄움 2018-05-25 rony
        if (url == '') {
          commonSVC.showMessage('상품보기를 지원하지 않는 쇼핑몰 입니다.');

          return false;
        }

        switch (shop_cd) {
          case 'A077':
            if (row.etc2) {
              url = `${url + row.etc2}/products/${sale_no}`;
            } else {
              url = `${row.etc5}/products/${sale_no}`;
            }

            break;

          case 'B118':
            url = row.etc1 + url + sale_no;
            break;

          case 'B917':
            url = `${row.etc2}/shop/goods/goods_view.php?goodsno=${sale_no}`;
            break;

          case 'B691':
            url = `${row.etc2.replace('gdadmin.', '')}/goods/goods_view.php?goodsNo=${sale_no}`;
            break;

          case 'B579':
            url = url + sale_no.replace('G00', '');
            break;

          case 'B614':
            if (sale_no != '') {
              sale_no = sale_no.split(' ')[0];
            }
            url = url.replace('[-ACODE-]', shop_id) + sale_no;
            break;

          case 'B616':
            if (row.etc3) {
              url = url.replace('[-ACODE-].cafe24.com', row.etc3.trim()) + sale_no;
            } else {
              url = url.replace('[-ACODE-]', shop_id) + sale_no;
            }
            break;

          case 'A131':
            sale_no = sale_no.split(' ')[0];
            url = sale_no.length === 8 ? `${url}20${sale_no}` : (sale_no.length === 9 ? `${url}2${sale_no}` : `${url}${sale_no}`);
            break;

          case 'B693':
            url = url + sale_no.substr(2);
            break;

          case 'B005':
            url = `https://${row.etc1}/shop_view/?idx=${sale_no}`;
            break;

          case 'B688':
            url = `${url + row.etc3}/products/${sale_no}`;
            break;

          case 'P044':
            url = `https://eqlstore.com/product/${sale_no}/detail?isBoPreview=Y`;
            break;

          case 'A536':
            if (!row.etc2) {
              commonSVC.showMessage('로그인 확인에서 몰 도메인을 입력해주세요.');

              return false;
            }
            url = `${row.etc1}/product/${sale_no}?preview=true`;
            break;

          case 'A537':
            url = `${row.etc1}/product-detail?productNo=${sale_no}`;
            break;

          case 'A092':
          case 'A093':
            url = `${url}${sale_no}.html`;
            break;

          case 'P058': // 셀러허브 스토어
            if (!row.etc2) {
              commonSVC.showMessage('[설정 > 쇼핑몰 계정관리] 에서 스토어 아이디를 입력해주세요.');

              return false;
            }
            url = url.replace('[-ACODE-]', row.etc2) + sale_no;
            break;

          // 도매창고
          case 'B668':
            url = url + sale_no.replace('OH', '');
            break;

          default: {
            // url에 계정 데이터가 필요한 경우 치환해서 넣어줌
            // url에 계정 데이터가 필요한 경우 치환해서 넣어줌
            const replaceVar = url.match(/{{\w+}}/g);

            replaceVar?.forEach(val => {
              url = url.replace(val, row[val.replace(/[{}]/g, '')]);
            });

            url = url + sale_no;
            break;
          }
        }

        window.open(url, '_blank', 'noopener,noreferrer');
      });
    };

    /**
     * 운송장번호 클릭시 배송조회페이지 이동
     */
    $rootScope.shipmentTrackingView = async function(carr_no, invoice_no) {
      const params = {
        carr_no: carr_no,
        invoice_no: invoice_no.replace(/-/gi, '')
      };

      // 한진택배 운송장 조회 수정
      if (carr_no == '5') {
        detectExtension(settings.chrome_app_id, async (installed) => {
          if (installed == true) {
            await new Promise(resolve => chrome.runtime.sendMessage(settings.chrome_app_id, {
              action: 'SCM_LOGIN',
              params: {
                admin_url: 'https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillSch.do?mCode=MN038',
                invoice_no,
                pa_shop_cd: 'hanjin'
              }
            }, response => resolve(response)));
          } else {
            const chromeCheckBool = navigator.userAgent.toLowerCase().indexOf('chrome') === -1 && document.cookie.indexOf('ChromePanel=not') === -1;
            let subText, mainText, func;

            if (chromeCheckBool) {
              mainText = gettextCatalog.getString('크롬 전용기능입니다.', {});
              subText = gettextCatalog.getString('한진택배 운송장 조회는 크롬에서만 가능합니다.', {});
              func = $scope.open_google_chrome;
            } else {
              mainText = gettextCatalog.getString('크롬 확장 프로그램 설치가 필요합니다.', {}),
              subText = gettextCatalog.getString('한진택배 운송장 조회를 이용하시려면 크롬 확장 프로그램 설치가 필요합니다. 지금 설치하시겠습니까? 설치 하신 후에 다시 시도하여 주십시오.', {});
              func = $scope.open_google_extention;
            }

            commonSVC.showConfirm(
              mainText,
              subText,
              func
            );
          }
        });

        return;
      }

      // 경동택배 운송장 조회 수정
      if (carr_no == '22') {
        detectExtension(settings.chrome_app_id, async(installed) => {
          if (installed) {
            await new Promise(resolve => chrome.runtime.sendMessage(settings.chrome_app_id, {
              action: 'SCM_LOGIN',
              params: {
                admin_url: 'https://kdexp.com/index.do',
                invoice_no,
                pa_shop_cd: 'kyungdong'
              }
            }, response => resolve(response)));
          } else {
            const chromeCheckBool = navigator.userAgent.toLowerCase().indexOf('chrome') === -1 && document.cookie.indexOf('ChromePanel=not') === -1;
            let subText, mainText, func;

            if (chromeCheckBool) {
              mainText = gettextCatalog.getString('크롬 전용기능입니다.', {});
              subText = gettextCatalog.getString('경동택배 운송장 조회는 크롬에서만 가능합니다.', {});
              func = $scope.open_google_chrome;
            } else {
              mainText = gettextCatalog.getString('크롬 확장 프로그램 설치가 필요합니다.', {}),
              subText = gettextCatalog.getString('경동택배 운송장 조회를 이용하시려면 크롬 확장 프로그램 설치가 필요합니다. 지금 설치하시겠습니까? 설치 하신 후에 다시 시도하여 주십시오.', {});
              func = $scope.open_google_extention;
            }
            commonSVC.showConfirm(
              mainText,
              subText,
              func
            );
          }
        });

        return;
      }

      shipmentModel.shipmentTrackingView(params, function(state, data) {
        let url = data[0].tracking_url;

        // 배송조회 URL이 없는경우 알림 팝업 띄움
        if (url == '') {
          commonSVC.showMessage('배송조회를 지원하지 않는 택배사입니다.');

          return false;
        }
        if (params.carr_no !== '107' && params.carr_no !== '1007') {
          url = url + params.invoice_no;
        }

        window.open(url);

      });
    };

    /**
     * 홈페이지 도움말 열기
     */
    $rootScope.openHomePageHelp = function(guide_no) {
      const sendInfo = localStorageService.get('sendInfo');
      const firstLogin = localStorageService.get('firstLogin');
      const info = atob(decodeURIComponent(sendInfo));

      // let url = `https://staging-www.plto.com/member/AdminLogin?sendInfo=${test}&firstLogin=${firstLogin}`;
      let url = $location.$$host === 'localhost' ? `http://localhost:8080/member/AdminLogin?info=${encodeURIComponent(info)}&firstLogin=${firstLogin}` : `https://www.plto.com/member/AdminLogin/?info=${encodeURIComponent(info)}&firstLogin=${firstLogin}`;

      if (guide_no) {
        url += (`&ret_url=customer/HelpDesc?sol=gmp&guide_no=${guide_no}`);
      } else {
        url += '&ret_url=customer/Help';
      }

      window.open(url);
    };

    /**
     * 상단 알림창 내 연결기능
     */
    $rootScope.notificationDetail = function(message) {
      // 재고부족: {"message":"SKU S2356476B60B 재고부족"}
      // 일정알림: {"message":"12시 일정알림"}
      // 1:1문의: {"message":"문의번호 13578 답변완료"}

      switch (message.msg_title) {
        case '품절 상품 알림':
        case '재고 부족 상품 알림':
          $rootScope.noti_search.edate = moment().format('YYYY-MM-DD');
          $rootScope.noti_search.page = 'inventory';

          $rootScope.noti_search.key = 'sku_cd';
          $rootScope.noti_search.key_name = 'SKU코드';
          $rootScope.noti_search.value = message.msg_content.message.split(' ')[1];
          navigator.clipboard.writeText($rootScope.noti_search.value);
          $rootScope.noti_search.search = true;
          $state.go('main.stock_inventory_list');
          $rootScope.$broadcast('alarmSearch_inventory');
          break;
        case '1:1문의 답변완료':
        case '1:1문의 답변지연':
          $rootScope.question_no = message.msg_content.message.split(' ')[1];
          $state.go('main.question_list');
          $rootScope.$broadcast('alarmSearch_question');
          break;
        case '일정 알림':
          $state.go('main.calendar');
          break;
        case 'EXCEL 작업완료 알림':
          $state.go('main.work', { tabType: 'work_excelTab' });
          break;
        case '쇼핑몰 입점 알림': {
          window.open(`${settings.wwwUrl}/entry/EntryResult/`, '_blank');
          break;
        }
      }
    };

    // 신규 메모 클릭 연결기능
    $rootScope.newMemoDetail = function(memo_no) {
      $rootScope.memo_noti_search.sdate = '2001-01-01';
      $rootScope.memo_noti_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.memo_noti_search.date_type = 'wdate';
      $rootScope.memo_noti_search.page = 'memo_list';

      $rootScope.memo_noti_search.key = 'memo_no';
      $rootScope.memo_noti_search.key_name = 'SKU코드';
      $rootScope.memo_noti_search.value = memo_no;
      $rootScope.memo_noti_search.search = true;
      $state.go('main.memo_list');
      $rootScope.$broadcast('memoList_new_memo_noti');
    };

    $scope.notices = {};

    // 상단 노출 공지사항 데이터 로드 함수
    const noticeInit = async () => {
      // 상단 노출 공지사항 불러옴
      try {
        const re = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/top`);
        $scope.notices.top = re.data?.results;

        if (re.data?.results.length > 1) {
          $interval(function() {
            $('#notices-top li:first').slideUp(function() {
              $(this).appendTo($('#notices-top')).slideDown();
            });
          }, 5000); // 5초마다 롤링, 데이터 2개 이상일 때만 적용.
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data?.message);
      }
    };

    noticeInit();

    /**
     * 읽지 않은 신규주문 카운트 조회
     */
    const newOrdInit = () => {
      commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/order/new-order-count/${userInfo.user.sol_no}`, {}, function (state, data) {
        if (state === 'success') {
          $rootScope.pusher.unread_globalOrd_cnt = $rootScope.pusher.unread_globalOrd_cnt + data.results.unread_globalOrd_cnt;
          $rootScope.pusher.unread_localOrd_cnt = $rootScope.pusher.unread_localOrd_cnt + data.results.unread_localOrd_cnt;
        }
      });
    };

    newOrdInit();

    // 개별 공지사항으로 이동 함수
    $scope.goNoticeDetail = function(noti_type, noti_no) {
      $rootScope.noti_no = noti_no;
      $rootScope.noticeType = noti_type;
      $rootScope.$emit('$closeTab', 'tab_notice_detail');
      $timeout(function() {
        $state.go('main.notice_detail', { noticeType: noti_type, noti_no: noti_no });
      }, 100);
    };

    /**
     * 개인정보 보호 정책 안내 열기.
     */
    $scope.openNoticeSecurity = () => {
      $('.modal-security-dim').removeClass('hide');
      $('#modal-notice-security').removeClass('hide');
    };

    // 개인정보 보호 정책 안내 닫기
    $rootScope.noticeSecurityClose = function() {
      $('.modal-security-dim').addClass('hide');
      $('#modal-notice-security').addClass('hide');
    };

    /**
     * 실시간 공지사항 팝업 실행
     */
    $rootScope.showNoticeNow = async function (data) {
      const noticeData = {
        data: { notices: data },
        backdropClass: 'notice-modal-backdrop',
        windowClass: 'notice-modal-window'
      };

      await commonSVC.openModal('notice', noticeData, 'noticeCtrl', 'views/home/modals/notice.html', false, false, false).result;
    };

    /**
     * PLTO2.0 간편 이용 가이드 열기.
     */
    $scope.openSimpleGuide = () => {
      commonSVC.openModal('lg', { data: {} }, 'simpleGuideCtrl', 'views/home/modals/simpleGuide.html', false, false, false);
    };

    /**
     * 쇼핑몰계정 등록 유도 모달 오픈
     */
    $rootScope.showFirstShopAddModal = async() => {
      try {
        // 최초로그인 완료처리
        $rootScope.first_login && $rootScope.completeFirstLogin();

        await commonSVC.openModal('lg', {}, 'FirstShopRegistCtrl', 'views/home/modals/firstShopRegist.html', false, true, false).result;
      } catch (err) {
        // 쇼핑몰계정 등록으로 이동없이 창 닫을시 공지오픈
        if (err.includes('escape') || err == 'cancel') {
          $rootScope.showNoticeModals();
        }
      }
    };

    /**
     * 입점 진행상태 안내 알림
     */
    $rootScope.notifyShopEntryStatus = async () => {
      const SHOP_ENTRY_STATUS = `shop_entry_status_${userInfo.user.m_no}`;

      try {
        const res = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/shop/entry-statuses`);

        if (res.data.length) {
          const readEntries = localStorageService.get(SHOP_ENTRY_STATUS) || [],
                unreadEntries = res.data
                  .filter(entry => !readEntries.includes(entry.entry_no))
                  .sort((a, b) => {
                    if (a.entry_status === '입점완료' && b.entry_status !== '입점완료') {
                      return -1;
                    }
                    if (a.entry_status !== '입점완료' && b.entry_status === '입점완료') {
                      return 1;
                    }

                    // '입점완료' 상태가 같을 경우 'status_mdate'로 정렬
                    return new Date(b.status_mdate) - new Date(a.status_mdate);
                  });

          if (unreadEntries.length) {
            for (const entry of unreadEntries) {

              const title = entry.entry_status === '입점완료' ? '입점승인안내' : '입점결과안내';
              const content = `<div>
                      ${
  entry.entry_status === '입점완료'
    ? `
        <div class="text-bold">${entry.shop_name} 입점이 승인되었습니다</div>
        <div class="text-bold">이제 더 많은 쇼핑몰에서 상품판매를 시작해보세요</div>
      `
    : `
        <div class="text-bold">${entry.shop_name} 입점이 추가상담진행 또는 반려처리되었습니다</div>
        <div class="mt-5 text-size-large text-grey-400" style="text-decoration: underline">'홈페이지>나의정보>입점결과확인'에서 확인할 수 있습니다</div>
      `
}
</div>`;

              const opt = {
                allowEscapeKey: false,
                showCancelButton: true,
                cancelButtonText: '닫기',
                confirmButtonText: entry.entry_status === '입점완료' ? '상품등록하러가기' : '입점결과 확인하러 가기',
                footerText: entry.entry_status !== '입점완료' || $rootScope.use_channel_list.some(({ pa_shop_cd }) => pa_shop_cd === entry.shop_cd) ? ''
                  : '<span class="text-size-small text-grey-300">현재 사용가능한 쇼핑몰 ID 수가 초과되어<br> 버전 업그레이드 또는 쇼핑몰 계정 삭제가 필요합니다.<span>'
              };

              const re = await commonSVC.showConfirmHtml(title, content, null, opt);

              if (re) {
                localStorageService.set(SHOP_ENTRY_STATUS, [...readEntries, entry.entry_no]);

                // 입점결과확인 페이지 이동
                if (entry.entry_status !== '입점완료') {
                  const sendInfo = localStorageService.get('sendInfo');
                  const info = atob(decodeURIComponent(sendInfo));

                  const url = `${settings.wwwUrl}/member/AdminLogin/?info=${encodeURIComponent(info)}&ret_url=entry/EntryResult`;

                  window.open(url);
                } else {
                  $state.go('main.online_product_list');
                }
              }
            }
          }
        }

      } catch (err) {
        commonSVC.showToaster('error', '실패');
      }
    };

    /**
     * 상품 퀵등록 모달 오픈
     */
    $rootScope.quickOnlineProdAdd = () => {

      if (localStorage.getItem(`isOnlineListLoading_${userInfo.user.sol_no}`) === 'true') {
        commonSVC.showMessageHtml('', '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');

        return false;
      }

      const SUPPORT_MALLS = ['A001', 'A006', 'A027', 'A077', 'A112', 'B378', 'B688', 'B719']; // 지원 쇼핑몰

      // 총괄관리자가 아니고 상품관리 권한이 없거나 사용권한이 있지만 상품전송 권한이 없는 경우 에러
      if (userInfo.user.auth_type !== '총괄관리자' &&
        (!userInfo.permission.online || !userInfo.permission.online.use_yn || !userInfo.permission.online.roles.registProd)
      ) {
        commonSVC.PermissionDenyAlert('');

        return false;
      }

      // 지원 쇼핑몰 중 권한이 없는 경우
      if (!$rootScope.use_channel_list.some(shop => SUPPORT_MALLS.includes(shop.pa_shop_cd))) {
        commonSVC.showMessage('상품 자동설정이 지원되는 쇼핑몰이 없습니다.', '※ 지원 쇼핑몰: 옥션, 지마켓, 11번가, 인터파크, 스마트스토어, 쿠팡, 카카오톡 스토어, 위메프2.0');

        return false;
      }

      // 현재 온라인상품 상세 페이지인 경우 선택된 상품이 있는지 확인
      if ($state.current.name === 'main.online_product_list') {
        const listener = $scope.$on('selectedOlProdResult', (event, data) => {
          if (data.some(({ sale_status }) => sale_status !== '판매중')) {
            commonSVC.showMessage('[판매중] 상태의 상품만 퀵등록이 가능합니다.');
            // 이벤트 리스너 제거
            listener();

            return false;
          }

          if (data.some(({ pa_shop_cd }) => !SUPPORT_MALLS.includes(pa_shop_cd))) {
            commonSVC.showMessage('지원되지 않는 쇼핑몰의 상품이 포함되어 있습니다.', '※ 지원 쇼핑몰: 옥션, 지마켓, 스마트스토어, 쿠팡, 11번가, 카카오톡스토어, 위메프2.0, 인터파크');
            // 이벤트 리스너 제거
            listener();

            return false;
          }

          commonSVC.openModal('xg2', { data: { prods: data }, systemList: systemModel => systemModel.load() }, 'QuickOnlineProdAddCtrl', 'views/home/modals/quickOnlineProdAdd.html', false, true, false);

          // 이벤트 리스너 제거
          listener();
        });
        $scope.$broadcast('sendSelectedOlProd');
      } else {
        commonSVC.openModal('xg2', { data: null, systemList: systemModel => systemModel.load() }, 'QuickOnlineProdAddCtrl', 'views/home/modals/quickOnlineProdAdd.html', false, true, false);
      }
    };

    /**
     * 만료일 초과 등으로 인한 솔루션 사용 금지 처리.
     */
    function disableSolution(location, showMsg = true) {
      $timeout(function() {
        $state.go(location);
      }, 0);

      if (showMsg) {
        switch (location) {
          case 'main.configs_user':
          case 'main.settings_shop_account':
            $rootScope.showVersionAdvisor(location);
            break;
          case 'main.home':
            break;
          default:
            if ($scope.userPermission?.pay?.use_yn !== false) {
              $rootScope.openPaySolExpired();
            } else {
              commonSVC.showMessage('솔루션 사용일이 만료한 계정입니다.',
                '솔루션을 정상적으로 이용하시기 위해서는\n마스터 계정 또는 결제권한이 있는 사용자 계정으로 결제해주시기 바랍니다.',
                function () {
                  $rootScope.logout();
                });
            }
            break;
        }
      }

      // 결제, 회사정보관리, 1:1 문의 제외 이동금지
      $scope.$on('$stateChangeStart', function(event, toState) {
        const allowPage = ['main.pay_list', 'main.configs_admin', 'main.question_list', 'main.settings_shop_account', 'main.settings_shop_account.channelSub', 'main.configs_user'];

        // 웨이크업 통해 로그인한 경우 만료돼도 대시보드는 열어줌
        if ($rootScope.isWakeupLogin) {
          allowPage.push('main.home');
        }

        if (!allowPage.includes(location)) {
          allowPage.push(location);
        }

        if (!allowPage.includes(toState.name)) {
          event.preventDefault();
        }
      });
    }

    /**
     * 결제모달 오픈
     */
    $rootScope.openPayReq = (paymentSetupInfo) => {
      $state.go('main.pay_list');
      $timeout(() => {
        $rootScope.$broadcast('payReq', paymentSetupInfo);
      }, 800);
    };

    /**
     * 쿠폰관리 모달 오픈
     */
    $scope.couponManagement = () => {
      $state.go('main.pay_list');
      $timeout(() => {
        commonSVC.openModal('xg', {}, 'couponManagementCtrl', 'views/pay/modals/couponManagement.html');
      }, 500);
    };

    /**
     * 로컬스토리지에 유효기간 설정하여 저장.
     * @param {string} key
     * @param {string} value
     * @param {boolean} notViewToday 오늘 더이상 보지않기
     * @param {number} expire 유효기간(일)
     */
    $rootScope.setLocalStorageWithExpire = (key, value, notViewToday = false, expire = 0) => {
      const itemString = localStorage.getItem(key);

      if (itemString) {
        const item = { ...JSON.parse(itemString), value };

        if (expire) {
          item.expire = parseInt(moment().add(expire - 1, 'days').format('YYYYMMDD'));
        }

        if (notViewToday) {
          item.notViewDate = parseInt(moment().format('YYYYMMDD'));
        }

        localStorage.setItem(key, JSON.stringify(item));
      }
    };

    /**
     * 유효기간 확인해서 로컬스토리지에 저장된 값 불러오기
     * @param {string} key
     * @returns localStorage에 저장된 값
     */
    $rootScope.getLocalStorageWithExpire = (key) => {
      const itemString = localStorage.getItem(key);

      if (itemString) {
        const item = JSON.parse(itemString);
        const now = parseInt(moment().format('YYYYMMDD'));

        if (item.expire >= now) {

          if (!item.notViewDate || now > item.notViewDate) {
            return item.value || 'true';
          }
        } else {
          localStorage.removeItem(key);
        }
      } else {
        const diffFirstLoginDate = moment().diff(moment(userInfo.user.first_visit_time), 'days');

        if (diffFirstLoginDate < 8) {
          localStorage.setItem('forceOpenHelpModal', JSON.stringify({
            value: 'Y',
            expire: parseInt(moment().add(7 - diffFirstLoginDate, 'days').format('YYYYMMDD'))
          }));

          return 'Y';
        }
      }

      return '';
    };

    /**
     * 이벤트페이지 탭 오픈
     */
    $rootScope.openTabEventPage = (event_name) => {
      const eventPage = {
        discountEvent: '할인 받고 사용하기',
        quickPlayEvent: '퀵플레이 체험하기'
      };

      $rootScope.$emit('$closeTab', 'main.event_page');
      $timeout(function() {
        $state.get('main.event_page').data.pageTitle = eventPage[event_name];
        $state.go('main.event_page', { event_name });
      }, 100);
    };

    // 개별요금 조회
    $rootScope.indvPayInfo = indvPayInfo?.data?.result || {};

    // 만료일이 유효한 유료사용자 및 사용자계정은 무료버전이용하기 버튼 비활성화
    $rootScope.disableToZero = (!userInfo.user.sol_expired && userInfo.user.charged_yn) || userInfo.user.user_grade !== '마스터';

    // 무료버전이거나 대리점/부품사 제휴처, 트렌드헌터 제휴처, 개별설정요금 사용자는 무료버전이용하기 버튼 숨기기
    $rootScope.hideToZero = userInfo.user.sol_version == 'ZERO' || !!$rootScope.affName || userInfo.user.aff_no === 1134 ||
      $rootScope.indvPayInfo.indv_pay_amt || $rootScope.indvPayInfo.ol_charge_cnt_limit || $rootScope.indvPayInfo.ord_charge_cnt_limit;

    /**
     * ZERO 버전 사용하기
     */
    $rootScope.goToZeroVersion = async(loadingFn) => {
      const opt = {
        title: '<h2 class="text-left text-danger text-bold pl-15">무료(ZERO) 버전으로 다운그레이드 시 모든 데이터가 삭제됩니다.</h2>',
        text: `
        <div class="sweet-alert-body pl-15 no-padding-top" style="font-size: 14px !important">
          무료(ZERO) 버전으로 다운그레이드 시 솔루션의 모든 설정이 초기화됩니다. <br>
          쇼핑몰계정, 주문, 상품 등 모든 데이터가 삭제되며, 삭제된 데이터는 다시 되돌릴 수 없습니다.
        </div>`,
        width: 750,
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: '(필수)모든 데이터 삭제 및 초기화에 동의합니다.',
        confirmButtonText: '초기화 후 무료버전 사용',
        cancelButtonText: '취소',
        icon: 'warning',
        willOpen: function () {
          const cfBt = Swal2.getConfirmButton();
          cfBt.disabled = true;
          Swal2.getInput().addEventListener('change', () => {
            cfBt.disabled = !Swal2.getInput().checked;
          });
        },
      };

      const { isConfirmed } = await commonSVC.showConfirmCustomSwal2(opt);

      if (!isConfirmed) {
        return false;
      }

      try {
        if (loadingFn) {
          loadingFn(true);
        }

        await payModel.useZeroVersion();

        commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
          localStorageService.set('token', `${'Token' + ' '}${result}`);
        });

        await commonSVC.showMessageHtml('<div style="line-height: 30px;">솔루션 초기화 작업이 완료되었습니다.<br>지금부터 무료(ZERO) 버전으로 사용 가능합니다.</div>');
        location.reload();
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data.message);
      } finally {
        if (loadingFn) {
          loadingFn(false);
        }
      }
    };
  });
