'use strict';

angular.module('gmpApp')
  .controller('StockWarehouseEditCtrl', function ($uibModalInstance, $scope, userInfo, $timeout, commonModel, warehouseSVC, shipmentModel, warehouseModel, settings, shipmentSVC, commonSVC, logHistorySVC, data, gettextCatalog, errorSVC) {
    //------------------------
    // 변수 설정
    //------------------------
    // 지급주기 치환위한 변수
    const depot_sett_period = {
      '0': '7일',
      '1': '15일',
      '2': '1개월',
      '7일': 0,
      '15일': 1,
      '1개월': 2
    };
    $scope.charge_name_list = [];
    $scope.charge_name_self = '';
    let origData = null;

    $scope.nation = userInfo.user.sol_country;
    $scope.name = '창고';

    $scope.location_list = warehouseSVC.location_list;  // 창고위치 리스트 (해외)
    $scope.state_list = $scope.nation === 'JP' ? shipmentSVC.jpCityList : []; //도시리스트
    $scope.warehouse = {};
    $scope.tab_active = 0;
    $scope.number = data.number;
    $scope.ebayEtcInfo = {};

    //------------------------
    // 이벤트 맵핑
    //------------------------

    //담당자 셀렉트
    /*
    $timeout(function () {
      $('.select2-search__field').css("width","150px");
      // Fixed width. Multiple selects
      $('.select-fixed-multiple').select2({
        multiple:true,
        minimumResultsForSearch: Infinity,
        width: "100%",
        placeholder: "(계정)선택",
        "language": {
          "noResults": function(){
            return "담당자 없음";
          }
        },
      });
      $('.reg-channel-select').change(function () {
        var va = $(this).val();
        $scope.warehouse.charge_no = va;

        $scope.$apply();
      });
    }, 100, true );
    */

    /**
     * 2018-06-26 Daniel
     * ESM Master ID 계정확인
     */
    $scope.esmLoginCheck = false;
    $scope.result = {};

    $scope.esmMasterLoginCheck = function () {
      $scope.esmLoginCheck = true;

      const id = $scope.warehouse.ebaydepot_id;
      const pw = $scope.warehouse.editPassword ? $scope.warehouse.ebaydepot_pwd_new : '';

      if (!id) {
        $('#id').effect('shake');

        $scope.esmLoginCheck = false;
        $scope.result = {
          state: 'danger',
          msg: 'ID 를 입력해주십시오.',
          icon: 'times-circle'
        };

        return false;
      }

      if ($scope.warehouse.editPassword && !pw) {
        $('#pw').effect('shake');

        $scope.esmLoginCheck = false;
        $scope.result = {
          state: 'danger',
          msg: 'PW 를 입력해주십시오.',
          icon: 'times-circle'
        };

        return false;
      }

      warehouseModel.checkEsmMasterId(id, pw, $scope.warehouse.depot_no, 'edit')
        .then(function (res) {
          if (res.status !== 200) {
            $scope.result = {
              state: 'danger',
              msg: res.message,
              icon: 'times-circle'
            };
          } else {
            if (res.data.engine_result.has_error) {
              commonSVC.showMessage('확인 실패', res.data.engine_result.results[0].site_error);

              $scope.result = {
                state: 'danger',
                msg: '아이디와 비밀번호를 확인해 주십시오.',
                icon: 'times-circle'
              };
            } else {
              $scope.result = {
                state: 'success',
                msg: '사용 가능한 계정입니다',
                icon: 'check-circle'
              };
              $scope.ebayEtcInfo = res.data.engine_result;

            }
          }
        })
        .catch(function (err) {
          if (err) {
            commonSVC.showMessage('확인 실패', err.data.error);

            $scope.result = {
              state: 'danger',
              msg: '아이디와 비밀번호를 확인해 주십시오.',
              icon: 'times-circle'
            };
          }
        })
        .finally(function () {
          $scope.esmLoginCheck = false;
        });
    };

    init();

    /**
     * 초기 셋팅
     */
    function init() {
      commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/stock/depot/user/list`, {}, function (state, data) {
        $scope.charge_name_list = data.results;
      });
      warehouseModel.load(data.number, (state, result) => {
        if (state === 'success') {
          $scope.warehouse = {
            ...result,
            depot_sett_period: depot_sett_period[result.depot_sett_period],
            editPassword: false,
            placeHolder: 'PW정보는 수정버튼을 눌러서 변경합니다.',
            buttonText: '수정'
          };

          if ($scope.warehouse.charge_name === null) {
            $scope.warehouse.charge_name = '';
          }

          if ($scope.warehouse.charge_name_self) {
            $scope.warehouse.charge_name = '-1';
            $scope.charge_name_self = $scope.warehouse.charge_name_self;
          }

          origData = angular.copy({ ...$scope.warehouse, depot_sett_period: result.depot_sett_period });
        } else {
          commonSVC.showMessage('데이터 로드 실패');
          $scope.cancel();
        }
      });
    }

    /**
     * 저장 버튼 클릭 시 (수정)
     */
    $scope.onSubmit = function () {
      $scope.warehouse.depot_sett_period = depot_sett_period[$scope.warehouse.depot_sett_period];
      if ($scope.form1.$valid) {

        // 스마일 배송여부 확인
        if ($scope.warehouse.ebaydepot_yn && $scope.result.state !== 'success' && (!$scope.warehouse.ebaydepot_id || (origData.ebaydepot_id !== $scope.warehouse.ebaydepot_id || ($scope.warehouse.editPassword && $scope.warehouse.ebaydepot_pwd_new)))) {
          commonSVC.showMessage('등록 실패', 'ESM Master ID 연동을 진행해주세요');

          return false;
        }

        if (!$scope.ebayEtcInfo && $scope.warehouse.ebaydepot_yn) {
          commonSVC.showMessage('등록 실패', 'ESM Master ID 계정정보 확인을 진행해주세요');

          return false;
        }

        const logField = {
          depot_name: `배송처명: ${origData.depot_name} -> ${$scope.warehouse.depot_name}`,
          zipcd: `우편번호: ${origData.zipcd} -> ${$scope.warehouse.zipcd}`,
          addr1: `주소: ${origData.addr1} -> ${$scope.warehouse.addr1}`,
          addr2: `상세주소: ${origData.addr2} -> ${$scope.warehouse.addr2}`,
          ebaydepot_yn: `G마켓 물류서비스 여부: ${$scope.warehouse.ebaydepot_yn ? 'G마켓 물류서비스' : '자체배송'}`,
          ctry_cd: `국가: ${warehouseSVC.ctry_name[origData.ctry_cd]} -> ${warehouseSVC.ctry_name[$scope.warehouse.ctry_cd]}`,
          charge_name: `담당자 변경: ${origData.charge_name === '-1' ? origData.charge_name_self : ($scope.charge_name_list.filter(o => o.m_no === origData.charge_name).length) ? $scope.charge_name_list.filter(o => o.m_no === origData.charge_name)[0].name : '없음'} -> ${$scope.warehouse.charge_name === '-1' ? $scope.charge_name_self : ($scope.charge_name_list.filter(o => o.m_no === $scope.warehouse.charge_name).length) ? $scope.charge_name_list.filter(o => o.m_no === $scope.warehouse.charge_name)[0].name : '없음'}`,
          charge_email: `담당자 이메일: ${origData.charge_email} -> ${$scope.warehouse.charge_email}`,
          charge_tel: `담당자 직통번호: ${origData.charge_tel} -> ${$scope.warehouse.charge_tel}`,
          depot_sett_period: `지급 주기: ${origData.depot_sett_period || '미설정'} -> ${$scope.warehouse.depot_sett_period}`
        };

        $scope.warehouse.charge_flag = String($scope.warehouse.charge_name);
        $scope.warehouse.charge_name = String($scope.warehouse.charge_name === '-1' ? $scope.charge_name_self : $scope.warehouse.charge_name);
        origData.charge_name = origData.charge_name === '-1' ? origData.charge_name_self : origData.charge_name;

        $scope.warehouse.log_contents = logHistorySVC.compare(logField, origData, $scope.warehouse).split(',');

        // 만일 pw칸이 열려있고 값이 있다면 ebaydepot_pwd로 백엔드에 전송
        if ($scope.warehouse.editPassword && $scope.warehouse.ebaydepot_pwd_new) {
          $scope.warehouse.ebaydepot_pwd = $scope.warehouse.ebaydepot_pwd_new;
        }

        // $scope 에 담김값을 그대로 쓰면 패스워드가 암호화된 값으로 변경되기 떄문에 전송값은 새로운 변수에 담아 사용.
        const sendParams = _.cloneDeep($scope.warehouse);

        if (sendParams.ebaydepot_yn) {
          sendParams.etc = $scope.ebayEtcInfo;
        }

        warehouseModel.update(sendParams)
          .then((re) => {
            if (re.data.results === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('배송처 수정에 성공하였습니다.'));
              $uibModalInstance.close({ result: 'success' });
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            commonSVC.showToaster('error', '실패', errorSVC.getError('depot', data.data.error));
          });
      }
    };

    /**
     * 패스워드 수정버튼 클릭
     */
    $scope.editPwd = () => {
      $scope.warehouse.editPassword = !$scope.warehouse.editPassword;
      if (!$scope.warehouse.editPassword) {
        $scope.warehouse.placeHolder = 'PW정보는 수정버튼을 눌러서 변경합니다.';
        $scope.warehouse.buttonText = '수정';
      } else {
        $scope.warehouse.placeHolder = '';
        $scope.warehouse.buttonText = '취소';
        $('#newPwd').focus();
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
