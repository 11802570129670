'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A112-Ctrl', function ($scope, $rootScope, $timeout, onlineProdSVC, data, systemList, templateList, $uibModalInstance, commonSVC, commonModel, shopAccountModel, onlineProductModel) {
    $scope.localMadeinList = data.madeinList['국내'];
    $scope.globalMadeinList = data.madeinList['해외'];
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.detailType = _.cloneDeep(data.data.detailType);
    $scope.isStdOl = data.checked ? data.checked.A112 : data.data.std_ol_yn;
    $scope.row.set_info.key_trfownsTermCd = '02';
    $scope.data = _.cloneDeep(data.data);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.row.set_info.importedBeefYn = data.shop_data?.match_cate_code?.includes('1001481') || data.shop_data?.match_cate_name?.includes('수입쇠고기') || data.data?.match_cate_name?.includes('수입쇠고기') || data.data?.match_sol_cate_name?.includes('수입쇠고기');

    // 새롭게 추가된 항목이라 값이 없을때, 기본 세팅
    $scope.row.set_info.key_reviewOptDispYn = $scope.row.set_info.key_reviewOptDispYn || 'Y';
    if (!$scope.row.set_info.key_maktPrcType) {
      if ($scope.row.set_info.key_maktPrc) {
        // 정가가 입력의 경우,
        $scope.row.set_info.key_maktPrcType = '02';
      } else {
        // 정가가 미입력의 경우
        $scope.row.set_info.key_maktPrcType = '01';
      }
    }

    $scope.ok = async type => {
      const set_info = $scope.row.set_info;

      if ($scope.row.set_info.importedBeefYn) {
        if ($scope.row.set_info.key_beefTraceStat === '01' && !$scope.row.set_info.key_beefTraceStat) {
          commonSVC.showMessage('수입쇠고기 카테고리의 경우 축산물 이력번호가 필수항목입니다.');

          return false;
        }
      } else {
        $scope.row.set_info.key_beefTraceStat = '';
        $scope.row.set_info.key_beefTraceNo = '';
      }

      if (set_info.key_useGiftYn == 'Y') {
        if (!set_info.key_giftNm || !set_info.key_giftAplBgnDt || !set_info.key_giftAplEndDt || !set_info.b64_giftInfo) {
          commonSVC.showMessage('사은품 정보를 확인하세요');

          return false;
        }
      }

      if ($scope.rootCateNo == '2967' && !set_info.key_maktPrc && set_info.key_maktPrcType === '02') {
        commonSVC.showMessage('도서 정보를 확인하세요');

        return false;
      }

      if (!set_info.key_maktPrc) {
        delete $scope.row.set_info.key_maktPrc;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    /**
     * 원재료 등록/수정에서 데이터 등록/수정시 호출되는 callback
     *
     * @param {array} material
     */
    function addMaterialCallBack(material) {
      $scope.row.set_info.key_material = angular.copy(material);
    }

    /**
     * 원재료 등록/수정 버튼 show/hide
     * @condition 원산지가 가공품
     *
     * @returns {boolean} true면 show false면 hide
     */
    $scope.isMaterialAddOrEdit = () => {
      return $scope.row.set_info.key_rdoOriginProductType === '03';
    };

    $scope.showMaterialAddOrEditModal = () => {
      const templatePath = 'views/online/product/channel/A112_material_add_edit.html';
      const controller = 'A112_MaterialAddOrEdit';
      const resolve = {
        data: {
          material: $scope.row.set_info.key_material && _.cloneDeep($scope.row.set_info.key_material) || []
        }
      };

      const modal = commonSVC.openModal('xxg', resolve, controller, templatePath);

      modal.result.then(function (re) {
        if (re && re !== 'cancel') {
          addMaterialCallBack(re);
        }
      });
    };

    $scope.getMaterialEditOrAddStatus = function () {
      return $scope.row.set_info.key_material && Object.keys($scope.row.set_info.key_material).length > 0 ? '수정' : '등록';
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.row.set_info[name] = v.url;
              $scope.row.set_info[`${name}_name`] = v.origFileName;
            }
          });
        });
    };

    $scope.fileClear = function (name) {
      delete $scope.row.set_info[name];
      delete $scope.row.set_info[`${name}_name`];
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    // 현제 선택된 카테고리 확인하여 도서, 해외 필수정보 처리./
    $scope.rootCateNo = '';

    // 상품군 분류
    $scope.hg_category_list = angular.copy(onlineProdSVC.A112.hg_category_list);

    if ($scope.row.match_cate_code != undefined && $scope.row.match_cate_code != null && $scope.row.match_cate_code != '') {
      const rootCateNos = $scope.row.match_cate_code.split('_');

      $scope.rootCateNo = rootCateNos[0];
    }

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function (event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  }
  );
