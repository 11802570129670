'use strict';

angular.module('gmpApp')
  .factory('tagModel', function ($rootScope, settings, commonSVC) {
    return {
      save: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/tag/save`;

        return commonSVC.sendUrl('POST', url, params);
      },

      list: function (params) {
        const url = `${settings.pa20ApiUrl}/app/order/tag/list`;

        return commonSVC.sendUrl('POST', url, params);
      },

      del: function(params) {
        const url = `${settings.pa20ApiUrl}/app/order/tag`;

        return commonSVC.sendUrl('DELETE', url, params);
      },
      terms: {
        save: function (params) {
          const url = `${settings.pa20ApiUrl}/app/order/tag/terms-save`;

          return commonSVC.sendUrl('POST', url, params);
        },

        detail: function(params) {
          const url = `${settings.pa20ApiUrl}/app/order/tag/terms_detail`;

          return commonSVC.sendUrl('GET', url, params);
        },

        apply: function (params) {
          const url = `${settings.pa20ApiUrl}/app/order/tag/apply`;

          return commonSVC.sendUrl('POST', url, params);
        },

        del: function(params) {
          const url = `${settings.pa20ApiUrl}/app/order/tag/terms`;

          return commonSVC.sendUrl('DELETE', url, params);
        },
      }
    };
  });
