/**
 * 2018-06-27 Daniel
 * sku 동기화
 */

'use strict';

angular.module('gmpApp')
  .controller('syncSkuCtrl', function ($scope, $uibModalInstance, $q, data, commonSVC, workSVC) {
    $scope.selected = data.selected;

    $scope.fields = [
      {
        key: 'prod_name',
        value: '상품명',
        selected: false
      },
      {
        key: 'barcode',
        value: '바코드',
        selected: false
      },
      {
        key: 'brand',
        value: '브랜드',
        selected: false
      },
      {
        key: 'maker',
        value: '제조사',
        selected: false
      },
      {
        key: 'bundle_avail_yn',
        value: '합포장여부',
        selected: false
      },
      {
        key: 'stock_cd',
        value: '재고관리코드',
        selected: false
      },
      {
        key: 'stock_cnt_real',
        value: '수량',
        selected: false
      }
    ];

    $scope.ok = function () {
      const fields = _.filter($scope.fields, { selected: true }).map(function(f) {
        return f.key;
      });

      if (!fields.length) {
        commonSVC.showMessage('한개 이상의 정보를 선택해주세요.');

        return false;
      }

      const skuGroupById = _.groupBy($scope.selected, 'ebaydepot_id');

      const addWorkProms = [];
      let success_cnt = 0;
      let error_cnt = 0;

      _.each(skuGroupById, function (list, id) {
        const ebaydepot_id = id;
        const ebaydepot_pwd = list[0].ebaydepot_pwd;

        // 작업 추가
        const param = {
          site: {
            site_code: 'ESMP',
            site_name: 'G마켓 물류',
            site_id: ebaydepot_id,
            site_pwd: ebaydepot_pwd,
            playapi_runner_os: 'AWS',
            uuid: list[0].uuid
          },
          data: {
            sku_cds: _.map(list, 'sku_cd'),
            fields: fields
          }
        };

        addWorkProms.push(workSVC.addEbaydepotWork('SyncSkuByFilter', param, true)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              success_cnt++;
            } else {
              error_cnt++;
            }
          })
          .catch(function () {
            error_cnt++;
          })
        );
      });

      $q.all(addWorkProms)
        .then(function () {
          commonSVC.showToaster('success', '작업등록 완료', `성공 : ${success_cnt}, 실패 : ${error_cnt}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
