'use strict';

angular.module('gmpApp')
  .controller('LoadMasterProdCtrl', function ($scope, $uibModalInstance, data, categoryModel) {
    $scope.select_type = data.type;

    $scope.masterProdList = data.prodList;
    $scope.selectedProd = '';
    $scope.masterSearchKey = '';

    /**
       * 마스터상품 설정
       */
    $scope.setMaster = async (prod) => {
      $scope.selectedProd = prod.sale_no;
    };

    /**
       * 마스터상품 검색어 필터링
       */
    $scope.masterSearchFilter = (m) => {
      return String(m.c_sale_cd).indexOf($scope.masterSearchKey) > -1 || m.shop_sale_name.indexOf($scope.masterSearchKey) > -1 || m.shop_sale_name === '';
    };

    $scope.close = async (flag) => {
      if (flag && $scope.selectedProd) {
        const { data } = await categoryModel.getSlaveProdCate({ sale_no: $scope.selectedProd });

        $uibModalInstance.close(data);
      } else {
        $uibModalInstance.close('cancel');
      }
    };
  });
