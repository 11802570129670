'use strict';

angular.module('gmpApp')
  .controller('AutoMatchCtrl', function ($scope, $uibModalInstance, commonSVC, data) {
    // type = "online_auto_match" || "sku_auto_match"
    $scope.type = data.type;

    // 매칭 설정 값
    const localstorage_match_list = localStorage.getItem(`${data.type}_list`);

    // type이 online_auto_match || sku_auto_match인 default data.
    $scope.defaultMatchListData = localstorage_match_list && JSON.parse(localstorage_match_list) || data.base_match_list;

    $scope.match_list = angular.copy($scope.defaultMatchListData);

    // prod_name과 c_sale_cd은 항상 true 여야 하기 때문에 고정 시켜버림
    $scope.match_list.prod_name.checked = true;

    if ($scope.type === 'online_auto_match') {
      $scope.match_list.c_sale_cd.checked = true;
    }

    /**
     * 전체 체크박스 확인
     */
    $scope.checkboxClick = function(ob, flag) {
      if (!ob[flag]?.checked) {
        $scope.match_list[flag].checked = false;

        return;
      }
      $scope.match_list[flag].checked = true;
    };

    /**
     * click막고 confirm창 띄우는 함수
     */
    $scope.preventClick = function(matchName) {
      const contents = matchName === 'c_sale_cd' ? "'판매자관리코드'는 상품 유사도 비교 시 필수 항목으로 체크 해제가 불가합니다." : "'상품명'은 상품 유사도 비교 시 필수 항목으로 체크 해제가 불가합니다.";

      commonSVC.showMessage('체크 해제 불가', contents);
    };

    $scope.openGuide = function () {
      const resolve = {
        data: {
          type: $scope.type // "online_auto_match"(쇼핑몰 상품 가져오기) || "sku_auto_match"(SKU상품 매칭)
        }
      };
      commonSVC.openModal('xxg', resolve, 'AutoMatchGuideCtrl', 'views/online/product/modals/auto_match_guide.html');
    };

    // 마스터 상품이 10만건 이고 쇼핑몰상품 자동매칭이면 [브랜드명, 제조사명, 모델명, 대표이미지] 체크해제 후 비활성화 진행
    if (data.type === 'online_auto_match' && data?.masterProdCount >= 100000) {
      ['prod_name', 'brand', 'maker', 'model', 'image'].forEach((key) => {
        if (key === 'prod_name') {
          $scope.match_list[key].checked = true;
          $scope.match_list[key].disabled = false;
          $scope.match_list[key].percent = 100;
        } else {
          $scope.match_list[key].checked = false;
          $scope.match_list[key].disabled = true;
        }
      });
    }

    $scope.apply = function () {
      const match_list = $scope.match_list;
      let checkValid = true;
      let valid_message = '';
      // type이 online_auto_match인 default.
      const match_name_list = data.type === 'online_auto_match' ? ['prod_name', 'brand', 'maker', 'model', 'image'] : ['prod_name', 'brand', 'maker', 'model', 'madein'];
      _.forEach(match_name_list, (key) => {
        if (match_list[key].checked && !!match_list[key].percent) {
          checkValid = checkValid && !(Number(match_list[key].percent) < 50 || Number(match_list[key].percent) > 100);
          if (!checkValid) {
            valid_message = '유사도는 50 ~ 100% 사이만 가능합니다.';
          }
        }
        if (match_list[key].checked && key !== 'image' && !match_list[key].percent) {
          checkValid = false;
          valid_message = '선택한 항목의 유사도는 필수입니다.';
        }
      });

      if (!checkValid && valid_message.length) {
        commonSVC.showConfirm(valid_message);

        return false;
      }

      localStorage.setItem(`${data.type}_list`, JSON.stringify(match_list));

      $uibModalInstance.close($scope.match_list);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('close');
    };
  });

angular.module('gmpApp')
  .controller('AutoMatchGuideCtrl', function (data, $scope, $uibModalInstance) {
    $scope.type = data.type;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });