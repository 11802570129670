/**
 *  템플릿 서비스
 */

'use strict';

angular.module('gmpApp')
  .factory('templateSVC', function() {
    const makeSummaryData = {
      A001: function(summaryObjData, templateData) {
        // 판매기간
        summaryObjData.selTerms = templateData.item.data.key_selTerms;

        // 배송비
        if (templateData.item.data.key_deliv_fee_type == '0') {
          summaryObjData.deliv_fee_type = '온라인상품 정보 사용';
        } else if (templateData.item.data.key_deliv_fee_type == '1') {
          summaryObjData.deliv_fee_type = '옥션 묶음배송비 사용';
        } else {
          summaryObjData.deliv_fee_type = '템플릿 정보 사용';
        }

        // 상품상태
        if (templateData.item.data.key_rdoProdType == 'N') {
          summaryObjData.ProdType = '신상품';
        } else {
          summaryObjData.ProdType = '중고품';
        }

        summaryObjData.key_DeliveryFeeTemplateNo = templateData.item.data.key_DeliveryFeeTemplateNo;    // 묶음배송비
        summaryObjData.key_DeliveryFeeTemplateNo2 = templateData.item.data.key_DeliveryFeeTemplateNo2;  // 묶음배송비 ESM2.0
      }
    };

    return {
      getSummaryData: function(shopCd, templateData) {
        const summaryData = {
          data: {}
        };

        return makeSummaryData[shopCd](summaryData.data, templateData);
      }
    };
  });