/**
 * 결제 방법 선택 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayMethodCtrl', function ($scope, data, commonSVC, $uibModalInstance, gettextCatalog, $ocLazyLoad, payModel, userInfo, $filter, commonModel, localStorageService) {

    // 넘어온 결제 정보
    $scope.payData = data.payData;
    $scope.payGrid = data.payGrid;
    $scope.coupon_list = data.coupon_list;

    if ($scope.payData.autoPay) {
      $scope.autoPayExtendTerm = parseInt($scope.payData.extendTerm) || 1;
    }

    // 자동결제정보가 입력되어있지않은경우 자동결제 체크 불가.
    payModel.getAutoPayInfo('', function (status, data) {
      if (status == 'success') {
        // 세금계산서 선발행 여부 2018-11-27 rony
        $scope.tax_invoice_before_add_yn = data.tax_invoice_before_add_yn;
        $scope.tax = {
          tax_invoice_before_add_yn: data.tax_invoice_before_add_yn
        };
      }
    });

    /**
     * 결제
     */
    $scope.openYourWallet = async function () {
      // 결제수단 선택
      let params = {};

      // 후불카운트 요금은 따로 처리.
      if (!_.isUndefined($scope.payData.pay_no) && $scope.payData.pay_no != '' && $scope.payData.pay_no != '0') {
        params = {
          payData: $scope.payData
        };
      } else {
        // 청구서 제목.
        // 여러건의 결제건이 존재하는경우 처리.
        if ($scope.payGrid.length > 1) {
          let mainTitle = '';

          for (var i = 0; i < $scope.payGrid.length; i++) {
            if ($scope.payGrid[i].division == 'PLAYAUTO 2.0') {
              mainTitle = $scope.payData.GoodsName || `${$scope.payGrid[i].content} ${$scope.payGrid[i].contentDetail}`;
              break;
            }
          }
          if (mainTitle == '') {
            mainTitle = `${$scope.payGrid[0].content} ${$scope.payGrid[0].contentDetail}`;
          }
          $scope.payData.GoodsName = `${mainTitle} 외 ${String($scope.payGrid.length - 1)} 건`;
        } else {
          $scope.payData.GoodsName = $scope.payData.GoodsName || `${$scope.payGrid[0].content} ${$scope.payGrid[0].contentDetail}`;
        }

        // 값을 배열로 넘기면 데이터가 안넘어가므로 오브젝트로 변경해주어야 함. ㅠ.,ㅜ
        $scope.objPayGrid = {};
        let objGridDetail = {};

        for (i = 0; i < $scope.payGrid.length; i++) {
          objGridDetail = {
            type: $scope.payGrid[i].type,
            content: $scope.payGrid[i].content,
            contentDetail: $scope.payGrid[i].contentDetail,
            division: $scope.payGrid[i].division,
            price: $scope.payGrid[i].price,
            price_pre: $scope.payGrid[i].price_pre,
            prod_no: $scope.payGrid[i].prod_no,
            sale_cnt: $scope.payGrid[i].sale_cnt
          };
          $scope.objPayGrid[i] = objGridDetail;
        }

        // 1. 청구서 저장.
        params = {
          payData: $scope.payData,
          payGrid: $scope.objPayGrid,
          userInfo: userInfo
        };
      }

      // ----------------------------------
      // 총 결제금액과 개별결제금액이 일치하지 않으면 에러.
      let checkPayPrice = 0;

      for (const chk_key in params.payGrid) {
        checkPayPrice += Math.floor(Number(params.payGrid[chk_key].price));
      }

      if (params.payData.finalPrice && Number(params.payData.finalPrice) !== checkPayPrice) {
        commonSVC.showMessage('오류', '결제금액이 정상적이지 않습니다.\n페이지 새로고침 후 결제를 다시 진행해 주십시오.');

        return false;
      }
      // ----------------------------------

      $scope.submitDisabled = true;
      payModel.savePayInfo(params, function (state, data) {
        if (state == 'success') {
          const pay_no = data.pay_no;
          // 2. 카드 및 가상계좌인경우 나이스 팝업 띄움.
          //    무통장입금은 그냥 안내팝업 띄워줌.
          //    자동결제 선택시 자동결제 로직 돌림.
          if ($scope.payData.PayMethod === 'AUTOCARD') {
            // 창 띄우기 전에 값 매칭.
            $scope.payData.Moid = `GMP_${pay_no}`;   // 생성된 pay_no
            $scope.payData.Amt = $scope.payData.finalPrice;

            payModel.completeProcess(params, async function (state, retData) {
              if (state == 'success') {
                // 적용중인 쿠폰 재설정 (기존에 적용되어 있던 쿠폰은 사용여부에 따라 해제 or 사용완료처리됨)
                const cpnCd = params.payData.coupon;
                if (cpnCd) {
                  const sumCpnCd = [cpnCd.cpn_cd1, cpnCd.cpn_cd2, cpnCd.cpn_cd3].filter(Boolean);
                  const param = $scope.coupon_list.filter(cpn => cpn.use_type == '정기결제할인' && sumCpnCd.includes(cpn.cpn_no.toString()));

                  if (param.length) {
                    await payModel.useCoupon({ couponList: param });
                  }
                }

                commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
                  localStorageService.set('token', `${'Token' + ' '}${result}`);
                });

                const successMessage = `${$scope.payData.GoodsName} 결제에 성공하였습니다.`;
                const additionalMessage = $scope.payData.autoPay ? '- 다음 솔루션 만료일 오후 6시에 입력하신 정기결제 정보로 솔루션 이용료가 자동 결제 됩니다.<br>- 정기결제를 원하지 않으시는 경우 [결제관리 > 결제정보 > 정기결제 설정] 여부를 미사용으로 변경하여주시기 바랍니다.' : '';

                commonSVC.showMessageHtml(successMessage, additionalMessage, function () {
                  $uibModalInstance.close('success');
                });
              } else {
                $scope.submitDisabled = false;
                $scope.waitBtnDisableFlag = false;

                const errMsg = retData?.data?.error?.message || retData?.data?.error || retData?.data || '';

                commonSVC.showMessage(`${$scope.payData.GoodsName} 결제에 실패하였습니다`, errMsg);
              }
            });
          } else if ($scope.payData.PayMethod === 'CARD' || $scope.payData.PayMethod === 'VBANK') {

            if ($scope.payData.PayMethod === 'VBANK') {
              commonSVC.showMessage(gettextCatalog.getString('가상계좌 결제건은 플레이오토 세금계산서가 발행되지 않습니다.\n결제시 현금영수증 신청하기를 반드시 선택하시기 바랍니다.'));
            }

            payModel.getNiceHashKey($scope.payData, function (state, { hashString }) {

              // 창 띄우기 전에 값 매칭.
              $scope.payData.Moid = `GMP_${pay_no}`;   // 생성된 pay_no
              $scope.payData.EncryptData = hashString;
              $scope.payData.VbankExpDate = moment().add(1, 'day').format('YYYYMMDD');
              $scope.payData.Amt = $scope.payData.finalPrice;

              // goPay 함수는 나이스 스크립트에서 호출하는 함수이므로 삭제하면 안됨.
              $ocLazyLoad.load(['https://web.nicepay.co.kr/flex/js/nicepay_tr_utf.js'], { cache: false })
                .then(function () {
                  goPay(document.payForm);
                });

              $scope.submitDisabled = false;
            });
          }
          // 무통장입금인경우.
          else {

            // 세금계산서 선발행을 선택한경우 1:1 문의등록 및 슬렉 푸시 2018-11-27 rony
            let viewAddMsg = '';

            if ($scope.tax.tax_invoice_before_add_yn) {
              payModel.pushTaxBill({ pay_no: pay_no }, function () { });
              viewAddMsg = '<br><br><br><font color=red>※ 세금계산서는 플레이오토에서 확인 및 발행 후<br>설정 > 회사정보관리 > 담당자 정보에 저장된 메일로 전송됩니다.<br>(미수신 및 변경은 1:1문의로 접수해 주세요.)</font>';
            }
            // 메세지 확인후 새로고침 되어야 하기 때문에 관련 처리 변경. 2018-07-19 rony
            commonSVC.showMessageHtml('결제 청구서가 발행되었습니다.', `무통장 입금 시, 입금자명은 회사명 또는 대표자명으로 해주세요.<br>입금 확인은 1:1문의로 남겨 주세요.<br><br>[무통장 입금 계좌 안내]<br>※ 입금하실금액 : ${$filter('number')($scope.payData.finalPrice)} 원<br>※ 입금하실계좌 :\t우리은행 1005-303-351458 (주)커넥트웨이브${viewAddMsg}`, function () {
              $uibModalInstance.close('success');
            });
          }
        } else {
          $scope.submitDisabled = false;
          $scope.waitBtnDisableFlag = false;
          const errMsg = data.data === 'finalPrice ERR' ? '최종 결제예정금액이 0원인 경우 \n결제가 불가합니다. \n결제금액이 0원을 초과하도록 변경하여 \n다시 시도해 주세요.'
            : '결제에 실패하였습니다.';

          commonSVC.showToaster('error', '실패', errMsg);
        }
      });
    };

    /**
     * https://web.nicepay.co.kr/flex/js/nicepay_tr_utf.js 에서 호출되는 함수
     * 결제창 종료 함수 <<'nicepayClose()' 이름 수정 불가능>>
     *
     * 카드 결제 취소시 발행된 청구서 삭제해주어야 함 2018-10-30 rony
     */
    window.nicepayClose = function () {
      const params = {
        pay_nos: [$scope.payData.Moid.replace('GMP_', '')]
      };

      payModel.deletePays(params, function () { });
      commonSVC.showMessage(gettextCatalog.getString('결제가 취소 되었습니다.'));
    };

    /**
     * https://web.nicepay.co.kr/flex/js/nicepay_tr_utf.js 에서 호출되는 함수
     * 결제 최종 요청시 실행됩니다. <<'nicepaySubmit()' 이름 수정 불가능>>
     */
    window.nicepaySubmit = function () {

      // 결제 카드정보 담아주어야 함.
      $scope.payData.TrKey = $("[name='TrKey']").val();
      // 로딩 이미지 닫기... ( 월래는 submit 으로 인한 페이지 이동으로 해당작업을 할 필요가 없으나
      // 결제 처리가 백앤드에서 진행되어 페이지 이동이 발생되지 않으므로 해당 처리 해주어야 함.
      $('#bg_layer').css('display', 'none');
      $('#nice_layer').css('display', 'none');

      const params = {
        payData: $scope.payData,
        payGrid: $scope.objPayGrid
      };

      // 카드 결제
      if ($scope.payData.PayMethod === 'CARD') {
        payModel.completeProcess(params, function (state, retData) {
          if (state == 'success') {
            commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
              localStorageService.set('token', `${'Token' + ' '}${result}`);
            });

            commonSVC.showMessageHtml(`${$scope.payData.GoodsName} 결제에 성공하였습니다.`, '', function () {
              $uibModalInstance.close('success');
            });
          } else {
            const errMsg = retData?.data?.error?.message || retData?.data?.error || retData?.data || '';

            commonSVC.showMessage(`${$scope.payData.GoodsName} 결제에 실패하였습니다`, errMsg);
          }
        });
      }
      // 가상계좌인경우
      else if ($scope.payData.PayMethod === 'VBANK') {
        payModel.publishVbank(params, function (state, data) {
          if (state == 'success') {
            let viewStr = '<center><table class="table table-striped table-bordered table-advance table-hover">';

            viewStr += `<tr><td>입금은행</td><td>${data.vbankBankName}</td></tr>`;
            viewStr += `<tr><td>입금금액</td><td>${$filter('number')(data.amt)} 원</td></tr>`;
            viewStr += `<tr><td>계좌번호</td><td>${data.vbankNum}</td></tr>`;
            viewStr += `<tr><td>입금기한</td><td>${data.vbankExpDate.substr(0, 4)}-${data.vbankExpDate.substr(4, 2)}-${data.vbankExpDate.substr(6, 2)}</td></tr>`;
            viewStr += '</table></center>';

            commonSVC.showMessageHtml('아래의 은행계좌로 해당 금액을 입금하여 주십시오.', viewStr, function () {
              $uibModalInstance.close('success');
            });
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString(`${$scope.payData.GoodsName} 가상계좌 발행 실패!!`));
          }
        });
      }
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });