'use strict';

angular.module('gmpApp').controller('OrderCustomerInquiryCollectAllCtrl', function ($scope, data, $uibModalInstance, workSVC, $q) {

  //------------------------
  // 변수 설정
  //------------------------
  $scope.channel_list = data; // 채널 리스트

  //------------------------
  // 함수
  //------------------------

  /**
   * 취소
   */
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  /**
   * 문의 수집 버튼 클릭 시
   */
  $scope.inquiryCollect = function() {

    const promiseArr = [];

    angular.forEach($scope.channel_list, function(v) {
      const params = {
        site_code: v.shop_cd,
        site_id: v.shop_id
      };
      const anHttpPromise = workSVC.addWork('ScrapCS', params);
      const anHttpPromise2 = workSVC.addWork('ScrapEmergencyCS', params);
      const anHttpPromise3 = workSVC.addWork('ScrapReview', params);

      // .then(function(result){
      //   if(result.status == 200 && result.data.result == "success"){ success_cnt++; }
      //   else{ error_cnt++; }
      //   return result;
      // })
      // .catch(function(err){
      //   error_cnt++;
      //   return err;
      // });
      promiseArr.push(anHttpPromise);
      promiseArr.push(anHttpPromise2);
      promiseArr.push(anHttpPromise3);
    });

    $q.all(promiseArr)
      .then(function() {
        $uibModalInstance.close('success');
      })
      .catch(function(err) {
        return err;
      });
  };

});
