'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A011-Ctrl', function ($scope, $timeout, $rootScope, shopAccountModel, onlineProductModel, categoryModel, commonModel, commonSVC, userInfo, siteInfo, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = data.data.detailType;

    // 품질검사 QA 전성분 멀티등록 용 변수.
    $scope.essential_ingredient = [];
    let es_cnt = 1;
    let essential_ingredient_info = {};

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.row.set_info[name] = v.url;
              $scope.row.set_info[`${name}_name`] = v.origFileName;
            }
          });
        });
    };

    $scope.fixSaleCode = function (key_codeType) {
      if (key_codeType === '0') {
        $scope.row.set_info.key_code = '';

        return $scope.data.c_sale_cd;
      }
      else {
        return $scope.row.set_info.key_code;
      }
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      const set_info = $scope.row.set_info;

      if (set_info.key_scDate) {
        set_info.key_scDate = moment(set_info.key_scDate).format('YYYY-MM-DD');
      }

      if (set_info.key_stdRelsRd === '0' && !set_info.key_thedayRels) {
        commonSVC.showMessage('당일출고 마감시간을 선택해주세요.');

        return false;
      }

      if (set_info.key_scYn === 'Y' && $scope.data.cert_info.cert_type !== '인증대상아님' && (($scope.data.cert_info.cert_type === '인증유형등록' === '0' && !set_info.key_scGubun) || !set_info.key_scOrg)) {
        commonSVC.showMessage('인증대상여부를 선택해주세요.');

        return false;
      }
      if ($scope.category && $scope.category.cate_code === $scope.phoneCode && !set_info.set_info.key_attrins_237 && !set_info.key_attrins_1003) {
        commonSVC.showMessage('휴대폰 정보를 확인하세요.');

        return false;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.category = null;
    $scope.userInfo = null;

    $scope.OpCode = null;
    $scope.rels = null;
    $scope.phoneCode = 'B67>01>07>05';
    $scope.brand_key = null;

    const shop_data = $scope.row;

    init();

    $scope.$on('infoEditGlobal', function(evt, originEvt, data) {
      init(data);
    });

    // gs 청소년 유해상품 여부 저장
    $scope.$watchCollection('data.minor', function () {
      $scope.row.set_info.key_adultChkYn = $scope.data.minor ? 'N' : 'Y';
    });

    // gs 바코드 저장
    $scope.$watchCollection('data.barcode', function () {
      $scope.row.set_info.key_barcode = $scope.data.barcode;
    });

    async function init(data) {
      $scope.row.set_info.key_barcode = $scope.data.barcode;
      $scope.row.set_info.key_adultChkYn = $scope.data.minor ? 'N' : 'Y';
      $scope.row.set_info.key_advrCnsdrMandVal = $scope.row.set_info.key_advrCnsdrMandVal || '0';

      // 전성분이 몇개 등록되었는지 체크.
      if ($scope.row.set_info.key_0_69_qa_file) {
        const pattern = /key_([0-9]+)_69_qa_/;

        for (const key in $scope.row.set_info) {
          const execValue = pattern.exec(key);

          if (execValue && Number(execValue[1]) + 1 > es_cnt) {
            es_cnt = Number(execValue[1]) + 1;
          }
        }
      } else if ($scope.row.set_info.key_69_qa_file) {
        $scope.row.set_info.key_0_69_qa_file = $scope.row.set_info.key_69_qa_file;
        $scope.row.set_info.key_0_69_qa_file_name = $scope.row.set_info.key_69_qa_file_name;
      }

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = shop_data.shop_cd;
      const shop_id = shop_data.shop_id;

      try {
        const shopAccountRe = await shopAccountModel.load({ shop_cd, shop_id });
        let shopInfoDetailRe;

        $scope.userInfo = shopAccountRe.data.results;

        if ($scope.data.sol_cate_no) {
          shopInfoDetailRe = await categoryModel.shopInfoDetailDataLoad({
            categoryId: $scope.data.sol_cate_no,
            shop_cd: shop_data.shop_cd,
            shop_id: shop_data.shop_id
          });
        }

        let no_add_info_temp = $scope.detailType === 'add' ? $scope.shop_data.shop_cate_no_add_info : $scope.data.shop_cate_no_add_info || {};

        if (typeof no_add_info_temp === 'string') {
          no_add_info_temp = JSON.parse(no_add_info_temp);
        }

        $scope.category = data ?
          data.param.detailData :
          (
            no_add_info_temp && Object.keys(no_add_info_temp).length ?
              no_add_info_temp :
              shopInfoDetailRe?.data?.data[0]?.sol_cate_shop_add_info || null
          );

        let brand_key;

        // 백화점 OP 코드
        requestPA('get_dept_code')
          .then(function (res) {
            $scope.OpCode = res.data.data.items;
          });

        // 인증 구분
        requestPA('get_auth_class')
          .then(function (res) {
            $scope.auth_class = res.data.data.items;
            $scope.scOrg();
          });

        if ($scope.category) {
          const cate_code = $scope.category.category_code.replace(/>/g, '');
          const cate_code2 = $scope.category.category_code;

          // 표준출고일
          requestPA('get_str_rels', { category: cate_code, md_id: $scope.category.MD.code })
            .then(function (res) {
              $scope.rels = res.data.data.items;
            });

          // 과세여부
          requestPA('get_tax_type', { category: cate_code2 })
            .then(function (res) {
              $scope.tax = res.data.data.items.taxType;
              $scope.row.set_info.key_taxType = $scope.tax === '99' ? '02' : $scope.tax;
            });

          // 인증정보 여부
          requestPA('get_auth_yn', { category: cate_code2 })
            .then(function (res) {
              const scType = res.data.data.items.scType;

              $scope.row.set_info.key_scYnFlag = scType;

              switch (scType) {
                case '1':
                  $scope.row.set_info.key_scYn = 'Y';
                  break;
                case '3':
                  $scope.row.set_info.key_scYn = 'N';
                  break;
              }
            });

          // 심의정보
          requestPA('get_de_list', { category: cate_code, brand_code: brand_key })
            .then(function (res) {
              $scope.de = res.data.data.items;
              $scope.advrCnsdrMandYn = res.data.data.advrCnsdrMandYn;
            });

          // QA정보
          requestPA('get_qa_list', { category: cate_code, brand_code: brand_key })
            .then(function (res) {
              $scope.checkNoEntTgtLiv = res.data.data.checkNoEntTgtLiv;
              $scope.checkNoEntTgtFood = res.data.data.checkNoEntTgtFood;

              if ($scope.checkNoEntTgtLiv === 'Y') {
                $scope.row.set_info.key_ocrLinkNoYn = 'Y';
              }

              $scope.qa = res.data.data.items;
              $timeout(() => {});
              // 전성분이미지는 이미지를 여러개 등록가능해야 하므로 따로 처리.
              if (Object.keys($scope.qa).includes('69')) {
                essential_ingredient_info = $scope.qa[69];

                for (let idx = 0; idx < es_cnt; idx++) {
                  $scope.essential_ingredient.push(essential_ingredient_info);
                }

                delete $scope.qa[69];
              }
            });
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '추가항목 조회에 실패했습니다.');
      }
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo,
            domain = siteInfo.A011.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    // 인증기관
    $scope.scOrg = function() {
      const auth_code = $scope.row.set_info.key_scGubun;

      if (auth_code) {
        requestPA('get_auth_org', { auth_code: auth_code })
          .then(function(res) {
            $scope.auth_org = res.data.data.items;
          });
      }
    };

    // 파일 다운로드
    $scope.downloadFiles = function(url) {
      window.open(url, '_blank');
    };

    $scope.calculate_eInfo = function (act, idx = 0) {
      if (essential_ingredient_info) {
        if (act === 'plus') {
          $scope.essential_ingredient.push(essential_ingredient_info);
        } else {
          $scope.essential_ingredient.splice(idx, 1);
        }
      }
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
