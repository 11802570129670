/*
 *  dragable modal
 *  2018-03-30 Daniel
 */

'use strict';

angular.module('gmpApp')
  .directive('draggableModal', function ($rootScope) {
    return {
      restrict: 'A',
      link: function ($scope, elem, attr) {
        const draggableElementList = {
          // 도움말 모달
          help: {
            style: {
              width: '450px',
              height: 'auto',
              overflow: 'hidden',
              margin: 0,
              top: '88px',
              left: 'calc(100% - 450px)',
              bottom: 'auto',
            },
            modal_body: {
              height: 'calc(100% - 40px)'
            }
          },
          // 쇼핑몰별 주의사항 모달
          shop: {
            style: {
              width: '1255px',
              height: '755px',
              overflow: 'hidden',
              margin: 0,
              top: '88px',
              left: 'calc(100% - 1255px)',
              bottom: 'auto',
            },
            modal_body: {
              height: 'calc(100% - 41px)'
            }
          },
          // 1:1문의 모달
          question: {
            style: {
              width: '1000px',
              height: 'auto',
              overflow: 'hidden',
              top: '30px',
              left: 'calc((100% - 1000px) / 2)',
              bottom: 'auto',
            },
            modal_body: {
              height: '670px'
            }
          }
        };

        const modal = $(elem).parent().parent().parent();

        // 클릭시 z-index 올려줌
        modal.mousedown(() => {
          $rootScope.draggableModalZindex(attr.draggableModal);
        });

        modal.css(draggableElementList[attr.draggableModal].style);
        modal.find('.modal-body').css(draggableElementList[attr.draggableModal].modal_body);

        modal.find('.modal-dialog').css({ margin: '0 auto' });
        modal.draggable({
          handle: '.modal-header', // 드래그할 엘리먼트
          containment: 'window' // 드래그 범위
        });

        const otherModals = $('.modal:not(.ui-draggable)');

        if (otherModals.length > 0) {
          const zi = parseInt($('.modal-backdrop').css('z-index'));

          $('.modal.ui-draggable').css({ 'z-index': '9999' });

          // 가장 상위 모달 하나만 z-index 조정
          otherModals.eq(0).css({ 'z-index': zi + 11 });
        } else {
          $('.modal-backdrop').hide();
        }

        $scope.$on('modalOpenGlobal', function (e, opt) {
          if (!opt.isHelpModal) {
            $('.modal.ui-draggable').css('z-index', '9999');
          }
        });

        $scope.$on('modalClosedGlobal', function () {
          if (!$('.modal:not(.ui-draggable)').length) {
            $('.modal-backdrop').hide();
          }
        });
      }
    };
  });
