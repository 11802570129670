'use strict';

angular.module('gmpApp')
  .factory('A092categorySVC', function () {

    return {

      //카테고리 data
      category_data: {
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });
