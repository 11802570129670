'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B720-Ctrl', function ($scope, shopAccountModel, $ocLazyLoad, siteInfo, $timeout, commonSVC, commonModel, B720categorySVC, categoryModel) {

    $scope.maxCate = [1, 4, 5, 6];
    $scope.cateItemList = [];
    $scope.cateSaveList = []; // 카테 저장항목

    function init() {
      fetchCate('1').then(result => {
        $scope.cateItemList[0] = result;
        $scope.cateData = angular.copy(B720categorySVC.category_data);
        $scope.cateData.detailData = angular.copy(B720categorySVC.category_data);
        $scope.cert_type = angular.copy(B720categorySVC.cert_type);
        $scope.category_names = ''; //노출될 카테고리 분류
        $scope.requireAttr = ''; //카테고리 속성 노출 안되게

        //상품에 매칭된 shop_cate가 있을경우
        if ($scope.matchingData.shop_cate_no_add_info) {
          matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
        } else if ($scope.matchingData.categoryId) {
          const param = {
            categoryId: $scope.matchingData.categoryId,
            shop_cd: $scope.matchingData.site_code,
            shop_id: $scope.matchingData.site_id
          };

          categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
            if (state == 'success') {
              if (result.length) {
                $scope.cateData = result[0];
                $scope.cateData.detailData = $scope.cateData.sol_cate_shop_add_info;
                $scope.cateSaveList = $scope.cateData.detailData.cateSaveList;
                $scope.category_names = result[0].category_names;
                matchDetailDataLoad($scope.cateData.detailData);

              }
            } else {
              commonSVC.showMessage('데이터 로드 실패');
            }
          });
        } else {
          reRender();
        }
      });
    }

    $scope.cateSelect = async function(selectNo) {
      $('#cate-spinner').removeClass('hidden');

      $scope.cateItemList.splice(selectNo);

      await $scope[`select${selectNo}`]();

      $('#cate-spinner').addClass('hidden');
    };

    $scope.select1 = async function () {
      $('.cateAttrList').html('');
      $scope.category_names = $('#CatMenu_0 option:selected').text();

      await fetchCate('2', $('#CatMenu_0').val()).then((result) => {
        if (result.length) {
          $scope.cateItemList[1] = result;
        }
        reRender();
      });
    };

    $scope.select2 = async function () {
      if ($('#CatMenu_1').val() != null) {
        $('.cateAttrList').html('');
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;

        await fetchCate('3', $('#CatMenu_1').val()).then((result) => {
          if (result.length) {
            $scope.cateItemList[2] = result;
          }
          reRender();
        });
      } else {
        return false;
      }
    };

    $scope.select3 = async function () {
      if ($('#CatMenu_2').val() != null) {
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
        await fetchCate('4', $('#CatMenu_2').val()).then(result => {
          if (result.length) {
            $scope.cateItemList[3] = result;
          }
          reRender();
        });
      } else {
        return false;
      }
    };

    $scope.select4 = async function () {
      if ($('#CatMenu_3').val() != '0_0_0_0' && $('#CatMenu_3').val() != null) {
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_3 option:selected').text()}`;
      } else {
        return false;
      }
    };

    function reRender() {
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    }

    function fetchCate(depth, lastCateCode) {
      const param = {
        depth: depth
      };

      if (lastCateCode) {
        param.category_code = lastCateCode;
      }

      return new Promise((resolve) => {
        try {
          requestPA('etc.scrap_category', param).then((result) => {
            resolve(result.data.data.categories.map((item) => {
              return {
                code: item[1],
                name: item[0]
              };
            }));
          }).catch (() => {
            resolve([]);
          });
        } catch (err) {
          commonSVC.showToaster('error', '실패', '카테고리 로딩 실패하였습니다.');
          resolve([]);
        }
      });
    }

    $scope.getCodeToString = item => item.map(obj => obj.code).join('>');
    $scope.getNameToString = item => item.map(obj => obj.name).join('>');

    // 카테고리 추가
    $scope.addCate = function() {
      const cateCds = [];
      const cateNames = [];

      for (let i = 0; i < 4; i++) {
        $scope.cateData.detailData[`cate${i}`] = $(`#CatMenu_${i} option:selected`).val();
        $scope[`CatMenu_name_${i}`] = $(`#CatMenu_${i} option:selected`).text();

        if ($scope.cateData.detailData[`cate${i}`]) {
          cateCds.push($scope.cateData.detailData[`cate${i}`]);
          cateNames.push($scope[`CatMenu_name_${i}`]);
        }
      }

      if ($scope.cateItemList.length !== cateCds.length) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      if ($scope.cateSaveList.length === 5) {
        commonSVC.showMessage('카테고리는 최대 5개까지 선택할수 있습니다');

        return false;
      }

      if (_.find($scope.cateSaveList, { cd: cateCds.join('>') })) {
        commonSVC.showMessage('이미 추가된 카테고리입니다');

        return false;
      }

      $scope.cateSaveList.push({ cd: cateCds.join('>'), name: cateNames.join('>') });
    };

    $scope.delCate = function(key) {
      $scope.cateSaveList.splice(key, 1);
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $timeout(() => {
        $scope.shopInfoSave(param);
      }, 500);
    });

    $scope.shopInfoSaveConfirm = function (event) {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', async function (confirm) {
          if (confirm) {
            // timeout 없이 저장하면 shopInfoSave에서 showMessage함수가 작동안해서 추가함 2020-01-15 Alvin
            $scope.shopInfoSave(event);
          }
        });
      }
    };

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (!$scope.cateSaveList.length) {
        commonSVC.showMessage('실패', '카테고리를 하나 이상 추가해야 합니다');

        return false;
      } else if (!$scope.cateSaveList.map(cateInfo => (cateInfo.cd.split('>'))).some(([cd]) => cd === '034')) {
        commonSVC.showMessage('실패', '지마켓 카테고리를 하나 이상은 필수로 지정해야합니다');

        return false;
      }

      for (let i = 0; i < $scope.maxCate.length; i++) {
        $scope.cateData.detailData[`cate${i}`] = !_.isUndefined($scope.cateSaveList[i]) ? $scope.cateSaveList[i].cd : '';
      }

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      [$scope.cateData.detailData.category_names] = $scope.cateSaveList.map(item => item.name);
      //category_code 초기화
      $scope.cateData.detailData.category_code = $scope.cateSaveList.map(item => item.cd).join('_');

      $scope.cateData.detailData.cateSaveList = $scope.cateSaveList;

      if ($scope.cateSaveList.length > 1) {
        $scope.category_names += (` 외 ${$scope.cateSaveList.length - 1}개`);
        $scope.cateData.detailData.category_names += (` 외 ${$scope.cateSaveList.length - 1}개`);
      }

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: $scope.cateData.detailData.category_code,
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: $scope.cateData.detailData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        /**
         * 2018-03-09 Roy
         * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
         */
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    async function matchDetailDataLoad(addInfo) {
      reRender();

      if (typeof addInfo === 'string') {
        addInfo = JSON.parse(addInfo);

        // 가끔 \" 가 붙어 두 번 해줘야 하는 상황이 있음
        if (typeof addInfo === 'string') {
          addInfo = JSON.parse(addInfo);
        }
      }

      if (addInfo && addInfo.cateSaveList) {
        $scope.cateSaveList = addInfo.cateSaveList;
        const category_code = addInfo.cateSaveList[0].cd.split('>');

        for (let i = 0; i < category_code.length; i++) {
          const item = category_code[i];

          $(`#CatMenu_${i} option[value=${item}]`).attr('selected', 'selected');

          await $scope.cateSelect(i + 1);
        }
      }

      reRender();
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const domain = siteInfo.B720.domain;
      const param = addData ? addData : { lvl: 1, code: '' };

      return commonSVC.requestPA($scope.userInfo, domain, action, param, commonModel, shopAccountModel);
    }

    // 계정정보 선 로딩후 카테고리 로딩
    shopAccountModel.load({ shop_cd: $scope.shop_cd, shop_id: $scope.shop_id })
      .then(function (res) {
        $scope.userInfo = res.data.results;

        init();
      });
  });