'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B688-Ctrl', function ($scope, $rootScope, $timeout, commonSVC, data, systemList, templateList, $uibModalInstance, shopAccountModel, onlineProductModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.row.set_info.key_DiscountType2 = $scope.row.set_info.key_DiscountType2 || 'PRICE';
    $scope.row.set_info.key_slotCount2 = $scope.row.set_info.key_slotCount2 || '3';
    $scope.row.set_info.key_shareDiscountStartAt2 = $scope.row.set_info.key_shareDiscountStartAt2 || moment().format('YYYY-MM-DD');
    $scope.row.set_info.key_shareDiscountStartTime2 = $scope.row.set_info.key_shareDiscountStartTime2 || '00';
    $scope.row.set_info.key_shareDiscountEndedTime2 = $scope.row.set_info.key_shareDiscountEndedTime2 || '24';
    $scope.row.set_info.key_GroupPersonnel2 = '2';
    $scope.row.set_info.key_groupDiscountStartAt2 = $scope.row.set_info.key_groupDiscountStartAt2 || moment().format('YYYY-MM-DD');
    $scope.row.set_info.key_groupDiscountStartTime2 = $scope.row.set_info.key_groupDiscountStartTime2 || '00';
    $scope.row.set_info.key_groupDiscountEndedTime2 = $scope.row.set_info.key_groupDiscountEndedTime2 || '24';
    $scope.row.set_info.key_plusFriendSubscriberExclusive2 = $scope.row.set_info.key_plusFriendSubscriberExclusive2 || 'false';

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.clearDiscount = () => {
      $scope.row.set_info.key_useDiscount2 = 'false';
      $scope.row.set_info.key_discountPrice2 = '';
      $scope.row.set_info.key_DiscountType2 = 'PRICE';
      $scope.row.set_info.key_useDiscountPeriod2 = 'false';
      $scope.row.set_info.key_discountStartedAt2 = '';
      $scope.row.set_info.key_discountEndedAt2 = '';
      $scope.row.set_info.key_discountStartTime2 = '';
      $scope.row.set_info.key_discountEndTime2 = '';
    };

    $scope.clearGroupDiscount = () => {
      $scope.row.set_info.key_useGroupDiscount2 = 'false';
      $scope.row.set_info.key_GroupPersonnel2 = '2';
      $scope.row.set_info.key_groupDiscountPrice2 = '';
      $scope.row.set_info.key_groupDiscountStartAt2 = moment().format('YYYY-MM-DD');
      $scope.row.set_info.key_groupDiscountStartTime2 = '00';
      $scope.row.set_info.key_groupDiscountEndedAt2 = '';
      $scope.row.set_info.key_groupDiscountEndedTime2 = '24';
      $scope.row.set_info.key_groupDiscountUseStockYn2 = 'false';
      $scope.row.set_info.key_groupDiscountUseStock2 = '';

      $scope.clearDiscount();
    };

    $scope.clearShareDiscount = function() {
      if ($scope.row.set_info.key_useGroupDiscount2 === 'false' && $scope.row.set_info.key_plusFriendSubscriberExclusive2 == 'false')
      {
        commonSVC.showMessage('톡딜 할인 설정시 해당 주문건은 판매수수료 10%가 부과되며 톡딜 할인중에 판매가의 수정 및 중지 처리가 불가합니다.');
      }
      $scope.row.set_info.key_useShareDiscount2 = 'false';
      $scope.row.set_info.key_shareDiscountPrice2 = '';
      $scope.row.set_info.key_slotCount2 = '3';
      $scope.row.set_info.key_shareDiscountStartAt2 = moment().format('YYYY-MM-DD');
      $scope.row.set_info.key_shareDiscountStartTime2 = '00';
      $scope.row.set_info.key_shareDiscountEndedAt2 = '';
      $scope.row.set_info.key_shareDiscountEndedTime2 = '24';
      $scope.row.set_info.key_useStockYn2 = 'false';
      $scope.row.set_info.key_stock2 = '';

      $scope.clearDiscount();
    };

    $scope.product_time1 = [
      { name: '00시', value: '00' },
      { name: '01시', value: '01' },
      { name: '02시', value: '02' },
      { name: '03시', value: '03' },
      { name: '04시', value: '04' },
      { name: '05시', value: '05' },
      { name: '06시', value: '06' },
      { name: '07시', value: '07' },
      { name: '08시', value: '08' },
      { name: '09시', value: '09' },
      { name: '10시', value: '10' },
      { name: '11시', value: '11' },
      { name: '12시', value: '12' },
      { name: '13시', value: '13' },
      { name: '14시', value: '14' },
      { name: '15시', value: '15' },
      { name: '16시', value: '16' },
      { name: '17시', value: '17' },
      { name: '18시', value: '18' },
      { name: '19시', value: '19' },
      { name: '20시', value: '20' },
      { name: '21시', value: '21' },
      { name: '22시', value: '22' },
      { name: '23시', value: '23' },
    ];

    $scope.product_time2 = [
      { name: '01시', value: '01' },
      { name: '02시', value: '02' },
      { name: '03시', value: '03' },
      { name: '04시', value: '04' },
      { name: '05시', value: '05' },
      { name: '06시', value: '06' },
      { name: '07시', value: '07' },
      { name: '08시', value: '08' },
      { name: '09시', value: '09' },
      { name: '10시', value: '10' },
      { name: '11시', value: '11' },
      { name: '12시', value: '12' },
      { name: '13시', value: '13' },
      { name: '14시', value: '14' },
      { name: '15시', value: '15' },
      { name: '16시', value: '16' },
      { name: '17시', value: '17' },
      { name: '18시', value: '18' },
      { name: '19시', value: '19' },
      { name: '20시', value: '20' },
      { name: '21시', value: '21' },
      { name: '22시', value: '22' },
      { name: '23시', value: '23' },
      { name: '24시', value: '24' },
    ];

    $scope.slot_count = [
      { name: '2회', value: '2' },
      { name: '3회', value: '3' },
      { name: '4회', value: '4' },
    ];

    $scope.setDate = (date) => {
      $scope.row.set_info.key_shareDiscountEndedAt2 = moment($scope.row.set_info.key_shareDiscountStartAt2).add(date, 'days').format('YYYY-MM-DD');
      $scope.row.set_info.key_shareDiscountEndedTime2 = '24';
    };

    $scope.ok = async type => {

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });