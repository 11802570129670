'use strict';

/**
 * SKU상품 매칭 검색
 */
angular.module('gmpApp')
  .controller('prodRuleSearch', function ($scope, $rootScope, $uibModalInstance, $filter, productModel, supplierModel, warehouseModel, systemModel, commonSVC, data, userInfo, settings, gettextCatalog, shipmentModel) {

    const channelList = angular.copy($rootScope.use_channel_list);

    const seller_nick_info = [];
    let prodList = {};
    _.forEach(channelList, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    // 직접 입력 추가
    channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

    /* 검색 및 버튼 관련 변수 */
    $scope.rule = {}; //매핑 규칙 리스트 관련 변수
    $scope.ruleData = {}; //선택한 규칙데이터
    $scope.prod = {}; //SKU상품 리스트 관련 변수
    $scope.prodData = {}; //선택한 SKU상품데이터
    $scope.set = {}; //세트상품 리스트 관련 변수
    $scope.setData = {}; //선택한 세트상품데이터

    $scope.isModal = !!data.selectedData.length; // 모달, 논모달 구분
    $scope.tab = 'prod'; //세트 상품, SKU상품 탭 구분

    $scope.flag = {};
    $scope.flag.newMappingRule = false;   //매칭규칙생성(수정)여부

    $scope.isMultiProd = data.isMultiProd;

    $scope.parentSearchData = angular.copy(data.searchForm);
    $scope.warehouseList = data.warehouseList || [];
    $scope.isAddOpt = data.isAddOpt; //추가구매옵션 여부
    // 모달인 경우에만 스타일 추가
    if ($scope.isModal) {
      $scope.tableStyle = {
        rule: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' },
        prod: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' }
      }; //테이블 컨테이너 스타일
    }
    let selectProdList = [];

    $scope.selectedCount = 0;

    /**
     * 매칭 규칙 데이터 테이블
     */
    const rule_search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: $scope.parentSearchData.sdate,
        edate: $scope.parentSearchData.edate,
        shop_id: '',
        shop_cd: '',
        map_type: '', //매칭구분
        multi_search_word: '', //멀티서치워드
        multi_type: 'shop_sale_no', //멀티서치 타입
        map_no_list: data.selectedData // 선택한 매칭규칙
      },
      searchData: {
        searchAreaClass: 'col-xs-6',
        searchAreafull: false,
        showDateSearch: false,
        haveBoxContent: false,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,       // 검색전체 건수
        showCount: 25,
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
          { label: gettextCatalog.getString('주문옵션명'), value: 'ord_opt_name' },
          { label: gettextCatalog.getString('SKU코드, 세트코드'), value: 'sku_cd,set_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          { label: gettextCatalog.getString('SKU상품명, 세트상품명'), value: 'prod_name,set_name' },
          { label: gettextCatalog.getString('속성명'), value: 'attr' }
        ],
        search_date_type: [
          { label: gettextCatalog.getString('규칙생성일'), value: 'wdate' },
          { label: gettextCatalog.getString('규칙수정일'), value: 'mdate' }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('매칭코드'), value: 'map_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        ]
      },
      searchDetail: [
        {
          title: gettextCatalog.getString('배송처 선택'),
          search_name: 'delivery_vendor',
          item_list: data.warehouseList || [],
          item_key: 'warehouse_name',
          item_value: 'code',
          select_value: ''
        },
        {
          // 채널 선택
          title: gettextCatalog.getString('쇼핑몰 선택'),
          search_name: 'shop_cd',
          item_list: commonSVC.getSiteList(channelList),
          item_key: 'shop_name',
          item_value: 'shop_cd',
          select_value: '',
          add_class: 'select-search'
        },
        {
          // 채널 계정 선택
          title: gettextCatalog.getString('계정 선택'),
          search_name: 'shop_id',
          item_list: commonSVC.getSiteIdList(channelList),
          item_key: 'shop_id',
          item_value: 'search_shop_id',
          select_value: '',
          add_class: 'select-search',
          filter: function(option) {
            return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.rule.searchForm.shop_cd && option.shop_id : _.intersection([$scope.rule.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
          }
        },
        {
          // 매칭구분
          title: gettextCatalog.getString('매칭구분'),
          search_name: 'map_type',
          item_list: [{ value: '자동', key: '자동매칭' }, { value: '수동', key: '수동매칭' }],
          item_key: 'key',
          item_value: 'value',
          select_value: ''
        }
      ]
    };

    $scope.rule.searchData = angular.copy(rule_search.searchData);
    $scope.rule.searchForm = angular.copy(rule_search.searchForm);
    $scope.rule.searchDetail = angular.copy(rule_search.searchDetail);
    $scope.rule.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true, 'rule');
      },
      resetDo: function() {
        $scope.resetDo('rule');
      },
      changeLen: function() {
        $scope.changeCount('rule');
      }
    };

    $scope.rule.methods = {};
    $scope.rule.options = {
      modal: $scope.isModal,
      pinningColumns: [],
      alignCenterColumns: ['map_type', 'opt_type'],
      alignRightColumns: ['map_cnt'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['shop_sale_name', 'map_cd', 'map_name', 'attri', 'opt_type', 'stock_cd'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: ['notice_msg', 'stock_cd'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/matching-rules`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.rule.searchForm);

          return data;
        },
        requestDidAction: function(result) {
          $scope.rule.searchData.totalCount = result.recordsTotal;
          prodList = result.prodList;

          $scope.ruleData = angular.copy(result.results);

          return result.results;
        }
      },
      columns: [
        {
          key: 'mdate',
          title: '규칙수정일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'wdate',
          title: '규칙 생성일',
          width: 90,
          filter: 'dateValid'
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰',
          width: 150,
          template: function(row) {
            let img = '<div class="text-center">직접입력</div>';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(data.systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
            }

            return img;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 120,
          filter: 'isNullHyphen'
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 150
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 200,
          filter: 'whiteSpace'
        },
        {
          key: 'ord_opt_name',
          title: '주문 옵션명',
          width: 250,
          notCompile: true
        },
        {
          key: 'opt_type',
          title: '옵션 구분',
          width: 80,
          notCompile: true,
          template: function(row) {
            return row.ord_opt_name === row.shop_sale_name ? '옵션없음' : row.ord_opt_type === '자동' ? '옵션' : '추가구매옵션';
          }
        },
        {
          key: 'map_type',
          title: '매칭 구분',
          width: 80,
          notCompile: true,
          template: function(row) {
            return row.map_modify_yn ? '수정' : row.map_type;
          }
        },
        {
          key: 'map_cd',
          title: '매칭코드',
          width: 180,
          template: function(row) {
            if (row.set_no) {
              return `<span class="label label-default bg-slate-400 mr-5">SET</span> <a ng-click="grid.appScope.showMultiSKUDetail('${row.map_no}', '${row.set_cd}', '${row.set_name}')">${row.set_cd}</a>`;
            } else {
              return prodList[row.map_no].length > 1 ?
                `<span class="bg-white border-warning-400 text-warning-400 label label-default mr-5">다중</span> <a ng-click="grid.appScope.showMultiSKUDetail('${row.map_no}')">${prodList[row.map_no][0].sku_cd} 외 ${prodList[row.map_no].length - 1}건</a>`
                : `<span class="label label-default bg-orange-800 mr-5">SKU</span>${prodList[row.map_no][0].sku_cd}`;
            }
          }
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 160,
          notCompile: true,
        },
        {
          key: 'map_name',
          title: '매칭상품명',
          width: 160,
          notCompile: true,
          template: function(row) {
            const name = row.set_name ? row.set_name :
              prodList[row.map_no].length > 1 ? `${prodList[row.map_no][0].prod_name} 외 ${prodList[row.map_no].length - 1}건` : row.prod_name;

            return name;
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 100,
          filter: 'isNullHyphen'
        },
        {
          key: 'map_cnt',
          title: '출고수량',
          tooltip: {
            text: '주문 1건당 출고되는 수량입니다.&#10;출고수량 수정 시 쇼핑몰 상품정보에 등록된 출고수량과 일치하지 않을 수 있습니다. 상품 > 쇼핑몰 상품 관리 > 상세 > 기본정보에서 출고수량을 확인하여 동일하게 수정해 주시기 바랍니다.',
            placement: 'left'
          },
          width: 80,
          template: function(row) {
            return (!row.set_name && prodList[row.map_no].length > 1) ? prodList[row.map_no].reduce((prev, curr) => prev + curr.pack_unit, 0) : (row.map_cnt || '');

          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 80
        }
      ]
    };

    /**
     * SKU 데이터테이블
     */
    const prod_search = {
      searchForm: {
        sdate: '',
        edate: '',
        search_word: '',                                                //검색어
        date_type: 'wdate',
        search_key: 'all',                                              //검색구분
        category: 'all',                                                //카테고리
        supplier_vendor: 'all',                                         //매입처
        delivery_vendor: 'all',                                         //배송처
        linkage_flag: 'all',                                            //연동상품여부
        tax_type: 'all',                                                 //과세여부
        recently_prod: 'all',                                             //신규상품(최근 일주일)
        safe_stock_alert: false,                                         //재고부족
        real_stock_none: false,                                           //판매불가
        group_prod: false,                                                //그룹상품
        multi_search_word: '', //멀티서치워드,
        multi_type: 'sku_cd', //멀티서치 타입
      },
      searchData: {
        searchAreaClass: 'col-xs-9',
        searchAreafull: false,
        showDateSearch: false,
        haveBoxContent: false,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        showCount: 25,
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('SKU코드'), value: ['sku_cd'] },
          { label: gettextCatalog.getString('재고관리코드'), value: ['stock_cd'] },
          { label: gettextCatalog.getString('SKU상품명'), value: ['prod_name'] },
          { label: gettextCatalog.getString('속성명'), value: ['attri'] },
          { label: gettextCatalog.getString('바코드'), value: ['barcode'] },
          { label: gettextCatalog.getString('모델명'), value: ['model'] }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        ],
        ...(!data.selectList?.isGlobal && { customSearchBtn: {
          title: '상품코드 기준 검색',
          tooltip: '선택한 주문의 상품이 솔루션에 존재하는 경우 쇼핑몰 상품코드에 매칭된 SKU상품 리스트를 검색합니다.',
          class: 'btn btn-primary',
          action: function() {
            $scope.standardSearch();
          },
        } })
      },
      searchDetail: [
        {
          // 배송처 선택
          title: gettextCatalog.getString('배송처 선택'),
          test_id: 'drop-select-delivery',
          search_name: 'delivery_vendor',
          item_list: data.warehouseList || [],
          item_key: 'warehouse_name',
          item_value: 'code',
          select_value: ''
        },
        {
          title: gettextCatalog.getString('상태'),
          search_name: 'stock_status',
          item_list: [{ state: '정상', value: '정상' }, { state: '품절', value: '품절' }, { state: '재고부족', value: '재고부족' }],
          item_key: 'state',
          item_value: 'value',
          select_value: ''
        }
      ]
    };

    $scope.prod.searchData = angular.copy(prod_search.searchData);
    $scope.prod.searchForm = angular.copy(prod_search.searchForm);
    $scope.prod.searchDetail = angular.copy(prod_search.searchDetail);
    $scope.prod.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, 'prod');
      },
      resetDo: function() {
        $scope.resetDo('prod');
      },
      changeLen: function() {
        $scope.changeCount('prod');
      }
    };

    $scope.prod.methods = {};
    $scope.prod.options = {
      modal: $scope.isModal,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'pack_unit', 'stock_cnt'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'pack_unit', 'stock_cnt', 'depot_name', 'bundle_avail_yn', 'sale_price', 'stock_status' ],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['wdate', 'stock_status'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/matching-list-of-base`,
        requestWillAction: function(data) {
          data = angular.merge({}, $scope.prod.searchForm, data);

          data.sol_no = $rootScope.user_profile.sol_no;

          // 선택된 항목에 있지만 선택 해제한 row 처리
          selectProdList = selectProdList.filter(prod => {
            if ($scope.prodList.find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no)) {
              return $scope.prod.methods.selectedData('all').find(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no);
            } else {
              return true;
            }
          });

          // 현재 선택된 row selectProdList에 추가
          selectProdList.push(... $scope.prod.methods.selectedData('all'));

          // selectProdList 중복 제거
          selectProdList = selectProdList.filter((prod, idx, arr) => arr.findIndex(p => p.prod_no === prod.prod_no && p.depot_no === prod.depot_no) === idx);

          return Object.assign($scope.prod.searchForm, data);
        },
        requestDidAction: function(result) {
          $scope.prod.searchData.totalCount = result.recordsTotal;

          result.results.forEach((data) => data.pack_unit = 1);
          $scope.prodList = result.results;
          // 선택된 row 선택 처리
          result.results.forEach(row => {
            const matchProd = selectProdList.find(prod => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no);

            row.isSelected = !!matchProd;
          });

          // 현재 페이지에 있는 row 제거
          selectProdList = selectProdList.filter(prod => !result.results.find(row => prod.prod_no === row.prod_no && prod.depot_no === row.depot_no));
          // 현재 페이지에 없는 선택된 row의 수
          $scope.selectedCount = selectProdList.length;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function(row) {
            return `<button class="btn btn-primary btn-xxs mr-5" ng-click="grid.appScope.apply(${row.prod_no},'prod',${row.depot_no})" >매칭</button>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 130
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 350,
          template: (row) => {

            return `<span><img src='${row.prod_img || '/assets/images/upload.png'}' width='20' height='20' class='mr-5'></img><a ng-click="grid.appScope.showDetail('${row.sku_cd}','${row.prod_no}')" >${row.prod_name}</a></span>`;
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 100
        },
        {
          key: 'pack_unit',
          title: '출고수량 입력',
          tooltip: {
            text: '선택한 SKU상품(또는 세트상품)이 주문수량 1개당 출고되어야 할 수량을 입력합니다.',
            placement: 'left'
          },
          width: 100,
          template: function(row) {
            const idx = _.findIndex($scope.prodList, { prod_no: row.prod_no, depot_no: row.depot_no });

            return `<input type="number" min="1" class="form-control input-xs2" name="prod_pack_unit" id="${row.prod_no}_prod_pack_unit"` +
              `ng-model="grid.appScope.prodList[${idx}].pack_unit">`;
          }
        },
        {
          key: 'stock_cnt',
          title: '판매가능재고',
          width: 150,
          notCompile: true,
          template: function(row) {
            row.stock_cnt = row.stock_cnt ? row.stock_cnt : 0;

            return `${$filter('currency')(row.stock_cnt, '', 0)}`;
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          title: '합포장 가능여부',
          key: 'bundle_avail_yn',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        {
          key: 'stock_status',
          title: '상태',
          width: 100
        }
      ]
    };
    /**
     * SET PROD 데이터 테이블
     */
    const set_search = {
      searchForm: {
        sdate: '',
        edate: '',
        date_type: 'wdate',                                             //검색기간 구분
        search_word: '',                                                //검색어
        search_key: 'all',                                              //검색구분
        category: 'all',                                                //카테고리
        supplier_vendor: 'all',                                         //매입처
        delivery_vendor: 'all',                                         //배송처
        linkage_flag: 'all',                                            //연동상품여부
        tax_type: 'all',                                                 //과세여부
        recently_prod: 'all',                                             //신규상품(최근 일주일)
        safe_stock_alert: false,                                         //재고부족
        real_stock_none: false,                                           //판매불가
        group_prod: false,                                                //그룹상품
        multi_search_word: '', //멀티서치워드,
        multi_type: 'sku_cd', //멀티서치 타입
      },
      searchData: {
        searchAreaClass: 'col-xs-6',
        searchAreafull: false,
        showDateSearch: false,
        haveBoxContent: false,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        showCount: 25,
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('세트코드'), value: ['set_cd'] },
          { label: gettextCatalog.getString('세트상품명'), value: ['set_name'] },
          { label: gettextCatalog.getString('SKU코드'), value: ['sku_cd'] },
          { label: gettextCatalog.getString('SKU상품명'), value: ['prod_name'] },
          { label: gettextCatalog.getString('메모'), value: ['memo'] }
        ],
        search_multi_items: [
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        ],
        ...(!data.selectList?.isGlobal && { customSearchBtn: {
          title: '상품코드 기준 검색',
          tooltip: '선택한 주문의 상품이 솔루션에 존재하는 경우 쇼핑몰 상품코드에 매칭된 SKU상품 리스트를 검색합니다.',
          class: 'btn btn-primary',
          action: function() {
            $scope.standardSearch();
          },
        } })
      },
      searchDetail: [
        {
          title: gettextCatalog.getString('배송처 선택'),
          search_name: 'delivery_vendor',
          item_list: data.warehouseList || [],
          item_key: 'warehouse_name',
          item_value: 'code',
          select_value: ''
        },
        {
          title: gettextCatalog.getString('상태'),
          search_name: 'set_status',
          item_list: [{ state: '정상', value: '정상' }, { state: '품절', value: '품절' }, { state: '사용불가', value: '사용불가' }, { state: '재고부족', value: '재고부족' }],
          item_key: 'state',
          item_value: 'value',
          select_value: ''
        }]

    };
    $scope.set.searchData = angular.copy(set_search.searchData);
    $scope.set.searchForm = angular.copy(set_search.searchForm);
    $scope.set.searchDetail = angular.copy(set_search.searchDetail);
    $scope.set.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, 'set');
      },
      resetDo: function() {
        $scope.resetDo('set');
      },
      changeLen: function() {
        $scope.changeCount('set');
      }
    };

    //세트 데이터 테이블
    $scope.set.methods = {};
    $scope.set.options = {
      modal: $scope.isModal,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'set_cd', 'set_no', 'pack_unit', 'stock_status'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'pack_unit'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/set/list`,
        requestWillAction: function(data) {
          $scope.set.searchForm.orderby && delete $scope.set.searchForm.orderby;

          return Object.assign($scope.set.searchForm, data);
        },
        requestDidAction: function(result) {
          $scope.set.searchData.totalCount = result.recordsTotal;

          result.results.forEach((data) => data.pack_unit = 1);
          $scope.setList = angular.copy(result.results);

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function(row) {
            return `<button class="btn btn-primary btn-xxs mr-5" ng-click="grid.appScope.apply(${row.set_no},'set',${row.depot_no})">매칭</button>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 150,
          notCompile: true,
        },
        {
          key: 'set_name',
          title: '세트상품명',
          width: 350,
          template: (row) => {

            return `<span><a ng-click="grid.appScope.showSetDetails('${row.set_no}')" >${row.set_name}</a></span>`;
          }
        },
        {
          key: 'set_prod_cnt',
          title: '세트구성 상품 수',
          width: 100
        },
        {
          key: 'pack_unit',
          title: '출고수량 입력',
          tooltip: {
            text: '선택한 SKU상품(또는 세트상품)이 주문수량 1개당 출고되어야 할 수량을 입력합니다.',
            placement: 'left',
            forView: $rootScope.userProfileCheck('sol_ser', 'smile', 'like')
          },
          width: 100,
          template: function(row) {
            const idx = _.findIndex($scope.setList, { set_no: row.set_no });

            return `<input type="number" min="1" class="form-control input-xs2" name="set_pack_unit" id="${row.set_no}_set_pack_unit"` +
              `ng-model="grid.appScope.setList[${idx}].pack_unit">`;
          }
        },
        {
          key: 'stock_status',
          title: '상태',
          width: 100,
          tooltip: {
            text: '품절: 세트 판매수량 = 0 또는 SKU상품 판매가능재고 = 0인 경우/ 재고부족: SKU상품이 0 < 판매가능재고 <= 안전재고인 경우\n사용불가: 세트에 속한 SKU상품 중 일부의 배송처가 변경된 경우',
            placement: 'left',
            forView: !$rootScope.userProfileCheck('sol_ser', 'smile', 'like')
          },
          notCompile: true,
          template: function(row) {
            const status = row.not_used ? '사용불가' : row.stock_status ? '품절' : row.set_status;

            return status;
          }
        },
        {
          key: 'delivery_vendor_name',
          title: '배송처',
          width: 150,
          notCompile: true
        }
      ]
    };
    $scope.standardSearch = function() {
      const ord_selected = $scope.order.methods.selectedData('shop_sale_no');
      if (ord_selected.length === 0) {
        commonSVC.showMessage('매칭된 SKU상품을 검색할 주문을 선택해주세요');

        return false;
      }

      const params = {
        shopSaleNoList: _.uniq(ord_selected),
        isSet: $scope.tab === 'set'
      };

      shipmentModel.searchMappingProd(params, function(state, data) {
        if (state === 'success') {
          if ($scope.tab === 'set') {
            $scope.set.searchForm = angular.copy(set_search.searchForm);
            $scope.set.searchForm.multi_type = 'set_cd';
            $scope.set.searchForm.multi_search_word = data.result.join('\n');
            $scope.searchDo(true, false, 'set');
          } else {
            $scope.prod.searchForm = angular.copy(prod_search.searchForm);
            $scope.prod.searchForm.prod_no = data.result;
            $scope.searchDo(true, false, 'prod');
          }
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('쇼핑몰 상품코드 기준검색에 실패하였습니다.'));
        }
      });
    };

    /**
      * 데이터테이블 pageLength 조절
      */
    $scope.changeCount = function (type) {
      $scope[type].methods.length($scope[type].searchData.showCount);
    };

    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };

    /**
       * 상품 상세페이지 모달창
       */
    $scope.showDetail = function(sku_cd, prod_number) {

      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
    };

    // SKU상품 등록 모달
    $scope.openProdAdd = function() {

      if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          detailType: 'add',
          openModal: true
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('full', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        $scope.searchDo(true, false, 'prod');
      });
    };

    // 세트상품 생성 모달
    $scope.openProdSet = function() {
      const modal = commonSVC.openModal('full', { data: {} }, 'addSetProdCtrl', 'views/prod/set/modals/add_set_prod.html');

      modal.result.then(function() {
        $scope.searchDo(true, false, 'set');
      });
    };

    // 세트상품 상세보기
    $scope.showSetDetails = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    // SKU상품, 세트상품 탭변경
    $scope.changeTab = function (tab) {
      $scope.resetDo(tab);
      $scope.tab = tab;
    };

    // 매칭
    $scope.apply = async function(no, type, depot_no) {
      const selectedProduct = [];
      const selectedSetProduct = [];
      const flag = true;
      let result;
      // row 선택안하고 매칭 버튼 클릭
      if (no && type && depot_no) {
        if (type === 'set') {
          selectedSetProduct.push(...$scope.setList.filter(set => set.set_no === no));
        }
      }
      //SKU상품 선택
      if ($scope.tab === 'prod') {
        const depotErrList = [], packUnitErrList = [];
        if (no && type && depot_no) {
          if (type === 'prod') {
            selectedProduct.push(...$scope.prodList.filter(prod => prod.depot_no === depot_no && prod.prod_no === no));
          }
        } else {
          let prod_list = [];

          prod_list = $scope.isMultiProd ? selectProdList.concat($scope.prod.methods.selectedData('all')) : $scope.prod.methods.selectedData('all');
          prod_list.forEach((element) => {
            if (!element.depot_no) {
              depotErrList.push(element);
            } else if (element.pack_unit < 1 || element.pack_unit > 9999) {
              packUnitErrList.push(element);
            } else {

              const prod = $scope.prodList.concat(selectProdList).find(({ prod_no, depot_no }) => prod_no === element.prod_no && depot_no === element.depot_no);

              element.newMappingRule = $scope.flag.newMappingRule;

              if ($scope.isAddOpt) {
                element.opt_prod_name = element.prod_name;
                element.prod_name = data.ord_opt_name;
              }

              element.pack_unit = prod.pack_unit;
              element.model_no = prod.model_no;

              selectedProduct.push(element);
            }
          });
        }

        if (!selectedProduct.length && !depotErrList.length && !packUnitErrList.length) {
          commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');

          return;
        }

        if (!$scope.rule.methods.selectedData('all').length) {
          commonSVC.showMessage('매칭하실 규칙을 선택 해주세요.');

          return;
        }

        // SKU 다중 매칭인 경우 합포장 불가 상품이 포함되어 있으면 실패 && 단일 매칭은 가능
        if (Array.from(new Set(selectedProduct)).length > 1 && selectedProduct.some(prod => !prod.bundle_avail_yn)) {
          commonSVC.showMessage('합포장 불가 상품이 포함되어 있는 경우 매칭이 불가능합니다.');

          return;
        }

        if (depotErrList.length) {
          commonSVC.showMessage(`SKU코드\n${depotErrList.map(d => d.sku_cd).join('\n')}\n상품은 배송처가 지정되지 않은 상품 입니다.`);

          return;
        }
        if (packUnitErrList.length) {
          commonSVC.showMessage(`출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.\nSKU코드\n${packUnitErrList.map(p => p.sku_cd).join('\n')}\n상품의 출고수량을 확인해주세요.`);

          return;
        }
        result = data.isMultiProd ? selectedProduct : selectedProduct[0];

        // SKU상품 선택이 1개 미만일 때
        if (selectedProduct.length < 1 && flag) {
          commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');
        }
      }
      //세트상품 선택
      else if ($scope.tab === 'set') {
        angular.forEach($('#mapping_rule_set_grid .ui-grid-render-container.ui-grid-render-container-body ' +
                          'div.ui-grid-viewport > div > div.ui-grid-row.ui-grid-row-selected.ui-grid-row-focused'), function(element) {
          const index = $(element).find('.ui-grid-row-inner').attr('row-index');

          $scope.set.methods.selectedData('all').push($scope.setList[index]);
        });

        if (!$scope.rule.methods.selectedData('all').length) {
          commonSVC.showMessage('매칭하실 규칙을 선택 해주세요.');

          return;
        }

        const usedFlag = _.filter(selectedSetProduct, function(row) { return row.set_status == '사용불가'; });

        if (usedFlag.length) {
          commonSVC.showMessage('', '배송처가 다르거나 합포장이 불가한 상품이 세트로 구성된 경우\n해당 세트상품은 사용이 불가합니다.\n\n세트 상품의 상세 페이지에서 구성 상품의 정보를 변경하신 후\n다시 매칭 작업을 진행해 주세요.');

          return false;
        }

        if (selectedSetProduct.some(e => e.pack_unit < 1 || e.pack_unit > 9999)) {
          commonSVC.showMessage('출고수량은 1 이상 9999 이하의 숫자만 입력 가능합니다.');

          return false;
        }
        productModel.setDetails({ set_no: selectedSetProduct[0].set_no }, function(state, resultData) {

          const idx = _.findIndex($scope.setList, { set_no: selectedSetProduct[0].set_no });

          resultData.data.set.pack_unit = $scope.setList[idx].pack_unit;
          resultData.data.set.set_pack_unit = $scope.setList[idx].pack_unit;
          resultData.data.set.newMappingRule = $scope.flag.newMappingRule;

          if ($scope.isAddOpt) {
            resultData.data.set.opt_prod_name = resultData.data.set.set_name;
            resultData.data.set.set_name = data.ord_opt_name;
          }
          const main_prod = resultData.data.prod.splice(resultData.data.prod.findIndex(p => p.prod_no === resultData.data.set.main_prod_no), 1);
          resultData.data.prod = main_prod.concat(resultData.data.prod);

          result = { isSet: true, data: resultData.data };
        });
      }
      if (data.pa_shop_cd && data.pa_shop_cd.startsWith('X') || $scope.rule.methods.selectedData('all').some(map => map.pa_shop_cd.startsWith('X'))) {
        if ((result.isSet && result.data.prod.some(re => re.ebaydepot_yn)) || (!result.isSet && result.some(re => re.ebaydepot_yn))) {
          commonSVC.showMessage('해외 주문은 G마켓 물류서비스 배송처 선택이 불가합니다.');

          return false;
        }
      }

      if (!result?.isSet && result?.some(r => r.depot_no !== result[0].depot_no)) {
        commonSVC.showMessage('배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인하시기 바랍니다.');

      } else {
        commonSVC.showConfirmHtml(`새로 선택한 ${$scope.tab === 'set' ? '세트' : 'SKU'}상품으로 매칭수정 하시겠습니까?`,
          `수정된 매칭 규칙 정보는 수정 이후에 수집되는 주문부터 적용됩니다.<br>기존 매칭정보는 초기화되며, 새롭게 매칭한 상품으로 수정됩니다.<br>쇼핑몰 상품에 매칭된 ${$scope.tab === 'set' ? '세트' : 'SKU'}상품 수정은 [쇼핑몰 상품 관리]의 상품상세에서 진행바랍니다.<br/>
          <span style="color:red; font-weight:bold">*설정 > 환경설정 > 주문 > "주문에 SKU상품 매칭 시 쇼핑몰 상품에 자동매칭" 항목이 "설정"으로 체크된 경우에만 적용 가능</span>`,
          function () {
            const param = {
              map_no: $scope.rule.methods.selectedData('all').map(v => v.map_no),
              is_set: result.isSet === true,
              map_info: result.isSet ?
                [{ prod_no: result.data.set.set_no, pack_unit: result.data.set.pack_unit, depot_no: result.data.set.depot_no, set_cd: result.data.set.set_cd, sku_cd: result.data.set.sku_cd }] :
                result.map(prod => ({ prod_no: prod.prod_no, pack_unit: prod.pack_unit, depot_no: prod.depot_no, set_cd: null, sku_cd: prod.sku_cd }))
            };
            shipmentModel.modifyMappingRule(param, function(state, data) {
              if (data.results == 'success') {
                $scope.rule.methods.reloadData(function () {}, true, true);
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('매칭수정에 성공하였습니다'));
                $scope.searchDo(false);
              } else {
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('매칭수정에 실패하였습니다'));
              }
            });
          });
      }
    };

    // 검색
    $scope.searchDo = function (refresh, noDelay, type) {
      $scope[type].methods.reloadData(null, refresh, noDelay);
    };

    // 검색 초기화
    $scope.resetDo = function(type) {
      const sf = type === 'rule' ? (rule_search.searchForm) : (type === 'set' ? set_search.searchForm : prod_search.searchForm);
      const sd = type === 'rule' ? (rule_search.searchDetail) : (type === 'set' ? set_search.searchDetail : prod_search.searchDetail);

      if (type === 'rule') {
        sf.map_yn = $scope[type].searchForm.map_yn;
      }
      $scope[type].searchForm = angular.copy(sf);
      $scope[type].searchData.search_key_name = '전체';
      $scope[type].searchDetail = angular.copy(sd);
      $scope[type].warehouseKey = '';
      selectProdList = [];
      $scope.selectedCount = 0;
      $scope[type].methods.unSelectByFilter(function () {
        return true;
      }, false);
      $scope.searchDo(true, true, type);
    };

    // SKU 다중 모달
    $scope.showMultiSKUDetail = function(map_no, set_cd, set_name) {
      const resolve = {
        data: { prodList: prodList[map_no], isMappingRule: true, set_cd, set_name }
      };

      commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
    };

    $scope.showList = function(row, list) {
      return list[row.item_key];
    };

    $scope.selectSearchForm = function (key, value, type) {
      $scope[type].searchForm[key] = value;
    };

    $scope.searchFilter = (item) => {
      return item.title === '쇼핑몰 선택' || item.title === '계정 선택';
    };

    // 배송처 검색
    $scope.warehouse = function(delivery_vendor, type) {
      $scope[type].searchForm.delivery_vendor = delivery_vendor;
      $scope[type].warehouseKey = delivery_vendor;

      if ($scope.tab === 'prod') {
        $scope.prod.methods.reloadData(function() {}, true, true);
      } else if ($scope.tab === 'set') {
        $scope.set.methods.reloadData(function() {}, true, true);
      }
    };
  });
