'use strict';

/**
 * Created by ally on 2017. 3. 14..
 * 상태에 따른 color class return
 */
angular.module('gmpApp')
  .filter('statusColor', function(gettextCatalog) {
    return function(status) {
      let className = '';
      let statusText = status;

      switch (status) {
        case '결제완료':
          className = 'label label-default bg-slate-800';
          break;
        case '결제대기':
          className = 'label label-default bg-orange-800';
          break;
        case '신규주문':
          className = 'label label-default bg-orange-800';
          break;
        case '주문보류':
          className = 'label label-default border-on border-orange-800 bg-white text-orange-800';
          break;
        case '출고대기':
          className = 'label label-default bg-orange-300';
          break;
        case '출고보류':
          className = 'label label-default border-on border-orange-300 bg-white text-orange-300';
          break;
        case '운송장출력':
          className = 'label label-default bg-success-600';
          break;
        case '출고완료':
          className = 'label label-default bg-success-300';
          break;
        case '배송중':
          className = 'label label-default bg-primary-800';
          break;
        case '발송완료':
          className = 'label label-default bg-primary-300';
          break;
        case '배송완료':
          className = 'label label-default bg-primary-300';
          break;
        case '구매결정':
          className = 'label label-default bg-grey-300';
          break;
        case '판매완료':
          className = 'label label-default bg-grey-100';
          break;
        case '취소요청':
          className = 'label label-default bg-danger-800';
          break;
        case '취소완료':
          className = 'label label-default border-on border-danger-300 bg-white text-danger-300';
          break;
        case '반품요청':
          className = 'label label-default bg-purple-800';
          break;
        case '반품진행':
          className = 'label label-default border-on border-purple-800 bg-white text-purple-800';
          break;
        case '반품회수완료':
          className = 'label label-default bg-purple-300';
          break;
        case '반품완료':
          className = 'label label-default border-on border-purple-300 bg-white text-purple-300';
          break;
        case '교환요청':
          className = 'label label-default bg-brown-600';
          break;
        case '교환진행':
          className = 'label label-default border-on border-brown-800 bg-white text-brown-800';
          break;
        case '교환회수완료':
          className = 'label label-default bg-brown-300';
          break;
        case '교환완료':
          className = 'label label-default border-on border-brown-300 bg-white text-brown-300';
          break;
        case '맞교환요청':
          className = 'label label-default bg-pink';
          break;
        case '맞교환완료':
          className = 'label label-default bg-pink';
          break;
        case '취소반려':
          className = 'label label-default border-on border-danger-300 bg-white text-danger-300';
          break;
        case '주문재확인':
          className = 'label label-default border-on border-primary-300 bg-white text-primary-300';
          break;
        default:
          statusText = status;
          break;
      }

      const html = `<span class="${className}">${gettextCatalog.getString(statusText)}</span>`;

      return html;
    };
  })
  .filter('statusColorInq', function(gettextCatalog) {
    return function(status) {
      let className = '';
      let statusText = status;

      switch (status) {
        case '신규문의':
          className = 'label label-default bg-orange-800';
          break;

        case '답변작성완료':
          className = 'label label-default bg-success-300';
          break;

        case '답변전송완료':
          className = 'label label-default bg-grey-300';
          break;

        case '답변전송실패':
          className = 'label label-default bg-danger-300';
          break;

        case '확인완료':
          className = 'label label-default btn-danger';
          break;

        default:
          statusText = status;
          break;
      }

      const html = `<span class="${className}">${gettextCatalog.getString(statusText)}</span>`;

      return html;
    };
  })

  /**
   * 2018-01-04 chris 온라인 상품용 추가
   */
  .filter('statusColorOl', function(gettextCatalog) {
    return function(status) {
      let className = '';
      let statusText = status;

      switch (status) {
        case '판매대기':
          className = 'label label-default bg-orange-300';
          break;
        case '승인대기':
          className = 'label label-default border-on border-orange-300 bg-white text-orange-300';
          break;
        case '반려':
          className = 'label label-default border-on border-danger-300 bg-white text-danger-300';
          break;
        case '판매중':
          className = 'label label-default bg-orange-800';
          break;
        case '종료대기':
          className = 'label label-default bg-danger-800';
          break;
        case '수정대기':
          className = 'label label-default bg-success-300';
          break;
        case '판매중지':
          className = 'label label-default bg-grey-300';
          break;
        case '일시품절':
          className = 'label label-default bg-slate-300';
          break;
        default:
          statusText = status;
          break;
      }

      const html = `<span class="${className}">${gettextCatalog.getString(statusText)}</span>`;

      return html;
    };
  })
  /**
   * 2018-07-03 Daniel G마켓 물류서비스 연동상태
   */
  .filter('statusColorEbaydepot', function (gettextCatalog) {
    return function (status) {
      let className = '';
      let statusText = status;

      switch (status) {
        case '발송정보 미등록':
          className = 'label label-default bg-slate-800';
          break;
        case '발송정보 전송실패':
          className = 'label label-default bg-slate-border-800';
          break;
        case '발송정보 수정대기':
          className = 'label label-default bg-orange-800';
          break;
        case '발송요청 대기':
          className = 'label label-default bg-orange-300';
          break;
        case '발송요청중':
          className = 'label label-default bg-purple-300';
          break;
        case '발송요청 완료':
          className = 'label label-default bg-success-300';
          break;
        case '발송요청 실패':
          className = 'label label-default bg-danger-800';
          break;
        case '발송 완료':
          className = 'label label-default bg-primary-800';
          break;
        case '판매취소 완료':
          className = 'label label-default border-on border-danger-300 bg-white text-danger-300';
          break;
        default:
          statusText = status;
          break;
      }

      const html = `<span class="${className}">${gettextCatalog.getString(statusText)}</span>`;

      return html;
    };
  })
  // AS관리 상태 변경
  .filter('statusColorAs', function(gettextCatalog) {
    return function(status) {
      let className = '';
      let statusText = status;

      switch (status) {
        case 'AS요청':
          className = 'label label-default bg-orange-800';
          break;
        case 'AS접수':
          className = 'label label-default bg-slate-800';
          break;
        case 'AS가능':
          className = 'label label-default bg-primary-600';
          break;
        case 'AS불가':
          className = 'label label-default bg-white border-on border-primary-600 text-primary-600';
          break;
        case 'AS완료':
          className = 'label label-default bg-success-300';
          break;
        case 'AS검수':
          className = 'label label-default bg-white border-on border-success-300 text-success-300';
          break;
        case 'AS출고':
          className = 'label label-default bg-grey-300';
          break;
        default:
          statusText = status;
          break;
      }

      const html = `<span class="${className}">${gettextCatalog.getString(statusText)}</span>`;

      return html;
    };
  });
