/**
 * Spinner 관련 지시자만 이 파일에 모은다.
 *
 * 2016-04-13 : 매튜
 */

'use strict';

angular.module('gmpApp')

  // Route State Load Spinner(used on page or content load)
  .directive('ngSpinnerBar', ['$rootScope', 'commonSVC',
    function($rootScope, commonSVC) {
      return {
        link: function(scope, element) {
          // by defult hide the spinner bar
          element.addClass('hide'); // hide spinner bar by default

          // display the spinner bar whenever the route changes(the content part started loading)
          $scope.$on('$stateChangeStartGlobal', function() {
            element.removeClass('hide'); // show spinner bar
          });

          // hide the spinner bar on rounte change success(after the content loaded)
          $scope.$on('$stateChangeSuccessGlobal', function(evt, originEvt, toState, toParams, fromState, fromParams) {

            element.addClass('hide'); // hide spinner bar
            // $('body').removeClass('page-on-load'); // remove page loading indicator
            // Layout.setSidebarMenuActiveLink('match'); // activate selected link in the sidebar menu

            $rootScope.preventState = fromState;
            $rootScope.preventParams = fromParams;

          });

          // handle errors
          $scope.$on('$stateNotFoundGlobal', function() {
            element.addClass('hide'); // hide spinner bar
          });

          // handle errors
          $scope.$on('$stateChangeErrorGlobal', function(evt, originEvt, toState, toParams, fromState, fromParams, error) {

            // element.addClass('hide'); // hide spinner bar

            // 에러 팝업 처리
            if (error && _.isObject(error)) {
              const title = error.title || '';
              const contents = error.contents || '';

              if (!_.isEmptyStr(title) || !_.isEmptyStr(contents)) {
                commonSVC.showMessage(title, contents);
              }
            }

            element.addClass('hide'); // hide spinner bar
            evt.preventDefault();
          });
        }
      };
    }

  ])

;
