'use strict';

angular.module('gmpApp')
  .controller('AutoSettingCtrl', function ($scope, $uibModalInstance) {

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.autoSetting = () => {
      $uibModalInstance.close('AutoSetting');
    };
    $scope.addMall = () => {
      $uibModalInstance.close('AddMall');
    };
  });
