/**
 * 2018-08-22 Daniel
 * 관리
 */

'use strict';

angular.module('gmpApp')
  .controller('MasAddcontentCtrl', function ($scope, $rootScope, $uibModalInstance, $q, settings, commonSVC, onlineProductSVC, onlineProductModel, data) {
    $scope.returnYn = data.returnYn;
    $scope.select_type = data.type;

    // 템플릿 해제 후 모달닫을때 부모 그리드 새로고침 해줘야 하기 때문에 해제 여부 변수로 받음.
    let templateReleaseYn = false;

    try {
      var selectProds = data.result;

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: '',
          search_word: '',
          site_id: '',
          site_code: '',
          status: '',
          status_sub: '',
          page: 'order',
          addcontentTarget: '상세설명'
        },
        searchData: {
          all_checked: false,                                                         // 전체선택 여부
          selectCount: 0,                                                             // 검색전체 건수
          totalCount: 0,                                                              // 검색전체 건수
          showDetailSearchArea: false,
          searchAreaClass: 'col-xs-10',
          searchNoMargin: true,
          search_key_items: [                                                         // 검색영역 키값
            { label: '전체', value: '' },
            { label: '템플릿코드', value: 'addcontent_no' },
            { label: '템플릿명', value: 'addcontent_name' }
          ]
        },
        searchDetail: [
          {
            title: '템플릿구분 전체',
            search_name: 'addcontentType',
            item_list: onlineProductSVC.addcontentTypes,
            item_key: 'name',
            item_value: 'name',
            select_value: ''
          },
        ],
        searchBtn: {
          actions_left: [],
          actions_right: [

          ],
          actions_right_bottom: []
        }
      };

      $scope.super_user = $rootScope.affName === 'LG전자' && $rootScope.userProfileCheck('sol_ser', 'super_user', 'like');

      if ($scope.super_user) {
        search.searchBtn.actions_right.push(
          {
            label: '신규 템플릿 등록',
            btn_type: 'button',
            action: function () {
              $scope.addcontentRule();
            }
          }
        );
      }

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchBtn = angular.copy(search.searchBtn);

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function () {
          $scope.searchDo(true, true);
        },
        resetDo: function () {
          $scope.resetDo();
        },
        changeLen: function (count) {
          $scope.changeCount(count);
        }
      };

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: [],
        alignCenterColumns: [
          'widget', 'addcontent_no', 'addcontent_type', 'addcontent_name', 'addcontent_no', 'view_position', 'mdate', 'apply_cnt'
        ],
        alignRightColumns: [],
        defaultSortingColumns: ['addcontent_no'],
        notSortingColumns: [
          'widget', 'addcontent_type', 'addcontent_name', 'view_position', 'mdate', 'apply_cnt'
        ],
        notResizingColumns: ['addcontent_no'],
        notMovingColumns: [],
        notVisibleColumns: [],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/add-contents/list`,
          requestWillAction: function (data) {
            data.mas_yn = 1;

            return Object.assign(data, $scope.searchForm);
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotal;
            result.results = result.rows;

            return result.results;
          }
        },
        selectOptions: {
          multiSelect: false
        },
        modal: true,
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 80,
            template: function (row) {
              return `
                <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentRule(${row.addcontent_no})" >상세</button>
                <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentAR(${row.addcontent_no}, false)" ng-disabled="${!$scope.super_user}">해제</button>
                <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addcontentCopy(${row.addcontent_no})" ng-disabled="${!$scope.super_user}">복사</button>
                <button class="btn btn-default btn-xxs" ng-click="grid.appScope.addcontentDelete(${row.addcontent_no})" ng-disabled="${!$scope.super_user}">삭제</button>`;
            }
          },
          {
            key: 'addcontent_no',
            title: '템플릿 코드',
            width: 60
          },
          {
            key: 'addcontent_type',
            title: '템플릿 구분',
            width: 80
          },
          {
            key: 'addcontent_name',
            title: '템플릿명',
            width: 250
          },
          {
            key: 'view_position',
            title: '노출위치',
            width: 100,
            template: function (row) {
              let viewPosition;

              if (row.header_content && row.footer_content) {
                viewPosition = '전체';
              } else if (row.header_content) {
                viewPosition = 'Header';
              } else {
                viewPosition = 'Footer';
              }

              return viewPosition;
            }
          },
          {
            key: 'mdate',
            title: '최종수정일',
            width: 100
          },
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function () {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function (number) {
        $scope.searchData.showCount = number;
        $scope.grid.methods.length(number);
      };

      /**
       * 템플랏 등록/수정
       */
      $scope.addcontentRule = function (addcontentNo) {
        const size = 'lg-md',
              ctrl = 'MasAddcontentRuleCtrl',
              html = 'views/online/product/modals/mas_addcontent_rule.html',
              resolve = {
                // 로직변경으로 주석
                data: {
                  addcontentNo: addcontentNo
                }
              };

        const modal = commonSVC.openModal(size, resolve, ctrl, html, false);

        modal.result.then(function () {
          $scope.searchDo(true);
        });
      };

      /**
       * 템플릿 복사
       */
      $scope.addcontentCopy = function (addcontentNo) {
        commonSVC.showConfirm('템플릿 복사', '선택한 템플릿을 복사하시겠습니까?', function (confirm) {
          if (confirm) {
            const params = {
              no: addcontentNo
            };

            onlineProductModel.addcontentCopy(params)
              .then(function (res) {
                if (res.status === 200) {
                  commonSVC.showToaster('success', '성공', '복사에 성공했습니다.');
                  $scope.searchDo(true);
                } else {
                  commonSVC.showToaster('error', '실패', '복사에 실패했습니다.');
                }
              })
              .catch(function () {
                commonSVC.showToaster('error', '실패', '복사에 실패했습니다.');
              });
          }
        });
      };

      /**
       * 템플릿 삭제
       */
      $scope.addcontentDelete = function (addcontentNo) {
        const addcontent = $scope.grid.methods.filteredData({ addcontent_no: addcontentNo }),
              applyYn = addcontent.apply_cnt > 0 || addcontent.mas_apply_cnt > 0,
              prodApplayYn = addcontent.prod_mas_apply_cnt > 0;

        if (applyYn) {
          commonSVC.showMessage('템플릿 삭제', '선택한 템플릿으로 연동된 상품이 존재합니다. \n 상품관리에서 [해제] 후 삭제해주시기 바랍니다');

          return;
        }

        if (prodApplayYn) {
          commonSVC.showMessage('템플릿 삭제', '선택한 템플릿으로 연동된 SKU상품이 존재합니다.\n SKU상품 관리에서 [해제] 후 삭제해주시기 바랍니다');

          return;
        }

        let result;

        result = $q(function (resolve) {
          commonSVC.showConfirm('템플릿 삭제', '선택한 템플릿을 삭제하시겠습니까?', function (confirm) {
            if (confirm) {
              resolve(false);
            }
          });
        });

        result
          .then(function (editYn) {
            const params = {
              no: addcontentNo,
              editYn: editYn
            };

            return onlineProductModel.addcontentDelete(params)
              .then(function (res) {
                if (res.status === 200) {
                  commonSVC.showToaster('success', '성공', '삭제에 성공했습니다.');
                  $scope.searchDo(true);
                } else {
                  commonSVC.showToaster('error', '실패', '삭제에 실패했습니다.');
                }
              })
              .catch(function () {
                commonSVC.showToaster('error', '실패', '삭제에 실패했습니다.');
              });
          });
      };

      /**
       * 템플릿 적용
       */
      $scope.addcontentApply = function () {
        const addcontent = $scope.grid.methods.selectedData('all')[0];

        if (!addcontent) {
          commonSVC.showMessage('적용 실패', '선택된 템플릿이 없습니다.');

          return false;
        }

        const no = addcontent.addcontent_no;

        if ($scope.returnYn) {
          const data = {
            no: no,
            name: addcontent.addcontent_name,
            header_content: addcontent.header_content,
            footer_content: addcontent.footer_content,
            sub_off_avail_yn: addcontent.sub_off_avail_yn
          };

          $uibModalInstance.close(data);
        } else {
          $scope.addcontentAR(no, true);
        }
      };

      /**
       * 템플릿 적용 / 해제
       */
      $scope.addcontentAR = async function (addcontentNo, applyYn) {
        const addcontent = $scope.grid.methods.filteredData({ addcontent_no: addcontentNo });

        let select_prods = [];

        if (applyYn) {
          if (data.type === 'prod') {
            select_prods = selectProds.map(o => ({
              sol_no: o.sol_no,
              prod_no: o.prod_no,
            }));

            // 해제시에 적용상품 없을시 막음
            if (!applyYn && !addcontent.prod_mas_apply_cnt) {
              commonSVC.showMessage('해제 실패', '템플릿이 적용된 상품이 없습니다.', function () {
                $scope.searchDo(false);
              });

              return false;
            }
          } else {
            select_prods = selectProds.map(o => ({
              sol_no: o.sol_no,
              sale_no: o.sale_no,
              ol_shop_no: o.ol_shop_no,
              shop_cd: o.shop_cd,
              shop_id: o.shop_id,
              sale_status: o.sale_status,
            }));
          }

          // 해제시에 적용상품 없을시 막음
          if (!applyYn && !addcontent.mas_apply_cnt) {
            commonSVC.showMessage('해제 실패', '템플릿이 적용된 상품이 없습니다.', function () {
              $scope.searchDo(false);
            });

            return false;
          }

        }

        const params = {
          addcontent_no: addcontentNo,
          applyYn: applyYn,
          select_prods,
          type: data.type
        };

        const resolve = {
          data: {
            type: '',
            title: ''
          }
        };

        if (params.applyYn) {
          resolve.data.type = '적용';
          resolve.data.title = '선택한 템플릿을 적용하시겠습니까?';
        } else {
          resolve.data.type = '해제';
          resolve.data.title = `선택한 템플릿이 적용된 모든 ${data.type === 'prod' ? 'SKU상품' : '상품'}을 해제하시겠습니까?`;
        }

        const confirm = await commonSVC.showConfirm(resolve.data.type, resolve.data.title);

        if (!confirm) {
          return;

        }
        const apply_res = await onlineProductModel.masAddcontentApply(params);

        if (apply_res.status === 200) {
          if (!params.applyYn) {
            templateReleaseYn = true;
          }
          commonSVC.showToaster('success', '성공', `${resolve.data.type}에 성공했습니다.`);
        } else {
          commonSVC.showToaster('error', '실패', `${resolve.data.type}에 실패했습니다.`);
        }

        if (applyYn) {
          $uibModalInstance.close('refresh');
        } else {
          $scope.searchDo(false);
        }
      };

      $scope.close = function () {
        if (templateReleaseYn) {
          $uibModalInstance.close('refresh');
        } else {
          $uibModalInstance.dismiss();
        }
      };

    } catch (e) {
      console.log('Error init ProdAddcontentCtrl');
      console.log(e);
    }
  });
