/**
 * 2018-08-22 Daniel
 * 판매자관리코드 일괄 수정
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineProductEditCSaleCdCtrl', function ($scope, $rootScope, $uibModalInstance, $timeout, $compile, $q, settings, commonSVC, onlineProductSVC, shopAccountModel, onlineProductModel, gettextCatalog, data) {

    $scope.mall = data.result;
    $scope.data = angular.copy(onlineProductSVC.defaultVdata);

    /**
     * 판매자관리코드 변경사항이 있을때 중복체크 초기화.
     */
    $scope.$watch('data.c_sale_cd', function () {
      $scope.overlapResult = null;
    });

    /**
    * 판매자관리코드 중복체크
    */
    $scope.adminCodeCheck = async () => {
      $scope.overlapResult = null;
      if (!$scope.data.c_sale_cd) {
        $scope.alerts = { msg: '판매자관리코드를 입력해 주세요.' };
        $scope.overlapResult = 'blank';

        return false;
      }
      $scope.mall = $scope.mall.map((mallData) => {
        return {
          ...mallData,
          msg: '',
          flag: '',
          overlap: '',
          scanning: true,
          main_ol_shop_no: '',
        };
      });

      const mallInfo = $scope.mall.map((mallData) => {
        return {
          old_c_sale_cd: mallData.c_sale_cd,
          new_c_sale_cd: $scope.data.c_sale_cd,
          ol_shop_no: mallData.ol_shop_no,
          site_info: {
            shop_cd: mallData.shop_cd,
            shop_id: mallData.shop_id,
            std_ol_yn: mallData.std_ol_yn,
          },
          type: 'edit'
        };
      });
      const re = await onlineProductModel.checkDuplicateCSaleCd({ mallInfo });
      const malls = re.data.results;
      for (const mall of malls) {
        const { site_info: { shop_cd, shop_id }, isChangeMasterCode, isExistMasterCode, old_c_sale_cd, new_c_sale_cd, ol_shop_no } = mall;
        const mallIndex = $scope.mall.findIndex((mallData) => mallData.ol_shop_no === ol_shop_no);
        const duplicateMalls = malls.filter((data, index) => {
          return data.site_info.shop_cd === shop_cd && data.site_info.shop_id === shop_id;
        });

        if (duplicateMalls.length > 1) {
          $scope.mall[mallIndex] = {
            ...$scope.mall[mallIndex],
            overlap: 'n',
            scanning: false,
            msg: '2개 이상 동일한 쇼핑몰(계정) 상품을 선택했습니다.',
          };
        } else {
          let msg = '';
          let flag = '';

          const commonCondition = !isExistMasterCode && !old_c_sale_cd.includes(new_c_sale_cd);

          if (isChangeMasterCode && isExistMasterCode) {
            msg = '신규 판매자관리코드 입니다. 새로운 마스터상품으로 분리됩니다.';
            flag = 'new';
          } else if (isChangeMasterCode && commonCondition) {
            msg = '동일한 판매자관리코드의 상품으로 이동됩니다.';
          } else if (!isChangeMasterCode && commonCondition) {
            msg = '수정하려는 코드에 동일한 쇼핑몰(계정) 상품이 있습니다.';
          } else {
            msg = '수정하려는 코드와 동일한 판매자관리코드의 상품입니다.';
          }

          $scope.mall[mallIndex] = {
            ...$scope.mall[mallIndex],
            msg,
            scanning: false,
            flag: flag || '',
            new_c_sale_cd: $scope.data.c_sale_cd,
            overlap: isChangeMasterCode ? 'y' : 'n',
          };
        }
        $scope.overlapResult = 'y';
      }
      $scope.newCSaleCd = $scope.mall.filter((data) => data.flag === 'new');
      $timeout(() => {});
    };

    /**
     * 판매자관리코드 수정시 경고 팝업 출력
     * 2018-10-02 rony
     */
    $scope.checkNotice = async function () {
      $scope.alerts = {};
      const selected = $scope.mall;
      let txt = '';

      const notPossibleChange = selected.filter((d) => {
        return d.overlap === 'n';
      });
      const availableItem = selected.filter((d) => {
        return d.overlap === 'y';
      });
      const newYn = selected.find((v) => {
        return v.flag === 'new';
      });

      // 입력여부 체크
      if (!$scope.data.c_sale_cd) {
        commonSVC.showMessage('상품 수정 실패', '수정할 판매자관리코드는 필수 항목입니다.');
        $scope.overlapResult = 'blank';
        $scope.alerts = { msg: '필수 항목입니다.' };

        return false;
      }

      // 중복확인여부 체크
      if ($scope.data.c_sale_cd && $scope.overlapResult != 'y') {
        commonSVC.showMessage('상품 수정 실패', '판매자관리코드 중복확인을 해주세요.');
        $scope.overlapResult = 'n';
        $scope.alerts = { msg: '판매자관리코드 중복확인을 진행해 주세요.' };

        return false;
      }

      // 수정가능한 상품 있는지 체크
      if (availableItem.length <= 0) {
        commonSVC.showMessage('수정 가능한 상품이 없습니다.', '중복확인 결과를 확인해 주세요.');

        return false;
      } else {
        const esmOlList = availableItem.filter(shop => ['A001', 'A006'].includes(shop.shop_cd) && shop.ebay_shop_mas_no);

        if (esmOlList.length) {
          let chkEsmMatch = false;

          Object.values(_.groupBy(esmOlList, 'ebay_shop_mas_no')).forEach(olList => {
            if (olList.length !== 2) {
              chkEsmMatch = true;
            }
          });

          if (chkEsmMatch) {
            commonSVC.showMessage('실패', 'ESM마스터상품은 하나의 상품만 수정할 수 없습니다.');

            return false;
          }
        }
      }

      // 판매자관리코드 신규 생성 시 기준 마스터상품 선택여부 체크
      if (!$scope.data.main_ol_shop_no && newYn) {
        commonSVC.showMessageHtml('실패',
          `판매자관리코드 신규생성 시 마스터상품 정보로 사용할 상품을<br/>
          필수로 선택해야 합니다.`);

        return;
      }

      // 판매자관리코드 신규생성 시 기준 상품
      $scope.mall.map((row) => {
        return row.main_ol_shop_no = $scope.data.main_ol_shop_no;
      });

      let msg = '';
      msg = `<div class="sweet-alert-body">
        ◆ 상품관리<br>
        <ol>
        <li>해당 쇼핑몰 상품의 판매자관리코드만 수정합니다.</li>
        <li>판매자관리코드 수정 시 현재 마스터 상품 그룹에서 해당 쇼핑몰 상품은 분리되며,<br>
        입력하신 코드가 이미 존재하는 경우 해당 그룹으로 쇼핑몰 상품을 이동합니다.<br>
        (단, 이동할 상품 그룹에 동일한 쇼핑몰(계정)의 상품이 존재할 경우는 이동불가.)</li>
        <li>기존 코드에 연동된 상품이 더 이상 존재하지 않을 경우 마스터 상품이 삭제됩니다.<br>
        마스터 상품의 데이터를 유지하고자 하시는 경우에는 마스터 상품을 복사해 두신 후<br>
        작업을 진행해 주세요.<br>
        <li>본 상품에 매칭된 ‘쇼핑몰상품 카테고리’가 이동할 마스터 상품의 카테고리와 다른 경우<br>
        이동할 마스터상품에 매핑된 카테고리로 반영되어 카테고리가 변경됩니다.<br>
        카테고리에 따라 추천 옵션을 제공하는 쇼핑몰인 경우 유의해주세요.</li>
        <li>수정대기 → 상품전송 시 변경된 판매자관리코드로 판매중인 상품에 적용됩니다.<br>
        (판매자관리코드 미지원 쇼핑몰은 해당사항없음)</li>
        <li>내부 관리코드를 사용해야 하는 쇼핑몰로 상품전송 시 본 코드 변경으로<br>
        상품 정보 수정, 상태 변경 등의 작업이 실패될 가능성이 있습니다.</li><br>
        </ol>
        ◆ 주문관리<br>
        <ol>
        <li>주문데이터에 매핑된 판매자관리코드는 변경되지 않습니다.<br>
        (매핑 당시 판매자관리코드 유지)</li><br>
        </ol>
        ※ 부득이한 경우가 아니라면 판매자관리코드 변경은 시스템에 많은 혼선을 줄 수 있으므로 자제하여 주십시오.<br><br>
        모든 사항을 숙지해 주시고 변경을 원하시는 경우 [확인]을 눌러주세요.
        </div>`;

      const re1 = await commonSVC.showConfirmHtml('판매자관리코드 수정 시 주의사항', msg);
      if (!re1) {
        return;
      }
      if (notPossibleChange.length && availableItem.length) {
        const re = await commonSVC.showConfirmCustom({
          title: '판매자관리코드 수정이 불가한 상품이 있습니다.',
          text: '수정 불가한 상품은 중복확인 결과를 확인해 주세요',
          type: 'warning',
          confirmButtonText: '가능한 상품만 수정'
        });
        if (!re) {
          return;
        }
        // 필터링 적용시켜 수정 가능한 상품만 넘기도록 처리
        txt = `쇼핑몰 상품 <span class="text-primary">${availableItem.length}건</span>의 판매자관리코드를 수정합니다.`;
      } else {
        txt = `쇼핑몰 상품 <span class="text-primary">${selected.length}건</span>의 판매자관리코드를 변경합니다.`;
      }
      const confrim = await commonSVC.showConfirmHtml(txt, '');
      if (!confrim) {
        return;
      }

      try {
        const data = await onlineProductModel.editCSaleCd({ availableItem });

        if (data.data?.results === 'success') {
          commonSVC.showToaster('success', '성공', '상품정보가 수정 되었습니다.');
          $rootScope.$broadcast('reloadOlProductList');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '판매자관리코드 수정에 실패하였습니다.');
        }
      } catch (err) {
        if (err.data.error_code) {
          commonSVC.showToaster('error', '실패', err.data.messages[0]);
        }
      }

    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });