/**
 * 상품집계 트리
 */

'use strict';

angular.module('gmpApp')

  .directive('leftContentOlShopTree', function () {

    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentOlShopTree.html',
      link: function ($scope) {

        // 사용중인 쇼핑몰
        let channelListArr = [];

        $scope.selectedNodes = [];
        $scope.treedata = [];
        $scope.treeOptions = {
          nodeChildren: 'children',
          multiSelection: true,
        };
        /**
         * 집계 갱신용 데이터 받아오기
        */
        $scope.$on('olTreeUdateEvent', function(event, data) {
          if (data.act === 'olTreeUdate') {
            channelListArr = data.channelListArr;

            // 상품 집계 트리 초기화
            $scope.treedata = intTreeCount();
            defaultExpandedNodes();

            // 상품 집계 업데이트
            updateTreeData(data.onlineListResults);
            // 검색에서 선택한 쇼핑몰 계정 & 상태 선택
            searchTreeSelect();
          }
          // 검색태그에서 쇼핑몰 계정 or 상품상태 삭제시 트리 선택값 갱신함
          else if (data.act === 'searchTagsDel') {
            searchTreeSelect();
          }
        });

        // 트리 펼침여부 초기화
        $scope.expandedNodes = [];

        $scope.showSelected = function (sel, selected) {
          if (selected) {

            //트리 선택시 상세검색 선택박스 선택
            $scope.searchForm.shop_cd = sel.shop_cd || '';
            $scope.searchDetail[2].select_value = sel.shop_cd || '';

            $scope.searchForm.shop_id = sel.shop_id ? sel.shop_id == 'all' ? '' : sel.shop_id : '';
            $scope.searchDetail[3].select_value = sel.shop_id ? sel.shop_id == 'all' ? '' : sel.shop_id : '';
          }
          // 트리 전체상품 선택
          if (sel.label == '전체상품') {
            $scope.searchForm.sale_status = ['판매대기', '승인대기', '반려', '판매중', '종료대기', '수정대기', '판매중지', '일시품절'];
            $scope.selectedNodes = [$scope.treedata[0]];
          }
          // 전체상품 트리만 다중선택 가능
          else if (!$scope.selectedNodes.some(v => v?.shop_id || v?.shop_cd) && sel.status) {
            $scope.selectedNodes = $scope.selectedNodes.filter((v) => v.status);
            $scope.searchForm.sale_status = $scope.selectedNodes.filter((v) => v.status).map(v => v.status) || '';
          } else {
            // 쇼핑몰 트리 선택 시 다중선택 불가능
            $scope.selectedNodes = [sel];
            $scope.searchForm.sale_status = $scope.selectedNodes.filter((v) => v.status).map(v => v.status) || '';
          }
          $scope.searchDo(true);
        };

        /**
         * 2018-04-19 chris
         * 온라인 상품 집계 angular-tree-control로 변경
         */
        function intTreeCount() {
          const data = [];

          _.each(channelListArr, function (v) {
            // 쇼핑몰 확인
            const idx = _.findIndex(data, {
              shop_cd: v.shop_cd
            });

            if (idx >= 0) {
              const new_children_node = add_shopid_status_node(v);

              data[idx].children.push(new_children_node);
            } else {
              const new_node = add_shopid_node(v);

              data.push(new_node);
            }
          });

          return data;
        }

        /**
         * 2018-04-19 chris
         * 집계 트리 데이터 업데이트
         */
        function updateTreeData(onlineListResults) {
          const treedata = {};

          // 쇼핑몰별 카운트 정리
          _.each(onlineListResults, function (v) {
            if (!treedata[v.shop_cd]) {
              treedata[v.shop_cd] = {};
              treedata[v.shop_cd].total = 0;
            }
            if (!treedata[v.shop_cd][v.shop_id]) {
              treedata[v.shop_cd][v.shop_id] = {};
              treedata[v.shop_cd][v.shop_id].total = 0;
            }
            treedata[v.shop_cd].total += v.cnt;
            treedata[v.shop_cd][v.shop_id].total += v.cnt;
            treedata[v.shop_cd][v.shop_id][v.sale_status] = v.cnt;
            treedata[v.sale_status] = treedata[v.sale_status] >= 0 ? treedata[v.sale_status] + v.cnt : v.cnt;
          });

          // $scope.treedata 전체를 변경하는 경우 트리정보가 초기화되어 수량만 갱신해줌
          _.each($scope.treedata, function (v) {
            // 쇼핑몰 카운트
            v.count = 0;
            if (treedata[v.shop_cd]) {
              v.count = treedata[v.shop_cd].total;
              _.each(v.children, function (v2, k2) {
                v2.count = 0;
                if (treedata[v2.shop_cd][v2.shop_id] || v2.shop_id == 'all') {
                  if (v2.shop_id == 'all') {
                    v2.count = treedata[v2.shop_cd].total;
                  } else {
                    v2.count = treedata[v2.shop_cd][v2.shop_id].total;
                  }
                  _.each(v2.children, function (v3) {
                    v3.count = 0;
                    if (v3.shop_id != 'all') {
                      if (treedata[v3.shop_cd][v3.shop_id][v3.label]) {
                        v3.count = treedata[v3.shop_cd][v3.shop_id][v3.label];
                        //쇼핑몰별 전체 카운팅
                        const treeIdx = _.findIndex($scope.treedata, {
                          shop_cd: v3.shop_cd
                        });
                        const treeSecandIdx = _.findIndex($scope.treedata[treeIdx].children[k2].children, {
                          label: v3.label
                        });

                        $scope.treedata[treeIdx].children[0].children[treeSecandIdx].count += v3.count;
                      }
                    }
                  });
                }
              });
            }
          });
        }

        /**
         * 상세 검색시 트리 위치 선택
         */
        function searchTreeSelect() {
          let shopIdx = -1;
          let shopidIdx = -1;
          let stateIdx = -1;

          const isStatus = $scope.searchForm.sale_status.length;
          const pickedStatus = $scope.treedata[0].children.filter((v) => $scope.searchForm.sale_status.includes(v.status));

          //쇼핑몰 선택시
          if ($scope.searchForm.shop_cd) {
            shopIdx = _.findIndex($scope.treedata, {
              shop_cd: $scope.searchForm.shop_cd
            });

            //쇼핑몰계정 선택시
            if ($scope.searchForm.shop_id) {
              shopidIdx = _.findIndex($scope.treedata[shopIdx].children, {
                shop_id: $scope.searchForm.shop_id
              });

              //상태 선택시
              if (isStatus) {
                stateIdx = _.findIndex($scope.treedata[shopIdx].children[shopidIdx].children, {
                  status: $scope.searchForm.sale_status.join()
                });
                $scope.selectedNodes = [$scope.treedata[shopIdx].children[shopidIdx].children[stateIdx]]; //선택
                $scope.expandedNodes.push($scope.treedata[shopIdx].children[shopidIdx]); //트리 열기
              } else {
                $scope.selectedNodes = [$scope.treedata[shopIdx].children[shopidIdx]];
              }
            } else {
              if (isStatus) {
                stateIdx = _.findIndex($scope.treedata[shopIdx].children[0].children, {
                  status: $scope.searchForm.sale_status.join()
                });
                $scope.selectedNodes = [$scope.treedata[shopIdx].children[0].children[stateIdx]];
                $scope.expandedNodes.push($scope.treedata[shopIdx].children[0]);
              } else {
                $scope.selectedNodes = [$scope.treedata[shopIdx]];
              }
            }
          } else {
            if (isStatus) {
              stateIdx = _.findIndex($scope.treedata[0].children, {
                status: $scope.searchForm.sale_status.join()
              });
              $scope.selectedNodes = pickedStatus;
            } else {
              $scope.selectedNodes = $scope.treedata[0];
            }
            $scope.selectedNodes = pickedStatus;

            // 상품상태 전체선택 시 상품집계 쪽 '전체상품' 선택
            if (isStatus === 8 || isStatus === 0) {
              $scope.selectedNodes = [$scope.treedata[0]];
            }
          }
        }

        // 쇼핑몰별 계정 추가
        function add_shopid_node(v) {
          const new_node = {
            shop_cd: v.shop_cd,
            label: v.shop_name,
            count: 0,
            children: []
          };
          const new_all_node = add_shopid_all_node(v);
          const new_children_node = add_shopid_status_node(v);

          new_node.children.push(new_all_node);
          new_node.children.push(new_children_node);

          return new_node;
        }

        // 쇼핑몰 계정별 상품상태
        function add_shopid_status_node(v) {
          const new_node = {
            label: v.shop_id,
            shop_id: v.shop_id,
            shop_cd: v.shop_cd,
            count: 0,
            children: []
          };

          _.each(['판매대기', '판매중', '수정대기', '종료대기', '승인대기', '반려', '일시품절', '판매중지'], function (v2) {
            new_node.children.push({
              shop_id: v.shop_id,
              shop_cd: v.shop_cd,
              label: v2,
              status: v2,
              count: 0
            });
          });

          return new_node;
        }

        /**
         * 쇼핑몰별 전체 트리 추가
         */
        function add_shopid_all_node(v) {
          const new_node = {
            label: `${v.shop_name} 전체`,
            shop_id: 'all',
            shop_cd: v.shop_cd,
            count: 0,
            children: []
          };

          _.each(['판매대기', '판매중', '수정대기', '종료대기', '승인대기', '반려', '일시품절', '판매중지'], function (v2) {
            new_node.children.push({
              shop_id: 'all',
              shop_cd: v.shop_cd,
              label: v2,
              status: v2,
              count: 0
            });
          });

          return new_node;
        }

        function defaultExpandedNodes() {
          _.each($scope.treedata, function (v) {
            $scope.expandedNodes.push(v);
          });
        }

      }
    };
  }

  );
