/**
 * 포장 형태 설정
 */

'use strict';

angular.module('gmpApp')
  .controller('prodPackageBoxCtrl', function ($scope, $uibModalInstance, $timeout, settings, commonSVC, productModel) {

    $scope.allSelected = false;
    $scope.searchData = {
      all_checked: false,
      totalCount: 0,
      showCount: 100,
      selectCount: 0
    };

    $scope.pack = {};
    $scope.pack.methods = {};
    $scope.pack.options = {
      modal: true,
      gridHeight: 430,
      initPagingSize: 10,
      pinningColumns: [],
      alignCenterColumns: ['pkg_no', 'pkg_name', 'max_ea_cnt', 'linkage_sku_cnt'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: [],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: true,
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/pkgbox/list`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'pkg_no',
          title: 'No',
          width: 20,
        },
        {
          key: 'pkg_name',
          title: '포장 형태',
          width: 220,
        },
        {
          key: 'max_ea_cnt',
          title: '합포장 가능 최대 수량',
          width: 150,
        },
        {
          key: 'linkage_sku_cnt',
          title: '연동 SKU',
          width: 110,
          template: function (row) {
            const linkIcon = row.linkage_sku_cnt ? `<a ng-click="grid.appScope.linkageModalOpen(${row.pkg_no})">${row.linkage_sku_cnt}</a>` : '';

            return linkIcon;
          }
        }
      ]
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 포장 형태 등록 / 수정
     */
    $scope.updateProdPkgBox = function (type) {

      const resolve = {
        allPkgBoxList: function () {
          return productModel.prodPkgBoxList({ start: 0, length: 999999999 });
        },
      };
      const selectDatas = $scope.pack.methods.selectedData('all');

      if (type === 'edit' && !selectDatas.length) {
        commonSVC.showMessage('선택된 항목이 없습니다.');

        return false;
      }

      if (type === 'edit' && selectDatas.length > 1) {
        commonSVC.showMessage('한 개의 포장 형태만 수정 가능합니다.');

        return false;
      }

      resolve.data = {
        type: type,
        ...(type === 'edit' && { pkgBoxData: selectDatas[0] })
      };
      const modal = commonSVC.openModal('sg', resolve, 'editProdPkgBoxCtrl', 'views/prod/product/modals/edit_prod_package_box.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.pack.methods.reloadData(function () {}, true);
        }
      });
    };

    $scope.deleteProdPkgBox = function () {
      const selectDatas = $scope.pack.methods.selectedData(['pkg_no', 'box_no', 'linkage_sku_cnt']);

      if (!selectDatas.length) {
        commonSVC.showMessage('선택된 항목이 없습니다.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if (selectDatas.some(pack => pack.linkage_sku_cnt)) {
        commonSVC.showMessage('선택한 포장 형태는 SKU상품과 연동 해제 후 삭제할 수 있습니다.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      commonSVC.showConfirm('선택한 포장 형태를 삭제하시겠습니까?', '', async (isConfirm) => {

        if (!isConfirm) {
          $timeout(function () { $scope.waitBtnDisableFlag = false; });

          return;
        }

        // 삭제 처리
        try {
          await productModel.deleteProdPkgBox({ list: selectDatas.map(pkg => ({ pkg_no: pkg.pkg_no, box_no: pkg.box_no })) });

          commonSVC.showToaster('success', '성공', '포장 형태 삭제에 성공하였습니다.');
          $scope.pack.methods.reloadData(function () {}, true);
        } catch (err) {
          commonSVC.showToaster('error', '실패', '포장 형태 삭제에 실패하였습니다.');
        } finally {
          $timeout(function () { $scope.waitBtnDisableFlag = false; });
        }
      });
    };

    $scope.linkageModalOpen = function (pkg_no) {
      const resolve = {
        data: { pkg_no }
      };

      const modal = commonSVC.openModal('xxg', resolve, 'pkgBoxLinkageSkuCtrl', 'views/prod/product/modals/package_box_linkage_sku.html');

      modal.result.then(() => {
        $scope.pack.methods.reloadData(function () {}, true);
      });

    };
  });
