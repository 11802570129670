'use strict';

/**
 * 2017-03-12 william
 * 1300k
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B007-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B007categorySVC, errorSVC, commonSVC, settings, siteInfo) {
    const svc = B007categorySVC;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터
    $scope.Cate = {};                                   // 추가한 카테고리

    $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    /**
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateTypeArray = [];

      for (let i = 0; i <= index; i++) {
        cateTypeArray.push($scope.CateList[i]);
      }
      const cateCd = _.map(cateTypeArray, function(key) {
        return $scope.CateData[key];     // 선택 카테고리 코드
      }).join('>');

      cateSetting(index, cateCd);

      if (index === '2' || cateCd === 'noSub' || !cateCd) {
        return false;
      } // 마지막 분류이거나, 하위분류없을시

      $scope.loading = true;

      clearOpt(index);

      setCateSub(index, cateCd)
        .then(function() {
          $scope.loading = false;
        })
        .catch(function(err) {
          commonSVC.showMessage('로딩실패');
          $scope.loading = false;
        });
    };

    // 카테고리 추가
    $scope.addCate = function() {
      const cateCd = [];
      let valid = true;

      if (Object.keys($scope.Cate).length === 3) {
        setMainCate();
        commonSVC.showMessage('카테고리는 최대 3개까지 선택할수 있습니다');

        return false;
      }

      _.each($scope.CateList, function(v, k) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }

        if (!value) {
          valid = false;

          return false;
        }

        cateCd.push(value);
      });
      $scope.Cate[cateCd.join('>')] = $scope.CateData.category_names;

      setMainCate();
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      delete $scope.Cate[key];
      setMainCate();
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }
      $scope.CateData.category_names = nameArr.join('>');
    }

    // 하위 카테고리 가져오기
    function setCateSub(index, cateCd) {

      return requestPA('etc.scrap_category_low', { category: cateCd })
        .then(function(res) {
          const data = res.data.data;

          if (!data) {
            $scope.CateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
          } // 하위카테고리 없을시 출력
          else {
            $scope.CateOpt[(parseInt(index) + 1)] = data.items;
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 4; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    function detailCate() {
      let cnt = 1;

      // 카테고리 저장
      _.each($scope.Cate, function (v, k) {
        const cntType = cnt === 1 ? '1' : cnt;
        const kdata = k.split('>');
        const catecd = kdata.pop();

        $scope.CateData.detailData[`key_categoryCd${cntType}`] = catecd;
        cnt++;
      });
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      const cateKey = _.map(Object.keys($scope.Cate), function (item) {
        return item.split('^')[0];
      });
      const cateCnt = cateKey.length;

      if (cateCnt === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      detailCate();

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateKey.join('_');
      // 카테고리 리스트 저장
      $scope.CateData.detailData.Cate = $scope.Cate;
      for (let i = 0; i < cateCnt; i++) {
        $scope.CateData.detailData[`cate_Cd${i}`] = cateKey[i];
      }

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B007',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: $scope.CateData.detailData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              if ($scope.matchingData.isOnlineAdd === true) {
                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);
              } else {
                $scope.infoLoad();
              }
            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;
          $scope.userInfo.shop_pwd = $scope.userInfo.shop_pwd;

          return requestPA('etc.scrap_basic_info');
        })
        .then(function (res) {
          console.log(res);
          const data = res.data.data.items.category;

          if (!data) {
            commonSVC.showMessage('로딩실패');
            $scope.loading = false;

            return false;
          } else {
            $scope.CateOpt[0] = data;
            const param = {
              categoryId: $scope.matchingData.categoryId,
              shop_cd: shop_cd,
              shop_id: shop_id
            };

            // 수정확인
            categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
              if (state === 'success') {
                if (result.length > 0) { // 수정
                  const data = result[0].sol_cate_shop_add_info;

                  $scope.CateData.category_code = data.category_code;
                  $scope.CateData.category_names = data.category_names;
                  $scope.Cate = data.Cate;
                }
              }

              $scope.loading = false;
            });
          }
        })
        .catch(function (err) {
          console.log(err);
          commonSVC.showMessage('로딩실패');
        });

      $('#B007List').sortable();
    }

    /**
     * 대표카테고리 설정
     */
    function setMainCate() {
      let name = '';

      console.log($scope.CateData.category_code.split('_'));
      if (Object.keys($scope.Cate).length > 0) {
        name = $scope.Cate[Object.keys($scope.Cate)[0]];
      }
      if (Object.keys($scope.Cate).length > 1) {
        name += (` 외 ${Object.keys($scope.Cate).length - 1}개`);
      }

      $scope.CateData.category_names = name;
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const user = $scope.userInfo;
      const domain = siteInfo.B007.domain;

      const userInfo = {
        shop_id: user.shop_id || '',
        shop_pwd: user.shop_pwd || '',
        etc1: user.etc1 || '',
      };

      console.log('userInfo', userInfo);

      return commonSVC.requestPA(userInfo, siteInfo.B007.domain, action, addData, commonModel, shopAccountModel);
    }

    init();
  });
