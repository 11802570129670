/*
 **  보안관리 모델
 *   2016-10-11 김민영
 */

'use strict';

angular.module('gmpApp')
  .factory('securityModel', function ($http, $q, settings, commonSVC) {
    return {
      /**
        * sms 발신번호 리스트 조회
        * @returns {*}
        */
      getSmsAuthList: function () {
        const url = `${settings.pa20ApiUrl}/app/settings/sms-auth`;

        return commonSVC.sendUrl('GET', url);
      },

      /**
        * sms 발신번호 삭제
        * @param number DB번호
        * @returns {*}
        */
      smsAuthDelete: function (number, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/sms-auth`;
        const params = { number: number };

        return commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          next(state, data);
        });
      },

      /**
        * sms 발신번호 리스트 순번 업데이트
        */
      editSmsAuthSeq: function (tel_list) {
        const url = `${settings.pa20ApiUrl}/app/settings/sms-auth`;
        const params = { tel_list: tel_list };

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
        * sms 휴대폰 인증번호 발송
        * @param tel 연락처
        * @returns {*}
        */
      smsAuthGet: function (tel) {
        const url = `${settings.pa20ApiUrl}/app/settings/send-sms-auth-key`;
        const params = { tel: tel };

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
        * sms 휴대폰 인증번호 확인
        * @param tel 연락처
        * @param auth_key 인증번호
        * @returns {*}
        */
      smsAuthCheck: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/sms-auth-check`;

        return commonSVC.sendUrl('POST', url, param, function (state, data) {
          next(state, data);
        });
      },

      /**
        * sms 서류심사 정보 등록
        * @returns {*}
        */
      smsAuthAdd: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/settings/sms-auth`;

        const param = {
          params: params
        };

        _.forEach(params, function(obj, idx) {
          param[`file${idx}_1`] = obj.auth_file1;
          param[`file${idx}_2`] = obj.auth_file2;
        });

        return commonSVC.sendUrlFile('POST', url, param, function (state, data) {
          next(state, data);
        });
      },

      /**
        * 이메일 인증 함수(SES)
        * @param email 이메일
        */
      auth: function (email, c_charge_no) {
        const url = `${settings.pa20ApiUrl}/app/email/auth`,
              params = { email, c_charge_no };

        return commonSVC.sendUrl('POST', url, params);
      },

      checkAuth: function(email) {
        const url = `${settings.pa20ApiUrl}/app/email/check/auth`;

        return commonSVC.sendUrl('POST', url, { email });
      },

      deleteVerifyEmail: function(c_charge_no, email) {
        const url = `${settings.pa20ApiUrl}/app/email/delete`,
              params = { email, c_charge_no };

        return commonSVC.sendUrl('POST', url, params);
      },

      sendEmail: function(param) {
        const url = `${settings.pa20ApiUrl}/app/email/send`,
              params = param;

        return commonSVC.sendUrl('POST', url, params);
      }
    };

  });
