'use strict';

angular.module('gmpApp')
  .factory('simpleGuideSVC', function () {
    return {
      // pa5 쇼핑몰 etc 명칭
      guideLink: [
        { name: '타 쇼핑몰로 상품 전송', link: 'G2QOGoQjWiI' },
        { name: '쇼핑몰 상품 가져오기', link: 'DmS684zIVcM' },
        { name: '상품 수정 하기', link: 'JydPXCWfVwE' },
        { name: '쇼핑몰 계정 정보 등록', link: '_FI03OTk1vQ' },
        { name: '주문 관리', link: 'sRuXrBMKuiY' },
        { name: '문의 관리', link: 'UJgYGdZOVyg' }
      ]
    };
  });
