'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A004-Ctrl', function ($scope, $rootScope, $timeout, siteInfo, shopAccountModel, onlineProductModel, commonModel, commonSVC, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크
    $scope.prodGosiType = []; // 상품고시유형 리스트
    $scope.prodGosi = []; // 상품고시항목 리스트
    $scope.safeInfo = []; // 제품 안전인증정보 리스트
    const lastCateCd = _.last(data.data.cate_cd.split('_'));
    let prodGosiType = '';
    // disabled된 항목 있는지 여부
    let hasDisabled = false;

    $timeout(() => {
      hasDisabled = !!$('div[ng-include] input[ng-model][ng-disabled], div[ng-include] select[ng-model][ng-disabled], div[ng-include] button[ng-model][ng-disabled]')
        .filter((idx, selector) => $(selector).attr('disabled') === 'disabled').length;
    }, 500);

    $scope.pa5_job_data = {
      get_prodNotiTypeList: {
        load: false,
        data: []
      },
      get_prodNotiItemList: {
        load: false,
        data: []
      },
      get_qcCertCate: {
        load: false,
        data: []
      }
    };

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, push_data) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (!re.data.has_error) {
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }

          return re.data.results[0].data;
        } else {
          commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.getProdNotiTypeList = async () => {
      $scope.prodGosiType = await run_pa5_jobs('get_prodNotiTypeList', '상품고시유형', { code: lastCateCd });
      prodGosiType = $scope.prodGosiType[0].id;
    };

    $scope.getProdNotiItemList = async (prodGosiType2) => {
      $scope.prodGosi = await run_pa5_jobs('get_prodNotiItemList', '상품고시항목', { code: prodGosiType2 });
    };

    $scope.getQcCertCate = async () => {
      $scope.safeInfo = await run_pa5_jobs('get_qcCertCate', '제품 안전인증정보', { code: lastCateCd });
    };

    const init = async () => {
      try {
        await $scope.getProdNotiTypeList();
      } catch (e) {
        commonSVC.showMessage('실패', '상품고시유형 로딩에 실패하였습니다.');
      }
      try {
        await $scope.getProdNotiItemList(prodGosiType);
      } catch (e) {
        commonSVC.showMessage('실패', '상품고시항목 로딩에 실패하였습니다.');
      }
      try {
        await $scope.getQcCertCate();
      } catch (e) {
        commonSVC.showMessage('실패', '제품 안전인증정보 로딩에 실패하였습니다.');
      }
    };

    init();

    $scope.ok = async type => {
      if (
        $scope.prodGosi.some((des) => $scope.row.set_info.key_prodAuthinfoUseYn === 'Y' && !$scope.row.set_info[`b64_prod_authinfo_${des.id}`])
      ) {
        commonSVC.showMessage('실패', '상품고시항목 필수값을 입력해주세요.');

        return;
      }

      if (
        $scope.row.set_info.key_delivery_prod_name_yn === 'Y' && !$scope.row.set_info.key_delivery_prod_name
      ) {
        commonSVC.showMessage('실패', '노출상품명을 입력해주세요.');

        return;
      }

      // 고시 정보 업데이트
      const filteredSetInfo = {};

      for (const key in $scope.row.set_info) {
        if (key.startsWith('key_') || key.match(/^b64_prod_authinfo_(\d+)$/) || key === 'template_name') { // 템플릿명은 유지되어야함
          // 'key_'로 시작하거나 'b64_prod_authinfo_'로 시작하면서 숫자가 일치하는 경우 유지
          if (!key.startsWith('b64_prod_authinfo_') || $scope.prodGosi.some(item => item.id === key.split('_').pop())) {
            filteredSetInfo[key] = $scope.row.set_info[key];
          }
        }
      }
      $scope.row.set_info = filteredSetInfo;

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    if (!$scope.row.set_info.key_safetycertUseYn) {
      $scope.row.set_info.key_safetycertUseYn = 'N';
    }

    if (!$scope.row.set_info.key_prodAuthinfoUseYn) {
      $scope.row.set_info.key_prodAuthinfoUseYn = 'N';
    }

    if (!$scope.row.set_info.key_secondaryContactName) {
      $scope.row.set_info.key_secondaryContactName = '';
    }

    if (!$scope.row.set_info.key_personalInformationProvisionItem) {
      $scope.row.set_info.key_personalInformationProvisionItem = '';
    }

    if (!$scope.row.set_info.key_informationPeriodBasis || $scope.row.set_info.key_informationPeriodBasis === '년') {
      $scope.row.set_info.key_informationPeriodBasis = '0';
    } else if ($scope.row.set_info.key_informationPeriodBasis === '개월') {
      $scope.row.set_info.key_informationPeriodBasis = '1';
    }

    if (!$scope.row.set_info.key_rentalOptionStatus) {
      $scope.row.set_info.key_rentalOptionStatus = 'N';
    }

    if (!$scope.row.set_info.key_rentalTypeCode) {
      $scope.row.set_info.key_rentalTypeCode = '0';
    }

    /**
     * 제품 안전인증정보 변경
     */
    $scope.changeSafetyInfo = function(type) {
      if ($scope.row.set_info.key_safetycert_danger == false &&
        $scope.row.set_info.key_safetycert_child == false &&
        $scope.row.set_info.key_safetycert_electric == false &&
        $scope.row.set_info.key_safetycert_etc == false) {
        commonSVC.showToaster('error', '', '1개이상 필수로 입력해야 합니다.');

        switch (type) {
          case 'danger': $scope.row.set_info.key_safetycert_danger = true; break;
          case 'child': $scope.row.set_info.key_safetycert_child = true; break;
          case 'electric': $scope.row.set_info.key_safetycert_electric = true; break;
          case 'etc': $scope.row.set_info.key_safetycert_etc = true; break;
        }
      }

    };

    $scope.batchApp = (key, type) => {
      $scope.prodGosi.forEach((des) => {
        if ((type === 'all') && !$scope.row.set_info[`${key}${des.id}`]) {
          $scope.row.set_info[`${key}${des.id}`] = '상세설명 참조';
        }
      });
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // 구성상품명 개수 맞춤
      // $scope.cpProdList = Array.from({ length: Object.keys($scope.row.set_info).filter(key => key.startsWith('key_cp_prod_name')).length || 1 }, () => ({}));

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        if (hasDisabled) {
          $scope.row.set_info_template_no = '';
          commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

          return false;
        }

        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType,
          hasDisabled
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
          hasDisabled
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  }
  );
