'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A100-Ctrl', function ($scope, $timeout, $ocLazyLoad, commonSVC, A100categorySVC, categoryModel, commonModel) {

    $scope.cert_type = angular.copy(A100categorySVC.cert_type);
    $scope.category_code = '';
    $scope.cateList = '';
    $scope.require_check = {};
    $scope.display_check = {};
    $scope.check = {};
    $scope.CateData = angular.copy(A100categorySVC.category_data);
    $scope.CateData.detailData = {};
    $scope.loading = false;

    $scope.pa5_job_data = {
      get_md: {
        load: false,
        data: []
      },
      get_md_product: {
        load: false,
        data: []
      },
      get_categories: {
        load: false,
        data: []
      },
    };

    let save_flag1 = '';  //아이템형 전시카테고리 플래그
    let selcetrowFlag = ''; //카테고리 변경 플래그

    init();

    async function run_pa5_jobs (job_name, job_kor_name, push_data, temp_saver) {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    }

    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.detailData = addInfo;

      if (!$scope.CateData.detailData.key_md_gsgr_no && $scope.CateData.detailData.key_MDCode_prod) {
        $scope.CateData.detailData.key_md_gsgr_no = $scope.CateData.detailData.key_MDCode_prod;
      }

      await $scope.mdLselect(true);
      await $scope.mdMselect();
      await $scope.cateSelect();
      await $scope.cateRegist('init');
    };

    //초기화
    async function init() {
      $scope.loading = true;

      try {
        await run_pa5_jobs('get_md', 'MD 조회', {}, (data) => {
          $scope.mdName = data;
          if ($scope.matchingData.shop_cate_no_add_info) {
            if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
              matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
            } else {
              matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
            }
          } else if (!$scope.matchingData.sol_cate_shop_add_info) {
            matchDetailDataLoad(angular.copy(A100categorySVC.category_data));
          } else {
            matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
          }
        });
      } catch (e) {
        commonSVC.showMessage('데이터 로드 실패');
      }

      $('#spinner').addClass('hidden');
    }

    //md명 셀렉트
    $scope.mdLselect = async function (init) {
      if ($scope.CateData.detailData.key_mdid) {
        $('#spinner').removeClass('hidden');

        angular.forEach($scope.mdName, function (md) {
          if (md.code === $scope.CateData.detailData.key_mdid) {
            $scope.CateData.detailData.key_EventSellFee = md.event_sell_fee; //행사마진율
            $scope.CateData.detailData.key_NormalSellFee = md.normal_sell_fee; //정상마진율
          }
        });

        if (!init) {
          $scope.CateData.detailData.SuperGroupName = null;
          $scope.CateData.detailData.key_md_gsgr_no = null;
        }

        return await run_pa5_jobs('get_md_product', 'MD 상품군 조회', { md_id: $scope.CateData.detailData.key_mdid }, (data) => {
          $scope.GroupName = data;
          $('#spinner').addClass('hidden');
        });
      }
    };

    //md 상품군 셀렉트
    $scope.mdMselect = async function () {
      if ($scope.CateData.detailData.SuperGroupName) {
        $('#spinner').removeClass('hidden');

        return await run_pa5_jobs('get_md_product', 'MD 상품군 조회', { md_id: $scope.CateData.detailData.key_mdid }, (data) => {
          $scope.md_gsgr_no = data.find(superGroup => superGroup.SuperGroupName === $scope.CateData.detailData.SuperGroupName).SubGroupInfoList.SubGroupInfo;
          $('#spinner').addClass('hidden');
        });
      }
    };

    //전시카테고리 조회
    $scope.cateSelect = async function () {
      if (!$scope.CateData.detailData.key_md_gsgr_no) {
        return false;
      }

      $('#spinner').removeClass('hidden');

      if ($scope.CateData.sol_cate_shop_add_info) {
        $scope.CateData.sol_cate_shop_add_info.key_md_gsgr_no = null;
      }

      return await run_pa5_jobs('get_categories', '전시 카테고리 조회', { md_gsgr_no: $scope.CateData.detailData.key_md_gsgr_no }, async (data) => {
        $scope.cateList = data;
        $('#spinner').addClass('hidden');

        const displayList = [];

        await $timeout(() => {
          angular.forEach($scope.cateList, function (value, key) {
            //아이템형 전시 카테고리
            if (value.etc.disp_tp_cd == 10 && value.name == $scope.CateData.detailData.key_cv0) {
              $scope.require_check[key] = true;
              save_flag1 = true;
            }

            //브랜드형 전시 카테고리 리스트 생성
            if ([10, 11, 12].includes(value.etc.disp_tp_cd)) {
              displayList.push(value);
            }
          });
        });

        await $timeout(() => {
          //체크박스 체크
          displayList.forEach((value, idx) => {
            //브랜드형 전시 카테고리
            if (value.name == $scope.CateData.detailData.key_cv1
                || value.name == $scope.CateData.detailData.key_cv2
                || value.name == $scope.CateData.detailData.key_cv3
                || value.name == $scope.CateData.detailData.key_cv4
                || value.name == $scope.CateData.detailData.key_cv5) {

              $scope.display_check[idx] = true;
            } else {
              $scope.display_check[idx] = false;
            }
          });
        });
      });
    };

    //표준, 전시카테고리 선택
    $scope.selcetrow = function (type, index) {
      selcetrowFlag = true;   //카테고리변경 플래그
      if (type == 'require') {
        $scope.require_check = {};
        $scope.require_check[index] = true;
      } else if (type == 'display') {
        if ($(`#display_check_${index}`).prop('checked') == false) {
          $(`#display_check_${index}`).prop('checked', true);
          $scope.display_check[index] = true;
        } else {
          $scope.display_check[index] = false;
          $(`#display_check_${index}`).prop('checked', false);
          $scope.display_check[index] = false;
        }
      }
    };

    //카테고리 선택 변경시
    $scope.cateChange = function () {
      selcetrowFlag = true;   //카테고리변경 플래그
    };

    //전시카테고리 등록
    $scope.cateRegist = async (type) => {
      const test = [];

      if (type == 'init') {
        //선택한 카테고리 보여주기
        $scope.cateviewFlag = true;
        selcetrowFlag = false;

        angular.forEach($scope.require_check, function (k, v) {
          if (k == true) {
            $scope.CateData.detailData.key_ct0 = $scope.cateList[v].name; //라디오
            $scope.CateData.detailData.radio = $scope.cateList[v].code; //라디오
            save_flag1 = true;
          }
        });

        const cateList = [];

        angular.forEach($scope.display_check, function (k, v) {
          if (k == true) {
            angular.forEach($scope.cateList, function (value, key) {
              if ([10, 11, 12].includes(value.etc.disp_tp_cd)) {
                test.push($scope.cateList[key]);
              }
            });
            cateList.push($scope.cateList[v].name);
          }
        });

        if (cateList.length > 5) {
          alert('전시카테고리는 5개까지만 등록 가능합니다.');

          return false;
        } else {
          $scope.CateData.detailData.select = [];

          let count = 1;

          await $timeout(() => {
            angular.forEach($scope.display_check, function (k, v) {
              $scope.CateData.detailData[`key_ct${count}`] = '';

              if (k == true) {
                $scope.CateData.detailData[`key_ct${count}`] = test[v].name; //셀렉트
                $scope.CateData.detailData.select.push(test[v].code); //셀렉트
                count++;
              }
            });
          });
        }
      } else {
        if (!Object.keys($scope.require_check).length) {
          commonSVC.showToaster('error', '실패', '아이템형 전시 카테고리를 선택해주세요');

          return false;
        }

        //선택한 카테고리 보여주기
        $scope.cateviewFlag = true;
        selcetrowFlag = false;

        angular.forEach($scope.require_check, function (k, v) {
          if (k == true) {
            $scope.CateData.detailData.key_ct0 = $scope.cateList[v].name; //라디오
            // $scope.CateData.detailData.key_ct0 = `${$scope.cateList[v].DispLrgNm} > ${$scope.cateList[v].DispMidNm} > ${$scope.cateList[v].DispSmlNm} > ${$scope.cateList[v].DispNm}`;
            $scope.CateData.detailData.key_cv0 = $scope.cateList[v].code;
            $scope.CateData.detailData.radio = $scope.cateList[v].code; //라디오

            $scope.CateData.detailData.key_disp_no = $scope.cateList[v].code;

            save_flag1 = true;
          }
        });

        const cateList = [];

        angular.forEach($scope.display_check, function (k, v) {
          if (k == true) {

            angular.forEach($scope.cateList, function (value, key) {
              if ([10, 11, 12].includes(value.etc.disp_tp_cd)) {
                test.push($scope.cateList[key]);
              }
            });

            cateList.push($scope.cateList[v].name);
          }
        });

        if (cateList.length > 5) {
          alert('전시카테고리는 5개까지만 등록 가능합니다.');

          return false;
        } else {
          $scope.CateData.detailData.select = [];

          for (let i = 0; i < 5; i++) {
            $scope.CateData.detailData[`key_cv${i + 1}`] = '';
            $scope.CateData.detailData[`key_ct${i + 1}`] = '';
            $scope.CateData.detailData[`key_disp_b_no${i}`] = '';
          }

          let count = 1;

          $timeout(() => {
            angular.forEach($scope.display_check, function (k, v) {
              if (k == true) {
                $scope.CateData.detailData[`key_ct${count}`] = test[v].name; //셀렉트
                // $scope.CateData.detailData[`key_ct${count}`] = `${test[v].DispLrgNm} > ${test[v].DispMidNm} > ${test[v].DispSmlNm} > ${test[v].DispNm}`; //셀렉트
                $scope.CateData.detailData.select.push(test[v].code); //셀렉트
                $scope.CateData.detailData[`key_cv${count}`] = test[v].code; //셀렉트

                $scope.CateData.detailData[`key_disp_b_no${count - 1}`] = test[v].code; //셀렉트

                count++;
              }
            });
          }, 300);
        }
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {
      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if ($scope.CateData.detailData.key_cv0 == $scope.CateData.detailData.key_cv1 || $scope.CateData.detailData.key_cv0 == $scope.CateData.detailData.key_cv2 || $scope.CateData.detailData.key_cv0 == $scope.CateData.detailData.key_cv3 || $scope.CateData.detailData.key_cv0 == $scope.CateData.detailData.key_cv4) {
        commonSVC.showMessage('실패', '필수전시카테고리와 추가전시카테고리에 동일한 카테고리 중복 설정이 불가능합니다.');

        return false;
      }

      if (selcetrowFlag == true) {
        commonSVC.showMessage('실패', '전시카테고리가 변경되었습니다. 전시카테고리 등록을 해주세요.');

        return false;
      }

      if (save_flag1 != true) {
        commonSVC.showMessage('실패', '전시카테고리를 등록해주세요');

        return false;
      }

      if ($scope.CateData.detailData.key_ct0 == '') {
        commonSVC.showMessage('실패', '전시카테고리를 등록해주세요');

        return false;
      }

      //key_mdName 세팅
      angular.forEach($scope.mdName, function (k) {
        if (k.code == $scope.CateData.detailData.key_mdid) {
          $scope.CateData.detailData.key_mdnm = k.code;
        }
      });

      //key_select_MD 세팅
      $scope.CateData.detailData.key_select_MD = $scope.CateData.detailData.key_mdid;
      $scope.CateData.detailData.category_names = $scope.CateData.detailData.key_ct0;

      //전시카테고리
      $scope.CateData.detailData.disp = [];

      angular.forEach($scope.CateData.detailData, function (k, v) {
        if (v.indexOf('key_ct') != -1) {
          if (v == 'key_ct1' && $scope.CateData.detailData.key_ct1 != '') {
            const value = {
              name: $scope.CateData.detailData.key_ct1,
              code: $scope.CateData.detailData.select[0]
            };

            $scope.CateData.detailData.disp.push(value);
          } else if (v == 'key_ct2' && $scope.CateData.detailData.key_ct2 != '') {
            const value = {
              name: $scope.CateData.detailData.key_ct2,
              code: $scope.CateData.detailData.select[1]
            };

            $scope.CateData.detailData.disp.push(value);
          } else if (v == 'key_ct3' && $scope.CateData.detailData.key_ct3 != '') {
            const value = {
              name: $scope.CateData.detailData.key_ct3,
              code: $scope.CateData.detailData.select[2]
            };

            $scope.CateData.detailData.disp.push(value);
          } else if (v == 'key_ct4' && $scope.CateData.detailData.key_ct4 != '') {
            const value = {
              name: $scope.CateData.detailData.key_ct4,
              code: $scope.CateData.detailData.select[3]
            };

            $scope.CateData.detailData.disp.push(value);
          } else if (v == 'key_ct5' && $scope.CateData.detailData.key_ct5 != '') {
            const value = {
              name: $scope.CateData.detailData.key_ct5,
              code: $scope.CateData.detailData.select[4]
            };

            $scope.CateData.detailData.disp.push(value);
          }
        }
      });

      // MD 상품군명
      $scope.CateData.detailData.key_md_gsgr_nm = `${$scope.CateData.detailData.SuperGroupName}-${$('#key_md_gsgr_no option:selected').text()}`;
      $scope.CateData.detailData.key_mdInfo = $scope.CateData.detailData.key_mdid;
      $scope.CateData.detailData.key_md_gsgr = $scope.CateData.detailData.key_md_gsgr_no;

      if ($scope.form1.$valid) {

        const param = {
          site_code: 'A100',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.radio,
          category_codes: 'test',
          category_names: $scope.CateData.detailData.key_ct0,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',    //국내/해외
          type: 0
        };

        /**
         * 2018-03-09 Roy
         * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
         */

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    $scope.filterFunction = function (cds) {
      return function (item) {
        return _.includes(cds, item.etc.disp_tp_cd);
      };
    };

  });
