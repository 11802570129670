'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentOrderConfirmCtrl', function ($scope, $uibModalInstance, data, commonSVC) {
    const send_data = [];

    _.map(data.list, function(value, key) {
      if (value == true) {
        send_data.push({ number: key });
      }
    });

    $scope.sync_yn = false;
    $scope.all_count = data.all_count;
    $scope.list_count = data.list_count;

    $scope.ok = function () {
      if ($scope.count_type == undefined) {
        commonSVC.showMessage('발주확인 주문을 선택해 주세요.');

        return false;
      }
      if ($scope.count_type == true && $scope.new_count == 0) {
        commonSVC.showMessage('선택한 발주확인 주문건이 존재하지 않습니다.');

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });