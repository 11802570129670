/**
 * Created by ally on 2017. 6. 22..
 */
'use strict';

angular.module('gmpApp')
  .directive('convertToNumber', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function(val) {
          return parseInt(val, 10);
        });
        ngModel.$formatters.push(function(val) {
          return `${val}`;
        });
      }
    };
  });
