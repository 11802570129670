/**
 * 공지사항 상세보기 컨트롤러
 * 2017-10-25 Pada
 */

'use strict';

angular.module('gmpApp')
  .controller('AddNotiController', function ($scope, $location, $anchorScroll, $uibModalInstance, data, subUserList, noticeModel, commonSVC) {
    $scope.hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    $scope.mins = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];

    $scope.sub_user_list = subUserList.data;
    $scope.select_user_list = [];
    $scope.type = data.type;
    $scope.show = false;
    $scope.preview_data = {};

    $scope.notice_data = {
      noti_type: '안내',
      top_expose_yn: '0',
      popup_yn: false,
      s_date: moment().format('YYYY-MM-DD'),
      s_hour: '00',
      s_min: '00',
      e_date: moment().format('YYYY-MM-DD'),
      e_hour: '23',
      e_min: '59',

      particular_company_yn: '0',
      file_array: []
    };

    if (data.type === 'edit') {
      $scope.notice_data = {
        ...data,
        noti_type: data.noti_type,
        top_expose_yn: data.top_expose_yn + '',
        expose_yn: data.expose_yn == '1',
        popup_yn: data.popup_yn == '1',
        particular_company_yn: '0',

        s_date: moment(data.sdate).format('YYYY-MM-DD'),
        s_hour: moment(data.sdate).format('HH'),
        s_min: moment(data.sdate).format('mm'),

        e_date: moment(data.edate).format('YYYY-MM-DD'),
        e_hour: moment(data.edate).format('HH'),
        e_min: moment(data.edate).format('mm'),

        file_array: []
      };

      if ($scope.notice_data.popup_yn) {
        $scope.notice_data.popup_s_date = moment(data.popup_sdate).format('YYYY-MM-DD');
        $scope.notice_data.popup_s_hour = moment(data.popup_sdate).format('HH');
        $scope.notice_data.popup_s_min = moment(data.popup_sdate).format('mm');
        $scope.notice_data.popup_e_date = moment(data.popup_edate).format('YYYY-MM-DD');
        $scope.notice_data.popup_e_hour = moment(data.popup_edate).format('HH');
        $scope.notice_data.popup_e_min = moment(data.popup_edate).format('mm');
      }

      if (data.file) {
        $scope.notice_data.file_array = data.file.split('#').map(v => (JSON.parse(v)));
      }

      if ($scope.sub_user_list.length != $scope.notice_data.user.length) {
        $scope.notice_data.particular_company_yn = '1';
        $scope.select_user_list = $scope.notice_data.user;

        const sol_list = $scope.notice_data.user.map(o => o.sol_no);

        setTimeout(() => {
          $('.reg-sub-user-select').val(sol_list).trigger('change');
        });
      }
    }

    $scope.$watch('$viewContentLoaded', function () {
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: '대상을 선택해주세요.',
        closeOnSelect: false
      });

      // 하위첩체
      $('.reg-sub-user-select').on('change.select2', function () {
        let va = $(this).val() || [];
        const user_list = [];

        if (va[0] == 'all') {
          $scope.channelSelectAll = true;
          va = [];

          $scope.sub_user_list.forEach(o => {
            va.push(o.sol_no);
          });

          $scope.select_user_list = [...$scope.sub_user_list];

          $(this).val(va).trigger('change');
          $(this).select2('close');

          return;
        }

        if (va[0] == 'del') {
          $scope.channelSelectAll = false;
          $scope.select_user_list = [];

          $('.reg-sub-user-select').val([]).trigger('change');
          $(this).select2('close');

          return;
        }

        _.forEach(va, (v) => {
          const sol_no = parseInt(v);
          const find = $scope.sub_user_list.find(o => o.sol_no === sol_no);

          if (find) {
            user_list.push(find);
          }
        });

        $scope.select_user_list = user_list;

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }

      });
    });

    /**
     * 파일 첨부
     */
    $scope.fileAdd = (value) => {
      const max_fields = 5; // 최대 업로드 가능 개수
      const file_len = $scope.notice_data.file_array.length;

      if (value && file_len < max_fields) {
        $scope.notice_data.file_array.push(value);
      }
    };

    $scope.subtractElement = function (index) {
      $scope.notice_data.file_array.splice(index, 1);
    };

    $scope.changePopup = () => {
      if ($scope.notice_data.popup_yn) {
        $scope.notice_data.popup_s_date = moment().format('YYYY-MM-DD');
        $scope.notice_data.popup_s_hour = '00';
        $scope.notice_data.popup_s_min = '00';
        $scope.notice_data.popup_e_date = moment().format('YYYY-MM-DD');
        $scope.notice_data.popup_e_hour = '23';
        $scope.notice_data.popup_e_min = '59';
      }
    };

    $scope.save = async () => {

      if (!$scope.notice_data.content) {

        commonSVC.showMessage('실패', '공지내용을 입룍하세요.');

        return;
      }

      const params = {
        ...$scope.notice_data,
        sdate: moment(new Date(`${$scope.notice_data.s_date} ${$scope.notice_data.s_hour}:${$scope.notice_data.s_min}:00`)).format('YYYY-MM-DD HH:mm:ss'),
        edate: moment(new Date(`${$scope.notice_data.e_date} ${$scope.notice_data.e_hour}:${$scope.notice_data.e_min}:00`)).format('YYYY-MM-DD HH:mm:ss'),
        sol_list: $scope.select_user_list.map(o => o.sol_no),
        publish_type: 'sub_sol',
        popup_view_area: 'app',
        expose_yn: $scope.notice_data.expose_yn ? '1' : '0',
        popup_yn: $scope.notice_data.popup_yn ? '1' : '0',
      };

      $scope.notice_data.file_array.forEach((item, index) => {
        params[`file_${index}`] = item;
      });

      if ($scope.notice_data.popup_yn) {
        params.popup_sdate = moment(new Date(`${$scope.notice_data.popup_s_date} ${$scope.notice_data.popup_s_hour}:${$scope.notice_data.popup_s_min}:00`)).format('YYYY-MM-DD HH:mm:ss');
        params.popup_edate = moment(new Date(`${$scope.notice_data.popup_e_date} ${$scope.notice_data.popup_e_hour}:${$scope.notice_data.popup_e_min}:00`)).format('YYYY-MM-DD HH:mm:ss');
      }

      if (data.type === 'edit') {

        try {
          const result = await noticeModel.edit(params);

          if (result.data?.results === 'success') {
            commonSVC.showToaster('success', '성공', '공지사항이 수정되었습니다.');

            $uibModalInstance.close('success');
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '공지사항 수정에 실패했습니다.');
        }
      } else {
        try {
          const result = await noticeModel.add(params);

          if (result.data?.results === 'success') {
            commonSVC.showToaster('success', '성공', '공지사항이 등록되었습니다.');

            $uibModalInstance.close('success');

          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '공지사항 등록에 실패했습니다.');

        }
      }
    };

    $scope.toggle = () => {
      if ($scope.show == false) {
        //smarteditor 내용 가져오기
        // $scope.setModel();

        $scope.preview_data = {
          noti_no: $scope.notice_data.noti_no || '',
          noti_type: $scope.notice_data.noti_type || '',
          title: $scope.notice_data.title || '',
          wdate: $scope.notice_data.wdate || '',
          content: $scope.notice_data.content || '',
          file: $scope.notice_data.file_array || []
        };

        setTimeout(() => {
          $location.hash('preview');
          $anchorScroll();
        });

      } else {
        $location.hash('view');
        $anchorScroll();
      }
      $scope.show = !$scope.show;
    };

    $scope.cancel = () => {
      $('.reg-sub-user-select').select2('close');
      $uibModalInstance.dismiss('cancel');
    };
  });
