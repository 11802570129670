'use strict';

// 농수산쇼핑몰(신)
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A118-Ctrl', function ($scope, $timeout, commonSVC, A118categorySVC, categoryModel, commonModel) {
    $scope.sel_category = [];
    $scope.cate = [];
    $scope.sel_disp_store = [];
    $scope.dispStore = [];
    $scope.CateData = angular.copy(A118categorySVC.category_data);
    $scope.cert_type = angular.copy(A118categorySVC.cert_type);
    $scope.max_depth = 4;
    $scope.max_disp_depth = 5;
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    $scope.lastSelectedEtc = '';

    $scope.selectDispLimit = 3; // 전시 매장 선택 제한 개수(-1은 무한대)
    $scope.savedDispStoreList = []; // 저장된 전시 매장 목록

    for (let i = 0; i < $scope.max_depth; i += 1) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
    }
    for (let i = 0; i < $scope.max_disp_depth; i++) {
      $scope.dispStore.push([]);
    }

    let save_flag = false;    //카테고리 선택여부

    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;
      $scope.savedDispStoreList = Object.keys(shop_cate_info.key_DISP_CLSSF_CD).length ? shop_cate_info.key_DISP_CLSSF_CD : [];

      await setCategory($scope.CateData.detailData.category_code);
    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            $scope.lastSelectedEtc = '';
            $scope.cate[idx + 1] = res.data.result;
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        $scope.lastSelectedEtc = cate.etc['공정위상품분류코드'];
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    // 전시 매장 선택 > 다음 뎁스 조회
    $scope.selectDisp = async (selectIdx) => {
      const selStore = $scope.sel_disp_store[selectIdx];

      if (!selStore) {
        return false;
      }

      for (let i = selectIdx + 1; i < $scope.max_disp_depth; i++) {
        $scope.dispStore[i] = [];
      }

      const categoryId = $scope.sel_disp_store.map((item, idx) => {
        if (idx < selectIdx + 1) {
          return item.display_category_code;
        }
      }).filter(Boolean).join('_');

      $('#spinner').removeClass('hidden'); //로딩중

      await run_pa5_jobs('get_display_stores', '전시 매장', { display_category_id: categoryId }, (data) => {
        if (data) {
          $scope.dispStore[selectIdx + 1] = data.display_stores;
        }

        $('#spinner').addClass('hidden'); // 로딩완료
        $timeout(() => {});
      });
    };

    $scope.saveStore = () => {
      const selStore = $scope.sel_disp_store[$scope.sel_disp_store.filter(Boolean).length - 1];

      let category_code = '';
      let category_name = '';

      for (let i = 0; i < $scope.max_disp_depth; i++) {
        if ($scope.sel_disp_store[i]) {
          category_code = category_code !== '' ? `${category_code}_${$scope.sel_disp_store[i].display_category_code}` : $scope.sel_disp_store[i].display_category_code;
          category_name = category_name !== '' ? `${category_name} > ${$scope.sel_disp_store[i].display_category_name}` : $scope.sel_disp_store[i].display_category_name;
        }
      }
      if ($scope.savedDispStoreList.length === $scope.selectDispLimit) {
        commonSVC.showMessage('실패', `전시매장을 ${$scope.selectDispLimit}개 초과로 선택하실 수 없습니다.`);

        return false;
      }

      $scope.savedDispStoreList.push({
        catalog_id: selStore.catalog_id,
        catalog_name: selStore.catalog_name,
        category_name: category_name,
        category_code: category_code,
        catgroup_id_child: selStore.catgroup_id_child
      });
    };

    $scope.removeSelectedDispStore = (index) => {
      $scope.savedDispStoreList.splice(index, 1);
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      if ($scope.savedDispStoreList.length < 1) {
        commonSVC.showMessage('전시매장을 선택해주세요.');

        return false;
      }

      let category_code = '';

      for (let i = 0; i < $scope.max_depth; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      //카테고리 depth별 저장될 category_code

      //detailData에 수정된 데이터를 넣기위해
      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;
      $scope.CateData.detailData.key_FTC_GOODS_CLSSF_CD = $scope.lastSelectedEtc;
      $scope.CateData.detailData.key_DISP_CLSSF_CD = $scope.savedDispStoreList;

      if ($scope.form1.$valid) {
        const param = {
          // "hscode": $stateParams.hscode,
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: 'test',
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    $scope.pa5_job_data = {
      get_display_stores: { load: false, data: [] },
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {

        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          if (re.data.exception_message.includes('조회된 데이터가 없습니다.')) {
            temp_saver(re.data.results);
          } else {
            commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
          }
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const init = async () => {
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A118', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      await run_pa5_jobs('get_display_stores', '전시 매장', { display_category_id: '' }, (data) => {
        $scope.dispStore[0] = data.display_stores;

        return data;
      });

      $('#spinner').addClass('hidden'); // 로딩완료

      $timeout(async () => {
        $scope.cate[0] = res.data.result;

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A118categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });

    };

    init();
  });