/*
 *  resizable modal
 *  2020-10-13 Boris
 */

'use strict';

angular.module('gmpApp')
  .directive('resizableModal', function ($rootScope) {
    return {
      restrict: 'A',
      link: function ($scope, elem, attr) {
        if (['help', 'shop'].includes(attr.draggableModal)) {
          const modal = $(elem).parent().parent().parent();
          $(elem).parent().parent().parent().css({ height: 'calc(100% + 79px)', resize: 'both', maxHeight: '100vh', minHeight: '450px', maxWidth: '100vw', minWidth: '450px' });
          $(elem).parent().parent().css({ height: '100%' });
          $(elem).parent().css({ height: '100%' });
          $(elem).css({ height: '100%' });
          modal.find('.modal-body').css({ maxHeight: 'calc(100vh - 68px)' });
        }
      }
    };
  });
