'use strict';

angular.module('gmpApp')
  .factory('openMarketCateSVC', function() {
    let sLarge,
        sMiddle,
        sSmall,
        sDetail;

    let oLarge,
        oMiddle,
        oSmall,
        oDetail,
        oOption;

    let bComplete = false;
    let arrCategory;

    function checkgroup(strLcode) {
      if (strLcode == '') {
        strLcode = sLarge;
      }

      return true;
    }

    function resetMiddleCategory(sCode) {
      if (typeof (sCode) != 'undefined') {
        sLarge = sCode;
      }

      $(oMiddle).html('');

      if (sLarge != '0') {
        for (let i = 0; i <= arrMiddleCode.length - 1; i++) {
          arrCategory = arrMiddleCode[i].split('_');
          if (arrCategory[0] == sLarge) {
            $(oMiddle).append(`<option value='${arrMiddleCode[i]}' name='${arrMiddleName[i]}'>${arrMiddleName[i]}</option>`);
          }
        }
      }

      if (!$(oMiddle).text()) {
        $(oMiddle).attr('disabled', 'disabled');
      } else {
        $(oMiddle).removeAttr('disabled');
      }

      if (typeof (sCode) != 'undefined') {
        displayCategory(1);
      }

      sMiddle = oMiddle.value;
    }

    function resetSmallCategory(sCode) {
      let arrCategory;

      if (typeof (sCode) != 'undefined') {
        const arrCode = sCode.split('_');

        sLarge = arrCode[0];
        if (arrCode.length == 2) {
          sMiddle = sCode;
        }
      }
      $(oSmall).html('');

      if (sMiddle != '0_0') {
        for (let i = 0; i <= arrSmallCode.length - 1; i++) {

          try {
            arrCategory = arrSmallCode[i].split('_');
            if (`${arrCategory[0]}_${arrCategory[1]}` == sMiddle) {
              $(oSmall).append(`<option value='${arrSmallCode[i]}' name='${arrSmallName[i]}'>${arrSmallName[i]}</option>`);
            }
          } catch (e) {
            console.log(`arrSmallCode[${i}]---------`);
            console.log(arrSmallCode[i]);
            console.log(e);

          }

        }
      }

      if (!$(oSmall).text()) {
        $(oSmall).attr('disabled', 'disabled');
      } else {
        $(oSmall).removeAttr('disabled');
      }

      if (typeof (sCode) != 'undefined') {
        displayCategory(2);
      }

      sSmall = oSmall.value;
    }

    function resetDetailCategory(sCode) {
      let arrCategory = '';

      if (typeof (sCode) != 'undefined') {
        const arrCode = sCode.split('_');

        sLarge = arrCode[0];
        sMiddle = `${arrCode[0]}_${arrCode[1]}`;
        if (arrCode.length == 3) {
          sSmall = sCode;
        }
      }

      $(oDetail).html('');//세분류 초기화 시키는곳

      if (sSmall != '0_0_0') {
        for (let i = 0; i <= arrDetailCode.length - 1; i++) {
          arrCategory = arrDetailCode[i].split('_');
          if (`${arrCategory[0]}_${arrCategory[1]}_${arrCategory[2]}` == sSmall) {
            $(oDetail).append(`<option value='${arrDetailCode[i]}'>${arrDetailName[i]}</option>`);
            // if (sDetail.length == '2'){
            //   sDetail =  sSmall + '_' + sDetail;
            // }
            // if (oOption.value==sDetail){
            //   oOption.selected=true;
            // }
          }
        }
      }

      if (!$(oDetail).text()) {
        $(oDetail).attr('disabled', 'disabled');
      } else {
        $(oDetail).removeAttr('disabled');
      }

      if (typeof (sCode) != 'undefined') {
        displayCategory(3);
      }

      sDetail = oDetail.value;
    }

    function displayCategory(type) {
      let arrCategory;
      let i;
      let asrt_lname_val;
      let asrt_mname_val;
      let asrt_sname_val;

      bComplete = false;
      if (type == 1) {
        for (i = 0; i <= arrLargeCode.length - 1; i++) {
          if (sLarge == arrLargeCode[i]) {
            $('.asrt_lname').val(arrLargeName[i]);
            $('.asrt_mname').val('');
            $('.asrt_sname').val('');
            $('.asrt_dname').val('');
            if (arrLargeExtension[i] == 0) {
              $('.Category1').val(`${arrLargeCode[i]}000000`);
              $('.key_ep1_txtAsrtCode').val(arrLargeCode[i]);
              bComplete = true;
            }
            break;
          }
        }
        if (bComplete == false) {
          $('.Category1').val('');
          $('.key_ep1_txtAsrtCode').val('');
          if (sLarge == '0') {
            $('.asrt_lname').val('undefined');
          }
        }
        const asrtlnameVal = $('.asrt_lname').val();

        $('.Category2').val(asrtlnameVal);
      } else if (type == 2) {
        for (i = 0; i <= arrMiddleCode.length - 1; i++) {
          if (sMiddle == arrMiddleCode[i]) {
            $('.asrt_mname').val(arrMiddleName[i]);
            $('.asrt_sname').val('');
            $('.asrt_dname').val('');
            if (arrMiddleExtension[i] == 0) {
              arrCategory = arrMiddleCode[i].split('_');
              $('.Category1').val(`${arrCategory[0] + arrCategory[1]}0000`);
              $('.key_ep1_txtAsrtCode').val(arrCategory[1]);
              bComplete = true;
            }
            break;
          }
        }
        if (bComplete == false) {
          $('.Category1').val('');
          $('.key_ep1_txtAsrtCode').val('');
          if (sMiddle == '0_0') {
            $('.asrt_mname').val('undefined');
          }
        }
        if ($('.asrt_mname').val() != '') {
          asrt_lname_val = $('.asrt_lname').val('');
          asrt_mname_val = $('.asrt_mname').val('');

          $('.Category2').val(`${asrt_lname_val}->${asrt_mname_val}`);
        }
      } else if (type == 3) {
        for (i = 0; i <= arrSmallCode.length - 1; i++) {
          if (sSmall == arrSmallCode[i]) {
            $('.asrt_sname').val(arrSmallName[i]);
            $('.asrt_dname').val('');
            if (arrSmallExtension[i] == 0) {
              arrCategory = arrSmallCode[i].split('_');
              $('.Category1').val(`${arrCategory[0] + arrCategory[1] + arrCategory[2]}00`);
              $('.key_ep1_txtAsrtCode').val(arrCategory[2]);
              bComplete = true;
            }
            break;
          }
        }
        if (bComplete == false) {
          $('.Category1').val('');
          $('.key_ep1_txtAsrtCode').val('');
          if (sSmall == '0_0_0') {
            $('.asrt_sname').val('undefined');
          }
        }

        if ($('.asrt_mname').val() != '' && $('.asrt_sname').val() != '') {
          asrt_lname_val = $('.asrt_lname').val();
          asrt_mname_val = $('.asrt_mname').val();
          asrt_sname_val = $('.asrt_sname').val();

          $('.Category2').val(`${asrt_lname_val}->${asrt_mname_val}->${asrt_sname_val}`);
        }
      } else {

        if ($('.CatMenu_3').prop('selectedIndex') > -1) {

          if ($('.CatMenu_3').val($('.CatMenu_3').prop('selectedIndex')) == '0_0_0_0') {

            $('.asrt_dname').val('undefined');
          } else {

            sDetail = $(`.CatMenu_3[${$('.CatMenu_3').prop('selectedIndex')}]`).val();
            $('.asrt_dname').val($(`.CatMenu_3[${$('.CatMenu_3').prop('selectedIndex')}]`).val());
            arrCategory = sDetail.split('_');
            $('.Category1').val(arrCategory[0] + arrCategory[1] + arrCategory[2] + arrCategory[3]);
            $('.key_ep1_txtAsrtCode').val(arrCategory[3]);
            bComplete = true;
          }
          if (bComplete == false) {
            $('.Category1').val('');
            $('.key_ep1_txtAsrtCode').val('');
          }
          if ($('.asrt_mname').val() && $('.asrt_sname').val() != '' && $('.asrt_dname').val() != '') {
            $('.Category2').val(`${$('.asrt_lname').val()}->${$('.asrt_mname').val()}->${$('.asrt_sname').val()}->${$('.asrt_dname').val()}`);
          }
        }
      }
    }

    return {
      // 인팍 전용 스크립트
      SelectLargeInterpark: function (strLcode) {
        if (!checkgroup(strLcode)) {
          return false;
        }

        //자동차 고정가 판매 금지
        resetMiddleCategory(strLcode);
        resetSmallCategory();
        resetDetailCategory();
      },

      SelectSmall: function (strLcode) {
        const dIndex = $('#CatMenu_2').prop('selectedIndex');

        if (dIndex == '-1') {
          return;
        }
        const dValue = $(`#CatMenu_2[${dIndex}]`).value;

        if ((dValue == '0_0_0') && (dIndex == 0)) {
          return;
        } else {
          resetDetailCategory(strLcode);
        }
        // if ($("#CatMenu_3 option").size()==1){
        //   $("#CatMenu_3 option:eq(0)").prop("selected",true);
        // }
      },

      SelectDetail: function () {
        const dIndex = $('.CatMenu_3').prop('selectedIndex');
        const dValue = $(`.CatMenu_3[${dIndex}]`).value;

        // var dIndex = document.form1.CatMenu_3.selectedIndex;
        // var dValue = document.form1.CatMenu_3[dIndex].value;
        if ((dValue == '0_0_0_0') && (dIndex == 0)) {
          return;
        } else {
          displayCategory(4);
        }
      },

      setCategoryList: function (ol, om, os, od) {
        oLarge = ol;
        oMiddle = om;
        oSmall = os;
        oDetail = od;
      },

      setInitCategory: function (sl, sm, ss, sd) {
        sLarge = sl;
        sMiddle = sm;
        sSmall = ss;
        sDetail = sd;
      },

      resetLargeCategory: function () {
        for (let i = 0; i <= arrLargeCode.length - 1; i++) {
          if (arrLargeCode[i] != '0') {
            $(oLarge).append(`<option value='${arrLargeCode[i]}' name='${arrLargeName[i]}'>${arrLargeName[i]}</option>`);
          }
        }
      },

      resetLargeCategory2: function () {
        let i;

        for (i = oLarge.options.length - 1; i >= 0; i--) {
          oLarge.options.remove(i);
        }

        for (i = 0; i <= arrLargeCode.length - 1; i++) {
          if (arrLargeCode[i] != '0') {
            oOption = document.createElement('OPTION');
            oOption.value = arrLargeCode[i];
            if (arrLargeExtension[i] == 1) {
              oOption.text = `${arrLargeName[i]} ��`;
            } else {
              oOption.text = arrLargeName[i];
            }
            oLarge.options.add(oOption);
            if (oOption.value == sLarge) {
              oOption.selected = true;
            }
          }
        }

        sLarge = oLarge.value;
      },

      resetMiddleCategory: function(sCode) {
        resetMiddleCategory(sCode);
      },

      resetSmallCategory: function (sCode) {
        resetSmallCategory(sCode);
      },

      resetDetailCategory: function (sCode) {
        resetDetailCategory(sCode);
      },

      // 옥션 전용 스크립트 (카테고리)
      SelectLarge: function (strLcode) {
        if (!checkgroup(strLcode)) {
          return false;
        }

        resetMiddleCategory(strLcode);
        resetSmallCategory();
        resetDetailCategory();
      },

      selectCate: function (strLcode) {
        resetMiddleCategory(strLcode);
        resetSmallCategory();
        resetDetailCategory();
      }
    };
  });