'use strict';

// 오너클랜
angular.module('gmpApp')
  .factory('B730categorySVC', function () {
    return {
      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });