'use strict';

angular.module('gmpApp')
  .service('carrSVC', function () {
    return {

      /**
       * 기본 Vdata
       */
      init: {
        carr_rule_name: '',
        carr_no: '',
        carr_ser_no: '',
        rule_type: '',
        shop: [],
        to_ctry_cd: [],
        ord_curr_cd: '',
        s_sale_cnt: '', e_sale_cnt: '',
        s_sales: '', e_sales: '',
        s_sales_tax: '', e_sales_tax: '',
        misc12: '',
        s_misc7: '', e_misc7: '',
        keyword: []
      },
    };
  });
