/**
 * 쿠폰적용
 * */

'use strict';

angular.module('gmpApp')
  .controller('SetCouponCtrl', function ($scope, data, payModel, $uibModalInstance) {

    $scope.coupon_contents = '';

    // 정기결제 일반쿠폰: genCoupon, 중복쿠폰: duplCoupon
    $scope.genCoupon = '';
    $scope.duplCoupon = '';

    $scope.genCoupons = data.autopay_coupon_list.filter(cpn => cpn.dupl_dc_yn === 0);
    $scope.duplCoupons = data.autopay_coupon_list.filter(cpn => cpn.dupl_dc_yn === 1);

    /**
     * 쿠폰 선택
     */
    $scope.sel_cpn = function () {
      if (!$scope.genCoupon && !$scope.duplCoupon) {
        $scope.coupon_contents = '';

        return;
      }

      $scope.coupon_contents = [$scope.genCoupon, $scope.duplCoupon].filter(o => !!o)
        .map(({ dupl_dc_yn, pay_month, dc_qty, dc_cnt, ben_sub_type }) =>
          `${dupl_dc_yn ? '[중복] ' : '[일반] '}${pay_month ? pay_month + '개월 ' : ''}정기결제 시 ${dc_qty}${ben_sub_type === '정률' ? '%' : '원'} 할인${dc_cnt ? ` ${dc_cnt}회` : ''}`
        )
        .join('<br>');
    };

    $scope.useCoupon = async function () {
      if (!$scope.genCoupon && !$scope.duplCoupon) {
        $uibModalInstance.close('success');

        return;
      }

      await payModel.useCoupon({ couponList: [$scope.genCoupon, $scope.duplCoupon].filter(o => !!o) });
      $uibModalInstance.close('success');
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.close('cancel');
    };

  });
