/**
 * Created by kimbada on 2017. 10. 25..
 */
'use strict';

angular.module('gmpApp')
  .factory('noticeSVC', function (commonSVC, userInfo, gettextCatalog) {

    return {
      searchForm: {
        search_key: 'title,content',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        noti_status: 'ALL',
        top_expose_yn: 'ALL',
        noti_type: 'ALL',
        group_by: 'noti_no',
        tabActive: 0,
        //개월 수 지정
        sdate: moment(userInfo.user.sol_sdate).format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('제목+내용'), value: 'title,content' },
        ],
        search_date_type: [
          { label: '공지 등록일', value: 'wdate' },
        ],
      }
    };
  });