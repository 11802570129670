'use strict';

angular.module('gmpApp')
  .service('historySVC', function () {
    /**
     * 2018-02-27 Daniel
     * 삭제 내역 관리 서비스
     */

    return {
      // 서치바 상세검색 데이터
      division: [
        // 재고
        { key: 'SKU상품', value: 'prod', type: 'prod' },
        { key: '배송처', value: 'depot', type: 'prod' },
        { key: '매입처', value: 'supp', type: 'prod' },

        // 상품
        { key: '카테고리', value: 'sol_cate', type: 'ol' },
        { key: '템플릿', value: 'template', type: 'ol' },
        { key: '쇼핑몰 상품', value: 'ol', type: 'ol' },

        // 주문
        { key: '결제완료', value: '결제완료', type: 'ord' },
        { key: '신규주문', value: '신규주문', type: 'ord' },
        { key: '출고대기', value: '출고대기', type: 'ord' },
        { key: '운송장출력', value: '운송장출력', type: 'ord' },
        { key: '출고완료', value: '출고완료', type: 'ord' },
        { key: '배송중', value: '배송중', type: 'ord' },
        { key: '배송완료', value: '배송완료', type: 'ord' },
        { key: '구매결정', value: '구매결정', type: 'ord' },
        { key: '취소요청', value: '취소요청', type: 'ord' },
        { key: '취소완료', value: '취소완료', type: 'ord' },
        { key: '반품요청', value: '반품요청', type: 'ord' },
        { key: '반품회수완료', value: '반품회수완료', type: 'ord' },
        { key: '반품완료', value: '반품완료', type: 'ord' },
        { key: '교환요청', value: '교환요청', type: 'ord' },
        { key: '교환완료', value: '교환완료', type: 'ord' },
        { key: '주문재확인', value: '주문재확인', type: 'ord' },

        // 문의
        { key: '긴급메세지(신규문의)', value: '긴급메세지(신규문의)', type: 'inquiry' },
        { key: '긴급메세지(답변작성완료)', value: '긴급메세지(답변작성완료)', type: 'inquiry' },
        { key: '긴급메세지(답변전송완료)', value: '긴급메세지(답변전송완료)', type: 'inquiry' },
        { key: '긴급메세지(확인완료)', value: '긴급메세지(확인완료)', type: 'inquiry' },
        { key: '상품문의(신규문의)', value: '상품문의(신규문의)', type: 'inquiry' },
        { key: '상품문의(답변작성완료)', value: '상품문의(답변작성완료)', type: 'inquiry' },
        { key: '상품문의(답변전송완료)', value: '상품문의(답변전송완료)', type: 'inquiry' },
        { key: '상품문의(확인완료)', value: '상품문의(확인완료)', type: 'inquiry' },
        { key: '상품평(신규문의)', value: '상품평(신규문의)', type: 'inquiry' },
        { key: '상품평(답변작성완료)', value: '상품평(답변작성완료)', type: 'inquiry' },
        { key: '상품평(답변전송완료)', value: '상품평(답변전송완료)', type: 'inquiry' },
        { key: '상품평(확인완료)', value: '상품평(확인완료)', type: 'inquiry' },

        // 사은품 규칙
        { key: '사은품 규칙(사은품 유지)', value: '사은품 규칙(사은품 유지)', type: 'gift' },
        { key: '사은품 규칙(사은품 삭제)', value: '사은품 규칙(사은품 삭제)', type: 'gift' },
      ],

      /**
       * 엑셀 모달창 필드 리스트
       */
      excelFieldList: [
        { header: 'NO.', key: 'del_log_no', select: true },
        { header: '날짜', key: 'wdate', select: true },
        { header: '구분', key: 'del_type_sub', select: true },
        { header: '상세', key: 'content', select: true },
        { header: '작업자', key: 'wm_name', select: true }
      ],

      /**
       * 재고할당 엑셀 모달창 필드 리스트
       */
      stockAllocExcelFieldList: {
        alloc: [
          { header: '재고할당일', key: 'wdate', select: true },
          { header: '재고할당규칙코드', key: 'prod_depot_shop_no', select: true },
          { header: '할당구분', key: 'alloc_type', select: true },
          { header: '할당방법', key: 'alloc_method', select: true },
          { header: '작업구분', key: 'work_type', select: true },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: 'SKU상품명', key: 'prod_name', select: true },
          { header: '속성명', key: 'attri', select: true },
          { header: '할당율(%)', key: 'alloc_rate', select: true },
          { header: '쇼핑몰(계정)', key: 'shop', select: true },
          { header: '판매자관리코드', key: 'c_sale_cd', select: true },
          { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '변동수량', key: 'new_stock', select: true },
          { header: '판매수량(원본)', key: 'ori_sale_cnt_limit', select: true },
          { header: '판매수량(할당)', key: 'sale_cnt_limit', select: true },
        ],
        re_alloc: [
          { header: '재고 재할당일', key: 'wdate', select: true },
          { header: '재할당 규칙코드', key: 'prod_depot_shop_no', select: true },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: 'SKU상품명', key: 'prod_name', select: true },
          { header: '속성명', key: 'attri', select: true },
          { header: '실재고(원본)', key: 'ori_real_stock', select: true },
          { header: '실재고(변동)', key: 'real_stock', select: true },
          { header: '할당율(%)', key: 'alloc_rate', select: true },
          { header: '쇼핑몰(계정)', key: 'shop', select: true },
          { header: '판매자관리코드', key: 'c_sale_cd', select: true },
          { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '판매수량(원본)', key: 'ori_sale_cnt_limit', select: true },
          { header: '판매수량(재할당)', key: 'sale_cnt_limit', select: true },
        ]
      },

      /**
       * 상품자동품절 엑셀 모달창 필드 리스트
       */
      autoSoldoutExcelFieldList: [
        { header: '자동품절일', key: 'wdate', select: true },
        { header: '자동품절 구분', key: 'soldout_type', select: true },
        { header: 'SKU코드', key: 'sku_cd', select: true },
        { header: 'SKU상품명', key: 'prod_name', select: true },
        { header: '속성명', key: 'attri', select: true },
        { header: '쇼핑몰(계정)', key: 'shop', select: true },
        { header: '판매자관리코드', key: 'c_sale_cd', select: true },
        { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
        { header: '온라인상품명', key: 'shop_sale_name', select: true },
        { header: '옵션', key: 'shop_opt_name', select: true },
        { header: '상품상태', key: 'sale_status', select: true },
      ],

      /**
       * 주문 SKU 매칭내역 엑셀 모달창 필드 리스트
       */
      matchingExcelFieldList: [
        { header: '작업번호', key: 'work_no', select: true },
        { header: '주문수집일', key: 'wdate', select: true },
        { header: '쇼핑몰(계정)', key: 'shop_name', select: true },
        { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
        { header: '주문상태', key: 'ord_status', select: true },
        { header: '판매자관리코드', key: 'c_sale_cd', select: true },
        { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
        { header: '온라인 상품명', key: 'shop_sale_name', select: true },
        { header: '세트코드', key: 'set_cd', select: true },
        { header: '옵션 및 수량', key: 'shop_opt_name', select: true },
        { header: '매칭 구분', key: 'map_type', select: true },
        { header: '매칭 코드', key: 'sku_cd', select: true },
        { header: '매칭상품명', key: 'prod_name', select: true },
        { header: '속성', key: 'attri', select: true },
        { header: '출고수량', key: 'pack_unit', select: true },
        { header: '매칭수정일', key: 'mdate', select: true },
        { header: '세트하위상품', key: 'set_prod', select: true },
      ],
      /**
       * 환경설정 변경 내역 엑셀 모달창 필드 리스트
       */
      configsexcelFieldList: [
        { header: '날짜', key: 'wdate', select: true },
        { header: '항목', key: 'col_name', select: true },
        { header: '변경내용', key: 'col_data', select: true },
        { header: '작업자', key: 'wm_name', select: true }
      ]
    };
  });
