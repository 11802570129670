'use strict';

angular.module('gmpApp')
  // 일반상품 엑셀 일괄 등록
  .controller('OnlineProductExcelUploadCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, $timeout, $rootScope) {
    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */
    switch ($rootScope.affName) {
      case 'LG전자': {
        if ($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
          $scope.sampelExcelUrl = 'https://cloud.plto.com/sampleExcel/Sample_online_product/Sample_online_product_LG_v11.xlsx';
        } else {
          $scope.sampelExcelUrl = $rootScope.user_profile.sol_stock > 0 ? 'https://cloud.plto.com/sampleExcel/Sample_online_product/Sample_online_product_v15.xlsx' :
            'https://cloud.plto.com/sampleExcel/Sample_online_product_nostock/Sample_online_product_nostock_v12.xlsx';
        }

        break;
      }
      case '에픽카':
        $scope.sampelExcelUrl = 'https://cloud.plto.com/sampleExcel/Sample_online_product/Sample_online_product_efcar_v13.xlsx';

        break;
      default:
        $scope.sampelExcelUrl = $rootScope.user_profile.sol_stock > 0 ? 'https://cloud.plto.com/sampleExcel/Sample_online_product/Sample_online_product_v15.xlsx' :
          'https://cloud.plto.com/sampleExcel/Sample_online_product_nostock/Sample_online_product_nostock_v12.xlsx';

        break;
    }

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;
      if (!Array.isArray($scope.fileData)) {
        const params = {
          // 단일여부
          singleYn: false,
          // 등록, 수정여부
          mode: 'add'
        };

        workSVC.addExcelWork('RegistOnlineProductExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  })

  // 단일상품 엑셀 일괄 등록
  .controller('OnlineProductExcelUploadSingleCtrl', function ($scope, $uibModalInstance, $timeout, $q, commonSVC, workSVC, onlineProductModel, categoryModel, shopList, cateLists, $rootScope) {
    // 추천옵션 다운여부
    $scope.shopCateOptYn = false;
    // 단일상품 쇼핑몰ID 리스트
    $scope.shopList = shopList;
    $scope.selectIndexes = [];
    // 카테고리 리스트
    $scope.cateListKeys = ['all', 'recently', 'bookmark'];
    $scope.cateLists = cateLists.data;
    let selectCates = [];

    // 선택 쇼핑몰 카테고리 리스트
    $scope.shopCateList = [];
    let solCateNo = null;

    switch ($rootScope.affName) {
      case 'LG전자': {
        if ($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
          $scope.sampelExcelUrl = 'https://cloud.plto.com/sampleExcel/Sample_online_product_single/Sample_online_product_single_LG_v11.xlsx';
        } else {
          $scope.sampelExcelUrl = $rootScope.user_profile.sol_stock > 0 ? 'https://cloud.plto.com/sampleExcel/Sample_online_product_single/Sample_online_product_single_v20.xlsx' :
            'https://cloud.plto.com/sampleExcel/Sample_online_product_single_nostock/Sample_online_product_single_nostock_v14.xlsx';
        }

        break;
      }
      case '에픽카':
      default:
        $scope.sampelExcelUrl = $rootScope.user_profile.sol_stock > 0 ? 'https://cloud.plto.com/sampleExcel/Sample_online_product_single/Sample_online_product_single_v20.xlsx' :
          'https://cloud.plto.com/sampleExcel/Sample_online_product_single_nostock/Sample_online_product_single_nostock_v14.xlsx';

        break;
    }

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀작업 등록실패', '엑셀 파일은 한개만 업로드해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀작업 등록실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    // 카테고리 검색필터
    $scope.categorySearchFilter = function (cate) {
      if (cate.name && !new RegExp($scope.cateSearchKey).test(cate.name) && !new RegExp($scope.cateSearchKey).test(String(cate.id))) {
        return false;
      } else {
        return true;
      }
    };

    // 쇼핑몰 선택
    $scope.selectShop = function() {
      setShopCateList();
    };

    // 카테고리 선택
    $scope.selectCate = function (cate) {
      const categoryParams = {
        shop_cds: _.map($scope.shopList, 'shop_cd'),
        shop_ids: _.map($scope.shopList, 'shop_id'),
        sol_cate_no: cate.id
      };

      // 셋팅되어있는 카테고리 매칭 리스트 조회.
      categoryModel.shopCategorysLoad(categoryParams)
        .then(function(res) {
          selectCates = res.data.data;
          solCateNo = cate.id;
        })
        .finally(function () {
          $scope.cateName = cate.name;
          $scope.cateListModal = false;

          setShopCateList();
        });
    };

    // 선택 쇼핑몰별 카테고리 세팅
    function setShopCateList() {
      // 선택 쇼핑몰 또는 카테고리 없을시
      if (!solCateNo) {
        return;
      }

      // 선택 쇼핑몰 데이터 정리
      const selectShops = _.map($scope.selectIndexes, function(shopIndex) {
        return $scope.shopList[shopIndex];
      });

      $scope.shopCateList = _.map(selectShops, function(shop) {
        const shopCate = _.find(selectCates, { shop_cd: shop.shop_cd, shop_id: shop.shop_id }) || {};

        return {
          pa_shop_cd: shop.pa_shop_cd,
          shop_cd: shop.shop_cd,
          shop_id: shop.shop_id,
          shop_name: shop.shop_name,
          shop_cate_no: shopCate.shop_cate_no || null,
          sol_cate_shop_add_info: shopCate.sol_cate_shop_add_info || null
        };
      });
    }

    // 쇼핑몰 카테고리 매칭정보 open
    $scope.infoOpen = async (shopCate) => {
      const resolve = {};

      resolve.data = {
        pa_shop_cd: shopCate.pa_shop_cd,
        shop_cd: shopCate.shop_cd,
        shop_id: shopCate.shop_id,
        category_code: solCateNo,
        esm: { flag: false },
        shop_cate_no_add_info: '',
        cateEdit: true,
        path: 'ol_edit'
      };

      try {
        const res = await categoryModel.shopInfoDetailDataLoad({
          categoryId: solCateNo,
          shop_cd: shopCate.shop_cd,
          shop_id: shopCate.shop_id
        });

        if (res.data.data && res.data.data.length) {
          resolve.data.shop_cate_no_add_info = res.data.data[0].sol_cate_shop_add_info;
        } else {
          resolve.data.shop_cate_no_add_info = null;
        }
      } catch (err) {
        commonSVC.showMessage('실패', '카테고리 로딩 실패');
      }

      const modal = commonSVC.openModal('full', resolve, 'OnlineCategoryMatchCtrl', 'views/online/product/modals/category_match.html');

      modal.result.then(function (newShopCate) {
        shopCate.shop_cate_no = newShopCate.shop_cate_no;
        shopCate.sol_cate_shop_add_info = newShopCate.detailData;
      });
    };

    // 옵션 사용 엑셀 양식 다운
    $scope.sampleExcelDown = function() {
      let sampleExcelProm = $q.resolve();
      let sampleExcelPath = 'https://cloud.plto.com/sampleExcel/Sample_online_product_single/Sample_online_product_single_v20.xlsx';

      if ($scope.user_profile.sol_stock === 0) {
        sampleExcelPath = 'https://cloud.plto.com/sampleExcel/Sample_online_product_single_nostock/Sample_online_product_single_nostock_v14.xlsx';
      }

      const params = {
        solCateNo: solCateNo,
        solCateName: $scope.cateName,
        shopCateList: $scope.shopCateList
      };

      if ($scope.shopCateOptYn) {
        if (!$scope.selectIndexes.length) {
          commonSVC.showMessage('실패', '선택한 쇼핑몰이 없습니다.');

          return;
        }

        if (!$scope.shopCateList.length) {
          commonSVC.showMessage('실패', '선택한 쇼핑몰 카테고리가 없습니다.');

          return;
        }

        if (_.find($scope.shopCateList, { sol_cate_shop_add_info: null })) {
          commonSVC.showMessage('실패', '카테고리가 설정되지 않은 쇼핑몰이 있습니다.');

          return;
        }

        sampleExcelProm = onlineProductModel.getSingleOptionExcel(params);
      }

      $scope.loading = true;

      sampleExcelProm
        .then(function(res) {
          const downloadList = [sampleExcelPath];

          if ($scope.shopCateOptYn) {
            downloadList.push(res.data);
          }

          commonSVC.downloadAll(downloadList);
        })
        .catch(function(err) {
          commonSVC.showToaster('error', '실패', `엑셀 다운로드에 실패하였습니다.\n${err.data.error}`);
        })
        .finally(function() {
          $scope.loading = false;
        });
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;

      if (!Array.isArray($scope.fileData)) {
        const params = {
          // 단일여부
          singleYn: true,
          // 등록, 수정여부
          mode: 'add'
        };

        workSVC.addExcelWork('RegistOnlineProductExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    function init() {
      // 파일명 초기화
      resetInput();

      $timeout(function() {
        // 쇼핑몰 계정선택
        $('#excel-single-shop-list').select2({
          minimumResultsForSearch: Infinity,
          width: '100%',
          placeholder: '쇼핑몰(계정)선택',
          closeOnSelect: false
        });
      });
    }

    init();
  })

  // 물류상품 엑셀 일괄 등록
  .controller('OnlineProductExcelUploadSmileCtrl', function ($scope, $uibModalInstance, $timeout, commonSVC, workSVC) {

    // 셈플엑셀 다운로드 URL
    $scope.sampelExcelUrl = 'https://cloud.plto.com/sampleExcel/Sample_online_product_smile/Sample_online_product_smile_v4.xlsx';

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀작업 등록실패', '엑셀 파일은 한개만 업로드해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀작업 등록실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;

      if (!Array.isArray($scope.fileData)) {
        const params = {
          singleYn: true,
          mode: 'add',
          isSmile: true
        };

        workSVC.addExcelWork('RegistOnlineProductExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  })

  // 전체상품 엑셀 일괄 수정
  .controller('OnlineProductExcelEditAllCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, $timeout, $rootScope) {
    // 기본값 설정
    $scope.target = 'master';
    // 마스터상품 수정 라디오 타입
    $scope.type1 = 'all';
    // 쇼핑몰상품 수정 라디오 타입
    $scope.type2 = 'normal';

    /** G 마켓 물류 사용여부 */
    $scope.isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀작업 등록실패', '엑셀 파일은 한개만 업로드해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀작업 등록실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;
      if (!Array.isArray($scope.fileData)) {
        const params = {
          // 수정상품
          target: $scope.target,
          // 수정타입
          type: $scope.target === 'master' ? $scope.type1 : $scope.type2,
          // 단일여부
          singleYn: $scope.target === 'shop' && ['single', 'smile'].includes($scope.type2),
          // 등록, 수정여부
          mode: 'edit',
          isSmile: $scope.type2 === 'smile'
        };
        let act = '';
        if (params.type === 'all') {
          act = 'EditOnlineProductExcelAll';
        }
        else if (params.type === 'only') {
          act = 'EditOnlineProductExcelOnly';
        }
        else {
          act = 'EditOnlineProductExcel';
        }
        workSVC.addExcelWork(act, params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });

      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.esmCommonInfo = function () {
      commonSVC.openModal('lm', {}, 'EsmCommonInfoCtrl', 'views/settings/shopAccount/esm_common_info.html');
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  })
  /**
   * 옵션전용엑셀 업로드
   */
  .controller('OnlineOptionExcelUploadCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, $timeout) {

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('.xlsx 확장자인 엑셀 파일만 업로드해주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;
      if (!Array.isArray($scope.fileData)) {
        const params = {};

        workSVC.addExcelWork('UploadOnlineOptionExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.esmCommonInfo = function () {
      commonSVC.openModal('lm', {}, 'EsmCommonInfoCtrl', 'views/settings/shopAccount/esm_common_info.html');
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  })
  /**
   * 판매자관리코드 엑셀 일괄 수정
   */
  .controller('OnlineMasterCdExcelUploadCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, $timeout) {

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('.xlsx 확장자인 엑셀 파일만 업로드해주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;

      if (!Array.isArray($scope.fileData)) {
        const msg = '<div class="sweet-alert-body">' +
        '◆ 상품관리<br>' +
        '<ol>' +
        '<li>해당 마스터 상품에 연동된 모든 쇼핑몰 상품의 판매자관리코드가 함께 수정됩니다.</li>' +
        '<li>수정대기 → 상품전송 시 변경된 판매자관리코드로 판매중인 상품에 적용됩니다.</li>' +
        '<li>내부 관리코드를 사용해야 하는 쇼핑몰로 상품전송 시 본 코드 변경으로<br>' +
        '상품 정보 수정, 상태 변경 등의 작업이 실패될 가능성이 있습니다.</li><br>' +
        '</ol>' +
        '◆ 주문관리<br>' +
        '<ol>' +
        '<li>주문데이터에 매핑된 판매자관리코드는 변경되지 않습니다.<br>' +
        '(매핑 당시 판매자관리코드 유지)</li>' +
        '</ol>' +
        '※ 부득이한 경우가 아니라면 판매자관리코드 변경은 시스템에 많은 혼선을 줄 수 있으므로 자제하여 주십시오.<br><br>' +
        '모든 사항을 숙지해 주시고 변경을 원하시는 경우 [확인]을 눌러주세요.' +
        '</div>';

        commonSVC.showConfirmHtml('판매자관리코드 수정 시 주의사항', msg, function (Confirm) {
          if (Confirm) {
            const params = {};

            workSVC.addExcelWork('EditOnlineMasterCodeExcel', params, $scope.fileData)
              .finally(function () {
                $uibModalInstance.close();
                $timeout(function () { $scope.waitBtnDisableFlag = false; });
              });
          } else {
            $timeout(function() {
              $scope.waitBtnDisableFlag = false;
            }, 500);
          }
        });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    // 파일명 초기화
    resetInput();
  });