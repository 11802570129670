'use strict';

angular.module('gmpApp')
  .controller('rejectReportCtrl', function ($scope, $uibModalInstance, systemModel, commonSVC) {

    $scope.rejectReason = '';
    $scope.rejectError = false;

    $scope.reject = () => {
      $scope.rejectError = !$scope.rejectReason.length;
      if (!$scope.rejectError) {
        systemModel.rejectReport({ reason: $scope.rejectReason });
        $uibModalInstance.close('success');
      }
    };

    /**
     * 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
