(function () {
  'use strict';

  /**
   * 답변지연 확인
   */
  angular
    .module('gmpApp')
    .controller('replyDelayInfoCtrl', replyDelayInfoCtrl);

  function replyDelayInfoCtrl ($scope, data, $uibModalInstance) {

    $scope.delayInfo = {
      reply_plan_date: data.reply_plan_date,
      reply_delay_reason: data.reply_delay_reason,
      reply_delay_mod_yn: data.reply_delay_mod_yn || 0
    };

    $scope.cancel = cancel;

    // ----------------------- FUNCTIONS -------------------

    function cancel () {
      $uibModalInstance.close();
    }
  }
})();
