'use strict';

angular.module('gmpApp')
  .factory('B616categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'CatMenu_0',
        1: 'CatMenu_1',
        2: 'CatMenu_2',
        3: 'CatMenu_3',
      },

      // 사이트카테고리
      shop_category_data: [
        {},
        {},
        {},
        {}
      ],

      //카테고리 data
      category_data: {
        key_cpm_cate_name: '',
        key_cpm_cate_code: '',
        key_cpm_cate_name1: '',
        key_cpm_cate_code1: '',
        key_cpm_cate_name2: '',
        key_cpm_cate_code2: '',
        key_cpm_cate_name3: '',
        key_cpm_cate_code3: '',
        key_cpm_cate_name4: '',
        key_cpm_cate_code4: '',
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      },
    };
  });
