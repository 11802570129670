/**
 * SMS 발송내역 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('MsgListCtrl', function ($scope, data, $uibModalInstance, settings) {
    const getParams = { ...data };
    const msgListViewGrid_searchData = {
      totalCount: 0    // 전체 건수
    };
    $scope.searchData = angular.copy(msgListViewGrid_searchData);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['no', 'to_htel', 'status', 'reason', 'pay_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['no'],
      notSortingColumns: ['no', 'to_htel', 'status', 'reason'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/messaging-history`,
        requestMethod: 'GET',
        requestWillAction: function (d) {
          d.send_type = getParams.send_type;
          d.tran_pr = getParams.tran_pr;
          d.wdate = getParams.wdate;

          return JSON.stringify(d);
        },
        requestDidAction: function (json) {
          $scope.searchData.totalCount = json.total;

          return json.results;
        }
      },
      columns: [
        // 순번
        { key: 'no', title: '순번', width: 10 },
        // 수신자 연락처
        { key: 'to_htel', title: '수신자 연락처', width: 40 },
        // 상태
        { key: 'status', title: '상태', width: 30, template: function(row) {
          const status = row.status == '성공' ? row.status : `<span class="text-danger">${row.status}</span>`;

          return status;
        } },
        // 상세내용
        { key: 'reason', title: '상세내용', width: 100 },
        // 무료여부
        { key: 'pay_yn', title: '무료여부', width: 50 }
      ]
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
