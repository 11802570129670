'use strict';

angular.module('gmpApp')
  .controller('LoadCateTemplateCtrl', function ($scope, $uibModalInstance, onlineProductSVC, data) {
    $scope.select_type = data.type;

    $scope.categoryList = data.cateList;
    $scope.selectedCate = '';
    $scope.categorySearchKey = '';

    /**
       * 카테고리 설정
       */
    $scope.setCategory = async (cate) => {
      $scope.selectedCate = cate;
    };

    /**
       * 카테고리 검색어 필터링
       */
    $scope.categorySearchFilter = (c) => {
      const searchKey = new RegExp($scope.categorySearchKey, 'i');

      return searchKey.test(String(c.id)) || searchKey.test(c.name) || c.name === '';
    };

    $scope.close = (flag) => {
      if (flag && $scope.selectedCate) {
        $uibModalInstance.close($scope.selectedCate);
      } else {
        $uibModalInstance.close({ message: 'cancel' });
      }
    };
  });
