'use strict';

/**
 * Created by ally on 2017. 8. 3..
 */

angular.module('gmpApp')
  .controller('OrderPrintCtrl', function ($uibModalInstance, $scope, $rootScope, userInfo, shipmentModel, orderPrintFormSVC, selectList, memoList, type, bundle, one_page, selectData, orderby, autoPrintFlag, ordCount, gettext, $timeout) {
    gettext('무료배송');
    gettext('선결제');
    gettext('착불');
    gettext('조건부배송');
    gettext('방문수령');
    gettext('퀵배송');
    gettext('일반우편');
    gettext('설치배송');
    gettext('기타');

    $scope.selectList = selectList.data;
    $scope.type = type;
    $scope.selectData = selectData;
    $scope.templateData = JSON.parse(selectData[0].template_data);
    $scope.autoPrintFlag = autoPrintFlag;
    $scope.ordCount = ordCount;
    $scope.isPackingShow = [];
    $scope.singleFlag; // 리스트에서 한개 선택 후 패킹리스트 인쇄했을 때 배송지 정보 오류나는거 수정 2018-06-12 Amelia
    $scope.ordColumn = [];
    $scope.isMemoPrint = $scope.selectData.some(col => col.item_cd === 'O00220'); // 메모 출력 여부

    if (type !== 'custom') {
      const templateData = {};
      ['ord', 'ol_shop'].forEach(type => $scope.templateData[type] && $scope.templateData[type].forEach(field => templateData[field.value] = field.selected));
      $scope.templateData = templateData;
    }

    // 로그 INSERT시 $scope.selectList 사용하면 합포장 내 하나의 uniq만 남아서 원본 데이터에서 uniq 목록 추출
    const uniqList = _.uniq(selectList.data.order_data.map(o => o.uniq));

    $scope.currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

    $scope.sol_stock = $rootScope.user_profile.sol_stock;

    // 재고 미사용 버전은 order_print_packing_no_stock.html로 감
    switch (type) {
      case 'user':
        $scope.template = 'views/order/shipment/modals/order_print_user.html';
        break;
      case 'packing_stock':
        $scope.template = 'views/order/shipment/modals/order_print_packing.html';
        break;
      case 'packing_stock_sku':
        $scope.template = 'views/order/shipment/modals/order_print_packing_set.html';
        break;
      case 'packing_no_stock':
        $scope.template = 'views/order/shipment/modals/order_print_packing_no_stock.html';
        break;
      case 'packing_sku':
        $scope.template = 'views/order/shipment/modals/order_print_packing_sku.html';
        break;
      case 'custom':
        $scope.template = 'views/order/shipment/modals/order_print_custom.html';
    }

    /**
       * 패킹용 주문일때 페이지 넘버링
       */
    $scope.showPackingPage = function(idx) {
      const s_idx = idx === 0 ? idx : idx * 3;
      const e_idx = s_idx + 3;

      $scope.currentPageIdx = idx;
      for (let i = 0; i < $scope.isPackingShow.length; i++) {
        $scope.isPackingShow[i] = !!(s_idx <= i && i < e_idx);
      }
    };

    /**
       * 취소
       */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.templateOnLoad = (autoPrintFlag) => {
      if (!autoPrintFlag) {
        return false;
      }

      $timeout(() => {
        $scope.print();

        $uibModalInstance.dismiss('cancel');
      });
    };

    /**
       * 인쇄
       */
    $scope.print = function () {
      const printDivs = document.getElementsByClassName('print-div-view'); // 주문상세내역들을 불러온다

      const features = 'menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=3000,height=3000,left=300,top=300';
      const printPage = window.open('about:blank', 'shipmentPrint', features); // 새창을 열어줌

      printPage.document.open();
      printPage.document.write('<html><head>' +
          '<style>' +
          '.text-semibold {font-weight: 500;}' +
          '.mb-10 {margin-bottom: 10px!important;}' +
          '.text-center { text-align: center; }' +
          '.list-img-sm { width: 35px; height: 35px; max-width: 35px; max-height: 35px;}' +
          '.list-img-lg { width: 70px; height: 70px; max-width: 70px; max-height: 70px;}' +
          '.table-bordered {border: 1px solid #ddd; width: 900px;  border-collapse: collapse;}' +
          '.table-bordered > thead > tr > th,' +
          '.table-bordered > tbody > tr > th,' +
          '.table-bordered > tfoot > tr > th,' +
          '.table-bordered > thead > tr > td,' +
          '.table-bordered > tbody > tr > td,' +
          '.table-bordered > tfoot > tr > td {border: 1px solid #ddd; padding: 15px 20px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;border-bottom: 1px solid #EEE;vertical-align: middle; border: 1px solid #E7E7E7 !important;}' +
          '.table-bordered > thead > tr > td.active,' +
          '.table-bordered > tbody > tr > td.active,' +
          '.table-bordered > tfoot > tr > td.active {background-color: #f8f8f8;}' +
          '.table-bordered > thead > tr > th,' +
          '.table-bordered > thead > tr > td {border-bottom-width: 2px;}' +
          '.table-bordered > .active {background-color: #f8f8f8;}' +
          '@media (max-width: 900px) { #ordTable, #olShopTable { width: 900px; } }' +
          '#ordTable tr:first-child td, #olShopTable tr:first-child td {border-top: 1px solid black !important; }' +
          '#ordTable tr:last-child td, #olShopTable tr:last-child td { border-bottom: 1px solid black !important; }' +
          '#ordTable tr td:first-child, #olShopTable tr td:first-child { border-left: 1px solid black !important; }' +
          '#ordTable tr td:last-child, #olShopTable tr td:last-child { border-right: 1px solid black !important; }' +
          '#olShopTable tr.last-row td.multi_field { border-bottom: 1px solid black !important; }' +
          '#olShopTable tr td.total_field { border-bottom: 1px solid black !important; }' +
          '</style>' +
          '</head>\n<body>');

      // 반복문을 돌면서 주문상세내역들을 body 에 그려준다
      angular.forEach(printDivs, function (div, idx) {
        if (type === 'user' || one_page) {
          printPage.document.write(`<div style='page-break-${type === 'custom' ? 'after' : 'before'}:always'>`); // page-break-before : 이 전까지의 데이터를 한 페이지로 구분
        }

        printPage.document.write(div.innerHTML);

        printPage.document.write('</div>');

        // 미매칭 테이블이 여백 페이지 크기보다 클 시 다음 페이지에 인쇄
        if (type === 'packing_stock_sku') {
          const elm = document.querySelector('.no_map_table');

          if (!elm) { return; }

          setTimeout(() => {
            const height = elm.offsetHeight;
            const remainingHeight = window.innerHeight - window.scrollY - elm.offsetTop;

            if (height > remainingHeight) {
              elm.style.pageBreakBefore = 'always';
            }
          }, 500);
        }
      });

      printPage.document.write('\n</body></html>');
      printPage.document.close();
      printPage.focus();
      printPage.print();
      printPage.close();

      if (!$rootScope.localMode) {
        // 주문서인쇄 로그
        const insert = uniqList.map(v => { return { uniq: v, msg_cd: 'L0042' }; });

        shipmentModel.orderWorklog({ insert: insert });
      }
    };

    function init() {

      const sortby = typeof orderby === 'string' ? [orderby.split(' ')[0]] : orderby.map(o => o.key);
      const orders = typeof orderby === 'string' ? [orderby.split(' ')[1]] : orderby.map(o => o.sort);
      const prodList = [];

      // 정렬 처리 전 세트상품의 경우 sku상품명 = 세트명, 속성 = 세트에 포함된 모든 속성명으로 변경
      prodList.push(_.groupBy($scope.selectList.order_data, 'uniq'));
      let attriSum;
      for (const key in prodList[0]) {
        prodList[0][key].filter(prod => prod.set_no).map(i => {
          attriSum = prodList[0][i.uniq]?.filter(prod => prod.attri).map(prod => prod.attri.split('=')[1] || '').join(',');
        });
        prodList[0][key].filter(prod => prod.set_no).forEach(i => {
          i.attri = attriSum;
        });
      }

      // 주문서 순서 현재 조회된 리스트의 정렬 순서로 출력되도록 함 2018-10-26 Amelia
      // SKU상품의 경우에는 2뎁스로 정렬 처리를 해야 함.
      // SKU상품명(prod_name)으로 정렬할 경우 세트상품은 세트 내 SKU상품명으로 정렬되어 순서가 섞이게 되므로 prod_name_pack으로 변경
      $scope.selectList.order_data = _($scope.selectList.order_data)
        .groupBy('bundle_no')
        .map(function (arr) {
          arr = _.orderBy(arr, sortby.includes('prod_name') ? ['prod_name_pack'] : sortby, orders);

          return arr;
        })
        .value();

      $scope.selectList.order_data = _.flatten($scope.selectList.order_data);

      if ($scope.isMemoPrint) {

        $scope.selectList.order_data.forEach(ord => {
          ord.memo = memoList.data.results.filter(memo => memo.memo_code === ord.uniq);
        });
      }

      if (type === 'user') {
        const bundleData = _.groupBy($scope.selectList.order_data, 'bundle_no');
        const bundleOptData = _.groupBy($scope.selectList.prod_list, 'bundle_no');
        const tempData = [];

        _.each(bundleData, function(list, key) {
          let item = {};
          let total_price = 0;
          let deli_cost = 0;
          let ship_msg_list = [];
          let currencyType = '원';
          const prod_list = [];
          const ord_no_list = [];
          const applied_gift_bundle_no = []; // 묶음주문의 사은품이 중복출력 되어 적용된 묶음번호 처리용 배열 추가

          item = _(list).uniqBy('uniq').sortBy(['wdate', 'ord_time']).head();
          item.gifts = [];
          item.giftsNotRule = [];
          item.totalOrderCnt = 0;
          // 기준 주문
          _.each(_.uniqBy(list, 'uniq'), function (order, idx) {

            const orderData = {
              prod_name: order.shop_sale_name,
              image: order.set_no ? order.set_img : order.prod_img,
              count: order.sale_cnt,
              price: order.sales,
              custom_label: order.custom_label,
              prod_opt_name: order.shop_opt_name
            };

            item.totalOrderCnt += order.sale_cnt;
            total_price += (order.ord_curr_cd === 'KRW' ? parseInt(orderData.price) : orderData.price) || 0;
            if (bundle) {
              if (bundle.ship_cost_type === '최소배송비') {
                deli_cost = idx && order.ship_cost > deli_cost ? deli_cost : order.ship_cost;
              } else if (bundle.ship_cost_type === '최대배송비') {
                deli_cost = idx && order.ship_cost < deli_cost ? deli_cost : order.ship_cost;
              } else if (bundle.ship_cost_type === '배송비합') {
                deli_cost += parseInt(order.ship_cost);
              } else { //직접입력
                deli_cost = bundle.ship_cost;
              }
            }
            currencyType = order.ord_curr_cd === 'KRW' ? '원' : order.ord_curr_cd; // 통화단위가 USD 인경우 "원" 이 아닌 "USD" 로 출력 2018-02-01 rony
            ord_no_list.push(order.shop_ord_no);
            ship_msg_list.push(order.ship_msg || '');
            prod_list.push(orderData);

            const gift_pack = order.gift_pack;
            if (gift_pack && !applied_gift_bundle_no.includes(order.bundle_no)) {
              const gift_list = gift_pack.split('|');

              gift_list.forEach((_g) => {
                const [g_name, g_sku, g_opt, g_cnt] = _g.split('#');

                item.totalOrderCnt += 1;

                item.gifts.push({
                  pre_name: '규칙사은품',
                  name: g_name,
                  sku_cd: g_sku,
                  prod_name: g_sku != '' ? g_name : '',
                  attri: g_opt,
                  cnt: 1,
                  sale_cnt: parseInt(g_cnt),
                });
              });

              applied_gift_bundle_no.push(order.bundle_no);
            }

            if (order.gift_name) {
              item.giftsNotRule.push(order.gift_name);
            }
          });

          // 옵션
          _.each(_.groupBy(bundleOptData[key], function(o) {
            return o.ord_opt_seq + o.uniq;
          }), function (opt) {
            _.each(opt, function(order, idx) {
              const orderData = {
                prod_name: order.ord_opt_name,
                image: order.image,
                count: order.opt_sale_cnt,
                price: 0,
                prod_opt_name: '-'
              };

              if (idx === 0) {
                item.totalOrderCnt += order.opt_sale_cnt;
                prod_list.push(orderData);
              }
            });
          });

          // 중복 메세지 병합 출력 체크했을 경우
          if ($scope.templateData.dup_ship_msg && _.uniq(ship_msg_list).length === 1) {
            ship_msg_list = _.uniq(ship_msg_list);
          }

          item.prod_list = prod_list;
          item.ord_no_list = ord_no_list.length > 1 ? `${ord_no_list[0]} 외 ${ord_no_list.length - 1}건` : ord_no_list[0];
          item.ship_msg = ship_msg_list.filter(Boolean).length ? `"${ship_msg_list.join('", "')}"` : '';
          item.currencyType = currencyType;
          item.currencyView = currencyType === '원' ? 0 : 2;
          item.total_price = total_price;
          item.deli_cost = deli_cost;
          if (item.pa_shop_cd[0] === 'X') {
            item.ship_method = item.misc12;
          }
          tempData.push(item);
        });

        $scope.selectList = tempData;
      } else if (type === 'custom') {
        // 세트, 다중 상품 내역 출력 여부
        const skuFlag = $scope.templateData.add_opt2;

        // 재고 미설정 사용자 SKU 관련 필드 제거
        if (!$rootScope.user_profile.sol_stock) {
          $scope.templateData.add_opt2 = false;
        }

        const bundleData = _.groupBy($scope.selectList.order_data, 'bundle_no');
        const bundleOptData = _.groupBy($scope.selectList.prod_list, 'bundle_no');
        const tempData = [];

        _.each(bundleData, function(list, key) {
          let deli_cost = 0,
              total_price = 0,
              is_set = false,
              is_set_cnt = 0,
              is_multi = false,
              is_multi_cnt = 0,
              prevUniq = 0,
              prodIdx = 1,
              ship_msg_list = [],
              currencyType = '원';
          const item = _(list).uniqBy('uniq').sortBy(['wdate', 'ord_time']).head() || {},
                gifts = [],
                prod_list = [],
                ord_no_list = [],
                gift_bundle_no = [],
                gift_rule_bundle_no = [], // 묶음주문의 사은품이 중복출력 되어 적용된 묶음번호 처리용 배열 추가
                global_flag = item.pa_shop_cd[0] === 'X';
          item.all_sale_cnt = 0;
          item.all_pack_unit = 0;
          item.all_total_cnt = 0;

          // 기준 주문
          list.forEach((order, idx) => {
            if (!skuFlag && prevUniq === order.uniq) {
              const idx = prod_list.findIndex(p => p.uniq === order.uniq && (p.set_no === order.set_no || p.multi_sku === order.sku_cd));

              prod_list[idx].pack_unit += (order.pack_unit || 1);
              prod_list[idx].total_cnt += (order.sale_cnt * (order.pack_unit || 1));

              item.all_pack_unit += (order.pack_unit || 1);
              item.all_total_cnt += (order.sale_cnt * (order.pack_unit || 1));

              return false;
            }

            let rowSpan = 1;
            const multi_sku_cds = order.sku_cd?.split(';') || [];

            currencyType = !order.ord_curr_cd || order.ord_curr_cd === 'KRW' ? '원' : order.ord_curr_cd;

            if (bundle) {
              if (bundle.ship_cost_type === '최소배송비') {
                deli_cost = idx && order.ship_cost > deli_cost ? deli_cost : order.ship_cost;
              } else if (bundle.ship_cost_type === '최대배송비') {
                deli_cost = idx && order.ship_cost < deli_cost ? deli_cost : order.ship_cost;
              } else if (bundle.ship_cost_type === '배송비합') {
                deli_cost += parseInt(order.ship_cost);
              } else { //직접입력
                deli_cost = order.ship_cost;
              }
            }

            // 사은품
            if (order.gift_name && !gift_bundle_no.includes(order.bundle_no)) {
              gifts.push({
                shop_sale_name: `★사은품★ ${order.gift_name}`,
                sale_cnt: '-',
                rowSpan: 1
              });

              gift_bundle_no.push(order.bundle_no);
            }

            // 규칙 사은품
            const gift_pack = order.gift_pack;

            if (gift_pack && !gift_rule_bundle_no.includes(order.bundle_no)) {
              const gift_list = gift_pack.split('|');

              gift_list.forEach((gift) => {
                const [g_name, g_sku, g_opt, g_cnt] = gift.split('#');

                // 합계 수량 추가
                item.all_sale_cnt += 1;
                item.all_pack_unit += parseInt(g_cnt);
                item.all_total_cnt += parseInt(g_cnt);

                gifts.push({
                  shop_sale_name: `★규칙사은품★ ${g_name}`,
                  sku_cd: g_sku,
                  prod_name: g_sku ? g_name : '',
                  attri: g_opt,
                  sale_cnt: 1,
                  pack_unit: parseInt(g_cnt),
                  total_cnt: parseInt(g_cnt),
                  rowSpan: 1
                });

                gift_rule_bundle_no.push(order.bundle_no);
              });
            }

            // 이전 상품이 세트가 아니었을 경우
            if (!is_set) {
              // 주문에 세트상품이 존재
              if (order.set_no) {
                is_set = order.set_no;
                is_set_cnt = 0;
              }
            } else { // 이전 상품이 세트였을 경우
              const setIdx = prod_list.findIndex(p => p.set_no === order.set_no && p.uniq === order.uniq);

              // 주문에 세트상품이 존재
              if (order.set_no) {
                // 이전 사용한 세트 상품일 경우
                if (order.set_no === is_set && prevUniq === order.uniq) {
                  rowSpan = 0;
                  prod_list[setIdx].rowSpan = ++is_set_cnt + 1;
                } else {
                  // 이전 사용한 세트 상품이 아닐 경우
                  is_set = order.set_no;
                  is_set_cnt = 0;
                }
              } else {
                // 주문에 세트상품이 존재하지 않음
                is_set = false;
                is_set_cnt = 0;
              }
            }

            // 이전 상품이 다중SKU상품이 아니었을 경우
            if (!is_multi) {
              // 주문에 다중SKU상품이 존재
              if (!order.set_no && order.sku_cd && multi_sku_cds.length > 1) {
                is_multi = order.sku_cd;
                is_multi_cnt = 0;
              }
            } else { // 이전 상품이 다중SKU였을 경우
              const multiIdx = prod_list.findIndex(p => p.multi_sku === order.sku_cd && p.uniq === order.uniq);

              // 주문에 다중SKU상품이 존재
              if (!order.set_no && order.sku_cd && multi_sku_cds.length > 1) {
                // 이전 사용한 SKU 상품일 경우
                if (order.uniq === prevUniq && order.sku_cd == is_multi) {
                  rowSpan = 0;
                  prod_list[multiIdx].rowSpan = ++is_multi_cnt + 1;
                } else {
                  // 이전 사용한 SKU 상품이 아닐 경우
                  is_multi = order.sku_cd;
                  is_multi_cnt = 0;
                }
              } else {
                // 주문에 다중SKU상품이 존재하지 않음
                is_multi = false;
                is_multi_cnt = 0;
              }
            }

            if (prevUniq !== order.uniq) {
              ord_no_list.push(order.shop_ord_no);
              ship_msg_list.push(order.ship_msg);
              item.all_sale_cnt += order.sale_cnt;
              total_price += (!order.ord_curr_cd || order.ord_curr_cd === 'KRW' ? parseInt(order.sales) : order.sales) || 0;
            }

            item.all_pack_unit += (order.pack_unit || 1);
            item.all_total_cnt += (order.sale_cnt * (order.pack_unit || 1));

            prod_list.push({
              index: prodIdx++, // 인덱스
              sale_img: order.sale_img, // 대표상품이미지
              prod_img: skuFlag ? order.prod_img : (order.set_img || order.prod_img), // SKU상품이미지
              shop_sale_name: order.shop_sale_name, // 상품명
              prod_name: skuFlag ? order.prod_name : order.set_cd ? order.set_name : multi_sku_cds.length > 1 ? `${order.prod_name} 외 ${multi_sku_cds.length - 1} 건` : order.prod_name, // SKU상품명
              shop_opt_name: order.shop_opt_name, // 옵션
              sale_cnt: order.sale_cnt, // 수량
              attri: order.attri_pack, // 속성
              sales: `${global_flag ? (order.sales || 0).toFixed(2) : order.sales > 0 ? currencyType === '원' ? order.sales.toLocaleString() : order.sales.toFixed(2) : 0} ${currencyType}`, // 금액
              shop_supply_price: `${global_flag ? (order.shop_supply_price || 0).toFixed(2) : order.shop_supply_price > 0 ? currencyType === '원' ? order.shop_supply_price.toLocaleString() : order.shop_supply_price.toFixed(2) : 0} ${currencyType}`, // 공급가
              c_sale_cd: order.c_sale_cd, // 판매자관리코드
              stock_cd: order.prod_stock_cd, // 재고관리코드
              sku_cd: skuFlag ? (order.prod_sku_cd || order.sku_cd) : order.set_cd ? order.set_cd : multi_sku_cds.length > 1 ? `${multi_sku_cds[0]} 외 ${multi_sku_cds.length - 1} 건` : order.sku_cd, // SKU코드
              pack_unit: order.pack_unit || 1, // 건별출고수량
              total_cnt: order.sale_cnt * (order.pack_unit || 1), // 총출고수량
              uniq: order.uniq,
              ord_opt_seq: order.ord_opt_seq,
              set_no: order.set_no,
              multi_sku: order.sku_cd,
              rowSpan: rowSpan,
              memo: order.memo?.length ? ($scope.templateData.memo_print_type === 'recently' ? order.memo[order.memo.length - 1].content.replaceAll('<br>', '\n') : order.memo.reverse().map(m => m.content.replaceAll('<br>', '\n')).join(' / ')) : '' // 메모
            });

            prevUniq = order.uniq;
          });

          is_set = false;
          is_set_cnt = 0;
          is_multi = false;
          is_multi_cnt = 0;
          prevUniq = 0;

          let prev_opt_seq = 0;

          // 옵션
          _.each(_.groupBy(bundleOptData[key], function(o) {
            return o.ord_opt_seq + o.uniq;
          }), function (opt) {
            opt.forEach(order => {
              if (!skuFlag && prevUniq === order.uniq && prev_opt_seq === order.ord_opt_seq) {
                const idx = prod_list.findIndex(p => p.uniq === order.uniq && p.ord_opt_seq === order.ord_opt_seq && (p.set_no === order.set_no || (!p.set_no && p.sku_cd)));

                prod_list[idx].pack_unit += (order.pack_unit || 1);
                prod_list[idx].total_cnt += (order.opt_sale_cnt * (order.pack_unit || 1));

                item.all_pack_unit += (order.pack_unit || 1);
                item.all_total_cnt += (order.opt_sale_cnt * (order.pack_unit || 1));

                return false;
              }

              let rowSpan = 1;
              const multi_sku_cds = bundleOptData[key].filter(opt => opt.uniq === order.uniq && !opt.set_no && opt.sku_cd && opt.ord_opt_seq === order.ord_opt_seq).map(opt => opt.sku_cd);

              // 이전 상품이 세트가 아니었을 경우
              if (!is_set) {
                // 주문에 세트상품이 존재
                if (order.set_no) {
                  is_set = order.set_no;
                  is_set_cnt = 0;
                }
              } else { // 이전 상품이 세트였을 경우
                const setIdx = prod_list.findIndex(p => p.set_no === order.set_no && p.ord_opt_seq === order.ord_opt_seq && p.uniq === order.uniq);

                // 주문에 세트상품이 존재
                if (order.set_no) {
                  // 이전 사용한 세트 상품일 경우
                  if (order.set_no == is_set && prev_opt_seq === order.ord_opt_seq && prevUniq === order.uniq) {
                    rowSpan = 0;
                    prod_list[setIdx].rowSpan = ++is_set_cnt + 1;
                  } else {
                    // 이전 사용한 세트 상품이 아닐 경우
                    is_set = order.set_no;
                    is_set_cnt = 0;
                  }
                } else {
                  // 주문에 세트상품이 존재하지 않음
                  is_set = false;
                  is_set_cnt = 0;
                }
              }

              // 이전 상품이 다중SKU상품이 아니었을 경우
              if (!is_multi) {
                // 주문에 다중SKU상품이 존재
                if (!order.set_no && order.sku_cd && multi_sku_cds.length > 1) {
                  is_multi = order.sku_cd;
                  is_multi_cnt = 0;
                }
              } else { // 이전 상품이 다중SKU였을 경우
                const multiIdx = prod_list.findIndex(p => !p.set_no && p.ord_opt_seq === order.ord_opt_seq && p.uniq === order.uniq);

                // 주문에 다중SKU상품이 존재
                if (!order.set_no && order.sku_cd && multi_sku_cds.length > 1) {
                  // 이전 사용한 SKU 상품일 경우
                  if (order.uniq === prevUniq && prev_opt_seq === order.ord_opt_seq) {
                    rowSpan = 0;
                    prod_list[multiIdx].rowSpan = ++is_multi_cnt + 1;
                  } else {
                    // 이전 사용한 SKU 상품이 아닐 경우
                    is_multi = prev_opt_seq;
                    is_multi_cnt = 0;
                  }
                } else {
                  // 주문에 다중SKU상품이 존재하지 않음
                  is_multi = false;
                  is_multi_cnt = 0;
                }
              }

              prod_list.push({
                index: prodIdx++, // 인덱스
                prod_img: skuFlag ? order.prod_img : (order.set_img || order.prod_img), // SKU상품이미지
                shop_sale_name: `★추가구매옵션★ ${order.ord_opt_name}`, // 상품명
                prod_name: skuFlag ? order.prod_name : order.set_cd ? order.set_name : multi_sku_cds.length > 1 ? `${order.prod_name} 외 ${multi_sku_cds.length - 1} 건` : order.prod_name, // SKU상품명
                sale_cnt: order.opt_sale_cnt, // 수량
                attri: order.attri_pack, // 속성
                sales: `${global_flag ? (order.sales || 0).toFixed(2) : order.sales > 0 ? currencyType === '원' ? order.sales.toLocaleString() : order.sales.toFixed(2) : 0} ${currencyType}`, // 금액
                shop_supply_price: `${global_flag ? (order.shop_supply_price || 0).toFixed(2) : order.shop_supply_price > 0 ? currencyType === '원' ? order.shop_supply_price.toLocaleString() : order.shop_supply_price.toFixed(2) : 0} ${currencyType}`, // 공급가
                stock_cd: order.stock_cd, // 재고관리코드
                sku_cd: skuFlag ? (order.prod_sku_cd || order.sku_cd) : order.set_cd ? order.set_cd : multi_sku_cds.length > 1 ? `${multi_sku_cds[0]} 외 ${multi_sku_cds.length - 1} 건` : order.sku_cd, // SKU코드
                pack_unit: order.pack_unit || 1, // 건별출고수량
                total_cnt: order.opt_sale_cnt * (order.pack_unit || 1), //총출고수량
                uniq: order.uniq,
                ord_opt_seq: order.ord_opt_seq,
                set_no: order.set_no,
                multi_sku: order.sku_cd,
                rowSpan: rowSpan,
                memo: order.memo?.length ? ($scope.templateData.memo_print_type === 'recently' ? order.memo[order.memo.length - 1].content.replaceAll('<br>', '\n') : order.memo.reverse().map(m => m.content.replaceAll('<br>', '\n')).join(' / ')) : '' // 메모
              });

              if (order.uniq !== prevUniq || prev_opt_seq !== order.ord_opt_seq) {
                item.all_sale_cnt += order.opt_sale_cnt;
                total_price += (!order.ord_curr_cd || order.ord_curr_cd === 'KRW' ? parseInt(order.sales) : order.sales) || 0;
              }

              item.all_pack_unit += (order.pack_unit || 1);
              item.all_total_cnt += (order.opt_sale_cnt * (order.pack_unit || 1));

              // 다중SKU매칭 확인하기 위해 직전 opt_seq 저장
              prev_opt_seq = order.ord_opt_seq;
              prevUniq = order.uniq;
            });
          });

          // 중복 메세지 병합 출력 체크했을 경우
          if ($scope.templateData.dup_ship_msg && _.uniq(ship_msg_list).length === 1) {
            ship_msg_list = _.uniq(ship_msg_list);
          }

          item.shop_name = `${item.shop_name}(${item.shop_id})`;
          item.to_addr = `(${item.to_zipcd})${item.to_addr1} ${item.to_addr2 || ''}`;
          item.order_name = `${item.order_name}(${item.order_id})`;
          item.print_date = moment().format('YYYY-MM-DD HH:mm:ss');
          item.user_name = userInfo.user.user_name;
          item.prod_list = [...prod_list, ...gifts.map((g, idx) => { return { ...g, ...{ index: prod_list.length + idx + 1 } }; })];
          item.shop_ord_no = ord_no_list.length > 1 ? `${ord_no_list[0]} 외 ${ord_no_list.length - 1}건` : ord_no_list[0];
          item.ship_msg = ship_msg_list.filter(Boolean).length ? `"${ship_msg_list.join('", "')}"` : '';
          item.carr_name = global_flag ? item.global_carr_name : item.carr_name;
          item.invoice_no = global_flag ? item.global_invoice_no : item.invoice_no;
          item.ship_method = global_flag ? item.misc12 : item.ship_method;
          item.total_price = `${global_flag ? total_price.toFixed(2) : total_price > 0 ? currencyType === '원' ? total_price.toLocaleString() : total_price.toFixed(2) : 0} ${currencyType}`;
          item.currencyType = currencyType;
          item.deli_cost = `${global_flag ? item.ship_cost.toFixed(2) : deli_cost} ${currencyType}`;
          item.lastRow = _.findLastIndex(item.prod_list, p => p.rowSpan) + 1;

          tempData.push(item);
        });

        $scope.selectList = tempData;

        // 커스텀 양식 컬럼
        const fieldList = angular.copy(orderPrintFormSVC.fieldList);

        $scope.customFiledList = { ord: [], ol_shop: [], tab: [] };

        selectData.forEach(f => {
          if (f.type) {
            const field = fieldList[f.type].find(field => field.item_cd === f.item_cd);

            // 재고 미설정 사용자 SKU 관련 필드 제거
            if (!$rootScope.user_profile.sol_stock && f.type === 'ol_shop' && field.no_stock) {
              return false;
            }

            f.value = field.value;
            f.text = field.text;
            f.col = field.col;
            f.multi = field.multi;
            f.total = field.total;

            $scope.customFiledList[f.type].push(f);
          } else {
            $scope.customFiledList.tab.push(f.seq);
          }
        });

        // 커스텀 양식에 맞게 주문 테이블 배치
        let sum = 0;
        let arr = [];

        $scope.customFiledList.ord.forEach((obj, idx) => {
          // 컬럼 넓이의 합이 6행을 넘거나 줄바꿈 컬럼과 만나면 다음 행으로 이동
          if ($scope.customFiledList.tab.includes(obj.seq + 1)) {
            // 수동 줄바꿈시 해딩 컬럼의 width 확장
            // 남은 width가 컬럼 기본 width보다 작으면 자동 줄바꿈
            if ((sum + (obj.col * 1)) > 6) {
              if (arr.length) {
                sum < 6 && arr.push({ col: 6 - sum + 1 });
                $scope.ordColumn.push(arr);
              }

              arr = [];
              sum = obj.col = 6;
            } else {
              obj.col = 6 - sum;
              sum = 6;
            }
          } else if ((sum + (obj.col * 1)) > 6) {
            sum < 6 && arr.push({ col: 6 - sum + 1 });
            $scope.ordColumn.push(arr);
            arr = [];
            sum = obj.col * 1;
          } else {
            sum += (obj.col * 1);
          }
          arr.push(obj);

          if (idx === $scope.customFiledList.ord.length - 1) {
            sum < 6 && arr.push({ col: 6 - sum + 1 });
            $scope.ordColumn.push(arr);
          }
        });
      } else if (type === 'packing_stock_sku') {
        const uniqData = _.groupBy($scope.selectList.order_data, 'bundle_no');
        const uniqOptData = _.groupBy($scope.selectList.prod_list, 'bundle_no');

        $scope.total = { price: 0, deli_cost: 0, count: 0 };
        $scope.page = [];
        $scope.currentPageIdx = 0;

        // 매칭/미매칭 주문 분류
        let map_ord = [],
            no_map_ord = [];
        const ord_date = [];
        const bundle_temp = {
          ord_date: [],
          all_pack_unit: { map_ord: 0, no_map_ord: 0 },
          all_sale_cnt: { map_ord: 0, no_map_ord: 0 },
          all_total_cnt: { map_ord: 0, no_map_ord: 0 }
        };

        for (const key in uniqData) {
          const list = uniqData[key];
          let ord_type;
          let is_set = false,
              is_set_cnt = 0,
              prevUniq = 0; // 이전 uniq

          for (let i = 0; i < list.length; i++) {
            const order = list[i];
            const pack_unit = order.pack_unit || 1;

            // 묶음주문 기준으로 규칙사은품이 결정나서 묶음당 1번 저장
            if (i === 0 && order.gift_pack) {
              const gift_list = order.gift_pack.split('|');

              gift_list.forEach((_g) => {
                const [g_name, g_sku, g_opt, g_cnt] = _g.split('#');

                if (g_sku) { // 매칭
                  ord_type = 'map_ord';

                  map_ord.push({
                    sku_cd: g_sku,
                    prod_name: g_sku != '' ? g_name : '',
                    cnt: parseInt(g_cnt),
                    sale_cnt: 1,
                    pack_unit: parseInt(g_cnt),
                    total_cnt: parseInt(g_cnt),
                    bundle_no: order.bundle_no
                  });
                } else { // 미매칭
                  ord_type = 'no_map_ord';

                  no_map_ord.push({
                    name: g_name,
                    attri: g_opt,
                    cnt: parseInt(g_cnt),
                    sale_cnt: 1,
                    pack_unit: parseInt(g_cnt),
                    total_cnt: parseInt(g_cnt)
                  });
                }

                // 합계 계산용
                bundle_temp.all_sale_cnt[ord_type]++;
                bundle_temp.all_total_cnt[ord_type] += parseInt(g_cnt);
              });
            }

            ord_date.push(order.ord_time);

            if (order.prod_sku_cd || order.sku_cd || order.set_no) { // 매칭
              ord_type = 'map_ord';

              if (!is_set) { // 직전에 세트상품이 아니었을 경우
                // 주문에 세트상품이 엮여있을 경우
                if (order.set_no) {
                  // 세트검사용 변수 초기화
                  is_set = order.set_no;
                  is_set_cnt = 0;
                }
              } else { // 직전에 세트상품이었을 경우
                // 주문에 세트코드가 있을 경우
                if (order.set_no) {
                  // 이전 세트코드랑 같을 경우
                  if (order.set_no == is_set && prevUniq === order.uniq) {
                    is_set_cnt++;
                  } else {
                    // 이전 세트코드랑 다를 경우
                    is_set = order.set_no;
                    is_set_cnt = 0;
                  }
                } else {
                  // 주문에 세트코드가 없을경우
                  is_set = false;
                  is_set_cnt = 0;
                }
              }

              prevUniq = order.uniq;

              // 세트상품 세트정보 출력 옵션 선택한 경우 개별 SKU 대신 세트정보 노출
              // 세트상품 하위 SKU 출력 옵션 선택한 경우 각 세트 상단에 세트정보 row 노출
              if (($scope.templateData.set_bundle || $scope.templateData.set_sku) && is_set && is_set_cnt === 0) {
                map_ord.push({
                  sku_cd: order.set_no,
                  prod_name: order.set_name,
                  attri: $scope.templateData.set_bundle ? '' : order.attri_pack,
                  stock_cd: $scope.templateData.set_bundle ? '' : order.prod_stock_cd,
                  cnt: (order.sale_cnt * order.set_pack_unit),
                  sale_cnt: order.sale_cnt,
                  pack_unit: order.set_pack_unit,
                  total_cnt: (order.sale_cnt * order.set_pack_unit),
                  set_yn: ($scope.templateData.set_bundle || $scope.templateData.set_sku) && order.set_no,
                  set_no: order.set_no,
                  set_pack_unit: order.set_pack_unit,
                  bundle_no: order.bundle_no
                });
              }

              // 세트상품 세트정보로 출력시 첫번째 SKU외에는 break
              if ($scope.templateData.set_bundle && !$scope.templateData.set_sku && is_set) {
                // 합계 계산용
                bundle_temp.all_sale_cnt[ord_type] += is_set_cnt > 0 ? 0 : order.sale_cnt;
                bundle_temp.all_total_cnt[ord_type] += is_set_cnt > 0 ? 0 : (order.sale_cnt * order.set_pack_unit);

                continue;
              }

              map_ord.push({
                sku_cd: ($scope.templateData.set_bundle && !$scope.templateData.set_sku) ? (order.set_no || order.prod_sku_cd || order.sku_cd) : (order.prod_sku_cd || order.sku_cd),
                prod_name: ($scope.templateData.set_bundle && !$scope.templateData.set_sku) ? (order.set_name || order.prod_name) : order.prod_name,
                attri: order.attri_pack,
                stock_cd: order.prod_stock_cd,
                cnt: (order.sale_cnt * pack_unit),
                sale_cnt: order.sale_cnt,
                pack_unit: pack_unit,
                total_cnt: (order.sale_cnt * pack_unit),
                set_no: order.set_no || '',
                set_pack_unit: order.set_pack_unit || '',
                bundle_no: order.bundle_no
              });
            } else { // 미매칭
              ord_type = 'no_map_ord';

              no_map_ord.push({
                name: order.shop_sale_name,
                shop_opt_name: order.shop_opt_name || '-',
                cnt: (order.sale_cnt * pack_unit),
                sale_cnt: order.sale_cnt,
                pack_unit: pack_unit,
                total_cnt: (order.sale_cnt * pack_unit)
              });
            }

            // 합계 계산용
            bundle_temp.all_sale_cnt[ord_type] += order.sale_cnt;
            bundle_temp.all_total_cnt[ord_type] += (order.sale_cnt * pack_unit);
          }

          is_set = false;
          is_set_cnt = 0;
          prevUniq = 0;

          let prev_opt_seq = 0;

          // 추가상품 출력
          for (let i = 0; i < uniqOptData[key]?.length; i++) {
            const opt = uniqOptData[key][i];

            const pack_unit = opt.pack_unit || 1,
                  temp_cnt = parseInt(opt.opt_sale_cnt) * parseInt(pack_unit);

            if (opt.sku_cd || opt.set_no) { // 매칭
              ord_type = 'map_ord';

              if (!is_set) { // 직전에 세트상품이 아니었을 경우
                // 주문에 세트상품이 엮여있을 경우
                if (opt.set_no) {
                  // 세트검사용 변수 초기화
                  is_set = opt.set_no;
                  is_set_cnt = 0;
                }
              } else { // 직전에 세트상품이었을 경우
                // 주문에 세트코드가 있을 경우
                if (opt.set_no) {
                  // 이전 세트코드랑 같을 경우
                  if (opt.set_no == is_set && prev_opt_seq === opt.ord_opt_seq && prevUniq === opt.uniq) {
                    is_set_cnt++;
                  } else {
                    // 이전 세트코드랑 다를경우
                    is_set = opt.set_no;
                    is_set_cnt = 0;
                  }
                } else {
                  // 주문에 세트코드가 없을경우
                  is_set = false;
                  is_set_cnt = 0;
                }
              }

              // 다중SKU매칭 확인하기 위해 직전 opt_seq 저장
              prev_opt_seq = opt.ord_opt_seq;
              prevUniq = opt.uniq;

              // 세트상품 세트정보 출력 옵션 선택한 경우 개별 SKU 대신 세트정보 노출
              // 세트상품 하위 SKU 출력 옵션 선택한 경우 각 세트 상단에 세트정보 row 노출
              if (($scope.templateData.set_bundle || $scope.templateData.set_sku) && is_set && is_set_cnt === 0) {
                map_ord.push({
                  sku_cd: opt.set_no,
                  prod_name: opt.set_name,
                  attri: $scope.templateData.set_bundle ? '' : opt.attri_pack,
                  stock_cd: $scope.templateData.set_bundle ? '' : opt.stock_cd,
                  cnt: (opt.opt_sale_cnt * opt.set_pack_unit),
                  sale_cnt: opt.opt_sale_cnt,
                  pack_unit: opt.set_pack_unit,
                  total_cnt: (opt.opt_sale_cnt * opt.set_pack_unit),
                  set_yn: ($scope.templateData.set_bundle || $scope.templateData.set_sku) && opt.set_no,
                  set_no: opt.set_no,
                  set_pack_unit: opt.set_pack_unit,
                  bundle_no: opt.bundle_no
                });
              }

              // 세트상품 세트정보로 출력시 첫번째 SKU외에는 break
              if ($scope.templateData.set_bundle && !$scope.templateData.set_sku && is_set) {
                // 합계 계산용
                bundle_temp.all_sale_cnt[ord_type] += is_set_cnt > 0 ? 0 : opt.opt_sale_cnt;
                bundle_temp.all_total_cnt[ord_type] += is_set_cnt > 0 ? 0 : (opt.opt_sale_cnt * opt.set_pack_unit);

                continue;
              }

              map_ord.push({
                sku_cd: ($scope.templateData.set_bundle && !$scope.templateData.set_sku) ? (opt.set_no || opt.sku_cd) : opt.sku_cd,
                prod_name: ($scope.templateData.set_bundle && !$scope.templateData.set_sku) ? (opt.set_name || opt.prod_name) : opt.prod_name,
                attri: opt.attri_pack,
                stock_cd: opt.stock_cd,
                cnt: temp_cnt,
                sale_cnt: opt.opt_sale_cnt,
                pack_unit: pack_unit,
                total_cnt: (opt.opt_sale_cnt * pack_unit),
                set_no: opt.set_no || '',
                set_pack_unit: opt.set_pack_unit || '',
                bundle_no: opt.bundle_no
              });
            } else { // 미매칭
              ord_type = 'no_map_ord';

              no_map_ord.push({
                name: opt.ord_opt_name || '-',
                shop_opt_name: '-',
                cnt: temp_cnt,
                sale_cnt: opt.opt_sale_cnt,
                pack_unit: pack_unit,
                total_cnt: (opt.opt_sale_cnt * pack_unit),
              });
            }

            // 합계 계산용
            bundle_temp.all_sale_cnt[ord_type] += opt.opt_sale_cnt;
            bundle_temp.all_total_cnt[ord_type] += (opt.opt_sale_cnt * pack_unit);
          }

          if (bundle) {
            if (bundle.ship_cost_type === '3') {
              bundle_temp.ship_cost = bundle.ship_cost;
            }
          }
        }

        const map_temp = {},
              no_map_temp = {},
              sku_arr = []; // 이미 처리한 SKU 리스트를 담아둘 배열
        let sku_idx = 1;

        bundle_temp.ord_date = [_.min(ord_date), _.max(ord_date)];

        // 매칭 주문 그룹핑 기준: SKU코드, 건별 출고수량
        // 하위 SKU 출력시 각 세트 상단에 세트 정보 출력되어야 하므로 임의 인덱스 0/1 추가
        map_ord.forEach(o => {
          const key = $scope.templateData.set_sku ? `${o.set_no || o.sku_cd}_${o.set_pack_unit || o.pack_unit}_${o.set_yn ? 0 : 1}_${o.sku_cd}` : `${o.sku_cd}_${o.pack_unit}`;

          if (map_temp[key]) {
            map_temp[key].cnt += o.cnt;
            map_temp[key].sale_cnt += o.sale_cnt;
            // map_temp[key].pack_unit += o.pack_unit;
            map_temp[key].total_cnt += o.total_cnt;
          } else {
            map_temp[key] = { ..._.cloneDeep(o) };

            // 건별 출고수량 합계 계산
            bundle_temp.all_pack_unit.map_ord += parseInt($scope.templateData.set_sku ? (o.set_yn ? 0 : o.pack_unit) : ($scope.templateData.set_bundle && !$scope.templateData.set_sku ? (o.set_pack_unit || o.pack_unit) : o.pack_unit));
          }
        });

        // 미매칭 주문 그룹핑 기준: 주문 상품명, 옵션명
        no_map_ord.forEach(o => {
          if (no_map_temp[`${o.name}_${o.shop_opt_name}`]) {
            no_map_temp[`${o.name}_${o.shop_opt_name}`].cnt += o.cnt;
            no_map_temp[`${o.name}_${o.shop_opt_name}`].sale_cnt += o.sale_cnt;
            // no_map_temp[`${o.name}_${o.shop_opt_name}`].pack_unit += o.pack_unit;
            no_map_temp[`${o.name}_${o.shop_opt_name}`].total_cnt += o.total_cnt;
          } else {
            no_map_temp[`${o.name}_${o.shop_opt_name}`] = _.cloneDeep(o);

            // 건별 출고수량 합계 계산
            bundle_temp.all_pack_unit.no_map_ord += parseInt(o.pack_unit);
          }
        });

        // 그룹핑 기준 오름차순으로 정렬해서 배열화
        map_ord = Object.entries(map_temp).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => {
          if (!$scope.templateData.set_sku) { // 옵션 없음, 세트상품 세트정보로 출력
            // 세트 내 구성품중 첫 SKU만 인덱스, SKU코드, 상품명 등 표시하기 위한 플래그
            const sku_dup_flag = sku_arr.includes(value.sku_cd);

            if (!sku_dup_flag) {
              sku_arr.push(value.sku_cd);
            }

            return { ...value, ...(!sku_dup_flag && { sku_idx: sku_idx++ }), sku_dup_flag };
          } else { // 세트상품 하위 SKU 출력
            return { ...value, ...((!value.set_no || value.set_yn) && { sku_idx: sku_idx++ }) };
          }
        });
        no_map_ord = Object.entries(no_map_temp).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => value);

        const pageIdx = _.size(uniqData) % 3 ? parseInt(_.size(uniqData) / 3) + 1 : parseInt(_.size(uniqData) / 3);

        for (let i = 0; i < pageIdx; i++) {
          $scope.page.push(i);
        }

        $scope.selectList = { ...bundle_temp, map: map_ord, no_map: no_map_ord };

        // 리스트에서 한개 선택 후 패킹리스트 인쇄했을 때 배송지 정보 오류나는거 수정 2018-06-12 Amelia
        if ($scope.selectList.length == 1) {
          $scope.singleFlag = true;
        }
      } else {
        const uniqData = _.groupBy($scope.selectList.order_data, 'bundle_no');
        const uniqOptData = _.groupBy($scope.selectList.prod_list, 'bundle_no');
        let idx = 0;

        const tempData = [];

        $scope.total = { price: 0, deli_cost: 0, count: 0 };
        $scope.page = [];
        $scope.currentPageIdx = 0;

        for (const key in uniqData) {
          const list = uniqData[key];
          const item_list = [];
          const bundle_temp = {
            all_pack_unit: 0,
            all_sale_cnt: 0,
            all_total_cnt: 0,
            etc_shop_cnt: 0,
          };
          let is_set = false,
              is_set_cnt = 0,
              is_multi = false,
              is_multi_cnt = 0,
              prevUniq = 0, // 이전 uniq
              ship_msg_list = [];

          const shop_list = [];
          const gift = [];

          for (let i = 0; i < list.length; i++) {
            const order = list[i];

            // 상세주소가 null 로 나오는경우 빈값으로 치환 2018-11-07 rony
            if (order.to_addr2 === null) {
              order.to_addr2 = '';
            }

            const pack_unit = order.pack_unit ? order.pack_unit : 1;
            // 세트상품이면 각각 출고수량 더해줌 - 2019-01-24 Alvin

            if (i === 0) {
              // 묶음상품의 기본정보 저장하는 로직
              bundle_temp.shop_name = order.shop_name;
              bundle_temp.shop_ord_no = order.shop_ord_no;
              bundle_temp.bundle_no = order.bundle_no;
              bundle_temp.to_name = order.to_name;
              bundle_temp.to_htel = order.to_htel;
              bundle_temp.order_name = order.order_name;
              bundle_temp.order_id = order.order_id;
              bundle_temp.to_zipcd = order.to_zipcd;
              bundle_temp.to_addr1 = order.to_addr1;
              bundle_temp.to_addr2 = order.to_addr2;
              bundle_temp.depot_name = order.depot_name;

              // 쇼핑몰 여러개일때 ~외 ~건 하기위한 변수
              shop_list.push(order.shop_cd);

              // 가끔 주문일자가 상이한 떄가 있어서 최초주문시간으로 계산되게 설정
              bundle_temp.ord_time = _.min(_.map(list, 'ord_time'));

              // 묶음주문 기준으로 규칙사은품이 결정나서 묶음당 1번 저장
              const gift_pack = order.gift_pack;
              if (gift_pack) {
                const gift_list = gift_pack.split('|');

                gift_list.forEach((_g) => {
                  const [g_name, g_sku, g_opt, g_cnt] = _g.split('#');
                  gift.push({
                    pre_name: '★규칙사은품★',
                    name: g_name,
                    sku_cd: g_sku,
                    prod_name: g_sku != '' ? g_name : '',
                    attri: g_opt,
                    cnt: parseInt(g_cnt),
                    sale_cnt: 1,
                    pack_unit: parseInt(g_cnt),
                    total_cnt: parseInt(g_cnt)
                  });
                });
              }
            } else if (!shop_list.includes(order.shop_cd)) {
              shop_list.push(order.shop_cd);
              bundle_temp.etc_shop_cnt += 1;
            }

            // 2020-01-28 Boris
            // 세트일경우 첫번째 출력 후엔 주문상품명 출력안하는 등 다른 처리가 필요함

            // 직전에 세트상품이 아니었을경우
            if (!is_set) {
              // 주문에 세트상품이 엮여있을경우
              if (order.set_no) {
                // 세트검사용 변수 초기화
                is_set = order.set_no;
                is_set_cnt = 0;
              }
            } else {
              // 직전에 세트상품이었을경우

              // 주문에 세트코드가 있을경우
              if (order.set_no) {
                // 이전 세트코드랑 같을경우
                if (order.set_no == is_set && prevUniq === order.uniq) {
                  is_set_cnt++;
                  if (type === 'packing_no_stock') {
                    item_list[i - is_set_cnt].pack_unit += order.pack_unit;
                    item_list[i - is_set_cnt].total_cnt += (order.sale_cnt * pack_unit);
                  }
                } else {
                  // 이전 세트코드랑 다를경우
                  is_set = order.set_no;
                  is_set_cnt = 0;
                }
              } else {
                // 주문에 세트코드가 없을경우
                is_set = false;
                is_set_cnt = 0;
              }
            }

            // 직전에 다중SKU상품이 아니었을경우
            if (!is_multi) {
              // 주문이 다중SKU 매칭되어있을경우
              if (!order.set_no && order.sku_cd && order.sku_cd.split(';').length > 1) {
                // 다중SKU검사용 변수 초기화
                is_multi = order.sku_cd;
                is_multi_cnt = 0;
              }
            } else {
              // 직전에 다중SKU상품이었을경우

              // 주문이 다중SKU 매칭되어있을경우
              if (!order.set_no && order.sku_cd && order.sku_cd.split(';').length > 1) {
                // 이전 SKU코드랑 같을경우
                if (order.sku_cd == is_multi) {
                  is_multi_cnt++;
                  if (type === 'packing_no_stock') {
                    item_list[i - is_multi_cnt].pack_unit += order.pack_unit;
                    item_list[i - is_multi_cnt].total_cnt += (order.sale_cnt * pack_unit);
                  }
                } else {
                  // 이전 SKU코드랑 다를경우
                  is_multi = order.sku_cd;
                  is_multi_cnt = 0;
                }
              } else {
                // 주문이 다중SKU매칭 아닌경우
                is_multi = false;
                is_multi_cnt = 0;
              }
            }

            // 패킹리스트 변경
            item_list.push({
              name: is_set_cnt == 0 && is_multi_cnt == 0 ? order.shop_sale_name + (order.gift_name ? ` ★사은품★ ${order.gift_name}` : '') : null,
              cnt: (order.sale_cnt * pack_unit),
              prod_img: order.prod_img,
              sku_cd: order.prod_sku_cd || order.sku_cd,
              stock_cd: order.prod_stock_cd || '',
              prod_name: order.prod_name,
              c_sale_cd: order.c_sale_cd,
              attri: order.attri_pack,
              gift_name: order.gift_name ? order.gift_name : '',
              add_opt: false,
              shop_opt_name: ((is_multi && !is_multi_cnt) || (is_set && !is_set_cnt) || (!is_multi && !is_set)) ? order.shop_opt_name : null,
              sale_cnt: order.sale_cnt,
              pack_unit: pack_unit,
              total_cnt: (order.sale_cnt * pack_unit),
              sales: order.sales
            });

            // 합계 계산용
            bundle_temp.all_pack_unit += pack_unit;
            bundle_temp.all_sale_cnt += order.sale_cnt;
            bundle_temp.all_total_cnt += (order.sale_cnt * pack_unit);

            // 배송 메시지 처리
            ship_msg_list.push(order.ship_msg);

            prevUniq = order.uniq;
          }

          is_set = false;
          is_set_cnt = 0;
          is_multi = false;
          is_multi_cnt = 0;
          prevUniq = 0;
          let prev_opt_seq = 0;

          // 추가상품 출력
          for (let i = 0; i < uniqOptData[key]?.length; i++) {
            const opt = uniqOptData[key][i];

            const pack_unit = opt.pack_unit || 1,
                  temp_cnt = parseInt(opt.opt_sale_cnt) * parseInt(pack_unit);

            // 2020-02-18 Boris
            // 세트일경우 첫번째 출력 후엔 주문상품명 출력안하는 등 다른 처리가 필요함

            // 직전에 세트상품이 아니었을경우
            if (!is_set) {
              // 주문에 세트상품이 엮여있을경우
              if (opt.set_no) {
                // 세트검사용 변수 초기화
                is_set = opt.set_no;
                is_set_cnt = 0;
              }
            } else {
              // 직전에 세트상품이었을경우

              // 주문에 세트코드가 있을경우
              if (opt.set_no) {
                // 이전 세트코드랑 같을경우
                if (opt.set_no == is_set && prev_opt_seq === opt.ord_opt_seq && prevUniq === opt.uniq) {
                  is_set_cnt++;
                  if (type === 'packing_no_stock') {
                    item_list[(i + list.length) - is_set_cnt].pack_unit += opt.pack_unit;
                    item_list[(i + list.length) - is_set_cnt].total_cnt += (opt.opt_sale_cnt * pack_unit);
                  }
                } else {
                  // 이전 세트코드랑 다를경우
                  is_set = opt.set_no;
                  is_set_cnt = 0;
                }
              } else {
                // 주문에 세트코드가 없을경우
                is_set = false;
                is_set_cnt = 0;
              }
            }

            // 직전에 다중SKU상품이 아니었을경우
            if (!is_multi) {
              // 주문이 다중SKU 매칭되어있을경우
              if (!opt.set_no && opt.sku_cd && i !== uniqOptData[key].length - 1 && opt.ord_opt_seq === uniqOptData[key][i + 1].ord_opt_seq) {
                // 다중SKU검사용 변수 초기화
                is_multi = opt.sku_cd;
                is_multi_cnt = 0;
              }
            } else {
              // 직전에 다중SKU상품이었을경우

              // 주문이 다중SKU 매칭되어있을경우
              if (!opt.set_no && opt.sku_cd && prev_opt_seq === opt.ord_opt_seq) {
                // 이전 SKU코드랑 같을경우
                if (prev_opt_seq === opt.ord_opt_seq) {
                  is_multi_cnt++;
                  if (type === 'packing_no_stock') {
                    item_list[(i + list.length) - is_multi_cnt].pack_unit += opt.pack_unit;
                    item_list[(i + list.length) - is_multi_cnt].total_cnt += (opt.opt_sale_cnt * pack_unit);
                  }
                } else {
                  // 이전 SKU코드랑 다를경우
                  is_multi = prev_opt_seq;
                  is_multi_cnt = 0;
                }
              } else {
                // 주문이 다중SKU매칭 아닌경우
                is_multi = false;
                is_multi_cnt = 0;
              }
            }

            // 패킹리스트 추가
            item_list.push({
              pre_name: (is_set_cnt == 0 && (prev_opt_seq !== opt.ord_opt_seq || prevUniq !== opt.uniq)) ? '★추가구매★' : '',
              name: (is_set_cnt == 0 && (prev_opt_seq !== opt.ord_opt_seq || prevUniq !== opt.uniq)) ? opt.ord_opt_name : null,
              cnt: temp_cnt,
              sku_cd: opt.sku_cd,
              stock_cd: opt.stock_cd,
              prod_name: opt.prod_name,
              attri: opt.attri_pack,
              add_opt: true,
              sale_cnt: opt.opt_sale_cnt,
              pack_unit: pack_unit,
              total_cnt: (opt.opt_sale_cnt * pack_unit),
              prod_img: opt.prod_img,
            });

            // 다중SKU매칭 확인하기 위해 직전 opt_seq 저장
            prev_opt_seq = opt.ord_opt_seq;
            prevUniq = opt.uniq;
            // 합계 계산용
            bundle_temp.all_pack_unit += pack_unit;
            bundle_temp.all_sale_cnt += opt.opt_sale_cnt;
            bundle_temp.all_total_cnt += (opt.opt_sale_cnt * pack_unit);
          }

          // 매칭된 사은품 출력
          gift.forEach((obj) => {
            item_list.push(obj);
            // 합계 계산용
            bundle_temp.all_pack_unit += obj.pack_unit;
            bundle_temp.all_sale_cnt += obj.sale_cnt;
            bundle_temp.all_total_cnt += (obj.sale_cnt * obj.pack_unit);
          });

          // 중복 메세지 병합 출력 체크했을 경우
          if ($scope.templateData.dup_ship_msg && _.uniq(ship_msg_list).length === 1) {
            ship_msg_list = _.uniq(ship_msg_list);
          }

          // 통화단위가 USD 인경우 "원" 이 아닌 "USD" 로 출력 2018-02-01 rony
          bundle_temp.currencyType = list[0].ord_curr_cd === 'USD' ? '(USD)' : '원';
          bundle_temp.currencyView = list[0].currencyType === '원' ? 0 : 2;
          bundle_temp.ship_msg = ship_msg_list.filter(Boolean).length ? `"${ship_msg_list.join('", "')}"` : '';
          if (bundle) {
            if (bundle.ship_cost_type === '3') {
              bundle_temp.ship_cost = bundle.ship_cost;
            }
          }
          tempData.push({
            ...bundle_temp,
            item_list
          });
          $scope.isPackingShow[idx] = idx < 3;
          idx++;
        }

        const pageIdx = _.size(uniqData) % 3 ? parseInt(_.size(uniqData) / 3) + 1 : parseInt(_.size(uniqData) / 3);

        for (let i = 0; i < pageIdx; i++) {
          $scope.page.push(i);
        }
        $scope.selectList = tempData;

        // 리스트에서 한개 선택 후 패킹리스트 인쇄했을 때 배송지 정보 오류나는거 수정 2018-06-12 Amelia
        if ($scope.selectList.length == 1) {
          $scope.singleFlag = true;
        }
      }
    }

    init();
  });
