'use strict';

angular.module('gmpApp')
  .controller('solIntroductionCtrl', function ($scope, $uibModalInstance, $state, userInfo) {

    $scope.user_name = userInfo.user.user_name;
    $scope.tabActive = 1;

    $scope.changeTab = (num = null) => {
      if (!num) { $scope.tabActive++; }
      else {
        $scope.tabActive = num;
      }
    };

    /**
     * 창 닫기
     */
    $scope.close = async () => {
      $uibModalInstance.close();
    };

    $scope.settings_shop_account = () => {
      $state.go('main.settings_shop_account');
      $uibModalInstance.close();
    };
  });