'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B325-Ctrl', function ($scope, $timeout, commonSVC, B325categorySVC, categoryModel, commonModel, $compile) {
    $scope.sel_category = ['', '', '', ''];
    $scope.cate = [[], [], [], []];
    $scope.disp_select_category = ['', '', '', ''];
    $scope.disp_cate = [[], [], [], []];
    $scope.CateData = angular.copy(B325categorySVC.category_data);
    $scope.dispCtg = angular.copy(B325categorySVC.dispCtg);
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.requireAttr = ''; //카테고리 속성 노출 안되게
    $scope.disenableSelectIconCd = true;

    let save_flag = false;    //카테고리 선택여부

    const setCateLoad = async () => {
      // 대분류 카테고리 조회 (일반카테고리와 전시카테고리가 같음)
      const res = await categoryModel.getScrapCategoryByDepth({
        shop_cd: 'B325',
        std_ol_yn: 0,
        shop_cate_see_type: '일반',
        shop_cate_see_cd: null,
        etc: { rainbowYn: $scope.CateData.detailData.rainbowYn }
      });

      $scope.cate[0] = res.data.result;
      $scope.disp_cate[0] = res.data.result;
    };

    /**
     * 저장정보 로드
     */
    const matchDetailDataLoad = async (shop_cate_info) => {
      $scope.CateData.detailData = shop_cate_info;

      await setCateLoad();

      if ($scope.CateData.detailData.category_code) {
        await setCategory($scope.CateData.detailData.category_code);
      }

      // 전시카테고리
      if ($scope.CateData.detailData.display_categories.length) {
        $scope.dispCtg.dispCtgSelectList = $scope.CateData.detailData.display_categories;

        for (const disp of $scope.dispCtg.dispCtgSelectList) {
          if (disp && disp.code) {
            const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${disp.code}"><span class="dispCtgNm">${disp.name}</span> <span class="dispCtgSelectRemoveBtn dispCtg badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

            $('.list-group.dispCtg').append(aElement);
            $compile(aElement)($scope);
          }
        }
      }
    };

    /**
     * 몰 선택
     */
    $scope.selectMall = async () => {
      if ($scope.CateData.detailData.rainbowYn) {
        if ($scope.sel_category.filter(o => o).length || $scope.disp_select_category.filter(o => o).length) {
          commonSVC.showMessage('알림', '몰 구분 변경으로 선택하신 카테고리 정보가 초기화 됩니다.');
        }

        $scope.cate = [];
        $scope.disp_cate = [];

        if ($('.list-group.dispCtg').length) {
          const li_items = $('.list-group.dispCtg')[0].getElementsByTagName('li');

          for (let itemIdx = li_items.length - 1; itemIdx >= 0; itemIdx--) {
            li_items[itemIdx].remove();
          }
        }

        await setCateLoad();
      }
    };

    /**
     * 카테고리 선택
     */
    $scope.select = async (idx, type) => {
      const cate = type === '일반' ? $scope.sel_category[idx] : $scope.disp_select_category[idx];

      if (!cate) {
        return false;
      }

      // 복지몰 일반카테고리 식품 대분류 선택시 식품 아이콘 활성화
      if ($scope.CateData.detailData.rainbowYn === 'Y' && type === '일반' && idx === 0) {
        if (['60000000', '65000000'].includes(cate.shop_cate_see_cd)) {
          $scope.disenableSelectIconCd = false;
        } else {
          $scope.disenableSelectIconCd = true;
        }
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < 4; i++) {
          if (type === '일반') {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          } else {
            $scope.disp_cate[i] = [];
            $scope.disp_select_category[i] = '';
          }
        }

        const res = await categoryModel.getScrapCategoryByDepth(cate);

        await new Promise(resolve => {
          $timeout(() => {
            if (type === '일반') {
              $scope.cate[idx + 1] = res.data.result;
            } else {
              $scope.disp_cate[idx + 1] = res.data.result;
            }
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return;
      } else if (!$scope.dispCtg.dispCtgSelectList.length) {
        commonSVC.showMessage('저장 실패', '전시카테고리는 한개이상 필수로 입력해주셔야 합니다.');

        return;
      }

      // 식품 카테고리인경우 식품 아이콘 필수
      if ($scope.category_names.includes('식품') && !$scope.CateData.detailData.key_iconCd && !$scope.disenableSelectIconCd) {
        commonSVC.showMessage('저장 실패', '식품카테고리인경우 식품 아이콘은 필수로 선택하셔야 합니다.');

        return;
      }

      let category_code = '';

      for (let i = 0; i < 4; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      $scope.CateData.detailData.category_names = $scope.category_names;
      $scope.CateData.detailData.category_code = category_code;

      if ($scope.form1.$valid) {
        const param = {
          site_code: $scope.matchingData.site_code,
          site_id: $scope.matchingData.site_id,
          category_code: category_code,
          category_codes: category_code,
          category_names: $scope.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: {
            ...$scope.CateData.detailData,
            display_categories: $scope.dispCtg.dispCtgSelectList
          },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          categoryModel.shopInfoDetailEdit(param, function (state, data) {

            if (state == 'success') {
              // 온라인 상품 수정이 아닌 경우에만 토스트 생성
              // Roy 2017-12-13 추가
              if (!event || (event && event.type !== 'online')) {
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              }

              param.shop_cate_no = data;

              $scope.returnAct(param);
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      } else {
        commonSVC.showToaster('error', '실패', '카테고리 정보 수정에 실패하였습니다.');
      }
    };

    /**
     * 카테고리 선택
     */
    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, '일반') : false;

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    /**
     * 초기화
     */
    const init = async () => {
      // 상품페이지
      if ($scope.matchingData.shop_cate_no_add_info) {
        await matchDetailDataLoad(typeof $scope.matchingData.shop_cate_no_add_info === 'string' ? JSON.parse($scope.matchingData.shop_cate_no_add_info) : $scope.matchingData.shop_cate_no_add_info);
      } else if ($scope.matchingData.sol_cate_shop_add_info) {
        await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
      } else {
        await matchDetailDataLoad(angular.copy(B325categorySVC.category_data).detailData);
      }
    };

    /**
     * 전시카테고리 추가
     */
    $scope.DispCateAdd = () => {
      const current_select_index = $scope.disp_select_category.filter(o => o.shop_cate_see_cd).length - 1;

      if (!$scope.disp_select_category[0] || !$scope.disp_select_category[current_select_index].end_yn) {
        commonSVC.showMessage('최하위 전시카테고리까지 모두 선택해 주세요.');

        return false;
      }

      const names = [],
            codes = [];

      for (let idx = 0; idx < 4; idx++) {
        if ($scope.disp_select_category[idx]) {
          names.push($scope.disp_select_category[idx].shop_cate_see_name);
          codes.push($scope.disp_select_category[idx].shop_cate_see_cd);
        }
      }

      const name = names.join(' >');
      const code = codes.join('_');

      if (!code) {
        return false;
      }

      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList.filter(o => o.name);

      for (const thisVal of dispCtgSelectList) {
        if (thisVal.code === code) {
          commonSVC.showMessage('이미 등록된 전시카테고리 입니다.');

          return false;
        }
      }

      $scope.dispCtg.dispCtgSelectList.push({ code, name });

      const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${code}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn dispCtg badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

      $('.list-group.dispCtg').append(aElement);
      $compile(aElement)($scope);
    };

    /**
     * 전시카테고리 삭제
     */
    $(document).on('click', '.dispCtgSelectRemoveBtn', function(e) {
      const dispType = e.target.classList[1];
      const dispCtgSelectList = $scope[dispType].dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;
      const item = $(e.target).parent();
      const code = item.attr('data-dispCtgCd');

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        const thisVal = dispCtgSelectList[i];

        if (thisVal == code) {
          dispCtgSelectList.splice(i, 1);
        }
      }

      item.remove();

      $scope[dispType].dispCtgSelectList = $scope[[dispType]].dispCtgSelectList.filter(o => o.code !== code);
    });

    init();
  });
