/**
 * 2018-03-30 Daniel
 * 주의사항
 */
'use strict';

angular.module('gmpApp')
  .service('cautionModel', function (settings, commonSVC) {
    const Url = `${settings.pa20ApiUrl}/app/guide`;

    return {
      /**
       * 주의사항 조회
       */
      getCaution: function (data) {
        const url = `${Url}/caution`;
        const params = {
          keyword: data || ''
        };

        return commonSVC.sendUrl('GET', url, params);
      },

      /**
       * 최신등록 주의사항 개수 조회 (일주일)
       */
      getNewCautionCount: function () {
        const url = `${Url}/caution/newCount`;
        const params = {};

        return commonSVC.sendUrl('GET', url, params);
      }
    };
  });
