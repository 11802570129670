/*
 *  detail-preview
 *  2017-09-25 roy
 */

'use strict';

angular.module('gmpApp')
  .directive('detailPreview', function ($rootScope, commonSVC, $state, shipmentModel, gettextCatalog) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/detail_preview.html',
      link: function ($scope, element, attrs) {
        $scope.number = attrs.number;
        $scope.type = attrs.type;
        $scope.sol_stock = $rootScope.user_profile.sol_stock;
        if ($scope.type == 'order') {
          order_preview($scope);
        } else if ($scope.type == 'order_call') {
          call_order_detail($scope);
        } else {
          log_preview($scope);
        }
      } };

    /*
    * 주문 요약 정보 불러오기
    */

    function call_order_detail($scope) {
      $scope.orderInfo = [];
      shipmentModel.load({ uniq: [$scope.params] }, function (state, data) {
        if (state == 'success') {
          $scope.orderInfo = [data.order_data[0].set_no ? data.order_data.find(order => order.main_prod_no === order.prod_no) : data.order_data[0]];

          $scope.orderInfo.forEach(order => {
            if (!order.set_no && order.sku_cd) {
              const prodNameList = order.prod_name_pack.split(';');
              order.skuList = order.sku_cd.split(';');

              order.prod_name_pack = prodNameList.length > 1 ? `${prodNameList[0]} (${order.skuList[0]}) 외 ${prodNameList.length - 1}건` : order.prod_name_pack;
              order.sku_cd = order.skuList.length > 1 ? '' : order.sku_cd;
            }

            order.prod_img = order.prod_img || '/assets/images/upload.png';
          });
        } else {
          commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
        }
      });
    }

    /*
    * 주문 요약 정보 미리보기
    */

    function order_preview($scope) {
      $scope.orderInfo = [];
      // prod_img => sale_img 로 변경 2018-04-10 rony
      // $scope.orderList[$scope.number].prod_img = $scope.orderList[$scope.number].prod_img || "/assets/images/upload.png";
      $scope.orderList[$scope.number].prod_img = $scope.orderList[$scope.number].sale_img || '/assets/images/upload.png';
      $scope.orderInfo.push($scope.orderList[$scope.number]);

      if ($state.current.name === 'main.order_shipment_order_list' || $state.current.name === 'main.order_shipment_delivery_list' || $state.current.name === 'main.order_shipment_unstoring_list' || $state.current.name === 'main.order_shipment_ebaydepot_list') {
        for (const item in $scope.orderList) {
          if ($scope.orderList[$scope.number].bundle_no == $scope.orderList[item].bundle_no && item != $scope.number) {
            // prod_img => sale_img 로 변경 2018-04-10 rony
            // $scope.orderList[item].prod_img = $scope.orderList[item].prod_img || "/assets/images/upload.png";
            $scope.orderList[item].prod_img = $scope.orderList[item].sale_img || '/assets/images/upload.png';
            $scope.orderInfo.push($scope.orderList[item]);
          }
        }
      }
    }

    /**
     * 로그 정보 미리보기
     */
    function log_preview($scope) {
      $scope.prodInfo = $scope.prodList[$scope.number];
    }
  });
