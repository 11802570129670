'use strict';

angular.module('gmpApp')
  .controller('guideCtrl', function ($scope, $rootScope, $timeout, $compile, $uibModalInstance, $q, settings, commonSVC, data, $state, $sce, helpSVC, helpModel, cautionSVC, cautionModel) {
    $scope.fold = false;
    $scope.newCautionCount = data.count;
    $scope.openValue = data.openValue;
    $scope.cautionList = [];
    $scope.selectShop = '';

    $rootScope.helpModalOpen = true;

    // 모달 축소
    $scope.folding = function() {
      if ($scope.fold) {
        $('#modal-help').parent().parent().parent().css({ width: $scope.save_modal_size.width, minWidth: '450px', height: $scope.save_modal_size.height, minHeight: '450px', resize: 'both' });
      } else {
        $scope.save_modal_size = {
          width: `${$('#modal-help').parent().parent().parent().css('width')}`,
          height: `${$('#modal-help').parent().parent().parent().css('height')}`,
        };
        $('#modal-help').parent().parent().parent().css({ width: '300px', minWidth: '300px', height: '45px', minHeight: '45px', resize: 'none' });
      }

      $scope.fold = !$scope.fold;
    };

    // 원격으로 모달 열기
    $rootScope.$on('helpFoldOpen', () => {
      if ($scope.fold) {
        $scope.folding();
      }
    });

    // 모달이 켜지면 Z-INDEX를 상위로 불러옴
    if ($rootScope.questionModalOpen) {
      $timeout(() => {
        $rootScope.draggableModalZindex('help');
      });
    }

    $uibModalInstance.closed.then(() => $rootScope.helpModalOpen = false);

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $rootScope.helpModalOpen = false;
      $uibModalInstance.dismiss('cancel');

      $rootScope.setLocalStorageWithExpire('forceOpenHelpModal', 'Y', true);
    };

    //
    $scope.helpTypes = angular.copy(helpSVC.helpTypes);
    $scope.searchData = angular.copy(helpSVC.searchData);
    $scope.detailData = angular.copy(helpSVC.detailData);

    $scope.rcmdHelp = [];
    $scope.pvHelp = [];

    const t = angular.copy($scope.helpTypes);

    delete t.ALL;
    delete t.ANO;

    const anoTypes = Object.keys(t);

    /**
     * 도움말 검색
     */
    $scope.searchHelp = function ($event) {
      if (!$event || $event.keyCode === 13) {
        const word = $scope.searchData.searchWord;
        const types = $scope.helpTypes;

        if (!word || word === '') {
          $scope.cancelSearch();

          return false;
        }

        if (word.length < 2) {
          return false;
        }

        shopInit(word);

        // 자주묻는 질문 검색
        helpModel.getPvHelp('', word).then((res) => {
          $scope.pvHelp = res.data.results;
        });

        helpModel.getSearchHelp(word)
          .then(function (res) {
            $scope.helpType = 'ALL';
            $scope.searchData.searchListAll = res.data.results;
            $scope.searchData.searchListView = splitArray(angular.copy($scope.searchData.searchListAll));

            const searchList = $scope.searchData.searchListAll;

            _.each(types, function (v, k) {
              switch (k) {
                case 'ALL':
                  types[k].count = searchList.length;
                  break;

                case 'ANO':
                  types[k].count = _.filter(searchList, function (o) {
                    return _.indexOf(anoTypes, o.guide_type_sub_cd) < 0;
                  }).length;
                  break;

                default:
                  var list = _.filter(searchList, { guide_type_sub_cd: k });
                  var count = list ? list.length : 0;

                  types[k].count = count;

                  break;
              }
            });

            $scope.detailData.detailYn = false;
            $scope.searchData.searchYn = true;
            $scope.searchData.page = 0;
          });
      }
    };

    /**
     * 검색 취소
     */
    $scope.cancelSearch = function () {
      $scope.helpType = 'ALL';
      $scope.searchData.searchWord = '';
      $scope.detailData.detailYn = false;
      $scope.searchData.searchYn = false;
      $scope.helpTypeChange('ALL');
      shopInit();
    };

    /**
     * 검색 문구 치환
     */
    $scope.replaceWord = function (word) {
      const w = $scope.searchData.searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '$&');
      const regex = new RegExp(w, 'gi');

      return word.replace(regex, `<span class='text-blue-400'>${w}</span>`);
    };

    /**
   * 검색 문구 하이라이트
   */
    $scope.replaceWordToHighlight = function (word) {
      const w = $scope.searchData.searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '$&');
      const regex = new RegExp(w, 'gi');

      return $sce.trustAsHtml(word.replace(regex, `<span style="background-color:yellow">${w}</span>`));
    };

    /**
     * 도움말 상세
     */
    $scope.helpDetail = function (guide_no) {
      helpModel.getHelpDetail(guide_no)
        .then(function (res) {
          $scope.detailData.data = res.data.results.detailData || { cd_name: '존재하지 않는 도움말 입니다' };
          $scope.detailData.data.attach_file = $scope.detailData.data?.attach_file?.split('#').map(file => JSON.parse(file));
          $scope.detailData.detailYn = true;
        });
    };

    $scope.trustHTML = (p0) => $sce.trustAsHtml(p0);

    /**
     * 도움말 타입선택
     */
    $scope.helpTypeChange = function (type) {
      $scope.helpType = type;
      $scope.searchData.page = 0;

      let list;
      let data = '';
      const searchList = angular.copy($scope.searchData.searchListAll);
      const types = angular.copy(anoTypes);

      switch (type) {
        case 'ALL':
          data = '';
          if ($scope.searchData.searchYn) {
            list = searchList;
          }
          break;

        case 'ANO':

          for (const t in types) {
            types[t] = `'${types[t]}'`;
          }

          data = `NOT IN(${types.join(',')})`;

          if ($scope.searchData.searchYn) {
            list = _.filter(searchList, function (o) {
              return _.indexOf(anoTypes, o.guide_type_sub_cd) < 0;
            });
          }
          break;

        default:
          data = `IN('${type}')`;

          if ($scope.searchData.searchYn) {
            list = _.filter(searchList, { guide_type_sub_cd: type });
          }
          break;
      }

      if ($scope.searchData.searchYn) {
        $scope.searchData.searchListView = splitArray(list);
      } else {
        $rootScope.$broadcast('helpTypeChange', type);
        init(data);
      }
    };

    /**
     * 홈페이지 새창으로 열기
     */
    $scope.openHomePage = function(guide_no) {
      guide_no = guide_no || ($scope.detailData.detailYn ? $scope.detailData.data.guide_no : '');

      // 2018-11-28 chris 메인 컨트롤러로 이동
      $rootScope.openHomePageHelp(guide_no);
    };

    function splitArray(array) {
      const result = [];

      const pageLength = $scope.searchData.pageLength;

      const allCount = array.length;
      const pageCount = Math.ceil(allCount / pageLength);

      for (let i = 0; i < pageCount; i++) {
        result[i] = array.splice(0, pageLength);
      }

      return result;
    }

    function init(data) {
      $q.all([helpModel.getRcmdHelp(data), helpModel.getPvHelp(data)])
        .then(function (resArr) {
          $scope.rcmdHelp = resArr[0].data.results;
          $scope.pvHelp = resArr[1].data.results;
          $('#scroll-body').scrollTop(0);
        });
    }

    function initType() {
      const state = $state.current.name;
      let type = '';

      // 스트링으로 들어올시 검색으로 라우팅
      if (typeof $scope.openValue === 'string') {
        $scope.helpType = 'ALL';
        $scope.searchData.searchWord = $scope.openValue;
        $scope.searchHelp();
      } else {
        // 999998 일시 전체카테고리로 열음
        if (state === 'main.home' || $scope.openValue === 999998) {
          type = 'ALL';
        } else if (state.indexOf('online') >= 0 || state.indexOf('category') >= 0 || state.indexOf('setForm') >= 0) {
          type = 'L101';
        } else if (state.indexOf('order_shipment') >= 0) {
          type = 'L102';
        } else if (state.indexOf('prod') >= 0 || state.indexOf('stock') >= 0 || state.indexOf('warehouse') >= 0 || state.indexOf('supplier') >= 0) {
          type = 'L104';
        } else if (state.indexOf('settings') >= 0 || state.indexOf('configs') >= 0) {
          type = 'L105';
        } else {
          type = 'ANO';
        }

        $scope.helpTypeChange(type);
      }
    }

    $scope.$on('$stateChangeSuccessGlobal', function () {
      initType();
    });

    $scope.$on('questionSearch', function (event, _data) {
      $rootScope.draggableModalZindex('help');
      $scope.newCautionCount = _data.data.count;
      $scope.openValue = _data.data.openValue;
      $scope.helpType = 'ALL';
      initType();

      if ($scope.openValue && $scope.openValue !== 999999) {
        $scope.helpDetail($scope.openValue);
      }

      if ($scope.openValue === 999999) {
        if ($scope.detailData.detailYn) {
          $scope.detailData.detailYn = false;
        }
        if ($scope.searchData.searchYn) {
          $scope.cancelSearch();
        }
        $timeout(() => {
          const offset = $('#help-shop').offset();

          $('#scroll-body').scrollTop($('#scroll-body').scrollTop() + offset.top - $('#scroll-body').offset().top);
          $scope.openValue = null;
        }, 500);
      } else {
        $('#scroll-body').scrollTop(0);
      }
    });

    /**
     * 신규체크
     */
    $scope.newCheck = function(wdate) {
      const now = moment();
      const end = moment(wdate);
      const duration = moment.duration(now.diff(end));
      const days = duration.asDays();

      return days < 7;
    };

    function shopInit(data) {
      cautionModel.getCaution(data)
        .then(function (res) {
          $scope.cautionList = [];

          const cautionList = _.groupBy(res.data.results, 'shop_cd');

          _.each(cautionList, function(site) {
            const caution = _.maxBy(site, 'wdate');

            $scope.cautionList.push({
              shop_cd: caution.shop_cd,
              shop_name: caution.shop_name,
              wdate_max: caution.wdate,
              group_cd_name: _.map(site, function(s) {
                return s.cd_name;
              }),
              group_content: _.map(site, function(s) {
                return s.content;
              })
            });
          });

          if ($scope.openValue === 999999) {
            $timeout(() => {
              const offset = $('#help-shop').offset();

              $('#scroll-body').scrollTop($('#scroll-body').scrollTop() + offset.top - $('#scroll-body').offset().top);
              $scope.openValue = null;
            }, 500);
          } else {
            $('#scroll-body').scrollTop(0);
          }
        });
    }

    initType();
    shopInit();

    if ($scope.openValue && $scope.openValue !== 999999) {
      $scope.helpDetail($scope.openValue);
    }
  });
