/**
 * Created by ally on 2017. 6. 8..
 */

'use strict';

angular.module('gmpApp')
  .controller('IntegratedMemoDownCtrl', function ($rootScope, $scope, data, $uibModalInstance, commonSVC, shipmentSVC, commonModel, systemModel, $timeout, memoSVC) {
    const apiUrl = data.url;

    if (data.progress) {
      $scope.percent = '0%';
      $scope.isFail = false;
    }

    $scope.page = data.page;
    $scope.title = data.title;
    $scope.description = data.description;
    $scope.count = data.count;
    $scope.tabIndex = data.tabIndex;
    $scope.visibleTable = data.visibleTable;
    $scope.type = data.type;
    $scope.user_profile = $rootScope.user_profile;
    $scope.masterProductCnt = Object.keys(_.groupBy(data.select_list, 'sale_no')).length;
    $scope.isAll = data.isAll;

    // 시스템 환경설정 구하기
    let systemList;

    systemModel.load().then(function (result) {
      systemList = result;
    });
    // G마켓물류주문 전용
    $scope.ebaydepot = {
      yn: data.ebaydepotYn || false,
      down: false
    };

    // 엑셀 다운로드 필드 활성화된 필드로 셋팅
    angular.forEach(data.excelFieldList, function(value, key) {
      angular.forEach($scope.visibleTable, function(visible) {
        data.excelFieldList[key].select = false;
        // 툴팁때문에 선택안돼서 툴팁제거
        visible.header = visible.header.replace(/<i.*\/i>/, '');

        if (value.header == visible.header || value.noneHeader) {
          data.excelFieldList[key].select = true;
        }
      });
    });
    /**
      * 2018-05-03 roy
      * SKU상품 배송처, 매입처 사용함만 다운로드할 수 있도록 설정
      */
    $scope.etcFiled = {
      useDepot: 1,
      useSupp: 1
    };
    const page = data.page;

    function init () {
      // 출고가능여부 출력 field name 값 변경으로 인해 로컬스토리지 저장명 수정. 2018-03-16
      // var settings = JSON.parse(localStorage.getItem('gmp_excel_download_'+page));
      // 로컬 스토리지에 저장된 선택 항목
      const settings = JSON.parse(localStorage.getItem(`gmp_excel_download_${page}_1`));
      // 엑셀다운로드 가능한 항목
      const fieldList = data.excelFieldList;

      // 기본 엑셀다운로드 가능한 항목에서 로컬 스토리지에 저장된 선택 항목이 있는경우 select 값만 업데이트
      if (settings) {
        _.each(fieldList, function(v) {
          const ss = _.find(settings.field, { key: v.key });

          if (ss) {
            v.select = ss.select;
          }
        });
      }

      $scope.option = {
        fileExt: settings ? settings.ext : 'xlsx'
      };
      $scope.fieldList = fieldList; // data.excelFieldList.length > 0 && settings ? settings.field : data.excelFieldList;
      $scope.allSelected = _.findIndex($scope.fieldList, { select: false }) === -1;
    }

    /**
     * 설정값 로컬스토리지에 저장
     * */
    function save() {
      if (page) {
        const val = {
          ext: $scope.option.fileExt,
          field: $scope.fieldList
        };

        // 출고가능여부 출력 field name 값 변경으로 인해 로컬스토리지 저장명 수정. 2018-03-16
        // localStorage.setItem('gmp_excel_download_'+page, JSON.stringify(val));
        localStorage.setItem(`gmp_excel_download_${page}_1`, JSON.stringify(val));
      }
    }

    /**
      * 전체선택 버튼 클릭
      * */
    $scope.allSelectToggle = function() {

      if ($scope.allSelected) {
        _.forEach($scope.fieldList, function(item) {
          item.select = false;
        });
        $scope.allSelected = false;
      } else {
        _.forEach($scope.fieldList, function(item) {
          item.select = true;
        });
        $scope.allSelected = true;
      }
    };

    /**
      * 버튼 클릭
      * */
    $scope.selectToggle = function(item) {
      $scope.allSelected = _.findIndex($scope.fieldList, { select: false }) === -1;
    };

    /**
      * 다운로드 버튼 비활성화 여부 확인
      */
    $scope.checkBtnDisable = () => {
      return $scope.fieldList?.length && $scope.fieldList.every(({ select }) => !select);
    };

    /**
      * 다운로드
      * */
    $scope.download = function() {

      // 출력될 데이터가 없는 경우 에러.
      if ($scope.count < 1) {
        commonSVC.showMessage('다운로드 받을 내역이 없습니다.');

        return false;
      }

      const downList = angular.copy(_.filter($scope.fieldList, 'select'));

      const checkFieldList = {};
      downList.forEach(list => {
        if (list.select) {
          checkFieldList[list.key] = memoSVC.excelFieldList[list.key];
        }
      });

      if (data.isAll && $scope.fieldList.length > 0 && downList.length === 0) {

        commonSVC.showMessage('다운받을 항목를 선택해주세요.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      } else {
        let params = {
          down_list: data.isAll ? [ checkFieldList ] : [memoSVC.excelFieldList],
          file_ext: $scope.option.fileExt,
          cate_id: data.cateId,
          sheet_name: data.sheetName || $scope.title,
          file_name: $scope.title,
          is_all: data.isAll,
          type: data.type,
          is_ebaydepot: $scope.ebaydepot.down,
          sol_stock: $scope.user_profile.sol_stock,
          etcFiled: $scope.etcFiled,
          shop_id_view_type: systemList.data.shop_id_view_type
        };

        if (data.searchForm) {
          params = angular.merge(params, data.searchForm);
        }

        // 일반, 단일 온라인 상품인지 구분
        switch ($scope.tabIndex) {
          case 0: // 일반 상품
            params.onlineType = '일반';
            break;
          case 1: // 단일 상품
            params.onlineType = '단일';
            break;
           // case 2: // 위메프 2.0 상품
             // params.onlineType = "위메프2.0";
             // break;
        }

        // 선택 다운로드
        if (!data.isAll) {
          if (data.uniq) {
            params.uniq = data.uniq;
          }
          if (data.number) {
            params.number = data.number;
          }
          if (data.sku_code) {
            params.sku_code = data.sku_code;
          }
          if (data.select_list) {
            params.select_list = data.select_list;
          }
          if (data.list) {
            params.list = data.list;
          }
        }

        if (data.orderby) {
          params.orderby = data.orderby;
        }

        if ($scope.percent) {
          $('#ext-select').css({ display: 'none' });
          $('.progress-bar-dir').css({ display: 'inline-block' });
        }

        // 2000건당 1분
        if (data.isAll) {
          params.timeout = Math.ceil(parseInt(data.count) / 2000) * 60 * 1000;
        }

        commonModel.excelDownload(apiUrl, params, function (state, result) {
          if (state == 'success') {

            if (data.progress) {
              $scope.isFinish = true;
              $scope.percent = '100%';
            }

            const newWin = window.open(result.url);

            if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
              commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
            } else {
              commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
            }
            // window.open(result.url,'');
            if ($scope.fieldList.length > 0 && !$scope.ebaydepot.yn) {
              save();
            }
            $uibModalInstance.close('success');
          } else {
            if ($scope.percent) {
              $('#ext-select').css({ display: 'block' });
              $('.progress-bar-dir').css({ display: 'none' });
            }

            commonSVC.showToaster('error', '실패', result.data.error || '엑셀 다운로드에 실패하였습니다.');

            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          }
        });
      }
    };

    /**
      * 모달창 닫기
      * */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    init();
  });
