/**
 *  재고 관리 제외버전 서비스
 *  2018-04-06 Daniel
 */

'use strict';

angular.module('gmpApp')
  .service('stockExceptionSVC', function ($rootScope) {
    // private
    function Exception(page, scope) {
      let dtOptions, searchData, searchDetail;

      switch (page) {
      // 주문관리
        // 결제완료
        case 'payment_list':
          /*
            0: 순번, 1: 선택박스, 2: 설정, 3: 쇼핑몰(계정), 4: 쇼핑몰 주문번호, 5: 주문수집일, 6: 쇼핑몰6상품코드, 7: 온라인7상품명, 8:옵션, 9: 주문수량
            10: 추가구매옵션, 11: 주문자(ID), 12: 수령자명, 13: 배송메세지, 14: 주소, 15: 금액, 16: 배송비, 17: 주문상태
            18: 주문일, 19: 결제완료일, 20: 상태변경일, 21: 국가, 22: 개인통관번호, 23: 배송처, 24: 주문자 전화번호, 25: 주문자 휴대폰번호
            26: 수령자 전화번호, 27: 수령자 휴대폰번호, 28: 판매자관리코드, 29: 사은품, 30: 결제통화, 31: 배송방법, 32: 엑셀 다운일시
          */

          dtOptions = scope.grid.dtOptions;

          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[3].targets.pop();

          // 재고관리 전용필드 제거.
          dtOptions.columns.splice(29, 1);    // SKU 코드

          searchData = scope.searchData;

          searchData.search_key_items.splice(4, 1);       // SKU코드
          searchData.search_multi_items.splice(3, 1);     // SKU코드

          scope.excelFieldList.splice(27, 1);             // SKU코드
          break;

        // 신규주문
        case 'order_list':

          /*
          재고관리 미사용 버전일 때 들어오늘 컬럼 인덱스

          0: 순번, 1: 선택박스, 2: 설정, 3: 묶음번호, 4: 배송처, 5: 쇼핑몰(계정), 6: 쇼핑몰 주문번호, 7: 주문수집일, 8:쇼핑몰 상품코드, 9: 쇼핑몰 상품명, 10: 옵션,
          11: 주문수량, 12: 추가구매옵션, 13: 주문자(ID), 14: 수령자명, 15: 배송메세지, 16: 주소, 17: 금액,
          18: 배송비, 19:발송예정일, 20: 배송지연여부, 21: 주문상태, 22: 주문일, 23: 결제완료일, 24: 상태변경일, 25: 주문확인일
          26: 국가, 27: 개인통관번호, 28: 주문자 전화번호, 29: 주문자 휴대폰번호, 30: 수령자 전화번호, 31: 수령자 휴대폰번호, 32: 판매자관리코드, 33:건별출고수량
          34: 규칙적용사은품, 35: 사은품, 36: 결제통화, 37: 배송방법, 38: 원가, 39: 공급가, 40: 엑셀 다운일시
        */

          dtOptions = scope.grid.dtOptions;

          dtOptions.columnDefs[1].targets.pop();      // 가운데 정렬
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.splice(dtOptions.columnDefs[1].targets.indexOf(9), 1); // 2018-10-16 신규주문 상품명 왼쪽 정렬
          dtOptions.columnDefs[1].targets.push(11);
          dtOptions.columnDefs[1].targets.splice(dtOptions.columnDefs[1].targets.indexOf(16), 1); // 2018-10-16 신규주문 주소 왼쪽 정렬
          dtOptions.columnDefs[1].targets.push(17);
          dtOptions.columnDefs[2].targets.pop();      // 오른쪽 정렬
          dtOptions.columnDefs[2].targets.pop();
          dtOptions.columnDefs[3].targets.pop();      // 노출여부
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.push(26);
          dtOptions.columnDefs[3].targets.splice(5, 1);

          // 주문수집일 정렬 셋팅
          dtOptions.order = [[ 6, 'desc' ]];

          // 재고사용안할경우 필드 노출이 정상적이지 않아 수정 2018-06-18 rony
          dtOptions.columns.splice(37, 1);    // 건별출고수량
          dtOptions.columns.splice(36, 1);    // SKU상품명
          dtOptions.columns.splice(35, 1);    // SKU
          dtOptions.columns.splice(6, 1);     // 매칭여부
          // dtOptions.columns.splice(3, 1);     // 출고가능

          searchData = scope.searchData;

          searchData.search_key_items.splice(7, 1);       // SKU상품명
          searchData.search_key_items.splice(4, 1);       // SKU코드
          searchData.search_multi_items.splice(3, 1);     // SKU코드

          searchDetail = scope.searchDetail;

          searchDetail.pop(); // 매칭여부 삭제

          scope.excelFieldList.splice(36, 1);     // 건별출고수량
          scope.excelFieldList.splice(35, 1);     // SKU상품명
          scope.excelFieldList.splice(34, 1);     // SKU코드
          scope.excelFieldList.splice(20, 1);     // 매칭여부
          // scope.excelFieldList.splice(1, 1);      // 출고가능여부
          break;

        // 출고관리
        case 'unstoring_list':
          /**
            0:순번 1:선택박스, 2:설정, 3:묶음번호, 4:출고가능여부, 5:출고상태, 6:출고지시일, 7:건별출고수7, 8:수령자명, 9:택배사, 10:운송장번호, 11:발송예정일,
            12:쇼핑몰(계정), 13:쇼핑몰주문번호, 14:쇼핑몰상품코드, 15:온라인상품명, 16:옵션, 17:주문수량, 17:추가구매옵션 19:주문자(ID), 20:배송메세지, 21:수령자주소,
            22:수령자휴대폰번호, 23:국가, 24:개인통관번호, 25:배송처, 26:규칙적용사은품, 26:사은품, 28:배송방법, 29:배송비, 30:출고완료일, 31:주문수집일, 32:주문일,
            33:결제완료일, 34:상태변경일, 34:주문확인일, 36:금액, 37:배송지연여부, 38:주문자전화번호, 39:주문자휴대폰번호, 40:수령자전화번호, 41:판매자관리코드,
            42:결제통화, 43:바코드, 44:엑셀다운일시, 45:원가, 46:공급가
           */

          dtOptions = scope.grid.dtOptions;

          dtOptions.columns.splice(10, 1);   // 건별출고수량
          dtOptions.columns.splice(9, 1);   // SKU상품명
          dtOptions.columns.splice(8, 1);   // SKU 코드
          dtOptions.columns.splice(5, 1);   // 매칭여부

          // 위에서 재고사용안함시 노출되면 안되는 필드를 제거했으므로 배열이 초과되는 항목은 제거해야함.
          dtOptions.columnDefs[1].targets.pop();      // 가운데정렬
          dtOptions.columnDefs[1].targets.pop();      // 가운데정렬
          dtOptions.columnDefs[1].targets.push(16);   // 주문수량 가운데정렬시킴
          dtOptions.columnDefs[1].targets.splice(dtOptions.columnDefs[2].targets.indexOf(14), 1); // 2018-10-16 출고관리 상품명 왼쪽 정렬
          dtOptions.columnDefs[1].targets.push(17);
          dtOptions.columnDefs[2].targets.pop();      // 오른쪽정렬
          dtOptions.columnDefs[2].targets.pop();
          dtOptions.columnDefs[2].targets.splice(dtOptions.columnDefs[3].targets.indexOf(20), 1); // 2018-10-16 출고관리 주소 왼쪽 정렬
          dtOptions.columnDefs[2].targets.push(22);
          dtOptions.columnDefs[3].targets.pop();      // 노출여부
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.push(30);

          // 주문수집일 정렬 셋팅
          dtOptions.order = [[ 6, 'desc' ]];

          searchData = scope.searchData;

          searchData.search_key_items.splice(8, 1);   // SKU상품명
          searchData.search_key_items.splice(5, 1);   // SKU코드
          searchData.search_multi_items.splice(3, 1); // SKU코드

          searchDetail = scope.searchDetail;

          searchDetail.pop(); // 매칭여부 삭제

          scope.excelFieldList.splice(36, 1);   // 매칭여부
          scope.excelFieldList.splice(20, 1);   // 총출고수량
          scope.excelFieldList.splice(7, 1);    // 건별출고수량
          scope.excelFieldList.splice(6, 1);    // SKU상품명
          scope.excelFieldList.splice(5, 1);    // SKU 코드

          break;

        // 엑셀 템플릿
        case 'excel_template':
          scope.columnList.splice(3, 1);
          break;

        // 사은품
        case 'gift_rule':
          // 2020-02-17 Boris 테이블에서 SKU코드 제거
          scope.grid.options.columns.splice(6, 1);
          // dtOptions = scope.grid.dtOptions;

          // dtOptions.columnDefs[0].targets.pop();
          // dtOptions.columnDefs[1].targets.pop();
          // dtOptions.columns.splice(7, 1);
          break;

        // 배송관리
        case 'delivery_list':
          /*
            0: 순번, 1: 선택박스, 2: 설정, 3: 묶음번호, 4: 주문상태, 5: 쇼핑몰(계정), 6: 쇼핑몰 주문번호, 7: 주문수집일, 8: 쇼핑몰 상품코드, 9: 쇼핑몰 상품명,
            10: 옵션, 11: 주문수량, 12: 추가구매옵션, 13: 택배사, 14: 운송장번호, 15: 송장 전송일, 16: 주문자(ID), 17: 수령자명, 18: 배송메세지, 19: 주소, 20: 금액,
            21: 배송비, 22: 규칙적용사은품, 23: 사은품, 24: 주문일, 25: 결제완료일, 26: 상태변경일, 27: 주문확인일, 28: 출고완료일, 29: 국가, 30: 개인통관번호,
            31: 배송처, 32: 주문자 전화번호, 33: 주문자 휴대폰번호, 34: 수령자 전화번호, 35: 수령자 휴대폰번호, 36: 판매자관리코드, 37: 건별출고수량, 38: 결제통화,
            39: 배송방법, 40: 원가, 41: 공급가, 42: 배송지연여부, 43: 발송예정일, 44: 엑셀다운일시
          */
          dtOptions = scope.grid.dtOptions;

          dtOptions.columnDefs[0].targets.splice(dtOptions.columnDefs[0].targets.indexOf(4), 1);
          // 가운데정렬-----------------------------------------------------
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.splice(dtOptions.columnDefs[1].targets.indexOf(9), 1); // 2018-10-16 배송관리 상품명 왼쪽 정렬
          dtOptions.columnDefs[1].targets.push(10);
          // 오른쪽 정렬 ----------------------------------------------------
          dtOptions.columnDefs[2].targets.pop();
          dtOptions.columnDefs[2].targets.splice(dtOptions.columnDefs[2].targets.indexOf(12), 1);
          // 노출여부 -------------------------------------------------------
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.push(30);
          dtOptions.columnDefs[3].targets.splice(6, 1);

          // 주문수집일 정렬 셋팅
          dtOptions.order = [[ 7, 'desc' ]];

          // 재고관리 전용필드 제거
          dtOptions.columns.splice(40, 1);            // 건별출고수량
          dtOptions.columns.splice(39, 1);            // SKU상품명
          dtOptions.columns.splice(38, 1);            // SKU코드
          dtOptions.columns.splice(4, 1);             // 매칭여부

          searchData = scope.searchData;

          searchData.search_key_items.splice(8, 1);   // SKU상품명
          searchData.search_key_items.splice(5, 1);   // SKU코드
          searchData.search_multi_items.splice(3, 1); // SKU코드

          searchDetail = scope.searchDetail;

          searchDetail.pop(); // 매칭여부 삭제

          scope.excelFieldList.splice(38, 1);         // 건별출고수량
          scope.excelFieldList.splice(37, 1);         // SKU상품명
          scope.excelFieldList.splice(36, 1);         // SKU코드
          scope.excelFieldList.splice(2, 1);          // 매칭여부
          break;

        // 전체주문조회
        case 'integrated_list':
          /*
            0: 순번, 1: 선택박스, 2: 설정, 3: 주문상태, 4: 쇼핑몰(계정), 5: 쇼핑몰 주문번호, 6: 주문수집일, 7: 쇼핑몰 상품코드, 8: 쇼핑몰 상품명, 9: 옵션,
            10: 주문수량, 11: 추가구매옵션, 12: 주문자(ID), 13: 수령자명, 14: 클레임사유, 15: 배송메세지, 16: 주소, 17: 택배사, 18: 운송장번호, 19: 송장전송일,
            20: 금액, 21: 배송비, 22: 규칙적용사은품, 23: 사은품, 24: 배송지연여부, 25: 발송예정일, 26: 묶음번호, 27: 주문일, 28: 결제완료일, 29: 상태변경일,
            30: 주문확인일, 31: 출고완료일, 32: 국가, 33: 개인통관번호, 34: 배송처, 35: 주문자 전화번호, 36: 주문자 휴대폰번호, 37: 수령자 전화번호, 38: 수령자 휴대폰번호,
            39: 판매자관리코드, 40: 결제통화, 41: 배송방법, 42: 원가, 43: 공급가, 44: 엑셀 다운일시, 45: 바코드, 46: 매입처
          */
          dtOptions = scope.grid.dtOptions;

          dtOptions.columnDefs[0].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[1].targets.pop();
          dtOptions.columnDefs[2].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();

          // 재고관리 전용필드 삭제
          dtOptions.columns.splice(42, 1);            // 건별출고수량
          dtOptions.columns.splice(41, 1);            // SKU상품명
          dtOptions.columns.splice(40, 1);            // SKU 코드

          searchData = scope.searchData;

          searchData.search_key_items.splice(8, 1);   // SKU상품명
          searchData.search_key_items.splice(5, 1);   // SKU코드
          searchData.search_multi_items.splice(3, 1); // SKU코드

          searchDetail = scope.searchDetail;

          searchDetail.pop(); // 매칭여부 삭제

          scope.excelFieldList.splice(39, 1);         // 건별출고수량
          scope.excelFieldList.splice(38, 1);         // SKU상품명
          scope.excelFieldList.splice(37, 1);         // SKU코드
          break;

        // 클레임관리
        case 'claim_list':
        /*
          0: 순번, 1: 선택박스, 2: 설정, 3: 주문상태, 4: 쇼핑몰(계정), 5: 쇼핑몰 주문번호, 6: 주문수집일, 7: 쇼핑몰 상품코드, 8: 쇼핑몰 상품명, 9: 옵션, 10: 주문수량,
          11: 추가구매옵션, 12: 주문자(ID), 13: 수령자명, 14: 클레임 사유, 15: 배송메세지, 16: 주소, 17: 택배사, 18: 운송장번호, 19: 송장 전송일, 20: 금액, 21: 배송비,
          22: 규칙적용사은품, 23: 사은품, 24: 배송지연여부, 25: 발송예정일, 26: 묶음번호, 27: 주문일, 28: 결제완료일, 29: 상태변경일, 30: 주문확인일, 31: 출고완료일, 32: 국가,
          33: 개인통관번호, 34: 배송처, 35: 주문자 전화번호, 36: 주문자 휴대폰번호, 37: 수령자 전화번호, 38: 수령자 휴대폰 번호, 39: 판매자관리코드, 40: 결제통화, 41: 배송방법,
          42: 원가, 43: 공급가, 44: 엑셀 다운일시
        */
          dtOptions = scope.grid.dtOptions;

          dtOptions.columnDefs[1].targets.pop();      // 가운데정렬
          dtOptions.columnDefs[2].targets.pop();      // 오른쪽정렬
          dtOptions.columnDefs[2].targets.pop();
          dtOptions.columnDefs[3].targets.pop();      // 노출여부
          dtOptions.columnDefs[3].targets.pop();
          dtOptions.columnDefs[3].targets.pop();

          // 재고관리 전용필드 삭제.
          dtOptions.columns.splice(42, 1);            // 건별출고수량
          dtOptions.columns.splice(41, 1);            // SKU상품명
          dtOptions.columns.splice(40, 1);            // SKU 코드

          searchData = scope.searchData;

          searchData.search_key_items.splice(8, 1);   // SKU상품명
          searchData.search_key_items.splice(5, 1);   // SKU코드
          searchData.search_multi_items.splice(3, 1); // SKU코드

          scope.excelFieldList.splice(39, 1);         // 건별출고수량
          scope.excelFieldList.splice(38, 1);         // SKU상품명
          scope.excelFieldList.splice(37, 1);         // SKU 코드
          break;

        // 주문관리 정산
        case 'order_list2':
          // 재고 미사용 버전에서는 재고관련 정보가 미노출되어야 합니다.
          scope.grid.options.columns = scope.grid.options.columns
            .filter(col => !['cost_price_cal', 'predict_margin', 'predict_margin_per', 'margin', 'margin_per', 'margin_err', 'margin_per_err'].includes(col.key));
          break;

        // 쇼핑몰 상품 관리
        case 'list':
          searchData = scope.searchData;

          searchData.search_key_items.splice(1, 1);
          searchData.search_multi_items.splice(2, 1);
          break;

        // 삭제 내역 관리
        case 'del_history':
          searchDetail = scope.searchDetail;

          searchDetail[0].item_list.shift();
          break;
      }
    }

    // public
    return {
      scopeExcept: function(page, scope) {
        if ($rootScope.user_profile.sol_stock < 1) {
          Exception(page, scope);
        }
      }
    };

  });
