'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P020-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonModel, commonSVC) {
    $scope.formHolder = {};
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.cateAttrs = [];
    $scope.row.set_info.attributes = $scope.row.set_info.attributes || [];
    $scope.notiData = data.data.prod_noti_data_new.map(data => data?.infoCode).filter(Boolean);
    $scope.checkIsImportNoti = [
      'Wear2023',
      'Shoes2023',
      'Bag2023',
      'FashionItems2023',
      'SleepingGear2023',
      'Furniture2023',
      'ImageAppliances2023',
      'HomeAppliances2023',
      'SeasonAppliances2023',
      'OfficeAppliances2023',
      'OpticsAppliances2023',
      'MicroElectronics2023',
      'CellPhone2023',
      'Navigation2023',
      'CarArticles2023',
      'MedicalAppliances2023',
      'KitchenUtensils2023',
      'Jewelry2023',
      'ProcessedFood2023',
      'HealthFunctionalFood2023',
      'Kids2023',
      'MusicalInstrument2023',
      'SportsEquipment2023'
    ].filter(data => $scope.notiData.includes(data)).length;

    $scope.checkFoodGMO = [ 'ProcessedFood2023', 'HealthFunctionalFood2023'].filter(data => $scope.notiData.includes(data)).length;
    $scope.checkFoodRisk = ['ProcessedFood2023'].filter(data => $scope.notiData.includes(data)).length;

    // 카테고리
    let siteCategoryCodes = [];

    if ($scope.data.shop_cate1_cd && $scope.data.shop_cate1_cd !== '0') {
      siteCategoryCodes = [$scope.data.shop_cate1_cd, $scope.data.shop_cate2_cd, $scope.data.shop_cate3_cd, $scope.data.shop_cate4_cd];
    } else if ($scope.data.sol_cate_shop_add_info) {
      siteCategoryCodes = $scope.data.sol_cate_shop_add_info.category_code.split('_');
    } else {
      siteCategoryCodes = $scope.row.match_cate_code.split('_');
    }

    /**
       * PA5 실시간 호출.
       */
    const getCateAttrs = async () => {
      try {
        const re = await commonModel.pa5('/channel_accounts/{uuid}/etc/get_category_attrs/sync', { code: siteCategoryCodes[siteCategoryCodes.length - 1] }, $scope.row.pa_shop_cd, $scope.row.shop_id);

        if (re.data.has_error == false) {
          const attributes = [ ...$scope.row.set_info.attributes];

          $scope.cateAttrs = re.data.results[0].data.attributes;
          $scope.row.set_info.attributes = [];

          $scope.cateAttrs.map(attr => {
            const matched = attributes.find(o => o.attr_code == attr.attr_code);

            if (matched) {
              $scope.row.set_info.attributes.push({
                attr_code: +matched.attr_code,
                attr_value: matched.attr_value
              });
            } else {
              $scope.row.set_info.attributes.push({});
            }
          });

          $timeout(() => {}, 100);
        } else {
          commonSVC.showMessage('실패', `카테고리 속성정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', '카테고리 속성정보 로딩에 실패하였습니다.');
      }
    };

    // 카테고리 정보가 있는경우 속성정보 조회.
    if (siteCategoryCodes.length) {
      getCateAttrs();
    }

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if (!$scope.formHolder.form1.$valid) {
        commonSVC.showMessage('필수값을 모두 입력해주세요.');

        return false;
      }
      // 속성정보는 하나이상 필수
      $scope.row.set_info.attributes = $scope.row.set_info.attributes.filter(o => o.attr_code && o.attr_value);

      // 속성정보가 존재하지 않는 경우 선택여부 검사 X
      if ($scope.cateAttrs.length && !$scope.row.set_info.attributes.length) {
        commonSVC.showMessage('속성정보는 하나이상 선택해주셔야 합니다.');

        return false;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });