'use strict';

angular.module('gmpApp')
  .factory('A085categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_Tctg',
        1: 'key_Lctg',
        2: 'key_Mctg',
        3: 'key_Sctg',
        4: 'key_Dctg'
      },

      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        php_SiteNo: [],
      },

      // 전시카테고리
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: [],
        searchResultList: [],
        siteNo: {
          6005: 'SSG.COM몰',
          6009: '신세계백화점몰',
        },
      },
    };
  });
