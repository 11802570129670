'use strict';

angular.module('gmpApp')
  .controller('VersionAdvisorController', function ($scope, $uibModalInstance, data, userInfo) {
    // 쇼핑몰, 사용자
    $scope.type = data.type == 'user' ? '사용자' : '쇼핑몰';

    // 현재 솔루션 정보
    $scope.currentInfo = {
      accountCnt: userInfo.user[`${data.type}AccountCnt`],
      versionCnt: data.currentInfo[`${data.type}VersionCnt`]
    };

    // 추천 솔루션 정보
    $scope.recommendInfo = data.recommendInfo;

    $scope.close = (result) => {
      $uibModalInstance.close(result);
    };
  });
