/**
 * Created by ally on 2017. 6. 28..
 */
'use strict';

angular.module('gmpApp')
  .controller('LogConfigsHistoryCtrl',
    function ($scope, $timeout, gettextCatalog, settings, commonSVC, historySVC, systemList) {

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: 'all',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD')
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectCount: 0,       // 검색전체 건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            { label: gettextCatalog.getString('항목'), value: 'col_name' },
            { label: gettextCatalog.getString('변경내용'), value: 'col_data' },
            { label: gettextCatalog.getString('작업자'), value: 'wm_name' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('날짜'), value: 'wdate' },
          ],
        },

      };

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchBtn = { actions_right: [],
        table_actions: [
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀 다운로드</span>' + '<span class="caret"></span>',
            small_label: '주문수집',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('전체 엑셀 다운로드'),
                action: function () {
                  $scope.excelDown('all');
                },
                perm_only: ['online.product']
              }, {
                label: gettextCatalog.getString('선택 엑셀 다운로드'),
                action: function () {
                  $scope.excelDown('select');
                },
                perm_only: ['online.product']
              }
            ]
          }
        ],

        // 검색 영역 선택한 데이터 버튼
        actions_left: [

          // 엑셀다운로드
          [
            {
              label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('전체 엑셀 다운로드')}`,
              btn_type: 'button',
              action: function () {
                $scope.excelDown('all');
              }
            },
            {
              label: `<i class="icon-file-excel text-success"></i> ${gettextCatalog.getString('선택 엑셀 다운로드')}`,
              btn_type: 'button',
              action: function () {
                $scope.excelDown('select');
              }
            }
          ]
        ],
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },

      };

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['wdate'],
        alignCenterColumns: ['wdate', 'wm_name'],
        alignRightColumns: [],
        defaultSortingColumns: ['wdate'],
        notSortingColumns: ['wdate', 'col_name', 'col_data', 'wm_name'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/history/setting`,
          requestWillAction: function(data) {
            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            $scope.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            key: 'wdate',
            title: '날짜',
            width: 150,
            filter: 'dateValid'
          },
          {
            key: 'col',
            title: '구분',
            width: 50
          },
          {
            key: 'col_name',
            title: '항목',
            width: 150
          },
          {
            key: 'col_data',
            title: '변경내용',
            width: 150
          },
          {
            key: 'wm_name',
            title: '작업자',
            width: 50
          }

        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 0;
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name === 'main.log_configs_history') {
          const $wrapper = $('#log_configs_history_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      // 엑셀다운
      $scope.excelDown = function (type) {
        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: angular.copy(historySVC.configsexcelFieldList),
          title: '환경설정 변경 내역',
          url: '/app/history/setting/excelDown',
          searchForm: $scope.pageData,
          page: 'configs_history'
        };

        if (type === 'all') {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
        } else {
          const selectList = $scope.grid.methods.selectedData('all');

          if (selectList.length === 0) {
            commonSVC.showMessage('선택된 내역이 없습니다.');

            return false;
          } else {
            resolve.data.select_list = selectList;
            resolve.data.count = selectList.length;
            resolve.data.isAll = false;
          }
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };
    });
