/*
 *  ptgui-imagebox 컴퍼넌트
 *  2016-04-29 권윤학
 */

'use strict';

angular.module('ptgui.imagebox', [
  'ngFileUpload'
])
  .directive('ptguiImagebox', ['$compile', function($compile) {
    return {
      templateUrl: 'scripts/libs/ptgui/ptgui-imagebox.html',
      restrict: 'E',
      require: ['ngModel'],
      scope: {
        ngModel: '=',
        ngEsm: '=',
        olAdd: '=',
        ngShowModel: '='
      },
      replace: true,
      link: function ($scope, element, attrs) {
        /*
        *2017-09-18 roy 추가
        *이미지 선택시 취소를 누를경우 리셋되는걸 방지하기 위한 처리
        */

        $scope.$watch('ngModel', function(newFile, oldFile) {
          if (newFile || newFile === '') {
            $scope.ngModel = newFile;
          } else if (oldFile) {
            $scope.ngModel = oldFile;
          } else {
            $scope.ngModel = '';
          }
        });
      }
    };
  }]);
