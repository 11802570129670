'use strict';
/**
 *  권한 관리 분리
 *  2017-02-03 MatthewKim
 */
angular.module('gmpApp')

  /**
   * 화면별 권한 설정
   *
   * 접근 권한을 부여해야할 경우 최소단위로 여기서 권한을 만들고, 유저의 권한에 따라 가/불 여부를 리턴 한다.
   * https://github.com/Narzerus/angular-permission/wiki/Managing-permissions
   *
   * 2017-02-03 MatthewKim
   */
  .run(function (PermPermissionStore, userInfo) {

    // 초기화 먼저
    PermPermissionStore.clearStore();

    // 로그인 유저의 권한 설정 2017-02-07 MatthewKim
    // userInfo.roles 에 유저의 모든 권한이 들어 있다.
    userInfo.roles_arr = userInfo.roles_arr || [];

    _.forEach(userInfo.roles_arr, function(role) {
      PermPermissionStore.definePermission(role, function () {
        return true;
      });
    });

    // 여러개 권한을 한번에 포함하는 추가 권한 검사는 아래처럼
    // var permissions = ['listMeeting', 'seeMeeting', 'editMeeting', 'deleteMeeting'];
    //
    // PermPermissionStore.defineManyPermissions(permissions, /*@ngInject*/ function (permissionName) {
    //   return _.contains(permissions, permissionName);
    // });

  })

// 2017-02-07 MatthewKim GMP에서는 그룹 role 사용하지 않음
  /**
   * 위에서 만든 화면별 퍼미션을 모아서 그룹별 권한 role 정의
   *
   * 모듈별로 필수적인 권한을 모아서 한번에 체크해야 할때 사용한다. (AND조건)
   * 아래의 권한은 모두 AND 조건으로 엮이므로 그룹 이름으로 검사할때는 하단으로 배열로 작성한 모든 권한을 다 가질 경우에만 있는 것으로 판단한다.
   * https://github.com/Narzerus/angular-permission/wiki/Managing-roles
   *
   * 만약 하단의 배열 권한중 1개라도 포함하면 ON 하는 경우 (OR조건)에는 이 모듈에는 없고
   * 다른 방법을 써야 하지만, 그런 경우는 메뉴 상위를 표시하는 경우외에는 없으므로
   * perm perm-only='' 대신 perm perm-only-start-with='' 를 사용한다.
   * ex)
   * perm perm-only-start-with="'settings.'" -> settings. 로 시작하는 권한 1개라도 있으면 OK
   * main.html 의 최상위 대메뉴 표시를 위 방법으로 한다.
   *
   * 2017-02-03 MatthewKim
   */
  .run(function (PermRoleStore) {

    PermRoleStore
      .defineManyRoles({

        //'AUTHORIZED': /* @ngInject*/ function (Session) { return Session.checkSession(); },

        'SETTINGS.MODULE':
          [
            'settings',
            'settings.channel',
            'settings.category',
            'settings.supplier',
            'settings.delivery'
          ],

        'PROD.MODULE':
          [
            'prod',
            'prod.product',
            'prod.shopping_code'
          ],

        'ONLINE.MODULE':
          [
            'online',
            'online.product',
            'online.template'
          ],

        'ORDER.MODULE':
          [
            'order',
            'order.shipment',
            'order.claim',
            'order.customer'
          ],

        'STOCK.MODULE':
          [
            'stock',
            'stock.inventory',
            'stock.warehouse'
          ]
      });

  })

  /**
   * 권한 부족으로 성공/거부시 받을 이벤트 정의
   *
   * https://github.com/Narzerus/angular-permission/wiki/Emitted-events-in-ui-router
   * 2017-02-03 MatthewKim
   */
  .run(function($rootScope, commonSVC) {

    /**
     * 권한 거부 됐을때, 여기서 모달 팝업을 띄우 거나, 팝업 메세지를 할 수 있음.
     */
    $rootScope.$on('$stateChangePermissionDenied', function(event, toState, toParams, options) {
      commonSVC.PermissionDenyAlert(options);
    });

  })
;
