'use strict';

angular.module('gmpApp')
  .factory('A011categorySVC', function () {

    return {
      // 카테고리
      category: {
        0: 'lgroup',
        1: 'mgroup',
        2: 'sgroup',
        3: 'dgroup'
      },

      //카테고리 repeat data
      category_list: {
        lgroup: {},
        mgroup: {},
        sgroup: {},
        dgroup: {}
      },

      //카테고리 data
      category_data: {
        lgroup: '',
        mgroup: '',
        sgroup: '',
        dgroup: ''
      },

      // 전시카테고리
      disp_category: {
        0: 'ljunsi',
        1: 'mjunsi',
        2: 'sjunsi',
        3: 'djunsi'
      },

      //전시카테고리 repeat data
      disp_category_list: {
        ljunsi: {},
        mjunsi: {},
        sjunsi: {},
        djunsi: {}
      },

      //전시카테고리 data
      disp_category_data: {
        ljunsi: '',
        mjunsi: '',
        sjunsi: '',
        djunsi: ''
      },

      // MD
      MD: {
        code: '',
        name: ''
      },

      etc: {
        set_all_shop: false //상품적용여부
      }
    };
  });
