'use strict';

angular.module('gmpApp')
  .controller('QuickOnlineProdAddCtrl', function (
    $rootScope, $scope, $uibModalInstance, $timeout,
    data, siteInfo, systemList,
    commonSVC, onlineProductSVC, errorSVC,
    shopAccountModel, onlineProductModel, commonModel) {
    $scope.mallAll = []; // 전체쇼핑몰 리스트
    $scope.targetMalls = [];
    $scope.selectedAll = true;

    $scope.selectedProds = data?.prods || []; // 선택된 상품
    $scope.selectedMasterProdsCnt = _.uniqBy($scope.selectedProds, 'sale_no').length; // 선택된 상품의 마스터상품 개수

    $scope.standardShop = ''; // 기준 쇼핑몰

    $scope.shop_id_view_type = systemList.data.shop_id_view_type; // 쇼핑몰 계정 표기 방식(id: 아이디, nickname: 별칭)

    const SAVE_MALL_TYPE = 'quickOnlineProdAddMalls', // 선택 쇼핑몰 로컬스토리지 저장 키
          SUPPORT_MALLS = ['A001', 'A006', 'A027', 'A077', 'A112', 'B378', 'B688', 'B719']; // 지원 쇼핑몰

    // 쇼핑몰 마지막 선택값 불러오기
    $scope.saveMalls = JSON.parse(localStorage.getItem(SAVE_MALL_TYPE)) || { use_yn: false, malls: [] };

    // 사용중인 쇼핑몰, 아이디 가져오기
    shopAccountModel.shopAccountUseList({ site_action: 'RegistProd', quick_prod_add_yn: true })
      .then(async res => {
        // STEP 1. 조회된 쇼핑몰 정보 담기. (엔진쇼핑몰코드 추가)
        $scope.mallAll = res.data.results.filter(m => SUPPORT_MALLS.includes(m.pa_shop_cd));
        $scope.mallAll.forEach(m => m.playapi_shop_cd = siteInfo[m.shop_cd]?.playapi_shop_cd);
        $scope.targetMalls = $scope.mallAll;

        // STEP 2. 마지막 선택값 기억 사용시 체크되었던 사이트 선택.
        $scope.targetMalls.forEach(m => {
          const matchedMall = $scope.saveMalls?.use_yn ? $scope.saveMalls.malls.find(o => o.shop_cd === m.shop_cd && o.shop_id === m.shop_id) : null;
          m.selected = matchedMall ? matchedMall.checked : true;
        });

        // STEP 3. 전체사이트 여부 체크.
        $scope.selectedAll = $scope.targetMalls.every(m => m.selected);

        // 배송정보 엔진조회 필요한 쇼핑몰계정이 있는 경우 미리 조회해둠
        const response = await commonModel.pa5('/channel_accounts/register_quick_options', {
          market_code: 'e0000',
          shop_id: '',
          market_username: '',
          market_password: ''
        });

        if (response.status === 200 & !response.data.engine_result.has_error) {
          for (const engineShopCd in response.data.engine_result.results[0].data) {
            const shops = $scope.targetMalls.filter(m => m.playapi_shop_cd === engineShopCd);

            for (const shop of shops) {
              shop.delivData = angular.copy(response.data.engine_result.results[0].data[engineShopCd]);
              shop.delivery_info = {}; // 배송정보 선택값 초기화
            }
          }
        }

        return true;
      }).then(async () => {
        if ($scope.selectedProds.length) {
          $scope.cntLoading = true;
          $scope.standardShop = { addProdCnt: {} };

          // 신규등록 예정 상품 수 조회
          const re = await onlineProductModel.quickOnlineProductAddCount({
            saleNos: _.uniq($scope.selectedProds.map(p => p.sale_no))
          });

          for (const shop of $scope.mallAll) {
            const cntInfo = re.data.results.find(r => r.shop_cd === shop.shop_cd && r.shop_id === shop.shop_id);

            $scope.standardShop.addProdCnt[`${shop.shop_cd}|${shop.shop_id}`] = cntInfo?.cnt || 0;
          }

          $scope.cntLoading = false;
        }

      }).catch(err => {
        $scope.cntLoading = false;

        if (err.data?.error?.includes('none uuid') || err.data?.error?.includes('channel_account not found')) {
          commonSVC.showToaster('error', '실패', errorSVC.getError('work', 'none uuid2'));
        } else {
          commonSVC.showToaster('error', '실패', err.data?.messages?.[0] || '쇼핑몰 정보를 가져오는데 실패하였습니다.');
        }

        $scope.cancel();
      });

    /**
     * 신규등록 예정 상품 수 계산
     */
    $scope.calAddProdCnt = async () => {
      if (!$scope.standardShop) { return false; }

      try {
        if (!$scope.standardShop.addProdCnt) {
          $scope.cntLoading = true;
          $scope.standardShop.addProdCnt = {};

          const re = await onlineProductModel.quickOnlineProductAddCount({
            shop_cd: $scope.standardShop.shop_cd,
            shop_id: $scope.standardShop.shop_id
          });

          // 기준 쇼핑몰 상품수
          const standardShopCnt = re.data.results.find(r => r.shop_cd === $scope.standardShop.shop_cd && r.shop_id === $scope.standardShop.shop_id)?.cnt || 0;

          for (const shop of $scope.mallAll) {
            const cntInfo = re.data.results.find(r => r.shop_cd === shop.shop_cd && r.shop_id === shop.shop_id);

            // 상품 조회결과 없으면 기준쇼핑몰 상품 100프로 등록이라 기준쇼핑몰 상품수로 세팅
            $scope.standardShop.addProdCnt[`${shop.shop_cd}|${shop.shop_id}`] = cntInfo ? cntInfo.cnt : standardShopCnt;
          }

          $scope.cntLoading = false;
        }
        $timeout();
      } catch (err) {
        $scope.cntLoading = false;
        commonSVC.showToaster('error', '실패', err.data?.messages?.[0] || '신규등록 예정 상품 수 조회에 실패하였습니다');
      }
    };

    /**
     * 쇼핑몰 선택
     */
    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        $scope.targetMalls.forEach(shop => {
          shop.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.targetMalls[type].selected)) {
          $scope.selectedAll = false;
        } else {
          $scope.selectedAll = !$scope.targetMalls.some(mall => !mall.selected);
        }
      }
    };

    /**
     * 퀵등록 버튼 비활성화 여부
     */
    $scope.isDisabled = () => {
      // 등록할 쇼핑몰 선택했는지 여부
      const isTargetMallSelected = $scope.targetMalls.some(mall => {
        return ($scope.selectedProds.length || (mall.shop_cd !== $scope.standardShop?.shop_cd || mall.shop_id !== $scope.standardShop?.shop_id)) && mall.selected;
      });

      // 기준 쇼핑몰 선택했는지 여부
      const isStandardShopSelected = Boolean($scope.standardShop);

      if (!isTargetMallSelected || !isStandardShopSelected) {
        return true;
      }

      // 신규등록 예정 상품수의 합이 0인 경우 퀵등록 버튼 비활성화
      const selectedShopCdShopId = Object.keys($scope.standardShop.addProdCnt).filter(key => {
        const [shop_cd, shop_id] = key.split('|');

        return $scope.targetMalls.some(mall => mall.shop_cd === shop_cd && mall.shop_id === shop_id && mall.selected);
      });

      const addProdCnt = selectedShopCdShopId.reduce((total, key) => total + $scope.standardShop.addProdCnt[key], 0);

      if (!addProdCnt) {
        return true;
      }

      return false;
    };

    /**
     * 배송정보 선택 모달 오픈
     */
    async function openDelivModal(delivShops) {
      const re = await commonSVC.openModal('xmd', { data: { delivShops } }, 'QuickOnlineProdAddDelivCtrl', 'views/home/modals/quickOnlineProdAddDeliv.html', false, true, false).result;

      // 배송정보 선택이 백엔드로 안넘어가는 경우가 있어서 추가
      if (re !== 'success') {
        throw 'cancel';
      }

      return true;
    }

    /**
     * 퀵등록
     */
    $scope.quickOnlineProdAdd = async() => {
      if (!$scope.selectedProds.length && !$scope.standardShop) {
        commonSVC.showMessage('기준 쇼핑몰을 선택해주세요.');

        return false;
      }

      const targetMalls = $scope.targetMalls.filter(m => {
        return m.selected && (
          ((!$scope.selectedProds.length && m.shop_cd !== $scope.standardShop.shop_cd && m.shop_id !== $scope.standardShop.shop_id)
          || ($scope.selectedProds.length !== 1 || !$scope.selectedProds.some(p => p.shop_cd === m.shop_cd && p.shop_id === m.shop_id)))
        );
      });

      if (!targetMalls.length) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

        return false;
      }

      try {
        const delivShops = targetMalls.filter(m => {
          return m.delivData && !m.system_default_template_no
          && ($scope.selectedProds.length || (m.shop_cd !== $scope.standardShop.shop_cd || m.shop_id !== $scope.standardShop.shop_id));
        });

        // 배송정보 선택이 필요한 쇼핑몰이 있는 경우 배송방법 선택 모달 띄움
        if (delivShops.length) {
          await openDelivModal(delivShops);
        }

        // 선택상품 없을 때는 2단계 대상 쇼핑몰에 선택쇼핑몰 포함안되게 필터링
        const targetShops = $scope.selectedProds.length ? targetMalls : targetMalls.filter(m => m.shop_cd !== $scope.standardShop.shop_cd || m.shop_id !== $scope.standardShop.shop_id);

        const params = {
          targetShops: targetShops.map(m => {
            const image_limit = onlineProductSVC.sub_img_objects[m.pa_shop_cd];

            return {
              pa_shop_cd: m.pa_shop_cd,
              shop_cd: m.shop_cd,
              shop_id: m.shop_id,
              shop_name: m.shop_name,
              uuid: m.uuid,
              delivery_info: m.delivery_info,
              option_data: ['A001', 'A006'].includes(m.pa_shop_cd) ? {} : undefined,
              image_limit: image_limit ? image_limit.length : undefined,
              system_default_template_no: m.system_default_template_no,
            };
          }),
        };

        // 배송정보 선택이 백엔드로 안넘어가는 경우가 있어서 추가
        if (params.targetShops.some(m => m.delivery_info && !m.system_default_template_no && !Object.keys(m.delivery_info).length)) {
          await openDelivModal(delivShops);
        }

        if ($scope.selectedProds.length) {
          params.selectedProds = $scope.selectedProds;
        } else {
          params.standardShop = $scope.standardShop;
        }

        await onlineProductModel.quickOnlineProductAdd(params);

        // 선택 쇼핑몰 로컬스토리지 저장.
        $scope.saveMalls.malls = $scope.targetMalls.map(m => ({ shop_cd: m.shop_cd, shop_id: m.shop_id, checked: m.selected }));
        localStorage.setItem(SAVE_MALL_TYPE, JSON.stringify($scope.saveMalls));

        commonSVC.showToaster('success', '성공', '퀵등록이 완료되었습니다.');
        $rootScope.$broadcast('reloadOlProductList');
        $scope.cancel();

        return true;
      } catch (err) {
        if (['escape key press', 'cancel'].includes(err)) {
          return false;
        } else if (err.data?.messages?.[0] === 'no product') {
          commonSVC.showMessage('[기준 쇼핑몰]에 등록된 상품이 없습니다.\n상품을 먼저 등록하시고 빠른 등록 서비스를 이용해 주세요.');
        } else {
          commonSVC.showToaster('error', '실패', err.data?.messages?.[0] || '퀵등록에 실패하였습니다.');
        }

        return false;
      }
    };

    /**
     * 창 닫기
     */
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 배송방법 선택 모달
   */
  .controller('QuickOnlineProdAddDelivCtrl', function ($scope, $uibModalInstance, data) {
    $scope.shops = data.delivShops;

    // 첫번째 값 기본으로 선택
    for (const shop of $scope.shops) {
      for (const deliv of shop.delivData) {
        shop.delivery_info[deliv.key] = deliv.values[0].value;

        if (deliv.values[0].addData) {
          shop.delivery_info[deliv.values[0].addData.key] = deliv.values[0].addData.values[0].value;
          deliv.addData = deliv.values[0].addData;
        }
      }
    }

    /**
     * 배송정보 1뎁스 선택시 추가정보(2뎁스) 세팅
     */
    $scope.delivSelect = (shop, deliv, value) => {
      deliv.addData = deliv.values.find(v => v.value === value).addData || null;
    };

    /**
     * 퀵등록
     */
    $scope.ok = () => {
      $uibModalInstance.close('success');
    };

    /**
     * 창 닫기
     */
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });
