'use strict';
/**
 * 커스텀 컬럼 일괄저장 모달
 */
angular.module('gmpApp')
  .controller('setFormCopyCtrl', function ($scope, data, $uibModalInstance, $timeout, $rootScope, commonSVC, templateModel) {
    $scope.copyList = [];

    Object.entries(_.groupBy(data.selectedList, 'pa_shop_cd')).forEach(([paShopCd, copyList]) => {
      $scope.copyList.push({
        pa_shop_cd: paShopCd,
        shop_name: copyList[0].shop_name,
        copyDatas: copyList,
        filteredShopList: $rootScope.use_channel_list.filter(shop => shop.pa_shop_cd === paShopCd),
        selectedShopList: [],
        channelSelectAll: true,
        chkSelected: false
      });
    });

    $scope.$watch('$viewContentLoaded', function () {
      $timeout(function () {

        $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
          var oldRemoveChoice = Search.prototype.searchRemoveChoice;

          Search.prototype.searchRemoveChoice = function () {
            oldRemoveChoice.apply(this, arguments);
            this.$search.val('');
          };

          for (let i = 0; i < $scope.copyList.length; i++) {

            $(`.select-fixed-multiple_${i}`).select2({
              minimumResultsForSearch: Infinity,
              width: '100%',
              placeholder: '쇼핑몰(계정)선택',
              closeOnSelect: false
            });

            // 선택 가능한 쇼핑몰이 하나밖에 없을 경우 자동 선택 되도록 함
            for (let i = 0; i < $scope.copyList.length; i++) {
              const copyData = $scope.copyList[i];

              if (copyData.filteredShopList.length === 1) {
                copyData.selectedShopList = [0];
                copyData.channelSelectAll = false;

                $(`.reg-channel-select_${i}`).val(copyData.selectedShopList.map(idx => `${copyData.pa_shop_cd}✝${idx}`)).trigger('change');
              }
            }

            // 쇼핑몰 선택 시 액션
            $(`.reg-channel-select_${i}`).on('change.select2', function () {
              let va = $(this).val() || [];

              if (va.length) {
                const paShopCd = va[0].split('✝')[0];

                if (va[0]?.includes('✝all')) {
                  $scope[`channelSelectAll_${paShopCd}`] = false;
                  va = [];

                  $scope.copyList.forEach(copy => {
                    if (copy.pa_shop_cd === paShopCd) {
                      copy.selectedShopList = [];

                      for (let i = 0; i < copy.filteredShopList.length; i++) {
                        copy.channelSelectAll = false;
                        copy.selectedShopList.push(i);
                        va.push(`${copy.pa_shop_cd}✝${i}`);
                      }
                    }
                  });

                  $(this).val(va).trigger('change');
                  $(this).select2('close');

                  return;
                }

                if (va[0]?.includes('✝del')) {
                  const matchCopyData = $scope.copyList.find(copy => copy.pa_shop_cd === paShopCd);

                  matchCopyData.channelSelectAll = true;
                  matchCopyData.selectedShopList = [];

                  $(`.reg-channel-select_${i}`).val([]).trigger('change');
                  $(this).select2('close');

                  return;
                }

                const matchCopyData = $scope.copyList.find(copy => copy.pa_shop_cd === paShopCd);
                matchCopyData.selectedShopList = [];

                for (let i = 0; i < va.length; i++) {
                  matchCopyData.selectedShopList.push(+va[i].split('✝')[1]);
                }

              } else {
                $scope.copyList[i].channelSelectAll = true;
                $scope.copyList[i].selectedShopList = [];
              }

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            });
          }
        });

      }, 0);
    });

    $scope.copy = () => {

      let chkValid = false;

      $scope.copyList.forEach(list => {
        if (!list.selectedShopList.length) {
          list.chkSelected = true;
          chkValid = true;
        }
      });

      if (chkValid) {
        return;
      }

      const params = [];

      $scope.copyList.forEach(list => {
        list.copyDatas.forEach(copyData => {
          list.selectedShopList.forEach(idx => {
            const selShop = list.filteredShopList[idx];

            params.push({ ...copyData, shop_cd: selShop.shop_cd, shop_id: selShop.shop_id });
          });
        });
      });

      templateModel.copy(params, function (state) {
        if (state == 'success') {
          commonSVC.showToaster('success', '템플릿 관리', `템플릿 복사 ${params.length}건 성공`);

          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '템플릿 관리', '템플릿 복사가 실패하였습니다.');
        }
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  });