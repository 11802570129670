'use strict';

/**
 * Created by ally on 2017. 4. 17..
 * undefined,null, 0 이면 빈값 리턴해주고 빈값클래스까지 리턴
 */
angular.module('gmpApp')
  .filter('checkEmpty', function() {
    return function(value, returnType) {
      let output;

      if (!value || value == 'undefined' || value == 'null' || value == '0' || value == '') {
        output = returnType === 'class' ? 'empty-input-box' : '';
      } else {
        output = returnType === 'class' ? '' : value;
      }

      return output;
    };
  })
  .directive('checkEmpty', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        function format(value) {
          if (value !== '' && (!value || value == 'undefined' || value == 'null' || value == '0')) {
            return '';
          } else {
            return value;
          }
        }

        ngModel.$formatters.push(format);

        // ngModel.$render = function() {
        // console.log(ngModel);
        // var value = ngModel.$viewValue;
        // console.log(value);
        // if(value !== '' && (!value || value == 'undefined' || value == 'null' || value == '0')){
        //   console.log(ngModel.$name);
        //   ngModel.$viewValue = '';
        // }
        // };
      }
    };
  });
