'use strict';

angular.module('gmpApp')
  .controller('CateLogCtrl', function ($scope, $uibModalInstance, data, logHistorySVC) {
    // 로그 로드
    logHistorySVC.getLog(null, { location: 'category', number: data.solCateNo })
      .then(function (res) {
        $scope.logs = res.data.rows;
      });

    $scope.close = function() {
      $uibModalInstance.close();
    };
  });
