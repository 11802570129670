'use strict';

angular.module('gmpApp')
  .factory('A100categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        key_select_MD: '',
        key_mdName: '',
        key_mdCode: '',
        key_md_gsgr_no: '',
        category_names: '',
        set_all_shop: false, //상품적용여부
        key_dcom_asgn_rtgs_hdc_use_yn: 'N'
      }

    };
  });
