'use strict';
angular.module('gmpApp')
  .controller('ConfigsAdminCtrl', function ($scope, $stateParams, $compile, userInfo, adminModel, shipmentSVC, commonSVC, gettextCatalog, $timeout, securityModel, $rootScope) {

    $scope.admin = {}; // Data 담는 변수
    $scope.flag = {}; // flag값 담는 변수
    $scope.city_list = shipmentSVC.jpCityList; //도시 리스트
    $scope.ksic = []; //업태/종목

    $scope.bsn_tim = {}; //업태 팀분류
    $scope.bsn_large = {}; //업태 대분류
    $scope.bsn_middle = {}; //업태 중분류
    $scope.bsn_small = {}; //업태 소분류

    $scope.from_tel_list = '';
    $scope.new_htel = [];
    $scope.requestAuthFlag = false; // 휴대폰 문자 인증 요청 여부
    $scope.requestAuthIng = false;  // 여러번 전송막기위한 Flag
    let sms_tel_list = ''; // SMS 발신번호 중복 등록 거르기 위한 리스트

    init();

    // 메뉴 열고닫음
    $scope.flag_change = function (key) {
      flagInit();
      $scope.flag[key] = true;

      // 플레이오토 사업자 번호는 중복 등록이 가능하도록
      // db 에서 null 로 저장됨.
      // null 표기를 바꾸기 위해 아래와 같이 처리함.
      // 메뉴를 열 때 key 값이 사업자번호이면 null 인지 검사
      if (key === 'biz_reg_no') {
        if ($scope.admin[key] === 'null') {
          $scope.admin[key] = '119-81-84318';
        }
      }

      // SMS 발신번호 보류사유가 있을때 클릭하면 모달 출력
      if (key === 'from_tel') {
        _.forEach($scope.admin.company_sms, function(row, idx) {
          if (row.hold_reason) {
            const replace = `<a ng-click="holdReason('${row.hold_reason}')">${row.auth_status}</a>`;

            $(`#auth_status_${idx}`).html($compile(replace)($scope));
          }
        });
      }

    };

    // 수정
    $scope.submitForm = async function(key) {
      let isSave = true;

      //업태/종목
      $scope.admin.ksic_cd = $scope.admin.ksic_cd4;

      // if(key === 'charge_info') {
      //   var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      //   angular.forEach($scope.admin.charge_info, function (obj) {

      //     if (obj.charge_email && obj.charge_email.match(regExp) == null) {
      //       isSave = false;
      //       commonSVC.showMessage(gettextCatalog.getString("담당자 이메일을 확인 해주세요."));
      //       return;
      //     }
      //   });
      // }
      // else if(!$scope.admin[key]){
      //   isSave = false;
      //   commonSVC.showMessage(gettextCatalog.getString("값을 입력 해주세요."));
      // }

      // 사업자 번호 칸에 검증절차를 추가시키기 위해 수정함.
      // 2017-11-30 박현도

      // 가장 먼저 인풋이 없는 경우 에러메시지 출력.
      if (!$scope.admin[key]) {
        isSave = false;
        commonSVC.showMessage(gettextCatalog.getString('값을 입력 해주세요.'));
      }
      // 인풋이 있는 경우에
      else {
        // 각각 키값에 따른 분기가 가능하도록 아래와 같이 구조를 변경함.
        if (key === 'charge_info') {
          const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
          angular.forEach($scope.admin.charge_info, function (obj) {
            if (obj.charge_email && obj.charge_email.match(regExp) == null) {
              isSave = false;
              commonSVC.showMessage(gettextCatalog.getString('담당자 이메일을 확인 해주세요.'));

              return;
            }
          });
        }
        // 키값이 사업자번호라면
        else if (key === 'biz_reg_no') {

          // 사업자 번호 10 자리를 제대로 입력하였을 경우
          if ($scope.admin[key].length === 10) {
            $scope.admin[key] = commonSVC.addHyphen($scope.admin[key], $scope.admin[key].length, 3, 5);
          }
          // - 를 추가해서 사업자번호 10자리를 입력했을 경우
          else if ($scope.admin[key].length === 12) {
            $scope.admin[key] = commonSVC.addHyphen($scope.admin[key], $scope.admin[key].length, 3, 5);
          }
          // 그 외 잘못된 인풋일 경우
          else {
            $scope.admin[key] = commonSVC.addHyphen($scope.admin[key], $scope.admin[key].length, 3, 5);
          }
          // 위 분기에서 - 를 추가시키고
          // 사업자번호 유효성을 검증하고 에러메시지 설정
          if (commonSVC.checkingBizNo($scope.admin[key]) === true) {
            $scope.validBizNo = true;
            $scope.invalidBizNo = false;
            $scope.existBizNo = false;
          } else {
            $scope.validBizNo = false;
            $scope.invalidBizNo = true;
            $scope.existBizNo = false;
          }
        } else if (key === 'homepage_url') {
          //도메인 유효성 체크
          const expUrl = /^(http:\/\/)?((\w+)[.])+(asia|biz|cc|cn|com|de|eu|in|info|jobs|jp|kr|mobi|mx|name|net|nz|org|travel|tv|tw|uk|us)(\/(\w*))*$/i;

          if (expUrl.test($scope.admin[key]) == false) {
            commonSVC.showMessage(gettextCatalog.getString('홈페이지는 도메인형식으로 입력해주시기 바랍니다.'));

            return;
          }
        }
      }
      // 타당하지 않은 사업자 번호일 때 백엔드로 넘어가지 않도록 설정.
      if ($scope.validBizNo === false) {
        isSave = false;
      }
      // param 이 위 과정을 거치고 갱신되도록 선언 위치를 변경함.
      const param = {
        c_no: $scope.admin.c_no,
        key: key,
        value: angular.copy($scope.admin[key])
      };

      if (key === 'biz_reg_url') {
        param.value = $scope.admin[key];
        if (!param.value) {
          commonSVC.showMessage('사업자 등록증의 업로드 규칙을 확인해주십시오.', '1. 5MB 이하의 파일만 등록 가능합니다.\n2. jpg, jpeg, gif, png, pdf 파일만 업로드 가능합니다.');

          return false;
        }
      }

      if (isSave) {
        adminModel.set(param, function (state, result) {
          if (state == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('변경사항이 저장되었습니다.'));
            init();
          }
          // 에러가 났을 경우에
          else {
            // 에러메시지를 검사해서 "biz_reg_no" 가 포함되어 있으면
            if (result.data.error.search('biz_reg_no') >= 0) {
              // 에러메시지를 출력함.
              $scope.existBizNo = true;
              $scope.invalidBizNo = false;
              $scope.validBizNo = false;
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('변경사항 저장에 실패하였습니다.'));
            }
          }

        });
      }
    };

    //대분류 선택시
    $scope.change1 = function(param) {
      $scope.bsn_large = {};
      if (param !== 'init') {
        $scope.admin.ksic_cd2 = '';
        $scope.admin.ksic_cd3 = '';
        $scope.admin.ksic_cd4 = '';
      }

      angular.forEach($scope.ksic, function(value, key) {
        if (value.pa_ksic_cd == $scope.admin.ksic_cd1) {
          $scope.bsn_large[key] = value;
        }
      });

    };

    //중분류 선택시
    $scope.change2 = function(param) {
      $scope.bsn_middle = {};
      if (param !== 'init') {
        $scope.admin.ksic_cd3 = '';
        $scope.admin.ksic_cd4 = '';
      }
      angular.forEach($scope.ksic, function(value, key) {
        if (value.pa_ksic_cd == $scope.admin.ksic_cd2) {
          $scope.bsn_middle[key] = value;
        }
      });
    };

    //소분류 선택시
    $scope.change3 = function(param) {
      $scope.bsn_small = {};
      if (param !== 'init') {
        $scope.admin.ksic_cd4 = '';
      }
      angular.forEach($scope.ksic, function(value, key) {
        if (value.pa_ksic_cd == $scope.admin.ksic_cd3) {
          $scope.bsn_small[key] = value;
        }
      });
    };

    // 데이터 불러옴
    async function init() {
      const emailCheckauth = (await securityModel.checkAuth()).data?.results;
      //자사정보 API 조회
      adminModel.load({ c_no: userInfo.user.c_no }, function(state, res) {
        if (state == 'success') {
          $scope.admin = { ...res.results.company, charge_info: res.results.company.charge_info.map(charge => {
            const verifyCheck = emailCheckauth.filter(check => check.c_charge_no === charge.c_charge_no);

            return { ...charge, verify: verifyCheck[0].verify };
          }) };

          //null 공백처리
          if ($scope.admin.ceo_name == 'null') {
            $scope.admin.ceo_name = '';
          }

          $scope.biz_reg_url = $scope.admin.biz_reg_url ? `<a href="${$scope.admin.biz_reg_url}" target="_blank">${$scope.admin.name} 사업자 등록증</a>` : '';

          // if($scope.admin.biz_no === "null") {
          //   $scope.admin.biz_no = "";
          // }

          if ($scope.admin.addr1 == 'null') {
            $scope.admin.addr1 = '';
          }

          $scope.ksic = res.results.ksic;
          $scope.ksic_cd1 = {};
          $scope.ksic_cd2 = {};
          $scope.ksic_cd3 = {};
          $scope.ksic_cd4 = {};

          angular.forEach($scope.ksic, function(value, key) {
            if (value.ksic_cd.length == 2) {
              $scope.bsn_tim[key] = value;
            }
          });

          // sms 인증대기건은 리스트 & 테이블에 출력하지 않음
          $scope.admin.company_sms = _.filter($scope.admin.company_sms, function(row) {
            return row.auth_status != 'sms_인증대기';
          });
          sms_tel_list = _.map($scope.admin.company_sms, 'from_tel').join(',');

          $scope.admin.from_tel = []; // 인증완료된 발신번호 리스트
          _.forEach($scope.admin.company_sms, function(row) {
            const auth_status = row.auth_status.split('_');

            // 인증완료건만 리스트에 출력해줌 (클릭시 나오는 테이블엔 출력)
            if (auth_status[1] == '인증완료') {
              $scope.admin.from_tel.push(row.from_tel);
            }

            row.auth_type = auth_status[0];
            row.auth_status = auth_status[1];
          });

          // 승인된 SMS 발신번호 리스트 텍스트 치환
          if (!$scope.admin.from_tel.length) {
            $scope.from_tel_list = '미등록';
            $('#from_tel').addClass('text-danger');
          } else if ($scope.admin.from_tel.length <= 5) {
            $scope.from_tel_list = $scope.admin.from_tel.join(', ');
          } else {
            $scope.from_tel_list = `${$scope.admin.from_tel[0]} 외 ${$scope.admin.from_tel.length - 1}건`;
          }

          //업태/종목 분류 선택해주기
          if ($scope.admin.ksic_cd != '' && $scope.admin.ksic_cd != null) {

            if ($scope.admin.ksic_cd.length == 2) {
              $scope.admin.ksic_cd1 = $scope.admin.ksic_cd;
            } else if ($scope.admin.ksic_cd.length == 3) {
              $scope.admin.ksic_cd2 = $scope.admin.ksic_cd;
              $scope.admin.ksic_cd1 = $scope.admin.ksic_cd2.substr(0, 2);
              $scope.change1();
            } else if ($scope.admin.ksic_cd.length == 4) {
              $scope.admin.ksic_cd3 = $scope.admin.ksic_cd;
              $scope.admin.ksic_cd2 = $scope.admin.ksic_cd3.substr(0, 3);
              $scope.admin.ksic_cd1 = $scope.admin.ksic_cd3.substr(0, 2);
              $scope.change1();
              $scope.change2();
            } else if ($scope.admin.ksic_cd.length == 5) {
              $scope.admin.ksic_cd4 = $scope.admin.ksic_cd;
              $scope.admin.ksic_cd3 = $scope.admin.ksic_cd4.substr(0, 4);
              $scope.admin.ksic_cd2 = $scope.admin.ksic_cd4.substr(0, 3);
              $scope.admin.ksic_cd1 = $scope.admin.ksic_cd4.substr(0, 2);
              $scope.change1('init');
              $scope.change2('init');
              $scope.change3('init');
            }
          }
          flagInit();
          // 주문관리 메뉴에서 SMS 미등록 알림창 확인 클릭해서 왔을경우 sms발신번호 메뉴 열린채로 페이지 오픈
          $scope.flag.from_tel = $stateParams.flag == 'sms' || false;
          // 결제관리 메뉴에서 사업자등록증 알림창 확인 클릭해서 왔을경우 사업자등록증 메뉴 열린채로 페이지 오픈
          $scope.flag.biz_reg_url = $stateParams.flag == 'pay' || false;

          // 별도발행 세금계산서 정보
          $scope.anotherTaxInfo = res.results.anotherTaxInfo;

          if ($scope.admin.tax_charge_yn) {
            $scope.anotherTaxInfo.charge_name = $scope.admin.charge_info[0]?.charge_name || '';
            $scope.anotherTaxInfo.charge_email = $scope.admin.charge_info[0]?.charge_email || '';
            $scope.anotherTaxInfo.charge_htel = $scope.admin.charge_info[0]?.charge_htel || '';
          }
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('정보 조회에 실패하였습니다.'));
        }
      });
    }

    // 메뉴 모두 닫혀있는 상태로 변경
    function flagInit() {
      angular.forEach($scope.admin, function(v, k) {
        $scope.flag[k] = false;
      });
    }

    $scope.sortableOptions = {
      update: function () {
        $timeout(function() {
          updateSeq();
        }, 100);
      }
    };

    /**
     * SMS 발신번호 순번 업데이트
     */
    async function updateSeq() {
      _.forEach($scope.admin.company_sms, function(tel, idx) {
        // 업데이트된 순번이 이전 순번과 다르다면 new_seq에 넣어줌
        if (tel.seq != (idx + 1)) {
          tel.new_seq = idx + 1;
        }
      });

      try {
        const result = await securityModel.editSmsAuthSeq($scope.admin.company_sms);

        if (result.state === 'success' || result.data?.results === 'success') {
          return commonSVC.showToaster('success', '성공', '발신번호 순번 수정에 성공하였습니다.');
        } else {
          commonSVC.showToaster('error', '실패', '발신번호 순번 수정에 실패하였습니다.');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '발신번호 순번 수정에 실패하였습니다.');
      }
    }

    /**
		 * SMS 발신번호 보류 사유 확인
		 */
    $scope.holdReason = function (hold_reason) {
      const resolve = {};

      resolve.data = {
        hold_reason: hold_reason
      };
      commonSVC.openModal('sm', resolve, 'SmsAuthHoldReasonCtrl', 'views/configs/modals/sms_auth_hold_reason.html');
    };

    /**
		 * SMS 발신번호 휴대폰 인증번호 문자발송
		 */
    $scope.requestSmsAuthKey = function() {
      const phone = $scope.new_htel.join('-');
      const resolve = {
        data: function () {
          return phone;
        }
      };

      if (!$scope.new_htel[0] || !$scope.new_htel[1] || !$scope.new_htel[2]) {
        commonSVC.showMessage('번호를 입력해주세요.');

        return false;
      }

      if (_.includes($scope.admin.from_tel, phone)) {
        commonSVC.showMessage('이미 존재하는 번호입니다.');

        return false;
      }

      sendSms(phone);

      const modal = commonSVC.openModal('sm', resolve, 'SmsAuthCtrl', 'views/configs/modals/sms_auth.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          init();
        }
      });
    };

    /**
     * 휴대폰 문자 인증 실제 발송
     */
    async function sendSms(phone) {
      $scope.requestAuthIng = true;

      try {
        const result = await securityModel.smsAuthGet(phone);
        $timeout(() => {
          $scope.requestAuthIng = false;
          if (result.data === 'SUCCESS') {
            $scope.requestAuthFlag = true;
          }
        }, 500);

        return;
      } catch (error) {
        $scope.requestAuthIng = false;
        $scope.alerts = [{ type: 'danger', msg: !$rootScope.backend_user ? error.data.messages[0] : error.data.error }];
        commonSVC.showToaster('error', '실패', !$rootScope.backend_user ? error.data.messages[0] : error.data.error);
      }
    }

    /**
		 * 저장된 SMS발신번호 삭제
		 */
    $scope.delelteSmsTel = function(number) {
      commonSVC.showConfirm('SMS 발신번호 삭제', '정말 삭제하시겠습니까? ', function() {
        securityModel.smsAuthDelete(number, function (state) {
          if (state == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('SMS발신번호가 삭제되었습니다.'));

            init();
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('SMS발신번호 삭제에 실패하였습니다.'));
          }
        });
      });
    };

    /**
		 * 서류 심사 요청
		 */
    $scope.requestDocumentation = function() {
      const resolve = {
        data: { tel_list: sms_tel_list }
      };

      const modal = commonSVC.openModal('md', resolve, 'SmsDocumentationReqCtrl', 'views/configs/modals/sms_documentation_req.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          init();
        }
      });
    };

    $scope.applyCancel = function() {
      if ($scope.masterYN === false) {
        commonSVC.showMessage('회원탈퇴는 마스터계정만 가능합니다.');

        return false;
      }

      commonSVC.openModal('md', {}, 'ApplyCancelCtrl', 'views/configs/modals/apply_cancel.html');
    };

    // 이메일 인증 함수
    $scope.authEmail = async function(email, c_charge_no) {
      if (!email) {
        return commonSVC.showMessage('이메일이 입력되지 않았습니다. 입력 후 다시 시도해주세요.');
      }
      try {
        const result = await securityModel.auth(email, c_charge_no);

        if (result.data?.result === 'success') {
          commonSVC.showMessageHtml('이메일 인증 메일이 발송되었습니다.', '<ul><li>입력한 메일로 인증메일이 발송되었습니다. 수신된 인증 메일의 링크를 클릭하여 인증을 완료하세요.</li>\n\
          <li>이메일 인증에 대한 도움말은 <a onclick="window.open(\'https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=13301\');">여기</a>를 클릭하여 확인할 수 있습니다.</li>\n\
          <li>이메일 인증이 완료되면 이메일보내기 > 보내는 사람에서 이메일을 선택할 수 있습니다.</li>');
        } else if (result.data?.result === 'AlreadyVerified') {
          return commonSVC.showMessage('이미 등록된 메일입니다. 다른 메일을 입력해주세요.');
        } else if (result.data?.message) {
          return commonSVC.showMessage(result.data?.message);
        }
      } catch (err) {
        commonSVC.showMessage('이메일 인증 메일 발송이 실패되었습니다.\n\n잠시 뒤 다시 시도해주세요.');
      }
    };

    // 이메일 삭제 함수
    $scope.deleteVerifyEmail = async function(c_charge_no, email) {
      const isDelete = await commonSVC.showConfirm('이메일을 삭제하시겠습니까?', '삭제한 이메일을 다시 등록하려면 재인증이 필요합니다.');
      if (isDelete) {
        try {
          await securityModel.deleteVerifyEmail(c_charge_no, email);
          document.getElementById(`email_${email}`).value = '';
          $scope.admin.charge_info = $scope.admin.charge_info.map(info => {
            if (info.c_charge_no === c_charge_no) {
              return { ...info, charge_email: '', verify: false };
            }

            return info;
          });
        } catch (error) {
          commonSVC.showToaster('error', '실패', '이메일 삭제가 실패되었습니다.');
        }

      }
      if (!$scope.$$phase) {
        $scope.$apply(); // 디지털 디스크럽션 주기가 진행 중이 아니라면 $apply 호출
      }
    };

    // 이메일 인증확인 함수
    $scope.checkAuth = async function(c_charge_no, email) {
      $timeout(async function() {
        try {
          const result = await securityModel.checkAuth(email);
          $scope.admin.charge_info = $scope.admin.charge_info.map(info => {
            if (info.c_charge_no === c_charge_no) {
              return { ...info, verify: result.data };
            }

            return info;
          });

          return commonSVC.showMessage('이메일 인증이 완료되었습니다.');
        } catch (error) {
          return commonSVC.showMessage('이메일 인증이 완료되지 않았습니다.\n\n인증완료 후 다시 시도해주세요.');
        }
      });
    };

    // 이메일 인증 가이드 도움말 오픈 함수
    $scope.openGuide = function() {
      window.open('https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=13301');
    };
  })

// ===============================
// sms 심사보류 사유 모달 컨트롤러
// ===============================
  .controller('SmsAuthHoldReasonCtrl', function ($scope, data, $uibModalInstance) {
    $scope.hold_reason = data.hold_reason;

    /**
     * 취소
     */
    $scope.cancle = function () {
      $uibModalInstance.close();
    };
  });

