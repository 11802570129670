'use strict';

angular.module('gmpApp')
  .controller('AltalkTemplateManageCtrl', function($scope, commonSVC, settings, $compile, $rootScope, $uibModalInstance, atalkModel) {
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'template_create',
        sdate: '2001-01-01',
        edate: moment().format('YYYY-MM-DD'),
        template_type: '',
        status: '',
        talk_id: 'ALL',
        flag: 'gmp'
      },
      searchData: {
        all_checked: false,
        totalCount: 0,
        showCount: 100,
        selectCount: 0,
        search_key_items: [
          { label: '전체', value: 'all' },
          { label: '템플릿명', value: 'template_name' },
          { label: '상태', value: 'status' },
          { label: '템플릿 유형', value: 'template_type' },
        ]
      }
    };
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };
    $scope.grid.searchData = angular.copy(search.searchData);
    $scope.grid.searchForm = angular.copy(search.searchForm);
    $scope.grid.searchDetail = '';
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: true,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['created_at'],
      alignCenterColumns: ['widget', 'created_at', 'inspected_at', 'inspection_status', 'plus_name', 'hidden'],
      alignRightColumns: [],
      alignLeftColumns: ['id', 'name', 'user_name'],
      notSortingColumns: ['widget', 'id', 'plus_name', 'inspection_status', 'name', 'user_name'],
      notVisibleColumns: [],
      notMovingColumns: ['widget'],
      notResizingColumns: ['widget'],
      initPagingSize: 100,
      selectOptions: {
        checkbox: true,
        multiSelect: true
      },
      externalRequestOptions: {
        requestUrl: `${settings.adminApiUrl}/api/marketing/atalk/templateList`,
        requestWillAction: function (d) {
          const data = angular.merge({ sol_no: $rootScope.user_profile.sol_no }, d, $scope.searchForm, $scope.searchData);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function (row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.id}')" >상세</button>`;
          }
        },
        {
          key: 'id',
          title: '템플릿 번호',
          width: 150,
          hideColVis: true
        },
        {
          key: 'created_at',
          title: '생성일시',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'plus_name',
          title: '플러스친구 ID',
          width: 130,
          template: function (row) {

            return row.type === 'S' ? `@${row.plus_name}` : '-';
          }
        },
        {
          key: 'inspection_status',
          title: '승인상태',
          width: 150,
          template: function (row) {
            const statusObj = {
              APR: '승인',
              REJ: '반려',
              REG: '등록',
              REQ: '심사중',
              TEMP: '임시저장'
            };
            const font_color = row.inspection_status === 'APR' ? 'rgb(7,58,138)' : (row.inspection_status === 'REJ' ? 'rgb(212,0,31)' : 'black');

            return `<span style="color:${font_color} ">${statusObj[row.inspection_status]}</span>`;
          }
        },
        {
          key: 'name',
          title: '템플릿명',
          width: 150
        },
        {
          key: 'user_name',
          title: '생성자',
          width: 150
        },
        {
          key: 'inspected_at',
          title: '승인/반려일시',
          width: 150,
          filter: 'dateValid'
        },
        {
          key: 'hidden',
          title: '노출여부',
          width: 150,
          template: (row) => {
            // hidden이 1이면 숨김처리하고싶다는거
            row.use_yn = !row.hidden;

            return row.inspection_status === 'APR' ? '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>' : '-';
          }
        }
      ]
    };

    /**
     * ui-grid 노출 건수 설정
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    $scope.resetDo = function() {
      search.searchData.showCount = $scope.searchData.showCount;
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchData = angular.copy(search.searchData);

      $scope.searchDo(true, true);
    };

    $scope.deleteTemplate = () => {
      const templateList = $scope.grid.methods.selectedData();
      const filterTemplate = templateList.filter(t => !['TEMP', 'REQ', 'REJ'].includes(t.inspection_status)); // 임시저장, 심사중, 반려 상태 템플릿만 삭제
      if (!templateList.length) {
        commonSVC.showMessage('선택된 템플릿이 없습니다.', '삭제하실 템플릿을 선택해주세요.');

        return;
      } else if (filterTemplate.length) {
        commonSVC.showMessage('템플릿 삭제 불가', '승인 완료된 템플릿은 삭제할 수 없습니다.');

        return;
      } else {
        commonSVC.showConfirm('삭제 하시겠습니까?', '삭제 시 해당 템플릿은 복구되지 않습니다.', async (isConfirm) => {
          if (isConfirm) {
            try {
              await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/deleteTemplate`, { list: templateList });

              commonSVC.showToaster('success', '성공', '템플릿 삭제 성공');
              $scope.grid.methods.reloadData(() => {}, true);
            } catch (err) {
              commonSVC.showToaster('error', '실패', err.data.error);
            }
          }
        }
        );
      }
    };

    /**
     * 상세페이지 보여주기
     */
    $scope.showDetail = async function(id) {
      const result = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateDetail`, { template_no: id });
      const plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
      const resolve = {
        data: { ...result.data?.result, plusIdList: plusIdList.data?.result }
      };
      const modal = commonSVC.openModal('x3g', resolve, 'AltalkTemplateDetailCtrl', 'views/alimtalk/modals/altalk_template_detail.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.grid.methods.reloadData(function () {}, true);
        }
      });
    };

    /**
     * 그룹 삭제 버튼 활성화 여부
     */
    $scope.checkDisabledBtnOfDelete = () => {

      return (!$scope.grid.methods.selectedData().length || $scope.grid.methods.selectedData().some(v => v.type === 'G'));
    };

    /**
     * 템플릿 리스트에서 노출여부 변경시
     */
    $scope.changeDo = async function ({ id, use_yn, plus_friend_id }) {
      try {
        const params = {
          id,
          hidden: use_yn ? 0 : 1,
          plus_friend_id
        };
        const result = await commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/atalk/template`, params);

        if (result.data.result === 'success') {
          commonSVC.showToaster('success', '성공', '템플릿 노출여부 수정에 성공하였습니다.');
        } else {
          throw Error;
        }
      } catch (error) {
        commonSVC.showToaster('error', '실패', '템플릿 노출여부 수정에 실패하였습니다.');

        return false;
      }
    };
  });