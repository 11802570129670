'use strict';

angular.module('gmpApp')
  .controller('ConfigsSmsTemplateEdit', function ($rootScope, $scope, $uibModalInstance, $timeout, $compile, commonSVC, settings, data) {
    $scope.temp_data = {
      name: '',
      data: {
        content: '',
      },
      ref: ''
    };

    $scope.refData = {
      'order_name': '[주문자명]',
      'to_name': '[수령자명]',
      'to_addr': '[배송주소]',
      'shop_sale_name': '[상품명]',
      'shop_name': '[쇼핑몰명]',
      'seller_nick': '[쇼핑몰별칭]',
      'invoice_no': '[운송장번호]',
      'carr_name': '[택배사명]',
      'carr_name | invoice_no': '[택배사명 | 운송장번호]'
    };

    $scope.refChange = function() {
      $scope.temp_data.data.content = $scope.temp_data.data.content + $scope.refData[$scope.temp_data.ref];
      $scope.temp_data.ref = '';
    };

    (async function init() {
      const result = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/order/smsTemplate/${data.templateNo}`, {});
      $timeout(() => {
        $scope.temp_data.name = result.data.results.template_name;
        result.data.results.template_data = result.data.results.template_data.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t');
        $scope.temp_data.data = JSON.parse(result.data.results.template_data);
        $scope.currentData = JSON.stringify($scope.temp_data.data);
        $scope.currentName = JSON.stringify($scope.temp_data.name);
      });
    })();

    /**
     * 수정
     */
    $scope.onSubmit = async function () {
      // 템플릿명, 템플릿 내용 어느 컬럼  변경됐는지 프론트에서 전달
      const initialData = JSON.stringify($scope.temp_data.data);
      const initialName = JSON.stringify($scope.temp_data.name);
      const changeValue = [];
      if ($scope.currentName !== initialName) {
        changeValue.push('템플릿 명');
      }
      if ($scope.currentData !== initialData) {
        changeValue.push('템플릿 내용');
      }
      if ($scope.form1.$valid) {
        const param = $scope.temp_data;
        param.changeValue = changeValue;
        param.data = JSON.stringify(param.data);
        param.templateNo = data.templateNo;

        try {
          if (param.changeValue.length) {
            await commonSVC.sendUrl('PUT', `${settings.pa20ApiUrl}/app/order/smsTemplate`, param);
          }
          commonSVC.showToaster('success', '성공', 'SMS 템플릿 수정에 성공하였습니다', '');
          $uibModalInstance.close('success');
        } catch (err) {
          commonSVC.showToaster('error', '실패', 'SMS 템플릿 수정에 실패하였습니다', '');
        }
      } else {
        commonSVC.showMessage('전부입력해주세요');
      }
    };

    /**
     * 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };
  });
