'use strict';

angular.module('gmpApp')
  .controller('MemoTemplateListCtrl', function ($scope, $rootScope, userInfo, $uibModalInstance, settings, commonSVC, memoModel) {
    const search = {
      searchForm: {
        search_key: '',
        search_word: '',
      },
      searchData: {
        all_checked: false,
        totalCount: 0,
        showCount: 10,
        selectCount: 0,
        search_key_items: [
          { label: '전체', value: '' },
          { label: '주문', value: 'ord' },
          { label: '쇼핑몰 상품', value: 'ol_shop' },
          ...(userInfo.user.sol_stock > 0 ? [{ label: 'SKU상품', value: 'prod' }, { label: '세트상품', value: 'depot_set' }] : []),
          ...(userInfo.user.sol_ser.includes('ord_as') ? [{ label: 'AS(수선)', value: 'mend' }] : []),
          { label: '공통', value: 'all' },
        ]
      }
    };

    // 검색영역 데이터
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      gridHeight: 450,
      selectOptions: {
        checkbox: false,
        enableRowSelection: true,
        multiSelect: false
      },
      noAutoResize: true,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'template_no', 'memo_type', 'template_name', 'mdate', 'bookmark_yn'],
      notResizingColumns: ['mdate'],
      enableRowSelection: true,
      notMovingColumns: 'ALL',
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['widget'],
      alignRightColumns: [],
      notVisibleColumns: [],
      initPagingSize: 10,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/memos/get-templates`,
        requestWillAction: (data) => {
          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: (result) => {
          $scope.searchData.totalCount = result.recordsTotal;

          return result;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 200,
          template: function(row) {
            return (`
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.editForm(row.entity)">상세</button>
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.copyForm(row.entity)">복사</button>
              <button class="btn btn-default btn-xxs" ng-click="grid.appScope.deleteForm(${row.template_no})">삭제</button>
              `
            );
          }
        },
        {
          key: 'template_no',
          title: '템플릿 코드',
          width: 100,
        },
        {
          key: 'memo_type',
          title: '메모 구분',
          width: 150,
          template: (row) => {
            switch (row.memo_type) {
              case 'ord':
                return '주문';
              case 'ol_shop':
                return '쇼핑몰 상품';
              case 'prod':
                return 'SKU상품';
              case 'depot_set':
                return '세트상품';
              case 'mend':
                return 'AS(수선)';
              default:
                return '공통';
            }
          }
        },
        {
          key: 'template_name',
          title: '템플릿명',
          width: 350
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 200,
        },
        {
          key: 'bookmark_yn',
          title: '노출여부',
          width: 150,
          template: (row) => {
            return `<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeBookmark(${row.template_no}, ${row.bookmark_yn})"></pa-ui-switch>`;
          }
        },
      ]
    };

    /**
     * 테이블 검색
     */
    $scope.searchDo = (refresh = true, noDelay) => {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 테이블 검색 초기화
     */
    $scope.resetDo = () => {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDo(true, true);
    };

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchFn = {
      searchDo: () => {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: () => {
        $scope.grid.methods.length($scope.searchData.showCount);
      }
    };

    /**
     * 메모 템플릿 생성
     */
    $scope.addForm = async () => {
      const modal = await commonSVC.openModal('md', { data: { isEdit: false } }, 'MemoTemplateCtrl', 'views/etc/memo_template.html');

      if (await modal.result === 'success') {
        commonSVC.showToaster('success', '저장 완료', '메모 템플릿 저장에 성공하였습니다.');
        $scope.searchDo();
        $rootScope.$broadcast('reloadMemoTemplate');
      }
    };

    /**
     * 메모 템플릿 수정
     */
    $scope.editForm = async (template) => {
      const modal = await commonSVC.openModal('md', { data: { isEdit: true, template } }, 'MemoTemplateCtrl', 'views/etc/memo_template.html');

      if (await modal.result === 'success') {
        commonSVC.showToaster('success', '수정 완료', '메모 템플릿 수정에 성공하였습니다.');
        $scope.searchDo();
        $rootScope.$broadcast('reloadMemoTemplate');
      }
    };

    /**
     * 메모 템플릿 복사
     */
    $scope.copyForm = async (template) => {
      const result = await commonSVC.showConfirmCustom({
        title: '템플릿 복사',
        text: '선택한 메모 템플릿을 복사하시겠습니까?',
        showCancelButton: true,
      });

      if (result) {
        const { bookmark_yn, memo_type, template_name, template_data } = template;

        try {
          await memoModel.addTemplate({ bookmark_yn, memo_type, template_name, template_data });

          commonSVC.showToaster('success', '복사 완료', '메모 템플릿 복사에 성공하였습니다.');
          $scope.searchDo();
          $rootScope.$broadcast('reloadMemoTemplate');
        } catch (err) {
          commonSVC.showToaster('error', '복사 실패', '메모 템플릿 복사에 실패하였습니다.');
        }
      }

    };

    /**
     * 메모 템플릿 삭제
     */
    $scope.deleteForm = async (template_no) => {
      const result = await commonSVC.showConfirmCustom({
        title: '템플릿 삭제',
        text: '선택한 메모 템플릿을 삭제하시겠습니까?',
        showCancelButton: true,
      });

      if (result) {
        try {
          await memoModel.deleteTemplate({ template_no });

          commonSVC.showToaster('success', '삭제 완료', '메모 템플릿 삭제에 성공하였습니다.');
          $scope.searchDo();
          $rootScope.$broadcast('reloadMemoTemplate');
        } catch (err) {
          commonSVC.showToaster('error', '삭제 실패', '메모 템플릿 삭제에 실패하였습니다.');
        }
      }
    };

    /**
     * 노출여부 변경
     */
    $scope.changeBookmark = async (template_no, bookmark_yn) => {
      try {
        await memoModel.editTemplate({ template_no, bookmark_yn: !bookmark_yn });

        commonSVC.showToaster('success', '수정 완료', '노출여부 수정에 성공하였습니다.');
        $scope.searchDo();
        $rootScope.$broadcast('reloadMemoTemplate');
      } catch (err) {
        commonSVC.showToaster('error', '수정 실패', '노출여부 수정에 실패하였습니다.');
      }
    };

    /**
     * 닫기(취소)
     */
    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });