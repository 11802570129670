/**
 * 반송 운송장 출력
 */
'use strict';

angular.module('gmpApp')
  .controller('claimInvoiceCtrl', function ($scope, $rootScope, $timeout, userInfo, settings, data, selectList, totalCount, commonSVC, shipmentModel, $uibModalInstance, deliveryModel) {
    $scope.custom = false;
    $scope.html = '';
    // 실제 운송장 출력 가능여부
    $scope.refusePrint = true;
    $scope.isReprint = !!data.carr_no; // 재출력 여부
    // 승인완료된 택배사 리스트
    const approveCarrierLists = [];
    $scope.title = data.claimType === 'return' ? '반품' : '교환';

    $scope.deliveryAllList = angular.copy(data.solCarrList.filter(o => {
      if (o.carr_id !== '' && parseInt(o.contract_yn) === 1 && parseInt(o.reg_confirm_yn) === 1 && o.carr_no === data.carr_no) {
        approveCarrierLists.push(`${o.carr_no}+${o.carr_id}`);
      }

      return o.carr_no === data.carr_no;
    })) || [];  // 택배사 리스트

    $scope.templateList = []; // 양식 리스트
    $scope.selectedDeli = {}; // 선택된 택배사 정보
    $scope.selectedTemp = {}; // 선택된 양식 정보

    $scope.selectList = selectList.data.results || [];
    $scope.selectListCount = selectList.data.recordsTotal;
    $scope.today = new Date();

    $scope.wait = false;
    $scope.totalProdCount = 0;
    $scope.print_set_yn = 0;

    let isPrint = false;

    /**
    * 택배사 선택
    */
    $scope.deliverySelect = deli => {
      $(`#delivery_${deli.carr_no}_${deli.carr_id}`).prop('checked', true);
      $scope.selectedDeli = deli;
      loadTemplate();
    };

    /**
    * 양식 선택
    */
    $scope.templateSelect = async (temp) => {
      $(`#template_${temp.type}`).prop('checked', true);  // 라디오박스 체크
      $scope.selectedTemp = angular.copy(temp);

      if (temp.type === 'temp' && !(temp.to_name && temp.to_tel && temp.to_zipcd && temp.to_addr1 && temp.to_addr2)) {
        $scope.editTemplate();
      }
    };

    /**
    * 양식 수정하기
    */
    $scope.editTemplate = () => {
      if ($scope.selectedTemp.type === 'temp') {
        const resolve = {};

        resolve.data = {
          delivery: angular.copy($scope.selectedDeli),
          template: angular.copy($scope.selectedTemp),
          pageType: 'edit',
          parent: $scope,
        };
        const modal = commonSVC.openModal('xxg', resolve, 'claimInvoiceTemplateCtrl', 'views/order/shipment/modals/claim_invoice_template.html', null, false);

        modal.result.then(() => {
          $timeout(() => {
            loadTemplate();
          }, 500);
        });
      } else {
        commonSVC.showMessage('수정할 수 없는 양식입니다.');

        return false;
      }
    };

    /**
    * 취소
    */
    $scope.cancel = () => {
      if (isPrint) {
        $uibModalInstance.close('success');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    // carr_no, carr_id로 템플릿 리스트 로드
    function loadTemplate() {
      const data = {
        carr_no: $scope.selectedDeli.carr_no,
        carr_id: $scope.selectedDeli.carr_id,
        carr_id_sub: $scope.selectedDeli.carr_id_sub,
      };

      if (![4, 956].includes(data.carr_no)) {
        $('#spinner').removeClass('hidden');
        deliveryModel.ClaimTemplateList(data, (state, data) => {
          if (state === 'success') {
            $scope.templateList[0] = {
              type: 'temp',
              ...(data.result && data.result)
            };

            $scope.templateList[1] = {
              type: 'order',
              carr_no: $scope.selectedDeli.carr_no,
              carr_id: $scope.selectedDeli.carr_id
            };
            $('#spinner').addClass('hidden');
          } else {
            commonSVC.showToaster('error', '실패', '양식 조회 실패');
            $('#spinner').addClass('hidden');
          }
        });

      }
      // 선택 양식이 승인완료된 양식인경우 출력버튼 활성화.
      $scope.refusePrint = !_.includes(approveCarrierLists, `${$scope.selectedDeli.carr_no}+${$scope.selectedDeli.carr_id}`);
      $scope.selectedTemp = [4, 956].includes(data.carr_no) ? {
        type: 'order',
        ...data
      } : null;
    }

    /**
    * 하단 버튼 클릭
    */
    $scope.print = _.throttle(async (selectedTemp) => {

      if (!selectedTemp) {
        selectedTemp = $scope.selectedTemp;
      }

      $scope.delivery = $scope.selectedDeli;

      if ($scope.selectedDeli.carr_no && selectedTemp?.carr_no) {
        $timeout(async () => {
          //실제 출력일 경우 운송장 번호 발급 후 출력

          // 출력 택배사가 미승인인경우 버튼비활성화 처리했지만 개발자도구로 속성을 지우고 출력하는 양아치짓을 할수있어 여기서도 막음.
          if ($scope.refusePrint) {
            commonSVC.showMessage('미승인 택배사 입니다.', '승인완료된 택배사만 운송장 출력이 가능합니다.');

            return false;
          }

          isPrint = true;
          printInvoice();

        }, 100);
      } else {
        commonSVC.showMessage('택배사와 양식을 선택하세요.');

        return false;
      }
    }, 1500, { trailing: false });

    // 운송장 번호 발급 - GMP.Carrier.API
    async function printInvoice() {
      $scope.wait = true;
      // 운송장 출력시 로딩 추가.
      $('#spinner').removeClass('hidden');

      const param = {
        uniqList: $scope.selectList.map(sel => sel.uniq),
        bundleList: $scope.selectList.map(sel => sel.bundle_no).filter((bundleNo, index) => $scope.selectList.findIndex(ord => ord.bundle_no === bundleNo) === index),
        templateData: { claimType: $scope.title, ...$scope.selectedTemp },
      };

      deliveryModel.claimInvoiceIssue(param, async (status, returnData) => {
        if (status === 'success') {
          if (!returnData.result) {
            commonSVC.showMessage('택배 API 호출 에러');
            $scope.wait = false;
          } else {
            const errOrds = returnData.result.filter(o => o.publishErrMsg);

            const addMsg = errOrds.length ? errOrds.reduce((prev, curr) => prev + `\n묶음번호 - ${curr.bundleNo}: ${curr.publishErrMsg}`, `아래의 주문건은 에러발생으로 ${$scope.title} 접수에 실패되었으니 확인해주십시오.\n`) : $scope.selectedDeli.carr_no === 4 ?
              '반송장번호는 LOIS Parcel>운송장출력>운송장출력에서 확인가능합니다.\n(반송장번호 발번까지 최대 10분 소요)' : '';

            if (returnData.result.filter(o => !o.publishErrMsg).length) {
              commonSVC.showMessage(`${$scope.title}접수 신청이 완료 되었습니다.`, addMsg);
            } else {
              const failMsg = errOrds.reduce((prev, curr) => prev + `\n묶음번호 - ${curr.bundleNo}: ${curr.publishErrMsg}`, '');
              commonSVC.showMessage(`${$scope.title}접수 신청이 실패 되었습니다.`, failMsg);
            }

            $uibModalInstance.close('success');
          }

        } else if (status == 'error') {
          $timeout(() => {
            commonSVC.showMessage('실패', returnData.data.error);
          }, 500);
          $scope.wait = false;
        }
        $('#spinner').addClass('hidden');
      });
    }
  });