'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A084-Ctrl', function ($scope, $rootScope, $timeout, siteInfo, commonModel, shopAccountModel, onlineProductModel, commonSVC, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.shop_data = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = _.cloneDeep(data.data.detailType);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.data.prod_info_opt === '건강기능식품' && !$scope.row.set_info.key_exportPlc_Cd) {
        commonSVC.showMessage('수출국을 확인하세요');

        return false;
      }
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 현재가만 지원함
    $scope.row.set_info.key_prcInfo = '1';

    // 19금상품 기본값세팅
    if ($scope.detailType !== 'edit') {
      $scope.$watchCollection('data.minor', function () {
        $scope.row.set_info.key_adultItemTypeCd = $scope.data.minor
          ? '10'
          : '90';
      });
    }

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.row.set_info[name] = v.url;
              $scope.row.set_info[`${name}_name`] = v.origFileName;
            }
          });
        });
    };

    $scope.fileClear = function (name) {
      delete $scope.row.set_info[name];
      delete $scope.row.set_info[`${name}_name`];
    };

    function init() {
      // 해당값이 없는경우 초기화 처리 추가 2018-03-27 rony
      if (_.isUndefined($scope.row.set_info)) {
        $scope.row.set_info = {};
      }

      // 판매유형
      $scope.row.set_info.key_itemSellTypeCd = '';

      // 상품정보고시 SSG 추가항목 확인전까지 상세설명 참조로 보냄
      const gosi = ['key_sellCapaUnitCd', 'key_sellTotCapa', 'key_sellUnitCapa', 'key_stock', 'key_mnrmNm', 'key_selPayYn', 'key_guideTip'];

      _.each(gosi, function (key) {
        $scope.row.set_info[key] = '[상세설명참조]';
      });

      getUserInfo();
      const userInfo = $scope.userInfo,
            domain = siteInfo.A084.domain,
            action = 'list_orplc',
            params = '';

      commonSVC.requestPA(userInfo, domain, action, params, commonModel, shopAccountModel)
        .then(function(res) {
          $scope.SSGOrplc = res.data.data.items;
        });
    }

    // 제조국
    $scope.getListOrplc = function () {
      const plcNm = $scope.row.orplc_Nm;

      getList(plcNm)
        .then(function(res) {
          // 검색결과가 하나일 경우 object 형태로 반환되기 때문에 리스트로 변환작업
          $scope.SSGOrplc = res.data.data.items.length > 1 ? res.data.data.items : [res.data.data.items];
        });
    };

    // 수출국
    $scope.getListExplc = function () {
      const plcNm = $scope.row.exportPlc_Nm;

      getList(plcNm)
        .then(function (res) {
          $scope.SSGExplc = res.data.data.items;
        });
    };

    /**
     * 조회 저장
     * type 1: 제조국, 2: 수출국
     */
    $scope.selecPlc = function (type) {
      const target = type === 1
        ? $('#selecOrplc option:selected')
        : $('#selecExplc option:selected');

      const val = target.val(),
            name = target.text();

      if (!val || parseInt(val).toString() === 'NaN') {
        return false;
      }

      if (type === 1) {
        $scope.row.set_info.key_orplc_Cd = val;
        $scope.row.set_info.key_orplc_Nm = name;
      } else {
        $scope.row.set_info.key_exportPlc_Cd = val;
        $scope.row.set_info.key_exportPlc_Nm = name;
      }
    };

    function getUserInfo() {
      const use_engines = $rootScope.use_channel_list,
            use_engines_len = use_engines.length;
      let row;

      for (let i = 0; i < use_engines_len; i++) {
        const ue = use_engines[i];

        if (ue.shop_cd === 'A084' && ue.shop_id === $scope.shop_data.shop_id) {
          row = ue;
        }
      }

      $scope.userInfo = row;
    }

    /**
     * SSG 원산지 조회
     */
    function getList(plcNm) {
      const orplcSearchValue = plcNm,
            action = 'list_orplc';

      // 아무것도 입력 안하고 검색을 하면 모든 제조국이 나오도록
      const requestData = _.isUndefined(orplcSearchValue)
        ? ''
        : { orplcId: '', orplcNm: orplcSearchValue };

      return requestPA(action, requestData);
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo,
            domain = siteInfo.A084.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    init();
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
