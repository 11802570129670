'use strict';

angular.module('gmpApp')
  .controller('GiftDetailCtrl', function (
    $rootScope, $scope, $uibModalInstance, $timeout,
    localStorageService, commonSVC, giftSVC, logHistorySVC,
    giftModel,
    warehouseList, data, giftList
  ) {
    $scope.mode = data.gift_no ? 'edit' : 'add';

    // directive(form) 용
    $scope.reCheckClass = {};

    // 전체 사은품규칙 리스트 (규칙 불러오기)
    $scope.giftList = giftList ? giftList.data.results : [];

    // SKU 직접입력 플래그
    const default_flag = !$rootScope.user_profile.sol_stock;
    $scope.flag = { custom_gift: default_flag };

    const channelList = angular.copy($rootScope.use_channel_list);

    // 쇼핑몰 리스트
    $scope.useChannelList = _.uniqBy(channelList, 'shop_cd').filter(ch => !ch.pa_shop_cd.startsWith('X') && ch.pa_shop_cd === ch.shop_cd);

    // 쇼핑몰ID 리스트
    $scope.useChannelIdList = channelList.filter(ch => !ch.pa_shop_cd.startsWith('X'));

    // 배송처 리스트
    $scope.warehouseList = warehouseList.data.result || [];

    // 로그용 데이터
    let old = {};
    const logField = {
      gift_rule_name: { prefix: '사은품 규칙명' },
      rule_type: {
        prefix: '적용 쇼핑몰 규칙',
        format: value => {
          switch (value) {
            case 'shop_cd': return '특정 쇼핑몰';
            case 'shop_id': return '특정 ID';
          }

          return '전체 쇼핑몰';
        }
      },
      site_list: {
        prefix: '적용 쇼핑몰',
        differenceWith: (oldData, newData, key) => {
          // 적용 쇼핑몰 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.rule_type !== newData.rule_type) { return { add: [], del: [] }; }

          const only_shopCd = oldData.rule_type == 'shop_cd';

          const add = _.differenceWith(newData[key], oldData[key], (a, b) => (only_shopCd ? a.shop_cd === b.shop_cd : (a.shop_cd === b.shop_cd && a.shop_id === b.shop_id)));
          const del = _.differenceWith(oldData[key], newData[key], (a, b) => (only_shopCd ? a.shop_cd === b.shop_cd : (a.shop_cd === b.shop_cd && a.shop_id === b.shop_id)));

          return { add, del };
        },
        format: value => {
          return value.shop_name;
        }
      },
      directinput_ord_apply_yn: {
        prefix: '직접입력 주문 포함여부',
        difference: (oldData, newData, key) => {
          // 적용 쇼핑몰 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.rule_type !== newData.rule_type) { return false; }

          return oldData[key] != newData[key];
        },
        format: value => {
          return value ? 'Y' : 'N';
        }
      },
      sdate: { prefix: '적용 기간(시작일)' },
      edate: { prefix: '적용 기간(종료일)' },
      stime: {
        prefix: '적용 시간(시작)',
        format: value => {
          if (!value) {
            return 'null';
          }

          return giftModel.formatTime(value);
        }
      },
      etime: {
        prefix: '적용 시간(종료)',
        format: value => {
          if (!value) {
            return 'null';
          }

          return giftModel.formatTime(value);
        }
      },
      sales_type: {
        prefix: '합계 금액 규칙',
        format: value => {
          switch (value) {
            case 'between': return '금액범위';
            case 'over': return '일정 금액 이상';
          }

          return '모든 금액 포함';
        }
      },
      s_sales: {
        prefix: '합계 최소금액',
        suffix: '원',
        difference: (oldData, newData, key) => {
          // 합계 금액 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.sales_type !== newData.sales_type) { return false; }

          return oldData[key] != newData[key];
        }
      },
      e_sales: {
        prefix: '합계 최대금액',
        suffix: '원',
        difference: (oldData, newData, key) => {
          // 합계 금액 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.sales_type !== newData.sales_type) { return false; }

          return oldData[key] != newData[key];
        }
      },
      sale_cnt_type: {
        prefix: '합계 수량 규칙',
        format: value => {
          switch (value) {
            case 'between': return '수량범위';
            case 'over': return '일정 수량 이상';
          }

          return '사용안함';
        }
      },
      s_sale_cnt: {
        prefix: '합계 최소수량',
        suffix: '건',
        difference: (oldData, newData, key) => {
        // 합계 금액 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.sale_cnt_type !== newData.sale_cnt_type) { return false; }

          return oldData[key] != newData[key];
        }
      },
      e_sale_cnt: {
        prefix: '합계 최대수량',
        suffix: '건',
        difference: (oldData, newData, key) => {
          // 합계 금액 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.sale_cnt_type !== newData.sale_cnt_type) { return false; }

          return oldData[key] != newData[key];
        }
      },
      add_rule_type: {
        prefix: '상품 선택 기준',
        format: value => {
          switch (value) {
            case 'depot': return '배송처';
            case 'prod_depot': return 'SKU코드';
            case 'shop_sale': return '쇼핑몰 상품코드';
          }

          return '사용안함';
        }
      },
      add_rule_apply_type: {
        prefix: '선택 상품 규칙',
        difference: (oldData, newData, key) => {
          // 상품 선택 기준이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.add_rule_type !== newData.add_rule_type) { return false; }

          return oldData[key] != newData[key];
        },
        format: value => {
          switch (value) {
            case 'or': return '일부포함';
            case 'except': return '제외';
          }

          return '전체포함';
        }
      },
      depot_no: {
        prefix: '선택 상품(배송처)',
        difference: (oldData, newData, key) => {
          // 상품 선택 기준이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.add_rule_type !== newData.add_rule_type) { return false; }

          return oldData[key] != newData[key];
        },
        format: value => {
          const warehouse = $scope.warehouseList.find(({ code }) => code == value);

          return warehouse ? warehouse.warehouse_name : '';
        }
      },
      prod_depot: {
        prefix: '선택 상품(SKU코드)',
        differenceWith: (oldData, newData, key) => {
          // 상품 선택 기준이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.add_rule_type !== newData.add_rule_type) { return { add: [], del: [] }; }

          const add = _.differenceWith(newData[key], oldData[key], (a, b) => (a.prod_no === b.prod_no));
          const del = _.differenceWith(oldData[key], newData[key], (a, b) => (a.prod_no === b.prod_no));

          return { add, del };
        },
        format: value => {
          return value.sku_cd;
        }
      },
      shop_sale: {
        prefix: '선택 상품(쇼핑몰 상품코드)',
        differenceWith: (oldData, newData, key) => {
          // 상품 선택 기준이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.add_rule_type !== newData.add_rule_type) { return { add: [], del: [] }; }

          const add = _.differenceWith(newData[key], oldData[key], (a, b) => (a === b));
          const del = _.differenceWith(oldData[key], newData[key], (a, b) => (a === b));

          return { add, del };
        }
      }
    };
    const logFieldProd = {
      dist_type: {
        prefix: '분배 규칙',
        format: value => {
          switch (value) {
            case 'per_term': return '규칙마다';
            case 'per_sale_cnt': return '주문수량';
          }

          return '고정수량';
        }
      },
      dist_cnt: {
        prefix: '분배 수량',
        difference: (oldData, newData, key) => {
          // 분배 규칙이 변경됐다면 새로운 값이므로 비교할 필요 없음
          if (oldData.dist_type !== newData.dist_type) { return false; }

          return oldData[key] != newData[key];
        },
        suffixRenderer: ({ dist_type }) => {
          switch (dist_type) {
            case 'fixed': return '개';
            case 'per_term': return '개씩';
          }

          return '배';
        }
      }
    };

    // Description 칸
    $scope.showDesc = localStorageService.get('giftShowDescYn') || 'Y';

    // 선택상품 규칙설명
    $scope.add_rule_apply_type_desc = {
      and: '선택한 상품 전체가 주문에 포함되어야 사은품이 분배됩니다. (합포장 기준)',
      or: '여러 상품 선택 시 선택한 상품중에 하나만 포함되어있어도 사은품이 지급됩니다. (합포장 기준)',
      except: '선택한 상품은 사은품 규칙 조건에서 제외 됩니다.',
    };

    // 사은품상품명 설명
    $scope.gift_prod_desc = `${$rootScope.user_profile.sol_stock ? `등록된 SKU상품을 검색하거나 직접 입력하여 사용할 수 있습니다.
등록된 SKU상품을 선택할 경우 사은품의 재고관리가 가능합니다.
` : ''}사은품 설정 후 [사은품 추가] 버튼을 눌러서 사은품을 추가해 주세요.`;

    // 사은품 분배방법
    $scope.dist_method_desc = {
      all: '지정 사은품 분배',
      rand: '랜덤 사은품 분배',
      firstcome: '소진시 다음 사은품 분배(추가된 사은품 1번,2번,3번...순서로 분배)'
    };

    // 분배수량 수량표기
    $scope.dist_type_desc = {
      fixed: '개',
      per_term: '개씩',
      per_sale_cnt: '배'
    };

    // 커스텀 에러라벨
    $scope.custom_error = {
      prod_depot: false,
      prod_name: false,
    };

    // 분배수량 에러Msg
    const err_per_term = '분배 수량 [규칙마다 n개씩]은\n합계 금액을 [모든 금액 포함], 합계 수량을 [일정 수량 이상]으로 설정하거나\n합계 금액을 [일정 금액 이상], 합계 수량을 [사용안함]으로\n설정했을 때만 사용 가능합니다.';

    // 사은품 1건 정보
    const giftProd = {
      prod_name: '',
      prod_no: null,
      depot_no: null,
      sku_cd: '',
      attri: '',
      real_stock: null,
      dist_type: 'fixed',
      dist_cnt: 1,
      gift_used_cnt_limit_yn: 0,
      gift_used_cnt_limit: 1,
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('giftShowDescYn', $scope.showDesc);
    };

    /**
     * 사은품설정 > 분배수량 > 규칙마다 n개씩 사용 가능 조건
     * 1. 합계 금액이 [모든금액 포함] 이면서 합계수량이 [일정 수량 이상] 일때
     * 2. 합계 금액이 [일정금액 이상] 이면서 합계수량이 [사용안함] 일때
     */
    function avail_per_term() {
      return ($scope.gift.sales_type == 'all' && $scope.gift.sale_cnt_type == 'over') ||
        ($scope.gift.sales_type == 'over' && $scope.gift.sale_cnt_type == 'none');
    }

    /**
     * 타입 변경시
     */
    $scope.changeRuleType = (type, prev = null) => {
      switch (type) {
        // 합계금액 타입
        case 'sales_type':
          $scope.gift.s_sales = null;
          $scope.gift.e_sales = null;
          break;

        // 합계수량 타입
        case 'sale_cnt_type':
          $scope.gift.s_sale_cnt = null;
          $scope.gift.e_sale_cnt = null;
          break;

        // 소진시 다음사은품 분배
        case 'dist_method':
          if ($scope.gift.dist_method == 'firstcome') {
            $scope.giftProd.gift_used_cnt_limit_yn = 1;
          }
          break;

        // 규칙마다 n개씩
        case 'dist_type':
          if ($scope.giftProd.dist_type == 'per_term' && !avail_per_term()) {
            commonSVC.showMessage('', err_per_term, () => {
              $timeout(function () {
                $scope.giftProd.dist_type = prev;
              });
            });
          }
          break;
      }
    };

    /**
     * SKU 매핑
     * @param {} mode
     */
    $scope.mappingProd = async (mode) => {
      const resolve = {};
      resolve.data = {
        from: '사은품',
        warehouseList: $scope.warehouseList,
        isMulti: mode == 'prod_depot'
      };
      const prodList = await commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html').result;
      if (mode == 'prod_depot') {
        $scope.custom_error.prod_depot = false;

        prodList.forEach(prod => {
          // 기존에 선택된 SKU상품 아닐 경우에만
          if (!$scope.gift.prod_depot.some(o => o.prod_no === prod.prod_no && o.depot_no === prod.depot_no)) {
            // SKU코드 리스트에 추가
            $scope.gift.prod_depot.push({ prod_no: prod.prod_no, sku_cd: `${prod.sku_cd} / ${prod.depot_name}`, depot_no: prod.depot_no });
          }
        });
      } else {
        if (prodList.some(prod => !prod.bundle_avail_yn)) {
          commonSVC.showMessage('매칭실패', '합포장 불가한 SKU상품은 사은품으로 생성하실 수 없습니다. ');

          return false;
        }

        $timeout(function () {
          $scope.custom_error.prod_name = false;
          $scope.flag.custom_gift = false;
        });

        $scope.giftProd.prod_name = prodList[0].prod_name;
        $scope.giftProd.prod_no = prodList[0].prod_no;
        $scope.giftProd.depot_no = prodList[0].depot_no;
        $scope.giftProd.sku_cd = prodList[0].sku_cd;
        $scope.giftProd.attri = prodList[0].attri;
        $scope.giftProd.real_stock = prodList[0].real_stock;
        $scope.giftProd.depot_name = $scope.warehouseList.find(({ code }) => code == prodList[0].depot_no)?.warehouse_name || '';
      }
    };

    /**
     * 사은품설정 초기화
     */
    $scope.resetGiftProd = (mode = 'all') => {
      if (mode == 'all') {
        $scope.giftProd = Object.assign(angular.copy(giftProd), $scope.giftProd ? { gift_used_cnt_limit_yn: $scope.giftProd.gift_used_cnt_limit_yn } : {});
        $scope.flag.custom_gift = default_flag;
        $scope.custom_error.prod_name = false;

        return;
      }

      $scope.giftProd.prod_name = '';
      $scope.giftProd.prod_no = null;
      $scope.giftProd.depot_no = null;
      $scope.giftProd.sku_cd = '';
      $scope.giftProd.attri = '';
      $scope.giftProd.real_stock = null;
    };

    /**
     * 사은품 추가
     */
    $scope.addGiftProd = () => {
      if (!$scope.giftProd.prod_name) {
        $scope.custom_error.prod_name = true;

        return;
      }

      $scope.gift.giftProds.push(angular.copy($scope.giftProd));
      $scope.resetGiftProd();
    };

    /**
     * 사은품 삭제
     */
    $scope.delGiftProd = async (index) => {
      const confirm = await commonSVC.showConfirm('사은품 삭제', '추가된 사은품을 삭제합니다.');
      if (confirm) {
        $timeout(function () {
          $scope.gift.giftProds.splice(index, 1);
        });
      }
    };

    /**
     * 쇼핑몰상품코드 입력란 키 제어
     */
    $scope.onKeyPress = e => {
      if (e.keyCode === 13) {
        const values = e.target.value;
        const arr = values.split(/\s*\n\s*/).filter(o => o);

        $timeout(function () {
          arr.forEach(o => {
            $('.bootstrap-tagsinput input').val(o);
            $('.bootstrap-tagsinput input').trigger($.Event('keypress', { which: 13 }));
          });

          e.target.value = '';
        });
      }
    };

    /**
     * 사은품 수정 or 추가
     */
    $scope.giftUpdate = async() => {
      $scope.reCheckClass.valid('recheck');

      // 쇼핑몰 상품코드 입력란 엔터
      if ($scope.gift.add_rule_type == 'shop_sale') {
        try {
          // 멀티검색 입력란 엔터 이벤트 강제발생
          $timeout(function () {
            document
              .querySelector('#shop_sale_input')
              .dispatchEvent(new KeyboardEvent('keypress', { keyCode: 13 }));
          });
        } catch (err) { }
      }

      try {
        // SKU코드 확인
        if ($scope.gift.add_rule_type == 'prod_depot' && !$scope.gift.prod_depot.length) {
          $scope.custom_error.prod_depot = true;
          throw Error('필수값을 모두 입력해주세요');
        }

        if (!$scope.giftForm.$valid || ($scope.gift.rule_type !== 'all' && !$scope.gift.site_list.length)) { throw Error('필수값을 모두 입력해주세요'); }

        if (!$scope.gift.giftProds.length) {
          $('#addGiftProd').focus();
          throw Error('[사은품 설정]에서 분배할 사은품을 추가해 주세요.');
        }

        if ($scope.gift.dist_method != 'all' && $scope.gift.giftProds.length < 2) {
          $('#addGiftProd').focus();
          throw Error('[사은품 분배 방법]을 "랜덤 사은품 분배" 또는 "소진 시 다음 사은품 분배" 로 설정하신 경우 사은품을 2개 이상 추가해야 합니다.\n하나의 사은품만 분배할 경우 "지정 사은품 분배" 로 선택해 주세요.');
        }

        let ck = _.findIndex($scope.gift.giftProds, o => !o.gift_used_cnt_limit_yn);
        if ($scope.gift.dist_method == 'firstcome' && ck > -1) {
          $(`#gift_used_cnt_limit_yn_${ck}`).focus();
          throw Error('[사은품 분배 방법]을 "소진 시 다음 사은품 분배" 로 설정하신 경우 사은품 "제한수량" 을 반드시 사용해야 합니다');
        }

        ck = _.findIndex($scope.gift.giftProds, o => o.dist_type == 'per_term');
        if (ck > -1 && !avail_per_term()) {
          $(`#dist_type_${ck}`).focus();
          throw Error(err_per_term);
        }

        // ---- 여기까지 유효성 ---- //
        $scope.gift.mode = $scope.mode;
        const toasterTxt = $scope.mode === 'edit' ? '수정' : '등록';

        if ($scope.mode === 'edit') {
          $scope.gift.log_contents = logHistorySVC.getDifferentLogs(logField, old, $scope.gift);

          for (let i = 0; i < old.giftProds.length; i++) {
            $scope.gift.log_contents.push(...logHistorySVC.getDifferentLogs(logFieldProd, old.giftProds[i], $scope.gift.giftProds[i], `[사은품 ${i + 1}]`));
          }
        }

        const result = await giftModel.save($scope.gift);

        if (result.data.result === 'success') {
          commonSVC.showToaster('success', '', `사은품 ${toasterTxt}에 성공하였습니다.`);
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '', `사은품  ${toasterTxt}에 실패하였습니다.`);
          $timeout(function () {
            $scope.waitBtnDisableFlag = false;
          }, 1000);
        }
      } catch (err) {
        commonSVC.showMessage('', err.message);
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
      }
    };

    /**
     * 다른 규칙 불러오기
     */
    $scope.importGift = async(gift_no) => {
      if (gift_no) {
        await getDetail(gift_no);
      }
    };

    /**
     * 시간설정 체크박스 disabled여부 설정 함수
     * 시간설정 체크박스는 스마트스토어, 카카오톡 스토어, 카카오 선물하기 쇼핑몰 선택시에만 체크박스 활성화
     */
    $scope.isTimeSelectEnabled = () => {
      return $scope.gift.site_list.length && $scope.gift.site_list.every(site => ['B688', 'B700', 'A077'].includes(site.pa_shop_cd));
    };

    /**
     * 시간설정값 reset 함수
     */
    $scope.resetTime = () => {
      $scope.gift.stime = $scope.gift.etime = null;
      $scope.gift.specify_time_yn = 0;
    };

    /**
     * 창닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 로딩중 이미지
     */
    function loading(is_loading) {
      if (is_loading) { // 로딩중
        $('#loginSpinner').removeClass('hidden');
      } else { // 로딩완료
        $('#loginSpinner').addClass('hidden');
      }
    }

    /**
     * 선택상품 기준 변경감지
     */
    $scope.$watch('gift.add_rule_type', (nv, ov) => {
      if (nv === ov) { return; }

      if (nv == 'shop_sale' && $scope.gift.shop_sale.length) {
        // 멀티검색 입력란 엔터 이벤트 강제발생
        $timeout(function () {
          const ele = document.querySelector('#shop_sale_input');
          ele.value = $scope.gift.shop_sale.join('\n');
          ele.dispatchEvent(new KeyboardEvent('keypress', { keyCode: 13 }));

          loading(false);
        });
      }
    });

    /**
     * 적용 쇼핑몰 변경감지
     */
    $scope.$watch('gift.rule_type', (nv, ov) => {
      if (nv === ov) { return; }

      if (nv !== 'all') {
        const isShopType = $scope.gift.rule_type == 'shop_cd';
        const useChannelList = isShopType ? $scope.useChannelList : $scope.useChannelIdList;

        const pushSiteIdx = $scope.gift.site_list.map(site =>
          useChannelList.findIndex(o =>
            isShopType ? (o.pa_shop_cd == site.pa_shop_cd) : (o.shop_cd == site.shop_cd && o.shop_id == site.shop_id)
          )
        );

        $timeout(function () {
          initSelect2();

          $('#shopList').val(pushSiteIdx).trigger('change');

          loading(false);
        });
      }
      !$scope.isTimeSelectEnabled() && $scope.resetTime();
    });

    /**
     * select2 초기화
     */
    function initSelect2() {
      $scope.channelSelectAll = true;

      // 기존 select2 제거
      if ($('#shopList').data('select2')) {
        $('#shopList').val([]).trigger('change');
        $('#shopList').select2('destroy');
        $('#shopList').off('change.select2');
      }

      // 새로 생성
      $('#shopList').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: `쇼핑몰${$scope.gift.rule_type == 'shop_cd' ? '' : '(계정)'} 선택`,
        closeOnSelect: false
      });
      $('input.select2-search__field').css('width', '970px');

      // 쇼핑몰 선택 시 액션
      $('#shopList').on('change.select2', function () {
        let va = $(this).val() || [];
        const site_list = [];
        const isShopType = $scope.gift.rule_type == 'shop_cd';
        const useChannelList = isShopType ? $scope.useChannelList : $scope.useChannelIdList;

        if (va[0] == 'all') {
          $scope.channelSelectAll = false;
          va = [];
          for (let i = 0; i < useChannelList.length; i++) {
            va.push(i);
          }
          $(this).val(va).trigger('change');
          $(this).select2('close');

          return;
        }

        if (va[0] == 'del') {
          $scope.channelSelectAll = true;
          $scope.gift.site_list = [];
          $(this).val([]).trigger('change');
          $(this).select2('close');

          return;
        }

        va.forEach(v => {
          const idx = parseInt(v);
          const site = useChannelList[idx];
          const siteIndex = $scope.gift.site_list.findIndex(o => isShopType ? (o.shop_cd == site.shop_cd) : (o.shop_cd == site.shop_cd && o.shop_id == site.shop_id));

          if (siteIndex < 0) {
            site_list.push({ shop_cd: site.shop_cd, pa_shop_cd: site.pa_shop_cd, shop_id: site.shop_id, shop_name: site.shop_name });
          } else {
            site_list.push($scope.gift.site_list[siteIndex]);
          }
        });

        $scope.gift.site_list = site_list;
        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
        !$scope.isTimeSelectEnabled() && $scope.resetTime();
      });
    }

    /**
     * 사은품 상세 불러오기
     */
    async function getDetail(gift_no) {
      try {
        loading(true);

        // 초기화
        $scope.gift = angular.copy(giftSVC.gift_new);
        $scope.resetGiftProd();

        const { data: { result } } = await giftModel.detail({ gift_no });

        Object.assign($scope.gift, result);
        $timeout(() => {});
        if (!(result.stime === '00:00:00' && result.etime === '23:59:59')) {
          $scope.gift.specify_time_yn = 1;
        } else {
          $scope.gift.stime = null;
          $scope.gift.etime = null;
        }

        if ($scope.mode == 'add') {
          $scope.gift.gift_rule_name = '';
        } else {
          old = angular.copy($scope.gift);
        }

        // 로딩박스 해제처리가 rule_type,add_rule_type 변경이벤트에 걸려있어서 해당조건이 아닐경우엔 여기서 로딩박스 해제
        if ($scope.gift.rule_type == 'all' && $scope.gift.add_rule_type != 'shop_sale') {
          loading(false);
        }
      } catch (err) {
        commonSVC.showMessage('', '데이터 로딩 실패');
      }
    }

    // 신규추가일때는 로딩중 박스 숨기기
    $scope.$watch('$viewContentLoaded', function() {
      if ($scope.mode == 'add') {
        loading(false);
      } else {
        loading(true);
      }
    });

    // 상품 선택 기준이 SKU코드, 쇼핑몰 상품코드인 경우 "제외" 선택 시 체크박스 비활성화 처리 및 rule로 값 변경처리
    $scope.checkException = () => {
      if ($scope.gift.add_rule_apply_type === 'except') {
        $scope.gift.cal_amt_type = 'rule';
      }
    };

    /**
     * init
     */
    async function init() {
      if (data.gift_no) {
        // 수정
        await getDetail(data.gift_no);
      } else {
        // 추가
        $scope.gift = angular.copy(giftSVC.gift_new);
        $scope.resetGiftProd();
      }
    }

    init();
  });