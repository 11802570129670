'use strict';

angular.module('gmpApp')
  .controller('StockAllocationChannelCtrl', function ($scope, data, $uibModalInstance, commonSVC, inventoryModel) {
    $scope.re_alloc_yn = data.alloc_type === 're_alloc'; // 재고재할당 여부
    $scope.alloc_type = data.alloc_type;
    $scope.modal_title = data.alloc_type === 're_alloc' ? '재고 재할당' : data.alloc_type === 'prod_shop_rate' ? 'SKU상품 별 재고할당' : '채널 별 재고할당';

    $scope.prodList = []; // SKU상품 리스트
    $scope.mall_list = []; // 채널별 재고할당 리스트

    $scope.stock_cnt = data.stock_cnt; // 추가된 재고 object ({'sku코드': 수량})

    $scope.selectedProd = {
      prod_no: null,
      sku_cd: '',
      prod_name: '',
      stock: 0,
    }; // 선택된 SKU상품
    $scope.alloc_sum = 0; // 할당 수량 합계

    $scope.new_stock = !$scope.re_alloc_yn ? data.new_stock || 0 : 0;

    // SKU상품 실재고 변동수량
    const updated_stock = data.new_stock;
    const fromExcel = data.from === 'excel_update_stock';
    const fromStockInout = ['stock_transfer', 'stock_cnt_real'].includes(data.from);

    const prod_list = data.prod_list && data.prod_list.map(p => [ +p.prod_no, +p.depot_no ]);

    // 데이터 조회를 위한 params 세팅
    const params = {
      is_all: data.is_all,
      alloc_type: $scope.alloc_type,
      jobId: data.jobId,
      ...(data.prod_list && { prod_list })
    };

    inventoryModel.stockAllocGetRules(params, function(state, data) {
      $scope.prodList = angular.copy(_.uniqBy(data.results.mall_list, o => `${o.prod_no}${o.depot_no}`));
      $scope.mall_list = data.results.mall_list;

      if (fromExcel) {
        $scope.stock_cnt = data.results.stock_cnt;
      }

      // 재고할당방법(일반rate/우선순위priority) + (재고재할당도 rate)
      let alloc_method = $scope.re_alloc_yn ? 'rate' : $scope.alloc_type.split('_')[1];
      const stock = $scope.re_alloc_yn ? $scope.mall_list[0].stock_cnt : (fromExcel || fromStockInout ? $scope.stock_cnt[`${$scope.prodList[0].prod_no}_${$scope.prodList[0].depot_no}`] : $scope.new_stock[`${$scope.prodList[0].prod_no}_${$scope.prodList[0].depot_no}`]); // 할당될 총 재고

      // ==================================재고할당 적용==================================
      _.forEach($scope.prodList, function(prod) {
        // 조회해온 채널리스트 중 각 prod에 속하는 것만 추출
        let prodShopList = _.filter($scope.mall_list, ['prod_no', prod.prod_no]);

        prod.alloc_type = prod.alloc_type === 'normal' ? 'rate' : 'priority';

        _.forEach($scope.mall_list, function(mall) {
          // opt_name1,2,3 합침
          mall.opt_name = mall.opt_name1 || '';
          mall.opt_name += mall.opt_name2 ? `,${mall.opt_name2}` : '';
          mall.opt_name += mall.opt_name3 ? `,${mall.opt_name3}` : '';

          // 재고 입/출고수량
          mall.new_stock = fromExcel || fromStockInout ? $scope.stock_cnt[`${prod.prod_no}_${prod.depot_no}`] : $scope.new_stock[`${prod.prod_no}_${prod.depot_no}`];

          mall.alloc_type = prod.alloc_type;

          // SKU상품 실재고 원본 저장
          mall.ori_real_stock = mall.real_stock * 1 - (updated_stock ? updated_stock[`${prod.prod_no}_${prod.depot_no}`] : mall.new_stock) * 1;
        });

        // 엑셀일괄재고수정일 경우 각 SKU상품에 재고입고수량 추가
        if (fromExcel || fromStockInout) {
          prod.new_stock = $scope.stock_cnt[`${prod.prod_no}_${prod.depot_no}`];
        } else if ($scope.new_stock) {
          prod.new_stock = $scope.new_stock[`${prod.prod_no}_${prod.depot_no}`];
        }

        // SKU상품별 할당일 경우 각 SKU상품에 저장된 할당방법을 사용
        if ($scope.alloc_type === 'prod_shop_rate') {
          alloc_method = prod.alloc_type;
        }

        /* ------------------일반 할당------------------ */
        if (alloc_method === 'rate') {
          _.forEach(prodShopList, function(shop) {
            const add_stock = $scope.re_alloc_yn ? prod.stock_cnt : ($scope.new_stock[`${$scope.prodList[0].prod_no}_${$scope.prodList[0].depot_no}`] || prod.new_stock);
            const alloc_cnt = Math.round(Math.abs(add_stock * (shop.alloc_rate / 100))) * (add_stock < 0 ? -1 : 1); // 할당될 재고수량
            const sale_limit = shop.opt_type === '일반' ? shop.sale_cnt_limit : shop.sale_opt_cnt_limit;

            // 재고 "재"할당일경우, 옵션판매수량이 할당수량으로 대치됨
            if ($scope.re_alloc_yn) {
              shop.alloc_cnt = alloc_cnt > -1 ? alloc_cnt : 0;
            } else {
              shop.alloc_cnt = shop.alloc_cnt || 0;
              // 판매수량보다 차감수량이 많은경우는 판매수량만큼만 차감
              shop.alloc_cnt = (sale_limit + alloc_cnt) < 0 ? sale_limit * -1 : alloc_cnt;
            }
          });
        }
        /* ------------------ 우선순위 할당 ------------------ */
        else if (alloc_method === 'priority') {
          let add_stock = $scope.new_stock[`${$scope.prodList[0].prod_no}_${$scope.prodList[0].depot_no}`] || prod.new_stock; // 추가된 재고
          const isMinus = add_stock < 0; // 입고수량이 (-) 인지

          prodShopList = _.sortBy(prodShopList, ['priority']);

          _.forEach(prodShopList, function(shop) {
            let alloc_cnt = Math.round(Math.abs(add_stock * ((shop.alloc_rate || 0) / 100))) * (add_stock < 0 ? -1 : 1); // 할당될 재고수량

            shop.alloc_cnt = shop.alloc_cnt || 0;

            // 계산한 수량을 할당수량에 더함 & 입고된 수량에서 빼는처리
            if ((add_stock - alloc_cnt < 0 && !isMinus) || (add_stock - alloc_cnt > 0 && isMinus)) {
              // 추가된재고가 할당될 재고수량보다 작을 경우 추가된재고만큼만 더함
              shop.alloc_cnt += add_stock;
              add_stock = 0;
            } else {
              const sale_limit = shop.opt_type === '일반' ? shop.sale_cnt_limit : shop.sale_opt_cnt_limit;

              // 판매수량보다 차감수량이 많은경우는 판매수량 0이 되는 수량까지만 차감
              alloc_cnt = (sale_limit + shop.alloc_cnt + alloc_cnt) < 0 ?
                0 - sale_limit - shop.alloc_cnt : alloc_cnt;
              shop.alloc_cnt += alloc_cnt;
              add_stock -= alloc_cnt;
            }
          });
        }

        $scope.prodClick($scope.prodList[0].prod_no, $scope.prodList[0].depot_no, $scope.prodList[0].sku_cd, $scope.prodList[0].prod_name, stock);
      });
    });

    /**
     * SKU상품 클릭
     */
    $scope.prodClick = function (prod_no, depot_no, sku_cd, prod_name, stock) {
      $scope.selectedProd.prod_no = prod_no;
      $scope.selectedProd.depot_no = depot_no;
      $scope.selectedProd.sku_cd = sku_cd;
      $scope.selectedProd.prod_name = prod_name;
      $scope.selectedProd.stock = stock;
      $scope.calcSum(prod_no, depot_no);
    };

    /**
     * 할당수량 합계
     */
    $scope.calcSum = function (prod_no, depot_no) {
      $scope.alloc_sum = $scope.mall_list.filter(o => o.prod_no === prod_no && o.depot_no === depot_no && o.alloc_cnt).reduce((acc, cur) => acc + +cur.alloc_cnt, 0);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 재고할당 적용
     */
    $scope.ok = function () {
      // 판매수량 변동이 있는 쇼핑몰만 보내서 수정
      const params = {
        mall_list: $scope.mall_list.filter((mall) => {
          return $scope.re_alloc_yn ?
            ((mall.opt_type === '일반' ? mall.sale_cnt_limit : mall.sale_opt_cnt_limit) !== parseInt(mall.alloc_cnt))
            : (parseInt(mall.alloc_cnt) !== 0);
        }),
        re_alloc_yn: $scope.re_alloc_yn,
        alloc_type: $scope.alloc_type
      };

      if (!params.mall_list.length) {
        commonSVC.showMessage('', `유효한 ${data.re_alloc_yn ? '재고재할당' : '재고할당'} 수량이 존재하지 않습니다.`);

        return false;
      }

      return inventoryModel.stockAllocApply(params, function (state) {
        if (state === 'success') {
          $uibModalInstance.close('success');
          commonSVC.showToaster('success', '성공', '재고할당 성공');
        } else {
          commonSVC.showToaster('error', '실패', '재고할당 실패');
        }
      });
    };
  });
