'use strict';

angular.module('gmpApp')
  .directive('olTemplateSearch', function ($timeout, commonSVC, onlineProdSVC, categoryModel) {
    return {
      templateUrl: 'views/online/product/channel/template.html',
      link: function ($scope) {
        function setEtcFiles(data) {
          let conti = true;
          let key, i;

          $scope.row.etc_files = [[], []];
          for (i = 20; i > 1; i--) {
            key = `key_safeInfoImg8_${i}_filename`;

            if (data.set_info[key] == undefined) {
              continue;
            }
            conti = false;
            if (data.set_info[key]) {
              $scope.row.etc_files[0].unshift({ files: { name: data.set_info[key] } });
            } else {
              $scope.row.etc_files[0].unshift({});
            }
          }
          if (data.set_info.key_safeInfoImg8_file) {
            $scope.row.etc_files[0].unshift({ files: { name: data.set_info.key_safeInfoImg8_filename } });
          } else {
            $scope.row.etc_files[0].unshift({});
          }

          //수입신고필증이 있는 경우 세팅
          conti = true;
          for (i = 20; i > 1; i--) {
            key = `key_safeInfoImg4_${i}_filename`;

            if (data.set_info[key] == undefined && conti) {
              continue;
            }
            conti = false;
            if (data.set_info[key]) {
              $scope.row.etc_files[1].unshift({ files: { name: data.set_info[key] } });
            } else {
              $scope.row.etc_files[1].unshift({});
            }
          }
          if (data.set_info.key_safeInfoImg4_file) {
            $scope.row.etc_files[1].unshift({ files: { name: data.set_info.key_safeInfoImg4_filename } });
          } else {
            $scope.row.etc_files[1].unshift({});
          }
        }

        /**
         * 템플릿 선택
         */
        $scope.templateNameAdd = function (index, data) {
          $scope.summary = {};

          //세트폼 form 데이터에 site_id는 seller_id playapi에서 처리해주면 완료
          const template_data = JSON.parse(data.template_data);
          const json_data = template_data.data || template_data;

          // 등록 쇼핑몰을 중간에 편집할경우 인덱스가 맞지않아 에러가 발생되므로 아래와 같이 처리함 2018-03-15 rony
          _.forEach($scope.data.site_list, function(v, k) {
            if ($scope.shop_params == undefined) {
              $scope.data.site_list[k].site_code = data.site_code;
              $scope.data.site_list[k].site_id = data.site_id;
              $scope.data.site_list[k].template_no = data.template_no;
              $scope.data.site_list[k].template_summary = true;  //템플릿 요약정보 출력
              $scope.template_summary = true;

              //템플릿 요약정보 테이블 값 세팅
              $scope.data.site_list[k].summary_data = {};
              $scope.data.site_list[k].summary_data.selTerms = json_data.selTerms;
              $scope.data.site_list[k].summary_data.deliv_fee_type = json_data.deliv_fee_type;
              $scope.data.site_list[k].summary_data.ProdType = json_data.ProdType;
              $scope.data.site_list[k].template_name = data.template_name;
              $scope.data.site_list[k].template_no = data.template_no;

            } else {
              if ($scope.shop_params.shop_cd == $scope.data.site_list[k].shop_cd && $scope.shop_params.shop_id == $scope.data.site_list[k].shop_id) {
                $scope.data.site_list[k].site_code = data.site_code;
                $scope.data.site_list[k].site_id = data.site_id;
                $scope.data.site_list[k].template_no = data.template_no;
                $scope.data.site_list[k].template_summary = true;  //템플릿 요약정보 출력
                $scope.template_summary = true;

                //템플릿 요약정보 테이블 값 세팅
                $scope.data.site_list[k].summary_data = {};
                $scope.data.site_list[k].summary_data.selTerms = json_data.selTerms;
                $scope.data.site_list[k].summary_data.deliv_fee_type = json_data.deliv_fee_type;
                $scope.data.site_list[k].summary_data.ProdType = json_data.ProdType;
                $scope.data.site_list[k].template_name = data.template_name;
                $scope.data.site_list[k].template_no = data.template_no;
              }
            }
          });

          //템플릿 리스트 닫기
          $scope.listModal('false');

          // 유효성 체크 2018-03-13 rony
          $scope.reCheckClass.valid('recheck');
        };

        // 임시저장 시에는 쇼핑몰정보, 옵션정보 초기화하지 않음
        if ($scope.shop_data.pa_shop_cd == 'B378') {
          if ($scope.loadTemporary) {
            $timeout(function() {
              setEtcFiles($scope.shop_data);
            });
          } else {
            //default setting
            $scope.row.etc_files = [[], []];
            $scope.prodOpt = $scope.prodOpt ? $scope.prodOpt : {};
            $scope.prodOpt.opt = $scope.prodOpt.opt ? $scope.prodOpt.opt : angular.copy(onlineProdSVC.B378.default_opt);

            $scope.$watchCollection('data.coupang_cate', () => {
              $timeout(async () => {
                await cpangInit();
              });
            });
          }
        }

        // init();
        const cpangInit = async () => {
          //수정이 아닐때 제품명 온라인상품명으로 기본값 설정
          if (!$scope.row.set_info.name_short_type && !$scope.data.isEdit) {
            $scope.row.set_info.name_short_type = 'ol_name';
          }

          // 수정모달인 경우 기존에 제품명 입력해 놓은 값이 있으면 직접입력으로 보여줌
          if ($scope.data.isEdit && $scope.row.set_info.name_short && !$scope.row.set_info.name_short_type) {
            $scope.row.set_info.name_short_type = 'etc';
          }

          $scope.row.etc_files = [[], []];

          // 2018-06-08 lucas 카테고리 매칭이 안되어있을 경우 더이상 진행하지 않음
          let match_cate_code;

          // 솔루션카테고리에 매칭된 쇼핑몰 카테고리
          if ($scope.row.match_cate_code) {
            match_cate_code = $scope.row.match_cate_code.split('_');
          }
          // 온라인상품에 매칭된 쇼핑몰 카테고리
          if ($scope.data.shop_cate_cd) {
            match_cate_code = $scope.data.shop_cate_cd.split('_');
          }
          //매칭된 카ㅔ고리가 없을경우 리턴
          if (!match_cate_code) {
            return;
          }

          //공백제거
          match_cate_code = _.filter(match_cate_code, function(row) {
            return row != '';
          });
          const shop_cate_see_cd = match_cate_code[match_cate_code.length - 1];

          try {
            // getScrapCategoryOption 도 있는데, 해당 함수는 2개이상 카테고리를 지원할 때 사용하도록 만들어서
            // 디테일로 해당 카테고리 etc에서 확인하는 방식으로 처리함
            const res = await categoryModel.getScrapCategoryDetail({
              shop_cd: 'B378',
              shop_cate_see_cd,
              std_ol_yn: 0
            });

            if (res.data.result?.etc) {
              if (res.data.result?.etc?.attributes) {
                $scope.data.cpang_options = res.data.result.etc.attributes.filter(({ exposed }) => ['EXPOSED', 'MANDATORY'].includes(exposed));
                // 검색옵션 정리
                $scope.row.attributes = res.data.result.etc.attributes.filter(row => (
                  row.required == 'OPTIONAL' && row.exposed == 'NONE'
                ));
              }

              $scope.data.is_possible_growth_cate = res.data.result.etc.is_possible_fulfillment;
              $scope.data.isExpirationDateRequiredForRocketGrowth = res.data.result.etc.isExpirationDateRequiredForRocketGrowth;
            }
          } catch (err) {
            commonSVC.showMessage('카테고리 메타 정보 로딩 실패');
          }

          //상품 수정인 경우 업로드 되있는 기타인증서류 정보 셋팅
          if ($scope.data.set_info) {
            setEtcFiles($scope.data);
          }
          //상품 등록의 경우 필요한 초기화 작업
          else {
            $scope.row.etc_files = [[{}], [{}]];
            // 추가항목 기본값 적용하는데 해당 필드를 {} 로 하면 안되서 일단 주석처리
            // key_siteOptObject 필드를 {} 로 한 이유가 로딩이 느려져서 그렇다는데 일단 주석 해보고 정 안된다 하면 주석 풀어야함
            // $scope.row.set_info.key_siteOptObject = {};
            $scope.row.set_info.opt_type = 'online';
          }
        };

        /**
         * 템플릿 검색 필터
         */
        $scope.filterFn = (row) => {
          if (!$scope.row.search) {
            return true;
          } else {
            return row.template_name.includes($scope.row.search) || row.template_no.toString().includes($scope.row.search);
          }
        };
      }
    };
  });
