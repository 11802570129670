'use strict';

angular.module('gmpApp')
  .controller('AltalkLinkCtrl', function(
    // common
    $state, $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog, $q, userInfo, $uibModalInstance, $http,
    // info
    settings,
    // SVC
    commonSVC,
    // model
    atalkModel
  ) {
    async function init() {
      // 알림톡 발신프로필 등록 시 필요한 사업자 카테고리 조회
      const result = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/category`);

      // 알림톡 발신 프로필 리스트 조회
      const accountList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
      $scope.accountList = accountList.data.result;  // 발신프로필 조회 데이터
      $scope.firstDepth = result.data.result?.root;  // 사업자 카테고리 1 depth
      $scope.secondCate = result.data.result?.node;  // 사업자 카테고리 2 depth
      $scope.thirdCate = result.data.result?.leaf;   // 사업자 카테고리 3 depth

      // 인증번호 받기 활성화 여부
      $scope.authCheck = false;
      // 연동하기 활성화 여부
      $scope.linkCheck = false;

      $timeout(() => {});
    }

    init();

    let timer;   // 알림톡 발신프로필 등록 시 인증 시간위해 추가
    $scope.tabActive = 0;  // tab (0: 연동, 1: 프로필 리스트)
    $scope.msg = {
      htel: '',
      id: '',
      token: '',
      expired: '',
      cate: ''
    };       // 필수값 누락 시 메시지 표시를 위해 선언
    $scope.account = {};
    $scope.firstCateVal = '';    // 사업자 카테고리 1 depth 선택 데이터
    $scope.secondCateVal = '';   // 사업자 카테고리 2 depth 선택 데이터
    $scope.thirdCateVal = '';    // 사업자 카테고리 3 depth 선택 데이터
    $scope.htelAuthData = { expiredFlag: false }; // 인증번호 데이터

    /**
     * 인증번호 받기, 연동하기 유효성 함수
     */
    $scope.checkAuthFunction = (type) => {
      updateValues(type);
      updateChecks();
    };

    /**
     * 카테고리 선택 함수 (유효성 체크도 함께)
     */
    $scope.cateSelect = (depth) => {
      updateCateValues(depth);
      updateChecks();
      $timeout(() => {});
    };

    /**
     * 인증번호 받기, 연동하기 유효성 처리 함수
     */
    function updateChecks() {
      if ($scope.account.htel && $scope.account.htel.length > 2 && $scope.account.token?.length === 6) {
        $scope.linkCheck = true;

        if ($scope.account.cate && $scope.account.id) {
          $scope.linkCheck = true;
        } else {
          $scope.linkCheck = false;
        }
      } else if ($scope.account.htel && $scope.account.htel.length > 2) {
        $scope.authCheck = true;
        $scope.linkCheck = false;
      }
    }

    function updateValues(type) {
      $scope.msg[type] = '';
    }

    /**
     * 발신 프로필 카테고리 선택
     * @param {*} depth
     */
    function updateCateValues(depth) {
      if (depth === '1' && $scope.firstCateVal) {
        $scope.secondDepth = $scope.secondCate.filter(cate => cate.root_id === JSON.parse($scope.firstCateVal).id);
        $scope.thirdDepth = '';
      } else if (depth === '2' && $scope.secondCateVal) {
        $scope.thirdDepth = $scope.thirdCate.filter(cate => cate.node_id === JSON.parse($scope.secondCateVal).id);
      } else if (depth === '3' && $scope.thirdCateVal) {
        $scope.account.cate = JSON.parse($scope.thirdCateVal).code;
        $scope.msg.cate = '';
      }
    }

    /**
     * 발신 프로필 연동 인증하기 함수
     */
    $scope.sendKakaoAuth = async () => {
      $scope.msg.err = '';
      $scope.msg.expired = '';

      if (!$scope.account.id) {
        $scope.msg.id = '검색용 아이디를 입력해주세요.';
      }
      if (!$scope.account.cate) {
        $scope.msg.cate = '사업자 카테고리를 선택해주세요.';
      }
      if (!$scope.account.htel) {
        $scope.msg.htel = '관리자 휴대폰 번호를 입력해주세요.';
      } else if ($scope.account.htel.length !== 11) {
        $scope.msg.htel = '11자의 관리자 휴대폰 번호를 입력해주세요';
      }
      if ($scope.accountList.find((a) => a.name === $scope.account.id)) {
        $scope.msg.id = '이미 연동된 플러스친구 ID입니다.';
      }

      if ($scope.msg.id.length || $scope.msg.cate.length || $scope.msg.htel.length) {
        return false;
      }

      try {
        const url = `${settings.adminApiUrl}/api/marketing/atalk/sendKakaoAuth`;
        const result = await commonSVC.sendUrl('POST', url, { id: $scope.account.id, htel: $scope.account.htel });
        if (result.status === 200) {
          const resData = result.data.result;
          const SECOND = 1000;

          $timeout(() => {
            if (resData === 'success') {
              $scope.tokenSendFlag = true;
              $scope.htelAuthData.expiredFlag = false;

              $scope.htelAuthData.limitTime = SECOND * 3 * 60;
              $scope.htelAuthData.limitString = moment($scope.htelAuthData.limitTime).format('mm:ss');

              clearInterval(timer);

              timer = setInterval(() => {
                $scope.htelAuthData.limitTime = $scope.htelAuthData.limitTime - SECOND;
                $scope.htelAuthData.limitString = moment($scope.htelAuthData.limitTime).format('mm:ss');

                if (!$scope.htelAuthData.limitTime) {
                  clearInterval(timer);

                  $scope.msg.expired = '인증 유효시간이 초과되었습니다. 인증번호를 재요청하시기 바랍니다.';
                  $scope.htelAuthData.expiredFlag = true;
                }

                $scope.$apply();
              }, 1000);
            } else if (resData.includes('존재하지 않는 카카오톡')) {
              $scope.msg.id = '정확한 채널 이름을 입력해주세요';
            } else if (resData.includes('카카오톡 채널 관리자 알림')) {
              $scope.msg.htel = '관리자 센터에 등록된 정보와 일치하지 않습니다. 다시 확인해 주세요.';
            } else {
              if (+resData === 3018) {
                return commonSVC.showMessage('연동 실패', '카카오톡 미사용, 알림톡 차단, 카카오 버전 7.3.0 버전 이하\n사용자에 해당되는지 확인해 주세요.');
              }

              return commonSVC.showMessage('연동 실패', `오류코드 ${resData}로 연동에 실패했습니다.\n담당자에게 문의주시기 바랍니다.`);
            }
          }, 500);
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '인증번호 요청에 실패했습니다. 계속 발생시 관리자에게 문의해주세요.');
      }
    };

    /**
     * 알림톡 플러스친구 ID 연동
     */
    $scope.link = async () => {
      const url = `${settings.adminApiUrl}/api/marketing/atalk/addAccount`;
      const params = {
        id: $scope.account.id,
        cate: $scope.account.cate,
        htel: $scope.account.htel,
        token: $scope.account.token,
        sol_no: $rootScope.user_profile.sol_no
      };
      if (!$scope.account.id || $scope.account.id == '') {
        $scope.msg.id = '검색용 아이디를 입력해주세요.';
      }
      if (!$scope.account.cate || $scope.account.cate == '') {
        $scope.msg.cate = '사업자 카테고리를 선택해주세요.';
      }
      if (!$scope.account.htel || $scope.account.htel == '') {
        $scope.msg.htel = '관리자 휴대폰 번호를 입력해주세요.';
      } else if ($scope.account.htel.length !== 11) {
        $scope.msg.htel = '11자의 관리자 휴대폰 번호를 입력해주세요.';
      }
      if (!$scope.account.token || $scope.account.token == '') {
        $scope.msg.token = '인증번호 받기 버튼을 클릭하여 관리자 휴대폰 번호를 인증해주세요.';
      } else if ($scope.account.token.length !== 6) {
        $scope.msg.token = '인증코드 6자를 입력해주세요.';
      }

      if ($scope.msg.id.length || $scope.msg.cate.length || $scope.msg.htel.length || $scope.msg.token.length) {
        return false;
      }

      try {
        const result = await commonSVC.sendUrl('POST', url, params);
        if (result.status === 200) {
          const resMsg = result.data.result;

          $timeout(() => {
            if (resMsg === 'success') {
              commonSVC.showConfirmCustom({
                title: '플러스 친구 ID 연동 완료',
                text: '',
                html: false,
                showCancelButton: false,
                confirmButtonText: '확인'
              }, (isConfirm) => {
                if (isConfirm) {
                  location.reload();
                  $uibModalInstance.close('success');
                }
              });

            } else if (resMsg === '유효하지 않은 인증번호 입니다.') {
              $scope.msg.token = '인증번호가 틀립니다. 다시 입력해주세요.';
            } else if (resMsg.includes('카카오톡 채널 관리자 알림 설정')) {
              $scope.msg.id = '검색용 아이디가 관리자 센터에 등록된 정보와 일치 하지 않습니다. 다시 확인해 주세요.';
            } else {
              if (resMsg === '비즈니스 채널 전환이 필요한 프로필입니다.') {
                return commonSVC.showMessage('연동 실패', '카카오 비즈센터에서 비즈니스 채널 전환되어 비즈니스 채널 표시 (V)가\n되어 있는 채널만 연동이 가능합니다.');
              } else {
                return commonSVC.showMessage('연동 실패', resMsg);
              }
            }
          });
        }

      } catch (err) {
        if (err.data.error.includes('Duplicate entry')) {
          $scope.msg.id = '이미 연동된 플러스친구 ID입니다.';
        } else {
          return commonSVC.showMessage('연동 실패', err.data?.error);
        }
      }
    };

    $scope.deleteAccount = (row) => {
      commonSVC.showConfirmHtml(`${row.name} 연동 해제하시겠습니까?`, '해제 시 해당 ID로 등록된 <span style="color: rgb(5,65,153);">템플릿, 자동 발송 등록, 알림톡 발송 예약 건</span> 사용은 불가합니다.', async (isConfirm) => {
        if (isConfirm) {
          try {

            await atalkModel.deleteAccount({ sol_no: $rootScope.user_profile.sol_no, key: row.profile_key, id: row.id });
            const accountList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
            $scope.accountList = accountList.data.result;
            location.reload();
            $timeout(() => {});
          } catch (err) {
            commonSVC.showToaster('error', '실패', err.data.error);
          }
        }
      }
      );
    };

    $scope.cancel = () => {
      $uibModalInstance.close();
    };

    $scope.clickTab = (num) => {
      $scope.tabActive = num;
    };

    $scope.searchData = {};
    $scope.grid = {}; // 플러스친구ID 관련 변수
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: true,
      pinningColumns: [],
      defaultSortingColumns: [],
      alignCenterColumns: [],
      alignRightColumns: [],
      notSortingColumns: [],
      notVisibleColumns: [],
      notMovingColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },

      externalRequestOptions: {
        requestUrl: `${settings.adminApiUrl}/api/marketing/atalk/accountList`,
        requestWillAction: function () {
          const r = angular.merge($scope.searchData, { name: $rootScope.user_profile.sol_no });

          return r;
        },
        requestDidAction: function (result) {

          return result.result;
        }
      },
      columns: [
        {
          key: 'name',
          title: '플러스친구 ID',
          width: 100
        },
        {
          key: 'cate',
          title: '사업자 카테고리',
          width: 250
        },
        {
          key: 'htel',
          title: '관리자 휴대폰 번호',
          width: 150
        },
        {
          key: 'created_at',
          title: '연동일',
          width: 150
        },
        {
          key: 'unlink',
          title: '연동해제',
          width: 100
        }
      ]
    };
  });