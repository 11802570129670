'use strict';

angular.module('gmpApp')
  .controller('ScrapSuperProdCtrl', function (
    $scope, $rootScope, $filter, $uibModalInstance, $timeout,
    settings,
    commonSVC,
    supplierModel, warehouseModel, productModel, systemModel) {
    $scope.duflicate = false;

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        sdate: moment(new Date('2001-01-01')).format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        status: '',
        status_sub: '',
        page: 'order'
      },
      searchData: {
        all_checked: false,                                                         // 전체선택 여부
        selectCount: 0,                                                             // 검색전체 건수
        totalCount: 0,                                                              // 검색전체 건수
        showDetailSearchArea: false,
        searchAreaClass: 'col-xs-12',
        searchNoMargin: true,
        search_key_items: [                                                         // 검색영역 키값
          { label: '전체', value: 'all' },
          { label: 'SKU코드', value: 'sku_cd' },
          { label: 'SKU상품명', value: 'prod_name' },
          { label: '모델번호', value: 'model_no' },
        ]
      },
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchDetail = angular.copy(search.searchDetail);
    $scope.searchBtn = angular.copy(search.searchBtn);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'unavail_out_cnt', 'model', 'brand', 'sku_cd'],
      alignRightColumns: ['sale_price', 'real_stock', 'stock_cnt', 'stock_cnt_safe', 'madein', 'cost_price', 'supply_price', 'supp_name'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'real_stock', 'stock_cnt_safe'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      modal: true,
      gridHeight: 460,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/super-list`,
        requestWillAction: (data) => {
          data = angular.merge({}, data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          data.sol_no = $rootScope.user_profile.pa_sol_no;

          return data;
        },
        requestDidAction: (result) => {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        //도구
        {
          title: '도구',
          key: 'widget',
          width: 100,
          template: (row) => {
            // row.uniq 수정필요
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(true,'${row.sku_cd}','${row.prod_no}')" >상세</button>`;
          }
        },
        // 등록일
        {
          title: '등록일',
          key: 'wdate',
          width: 130,
          filter: 'dateValid',
        },
        //SKU_CODE
        {
          title: 'SKU코드',
          key: 'sku_cd',
          width: 120,
          notCompile: true,
          template: (row) => {
            return `<span class="text-primary" id=${row.prod_no}>${row.sku_cd}</span>`;
          }
        },
        //이미지+상품명
        {
          title: 'SKU상품명',
          key: 'prod_name',
          width: 330,
          notCompile: true,
          template: (row) => {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        //속성
        {
          title: '속성',
          key: 'attri',
          width: 160
        },
        //상태
        {
          title: '상태',
          key: 'stock_status',
          width: 100,
          notCompile: true,
          template: (row) => {
            return row.stock_status;
          }
        },
        //실재고
        {
          title: '실재고',
          key: 'stock_cnt_real',
          width: 100,
          notCompile: true,
          template: (row) => {
            return row.stock_cnt_real < 0 ? 0 : row.stock_cnt_real;
          }
        },
        //안전재고
        {
          title: '안전재고',
          key: 'stock_cnt_safe',
          width: 100,
          notCompile: true,
          template: (row) => {
            return parseInt(row.stock_cnt_safe) || 0;
          }
        },

        // 판매가
        {
          title: '판매가',
          key: 'sale_price',
          width: 100,
          notCompile: true,
          template: (row) => {
            return $filter('currency')(row.sale_price, '', 0);
          }
        },
        {
          title: '모델번호',
          key: 'model_no',
          width: 100
        },
        // //브랜드
        // {
        //   title: '브랜드',
        //   key: 'brand',
        //   width: 100
        // },
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = (refresh, noDelay) => {
      $scope.grid.methods.reloadData(null, refresh, noDelay, true);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = () => {
      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = (count) => {
      $scope.searchData.showCount = count;
      $scope.grid.methods.length(count);
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: () => {
        $scope.searchDo(true, true);
      },
      resetDo: () => {
        $scope.resetDo();
      },
      changeLen: (count) => {
        $scope.changeCount(count);
      }
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(show, sku_cd, prod_number) {
      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          sol_no: $rootScope.user_profile.pa_sol_no,
          detailType: 'edit',
          nonEdit: true
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.pa_sol_no });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.pa_sol_no });
      };

      resolve.madeinList = function() {
        return productModel.getMadein();
      };

      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {

        //리스트 유지한채 데이터 업데이트
        $scope.grid.methods.reloadData(function() {}, false);

      });
    };

    $scope.save = async () => {
      const select_prod = $scope.grid.methods.selectedData('all');

      if (!select_prod.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return;
      }

      const params = {
        select_prod,
        duplicate_save: !!$scope.duplicate_save,
      };

      try {
        const result = await productModel.scrapProd(params);
        const success_cnt = result.data.result.filter(item => item.state === 'success').length;

        commonSVC.showToaster(
          success_cnt > 0 ? 'success' : 'error',
          'SKU상품 가져오기',
          `전체 ${result.data.result.length}건 \n${success_cnt}건 성공 / ${ result.data.result.length - success_cnt}건 실패`
        );

        if (success_cnt > 0) {
          $uibModalInstance.close('success');
        } else {
          $timeout(function () { $scope.waitBtnDisableFlag = false; });
        }
      } catch (err) {
        commonSVC.showToaster(
          success_cnt > 0 ? 'success' : 'error',
          'SKU상품 가져오기',
          `전체 ${result.data.result.length}건 \n${success_cnt}건 성공 / ${ result.data.result.length - success_cnt}건 실패`
        );
      }

      // const resolve = {};
      // resolve.warehouseList = function() {
      //   return warehouseModel.ListAll({ use_yn: true });
      // };
      // const depot_modal = await commonSVC.openModal('md', resolve, 'ProdDepotSelectCtrl', 'views/prod/product/modals/select_depot.html');

      // depot_modal.result
      //   .then(async (depot_no) => {
      //     if (depot_no) {

      //       $scope.waitBtnDisableFlag = true;

      //     }
      //   });

      // $timeout(function () { $scope.waitBtnDisableFlag = false; });

    };

    $scope.close = () => {
      $uibModalInstance.dismiss();
    };
  });

// .controller('ProdDepotSelectCtrl', function ($scope, $uibModalInstance, commonSVC, warehouseList) {
//   $scope.depot_no = '';
//   $scope.warehouseList = warehouseList.data.result.filter(item => item.use_yn === 1);

//   $scope.save = () => {
//     if (!$scope.depot_no) {
//       commonSVC.showMessage('배송처를 선택하세요');

//       return;
//     }

//     $uibModalInstance.close($scope.depot_no);
//   };

//   $scope.close = ()=> {
//     $uibModalInstance.dismiss();
//   };
// });