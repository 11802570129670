'use strict';

angular.module('gmpApp')

  .controller('EtcLockSreenCtrl', function ($scope, settings, $rootScope, commonSVC, tokenSVC, localStorageService, userInfo, gettextCatalog, detectBrowser) {

    //새로고침시 로그아웃위해 토큰값 삭제
    localStorage.removeItem('ls.token');

    // 사용자의 화면잠금 버튼을 누름으로 인한 엑션은 모달 타이틀을 다르게 줌.
    if ($scope.lockSreenType == 'jwt_expired') {
      window.document.title = '로그인 정보가 만료되었습니다.';
    } else if ($scope.lockSreenType == 'manual') {
      window.document.title = '화면 잠그기';
    } else {
      window.document.title = '장시간 동안 사용하지 않아, 화면이 잠겼습니다.';
    }
    //
    // $scope.viewType = data.viewType;

    // $scope.modalTitle = data.viewType == "manual" ? "화면 잠그기" : "장시간 동안 사용하지 않아, 화면이 잠겼습니다.";

    //키보드 입력 방지
    $(document).off('keydown');

    $scope.error = '';

    $scope.ok = function () {

      if ($scope.exModalForm?.$valid) {
        const vdata = {
          lock_sreen_type: $scope.lockSreenType,
          sol_no: userInfo.user.sol_no,
          m_no: userInfo.user.user_id,
          email: $scope.lock_sreen_id,
          password: $scope.lock_sreen_pass,
          adminMode: userInfo.user.adminMode,
          ...detectBrowser
        };
        const url = `${settings.pa20ApiUrl}/app/home/lock`;

        commonSVC.sendUrl('POST', url, vdata)
          .then(function(res) {

            if (res.data != '' || undefined) {
              //다시 받아온 토큰값 추가
              localStorage.setItem('ls.token', `"Token ${res.data}"`);
              tokenSVC.setClientToken(localStorageService.get('token'));
              $('#lock_sreen_form').remove();
              // $uibModalInstance.close("success");

              // 문서제목 PLAYAUTO 로 다시 바꺼줌. 2018-03-20 rony
              window.document.title = 'PLAYAUTO';
              $rootScope.start();
            }
          })
          .catch(function() {
            $scope.error = gettextCatalog.getString('비밀번호가 틀렸습니다.');
          });
      }
    };

  });
