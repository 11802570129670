/**
 * 프로모션 코드 등록
 * 2019-06-17 rony
 * */

'use strict';

angular.module('gmpApp')
  .controller('addPromoCodeCtrl', function ($scope, commonSVC, $uibModalInstance, payModel) {

    $scope.promotion_code = '';

    /**
     * 프로모션 적용
     */
    $scope.setPromotion = async function () {
      try {
        if ($scope.promotion_code === '') {
          commonSVC.showMessage('프로모션 코드를 입력해주십시오.');

          return false;
        }

        // 프로모션 혜택 적용
        const { data } = await payModel.setPromotion({ promotion_code: $scope.promotion_code });

        commonSVC.showMessageHtml(
          '프로모션 코드 등록 성공!',
          `<h4 class="text-bold text-primary no-margin-bottom" style="display: inline-block;">${data.results.promotion_name}</h4>
           <h4 class="text-bold no-margin-bottom" style="display: inline-block; width: 48%;">쿠폰 <b class="text-primary">${data.results.benefit.length}</b> 건이 지급되었습니다.</h4>
           <ul class="text-box text-box-list pt-20">${data.results.benefit.map(o => `<li>${o}</li>`).join('')}</ul>`,
          function () {
            $uibModalInstance.close('success');
          },);
      } catch ({ data }) {
        commonSVC.showMessage('프로모션 코드 등록에 실패하였습니다.', data.error);
      }
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
