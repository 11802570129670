'use strict';

angular.module('gmpApp')
  .controller('OnlineProductProdInfoTemplateListCtrl', ($scope, settings, commonSVC, $uibModalInstance, onlineProductSVC) => {
    /**
     * 상품분류 정의
     * **/
    const types = onlineProductSVC.newTypes;

    $scope.type_array = types.map(value => ({ key: value.code, name: value.name }));

    $scope.searchData = {
      totalCount: 0,       // 검색전체 건수
    };
    $scope.searchForm = {
      search_key: '',
      search_word: '',
    };
    /**
     *  템플릿 그룹 데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      initPagingSize: 25,
      pinningColumns: ['widget'],
      alignCenterColumns: [
        'widget', 'prod_info_type', 'template_name'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['template_name'],
      notSortingColumns: ['widget', 'prod_info_type'],
      notResizingColumns: ['widget'],
      notMovingColumns: ['widget'],
      notVisibleColumns: [],
      emptyText: '저장된 상품정보제공고시 템플릿이 없어요.',
      selectOptions: {
        multiSelect: false,
        checkbox: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/prod-noti-template/list`,
        requestWillAction: function (data) {

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.templateList = result.results;
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          width: 100,
          title: '도구',
          template: (row) => {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(${row.template_no})">상세</button>
              <button
                class="btn btn-default btn-xxs"
                ng-click="grid.appScope.deleteTemplate(${row.template_no})">
                삭제
              </button>`;
          }
        },
        {
          key: 'prod_info_type',
          width: 180,
          title: '상품분류',
          template: row => row.template_data.infoName
        },
        {
          key: 'template_name',
          title: '템플릿명',
          width: 250
        },
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(function() { }, refresh, noDelay);
    };

    /**
     * 검색초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = {
        search_key: '',
        search_word: '',
      };
      $scope.searchDo(true, true);
    };

    /**
     * 테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 템플릿 상세 모달
     */
    $scope.showDetail = function (template_no) {
      const resolve = {
        data: {
          template: getTemplate(template_no),
        },
      };

      const modal = commonSVC.openModal('md', resolve, 'EditProdInfoTemplateCtrl', 'views/online/product/modals/edit_prod_info_template.html');

      modal.result.then(function() {
        $scope.searchDo();
      });
    };

    // 템플릿 삭제
    $scope.deleteTemplate = (template_no) => {
      commonSVC.showConfirm('상품정보제공고시 템플릿 삭제', '기존에 해당 템플릿으로 적용된 상품의 정보제공고시는 유지됩니다.', async (confirm) => {
        if (confirm) {
          try {
            await commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/online/prod-noti-template`, { template_no });

            $scope.searchDo();
            commonSVC.showToaster('success', '성공', '상품정보제공고시 템플릿을 삭제하였습니다.');
          } catch (err) {
            commonSVC.showToaster('error', '실패', '상품정보제공고시 템플릿 삭제 실패');
          }
        }
      });
    };

    // 선택된 템플릿으로 온라인 상품에 적용
    $scope.apply = function() {

      $uibModalInstance.close(getTemplate($scope.grid.methods.selectedData('template_no')[0]));
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    const getTemplate = (template_no) => {
      return $scope.templateList.find(template => template.template_no === template_no);
    };
  });