'use strict';

angular.module('gmpApp')
  .service('tokenSVC', function () {
    let clientToken = null;
    let wakeUpToken = null; // 웨이크업용 토큰

    return {
      setClientToken: token => {
        clientToken = token;
      },
      getClientToken: () => clientToken,

      setWakeUpToken: token => {
        wakeUpToken = token;
      },
      getWakeUpToken: () => wakeUpToken
    };
  });
