'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B668-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, commonModel, shopAccountModel, onlineProductModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.product_information_name = '';
    $scope.product_information_code = '';
    $scope.pa5_job_data = {};

    // 선택된 정보고시에 따라 노출
    if ($scope.data.prod_noti_data_new.length) {
      $scope.product_information_name = $scope.data.prod_noti_data_new[0].infoName;
      $scope.product_information_code = $scope.data.prod_noti_data_new[0].infoCode;
    }

    // 수입품여부(Y/N)
    $scope.is_imported_goods_list = [
      'Wear2023',
      'Shoes2023',
      'Bag2023',
      'FashionItems2023',
      'SleepingGear2023',
      'Furniture2023',
      'ImageAppliances2023',
      'HomeAppliances2023',
      'SeasonAppliances2023',
      'OfficeAppliances2023',
      'OpticsAppliances2023',
      'MicroElectronics2023',
      'CellPhone2023',
      'Navigation2023',
      'CarArticles2023',
      'MedicalAppliances2023',
      'KitchenUtensils2023',
      'Jewelry2023',
      'ProcessedFood2023',
      'HealthFunctionalFood2023',
      'Kids2023',
      'MusicalInstrument2023',
      'SportsEquipment2023',
      'Food2023',
    ];

    // 수입자 및 제조국
    $scope.imported_goods_list = [
      'Wear2023',
      'Shoes2023',
      'Bag2023',
      'FashionItems2023',
      'SleepingGear2023',
      'Furniture2023',
      'ImageAppliances2023',
      'HomeAppliances2023',
      'SeasonAppliances2023',
      'OfficeAppliances2023',
      'OpticsAppliances2023',
      'MicroElectronics2023',
      'CellPhone2023',
      'Navigation2023',
      'CarArticles2023',
      'MedicalAppliances2023',
      'KitchenUtensils2023',
      'Jewelry2023',
      'ProcessedFood2023',
      'HealthFunctionalFood2023',
      'Kids2023',
      'MusicalInstrument2023',
      'SportsEquipment2023',
      'Food2023',
    ];

    // 기능성여부(Y/N)
    $scope.is_functionality_list = ['Wear2023'];

    // 운동화 여부(Y/N)
    $scope.is_sneakers_list = ['Shoes2023'];

    // 충전재여부(Y/N)
    $scope.is_filling_list = ['SleepingGear2023'];

    // 충전재
    $scope.filling_list = ['SleepingGear2023'];

    // 복수구성품여부(Y/N)
    $scope.is_multiple_components_list = ['Furniture2023'];

    // 노트북여부(Y/N)
    $scope.is_laptop_list = ['OfficeAppliances2023'];

    // 의료기기여부(Y/N)
    $scope.is_medical_device_list = ['is_medical_device_list'];

    // 표시광고사전심의필유무(Y/N)
    $scope.is_advertisement_review_list = ['MedicalAppliances2023', 'ProcessedFood2023', 'HealthFunctionalFood2023'];

    // 인증유형
    $scope.kc_type_list = ['MedicalAppliances2023'];

    // 인증번호
    $scope.kc_code_list = ['MedicalAppliances2023'];

    // 전기용품여부(Y/N)
    $scope.is_electrical_appliances_list = ['MedicalAppliances2023'];

    // 유기농화장품여부(Y/N)
    $scope.is_organic_cosmetics_list = ['Cosmetic2023'];

    // 기능성 내용
    $scope.dunctional_content_list = ['Cosmetic2023'];

    // 원산지
    $scope.is_origin_list = ['ProcessedFood2023', 'HealthFunctionalFood2023'];

    // 가능성 여부(Y/N)
    $scope.is_possible_list = ['ProcessedFood2023', 'HealthFunctionalFood2023'];

    // 부작용발생가능성
    $scope.possibility_of_side_effects_list = ['ProcessedFood2023', 'HealthFunctionalFood2023'];

    // 제조연월일
    $scope.date_of_manufacture_list = ['HealthFunctionalFood2023'];

    // 구매일로부터사용기간
    $scope.eriod_of_use_list = ['GiftCard2023'];

    // 수량
    $scope.stock_list = ['Food2023'];

    // 크기
    $scope.size_list = ['Food2023'];

    // 지리적표시대상 여부(Y/N) - 쇠고기 이력관리 표시 유무
    $scope.geographic_or_beef_history_list = ['Food2023'];

    $scope.additional_items_list = [...new Set([
      $scope.is_imported_goods_list,
      $scope.imported_goods_list,
      $scope.is_functionality_list,
      $scope.is_sneakers_list,
      $scope.is_filling_list,
      $scope.filling_list,
      $scope.is_multiple_components_list,
      $scope.is_laptop_list,
      $scope.is_medical_device_list,
      $scope.is_advertisement_review_list,
      $scope.kc_type_list,
      $scope.kc_code_list,
      $scope.is_electrical_appliances_list,
      $scope.is_organic_cosmetics_list,
      $scope.dunctional_content_list,
      $scope.is_origin_list,
      $scope.is_possible_list,
      $scope.possibility_of_side_effects_list,
      $scope.date_of_manufacture_list,
      $scope.eriod_of_use_list,
    ].flat())];

    $scope.prodInfoDesc = (type) => {
      const flag = type + '_flag';
      if ($scope.row.set_info[flag] === 'ref') {
        $scope.row.set_info[type] = '[상세설명참조]';
      } else {
        $scope.row.set_info[type] = '';
      }
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
