/**
 * 자동결제정보 또는 환불정보 입력 모달.
 * */

'use strict';

angular.module('gmpApp')
  .controller('EditUserInfoModalCtrl', function ($scope, data, $uibModalInstance, adminModel, commonSVC, userModel) {
    $scope.initData = {};
    $scope.param = {
      files: null
    };

    const column = ['name', 'ceo_name', 'biz_reg_no', 'c_type', 'zipcd', 'addr1', 'uptae', 'upjong', 'biz_reg_url'];

    $scope.data = {};

    column.forEach(col => {
      // 데이터 넣기
      if (col === 'c_type' && (data.c_type === '개인사업자' || data.c_type === '개인판매자')) {
        data[col] = '';
      }

      if ((col === 'name' || col === 'ceo_name') && commonSVC.isEmailFormat(data[col])) {
        data[col] = '';
      }

      $scope.data[col] = data[col];
      // 비활성화 처리

      $scope.initData[col] = !!$scope.data[col];
    });

    $scope.isChkBizNo = $scope.initData.biz_reg_no; // 사업자번호 유효성 확인했는지 확인 필드

    // 데이터 전송
    $scope.onSubmit = async () => {
      if ($scope.editUserForm.$invalid) {
        return false;
      }

      // 회사명, 대표자명, 주소, 업태, 업종의 경우에는 이메일 형식 입력 불가
      let email_error_msg = '';
      if (commonSVC.isEmailFormat($scope.data.name)) {
        email_error_msg = '회사명은 이메일형식으로 저장할 수 없습니다.';
      } else if (commonSVC.isEmailFormat($scope.data.ceo_name)) {
        email_error_msg = '대표자명은 이메일형식으로 저장할 수 없습니다.';
      } else if (commonSVC.isEmailFormat($scope.data.addr1)) {
        email_error_msg = '주소는 이메일형식으로 저장할 수 없습니다.';
      } else if (commonSVC.isEmailFormat($scope.data.uptae)) {
        email_error_msg = '업태는 이메일형식으로 저장할 수 없습니다.';
      } else if (commonSVC.isEmailFormat($scope.data.upjong)) {
        email_error_msg = '업종은 이메일형식으로 저장할 수 없습니다.';
      }

      if (email_error_msg) {
        commonSVC.showMessage(email_error_msg);

        return false;
      }

      // 사업자 번호 조회 실패 시 진행 불가
      if (!$scope.isChkBizNo) {
        commonSVC.showMessage('사업자번호를 조회해주세요.');

        return false;
      }

      // 이미지 크기가 5MB를 넘길 경우 얼럿 발생.
      if ($scope.param.files && $scope.param.files.size > 5120000) {
        commonSVC.showMessage('사업자등록증은 5MB 이하의 파일만 등록 가능합니다.');

        return false;
      }

      try {
        await adminModel.edit({ company: $scope.data, files: $scope.param.files || null });

        $uibModalInstance.close('success');
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.data?.messages[0]);
      }
    };

    $scope.chkBizNo = async () => {
      if (!$scope.data.biz_reg_no) {
        commonSVC.showMessage('사업자 번호를 입력해 주세요.');

        return false;
      }

      const re = await userModel.checkBizNo($scope.data.biz_reg_no);
      if (re.data.result && re.data.results.length > 0) {
        commonSVC.showMessage('등록된 사업자 번호입니다.');

        return false;
      } else {
        $scope.isChkBizNo = true;

        commonSVC.showMessage('사용할 수 있는 사업자 번호입니다.');
      }
    };

    /**
     * 찯 닫기
     */
    $scope.close = () => {
      $uibModalInstance.close('cancel');
    };
  });
