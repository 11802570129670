/**
 * 무료 부가서비스 내역 그리드 컨트롤러
 */
'use strict';

angular.module('gmpApp')
  .controller('BenefitGridCtrl', function ($scope, commonSVC, paySVC, workSVC, settings) {
    const search = $scope.search.benefit;

    // 검색영역 폼
    $scope.searchData = search.searchData;
    $scope.searchForm = search.searchForm;

    search.benefitType = angular.copy(paySVC.benefitType);

    search.searchFn = {
      searchDo: () => {
        $scope.searchDo(true, true);
      },
      changeLen: (count) => {
        $scope.changeCount(count);
      },
      resetDo: () => {
        $scope.resetDo();
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.search[$scope.gridType].searchForm = angular.copy(paySVC.searchForm_benefit);

      $scope.searchDo(true, true);
    };

    search.searchBtn = angular.copy($scope.searchBtn);

    /**
     * 무료 부가서비스 내역 DataTable 설정
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['wdate', 'title', 'send_type', 'expiration_period', 'charge_cnt'],
      defaultSortingColumns: ['wdate'],
      notMovingColumns: [],
      notVisibleColumns: [],
      notResizingColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/benefit-list`,
        requestWillAction: function (data) {
          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(Object.assign(data, search.searchForm));

          return data;
        },
        requestDidAction: function(result) {
          search.searchData.totalCount = result.recordsTotal;
          $scope.rowTotalCount = result.rowsTotal;
          $scope.result = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'wdate',
          title: '지급일',
          width: 150,
          filter: 'dateValid'
        },
        {
          key: 'title',
          title: '지급내용',
          width: 300
        },
        {
          key: 'send_type',
          title: '구분',
          width: 150,
          template: (row) => {
            const type = {
              email: 'email',
              atalk: '알림톡',
              sms: 'SMS'
            };

            return type[row.send_type];
          }
        },
        {
          key: 'expiration_period',
          title: '유효기간',
          width: 250
        },
        {
          key: 'charge_cnt',
          title: '남은 건 수',
          width: 150
        }
      ]
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length(search.searchData.showCount);
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh = true, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };
  });