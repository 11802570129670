'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A077-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $uibModalInstance, commonSVC, commonModel, shopAccountModel, onlineProductModel, $timeout) {
    $scope.row = angular.copy(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.row.set_info.key_fromUseSite = $scope.row.set_info.key_fromUseSite || '1';
    $scope.data = _.cloneDeep(data.data);
    $scope.visible = {
      more: false,
      basic: true,
      etc: true
    };
    $scope.productAttrBasicData = [];
    $scope.productAttrEtcData = [];
    let attributes = [];  // 추가항목 내 상품 속성
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      const set_info = $scope.row.set_info;

      // 상품속성 저장
      if ($scope.row.set_info.key_fromUseSite == 0) {
        $scope.row.set_info.key_ProductAttributeType = [];
        const productAttrs = $scope.productAttrBasicData.concat($scope.productAttrEtcData);
        productAttrs.forEach((item) => {
          const productAttr = {
            key_AttributeSeq: item.code,
            key_AttributeValueSeq: item.key_AttributeValueSeq || '',
            key_AttributeRealValue: item.key_AttributeRealValue || '',
            key_AttributeRealValueUnitCode: item.key_AttributeRealValueUnitCode || '',
          };

          if (item.view_type.code === 'MULTI_SELECT') {
            productAttr.key_AttributeValueSeq = item.data.filter(o => o.value).map(o => o.code);
          }
          $scope.row.set_info.key_ProductAttributeType.push(productAttr);
        });
      }

      if (set_info.key_PeriodType == 'FB') {
        if (!set_info.key_PeriodDays_select || (set_info.key_PeriodDays_select == '-1' && !set_info.key_PeriodDays)) {
          commonSVC.showMessage('쿠폰유효기간을 확인하세요');

          return false;
        }
      }
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    let set_info = angular.copy($scope.row.set_info);

    if (_.isUndefined($scope.row.set_info)) {
      set_info = {};
    }

    //수입사 확인
    if ($scope.row.set_info.key_importer) {
      $scope.row.set_info.key_fromUseTemplate = '0';
    } else {
      $scope.row.set_info.key_fromUseTemplate = '1';
    }

    // 독립출판물 여부
    $scope.row.set_info.key_IndependentPublicationYn = $scope.row.set_info.key_IndependentPublicationYn || 'N';

    // 문화비 소득공제 여부
    $scope.row.set_info.key_CultureCostIncomeDeductionYn = $scope.row.set_info.key_CultureCostIncomeDeductionYn || 'N';
    // 이벤트 정보 유지 여부
    $scope.row.set_info.key_use_PublicityPhraseContent = $scope.row.set_info.key_use_PublicityPhraseContent || 'N';

    $scope.importerEtcFn = function(type) {
      if (type === '1') {
        $scope.row.set_info.key_importer = '';
      }
    };

    /**
     * 2018-01-22 Daniel
     * 상세설명 참조 넣는 함수
     */
    $scope.detailRef = function() {
      if (!$scope.row.set_info.contentRef) {
        $scope.row.set_info.contentRef = '0';
      }
      const cR = $scope.row.set_info.contentRef;

      const detailKeys = ['ReturnCostReason', 'NoRefundReason', 'QualityAssuranceStandard', 'CompensationProcedure', 'TroubleShootingContents'];

      _.each(detailKeys, function(key) {
        const detail = set_info[`b64_${key}`];

        if (cR === '0') {
          $scope.row.set_info[`b64_${key}`] = '';
        } else {
          if (detail === '상세설명 참조') {
            $scope.row.set_info[`b64_${key}`] = '';
          } else {
            $scope.row.set_info[`b64_${key}`] = detail;
          }
        }
      });
    };

    $scope.detailRef();

    // 상품속성 세팅
    $scope.getCateAttr = async () => {
      const match_cate_code = data.shop_data.match_cate_code;
      const shop_cate_no_add_info = typeof data.data.shop_cate_no_add_info === 'string' ? JSON.parse(data.data.shop_cate_no_add_info) : data.data.shop_cate_no_add_info;
      const sol_cate_shop_add_info = typeof data.data.sol_cate_shop_add_info === 'string' ? JSON.parse(data.data.sol_cate_shop_add_info) : data.data.sol_cate_shop_add_info;

      const category_code = match_cate_code || shop_cate_no_add_info?.category_code || sol_cate_shop_add_info?.category_code;

      if (!category_code) {
        commonSVC.showMessage('카테고리 정보가 없습니다.');

        return;
      }
      const category_id = category_code.split('_').pop();

      const re = await commonModel.pa5('/channel_accounts/{uuid}/etc/get_category_attributes/sync', { category_id }, data.shop_data.shop_cd, data.shop_data.shop_id);

      if (re.data.results) {
        attributes = re.data.results[0].data.attributes;

        if ($scope.row.set_info.key_fromUseSite == 0) {
          attributes.forEach((item) => {
            const findAttr = $scope.row.set_info.key_ProductAttributeType.find(o => o.key_AttributeSeq === item.code);

            if (findAttr) {
              item.key_AttributeSeq = findAttr.key_AttributeSeq;
              item.key_AttributeValueSeq = findAttr.key_AttributeValueSeq;
              item.key_AttributeRealValue = findAttr.key_AttributeRealValue;
              item.key_AttributeRealValueUnitCode = findAttr.key_AttributeRealValueUnitCode;

              if (item.view_type.code === 'MULTI_SELECT') {
                item.data.forEach(o => {
                  if (findAttr.key_AttributeValueSeq.includes(o.code)) {
                    o.value = true;
                  }
                });
              }
            }
          });
        }

        $scope.productAttrBasicData = attributes.filter(item => item.type.code === 'PRIMARY');
        $scope.productAttrEtcData = attributes.filter(item => item.type.code === 'OPTIONAL');
        $scope.units = re.data.results[0].data.units;

        $timeout(() => {});
      }
    };

    $scope.getCateAttr();

    $scope.productAttrReset = () => {
      $scope.productAttrBasicData.forEach(item => {
        item.key_AttributeValueSeq = '';
        item.key_AttributeRealValue = '';
        item.key_AttributeRealValueUnitCode = '';

        if (item.view_type.code === 'MULTI_SELECT') {
          item.data.forEach(subItem => {
            subItem.value = false;
          });
        }
      });

      $scope.productAttrEtcData.forEach(item => {
        item.key_AttributeValueSeq = '';
        item.key_AttributeRealValue = '';
        item.key_AttributeRealValueUnitCode = '';

        if (item.view_type.code === 'MULTI_SELECT') {
          item.data.forEach(subItem => {
            subItem.value = false;
          });
        }
      });
    };

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };
      attributes.forEach((item) => {
        const findAttr = $scope.row.set_info.key_ProductAttributeType.find(o => o.key_AttributeSeq === item.code);

        if (findAttr) {
          item.key_AttributeSeq = findAttr.key_AttributeSeq;
          item.key_AttributeValueSeq = findAttr.key_AttributeValueSeq;
          item.key_AttributeRealValue = findAttr.key_AttributeRealValue;
          item.key_AttributeRealValueUnitCode = findAttr.key_AttributeRealValueUnitCode;

          if (item.view_type.code === 'MULTI_SELECT') {
            item.data.forEach(o => {
              if (findAttr.key_AttributeValueSeq.includes(o.code)) {
                o.value = true;
              }
            });
          }
        }
      });

      $scope.productAttrBasicData = attributes.filter(item => item.type.code === 'PRIMARY');
      $scope.productAttrEtcData = attributes.filter(item => item.type.code === 'OPTIONAL');

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    /**
     * 주소록 조회 추가
     */
    $scope.get_delivery_template = async () => {
      const response = await commonModel.pa5('/channel_accounts/{uuid}/etc/get_delivery_template/sync', '', data.shop_data.shop_cd, data.shop_data.shop_id);

      if (response.data.results) {
        $scope.delivery_list = response.data.results[0].data.map(v => ({
          key: v.addressInfo.fullAddressInfo,
          value: v.id
        }));

      }
    };
    $scope.get_delivery_template();
  }
  );
