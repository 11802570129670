/**
 *  ptgui 컴퍼넌트
 *  2016-06-01 권윤학
 */

angular.module("ptgui", [
  'ptgui.zipcode',
  'ptgui.imagebox',
  'ptgui.tel',
  'ptgui.decimal',
  'ptgui.numberonly',
  'ptgui.regexMask'
]);
