'use strict';

angular.module('gmpApp')
  .service('brandSVC', function (commonSVC) {

    return {
      /**
       * 폼 영역 변수 설정
       */
      searchForm: {
        search_key: 'all',
        search_word: '',
        stock_type: 'real',
        s_stock: '',
        e_stock: '',
        date_type: 'wdate',
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd')
      },
      /**
       * 기본 Vdata
       */
      dafaultVdata: {
        wid: '',
        code: '',
        name: '',
        name_eng: '',
        channel_name: '',
        maker: '',
        log_contents: ''
      }
    };
  });
