'use strict';

/**
 * Created by ally on 2017. 8. 29..
 */
angular.module('gmpApp')
  .controller('makeBundleOrderCtrl', function ($scope, $uibModalInstance, data, commonSVC, shipmentModel, gettextCatalog) {
    $scope.selectList = _.uniqWith(data.selectList, function(a, b) {
      return a.to_name === b.to_name && a.to_zipcd === b.to_zipcd && a.to_addr1 === b.to_addr1 && a.to_htel === b.to_htel;
    });
    $scope.selectBundleIndex = null;
    $scope.submitDisabled = false;

    let standardData = {};
    const param = {
      uniqList: data.uniqList,
    };
    const checkShopeeOrd = $scope.selectList.some(ord => ord.pa_shop_cd === 'X099');

    $scope.selectSData = function(idx) {
      $scope.selectBundleIndex = idx;
      standardData = $scope.selectList[idx].uniq;
    };

    $scope.ok = function () {
      if (checkShopeeOrd) {
        commonSVC.showToaster('success', '성공', '작업이 등록되었습니다.');
        $scope.submitDisabled = true;
      }
      if ($scope.selectBundleIndex != null) {
        param.standardData = standardData;

        shipmentModel.makeBundle(param, function (state, result) {
          if (state == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('배송 관리'), gettextCatalog.getString('주문 합포장 성공'));
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '', result.message || result);
            $scope.submitDisabled = false;
          }
        });
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
