'use strict';

/**
 * 2018-05-08 lucas
 * 메이크샵
 */
angular.module('gmpApp')
  .factory('B118categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_lcate',
        1: 'key_mcate',
        2: 'key_scate'
      },

      category_action: {
        0: 'scrap_category_large',
        1: 'scrap_category_list',
        2: 'scrap_category_list'
      },

      category_template: {
        // 표준분류
        key_lcate: '',
        key_mcate: '',
        key_scate: '',

        // 카테고리 이름, 코드, 옵션
        category_names: '',
        category_code: '',
        category_opt: [[], [], []],

        key_cate: ''
      },

      category_data: {
        codes: [],
        names: [],
        key_class1: '',
        key_class2: '',
        key_class3: '',
        category_code: '',
        category_names: ''
      }

    };
  });
