/**
 *  string 클래스 관련 처리
 *  2016-06-17 권윤학
 */

/**
 * 문자 좌우 공백 제거 2016-06-17 권윤학
 * @returns {string}
 */
String.prototype.trim = function() {
  return this.replace(/(^\s*)|(\s*$)/gi, "");
};

/**
 * 문자 좌측 공백 제거 2016-06-17 권윤학
 * @returns {string}
 */
String.prototype.ltrim = function() {
  return this.replace(/(^\s*)/, "");
};

/**
 * 문자 우측 공백제거 2016-06-17 권윤학
 * @returns {string}
 */
String.prototype.rtrim = function() {
  return this.replace(/(\s*$)/, "");
};

/**
 * 문자열의 byte 길이 변환
 * 한글 2byte 취급 2016-06-17 권윤학
 * @returns {number}
 */
String.prototype.byte2 = function() {
  for(b=i=0; c=this.charCodeAt(i++); b+=c>>11?2:c>>7?2:1);
  return b
};

/**
 * 문자열의 byte 길이 변환
 * 한글 3byte 취급 2016-06-17 권윤학
 * @returns {number}
 */
String.prototype.byte3 = function() {
  for(b=i=0;c=this.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
  return b
};