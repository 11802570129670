/* eslint-disable @typescript-eslint/no-empty-function */
'use strict';

angular.module('gmpApp')
  .controller('SettingsShopDetailCtrl', function ($rootScope, $scope, $state, $stateParams, shopAccountSVC, shopAccountModel, commonSVC, siteInfo, userInfo, gettextCatalog, $window, $q, commonModel, $timeout, workSVC, localStorageService, systemModel) {

    $rootScope.channelDetailOpen = false;                         // 상세페이지 open 여부
    $scope.isChannelAdd = $stateParams.mode === 'add';           // 추가페이지 or 수정페이지
    $scope.reCheckClass = {};

    $scope.isFirstShopRegist = $scope.$parent.isFirstShopRegist; // 최초접속자 쇼핑몰 등록 모달에서 넘어온 경우
    $rootScope.cantChangeTab = !$rootScope.use_channel_list.length && $scope.isFirstShopRegist && !localStorageService.get(`isAutoSetting_${userInfo.user.sol_no}`);
    const { pa_shop_cd, shop_cd, shop_id } = $stateParams;
    let { domain } = $stateParams;

    // 티몬오픈마켓 // 갤러리아몰
    if (domain == 'ticketmonster.co.kr') {
      domain = 'openmarket.ticketmonster.co.kr';
    } else if (domain == 'galleria.co.kr') {
      domain = 'gvs.galleria.co.kr';
    }

    // 보안정보 수정
    $scope.encryptData = {
      password: {
        isEdit: false,
        oldvalue: '',
        placeHolder: '수정버튼을 눌러 비밀번호 변경',
        buttonText: '수정'
      },
      apikey: {
        isEdit: false,
        oldvalue: '',
        placeHolder: '수정버튼을 눌러 API KEY 변경',
        buttonText: '수정'
      }
    };

    // 엔진팀 계정만 활성화시키기 위한 변수
    $scope.sol_no = userInfo.user.sol_no;
    // 엘지 계정인지 확인
    $scope.isLG = $rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no;

    $scope.isMultiAgency = false; // 복수 대행사 사용 여부 (가이드이미지 변경용)

    let needLoginCheck = true;

    if ((shopAccountSVC.noneLoginCheck.indexOf(pa_shop_cd) > -1)   // 로그인체크 해야하는 사이트인지 확인
      || ($stateParams.shop_type != 'ALL')) {
      needLoginCheck = false;
    }
    if (!$scope.isChannelAdd)   // 로그인 정보 입력 폼 수정여부 확인(신규추가가 아니면 default값 true)
    {
      $scope.loginChecked = true;
    }
    $scope.providerChecked = pa_shop_cd !== 'A027';    // 공급업체 확인 여부(인터파크)
    $scope.tmp = {};

    $scope.pa5_job_data = {}; // pa5 작업호출 결과 데이터 저장

    /**
     * pa5 호출
     * @param {string} job_name 작업
     * @param {string} job_kor_name 작업명
     * @param {string} uuid pa5 채널어카운트id
     * @param {*} push_data body
     * @param {(data) => data} temp_saver 작업 성공한 결과 처리 콜백함수
     * @param {() => void} error_logic 작업 실패시 로직 실행
     * @returns
     */
    const run_pa5_jobs = async (job_name, job_kor_name, uuid, push_data, temp_saver, error_logic) => {

      if (!$scope.pa5_job_data[job_name]) {
        $scope.pa5_job_data[job_name] = {
          load: false,
          data: []
        };
      }

      try {
        const re = await commonModel.pa5(`/channel_accounts/${uuid}/etc/${job_name}/sync`, push_data, $scope.vdata.pa_shop_cd, $scope.vdata.shop_id);

        if (re.data.has_error) {
          commonSVC.showMessage('불러오기 실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message || ''}`);
          error_logic();

          return;
        }

        $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
        $scope.pa5_job_data[job_name].load = true;

        $timeout(() => {});

        return re.data.results[0].data;
      } catch (e) {
        commonSVC.showMessage('불러오기 실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
        error_logic();
      }
    };

    /**
     * pa5 사용 쇼핑몰 리스트
     */
    // pa5 로그인확인
    const loginURLs_pa5 = ['A001', 'A004', 'A006', 'A011', 'A112', 'B666', 'P012', 'P013', 'A528', 'A109', 'P014', 'P015', 'A525', 'P016', 'A032', 'A076', 'P017', 'P018', 'B502', 'B005', 'A118', 'B957', 'A077', 'A133', 'B669', 'P020', 'A140', 'B959', 'B325', 'A150', 'B696', 'P028', 'P038', 'A085', 'B612', 'B690', 'P037', 'A124', 'B614', 'B378', 'A524', 'B693', 'P039', 'P042', 'P044', 'P045', 'P046', 'A530', 'B118', 'P047', 'P048', 'A100', 'B688', 'P050', 'P051', 'B700', 'A170', 'A017', 'A171', 'A527', 'A526', 'A536', 'B668', 'B953', 'B661', 'B509', 'A537', 'B694', 'B722', 'B727', 'B703', 'B685', 'B572', 'B702', 'B682', 'B880', 'A114', 'A024', 'B692', 'B087', 'A065', 'A134', 'A160', 'B569', 'A534', 'B707', 'B726', 'B712', 'B771', 'B594', 'B845', 'B877', 'A132', 'B924', 'A131', 'P053', 'B046', 'A176', 'A175', 'B719', 'A027', 'P056', 'A178', 'A179', 'P055', 'A538', 'A093', 'A092', 'P058', 'P054', 'B616', 'B603', 'A064', 'B394', 'B684', 'X095', 'B878', 'B730', 'P060', 'B041'];

    // pa5 배송지/반품지 조회
    const shopURLs_pa5 = {
      A077: ['get_delivery_template'], // 스마트스토어
      A109: ['get_delivery_template'], // 하프클럽
      A100: ['get_delivery_template|10', 'get_delivery_template|20'], // 롯데아이몰
      A112: ['get_shipping_places', 'get_return_places'], // 11번가
      B614: ['get_delivery_template'], // 홈앤쇼핑
      B688: ['get_delivery_template'], // 카카오톡 스토어
      B700: ['get_delivery_template'], // 카카오 선물하기
      P014: ['get_delivery_template'], // 보리보리
      A004: ['get_shipRecallList'], // CJ온스타일
      A001: ['get_shipping_place', 'get_seller_address'], // 옥션
      A006: ['get_shipping_place', 'get_seller_address'], // 지마켓
      B959: ['get_delivery_addr'], // 오늘의 집
      B719: ['get_delivery_templates|product.sellership.scrap'], // 위메프2.0 (기존 PA4 key 매칭)
      A027: ['get_delivery_templates|load_addr'], // 위메프2.0 (기존 PA4 key 매칭)
      B730: ['get_shipping_places', 'get_return_places'], // 오너클랜
    };

    /**
     * pa4 로그인확인
     */
    const loginActions = {
      B048: 'etc.check_login',
      B378: 'etc.check_login',
      B579: 'etc.check_login',
      B917: 'etc.check_login',
      B007: 'etc.check_login',
      B051: 'etc.check_login',
      B851: 'etc.check_login',
      B956: 'etc.check_login',
      B720: 'etc.check_login',
      B687: 'etc.check_login'
    };

    /**
     * pa4 출고지, 반품/교환지 조회 등
     */
    const shopActions = {
      A008: ['load_addr'],
      A011: ['get_deliv_comp', 'get_visit_addr'],
      A012: ['get_return_addr', 'get_dlv_polc'],
      A083: ['deliv_location_code_list'],
      A084: ['deliv_location_code_list'],
      B378: ['etc.scrap_delivery_place_out', 'etc.scrap_delivery_place_in'],
      B956: ['etc.scrap_delivery_place', 'etc.scrap_return_place'],
      B851: ['etc.scrap_delivery_place_d', 'etc.scrap_delivery_place_r'],
      A524: ['etc.get_DvpList'],
    };

    const shopValueKey = {
      A001: 'value',
      A006: 'value',
      A008: 'key_rtnDelvNo',
      A027: 'key_rtnDelvNo',
      A100: 'ReturnCode',
      B851: 'addrSeqno',
      A524: 'dvpNo',
      B719: 'shipPolicyNo',
    };

    init();

    let origin_shipInfo;
    async function init() {
      $scope.shipInfo = {};  // gmp.sol_shop.ship_info_default (템플릿에 출고지/반품지 기본 세팅 목적) 저장할 json
      $scope.tmp = {}; // 출고지/반품지 선택한 값 세부 정보 관리하기 위한 필드

      if ($scope.isChannelAdd) { // 추가시
        $scope.vdata = angular.copy(shopAccountSVC.dafaultVdata);
        $scope.vdata.pa_shop_cd = pa_shop_cd;
        $scope.vdata.shop_cd = shop_cd;
        $scope.vdata.shop_name = decodeURIComponent($stateParams.shop_name);

        // 직접입력 쇼핑몰
        if (shop_cd?.startsWith('U')) {
          $scope.vdata.shop_cd = shop_cd;
        }
        if (pa_shop_cd === 'A077') { //(스마트스토어) 계정유형/스토어 초기화
          $scope.vdata.etc3 = '1';
          $scope.use_yn = false; //연동스토어 선택 여부(스마트스토어)
          $scope.commerce_id = {
            view: false,    // 커머스아이디 노출여부
            use: false      // 커머스아이디 (스마트스토어)
          };
          $scope.storeType = 'smartstore'; // 스토어 url (smartstore: 스마트스토어 도메인, personal: 개인도메인)
        } else if (pa_shop_cd === 'B009') { // 롯데 파트너 플러스
          $scope.vdata.etc1 = ''; // 채널 선택 여부
        } else if (pa_shop_cd === 'A131') { // HMALL 홈쇼핑 주문 수집 여부
          $scope.vdata.etc4 = 'N';
        } else if (pa_shop_cd === 'P016') { // ABLY 판매자 타입
          $scope.vdata.etc2 = '3';
        } else if (['P012', 'P013', 'P017'].includes(pa_shop_cd)) { // 셀러 유형
          $scope.vdata.etc2 = false;
        } else if (pa_shop_cd === 'A176') { // 엔진에서 샵바이 본사, 파트너 및 기타 구분을 위한 코드
          $scope.vdata.etc3 = 'cashdeal';
        } else if (pa_shop_cd === 'B378') {
          $scope.vdata.etc6 = false;
          $scope.vdata.etc7 = 'N';
        }

        if (['A001', 'A006', 'A077'].includes(pa_shop_cd)) {
          $scope.vdata.etc1 = 'N'; // 물류 주문 수집 여부
          $scope.useEsmMasterId = 'N';
        }

        // makeshop sso 계정 생성시 비밀번호 자동생성
        if (shop_cd === 'P059') {
          $scope.vdata.shop_pwd = `playautomakeshopsso${userInfo.user.sol_no}`;
        }

        $scope.addrList = {};  // 로드한 배송지/반품지 리스트 값 저장

      } else { // 수정시
        // 배송지 로딩 스피너
        $('#deliverySpinner > div').html('<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />배송지 로드중');
        $('#deliverySpinner').removeClass('hidden');

        const params = {
          shop_cd: shop_cd,
          shop_id: shop_id,
          shop_id_sub: $stateParams.shop_id_sub
        };

        const { status, data } = await shopAccountModel.load(params);

        if (status === 200) {
          const result = data.results;

          if (result) {
            $scope.encryptData.apikey.oldvalue = angular.copy(result.api_enc);
            $scope.encryptData.password.oldvalue = angular.copy(result.shop_pwd);
          }

          $scope.vdata = {
            ...result,
            new_pwd: '',
            new_api_enc: '',
            pa_shop_cd
          };

          $timeout(() => {});

          origin_shipInfo = $scope.vdata.ship_info_default || {};
          $scope.shipInfo = { ...origin_shipInfo };

          $scope.addrList = {};
          $scope.use_yn = false;

          if (['A001', 'A006', 'A077'].includes(pa_shop_cd)) {
            if ($scope.vdata.etc1 !== 'Y') {
              $scope.vdata.etc1 = 'N'; // 물류 주문 수집 여부
            }

            if (pa_shop_cd !== 'A077') {
              $scope.useEsmMasterId = $scope.vdata.etc7 ? 'Y' : 'N';
            }
          }

          if (pa_shop_cd === 'A077') {
            $scope.storeType = $scope.vdata.etc2 ? 'smartstore' : 'personal';

            // 2022-08-23 이전 등록 계정은 커머스아이디 노출
            $scope.commerce_id = {
              view: result.wdate ? moment(result.wdate) < moment('2022-08-23 00:00:00') : false,
              use: !!result.etc6
            };
          }

          if (pa_shop_cd === 'A027' || pa_shop_cd === 'A008') {
            if (result.etc1) {
              $scope.vdata.etc1 = result.etc1 * 1;
            } else {
              $scope.vdata.etc1 = '';
            }

            if (pa_shop_cd === 'A027') {
              await $scope.loadProviderOM('init');
            } else {
              const params = {
                job_data: {
                  account: {
                    seller_id: shop_id,
                    seller_pw: result.shop_pwd
                  }
                },
                req_code: 'interpark.com',
                req_action: 'load_accounts'
              };

              await $scope.loadProvider(params);
            }
          }

          if (['P012', 'P013', 'P017'].includes(pa_shop_cd)) {
            $scope.vdata.etc2 = $scope.vdata.etc2 === 'true';
          }

          if (pa_shop_cd === 'B378') {
            $scope.vdata.etc6 = $scope.vdata.etc6 === 'true';
            $scope.vdata.etc7 = $scope.vdata.etc7 || 'N';
            $scope.useGrowth = $scope.vdata.etc7 || 'N';
          }

          if (pa_shop_cd === 'B118') {
            $scope.noticeBoardList();
          }

          if (pa_shop_cd === 'A017') {
            $scope.getDeliveryAddr();
          }

          setShipInfoView();

          // 직접입력 쇼핑몰이면 배송처 로드 안함
          if (!pa_shop_cd.startsWith('U')) {
            await $scope.loadAddrList();
            $timeout(() => {});
          }
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('쇼핑몰 계정정보 로드 실패'));
        }
      }

      $scope.$watch('vdata.etc1', function(nv, ov) {
        if (!$scope.vdata || nv === ov) {
          return;
        }  //2018-04-13 ally vdata없는것도 있어서 추가
        if (pa_shop_cd === 'A027') {
          if (nv !== '' && $scope.providerList) {
            const p = $scope.providerList[$scope.vdata.etc1];

            $scope.vdata.etc2 = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
            $scope.vdata.shop_info = { company: p };

            $scope.vdata.shop_id_sub = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
            $scope.loginChecked = false;
            $scope.providerChecked = true;
            $scope.vdata.uuidEtc = { company: p };
          } else {
            $scope.vdata.etc2 = '';
            $scope.vdata.shop_id_sub = '';
            $scope.loginChecked = false;
            $scope.providerChecked = false;
          }
        } else if (pa_shop_cd === 'A008') {
          if (nv !== '') {
            const p = $scope.providerList[$scope.vdata.etc1];

            $scope.vdata.etc2 = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
            $scope.vdata.shop_id_sub = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
            $scope.loginChecked = false;
          } else {
            $scope.vdata.etc2 = '';
            $scope.vdata.shop_id_sub = '';
            $scope.loginChecked = false;
          }
        }
      });
    }

    $scope.pa5_addr_data = [];
    let pa5_addr_origin_data = {};

    /**
     * 출하지회수지 로드 ( AK몰 )
     */
    $scope.getDeliveryAddr = function () {
      showSpinner('getDeliveryAddr', '출하지 로드중');
      const actions = ['get_delivery_addr', 'get_delivery_cost'];
      actions.map(function (action) {
        if (action == 'get_delivery_addr') {
          run_pa5_jobs(action, '출하지', $scope.vdata.uuid, {}, (data) => {
            $scope.loadAddrNone = !data.length;

            pa5_addr_origin_data = { ...data };
            $scope.pa5_addr_data.out = data;
            $scope.pa5_addr_data.in = data;

            // 화면에 기본값 설정된채로 노출
            $scope.shipInfo.out_addr ??= $scope.pa5_addr_data.out.find(e => e.addr_div_code === '30')?.vendor_addr_id;
            $scope.shipInfo.in_addr ??= $scope.pa5_addr_data.in.find(e => e.addr_div_code === '40')?.vendor_addr_id;
            hideSpinner('getDeliveryAddr');
          }, () => hideSpinner('getDeliveryAddr'));
        } else {
          run_pa5_jobs(action, '출하지', $scope.vdata.uuid, {}, (data) => {
            pa5_addr_origin_data = { ...data };
            $scope.pa5_addr_data.cost = data;

            // 화면에 기본값 설정된채로 노출
            $scope.shipInfo.deliv_cost ??= $scope.pa5_addr_data.cost[0].deliv_price_policy_id;
            hideSpinner('getDeliveryAddr');
          }, () => hideSpinner('getDeliveryAddr'));
        }
      });

      $scope.loadAddrFail = false;
    };

    /**
     * 기본 배송지 설정 view 위치 변경
     */
    function setShipInfoView() {
      // 선택한 쇼핑몰의 기본배송지 div
      const shipInfo = document.querySelector('#channelContents .shipInfoSettings');
      // 기본배송지 div를 담을 element
      const targetContainer = angular.element(document.querySelector('.shipInfoContents'));

      // 기존 기본배송지 div 초기화
      angular.element(document.querySelector('.shipInfoContents')).empty();

      targetContainer.append(shipInfo);
      $('.shipInfoSettings').removeClass('hide');
      hideSpinner('deliverySpinner');
    }

    /**
     * 오브젝트 빈값 확인
     */
    $scope.isObjectEmpty = obj => {
      return !obj || (typeof obj === 'string' && obj === '{}') || Object.keys(obj).length === 0;
    };

    /**
     * 게시판 리스트 로드(메이크샵)
     */
    $scope.noticeBoardList = async () => {
      const shopPwd = $scope.isChannelAdd ? $scope.vdata.shop_pwd : ($scope.encryptData.password.isEdit ? $scope.vdata.new_pwd : $scope.encryptData.password.oldvalue);

      if (!$scope.vdata.shop_id || !shopPwd) {
        commonSVC.showMessage('아이디, 또는 패스워드를 입력하여 주십시오.');

        return false;
      }

      if ($scope.loginChecked || !needLoginCheck || $scope.providerChecked) {
        // PA5
        if (pa_shop_cd === 'B118') {
          if (!$scope.vdata.uuid) {
            commonSVC.showMessage('로그인 확인을 해 주세요.');

            return false;
          }
          $scope.pa5_addr_data = [];

          showSpinner('noticeBoardList', '게시판 로드중');
          await run_pa5_jobs('scrap_notice_board', '게시판', $scope.vdata.uuid, {}, (data) => {
            pa5_addr_origin_data = { ...data };

            data.map(o => {
              const scrapManInfo = {
                code: o.code,
                name: o.name,
                reply: o.reply === 'Y' ? '사용' : '미사용',
                comment: o.comment === 'Y' ? '사용' : '미사용',
                type: o.type,
                write_type: o.write_type,
                lock_ok: o.lock_ok,
                read_type: o.read_type,
              };
              $scope.pa5_addr_data.push(scrapManInfo);
            });

          }, () => hideSpinner('noticeBoardList'));

          hideSpinner('noticeBoardList');
        }

      } else {
        commonSVC.showMessage('로그인 확인을 해주세요.');

        return false;
      }
    };

    /**
     * 메이크샵에서 사용
     */
    $scope.addNoticeBoard = function (type) {
      if (!$scope.shipInfo?.inquiry_board_list) {
        $scope.shipInfo.inquiry_board_list = [];
      }
      if (!$scope.shipInfo?.comment_board_list) {
        $scope.shipInfo.comment_board_list = [];
      }

      const inquiry_board_code_list = $scope.shipInfo.inquiry_board_list.map(board => board.code);
      const comment_board_code_list = $scope.shipInfo.comment_board_list.map(board => board.code);

      if (type === 'inquiry') {
        const board_data = JSON.parse($scope.shipInfo.scrap_man);

        if (!board_data) {
          commonSVC.showMessage('선택된 문의 게시판이 없습니다.');
          $scope.shipInfo.scrap_man = '';

          return false;
        }

        if (inquiry_board_code_list.includes(board_data.code)) {
          commonSVC.showMessage('이미 선택된 문의 게시판입니다.');
          $scope.shipInfo.scrap_man = '';

          return false;
        }
        if (comment_board_code_list.includes(board_data.code)) {
          commonSVC.showMessage('상품평 게시판에 선택된 게시판입니다.');
          $scope.shipInfo.scrap_man = '';

          return false;
        }

        $scope.shipInfo.inquiry_board_list.push(board_data);
        $scope.shipInfo.inquiry_board_list = [...new Set($scope.shipInfo.inquiry_board_list)];
        $scope.shipInfo.scrap_man = JSON.stringify([...new Set($scope.shipInfo.inquiry_board_list)][0]);
      }

      if (type === 'comment') {
        const board_data = JSON.parse($scope.shipInfo?.scrap_man2);

        if (!board_data) {
          commonSVC.showMessage('선택된 상품평 게시판이 없습니다.');
          $scope.shipInfo.scrap_man2 = '';

          return false;
        }

        if (comment_board_code_list.includes(board_data.code)) {
          commonSVC.showMessage('이미 선택된 상품평 게시판입니다.');
          $scope.shipInfo.scrap_man2 = '';

          return false;
        }

        if (inquiry_board_code_list.includes(board_data.code)) {
          commonSVC.showMessage('문의게시판에 선택된 게시판입니다.');
          $scope.shipInfo.scrap_man2 = '';

          return false;
        }

        $scope.shipInfo.comment_board_list.push(board_data);
        $scope.shipInfo.comment_board_list = [...new Set($scope.shipInfo.comment_board_list)];
        $scope.shipInfo.scrap_man2 = JSON.stringify([...new Set($scope.shipInfo.comment_board_list)][0]);
      }
    };

    $scope.deleteNoticeBoard = function (type, data) {
      if (type === 'inquiry') {
        $scope.shipInfo.inquiry_board_list = $scope.shipInfo.inquiry_board_list.filter(board => board !== data);
      }

      if (type === 'comment') {
        $scope.shipInfo.comment_board_list = $scope.shipInfo.comment_board_list.filter(board => board !== data);
      }
    };

    /**
     * 배송지/반품지 리스트 로드
     */
    $scope.loadAddrList = async function () {
      const shopPwd = $scope.isChannelAdd ? $scope.vdata.shop_pwd : ($scope.encryptData.password.isEdit ? $scope.vdata.new_pwd : $scope.encryptData.password.oldvalue);

      if (!$scope.vdata.shop_id || !shopPwd) {
        commonSVC.showMessage('아이디, 또는 패스워드를 입력하여 주십시오.');
        $scope.loadAddrFail = true;

        return false;
      }

      if ($scope.loginChecked || !needLoginCheck || $scope.providerChecked) {
        showSpinner('deliverySpinner', '배송지 로드중');

        // PA5
        if (Object.keys(shopURLs_pa5).includes(pa_shop_cd)) {
          if (!$scope.vdata.uuid) {
            commonSVC.showMessage('로그인 확인을 해 주세요.');
            $scope.loadAddrFail = true;
            hideSpinner('deliverySpinner');

            return false;
          }

          try {
            for (let action of shopURLs_pa5[pa_shop_cd]) {
              const actionInfo = action.split('|');

              // real_action
              action = actionInfo[0];

              const param = {};
              if (pa_shop_cd === 'A100') { // 롯데아이몰
                param.type = actionInfo[1];
              }

              const loadAddrRe = await run_pa5_jobs(action, '배송지', $scope.vdata.uuid, param, data => {
                if (['A001', 'A006'].includes(pa_shop_cd)) { // 옥션, 지마켓
                  const action_table = {
                    get_shipping_place: 'addr_list', // 출고지
                    get_seller_address: 'deliv_addr_list', // 반품/교환지
                  };
                  const _action = action_table[action];

                  const temp = data.find(({ value }) => value === $scope.shipInfo?.[_action]);
                  if (temp) {
                    $scope.tmp[_action] = temp;
                  } else {
                    // 기존 저장된게 없는 경우, 화면에 기본값 설정된채로 노출
                    $scope.tmp[_action] = data[0];
                    $scope.shipInfo[_action] ??= data[0]?.value;
                  }
                } else if (pa_shop_cd === 'B959') { // 오늘의 집
                  // 출고지, 반품교환지가 하나의 api에서 같이 나옴, 구별되는 값 없음.
                  const action_table = {
                    get_delivery_addr: ['out_addr', 'in_addr'], // 출고지, 반품/교환지
                  };
                  const _actions = action_table[action];

                  for (const _action of _actions) {
                    const temp = data.find(({ id }) => id === $scope.shipInfo?.[_action]);

                    if (temp) {
                      $scope.tmp[_action] = temp;
                    } else {
                      // 기존 저장된게 없는 경우, 화면에 기본값 설정된채로 노출
                      $scope.tmp[_action] = data[0];
                      $scope.shipInfo[_action] ??= data[0]?.value;
                    }
                  }

                } else if (pa_shop_cd === 'B614') { // 홈앤쇼핑
                  $scope.addrList = {
                    scrap_man: [],
                    scrap_man2: []
                  };

                  data.forEach(o => {
                    const scrapManInfo = {
                      code: o.entpManSeq,
                      name: o.entpManName,
                      tel: o.entpManHp,
                      addr: o.addr
                    };

                    // 회수 담당자
                    if (o.entpManGb === '20') {
                      $scope.addrList.scrap_man2.push(scrapManInfo);
                    }
                    // 출고담당자.
                    else if (o.entpManGb === '30') {
                      $scope.addrList.scrap_man.push(scrapManInfo);
                    }

                    // 화면에 기본값 설정된채로 노출
                    $scope.shipInfo.scrap_man ??= $scope.addrList.scrap_man?.[0]?.code;
                    $scope.shipInfo.scrap_man2 ??= $scope.addrList.scrap_man2?.[0]?.code;
                  });
                } else if (pa_shop_cd === 'A077') { // 스마트스토어
                  $scope.addrList = {
                    out_addr: [],
                    in_addr: []
                  };

                  data.forEach(value => {
                    if (value.addressBookType === 'RELEASE') {
                      value.alias = `[출고지] ${value.alias}`;
                      $scope.addrList.out_addr.push(value);
                    } else if (value.addressBookType === 'REFUND_OR_EXCHANGE') {
                      value.alias = `[반품/교환지] ${value.alias}`;
                      $scope.addrList.in_addr.push(value);
                    } else if (value.addressBookType === 'GENERAL') {
                      value.alias = `[일반] ${value.alias}`;
                      $scope.addrList.out_addr.push(value);
                      $scope.addrList.in_addr.push(value);
                    }
                  });

                  // 화면에 기본값 설정된채로 노출
                  $scope.shipInfo.in_addr ??= $scope.addrList.in_addr?.[0]?.id;
                  $scope.shipInfo.out_addr ??= $scope.addrList.out_addr?.[0]?.id;
                } else if (pa_shop_cd === 'A100') { // 롯데아이몰
                  if (param.type === '10') {
                    $scope.pa5_addr_data.addr_out = data;
                  }
                  else if (param.type === '20') {
                    $scope.pa5_addr_data.addr_in = data;
                  }

                  // 화면에 기본값 설정된채로 노출
                  $scope.shipInfo.scrap_delivery_place_in ??= $scope.pa5_addr_data.addr_in?.[0]?.code;
                  $scope.shipInfo.scrap_delivery_place_out ??= $scope.pa5_addr_data.addr_out?.[0]?.code;
                } else if (pa_shop_cd === 'B688' || pa_shop_cd === 'B700') { // 카카오톡 스토어
                  $scope.addrList = {
                    addr_out: [],
                    addr_in: []
                  };

                  data.map(addr => {
                    $scope.addrList.addr_out.push(addr);
                    $scope.addrList.addr_in.push(addr);
                  });

                  // 화면에 기본값 설정된채로 노출
                  if (pa_shop_cd === 'B688') {
                    $scope.shipInfo.scrap_delivery_place_in ??= $scope.addrList.addr_in?.[0]?.id;
                    $scope.shipInfo.scrap_delivery_place_out ??= $scope.addrList.addr_out?.[0]?.id;
                  } else {
                    $scope.shipInfo.address2 ??= $scope.addrList.addr_in?.[0]?.id;
                    $scope.shipInfo.address ??= $scope.addrList.addr_out?.[0]?.id;
                  }
                } else if (['A109', 'P014'].includes(pa_shop_cd)) { // 하프클럽, 보리보리
                  pa5_addr_origin_data = { ...data };
                  $scope.pa5_addr_data = Object.entries(data);
                  $scope.pa5_addr_data.forEach(([k, ]) => {
                    if ($scope.shipInfo[k]) {
                      $scope.setTmp(k, true);
                    }
                  });

                  // 화면에 기본값 설정된채로 노출
                  if ($scope.pa5_addr_data[0]?.[0]) {
                    $scope.shipInfo[$scope.pa5_addr_data[0][0]] ??= $scope.pa5_addr_data[0][1]?.[0]?.value;
                    $scope.setTmp($scope.pa5_addr_data[0][0], true);
                  }
                } else if (pa_shop_cd === 'A112') { // 11번가
                  const addrList = data.data.map(each => ({ ...each, id: each.is_overseas ? `${each.id}|OUT` : each.id }));

                  // 화면에 기본값 설정된채로 노출
                  if (data.data?.length) {
                    $scope.shipInfo[action === 'get_shipping_places' ? 'load_out_addr' : 'load_in_addr'] = addrList[0].id;
                  }

                  return addrList;
                } else if (pa_shop_cd === 'A004') { // CJ 온스타일
                  $scope.shipInfo.key_ZSUPSHIPSEQNO_Cd ??= data[0].ADDR_TP_SEQ;
                  $scope.shipInfo.key_ZRETURNSEQNO_Cd ??= data[0].ADDR_TP_SEQ;
                  $scope.shipInfo.key_ZASSUPSHIPSEQNO_Cd ??= data[0].ADDR_TP_SEQ;
                  $scope.shipInfo.key_ZASRETURNSEQNO_Cd ??= data[0].ADDR_TP_SEQ;
                } else if (['B719', 'A027'].includes(pa_shop_cd)) { // 위메프2.0, 인터파크
                  const addrKey = actionInfo[1] || action;

                  const temp = data.find((o) => o[shopValueKey[pa_shop_cd]] === $scope.shipInfo[addrKey]);

                  if (temp) {
                    $scope.tmp[addrKey] = temp;
                  } else {
                    // 기존 저장된게 없는 경우, 화면에 기본값 설정된채로 노출
                    $scope.tmp[addrKey] = data[0];
                    $scope.shipInfo[addrKey] ??= data[0]?.[shopValueKey[pa_shop_cd]];
                  }
                }

                return data;
              }, () => hideSpinner('deliverySpinner'));

              if (!loadAddrRe) {
                $scope.loadAddrFail = true;
                $scope.shipInfo = {};

                return false;
              } else {
                const isDataEmpty = Array.isArray(loadAddrRe) ? !loadAddrRe.length : !loadAddrRe[Object.keys(loadAddrRe)[0]]?.length;

                if (isDataEmpty) {
                  $scope.loadAddrNone = true;
                  $scope.shipInfo = {};

                  return false;
                } else {
                  $scope.loadAddrNone = false;
                  $scope.loadAddrFail = false;
                }
              }
            }

            hideSpinner('deliverySpinner');
          } catch (err) {
            hideSpinner('deliverySpinner');
            $scope.loadAddrFail = true;

            return false;
          }
        } else {
          // PA4
          const actions = shopActions[pa_shop_cd];

          if (actions) {
            const promises = actions.map(async function (action) {
              const reqBody = getRequestBody(action);
              const no_decrypt = $scope.isChannelAdd || ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd);
              const no_apikeyDecrypt = $scope.isChannelAdd || ($scope.encryptData.apikey.isEdit && $scope.vdata.new_api_enc);

              const { data: etcInfo } = await shopAccountModel.getShopEtcData({ shop_cd: pa_shop_cd });

              // api_key 암호화 관련 처리
              if (etcInfo) {
                let sellerApikey = $scope.vdata.api_enc;

                if (!no_apikeyDecrypt) {
                  sellerApikey = $scope.encryptData.apikey.oldvalue;
                } else if (!$scope.isChannelAdd) {
                  sellerApikey = $scope.vdata.new_api_enc;
                }

                const api_field = Object.keys(etcInfo).find(o => etcInfo[o].api_enc);

                reqBody.job_data.account.apikeyData = {
                  value: sellerApikey,
                  field: api_field,
                  isEncrypt: !no_apikeyDecrypt
                };
              }

              return commonModel.pa4(reqBody, no_decrypt, true)
                .then(function (result) {
                  if (result.data.code === 200) {
                    const re = result.data.data;

                    re.action = action;

                    if (!re.items) { // items가 없으면 alert에 에러메세지 그대로 노출하기 때문에 처리
                      return $q.reject('배송지 로드 실패');
                    }

                    return re;
                  } else { // 데이터를 성공적으로 받아도 code가 200이 아니면 reject
                    return $q.reject('배송지 로드 실패');
                  }
                })
                .catch(function (err) {
                  const errorMessage = typeof err === 'string' ? '배송지 로드 실패' : '로그인 실패';

                  return $q.reject(errorMessage);
                });
            });

            await $q.all(promises)
              .then(function (results) {
                results.forEach(result => {
                  $scope.loadAddrFail = false;
                  // 배송지가 없을경우 처리
                  $scope.loadAddrNone = pa_shop_cd === 'A011' ? !Object.keys(result.items).length : !result.items.length;

                  // playapi 액션명 etc 붙어있을경우 처리 2018-04-19 william
                  if (result.action.indexOf('etc') != -1) {
                    result.action = result.action.replace('etc.', '');
                  }

                  if (pa_shop_cd === 'A524') { // 롯데온
                    result.items = result.items.filter(item => item.useYn === 'Y');

                    $scope.addrList.key_out_addr = [];
                    $scope.addrList.key_in_addr = [];

                    result.items.forEach(item => {
                      switch (item.dvpTypCd) {
                        case '01':
                          $scope.addrList.key_in_addr.push(item);
                          break;
                        case '02':
                          $scope.addrList.key_out_addr.push(item);
                          break;
                      }
                    });

                    // 화면에 기본값 설정된채로 노출
                    $scope.shipInfo.key_in_addr ??= $scope.addrList.key_in_addr[0]?.dvpNo;
                    $scope.shipInfo.key_out_addr ??= $scope.addrList.key_out_addr[0]?.dvpNo;
                  } else {
                    $scope.addrList[result.action] = result.items;

                    // 화면에 기본값 설정된채로 노출
                    if (['B378', 'B851', 'B956'].includes(pa_shop_cd)) {
                      let addrCode = '';
                      switch (pa_shop_cd) {
                        case 'B378': {
                          addrCode = result.action === 'scrap_delivery_place_out' ? 'outboundShippingPlaceCode' : 'returnCenterCode';
                          break;
                        }
                        case 'B851': {
                          addrCode = 'addrSeqno';
                          break;
                        }
                        case 'B956': {
                          addrCode = 'code';
                          break;
                        }
                      }

                      $scope.shipInfo[result.action] ??= $scope.addrList[result.action][0]?.[addrCode];
                    } else if (pa_shop_cd === 'A011') {
                      if (result.action === 'get_visit_addr') {
                        $scope.shipInfo.out_addr ??= Object.keys($scope.addrList.get_visit_addr.ship_cntr)[0];
                        $scope.shipInfo.in_addr ??= Object.keys($scope.addrList.get_visit_addr.ret_cntr)[0];
                      } else {
                        $scope.shipInfo.deliv_point ??= Object.keys($scope.addrList.get_deliv_comp)[0];
                      }
                    } else if (pa_shop_cd === 'A012') {
                      $scope.shipInfo[result.action] ??= Object.keys($scope.addrList[result.action])[0];
                    }

                  }

                  if (!$scope.isChannelAdd) {
                    if (pa_shop_cd === 'A524') {
                      $scope.setTmp('key_out_addr');
                      $scope.setTmp('key_in_addr');
                    } else if (pa_shop_cd === 'B959') {
                      $scope.setTmp('out_addr');
                      $scope.setTmp('in_addr');
                    } else {
                      $scope.setTmp(result.action);
                    }
                  }
                });
                hideSpinner('deliverySpinner');
              })
              .catch(function (err) {
                commonSVC.showMessage('불러오기 실패', err);
                $scope.loadAddrFail = true;
                hideSpinner('deliverySpinner');
              });
          }
        }

      } else {
        commonSVC.showMessage('로그인 확인을 해주세요.');

        return false;
      }
    };

    /**
     * 어드민 로그인 체크
     */
    let limit_data = {};

    $scope.checkLogin = async (action = 'verify_account') => {

      // 직접입력쇼핑몰은 로그인확인 안함
      if (['U000'].includes($scope.vdata.pa_shop_cd)) {
        return true;
      }

      if ($scope.encryptData.password.isEdit && !$scope.vdata.new_pwd) {
        commonSVC.showMessage('비밀번호를 변경하시려는 경우 변경할 비밀번호를 입력해주십시오.');

        return false;
      }

      // 아이디, 패스워드 확인처리 추가 2018-03-21 rony
      const reqBody = getRequestBody(loginActions[pa_shop_cd] || 'check_login');

      if (reqBody.job_data.account.seller_id == '' || reqBody.job_data.account.seller_pw == '') {
        commonSVC.showMessage('아이디, 또는 비밀번호를 입력하여 주십시오.');

        return false;
      }

      if (action === 'verify_account' && !$scope.providerChecked && $scope.isChannelAdd) {
        commonSVC.showMessage('공급업체를 선택해주세요.');

        return false;
      }

      if (pa_shop_cd === 'A077') {
        if (!$scope.vdata.etc4) {
          commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', '필수 항목을 모두 입력하여 주십시오.');

          return false;
        }
        if (($scope.storeType === 'smartstore' && !$scope.vdata.etc2) || ($scope.storeType === 'personal' && !$scope.vdata.etc5)) {
          commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', '스토어URL을 입력해주세요.');

          return false;
        }
        if ($scope.storeType === 'personal' && $scope.vdata.etc5) {
          const regx = new RegExp(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/);

          if (!regx.exec($scope.vdata.etc5)) {
            commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', '스토어URL이 올바르지 않습니다.', '도메인 형식에 맞게 입력하여 주십시오.');

            return false;
          }
        }
      } else if (pa_shop_cd === 'B956' && !$scope.vdata.api_enc) {
        commonSVC.showMessage('파트너 API KEY 값을 입력해주십시오.');

        return false;
      } else if (pa_shop_cd === 'A100' && !$scope.vdata.etc1) {
        commonSVC.showMessage('핸드폰번호를 입력해주십시오.');

        return false;
      } else if (['P012', 'P013', 'P017'].includes(pa_shop_cd) && typeof $scope.vdata.etc2 !== 'boolean') {
        commonSVC.showMessage('셀러유형을 선택해주십시오.');

        return false;
      }

      if ($scope.form1.$valid || action === 'load_accounts') {
        if (action === 'verify_account') {
          showSpinner('loginSpinner', '로그인 확인중');
        }

        const no_decrypt = $scope.isChannelAdd || ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd);
        const no_apikeyDecrypt = $scope.isChannelAdd || ($scope.encryptData.apikey.isEdit && $scope.vdata.new_api_enc);

        $scope.loginChecked = false;

        const { data: etcInfo } = await shopAccountModel.getShopEtcData({ shop_cd: $scope.vdata.pa_shop_cd });

        // 메이크샵의 경우 계정정보 저장 시 게시판 정보도 필요
        if (pa_shop_cd === 'B118') {
          etcInfo.etc5 = {};
          etcInfo.etc6 = {};
          etcInfo.etc5.field = 'scrap_inquiry';
          etcInfo.etc6.field = 'scrap_comment';
        }

        // PA5 로그인체크
        if (loginURLs_pa5.includes(pa_shop_cd)) {
          try {
            const pa_cd = siteInfo[pa_shop_cd].playapi_shop_cd;
            const pa = {
              shop_id: `${userInfo.user.sol_no}`,
              market_code: pa_cd,
              market_username: reqBody.job_data.account.seller_id,
              id: $scope.vdata.uuid?.split('|')[0] || '',
              etc: $scope.vdata.uuidEtc || {}
            };

            if (no_decrypt) {
              pa.no_decrypt = reqBody.job_data.account.seller_pw;
            } else {
              pa.market_password = reqBody.job_data.account.seller_pw;
            }

            // pa5 로그인체크시에는 etc값을 치환해서 넘겨줌
            if (etcInfo) {
              Object.keys(etcInfo).forEach(k => {
                // apikey 암호화 관련 처리
                if (etcInfo[k].api_enc) {
                  let sellerApikey = $scope.vdata.api_enc;

                  if (!no_apikeyDecrypt) {
                    sellerApikey = $scope.encryptData.apikey.oldvalue;
                  } else if (!$scope.isChannelAdd) {
                    sellerApikey = $scope.vdata.new_api_enc;
                  }

                  pa.apikeyData = {
                    value: sellerApikey,
                    field: etcInfo[k].field,
                    isEncrypt: !no_apikeyDecrypt
                  };
                } else if (etcInfo[k].field) {
                  pa.etc[etcInfo[k].field] = reqBody.job_data.account[k];
                }
              });
            }

            // 카페24
            if (pa_shop_cd === 'B616') {
              pa.market_username = reqBody.job_data.account.etc1; // 부운영자 아이디
              pa.etc.mall_id = reqBody.job_data.account.seller_id; // 상점 아이디
            }

            const data = await commonModel.pa5(`/channel_accounts/${action}`, pa);
            const er = data.data;

            if (er.engine_result) {
              if (er.engine_result.has_error) {
                let errorMessage = er.engine_result.exception_message || er.engine_result.results?.[0].site_error || '알수없는 오류로 실패하였습니다. 잠시 후 재시도 해주세요.';

                if (['A001', 'A006'].includes(pa_shop_cd) && errorMessage.includes('ESM 어드민에 접속하여 보호조치를 해제')) {
                  errorMessage = `${errorMessage} <br/> <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=13343" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>`;
                }

                commonSVC.showMessageHtml($scope.isChannelAdd ? '쇼핑몰 등록 실패' : action === 'load_accounts' ? '공급업체 로드 실패' : '쇼핑몰 수정 실패', errorMessage);

                return false;
              } else {
                $scope.loginChecked = true;

                if (action === 'load_accounts') {
                  return er.engine_result.results[0].data;
                }

                $scope.vdata.uuid = er.engine_result.channel_account_id;

                // 배송처 받기위해 임시로 웹소켓 연결
                workSVC.pa5_job_connect($scope.vdata.uuid);

                return true;
              }
            } else {
              throw Error();
            }
          } catch (err) {
            if (err.status == 400) {
              switch (err.data.error) {
                case 'Bad Request':
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : action === 'load_accounts' ? '공급업체 로드 실패' : '쇼핑몰 수정 실패', err.data.message);
                  break;
                default:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : action === 'load_accounts' ? '공급업체 로드 실패' : '쇼핑몰 수정 실패', '입력하신 계정정보로 로그인이 불가능합니다.\n쇼핑몰 판매자페이지에서 로그인정보를 확인해주시기 바랍니다.');
                  break;
              }
            }
          } finally {
            if (action === 'verify_account') {
              hideSpinner('loginSpinner');
            }

            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }
          }
        } else {
          // api_key 암호화 관련 처리
          if (etcInfo) {
            let sellerApikey = $scope.vdata.api_enc;

            if (!no_apikeyDecrypt) {
              sellerApikey = $scope.encryptData.apikey.oldvalue;
            } else if (!$scope.isChannelAdd) {
              sellerApikey = $scope.vdata.new_api_enc;
            }

            const api_field = Object.keys(etcInfo).find(o => etcInfo[o].api_enc);

            reqBody.job_data.account.apikeyData = {
              value: sellerApikey,
              field: etcInfo[api_field]?.field,
              isEncrypt: !no_apikeyDecrypt
            };
          }

          return commonModel.pa4(reqBody, no_decrypt, true)
            .then(function(data) {
              if (data.data.data.status == 200) {
                if (pa_shop_cd === 'A524' && data.data.data.items && data.data.data.items.statusCode) {
                  throw data.data.data.items.statusCode;
                }

                hideSpinner('loginSpinner');
                $scope.loginChecked = true;

                return true;
              } else {
                throw data.data.data.status;
              }
            })
            .catch(function(err) {
              hideSpinner('loginSpinner');
              $scope.loginChecked = false;

              switch (err) {
                case 4001:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('비밀번호가 틀렸습니다. 비밀번호를 확인해주시기 바랍니다.'));
                  break;
                case 4002:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('판매자 계정이 아닙니다. 계정을 확인해주시기 바랍니다.'));
                  break;
                case 4003:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('공급업체를 먼저 확인해주시기 바랍니다.'));
                  break;
                case 4008:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('로그인 불가, 인터파크 판매자페이지에 직접 접속하여 확인바랍니다.'));
                  break;
                case 4012:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('복수 스토어 계정은 연동 스토어 여부를 Y으로 변경한 뒤 연동 스토어를 선택해 주세요.'));
                  break;
                case 5010:
                case 5011:
                case 5012:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('API ID를 재확인하시어 입력해 주시기 바랍니다.\n경로 : 스마트스토어 쇼핑파트너 센터 > 스토어 전시관리 > 스토어 관리 > API 정보\n또는 도움말을 참고하시어 API ID를 재확인해 주시기 바랍니다.'));
                  break;
                case 5013:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('쇼핑몰 보안 정책에 의한 로그인 확인 실패.\n보호조치를 해제 하신 후 다시 시도해 주시기 바랍니다.'));
                  break;
                case 5014:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('없는 계정이거나, 비밀번호가 틀립니다.\nID/PW 를 확인해주시기 바랍니다.'));
                  break;
                case 'limit_error':
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString(`이용중인 버전에서 사용할 수 있는 쇼핑몰 ID 수가 부족합니다. \n추가 사용을 원하실 경우  이용버전을 업그레이드 하시기 바랍니다.\n- 현재 버전 ${userInfo.user.sol_version} : 쇼핑몰 ID - ${limit_data.lim}개 사용 가능`));
                  break;
                default:
                  commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', gettextCatalog.getString('입력하신 계정정보로 로그인이 불가능합니다.\n쇼핑몰 판매자페이지에서 로그인정보를 확인해주시기 바랍니다.'));
                  break;
              }
            });
        }
      } else {
        if (['A001', 'A006'].includes($stateParams.pa_shop_cd)) {
          if ($scope.useEsmMasterId === 'Y' && !$scope.vdata.etc7) {
            $scope.checkEsmId = false;
          }
        }
        if ($stateParams.pa_shop_cd === 'B378' && $scope.vdata.etc6 && $scope.vdata.etc7 === 'N') {
          $scope.vdata.checkGrowth = false;
        }

        commonSVC.showMessage(gettextCatalog.getString('필수 항목을 확인하여 주시길 바랍니다.'));
      }
    };

    // 로딩스피너 보이기
    function showSpinner(id, text) {
      $(`#${id} > div`).html(`<i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i><br />${text}`);
      $(`#${id}`).removeClass('hidden');
    }

    // 로딩스피너 숨기기
    function hideSpinner(id) {
      $(`#${id}`).addClass('hidden');
    }

    /**
     * 공급업체 로드 (인터파크MD)
     */
    $scope.loadProvider = function (init) {
      if ($scope.vdata.shop_id) {
        showSpinner('loginSpinner', '공급업체 로드중');
        const reqBody = getRequestBody('load_accounts');
        const no_decrypt = $scope.isChannelAdd || ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd);

        return commonModel.pa4(reqBody, no_decrypt)
          .then(function (re) {
            if (re.data.data.status == 200) {
              $scope.providerList = re.data.data.items;
              $scope.providerChecked = $scope.providerList.length === 0;

              // 공급업체 로드시 해당 값 담아 주어야 함.
              // watch 로 etc1 을 보고잇지만 저장된 정보 로딩시 etc1 처리가 먼저 돌기 때문에 공급업체코드가 빈값으로 저장되는 문제가 있음 2018-07-30 rony
              if ($scope.vdata.etc1 !== '' && $scope.providerList.length) {
                const p = $scope.providerList[$scope.vdata.etc1];

                $scope.vdata.etc2 = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
                $scope.vdata.shop_id_sub = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
                // init에서 호출한게 아닐때에만 로그인확인 false처리
                if (!init) {
                  $scope.loginChecked = false;
                }
                $scope.providerChecked = true;
              }
            }
            // 2018-05-04 response 에러메세지 바로 보여주도록 변경
            // else if (re.data.data.status == 4001) {
            //   $scope.loginChecked = false;
            //   commonSVC.showMessage(gettextCatalog.getString("로그인 실패"), gettextCatalog.getString("비밀번호가 틀렸습니다. 비밀번호를 확인해주시기 바랍니다."));
            // } else if (re.data.data.status == 4002) {
            //   $scope.loginChecked = false;
            //   commonSVC.showMessage(gettextCatalog.getString("로그인 실패"), gettextCatalog.getString("판매자 계정이 아닙니다. 계정을 확인해주시기 바랍니다."));
            // } else if (re.data.data.status == 4003) {
            //   $scope.loginChecked = false;
            //   commonSVC.showMessage(gettextCatalog.getString("로그인 실패"), gettextCatalog.getString("공급업체를 먼저 확인해주시기 바랍니다."));
            // } else if (re.data.data.status == 4004) {
            //   $scope.loginChecked = false;
            //   commonSVC.showMessage(gettextCatalog.getString("로그인 실패"), gettextCatalog.getString("해당 공급업체로 로그인할 수 없습니다."));
            // } else if (re.data.data.status.toString().includes(500)) {
            //   $scope.loginChecked = false;
            //   commonSVC.showToaster("error", "실패", "인터파크에서 일시적 오류가 발생했습니다.\n다시 시도해주시기 바랍니다.")
            // }
            else {
              $scope.loginChecked = false;
              const msg = re.data.data.message || '공급업체 로딩에 실패했습니다.';

              commonSVC.showMessage(gettextCatalog.getString('로그인 실패'), msg);
            }
            hideSpinner('loginSpinner');
            if (init) {
              $scope.loadAddrList();
              $timeout(() => {});
            }
          })
          .catch(function () {
            $scope.loginChecked = false;
            commonSVC.showToaster('error', '실패', '공급업체 로드에 실패했습니다.');
          });

      } else {
        commonSVC.showMessage('아이디/비밀번호를 입력하세요.');

        return false;
      }
    };

    /**
     * 공급업체 로드 (인터파크-오픈마켓)
     */
    $scope.loadProviderOM = async (init) => {
      if ($scope.encryptData.password.isEdit && !$scope.vdata.new_pwd) {
        commonSVC.showMessage('비밀번호를 입력해주세요.');

        return false;
      }

      try {
        showSpinner('loginSpinner', '공급업체 로드중');

        const re = await $scope.checkLogin('load_accounts');

        if (!re) {
          $scope.loginChecked = false;

          return false;
        }

        $scope.providerList = re;
        $scope.providerChecked = $scope.providerList.length === 0;

        // 공급업체 로드시 해당 값 담아 주어야 함.
        // watch 로 etc1 을 보고잇지만 저장된 정보 로딩시 etc1 처리가 먼저 돌기 때문에 공급업체코드가 빈값으로 저장되는 문제가 있음 2018-07-30 rony
        if ($scope.vdata.etc1 !== '' && $scope.providerList.length) {
          const p = $scope.providerList[$scope.vdata.etc1];

          $scope.vdata.etc2 = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;
          $scope.vdata.shop_id_sub = `${p.supplyCtrtNm}^^^${p.contractStatus}^^^${p.supplyCtrtSeq}`;

          $scope.vdata.shop_info = { company: p };
          $scope.vdata.uuidEtc = { company: p };

          // init에서 호출한게 아닐때에만 로그인확인 false처리
          if (!init) {
            $scope.loginChecked = false;
          }
          $scope.providerChecked = true;
        }

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }

        return true;
      } catch (err) {
        $scope.loginChecked = false;
        commonSVC.showToaster('error', '실패', '공급업체 로드에 실패했습니다.');

        return false;
      } finally {
        hideSpinner('loginSpinner');
      }
    };

    /**
     * 계정 삭제
     */
    $scope.channelDel = function() {
      commonSVC.showConfirm('삭제하시겠습니까?', '계정 삭제 시 상품, 주문, 문의 등 해당 계정의 모든 정보가 삭제되며 복구가 불가능하므로 주의바랍니다. \n- "출고완료" 이후 (출고완료/배송중/구매결정/배송완료) 주문은 삭제되지 않습니다. \n- 계정 삭제 후 솔루션을 재로그인하여 확인해주시기 바랍니다.', function () {
        const param = {
          shop_cd: $scope.vdata.shop_cd,
          pa_shop_cd: $scope.vdata.pa_shop_cd,
          shop_id: $scope.vdata.shop_id,
          shop_id_sub: $scope.vdata.shop_id_sub,
          seller_nick: $scope.vdata.seller_nick,
          uuid: $scope.vdata.uuid
        };

        shopAccountModel.delete(param, function(state) {
          if (state != 'success') {
            commonSVC.showToaster('error', '', gettextCatalog.getString('쇼핑몰 계정 삭제에 실패하였습니다.'));
          } else {
            commonSVC.showToaster('success', '', gettextCatalog.getString('쇼핑몰 계정이 삭제 되었습니다.'));
            $scope.resetForm();
            // 사용중 쇼핑몰 정보 불러오기
            // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
            $timeout(function() {
              $scope.reRoadUseChannel('delete');
              $scope.shopAccountNewAddList();
            }, 500);
          }
        });
      });
    };

    /**
     * 쇼핑몰계정 저장/수정 버튼
     */
    $scope.onSubmit = async function() {
      showSpinner('saveSpinner', `계정 ${$scope.isChannelAdd ? '등록' : '수정'} 진행중`);
      const re = await $scope.checkLogin();

      if (!re) {
        return false;
      }

      if ($scope.form1.$valid) {
        if (pa_shop_cd == 'A077') {
          if ($scope.storeType === 'smartstore') {
            $scope.vdata.etc5 = '';
          } else if ($scope.storeType === 'personal') {
            $scope.vdata.etc2 = '';
          }
        }

        // 메이크샵 게시판 선택
        if (pa_shop_cd == 'B118') {
          if (!$scope.shipInfo?.inquiry_board_list) {
            $scope.shipInfo.inquiry_board_list = [];
          }
          if (!$scope.shipInfo?.comment_board_list) {
            $scope.shipInfo.comment_board_list = [];
          }

          $scope.vdata.etc5 = $scope.shipInfo.inquiry_board_list.map(board => board.code) || [];
          $scope.vdata.etc6 = $scope.shipInfo.comment_board_list.map(board => board.code) || [];

          /**
           * 로그인 확인 한번 더해줘야함(로그인 확인에 shipInfo를 초기화 하는 부분이 있음)
           * etc 치환하는 부분만 가져다 쓰기에는 너무 많은 로직이 중복됨...
           */
          const ship_info = $scope.shipInfo;
          await $scope.checkLogin();
          $scope.shipInfo = ship_info;
          commonSVC.showMessage('게시판 정보 저장 성공', '게시판 정보 저장에 성공하였습니다. \n 계정 정보를 변경하셨다면 변경된 내용으로 다시 저장됩니다.');
        }

        // 직접입력 쇼핑몰 저장시 지원중인 쇼핑몰명과 중복되는 이름 사용불가 처리
        if ($scope.vdata.pa_shop_cd === 'U000') {
          if ($scope.add_list.some(shop => shop.shop_type !== 'ORD_M' && shop.shop_name === $scope.vdata.shop_name)) {
            hideSpinner('saveSpinner');
            commonSVC.showMessage('2.0에서 지원중인 쇼핑몰입니다.\n쇼핑몰명을 검색하여 이용해주시기 바랍니다.');

            return false;
          }
        }

        $scope.isChannelAdd ? $scope.regist() : $scope.save();
      }
    };

    /**
     * 쇼핑몰계정 신규추가시 추가버튼
     */
    $scope.regist = async () => {
      try {
        // TODO: 어뷰징 계정 등록 임시 차단 => 정책 수정되면 해당 처리 제거 필요
        if ($scope.vdata.shop_cd === 'A077' && $scope.vdata.shop_id === 'owwuie497031@naver.com') {
          throw 'abusing';
        }

        // 버전별 쇼핑몰 ID수 제한 확인
        const idLimitRe = await shopAccountModel.checkLimit();
        limit_data = idLimitRe.data.results;

        if (limit_data.lim != '무제한' && parseInt(limit_data.total) >= parseInt(limit_data.lim)) {
          hideSpinner('saveSpinner');
          throw 'limit_error';
        }

        const isDup = await checkDup();

        if (!isDup) {
          hideSpinner('saveSpinner');

          return false;
        }

        $scope.vdata.ship_info_default = $scope.shipInfo ? JSON.stringify($scope.shipInfo) : '{}';

        if (['A032'].includes($scope.vdata.shop_cd)) {
          $scope.vdata.uuidEtc = { api_access_key: $scope.vdata.etc1 };
        }

        const re = await shopAccountModel.insert($scope.vdata);

        const shopInfo = {
          shop_cd: $scope.vdata.shop_cd,
          shop_id: $scope.vdata.shop_id,
        };

        if (re.status === 200) {
          hideSpinner('saveSpinner');
          commonSVC.showToaster('success', '', gettextCatalog.getString('계정이 추가 되었습니다.'));

          // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
          $timeout(function() {
            $scope.reRoadUseChannel('add');
            init();
          }, 500);

          if ($scope.isFirstShopRegist) {
            // first_add_shop_cds에 저장된 다음 shop_cd
            const selectedShopCds = userInfo.user.first_add_shop_cds.split(',').filter(Boolean);
            const targetShopCd = selectedShopCds.find(shop => ![$scope.vdata.shop_cd, ...$scope.use_engines.map(s => s.pa_shop_cd)].includes(shop));

            if (targetShopCd) {
              // TODO : $scope.isLG 변수 추후 스케일업 대상자 변수 생성 된 후 변경
              if ($scope.isLG) {
                commonSVC.showMessageHtml('쇼핑몰 등록 완료', '이제 상품 가져오기, 주문 수집 등의 연동이 가능합니다.', () => {
                  $scope.$parent.addMall(null, targetShopCd);
                }, {
                  confirmButtonText: '다음 쇼핑몰 계정 등록하기',
                  showCancelButton: true
                });
              } else {
                commonSVC.showConfirmCustom(
                  {
                    title: '쇼핑몰 등록 완료',
                    text: '',
                    confirmButtonText: '다음 쇼핑몰 계정 등록하기',
                    showCancelButton: true,
                    cancelButtonText: '취소',
                  },
                  async function (Confirm) {
                    if (Confirm) {
                      $scope.$parent.addMall(null, targetShopCd);
                    } else {
                      const modal = await commonSVC.openModal('sg', { addClass: 'add-mall-modal' }, 'AutoSettingCtrl', 'views/settings/shopAccount/modals/autoSettingModal.html');

                      modal.result.then((re) => {
                        if (re === 'AutoSetting') {
                          $scope.autoSetting();
                        } else if (re === 'AddMall') {
                          $scope.$parent.addMall(null, targetShopCd);
                        }
                      }).catch(() => {
                        $rootScope.cantChangeTab = false;
                        localStorageService.set(`isAutoSetting_${userInfo.user.sol_no}`, true);
                      });
                    }
                  });
              }
            } else {
              if ($scope.isLG) {
                commonSVC.showMessageHtml('쇼핑몰 등록 완료', '이제 상품 가져오기, 주문 수집 등의 연동이 가능합니다.', () => {
                  $state.go('main.prodScrap_match_list');
                }, {
                  confirmButtonText: '쇼핑몰 상품 가져오기 메뉴로 이동',
                  showCancelButton: true
                });
              } else {
                commonSVC.showConfirmCustom(
                  {
                    title: '쇼핑몰 계정 등록 완료',
                    text: '쇼핑몰에서 판매 중인 상품, 주문, 문의 데이터를 플레이오토가 대신 수집하여 설정해 드립니다.<br><p p class="no-padding help-text text-primary-600">* 작업이 완료되면 회원가입 시 입력한 정보로 카카오 알림톡과 이메일을 발송해드립니다.</p>',
                    confirmButtonText: '확인',
                    showCancelButton: false,
                    isHtml: true,
                  },
                  async function (Confirm) {
                    if (Confirm) {
                      $scope.autoSetting();
                    }
                  });
              }
            }

            return;
          } else if (userInfo.user.auth_type === '총괄관리자') {
            const msg = `솔루션 사용중에 추가된 쇼핑몰 계정은 마스터(관리자) 계정으로 로그인 후<br>
              각 사용자의 권한 설정 메뉴에서 채널별관리 권한을 부여하신 후 노출됩니다.<br>
              <div class="pt-10 text-grey-300">
                * 마스터 및 총괄관리자 계정은 권한설정 불필요<br>
                * 사용자 계정에 "관리자" 권한 부여되어 있을 시 권한설정 불필요
              </div>
            `;

            const re = await commonSVC.showConfirmCustom({
              title: '추가된 쇼핑몰의 관리 권한 설정 안내',
              text: msg,
              confirmButtonText: '사용자 관리로 이동',
              showCancelButton: true,
              html: true
            });

            if (re) {
              $state.go('main.configs_user');

              return;
            }
          } else {
            const msg = `솔루션 사용중에 추가된 쇼핑몰 계정은<br>
              채널별 관리 권한이 설정되어야 솔루션에 노출됩니다.<br>
              귀사의 마스터 권한이 있는 담당자에게 권한 설정을 요청 하신 후 이용해주세요.<br>
              - 경로 : 마스터 및 총괄관리자 계정으로 로그인>설정>사용자관리>각 계정의 상세>권한설정 탭>채널별관리
              <div class="pt-10 text-grey-300">
                * "관리자" 권한이 부여되어 있는 사용자 계정 일시 권한 설정 요청 불필요
              </div>
            `;

            await commonSVC.showMessageHtml('추가된 쇼핑몰의 관리 권한 설정 안내', msg, null);
          }

          await commonSVC.showMessage('쇼핑몰 등록 완료', $scope.vdata.pa_shop_cd !== 'U000' ? '이제 상품 가져오기, 주문 수집 등의 연동이 가능합니다.' : '이제 주문 엑셀 등록 등의 관리가 가능합니다.');

          $scope.editMall(null, shopInfo);
        } else {
          hideSpinner('saveSpinner');
          throw '계정추가 실패';
        }
      } catch (err) {

        // 계정 추가 실패시 uuid 초기화
        // 계정추가시에는 uuid 가 비어있어야 하는데.. 이미 등록된 계정으로 조회후 다시 새로운 계정으로 추가할때 기존 계정의 uuid 가 초기화 되지않고 남아있어 문제됨.
        if ($scope.isChannelAdd && loginURLs_pa5.includes(pa_shop_cd)) {
          $scope.vdata.uuid = '';
        }

        hideSpinner('loginSpinner');
        hideSpinner('saveSpinner');
        if (err?.data?.error_code === 'e1013') {
          commonSVC.showMessage($scope.isChannelAdd ? '쇼핑몰 등록 실패' : '쇼핑몰 수정 실패', '이미 등록된 계정입니다.');
        } else if (err?.data?.error_code === 'e9999') {
          commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString(err?.data?.message));
        } else if (err === 'limit_error') {
          let errMsg = `이용중인 버전에서 사용할 수 있는 쇼핑몰 ID 수가 부족합니다. \n추가 사용을 원하실 경우  이용버전을 업그레이드 하시기 바랍니다.\n- 현재 버전 ${userInfo.user.sol_version} : 쇼핑몰 ID - ${limit_data.lim}개 사용 가능`;

          // VIP 버전 쇼핑몰 계정 100 개 초과시 에러메세지 변경.
          if (userInfo.user.sol_version === 'VIP') {
            errMsg = 'PLAYAUTO 2.0에 등록할 수 있는 쇼핑몰 계정은 100 개를 초과할수 없습니다.';
          }

          commonSVC.showMessage('실패', errMsg);
        } else if (err === 'abusing') {
          commonSVC.showMessage('실패', '비정상적인 접근으로 인해 계정 등록이 차단되었습니다.');
        } else if (err?.data) {
          let msg = err.data.message;
          if (err.data.message == '(오류) kakao-store API 키 등록 실패:: 이미 매핑된 대행사가 있습니다.') {
            msg = 'kakao-store API 키 등록 실패  : 이미 등록된 API 키 입니다.';
          }
          commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString(msg));
        } else {
          const msg = '계정 추가에 실패했습니다.';

          commonSVC.showMessage(gettextCatalog.getString('계정 추가 실패'), gettextCatalog.getString(msg));
        }
      }
    };

    /**
     * 계정 중복검사
     */
    const checkDup = async () => {
      $scope.vdata.etc7 = $scope.vdata.etc7 ? $scope.vdata.etc7 : '';

      const params = {
        pa_shop_cd: $scope.vdata.pa_shop_cd,
        shop_id: $scope.vdata.shop_id,
        shop_id_sub: $scope.vdata.shop_id_sub,
        etc1: $scope.vdata.etc1,
        etc7: $scope.vdata.etc7,
        shop_name: $scope.vdata.shop_name
      };

      if (params.pa_shop_cd === 'B009') {
        params.shop_cd = $scope.vdata.shop_cd;
      } else if (['B688', 'B700'].includes(params.pa_shop_cd)) {
        // 카카오 선물하기, 카카오톡 스토어 복수스토어 중복 확인용 처리
        params.api_enc = $scope.vdata.api_enc;
      } else if (shop_cd === 'P059') {
        params.shop_cd = [shop_cd];
      }

      try {
        const re = await shopAccountModel.checkDuplicate(params);

        if (re.data.results.state) {
          if ($scope.vdata.shop_cd !== 'P059') {
            $scope.vdata.shop_cd = re.data.results.shop_cd || $scope.vdata.pa_shop_cd;
          }

          // 직접입력 쇼핑몰
          if ($scope.vdata.pa_shop_cd === 'U000' && re.data.results.shop_cd) {
            $scope.vdata.pa_shop_cd = $scope.vdata.shop_cd;
          }

          return true;
        } else {
          if (['B851', 'B956'].includes(pa_shop_cd)) {
            commonSVC.showMessageHtml('<span style="font-size: 20px;">티몬[오픈마켓], 티몬[특가] 계정 중복 오류</span>',
              '<div style="font-size: 15px">티몬 오픈마켓과 특가 채널의 ID가 동일한 경우 주문이 중복으로 수집 될 수 있습니다.'
              + '<br>티몬(오픈마켓) 계정으로 등록하시면 티몬(특가)의 주문까지 수집할 수 있습니다.'
              + '<br>따라서 티몬(오픈마켓) 계정만 추가하여 관리해 주세요.<br><br>'
              + '<ul style="font-size:13px;">'
              + '<li>티몬(특가) 계정을 먼저 연동하셨을 경우에는 특가계정을 삭제해 주신 후 오픈마켓 계정을 추가해 주시기 바랍니다.</li>'
              + '<li>[출고완료] 이후 상태의 주문건은 계정 삭제 시에도 유지됩니다.</li>'
              + '<li>단, 고객문의 내역 보관이 필요하신 경우 계정 삭제 전 문의관리 메뉴에서 엑셀 다운로드가 가능합니다.</li>'
              + '</ul>'
              + '</div>',
            );
          } else if (shop_cd === 'P059') {
            commonSVC.showMessage('계정 중복 오류', '메이크샵(SSO) 쇼핑몰은 하나만 등록 가능합니다.');
          } else {
            commonSVC.showToaster('error', '', gettextCatalog.getString('중복된 계정입니다.'));
          }

          return false;
        }
      } catch (err) {
        if (err?.data?.messages[0] === 'Duplicate Shop Account') {
          commonSVC.showMessageHtml('쇼핑몰 계정 관리 안내', `
            다른 마스터 계정에 등록된 쇼핑몰 ID입니다.<br/>
            Zero 버전에서는 동일한 쇼핑몰 ID는 추가 및 관리가 불가합니다.
            <ul>
              <li>상위 버전으로 업그레이드</li>
              <li>기등록된 쇼핑몰 ID 삭제 후 추가</li>
            </ul>`);
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('중복검사 실패.'));
        }

        return false;
      }
    };

    /**
     * 쇼핑몰계정 수정시 저장버튼
     */
    $scope.save = function () {
      //수정의 경우도 카카오스토어 API키 확인
      if (pa_shop_cd === 'B009') {
        if ($scope.vdata.etc === '') {
          commonSVC.showMessage('실패', '채널을 선택해주세요.');
          hideSpinner('saveSpinner');

          return false;
        }
      }

      update();
    };

    //수정
    async function update() {
      $scope.vdata.ship_info_default = $scope.shipInfo ? JSON.stringify($scope.shipInfo) : '{}';

      // 비밀번호 수정을 하는경우 처리.
      if ($scope.encryptData.password.isEdit) {
        if (!$scope.vdata.new_pwd) {
          commonSVC.showMessage('비밀번호를 변경하시려는 경우 변경할 비밀번호를 입력해주십시오.');

          return false;
        } else {
          $scope.vdata.shop_pwd = $scope.vdata.new_pwd;
        }
      } else {
        $scope.vdata.shop_pwd = '';
      }

      // apikey 수정하는경우
      if ($scope.encryptData.apikey.isEdit) {
        if (!$scope.vdata.new_api_enc) {
          commonSVC.showMessage('API KEY를 변경하시려는 경우 변경할 정보를 입력해주십시오.');

          return false;
        } else {
          $scope.vdata.api_enc = $scope.vdata.new_api_enc;
        }
      } else {
        $scope.vdata.api_enc = '';
      }

      if (['B719', 'A032'].includes($scope.vdata.shop_cd)) {
        $scope.vdata.uuidEtc = { api_access_key: $scope.vdata.etc1 };
      } else if ($scope.vdata.shop_cd === 'B378') {
        $scope.vdata.uuidEtc = {
          api_access_key: $scope.vdata.etc1,
          api_secret_key: $scope.vdata.etc2,
          api_etc_key: $scope.vdata.etc3
        };
      }

      try {
        const re = await shopAccountModel.update($scope.vdata);

        if (re.data.results === 'success') {
          hideSpinner('saveSpinner');
          commonSVC.showMessage('쇼핑몰 수정 완료');

          // 계정정보 변경시 로컬스토리지에 저장된 셋폼토큰 재발급 필요하므로 제거
          localStorage.removeItem(`gmp_setForm_token_${$scope.vdata.shop_cd}_${$scope.vdata.shop_id}_${$scope.sol_no}`);

          // pdb_s 에서 조회되기까지 시간이 걸리므로 0.5 초 딜레이 2018-07-02 rony
          $timeout(async function() {
            // 저장 시 복수 스토어 이용여부 초기화
            $scope.reRoadUseChannel('update');

            // 직접입력 쇼핑몰이면 배송처 로드 안함
            if (!pa_shop_cd.startsWith('U')) {
              $scope.shipInfo = { ...origin_shipInfo };
              await $scope.loadAddrList();
            }
          }, 500);
        }
      } catch (err) {
        hideSpinner('saveSpinner');
        if (err?.data?.error_code === 'e9999') {
          commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString(err?.data?.message));
        } else {
          commonSVC.showMessage('쇼핑몰 수정 실패', err?.data?.message === 'APIKEY ERROR' ? '동일 ID에서 사용중인 API 인증키입니다.' : '일시적인 오류입니다.\n계속 실패한다면 잠시 후 다시 시도해주세요.');
        }
      }
    }

    /**
     * 기본 배송지 설정 저장
     */
    $scope.saveShipInfo = async () => {
      $scope.vdata.ship_info_default = $scope.shipInfo ? JSON.stringify($scope.shipInfo) : '{}';
      $scope.vdata.updateShipInfo = true;

      try {
        const re = await shopAccountModel.update($scope.vdata);

        if (re.data.results === 'success') {
          // 계정정보 변경시 로컬스토리지에 저장된 셋폼토큰 재발급 필요하므로 제거
          localStorage.removeItem(`gmp_setForm_token_${$scope.vdata.shop_cd}_${$scope.vdata.shop_id}_${$scope.sol_no}`);
          origin_shipInfo = JSON.parse($scope.vdata.ship_info_default);

          commonSVC.showMessage('기본 배송지 설정 완료', '해당 쇼핑몰의 상품 템플릿을 만들 때 배송정보가 자동으로 반영됩니다.');
        }
      } catch (err) {
        commonSVC.showMessage('기본 배송지 설정 실패', '일시적인 오류입니다.\n계속 실패한다면 잠시 후 다시 시도해주세요.');
      }
    };

    /**
     * 기본 배송지 설정 해제
     */
    $scope.delShipInfo = async () => {
      const confirm = await commonSVC.showConfirmCustom({
        title: '설정 해제하시겠습니까?',
        text: '해제하시면 해당 쇼핑몰의 상품 템플릿을 만들 때,\n배송정보가 자동 반영되지 않습니다.',
        confirmButtonText: '해제',
        showCancelButton: true
      });

      if (!confirm) {
        return false;
      }

      $scope.vdata.ship_info_default = '{}';
      $scope.vdata.updateShipInfo = true;

      try {
        const re = await shopAccountModel.update($scope.vdata);

        if (re.data.results === 'success') {
          // 계정정보 변경시 로컬스토리지에 저장된 셋폼토큰 재발급 필요하므로 제거
          localStorage.removeItem(`gmp_setForm_token_${$scope.vdata.shop_cd}_${$scope.vdata.shop_id}_${$scope.sol_no}`);
          origin_shipInfo = $scope.shipInfo = {};
          $timeout(() => {});

          $scope.loadAddrFail = true;
          commonSVC.showMessage('기본 배송지 설정 해제 완료');
        }
      } catch (err) {
        commonSVC.showMessage('기본 배송지 설정 해제 실패', '일시적인 오류입니다.\n계속 실패한다면 잠시 후 다시 시도해주세요.');
      }
    };

    /**
     * 판매자센터 로그인페이지 오픈
     */
    $scope.openAdmin = () => {
      let adminUrl = $scope.$parent.params?.admin_url;

      $scope.$parent.params?.admin_url.match(/{{\w+}}/g)?.forEach(val => {
        adminUrl = adminUrl.replace(val, $scope.$parent.params[val.replace(/[{}]/g, '')]);
      });

      window.open(adminUrl, '_blank');
    };

    /**
     * 카페24 인증페이지 팝업띄움
     * 2019-05-20 rony
     */
    $scope.getAuthCafe24 = function () {
      const x = screen.width / 2 - 800 / 2;
      const y = screen.height / 2 - 700 / 2;

      window.localStorage.setItem('cafe24Token', '');
      // $window.open('http://www.playauto.co.kr/cafe24auth.php?siteid=' + $scope.vdata.etc1, 'getAuth_cate24', 'width=1000,height=700,left=' + x + ',top=' + y);
      $window.open(`https://${$scope.vdata.etc1}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=ggenYRwSXCbUfELCW6GYjL&state=${$scope.vdata.etc1}&redirect_uri=https://pstaging.playauto.io/action.html&scope=mall.read_category,mall.read_application,mall.write_application,mall.write_product,mall.read_collection,mall.read_supply,mall.read_product,mall.read_store`, 'getAuth_cate24', `width=1000,height=700,left=${x},top=${y}`);

      const tokenTimer = setInterval(function () {
        if (window.localStorage.getItem('cafe24Token') !== '') {
          clearInterval(tokenTimer);
          $scope.vdata.etc3 = window.localStorage.getItem('cafe24Token');
        }
      }, 1500);
    };

    /**
     * (pa5 용) new 출하지/배송지 선택시 하단 정보 셋팅
     * pa5는 setTmp 가 잘 동작 안하는거 같은데, 다른 곳도 쓰고 있어서 냅두고 새로 만듬
     */
    $scope.setPa5Tmp = function (action, addrKey) {
      let temp;
      if (pa_shop_cd === 'B959') {
        const action_table = {
          get_delivery_addr: ['out_addr', 'in_addr']
        };

        for (const _actions of action_table[action]) {
          temp = $scope.pa5_job_data[action]?.data.find(({ id }) => id === $scope.shipInfo?.[_actions]) ?? {};

          if (temp) {
            $scope.tmp[_actions] = temp;
          }
        }

        return;
      }

      let _action = action;

      // 옥션, 지마켓 (pa4 쓰던건데, 액션명이 달라서 분기)
      if (['A001', 'A006'].includes(pa_shop_cd)) {
        const action_table = {
          get_shipping_place: 'addr_list',
          get_seller_address: 'deliv_addr_list',
        };
        _action = action_table[_action];
      }

      // 따로 명시된 키가 없으면 액션명과 동일
      addrKey = addrKey || _action;

      temp = $scope.pa5_job_data[action]?.data?.find((o) => o[shopValueKey[pa_shop_cd] || 'id'] === $scope.shipInfo[addrKey]) ?? {};
      $scope.tmp[addrKey] = temp;
    };

    /**
     * 출하지/배송지 선택시 하단 정보 셋팅
     */
    $scope.setTmp = function (action, is_pa5 = false) {
      if (is_pa5) {
        const temp = { ...pa5_addr_origin_data[action].find(({ value }) => value === $scope.shipInfo[action]) };
        delete temp.name;
        delete temp.value;
        delete temp.$$hashKey;

        $scope.tmp[action] = Object.entries(temp);
      } else {
        const find = {};

        find[shopValueKey[pa_shop_cd] || 'id'] = $scope.shipInfo[action];
        const idx = _.findIndex($scope.addrList[action], find);

        $scope.tmp[action] = $scope.addrList[action][idx];
      }
    };

    $scope.$watch('vdata.etc4', function(nv) {
      if (!$scope.vdata) {
        return;
      }
      if (pa_shop_cd === 'A077') {
        $scope.vdata.shop_id_sub = nv;
      }
    });

    /**
     * 보안정보 수정 버튼 클릭
     */
    $scope.editEncryptData = (type) => {
      $scope.encryptData[type].isEdit = !$scope.encryptData[type].isEdit;

      if (!$scope.encryptData[type].isEdit) {
        $scope.encryptData[type].placeHolder = `수정버튼을 눌러 새 ${type === 'password' ? '비밀번호' : type} 입력`;
        $scope.encryptData[type].buttonText = '수정';
      } else {
        $scope.encryptData[type].placeHolder = `새 ${type === 'password' ? '비밀번호' : type} 입력`;
        $scope.encryptData[type].buttonText = '취소';
        $(`#new_${type}`).focus();
      }

      // 인터파크 비밀번호 수정시 공급업체 불러오기 버튼 노출
      if (type === 'password' && pa_shop_cd === 'A027') {
        $scope.showLoadProvider = true;
      }
    };

    /**
     * get request body for action
     */
    function getRequestBody(action) {
      let sellerPw = $scope.vdata.shop_pwd;

      if ($scope.encryptData.password.isEdit && $scope.vdata.new_pwd) {
        sellerPw = $scope.vdata.new_pwd;
      } else if ($scope.encryptData.password.oldvalue) {
        sellerPw = $scope.encryptData.password.oldvalue;
      }

      const requestBody = {
        job_data: {
          account: {
            seller_id: $scope.vdata.shop_id,
            seller_pw: sellerPw
          }
        },
        req_code: domain,
        req_action: action,
        req_type: 'application/json+emp',
        res_type: 'application/json+emp'
      };

      if ('etc1' in $scope.vdata) {
        requestBody.job_data.account.etc1 = $scope.vdata.etc1;
      }
      if ('etc2' in $scope.vdata) {
        requestBody.job_data.account.etc2 = $scope.vdata.etc2;
      }
      if ('etc3' in $scope.vdata) {
        requestBody.job_data.account.etc3 = $scope.vdata.etc3;
      }
      if ('etc4' in $scope.vdata) {
        requestBody.job_data.account.etc4 = $scope.vdata.etc4;
      }
      if ('etc5' in $scope.vdata) {
        requestBody.job_data.account.etc5 = $scope.vdata.etc5;
      }
      if ('etc6' in $scope.vdata) {
        requestBody.job_data.account.etc6 = $scope.vdata.etc6;
      }
      if ('etc7' in $scope.vdata) {
        requestBody.job_data.account.etc7 = $scope.vdata.etc7;
      }

      if (action === 'check_login' && pa_shop_cd === 'A008') {
        requestBody.job_data.account.seller_nick = $scope.vdata.seller_nick;
      }

      if (action === 'etc.scrap_delivery_place' && pa_shop_cd === 'A100') {
        // requestBody.job_data.account.seller_pw = $scope.vdata.etc1;
      }
      //쿠팡
      if ((action === 'etc.scrap_delivery_place_in' || action === 'etc.scrap_delivery_place_out') && pa_shop_cd === 'B378') {
        requestBody.job_data.vendorId = $scope.vdata.etc3;
      } else if (pa_shop_cd === 'B614' && action !== 'etc.check_login') {
        if (action === 'etc.scrap_man') {
          requestBody.job_data.man_code = 30;
        } else {
          requestBody.job_data.man_code = 20;
          requestBody.req_action = 'etc.scrap_man';
        }
      }

      return requestBody;
    }

    $scope.change_shop_cd = function () {
      if (pa_shop_cd === 'B009') {
        $scope.vdata.shop_cd = `${$scope.vdata.etc1}009`;
        $scope.vdata.shop_id_sub = $scope.vdata.etc1;
      }
    };

    $scope.changeCoupangGrowth = () => {
      if (!$scope.vdata.etc6) {
        $scope.vdata.etc7 = 'N';
      }
    };

    $scope.showShopHelpInfo = function () {
      let ctrl = '';
      let page = '';

      switch ($scope.vdata.shop_cd) {
        case 'A001':
        case 'A006':
          ctrl = 'EsmCommonInfoCtrl';
          page = 'views/settings/shopAccount/esm_common_info.html';
          break;
        case 'B378':
          ctrl = 'RocketGrowthInfoCtrl';
          page = 'views/settings/shopAccount/rocket_growth_info.html';
          break;
      }

      commonSVC.openModal('lm', {}, ctrl, page);
    };

    $scope.changeEsmMasterId = function (checkValue) {
      $scope.vdata.etc7 = checkValue === 'N' ? '' : $scope.vdata.etc7;
    };

    /**
     * 스마일배송 서비스 신청
     */
    $scope.registSmileService = () => {
      window.open('https://www.esmplus.com/Home/Home/#HTDM364', '_blank');
    };

    /**
     * 자동 세팅 작업 시작
     */
    $scope.autoSetting = async () => {
      // 엘지 사용자인 경우 자동세팅 하지 않음
      if ($scope.isLG) {
        return false;
      }

      $rootScope.cantChangeTab = false;
      localStorageService.set(`isAutoSetting_${userInfo.user.sol_no}`, true);

      const applyShopList = $scope.use_engines.filter(shop => $scope.autoSettingShopList.includes(shop.pa_shop_cd));

      if (applyShopList.length) {
        await workSVC.addSolWork('AutoSetting', { applyShopList });
        localStorage.setItem(`isOnlineListLoading_${userInfo.user.sol_no}`, true);
      }

      const { data: { result: reportUser } } = await systemModel.checkReportUser({ sol_no: userInfo.user.sol_no });
      commonSVC.openModal('xmd', { data: { isSummary: true }, addClass: 'order-report', reportUser }, 'orderReportCtrl', 'views/home/modals/orderReport.html', false, false, false);
    };

    // 글로벌 이벤트 바인딩
    $scope.$on('$stateChangeStart', async (...args) => {
      if (['main.settings_shop_account', 'main.settings_shop_account.channelSub'].includes(args[1].name)) {
        return false;
      }

      if ($rootScope.cantChangeTab) {
        args[0].preventDefault();
        const $chromeTabsShell = $('.chrome-tabs-shell');

        // 모든 탭 선택 해제
        $chromeTabsShell.find('.chrome-tab').removeClass('chrome-tab-current');
        // 쇼핑몰 계정 관리 탭 선택
        $('.chrome-tab[tab-id="tab_settings_shop_account"]').addClass('chrome-tab-current');

        commonSVC.showConfirmCustom({
          title: '쇼핑몰 계정 등록 안내',
          text: '<p class="no-padding help-text text-danger-600">쇼핑몰 계정 등록 후 솔루션 이용이 가능합니다.</p>',
          confirmButtonText: '쇼핑몰 계정 등록',
          isHtml: true,
          showCancelButton: false
        });
      }
    });
  });