'use strict';

angular.module('gmpApp')
  .factory('onlineProdSVC', function () {
    return {
      A112: {
        hg_category_list: [
          { key: '브랜드패션', value: '153604' },
          { key: '의류', value: '153605' },
          { key: '잡화', value: '153606' },
          { key: '뷰티', value: '153607' },
          { key: '식품', value: '153608' },
          { key: '출산/육아', value: '153609' },
          { key: '가구/인테리어', value: '153610' },
          { key: '생필품/주방', value: '153611' },
          { key: '스포츠/레저', value: '153612' },
          { key: '건강', value: '153624' },
          { key: '자동차/공구', value: '154152' },
          { key: '디지털', value: '154153' },
          { key: '가전', value: '154154' },
          { key: '컴퓨터', value: '154155' },
          { key: '도서/문구', value: '154156' },
          { key: '여행/e쿠폰', value: '154157' },
          { key: '취미', value: '154158' },
          { key: '반려동물', value: '154159' },
          { key: '생활플러스', value: '154270' },
          { key: '비즈11번가', value: '160995' }
        ]
      },
      B048: {
        satety_type: [
          { code: '11', value: '생활용품 안전인증' },
          { code: '12', value: '생활용품 안전확인' },
          { code: '40', value: '생활용품 공급자적합성확인' },
          { code: '21', value: '생활용품 어린이보호포장' },
          { code: '13', value: '전기용품 안전인증' },
          { code: '14', value: '전기용품 안전확인' },
          { code: '41', value: '전기용품 공급자적합성확인' },
          { code: '20', value: '방송통신기자재 적합인증' },
          { code: '22', value: '방송통신기자재 적합등록' },
          { code: '23', value: '방송통신기자재 잠정인증' },
          { code: '42', value: '위해우려제품 자가검사' },
          { code: '18', value: '표시광고사전심의) 의료기기' },
          { code: '24', value: '표시광고사전심의) 건강기능식품' },
          { code: '39', value: '표시광고사전심의) 특수용도식품' },
          { code: '16', value: '식약허가) 의약외품' },
          { code: '17', value: '식약허가) 기능성화장품' },
          { code: '15', value: 'KS인증' },
          { code: '19', value: '농축산물) 친환경인증(유기농산물)' },
          { code: '25', value: '농축산물) 친환경인증(무농약농산물)' },
          { code: '26', value: '농축산물) 친환경인증(저농약농산물)' },
          { code: '27', value: '농축산물) 친환경인증(유기축산물)' },
          { code: '28', value: '농축산물) 친환경인증(무항생제축산물)' },
          { code: '29', value: '농축산물) 친환경인증(유기가공식품)' },
          { code: '30', value: '농축산물) 우수관리농산물(GAP)' },
          { code: '31', value: '농축산물) 우수식품(전통식품)' },
          { code: '32', value: '농축산물) 우수식품(유기가공식품)' },
          { code: '33', value: '농축산물) 우수식품(가공식품 KS)' },
          { code: '34', value: '수산물) 친환경수산물' },
          { code: '35', value: '수산물) 유기수산물' },
          { code: '36', value: '수산물) 품질인증(수산물)' },
          { code: '37', value: '수산물) 품질인증(수산특산물)' },
          { code: '38', value: '수산물) 품질인증(전통식품)' }
        ]
      },
      B378: {
        default_opt: [
          { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [] },
          { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [] },
          { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [] }
        ]
      },
      B616: {
        mileage_type: [
          { name: '%', value: 'P' },
          { name: '원', value: 'W' },
        ],
      },
      B691: {
        // 관련상품 검색
        searchWordType: {
          goodsNm: '상품명',
          goodsNo: '상품코드',
          goodsCd: '자체상품코드',
          makerNm: '제조사',
          originNm: '원산지',
          goodsSearchWord: '검색 키워드',
          goodsModelNo: '모델번호'
        }
      },
      B791: {
        //기준가격근거
        standardBasis: {
          ONL: '온라인판매가',
          DIS: '할인점판매가',
          HOM: '홈페이지판매가',
          DEP: '백화점판매가',
          AGE: '대리점판매가',
          SHP: '홈쇼핑판매가',
          CAL: '카탈로그판매가',
          SPE: '전문매장판매가',
          LIM: '한정판',
          WMP: '위메프가'
        }
      },
      B851: {
        // 정상가 근거
        info_data_price_source: [
          {
            refCode: 'OL',
            title: '온라인 판매가',
            option1: '제조사에서 지정하는 온라인 권장 판매가입니다. ',
            option2: '할인쿠폰 등이 적용되지 않은 판매가이니 참고해 주시기 바랍니다.',
            displaySort: 1
          },
          {
            refCode: 'OS',
            title: '자사몰 판매가',
            option1: '자체 사이트를 운영하고 있는 공급사에서 일주일 전 기준으로 판매하고 있는 가격입니다.',
            displaySort: 2
          },
          {
            refCode: 'HS',
            title: '홈쇼핑 판매가',
            option1: '최근 홈쇼핑에서 판매된 판매가입니다. ',
            option2: '단, 수입브랜드 상품들의 경우 국내 정식 수입원의 가격을 기준으로 표기하였기에 병행수입 상품의 경우 차이가 있을 수 있으니 참고해 주시기 바랍니다.',
            displaySort: 3
          },
          {
            refCode: 'DS',
            title: '할인점 판매가',
            option1: '일주일 전 기준, 할인점 판매가입니다. ',
            option2: '단, 세일 시 세일 전 판매가이며, 수입브랜드 상품들의 경우 국내 정식 수입원의 가격을 기준으로 표기하였기에 병행수입 상품의 경우 차이가 있으니 참고해 주시기 바랍니다.',
            displaySort: 4
          },
          {
            refCode: 'DP',
            title: '백화점 판매가',
            option1: '일주일 전 기준, 백화점 판매가입니다. ',
            option2: '단, 세일 시 세일 전 판매가이며, 수입브랜드 상품들의 경우 국내 정식 수입원의 가격을 기준으로 표기하였기에 병행수입 상품의 경우 차이가 있으니 참고해 주시기 바랍니다.',
            displaySort: 5
          },
          {
            refCode: 'SS',
            title: '전문매장 판매가',
            option1: '일주일 전 기준, 전문매장 판매가입니다. ',
            option2: '단, 세일 시 세일 전 판매가이며, 수입브랜드 상품들의 경우 국내 정식 수입원의 가격을 기준으로 표기하였기에 병행수입 상품의 경우 차이가 있으니 참고해 주시기 바랍니다.',
            displaySort: 6
          },
          {
            refCode: 'CV',
            title: '편의점 판매가',
            option1: '일주일 전 기준, 편의점 판매가입니다. 단, 세일 시 세일 전 판매가입니다.',
            displaySort: 7
          },
          {
            refCode: 'RP',
            title: '권장소비자가',
            option1: '제조사에서 표시한 권장소비자가격을 기준으로 합니다.',
            displaySort: 8
          },
          {
            refCode: 'AG',
            title: '대리점 출고가',
            option1: '제조사에서 대리점에 출고하는 가격을 기준으로 합니다.',
            displaySort: 9
          },
          {
            refCode: 'CA',
            title: '카탈로그 판매가',
            option1: '카탈로그에서 기재된 판매가입니다. ',
            option2: '단, 수입브랜드 상품들의 경우 국내 정식 수입원의 가격을 기준으로 표기하였기에 병행수입 상품의 경우 차이가 있을 수 있으니 참고해 주시기 바랍니다.',
            displaySort: 10
          },
          {
            refCode: 'TM',
            title: '티몬가',
            option1: '티몬에서 책정한 판매가격입니다.',
            displaySort: 11
          }
        ],

        // 출산 유아동 출산유아 상품연령
        info_data_baby_age: [
          {
            refCode: '1',
            title: '임신/출산',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 1
          },
          {
            refCode: '2',
            title: '0~3개월',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 2
          },
          {
            refCode: '3',
            title: '3~6개월',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 3
          },
          {
            refCode: '4',
            title: '6~12개월',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 4
          },
          {
            refCode: '5',
            title: '12~24개월',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 5
          },
          {
            refCode: '6',
            title: '24~36개월',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 6
          },
          {
            refCode: '7',
            title: '3~8세',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 7
          },
          {
            refCode: '8',
            title: '8세 이상',
            option1: '출산·유아동  상품 연령 속성',
            option2: '',
            displaySort: 8
          }
        ],

        // 법적허가/신고대상 상품
        required_doc: [
          {
            refCode: 'DOC1',
            title: '건강기능식품',
            option1: '',
            option2: '',
            displaySort: 1
          },
          {
            refCode: 'DOC2',
            title: '의료기기',
            option1: '',
            option2: '',
            displaySort: 2
          },
          {
            refCode: 'DOC3',
            title: '기능성 화장품',
            option1: '',
            option2: '',
            displaySort: 3
          },
          {
            refCode: 'DOC4',
            title: '특수용도 식품',
            option1: '',
            option2: '',
            displaySort: 4
          },
          {
            refCode: 'DOC5',
            title: '의약외품',
            option1: '',
            option2: '',
            displaySort: 5
          },
          {
            refCode: 'NONE',
            title: '해당없음',
            option1: '',
            option2: '',
            displaySort: 6
          }
        ],

        title_decoration: [
          { name: '상품명', value: 'name' },
          { name: '브랜드', value: 'brand' },
          { name: '모델명', value: 'model' },
          { name: '직접입력', value: 'direct' }
        ]
      },
      B917: {
        income_make: [
          { name: '-목록보기-', value: '-1' },
          { name: '해외구매대행', value: '1' },
          { name: '병행수입', value: '2' },
          { name: '주문제작', value: '3' }
        ],

        sales_method: [
          { name: '-목록보기-', value: '-1' },
          { name: '도매', value: '1' },
          { name: '렌탈', value: '2' },
          { name: '대여', value: '3' },
          { name: '할부', value: '4' },
          { name: '예약판매', value: '5' },
          { name: '구매대행', value: '6' }
        ],

        use_gender: [
          { name: '-목록보기-', value: '-1' },
          { name: '남성', value: '1' },
          { name: '여성', value: '2' },
          { name: '남여공용', value: '3' }
        ],

        img_select: [
          { name: '1번 이미지', value: '-1' },
          { name: '2번 이미지', value: '2' },
          { name: '3번 이미지', value: '3' },
          { name: '4번 이미지', value: '4' },
          { name: '5번 이미지', value: '5' },
          { name: '6번 이미지', value: '6' },
          { name: '7번 이미지', value: '7' },
          { name: '8번 이미지', value: '8' },
          { name: '9번 이미지', value: '9' },
          { name: '10번 이미지', value: '10' }
        ],
      },
      B956: {
        title_decoration: [
          { name: '브랜드', value: 'brand' },
          { name: '모델명', value: 'model' },
          { name: '상품명', value: 'name' },
          { name: '직접입력', value: 'direct' },
        ],

        DealLegalPermissionType: [
          { code: 'DOC1', description: '건강기능식품' },
          { code: 'DOC2', description: '의료기기' },
          { code: 'DOC3', description: '기능성 화장품' },
          { code: 'DOC4', description: '특수용도 식품' },
          { code: 'DOC5', description: '의약외품' },
          { code: 'NONE', description: '해당없음' }
        ]
      },
      A524: {
        useYnCfmWayCds: [
          { key: 'ENTP_CFM', value: '업체확인필요' },
          { key: 'ST_INQ', value: '시스템조회가능' }
        ],
        ecpnSeTypCds: [
          { key: 'ISN', value: '발행분' },
          { key: 'USE', value: '사용분' }
        ],
        ecpnVldPrdDvsCds: [
          { key: 'ISS_STD', value: '발급일기준' },
          { key: 'PRD_DGNN', value: '기간지정' }
        ],
        rtngPsbDvsCds: [
          { key: 'WTHN_PRD', value: '사용기간내' },
          { key: 'EXCD', value: '주문일+7' },
          { key: 'ELPS__PRD', value: '사용기간경과' }
        ],
        ecpnRfndTypCds: [
          { key: '100', value: '일반_고객, 공통코드 추가속성 - 환불정산율(90%)' },
          { key: '90', value: '일반_전액, 공통코드 추가속성 - 환불정산율(100%)' },
          { key: '70', value: '특가_고객, 공통코드 추가속성 - 환불정산율(70%)' },
          { key: '50', value: '특가_50%, 공통코드 추가속성 - 환불정산율(50%)' }
        ],
        issWayCds: [
          { key: 'CRT', value: '자체생성형' },
          { key: 'UPLD', value: '업로드형' },
          { key: 'LNK', value: '외부연동형' },
          { key: 'TR_SND', value: '거래처발송형' }
        ],
        sndWayCds: [
          { key: 'LNK', value: '외부연동형' },
          { key: 'AUTO', value: '자동형' },
          { key: 'TR_SND', value: '거래처발송형' }
        ],
        sndMnsCds: [
          { key: 'MMS', value: 'MMS' },
          { key: 'MAIL', value: 'e메일' }
        ]
      }
    };
  });
