'use strict';

/**
 * 2020-11-03 Boris
 * 카카오 선물하기
 */
angular.module('gmpApp')
  .factory('B700categorySVC', function () {

    return {
      // 옵션 repeat data
      category_opt: [
        {},
        {},
        {},
        {}
      ],

      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      },
    };
  });
