'use strict';

/**
 * 홈앤쇼핑
 * rony
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B614-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B614categorySVC, commonSVC, errorSVC, settings, siteInfo, gettextCatalog) {
    const svc = B614categorySVC;

    $scope.CateList = angular.copy(svc.category_list);            // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);              // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);            // 카테고리 데이터
    $scope.spDispCtg = angular.copy(B614categorySVC.spDispCtg);   // 상품카테고리
    $scope.getSpDispList = {};                                    // 조회된 상품카테고리
    $scope.max_depth = 5;
    $scope.savedDispCateList = []; //다중카테고리 저장하는 배열

    $scope.pa5_job_data = {
      get_display_categories: { load: false, data: [] }
    };

    /**
     * PA5 실시간 호출.
     */
    const run_pa5_jobs = async (job_name, job_kor_name, params, temp_saver) => {
      $scope.pa5_job_data[job_name].data = [];

      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, params, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    /**
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 하위카테고리 선택
     * @param { } index
     */
    $scope.cateSelect = (index) => {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd, 'cate');

      if (index === '3' || cateCd === 'noSub' || !cateCd) { return false; } // 마지막 분류이거나, 하위분류없을시

      loading('0', false);

      clearOpt(index);

      try {
        setCateSub(index, cateCd);

        loading('0', true);
        cateCd = 0;
      } catch (err) {
        cateCd = 0;
        commonSVC.showMessage('로딩실패');
        loading('0', true);
      }
    };

    /**
     * 상품카테고리 삭제
     */
    $scope.removeSelectedDispCate = (index) => {
      $scope.savedDispCateList.splice(index, 1);
    };

    /**
     * 하위 카테고리 불러오기
     */
    const setCateSub = async (index, cateCd) => {
      index = index * 1;

      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'B614', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: cateCd });

      $timeout(() => {
        if (res.data.result.length < 1) {
          $scope.CateOpt[index + 1] = [{ shop_cate_see_cd: 'noSub', shop_cate_see_name: '하위 카테고리가 없습니다' }]; // 하위카테고리 없을시 출력
        } else {
          $scope.CateOpt[index + 1] = res.data.result;
        }
      });
    };

    /**
     * 카테고리 이름 설정
     */
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') { return false; }

      let name = '';
      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        // 선택 카테고리 이름
        name = $(`#CatMenu_${i}`).find('option:selected').text();

        if (!name) { break; }
        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

    /**
     * 옵션 초기화
     */
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 5; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    /**
     * 전시카테고리 저장
     */
    function DispCtgList(param) {
      const dispTypeArr = ['dispCtg'];

      dispTypeArr.forEach(dispType => {
        const savedList = $scope[`saved${dispType.replace('dispCtg', 'DispCate')}List`];

        if (dispType === 'dispCtg') {
          param.display_categories = savedList;
        }
      });
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      // 표준 카테고리
      if ($scope.CateData.key_dcate == '') {
        $timeout(() => {
          commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');
        }, 500);

        return false;
      }

      // 상품 카테고리
      if (!$scope.savedDispCateList.length) {
        $timeout(() => {
          commonSVC.showMessage('상품카테고리를 선택해 주십시오.');
        }, 500);

        return false;
      }

      $scope.CateData.detailData.key_mall_cate_nm = $scope.CateData.category_names.replace(/ >> /g, ' >> ');
      $scope.CateData.detailData.key_mall_cate_cd = $scope.CateData.key_dcate;
      $scope.CateData.category_code = $scope.CateData.detailData.key_mall_cate_cd.replace(/>/g, '_');

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = $scope.CateData.detailData.key_mall_cate_cd;
      // 카테고리 리스트 저장
      // $scope.CateData.detailData.Cate = $scope.Cate;

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B614',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.category_code,
          category_names: $scope.CateData.category_names.replace(/ >> /g, ' > '),
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 전시카테고리 데이터 저장
        DispCtgList(param.detailData);

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    /**
     * 옵션태그 리턴함수
     * @param value
     * @param name
     * @returns {string}
     */
    function getOptionTag(value, name) {
      return `<option value='${value}'>${name}</option>`;
    }

    /**
     * 상품카테고리 선택이벤트
     */
    $scope.setDispCtgList = function() {
      if (!$scope.spDispCtg.dispCtgSelect) { return false; }

      if ($scope.savedDispCateList.length >= $scope.max_depth) {
        commonSVC.showMessage('상품카테고리는 5개까지 선택 가능합니다.');

        return false;
      }

      if ($scope.savedDispCateList.some(({ code }) => code === $scope.spDispCtg.dispCtgSelect.code)) {
        commonSVC.showMessage('이미 선택한 상품카테고리 입니다.');

        return false;
      }

      $scope.savedDispCateList.push({
        code: $scope.spDispCtg.dispCtgSelect.code,
        name: $scope.spDispCtg.dispCtgSelect.name
      });
    };

    // 초기화
    const init = async () => {
      // 로딩중
      loading('0', false);                  // 표준카테고리 로딩중
      $scope.spDispCtg.dispCtgLoad = true;  // 상품카테고리 로딩중

      // 카테고리 대분류 조회
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'B614', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });
      const category = res.data.result;

      $scope.cateSelect(0);

      // 상품카테고리 조회 (전시카테고리는 업체마다 노출되는 정보가 다르므로 실시간 조회.)
      await run_pa5_jobs('get_display_categories', '상품카테고리', {}, (items) => {
        if (!items.length) {
          commonSVC.showMessage('조회된 상품카테고리 카테고리가 없습니다');
        } else {
          $scope.getSpDispList = items.map(item => ({ code: item.code, name: item.name.replace(/.*\[/gi, '').replace(']', '').replace(/>/gi, ' > ') }));
        }
      });

      $scope.spDispCtg.dispCtgLoad = false;

      if (!category) {
        commonSVC.showMessage('카테고리 로딩실패');
        loading('0', true);

        return false;
      } else if ($scope.spDispCtg.dispCtgLoad) {
        commonSVC.showMessage('상품카테고리 로딩실패');
        $scope.spDispCtg.dispCtgLoad = false;

        return false;
      } else {
        $scope.CateOpt[0] = category;          // 표준카테고리
      }

      const cateAddInfo = $scope.matchingData.shop_cate_no_add_info ?
        (typeof $scope.matchingData.shop_cate_no_add_info === 'string' ? JSON.parse($scope.matchingData.shop_cate_no_add_info) : $scope.matchingData.shop_cate_no_add_info)
        : ($scope.matchingData.sol_cate_shop_add_info || angular.copy(B614categorySVC.category_data));

      if (cateAddInfo.display_categories) {
        $scope.savedDispCateList = cateAddInfo.display_categories;
      } else {
        for (const key in cateAddInfo) {
          if (key.startsWith('Key_jscate_cd')) {
            const name = cateAddInfo[`Key_jscate_nm${key.slice(-1)}`].replace(/.*\[/gi, '').replace(']', '').replace(/>/gi, ' > ');

            $scope.savedDispCateList.push({ code: cateAddInfo[key], name });
          }
        }
      }

      matchDetailDataLoad(cateAddInfo);
    };

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;

      if (!addInfo.category_code) {
        loading('0', true);
        loading('1', true);

        return false;
      }

      const splitCd = addInfo.category_code.includes('_') ? addInfo.category_code.split('_') : addInfo.category_code.split('>');

      try {
        await $timeout(async () => {
          if (splitCd[0]) {
            $scope.CateData.key_lcate = splitCd[0];
            await setCateSub(0, $scope.CateData.key_lcate, 'etc.scrap_cate');
          }

          if (splitCd[1]) {
            $scope.CateData.key_mcate = `${splitCd[0]}_${splitCd[1]}`;
            await setCateSub(1, $scope.CateData.key_mcate, 'etc.scrap_cate');
          }

          if (splitCd[2]) {
            $scope.CateData.key_scate = `${splitCd[0]}_${splitCd[1]}_${splitCd[2]}`;
            await setCateSub(2, $scope.CateData.key_scate, 'etc.scrap_cate');
          }

          if (splitCd[3]) {
            $scope.CateData.key_dcate = `${splitCd[0]}_${splitCd[1]}_${splitCd[2]}_${splitCd[3]}`;
          }
        });
      } catch (err) {
        commonSVC.showMessage(`카테고리 로딩실패${err}`);
      }

      loading('0', true);
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
