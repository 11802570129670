/**
 * 리스트 검색 자동완성
 * 2019-09-04 Jackal
 */
'use strict';

angular.module('gmpApp')
  .directive('searchAutoComplete', function () {
    return {
      restrict: 'A',
      scope: {
        placeholder: '=ngPlaceholder',
        noResults: '=ngNoresults',
        hideSearch: '=?',
        model: '=ngModel',
        class: '=ngClass',
        width: '=ngWidth',
        default: '=ngDefault',
        dropdownClass: '=ngDropdownClass',
      },
      link: function (scope, element) {
        const resetSelect2 = () => {
          setTimeout(function () {
            $(element).select2({
              width: scope.width || '100%',
              placeholder: scope.placeholder || '검색어 입력',
              language: {
                noResults: function () {
                  return scope.noResults || '해당정보 없음';
                }
              },
              containerCssClass: scope.class,
              ...(scope.dropdownClass && { dropdownCssClass: scope.dropdownClass })
            });
          }, 100);
        };

        // ng-placeholder처럼 작동하게 바인딩해서 구현
        scope.$watch('placeholder', function() {
          resetSelect2();
        });

        // 클래스 변경 감지
        scope.$watch('class', function() {
          resetSelect2();
        });

        // 모델 변경 감지
        scope.$watch('model', function() {
          resetSelect2();
        });

        // 초기값 변경 감지
        scope.$watch('default', function() {
          if (scope.default) {
            scope.model = String(scope.default);
          }

          resetSelect2();
        });

        $(element).ready(function () {
          // 가끔 선택되었는데 검색어 입력으로 나와서 타임아웃 걸어줌
          resetSelect2();
        });
      }
    };
  });
