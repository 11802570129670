'use strict';

angular.module('gmpApp')
  .controller('SolutionUseInfoCtrl', function ($scope, $uibModalInstance, userInfo, commonSVC, settings, systemList, commonModel) {
    $scope.sol_data = {};
    $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';
    // 이메일, sms 카운트 조회
    commonModel.getServiceCnt().then((re) => {
      if (re.data?.status === 'success') {
        $scope.emailCnt = re.data?.emailCnt;
        $scope.smsCnt = re.data?.smsCnt;
        $scope.atalkCnt = re.data?.atalkCnt;
      } else {
        throw new Error('EMAIL,SMS, 알림톡 카운트 조회 실패');
      }
    }).catch(err => {
      commonSVC.showToaster('error', '실패', err.message);
    });
    commonModel.solutionUseInfo({ period: systemList.data.search_date, data_type: '솔루션사용정보', shopType: $scope.selectedShopType }, function (data) {
      $scope.sol_data = data.result;
      $scope.sol_data.solUseInfo = $scope.sol_data.solUseInfo[0][0];

      if ($scope.sol_data.solUseInfo['사용버전'] == 'ZERO') {
        $scope.sol_data.solUseInfo['잔여일'] = '무제한';
        $scope.sol_data.solUseInfo['만료일'] = '무제한';
        $scope.sol_data.solUseInfo.charge_sdate = moment($scope.sol_data.solUseInfo.charge_edate).add(1, 'd').format('YYYY-MM-DD');
      } else {
        $scope.sol_data.solUseInfo['잔여일'] = `${$scope.sol_data.solUseInfo['잔여일'] || 0}일`;
      }

      const monthPrice = $scope.sol_data.solUseInfo['월이용금액'];

      // 자동결제면 3% 할인 금액 노출
      $scope.autoPaymentDay = $scope.sol_data.solUseInfo['자동결제일'] !== '미사용';
      $scope.sol_data.solUseInfo['월이용금액'] = $scope.autoPaymentDay ? monthPrice - (monthPrice * 0.03) : monthPrice;

      $scope.ol_shop_percent = Math.floor(($scope.sol_data.charge.ol_shop_cnt_limit - $scope.sol_data.charge.ol_shop_cnt) / $scope.sol_data.charge.ol_shop_cnt_limit * 100);
      $scope.ord_percent = Math.floor(($scope.sol_data.charge.ord_cnt_limit - $scope.sol_data.charge.ord_cnt) / $scope.sol_data.charge.ord_cnt_limit * 100);
    });

    /**
      * 창 닫기
    */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
