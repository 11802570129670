'use strict';

angular.module('gmpApp')
  .service('adminModel', function ($http, $q, settings, commonSVC) {
    return {
      /**
      * 자사정보 조회
      * @param next 콜백함수
      */
      load: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/company/detail`;
        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      /**
      * 자사정보 업데이트
      * @returns {promise} promise 객체를 리턴함
      */
      set: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/company/set`;
        if (next) {
          commonSVC.sendUrlFile('PATCH', url, param, function (state, data) {
            // front 에서 에러객체를 받기 위해 data.result 를 data 로 변경.
            // 2017-11-30 박현도
            next(state, data);
          });
        } else {
          return commonSVC.sendUrlFile('PATCH', url, param);
        }
      },

      /**
       * 자사정보 업데이트 all
       */
      edit: (param) => {
        return commonSVC.sendUrlFile('PATCH', `${settings.pa20ApiUrl}/app/company/edit`, param);
      }

    };
  });
