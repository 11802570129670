'use strict';

angular.module('gmpApp')
  .factory('B325categorySVC', function () {

    return {
      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false,  //상품적용여부
        detailData: {
          display_categories: [],
          key_iconCd: '',               // 식품아이콘
          rainbowYn: 'Y'                // Y : 복지몰, N : 브랜드몰
        }
      },
      // 전시카테고리
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      }
    };
  });