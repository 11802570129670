'use strict';
/**
 * 커스텀 컬럼 일괄저장 모달
 */
angular.module('gmpApp')
  .controller('asAddCtrl', function ($scope, $rootScope, $uibModalInstance,
    commonSVC, asSVC,
    supplierModel, systemModel, productModel, warehouseModel, asModel,
    data, userInfo, $timeout
  ) {
    $scope.tabActive = 0;
    $scope.data = angular.copy(asSVC.asData);
    $scope.deliMethodList = angular.copy(asSVC.deliMethodList);
    $scope.warehouseList = data.warehouseList;

    // 요청잔 정보와 동일 체크박스
    $scope.autoWriteCheck = false;

    // 모달 닫음
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    // 주문자 정보와 동일함
    $scope.autoWrite = () => {
      if ($scope.autoWriteCheck) {
        $scope.data.to_name = $scope.data.req_name;
        $scope.data.to_tel = $scope.data.req_tel;
        $scope.data.to_htel = $scope.data.req_htel;
        $scope.data.to_email = $scope.data.req_email;
      } else {
        $scope.data.to_name = '';
        $scope.data.to_tel = '';
        $scope.data.to_htel = '';
        $scope.data.to_email = '';
      }
    };

    $scope.onSubmit = () => {
      if (!$scope.asForm.$valid) {
        commonSVC.showMessage('필수값을 모두 입력하여 주십시오.');
        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        }, 1000);

        return;
      }

      asModel.add($scope.data)
        .then((re) => {
          if (re.data.results === 'success') {
            commonSVC.showToaster('success', 'A/S', '등록에 성공하였습니다.');

            $uibModalInstance.close('success');
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          commonSVC.showToaster('error', 'A/S', '등록에 실패했습니다.');
          $timeout(() => {
            $scope.waitBtnDisableFlag = false;
          }, 1000);
        });
    };
  })
  // as  상세
  .controller('asEditCtrl', function ($scope, $rootScope, $uibModalInstance,
    commonSVC, asSVC, logHistorySVC,
    supplierModel, systemModel, productModel, warehouseModel, asModel,
    data, userInfo, $timeout
  ) {
    $scope.tabActive = 0;
    $scope.mend_no = data.mend_no;
    // $scope.data = angular.copy(asSVC.asData);
    $scope.deliMethodList = angular.copy(asSVC.deliMethodList);
    $scope.warehouseList = data.warehouseList;
    $scope.deliveryList = $rootScope.deliveryInfoList;
    $scope.memoUpdate = false;

    // 로그용 데이터
    let oldData = {};
    const logField = angular.copy(asSVC.logField);

    init();
    function init() {
      asModel.detail({ mend_no: [data.mend_no] })
        .then((re) => {
          $scope.data = re.data.results[0];
          oldData = angular.copy($scope.data); // 수정 로그용 원본 데이터

          $scope.data.carr_no = $scope.data.carr_no ? $scope.data.carr_no.toString() : null;
          const checkOnList = $scope.deliveryList.filter(o => o.carr_no == $scope.data.carr_no);

          if (!checkOnList.length) {
            $scope.unlisted = $rootScope.deliveryInfoListAll.find(o => o.carr_no == $scope.data.carr_no);
          }
        }).catch(() => {
          commonSVC.showToaster('error', 'A/S주문', 'A/S주문 상세보기 조회가 실패되었습니다.');
        });

    }

    // 주문자 정보와 동일함
    $scope.autoWrite = () => {
      if ($scope.autoWriteCheck) {
        $scope.data.to_name = $scope.data.req_name;
        $scope.data.to_tel = $scope.data.req_tel;
        $scope.data.to_htel = $scope.data.req_htel;
        $scope.data.to_email = $scope.data.req_email;
      } else {
        $scope.data.to_name = '';
        $scope.data.to_tel = '';
        $scope.data.to_htel = '';
        $scope.data.to_email = '';
      }
    };

    //택배사 변경
    $scope.changeCarr = () => {
      $timeout(function () {
        const carr = _.find($scope.deliveryList, { carr_no: parseInt($scope.data.carr_no) });

        $scope.data.carr_name = carr ? carr.carr_name : '';
      });
    };

    $scope.onSubmit = () => {
      if (!$scope.asEditForm.$valid) {
        commonSVC.showMessage('필수값을 모두 입력하여 주십시오.');
        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        }, 1000);

        return;
      }
      $scope.data.log_contents = logHistorySVC.asLogReturnVal(logField, oldData, $scope.data);

      if (!$scope.data.log_contents) {
        commonSVC.showMessage('수정사항이 없습니다.');
        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        }, 1000);

        return;
      }

      asModel.edit({ ...$scope.data, ship_cost: +$scope.data.ship_cost, as_cost: +$scope.data.as_cost })
        .then((re) => {
          if (re.data.results === 'success') {
            commonSVC.showToaster('success', 'A/S', '수정에 성공하였습니다.');

            $uibModalInstance.close('success');
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          commonSVC.showToaster('error', 'A/S', '수정에 실패했습니다.');
          $timeout(() => {
            $scope.waitBtnDisableFlag = false;
          }, 1000);
        });
    };

    // 모달 닫음
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  })
  .controller('ordAsList', function ($scope, $rootScope, $uibModalInstance, $filter,
    commonSVC, asSVC, logHistorySVC, userInfo,
    supplierModel, systemModel, productModel, warehouseModel, asModel,
    data, $timeout
  ) {
    $scope.data = angular.copy(data);
    $scope.selectRow = (row) => {
      row.checked = !row.checked;
    };

    $scope.checkAll = () => {
      $scope.data.rows.forEach((o) => {
        o.checked = $scope.checkAllFlag;
      });
    };

    $scope.onSubmit = async () => {
      const filter_rows = _.filter($scope.data.rows, 'checked');

      if (!filter_rows.length) {
        commonSVC.showMessage('선택된 주문이 없습니다.', '주문 선택 후 다시 시도하시기 바랍니다');
        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        }, 500);

        return false;
      }

      const confrirm = await commonSVC.showConfirm('선택된 주문을 A/S요청 하시겠습니까?', '확인을 누르시면 A/S(수선)관리에 주문이 생성됩니다.');

      if (!confrirm) {
        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        }, 500);

        return false;
      }

      asModel.request({ ordList: filter_rows })
        .then((re) => {
          if (re.data.results === 'success') {
            commonSVC.showToaster('success', 'A/S요청', '정상적으로 A/S주문이 생성되었습니다.');
            $scope.cancel();
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          commonSVC.showToaster('error', 'A/S요청', 'A/S주문 생성 실패');
        });
    };

    // 모달 닫음
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

  })
  .controller('asModifyBundle', function ($scope, $uibModalInstance, data, asModel, commonSVC) {
    $scope.selectList = _.uniqWith(data.selectList, function(a, b) {
      return a.to_name === b.to_name && a.to_zipcd === b.to_zipcd && a.to_addr1 === b.to_addr1 && a.to_htel === b.to_htel;
    });
    $scope.selectBundleIndex = null;
    let standardData = {};
    const param = {
      mendList: data.mendList,
      selectList: data.selectList
    };

    $scope.selectSData = function(idx) {
      $scope.selectBundleIndex = idx;
      standardData = $scope.selectList[idx];
    };

    $scope.ok = function () {
      if ($scope.selectBundleIndex != null) {
        param.standardMendNo = standardData.mend_no;
        asModel.combine(param)
          .then((re) => {
            if (re.data.results === 'success') {
              commonSVC.showToaster('success', 'A/S주문', 'A/S주문 합포장 성공');
              $uibModalInstance.close('success');
            }
          })
          .catch(() => {
            commonSVC.showToaster('error', 'A/S주문', 'A/S주문 합포장 실패');
          });
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })
  .controller('asPrintInvoice', function ($scope, $uibModalInstance, data, selectList, asModel, deliveryModel, commonSVC, $timeout, settings, userInfo) {
    // 실제 운송장 출력 가능여부
    $scope.refusePrint = true;
    // 승인완료된 택배사 리스트
    const approveCarrierLists = [];

    $scope.deliveryAllList = angular.copy(data.solCarrList.filter((o) => {
      if (o.carr_id !== '' && parseInt(o.contract_yn) === 1 && parseInt(o.reg_confirm_yn) === 1) {
        approveCarrierLists.push(`${o.carr_no}+${o.carr_id}`);
      }

      return true;
    })) || [];  // 택배사 리스트

    $scope.templateList = []; // 양식 리스트
    $scope.selectedDeli = {}; // 선택된 택배사 정보
    $scope.selectedTemp = {}; // 선택된 양식 정보
    $scope.modalData = data;  // 모달 정보

    $scope.selectList = selectList.data.results || [];
    $scope.getInvoiceList = angular.copy($scope.selectList);         // $scope.selectList 값은 추후 가공되므로 운송장 발행 하기 위한 변수를 따로 설정 2018-06-19 rony
    $scope.selectListCount = selectList.data.recordsTotal;
    $scope.today = new Date();

    $scope.view = ''; // 프린터기 마다 출력할 html 다름
    $scope.wait = false;
    $scope.today = new Date();

    // 2018-08-21 chris 대한통운 송장 출력시 다이아몬드 표시 추가(대한통운 요청)
    $scope.show_diams = false;
    // if (moment().unix() > moment('2018-08-22 05:00:00').unix()) {
    //   $scope.show_diams = true;
    // }

    let originView = '';

    // 송장 발행 후 발급받은 송장번호 등의 정보를 담은 데이터
    let returnDelivInfo;

    // AS주문데이터 가공

    function init() {
      asModel.detail({ mend_no: $scope.selectList.map((as) => { return as.mend_no; }) })
        .then((res) => {
          // 묶음번호로 묶음
          const bundleData = _.groupBy(res.data.results, 'bundle_no');
          const tempData = [];

          _.forEach(bundleData, function(list) {
            // list: 묶음주문리스트
            let item = {};
            const ship_method_list = [];
            const ship_cost_list = [];
            const ship_msg_list = [];
            const prod_list = [];

            // 묶음 출고수량 처리 2018-07-04 rony
            _.each(list, function (as) {
              // 각 주문의 상품데이터 처리
              const asData = {
                prod_name: as.as_opt_name !== '' ? `${as.as_sale_name} / ${as.as_opt_name}` : as.as_sale_name,
                opt_name: as.as_opt_name !== '' ? as.as_opt_name : as.as_sale_name,

                count: as.sale_cnt
              };

              item = as;
              item.deli_invoice = '000-0000-0000';

              item.ship_method_etc = '';

              ship_method_list.push(as.ship_method);
              ship_cost_list.push(as.ship_cost);
              ship_msg_list.push(as.ship_msg);
              prod_list.push(asData);
            });

            // 묶음주문일 경우 배송비, 배송방법 처리
            if (list.length > 1) {
              item.bundle_ship_cost = {};
              if (ship_method_list.includes('선결제')) {
                item.bundle_ship_method = '선결제';
                item.bundle_ship_cost['최소배송비'] = ship_cost_list.sort()[0];
                item.bundle_ship_cost['최대배송비'] = ship_cost_list.sort()[ship_cost_list.length - 1];
                item.bundle_ship_cost['배송비합'] = _.sum(ship_cost_list);
              } else if (ship_method_list.includes('무료배송')) {
                item.bundle_ship_method = '무료배송';
              } else {
                item.bundle_ship_method = '착불';
                item.bundle_ship_cost['최소배송비'] = ship_cost_list.sort()[0];
                item.bundle_ship_cost['최대배송비'] = ship_cost_list.sort()[ship_cost_list.length - 1];
                item.bundle_ship_cost['배송비합'] = _.sum(ship_cost_list);
              }

              // 우체국 택배의 경우 묶음주문건에 조건부배송이 포함되어있는경우 신용으로 전송필요.
              if (ship_method_list.includes('조건부배송')) {
                item.ship_method_etc = '신용';
              }
            }

            item.prod_list = prod_list;
            item.ship_msg = _.uniq(_.filter(ship_msg_list)).join(',');
            tempData.push(item);
          });

          $scope.selectList = tempData;
        }).catch(() => {
          commonSVC.showToaster('error', 'A/S주문', 'A/S주문 상세보기 조회가 실패되었습니다.');
        });

    }

    init();

    /**
     * 택배사 선택
     */
    $scope.deliverySelect = (deli) => {
      $(`#delivery_${deli.carr_no}_${deli.carr_id}`).prop('checked', true);
      $scope.selectedDeli = deli;

      loadTemplate();
    };

    // carr_no, carr_id로 템플릿 리스트 로드
    function loadTemplate() {
      const data = {
        carr_no: $scope.selectedDeli.carr_no,
        carr_id: $scope.selectedDeli.carr_id,
        carr_id_sub: $scope.selectedDeli.carr_id_sub,
        isAs: true
      };

      deliveryModel.DeliveryTemplateList(data, function (state, res) {
        if (state === 'success') {
          $scope.templateList = res.results || [];
        } else {
          commonSVC.showToaster('error', '실패', '양식 조회 실패');
        }
      });

      // 선택 양식이 승인완료된 양식인경우 출력버튼 활성화.
      $scope.refusePrint = !_.includes(approveCarrierLists, `${$scope.selectedDeli.carr_no}+${$scope.selectedDeli.carr_id}`);
    }

    /**
     * 양식 선택
     */
    $scope.templateSelect = (temp) => {
      $(`#template${temp.template_no}`).prop('checked', true);  // 라디오박스 체크
      $scope.selectedTemp = temp;

      const tempData = [];
      let itemDetail = [];
      let prodListDetails = [];
      const total_page_cnt = $scope.selectList.length;

      _.forEach($scope.selectList, function(as, idx) {
        as.prod_list_temp = angular.copy(as.prod_list);

        if (as.bundle_ship_method) {
          if (as.bundle_ship_method == '무료배송' || as.bundle_ship_method == '선결제' || as.bundle_ship_method == '선불' || as.bundle_ship_method == '신용') {
            as.bundle_ship_method = $scope.selectedTemp.sett_ship_free_txt;
          } else {
            as.bundle_ship_method = $scope.selectedTemp.sett_ship_cod_txt != '' ? $scope.selectedTemp.sett_ship_cod_txt : '착불';
          }
        }

        as.total_page_cnt = total_page_cnt;
        // 현제 출력 페이지
        as.current_page_cnt = idx + 1;
        // 총 품목갯수
        as.prod_list_cnt = 0;

        // 우체국 택배의 경우 묶음주문에 조건부 배송이 포함되어있는경우 '신용' 으로 전송해야 함
        if ($scope.selectedTemp.carr_no === 6 && as.ship_method_etc !== '') {
          as.bundle_ship_method = '신용';
        }

        // 분할인쇄 설정
        if ($scope.selectedTemp.bundle_division_cnt > 0) {
          itemDetail = [];
          prodListDetails = _.chunk(as.prod_list_temp, $scope.selectedTemp.bundle_division_cnt);
          _.forEach(prodListDetails, function(prodListDetail, k) {
            itemDetail = angular.copy(as);
            itemDetail.prod_list_temp = prodListDetail;
            itemDetail.addPage = k === 0 ? 'none' : 'view';
            tempData.push(itemDetail);
          });
        } else {
          tempData.push(as);
        }
      });

      $scope.selectList = tempData;
      // 3단용 데이터 셋팅.
      divideOrder();

      $scope.view = `views/order/shipment/invoice_type/${temp.template_type_cd}.html`;

      if (originView !== $scope.view && $scope.selectedDeli.carr_no != 8 && $scope.selectedDeli.carr_no != 7 && $scope.selectedDeli.carr_no != 6) {
        $scope.wait = true;
        originView = $scope.view;
        $('#spinner').removeClass('hidden');
      }
    };

    // 3단용 데이터
    function divideOrder() {
      $scope.shipmentList = [];
      let tmp = [];

      _.forEach($scope.selectList, function(ord, idx) {
        ord.idx = idx;
        tmp.push(ord);
        if (idx % 3 === 2 || idx === $scope.selectList.length - 1) {
          $scope.shipmentList.push(tmp);
          tmp = [];
        }
      });
    }

    /**
     * 양식 삭제
     */
    $scope.deleteTemplate = function() {
      if ($scope.selectedTemp.template_no) {
        commonSVC.showConfirm('양식을 삭제 하시겠습니까?', '', function () {
          deliveryModel.DeleteDeliveryTemplate({ template_no: $scope.selectedTemp.template_no }, function(state) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', '택배사 양식 삭제 성공');
              setTimeout(() => {
                loadTemplate();
              }, 500);
            } else {
              commonSVC.showToaster('error', '실패', '택배사 양식 삭제 실패');
            }
          });

        });
      } else {
        commonSVC.showMessage('삭제할 양식을 선택하세요.');

        return false;
      }
    };
    /**
     * 양식 수정하기
     */
    $scope.editTemplate = function() {
      if ($scope.selectedTemp.template_no) {
        const resolve = {};

        resolve.data = {
          delivery: angular.copy($scope.selectedDeli),
          template: angular.copy($scope.selectedTemp),
          pageType: 'edit',
          parent: $scope,
          isAs: true
        };
        const modal = commonSVC.openModal('xxg', resolve, 'invoiceTemplateCtrl', 'views/order/shipment/modals/invoice_template.html');

        modal.result.then(() => {
          $timeout(() => {
            $scope.reset();
          }, 500);
        });
      } else {
        commonSVC.showMessage('수정할 양식을 선택하세요.');

        return false;
      }
    };

    /**
     * 양식 추가하기
     */
    $scope.addTemplate = function() {
      if ($scope.selectedDeli.carr_no) {
        const resolve = {};

        resolve.data = {
          delivery: angular.copy($scope.selectedDeli),
          pageType: 'add',
          parent: $scope,
          isAs: true
        };
        const modal = commonSVC.openModal('xxg', resolve, 'invoiceTemplateCtrl', 'views/order/shipment/modals/invoice_template.html');

        modal.result.then(() => {
          $timeout(() => {
            $scope.reset();
          }, 500);
        });
      } else {
        commonSVC.showMessage('택배사를 선택하세요.');

        return false;
      }
    };

    /**
     * 하단 버튼 클릭 (출력, 테스트출력, 미리보기)
     */
    $scope.print = function (type) {
      $scope.type = type;
      if ($scope.selectedDeli.carr_no && $scope.selectedTemp.carr_no) {
        $timeout(function() {
          //실제 출력일 경우 운송장 번호 발급 후 출력
          if (type === 'print') {

            // 출력 택배사가 미승인인경우 버튼비활성화 처리했지만 개발자도구로 속성을 지우고 출력하는 양아치짓을 할수있어 여기서도 막음.
            if ($scope.refusePrint) {
              commonSVC.showMessage('미승인 택배사 입니다.', '승인완료된 택배사만 운송장 출력이 가능합니다.');

              return false;
            }

            if ($scope.selectedTemp.carr_no === 4) {
              // 박스타입 특대(이형)은 대한통운 측의 요청으로 사용 불가능하므로 에러처리함. 2018-11-29. rony
              // 아이온업체는 이형 출력 가능 (대한통운 확인) 2018-12-03 rony
              // print_invoice.js 와 동일하게 수정 2023-03-15 luna
              if (![1, 812, 4804, 4521, 13322, 36160, 74097].includes(userInfo.user.sol_no)) {
                if ($scope.selectedTemp.box_type === '이형' || $scope.selectedTemp.box_type === '특대') {
                  commonSVC.showMessage('박스타입 이형(특대)는 사용이 불가능합니다.', '선택하신 양식의 박스타입을 변경하신 후 재시도해주십시오.\n\n박스 크기 이형의 경우 추가수수료 관련 이슈로 해당 주문건의 경우 CJ대한통운 자체프로그램(CN PLUS)을 통하여 출력을 진행해 주시기 바랍니다.');
                  $scope.wait = false;

                  return false;
                }
              }

              const senderTelNumber = $scope.selectedTemp.sender_tel?.replace(/[^0-9]/g, '');

              // 대한통운은 발송자 전화번호가 필수이기 때문에 없는경우 에러.
              if (!senderTelNumber || senderTelNumber.length < 8) {
                commonSVC.showMessage('발송인 전화번호 에러!', '보내는분 전화번호는 필수 입니다.\n올바른 전화번호로 입력해주십시오.');
                $scope.wait = false;

                return false;
              }

              // 설정한 운임정보가 대한통운 시스템에 등록되어있는지 확인로직 추가 2018-11-29 rony
              deliveryModel.checkDHParams($scope.selectedTemp, function(state, data) {
                if (state === 'success') {
                  if (data.results.check1 !== 'N' && data.results.check2 !== 'N') {
                    setInvoice();
                  } else {

                    const noRegistType = [];

                    if (data.results.check1 === 'N') {
                      noRegistType.push(`▶ 접수구분:일반, 운임구분:${$scope.selectedTemp.sett_ship_free_txt}, 박스타입:${$scope.selectedTemp.box_type}`);
                    }
                    if (data.results.check2 === 'N') {
                      noRegistType.push(`▶ 접수구분:일반, 운임구분:착불, 박스타입:${$scope.selectedTemp.box_type}`);
                    }

                    const title = '송장발행 주의사항 안내';
                    const contents = `대한통운 시스템에 설정하신 운임정보가 없습니다.<br><br>미등록 운임정보: <br>${noRegistType.join('<br>')}<br><br>송장발행을 계속 진행하실 경우, 반드시 대한통운 지점에 연락하시어<br>발행된 데이터의 운임정보를 수정하셔야 합니다.<br><br>위 내용을 따르지 않아 발생한 문제는 당사에서 책임지지 않습니다.<br><br>※ 해당 내용은 CJ대한통운의 권고 사항으로 운송장 양식에 설정된 사항이<br>&nbsp;&nbsp;&nbsp;계약 내용과 다르거나 필수인 착불 운임이 계약되지 않은 경우 보여집니다.<br>&nbsp;&nbsp;&nbsp;계약 사항은 일치 하나 착불 운임이 없는 경우, 박스 크기별로<br>&nbsp;&nbsp;&nbsp;모든 운임에 착불 운임을 추가 계약 해주시기 바랍니다.<br><br>ex.&nbsp;&nbsp;&nbsp;팝업 됨.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ex.&nbsp;&nbsp;&nbsp;팝업 안됨.<br>박스크기-극소(신용O,착불X)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;박스크기-극소(신용O,착불O)<br>박스크기-소 (신용O, 착불O)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;박스크기-소 (신용O, 착불O)`;
                    const buttons = [
                      {
                        name: '확인완료 및 송장발행 계속 진행',
                        next: function() {
                          setInvoice();
                        }
                      },
                      {
                        name: '송장발행 취소',
                        next: function() {
                          return false;
                        }
                      }
                    ];

                    commonSVC.showMultiButton(title, contents, buttons);
                  }
                } else {
                  commonSVC.showMessage('운임정보 조회실패!', '송장발행을 재시도해주십시오.');

                  return false;
                }
              });
            } else {
              setInvoice();
            }
          } else {
            //테스트는 가라 데이터로 출력 이라 바로 출력
            labelPrint(type);
          }
        }, 100);
      } else {
        commonSVC.showMessage('택배사와 양식을 선택하세요.');

        return false;
      }
    };

    // 운송장 번호 발급 - GMP.Carrier.API
    async function setInvoice() {
      try {
        $scope.wait = true;
        // 운송장 출력시 로딩 추가.
        $('#spinner').removeClass('hidden');

        const param = {
          mend_no_list: _.map(selectList.data.results, 'mend_no'),
          templateData: $scope.selectedTemp
        };

        const returnData = await asModel.issue(param);
        const barcodeList = [];
        returnDelivInfo = returnData.data.result;

        if ($scope.selectedDeli.carr_no === 7 || $scope.selectedDeli.carr_no === 8) {
          setStateByIssue(returnDelivInfo);

          return false;
        }

        _.each(returnDelivInfo, function (item) {
          const idx = _.findIndex($scope.selectList, { bundle_no: parseInt(item.bundleNo) });

          $scope.selectList[idx].deli_invoice = item.invoiceNo.toString();

          Object.assign($scope.selectList[idx], item.colDelCd);
          if ($scope.selectedDeli.carr_no === 4) {
          // 대한통운은 좌측 상단의 바코드가 운송장번호가 아닌 도착점 코드로 찍혀야 하기 떄문에 조건문 추가 2018-10-22 rony
          // 바코드 인식이 잘 안된다고 해서 코드 타입 변경 2018-12-14 rony
            barcodeList.push(JsBarcode(`.barcode.dcode_${idx}`, $scope.selectList[idx].dcode, { format: 'CODE128A', font: 'Arial' })); // 집배코드 바코드
            barcodeList.push(JsBarcode(`.barcode.key_${idx}`, $scope.selectList[idx].deli_invoice.replace(/-/g, ''), { format: 'CODE128', font: 'Arial' }));
          } else {
            barcodeList.push(JsBarcode(`.barcode.key_${idx}`, $scope.selectList[idx].deli_invoice.replace(/-/g, ''), { format: 'CODE128', font: 'Arial' }));
          }

          if ($scope.selectedDeli.carr_no == 6) {
          // barcode_zipcd 을 안쓰는 양식이 있어 try catch 처리 함 2018-11-30 rony
            if ($('.barcode_zipcd').length) {
              barcodeList.push(JsBarcode(`.barcode_zipcd.key_${idx}`, $scope.selectList[idx].to_zipcd.replace(/-/g, ''), { format: 'CODE128', font: 'Arial' })); //,  displayValue :false
            }

            //delivAreaCd 파싱
            $scope.selectList[idx].delivAreaCd = item.colDelCd.delivAreaCd.split(/(.{2})(.{3})(.{2})(.{2})/);
          }
        });

        $timeout(() => {}, 2000);

        if (($scope.selectedDeli.carr_no != 7 && $scope.selectedDeli.carr_no != 8) && barcodeList.length) {
          Promise.all(barcodeList).then(() => labelPrint('print'));
        }

        $('#spinner').addClass('hidden');
      } catch (err) {
        commonSVC.showToaster('error', '실패', '운송장 출력에 실패하였습니다.');
        $('#spinner').addClass('hidden');
        $scope.wait = false;
      }
    }

    // 출력
    function labelPrint(type) {
      const printDivs = document.getElementsByClassName('print-content-example');
      const style = document.getElementById('invoice-print-style');
      const features = 'menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1500,height=800,left=150,top=150';
      const printPage = window.open('about:blank', 'shipmentPrint', features); // 새창을 열어줌
      let script = '';

      // 스크립트는 실제 프린트시에만 적용.
      if (type === 'print') {
        script = '<script>' +
        'if (window.matchMedia) {' +
        'var mQuery = window.matchMedia(\'print\');' +
        'mQuery.addListener(function (mql) {' +
        'if (mql.matches) {' +
              'opener.parent.beforePrint();' +
            '} else {' +
              'opener.parent.afterPrint();' +
            '}' +
          '});' +
        '}' +
        '</script>';
      }

      printPage.document.open();
      printPage.document.write(`${'<html><head>' +
        '<style>'}${
        style.innerText
      }</style>${
        script
      }</head>\n<body>`);

      // 반복문을 돌면서 주문상세내역들을 body 에 그려준다
      angular.forEach(printDivs, function (div) {
        printPage.document.write(div.innerHTML);

      });

      printPage.document.write('\n</body></html>');
      printPage.document.close();

      // $timeout(function() {
      if (type !== 'preview') {
        printPage.focus();
        printPage.print();
        printPage.close();
      }
      $scope.wait = false;
      // }, 1000);
    }

    /**
     * 인쇄 다이얼로그가 닫힌 후 호출. ( 인쇄, 또는 취소 버튼 클릭 )
     */
    window.afterPrint = function () {
      commonSVC.showConfirm('AS상태를 [AS출고]로 변경하시겠습니까?', '- 운송장이 정상적으로 출력된경우 [확인]버튼을 클릭하여 상태를 변경해주십시오.\n- 운송장이 정상적으로 출력되지않은경우 [취소] 버튼을 클릭하신 후 재출력하여 주십시오.', function (re) {
        if (re) {
          setStateByIssue(returnDelivInfo);
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    function setStateByIssue (bundleList) {
      const sendParams = {
        mend_list: bundleList.map((bundle) => { return { bundle_no: bundle.bundleNo, invoice_no: bundle.invoiceNo }; }),
        carr_no: $scope.selectedDeli.carr_no,
        carr_name: $scope.selectedDeli.carr_name
      };

      asModel.setStateByIssue(sendParams)
        .then(() => {
          $uibModalInstance.close('success');
        })
        .catch((err) => {
          commonSVC.showToaster('error', '실패', err.data.error);
        });
    }

    /**
     * 출력양식 view 변경 시 load된 후 타는 곳
     */
    $scope.setBarcode = function() {
      if ($scope.view) {
        $timeout(function() {
          JsBarcode('.barcode', '0000000000', {
            format: 'CODE128'
          });
          if ($scope.selectedDeli.carr_no == 6) {
            // barcode_zipcd 이 없는 양식이 있기 때문에 확인
            if ($('.barcode_zipcd').length) {
              JsBarcode('.barcode_zipcd', '12345', {
                format: 'CODE128'
              });
            }
          }
          $scope.wait = false;
          $('#spinner').addClass('hidden');
        }, 500);
      }
    };
  });