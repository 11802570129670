/**
 * 출고보류 처리
 * Created by ally on 2017. 7. 30..
 */
'use strict';

angular.module('gmpApp')
  .controller('orderPackageCtrl', function (// common
    $state, $rootScope, $scope, $timeout, $filter, $uibModalInstance, gettextCatalog, data,
    // info
    settings, userInfo,
    // SVC
    commonSVC,
    // Model
    shipmentModel, deliveryModel,
    // List
    warehouseList, systemList, countryList, solCarrList, globalCarrList
  ) {
    const channelList = angular.copy($rootScope.use_channel_list);

    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelList, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    /* 검색 및 버튼 관련 변수 */
    $scope.package = {}; //패키지 관련 변수
    $scope.packageData = {}; //선택한 패키지데이터
    $scope.order = {}; //주문 리스트 관련 변수
    $scope.orderData = {}; //선택한 주문데이터
    $scope.setAllData = {}; // 일괄 입력 관련 변수

    $scope.pkgCountList = { package: 0, issue: 0 }; //  패키지 카운트
    $scope.ordCountList = { pack: 0, unPack: 0 }; //주문 카운트

    $scope.prodCountList = { prod: {}, set: {} }; // 매칭 수량 입력
    $scope.pkgTab = 'package'; // 패키지, 송장 출력 주문 탭
    $scope.ordTab = 'pkg_n'; // 패키징 주문, 미패키징 주문 탭
    $scope.prevSelectedPkgNo = '';

    // 모달인 경우에만 스타일 추가
    $scope.isModal = true;
    $scope.ordTableStyle = {
      package: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' },
      order: { 'height': '100%', 'overflow': 'hidden', 'overflow-y': 'auto' }
    }; //테이블 컨테이너 스타일

    let prodList = []; //주문리스트 SKU 상품 정보

    $scope.selectedCount = 0;

    init();

    /*****************************
       *    패 키 지 DataTable       *
       * ***************************/
    const package_search = {
      searchForm: {
        sdate: data.sdate,
        edate: data.edate,
        search_key: '__ALL__',
        search_word: '',
        shop_id: '',
        shop_cd: '',
        carr_no: '',
        page: 'unstoring',
        isIssued: false
      },
      searchData: {
        searchAreaClass: 'col-xs-9',
        searchAreafull: false,
        showDateSearch: false,
        haveBoxContent: false,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), test_id: 'btn-select-all', value: 'all' },
          { label: gettextCatalog.getString('상품명'), test_id: 'btn-shop-sale-name', value: 'shop_sale_name' },
          { label: gettextCatalog.getString('묶음번호'), test_id: 'btn-bundle-no', value: 'bundle_no' },
          { label: gettextCatalog.getString('패키지번호'), test_id: 'btn-pa-bundle-no', value: 'pa_bundle_no' },
          { label: gettextCatalog.getString('쇼핑몰 주문번호'), test_id: 'btn-shop-ord-no', value: 'shop_ord_no' },
          { label: gettextCatalog.getString('국내운송장번호'), test_id: 'btn-invoice-no', value: 'invoice_no' },
        ]
      },
      searchDetail: [
        {
          // 채널 선택
          title: gettextCatalog.getString('쇼핑몰 선택'),
          search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
          item_list: commonSVC.getSiteList(channelList),
          item_key: 'shop_name',
          item_value: 'shop_cd',
          select_value: '',
          filter: function(option) {
            return option.shop_cd.startsWith('X');
          }
        },
        {
          // 채널 계정 선택
          title: gettextCatalog.getString('계정 선택'),
          search_name: 'shop_id',
          item_list: commonSVC.getSiteIdList(channelList),
          item_key: 'shop_id',
          item_value: 'search_shop_id',
          select_value: '',
          filter: function(option) {
            return _.intersection([$scope.package.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
          }
        },
        {
          title: gettextCatalog.getString('국내택배사 선택'),
          search_name: 'carr_no',
          item_list: $rootScope.deliveryInfoList,
          item_key: 'carr_name',
          item_value: 'carr_no',
          select_value: ''
        }
      ]
    };

    // 검색영역 버튼 설정
    $scope.table_actions = [
      {
        label: '<span class="pl-5">운송장발행</span> <span class="caret"></span>',
        btn_type: 'dropdown',
        item_list: [
          {
            label: gettextCatalog.getString('Shopee(SPS)'),
            action: function () {
              $scope.printInvoice('shopee');
            },
          }, {
            label: gettextCatalog.getString('기타 국내 택배사'),
            action: function () {
              $scope.printInvoice('domestic');
            },
          }
        ]
      }
    ];

    $scope.package.searchData = angular.copy(package_search.searchData);
    $scope.package.searchForm = angular.copy(package_search.searchForm);
    $scope.package.searchDetail = angular.copy(package_search.searchDetail);
    $scope.package.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, 'package');
      },
      resetDo: function() {
        $scope.prevSelectedPkgNo = '';
        $scope.resetDo('package');
      },
      changeLen: function(count) {
        $scope.changeCount('package', count);
      }
    };
    $scope.package.methods = {};
    $scope.package.options = {
      modal: $scope.isModal,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'pa_bundle_no', 'package_cnt'],
      alignRightColumns: [],
      defaultSortingColumns: ['pa_bundle_no'],
      notSortingColumns: ['widget', 'package_cnt' ],
      notResizingColumns: ['widget'],
      notMovingColumns: ['widget'],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/package/list`,
        requestWillAction: function(data) {
          $scope.package.searchForm.isIssued = $scope.pkgTab !== 'package';
          data = angular.merge({}, data, $scope.package.searchForm);

          // 배송처일 때 해당 배송처 데이터만 검색되도록 수정 #gmpkr-7591 2019-09-18 Jacob
          if ($rootScope.user_profile.auth_type === '배송처') {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          return data;
        },
        requestDidAction: function(result) {
          $scope.pkgCountList.package = result.recordsPkgTotal;
          $scope.pkgCountList.issue = result.recordsIssueTotal;
          $scope.package.searchData.totalCount = $scope.pkgTab === 'package' ? result.recordsPkgTotal : result.recordsIssueTotal;

          // SPS 택배 가능한 주문 존재하는 지 체크
          $scope.checkShopeeSPS = result.results?.some(pkg => pkg.pa_shop_cd !== 'X099');

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function(row) {
            return `<button ng-class="{'bg-orange-300': row.entity.pa_bundle_no === grid.appScope.prevSelectedPkgNo, 'btn-default': row.entity.pa_bundle_no !== grid.appScope.prevSelectedPkgNo}" class="btn btn-xxs mr-5" ng-click="grid.appScope.showOrdSearch('${row.pa_bundle_no}')" >주문보기</button>`;
          }
        },
        {
          key: 'pa_bundle_no',
          title: '패키지번호',
          width: 130,
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰',
          width: 120,
          template: function(row) {
            return `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">${row.shop_name}`;
          }
        },
        {
          key: 'package_cnt',
          title: '패키지 주문 수',
          width: 100,
          tooltip: {
            text: '패키지 되어있는 총 주문 수 \n- 개별주문(묶음주문)',
            placement: 'left'
          },
          template: function(row) {
            return `${$filter('number')(row.ord_cnt, '', 0)} (${$filter('number')(row.bundle_cnt, '', 0)})`;
          }
        },
        {
          key: 'carr_no',
          title: '국내택배사',
          width: 70,
          template: function(row) {
            return $rootScope.deliveryInfoList.find(carr => carr.carr_no === row.carr_no)?.carr_name || '';
          }
        },
        {
          key: 'invoice_no',
          title: '국내운송장번호',
          width: 110,
          template: function(row) {
            const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
            const carr_no = row.carr_no;

            if (
              carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
            ) {
              return (
                `<span>${
                  invoice_no
                }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                  carr_no
                }','${
                  invoice_no.replace(/-/gi, '')
                }')"></i></span>`
              );
            } else {
              return row.invoice_no;
            }
          }
        },
      ]
    };

    /*****************************
     *       주 문 DataTable      *
     * ***************************/
    const order_search = {
      searchForm: {
        search_key: '__ALL__',
        search_word: '',
        shopType: 'global',
        date_type: 'wdate',
        sdate: data.sdate,
        edate: data.edate,
        bundle_yn: true,
        site_id: '',
        site_code: '',
        status: ['출고대기', '출고보류', '운송장출력'],
        ord_status_stat: ['출고대기', '출고보류', '운송장출력'],
        page: 'unstoring',
        bySearchDetail: false,
        isPackaged: false
      },
      searchData: {
        searchAreaClass: 'col-xs-9',
        searchAreafull: false,
        showDateSearch: false,
        haveBoxContent: false,
        showDetailSearchArea: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), test_id: 'btn-select-all', value: '__ALL__' },
          { label: gettextCatalog.getString('상품명'), test_id: 'btn-shop-sale-name', value: 'shop_sale_name' },
          { label: gettextCatalog.getString('묶음번호'), test_id: 'btn-bundle-no', value: 'bundle_no' },
          { label: gettextCatalog.getString('패키지번호'), test_id: 'btn-pa-bundle-no', value: 'pa_bundle_no' },
          { label: gettextCatalog.getString('쇼핑몰 주문번호'), test_id: 'btn-shop-ord-no', value: 'shop_ord_no' },
          { label: gettextCatalog.getString('국내운송장번호'), test_id: 'btn-invoice-no', value: 'invoice_no' },
        ]
      },
      searchDetail: [
        {
          // 채널 선택
          title: gettextCatalog.getString('쇼핑몰 선택'),
          search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
          item_list: commonSVC.getSiteList(channelList),
          item_key: 'shop_name',
          item_value: 'shop_cd',
          select_value: '',
          filter: function(option) {
            return option.shop_cd.startsWith('X');
          }
        },
        {
          // 채널 계정 선택
          title: gettextCatalog.getString('계정 선택'),
          search_name: 'shop_id',
          item_list: commonSVC.getSiteIdList(channelList),
          item_key: 'shop_id',
          item_value: 'search_shop_id',
          select_value: '',
          filter: function(option) {
            return _.intersection([$scope.order.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
          }
        },
        {
          title: gettextCatalog.getString('해외택배사 선택'),
          search_name: 'global_carr_no',
          item_list: globalCarrList,
          item_key: 'carr_view_name',
          item_value: 'carr_no',
          select_value: '',
          filter: function (option) {
            return option.carr_no !== -1;
          },
        },
      ]
    };

    $scope.order.searchData = angular.copy(order_search.searchData);
    $scope.order.searchForm = angular.copy(order_search.searchForm);
    $scope.order.searchDetail = angular.copy(order_search.searchDetail);
    $scope.order.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, 'order');
      },
      resetDo: function() {
        $scope.resetDo('order');
      },
      changeLen: function(count) {
        $scope.changeCount('order', count);
      }
    };

    $scope.order.methods = {};
    $scope.order.options = {
      modal: $scope.isModal,
      pinningColumns: [],
      alignCenterColumns: [ 'widget', 'shop_name', 'ord_status', 'bundle_no'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['widget', 'pack_unit', 'total_cnt'],
      notResizingColumns: ['widget'],
      notMovingColumns: ['widget'],
      notVisibleColumns: ['wdate', 'pa_bundle_no', 'bundle_no', 'shop_ord_no', 'shop_sale_no', 'sku_cd', 'attri', 'pack_unit', 'total_cnt', 'carr_name', 'prod_name'],
      bundleOptions: {
        bundleCountKey: 'selectBundleCnt',
        bundleDataKey: 'bundle_no',
        bundleUniqKey: 'uniq'
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/package/listOfOrder`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.order.searchForm);

          data.sol_no = $rootScope.user_profile.sol_no;

          const prodList = $scope.order.methods.selectedData('all');
          // 출고수량 저장
          prodList.forEach(p => p.pack_unit = $scope.prodCountList.prod[`${p.prod_no}_${p.depot_no}`]);

          return data;
        },
        requestDidAction: function(result) {

          prodList = [];
          result.results_prod.forEach(function(subRow) {
            if (subRow.add_opt_yn != 1) {

              if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

              prodList[subRow.uniq].push(subRow);

            }
          });
          $scope.order.searchData.totalCount = result.recordsTotalCount;

          $scope.ordCountList.pack = $scope.order.searchForm.isPackaged ? result.recordsTotalCount : result.recordsOtherCount;
          $scope.ordCountList.unPack = $scope.order.searchForm.isPackaged ? result.recordsOtherCount : result.recordsTotalCount;

          // 합포장 갯수
          $scope.order.bundleCnt = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 45,
          template: function(row) {
            return `<button class="btn btn-primary btn-xxs mr-5" ng-click="grid.appScope.showOrderDetail('${row.uniq}')" >상세</button>`;
          }
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
            }

            return img;
          }
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return (
              `<span id="${row.uniq}_ordStatus">${$filter('statusColor')(row.ord_status)}</span>`
            );
          }
        },
        {
          key: 'pa_bundle_no',
          title: '패키지번호',
          width: 130
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          name: 'bundle_no',
          width: 170
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호1',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 300,
          notCompile: true,
          template: function(row) {
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return (
                `<span>
                  <img src='${
                img
                }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                  filteredShopSaleName
                }</span>`
              );
            } else {
              return `<span>${filteredShopSaleName}</span>`;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function(row) {

            if (row.shop_sale_no) {
              return (
                `<span>${row.shop_sale_no}
                    <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_cd}','${row.shop_id}','${row.shop_sale_no}')"></i></span>`
              );
            } else {
              return row.shop_sale_no;
            }
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU(세트)코드',
          requireStock: true,
          width: 160,
          template: function(row) {
            if (row.set_no && prodList[row.uniq]) {
              return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq].length > 1 ?
                `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                : prodList[row.uniq][0].sku_cd;
            } else {
              return '미매칭 출고';
            }
          }
        },
        {
          key: 'prod_name',
          title: 'SKU(세트)상품명',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'attri',
          title: 'SKU속성',
          width: 130,
          notCompile: true,
          template: function(row) {
            return prodList[row.uniq]?.filter(prod => prod.attri).map(prod => prod.attri).join(',') || '';
          }
        },
        {
          key: 'pack_unit',
          title: '건별출고수량',
          tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
          requireStock: true,
          width: 110,
          notCompile: true
        },
        {
          key: 'total_cnt',
          title: '총 출고수량',
          tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
          width: 100,
          template: function(row) {
            return row.out_cnt || '';
          }
        },
        {
          key: 'global_carr_name',
          title: '해외택배사',
          width: 140
        },
        {
          key: 'global_invoice_no',
          title: '트래킹번호',
          width: 140
        },
        {
          key: 'carr_name',
          title: '국내택배사',
          width: 120
        },
        {
          key: 'invoice_no',
          title: '국내운송장번호',
          width: 150,
          template: function(row) {
            const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
            const carr_no = row.carr_no;

            if (
              carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
            ) {
              return (
                `<span>${
                  invoice_no
                }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                  carr_no
                }','${
                  invoice_no.replace(/-/gi, '')
                }')"></i></span>`
              );
            } else {
              return row.invoice_no;
            }
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'to_ctry_cd',
          title: '수령자 국가',
          width: 80,
          template: function(row) {
            return countryList.find(country => country.ctry_cd === row.to_ctry_cd)?.ctry_name || '';
          }
        },
      ]
    };

    /**********************************
     *  action
     *********************************/

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, type) {

      // 다른 패키지 번호로 검색 시 패키지 리스트 주문보기 버튼 주황색 제거
      if (type === 'order' && $scope.order.searchForm.search_word !== $scope.prevSelectedPkgNo) {
        $scope.prevSelectedPkgNo = '';
      }
      $scope[type].methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function(type) {
      const searchForm = type === 'package' ? package_search.searchForm : order_search.searchForm;
      const searchDetail = type === 'package' ? package_search.searchDetail : order_search.searchDetail;

      $scope[type].searchForm = angular.copy(searchForm);
      $scope[type].searchData.search_key_name = '전체';
      $scope[type].searchDetail = angular.copy(searchDetail);

      $scope.searchDo(true, true, type);
    };

    /**
       * 데이터테이블 pageLength 조절
       */
    $scope.changeCount = function (type) {
      $scope[type].methods.length($scope[type].searchData.showCount);
    };

    /**
     * 카운트 버튼
     */
    $scope.countPkgSearch = function(type) {
      $scope.package.searchForm.isIssued = type !== 'package';

      $scope.pkgTab = type;
      $scope.searchDo(true, true, 'package');
    };

    $scope.countOrdSearch = function(type) {
      $scope.order.searchForm.isPackaged = type !== 'pkg_n';

      $scope.ordTab = type;
      $scope.searchDo(true, true, 'order');
    };

    /**
       * 주문상세페이지 보여주기
       */
    $scope.showOrderDetail = function(uniq) {
      const resolve = {
        data: {
          fromPage: 'package',
          uniq: uniq,
          warehouseList: warehouseList.data.result || [],
          systemList: systemList.data || []
        }
      };
      const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * 배송정보 일괄 업데이트
     */
    $scope.setDelivInfo = () => {
      if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
        return false;
      }
      const selected = $scope.package.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택한 주문이 없습니다.');

        return false;
      }

      if (!$scope.setAllData) {
        commonSVC.showMessage('일괄적용할 항목을 선택해주십시오.');

        return false;
      }

      if ($scope.setAllData.type === 'carr_no' && !$scope.setAllData.carr_no) {
        commonSVC.showMessage('택배사를 선택해 주세요.');

        return false;
      }

      if ($scope.setAllData.type === 'invoice_no' && !$scope.setAllData.invoice_no) {
        commonSVC.showMessage('국내 운송장 번호를 입력해 주세요.');

        return false;
      }

      const delivInfoUpdateList = {};
      let checkCarr = false;
      selected.forEach(ord => {
        if ($scope.setAllData.type === 'carr_no') {
          $scope.setAllData.carr_name = $rootScope.deliveryInfoList.find(carr => carr.carr_no === $scope.setAllData.carr_no).carr_name;

          delivInfoUpdateList[ord.pa_bundle_no] = { carr_no: $scope.setAllData.carr_no, carr_name: $scope.setAllData.carr_name };
        } else {
          if (!ord.carr_no) {
            checkCarr = true;
          }
          delivInfoUpdateList[ord.pa_bundle_no] = { carr_no: ord.carr_no, invoice_no: $scope.setAllData.invoice_no };
        }
      });

      if (checkCarr) {
        commonSVC.showMessage('운송장 번호 입력 시 택배사는 필수입니다.');

        return false;
      }

      commonSVC.showConfirm('일괄입력', `선택한 ${selected.length}건의 패키지 정보를 일괄 입력합니다.`, function (re) {
        if (re) {
          deliveryModel.setDelivInfo({ delivInfoUpdateList, isPkgNo: true }, (res, error) => {
            if (res === 'success') {
              commonSVC.showToaster('success', '성공', '배송정보 일괄 입력에 성공하였습니다.');
              $timeout(() => {
                $scope.searchDo(true, true, 'package');
              }, 500);
              $scope.setAllData = {};

            } else {
              commonSVC.showToaster('error', '실패', '배송정보 일괄 입력에 실패하였습니다.');
              $scope.setAllData = {};
            }
          });
        }
      });
    };

    /**
      * 탭닫기
      */
    $scope.closeTab = function() {
      $rootScope.$emit('$closeTab', 'tab_order_shipment_packaging');
    };

    $scope.showOrdSearch = function(pa_bundle_no) {
      $scope.prevSelectedPkgNo = pa_bundle_no;

      $scope.order.searchForm.search_key = 'pa_bundle_no';
      $scope.order.searchData.search_key_name = '패키지번호';
      $scope.order.searchForm.search_word = pa_bundle_no;
      $scope.order.searchForm.bySearchDetail = true;

      $scope.searchDo(true, true, 'order');

      if (!$scope.ordCountList.unpack) {
        $scope.countOrdSearch('pkg_y');
      }
    };

    $scope.close = function () {
      $uibModalInstance.close('success');
    };

    $scope.showMultiSKUDetail = (uniq) => {
      const resolve = {
        data: { prodList: prodList[uniq] }
      };

      commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
    };

    $scope.showSetDetail = function(setNo) {
      const resolve = {
        data: { from: '수정', set_no: setNo }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    /**
       * 테이블에서 선택 변경 시
       */
    $scope.$on('OnSelectChangeBefore', function(event, data) {
      $(data.targetClasses).addClass('selected');
    });

    /**
     * 테이블에서 선택해제시
     */
    $scope.$on('OnDeSelected', function(event, data) {
      $(data.targetClasses).removeClass('selected');
    });

    $scope.addPackage = () => {
      const selected = $scope.order.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택한 주문이 없습니다.');

        return false;
      }

      // 서로 다른 쇼핑몰의 경우 패키지 생성이 각각 되야 됨
      const data = Object.values(_.groupBy(selected, 'pa_shop_cd')).map(ords => {
        return ords.map(ord => ord.bundle_no);
      });

      if (data.length > 1) {
        commonSVC.showConfirmCustom({
          title: '쇼핑몰이 다른 주문이 선택되었습니다.<br>쇼핑몰 별로 패키지를 생성하시겠습니까?',
          text: '<div style="display: none;"></div>',
          html: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소'
        }, async (confirm) => {
          if (confirm) {
            shipmentModel.addPackage({ bundle_codes: data }, (state, data) => {
              if (data === 'success') {
                commonSVC.showToaster('success', '성공', '패키지 생성에 성공하였습니다.');
                $timeout(() => {
                  $scope.searchDo(true, true, 'order');
                  $scope.searchDo(true, true, 'package');
                }, 500);
              } else {
                commonSVC.showToaster('error', '실패', '패키지 생성에 실패하였습니다.');
              }
            });
          }
        });
      } else {
        shipmentModel.addPackage({ bundle_codes: data }, (state, data) => {
          if (data === 'success') {
            commonSVC.showToaster('success', '성공', '패키지 생성에 성공하였습니다.');
            $timeout(() => {
              $scope.searchDo(true, true, 'order');
              $scope.searchDo(true, true, 'package');
            }, 500);

          } else {
            commonSVC.showToaster('error', '실패', '패키지 생성에 실패하였습니다.');
          }
        });
      }
    };

    $scope.combinePackage = () => {
      const selected = $scope.package.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택한 패키지가 없습니다.');

        return false;
      } else if (selected.length === 1) {
        commonSVC.showMessage('패키지를 2개 이상 선택해 주세요.');

        return false;
      }

      let totalOrd = 0;
      selected.forEach(ord => {
        totalOrd += ord.ord_cnt;
      });

      if (totalOrd > 1000 || Object.keys(_.groupBy(selected, 'pa_shop_cd')).length > 1) {
        commonSVC.showMessage('패키지 합치기가 불가능합니다.', '- 주문 1000건 이하만 하나의 패키지로 처리가 가능합니다.\n-쇼핑몰이 같은 패키지만 합칠 수 있습니다.');

        return false;
      }
      const resolve = {
        data: {
          data: selected
        }
      };

      if (selected.some(ord => ord.carr_no || ord.invoice_no)) {
        const modal = commonSVC.openModal('', resolve, 'OrderPackageCombineCtrl', 'views/order/shipment/modals/order_package_combine.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $timeout(() => {
              $scope.searchDo(true, true, 'package');
            }, 500);
          }
        });

      } else {
        commonSVC.showConfirmCustom({
          title: '선택한 패키지를 합치겠습니까?',
          text: '- 선택한 패키지 모두 하나의 패키지번호로 변경됩니다.',
          confirmButtonText: '확인',
          cancelButtonText: '취소'
        }, async (confirm) => {
          if (confirm) {
            await shipmentModel.combinePackage({ pa_bundle_codes: selected.map(shop => shop.pa_bundle_no) }, (state, data) => {
              if (data === 'success') {
                commonSVC.showToaster('success', '성공', '패키지 합치기에 성공하였습니다.');
                $timeout(() => {
                  $scope.searchDo(true, true, 'package');
                }, 500);
              } else {
                commonSVC.showToaster('error', '실패', '패키지 합치기에 실패하였습니다.');
              }
            });
          }
        });
      }
    };

    $scope.deletePackage = (isPkgNo) => {
      const selected = isPkgNo ? $scope.package.methods.selectedData('all') : $scope.order.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage(`선택한 ${isPkgNo ? '패키지가' : '주문이'} 없습니다.`);

        return false;
      }

      commonSVC.showConfirmCustom({
        title: '패키지를 삭제하시겠습니까?',
        text: '<div style="font-size: 15px">- 삭제한 패키지 주문 건은 [패키지 생성] 작업을 다시 진행 후 국내 운송장 출력이 가능합니다.<br>- 송장 발행 완료 패키지일 경우 입력된 국내택배사, 국내운송장 번호는 삭제됩니다.</div>',
        html: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }, async (confirm) => {
        if (confirm) {
          shipmentModel.deletePackage({ bundle_codes: selected.map(shop => shop.pa_bundle_no), isPkgNo }, (state, data) => {
            if (data === 'success') {
              commonSVC.showToaster('success', '성공', '패키지 삭제에 성공하였습니다.');
              $timeout(() => {
                $scope.searchDo(true, true, 'order');
                $scope.searchDo(true, true, 'package');
              }, 500);
            } else {
              commonSVC.showToaster('error', '실패', '패키지 삭제에 실패하였습니다.');
            }
          });
        }
      });
    };

    $scope.dividePackage = () => {
      const selected = $scope.order.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택한 주문이 없습니다.');

        return false;
      }

      commonSVC.showConfirm('패키지를 분리하시겠습니까?', '', async (confirm) => {
        if (confirm) {
          shipmentModel.dividePackage({ bundle_codes: selected.map(shop => shop.bundle_no) }, (state, data) => {
            if (data === 'success') {
              commonSVC.showToaster('success', '성공', '패키지 분리에 성공하였습니다.');
              $timeout(() => {
                $scope.searchDo(true, true, 'order');
                $scope.searchDo(true, true, 'package');
              }, 500);
            } else {
              commonSVC.showToaster('error', '실패', '패키지 분리에 실패하였습니다.');
            }
          });
        }
      });
    };

    /**
     * 운송장출력 기본설정 모달 여는 버튼
     */
    $scope.openInvoiceSettingsModal = function () {
      const resolve = {
        data: {
          channelList: channelList.data.results,
          matchShopAddrList: $scope.matchShopAddrList,
          addrTemplateList: $scope.addrTemplateList
        }
      };
      const modal = commonSVC.openModal('xl', resolve, 'DomesticPrintSettingModalController', 'views/order/shipment/modals/domesticPrintSettingModal.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          init();
        }
      });
    };

    /**
     * 운송장 출력 버튼
     */
    $scope.printInvoice = async (carrType) => {

      const printType = $scope.pkgTab === 'issued' ? 'reprint' : 'print';

      if (commonSVC.checkPermission('shipping.roles.printInvoice', userInfo.permission) === false) {
        return false;
      }

      const selected = $scope.package.methods.selectedData('all');

      if (!selected.length) {
        commonSVC.showMessage('선택한 패키지가 없습니다.');

        return false;
      }

      const carr_no = selected.map(ord => ord.carr_no);
      const invoice_no = selected.map(ord => ord.invoice_no);

      if (printType === 'reprint') {

        if (_.uniq(carr_no).length > 1) {
          commonSVC.showMessage('실패', '택배사가 다른 패키지가 있습니다.');

          return false;
        } else if (!_.compact(carr_no).length || !_.compact(invoice_no)) {
          commonSVC.showMessage('실패', '택배사 정보가 없습니다.');

          return false;
        }

        if (selected.some(pkg => !pkg.invoice_time)) {
          if (selected.length === 1) {
            commonSVC.showMessage('운송장 출력이 불가능한 패키지 입니다.');

            return false;
          } else {
            const opt = {
              title: '운송장 출력이 불가능한 패키지가 포함되어 있습니다.',
              text: ' ',
              confirmButtonText: '가능한 패키지만 선택'
            };

            commonSVC.showConfirmCustom(opt, function (re) {
              if (re) {
                $scope.package.methods.unSelectByFilter(function (r) {
                  return !r.invoice_time;
                }, true);

                return false;
              }
            });

            return false;
          }
        }
      } else {
        if (selected.some(ord => ord.cantPrintYn)) {
          if (selected.length === 1) {
            commonSVC.showMessage('운송장 출력이 불가능한 패키지 입니다.');

            return false;
          } else {
            const opt = {
              title: '운송장 출력이 불가능한 패키지가 포함되어 있습니다.',
              text: ' ',
              confirmButtonText: '가능한 패키지만 선택'
            };

            commonSVC.showConfirmCustom(opt, function (re) {
              if (re) {
                $scope.package.methods.unSelectByFilter(function (r) {
                  return r.cantPrintYn;
                }, true);

                return false;
              }
            });

            return false;
          }
        }
      }

      if (carrType === 'shopee') {
        if ($scope.checkShopeeSPS) {
          commonSVC.showConfirm('Shopee(SPS)로 발행할 수 있는 패키지가 없습니다.');

          return false;
        }
        if (selected.some(ord => ord.pa_shop_cd !== 'X099')) {
          const opt = {
            title: 'Shopee(SPS)로 발행이 불가능한 패키지가 있습니다.',
            text: ' ',
            confirmButtonText: '가능한 패키지만 선택'
          };

          commonSVC.showConfirmCustom(opt, function (re) {
            if (re) {
              $scope.package.methods.unSelectByFilter(function (r) {
                return r.pa_shop_cd !== 'X099';
              }, true);

              return false;
            }
          });
        } else {
          const pickupAddrInfo = $scope.matchShopAddrList.find(shop => shop.pa_shop_cd === 'X099');

          if (!pickupAddrInfo.fromAddr) {
            commonSVC.showMessage('기본설정 후 송장발행을 진행해 주세요.');

            return false;
          }

          const resolve = {
            data: {
              channelList: channelList.data.results,
              orderList: selected,
              addrTemplateList: $scope.addrTemplateList,
              pickupAddrInfo: $scope.matchShopAddrList.find(shop => shop.pa_shop_cd === 'X099'),
              printType: printType
            }
          };
          if (printType === 'reprint') {
            resolve.windowClass = 'global-pickup-reprint-modal';
          }
          commonSVC.openModal(`${printType === 'reprint' ? 'lm' : 'xl'}`, resolve, 'ShopeeCreatePickupController', 'views/order/shipment/modals/shopeeCreatePickup.html').result.then(() => {
            $scope.searchDo(true, true, 'package');
          });
        }

      } else if (carrType === 'domestic') {
        if (solCarrList.data.results.length === 0) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $uibModalInstance.close();

            $state.go('main.settings_delivery');
          });
        }

        const resolve = {
          data: {
            solCarrList: solCarrList.data.results
          }
        };

        // 송장재출력 유효성 확인
        if (printType === 'reprint') {

          // 재출력은 다음의 택배사인경우에만 지원.
          const checkCarr = selected.filter((o) => {
            return ![4, 5, 6, 7, 8, 956].includes(o.carr_no);
          });

          if (checkCarr.length) {
            commonSVC.showMessage('실패', '선택한 택배사로 출력할 수 없는 패키지가 있습니다.');

            return false;
          }

          resolve.data.carr_no = _.compact(carr_no)[0];
          resolve.data.invoice_no = _.compact(invoice_no)[0];
        }

        resolve.data.type = 'select';

        const orderParams = angular.copy($scope.order.searchForm);

        orderParams.multi_type = 'bundle_no';
        orderParams.multi_search_word = selected.map(pkg => pkg.pa_bundle_no).join('\n');

        const ordList = (await shipmentModel.list(orderParams)).data.results;

        // 기본설정 (집하지 주소) 체크
        if (ordList.some(ord => !$scope.matchShopAddrList.find(temp => ord.pa_shop_cd === temp.pa_shop_cd).toAddr)) {
          commonSVC.showMessage('기본설정 후 송장발행을 진행해 주세요.');

          return false;
        }

        // 국내 송장 출력 시 보내는 곳 (집하지) 주소를 해당 모달 내 기본설정 > 주소관리에서의 집하지 주소로 변경해주어야 함
        ordList.forEach(ord => {
          const addrInfo = $scope.matchShopAddrList.find(temp => ord.pa_shop_cd === temp.pa_shop_cd);

          ord.to_zipcd = addrInfo.toAddr.zipcd;
          ord.to_addr1 = addrInfo.toAddr.addr1;
          ord.to_addr2 = addrInfo.toAddr.addr2;
          ord.to_htel = addrInfo.toAddr.tel;
          ord.to_tel = addrInfo.toAddr.tel;
          ord.ctry_cd = addrInfo.toAddr.ctry_cd;
          ord.ship_method = addrInfo.shipcenter_ship_type;
        });

        resolve.totalCount = ordList.length;

        resolve.selectList = function () {
          return { data: { results: ordList, recordsTotal: ordList.length, isGlobal: true } };
        };
        resolve.solCarrList = solCarrList;

        const modal = commonSVC.openModal('lg', resolve, 'printInvoiceCtrl', 'views/order/shipment/modals/print_invoice.html', false, true, false);

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true, true, 'package');
            $scope.searchDo(true, true, 'order');
          }
        });
      }
    };

    async function init() {
      $scope.matchShopAddrList = (await shipmentModel.getDomesticAddressSetting())?.data;
      $scope.addrTemplateList = (await shipmentModel.addressTemplateList())?.data.result;

      // 쇼핑몰 집하지, 픽업 주소 설정
      $scope.matchShopAddrList.forEach(shop => {
        const matchFromAddr = $scope.addrTemplateList.find(temp => temp.template_no === shop.pickup_from_location_no);
        const matchToAddr = $scope.addrTemplateList.find(temp => temp.template_no === shop.pickup_to_location_no);

        if (matchFromAddr) {
          shop.fromAddr = matchFromAddr;
        }
        if (matchToAddr) {
          shop.toAddr = matchToAddr;
        }
      });
    }
  });