
'use strict';

/**
 * 2020-06-09 boris
 * 신세계 TV 쇼핑
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B878-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, commonSVC, errorSVC, settings, siteInfo, B878categorySVC) {
    const svc = B878categorySVC;
    const category_code = {};
    let saveFlag = false;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터
    $scope.Cate = [];                                   // 추가한 카테고리
    $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    /**
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $timeout(() => {
              $scope.shopInfoSave(event);
            }, 500);
          }
        });
      }
    }, 300);

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      const cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      if (!cateCd) {
        return false;
      }
      cateSetting(index, cateCd);
      clearOpt(index);
      if (index === '4') {
        saveFlag = true;

        return false; // 마지막 분류이거나, 하위분류없을시
      } else {
        saveFlag = false;
      }
      $scope.loading = true;
      setCateSub(index, cateCd)
        .then(function() {
          $scope.loading = false;
          $timeout();
        })
        .catch(function() {
          commonSVC.showMessage('로딩실패');
          $scope.loading = false;
        });
    };

    // 카테고리 추가
    $scope.addCate = function() {
      const cateNm = [];

      if ($scope.Cate.length === 5) {
        commonSVC.showMessage('카테고리는 최대 5개까지 선택할수 있습니다');

        return false;
      }

      if (_.find($scope.Cate, { cd: cateNm })) {
        commonSVC.showMessage('이미 추가된 카테고리입니다');

        return false;
      }
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      $scope.Cate.splice(key, 1);
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

		 // 하위 카테고리 가져오기
		 function setCateSub(index, cateCd) {

      const $v = ['l', 'm', 's', 'd', 't'];

      category_code[`${$v[index]}code`] = cateCd;

      return requestPA(`etc.get_category_${$v[parseInt(index) + 1]}group`, { category_code })
        .then(function (res) {
          const data = res.data.data.groupList;

          if (!data) {
            $scope.CateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
          } // 하위카테고리 없을시 출력
          else {
            $scope.CateOpt[(parseInt(index) + 1)] = data.map(e => Object({
              key: e[`${$v[parseInt(index) + 1]}group`],
              name: e[`${$v[parseInt(index) + 1]}groupName`],
              leaf: parseInt(index) === 3
            }));
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 5; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if (!saveFlag) {

        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }
      const cateCd = [];
      let valid = false;

      for (const key in $scope.CateList) {
        const value = $scope.CateData[$scope.CateList[key]];
        const sel_val = $scope.CateOpt[key].filter(o => o.key === value)[0];

        cateCd.push(value);
        if (sel_val && sel_val.leaf) {
          valid = true;

          break;
        }
      }

      if (!valid) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateCd.join('_');

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B878',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;
              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;
            $scope.applyCate(param);
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          return requestPA('etc.get_category_lgroup');
        })
        .then(function (res) {
          const data = res.data.data.groupList;

          if (!data) {
            commonSVC.showMessage('로딩실패');
            $scope.loading = false;

            return false;
          } else {
            $scope.CateOpt[0] = data.map(e => Object({
              key: e.lgroup,
              name: e.lgroupName,
              leaf: false
            }));

            if ($scope.matchingData.shop_cate_no_add_info) {
              if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
                matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
              } else {
                matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
              }
            } else if (!$scope.matchingData.sol_cate_shop_add_info) {
              matchDetailDataLoad(angular.copy(B878categorySVC.category_data));
            } else {
              matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
            }
          }
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        })
        .finally(function () {
          $scope.loading = false;
        });
      $('#B878List').sortable();
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const user = $scope.userInfo;
      const domain = siteInfo.B878.domain;

      const userInfo = {
        pa_shop_cd: user.pa_shop_cd,
        shop_id: user.shop_id || '',
        shop_pwd: user.shop_pwd || '',
        etc1: user.etc1 || '',
        etc2: user.etc2 || '',
        etc3: user.etc3 || '',
      };

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;

      //카테고리 로드
      const cate_codes = addInfo.category_code.split('_');
      const cateType = ['key_lcate', 'key_mcate', 'key_scate', 'key_dcate', 'key_tcate'];

      await $timeout(async () => {
        for (let idx in cateType) {
          idx = parseInt(idx);

          if (cate_codes[idx]) {
            $scope.CateData[cateType[idx]] = cate_codes[idx];

            if (idx < 4) {
              await setCateSub(idx, $scope.CateData[cateType[idx]]);
            }
          }
        }
      });

      saveFlag = true;
    };

    init();
  });
