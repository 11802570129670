'use strict';
/**
 * 원가 템플릿 상세(등록, 수정) 컨트롤러
 */

angular.module('gmpApp')
  .controller('ProdCostTemplateDetailCtrl', function($uibModalInstance, data, $scope, settings, commonSVC, $timeout) {
    function init () {
      $scope.mode = data.mode;
      // 원가템플릿 정보 초기화
      if ($scope.mode === 'add') {
        $scope.template = {
          name: '',
          purchase_cost: '',
          in_ship_cost: '',
          ship_cost: '',
          adv_cost: '',
          other_cost: ''
        };
      } else {
        $scope.template = angular.copy(data.template_data);
      }
    }

    init();

    /**
     * 원가 템플릿 등록
     */
    $scope.add = async () => {
      if (!$scope.template.name) {
        return commonSVC.showMessage('저장 실패', '원가명은 필수값 입니다.');
      }
      try {
        for (const key in $scope.template) {
          if ($scope.template[key] === '') {
            $scope.template[key] = 0;
          }
        }

        const result = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/stock/prod-cost/template`, { template: $scope.template });

        if (result.data?.results === 'success') {
          commonSVC.showToaster('success', '저장 완료', '원가 템플릿 저장에 성공하였습니다.');
          $uibModalInstance.close('success');
        } else {
          throw Error('원가 템플릿 저장에 실패하였습니다.');
        }
      } catch (err) {
        return commonSVC.showToaster('error', '저장 실패', err.message || err.data?.messages?.[0]);
      }
    };

    /**
   * 원가 템플릿 수정
   */
    $scope.edit = async () => {
      if (!$scope.template.name) {
        return commonSVC.showMessage('수정 실패', '원가명은 필수값 입니다.');
      }

      try {
        for (const key in $scope.template) {
          if ($scope.template[key] === '') {
            $scope.template[key] = 0;
          }
        }

        const result = await commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/stock/prod-cost/template`, { template: $scope.template });

        if (result.data?.results === 'success') {
          commonSVC.showToaster('success', '수정 완료', '원가 템플릿 수정에 성공하였습니다.');
          $uibModalInstance.close('success');
        } else {
          throw Error('원가 템플릿 저장에 실패하였습니다.');
        }
      } catch (err) {
        return commonSVC.showToaster('error', '수정 실패', err.message || err.data?.messages?.[0]);
      }
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };
  });