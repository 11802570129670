
'use strict';

angular.module('gmpApp')
  .controller('tradeProdListCtrl', function ($scope, $uibModalInstance, $timeout, $compile, $filter, commonSVC, productModel, systemModel, supplierModel, warehouseModel, data, settings, userInfo) {

    const supp_no = data.supp_no; // 매입처 번호

    $scope.supp_name = data.supp_name; // 매입처명

    /**
     * 서치바 관련 변수
     */
    $scope.searchForm = {
      supp_no: supp_no
    };

    // 검색영역 데이터
    $scope.searchData = {
      all_checked: false,                                                         // 전체선택 여부
      selectCount: 0,                                                             // 검색전체 건수
      totalCount: 0,                                                              // 검색전체 건수
      showDetailSearchArea: false
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function (number) {
      $scope.searchData.showCount = number;
      $scope.grid.methods.length(number);
    };

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {

      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['prod_no', 'sku_cd', 'attri', 'real_stock'],
      alignRightColumns: ['sale_price'],
      defaultSortingColumns: ['sku_cd'],
      notSortingColumns: ['prod_no', 'attri', 'sale_price', 'real_stock'],
      notResizingColumns: ['prod_no'],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/supplier/getLinkedBase`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          return JSON.stringify(data);
        },
        requestDidAction: function (json) {
          $scope.searchData.totalCount = json.recordsTotal;

          return json.results;
        }
      },
      columns: [
        // 도구
        {
          key: 'prod_no', title: '도구', width: 10, template: function (row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.sku_cd}','${row.prod_no}')" >상세</button>`;
          }
        },
        // SKU코드
        {
          key: 'sku_cd', title: 'SKU코드', width: 15
        },
        // SKU상품명
        {
          key: 'prod_name', title: 'SKU상품명', width: 35, template: function (row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }, notCompile: true
        },
        // 속성
        {
          key: 'attri', title: '속성', width: 15
        },
        // 판매가
        {
          key: 'sale_price', title: '판매가', width: 13, template: function (row) {
            return $filter('currency')(row.sale_price, '', 0);
          }, notCompile: true
        },
        // 실재고
        {
          key: 'real_stock', title: '실재고', width: 12, template: function (row) {
            return row.real_stock < 0 ? 0 : row.real_stock;
          }, notCompile: true
        }
      ]
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(sku_cd, prod_no) {
      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_no,
          detailType: 'edit'
        },
        supplierList: supplierModel.ListAll({ use_yn: true }),
        warehouseList: warehouseModel.ListAll({ use_yn: true }),
        madeinList: productModel.getMadein(),
        systemList: systemModel.load()
      };

      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {
        //리스트 유지한채 데이터 업데이트
        $scope.grid.methods.reloadData(function() {}, false);

      });
    };

    /**
     * 모달 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });
