/**
 * JQuery 재적용 서비스
 *
 * 뷰 이동 할때마다 적용 한다.
 */
'use strict';
angular.module('gmpApp')
  .factory('jquerySVC', function ($rootScope, $state, $timeout, $log, MessageSVC) {

    // 2017-03-13 MatthewKim 실제로 탭 뷰 이동 완료시 마다 호출될 내용
    $rootScope.$on('$stateChangeSuccessForJquery', function() {
      $timeout(function() {
        __master.OnViewChanged();
      });
    });

    var __master = {

      /**
       * 화면상에 필요한 모든 Jquery 객체에 효과 처리를 진행함
       * 2017-03-13 MatthewKim
       */
      OnViewChanged: function() {

        $log.debug('JQ-SVC-OnviewChanged: OK');

        // Hide if collapsed by default
        $('.category-collapsed').children('.category-content').hide();
        // Rotate icon if collapsed by default
        $('.category-collapsed').find('[data-action=collapse]').addClass('rotate-180');
        // Hide if collapsed by default
        $('.panel-collapsed').children('.panel-heading').nextAll().hide();
        // Rotate icon if collapsed by default
        $('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');

        // Add 'active' class to parent list item in all levels
        // li active 상태인 모든 부모에도 active 클래스 추가
        $('.a-sidebar-ul').find('li.active').parents('li').addClass('active');

        //Highlight children links
        // $('.a-sidebar-ul').find('li').has('ul').children('a').addClass('has-ul');
        // Add active state to all dropdown parent levels
        // $('.dropdown-menu:not(.dropdown-content), .dropdown-menu:not(.dropdown-content) .dropdown-submenu').has('li.active').addClass('active').parents('.navbar-nav .dropdown:not(.language-switch), .navbar-nav .dropup:not(.language-switch)').addClass('active');

        // 현재 메뉴 펼치기 2017-03-13 MatthewKim
        // 여기서 .active 된 내용을 찾아서 처리 하려 했으나 앵귤러가 active 시키는 타이밍이 더 느림
        // 그래서 어쩔수 없이 현재 sref 값 꺼내서 사용
        // 현재 스테이트 꺼내기 위해 이 로직 실행 위치 전체를 앵귤러 안으로 가져오고 JquerySVC로 만듦

        // 2017-05-12 ally
        // 자식이있는 메뉴에서 자식이 없는 다른 메뉴로 이동시 그전에 열려있던 (자식이 있는) 메뉴에 active가 계속 활성되어있어
        // 모든 부모 li에 active 삭제 후 현재 열려있는 메뉴의 부모 li에만 active 클래스 추가해줌

        // // 일단 모든 메뉴 닫음
        $('.a-sidebar-ul').children('li').has('ul').children('ul').children('li').removeClass('active');
        // $('.a-sidebar-ul').children('li').removeClass('active'); // ally
        //$('.a-sidebar-ul').find('li').has('ul').children('ul').hide();

        // 현재 메뉴만 다시 펼침
        let current_state_name = $state.current.name;

        // 2017-09-14 Noah 뎁스 조정 및 '/add' 의 경우 별도로 처리 필요
        current_state_name = `main.${current_state_name.replace('main.', '').split('.')[0]}`;
        if (current_state_name.indexOf('_add') > 0) {
          current_state_name = current_state_name.replace('_add', '_list');
        }

        const $cur_menu = $(`.a-sidebar-ul li a[ui-sref="${current_state_name}"]`);

        // 홈인 경우 $curr_menu[0] === undefined
        if (!$cur_menu[0]) {
          $('.a-sidebar-ul').children('li').removeClass('active');
          $('.a-sidebar-ul').children('li').has('ul').children('ul').children('li').removeClass('active');
        } else {
          // $cur_menu.parents('ul').show();
          $cur_menu.parents('li').addClass('active'); // ally
          $cur_menu.parents('li').siblings().removeClass('active');
          $cur_menu.parents('li').siblings().children('ul').children('li.active').removeClass('active');
        }

        // Popover
        $('[data-popup="popover"]').popover();
        // Tooltip
        $('[data-popup="tooltip"]').tooltip();

        // 2017-03-03 MatthewKim
        // 이게 좋지가 않은듯
        // $('[data-hover="dropdown"]').dropdownHover();

        // If menu has child levels, add selector class
        $('.menu-list').find('li').has('ul').parents('.menu-list').addClass('has-children');

        // 알림 메뉴 열었다가 닫으면 읽기 완료 처리
        $('#messages_dropdown_menu').on('hide.bs.dropdown', function () {
          MessageSVC.readAllMessages();
        });

        // 2017-09-19 chris 탭 비활성화 처리
        $('.nav-tabs a[data-toggle=tab]').on('click', function(e) {
          if ($(this).hasClass('disabled')) {
            e.preventDefault();

            return false;
          }
        });

        // 2020-04-01 lucas comma attribute가 적용되어있는 요소에 대한 validator 진행 시, 기존 함수에 문제가 있어 오버라이드 진행
        $.validator.methods.min = (a, b, c) => {
          return parseInt(String(a).replace(/,/g, '')) >= parseInt(String(c).replace(/,/g, ''));
        };

        $.validator.methods.max = (a, b, c) => {
          return parseInt(String(a).replace(/,/g, '')) <= parseInt(String(c).replace(/,/g, ''));
        };
      }

    };

    return __master;
  });
