'use strict';

angular.module('gmpApp')
  .controller('AltalkSendDetailCtrl', function(
    // common
    $scope, data, $uibModalInstance, commonSVC,
    // info
    settings
  ) {
    $scope.searchData = {};
    $scope.grid = {}; // 발송내역 관련 변수
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: false,
      gridHeight: 400,
      pinningColumns: [],
      defaultSortingColumns: [],
      alignCenterColumns: ['phone', 'status', 'send_result', 'sms_result', 'use_cnt', 'pay_yn'],
      alignRightColumns: [],
      notSortingColumns: ['phone', 'status', 'send_result', 'sms_result', 'use_cnt'],
      notVisibleColumns: [],
      notMovingColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },

      externalRequestOptions: {
        requestUrl: `${settings.adminApiUrl}/api/marketing/atalk/sendTalkDetail`,
        requestWillAction: function (d) {
          const r = angular.merge($scope.searchData, { talk_no: data.uniq, send_time: data.time, send_type: data.send_type });

          return r;
        },
        requestDidAction: function (result) {
          $scope.detail_data = result.result[0];

          return result.result;
        }
      },
      columns: [
        {
          key: 'phone',
          title: '휴대폰 번호',
          width: 150
        },
        {
          key: 'status',
          title: '발송결과',
          width: 150,
          template: function(row) {

            return row.status || '-';
          }
        },
        {
          key: 'sms_result',
          title: '실패사유',
          width: 200,
          template: function(row) {
            return row.sms_result || '알 수 없는 오류';
          }
        },
        {
          key: 'send_result',
          title: 'SMS 상태',
          width: 100,
          template: function(row) {
            return ['M000', 'M001'].includes(row.result_code) ? row.send_result : '-';
          }
        },
        {
          key: 'use_cnt',
          title: '차감건수',
          width: 50,
        },
        {
          key: 'pay_yn',
          title: '무료여부',
          width: 50,
        }
      ]
    };

    $scope.excelDownload = async () => {
      const excleFieldList = [
        { header: '휴대폰 번호', key: 'phone' },
        { header: '발송결과', key: 'status' },
        { header: '실패사유', key: 'sms_result' },
        { header: 'SMS 상태', key: 'send_result' },
        { header: '차감건수', key: 'use_cnt' }
      ];

      const params = {
        talk_no: $scope.detail_data.id,
        send_type: $scope.detail_data.send_type,
        send_time: $scope.detail_data.send_time,
        file_ext: 'xlsx',
        sheet_name: `(${$scope.detail_data.send_id}) 발송대상 리스트`,
        down_list: excleFieldList
      };

      const result = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/excelDown`, params);

      if (result.data.url) {
        commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
        window.open(result.data.url, '');
      } else {
        commonSVC.showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');
      }
    };

    $scope.cancelReservation = () => {
      commonSVC.showConfirm('삭제하시겠습니까?', '삭제 시 복구 불가능하며 해당 건은 발송되지 않습니다.', async (isConfirm) => {
        if (isConfirm) {
          await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/deleteSendTalk`, { task_id: $scope.detail_data.id });
        }

        return $uibModalInstance.close('success');
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.close();
    };
  });