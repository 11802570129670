'use strict';

angular.module('gmpApp')
  .controller('TagTermCtrl', function(
    $rootScope, $scope, data, $uibModalInstance, commonSVC, tagSVC, $timeout, tagModel
  ) {
    $scope.mode = data.ordterm_no ? 'edit' : 'add';
    $scope.applyFlag = false;
    // directive(form) 용
    $scope.reCheckClass = {};

    // 커스텀 에러라벨
    $scope.custom_error = {
      prod_depot: false,
      prod_name: false,
    };
    $scope.tag = angular.copy(tagSVC.terms);
    $scope.user_profile = $rootScope.user_profile;

    // 선택상품 규칙설명
    $scope.sku_rule_type_desc = {
      and: '선택한 상품 전체가 주문에 포함되어야 사용자태그가 적용됩니다.',
      or: '선택한 상품중에 하나만 포함되어있어도 사용자태그가 적용됩니다.',
    };

    /**
     * SKU 매핑
     * @param {} mode
     */
    $scope.mappingProd = async (mode) => {
      const resolve = {
        data: {
          from: '조건',
          warehouseList: $scope.warehouseList,
          isMulti: mode == 'prod_depot',
          isSetMulti: true
        }
      };
      const prodList = await commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html').result;
      if (mode == 'prod_depot') {
        $scope.custom_error.prod_depot = false;
        prodList.forEach(prod => {
          if (prod.isSet) {
            prod.data.forEach(item => {
              if (item.set_no && item.depot_no && item.set_cd) {
                if (!$scope.tag.prod_depot.some(o => o.set_no === item.set_no && o.depot_no === item.depot_no)) {
                  $scope.tag.prod_depot.push({
                    set_no: item.set_no,
                    set_cd: `${item.set_cd} / ${prod.data.find(d => d.depot_name)?.depot_name}`,
                    depot_no: item.depot_no
                  });
                }
              }
            });
          } else {
            if (!$scope.tag.prod_depot.some(o => o.prod_no === prod.prod_no && o.depot_no === prod.depot_no)) {
              $scope.tag.prod_depot.push({
                prod_no: prod.prod_no,
                sku_cd: `${prod.sku_cd} / ${prod.depot_name}`,
                depot_no: prod.depot_no
              });
            }
          }
        });
      } else {
        if (prodList.some(prod => !prod.bundle_avail_yn)) {
          commonSVC.showMessage('매칭실패', '합포장 불가한 SKU상품은 사은품으로 생성하실 수 없습니다. ');

          return false;
        }

        $timeout(function () {
          $scope.custom_error.prod_name = false;
        });
      }
    };

    // 동일옵션 선택 불가처리
    function checkDup(array, type) {
      if (array.some((item, idx, arr) => {
        return arr.some((innerItem, innerIdx) => {
          if (innerIdx !== idx && innerItem.type === item.type && !!innerItem.type) {
            innerItem.type = '';
            innerItem.value = '';

            return true;
          }

          return false;
        });
      })) {
        throw new Error(`동일한 ${type}은 선택할 수 없습니다.`);
      }
    }

    /**
     * 타입 변경시
     */
    $scope.changeRuleType = (type, index) => {
      try {
        switch (type) {
          // SKU상품
          case 'sku_rule_apply_type':
            $scope.tag.prod_depot = [];
            break;

          // 판매금액
          case 'sales_type':
            $scope.tag.s_sales = null;
            $scope.tag.e_sales = null;
            break;

          // 수량
          case 'sale_cnt_type':
            $scope.tag.s_sale_cnt = null;
            $scope.tag.e_sale_cnt = null;
            break;

          // 휴대폰번호
          case 'contacts':
            $scope.tag.contacts[index].value = null;
            checkDup($scope.tag.contacts, '옵션');
            break;

          // 키워드
          case 'keywords':
            $scope.tag.keywords[index].value = null;
            checkDup($scope.tag.keywords, '옵션');
            break;
        }
      } catch ({ message }) {
        commonSVC.showMessage(message);
      }
    };

    /**
     * 창닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 로딩중 이미지
     */
    function loading(is_loading) {
      if (is_loading) { // 로딩중
        $('#loginSpinner').removeClass('hidden');
      } else { // 로딩완료
        $('#loginSpinner').addClass('hidden');
      }
    }

    $scope.tagTermsUpdate = async() => {
      $scope.reCheckClass.valid('recheck');
      let tagTermData;

      if ($scope.mode === 'edit') {
        const confirm = await commonSVC.showConfirm('수정한 조건에 따라 태그가 재적용됩니다.\n조건을 수정하시겠습니까?', '');

        if (!confirm) {
          $timeout(function () {
            $scope.waitBtnDisableFlag = false;
          }, 0);

          return;
        }
      }

      try {
        validateTag($scope.tag);
        processKeywordsAndContacts($scope.tag);

        $scope.tag.mode = $scope.mode;
        tagTermData = await saveTagTerm(); // 사용자태그 조건 수정 및 등록

        if (($scope.mode === 'add' && $scope.applyFlag) || $scope.mode === 'edit') {
          await tagModel.terms.apply({ ordterm_no: [tagTermData.data.ordterm_no] }); // 사용자태그 주문 적용
        }

      } catch (err) {
        commonSVC.showMessage(err.message || err.data.message, '');
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
      }
    };

    async function saveTagTerm() {
      const result = await tagModel.terms.save($scope.tag);
      const toasterTxt = $scope.mode === 'edit' ? '수정' : '등록';

      if (result.data.result === 'success') {
        commonSVC.showToaster('success', '', `사용자 태그 ${toasterTxt}에 성공하였습니다.`);
        $uibModalInstance.close('success');
      } else {
        commonSVC.showToaster('error', '', `사용자 태그 ${toasterTxt}에 실패하였습니다.`);
        $timeout(function () {
          $scope.waitBtnDisableFlag = false;
        }, 1000);
        throw Error(result.data.message);
      }

      return result.data;
    }

    $scope.validateNumber = function(event) {
      event.target.value = event.target.value.replace(/[^0-9,]/g, '');
    };

    function validateTag(tag) {
      if (tag.sku_rule_apply_type !== 'none' && !tag.prod_depot.length) {
        tag.sku_rule_apply_type = 'none';
      }

      if (tag.sales_type !== 'none' && !tag.s_sales) {
        tag.sales_type = 'none';
      }

      if (tag.sale_cnt_type !== 'none' && !tag.s_sale_cnt) {
        tag.sale_cnt_type = 'none';
      }

      const isAnyConditionSet = tag.s_sales || tag.s_sale_cnt || tag.prod_depot.length || tag.contacts.some(v => v.value) || tag.keywords.some(v => v.value);

      if (!isAnyConditionSet) {
        throw new Error('조건을 1개이상 입력해주세요.');
      }

      if (!tag.tag_no) {
        throw new Error('태그를 선택해주세요.');
      }
    }

    function processKeywordsAndContacts(tag) {
      tag.keywords = tag.keywords.filter(v => v.value);
      if (!tag.keywords.length) {
        tag.keywords = [{ type: '', value: '' }];
      }

      tag.contacts = tag.contacts.filter(v => v.value);
      if (!tag.contacts.length) {
        tag.contacts = [{ type: '', value: '' }];
      }
    }

    //연락처 or 키워드 추가, 삭제
    $scope.calculateOPT = function (type, calMode, idx) {
      if (type === 'contact') {
        if (calMode === 'plus') {
          if ($scope.tag.contacts.length < 2) {
            $scope.tag.contacts.push({ type: '', value: '' });
          }
        } else {
          $scope.tag.contacts.splice(idx, 1);
        }
      } else {
        if (calMode === 'plus') {
          if ($scope.tag.keywords.length < 6) {
            $scope.tag.keywords.push({ type: '', value: '' });
          }
        } else {
          $scope.tag.keywords.splice(idx, 1);
        }
      }
    };

    /**
     * 사용자태그 상세 불러오기
     */
    async function getDetail(ordterm_no) {
      try {
        loading(true);

        const { data: { result } } = await tagModel.terms.detail({ ordterm_no });
        Object.assign($scope.tag, result);

        $timeout();

        if (result.s_sales === 0 && result.e_sales === 99999999) {
          $scope.tag.s_sales = null;
        }
        if ($scope.mode == 'add') {
          $scope.tag.tag_name = '';
        }
      } catch (err) {
        commonSVC.showMessage('', '데이터 로딩 실패');
      }
    }

    // 신규추가일때는 로딩중 박스 숨기기
    $scope.$watch('$viewContentLoaded', function() {
      if ($scope.mode == 'add') {
        loading(false);
      } else {
        loading(true);
      }
    });

    /**
     * 사용자태그 리스트 불러오기
     */
    async function getTag() {
      try {
        const { data: { results } } = await tagModel.list();
        $scope.tag.tag_list = results;

        $timeout();
      } catch (err) {
        commonSVC.showMessage('', '태그 데이터 로딩 실패');
      }
    }

    /**
     * 태그 관리
     */
    $scope.showTag = () => {
      const modal = commonSVC.openModal('mini', {}, 'TagDetailCtrl', 'views/order/shipment/modals/tag_detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          getTag(); // 태그리스트 새로고침
        }
      });
    };

    /**
     * init
     */
    async function init() {
      if (data.ordterm_no) {
        await getDetail(data.ordterm_no); // 수정
      }
    }
    getTag();
    init();
  });