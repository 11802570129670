'use strict';

angular.module('gmpApp')
  .service('categoryModel', function (settings, commonSVC, $rootScope) {

    const self = {};

    self.categoryListarr = [];
    self.enginesList = [];
    self.shopDetailDataList = [];
    self.shopDetailDataGlobal = [];

    return {
      // 카테고리에 매칭된 쇼핑몰 리스트 조회
      cateShopIdSelectList: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/categoryShopList`, params);
      },

      // 카테고리 리스트 불러오기
      categorySelectList: function (next) {
        const url = `${settings.pa20ApiUrl}/app/product/categoryTemplateList`;

        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {

            next(state, data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }
      },

      // 카테고리 리스트 불러오기 (트리)
      categoryListTree: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category`;

        if (next) {
          commonSVC.sendUrl('GET', url, param, function (state, data) {
            next(state, data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, param);
        }
      },

      // 카테고리 추가 하기
      categoryAdd: function(data, next) {
        const params = {
          parents_id: data[0].parents_id,
          name: data[0].name,
          depth: data[0].depth
        };

        const url = `${settings.pa20ApiUrl}/app/product/category`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            if (next) {
              next(state, data);
            } else {
              angular.extend(self.categoryListarr, data.results);
            }
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      shopCategoryAdd: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category/shop`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      // 카테고리 네임 변경
      categoryNameUpdate: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category/name`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          if (next) {
            next(state, data);
          } else {
            angular.extend(self.categoryListarr, data.results);
          }
        });
      },

      // 카테고리 삭제하기
      categoryDel: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category`;

        commonSVC.sendUrl('DELETE', url, params, function (state, data) {
          if (next) {
            next(state, data);
          } else {
            angular.extend(self.categoryListarr, data.results);
          }
        });
      },

      // 카테고리 순서 변경하기
      categoryOrder: function(data, next) {
        const params = {
          data: data
        };
        const url = `${settings.pa20ApiUrl}/app/product/category/sort`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, params);
        }
      },

      // 카테고리 복사하기
      categoryCopy: function(data) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/category/copy`, data);
      },

      categoryChannelList: function (id, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/use-shops`;

        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }
      },

      matchingInfo_list: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/matched-shops`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      playauto_category: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/playauto-standard`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      playauto_category_mapping: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/match-standard-categorys`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      categoryInfo_list: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/detail`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      shopInfoDetailEdit: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/edit-shop-category`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, params, function (state, data) {
            next(data.results, data.shop_cate_no);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, params);
        }
      },

      shopInfoDetailEditAll: (data) => {
        const url = `${settings.pa20ApiUrl}/app/product/category/edit-all`;

        return commonSVC.sendUrl('PUT', url, data);
      },

      CategorybookmarkEdit: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category/bookmark`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, params);
        }
      },

      categoryPinEdit: function(params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/category/pins`, params);
      },

      // 카테고리에 매칭된 상품 있는 지 확인
      findOlExsits: (data) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/category/ol-exists`, data);
      },

      shopInfoDetailDelete: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/matched-shops`;

        if (next) {
          commonSVC.sendUrl('DELETE', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('DELETE', url, params);
        }
      },

      shopInfoDetailDataLoad: function(data, next) {
        const params = {
          categoryId: data.categoryId,
          shop_cd: data.shop_cd,
          shop_id: data.shop_id
        };

        const url = `${settings.pa20ApiUrl}/app/product/category/shop-detail`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function (state, data) {
            next(state, data.data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      //상품 등록시 매칭된 카테고리 리스트 불러오기
      shopCategorysLoad: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/category/get-shop-categorys`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(state, data.data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      // ESM 카테고리로 일반 카테고리 리스트 불러오기
      categorySelectListESM: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/category/esm`;

        return commonSVC.sendUrl('GET', url, params);
      },

      // 단일상품 카테고리 일괄 적용
      applyBatchEachProduct: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category/apply-product`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      // 카테고리 일괄 수정
      applyShopCate: function(data, next) {
        const params = data;
        const url = `${settings.pa20ApiUrl}/app/product/category/shop-id-all`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, params, function (state, data) {
            next(data.results);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, params);
        }
      },
      // 쇼핑몰 카테고리 불러오기(자식 카테고리 불러오기)
      getScrapCategoryByDepth: (params) => {
        const url = `${settings.pa20ApiUrl}/app/product/category/children`;

        return commonSVC.sendUrl('POST', url, params);
      },
      // 쇼핑몰 카테고리 불러오기(부모 카테고리 불러오기)
      getScrapParentCategory: (params) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/category/scrap-parent-category`, params);
      },
      // 수집카테고리 옵션 불러오기
      getScrapCategoryOption: params => {
        const url = `${settings.pa20ApiUrl}/app/product/category/shop-option`;

        return commonSVC.sendUrl('GET', url, params);
      },
      // 수집 카테고리 디테일 불러오기
      getScrapCategoryOptionDetail: params => {
        const url = `${settings.pa20ApiUrl}/app/product/category/not-direct-option`;

        return commonSVC.sendUrl('GET', url, params);
      },

      // 수집 카테고리 디테일 불러오기
      getScrapCategoryDetail: params => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/category/scrap-category-detail`, params);
      },

      /**
       * 유사 카테고리 검색
       */
      findCategory: params => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/category/similar`, params);
      },
      /**
       * 카테고리 추가정보 데이터 가져오기
       */
      getDetailData: params => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/category/get-detail-data`, params);
      },
      // 일반 카테고리의 ESM카테고리 존재 유무
      getEsmCategoryExistYn: params => {
        const url = `${settings.pa20ApiUrl}/app/product/category/is-esm`;

        return commonSVC.sendUrl('GET', url, params);
      },
      // ESM 단일상품 옵션 직접입력 불가능 카테고리 조회
      getEsmDirectOptionDisableCate: () => {
        const url = `${settings.pa20ApiUrl}/app/product/category/esm-not-direct-option`;

        return commonSVC.sendUrl('GET', url, {});
      },
      // 마스터-슬레이브 상품 카테고리 조회
      getSlaveProdCate: (params) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/getSlaveProdCate`, params);
      },
      // 카테고리 숨김 / 숨김취소 처리
      hideCategory: function(params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/category/hideCategory`, params);
      },
    };
  });
