'use strict';

angular.module('gmpApp')
  .controller('ExcelDownIntegratedCtrl', function ($scope, $rootScope, data, userInfo, shipmentModel, commonSVC, settings, gettextCatalog, $uibModalInstance, commonModel, $timeout, localStorageService, $state, securityModel, warehouseModel, $sce) {
    $scope.showDesc = localStorageService.get('excelDownIntegratedYn') || 'Y'; // Description 칸

    $scope.percent = '0%';
    $scope.isOurComp = $rootScope.user_profile.remote_ip === '127.0.0.1' || $rootScope.user_profile.sol_no == 496;
    $scope.modalData = data;
    $scope.templateList = [];
    $scope.editTemplateData = {};
    $scope.title = data.title ? `'${data.title}'` : '주문';
    $scope.down_carr_cd = false;
    $scope.excel_password_yn = 0;
    $scope.email_yn = false;
    $scope.email_check_yn = 0;
    $scope.email = { content: '', title: '' };
    $scope.isEncryption = false;
    $scope.mailList = localStorageService.get('mailList') || [];

    // 주문 엑셀 다운로드 모달 서브 타이틀 컨트롤러 단에서 처리 후 html 바인딩 처리
    const select_excel_sub_title = `<span>선택한 ${$scope.modalData.page === 'consignment-total' ? `${$scope.title}주문` : $scope.title} <span style="color: #fe6524">${$scope.modalData.count}건</span>을 Excel로 다운로드합니다.</span>`;
    const total_excel_sub_title = `<span>전체 ${$scope.title} <span style="color: #fe6524">${$scope.modalData.count}건</span>을 Excel로 다운로드합니다.</span>`;

    $scope.select_trustedHtml = $sce.trustAsHtml(select_excel_sub_title);
    $scope.total_trustedHtml = $sce.trustAsHtml(total_excel_sub_title);

    let template;
    const searchForm = {
      search_word: '',
      search_key: 'ALL',
      isGlobal: data.isGlobal,
      consignmentType: data.consignmentType
    };
    $scope.searchForm = angular.copy(searchForm);
    $scope.searchData = {
      totalCount: 0,
      selectCount: 0,
      showCount: 10,
      selectType: false,
      all_checked: false,
      search_key_items: [],
      search_multi_items: []
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      pinningColumns: [],
      alignCenterColumns: ['cd', 'mdate', 'name', 'file_type', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['bookmark_yn'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      gridHeight: 405,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/integratedExcelTemplate/list`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.totalCount;
          $scope.searchData.selectCount = result.rowsTotal;
          $scope.templateList = result.results;
          $scope.linkage_depot_list = result.linkageDepotList;
          result.recordsTotal = result.totalCount;

          return result.results;
        }
      },
      columns: [
        {
          key: 'cd',
          width: 123,
          title: '분류',
          template: function (row) {
            return row.cd === 'T1' ? '기본양식' : row.cd_name;
          }
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 185
        },
        {
          key: 'name',
          title: '양식명',
          width: 234
        },
        {
          key: 'file_type',
          title: '저장형식',
          width: 122
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 73,
          template: function (row) {
            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    async function init() {
      localStorageService.remove('tempMailList');
      if (localStorage.getItem('EXCEL_PASSWORD_YN') === '1') {
        $scope.encrypt_yn = true;
      }
      try {
        const result = await securityModel.checkAuth();
        $scope.send_mails = result.data?.results.filter(v => v.verify).map(info => info.email);
        // 이메일 잔여 카운트 조회
        commonModel.getServiceCnt().then((re) => {
          if (re.data?.status === 'success') {
            $scope.emailAvail = re.data?.emailCnt.remain < 0 ? 0 : re.data?.emailCnt.remain;
          } else {
            throw new Error('EMAIL 카운트 조회 실패');
          }
        }).catch(err => {
          commonSVC.showToaster('error', '실패', err.message);
        });
      } catch (error) {
        commonSVC.showToaster('error', '실패', '인증메일 조회에 실패하였습니다.');
      }
    }

    init();

    $scope.searchFn = {
      // 몇 건씩 볼지 결정
      changeLen: function (len) {
        $scope.searchData.showCount = len;
        $scope.grid.methods.length(len);
        $scope.grid.methods.reloadData(function () { }, true);
      },
      // 탭 변경
      // 검색
      searchDo: function () {
        $scope.grid.methods.reloadData(function () { }, true);
      },
      // 초기화
      resetDo: function () {
        $scope.searchForm = angular.copy(searchForm);
        $scope.searchFn.searchDo();
      }
    };

    $scope.search = function (e) {
      if ((e && (e.keyCode === 13 || e.which === 13))) {
        $scope.searchFn.searchDo();
      }
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('excelDownIntegratedYn', $scope.showDesc);
    };

    $scope.changeDo = async function ({ template_no, bookmark_yn }) {
      try {
        await shipmentModel.updateIntegratedExcelTemplate({ template_no, template: { bookmark_yn } });

        commonSVC.showToaster('success', '성공', '즐겨찾기 수정 성공');
        $scope.searchFn.searchDo(); //즐겨 찾기 수정후 리스트재정렬 위해 새로고침
      } catch (error) {
        commonSVC.showToaster('error', '실패', '즐겨찾기 수정 실패');

        return false;
      }
    };
    $scope.viewPriceTable = function () {
      const viewStr = `<div class="sweet-alert-body" style="padding: 0 !important;">
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">방송통신위원회고시 ‘개인정보의 기술적/관리적 보호조치 기준’ 제6조 4항</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 6조(개인정보의 암호화)</span><br>
        <span style="font-size: small;">④ 정보통신서비스 제공자등은 이용자의 개인정보를 컴퓨터, 모바일 기기 및 보조저장매체 등에 저장 할 때에는 이를 암호화해야 한다.</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 기술적/관리적 보호조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 4조(접근통제)</span><br>
        <span style="font-size: small;">⑧ 정보통신서비스 제공자등은 개인정보취급자를 대상으로 다음 각 호의 사항을 포함하는 비밀번호 작성규칙을 수립하고, 이를 적용 운용하여야 한다.</span><br>
        <span style="font-size: small;">1. 영문, 숫자, 특수문자 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 구성</span><br><br><br>
        <span style="
          font-size: initial;
          font-weight: 600;
          color: royalblue;
        ">개인정보의 안전성 확보조치 기준</span><br><br>
        <span style="
          font-weight: 600;
          font-size: 15px;
        ">제 8조(접속기록의 보관 및 점검)</span><br>
        <span style="font-size: small;">② 개인정보처리자는 개인정보의 오·남용, 분실·도난·유출·위조·변조 또는 훼손 등에 대응하기 위하여 개인정보처리시스템의 접속기록 등을 월 1회 이상 점검하여야 한다.´</span><br>
        <span style="font-size: small;">특히 개인정보를 다운로드한 것이 발견되었을 경우에는 내부관리 계획으로 정하는 바에 따라 그 사유를 반드시 확인하여야 한다.</span><br>
        </div>`;
      commonSVC.showMessageHtml('관련 법적 고지 안내<hr style="margin-bottom: 0;">', viewStr);
    };

    /**
     * 비밀번호 및 사유 변경사항이 있을때 유효성 검사 초기화.
     */
    $scope.$watchGroup(['password.new', 'password.confirm', 'password.reason'], function () {
      $scope.overlapResult = null;
      $scope.confirmOverlap = null;
      $scope.newOverlap = null;
      $scope.isEncryption = false;
      const regExp = /^(?=.*[a-zA-Z])(?=.*[,.<>/?~()_[\]{}|;:!@#$%^&*+=-])(?=.*[0-9]).{8,}$/;
      const validPassword = regExp.test($scope.password.new);
      if (!validPassword && $scope.password.new !== '') {
        $scope.isEncryption = false;
        $scope.newOverlap = 'n';
        $scope.alerts = { msg: '비밀번호 규칙에 맞게 다시 설정해 주세요.' };
      }
      if ($scope.password.confirm !== '') {
        if ($scope.password.confirm === $scope.password.new) {
          $scope.isEncryption = $scope.password.reason !== '';
          $scope.confirmOverlap = 'y';
          $scope.alerts = { msg: '비밀번호가 일치합니다.' };
          const regExp = /^(?=.*[a-zA-Z])(?=.*[,.<>/?~()_[\]{}|;:!@#$%^&*+=-])(?=.*[0-9]).{8,}$/;
          const validPassword = regExp.test($scope.password.new);
          if (!validPassword) {
            $scope.isEncryption = false;
          }
        } else if ($scope.password.confirm !== $scope.password.new) {
          $scope.isEncryption = false;
          $scope.confirmOverlap = 'n';
          $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };

          return false;
        }
      }
    });

    //비밀번호 초기값 변수
    $scope.password = {
      new: '',
      confirm: '',
      reason: ''
    };

    // localStroage에 저장된 사유 불러오기
    if (localStorage.getItem('PASSWORD_REASON')) {
      $scope.password.reason = localStorage.getItem('PASSWORD_REASON').replace(/"/g, '');
    }
    // localStroage에 엑셀 암호화 사용여부 체크박스 값 불러오기
    if (localStorage.getItem('EXCEL_PASSWORD_YN')) {
      $scope.excel_password_yn = JSON.parse(localStorage.getItem('EXCEL_PASSWORD_YN'));
    }

    /**
     * 양식 추가하기
     */
    $scope.addTemplate = function () {
      const resolve = {};

      resolve.data = {
        pageType: 'add',
        page: data.page,
        isGlobal: data.isGlobal,
        used_depot_list: $scope.linkage_depot_list, // 이미 연동된 배송처 리스트
        consignmentType: data.consignmentType
      };

      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.sol_no });
      };

      const modal = commonSVC.openModal('lg', resolve, 'ExcelEditIntegratedCtrl', 'views/order/shipment/modals/excel_edit_integrated.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 수정하기
     */
    $scope.editTemplate = function () {
      template = $scope.grid.methods.selectedData('template_no');

      if (!template || !template.length) {
        commonSVC.showMessage('수정할 양식을 선택하세요.');

        return false;
      } else if (template[0].cd === 'T1') {
        commonSVC.showMessage('기본양식은 수정할 수 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          pageType: 'edit',
          template_no: template[0],
          page: data.page, // 초기화 작동하지 않는 문제로 페이지 정보 넘겨줌
          isGlobal: data.isGlobal,
          used_depot_list: $scope.linkage_depot_list, // 이미 연동된 배송처 리스트
          consignmentType: data.consignmentType
        },
        warehouseList: function() {
          return warehouseModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.sol_no });
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'ExcelEditIntegratedCtrl', 'views/order/shipment/modals/excel_edit_integrated.html');

      modal.result.then(function (result) {
        if (result === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 삭제하기
     */
    $scope.deleteTemplate = function () {
      template = $scope.grid.methods.selectedData('all');
      if (template && template.length && template[0].template_no) {
        if (template[0].cd === 'T1') {
          commonSVC.showMessage('기본양식은 삭제할 수 없습니다.');

          return false;

        } else {
          let msg = '양식을 삭제 하시겠습니까?';

          if (template[0].bookmark_yn) {
            msg = `선택한 데이터는 즐겨찾는 EXCEL 양식입니다.\n${msg}`;
          }

          commonSVC.showConfirm(msg, '', function () {
            shipmentModel.deleteIntegratedExcelTemplate({ template_no: template[0].template_no }, function (state) {
              if (state === 'success') {
                commonSVC.showToaster('success', '성공', 'Excel 양식 삭제 성공');
                $scope.searchFn.resetDo();

              } else {
                commonSVC.showToaster('error', '실패', 'Excel 양식 삭제 실패');

                return false;
              }
            });
          });
        }
      } else {
        commonSVC.showMessage('삭제할 양식을 선택하세요.');

        return false;
      }
    };

    /**
     * 양식 복사하기
     */

    $scope.copyTemplate = function () {
      template = $scope.grid.methods.selectedData('all');

      if (template && template.length && template[0].template_no) {
        if (template[0].cd === 'T1') {
          commonSVC.showMessage('기본양식은 복사할 수 없습니다.');

          return false;
        } else {
          commonSVC.showConfirm('양식을 복사 하시겠습니까?', '', function (flag) {
            if (flag) {
              shipmentModel.copyIntegratedExcelTemplate({ template_no: template[0].template_no }, function (state) {
                if (state === 'success') {
                  commonSVC.showToaster('success', '성공', 'Excel 양식 복사 성공');
                  $scope.searchFn.resetDo();

                  return true;
                } else {
                  commonSVC.showToaster('error', '실패', 'Excel 양식 복사 실패');

                  return false;
                }
              });
            }
          });
        }
      } else {
        commonSVC.showMessage('복사할 양식을 선택하세요.');
      }
    };

    // 발신 메일 저장
    $scope.selectSenderMail = function(mail) {
      localStorageService.set('tempLatestSender', mail);
    };

    /**
     * 다운로드 버튼 비활성화 여부 확인
     */
    $scope.checkBtnDisable = () => {
      return !$scope.modalData.count;
    };

    /**
     * 엑셀 다운로드
     * */
    $scope.download = async function () {
      $scope.alerts = {};
      const tempLatestSender = localStorageService.get('tempLatestSender') || '';
      const tempMailList = localStorageService.get('tempMailList') || [];
      localStorageService.set('latestSender', $('#senderMail option:selected').text() || tempLatestSender);
      const saveMailList = localStorageService.get('mailList') || [];
      $('#downloadBtn').attr('disabled', true).html('<i class="icon-spinner3 spinner"></i>');

      // 보유 이메일 카운트보다 받는 메일 수가 많을 시 모달 출력
      if ($scope.email_check_yn && $scope.emailAvail < tempMailList.length) {
        $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

        return commonSVC.showMessageHtml('발송 가능한 카운트가 없습니다.', '<b>[이메일 충전하기]에서 이메일 카운트 충전 후 이용해주세요.</b>');
      }
      if ($scope.excel_password_yn === 1) {

        //비밀번호 유효성 체크
        if ($scope.newOverlap === 'n') {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호 규칙에 맞게 다시 설정해 주세요.'));
          $scope.alerts = { msg: '비밀번호 규칙에 맞게 다시 설정해 주세요.' };
          $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

          return false;
        }

        if ($scope.password.new !== $scope.password.confirm) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호가 일치하지 않습니다.'));
          $scope.confirmOverlap = 'n';
          $scope.alerts = { msg: '비밀번호가 일치하지 않습니다.' };
          $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

          return false;
        }
        if (!$scope.password.new || !$scope.password.confirm || !$scope.password.reason) {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('[엑셀 암호화하기] 필수 입력 항목을 확인해주세요.'));
          $scope.overlapResult = 'blank';
          $scope.alerts = { msg: '필수 입력 항목입니다.' };
          $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

          return false;
        }
        if ($scope.email_check_yn) {
          if (!($scope.sendMailSelected || tempLatestSender) || !(($('select#selectReceiver').val() || tempMailList).length)) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('[이메일 보내기] 필수 입력 항목을 확인해주세요.'));
            $scope.overlapResult = 'blank';
            $scope.alerts = { msg: '필수 입력 항목입니다.' };
            $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

            return false;
          }
        }
      }
      /**
      * 사유 로컬스토리지에 저장
      **/

      localStorage.setItem('PASSWORD_REASON', JSON.stringify($scope.password.reason));
      $('.progress-bar-dir').css({ display: 'inline-block' });
      $('#down_carr').css({ display: 'none' });

      localStorage.setItem('EXCEL_PASSWORD_YN', JSON.stringify($scope.excel_password_yn));

      const apiUrl = data.url;

      template = $scope.grid.methods.selectedData('all');

      if (!template.length) {
        commonSVC.showMessage('양식을 선택해 주십시오.');

        $('.progress-bar-dir').css({ display: 'none' });
        $('#down_carr').css({ display: 'inline-block' });
        $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');

        return false;
      }

      let params = {
        template_no: template[0].template_no,
        is_all: data.isAll,
        uniq: data.uniq,
        down_carr_cd: $scope.down_carr_cd,
        page: data.page,
        password: $scope.password.new,
        reason: $scope.password.reason,
        excel_password_yn: $scope.excel_password_yn,
        send_email_yn: $scope.email_check_yn,
        excludeList: data.excludeBundleNos || []
      };

      if (data.searchForm) {
        params = angular.merge(params, { ...data.searchForm, shopType: data.isGlobal ? 'global' : 'domestic' });
      }
      // 선택 다운로드
      if (!data.isAll) {
        params.bundle_no = data.bundleList;
      }

      params.timeout = Math.ceil(parseInt($scope.modalData.count) / 2000) * 60 * 1500; // 2000 개당 1.5분으로 타임아웃 보냄 (전체 대상 다운로드 시 주문 수가 많을 수록 대상 건 수가 적어도 프로시져 자체가 오래 걸림)
      if ($scope.modalData?.page === 'consignment-aggregation') {
        params.excelData = $scope.modalData.excelData;
      }

      commonModel.excelDownload(apiUrl, params, async function (state, result) {
        if (state === 'success') {
          // 팝업차단 설정 추가.
          if (result.url) {
            let newWin;
            let isFail = false;
            if (_.isArray(result.url)) {
              for (const url of result.url) {
                isFail = true;
                newWin = window.open(url);
                await new Promise(resolve => {
                  const intervalId = setInterval(() => {
                    if (newWin && newWin.closed) {
                      clearInterval(intervalId);
                      isFail = false;
                      resolve();
                    }
                  }, 500);
                });
              }
            } else {
              newWin = window.open(result.url.result);
              if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                isFail = true;
              }
            }
            if (isFail) {
              commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
              $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');
              $('.progress-bar-dir').css({ display: 'none' });
              $('#down_carr').css({ display: 'block' });
            } else {
              $scope.isFinish = true;
              $scope.percent = '100%';
              commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
              if ($scope.email_check_yn) {
                try {
                  await securityModel.sendEmail({ title: $scope.email.title || '(제목없음)', content: $scope.email.content || '(내용없음)', from: tempLatestSender, to: tempMailList, url: Array.isArray(result.url) ? result.url : [result.url.result], password: $scope.password.confirm, ext: result.url.ext });
                  if (($('select#selectReceiver').val() || tempMailList).concat(saveMailList).length > 10) {
                    $scope.mailList = ($('select#selectReceiver').val() || tempMailList).concat(saveMailList).slice(0, 10);
                  }
                  localStorageService.set('mailList', $scope.mailList);
                  commonSVC.showMessageHtml('이메일 발송이 완료 되었습니다.', '<ul><li>보낸 이메일 내역 및 성공/실패 여부는<br/>결제 탭 > 부가서비스 사용내역에서 확인 할 수 있습니다.</li></ul>');
                } catch (err) {
                  commonSVC.showMessageHtml('이메일 발송이 실패 되었습니다.', '<ul><li>알 수 없는 이유로 실패하였습니다. 관리자에게 문의해주세요.</li></ul>');
                }

              }
              $uibModalInstance.close('success');
            }

          }
        } else {
          commonSVC.showToaster('error', '실패', result.data?.messages[0].includes('선택 항목') ? result.data?.messages[0] : '엑셀 다운로드에 실패하였습니다.');
          $('.progress-bar-dir').css({ display: 'none' });
          $('#down_carr').css({ display: 'block' });
          $('#downloadBtn').attr('disabled', false).html($scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드');
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.getBtnText = function() {
      return $scope.emailFormCheck ? '보내기 및 다운로드' : '다운로드';
    };
    // 이메일 보내기 클릭 함수
    $scope.clickEmailTag = function (type) {
      $scope.sendMailSelected = localStorageService.get('latestSender') || localStorageService.get('tempLatestSender');
      $scope.emailFormCheck = type;
      let mailList = localStorageService.get('mailList') || [];
      if (!type) {
        $scope.email_check_yn = $scope.email_check_yn === 0 ? 1 : 0;
        $scope.email_yn = !!$scope.email_check_yn;
        $scope.encrypt_yn = true;
      } else {
        $scope.email_yn = true;
        $scope.encrypt_yn = false;
      }

      $timeout(function() {
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        $('select#selectReceiver').select2({
          minimumResultsForSearch: Infinity,
          placeholder: 'ENTER 키로 여러 건 등록 가능합니다.',
          tags: true,
          templateSelection: function(data) {
            if (regExp.test(String(data.text).trim())) {
              return data.text;
            }

            return null;
          },
          templateResult: function(data) {
            const email = String(data.id).trim();
            if (regExp.test(email)) {
              return email;
            }

            return null;
          },
          createTag: function(data) {
            const email = String(data.term).trim();
            if (regExp.test(email)) {
              return {
                id: email,
                text: email,
              };
            }

            return null;
          },
          cache: false
        });
        $scope.$on('tempMailList', function(event, data) {
          const tempMailList = Array.from(new Set(data));
          localStorageService.set('tempMailList', tempMailList);
          $scope.tempMailList = tempMailList;
          $scope.$apply();
        });

        // 이메일 입력 또는 선택 시 액션
        $('select#selectReceiver').on('select2:select', function () {
          mailList = Array.from(new Set($('select#selectReceiver').val()));
          $('select#selectReceiver').val(mailList).trigger('change');
          $scope.$emit('tempMailList', $('select#selectReceiver').val());
          $scope.mailList = mailList;
          $scope.$apply();
        });

        $('select#selectReceiver').on('select2:unselect', function() {
          $scope.$emit('tempMailList', $('select#selectReceiver').val());
          $scope.emailFormCheck = !!$('#senderMail option:selected').text() && !!$('select#selectReceiver').val().length;
          $scope.$apply();
        });

        const initTempMailList = localStorageService.get('tempMailList') || [];
        $('select#selectReceiver').empty(); // Clear the existing options
        $scope.mailList.concat(initTempMailList).forEach(function(email) {
          const option = new Option(email, email, false, false);
          $('select#selectReceiver').append(option);
        });
      });
    };

    // 엑셀 암호화 클릭 함수
    $scope.clickEncryptTag = function(type) {
      if (!type) {
        $scope.excel_password_yn = $scope.excel_password_yn === 0 ? 1 : 0;
        $scope.encrypt_yn = !!$scope.excel_password_yn;
        $scope.email_yn = false;
        $scope.email_check_yn = false;
        $scope.emailFormCheck = false;
        $scope.password.confirm = '';
        $scope.password.new = '';
      } else {
        $scope.encrypt_yn = true;
        $scope.email_yn = false;
      }
    };

    // 이메일 충전하기 버튼 함수
    $scope.chargeEmailCnt = function() {
      $rootScope.openPayReq();
      $uibModalInstance.dismiss('cancel');
    };
  });