// 그리드 노출항목설정
'use strict';

angular.module('gmpApp')
  .controller('PaUiGridColVisCtrl', function ($scope, userInfo, $uibModalInstance, data, commonSVC) {

    $scope.page = data.page;
    $scope.addColGrid = data._gridOptions.addColGrid;
    $scope._gridObjScope = data._gridObjScope;

    let preColData = '';
    columnsSet();

    $scope.columnCtrl = () => {
      if (!commonSVC.checkPermission('order.roles.editColumn', userInfo.permission)) {
        return false;
      }

      const columnCtrl = 'EditColumnsCtrl',
            columnView = 'views/common/editColumn.html',
            columnData = {
              data: {
                page: $scope.page,
                columns: $scope.columns,
                _gridOptions: data._gridOptions
              }
            };

      const columnModal = commonSVC.openModal('xmd', columnData, columnCtrl, columnView);

      columnModal.result
        .then(() => {
          columnsSet();
        });
    };

    function columnsSet() {
      $scope.columns = data.columns
        .filter(function (col) {
          return col.displayName && !['도구', 'Row Selection Checkbox'].includes(col.displayName);
        })
        .map(function (col) {
          if (typeof col.colDef?.headerTooltip === 'object') {
            col.colDef.headerTooltip = col.colDef.headerTooltip.text;
          }

          return Object.assign({}, col);
        });

      $scope.columns.forEach((col) => {
        preColData += `${col.displayName}${col.visible}`;
      });

      // try {
      //   preColData = JSON.stringify($scope.columns);

      // } catch (err) {
      //   console.log(err);
      // }
    }

    $scope.changeCheck = () => {
      let currData = '';
      $scope.columns.forEach((col) => { currData += `${col.displayName}${col.visible}`; });

      return preColData == currData;
    };

    // 노출 여부 변경
    $scope.toggleColVisible = function(col) {
      col.visible = !col.visible;
    };

    // 초기화
    $scope.reset = function() {
      $scope.columns.forEach((row) => {
        const initCol = data._gridInitState.columns.find(({ name }) => name === row.field);

        row.visible = initCol ? initCol.visible : true;
        row.width = initCol ? initCol.width : 120;
      });
    };

    $scope.allReset = async () => {
      if (!(await commonSVC.showConfirm('주의', '직접 설정하신 리스트 항목의 너비, 노출 여부, 노출 순서, 정렬, 리스팅 갯수가 모두 초기화 됩니다.\n초기화 하시겠습니까?\n ※ 확인 버튼 클릭 시 설정 창이 닫히며 바로 적용됩니다.'))) {
        return;
      }

      $scope._gridObjScope.methods.reset();
      $scope.reset();
      $uibModalInstance.close();
    };

    // 적용
    $scope.apply = function() {
      $uibModalInstance.close({ type: 'apply', columns: $scope.columns });
    };

    // 닫기
    $scope.close = function () {
      $uibModalInstance.close();
    };
  })

  //컬럼 추가 리스트 컨트롤러
  .controller('EditColumnsCtrl', function ($scope, $rootScope, $uibModalInstance, data, commonSVC, settings, columnModel, errorSVC, columnSVC) {
    $scope.data = data;

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        prod_type: 'all',
        opt_type: 'all',
        shop: 'all',
        shop_cd: '',
        shop_id: '',
        addcol_type: $scope.data.page,
      },
      searchData: {
        totalCount: 0,       // 검색전체 건수
      },
    };

    // var warehouse_list = warehouseList.data.result || [];

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: true,
      enablePaginationControls: false,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'col_name', 'input_type', 'wdate', 'm_name'],
      alignRightColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/column/list`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.list = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'col_name',
          title: '항목명',
          width: 150,
          template: (row) => {
            return columnSVC.setColumnName(row.col_name, 'GET');
          }

        },
        {
          key: 'input_type',
          title: '입력형식',
          width: 100,
          template: (row) => {
            switch (row.input_type) {
              case 'str':
                return '문자';
              case 'int':
                return '숫자';
              case 'date':
                return '날짜';
              case 'enum':
                return '선택';
            }
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 150,
        },
        {
          key: 'm_name',
          title: '등록자',
          width: 80,
        }
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      }
    };

    $scope.searchDo = function(refresh = true, noDelay) {
      $scope.grid.methods.reloadData(refresh, noDelay);
    };

    //항목추가
    $scope.addColumn = () => {
      if ($scope.list.length > 7) {
        commonSVC.showMessage('실패', '항목은 최대 8개까지 추가 가능합니다.');

        return false;
      }

      const ctrl = 'AddColumnCtrl',
            view = 'views/common/addColumn.html',
            resolve = {
              data: {
                length: $scope.list.length,
                addcol_type: $scope.data.page,
              }
            };

      const modal = commonSVC.openModal('sg', resolve, ctrl, view);

      modal.result
        .then((res) => {
          if (res.status === 'success') {
            $scope.searchFn.searchDo();
            res.data.forEach((col) => {
              if (col.enumList && col.enumList.length) {
                col.enumList = col.enumList.map((e, idx) => [idx, e]);
              }
              data._gridOptions.columnDefs.push({
                displayName: col.col_name,
                name: columnSVC.setColumnName(col.col_name, 'SET'),
                width: 100,
                visible: false,
                enableSorting: false,
                cellTemplate: `<div class="ui-grid-cell-contents" compile="row.entity._uiGridData.uiGridColTemplates['${columnSVC.setColumnName(col.col_name, 'SET')}']">
                                ${columnSVC.columnTemplate(col)}
                               </div>`
              });
            });
          }
        });
    };

    // 컬럼 삭제
    $scope.deleteColumn = () => {
      const addcol = $scope.grid.methods.selectedData('all');

      if (!addcol.length) {
        commonSVC.showMessage('실패', '선택된 항목이 없습니다.');

        return false;
      }

      commonSVC.showConfirm('해당 항목의 모든 정보가 삭제되며, 삭제 후 복구는 불가합니다.\n필요시, 데이터는 Excel 다운로드하여 삭제 전에 별도로 백업하시기 바랍니다.\n삭제 하시겠습니까?')
        .then((confirm) => {
          if (confirm) {

            columnModel.deleteColumn({ addcol_list: addcol })
              .then((re) => {
                if (re.data.result === 'success') {
                  commonSVC.showToaster('success', '항목 삭제', '정상적으로 삭제되었습니다.\n페이지 새로고침을 진행해주세요.');
                  $scope.searchFn.searchDo();

                  addcol.map(o => o.col_name).forEach((name) => {
                    const opt_idx = data._gridOptions.columnDefs.findIndex((col) => { return col.name === name; });

                    if (opt_idx > -1) {
                      data._gridOptions.columnDefs.splice(opt_idx, 1);
                    }
                  });
                }
              })
              .catch((err) => {
                const errMsg = errorSVC.getError('settings', err.data.error);

                commonSVC.showToaster('error', '항목 삭제', errMsg);
              });

          }
        });
    };

    // 닫기
    $scope.close = function () {
      $uibModalInstance.close();
    };
  })
  .controller('AddColumnCtrl', function ($scope, $uibModalInstance, data, commonSVC, columnModel, errorSVC) {

    const colData = {
      col_name: '',
      input_type: 'str',
    };

    const colLength = data.length;

    init ();

    function init () {

      $scope.data = [];
      $scope.data.push(angular.copy(colData));
    }

    $scope.addColData = () => {
      if ((colLength + $scope.data.length) < 8) {
        $scope.data.push(angular.copy(colData));
      } else {
        commonSVC.showMessage('실패', '항목은 최대 8개까지 추가 가능합니다.');
      }
    };

    $scope.deleteColData = (idx) => {
      if ($scope.data.length > 1) {
        $scope.data.splice(idx, 1);
      }
    };

    $scope.addCol = () => {
      if (!$scope.addColForm.$valid) {
        commonSVC.showMessage('실패', '필수항목을 확인하세요');

        return false;
      }

      const isDup = _.filter(_.map($scope.data, 'col_name'), function (value, index, iteratee) {
        return _.includes(iteratee, value, index + 1);
      });

      if (isDup.length) {
        commonSVC.showMessage('실패', '중복된 항목명이 있습니다.');

        return false;
      }

      let enumChecked = false;

      $scope.data.forEach((row) => {
        if (row.enums) {
          row.enumList = row.enums.split(',');
          if (row.input_type === 'enum' && row.enumList.length < 2) {
            enumChecked = true;

            return false;
          }
        }
      });

      if (enumChecked) {
        commonSVC.showMessage('실패', '선택값은 최소2개 이상이여야 합니다.');

        return false;
      }

      commonSVC.showConfirm('저장한 항목명은 수정이 불가합니다.\n 수정이 필요한 경우 삭제 후 다시 추가해 주시기 바랍니다.\n 적용 하시겠습니까?')
        .then((confirm) => {
          if (confirm) {
            const params = {
              addcol_type: data.addcol_type,
              data: $scope.data
            };

            columnModel.addColumn(params)
              .then((re) => {
                if (re.data.result === 'success') {
                  $uibModalInstance.close({ status: re.data.result, data: $scope.data });
                  commonSVC.showToaster('success', '항목 추가', '정상적으로 등록되었습니다.\n페이지 새로고침을 진행해주세요.');
                }
              })
              .catch((err) => {
                const errMsg = errorSVC.getError('settings', err.data.error);

                commonSVC.showToaster('error', '항목 추가', errMsg);
              });
          }
        });
    };

    // 닫기
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });