/**
 * Created by Eddy on 2016-12-14.
 */
'use strict';

angular.module('gmpApp')
  .factory('inventoryModel', function (settings, commonSVC) {
    const stockPa20ApiUrl = `${settings.pa20ApiUrl}/app/stock`;

    return {
      /**
       * 재고할당 규칙 채널/SKU상품 조회
       */
      stockAllocShopBaseList: function (params, next) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/stock/alloc/list-of-shop-base`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 규칙 설정 업데이트
       * (재고할당 타입, 재고할당 방법, 할당률)
       */
      stockAllocEditRule: function (params, next) {
        commonSVC.sendUrl('PUT', `${stockPa20ApiUrl}/alloc/rules`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 규칙 삭제
       */
      stockAllocDeleteRule: function (params, next) {
        commonSVC.sendUrl('DELETE', `${stockPa20ApiUrl}/alloc/rules`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 규칙 사용여부 변경 (SKU상품별할당)
       */
      stockAllocSetRuleUse: function (params) {
        return commonSVC.sendUrl('PATCH', `${stockPa20ApiUrl}/alloc/rules`, params);
      },

      /**
       * 재고할당 SKU상품별 연동채널 할당 리스트
       */
      stockAllocBaseShopList: function (params) {
        const url = `${stockPa20ApiUrl}/alloc/list-of-base-shop`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 재고할당 SKU상품별 연동채널 규칙 추가
       */
      stockAllocAddBaseShopRule: function (params, next) {
        const url = `${stockPa20ApiUrl}/alloc/add-base-shop-rule`;

        commonSVC.sendUrl('POST', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 SKU상품별 연동채널 규칙 일괄추가
       */
      stockAllocAddBatchRule: function (params, next) {
        const url = `${stockPa20ApiUrl}/alloc/add-batch-rule`;

        commonSVC.sendUrl('POST', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 규칙 조회
       */
      stockAllocGetRules: function (params, next) {
        commonSVC.sendUrl('POST', `${stockPa20ApiUrl}/alloc/rules`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 규칙여부 조회
       */
      stockAllocCheckRuleExists: function (params, next) {
        commonSVC.sendUrl('POST', `${stockPa20ApiUrl}/alloc/check-rule-exists`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 적용
       */
      stockAllocApply: function (params, next) {
        commonSVC.sendUrl('POST', `${stockPa20ApiUrl}/alloc/apply`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 방법 변경
       */
      stockAllocSetType: function (params, next) {
        commonSVC.sendUrl('PUT', `${stockPa20ApiUrl}/alloc/types`, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 재고할당 방법 조회
       */
      stockAllocGetType: function (params, next) {
        const url = `${stockPa20ApiUrl}/alloc/types`;

        commonSVC.sendUrl('GET', url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 입/출력 로그 사유수정
       */
      inoutLogEdit: function (params, next) {
        commonSVC.sendUrl('PATCH', `${stockPa20ApiUrl}/inout-reason`, params, function(state, data) {
          next(state, data);
        });
      }
    };
  });

