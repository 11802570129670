'use strict';

angular.module('gmpApp')
  .service('memoSVC', function () {
    /**
     * 2021-01-07 Denver
     * 메모 관리 서비스
     */

    return {
      /**
       * 엑셀 모달창 필드 리스트
       */
      excelFieldList: {
        total: [
          { header: '주문', key: 'ord', select: false },
          { header: '온라인상품', key: 'ol_shop', select: false },
          { header: 'SKU상품', key: 'prod', select: false },
          { header: '세트상품', key: 'depot_set', select: false },
          { header: 'A/S(수선)', key: 'mend', select: false }
        ],
        ord: [
          { header: '주문수집일', key: 'ord_wdate', select: true },
          { header: '쇼핑몰(계정)', key: 'ord_shop_id', select: true },
          { header: '쇼핑몰 주문 번호', key: 'ord_shop_ord_no', select: true },
          { header: '주문자명', key: 'ord_order_name', select: true },
          { header: '온라인 상품명', key: 'ord_shop_sale_name', select: true },
          { header: '쇼핑몰 상품코드', key: 'ord_shop_sale_no', select: true },
          { header: '메모 번호', key: 'memo_no', select: true },
          { header: '메모 상태', key: 'memo_status', select: true },
          { header: '메모 등록일', key: 'wdate', select: true },
          { header: '메모 수정일', key: 'content_mdate', select: true },
          { header: '등록자', key: 'wname', select: true },
          { header: '메모내용', key: 'content', select: true },
          { header: '메모 완료일', key: 'mdate', select: true },
          { header: '완료자', key: 'complete_m_name', select: true }
        ],
        ol_shop: [
          { header: '쇼핑몰(계정)', key: 'ol_shop_shop_id', select: true },
          { header: '마스터 상품명', key: 'ol_shop_master_shop_sale_name', select: true }, // 키 값 확인 후 수정 필요
          { header: '판매자 관리코드', key: 'ol_shop_c_sale_cd', select: true },
          { header: '온라인 상품명', key: 'ol_shop_shop_sale_name', select: true },
          { header: '쇼핑몰 상품코드', key: 'ol_shop_shop_sale_no', select: true },
          { header: '메모 번호', key: 'memo_no', select: true },
          { header: '메모 상태', key: 'memo_status', select: true },
          { header: '메모 등록일', key: 'wdate', select: true },
          { header: '메모 수정일', key: 'content_mdate', select: true },
          { header: '등록자', key: 'wname', select: true },
          { header: '메모내용', key: 'content', select: true },
          { header: '메모 완료일', key: 'mdate', select: true },
          { header: '완료자', key: 'complete_m_name', select: true }
        ],
        prod: [
          { header: 'SKU코드', key: 'prod_sku_cd', select: true },
          { header: 'SKU상품명', key: 'prod_prod_name', select: true },
          { header: '메모 번호', key: 'memo_no', select: true },
          { header: '메모 상태', key: 'memo_status', select: true },
          { header: '메모 등록일', key: 'wdate', select: true },
          { header: '메모 수정일', key: 'content_mdate', select: true },
          { header: '등록자', key: 'wname', select: true },
          { header: '메모내용', key: 'content', select: true },
          { header: '메모 완료일', key: 'mdate', select: true },
          { header: '완료자', key: 'complete_m_name', select: true }
        ],
        depot_set: [
          { header: '세트코드', key: 'depot_set_set_cd', select: true },
          { header: '세트 상품명', key: 'depot_set_set_name', select: true },
          { header: '메모 번호', key: 'memo_no', select: true },
          { header: '메모 상태', key: 'memo_status', select: true },
          { header: '메모 등록일', key: 'wdate', select: true },
          { header: '메모 수정일', key: 'content_mdate', select: true },
          { header: '등록자', key: 'wname', select: true },
          { header: '메모내용', key: 'content', select: true },
          { header: '메모 완료일', key: 'mdate', select: true },
          { header: '완료자', key: 'complete_m_name', select: true }
        ],
        mend: [
          { header: '요청자명', key: 'mend_req_name', select: true },
          { header: 'A/S 요청일', key: 'mend_req_time', select: true },
          { header: '묶음번호', key: 'mend_bundle_no', select: true },
          { header: 'A/S 상품명', key: 'mend_as_sale_name', select: true },
          { header: '메모 번호', key: 'memo_no', select: true },
          { header: '메모 상태', key: 'memo_status', select: true },
          { header: '메모 등록일', key: 'wdate', select: true },
          { header: '메모 수정일', key: 'content_mdate', select: true },
          { header: '등록자', key: 'wname', select: true },
          { header: '메모내용', key: 'content', select: true },
          { header: '메모 완료일', key: 'mdate', select: true },
          { header: '완료자', key: 'complete_m_name', select: true }
        ]
      }
    };
  });
