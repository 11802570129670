'use strict';

angular.module('gmpApp')
  .factory('A004categorySVC', function () {

    return {
      // 옵션 repeat data
      category_opt: [
        {},
        {},
        {},
        {}
      ],

      //카테고리 data
      category_data: {
        // 표준분류
        key_lcate: '',
        key_mcate: '',
        key_scate: '',
        key_dcate: '',

        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });
