/**
* 기초상품 일괄 수정
*/
'use strict';

angular.module('gmpApp')
  .controller('ProdProductEditCtrl', function ($scope, data, $uibModalInstance, commonSVC, productModel, productSVC, madeinList, supplierList, warehouseList, userInfo, gettextCatalog, settings, $timeout) {

    const prod_auto_set_yn = data.prod_auto_set_yn;
    const costDetailData = ['purchase_cost', 'in_ship_cost', 'ship_cost', 'adv_cost', 'other_cost'];

    $scope.checkedCnt = 0;
    $scope.prodNoList = data.prodNoList || [];
    $scope.depotNoList = data.depotNoList || [];
    $scope.ebaydepotExist = data.ebaydepotExist;                      // G마켓물류 배송처 SKU상품 존재여부

    $scope.prodData = angular.copy(productSVC.product);
    delete $scope.prodData.supply_price;
    delete $scope.prodData.cost_price;

    $scope.nation = userInfo.user.sol_country;

    $scope.supplierList = supplierList.data.result || [];             // 매입처 리스트
    $scope.warehouseList = warehouseList.data.result || [];          // 배송처 리스트
    $scope.expirationMonths = _.range(1, productSVC.expirationMonth); //유통기한 개월수 리스트(1~50)
    $scope.costPriceSettingActive = false; // 원가 세부설정 아코디언 플래그

    $scope.checkItem = function(b, key) {
      if (b) {
        $scope.checkedCnt--;
      } else {
        $scope.checkedCnt++;
      }

      if (key === 'is_cost_price') {
        $scope.resetTemplate(true);
      }
    };

    // 원산지 대분류 선택
    $scope.selectMadein1 = function (val) {
      $scope.madeinCountryList = madeinList.data.result[val];

      $scope.madein_group = '';
      $scope.prodData.madein = '';
    };

    // 원산지 중분류 선택
    $scope.selectMadein2 = function (val) {
      $scope.madeinCityList = $scope.madeinCountryList[val];
    };

    // 저장
    $scope.save = async () => {
      if ($scope.form1.$valid) {
        const params = {
          prod_nos: $scope.prodNoList,
          change_data: {},
          prod_online_set_yn: false,
          same_depot_no_check_list: data.dupCheckList
        };

        for (const key in $scope.prodData) {
          if ($scope[`is_${key}`] || (key === 'cost_price' && $scope[`is_${key}`] === 0)) {
            if (key === 'madein') {
              params.change_data.madein_type = $scope.prodData.madein_type;
              params.change_data.madein = $scope.prodData.madein;
            } else if (key === 'expire_date' && $scope.prodData[key] !== '') {
              if ($scope.prodData.expiration_month == '52') {
                params.change_data.expire_date = null;
              } else {
                params.change_data.expire_date = $scope.prodData.expire_date;
              }
            } else {
              params.change_data[key] = $scope.prodData[key];
            }

            if (key === 'cost_price' && $scope.costPriceSettingActive) {
              params.change_data.purchase_cost = +$scope.prodData.purchase_cost || 0;
              params.change_data.in_ship_cost = +$scope.prodData.in_ship_cost || 0;
              params.change_data.ship_cost = +$scope.prodData.ship_cost || 0;
              params.change_data.adv_cost = +$scope.prodData.adv_cost || 0;
              params.change_data.other_cost = +$scope.prodData.other_cost || 0;
            }
          }
        }

        if (!Object.keys(params.change_data).length) {
          commonSVC.showMessage(gettextCatalog.getString('수정하실 항목을 선택해주세요.'));
        } else {
          // 대표 SKU상품 정보 자동세팅 설정 있는 경우에만 노출
          if (prod_auto_set_yn === 'Y' && ['is_supply_price', 'is_cost_price', 'is_madein', 'is_tax_type', 'is_made_date', 'is_expire_date'].some(o => $scope[o])) {
            const confirmOpt = {
              title: '대표 SKU상품 정보 자동 세팅',
              text: '<div style="font-size: 12px; font-weight: 400;">' +
                      '* 설정 > 환경설정 > 상품 > 대표 SKU상품 정보 자동 세팅 > 설정인 경우에 해당합니다.' +
                      '<ul class="popup-ul"">' +
                        '<li><span class="text-danger">[반영]</span>을 클릭하시면 수정된 정보 중 아래 항목은 연동된 온라인 상품에도 수정 반영합니다.<br>' +
                        '<span style="font-size: 12px">(공급가, 원가, 원산지, 과세여부, 바코드, UPC/EAN코드, HS코드, ISBN코드, 제조일자, 유효일자)</span></li>' +
                        '<li><span class="text-danger">[반영안함]</span>를 클릭하시면 SKU상품만 수정하고 온라인 상품에는 반영하지 않습니다.</li>' +
                        '<li><span class="text-danger">쇼핑몰 상품에서 필수값이나 SKU상품에서 비어있는 항목의 경우, SKU상품 정보로 반영되오니 필수값은 쇼핑몰 상품 관리에서 수정 후 쇼핑몰로 전송해주시기 바랍니다.</span></li>' +
                      '</ul>' +
                      '<div class="mt-25" style="font-size: 15px">연동된 온라인상품에도 반영하시겠습니까?</div>' +
                      '(판매중인 상태는 수정 시 수정대기로 변경됩니다.)' +
                    '</div>',
              confirmButtonText: '반영',
              cancelButtonText: '반영안함',
              html: true
            };

            params.prod_online_set_yn = await commonSVC.showConfirmCustom(confirmOpt);
          }

          productModel.changeProdInfo(params, function (state, data) {
            if (state == 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('상품 일괄수정에 성공하였습니다.'));
              $uibModalInstance.close('success');
            } else {
              if (data.data?.error && data.data?.error === 'not_allowed_depot_change') {
                commonSVC.showMessageHtml('<b>SKU상품 수정에 실패 했습니다.</b>', '아래와 같은 경우 배송처 수정이 제한됩니다.<br />&nbsp; - 매칭된 주문이 있는 경우<br />&nbsp; - 세트상품으로 구성된 경우<br />&nbsp; - 다중 매칭으로 매칭 규칙이 있는 경우<br /> <a href="https://app.playauto.io/#!/notice/system/detail/2221" class="button-a button-a-background">[관련 공지사항 보기]</a>');
              } else {
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('상품 일괄수정에 실패하였습니다.'));
              }
            }
          });
        }
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 원가 템플릿 모달 함수
     */
    $scope.openCostTemplateModal = () => {
      const modal = commonSVC.openModal('x3g', { data: { type: '' } }, 'ProdCostTemplateManageCtrl', 'views/prod/product/modals/prod_cost_template.html');
      modal.result.then(async (re) => {
        if (re) {
          $scope.costPriceSettingActive = true;
          try {
            const result = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/stock/prod-cost/template?template_no=${re.data.template_no}`);

            if (result.data?.results) {
              const template_data = result.data?.results?.template_data;

              for (const key in template_data) {
                template_data[key] = Number(template_data[key]);
              }
              $scope.template_name = re?.data.template_name.length > 15 ? re?.data.template_name.slice(0, 15) + '...' : re?.data.template_name;
              delete template_data.template_name;

              costDetailData.forEach(key => {
                $scope.prodData[key] = template_data[key];
              });

              $scope.prodData.cost_price = Math.round(costDetailData.reduce((acc, cur) => acc + Number($scope.prodData[cur]), 0) / 10) * 10;
              $scope.isApplyTemplate = true;

              $timeout(() => {});
            } else {
              throw Error('원가 템플릿 조회에 실패하였습니다.');
            }
          } catch (err) {
            return commonSVC.showToaster('error', '조회 실패', err.message || err.data?.messages?.[0]);
          }
        } else {
          return;
        }
      });
    };

    /**
     * 적용한 템플릿 데이터 초기화
     */
    $scope.resetTemplate = (isModal) => {
      if (!isModal) {
        return commonSVC.showConfirm('템플릿 해제', '선택한 원가 템플릿을 해제하시겠습니까?\n해제 시 입력된 값이 모두 초기화됩니다.', (confirm) => {
          if (confirm) {
            costDetailData.forEach(key => { $scope.prodData[key] = 0; });

            $scope.prodData.cost_price = 0;

            $scope.isApplyTemplate = false;

            $timeout(() => {});
          }
        });
      } else {
        costDetailData.forEach(key => { $scope.prodData[key] = 0; });

        $scope.prodData.cost_price = 0;

        $scope.isApplyTemplate = false;
      }
    };

    /**
     * 원가 세부 설정값 변경 시 원가 변경처리 함수
     */
    $scope.calculateCostPrice = () => {
      $scope.prodData.cost_price = Math.round(costDetailData.reduce((acc, cur) => acc + Number($scope.prodData[cur]), 0) / 10) * 10;
    };
  });