/**
 * 출고보류 처리
 * Created by ally on 2017. 7. 30..
 */
'use strict';

angular.module('gmpApp')
  .controller('orderHoldCtrl', function ($scope, data, commonSVC, shipmentModel, $uibModalInstance) {
    $scope.selectList = data.selectList || [];
    $scope.changeStatus = data.changeStatus || '출고보류';    // 주문보류인지, 출고보류인지의 구분값

    $scope.save = function() {
      if ($scope.form1.$valid) {
        const dData = {
          bundle_codes: _.map($scope.selectList, 'bundle_no'),
          uniqList: _.map($scope.selectList, 'uniq'),
          holdReason: $scope.holdReason,
          status: $scope.changeStatus,
        };

        shipmentModel.orderHold(dData, function(state, data) {
          if (data === 'success') {
            commonSVC.showToaster('success', '성공', `${$scope.changeStatus}에 성공하였습니다.`);
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', `${$scope.changeStatus}에 실패하였습니다.`);
          }
        });
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
