'use strict';

angular.module('gmpApp')
  .controller('ConfigsSubUserCtrl', function ($scope, $compile, $location, settings, commonSVC, userModel, gettextCatalog, $timeout, $rootScope, shopAccountModel, systemList, userInfo) {
    // 마스터 아니면 창 끄게만듬
    if (!$rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
      commonSVC.showMessage('권한이 없는 사용자입니다.');
      $rootScope.$emit('$closeTab', 'tab_configs_sub_user');
    }

    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      selectDate: 'ALL',
      sdate: '2001-01-01',
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      use_yn: '',
      user_grade: ''
    };

    $scope.registerUrl = $location.$$host === 'localhost' ? `http://localhost:8080/register/Register/?pa_sol_no=${$rootScope.user_profile.sol_no}&affiliate=sub` : `https://plto.com/register/Register/?pa_sol_no=${$rootScope.user_profile.sol_no}&affiliate=sub`;

    switch ($rootScope.affName) {
      case 'LG전자':
        $scope.subName = '대리점(특약점)';
        $scope.registerUrl += '&company=LG';
        break;
      case '에픽카':
        $scope.subName = '부품사';
        $scope.registerUrl += '&company=EFC';
        break;
    }

    /**
     * 서치바 관련 변수
     *
     * 날짜검색 제거로 sdate,edate 주석 2018-02-02 rony
     */

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(searchForm);

    // 검색영역 데이터
    $scope.searchData = {};
    $scope.searchData.showSearchArea = true;   // 검색어 노출여부
    $scope.searchData.showSelectArea = false;   // 선택 버튼 노출여부
    $scope.searchData.all_checked = false;  // 전체선택 여부
    $scope.searchData.totalCount = 0;       // 검색전체 건수
    $scope.searchData.selectCount = 0;      // 선택한 건수 <- 작업예정
    $scope.searchData.showCount = 500;       // 한 페이지에 보이는 건수
    $scope.searchData.showDateSearch = true;   // 상세검색에 날짜 노출 여부. 2018-02-02 rony
    $scope.searchData.search_date_type = [
      { label: gettextCatalog.getString('등록일'), value: 'wdate' },
      { label: gettextCatalog.getString('최근 접속시간'), value: 'last_login_time' }
    ];
    // 검색영역 키값
    $scope.searchData.search_key_items = [
      { label: '전체', value: 'all' }
    ];

    $scope.searchFn = {};

    // 검색 실행
    $scope.searchFn.searchDo = () => {
      $scope.searchDo(true, true);
    };
    // 검색 초기화
    $scope.searchFn.resetDo = () => {
      $scope.searchForm = angular.copy(searchForm);       // 검색 폼 초기화

      $scope.searchDo(true, true);
    };

    // 테이블 선택 삭제
    $(document).on('click', '.dataTable', () => {
      $('.dataTable .selected').removeClass('selected');
    });

    // 사용자관리 검색 우측 버튼.
    const actionsRight = [];

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: actionsRight
    };

    // 배송처 정보
    $scope.depot_info_list = {};

    $scope.grid = {};
    $scope.grid.methods = {};

    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['detail', 'sol_no', 'email', 'name', 'company_name', 'wdate', 'last_login_time', 'last_login_ip', 'm_status'],
      alignRightColumns: [],
      defaultSortingColumns: ['sol_no'], //3
      notSortingColumns: ['detail', 'm_status', 'email', 'tel', 'wdate'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/users/sublist`,
        requestWillAction: (d) => {
          const data = angular.merge({}, d, $scope.searchForm);

          return data;
        },
        requestDidAction: (result) => {
          $scope.searchData.totalCount = result.recordsTotal;

          $timeout(() => {
            $scope.grid.methods.setColumnAutoFitWidth();
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'detail',
          title: '도구',
          width: 70,
          template: (row) => {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.open(${row.sol_no})">상세</button>
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.release(${row.sol_no},${row.pa_use_yn},${row.pa_sol_no})">${row.pa_use_yn ? '해제' : '연동'}</button>
            `;
          }
        },
        {
          key: 'sol_no',
          title: '솔루션 번호',
          width: 60
        },
        {
          key: 'email',
          title: '이메일(ID)',
          width: 150
        },
        {
          key: 'name',
          title: '이름',
          width: 100
        },
        {
          key: 'company_name',
          title: '업체명',
          width: 150
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120
        },
        {
          key: 'last_login_time',
          title: '최근 접속시간',
          width: 120,
          filter: 'dateValid',
          template: (row) => {
            return row.last_login_time;
          }
        },
        {
          key: 'last_login_ip',
          title: '접속IP',
          width: 100
        },
        {
          key: 'm_status',
          title: '상태',
          width: 20,
          template: (row) => {

            if ($rootScope.affName === '에픽카') {
              return moment().format('YYYY-MM-DD') > row.sol_edate ? '만료' : '정상';

            } else {
              row.switch_yn = row.m_status === '정상' && row.use_yn === 1;

              return '<pa-ui-switch ng-model="row.entity.switch_yn" fn="grid.appScope.userStateChange(row.entity)"></pa-ui-switch>';
            }
          }
        },
      ]
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', (event, data) => {
      // 로다시 제거(문제있으면 롤백하게 주석) Boris GMPKR-9580
      data.forEach((row) => {
        $compile(row)($scope);
      });
    });

    /**
     * 대리점 상태 변경
     */
    $scope.userStateChange = async ({ sol_no, switch_yn }) => {
      let message = '';

      if (!switch_yn) {
        message = '대리점을 비활성화 하시겠습니까? \n 비활성화 시 모든 사용자가 비활성화 됩니다.';
      } else {
        message = '대리점을 활성화 하시겠습니까? \n 활성화 시 마스터 사용자만 활성화됩니다. 사용자 관리에서 추가 확인 부탁드립니다.';
      }

      const confirm = await commonSVC.showConfirm('상태 변경', message);

      if (!confirm) { return false; }

      try {
        const params = {
          sol_no,
          use_yn: switch_yn,
        };

        await userModel.subUserStatusSet(params);

        commonSVC.showToaster('success', '대리점 상태', '대리점 상태변경에 성공하였습니다.');
      } catch (error) {
        commonSVC.showToaster('error', '대리점 상태', '대리점 상태변경에 실패했습니다.');

        return false;
      } finally {
        $scope.searchDo();
      }
    };

    /**
     * 모달열기
     */
    $scope.open = (sol_no) => {
      const resolve = {
        data: {
          sol_no,
        },
        userData: () => userModel.subUserDetail({ sol_no }),
        channelList: () => shopAccountModel.shopAccountUseList({ sol_no })
      };

      const modal = commonSVC.openModal('xg', resolve, 'ConfigsSubUserDetailCtrl', 'views/configs/sub_user_detail.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });

    };

    $scope.release = async (sol_no, pa_use_yn, pa_sol_no) => {
      const params = {
        sol_no,
        pa_use_yn,
        pa_sol_no
      };

      const result = await userModel.release(params);
      if (result?.status === 200) {
        commonSVC.showToaster('success', `${$rootScope.affName === '에픽카' ? '부품사' : '대리점'} ${pa_use_yn ? '해제' : '연동'}`, `${$rootScope.affName === '에픽카' ? '부품사' : '대리점'} ${pa_use_yn ? '해제' : '연동'}에 성공하였습니다.`);
        $scope.searchDo(false);
      }
    };

    /**
     * 검색
     */
    $scope.searchDo = (refresh, noDelay) => {
      $scope.grid.methods.reloadData(() => {}, refresh, noDelay);
    };

    // 2017-06-15 chris 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', (evt, originEvt, toState) => {
      if (toState.name == 'main.configs_user') {
        const $wrapper = $('#configs_user_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(() => {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

  })

  .controller('ConfigsSubUserDetailCtrl', function ($scope, $uibModalInstance, data, userData, channelList) {
    $scope.userData = userData.data.result;
    $scope.channelList = channelList.data.results;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });

