'use strict';

angular.module('gmpApp')
  .directive('addColumnDetail', function () {
    return {
      templateUrl: 'views/modules/add_column_detail.html',
      restrict: 'E',
      scope: {
        shipment: '=',
        fromPage: '='
      },
      link: function ($scope) {
      },
      controller: function addColumnDetailController($scope, $rootScope, commonSVC, columnSVC) {
        $scope.readonly = ['claim', 'integrated'].findIndex((o) => { return o === $scope.fromPage; }) > -1;
        $scope.addColList = angular.copy(columnSVC.addColList.ord);
        $scope.columnSVC = columnSVC;
      }
    };
  });
