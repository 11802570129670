
/**
 * Created by kimbada on 2017. 9. 12..
 */

'use strict';
angular.module('gmpApp').directive('cp', function(commonSVC) {
  // dayClick시 템플릿이 떠야한다

  return {
    restrict: 'A',
    scope: {
      preview: '=',
      cal_no: '=',
      start: '='
    },
    templateUrl: 'views/home/calendar_preview.html',
    controller: function($scope) {
      $scope.openEditModal = function(number) {
        const resolve = {
          data: number //글번호
        };

        commonSVC.openModal('lg', resolve, 'HomeCalendarEdit', 'views/home/calendar_edit.html');
      };

    }
  };
});