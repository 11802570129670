/**
 * Created by kimbada on 2017. 10. 25..
 */
'use strict';

angular.module('gmpApp')
  .factory('questionSVC', function (commonSVC, gettext, gettextCatalog) {

    return {
      searchForm: {
        search_key: 'ALL',
        search_word: '',
        search_type: 'partial',
        cs_cate1: '',
        board_status: '',
        dashboard: '',
        inquiry_type: '',
        date_type: 'wdate',
        group_by: 'cs_no',
        question: '',
        user_info: '',
        //개월 수 지정
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수 <- 작업예정
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'ALL' },
          { label: gettextCatalog.getString('제목'), value: 'title' },
          { label: gettextCatalog.getString('내용'), value: 'content' },
          { label: gettextCatalog.getString('문의자'), value: 'mem.name' },
        ],
        search_date_type: [
          { label: gettextCatalog.getString('문의 일시'), value: 'wdate' },
          { label: gettextCatalog.getString('답변일'), value: 'reply_time' }
        ]
      }
    };
  });