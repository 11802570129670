'use strict';

angular.module('gmpApp')
  .controller('StockInventoryOrderListCtrl', function ($scope, resolve, $uibModalInstance, settings, $rootScope, commonSVC, warehouseList, systemList) {
    $scope.title = resolve.sku_code ? `SKU: ${resolve.sku_code}` : `세트코드: ${resolve.set_cd}`;  // modal title
    const set_no = resolve.set_no || false;

    // searchData 는 필수임으로 넣어주어야 함 2018-02-28 rony
    $scope.searchData = {};
    /**
     * 취소 버튼 클릭 시
     */
    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

    /**
     * 상세페이지 모달
     * 2018-06-15 lucas
     * views/order/shipment/detail_order.html
     * 위 파일을 상세페이지 모달 용으로 만든 것 같은데 컨트롤러를 만들지 않아
     * views/order/shipment/detail.html 파일로 대체함
     */
    $scope.showDetail = function(uniq, pa_shop_cd) {
      const resolve = {
        data: {
          fromPage: set_no ? 'set' : 'product',
          uniq: uniq,
          set_no: set_no,
          warehouseList: warehouseList.data.result || [],
          systemList: systemList.data || []
        }
      };

      let modal;

      if (pa_shop_cd?.startsWith('X')) {
        modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
      } else {
        modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
      }

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    /**
     * datatable 설정
     */
    $scope.grid = {};
    $scope.grid.methods = {};

    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'ord_status', 'shop_cd', 'shop_ord_no', 'wdate', 'shop_sale_no', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'shop_add_opt_name', 'order_id', 'to_name', 'depot_name', ],
      alignRightColumns: [],
      defaultSortingColumns: ['shop_cd'],
      notSortingColumns: ['widget', 'shop_ord_no', 'shop_sale_no', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'shop_add_opt_name', 'order_id', 'to_name', 'depot_name'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/list-of-sku`,
        requestWillAction: function (d) {
          const data = d;

          data.search_word = set_no || resolve.sku_code;
          data.is_set = !!set_no;

          return JSON.stringify(data);
        },
        requestDidAction: function(json) {
          return json.results;
        }
      },
      columns: [
        { key: 'widget', width: 80, title: '상세보기', template: function (row) {
          return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.showDetail('${row.uniq}', '${row.pa_shop_cd}')">상세</button>`;
        } },
        { key: 'ord_status', width: 80, title: '주문상태' },
        { key: 'shop_cd', width: 90, title: '쇼핑몰(계정)', notCompile: true,
          template: function (row) {
            return row.shop_name + (row.shop_id ? `(${row.shop_id})` : '');
          } },
        { key: 'shop_ord_no', width: 150, title: '쇼핑몰 주문번호' },
        { key: 'wdate', width: 150, title: '주문 수집일' },
        { key: 'shop_sale_no', width: 100, title: '쇼핑몰 상품코드' },
        { key: 'shop_sale_name', width: 150, title: '상품명' },
        { key: 'shop_opt_name', width: 100, title: '옵션' },
        { key: 'sale_cnt', width: 50, title: '수량' },
        { key: 'shop_add_opt_name', width: 100, title: '추가구매옵션' },
        { key: 'order_id', width: 80, title: '주문자(ID)' },
        { key: 'to_name', width: 100, title: '수령자명' },
        { key: 'depot_name', width: 100, title: '배송처', template: (row) => { return resolve.set_cd ? resolve.depot_name : row.depot_name; } },
      ]
    };
  });