'use strict';

angular.module('gmpApp')
  .controller('P013RelateProductsCtrl', function ($scope, $rootScope, data, $timeout, $uibModalInstance, commonModel, commonSVC) {

    $scope.pa5_job_data = {
      get_related_products: { load: false, data: [] }
    };
    $scope.loading = false;
    $scope.pre_selected = data.set_info.key_relateProducts || [];

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.search_type = 'name';
    $scope.search_text = '';

    $scope.searchDo = () => {
      $scope.loading = true;
      run_pa5_jobs('get_related_products', '코디 상품', { [`${$scope.search_type}`]: $scope.search_text }, (data) => data);
    };

    $scope.selectCheck = () => !$scope.pa5_job_data.get_related_products.data.filter(({ check }) => check).length;

    $scope.ok = function () {
      $uibModalInstance.close({ data: $scope.pa5_job_data.get_related_products.data.filter(({ check }) => check) });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
