'use strict';

angular.module('gmpApp')
  .controller('AddSendGroupCtrl', function(
    $scope, commonSVC, $rootScope, settings, $uibModalInstance
  ) {
    function init () {
      $scope.canAdd = false;
      $scope.group = {
        name: '',
        desc: '',
      };
    }

    init ();

    $scope.validityCheck = () => {
      if ($scope.group.name && $scope.group.desc) {
        $scope.canAdd = true;
      } else {
        $scope.canAdd = false;
      }
    };

    $scope.addGroup = async () => {
      try {
        const result = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/atalk/group`, { ...$scope.group });

        if (result.data.results === 'success') {
          commonSVC.showToaster('success', '성공', '발송 그룹 생성 성공');

          $uibModalInstance.close('success');

          return;
        }
      } catch (err) {
        if (err.data.messages[0].includes('Duplicate')) {
          return commonSVC.showMessage('동일한 그룹명이 있습니다.', '다른 그룹명으로 입력해 주세요.');
        }
      }
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };
  });