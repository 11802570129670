/**
 * Created by ally on 2017. 3. 24..
 */
'use strict';
angular.module('gmpApp')
  .controller('DashboardCtrl', function ($scope, $rootScope, localStorageService, $state, $q, settings, userInfo, userModel, commonModel, adminModel, summaryModel, noticeModel, systemModel, shopAccountModel, commonSVC, PolicySVC, summarySVC, gettext, gettextCatalog, systemList, channelList, $timeout, payModel) {
    // 번역 데이터 '마스터','사용자','배송처','창고'
    gettext('마스터'); gettext('사용자'); gettext('배송처'); gettext('창고');
    // 날짜 구하기
    moment.locale('ko');
    $scope.current = moment();
    // 크롬체크
    $scope.chromeCheckBool = navigator.userAgent.toLowerCase().indexOf('chrome') === -1 && document.cookie.indexOf('ChromePanel=not') === -1;
    // 크롬체크 닫기
    $scope.chromeCheckClose = function() {
      $scope.chromeCheckBool = false;
      document.cookie = 'ChromePanel=not';
    };
    // 유저 데이터
    $scope.userPermission = userInfo.permission;
    $scope.channelPermission = channelList.data.results;
    $scope.searchPeriod = systemList.data.search_date;
    $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';
    $scope.btnIsLoading = false;
    $scope.checkSubNotice = false;

    // 대시보드 노출 데이터 필드 목록
    const dashboardField = ['주문현황', '상품현황', '재고현황', '문의현황(신규)', '주문수집추이', '최근7일쇼핑몰주문분포', '솔루션사용정보'];

    // ---대리점관리 모듈 전용 데이터---
    let isInitDone = false; // 페이지 초기화 완료 여부

    $scope.tab = {
      noitcesTab: 'system'
    };

    // Super계정 여부
    $scope.isSuperUser = userInfo.user.sol_no === 2450 || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like');
    // 차트 노출데이터 처리
    $scope.chartNoData = {
      sub: false,
      shop: false,
      category: false,
      prod: false
    };

    // 쿠폰 관리 모달
    $scope.couponManagement = () => {
      commonSVC.openModal('xg', {}, 'couponManagementCtrl', 'views/pay/modals/couponManagement.html');
    };

    // 차트 검색 조건
    const chartSearchForm = {
      sdate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
      edate: moment().format('YYYY-MM-DD'),
      date_sub: {
        salesAll: {
          sdate: moment().subtract(1, 'day').subtract(3, 'month').format('YYYY-MM-DD'),
          edate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        },
        top: {
          sdate: moment().subtract(1, 'day').subtract(3, 'month').format('YYYY-MM-DD'),
          edate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        },
      },
      sub_sol_no: '',
      sol_no_sub: {
        summary: '',
        salesAll: '',
        top: ''
      },
      date_unit: 'month',
      date_unit_sub: {
        sales: 'month',
        sub: '',
        shop: '',
        prod: '',
      },
      date_type: 'wdate',
    };

    $scope.chartSearchForm = angular.copy(chartSearchForm);

    // 차트 색상
    const chartColor = {
      shop: ['#467E36', '#28B75C', '#A0E15D', '#C3F3B5', '#EBF114', '#E0F972', '#EDFEAB', '#E7FDDA', '#F1FFDA', '#F1FFDA'],
      category: ['#00ABE8', '#65D0F0', '#65D0F0', '#00959D', '#00959D', '#9EE4E5', '#C1F1E5', '#DBF8E8', '#B9D4F3', '#B9D4F3'],
      // prod: ['#160047', '#270E77', '#582E92', '#593D9C', '#8576C7', '#4B52B1', '#792B8C', '#B652A6', '#9663B4', '#C08BCF'],
      prod: ['#C08BCF', '#F80066', '#FD2586', '#EA3995', '#FF6FA1', '#FF6FB8', '#FCA6D9', '#EAC2E8', '#FEBFD2', '#FEDFF2'],
    };

    // c3 차트 load 처리를 위한 객체 저장 변수
    const graphData = {
      c3_data: []
    };
    // ---//대리점관리 모듈 전용 데이터---

    /**
     * 전체 SKU상품
     * @param {*} type 슈퍼 계정 조회 여부 (에픽카)
     * 에픽카는 서브 계정의 SKU, 슈퍼 계정의 SKU 조회가 둘 다 있어서 type이 없을 시 sub, 있을 시 슈퍼 계정 조회
     */
    $scope.goProductList = function (type) {
      let dest = 'main.prod_product_list';
      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'product';
      $rootScope.side_search.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.selectCount = 'total';

      if ($scope.isSuperUser) {
        if (type === '에픽카') {
          $rootScope.side_search.type = 'super';
        } else {
          $rootScope.side_search.type = 'sub';
        }
        $rootScope.side_search.status = '';
        $rootScope.side_search.sub_sol_no = $scope.chartSearchForm.sub_sol_no;
        dest = 'main.sub_prod_product_list';
      }

      $state.go(dest);
      $rootScope.$broadcast('sidebarSearch_product', $scope.side_search);
    };

    // 7일 이내 배송관리
    $scope.goDelivery7 = function (status, selectCount) {
      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'delivery';
      $rootScope.side_search.status = [status];
      $rootScope.side_search.sdate = moment().subtract(7, 'day').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'ord_status_mdate';
      $rootScope.side_search.selectCount = selectCount;
      $rootScope.side_search.selectedShopType = $scope.selectedShopType;
      $state.go('main.order_shipment_delivery_list');
      $rootScope.$broadcast('sidebarSearch_delivery', $scope.side_search);
    };

    // 오늘 수집된 주문
    $scope.goOrderToday = function () {
      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'order';
      $rootScope.side_search.status = ['신규주문', '주문보류'];
      $rootScope.side_search.sdate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.selectCount = 'collect';
      $rootScope.side_search.selectedShopType = $scope.selectedShopType;
      $state.go('main.order_shipment_order_list');
      $rootScope.$broadcast('sidebarSearch_order', $scope.side_search);
    };

    // 출고지연 주문
    $scope.goDelayIntegrated = function () {
      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'integrated';
      $rootScope.side_search.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.selectCount = 'delayShip';
      $rootScope.side_search.delay_ship = true;
      $rootScope.side_search.selectedShopType = $scope.selectedShopType;
      $state.go('main.order_shipment_integrated_list');
      $rootScope.$broadcast('sidebarSearch_integrated_list', $scope.side_search);
    };

    // 신규주문 중 배송지연
    $scope.goDelayOrder = function () {
      let dest = 'main.order_shipment_order_list';

      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'order';
      $rootScope.side_search.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.selectCount = 'total';
      $rootScope.side_search.status = ['신규주문', '주문보류'];
      $rootScope.side_search.delay_status = '1';

      if ($scope.isSuperUser && $rootScope.affName === 'LG전자') {
        dest = 'main.order_shipment_agency_list';
        $rootScope.side_search.sdate = $scope.chartSearchForm.sdate;
        $rootScope.side_search.edate = $scope.chartSearchForm.edate;
        $rootScope.side_search.date_type = 'o.wdate';
        $rootScope.side_search.sub_sol_no = $scope.chartSearchForm.sub_sol_no;
      }

      $rootScope.side_search.selectedShopType = $scope.selectedShopType;

      $state.go(dest);
      $rootScope.$broadcast('sidebarSearch_order', $scope.side_search);
    };

    // 상품현황 링크
    $scope.goProductDetail = function(status) {
      let dest = 'main.online_product_list';
      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'online';
      $rootScope.side_search.sale_status = status;
      $rootScope.side_search.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');

      if ($scope.isSuperUser && $rootScope.affName === 'LG전자') {
        dest = 'main.sub_online_product_list';
        $rootScope.side_search.date_type = 'wdate';
        $rootScope.side_search.sub_sol_no = $scope.chartSearchForm.sub_sol_no;
      }

      $state.go(dest, { modal: '' });
      $rootScope.$broadcast('sidebarSearch_online_product', $scope.side_search);
    };

    // 문의현황 링크
    $scope.goInquiry = function (type, select) {
      let dest = 'main.order_customer_inquiry_list';

      $rootScope.side_search.search = true;
      $rootScope.side_search.page = 'inquiry';
      $rootScope.side_search.inq_status = '신규문의';
      $rootScope.side_search.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.inq_type = type;
      $rootScope.side_search.selectCount = select;

      if ($scope.isSuperUser && $rootScope.affName === 'LG전자') {
        $rootScope.side_search.sub_sol_no = $scope.chartSearchForm.sub_sol_no;
        dest = 'main.order_customer_inquiry_agency_list';
      }

      $state.go(dest);
      $rootScope.$broadcast('sidebarSearch_inquiry', $scope.side_search);
    };

    // 나머지 링크
    $scope.goDetail = function (page, status, selectCount, go) {
      let dest = go;

      $rootScope.side_search.search = true;
      $rootScope.side_search.page = page;
      $rootScope.side_search.status = [status];
      $rootScope.side_search.sdate = page.includes('inventory') ? '2000-01-01' : moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $rootScope.side_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.side_search.date_type = 'wdate';
      $rootScope.side_search.selectCount = selectCount;

      if (status === '신규주문' || $scope.isSuperUser) {
        $rootScope.side_search.delay_status = '';
      }

      if ($scope.isSuperUser) {
        if (['claim', 'order'].includes(page) && $rootScope.affName === 'LG전자') {
          page = 'order';
          dest = 'main.order_shipment_agency_list';
          $rootScope.side_search.date_type = $rootScope.side_search.date_type.replace('wdate', 'o.wdate');
        } else if (page.includes('inventory')) {
          $rootScope.side_search.type = $rootScope.affName === '에픽카' && page.split('|')[1];
          page = 'product';
          $rootScope.side_search.status = selectCount;
          dest = 'main.sub_prod_product_list';
        }
        $rootScope.side_search.sub_sol_no = $scope.chartSearchForm.sub_sol_no;
        $rootScope.side_search.page = page;
      }

      if (['order', 'unstoring', 'delivery', 'claim'].includes(page)) {
        $rootScope.side_search.selectedShopType = $scope.selectedShopType;
      }

      $state.go(dest);
      $rootScope.$broadcast(`sidebarSearch_${page}`, $scope.side_search);
    };

    // 새로고침
    // 새로고침 시 subType이 sub인 경우 하위 계정 데이터 조회 (LG는 하위계정 데이터만 조회하기 때문에 예외)
    $scope.refreshClass = {};
    $scope.refresh = function (dataType, subType) {
      // 웨이크업 통해 로그인한 경우 만료돼도 대시보드는 열어줌. 조회는 불가능해야 하므로 return
      if (userInfo.user.sol_expired) {
        return;
      }

      if (dataType === 'all' && $scope.isSuperUser && $rootScope.affName === 'LG전자') {
        // all일경우 주문현황, 상품현황, 재고현황, 문의현황(신규) 데이터 새로 불러옴
        for (let i = 0; i < 4; i++) {
          $scope.refreshClass[dashboardField[i]] = ' fa-spin';
        }
      } else {
        $scope.refreshClass[dataType] = ' fa-spin';
      }
      $timeout(function () {
        const params = {
          period: systemList.data.search_date,
          dataType: dataType === 'all' ? '주문현황,상품현황,재고현황,문의현황(신규)' : dataType,
          sub_sol_no: $scope.isSuperUser && $rootScope.affName === 'LG전자' ? ($scope.chartSearchForm.sub_sol_no ? [Number($scope.chartSearchForm.sub_sol_no)] : $scope.subUserList.map(({ sol_no }) => sol_no)) : [],
          shopType: $scope.selectedShopType
        };

        if (subType === 'sub') {
          params.dataType = dataType;
          params.sub_sol_no = $scope.chartSearchForm.sub_sol_no ? [Number($scope.chartSearchForm.sub_sol_no)] : $scope.subUserList.map(({ sol_no }) => sol_no);
        }

        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/home/dashboard/getData`, params, function (state, data) {
          if (state == 'success') {
            if (subType === 'sub') {
              $scope.subDashboardData = data.result[0];
              $scope.refreshClass[dataType] = '';
            } else {
              if (dataType === 'all' && $scope.isSuperUser) {
                // all일경우 주문현황, 상품현황, 재고현황, 문의현황(신규) 데이터 새로 불러옴
                Object.keys(data.result).forEach(res => {
                  data.result[res[0].s_type] = res;
                  $scope.refreshClass[[res[0].s_type]] = '';
                });
              } else {
                $scope.dashboardData[dataType][0] = data.result[0][0];
                $scope.refreshClass[dataType] = '';
              }
            }
          }

          dashboardPermission();

          if (dataType === '주문수집추이') {
            chartGenerator(data.result[0]);
          } else if (dataType == '최근7일쇼핑몰주문분포') {
            donutGenerator(data.result[0]);
          }
        });
      }, 1000); // 새로고침 누르면 1초간 화살표 돌아가게 만듦
    };

    $scope.selectShopType = (shopType) => {
      $scope.selectedShopType = shopType;

      dashboardInit('state');
    };

    // var dashboardCollect;
    // var dashboardSales;
    // var dashboardDonut;

    // 권한 별 대시보드 출력 데이터 설정
    function dashboardPermission() {
      // 권한 별 주문 필드 노출 데이터
      const ordPermission = {
        order: ['today_collect_cnt', '결제완료_cnt', '신규주문_cnt', '신규주문_배송지연_cnt', '배송중_cnt', '배송완료_cnt', '구매결정_cnt', '취소요청_cnt', '반품요청_cnt', '교환요청_cnt'],
        shipping: ['출고대기_cnt', '출고보류_cnt', '운송장출력_cnt', '출고완료_cnt'],
        depot_user: ['out_delay_cnt', '주문재확인_cnt', '출고대기_cnt', '출고보류_cnt', '운송장출력_cnt', '출고완료_cnt']
      };

      // 주문관리-출고관리 권한은 일부 공유하는 필드 (주문재확인, 출고지연) 외에 해당사항 없으면 삭제
      if (!$scope.userPermission.order.use_yn) {
        ordPermission.order.forEach(key => { delete $scope.dashboardData['주문현황'][0][key]; });
      }
      if (!$scope.userPermission.shipping.use_yn) {
        ordPermission.shipping.forEach(key => { delete $scope.dashboardData['주문현황'][0][key]; });
      }

      // 배송처는 출고 관련된 주문만 출력해줘야 함
      if ($rootScope.user_profile.auth_type === '배송처') {
        const stockOrder = {};

        ordPermission.stock_user.forEach(key => { stockOrder[key] = $scope.dashboardData['주문현황'][0][key]; });

        $scope.dashboardData['주문현황'][0] = stockOrder;
      }
    }

    // 주문 수집 추이 관련 함수
    function chartGenerator(data) {
      const chartCountData = [];
      const chartSumData = [];
      const chartAxisDate = [];

      data.forEach(function(element) {
        chartCountData.push(element.cnt);
        chartSumData.push(element.sales);
        chartAxisDate.push(element.curdate);
      });

      // 주문 수집 건수
      c3.generate({
        padding: {
          right: 20
        },
        bindto: '#collect_count',
        data: {
          x: '날짜',
          columns: [
            ['날짜'].concat(chartAxisDate),
            ['건수'].concat(chartCountData),
          ],
        },
        legend: {
          show: false
        },
        axis: {
          y: {
            show: true,
            padding: {
              bottom: 0
            }
          },
          x: {
            type: 'timeseries',
            tick: {
              // rotate: 90,
              format: function (x) {
                return `${x.getMonth() + 1}.${x.getDate()}`;
              }
            }
          },
        },
        // tooltip: {
        //   show: false
        // }
      });
      // 주문 수집 금액
      c3.generate({
        padding: {
          right: 20
        },
        bindto: '#collect_sales',
        data: {
          x: '날짜',
          columns: [
            ['날짜'].concat(chartAxisDate),
            ['금액'].concat(chartSumData)
          ],
        },
        legend: {
          show: false
        },
        axis: {
          y: {
            tick: {
              format: function (d) {
                return d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            },
            padding: {
              bottom: 0
            }
          },
          x: {
            type: 'timeseries',
            tick: {
              // rotate: 90,
              format: function (x) {
                return `${x.getMonth() + 1}-${x.getDate()}`;
              }
            }
          }
        },
        // tooltip: {
        //   show: false
        // }
      });
    }
    // 쇼핑몰별 주문 분포 함수
    function donutGenerator(data) {
      c3.generate({
        bindto: '#donut',
        data: {
          columns: (function () {
            const x = [];

            data.forEach(function(element) {
              const y = [];

              y.push(element.shop_name);
              y.push(element.cnt);
              x.push(y);
            });

            return x;
          })(),
          type: 'donut',
        },
        donut: {
          title: '쇼핑몰별 분포',
          label: {
            show: false
          }
        },
        tooltip: {
          format: {
            value: function (value, ratio) {
              return `${value}건` + `(${(ratio * 100).toFixed(2)}%)`;
            }
          }
        }
      });
    }

    // 대시보드 초기 데이터 로드 함수
    // LG전자는 sub계정의 데이터만 조회, 에픽카는 슈퍼 계정 조회 후 서브 계정 (재고) 조회
    const dashboardInit = _.throttle(async (mode) => {

      // 서브계정 리스트
      $scope.subUserList = $rootScope.affName ? (await commonModel.subUserList()).data : [];
      // 대시보드 권한 리스트
      const permissionList = {
        order: '주문현황,주문수집추이,최근7일쇼핑몰주문분포',
        shipping: '주문현황',
        online: '상품현황',
        stock: '재고현황',
        inquiry: '문의현황(신규)',
        pay: '솔루션사용정보'
      };

      let permission;

      if ($scope.isSuperUser && $rootScope.affName && $rootScope.affName === 'LG전자') {
        permission = '주문현황,상품현황,재고현황,문의현황(신규)';
      } else if ($scope.userPermission.master.use_yn) {
        permission = dashboardField.join(','); // 전체 필드 조회
      } else if ($rootScope.user_profile.auth_type === '배송처') {
        permission = '주문현황,주문수집추이,최근7일쇼핑몰주문분포,재고현황';
      } else {
        permission = Object.keys(permissionList).filter(p => $scope.userPermission[p]?.use_yn).map(p => permissionList[p]).join(',');
      }

      const params = {
        period: systemList.data.search_date,
        dataType: permission,
        sub_sol_no: $scope.isSuperUser && $rootScope.affName === 'LG전자' ? ($scope.chartSearchForm.sub_sol_no ? [Number($scope.chartSearchForm.sub_sol_no)] : $scope.subUserList.map(({ sol_no }) => sol_no)) : [],
        shopType: $scope.selectedShopType
      };

      $scope.btnIsLoading = true;
      // 각종 집계 데이터 불러옴
      commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/home/dashboard/getData`, params, function (state, data) {
        if (state == 'success') {
          $scope.dashboardData = {};

          data.result.forEach(res => {
            if (res[0]) { $scope.dashboardData[res[0].s_type] = res; }
          });

          if ($scope.isSuperUser && $rootScope.affName === '에픽카') {
            params.dataType = '재고현황';
            params.sub_sol_no = $scope.chartSearchForm.sub_sol_no ? [Number($scope.chartSearchForm.sub_sol_no)] : $scope.subUserList.map(({ sol_no }) => sol_no);

            // 에픽카 하위 부품사 재고 현황 조회
            commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/home/dashboard/getData`, params, function (state, data) {
              $scope.subDashboardData = data.result[0];
            });
          }

          dashboardPermission();

          // 차트 데이터 다시 로드
          chartGenerator(data.result.find(res => res[0].s_type === '주문수집추이'));
          donutGenerator(data.result.find(res => res[0].s_type === '최근7일쇼핑몰주문분포'));
        }

        const payIdx = data.result.findIndex(res => res[0].s_type === '솔루션사용정보');

        if (payIdx > -1) {
          const startDate = moment(data.result[payIdx][0]['시작일']);
          const endDate = moment(data.result[payIdx][0]['만료일']);

          $scope.percentage = data.result[payIdx][0]['잔여일'] / endDate.diff(startDate, 'days') * 100; // 전체 사용 기간 대비 남은 사용 기간 비율 계산
          $scope.price = data.result[payIdx][0]['월이용금액'];
        }

        $scope.btnIsLoading = false;
      });

      $scope.dashboardSolData = {};
      commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/dashboard/getSolStatus`, {}, function (state, data) {
        // 솔루션 사용정보
        $scope.dashboardSolData = data.result;
        $scope.dashboardSolData.textLeftDay = userInfo.user.sol_version == 'ZERO' ? '무제한' : `${moment(userInfo.user.sol_edate).endOf('day').diff(moment().endOf('day'), 'days')}일 남음`;
      });
      $scope.notices = {};

      // 시스템 공지사항 불러옴
      try {
        const systemNoticeResult = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/system`);

        $scope.notices.system = systemNoticeResult?.data?.results.result;

        if (mode === 'init' && !$rootScope.startWakeUpYN) {

          // 패스워드 변경 안내
          if ($scope.showPwdNoticeYN) {
            $rootScope.password = {};

            $('#modal-notice-password').removeClass('hide');
            $('.modal-password-dim').removeClass('hide');
          }

          if (!$rootScope.first_login) {
            const channels = (await shopAccountModel.shopAccountUseList({ firstShopYn: true })).data?.results || [];

            const delShopLogYn = userInfo.user.delShopLogYn, // 쇼핑몰 삭제이력 여부
                  firstShopCds = userInfo.user.first_add_shop_cds?.split(',').filter(Boolean) || [], // 최초접속자 선택 shop_cd
                  notAddFirstShopCds = !firstShopCds.every(shop_cd => channels.some(e => shop_cd === e.shop_cd)); // 최초접속자 선택 쇼핑몰 중 미등록 쇼핑몰 있는지 여부

            if ($rootScope.user_profile.auth_type !== '배송처' && !$rootScope.wakeUpTarget && !$rootScope.userProfileCheck('sol_ser', 'super_user', 'like') && $rootScope.affName !== 'LG전자') {
              // (삭제이력X && 등록된쇼핑몰계정X) || 최초접속쇼핑몰 선택쇼핑몰 중 미등록쇼핑몰O
              if ((!delShopLogYn && !channels.length) || notAddFirstShopCds || !channels.length) {
                $rootScope.firstShopAddModalTarget = true;
                $rootScope.showFirstShopAddModal();

                // 등록된 쇼핑몰계정 없는 경우에도 최초접속쇼핑몰 모달 뜨게 기획팀 요청. 변경될 수 있어서 주석처리..
                // } else if (!$rootScope.use_channel_list.length) {
                //   const re = await commonSVC.showConfirmCustom({
                //     title: '등록된 쇼핑몰이 없습니다.',
                //     text: '쇼핑몰을 등록하셔야 PLTO2.0의 온라인 판매 통합 기능을 사용할 수 있어요.',
                //     confirmButtonText: '쇼핑몰 등록하기',
                //     showCancelButton: true,
                //     html: true
                //   });

                //   if (re) {
                //     $state.go('main.settings_shop_account');
                //   } else {
                //     $rootScope.showNoticeModals();
                //   }
              } else {
                $rootScope.showNoticeModals();
              }
            } else {
              $rootScope.showNoticeModals();
            }
          }
        }
      } catch (err) {
        const msg = err?.data?.message;

        commonSVC.showToaster('error', '실패', msg);
      }

      //대리점 공지사항
      if ($rootScope.user_profile.pa_sol_no) {
        if ($rootScope.user_profile.user_grade === '마스터' && ($rootScope.affName === 'LG전자' && !$rootScope.user_profile.etc?.LGTermsAgreeDate && !$rootScope.adminMode)) {
          await PolicySVC.agreeTerms('LG', async () => {
            const memEtc = { ...$rootScope.user_profile.etc, LGTermsAgreeDate: moment().format('YYYY-MM-DD HH:mm:ss') };

            await userModel.update({
              user_no: $rootScope.user_profile.m_no,
              etc: memEtc,
              updateFields: ['etc']
            }, '');
          });
        }

        if (!$scope.checkSubNotice) {
          commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/notice/sub/popupList`, {}, function (state, data) {

            if (data?.results?.length) {
              $timeout(() => {
                showNoticePopup(data.results, 'NEVER_VIEW_POPUP_NOTICES');

                $scope.checkSubNotice = $rootScope.affName === '에픽카';
              });
            }
          });
        }
      }

      // 위수탁계약 동의
      if ($rootScope.user_profile.user_grade === '마스터' && !userInfo.user.mas_contract_yn) {
        await PolicySVC.agreeTerms('contract', async () => {
          await userModel.update({
            user_no: $rootScope.user_profile.m_no,
            contract_yn: 1,
            updateFields: ['contract_yn']
          }, '');
        });
      }

      try {
        // 쇼핑몰 공지사항 불러옴
        const shopNoticeResult = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/shop`);
        $scope.notices.shop = shopNoticeResult.data.results;

        // 쇼퍼계정 대리점 공지
        if ($rootScope.userProfileCheck('sol_ser', 'super_user', 'like') || !!$rootScope.user_profile.pa_sol_no) {
          const subNoticeResult = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/sub`, { type: $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') ? 'super' : 'sub' });
          $scope.notices.sub = subNoticeResult.data.results;
        }

        $scope.couponList = {
          available: [],
          used: []
        };

      } catch (err) {
        const msg = err?.data?.message;

        commonSVC.showToaster('error', '실패', msg);
      }

      // 쿠폰 정보
      payModel.getUserCouponList({})
        .then(re => {
          re.data.results.forEach((cpn) => {
            if (['미사용', '사용대기'].includes(cpn.cpn_status)) {
              $scope.couponList.available.push(cpn);
            } else {
              $scope.couponList.used.push(cpn);
            }
          });
        });

      // 이메일, sms 카운트 조회
      commonModel.getServiceCnt().then((re) => {
        if (re.data?.status === 'success') {
          $scope.emailCnt = re.data?.emailCnt;
          $scope.smsCnt = re.data?.smsCnt;
          $scope.atalkCnt = re.data?.atalkCnt;
        } else {
          throw new Error('EMAIL,SMS, 알림톡 카운트 조회 실패');
        }
      }).catch(err => {
        commonSVC.showToaster('error', '실패', err.message);
      });

      payModel.getSolInfo(userInfo.user_mo, function(status, data) {
        if (status === 'success') {
          $scope.solutionInfo = data.results.solutionInfos;
        }
      });
    }, 1000);

    /**
     * 대시보드 공지모달들 노출
     */
    $rootScope.showNoticeModals = from => {
      // 다시보기를 통한 접근이면 공지모달 노출x
      if (from === 'manual') {
        return;
      }

      $state.go('main.home');

      const promiseArr = [];
      let noticeResult = null, // 공지사항 조회 결과
          cntResult = null; // 카운트정보 조회 결과

      // 공지사항
      promiseArr.push(commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/system`)
        .then(data => {
          noticeResult = data.data?.results?.popup;
        })
        .catch(err => {
          commonSVC.showToaster('error', '실패', err.data?.message);
        })
      );

      // 카운트정보
      promiseArr.push(commonModel.solutionUseInfo({ period: '1', data_type: '솔루션사용정보', shopType: $scope.selectedShopType })
        .then(data => {
          cntResult = data.data.result.charge;
        }).catch(() => {})
      );

      $q.all(promiseArr)
        .then(() => {
          // 작업카운트 안내 모달
          if (cntResult) {
            const { ord_cnt, ord_cnt_limit, ol_shop_cnt, ol_shop_cnt_limit } = cntResult;

            const orderCheck = ord_cnt_limit === null ? true : (ord_cnt / ord_cnt_limit < 0.9);
            const productCheck = ol_shop_cnt_limit === null ? true : (ol_shop_cnt / ol_shop_cnt_limit < 0.9);

            // 제로버전 버전추천모달 안에 업그레이드 내용이 있어 중복된 모달 노출하지 않도록 조건 추가 shopAccount_exceeded_zero
            if ((!orderCheck || !productCheck) && ![1051, 1057].includes($rootScope.user_profile.aff_no)) {
              $rootScope.workCount_exceeded = true;

              if (!userInfo.user.shopAccount_exceeded_zero && !$rootScope.showWorkCountModal.closeYn) {
                $rootScope.showWorkCountModal.closeYn = true;
                commonSVC.openModal('md', {
                  data: {
                    orderCheck: orderCheck,
                    productCheck: productCheck
                  },
                  backdropClass: 'count-modal-backdrop',
                  windowClass: 'count-modal-window'
                }, 'WorkCountAlarmModalCtrl', 'views/common/workCountAlarmModal.html', false, true, false);
              }
            }
          }

          // 공지사항 모달
          if (noticeResult) {
            $timeout(() => {
              showNoticePopup(noticeResult);
            });
          } else {
            // 입점결과 안내 알림
            $rootScope.notifyShopEntryStatus();
          }
        });

      // 정기적 사용자정보 확인 안내 (솔루션 가입 6개월 지난 마스터계정만 체크)
      if ($scope.showCmpInfoNoticeYN) {
        const compInfo_check = localStorageService.get('compInfo_check'),
              today = moment().format('YYYYMMDD');

        if (!compInfo_check || today >= parseInt(compInfo_check)) {
          const url = `${settings.pa20ApiUrl}/app/company/check-last-update`;

          commonSVC.sendUrl('POST', url, {})
            .then(re => {
              const last_info_mdate = re.data.result;

              // 마지막 수정일이 없거나 수정한지 6개월이 지났을 경우
              if (!last_info_mdate || moment().endOf('day').diff(moment(last_info_mdate).endOf('day'), 'days') >= 180) {
                adminModel.load({ c_no: userInfo.user.c_no, onlyCompany: true })
                  .then(re => {
                    $rootScope.compInfo = re.data.results.company;
                    $rootScope.copy_compInfo = { ...$rootScope.compInfo }; // 모달 표기용 값

                    $('.modal-company-dim').removeClass('hide');
                    $('#modal-notice-company').removeClass('hide');
                  });

              }
            })
            .catch(err => {
              commonSVC.showToaster('error', '실패', '사용자 정보 조회 실패');
            });
        }
      }
    };

    $scope.check_popup = false;

    /**
     * 사용자정보 변경
     */
    $rootScope.updateCompanyInfo = () => {
      if (!$scope.companyForm.$valid) {
        commonSVC.showMessage('값을 모두 입력해주시기 바랍니다.');

        return;
      }

      const update = {
        name: $rootScope.compInfo.name,
        ceo_name: $rootScope.compInfo.ceo_name,
        addr1: $rootScope.compInfo.addr1,
        c_type: $rootScope.compInfo.c_type,
        uptae: $rootScope.compInfo.uptae,
        upjong: $rootScope.compInfo.upjong,
      };

      adminModel.set({ company: update, is_regular_check: true })
        .then(re => {
          if (re.status === 200) {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('사업자정보가 수정되었습니다.'));

            // 6개월 뒤 다시 확인
            localStorageService.set('compInfo_check', moment().add(180, 'd').format('YYYYMMDD'), false);

            $('.modal-company-dim').addClass('hide');
            $('#modal-notice-company').addClass('hide');
          }
        })
        .catch(err => {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('변경사항 저장에 실패하였습니다.'));
        });
    };

    // 비밀번호 변경
    $rootScope.regist = function () {
      let old_password = $rootScope.password.old;
      let new_password = $rootScope.password.new;
      let confirm_password = $rootScope.password.confirm;

      const regExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/;
      const validPassword = regExp.test(new_password);

      $rootScope.password.old_text = '';
      $rootScope.password.new_text = '';
      $rootScope.password.confirm_text = '';

      if (!validPassword) {
        $rootScope.password.new_text = gettextCatalog.getString('영문+숫자+특수문자(`‘“ 제외) 8자 이상으로 입력해주시기 바랍니다.');

        return false;
      }

      if (new_password !== confirm_password) {
        $rootScope.password.confirm_text = gettextCatalog.getString('새 비밀번호와 동일하게 입력해주시기 바랍니다.');
        old_password = '';
        new_password = '';
        confirm_password = '';

        return false;
      } else {
        const resolve = {
          m_no: userInfo.user.m_no,
          old_password: old_password,
          new_password: new_password
        };

        userModel.setPassword(resolve, function (state, result) {
          if (result == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('비밀번호가 수정 되었습니다.'));
            $rootScope.password.old = '';
            $rootScope.password.new = '';
            $rootScope.password.confirm = '';
            $('#modal-notice-password').addClass('hide');
            $('.modal-password-dim').addClass('hide');
          } else {
            if (result.data.message == 'same_password') {
              $rootScope.password.new_text = gettextCatalog.getString('현재 비밀번호와 다른 비밀번호를 입력해주시기 바랍니다.');
            } else {
              $rootScope.password.old_text = gettextCatalog.getString('현재 비밀번호와 일치하지 않습니다.');
            }
          }
        });
      }
    };

    async function showNoticePopup(notices, STORAGE_KEY = 'NEVER_VIEW_NOTICES') {
      const NOTICE_STORAGE_KEY = STORAGE_KEY;

      const neverViewNoticeNos = localStorageService.get(NOTICE_STORAGE_KEY) || [];
      const filteredNotices = notices.filter(({ noti_no }) => !neverViewNoticeNos.includes(noti_no));

      try {
        if (filteredNotices.length) {
          const data = {
            data: { notices: filteredNotices },
            backdropClass: 'notice-modal-backdrop',
            windowClass: 'notice-modal-window'
          };

          const newNeverViewNoticeNos = await commonSVC.openModal('notice', data, 'noticeCtrl', 'views/home/modals/notice.html', false, false, false).result;

          localStorageService.set(NOTICE_STORAGE_KEY, [...neverViewNoticeNos, ...newNeverViewNoticeNos]);
        }
      } finally {
        const leftDay = moment(userInfo.user.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');
        const isVersionAdvisorTarget = userInfo.user.shopAccount_exceeded_zero
          || (!$scope.solutionInfo.auto_payment_yn && userInfo.user.sol_version !== 'ZERO' && (leftDay >= 0 && leftDay < 3));

        // 공지사항모달 종료 후 주문매출리포트 출력
        if ($rootScope.isShowOrderReport) {
          showReportModal();
        } else if (isVersionAdvisorTarget) {
          showVersionAdvisor();
        } else {
          // 입점결과 안내 알림
          $rootScope.notifyShopEntryStatus();
        }

        /**
         * 가이드 강제 오픈 설정이 되어있는경우 도움말 모달 열어줌.
         */
        if ($rootScope.getLocalStorageWithExpire('forceOpenHelpModal')) {
          $rootScope.openGuide();
        }
      }
    }

    // 비밀번호 변경 공지 닫기
    $rootScope.noticePasswordClose = function(type) {
      if (type === 'next') {
        localStorage.removeItem('pwd_change');
      } else if (type === 'week') {
        localStorage.setItem('pwd_change', moment().add(14, 'd').format('YYYYMMDD'));
      }
      $('#modal-notice-password').addClass('hide');
      $('.modal-password-dim').addClass('hide');
    };

    // 정기적 사용자 정보확인 팝업 닫기
    $rootScope.companyInfoClose = function(type) {
      if (type === 'no_change') {
        localStorageService.set('compInfo_check', moment().add(180, 'd').format('YYYYMMDD'));
      } else if (type === 'week') {
        localStorageService.set('compInfo_check', moment().add(14, 'd').format('YYYYMMDD'));
      }

      $('.modal-company-dim').addClass('hide');
      $('#modal-notice-company').addClass('hide');
    };

    dashboardInit('init');
    // 시스템 공지사항 보여줄지 쇼핑몰 공지사항 보여줄지 설정하는 변수 기본값: 시스템 공지사항
    $scope.showSystemNotice = true;
    // 공지사항 목록으로 이동하는 함수
    $scope.moreNotices = function(type) {
      $rootScope.noticeListShop = !$scope.showSystemNotice;
      $rootScope.noticeCurrentTap = type;
      $state.go('main.notice_list', {});
    };

    // dashboard 접근시마다 데이터 새로 불러와줌
    $scope.$on('$stateChangeSuccessGlobal', async function (evt, originEvt, toState) {
      if (toState.name == 'main.home') {
        dashboardInit('state');
      }
    });

    // ----- 차트 관련 함수 (대리점 모듈 전용) -----
    /**
    * 초기 그래프 생성 함수
    * Lucas 200316
    * @param {Object} results 그래프에 사용될 데이터 오브젝트
    * @returns {Object} 추후 그래프의 데이터 갱신에 사용될 메소드가 담긴 c3 오브젝트 return;
    */
    const initGraph = results => {
      const data = angular.copy(results);

      $('.spinner_chart').addClass('hidden');

      return [
        // 매출현황
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          grid: {
            x: {
              show: false
            }
          },
          bindto: '#sales_all_super',
          data: {
            x: 'x',
            xFormat: '%Y-%m-%d',
            columns: [
              ['x'].concat(data.sales.map(({ curdate }) => curdate)),
              ['sales_total'].concat(data.sales.map(({ sales }) => sales))
            ],
            names: {
              sales_total: '매출'
            },
            colors: {
              sales_total: '#FE5051'
            },
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: x => { return formatter(x); }
              },
            },
            y: {
              tick: {
                format: d3.format(',')
              }
            }
          },
          legend: {
            show: false
          },
          transition: summarySVC.chartData.transition
        }),
        // 대리점 별 매출 순위 TOP10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_sub',
          data: {
            x: 'x',
            columns: [
              ['x'].concat(data.sub.map(({ c_name }) => c_name)),
              ['sales'].concat(data.sub.map(({ sales }) => sales))
            ],
            type: 'bar',
            names: {
              sales: '매출'
            },
          },
          axis: {
            rotated: true,
            x: {
              type: 'category',
            }
          },
          legend: {
            show: false,
          },
          transition: summarySVC.chartData.transition
        }),
        // 쇼핑몰 TOP 10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_shop_super',
          data: {
            columns: data.shop.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []),
            type: 'donut',
            names: Object.fromEntries(data.shop.map((e, i) => [i + 1, `${e.shop_name}${e.shop_name !== '직접입력' ? `(${e.shop_id})` : ''}`])),
            colors: Object.fromEntries(data.shop.map((e, i) => [i + 1, chartColor.shop[i]])),
          },
          donut: {
            label: {
              show: true,
            }
          },
          legend: {
            show: true,
            position: 'right'
          },
          transition: summarySVC.chartData.transition
        }),
        // 카테고리 TOP 10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_category',
          data: {
            columns: data.category.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []),
            type: 'donut',
            names: Object.fromEntries(data.category.map((e, i) => [i + 1, commonSVC.getByteLength((e.sol_cate2 || e.sol_cate1)) > 40 ? commonSVC.cutByByte((e.sol_cate2 || e.sol_cate1), 40) + '...' : (e.sol_cate2 || e.sol_cate1)])),
            colors: Object.fromEntries(data.category.map((e, i) => [i + 1, chartColor.category[i]])),
          },
          donut: {
            label: {
              show: true,
            }
          },
          legend: {
            show: true,
            position: 'right'
          },
          transition: summarySVC.chartData.transition
        }),
        // 상품별 매출 TOP 10
        c3.generate({
          size: summarySVC.chartData.size,
          padding: summarySVC.chartData.padding,
          bindto: '#top10_prod_super',
          data: {
            columns: data.prod.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []),
            type: 'donut',
            names: Object.fromEntries(data.prod.map((e, i) => [i + 1, commonSVC.getByteLength(e.shop_sale_name) > 40 ? commonSVC.cutByByte(e.shop_sale_name, 40) + '...' : e.shop_sale_name])),
            colors: Object.fromEntries(data.prod.map((e, i) => [i + 1, chartColor.prod[i]])),
          },
          donut: {
            label: {
              show: true,
            }
          },
          legend: {
            show: true,
            position: 'right'
          },
          transition: summarySVC.chartData.transition
        })];
    };

    /**
     * 검색날짜 단위 변경
     * (매출현황: 월별/주별/일별 데이터 출력, 나머지차트: 최근 1개월, 1주, 1일 데이터 출력)
     * @param {String} date_unit 검색날짜 단위 (day, week, month)
     * @param {String} type 차트 타입
     */
    $scope.changeDateUnit = _.throttle((date_unit, type) => {
      if (type === 'sales') {
        $scope.chartSearchForm.date_unit = date_unit;
      } else {
        $scope.chartSearchForm.date_unit_sub[type] = date_unit;
      }

      $scope.searchDo(type, type === 'sales' ? '' : date_unit);
    }, 1000);

    /**
     * 조회
     * @param {String} type 차트 타입
     * @param {String} date_range 최근 날짜범위 (month: 최근 1개월, week: 1주, day: 1일)
     */
    $scope.searchDo = _.throttle(async (type, date_range) => {
      const data = angular.copy($scope.chartSearchForm);
      let searchType = type;

      data.isSuper = true;

      if (type === 'all') {
        data.typeList = ['sales', 'shop', 'prod', 'sub', 'category'];
      } else {
        if (type === 'top') {
          data.typeList = ['shop', 'prod', 'category'];
        } else if (type === 'salesAll') {
          data.typeList = ['sales', 'sub'];
        } else if (type === 'shop' && date_range) {
          data.typeList = ['shop', 'category'];
        } else {
          data.typeList = [type];
        }

        // 타입별 조회일자 / 조회날짜단위 별도 적용
        if (['sales', 'sub'].includes(type)) {
          searchType = 'salesAll';
        } else if (['shop', 'prod', 'category'].includes(type)) {
          searchType = 'top';
        }

        data.sdate = $scope.chartSearchForm.date_sub[searchType].sdate;
        data.edate = $scope.chartSearchForm.date_sub[searchType].edate;
        data.sub_sol_no = $scope.chartSearchForm.sol_no_sub[searchType];

        if (date_range) {
          data.sdate = moment().subtract(1, date_range).format('YYYY-MM-DD');
          data.edate = moment().subtract(1, 'day').format('YYYY-MM-DD');
        }
      }

      // 스피너 노출 및 차트 숨김
      for (const type of data.typeList) {
        $(`.spinner_${type}`).removeClass('hidden');
        $(`.chart_${type}`).addClass('hidden');
        $scope.chartNoData[type] = false;
      }

      try {
        const { data: { results } } = await summaryModel.getData(data);

        reloadGraph(results, data.typeList);

        $timeout(() => {
          // 데이터 없는경우 처리
          for (const type of data.typeList) {
            if (!results[type].length) {
              $scope.chartNoData[type] = true;
              $(`.chart_${type}`).addClass('hidden');
            }
          }
        });

      } catch (err) {
        commonSVC.showToaster('error', '실패', '통계 데이터 조회에 실패했습니다.');

        $('.spinner_default').addClass('hidden');
        $('.chart_default').removeClass('hidden');
      }
    }, 1000);

    /**
     * 검색폼 초기화
     */
    const formReset = type => {
      $scope.chartSearchForm.date_sub[type] = angular.copy(chartSearchForm.date_sub[type]);
      $scope.chartSearchForm.sol_no_sub[type] = angular.copy(chartSearchForm.sol_no_sub[type]);

      if (type === 'salesAll') {
        $scope.chartSearchForm.date_unit = angular.copy(chartSearchForm.date_unit);
        $scope.chartSearchForm.date_unit_sub.sub = angular.copy(chartSearchForm.date_unit_sub.sub);
      } else {
        $scope.chartSearchForm.date_unit_sub.shop = angular.copy(chartSearchForm.date_unit_sub.shop);
        $scope.chartSearchForm.date_unit_sub.prod = angular.copy(chartSearchForm.date_unit_sub.prod);
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = _.throttle(type => {
      formReset(type);
      $scope.searchDo(type);
    }, 1000);

    // 차트 날짜형식 formatter
    let formatter;
    function updateFormatter(byMonth) {
      formatter = d3.time.format(byMonth ? '%Y-%m' : '%Y-%m-%d');
    }

    updateFormatter(true);

    /**
    * 그래프 데이터 갱신 함수
    * @param {Object} data 그래프 갱신에 사용될 데이터 오브젝트
    * @param {Array} typeList 업데이트할 그래프 타입 리스트
    */
    const reloadGraph = (data, typeList) => {
      const typeMap = ['sales', 'sub', 'shop', 'category', 'prod'];

      typeList.forEach(type => {
        $(`.spinner_${type}`).addClass('hidden');
        $(`.chart_${type}`).removeClass('hidden');
      });

      /** i
       * 0 - 매출현황
       * 1 - 대리점별 매출순위 TOP10
       * 2 - 쇼핑몰별 매출순위 TOP10
       * 3 - 카테고리별 매출순위 TOP10
       * 4 - 상품별 매출순위 TOP10
       */
      for (const i in graphData.c3_data) {
        if (!typeList.includes(typeMap[i])) {
          continue;
        }

        const newData = {
          columns: [],
          unload: true
        };

        if (i == 0) {
          newData.columns = [
            ['x'].concat(data.sales.map(({ curdate }) => curdate)),
            ['sales_total'].concat(data.sales.map(({ sales }) => sales))
          ];
        } else if (i == 1) {
          newData.columns = [
            ['x'].concat(data.sub.map(({ c_name }) => c_name)),
            ['sales'].concat(data.sub.map(({ sales }) => sales))
          ];
        } else if (i == 2) {
          newData.columns = data.shop.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []);
          newData.names = Object.fromEntries(data.shop.map((e, i) => [i + 1, `${e.shop_name}${e.shop_name !== '직접입력' ? `(${e.shop_id})` : ''}`]));
          newData.colors = Object.fromEntries(data.shop.map((e, i) => [i + 1, chartColor.shop[i]]));
        } else if (i == 3) {
          newData.columns = data.category.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []);
          newData.names = Object.fromEntries(data.category.map((e, i) => [i + 1, commonSVC.getByteLength((e.sol_cate2 || e.sol_cate1)) > 40 ? commonSVC.cutByByte((e.sol_cate2 || e.sol_cate1), 40) + '...' : (e.sol_cate2 || e.sol_cate1)]));
          newData.colors = Object.fromEntries(data.category.map((e, i) => [i + 1, chartColor.category[i]]));
        } else {
          newData.columns = data.prod.reduce((prev, curr, i) => [...prev, [i + 1, curr.sales_per]], []);
          newData.names = Object.fromEntries(data.prod.map((e, i) => [i + 1, commonSVC.getByteLength(e.shop_sale_name) > 40 ? commonSVC.cutByByte(e.shop_sale_name, 40) + '...' : e.shop_sale_name]));
          newData.colors = Object.fromEntries(data.prod.map((e, i) => [i + 1, chartColor.prod[i]]));
        }

        graphData.c3_data[i].load(newData);
        updateFormatter($scope.chartSearchForm.date_unit === 'month');
      }
    };

    /**
     * 엑셀 다운로드
     */
    $scope.excelDown = type => {
      if (commonSVC.checkPermission('order.roles.excelOrder', $scope.userPermission) === false) {
        return false;
      }

      const resolve = {};

      let title = '',
          typeList = [];

      switch (type) {
        case 'sales':
          title = '매출현황';
          break;
        case 'sub':
          title = '대리점별 매출';
          break;
        case 'shop':
          title = '쇼핑몰별 매출';
          break;
        case 'prod':
          title = '상품별 매출';
          break;
      }

      typeList = [type];

      $scope.chartSearchForm.typeList = typeList;
      $scope.chartSearchForm.excelType = `super_${type}`;

      const data = { ...$scope.chartSearchForm, isSuper: true };

      // 타입별 조회일자 / 조회날짜타입 별도 적용
      if (['shop', 'prod'].includes(type)) {
        data.sdate = angular.copy($scope.chartSearchForm.date_sub.top.sdate);
        data.edate = angular.copy($scope.chartSearchForm.date_sub.top.edate);
        data.sub_sol_no = angular.copy($scope.chartSearchForm.sol_no_sub.top);
        // data.date_unit = angular.copy($scope.chartSearchForm.date_unit_sub.top);
      } else if (['sales', 'sub'].includes(type)) {
        data.sdate = angular.copy($scope.chartSearchForm.date_sub.salesAll.sdate);
        data.edate = angular.copy($scope.chartSearchForm.date_sub.salesAll.edate);
        data.sub_sol_no = angular.copy($scope.chartSearchForm.sol_no_sub.salesAll);
        // data.date_unit = angular.copy($scope.chartSearchForm.date_unit_sub.salesAll);
      }

      data.date_unit = 'day';

      resolve.data = {
        type: 'all',
        excelFieldList: [],
        title: `${title}`,
        sheetName: title,
        url: '/app/summaries/excelDown',
        searchForm: data,
        page: 'summary',
        progress: true,
        isAll: true
      };

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 초기화
     */
    const init = () => {
      $timeout(async () => {
        const data = angular.copy($scope.chartSearchForm);

        data.isSuper = true;
        data.typeList = ['sales', 'shop', 'prod', 'sub', 'category'];

        try {
          const { data: { results } } = await summaryModel.getData(data);

          graphData.c3_data = initGraph(results);

          $('.spinner_default').addClass('hidden');

          // 데이터 없는경우 처리
          $timeout(() => {
            for (const type of data.typeList) {
              if (!results[type].length) {
                $scope.chartNoData[type] = true;
                $(`.chart_${type}`).addClass('hidden');
              }
            }
          });

          isInitDone = true;
        } catch (err) {
          commonSVC.showToaster('error', '실패', '통계 데이터 조회에 실패했습니다.');

          $('.spinner_default').addClass('hidden');
          $('.chart_default').removeClass('hidden');
        }
      });
    };

    if ($scope.isSuperUser && $rootScope.affName === 'LG전자') {
      init();
    }

    /**
     * 차트 redraw
     */
    function redraw() {
      for (const chart of graphData.c3_data) {
        // Force to redraw
        chart.flush();
      }
    }

    $scope.$on('$stateChangeStart', function(e, state, params, fromState) {
      // 차트 크기에 영향 주는 페이지
      const affectStates = ['main.setForm_template_add', 'main.setForm_template_add.channelSub', 'main.settings_shop_account', 'main.settings_shop_account.channelSub', 'main.settings_category', 'main.settings_category.categoryDetailPanel'];

      // 특정 페이지에서 통계 페이지로 반복해서 이동시 차트 크기가 변경되는 이슈가 있어 강제 redraw 처리
      if (isInitDone && state.name === 'main.home' && affectStates.includes(fromState.name)) {
        $timeout(() => {
          redraw();
        });
      }
    });

    /**
     * LG전자 공지 등록
     */
    $scope.addNoti = async () => {
      const resolve = {
        subUserList: () => {
          return commonModel.subUserList();
        },
        data: {
          type: 'add'
        }
      };
      const modal = await commonSVC.openModal('lg', resolve, 'AddNotiController', 'views/notice/modals/addNoti.html', undefined, undefined, false);

      modal.result
        .then((res) => {
          if (res === 'success') {
            commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/sub`, { type: $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') ? 'super' : 'sub' }, function (state, data) {
              $scope.notices.sub = data.result;
            });
          }
        });
    };

    /**
     * LG전자 공지 수정
     */
    $scope.editNoti = async (noti_no) => {
      const notidata = await noticeModel.detail({ noti_no });

      const resolve = {
        subUserList: () => {
          return commonModel.subUserList();
        },
        data: {
          ...notidata.data.results.row[0],
          type: 'edit',
          user: notidata.data.results.user
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'AddNotiController', 'views/notice/modals/addNoti.html', undefined, undefined, false);

      modal.result
        .then((res) => {
          if (res === 'success') {
            commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/sub`, { type: $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') ? 'super' : 'sub' }, function (state, data) {
              $scope.notices.sub = data.result;
            });
          }
        });
    };

    /**
     * 서브계정 공지 삭제
     */
    $scope.deleteNoti = async (noti_no) => {
      const confirm = await commonSVC.showConfirm('공지 삭제 안내', '공지 삭제 시 삭제된 정보는 복구되지 않습니다.\n해당 공지를 삭제하시겠습니까?');

      if (confirm) {
        const result = await noticeModel.delete({ noti_no });

        if (result.data?.results === 'success') {
          commonSVC.showToaster('success', '성공', '공지사항이 삭제었습니다.');

          commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/home/notice/sub`, { type: $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') ? 'super' : 'sub' }, function (state, data) {
            $scope.notices.sub = data.result;
          });
        } else {
          commonSVC.showToaster('error', '실패', '공지사항 삭제에 실패하였습니다.');
        }
      }

    };

    // ----- //차트 관련 함수 (대리점 모듈 전용) -----

    /**
     * PLTO2.0 주문/매출 리포트 열기.
     */
    async function showReportModal () {
      try {
        const { data: { result: reportUser } } = await systemModel.checkReportUser({ sol_no: userInfo.user.sol_no });
        const re = await commonSVC.openModal('xmd', { data: { isSummary: false, isAuto: true },
          addClass: 'order-report', reportUser }, 'orderReportCtrl', 'views/home/modals/orderReport.html', false, false, false).result;

        if (re === 'cancel') {
          $rootScope.notifyShopEntryStatus();
        }

      } catch (reason) {
        if (reason === 'escape key press') {
          // 입점결과 안내 알림
          $rootScope.notifyShopEntryStatus();
        }
      } finally {
        showVersionAdvisor();
      }
    }

    /**
     * 쇼핑몰 계정이 ZERO 버전에서 허용가능한 갯수보다 많이 등록된경우 버전추천 모달 오픈 (홈탭용)
     */
    function showVersionAdvisor() {
      if (userInfo.user.shopAccount_exceeded_zero) {
        $rootScope.showVersionAdvisor('main.settings_shop_account', () => {
          $timeout(function() {
            $state.go('main.settings_shop_account');
          }, 0);
        });
      } else if (!$scope.solutionInfo.auto_payment_yn && userInfo.user.sol_version !== 'ZERO') {
        // 기간만료 전 안내 모달 오픈
        const leftDay = moment(userInfo.user.sol_edate).endOf('day').diff(moment().endOf('day'), 'days');
        if (leftDay >= 0 && leftDay < 3) {
          const dd = localStorage.getItem(`notice_expired_${userInfo.user.m_no}`),
                today = moment().format('YYYYMMDD');

          if (!dd || today > dd) {
            $rootScope.openPaySolExpired();
          }
        }
      }
    }
  });
