'use strict';

angular.module('gmpApp')
  .controller('ConfigsUserCtrl', function ($scope, $compile, settings, commonSVC, userModel, gettextCatalog, $timeout, $rootScope, warehouseList, userInfo) {
    // 마스터(총괄관리자) 아니면 창 끄게만듬
    if (userInfo.user.auth_type !== '총괄관리자') {
      commonSVC.showMessage('권한이 없는 사용자입니다.');
      $rootScope.$emit('$closeTab', 'tab_configs_user');
    }

    /**
     * 모달열기
     */
    $scope.open = function (controller, type, m_no) {
      const resolve = {
        data: {
          m_no,
          warehouseList: warehouseList.data.result
        }
      };

      const modal = commonSVC.openModal('xg', resolve, controller, `views/configs/${type}.html`);

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });

    };

    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      sdate: '',
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      use_yn: '',
      user_grade: ''
    };

    /**
     * 서치바 관련 변수
     *
     * 날짜검색 제거로 sdate,edate 주석 2018-02-02 rony
     */

    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(searchForm);

    // 검색영역 데이터
    $scope.searchData = {};
    $scope.searchData.showSearchArea = true;   // 검색어 노출여부
    $scope.searchData.showSelectArea = false;   // 선택 버튼 노출여부
    $scope.searchData.all_checked = false;  // 전체선택 여부
    $scope.searchData.totalCount = 0;       // 검색전체 건수
    $scope.searchData.selectCount = 0;      // 선택한 건수 <- 작업예정
    $scope.searchData.showCount = 25;       // 한 페이지에 보이는 건수
    $scope.searchData.showDateSearch = true;   // 상세검색에 날짜 노출 여부. 2018-02-02 rony
    // 검색영역 키값
    $scope.searchData.search_key_items = [
      { label: '전체', value: 'all' }
    ];

    $scope.searchFn = {};

    // 검색 실행
    $scope.searchFn.searchDo = function() {
      $scope.searchDo(true, true);
    };
    // 검색 초기화
    $scope.searchFn.resetDo = function() {
      $scope.searchForm = angular.copy(searchForm);       // 검색 폼 초기화
      $scope.searchDetail = angular.copy(searchDetail);   // 상세 검색 초기화

      $scope.searchDo(true, true);
    };

    // 테이블 선택 삭제
    $(document).on('click', '.dataTable', function() {
      $('.dataTable .selected').removeClass('selected');
    });

    // 검색영역 상세검색 설정
    const searchDetail = [
      {
        title: gettextCatalog.getString('상태'),
        sub_title: gettextCatalog.getString('사용여부 선택'),
        name: gettextCatalog.getString('모두'),
        value: $scope.searchForm.m_status,
        search_name: 'm_status',
        item_list: [
          { name: gettextCatalog.getString('정상'), value: '정상' },
          { name: gettextCatalog.getString('가입대기'), value: '가입대기' },
          { name: gettextCatalog.getString('비활성'), value: '비활성' }
        ],
        item_key: 'name',
        item_value: 'value'
      },
      {
        title: gettextCatalog.getString('사용자 구분'),
        sub_title: gettextCatalog.getString('사용자 구분 선택'),
        name: gettextCatalog.getString('모두'),
        value: $scope.searchForm.user_grade,
        search_name: 'user_grade',
        item_list: [
          { name: gettextCatalog.getString('마스터'), value: '마스터' },
          { name: gettextCatalog.getString('총괄관리자'), value: '총괄관리자' },
          { name: gettextCatalog.getString('사용자'), value: '사용자' },
          { name: gettextCatalog.getString('배송처'), value: '배송처' }
        ],
        item_key: 'name',
        item_value: 'value'
      },
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    // 사용자관리 검색 우측 버튼.
    const actionsRight = [];

    // 사용자 추가는 마스터계정인 경우에만 노출. 2019-02-14 rony
    if (userInfo.user.auth_type === '총괄관리자') {
      actionsRight.push({
        label: `<i class="icon-plus2"></i> ${gettextCatalog.getString('사용자 추가')}`,
        small_label: '사용자추가',
        sub_label: '',
        btn_type: 'button',
        add_class: 'col-xs-12',
        perm_only: ['configs.user+admin'],
        action: function() {
          $scope.userAdd();
        }
      });
    }

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      // 검색 영역 오른쪽 버튼
      actions_right: actionsRight
    };

    // 배송처 정보
    $scope.depot_info_list = {};

    $scope.grid = {};
    $scope.grid.methods = {};

    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['m_no', 'detail', 'm.type', 'm_status', 'user_delete', 'last_login_time'],
      alignRightColumns: [],
      defaultSortingColumns: ['m_no'], //3
      notSortingColumns: ['m.type', 'm_status', 'email', 'dept', 'posi', 'tel', 'wdate', 'detail', 'user_delete'],
      notResizingColumns: ['m_no', 'detail'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/users`,
        requestWillAction: function(d) {
          const data = angular.merge({}, d, $scope.searchForm);

          return data;
        },
        requestDidAction: function(result) {
          $timeout(() => {
            $scope.grid.methods.setColumnAutoFitWidth();
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'm_no',
          title: 'NO',
          width: 20
        },
        {
          key: 'detail',
          title: '설정',
          width: 20,
          template: function(row) {
            // 마스터는 모두 볼수 있음
            // 최고관리자는 다른 최고관리자의 상세는 볼수 없음
            return `<button
              class="btn btn-default btn-xxs mr-5"
              ng-disabled="${userInfo.user.user_grade !== '마스터' && row.auth_type == '총괄관리자'}"
              ng-click="grid.appScope.open('ConfigsUserEditCtrl','user_edit','${row.m_no}')"
            >상세</button>`;
          }
        },
        {
          key: 'email',
          title: '이메일(ID)',
          width: 100
        },
        {
          key: 'name',
          title: '이름',
          width: 150
        },
        {
          key: 'auth_type',
          title: '사용자 구분',
          width: 20,
          notCompile: true,
          template: function(row) {
            let auth_type = row.auth_type;

            if (auth_type == '배송처') {
              $scope.depot_info_list[row.m_no] = row.depot_no;
              auth_type = '<span style="color: #B3B3B3">배송처</span>';
            } else if (row.m_type == '마스터') {
              auth_type = '마스터';
            }

            return gettextCatalog.getString(auth_type);
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120
        },
        {
          key: 'last_login_time',
          title: '최근 접속시간',
          width: 120,
          filter: 'dateValid',
          template: (row) => {
            return row.m_status === '가입대기' ? `가입대기 <button
              type="button"
              class="btn btn-default btn-xxs mr-5"
              ng-click="grid.appScope.reSendMail('${row.m_no}', '${row.email}')"
              data-popup="tooltip"
              data-placement="right"
              data-original-title="10분 이내 인증메일 미수신 시 스팸메일 확인 또는 재발송을 진행하시기 바랍니다."
            >메일 재발송</button>` : row.last_login_time;
          }
        },
        {
          key: 'last_login_ip',
          title: '접속IP',
          width: 100
        },
        {
          key: 'm_status',
          title: '상태',
          width: 20,
          template: (row) => {
            row.switch_yn = row.m_status === '정상' || (row.m_status === '가입대기' && row.use_yn === 1);

            const noEdit = row.m_type == '마스터' || (userInfo.user.user_grade !== '마스터' && row.auth_type == '총괄관리자');

            return !noEdit ? '<pa-ui-switch ng-model="row.entity.switch_yn" fn="grid.appScope.userStateChange(row.entity)"></pa-ui-switch>' : '';
          }
        },
        {
          key: 'user_delete',
          title: '삭제',
          width: 10,
          template: (row) => {

            const noEdit = row.m_type == '마스터' || (userInfo.user.user_grade !== '마스터' && row.auth_type == '총괄관리자');

            return !noEdit ? `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.userStateChange(row.entity, 'delete')"
            ng-disabled = "row.entity.switch_yn"}
            ><img src="/assets/images/trash.png">
            </img></button>` : '';
          }
        }
      ]
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      data.forEach((row) => {
        $compile(row)($scope);
      });
    });

    /**
     * 사용자 상태 변경
     */
    $scope.userStateChange = async ({ m_status, m_no, switch_yn, auth_type }, type = '') => {
      let result;
      if (switch_yn) {
        if (userInfo.user.userVersionCnt !== '무제한' && userInfo.user.userAccountCnt >= userInfo.user.userVersionCnt) {
          commonSVC.showMessage('실패', `이용중인 버전에서 사용할 수 있는 사용자 ID 수가 부족합니다.\n추가 사용을 원하실 경우  이용버전을 업그레이드 하시기 바랍니다.\n현재 버전 ${userInfo.user.sol_version} : 사용자 ID - ${userInfo.user.userVersionCnt}개 사용 가능`);
          commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));

          return false;
        }

        if ($scope.depot_info_list[m_no] !== undefined && $scope.depot_info_list[m_no] === null) {
          commonSVC.showMessage('배송처는 필수 입력값입니다.', '상세정보에서 배송처를 등록해주시기 바랍니다.');
          commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));

          return false;
        }

        if (userInfo.user.superUserAccountCnt >= 3 && auth_type == '총괄관리자') {
          commonSVC.showMessage('총괄 관리자는 3명만 설정할 수 있습니다.', '현재 총괄 관리자를 사용자로 변경 하신 후 이용해 주세요.');
          commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));

          return false;
        }

        const param = {
          type: 'activate',
          m_status,
          m_no
        };

        try {
          result = await userModel.changeStatus(param);

          userInfo.user.userAccountCnt = result.data.total;
          userInfo.user.superUserAccountCnt = result.data.superCnt;

          $rootScope.$applyAsync();

          commonSVC.showToaster('success', '', gettextCatalog.getString('사용자 상태가 정상으로 수정 되었습니다.'));
        } catch (error) {
          if (error.data.message == 'limit_error') {
            userInfo.user.userAccountCnt = error.data.total;
            userInfo.user.superUserAccountCnt = error.data.superCnt;

            $rootScope.$applyAsync();

            commonSVC.showMessage('실패', `이용중인 버전에서 사용할 수 있는 사용자 ID 수가 부족합니다.\n추가 사용을 원하실 경우  이용버전을 업그레이드 하시기 바랍니다.\n현재 버전 ${userInfo.user.sol_version} : 사용자 ID - ${userInfo.user.userVersionCnt}개 사용 가능`);
            commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));
          }

          return false;
        }
      }
      else {
        let showMsg = [];

        if (userInfo.user.solAccountCnt > 1) {
          showMsg = type === 'delete' ?
            ['※ 주의 ※', `<font color="red">사용자 삭제 시 플토2.0과 플토Global에서 모두 삭제됩니다.</font>
            <br>플토2.0의 접속권한만 해제하고 싶으신 경우 상태를 비활성화로 변경해주시기 바랍니다.
            <br>플토2.0과 플토Global에서 사용자를 삭제하시겠습니까?`] :
            ['※ 주의 ※', `<font color="red">사용자 비활성화 시 플토2.0에서만 비활성화됩니다.</font>
            <br>플토Global의 접속권한을 비활성화하고 싶으신 경우
            <br>플토Global에서 비활성화로 변경해 주시기 바랍니다.
            <br>사용자 비활성화 시 해당 사용자는 솔루션에서 로그아웃 됩니다.
            <br>플토2.0에서 선택한 사용자를 비활성화 하시겠습니까?`];
        } else {
          showMsg = type === 'delete' ? ['선택한 사용자를 삭제 하시겠습니까?', ''] : ['선택한 사용자를 비활성화 하시겠습니까?', ''];
        }
        const showType = type === 'delete' ? '삭제' : '비활성화';

        const re = await commonSVC.showConfirmHtml(...showMsg);
        if (!re) { return false; }

        try {
          const params = {
            type: type === 'delete' ? 'delete' : 'deactivate',
            m_status,
            m_no
          };

          result = await userModel.changeStatus(params);

          userInfo.user.userAccountCnt = result.data.total;
          userInfo.user.superUserAccountCnt = result.data.superCnt;

          $rootScope.$applyAsync();

          commonSVC.showToaster('success', '', gettextCatalog.getString(`사용자 정보 ${showType}에 성공하였습니다.`));
          type === 'delete' && $scope.searchDo(false);

          // 사용자 초과로 사용불가시 삭제로 인해 정상화 될경우 브라우저 새로고침.
          if (userInfo.user.userAccount_exceeded && userInfo.user.userVersionCnt >= userInfo.user.userAccountCnt) {
            location.reload();
          }
        } catch (error) {
          if (error.data.error.indexOf('cs found') > -1) {
            commonSVC.showMessageHtml('', '<b>사용 이력이 확인되어 삭제가 불가능합니다.<br> 1:1문의를 통해 문의해주시기 바랍니다.</b>');
          }
          commonSVC.showToaster('error', '', gettextCatalog.getString(`사용자 정보 ${showType}에 실패하였습니다.`));

          return false;
        }
      }
    };

    /**
     * 인증메일 재발송
     */
    $scope.reSendMail = function (m_no, email) {
      commonSVC.showConfirm(gettextCatalog.getString('인증 메일을 재발송 하시겠습니까?'), '', function () {
        const params = {
          m_no: m_no,
          user_email: email
        };
        const url = `${settings.pa20ApiUrl}/app/users/add-send-mail`;

        commonSVC.sendUrl('POST', url, params, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('인증메일이 발송되었습니다.'));
          } else {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('인증메일 발송에 실패하였습니다.'));
          }
        });
      });
    };

    /**
     * 검색
     */
    $scope.searchDo = function(refresh, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 사용자 추가 모달 오픈
     */
    $scope.userAdd = async () => {
      const templatePath = 'views/configs/user_add.html';
      const resolve = {};

      resolve.data = {
        warehouserList: warehouseList.data.result
      };

      const modal = commonSVC.openModal('xg', resolve, 'ConfigsUserAddCtrl', templatePath);

      modal.result.then(function (result) {
        if (result == 'success') {
          $scope.searchDo(true);
        }
      });
    };

    // 2017-06-15 chris 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.configs_user') {
        const $wrapper = $('#configs_user_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

  });
