/**
 * progressBar
 */

'use strict';

angular.module('gmpApp')
  .directive('progressBar', function($rootScope) {
    return {
      restrict: 'E',
      scope: {
        listen: '@',
        percent: '=',
        isFinish: '=',
      },
      link: function(scope, element, attri) {
        $rootScope.$on(scope.listen, function(event, data) {
          if (!scope.isFinish && parseInt(scope.percent) < parseInt(data)) {
            scope.percent = data;

            if (attri.type === 'bar') {
              const bGradient = `linear-gradient(to right, #005bbe 0%, #005bbe ${data}, rgba(0, 91, 190, .6) ${data},rgba(0, 91, 190, .6) ${data},rgba(0, 91, 190, .6) 100%)`;

              $(element).css('background', bGradient);
            }
          } else if (scope.isFinish) {
            scope.percent = '100%';

            if (attri.type === 'bar') {
              element.css('background', '#005bbe');
            }
          }
        });
      }
    };
  });
