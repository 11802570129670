'use strict';

angular.module('gmpApp')
  .controller('EditProdInfoTemplateCtrl', function ($scope, $uibModalInstance, commonSVC, data, settings) {

    $scope.template = data.template;

    /**
     * 등록
     * **/
    $scope.ok = async () => {
      if ($scope.form1.$valid) {
        const templateData = $scope.template.template_data;

        const prodNotiInfoValueCheck = templateData.infoCode && templateData.infoDetail.some(({ detail }) =>
          detail.some(({ value }) => !value)
        );

        if (prodNotiInfoValueCheck) {
          commonSVC.showMessage('세부항목을 모두 입력해주세요.', '');

          return false;
        } else {

          try {
            await commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/online/prod-noti-template`, { template_no: $scope.template.template_no, template_data: $scope.template.template_data });

            commonSVC.showToaster('success', '성공', '새로운 상품정보제공고시 템플릿을 저장했습니다.');
          } catch (err) {
            commonSVC.showToaster('error', '실패', '새로운 상품정보제공고시 템플릿 저장 실패');
          }

          $uibModalInstance.close($scope.template);
        }
      }
    };

    /**
     * 취소
     * **/
    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };
  });
