'use strict';

angular.module('gmpApp')
  .controller('AltalkTemplateDetailCtrl', function(
    $scope, data, commonSVC, $timeout, $uibModalInstance, settings, $rootScope, atalkModel
  ) {
    function init () {
      $timeout(async () => {
        const statusObj = {
          APR: '승인',
          REJ: '반려',
          REQ: '심사중',
          TEMP: '임시저장',
          REG: '등록'
        };

        const inspectionContentObj = {
          APR: '승인이 완료되었습니다. 해당 템플릿으로 알림톡 발송이 가능합니다.',
          TEMP: '승인 요청 전 템플릿 입니다. 승인요청 신청이 가능합니다.',
          REQ: '승인요청일로 부터 평일 기준 2~3일 소요됩니다.',
          REG: '등록 상태입니다. 다시 한번 승인을 요청해 주세요.'
        };
        $scope.data = angular.copy(data);

        if (data.tmpsave_data) {
          $scope.data.template_emphasize_type = data.tmpsave_data.template_emphasize_type || 'NONE';

          if (data.tmpsave_data.template_emphasize_type === 'IMAGE') {
            $scope.data.template_img_name = data.tmpsave_data.template_img_name;
          }

          if (data.tmpsave_data.template_emphasize_type === 'TEXT') {
            $scope.data.template_title = data.tmpsave_data.template_title;
            $scope.data.template_sub_title = data.tmpsave_data.template_sub_title;
          }
        }

        // 상세 조회 시 이미지 업로드 처리(임시저장의 경우)
        if ($scope.data.temp_template_img_name && !['TEMP', 'REG'].includes($scope.data.inspection_status)) {
          imageUpload($scope.data.temp_template_img_name);
        } else {
          imageUpload($scope.data.template_img_name);
        }

        if ($scope.data.plusIdList.length) {
          $scope.data.plusIdList = $scope.data.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
          $scope.plusIdCheck = true;
          $scope.data.plus_friend_id = $scope.data.plusIdList[0].id;
        } else {
          $scope.plusIdCheck = false;
        }

        $scope.data.add_info_yn = $scope.data.template_extra && true;

        if ($scope.data.add_info_yn && ($scope.data.template_extra && $scope.data.template_extra !== 'null')) {
          document.getElementById('preview_add_box').innerHTML = $scope.data.template_extra?.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
        }

        $scope.template_type = $scope.data.template_type === 'BA' ? 'basic' : 'complex';
        $scope.data.status = statusObj[$scope.data.inspection_status];
        $scope.inspection_content = $scope.data.inspection_status === 'REJ' ? $scope.data.inspection_content : inspectionContentObj[$scope.data.inspection_status];
        $scope.data.template_emphasize_type = $scope.data?.template_emphasize_type ?? 'NONE';   // 상단 강조영역 초기화
        document.getElementById('preview_box').innerHTML = $scope.data.content.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
        if (data.buttons) {
          $scope.data.btnGroup = JSON.parse(`[${data.buttons}]`);
          $scope.data.use_add_channel_yn = $scope.data.btnGroup.some(btn => btn.type === 'AC');
        } else {
          $scope.data.btnGroup = [];
        }
        if ($scope.data.template_img_name && $scope.data.template_emphasize_type === 'IMAGE') {
          const preview = document.getElementById('emphasis_img');
          const currentStyle = preview.getAttribute('style') || '';
          preview.setAttribute('style', `${currentStyle} background-image: url('${data.template_img_name || data.tmpsave_data.template_img_name }'); background-size: 100%;`);
        }

        $scope.canRemake = ['APR', 'REQ'].includes($scope.data.inspection_status) || $scope.data.type === 'G'; // 다시 만들기, 임시저장 구분 변수(승인, 반려) 상태 및 그룹 텝플릿

        // 임시저장, 승인요청, 취소 상태 시 모달 내에서 바로 수정 가능
        $scope.isRemakeState = !$scope.canRemake;
        $scope.disabledCheck = false;  // 임시저장 상태와 같이 상세페이지에서 바로 수정 가능한 경우에는 승인 버튼 활성화
        $scope.variableList = ((await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/variableList`)).data.result).filter(variable => variable.type === '주문');
      });
    }

    init();

    /**
     * 템플릿 다시 만들기
     */
    $scope.reMakeTemplate = () => {
      $scope.isRemakeState = true;
      $scope.disabledCheck = true;
      $scope.data.name = '';

      imageUpload($scope.data.temp_template_img_name);
    };

    function imageUpload(img_url) {
      // 기존 이미지 존재하는 템플릿 상세 > 다시 만들기 시 기존에 존재하는 이미지 파일을 할당하는 처리 필요
      if (img_url) {
        fetch(img_url, { mode: 'cors' })
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

            // 이미지 파일 할당
            $scope.file = file;

            assignFileToInput('altalk_emphasis_img', file);
          })
          .catch(() => {
            return commonSVC.showToaster('error', '실패', '이미지 초기화에 실패하였습니다.'); });
      }
    }

    // 파일 input에 파일을 할당하는 함수
    function assignFileToInput(inputId, file) {
      // input element 가져오기
      const inputElement = document.getElementById(inputId);

      // DataTransfer 객체 생성
      const dataTransfer = new DataTransfer();

      // 파일 추가
      dataTransfer.items.add(file);

      // DataTransfer를 input element에 할당
      inputElement.files = dataTransfer.files;
    }

    /**
     * 필수항목 체크
     */
    $scope.checkInputs = () => {
      if ($scope.data?.name && $scope.data?.plus_friend_id && $scope.data?.content) {
        $scope.disabledCheck = false;
      } else {
        $scope.disabledCheck = true;
      }
    };

    /**
     * 부가 정보 여부 변경 시 동작 함수
     */
    $scope.addInfoChange = () => {
      if (!$scope.data.add_info_yn) {
        $scope.data.template_extra = '';
        document.getElementById('preview_add_box').innerHTML = '';
      } else {
        document.getElementById('preview_add_box').innerHTML = '<p>문의전화: 02-1234-4567</p>';
      }
    };

    $scope.setBtnType = (idx, v) => {
      $scope.data.btnGroup[idx].type = v;
      if (v === 'DS') {
        $scope.data.btnGroup[idx].name = '배송 조회하기';
      } else {
        $scope.data.btnGroup[idx].name = '';
      }
    };

    /**
     * 버튼 삭제
     */
    $scope.deleteBtn = (idx) => {
      if ($scope.data.btnGroup[idx].type === 'AC') {
        $scope.data.use_add_channel_yn = false;
      }
      $scope.data.btnGroup.splice(idx, 1);
    };

    /**
     * 미리보기 적용
     */
    $scope.textPreview = () => {
      if ($scope.data?.name && $scope.data?.plus_friend_id && $scope.data?.content) {
        $scope.disabledCheck = false;
      } else {
        $scope.disabledCheck = true;
      }
      document.getElementById('preview_box').innerHTML = $('#template-content-box').val().split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
    };

    /**
     * 부가 정보 미리보기
     */
    $scope.textPreview_add_info = () => {
      document.getElementById('preview_add_box').innerHTML = $('#template-add-content-box').val() ? $('#template-add-content-box').val().split('\n').map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('') : '<p>문의전화: 02-1234-4567</p>';
    };

    /**
     * 강조 유형 설정
     */
    $scope.setEmphasisType = (type) => {
      if (type === 'TEXT') {
        document.getElementById('emphasis_phrase').innerHTML = '보조문구';
        document.getElementById('emphasis_title').innerHTML = '강조제목';
      }
    };

    /**
     * 강조 제목 미리보기
     */
    $scope.previewEmphasisTitle = () => {
      document.getElementById('emphasis_title').innerHTML = $('#emphasis_title_box').val();
    };

    /**
     * 보조 문구 미리보기
     */
    $scope.previewEmphasisPhrase = () => {
      document.getElementById('emphasis_phrase').innerHTML = $('#emphasis_phrase_box').val();
    };

    /**
     * 강조 이미지 업로드
     */
    $scope.previewEmphasisImg = (row) => {
      $scope.file = row;
      const preview = document.getElementById('emphasis_img');
      const imageSrc = URL.createObjectURL(row);
      const currentStyle = preview.getAttribute('style') || '';
      preview.setAttribute('style', `${currentStyle} background-image: url('${imageSrc}'); background-size: 100%;`);
      $timeout(() => {});
    };

    /**
     * 채널 추가 사용
     */
    $scope.useChannelAdd = () => {
      if (!$scope.data.use_add_channel_yn) {
        $scope.deleteBtn(0);
      } else {
        $scope.data.btnGroup.unshift({ name: '채널 추가', type: 'AC', url_mobile: '', url_pc: '' });
      }
    };

    /**
     * 변수 적용
     */
    $scope.insertVar = (varName) => {
      const elm = $('#template-content-box');
      const idx = elm[0].selectionStart;

      elm[0].value = elm[0].value.slice(0, idx) + `#{${varName}}` + elm[0].value.slice(idx);
      elm.focus()[0].setSelectionRange(idx + varName.length + 3, idx + varName.length + 3);
      elm.trigger('change');

      $scope.textPreview();
    };

    /**
     * 알림톡 배송조회 지원 택배사 알럿으로 노출
     */
    $scope.alertSupportCarrList = () => {
      commonSVC.showMessageHtml('지원 택배사',
        `로젠택배, 우체국택배, KGB택배, 일양로지스, 한진택배<br/>
           천일택배, 롯데택배, 농협택배, 경동택배, 합동택배<br/>
           GTX로지스, Fedex, 호남택배, 대신택배, 건영택배<br/>
           CU 편의점택배, CVSnet편의점택배, TNT Express, USPS<br/>
           EMS, DHL, 대신택배, 한덱스`);
    };

    /**
     * 버튼 추가
     */
    $scope.addBtn = () => {
      if ($scope.data.btnGroup.length === 5) {
        commonSVC.showMessage('버튼의 수는 최대 5개입니다.');

        return false;
      }
      $scope.data.btnGroup.push({ name: '', type: '', url_mobile: '', url_pc: '' });
    };

    /**
     * 템플릿 형태 변경 시 작성한 추가 정보 초기화 함수
     */
    $scope.clearAddInfo = () => {
      // 버튼 정보 초기화
      $scope.btnGroup = [];
      $scope.data.template_sub_title = '';
      $scope.data.template_title = '';
      $scope.data.template_emphasize_type = 'NONE';
      $scope.data.use_add_channel_yn = false;
      $scope.data.template_extra = '';
      $scope.data.add_info_yn = '';
    };

    function handleResult(result, status) {
      if (result.data.result === 'success') {
        const message = status === 'TEMP' ? '임시저장 성공' : '승인 요청 완료';
        const additionalMessage = status !== 'TEMP' ? '템플릿 승인은 업무일 기준 2~3일 소요됩니다.' : '';
        commonSVC.showMessage(message, additionalMessage);
        $uibModalInstance.close('success');
      } else if (result.data?.error) {
        handleError(result.data.error);
      }
    }

    function handleError(error, status) {
      if (error.includes('이미 등록된 템플릿명입니다')) {
        commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '동일한 템플릿 명이 있습니다.');
      } else if (error.includes('Duplicate Friend Error')) {
        commonSVC.showMessage('실패', '플러스친구 ID에 등록된 동일 템플릿 명이 존재합니다. 다른 템플릿 명을 입력해 주세요.');
      } else if (error === '글자수가 1,000자를 초과합니다.') {
        return commonSVC.showMessage('승인 요청 실패', '템플릿 내용, 부가정보 내용의 총 글자수 합이 1,000자 초과하였습니다.');
      } else {
        commonSVC.showMessage('실패', error);
      }
    }

    $scope.onSubmit = async (status) => {
      let reqCheck = true;     // 부가정보, 버튼, 강조 유형 데이터 유효성 체크 변수
      let template_message_type;
      // MI(복합형) - 부가정보(필수), 채널추가
      if (($scope.data.add_info_yn && $scope.data.template_extra)
        && ($scope.data.use_add_channel_yn && $scope.data.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'MI';
      // EX(부가정보형) - 부가 정보 필수
      } else if (($scope.data.add_info_yn && $scope.data.template_extra)
        && !($scope.data.use_add_channel_yn && $scope.data.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'EX';
      // AD(채널추가형) - 채널추가 버튼 필수
      } else if (!($scope.data.add_info_yn && $scope.data.template_extra)
        && ($scope.data.use_add_channel_yn && $scope.data.btnGroup.some(btn => btn.type === 'AC'))) {
        template_message_type = 'AD';
      // BA(기본형) - 부가정보, 채널추가 없는 템플릿
      } else {
        template_message_type = 'BA';
      }

      const data = {
        type: $scope.data.type,
        name: $scope.data.name,
        plus_friend_id: $scope.data.plus_friend_id || '1',
        content: $scope.data.content,
        buttons: $scope.data.btnGroup ? $scope.data.btnGroup : [],
        user_name: $rootScope.user_profile.user_name,
        status: $scope.data.inspection_status === 'REJ' ? 'REJ' : (status || $scope.data.inspection_status),
        sol_no: $rootScope.user_profile.sol_no,
        template_type: template_message_type,
        template_emphasize_type: $scope.data.template_emphasize_type,
        template_extra: $scope.data.template_extra,
        template_sub_title: $scope.data.template_sub_title,
        template_title: $scope.data.template_title,
        template_img: $scope.file,
        ...((['TEMP', 'REG', 'REJ'].includes($scope.data.inspection_status)) && { template_no: $scope.data.id })
      };

      // 상단 영역 강조 유형이 선택안함인 경우 강조 유형 데이터 없으면 유효성 에러
      if ($scope.data.template_emphasize_type === 'TEXT') {
        if (!($scope.data.template_title && $scope.data.template_sub_title)) {
          reqCheck = false;
        }
      }
      if ($scope.data.template_emphasize_type === 'IMAGE') {
        if (!$scope.file) {
          reqCheck = false;
        }
      }
      if ($scope.template_type === 'complex' && ($scope.data.add_info_yn && !$scope.data.template_extra)) {
        reqCheck = false;
      }

      if ($scope.data.btnGroup.length) {
        for (const btn of $scope.data.btnGroup) {
          if (!['AC', 'DS'].includes(btn.type) && (!btn.url_mobile && !btn.url_pc && !btn.name)) {
            reqCheck = false;

            break;
          }
        }
      }

      if (!reqCheck) {
        return commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '버튼명, 이미지 첨부, 강조형 문구 선택 시 해당 항목 입력은 필수값입니다.');
      }

      try {
        let result;
        const isComplexTemplate = $scope.template_type === 'complex';
        const hasValidAddInfo = isComplexTemplate && (($scope.data.add_info_yn && $scope.data.template_extra) || ($scope.data.use_add_channel_yn && $scope.data.btnGroup.some(btn => btn.type === 'AC')));

        if ($scope.file) {
          data.template_img = $scope.file;
        }

        if ($scope.template_type === 'complex' && (!($scope.data.add_info_yn && $scope.data.template_extra) && !($scope.data.use_add_channel_yn && $scope.data.btnGroup.some(btn => btn.type === 'AC')))) {
          if (isComplexTemplate && !hasValidAddInfo) {
            commonSVC.showConfirm('부가정보, 채널 추가 두 항목 미 입력 시 기본형 형태로 템플릿이 저장됩니다.', '', async (isConfirm) => {
              if (!isConfirm) { return; }
              result = await atalkModel.templateAdd(data, $scope.file);

              return handleResult(result, status);
            });
          } else {
            result = await atalkModel.templateAdd(data, $scope.file);

            return handleResult(result, status);
          }
        } else {
          if ($scope.file) {
            data.template_img = $scope.file;
            result = await atalkModel.templateAdd(data, $scope.file);
          } else {
            result = await atalkModel.templateAdd(data, $scope.file);
          }

          if (result.data.result === 'success') {
            if (status === 'TEMP') {
              commonSVC.showMessage('임시저장 성공');
            } else {
              commonSVC.showMessage('승인 요청 완료', '템플릿 승인은 업무일 기준 2~3일 소요됩니다.');
            }

            return $uibModalInstance.close('success');
          } else if (result.data?.error) {
            throw new Error(result.data?.error);
          }
        }
      } catch (err) {
        if (err.data?.error.includes('이미 등록된 템플릿명입니다')) {
          return commonSVC.showMessage(`${status === 'TEMP' ? '임시저장' : '승인 요청'} 실패`, '동일한 템플릿 명이 있습니다.');
        } else if (err.data?.error.includes('Duplicate Friend Error')) {
          return commonSVC.showMessage('실패', '플러스친구 ID에 등록된 동일 템플릿 명이 존재합니다. 다른 템플릿 명을 입력해 주세요.');
        } else if (err.data?.error === '글자수가 1,000자를 초과합니다.') {
          return commonSVC.showMessage('승인 요청 실패', '템플릿 내용, 부가정보 내용의 총 글자수 합이 1,000자 초과하였습니다.');
        }

        return commonSVC.showMessage('실패', err.data?.error);
      }
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };
  });