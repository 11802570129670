/**
 * 2019-03-13 Daniel
 * 임의분류 관리
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineCategoryCtrl', function ($scope, $uibModalInstance, $timeout, commonSVC, onlineProductModel, olCateList, data) {
    // 선택 데이터
    const masterProds = data.masterProds;

    // 선택 데이터 갯수
    $scope.selectedLength = masterProds.length;

    // 모드 분기
    // 선택 마스터상품이 있을시 setting(설정) 모드, 없을시 manage(관리) 모드
    $scope.mode = masterProds.length ? 'setting' : 'manage';

    // 분류(나중에 혹시 더 늘어날까봐 일단 추가함)
    $scope.categoryNames = ['대분류', '중분류', '소분류', '세분류'];
    // 분류 최대 깊이(나중에 혹시 더 늘어날까봐 일단 추가함)
    const maxDepth = $scope.categoryNames.length;
    // DB조회한 임의분류 부모 임의분류 번호로 그룹핑 (*대분류는 부모번호가 null이다)
    const olCateListGroup = _.groupBy(olCateList.data, 'pa_ol_cate_no');

    // 검색명
    $scope.olCateSearchValue;
    // 검색리스트
    $scope.olCateSearchList;
    // 데이터 리스트
    $scope.olCateLists;
    // 선택데이터
    $scope.olCateDatas;
    // 분류추가 데이터
    $scope.olCateAddDatas;
    // 로딩여부 데이터
    $scope.olCateLoadings;

    // 선택 이벤트
    $scope.olCateSelect = function($event, depth, olCate) {
      // 내부 함수로 들어온경우 제외
      // 선택가능영역 제외 클릭시 이벤트 발생안시킴
      if ($event && $event.target.id !== 'selectArea') {
        return;
      }

      const nextDepth = depth + 1;

      // 선택값 할당
      $scope.olCateDatas[depth] = olCate;

      // 마지막 뎁스 선택시 리스트 초기화 및 할당 작업 안함
      if (nextDepth === maxDepth) {
        return;
      }

      // 선택한 뎁스 하위의 리스트와 선택값 초기화
      dataInit(nextDepth);

      // 다음 뎁스 데이터 찾아서 리스트 할당
      // 데이터 없으면 생성후 할당
      // 데이터 유지를 위함
      if (!_.has(olCateListGroup, olCate.ol_cate_no)) {
        olCateListGroup[olCate.ol_cate_no] = [];
      }

      $scope.olCateLists[nextDepth] = olCateListGroup[olCate.ol_cate_no];
    };

    // 정렬 옵션(ui-sortable module option)
    $scope.olCateSortableOpt = {
      handle: '> div > .actions > .sort-handle',
      // 정렬 업데이트 이벤트
      stop: function(e) {
        // 정렬 변경한 분류뎁스
        const changeDepth = e.target.parentElement.parentElement.dataset.depth;
        const olCateSortableList = $scope.olCateLists[changeDepth];

        // 정렬 변경 여부
        let sortChangeYn = false;

        // 정렬번호 재할당
        _.each(olCateSortableList, function(olCate, index) {
          if (olCate.order_seq !== index) {
            sortChangeYn = true;
          }

          olCate.order_seq = index;
        });

        // 변경된게 없으면 업데이트 안함
        if (!sortChangeYn) {
          return;
        }

        const editParam = {
          olCateList: olCateSortableList
        };

        $scope.olCateLoadings[changeDepth] = true;

        // 분류명 정렬 DB수정 액션
        onlineProductModel.olCateSort(editParam)
          .then(function (res) {
            if (res.data !== 'success') {
              throw '';
            }

            commonSVC.showToaster('success', '성공', '분류 정렬 수정에 성공했습니다.');
          })
          .catch(function () {
            commonSVC.showToaster('error', '실패', '분류 정렬 수정에 실패했습니다.');
          })
          .finally(function () {
            $scope.olCateLoadings[changeDepth] = false;
          });
      }
    };

    // 추가 핸들러
    $scope.olCateAddHandler = function(depth, openYn) {
      $scope.olCateAddDatas[depth].name = '';
      $scope.olCateAddDatas[depth].open = openYn;
    };

    // 추가 이벤트
    $scope.olCateAdd = function (depth) {
      const olCateList = $scope.olCateLists[depth];
      const olCatePrevData = $scope.olCateDatas[(depth - 1)];
      const addData = $scope.olCateAddDatas[depth];

      // 분류명 검사
      let failMsg = '';
      const nameByte = addData.name.byte2();

      // 분류명 미입력
      if (!nameByte) {
        failMsg = '분류명을 입력해주세요';
      }
      // 20Btye 초과
      else if (nameByte > 20) {
        failMsg = '분류명은 최대 20Byte까지 입력할수 있습니다';
      }
      // 분류에서 중복검사
      else {
        if (_.filter(olCateList, function(o) { return o.ol_cate_name === addData.name; }).length) {
          failMsg = '사용할 수 없는 분류명입니다.\n동일 분류에서 입력한 분류명이 이미 존재합니다.';
        }
      }

      if (failMsg) {
        commonSVC.showMessage('추가 실패', failMsg);

        return;
      }

      const addParam = {
        pa: olCatePrevData,
        depth: depth,
        name: addData.name,
        seq: olCateList.length
      };

      // 로딩시작
      $scope.olCateLoadings[depth] = true;

      // 분류 DB추가 액션
      onlineProductModel.olCateAdd(addParam)
        .then(function(res) {
          // 추가 인풋 제거
          $scope.olCateAddHandler(depth, false);

          // 데이터 다시불러오지않고 받을데이터로 바로 반영
          olCateList.push(res.data);
          // 첫분류 아닐경우에만 생성
          if (olCatePrevData) {
            // 하위분류 표시 생성
            olCatePrevData.childExistYn = true;
          }

          // 검색 반영
          searchInit();

          commonSVC.showToaster('success', '성공', '분류 추가에 성공했습니다.');
        })
        .catch(function() {
          commonSVC.showToaster('error', '실패', '분류 추가에 실패했습니다.');
        })
        .finally(function() {
          $scope.olCateLoadings[depth] = false;
        });
    };

    // 분류 수정여부 핸들러
    $scope.olCateEditYnHandler = function(olCate, editYn) {
      // 수정오픈시 분류명 재할당
      if (editYn) {
        olCate.editName = olCate.ol_cate_name;
      }

      olCate.editYn = editYn;
    };

    // 분류명 수정
    $scope.olCateNameEdit = function(olCate, depth) {
      let failMsg = '';

      if (!olCate.editName) {
        failMsg = '분류명을 입력해주세요';
      } else if (olCate.editName === olCate.ol_cate_name) {
        failMsg = '변경사항이 없습니다.';
      }

      if (failMsg) {
        commonSVC.showMessage('수정 실패', failMsg);

        return;
      }

      $scope.olCateLoadings[depth] = true;

      // 분류명 DB수정 액션
      onlineProductModel.olCateNameEdit(olCate)
        .then(function (res) {
          if (res.data !== 'success') {
            throw '';
          }

          // 입력분류명 데이터 분류명에 할당
          olCate.ol_cate_name = olCate.editName;
          olCate.editYn = false;

          // 검색 반영
          searchInit();

          commonSVC.showToaster('success', '성공', '분류 수정에 성공했습니다.');
        })
        .catch(function () {
          commonSVC.showToaster('error', '실패', '분류 수정에 실패했습니다.');
        })
        .finally(function () {
          $scope.olCateLoadings[depth] = false;
        });
    };

    // 분류 삭제
    $scope.olCateDelete = function(olCate, depth) {
      commonSVC.showConfirm('주의', '해당 분류에 포함된 상품들은 모두 분류 미지정으로 변경됩니다.\n분류를 삭제하시겠습니까?')
        .then(function(confirm) {
          if (!confirm) {
            return;
          }

          const removeParam = {
            ol_cate_no: olCate.ol_cate_no
          };

          $scope.olCateLoadings[depth] = true;

          onlineProductModel.olCateDelete(removeParam)
            .then(function(res) {
              if (res.data !== 'success') {
                throw '';
              }

              // 삭제한게 지금 선택된 분류라면 데이터 초기화
              if ($scope.olCateDatas[depth] === olCate) {
                $scope.olCateDatas[depth] = null;
                dataInit((depth + 1));
              }

              // 삭제분류 반영
              _.remove($scope.olCateLists[depth], { ol_cate_no: olCate.ol_cate_no });

              // 삭제한게 대분류가 아니고
              // 하위분류가 다사라졌다면 하위분류 존재여부 제거
              if (depth && !$scope.olCateLists[depth].length) {
                $scope.olCateDatas[(depth - 1)].childExistYn = false;
              }

              // 검색 반영
              searchInit();

              commonSVC.showToaster('success', '성공', '분류 삭제에 성공했습니다.');
            })
            .catch(function() {
              commonSVC.showToaster('error', '실패', '분류 삭제에 실패했습니다.');
            })
            .finally(function () {
              $scope.olCateLoadings[depth] = false;
            });
        });
    };

    // 선택 검색
    $scope.olCateSearchSelect = function() {
      // 초기값 선택시
      if (!$scope.olCateSearchValue) {
        return;
      }

      const olCateNos = $scope.olCateSearchValue.split('_');
      let olCateListNo = 'null';

      // _로 결합된 olCateNo들로 각 뎁스의 olCate를 찾아서 선택시킴
      _.forEach(olCateNos, function(olCateNo, depth) {
        const olCateList = olCateListGroup[olCateListNo];
        const olCate = _.find(olCateList, { ol_cate_no: parseInt(olCateNo) });

        $scope.olCateSelect(null, depth, olCate);

        // 다음 뎁스의 키값을 미리 할당해야함
        olCateListNo = olCateNo;
      });
    };

    // 선택 검색 세팅
    function setOlCateSearchList(list, value, name) {
      // 다음 뎁스 데이터 없으면 종료
      if (!list || !list.length) {
        return;
      }

      // 리스트 분류 뎁스별로 전부 추출
      _.forEach(list, function (olCate) {
        let v = olCate.ol_cate_no,
            n = olCate.ol_cate_name;

        // 첫번째일때도 앞에 붙어서 첫번쨰는 패스시킴
        if (value && name) {
          v = `${value}_${v}`;
          n = `${name} > ${n}`;
        }

        $scope.olCateSearchList.push({ value: v, name: n });

        // 재귀로 한번에 다돌림
        setOlCateSearchList(olCateListGroup[olCate.ol_cate_no], v, n);
      });
    }

    // 검색 초기화
    function searchInit() {
      // 리스트 데이터만 초기화
      $scope.olCateSearchList = [];

      setOlCateSearchList(olCateListGroup.null, '', '');
    }

    // 시작뎁스부터 데이터 초기화
    function dataInit(startDepth) {
      for (let i = startDepth; i < maxDepth; i++) {
        $scope.olCateLists[i] = [];
        $scope.olCateDatas[i] = null;
        $scope.olCateAddDatas[i] = { name: '', open: false };
        $scope.olCateLoadings[i] = false;
      }
    }

    // 초기화
    function init() {
      // 검색 초기화
      $scope.olCateSearchValue = '';

      // 리스트, 선택값, 등록여부 초기화
      $scope.olCateLists = [];
      $scope.olCateDatas = [];
      $scope.olCateAddDatas = [];
      $scope.olCateLoadings = [];

      // 선택 분류명 초기화
      $scope.selectedOlCate = null;

      dataInit(0);
      searchInit();

      _.forEach(olCateListGroup, function (olCateList) {
        _.forEach(olCateList, function(olCate) {
          // 하위 분류 존재시 '>' 출력 위해서 처리
          olCate.childExistYn = !_.isUndefined(olCateListGroup[olCate.ol_cate_no]);
          // 수정 실행 여부
          olCate.editYn = false;
        });
      });

      // 대분류 먼저 세팅
      $scope.olCateLists[0] = olCateListGroup.null || [];
    }

    // 적용
    $scope.apply = function() {
      // 제일 마지막에 선택한 분류로 적용
      const lastSelectOlCate = _($scope.olCateDatas).compact().last();

      if (!lastSelectOlCate) {
        commonSVC.showMessage('적용 실패', '분류를 선택해주세요.');

        return;
      }

      const params = {
        saleNos: _.map(masterProds, 'sale_no'),
        olCateNo: lastSelectOlCate.ol_cate_no
      };

      onlineProductModel.olCateApply(params)
        .then(function(res) {
          if (res.data !== 'success') {
            throw '';
          }

          commonSVC.showToaster('success', '성공', '분류 적용에 성공했습니다.');
          $uibModalInstance.close();
        })
        .catch(function () {
          commonSVC.showToaster('error', '실패', '분류 적용에 실패했습니다.');
        });
    };

    // 닫기
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };

    // JQUERY SELECT2 적용
    $timeout(function () {
      $('#ol-cate-select-search').select2({
        dropdownAutoWidth: true
      });
    });

    init();
  });
