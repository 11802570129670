'use strict';

angular.module('gmpApp')
  .controller('AltalkSendGroupExcelUploadCtrl', function($scope, data, $uibModalInstance, commonSVC, $timeout, workSVC) {
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
		 * 엑셀 업로드
		 * */
    $scope.upload = function () {
      $scope.waitBtnDisableFlag = true;
      if (!Array.isArray($scope.fileData)) {
        const params = {
          mode: 'add',
          custgroup_no: data.custgroup_no,
          field: {
            name: '',
            phone: '',
            memo: ''
          }
        };
        workSVC.addExcelWork('UploadAtalkMemberExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    resetInput();
  });