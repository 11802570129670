'use strict';

angular.module('gmpApp')
  .controller('A112_MaterialAddOrEdit', function ($scope, data, $uibModalInstance) {
    function validation() {
      return $scope.materials.every((prod) => {
        if (!prod.name) {
          alert('상품명은 필수 값 입니다');

          return false;
        }

        return prod.materials.every((material) => {
          if (!material.name) {
            alert('원료명은 필수 값 입니다');

            return false;
          }
          if (!material.origin) {
            alert('원산지는 필수 값 입니다');

            return false;
          }

          return true;
        });
      });
    }

    $scope.maxProdCount = 10;
    $scope.maxMaterialCount = 5;

    $scope.materials = data.material || [];

    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = () => {
      const validationCheck = validation();

      if (validationCheck === true) {
        $uibModalInstance.close($scope.materials);
      }
    };
  });