'use strict';

/**
 * 2017-05-15 chris
 */
angular.module('gmpApp')
  .filter('testFilters', function(gettextCatalog) {
    return function(status) {

      let statusText = status || '';

      // 동기화후 작업관리에서 메세지에 로그인/로그아웃 이 한국어로 나오기 시작했습니다. 또 "동기화중"에 "중"만 번역이 안되어있네요

      statusText = statusText.replace('로그인 중', gettextCatalog.getString('로그인 중'));
      statusText = statusText.replace('주문 동기화중', gettextCatalog.getString('주문 동기화중'));
      statusText = statusText.replace('로그인', gettextCatalog.getString('로그인'));
      statusText = statusText.replace('로그아웃', gettextCatalog.getString('로그아웃'));

      statusText = statusText.replace('주문 수집중', gettextCatalog.getString('주문 수집중'));
      statusText = statusText.replace('주문 동기화', gettextCatalog.getString('주문 동기화'));
      statusText = statusText.replace('송장 전송중', gettextCatalog.getString('송장 전송중'));
      statusText = statusText.replace('작업결과 저장중', gettextCatalog.getString('작업결과 저장중'));

      statusText = statusText.replace('건 완료', gettextCatalog.getString('건 완료'));
      statusText = statusText.replace('건 실패', gettextCatalog.getString('건 실패'));
      statusText = statusText.replace('건 중복', gettextCatalog.getString('건 중복'));
      statusText = statusText.replace('건 처리완료', gettextCatalog.getString('건 처리완료'));
      statusText = statusText.replace('작업결과 저장 실패', gettextCatalog.getString('작업결과 저장 실패'));
      statusText = statusText.replace('[실패]', gettextCatalog.getString('[실패]'));
      statusText = statusText.replace('[성공]', gettextCatalog.getString('[성공]'));

      // statusText = statusText.replace("주문 수집중","注文収集中");
      // statusText = statusText.replace("주문 동기화","注文の同期中");
      // statusText = statusText.replace("송장 전송중","送り状の転送中");
      // statusText = statusText.replace("작업결과 저장중","作業結果の保存中");
      //
      // statusText = statusText.replace("건 완료","件完了");
      // statusText = statusText.replace("건 실패","件失敗");
      // statusText = statusText.replace("건 처리완료","件の処理完了");
      // statusText = statusText.replace("작업결과 저장 실패","作業結果の保存失敗");
      // statusText = statusText.replace("[실패]","[失敗]");
      // statusText = statusText.replace("[성공]","[完了]");

      return statusText;
    };
  });
