'use strict';

angular.module('gmpApp')
  .controller('shopCautionCtrl', function ($scope, $rootScope, $timeout, $compile, $uibModalInstance, $q, settings, commonSVC, data, $state, $sce, helpSVC, helpModel, cautionSVC, cautionModel) {
    $scope.fold = false;
    $scope.cautionList = [];
    $scope.selectedShopIdx = '';
    $scope.selectedTabIdx = 0;

    $rootScope.shopModalOpen = true;

    // 모달 축소
    $scope.folding = function() {
      if ($scope.fold) {
        $('#modal-shop').parent().parent().parent().css({ width: $scope.save_modal_size.width, minWidth: '450px', height: $scope.save_modal_size.height, minHeight: '450px', resize: 'both' });
      } else {
        $scope.save_modal_size = {
          width: `${$('#modal-shop').parent().parent().parent().css('width')}`,
          height: `${$('#modal-shop').parent().parent().parent().css('height')}`,
        };
        $('#modal-shop').parent().parent().parent().css({ width: '300px', minWidth: '300px', height: '45px', minHeight: '45px', resize: 'none' });
      }

      $scope.fold = !$scope.fold;
    };

    /*
    * 쇼핑몰 체크
    */
    function checkShop(shop_cd) {
      const selectedShopIdx = String($scope.cautionList.findIndex(element => element.shop_cd === shop_cd));
      if (selectedShopIdx === '-1') {
        $scope.selectedShopIdx = '';
      }
      else {
        $scope.selectedShopIdx = selectedShopIdx;
        $scope.selectedTabIdx = 0;
      }
    }

    // 원격으로 모달 열기
    $rootScope.$on('shopFoldOpen', (event, shop_cd) => {
      checkShop(shop_cd);
      if ($scope.fold) {
        $scope.folding();
      }
    });

    // 모달이 켜지면 Z-INDEX를 상위로 불러옴
    if ($rootScope.questionModalOpen) {
      $timeout(() => {
        $rootScope.draggableModalZindex('shop');
      });
    }

    $uibModalInstance.closed.then(() => $rootScope.shopModalOpen = false);

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $rootScope.shopModalOpen = false;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.searchData = angular.copy(helpSVC.searchData);

    /**
     * 도움말 검색
     */
    $scope.searchHelp = function ($event) {
      if (!$event || $event.keyCode === 13) {
        const word = $scope.searchData.searchWord;

        if (!word || word === '') {
          $scope.cancelSearch();

          return false;
        }

        if (word.length < 2) {
          return false;
        }

        $scope.selectedShopIdx = '';
        $('select[name="shopSelect"]').val('');

        shopInit(word);
      }
    };

    /**
     * 검색 취소
     */
    $scope.cancelSearch = function () {
      $scope.searchData.searchWord = '';
      $scope.searchData.searchYn = false;

      $scope.selectedShopIdx = '';
      $('select[name="shopSelect"]').val('');

      shopInit();
    };

    /**
     * 검색 문구 치환
     */
    $scope.replaceWord = function (word) {
      const w = $scope.searchData.searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '$&');
      const regex = new RegExp(w, 'gi');

      return word.replace(regex, `<span class='text-blue-400'>${w}</span>`);
    };

    /**
   * 검색 문구 하이라이트
   */
    $scope.replaceWordToHighlight = function (word) {
      if (word && typeof word === 'string') {
        if ($scope.searchData.searchWord) {
          const w = $scope.searchData.searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '$&');
          const regex = new RegExp(w, 'gi');

          return $sce.trustAsHtml(word.replace(regex, `<span style="background-color:yellow">${w}</span>`));
        } else {
          return $scope.trustHTML(word);
        }
      }
    };

    $scope.trustHTML = (p0) => $sce.trustAsHtml(p0);

    /**
     * 주의사항 탭 변경
     */
    $scope.changeTab = index => {
      $scope.selectedTabIdx = index;
    };

    /**
     * 쇼핑몰 리스트 선택
     */
    $scope.changeShop = () => {
      $scope.selectedTabIdx = 0;
      $scope.replaceWordToHighlight($scope.cautionList[$scope.selectedShopIdx]?.group_content[$scope.selectedTabIdx]);
    };

    /**
     * 검색결과 쇼핑몰 선택
     */
    $scope.selectSearchShop = selectedShopCd => {
      $scope.selectedShopIdx = $scope.cautionList.findIndex(({ shop_cd }) => shop_cd === selectedShopCd);
      $scope.changeShop();

      $timeout(() => {
        $('select[name="shopSelect"]').val($scope.selectedShopIdx);
      });
    };

    function splitArray(array) {
      const result = [];

      const pageLength = $scope.searchData.pageLength;

      const allCount = array.length;
      const pageCount = Math.ceil(allCount / pageLength);

      for (let i = 0; i < pageCount; i++) {
        result[i] = array.splice(0, pageLength);
      }

      return result;
    }

    function shopInit(keyword) {
      cautionModel.getCaution(keyword)
        .then(function (res) {
          const cautionList = _.groupBy(res.data.results, 'shop_cd');

          $scope.cautionList = Object.keys(cautionList).map(shopCd => {
            const shop = cautionList[shopCd];
            const caution = _.maxBy(shop, 'wdate');

            return ({
              shop_cd: caution.shop_cd,
              shop_name: caution.shop_name,
              wdate_max: caution.wdate,
              group_cd_name: shop.map(({ cd_name }) => cd_name),
              group_content: shop.map(({ content }) => content),
            });
          });

          $scope.cautionListView = splitArray(angular.copy($scope.cautionList));

          $('#scroll-body').scrollTop(0);

          if (keyword) {
            $scope.searchData.searchYn = true;
            $scope.searchData.page = 0;
          }
          checkShop(data.shop_cd);
        });
    }
    shopInit();
  });
