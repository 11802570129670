'use strict';

angular.module('gmpApp')
  .controller('OrderCustomerInquiryCollectCtrl', function ($scope, data, $uibModalInstance, commonSVC, workSVC, errorSVC, $q, $log, $timeout) {

    //------------------------
    // 변수 설정
    //------------------------
    $scope.selectedAll = true; // 전체체크
    $scope.channel_list = data; // 채널 리스트

    $scope.fields = [
      {
        key: 'ScrapCS',
        value: '고객문의',
        selected: true
      },
      {
        key: 'ScrapEmergencyCS',
        value: '긴급메세지',
        selected: true
      },
      {
        key: 'ScrapReview',
        value: '상품평',
        selected: true
      }
    ];

    // 마지막 선택값 기억.2019-04-08 rony
    $scope.saveMalls = JSON.parse(localStorage.getItem('scrapCsMalls'));
    if (!$scope.saveMalls) {
      $scope.saveMalls = {
        use_yn: false,
        fields: $scope.fields,
        malls: []
      };
    } else if ($scope.saveMalls.use_yn) {
      $scope.fields = $scope.saveMalls.fields;
    } else {
      $scope.saveMalls.fields = $scope.fields;
    }

    let mastchMalls;

    _.each($scope.channel_list, function(m) {
      mastchMalls = [];
      if ($scope.saveMalls && $scope.saveMalls.use_yn) {
        mastchMalls = _.filter($scope.saveMalls.malls, function(o) {
          return o.shop_cd === m.shop_cd && o.shop_id === m.shop_id;
        });
      }

      if (mastchMalls.length) {
        m.selected = mastchMalls[0].checked;
      } else {
        m.selected = true;
      }
    });

    // 전체사이트 여부 체크.
    const unSelectedOrder = _.filter($scope.channel_list, function(o) {
      return o.selected === false;
    });

    $scope.selectedAll = !unSelectedOrder.length;

    //------------------------
    // 함수
    //------------------------
    /**
     * 취소
     */
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 문의 수집 버튼 클릭 시
     */
    $scope.inquiryCollect = function() {

      const idx = _.findIndex($scope.channel_list, { selected: true });
      const fields = _.filter($scope.fields, { selected: true }).map(function(field) {
        return field.key;
      });

      if (idx < 0) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if (!fields.length) {
        commonSVC.showMessage('한개 이상의 정보를 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      const reCnt = {
        success: 0,
        error: 0,
      };

      let actionName = '';
      let promiseArr = [];
      const viewMsgs = [];
      $scope.saveMalls.malls = [];
      angular.forEach($scope.channel_list, function(shop) {
        if (shop.selected == true) {
          // 작업 추가
          const params = {
            site_code: shop.shop_cd,
            site_id: shop.shop_id
          };

          const shopPromiseArr = [];

          actionName = '';

          angular.forEach(fields, function(field) {
            shopPromiseArr.push(
              workSVC.addWork(field, params)
                .then(function(result) {
                  if (result.status == 200) {
                    reCnt.success++;
                  } else if ((typeof result.data.error) !== 'string' || !result.data.error.includes('not_supported_act')) {
                    ((typeof result.data.error) === 'string') // throw로 받아오는 에러의 경우
                      ? viewMsgs.push(errorSVC.getError('work', result.data.error))
                      : reCnt.error++;
                  } else if (result.data.error.includes('not_supported_act')) {
                    actionName = _.filter($scope.fields, function(o) { return o.key === field; });
                    viewMsgs.push(`${shop.shop_name} 사이트는 [${actionName[0].value}] 기능을 지원하지 않습니다.`);
                  }

                  return result;
                })
                .catch(function(err) {
                  reCnt.error++;

                  return err;
                })
            );
          });
          promiseArr = promiseArr.concat(shopPromiseArr);
        }

        $scope.saveMalls.malls.push({ shop_cd: shop.shop_cd, shop_id: shop.shop_id, checked: shop.selected });
      });
      // 선택 사이트 정보 로컬스토리지 저장.
      localStorage.setItem('scrapCsMalls', JSON.stringify($scope.saveMalls));

      $q.all(promiseArr)
        .then(function() {
          if (!reCnt.success) {
          // 작업이 지원하지 않는 작업만 걸린경우 에러 처리 추가 2019-01-09 rony
            if (!reCnt.error) {
              commonSVC.showToaster('error', '작업등록 실패', viewMsgs.join('\n\n'));
              $timeout(function () { $scope.waitBtnDisableFlag = false; });
            }
            // 성공한게 1개도 없는경우 실패처리
            else {
              throw 'none_success_cnt';
            }
          } else {
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
            $uibModalInstance.close('success');
          }
        })
        .catch(function(err) {
          commonSVC.showToaster('error', '실패', '작업 등록에 실패하였습니다.');
          $timeout(function () { $scope.waitBtnDisableFlag = false; });

          return err;

        });
    };

    /**
     * 체크박스 선택시
     */
    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.channel_list, function (list) {
          list.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.channel_list[type].selected)) {
          $scope.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.channel_list) {
            if (!$scope.channel_list[i].selected) {
              flag = false;

              return;
            }
          }
          $scope.selectedAll = flag;
        }
      }
    };

  });
