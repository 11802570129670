/**
 * 환불내역 그리드 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('RefundGridCtrl', function ($scope, $filter, $location, $state, $timeout, $compile, commonSVC, paySVC, payModel, userInfo, settings, gettextCatalog) {
    const search = $scope.search.refund;

    // 검색영역 폼
    $scope.searchForm = search.searchForm;
    $scope.searchData = search.searchData;

    // 데이터 테이블 관련기능
    search.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      },
      resetDo: function() {
        $scope.resetDo();
      }
    };

    /**
      * 검색 초기화
      */
    $scope.resetDo = function() {
      $scope.search[$scope.gridType].searchForm = angular.copy(paySVC.searchForm_refund);
      $scope.search[$scope.gridType].searchDetail = angular.copy(paySVC.searchDetail_refund);
      $scope.searchDo(true, true);
    };

    search.searchBtn = angular.copy($scope.searchBtn);
    $scope.searchBtn.table_actions = [{
      label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
      btn_type: 'dropdown',
      add_class: 'btn-default',
      item_list: [{
        label: gettextCatalog.getString('전체엑셀 다운로드'),
        action: function () {
          $scope.excelDown('all');
        }
      }, {
        label: gettextCatalog.getString('선택엑셀 다운로드'),
        action: function () {
          $scope.excelDown('select');
        }
      }]
    }];

    /**
     * 환불내역 DataTable 설정
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      rowHeight: 40,
      pinningColumns: [],
      alignCenterColumns: [],
      alignRightColumns: ['sales'],
      defaultSortingColumns: ['refund_ord_no'],
      notSortingColumns: ['email', 'refund_bank_account', 'process_status'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/pay/list`,
        requestWillAction: function (data) {
          const result = Object.assign(data, search.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(result);

          return result;
        },
        requestDidAction: function(result) {
          search.searchData.totalCount = result.recordsTotal;
          $scope.rowTotalCount = result.rowsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'refund_ord_no',
          title: 'No',
          width: 50
        },
        {
          key: 'wdate',
          title: '환불요청일',
          width: 110
        },
        {
          key: 'mdate',
          title: '환불완료일',
          width: 110,
          template: function (row) {
            return row.refund_status === '환불완료' ? row.mdate : '';
          }
        },
        {
          key: 'email',
          title: '요청자(ID)',
          width: 150
        },
        {
          key: 'sales',
          title: '환불금액',
          width: 100,
          template: function (row) {
            return `${$filter('number')(row.refund_sales + Math.round(row.refund_sales / 10))} 원`;
          }
        },
        {
          key: 'refund_bank_account',
          title: '환불계좌',
          width: 150,
          template: function (row) {
            if (row.pay_method === '카드') {
              return row.pay_method;
            }

            return `${row.bank_name}(${row.acct_holder_name})<br>${row.acct_no}`;
          }
        },
        {
          key: 'process_status',
          title: '처리상태',
          width: 130,
          template: function (row) {
            // 처리상태
            let addBtn = '';

            if (row.refund_status === '환불거부') {
              addBtn = `
                <span
                  class="label label-danger cursor-pointer mt-5"
                  ng-click="grid.appScope.rejectReason('${row.refund_rjct_reason}')"
                >
                  사유확인
                </span>`;
            }

            return row.refund_status + addBtn;
          }
        }
      ]
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length(search.searchData.showCount);
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh = true, noDelay) {
      $scope.viewPayStatus = ['환불신청', '처리중', '환불완료', '환불거부'];
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(paySVC.refundExcelFieldList),
        title: '환불내역',
        url: '/app/pay/excel-down',
        searchForm: $scope.pageData
      };

      if (type === 'all') {
        resolve.data.count = search.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const payList = $scope.grid.methods.selectedData('pay_no');

        if (payList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 환불정보가 없습니다.'));

          return false;
        } else {
          resolve.data.select_list = payList;
          resolve.data.count = payList.length;
          resolve.data.isAll = false;
        }
      }

      resolve.data.searchForm.downType = 'refund';

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 환불거부사유 출력
     */
    $scope.rejectReason = function (str) {
      commonSVC.showMessage(gettextCatalog.getString(str));

      return false;
    };
  });