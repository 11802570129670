
'use strict';

/**
 * 2017-03-12 Daniel
 * 우먼스톡
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B687-Ctrl', function ($scope, $timeout, categoryModel, shopAccountModel, commonModel, B687categorySVC, commonSVC, errorSVC, siteInfo) {
    const svc = B687categorySVC;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터
    $scope.Cate = [];                                   // 추가한 카테고리

    $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    $scope.sortableOptions = {
      update: function () {
        $timeout(function() {
          setMainCate();
        }, 100);
      }
    };

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      const cateCd = $scope.CateData[cateType].split('|')[0];     // 선택 카테고리 코드

      if (index === '2' || cateCd === 'noSub' || !cateCd) {
        return false;
      } // 마지막 분류이거나, 하위분류없을시

      // cateSetting(index, cateCd);

      $scope.loading = true;

      clearOpt(index);

      setCateSub(index, cateCd)
        .then(function() {
          $scope.loading = false;
        })
        .catch(function() {
          commonSVC.showMessage('로딩실패');
          $scope.loading = false;
        });
    };

    // 카테고리 추가
    $scope.addCate = function() {
      const cateCd = [];
      const cateNm = [];
      let valid = true;

      if ($scope.Cate.length === 5) {
        commonSVC.showMessage('카테고리는 최대 5개까지 선택할수 있습니다');

        return false;
      }

      _.each($scope.CateList, function(v, k) {
        const data = $scope.CateData[v].split('|');
        const value = data[0];
        const key = data[1];

        if (!value && Object.keys($scope.CateOpt[k]).length) {
          valid = false;

          return false;
        } else if (!value && !Object.keys($scope.CateOpt[k]).length) {
          return false;
        }

        cateCd.push(value);
        cateNm.push(key);
      });

      if (!valid) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      const cateCds = cateCd.join('>');
      const names = cateNm.join('>');

      if (_.find($scope.Cate, { cd: cateCds })) {
        commonSVC.showMessage('이미 추가된 카테고리입니다');

        return false;
      }

      $scope.Cate.push({ cd: cateCds, name: names });

      setMainCate();
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      $scope.Cate.splice(key, 1);

      setMainCate();
    };

    // 하위 카테고리 가져오기
    function setCateSub(index, cateCd) {
      return requestPA('etc.get_cate_sub', { cateCd: cateCd })
        .then(function(res) {
          const data = res.data.data;

          $scope.CateOpt[(parseInt(index) + 1)] = data.items || {};
        })
        .catch(function () {
          $scope.CateOpt[(parseInt(index) + 1)] = {};
        });
    }

    // 옵션 초기화
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 3; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    function detailCate() {
      // 카테고리 저장
      _.each($scope.Cate, function (v, k) {
        const cntType = k === 0 ? '' : k;

        $scope.CateData.detailData[`key_cateNm${cntType}`] = v.name;
        $scope.CateData.detailData[`key_cateCd${cntType}`] = v.cd;
      });
    }

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if ($scope.Cate.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      const cateKey = _.map($scope.Cate, function(c) {
        return c.cd;
      });

      detailCate();

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateKey.join('_');
      // 카테고리 리스트 저장
      $scope.CateData.detailData.Cate = [ ...$scope.Cate ];

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B687',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              param.shop_cate_no = res.data.shop_cate_no;
              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;
            $scope.applyCate(param);
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          return requestPA('etc.get_cate');
        })
        .then(function (res) {
          const data = res.data.data.items;

          if (!data) {
            commonSVC.showMessage('등록된 카테고리 정보가 없습니다.\n쇼핑몰에서 카테고리 생성 후 다시 시도해 주세요.');
            $scope.loading = false;

            return false;
          } else {
            $scope.CateOpt[0] = data;

            if ($scope.matchingData.shop_cate_no_add_info) {
              if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
                matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
              } else {
                matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
              }
            } else if (!$scope.matchingData.sol_cate_shop_add_info) {
              matchDetailDataLoad(angular.copy(B687categorySVC.category_data));
            } else {
              matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
            }
          }
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    /**
     * 대표카테고리 설정
     */
    function setMainCate() {
      let name = '';

      if ($scope.Cate.length > 0) {
        name = $scope.Cate[0].name;
      }
      if ($scope.Cate.length > 1) {
        name += (` 외 ${$scope.Cate.length - 1}개`);
      }

      $scope.CateData.category_names = name;
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = { ...$scope.userInfo, pa_shop_cd: $scope.shop_cd },
            domain = siteInfo.B687.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    const matchDetailDataLoad = (addInfo) => {
      // 수정확인
      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;

      $scope.Cate = addInfo.Cate ? [ ...addInfo.Cate ] : [];

      $scope.loading = false;
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
