'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B005-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, commonSVC, siteInfo, commonModel, shopAccountModel, onlineProductModel, categoryModel) {
    $scope.naverCateList = [];                                                   // 네이버카테고리
    $scope.dispNaverCateList = [];
    $scope.selectedNaverCate = {
      code: '',
      name: ''
    };
    $scope.naver_loading = true;
    $scope.naverCateLoadingMsg = '로딩중...';
    $scope.naver_select = false;

    const textarea_list = ['#key_simple_content'];

    $scope.row = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;

    // disabled된 항목 있는지 여부
    let hasDisabled = false;

    $timeout(() => {
      hasDisabled = !!$('div[ng-include] input[ng-model][ng-disabled], div[ng-include] select[ng-model][ng-disabled], div[ng-include] button[ng-model][ng-disabled]')
        .filter((idx, selector) => $(selector).attr('disabled') === 'disabled').length;
    }, 500);

    const init = async () => {
      try {
        // 계정정보 선 로딩후 카테고리 로딩
        const res = await shopAccountModel.load({ shop_cd: data.shop_data.shop_cd, shop_id: data.shop_data.shop_id });

        $scope.userInfo = res.data.results;

        const cateList = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'B005', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });
        $scope.naverCateList = cateList.data.result.map(cate => {
          return {
            category_id: cate.shop_cate_see_cd,
            category: cate.shop_cate_see_name
          };
        });
        $scope.naverCateLoadingMsg = '검색어를 두 자 이상 입력해주세요.';
        $scope.naver_loading = false;

        if ($scope.row.set_info.key_naver_category) {
          $scope.selectedNaverCate.code = $scope.row.set_info.key_naver_category;
          $scope.naverCateList.some(c => {
            if (c.category_id == $scope.selectedNaverCate.code) {
              $scope.naverCateSearch(c.category);
              $scope.naver_select = true;
            }
          });
        } else {
          $scope.dispNaverCateList = $scope.naverCateList;
        }
      } catch (e) {
        commonSVC.showMessage('실패', '네이버 카테고리 조회 실패');

        $scope.naver_loading = true;
        $scope.naver_placeholder = '조회 실패. 다시 조회하시거나 쇼핑몰 계정을 확인해주세요.';
      } finally {
        $timeout(function() {
          textarea_list.forEach(i => {
            resizeEditTextarea(i);
            $(i).on('input', function() {
              resizeEditTextarea(i);
            });
          });
        }, 100);

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      }
    };

    $scope.naverCateSearch = (change_data) => {
      if (change_data && $scope.selectedNaverCate.name != change_data) {
        $scope.selectedNaverCate.name = change_data;
      }
      if ($scope.selectedNaverCate.name.length >= 2) {
        $scope.dispNaverCateList = $scope.naverCateList.reduce((aur, cur) => {
          if (cur.category.indexOf($scope.selectedNaverCate.name) > -1) {
            aur.push(cur);
          }

          return aur;
        }, []);

        if ($scope.dispNaverCateList.length == 0) {
          $scope.naverCateLoadingMsg = '검색 결과가 없습니다.';
        }
      } else if ($scope.selectedNaverCate.name.length === 0) {
        $scope.dispNaverCateList = $scope.naverCateList;
      } else {
        $scope.naverCateLoadingMsg = '검색어를 두 자 이상 입력해주세요.';
        $scope.dispNaverCateList = [];
      }

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    $scope.naverCateAdd = (data) => {
      if (!$scope.naver_loading && !$scope.naver_select) {
        $scope.selectedNaverCate.code = data.category_id;
        $scope.naverCateSearch(data.category);
        $scope.selectedNaverCate.name = data.category;
        $scope.naver_select = true;
      }
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    $scope.resetNaverCategory = () => {
      $scope.selectedNaverCate.code = '';
      $scope.selectedNaverCate.name = '';
      $scope.naver_select = false;
      $scope.dispNaverCateList = [];
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      $scope.row.set_info.key_naver_category = $scope.selectedNaverCate.code || '';
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    const resizeEditTextarea = (id) => {
      const textarea = $(id);

      textarea.height('auto');
      const scrollHeight = textarea.prop('scrollHeight');

      if (scrollHeight < 54) {
        textarea.height(54);
      } else {
        textarea.height(scrollHeight);
      }
    };

    init();
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        if (hasDisabled) {
          $scope.row.set_info_template_no = '';
          commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

          return false;
        }

        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType,
          hasDisabled
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
          hasDisabled
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
