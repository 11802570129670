'use strict';

angular.module('gmpApp')
  .factory('A032categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_Tctg',
        1: 'key_Lctg',
        2: 'key_Mctg',
        3: 'key_Sctg',
        4: 'key_Dctg'
      },

      //카테고리 data
      category_data: {
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        php_SiteNo: [],
        buyFrm: {
          code: 0,
          name: ''
        }
      },

      // 전시카테고리
      dispCtg: {
        dispCtgType: '1',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: {},
        dispCtgSelectList: [],
        searchResultList: [],
        siteNo: {
          6001: '이마트몰',
          6002: '트레이더스몰',
          6004: '신세계몰',
          6005: 'SSG.COM몰',
          7018: '그로서리몰',
        },
        useSiteNo: { // 판매 사이트
          6001: false,
          6002: false,
          6004: false,
          6005: true
        }
      },

      site_list: [
        { name: '이마트몰', value: '6001' },
        { name: '신세계몰', value: '6004' },
        { name: '트레이더스몰', value: '6002' },
      ]
    };
  });
