'use strict';
/**
 *  Plug-in offers the same functionality as `full_numbers` pagination type 
 *  (see `pagingType` option) but without ellipses.
 *
 *  See [example](http://www.gyrocode.com/articles/jquery-datatables-pagination-without-ellipses) for demonstration.
 *
 *  @name Full Numbers - No Ellipses
 *  @summary Same pagination as 'full_numbers' but without ellipses
 *  @author [Michael Ryvkin](http://www.gyrocode.com)
 */

$.fn.DataTable.ext.pager.full_numbers_custom = function (page, pages)  {
    function _range ( len, start ) {
       var end;
    
       if ( typeof start === "undefined" ){ 
          start = 0;
          end = len;
 
       } else {
          end = start;
          start = len;
       }
 
       var out = []; 
       var i = start;
       for ( i = start ; i < end; i++ ){ out.push(i); }
    
       return out;
    };

    var numbers = [];
    var buttons = 10;
    var currentSection = Math.ceil( (page + 1) / buttons );
    var firstPage = (currentSection * buttons) - (buttons - 1); 
    var lastPage = 0;

    if(page + 1 === pages) {
	    lastPage = pages;
	} else {
		lastPage = currentSection * buttons; 
    }

    if ( pages <= lastPage ) {
        numbers = _range( firstPage -1, pages);
    } else {
        numbers = _range( firstPage -1, lastPage);
    }
 
    numbers.DT_el = 'span';
    
    var DownButton = "next"; //"<span id='PgDn' move_page='" + lastPage + "' title='다음' style='margin-right:10px; margin-left:20px; cursor: pointer;' ><i class='fa fa-angle-right'></i></span>";
    var UpButton = "previous"; // "<span id='PgUp' move_page='" + (firstPage - 11) + "' title='이전' style='margin-right:20px; margin-left:10px; cursor: pointer;' ><i class='fa fa-angle-left'></i></span>";

    if(buttons > page) {
        if( buttons >= pages) {
            return [ numbers ];
        }else {
            return [ numbers, DownButton, 'last' ];
        }
    }else  {
        if (pages <= lastPage) {
            return [ 'first', UpButton, numbers ];
        }else {
            return [ 'first', UpButton, numbers, DownButton, 'last' ];
        }
    }
 };