
'use strict';

/**
 * 2017-03-12 william
 * 1300k
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B048-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B048categorySVC, errorSVC, commonSVC, settings, siteInfo) {
    const svc = B048categorySVC;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터
    $scope.Cate = {};                                   // 추가한 카테고리
    $scope.test = {};

    let f_categoryno1 = '';
    let f_categoryno2 = '';
    let f_categoryno3 = '';

    $scope.loading = false;                             // true: 로딩중, false: 로딩끝

    /**
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd);

      if (index === '3' || cateCd === 'noSub' || !cateCd) {
        return false;
      } // 마지막 분류이거나, 하위분류없을시

      $scope.loading = true;

      clearOpt(index);

      setCateSub(index, cateCd)
        .then(function() {
          $scope.loading = false;
          cateCd = 0;
        })
        .catch(function(err) {
          cateCd = 0;
          commonSVC.showMessage('로딩실패');
          $scope.loading = false;
        });
    };

    // 카테고리 삭제
    $scope.delCate = function(key) {
      delete $scope.Cate[key];
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) {
          break;
        }

        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

    // 하위 카테고리 가져오기
    function setCateSub(index, cateCd) {
      index = index * 1;
      let test = {};

      if (index == 0) {
        f_categoryno1 = cateCd;
        test = {
          f_level: index + 2,
          f_categoryno1: f_categoryno1,
          f_categoryno2: '',
          f_categoryno3: ''
        };
      } else if (index == 1) {
        f_categoryno2 = cateCd;
        test = {
          f_level: index + 2,
          f_categoryno1: f_categoryno1,
          f_categoryno2: f_categoryno2,

          f_categoryno3: ''
        };
      } else if (index == 2) {
        f_categoryno3 = cateCd;
        test = {
          f_level: index + 2,
          f_categoryno1: f_categoryno1,
          f_categoryno2: f_categoryno2,
          f_categoryno3: f_categoryno3
        };
      }

      return requestPA('etc.scrap_category', test)
        .then(function(res) {
          const data = res.data.data.items.category;

          if (!data) {
            $scope.CateOpt[(parseInt(index) + 1)] = { noSub: '하위 카테고리가 없습니다' };
          } // 하위카테고리 없을시 출력
          else {
            $scope.CateOpt[index + 1] = data;
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 4; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = {};
        $scope.CateData[key] = '';
      }
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      const cateCd = [];
      let valid = true;

      _.each($scope.CateList, function(v, k) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }

        if (!value) {
          valid = false;

          return false;
        }

        cateCd.push(value);
      });

      $scope.Cate = {};

      $scope.Cate[cateCd.join('>')] = $scope.CateData.category_names;

      const cateKey = Object.keys($scope.Cate);

      if (cateKey.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;

      //category_code 초기화
      $scope.CateData.detailData.category_code = cateKey[0].replace(/>/g, '_');

      // 카테고리 리스트 저장
      $scope.CateData.detailData.Cate = $scope.Cate;

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B048',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: $scope.CateData.detailData,
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              $scope.loading = true;
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          param.isApply = true;

          $scope.applyCate(param);
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      $scope.loading = true;

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      var param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;
          $scope.userInfo.shop_pwd = $scope.userInfo.shop_pwd;

          return requestPA('etc.scrap_category');
        })
        .then(function (res) {
          const data = res.data.data.items;

          if (!data) {
            commonSVC.showMessage('로딩실패');
            $scope.loading = false;

            return false;
          } else {
            $scope.CateOpt[0] = data.category;

            const param = {
              categoryId: $scope.matchingData.categoryId,
              shop_cd: shop_cd,
              shop_id: shop_id
            };

            // 수정확인
            categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
              if (state === 'success') {
                if (result.length > 0) { // 수정
                  const data = result[0].sol_cate_shop_add_info;

                  $scope.CateData.category_code = data.category_code;
                  $scope.CateData.category_names = data.category_names;
                  $scope.Cate = data.Cate;
                }
              }

              $scope.loading = false;
            });
          }
        })
        .catch(function (err) {
          commonSVC.showMessage('로딩실패');
        });

      /**
         * 2018-03-09 roy
         * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
         */
      if ($scope.matchingData.shop_cate_no_add_info) {
        const param_sol_cate_shop_add_info = JSON.parse($scope.matchingData.shop_cate_no_add_info);

        matchDetailDataLoad(param_sol_cate_shop_add_info);
      } else {
        var param = {
          categoryId: $scope.matchingData.categoryId,
          shop_cd: 'A001',
          shop_id: $scope.matchingData.site_id
        };

        categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
          if (state == 'success') {
            if (result.length == 0) {
              $scope.CateData.detailData = angular.copy(A001categorySVC.category_data);
            } else {
              const param_sol_cate_shop_add_info = result[0].sol_cate_shop_add_info;

              matchDetailDataLoad(param_sol_cate_shop_add_info);
            }
          } else {
            commonSVC.showMessage('데이터 로드 실패');
          }
        });
      }
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {

      var userInfo = $scope.userInfo;
      const domain = siteInfo.B048.domain;

      if (!addData) {
        var f_level = '';
        var f_categoryno1 = '';
        var f_categoryno2 = '';
        var f_categoryno3 = '';
      } else {
        var f_level = addData.f_level;
        var f_categoryno1 = addData.f_categoryno1;
        var f_categoryno2 = addData.f_categoryno2;
        var f_categoryno3 = addData.f_categoryno3;
      }

      var userInfo = {
        shop_id: userInfo.shop_id || '',
        shop_pwd: userInfo.shop_pwd || ''
      };

      var addData = {
        f_level: f_level,
        f_categoryno1: f_categoryno1,
        f_categoryno2: f_categoryno2,
        f_categoryno3: f_categoryno3
      };

      return commonSVC.requestPA(userInfo, siteInfo.B048.domain, 'etc.scrap_category', addData, commonModel, shopAccountModel);
    }

    init();
  });
