'use strict';

angular.module('gmpApp')
  .service('tagSVC', function () {
    return {
      /**
       * 태그 조건
       */
      terms: {
        ordterm_no: null,
        tag_no: null,
        color: 'black',
        s_sales: null,
        e_sales: null,
        s_sale_cnt: null,
        e_sale_cnt: null,
        sales_type: 'none',
        sale_cnt_type: 'none',
        prod_depot: [],
        sku_rule_apply_type: 'none',
        contacts: [{ type: '', value: '' }],
        keywords: [{ type: '', value: '' }],
        tag_list: [],
      },
      /**
       * 태그
       */
      tag: {
        tag_no: null,
        tag_name: null,
        tag_color: 'black',
      },
      tag_color: function () {
        return [
          { name: 'black', code: '#000000' },
          { name: 'red', code: '#f06292' },
          { name: 'blue', code: '#5c90d2' },
          { name: 'green', code: '#4caf50' },
          { name: 'orange', code: '#FF8724' },
          { name: 'purple', code: '#9575cd' }
        ];
      }
    };
  });