
'use strict';

/**
 * 2017-04-27 roy
 * 위메프
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B719-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B719categorySVC, commonSVC, errorSVC, settings, siteInfo) {
    const svc = B719categorySVC;
    let dcateCode;

    $scope.cate = angular.copy(svc.category_opt);       // 카테고리 리스트 옵션
    $scope.sel_category = ['', '', '', ''];             // 선택된 카테고리
    $scope.loading = false;                             // true: 로딩중, false: 로딩끝
    let save_flag = false;                              // 저장 가능 여부

    $scope.select = async (idx, init_yn) => {
      try {
        const cate = $scope.sel_category[idx];

        if (!cate) {
          return false;
        }

        $scope.loading = false;

        $scope.category_names = cate.shop_cate_see_detail.shop_cate_see_name_detail;

        // 마지막 카테고리인지 여부
        save_flag = cate.end_yn;
        if (!save_flag) {
          // 선택시 자식 카테고리 초기화
          for (let i = idx + 1; i <= 3; i++) {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          }

          const res = await categoryModel.getScrapCategoryByDepth(cate);

          await $timeout(() => {
            $scope.cate[idx + 1] = res.data.result;

            if (!init_yn) {
              $scope.loading = true;
            }
          });

          return true;
        } else {
          dcateCode = cate.shop_cate_see_cd;

          // 선택시 자식 카테고리 초기화
          for (let i = idx + 1; i <= 3; i++) {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          }

          await $timeout(() => {
            $scope.loading = true;
          });

          return false;
        }
      } catch (e) {
        // 에러발생시 로딩 처리
        await $timeout(() => {
          $scope.loading = true;
        });
      }
    };
    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);
    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      if (!save_flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B719',
          site_id: $scope.matchingData.site_id,
          category_names: $scope.category_names,
          category_code: `${dcateCode}`,
          categoryId: $scope.matchingData.categoryId,
          detailData: { categoryCd: dcateCode, category_names: $scope.category_names },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');
              param.shop_cate_no = res.data.shop_cate_no;
              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;
            $scope.applyCate(param);
          });
        }
      }
    };

    // 초기화
    const init = async () => {
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'B719', std_ol_yn: 0, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      await $timeout(async () => {
        $scope.cate[0] = res.data.result;
        // 로딩중
        $scope.loading = true;

        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) {
          matchDetailDataLoad(angular.copy(B719categorySVC.category_data));
        } else {
          matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    const matchDetailDataLoad = async (addInfo) => {
      if (!addInfo.categoryCd) {
        $scope.loading = false;

        return false;
      }

      // 카테고리 정보에 마지막 카테고리 정보만 담고 있으므로 디테일 불러옴
      const res = await categoryModel.getScrapCategoryDetail({
        shop_cd: 'B719',
        std_ol_yn: 0,
        shop_cate_see_cd: addInfo.categoryCd
      });

      const cate = res.data.result;

      if (!cate || !cate.shop_cate_see_detail || !cate.shop_cate_see_detail.shop_cate_see_cd_detail) {
        return false;
      }

      await setCategory(cate.shop_cate_see_detail.shop_cate_see_cd_detail);
    };

    const setCategory = async (code) => {
      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';
        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, true) : false;

        if (!hasNext) {
          $timeout(() => {
            $scope.loading = true;
          });
        }

        idx++;
      }
    };

    init();
  });
