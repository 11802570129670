'use strict';

angular.module('gmpApp')
  .factory('B430categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'first_categ',
        1: 'second_categ',
        2: 'third_categ',
        3: 'fourth_categ'
      },

      category_action: {
        0: 'scrap_category_large',
        1: 'scrap_category_middle',
        2: 'scrap_category_small',
        3: 'scrap_category_elaborate'
      },

      // 옵션 repeat data
      category_opt: [
        [],
        [],
        [],
        []
      ],

      disp_category_list: {
        0: 'first_loc',
        1: 'second_loc'
      },

      disp_category_action: {
        0: 'scrap_location_category_large',
        1: 'scrap_location_category_middle'
      },

      disp_category_opt: [
        [],
        []
      ],

      //카테고리 data
      category_data: {
        // 표준분류
        first_categ: '',
        second_categ: '',
        third_categ: '',
        fourth_categ: '',

        first_loc: '',
        second_loc: '',
        set_all_shop: false, //상품적용여부
        // 카테고리 이름,코드
        category_names: '',
        category_code: ''
      }

    };
  });
