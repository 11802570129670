/**
 * Created by ally on 2017. 6. 5..
 */
'use strict';

angular.module('gmpApp')
  .controller('ProdProductStockCtrl', function ($rootScope, $scope, data, settings, $uibModalInstance, commonSVC, productModel, inventoryModel, inventorySVC, gettextCatalog) {
    $scope.searchForm = data.searchForm;
    $scope.searchData = angular.copy(inventorySVC.searchData);
    $scope.prodList = data.prodList;
    $scope.prodCnt = $scope.prodList.length;
    $scope.type = data.type;
    $scope.out_type = 'real_stock_out';
    $scope.outTypeText = '<strong>2.선택된 상품의 출고 수량과 출고 사유를 입력해 주세요.</strong>';

    switch ($scope.type) {
      case 'real_in':
        $scope.typeKor = '입고';
        break;
      case 'real_out':
        $scope.typeKor = '출고';
        break;
      case 'real_notsale':
        $scope.typeKor = '판매불가';
        break;
    }

    $scope.stock = { real: 0, safe: 0, real_stock_setting: 0, cal_type: '+' };
    $scope.skuList = data.skuCodeList;

    // 재고 입력 시 수정 후 실재고 계산
    $scope.editStock = (row, type) => {
      if (type === 'real_in') {
        row.after_edit_cnt = row.stock_cnt_real + +row.edit_stock_cnt;
      } else if (['real_out', 'real_notsale'].includes(type)) {
        if (type === 'real_notsale') {
          row.after_edit_cnt = row.stock_cnt - +row.edit_stock_cnt;

        } else {
          if ($scope.out_type === 'real_stock_out') {
            row.after_edit_cnt = row.stock_cnt - +row.edit_stock_cnt;
          } else {
            row.after_edit_cnt = row.notsale_type_cnt - +row.edit_stock_cnt;
          }
        }
      }
    };

    // 일괄 수정 처리
    $scope.allEdit = (key, value) => {
      const selectedData = $scope.type === 'real_out' && $scope.out_type === 'notsale_out' ? $scope.notSaleOutGrid.methods.selectedData('all') : $scope.grid.methods.selectedData('all');

      if (!selectedData.length) {
        commonSVC.showMessage(gettextCatalog.getString('선택된 상품이 없습니다.'));
      }
      selectedData.forEach(item => {
        item[key] = value;
        if (key === 'edit_stock_cnt') {
          $scope.editStock(item, $scope.type);
        } else {
          item.temp_edit_type = value;
        }
      });
    };

    $scope.changeOutType = (type) => {
      $scope.prodCnt = type === 'real_stock_out' ? $scope.prodList.length : $scope.prodNotSaleList.length;
      $scope.out_type = type;
      $scope.outTypeText = `<strong>2.선택된 상품의 ${type === 'real_stock_out' ? '출고 수량과 출고 사유' : '폐기 수량과 폐기 사유'}를 입력해 주세요.</strong>`;
    };

    $scope.save = _.throttle(function() {
      // 2018-01-14 chris 리스트 새로고침용 이벤트명
      const reloadEvent = data.reloadEvent;

      if (!['real_stock_setting', 'safe_stock_setting', 'safe'].includes($scope.type)) {
        $scope.form1.$valid = $scope.out_type === 'notsale_out' ? !$scope.prodNotSaleList.some(prod => !prod.edit_stock_cnt) : !$scope.prodList.some(prod => !+prod.edit_stock_cnt);
      }

      if ($scope.form1.$valid) {
        if (['real_out', 'real_notsale'].includes($scope.type)) {
          let checkCntOver = false;
          let message = '';

          if ($scope.out_type === 'notsale_out') {
            if (!$scope.prodNotSaleList.length) {
              checkCntOver = true;
              message = '출고(폐기)할 상품이 존재하지 않습니다. \n [출고]창을 닫고 리스트에서 상품 선택 후\n 다시 진행해 주세요.';
            } else {
              checkCntOver = $scope.prodNotSaleList.find(prod => prod.notsale_type_cnt < prod.edit_stock_cnt);
              message = '유형별 판매불가 재고 보다 작거나 같은 수만\n출고할 수 있습니다.';
            }
          } else {
            checkCntOver = $scope.prodList.find(prod => prod.stock_cnt < prod.edit_stock_cnt);
            message = '판매가능재고(실재고-출고예정수량)보다 작은 수만 입력할 수 있습니다.';
          }

          if (checkCntOver) {
            commonSVC.showMessage(message);

            return false;
          }
        }

        if ($scope.type === 'real_notsale') {
          const checkDisabledType = $scope.prodList.find(prod => !prod.inout_memo);

          if (checkDisabledType) {
            commonSVC.showMessage('판매불가 사유는 필수 입력 항목 입니다.\n 사유를 선택 후 다시 진행해 주세요.');

            return false;
          }
        }
        let notSaleData = [];

        if (!['real_in', 'real_out', 'real_notsale'].includes($scope.type)) {
          $scope.prodList = data.prodList;
        } else {

          if ($scope.out_type === 'notsale_out') {
            $scope.prodNotSaleList.forEach((prod) => {
              delete prod._uiGridData;
              delete prod.$$hashKey;
            });
            notSaleData = $scope.prodNotSaleList.filter(list => +list.edit_stock_cnt > 0);

            if (!notSaleData.length) {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('변경된 정보가 없습니다.'));

              $uibModalInstance.close('success');
            }
          } else {
            $scope.prodList.forEach((prod) => {
              delete prod._uiGridData;
              delete prod.$$hashKey;
            });
          }

          if ($scope.type === 'real_notsale') {
            const real_notsale_reason = {
              2: '불량',
              3: '파손',
              4: '판매기한 경과',
              5: '판매기한 임박',
              6: '폐기 대상',
              999: '기타'
            };

            $scope.prodList = $scope.prodList.map(prod => ({
              ...prod,
              notsale_type: prod.inout_memo,
              inout_memo: real_notsale_reason[prod.inout_memo]
            }));
          }
        }
        const params = {
          prod_nos: data.prodList,
          prod_list: $scope.out_type === 'notsale_out' ? notSaleData : $scope.prodList,
          change_data: [],
          logContents: ['일괄 수정'],
          stock_edit_all: data.stock_edit_all || false  // 해당 sku 의 모든 배송처의 재고 수정여부
        };

        let editStockCnt;

        if (['real_in', 'real_out', 'real_notsale'].includes($scope.type)) {
          editStockCnt = $scope.out_type === 'notsale_out' ? notSaleData.map(prod => prod.edit_stock_cnt) : $scope.prodList.map(prod => prod.edit_stock_cnt);
        }

        if ($scope.type === 'safe') {
          params.logContents.splice(0, 0, '안전재고');
          params.change_data.push({ key: 'stock_cnt_safe', value: $scope.stock.safe });
        }
        if ($scope.type === 'real_in') {
          params.logContents.splice(0, 0, '실재고');
          params.change_data.push({ key: 'stock_cnt_real', type: '+', value: editStockCnt });
        }
        if ($scope.type === 'real_out') {
          if ($scope.out_type === 'real_stock_out') {
            params.logContents.splice(0, 0, '실재고');
            params.change_data.push({ key: 'stock_cnt_real', type: '-', value: editStockCnt });
          } else {
            params.logContents.splice(0, 0, '판매불가');
            params.change_data.push({ key: 'notsale_cnt', type: '-', value: editStockCnt });
          }
        }
        if ($scope.type === 'real_notsale') {
          params.logContents.splice(0, 0, '실재고');
          params.change_data.push({ key: 'notsale_cnt', type: '+', value: editStockCnt });
        }
        if ($scope.type === 'real_stock_setting') {
          params.logContents.splice(0, 0, '실재고수량');
          params.change_data.push({ key: 'real_stock_setting', type: $scope.stock.cal_type, value: $scope.stock.real_stock_setting });
        }
        if ($scope.type === 'safe_stock_setting') {
          params.logContents.splice(0, 0, '안전재고수량');
          params.change_data.push({ key: 'safe_stock_setting', value: $scope.stock.safe_stock_setting });
        }
        params.logContents = params.logContents.join(' ');

        productModel.setStock(params, function (state, result) {
          if (state == 'success') {

            // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
            if (reloadEvent) {
              $rootScope.$broadcast(reloadEvent);
            }

            const succViewMsg = ['real_in', 'real_out', 'real_notsale'].includes($scope.type) ? `${$scope.type !== 'real_notsale' ? $scope.typeKor : `${$scope.typeKor} 처리`}에 성공하였습니다.` : '재고 수량 조정에 성공하였습니다.';

            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString(succViewMsg));

            // 실재고 할당 && 재고할당 설정 있으면 재고할당진행
            // 배송처일경우 진행 X
            if ((['real_in', 'real_out', 'real_notsale'].includes($scope.type) && $scope.out_type !== 'notsale_out' || $scope.type === 'real_stock_setting') && data.alloc_type && $rootScope.user_profile.auth_type !== '배송처') {
              const alloc_type = data.alloc_type;
              const prod_list = data.prodList.map(prod => {
                return {
                  prod_no: +prod.prod_no,
                  depot_no: +prod.depot_no
                };
              });

              // 유효한 재고할당 규칙이 있을경우
              if (prod_list.length) {
                inventoryModel.stockAllocCheckRuleExists({ prod_list: prod_list, alloc_type: alloc_type }, function(state, data) {
                  if (data.result) {
                    const newStock = {};
                    newStock[`${$scope.prodList[0].prod_no}_${$scope.prodList[0].depot_no}`] = $scope.stock.real_stock_setting * ($scope.stock.cal_type === '+' ? 1 : -1);

                    const stock_cnt = $scope.prodList.reduce((acc, cur) => {
                      return { ...acc, [`${cur.prod_no}_${cur.depot_no}`]: ['real_out', 'real_notsale'].includes($scope.type) ? -cur.edit_stock_cnt : cur.edit_stock_cnt };
                    }, {});

                    const alloc_params = {
                      prod_list: prod_list,
                      re_alloc_yn: alloc_type === 're_alloc',
                      alloc_type: alloc_type,
                      new_stock: newStock,
                      ...((['real_in', 'real_notsale'].includes($scope.type) || ($scope.type === 'real_out' && $scope.out_type === 'real_stock_out')) && { from: 'stock_cnt_real', stock_cnt })
                    };

                    commonSVC.openModal('full', { data: alloc_params }, 'StockAllocationChannelCtrl', 'views/stock/inventory/stock_allocation_channel.html');
                  } else {
                    return true;
                  }
                });
              }
            }

            $uibModalInstance.close('success');
          } else {

            let errViewMsg = '';

            if (['real_in', 'real_out', 'real_notsale'].includes($scope.type)) {
              errViewMsg = `${$scope.type !== 'real_notsale' ? $scope.typeKor : `${$scope.typeKor} 처리`}에 실패하였습니다.`;
            } else {
              errViewMsg = result.data.error;
            }

            // var errViewMsg = $scope.type === 'real' ? "재고 추가 입고에 실패하였습니다." : "재고 수량 조정에 실패하였습니다.";
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString(errViewMsg));
          }
        });
      } else {
        if (!['real_stock_setting', 'safe_stock_setting', 'safe'].includes($scope.type)) {
          const checkNoneInput = $scope.out_type === 'notsale_out' ? $scope.prodNotSaleList.some(prod => !+prod.edit_stock_cnt) : $scope.prodList.some(prod => !+prod.edit_stock_cnt);
          if (checkNoneInput) {
            commonSVC.showMessage(`${$scope.out_type === 'notsale_out' ? '폐기' : $scope.typeKor} 수량은 필수 입력 항목 입니다. \n ${$scope.out_type === 'notsale_out' ? '폐기' : $scope.typeKor} 수량을 0이상으로 입력 후 다시 진행해 주세요.`);

            return false;
          }
        }
      }
    }, 1000);

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 데이터 테이블 스크롤 시 selectbox 값이 초기화되는 문제가 있어 null로 초기화될 경우 기존 저장된 값으로 변경
    $scope.checkChangeAuto = (row) => {
      if (!row.inout_memo) {
        row.inout_memo = row.temp_edit_type;
      } else {
        row.temp_edit_type = row.inout_memo;
      }
    };

    $scope.checkBtnDisable = (type) => {
      if (type === 'safe') {
        return !$scope.stock[type];
      }

      return false;
    };

    $scope.searchBtn = {      // 상세조건 버튼
      actions_right: [],
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy($scope.searchForm);
      // $scope.searchData = angular.copy(search.searchData);
      $scope.searchDo(true, true);
    };

    /**
     * 데이터 테이블 (입고, 일반 출고, 판매불가)
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      enablePaginationControls: false,
      gridHeight: 400,
      pinningColumns: [],
      alignCenterColumns: [
        'sku_cd',
        'depot_name'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: [
        'sku_cd', 'stock_cd', 'prod_name', 'depot_name', 'stock_cnt_real', 'stock_cnt', 'edit_stock_cnt', 'after_edit_cnt', 'inout_memo'
      ],
      alignRightColumns: [ 'stock_cnt_real', 'stock_cnt', 'edit_stock_cnt', 'after_edit_cnt' ],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/status`,
        requestWillAction: (data) => {
          const result = Object.assign(data, $scope.searchForm);
          result.is_edit = true;
          result.prodList = $scope.prodList.map(o => {
            return `(${o.prod_no}, ${o.depot_no})`;
          });

          return result;
        },
        requestDidAction: (result) => {
          $scope.prodList = result.results;
          $scope.prodList.forEach(row => {
            row.after_edit_cnt = $scope.type === 'real_in' ? row.stock_cnt_real : row.stock_cnt;
          });

          return result.results;
        }
      },
      columns: [
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 90
        },
        {
          key: 'prod_name',
          title: 'SKU상품명_속성',
          width: 310,
          template: function(row) {
            if (row.attri) {
              return `${row.prod_name}_${row.attri}`;
            }

            return row.prod_name;
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 90
        },
        {
          key: $scope.type === 'real_in' ? 'stock_cnt_real' : 'stock_cnt',
          title: $scope.type === 'real_in' ? '실재고' : '판매가능재고',
          width: 70,
          template: function(row) {
            if ($scope.type === 'real_in') {
              return row.stock_cnt_real;
            } else if ($scope.type === 'real_out') {
              if ($scope.out_type === 'real_stock_out') {
                return row.stock_cnt;
              } else {
                return row.stock_cnt_real;
              }
            }

            return row.stock_cnt;
          }
        },
        {
          key: 'edit_stock_cnt',
          title: `${$scope.typeKor} 수량`,
          width: 70,
          required: true,
          template: function(row) {
            const idx = $scope.prodList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no);

            return `
              <input type="text" class="form-control ng-pristine ng-valid ng-empty" style="text-align: right; border: 1px solid #d4d4d4" name="grid.appScope.prodList[${idx}].sku_cd"
                ng-model="grid.appScope.prodList[${idx}].edit_stock_cnt" 
                ng-change="grid.appScope.editStock(grid.appScope.prodList[${idx}], grid.appScope.type)" 
                maxlength="6"
                ptgui-regex-mask="positiveNum" required
              />
            `;
          }
        },
        {
          key: 'after_edit_cnt',
          title: `${$scope.type !== 'real_notsale' ? `${$scope.typeKor} 후 수량` : '차감 후 재고'}`,
          width: 90,
          template: function(row) {
            const idx = $scope.prodList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no);

            return `<div ng-bind="grid.appScope.prodList[${idx}].after_edit_cnt"></div>`;
          }
        },
        {
          key: 'inout_memo',
          title: '사유',
          width: 270,
          tooltip: {
            text: ($scope.type === 'real_notsale' ? '판매불가 사유를 유형별로 지정해 주세요.\n' : '간단한 메모를 등록할 수 있으며, ') + '등록된 사유는 입/출고 내역에서 확인할 수 있습니다.',
            placement: 'top-right',
          },
          required: $scope.type === 'real_notsale',
          template: function(row) {
            const idx = $scope.prodList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no);
            let template;
            if ($scope.type === 'real_notsale') {
              template = `
                <select class="form-control full-width no-padding" style="height: 22px;" ng-model="grid.appScope.prodList[${idx}].inout_memo" 
                ng-change="grid.appScope.checkChangeAuto(grid.appScope.prodList[${idx}])">
                  <option ng-value="''">유형을 선택하세요.</option>
                  <option ng-value="'2'">불량</option>
                  <option ng-value="'3'">파손</option>
                  <option ng-value="'4'">판매기한 경과</option>
                  <option ng-value="'5'">판매기한 임박</option>
                  <option ng-value="'6'">폐기 대상</option>
                  <option ng-value="'999'">기타</option>
                </select>`;
            } else {
              template = `
              <div class="input-group full-width">
                <input type="text" class="form-control" ng-model="grid.appScope.prodList[${idx}].inout_memo" max-bytes="60"></input>
                <span class="input-group-btn">
                  <span class="help-inline no-margin-top"> <span class="str_bytes">0</span> / 60byte</span>
                </span>
              </div>`;
            }

            return template;
          }
        }]
    };

    /**
     * 출고(판매불가) 데이터 테이블
     */
    $scope.notSaleOutGrid = {};
    $scope.notSaleOutGrid.methods = {};
    $scope.notSaleOutGrid.options = {
      modal: true,
      emptyText: '선택된 상품에는 폐기 대상 재고가 존재하지 않습니다.', // 판매불가 가능 상품 없을 경우 해당 문구 데이터테이블에 출력
      emptyTextSub: '리스트에서 판매불가 재고가 존재하는 상품을 선택하신 후 다시 작업을 진행해 주세요.',
      enablePaginationControls: false,
      gridHeight: 400,
      pinningColumns: [],
      alignCenterColumns: [
        'sku_cd',
        'depot_name',
        'notsale_type_cnt',
        'notsale_type'
      ],
      notResizingColumns: [],
      notMovingColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: [
        'sku_cd', 'stock_cd', 'prod_name', 'depot_name', 'stock_cnt_real', 'stock_cnt', 'edit_stock_cnt', 'after_edit_cnt', 'inout_memo', 'notsale_type_cnt', 'notsale_type'
      ],
      alignRightColumns: [ 'stock_cnt_real', 'stock_cnt', 'edit_stock_cnt', 'after_edit_cnt' ],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/status`,
        requestWillAction: (data) => {
          const result = Object.assign(data, $scope.searchForm);
          result.is_edit = true;
          result.prodList = $scope.prodList.map(o => {
            return `(${o.prod_no}, ${o.depot_no})`;
          });

          return result;
        },
        requestDidAction: (result) => {
          const resultData = result.results.filter(row => row.notsale_cnt); // 판매가능 재고 없는 상품 거름

          const divideTypeData = [];
          if (resultData.length) {
            resultData.forEach(row => {
              row.notsale_type.forEach(type => {
                for (const [key, value] of Object.entries(type)) {
                  if (value) {
                    divideTypeData.push({ ...row, edit_stock_cnt: '0', notsale_type: key, notsale_type_cnt: value, after_edit_cnt: value });
                  }
                }
              });

            });
            result.results = divideTypeData;
          } else {
            result.results = [];
          }
          $scope.prodNotSaleList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          width: 90
        },
        {
          key: 'prod_name',
          title: 'SKU상품명_속성',
          width: 310,
          template: function(row) {
            if (row.attri) {
              return `${row.prod_name}_${row.attri}`;
            }

            return row.prod_name;
          }
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 90
        },
        {
          key: 'notsale_type',
          title: '판매불가 유형',
          width: 70,
          template: function(row) {
            switch (row.notsale_type) {
              case '2':
                return '불량';
              case '3':
                return '파손';
              case '4':
                return '판매기한 경과';
              case '5':
                return '판매기한 임박';
              case '6':
                return '폐기 대상';
              case '999':
                return '기타';
            }
          }
        },
        {
          key: 'notsale_type_cnt',
          title: '판매불가 재고',
          width: 70
        },
        {
          key: 'edit_stock_cnt',
          title: '폐기 수량',
          width: 70,
          required: true,
          template: function(row) {
            const idx = $scope.prodNotSaleList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no && data.notsale_type === row.notsale_type);

            return `
                <input type="text" class="form-control ng-pristine ng-valid ng-empty" style="text-align: right; border: 1px solid #d4d4d4" name="grid.appScope.prodNotSaleList[${idx}].sku_cd"
                  ng-model="grid.appScope.prodNotSaleList[${idx}].edit_stock_cnt" 
                  ng-change="grid.appScope.editStock(grid.appScope.prodNotSaleList[${idx}], grid.appScope.type)" 
                  ptgui-regex-mask="onlyNum" required
                />
              `;
          }
        },
        {
          key: 'after_edit_cnt',
          title: '폐기 후 수량',
          width: 90,
          template: function(row) {
            const idx = $scope.prodNotSaleList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no && data.notsale_type === row.notsale_type);

            return `<div ng-bind="grid.appScope.prodNotSaleList[${idx}].after_edit_cnt"></div>`;
          }
        },
        {
          key: 'inout_memo',
          title: '사유',
          width: 200,
          tooltip: {
            text: '간단한 메모를 등록할 수 있으며, 등록된 사유는 입/출고 내역에서 확인할 수 있습니다.',
            placement: 'top-right'
          },
          template: function(row) {
            const idx = $scope.prodNotSaleList.findIndex(data => data.prod_no === row.prod_no && data.depot_no === row.depot_no && data.notsale_type === row.notsale_type);

            return `
            <div class="input-group full-width">
              <input type="text" class="form-control" ng-model="grid.appScope.prodNotSaleList[${idx}].inout_memo" max-bytes="60"></input>
              <span class="input-group-btn">
                <span class="help-inline no-margin-top"> <span class="str_bytes">0</span> / 60byte</span>
              </span>
            </div>`;
          }
        }
      ]
    };
  });