'use strict';

angular.module('gmpApp')
  .controller('OrderSendEmailSettingCtrl', function($scope, $rootScope, $timeout, data, $uibModalInstance, userInfo, commonSVC, warehouseSVC, errorSVC, shipmentSVC, columnSVC, securityModel, adminModel, warehouseModel, shipmentModel, columnModel) {
    const { chargeList, warehouseList, conditions, usedDepotNoList } = data;

    $scope.activeTab = 0;
    $scope.chargeList = chargeList;
    $scope.conditions = conditions;
    $scope.allWarehouseList = angular.copy(warehouseList);

    // 보내는 사람 이메일 주소 설정 관련
    $scope.manage; // 담당자(보내는 이메일)

    // 배송처 별 이메일 주소설정 관련
    $scope.depotAddModalBodyView = 'views/stock/warehouse/add_modal_body.html';
    $scope.selectedWarehouseCd = '';
    $scope.selectedWarehouse = {};
    $scope.used_depot_list = usedDepotNoList?.map(Number);
    let oldEmail = '';

    // 배송처 추가 관련
    $scope.warehouse = angular.copy(warehouseSVC.defaultVdata);
    $scope.setDefault = false; // 사용여부
    $scope.location_list = warehouseSVC.location_list;
    $scope.result = {};
    $scope.charge_name = '';
    $scope.charge_name_self = '';

    // 배송처 엑셀 추가
    $scope.excelModalBodyView = 'views/order/shipment/modals/excel_template_modal_body.html';
    const defaultColumnList = angular.copy(shipmentSVC.integratedExcel);
    let repeatIndex = 0;
    let addColList;
    $scope.sendOrderSettingModal = true;
    $scope.columnList = defaultColumnList.filter(col => data.isGlobal ? col.isGlobal : col.isDomestic);
    $scope.columnListSearch = '';
    $scope.colIndex = null;
    $scope.selectedList = [];
    $scope.selectedColumns = [];
    $scope.deselectedColumns = [];
    $scope.selectedDepot = '';
    $scope.selectedDepotList = [];
    $scope.warehouseList = warehouseList.filter(depot => (!usedDepotNoList.includes(depot.code) && depot.charge_email));
    $scope.cell = {
      header: '',
      variable: '',
      key_settings: 0,
      key: '',
      separator: '',
      width: 20
    };
    $scope.isNew = false;
    $scope.colIndex = null;
    $scope.isCarr = false; // 택배사 정보출력 여부-
    const excelEditUseYn = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;
    const canSeparateCol = ['#SKU상품명', '#추가구매SKU상품명', '#SKU코드', '#SKU코드및출고수량', '추가구매SKU코드'
      , '추가구매SKU코드및출고수량', '#건별출고수량', '#추가구매건별출고수량', '#총출고수량', '#추가구매총출고수량'
      , '#추가구매주문수량', '#바코드', '#추가구매바코드', '#추가구매옵션', '#추가구매세트상품명', '#추가구매세트코드'
      , '#추가구매세트건별출고수량', '#추가구매세트총출고수량', '#SKU상품속성', '#추가구매SKU상품속성'];

    // 엑셀 일괄 수정 사용시 uniq 필요하여 필드목록에 추가
    if (excelEditUseYn) {
      $scope.columnList.splice(1, 0, { header: '주문고유번호', key: 'uniq', select: false, variable: '#주문고유번호', key_settings: '0', width: 35, isDomestic: true, isGlobal: false });
    }

    // 컬럼마다 구분자 추가
    _.forEach($scope.columnList, function (c) {
      c.index = repeatIndex++;
    });

    const columnList = angular.copy($scope.columnList);

    if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) {
      columnModel.addColumnList({ addcol_type: 'all' })
        .then((res) => {
          if (res.data && res.data.results && res.data.results.length) {
            addColList = _.map(res.data.results, 'col_name');
            addColSet(addColList);
          }
        });
    }
    // 배송처 선택 제거
    $scope.delSelectedDepot = (index) => {
      $scope.warehouseList.push($scope.selectedDepotList[index]);
      $scope.selectedDepotList.splice(index, 1);
    };

    // 배송처 선택 여부 확인
    $scope.isDepotSelected = function(depot) {
      return $scope.selectedDepotList.some(selectedDepot => selectedDepot.code === depot.code);
    };

    // 배송처 선택 토글
    $scope.toggleDepotSelection = function(event, depot) {
      event.preventDefault();
      event.stopPropagation();
      const index = $scope.selectedDepotList.findIndex(selectedDepot => selectedDepot.code === depot.code);
      if (index === -1) {
        $scope.selectedDepotList.push(depot);
      } else {
        $scope.selectedDepotList.splice(index, 1);
      }
    };

    // 배송처 전체 선택 여부 확인
    $scope.isAllSelected = function() {
      if (!$scope.selectedDepotList.length) {
        return false;
      }
      const selectedCodes = new Set($scope.selectedDepotList.map(depot => depot.code));

      return $scope.warehouseList.every(depot => selectedCodes.has(depot.code));
    };

    // 배송처 전체 선택 토글
    $scope.toggleSelectAll = function(event) {
      event.preventDefault();
      event.stopPropagation();
      if ($scope.isAllSelected()) {
        $scope.warehouseList = $scope.selectedDepotList;
        $scope.selectedDepotList = [];
      } else {
        const selectedCodes = new Set($scope.selectedDepotList.map(depot => depot.code));
        const combinedList = $scope.warehouseList.filter(depot => !selectedCodes.has(depot.code));

        $scope.selectedDepotList = [...$scope.selectedDepotList, ...combinedList];
      }
    };

    // 새 배송처 만들기
    $scope.addNewDepot = function() {
      const resolve = {
        data: {
          depotListLength: $scope.warehouseList.length
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'StockWarehouseAddCtrl', 'views/stock/warehouse/add.html', false, true, false);

      modal.result.then(function (re) {
        if (re.result === 'success') {
          warehouseModel.ListAll({ use_yn: true }, function(state, data) {
            $scope.warehouseList = data.result.filter(depot =>
              !$scope.used_depot_list.includes(depot.code) &&
              !$scope.template.linkage_depot_list?.includes(depot.code) &&
              depot.charge_email
            );
          });
        }
      });
    };

    // 배송처 별 발주서 엑셀 양식 설정 관련
    const excelDefaultData = {
      file_type: 'xlsx',
      rename_yn: false,
      sale_name_type: '',
      bundle_ord_down_type: 'ord',
      order_by: 'default',
      template_type_cd: 'T107',
      bundle_order_yn: false,
      bundle_order_type: 'desc',
      bundle_ship_cost_type: '직접입력',
    };

    $scope.template = angular.copy(excelDefaultData);

    $scope.selectedList = angular.copy(shipmentSVC.depotSelectedCols);

    // 추가컬럼 항목 세팅
    function addColSet (addColList) {
      let allColIdx = columnList.length - 1;

      _.forEach(addColList, (col) => {
        if (!columnList.find(({ key }) => key === col)) {
          // 커스텀 컬럼이 있는 경우 추가
          columnList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          $scope.columnList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          allColIdx++;
        }
      });
    }

    /**
     * 출력 값 설정 변경
     */
    $scope.change_key_settings = function () {
      const pattern = /#[a-zA-Z가-힣\n]+(?=[-+/*#]|$)/g;
      const matches = $scope.cell.variable.match(pattern) || [];
      if (!$scope.isNew) {
        if ($scope.cell.key_settings === '0' && !$scope.cell.key) {
          if (!matches.length || !$scope.columnList.filter(v => v.variable).map(item => item.variable).includes(matches[0])) {
            return commonSVC.showMessage('실패', '출력값 설정이 [지정안함]일 시 실제 출력 값에 유효한 [#항목명] 을 입력해주세요.');
          }
          $scope.selectedList = $scope.selectedList.map(item => {
            if (item.header === $scope.cell.header) {
              return { ...item, key: $scope.columnList.filter(item => item.variable === matches[0])[0].key };
            } else {
              return item;
            }
          });
          $scope.cell.key = $scope.columnList.filter(item => item.variable === matches[0])[0].key;
        }
      }
      if ($scope.cell.key_settings === '0' && !$scope.isNew) {
        const origin = _.filter(columnList, function (c) {
          return $scope.cell.key === c.key;
        });
        $scope.cell.variable = origin[0].variable;
      }

      if ($scope.cell.key_settings !== '2') {
        delete $scope.cell.separator;
      }
    };

    /**
     * 택배사 사용여부 변경
     */
    $scope.useDefaultCarr = function (flag) {
      $scope.isCarr = flag;
    };

    const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    $scope.esmMasterLoginCheck = function() {
      $scope.esmLoginCheck = true;

      const id = $scope.warehouse.ebaydepot_id;
      const pw = $scope.warehouse.ebaydepot_pwd;

      if (!id || !pw) {
        $('#id,#pw').effect('shake');

        $scope.esmLoginCheck = false;
        $scope.result = {
          state: 'danger',
          msg: 'ID 또는 PW 를 입력해주십시오.',
          icon: 'times-circle'
        };

        return false;
      }

      warehouseModel.checkEsmMasterId(id, pw, 0, 'add')
        .then(function(res) {
          if (res.status !== 200) {
            let msg = '';

            switch (status) {
              case 5005:
                msg = '현재 ESMPLUS 자체 사유로 로그인이 불가능합니다.';
                break;

              default:
                msg = res.message;
                break;
            }

            $scope.result = {
              state: 'danger',
              msg,
              icon: 'times-circle'
            };
          } else {
            if (res.data.engine_result.has_error) {
              commonSVC.showMessage('확인 실패', res.data.engine_result.results[0].site_error);

              $scope.result = {
                state: 'danger',
                msg: '아이디와 비밀번호를 확인해 주십시오.',
                icon: 'times-circle'
              };
            } else {
              $scope.result = {
                state: 'success',
                msg: '사용 가능한 계정입니다',
                icon: 'check-circle'
              };

              $scope.ebayEtcInfo = res.data.engine_result;

            }
          }
        })
        .catch(function(err) {
          if (err) {
            commonSVC.showMessage('확인 실패', err.data.error);
          }
        })
        .finally(function () {
          $scope.esmLoginCheck = false;
        });
    };

    /**
     * 탭 클릭
     */
    $scope.clickTab = function (tabIndex) {
      $scope.activeTab = tabIndex;
    };

    /**
     * 이메일 인증 가이드 새창 열기
     */
    $scope.openGuide = function() {
      window.open('https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=13301');
    };

    /**
     * 이메일 인증 확인
     */
    const checkEmailAuth = async function() {
      const emailCheckAuth = (await securityModel.checkAuth()).data?.results;
      adminModel.load({ c_no: userInfo.user.c_no }, function(state, res) {
        if (state === 'success') {
          $scope.chargeList = res.results.company.charge_info.map(charge => {
            const verifyCheck = emailCheckAuth.filter(check => check.c_charge_no === charge.c_charge_no);

            return { ...charge, verify: verifyCheck[0].verify };
          });
          $scope.conditions.sendEmail = $scope.chargeList.some(charge => charge.verify);
        }
      });

    };

    // 이메일 인증 함수
    $scope.authEmail = async function(email, c_charge_no) {
      if (!email) {
        return commonSVC.showMessage('이메일이 입력되지 않았습니다. 입력 후 다시 시도해주세요.');
      }
      try {
        const result = await securityModel.auth(email, c_charge_no);

        if (result.data?.result === 'success') {
          const isConfirm = await commonSVC.showConfirmCustom({
            html: true,
            title: '이메일 인증 메일이 발송되었습니다.',
            text: '<ul><li>입력한 메일로 인증메일이 발송되었습니다. 수신된 인증 메일의 링크를 클릭하여 인증을 완료하세요.</li>\n\
            <li>이메일 인증에 대한 도움말은 <a onclick="window.open(\'https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=13301\');">여기</a>를 클릭하여 확인할 수 있습니다.</li>\n\
            <li>이메일 인증이 완료되면 이메일보내기 > 보내는 사람에서 이메일을 선택할 수 있습니다.</li>',
            showCancelButton: false,
          });

          if (isConfirm) {
            $scope.saveTab($scope.activeTab, 'next');
          }
        } else if (result.data?.result === 'AlreadyVerified') {
          return commonSVC.showMessage('이미 등록된 메일입니다. 다른 메일을 입력해주세요.');
        } else if (result.data?.message) {
          return commonSVC.showMessage(result.data?.message);
        }
      } catch (err) {
        commonSVC.showMessage('이메일 인증 메일 발송이 실패되었습니다.\n\n잠시 뒤 다시 시도해주세요.');
      }
    };

    /**
     * 인증된 이메일 삭제
     */
    $scope.deleteVerifyEmail = async function(c_charge_no, email) {
      const isDelete = await commonSVC.showConfirm('이메일을 삭제하시겠습니까?', '삭제한 이메일을 다시 등록하려면 재인증이 필요합니다.');
      if (isDelete) {
        try {
          await securityModel.deleteVerifyEmail(c_charge_no, email);
          document.getElementById(`email_${email}`).value = '';
          $scope.chargeList = $scope.chargeList.map(info => {
            if (info.c_charge_no === c_charge_no) {
              return { ...info, charge_email: '', verify: false };
            }

            return info;
          });
        } catch (error) {
          commonSVC.showToaster('error', '실패', '이메일 삭제가 실패되었습니다.');
        }

      }
      if (!$scope.$$phase) {
        $scope.$apply(); // 디지털 디스크럽션 주기가 진행 중이 아니라면 $apply 호출
      }
    };

    /**
     * 발주서 엑셀 양식 설정 도움말 모달
     */
    $scope.openHelp = function () {
      commonSVC.openModal('lg', {}, 'ExcelEditIntegratedHelpCtrl', 'views/order/shipment/modals/excel_edit_integrated_help.html');
    };

    /**
     * 이전/다음 페이지로 이동(이동시마다 저장)
     */
    $scope.saveTab = async function (tabIndex, type) {
      if (tabIndex === 0) {
        let isSave = true;

        angular.forEach($scope.chargeList, function (obj) {
          if (obj.charge_email && obj.charge_email.match(emailRegExp) == null) {
            isSave = false;
            commonSVC.showMessage('담당자 이메일을 확인 해주세요.');

            return;
          }
        });

        if (!isSave) {
          return false;
        }

        const params = {
          c_no: userInfo.user.c_no,
          key: 'charge_info',
          value: angular.copy($scope.chargeList)
        };

        await checkEmailAuth();
        adminModel.set(params);
      }

      if (tabIndex === 1) {
        // 선택된 배송처 코드가 있고, 배송처 추가가 아닌 경우 해당 배송처 정보에 email주소 업데이트
        if ($scope.selectedWarehouseCd) {
          if ($scope.selectedWarehouseCd === 'addWarehouse') {
            if (!$scope.warehouse.depot_name) {
              commonSVC.showMessage('실패', '배송처명을 입력해 주세요.');

              return false;
            }

            if ($scope.addWarehouseForm.$valid) {
              if ($scope.warehouse.ebaydepot_yn === 1 && $scope.result.state !== 'success') {
                commonSVC.showMessage('등록 실패', 'ESM Master ID 연동을 진행해주세요');

                return false;
              }

              $scope.warehouse.charge_flag = $scope.charge_name;
              $scope.warehouse.charge_name = $scope.charge_name === '-1' ? $scope.charge_name_self : $scope.charge_name;
              $scope.warehouse.setDefault = $scope.setDefault;

              if ($scope.warehouse.ebaydepot_yn) {
                $scope.warehouse.etc = $scope.ebayEtcInfo;
              }

              warehouseModel.insert($scope.warehouse, async function (state, data) {
                if (data.results === 'success') {
                  commonSVC.showToaster('success', '성공', '배송처 등록에 성공하였습니다.');
                  $scope.conditions.depotEmail = !!$scope.warehouse.charge_email;
                  const re = await warehouseModel.ListAll({ use_yn: true });
                  if (re.data.result.length) {
                    $timeout(() => {
                      $scope.warehouseList = re.data.result.filter(depot => (!usedDepotNoList.includes(depot.code) && depot.charge_email));
                    });
                  }
                } else {
                  commonSVC.showToaster('error', '실패', errorSVC.getError('depot', data.message));
                  $scope.conditions.depotEmail = false;
                }
              });
            } else {
              return false;
            }
          } else {
            // 배송처 이메일 업데이트
            if ($scope.selectedWarehouse.charge_email) {
              // 이메일 입력 안해도 ㄱㅊㄱㅊ 입력한 경우에는 이메일 형식
              if ($scope.selectedWarehouse.charge_email.match(emailRegExp) == null) {
                commonSVC.showMessage('담당자 이메일을 확인 해주세요.');

                return false;
              } else {
                // 로그 추가
                $scope.selectedWarehouse.log_contents = [`담당자 이메일: ${oldEmail} -> ${$scope.selectedWarehouse.charge_email}`];

                warehouseModel.update($scope.selectedWarehouse)
                  .then(async (re) => {
                    if (re.data.results === 'success') {
                      commonSVC.showToaster('success', '성공', '배송처 수정에 성공하였습니다.');
                      $scope.conditions.depotEmail = true;
                      const re = await warehouseModel.ListAll({ use_yn: true });
                      if (re.data.result.length) {
                        $scope.warehouseList = re.data.result.filter(depot => (!usedDepotNoList.includes(depot.code) && depot.charge_email));
                      }
                    } else {
                      $scope.conditions.depotEmail = false;
                      throw new Error();
                    }
                  })
                  .catch(() => {
                    commonSVC.showToaster('error', '실패', errorSVC.getError('depot', data.data.error));
                  });
              }
            }
          }

        }
      }

      if (tabIndex === 2) {
        if (!$scope.conditions.depotExcel) {
          if (!$scope.selectedList.filter(o => o).length) {
            commonSVC.showMessage('실패', '선택항목이 비어 있습니다. 전체항목에서 다운로드 받을 항목을 선택해주세요.');

            return false;
          }

          if ($scope.template.template_type_cd === 'T107' && !$scope.selectedDepotList.length && !$scope.isGlobal) {
            commonSVC.showMessage('실패', '연동할 배송처를 선택하여 주세요.');

            return false;
          }

          if (!$scope.template.name) {
            commonSVC.showMessage('실패', 'Excel 양식명을 입력해 주세요.');

            return false;
          }

          let isErr = false;

          $scope.selectedList.forEach((col) => {
            if (col.message) {
              isErr = true;
              commonSVC.showMessageHtml('실패', `항목명: ${col.header} <br> 내용: ${col.message}`);

              return false;
            }
          });

          if (isErr) {
            return false;
          }

          if ($scope.deliveryExcelTemplateForm.$valid) {
            if ($scope.selectedList.length === 0) {
              commonSVC.showMessage('엑셀 항목을 선택해주세요.');

              return false;
            }

            // selectedList만 데이터에 넣음
            $scope.template.columns_rename = $scope.selectedList;
            $scope.template.default_carr_no = $scope.isCarr ? $scope.template.default_carr_no : null; //택배사 정보 출력 여부 체크
            $scope.template.bundle_order_type = $scope.template.bundle_order_yn ? $scope.template.bundle_order_type : '';
            $scope.template.global_yn = data.isGlobal ? 1 : 0;

            // 템플릿 타입 배송처일 경우 연동 배송처 저장
            if ($scope.template.template_type_cd === 'T107' && !$scope.isGlobal) {
              $scope.template.linkage_depot_list = $scope.selectedDepotList.map(depot => depot.code);
            }

            const confirm = await commonSVC.showConfirm('등록하시겠습니까?', '');
            if (confirm) {
              const re = await shipmentModel.insertIntegratedExcelTemplate($scope.template);

              if (re.data.affectedRows === 1) {
                commonSVC.showToaster('success', '성공', 'Excel 양식 등록 성공');
                $scope.conditions.depotEmail = true;
              } else {
                commonSVC.showToaster('error', '실패', 'Excel 양식 등록 실패');
                $scope.conditions.depotEmail = false;
              }
            } else {
              return false;
            }

          }
        }
      }

      // 이동 타입에따라 인덱스 이동
      if (type === 'prev') {
        if ($scope.activeTab === 1) {
          await checkEmailAuth();
        }

        if ($scope.activeTab !== 0) {
          $scope.activeTab -= 1;
        }
      }
      if (type === 'next') {
        if ($scope.activeTab !== 2) {
          $scope.activeTab += 1;
        } else {
          $uibModalInstance.close('success');
        }
      }
    };

    $scope.changeWarehosueCd = function() {
      if ($scope.selectedWarehouseCd !== 'addWarehouse') {
        warehouseModel.load($scope.selectedWarehouseCd, (state, result) => {
          if (state === 'success') {
            $scope.selectedWarehouse = { ...result };
            if ($scope.warehouse.charge_name === null) {
              $scope.warehouse.charge_name = '';
            }

            if ($scope.warehouse.charge_name_self) {
              $scope.warehouse.charge_name = '-1';
              $scope.charge_name_self = $scope.warehouse.charge_name_self;
            }

            oldEmail = angular.copy($scope.selectedDepot.charge_email);
          }
        });
      }
    };

    // 배송처 선택 제거
    $scope.delSelectedDepot = (index) => {
      $scope.warehouseList.push($scope.selectedDepotList[index]);
      $scope.selectedDepotList.splice(index, 1);
    };

    // 발주서 엑셀 양식 설정 탭 - 새 배송처 만들기
    $scope.addSelectedDepot = function (values) {
      if (values === 'addDepot') {
        const resolve = {
          data: {
            depotListLength: $scope.warehouseList.length // 모달 열릴때 현재 배송처 개수를 전달
          }
        };

        const modal = commonSVC.openModal('lg', resolve, 'StockWarehouseAddCtrl', 'views/stock/warehouse/add.html', false, true, false);

        modal.result.then(function (re) {
          if (re.result === 'success') {
            warehouseModel.ListAll({ use_yn: true }, function(state, data) {
              $scope.warehouseList = data.result.filter(depot => (!usedDepotNoList.includes(depot.code) && !$scope.template.linkage_depot_list?.includes(depot.code)) && depot.charge_email);

              $scope.selectedDepot = '';
            });
          }
        });

        return;
      }

      if (!Array.isArray(values)) {
        values = [ values ];
      }

      for (const value of values) {
        if (!value) {
          return;
        }
        if ($scope.selectedDepotList.every(v => v.code != value.code)) {
          $scope.selectedDepotList.push(value);
          $scope.warehouseList = $scope.warehouseList.filter(v => !$scope.selectedDepotList.some(depot => depot.code === v.code));
        }

        return;
      }

    };

    /**
     * 전체항목 검색
     */
    $scope.columnListFilter = column => {
      // 분류가 신규주문/출고관리 아닌 경우 '출고가능여부' 컬럼 리스트에서 제외
      if (!['T103', 'T104'].includes($scope.template.template_type_cd) && column.header === '출고가능여부') {
        return false;
      }

      // 검색어 처리
      if ($scope.columnListSearch) {
        return column.header.indexOf($scope.columnListSearch) >= 0;
      } else {
        return true;
      }
    };

    /**
     * 전체항목 검색 초기화
     */
    $scope.clearFilter = function () {
      $scope.columnList = angular.copy(columnList);
      $scope.columnListSearch = '';
    };

    /**
     * 선택항목에 추가
     */
    $scope.select = function(flag) {
      if (flag === 'all') {
        const temp = _.filter($scope.selectedList, function (s) {
          return s.custom;
        });

        $scope.selectedList = _.concat(temp, angular.copy($scope.columnList));
      } else {
        if ($scope.selectedColumns.length === 0) {
          return;
        }
        _.forEach($scope.selectedColumns, function(v) {
          const isDuplicated = _.findIndex($scope.selectedList, ['header', v]) !== -1; //중복 확인

          if (isDuplicated) {
            commonSVC.showMessage('이미 선택된 항목이 있습니다.');

            return false;
          } else {
            const idx = _.findIndex($scope.columnList, ['header', v]);

            $scope.selectedList.push(angular.copy($scope.columnList[idx]));
          }
        });
      }
    };

    /**
     * 선택항목에서 제거
     */
    $scope.deselect = function(flag) {
      if (flag === 'all') {
        const customList = _.filter($scope.selectedList, function (s) {
          return s.custom;
        });

        $scope.selectedList = angular.copy(customList);
      } else {
        if ($scope.deselectedColumns.length === 0) {
          return false;
        }
        _.forEach($scope.deselectedColumns, function (c) {
          const idx = _.findIndex($scope.selectedList, function (s) {

            return c === s.header + (s.key_settings || '') + s.index;
          });

          _.pullAt($scope.selectedList, idx);
        });
      }
    };

    /**
     * 순서 변경
     */
    $scope.change_seq = function(calcul, position) {
      if ($scope.deselectedColumns.length === 0) {
        return;
      }

      // if (idx == 0 && calcul === 'up' || idx == $scope.selectedList.length - 1 && calcul === 'down') {
      //   return false;
      // }

      // var tmp = $scope.selectedList[idx];
      const index = [];
      let sTemp = [];

      if (position === 'end') {
        _.forEach($scope.deselectedColumns, function (d) {
          index.push(_.findIndex($scope.selectedList, function (s) {
            return d === columnSVC.setColumnName(s.header, 'GET') + (s.key_settings || '') + (_.isNullOrEmpty(s.index) ? '' : s.index);
          }));
        });

        if (calcul === 'down') {
          if (_.isEqual(_.map($scope.selectedList.slice($scope.selectedList.length - index.length, $scope.selectedList.length), 'header'), $scope.deselectedColumns)) {
            commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

            return false;
          }

          $scope.colIndex = $scope.selectedList.length - 1;
          sTemp = _.pullAt($scope.selectedList, index);

          $scope.selectedList = _.concat($scope.selectedList, sTemp);
        } else {
          if (_.isEqual(_.map($scope.selectedList.slice(0, index.length), 'header'), $scope.deselectedColumns)) {
            commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

            return false;
          }

          $scope.colIndex = 0;
          sTemp = _.pullAt($scope.selectedList, index);

          $scope.selectedList = _.concat(sTemp, $scope.selectedList);
        }
      } else {
        const toIndex = [];

        _.forEach($scope.deselectedColumns, function (d) {
          const i = _.findIndex($scope.selectedList, function (s) {
            return d === s.header + (s.key_settings || '') + (s.index || '');
          });

          if (calcul === 'down') {
            index.unshift(i);

            if (i !== $scope.selectedList.length - 1 && toIndex.indexOf(i + 1) === -1) {
              toIndex.unshift(i + 1);
            } else if ($scope.selectedList.length - 1 && toIndex.indexOf(i) === -1) {
              toIndex.unshift(i);
            } else {
              toIndex.unshift(i - 1);
            }
          } else {
            index.push(i);
            if (i !== 0 && toIndex.indexOf(i - 1) === -1) {
              toIndex.push(i - 1);
            } else {
              toIndex.push(i);
            }

          }
        });
        if (_.isEqual(_.sortBy(toIndex), _.sortBy(index))) {
          commonSVC.showMessage('실패', '이동할 수 있는 컬럼이 존재하지 않습니다.');

          return false;
        }

        if (calcul === 'up') {
          _.forEach(index, function (idxV, i) {
            const sTemp = $scope.selectedList[idxV];

            $scope.selectedList[idxV] = $scope.selectedList[toIndex[i]];
            $scope.selectedList[toIndex[i]] = sTemp;
            $scope.colIndex--;
          });
        } else {
          _.forEach(index, function (idxV, i) {
            const sTemp = $scope.selectedList[idxV];

            $scope.selectedList[idxV] = $scope.selectedList[toIndex[i]];
            $scope.selectedList[toIndex[i]] = sTemp;
            $scope.colIndex++;
          });
        }

      }
    };

    // 양식 변경 탭
    $scope.newColumn = function () {
      $scope.isNew = true;
      $scope.cell.header = '신규';
      $scope.cell.variable = '';
      $scope.cell.key_settings = '1';
      $scope.cell.width = 20;
    };

    // 컬럼 클릭(변경)
    $scope.changeColumn = function (index) {
      if ($scope.deselectedColumns.length !== 1) {
        $scope.isEdit = false;

        return false;
      } else {
        $scope.isEdit = true;
      }

      if ($scope.isNew) {
        $scope.isNew = false;
        $scope.isEdit = true;
      }

      // 커스텀 컬럼인 경우 수정불가
      if ($scope.selectedList[index].unchangable) {
        $scope.isNew = false;
        $scope.isEdit = false;

        return true;
      }

      if ($scope.colIndex !== index) {
        const column = $scope.selectedList[index];

        $scope.cell.variable = column.variable;
        $scope.cell.header = column.header;
        $scope.cell.key_settings = column.key_settings;
        $scope.cell.key = column.key;
        $scope.cell.separator = column.separator;
        $scope.cell.width = column.width;
        if ($scope.deselectedColumns.length === 1) {
          $scope.colIndex = index;
        }
      }
    };

    // 양식 내 신규 셀 설정 저장
    $scope.updateRenameList = function () {
      $scope.template.rename_yn = true;
      let column = {};
      let flag = true;
      let message = '';
      // 출력 값 설정 === 고정텍스트 일 때만 빈값 허용
      if ($scope.cell.key_settings !== '1' && !$scope.cell.variable) {
        commonSVC.showMessage('실패', '고정텍스트 형식인 항목에서만 빈 값이 허용됩니다.');

        return false;
      }
      if ($scope.isNew) {
        column = {
          header: $scope.cell.header,
          key_settings: $scope.cell.key_settings,
          variable: $scope.cell.variable,
          custom: 1,
          index: repeatIndex++
        };

        // 항목명이 있을 때만 동일한 항목명 구분
        if (_.filter($scope.selectedList, function (s) {
          return $scope.cell.header === s.header;
        }).length && $scope.cell.header) {
          commonSVC.showMessage('실패', '동일한 항목명이 이미 존재합니다.');

          return false;
        }
      } else if ($scope.isEdit) {
        // 항목명이 있을 때만 동일한 항목명 구분
        if ($scope.cell.key_settings === '0') {
          const pattern = /#[a-zA-Z가-힣\n]+(?=[-+/*#]|$)/g;
          const matches = $scope.cell.variable.match(pattern) || [];
          if (!matches.length || !$scope.columnList.filter(v => v.variable).map(item => item.variable).includes(matches[0])) {
            commonSVC.showMessage('실패', '출력값 설정이 [지정안함]일 시 실제 출력 값에 유효한 [#항목명] 을 입력해주세요.');

            return false;
          } else {
            const originKey = $scope.selectedList.length ? $scope.selectedList[$scope.colIndex].key : $scope.columnList[$scope.colIndex].key;
            column.newKey = originKey;
            column.variable = _.filter($scope.columnList, function(o) { return o.key === originKey; })[0].variable;
          }
        }
        if ($scope.selectedList[$scope.colIndex].header !== $scope.cell.header && _.filter($scope.selectedList, function (s) {
          return $scope.cell.header === s?.header;
        }).length && $scope.cell.header) {
          commonSVC.showMessage('실패', '동일한 항목명이 이미 존재합니다.');

          return false;
        }
        $scope.cell.index = column.index;
        column = $scope.selectedList[$scope.colIndex]; // 수정될 컬럼
        column.key_settings = $scope.cell.key_settings;
        column.variable = $scope.cell.variable;
        column.header = $scope.cell.header;
      }

      if (!$scope.cell.key_settings || $scope.cell.key_settings === '0') {
        // 사용안함일때 key 원본 값 사용 및 variable 초기화
        // 선택항목이 있는경우 선택항목 값으로 복구해야 함. 2019-05-29 rony
        const originKey = $scope.selectedList.length ? $scope.selectedList[$scope.colIndex].key : $scope.columnList[$scope.colIndex].key;

        column.newKey = originKey;
        column.variable = _.filter($scope.columnList, function(o) { return o.key === originKey; })[0].variable;
      } else if ($scope.cell.key_settings === '1') {
        $scope.cell.index = column.index;
        const { header, key, key_settings, variable, width, index } = $scope.cell;
        // 고정 텍스트 사용할 때 variable 만 변경
        column = { header, key, key_settings, variable, width, index };
      } else if ($scope.cell.key_settings === '2') {
        column.newKey = $scope.cell.variable.replace(/#[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+/g, function (e) {
          const columns = _.filter(columnList, function (col) {
            return col.variable === e;
          });

          if (columns.length) {
            return `#${columns[0].key}`;
          } else {
            message = `<b class="text-danger">${e.replace('#', '')}</b> 변수명을 찾을 수 없습니다.`;

            flag = false;
          }
        });
        column.variable = $scope.cell.variable;
      } else if ($scope.cell.key_settings === '3') {
        // 수식 사용일 때 수식 + db 컬럼명으로 치환
        const check = $scope.cell.variable.split(/[+*/()-]/g); // 수식확인

        if (!_.isArray(check) || _.compact(check).length <= 1) {
          message = '정상적인 수식이 아닙니다.';
          flag = false;
        } else if (_.filter(check, (col) => { return col.match(/^[^#0-9]+/g); }).length) {
          const notMatch = _.filter(check, (col) => { return col.match(/^[^#0-9]/g); });

          message = `수식에 변수에 포함되지 않은 문자열이 있습니다. 문자열: <b class="text-danger">${notMatch.join(',')}</b>`;
          flag = false;
        } else {
          column.newKey = $scope.cell.variable.replace(/[a-zA-Zㄱ-힣]+/g, function (columnHeader) {
            // 구분자 사용하는 컬럼 수식 불가
            // 국가 사용불가..
            if (!columnHeader.match(/^[가-힣]+[가액비량]$/g) || canSeparateCol.indexOf(`#${columnHeader}`) > -1 || columnHeader === '국가') { // 인트형 컬럼명 찾기...
              message = `수식에 사용 불가능한 변수 또는 문자열이 입력되어 있습니다. \n 변수(문자열): <b class="text-danger">${columnHeader}</b>`;
              flag = false;

              return;
            } else {
              const columns = _.filter(columnList, function (col) {
                return col.header === columnHeader;
              });

              if (columns.length) {
                return columns[0].key;
              } else {
                // 일치하는 컬럼명이 없을 때
                message = `변수명이 올바르지 않습니다. \n 변수명: #${columnHeader}`;
                flag = false;
              }
            }
          }).replace(/[a-zA-Z_]+\(/g, function (e) {  // #변수(#변수+#변수) 곱하기 처리
            return e.replace('(', '*(');
          }).replace(/\)[a-zA-A_]+/g, function (e) {  // (#변수+#변수)#변수 곱하기 처리
            return e.replace(')', ')*');
          });

          column.variable = $scope.cell.variable;
        }
      }

      // 구분자 처리
      if ($scope.cell.key_settings === '2' && $scope.checkSeparator($scope.cell.variable)) {
        column.separator = $scope.cell.separator;
      } else {
        delete column.separator;
      }

      column.width = parseInt($scope.cell.width);

      if (!flag) {
        column.message = message;
        commonSVC.showMessageHtml('실패', message);

        return false;
      } else {
        delete column.message;

        if ($scope.isNew) {
          column.index = repeatIndex;
          $scope.selectedList.unshift(column);
        } else if ($scope.isEdit) {
          $scope.selectedList[$scope.colIndex] = column;
        }

        $scope.isNew = $scope.isEdit = false;
        commonSVC.showToaster('success', '성공', '저장 성공\n모달 가장 하단에 있는 저장버튼을 누르시면 최종적으로 저장되어 적용됩니다.');
      }

      return;
    };

    const init = async function() {
      await checkEmailAuth();
    };

    init();

    $scope.cancel = function () {
      $uibModalInstance.close();
    };
  });