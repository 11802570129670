/**
 * 스크롤 최하단으로 자동으로 내려주기
 * 2017-01-17 : Matthew
 */
'use strict';

angular.module('gmpApp')

  .directive('scrollToLast', ['$location', '$anchorScroll', function($location, $anchorScroll) {

    function linkFn(scope, element, attrs) {
      $location.hash(attrs.scrollToLast);
      $anchorScroll();
    }

    return {
      restrict: 'AE',
      scope: {

      },
      link: linkFn
    };

  }]);
