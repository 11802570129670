/**
 * channel.io
 *
 */
'use strict';

angular.module('gmpApp')
  .factory('channelSVC', function ($rootScope) {

    $rootScope.channel = {
      unread_cnt: 0,
      isOpen: false
    };

    loadScript();
    function loadScript() {
      const w = window;

      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function() {})('ChannelIO script included twice.');
      }
      const d = window.document;
      const ch = function() {
        ch.c(arguments);
      };

      ch.q = [];
      ch.c = function(args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement('script');

        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        const x = document.getElementsByTagName('script')[0];

        x.parentNode.insertBefore(s, x);

        //  boot 완료시 읽지않은 메세지 개수 가져와서 세팅
        // 해당 처리는 아래의 boot 에 콜백으로 넣어줌.
        // window.ChannelIO('onBoot', (guest) => {
        //   if (guest) {
        //     $rootScope.channel.unread_cnt = guest.alert;
        //   }
        // });

        window.ChannelIO('onBadgeChanged', (unreadCount) => {
          $rootScope.channel.unread_cnt = unreadCount;
        });

        window.ChannelIO('onHideMessenger', function() {
          $rootScope.channel.isOpen = false;
        });
        window.ChannelIO('onShowMessenger', function() {
          $rootScope.channel.isOpen = true;
        });
      }
      if (document.readyState === 'complete') {
        l();
      } else if (window.attachEvent) {
        window.attachEvent('onload', l);
      } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
      }
    }

    return {
      boot: (settings) => {
        window.ChannelIO('boot', settings, (error, user) => {
          if (user) {
            $rootScope.channel.unread_cnt = user.alert;
            // unread_cnt 가 페이지 로딩시 바로 반영되지않아 apply 처리해줌.
            $rootScope.$apply();
          }
        });
      },

      shutdown: () => {
        window.ChannelIO('shutdown');
      },

      show: () => {
        window.ChannelIO('showMessenger');
        $('#ch-plugin-script').css('margin-left', 35);
      },

      hide: () => {
        window.ChannelIO('hideMessenger');
      },

      track: (eventName, obj) => {
        window.ChannelIO('track', eventName, obj);
      }
    };
  });
