'use strict';

angular.module('gmpApp')
  .controller('ExcelTemplateCtrl', function ($scope, data, $uibModalInstance, commonSVC, columnSVC, deliveryModel, columnModel, shipmentSVC, stockExceptionSVC, $rootScope) {
    $scope.carrList = $rootScope.deliveryInfoList;
    $scope.template = data.template ? angular.copy(data.template) : {};
    $scope.pageType = data.pageType;
    $scope.view = 'views/order/shipment/modals/invoice_excel_template_modal_body.html';
    // 주문 엑셀 수정 사용 가능여부
    const excelEditUseYn = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;
    $scope.nameCheck = true; // 엑셀 양식명 체크
    // 재고관리 미사용버전인경우 sku 코드 항목이 노출되면 안되므로 필터링함. 2018-11-28 rony
    if ($rootScope.user_profile.sol_stock < 1) {
      $scope.columnList = _.filter(shipmentSVC.excelFieldList.unstoring, function(o) {
        return o.key !== 'sku_cd' && o.key !== 'prod_data' && o.key !== 'map_yn' && o.key !== 'pack_unit' && o.key !== 'sale_cnt_total';
      });
    } else {
      $scope.columnList = shipmentSVC.excelFieldList.unstoring; // 전체 항목 리스트
    }

    // 엑셀 일괄 수정 사용시 uniq 필요하여 필드목록에 추가함
    if (excelEditUseYn) {
      $scope.columnList.splice(1, 0, { header: '주문고유번호', key: 'uniq', select: true });
    }

    /**
     * 추가컬럼 항목 세팅
     * @param {Array} addColList 추가항목 리스트
     */
    function addColSet(addColList) {
      let allColIdx = $scope.columnList.length - 1;

      addColList.forEach(col => {
        if (!$scope.columnList.find(({ key }) => key === col)) {
          // 커스텀 컬럼이 있는 경우 추가
          $scope.columnList.push({ header: columnSVC.setColumnName(col, 'GET'), key: col, key_settings: '0', unchangable: true, width: 30, index: allColIdx });
          allColIdx++;
        }
      });
    }

    // LG 대리점 계정인 경우 커스텀컬럼도 필드에 추가
    if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      columnModel.addColumnList({ addcol_type: 'all' })
        .then((res) => {
          if (res.data && res.data.results && res.data.results.length) {
            addColSet(res.data.results.map(({ col_name }) => col_name));
          }
        });
    }

    $scope.selectedList = [];                        // 선택 항목 리스트
    $scope.renameList = [];                          // 항목명 변경 리스트
    $scope.selectedColumns = [];
    $scope.deselectedColumns = [];
    $scope.renameColumn = '';

    const defaultData = {
      file_type: 'xlsx',
      rename_yn: false,
      sale_name_type: 'ord'
    };

    const defaultColumns = [
      { header: '묶음번호', key: 'bundle_no', select: false },
      { header: '택배사코드', key: 'carr_no', select: false },
      { header: '운송장번호', key: 'invoice_no', select: false }
    ];

    function init() {
      if ($scope.pageType === 'add') {
        $scope.template = angular.copy(defaultData);
        $scope.selectedList = angular.copy(defaultColumns);
        $scope.renameList = angular.copy(defaultColumns);
      } else if ($scope.pageType === 'edit') {
        $scope.selectedList = (typeof $scope.template.columns_origin === 'object' && $scope.template.columns_origin)
          || JSON.parse($scope.template.columns_origin);
        $scope.renameList = $scope.template.rename_yn
          ? (typeof $scope.template.columns_rename === 'object' && $scope.template.columns_rename)
            || JSON.parse($scope.template.columns_origin)
          : Object.assign([], $scope.selectedList);
        //$scope.template.rename_yn = $scope.template.rename_yn == 1;
      }
    }

    init();

    /**
     * 저장
     */
    $scope.submit = function () {
      if (!$scope.template.name) {
        $scope.nameCheck = false;

        return false;
      }

      if ($scope.deliveryExcelTemplateForm.$valid) {
        if ($scope.pageType === 'add') {
          insert();
        } else if ($scope.pageType === 'edit') {
          update();
        }
      }
    };

    /**
     * 양식 추가
     */
    function insert() {
      if ($scope.renameList.length === 0) {
        commonSVC.showMessage('엑셀 항목을 선택해주세요.');

        return false;
      }
      $scope.template.columns_origin = JSON.stringify($scope.selectedList);
      $scope.template.columns_rename = $scope.template.rename_yn ? JSON.stringify($scope.renameList) : '';

      commonSVC.showConfirm('등록하시겠습니까?', '', function () {
        deliveryModel.InsertDeliveryExcelTemplate($scope.template, function(state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', 'Excel 양식 등록 성공');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', 'Excel 양식 등록 실패');
          }
        });
      });
    }

    /**
     * 양식 수정
     */
    function update() {
      $scope.template.columns_origin = JSON.stringify($scope.selectedList);
      $scope.template.columns_rename = $scope.template.rename_yn ? JSON.stringify($scope.renameList) : '';

      commonSVC.showConfirm('수정하시겠습니까?', '', function () {
        deliveryModel.UpdateDeliveryExcelTemplate($scope.template, function(state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', 'Excel 양식 수정 성공');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', 'Excel 양식 수정 실패');
          }
        });
      });
    }

    /**
     * 선택항목에 추가
     */
    $scope.select = function(flag) {
      if (flag === 'all') {
        $scope.selectedList = angular.copy($scope.columnList);
        $scope.renameList = angular.copy($scope.columnList);
      } else {
        if ($scope.selectedColumns.length === 0) {
          return;
        }
        _.forEach($scope.selectedColumns, function(v) {
          const isDuplicated = _.findIndex($scope.selectedList, ['header', v]) !== -1; //중복 확인

          if (isDuplicated) {
            commonSVC.showMessage('이미 선택된 항목이 있습니다.');

            return false;
          } else {
            const idx = _.findIndex($scope.columnList, ['header', v]);

            $scope.selectedList.push(angular.copy($scope.columnList[idx]));
            $scope.renameList.push(angular.copy($scope.columnList[idx]));
          }
        });
      }
    };

    /**
     * 선택항목에서 제거
     */
    $scope.deselect = function(flag) {
      if (flag === 'all') {
        $scope.selectedList = angular.copy(defaultColumns);
        $scope.renameList = angular.copy(defaultColumns);
      } else {
        if ($scope.deselectedColumns.length === 0) {
          return;
        }
        _.forEach($scope.deselectedColumns, function(v) {
          // 필수값 검사
          if (v === '묶음번호' || v === '택배사' || v === '운송장번호') {
            commonSVC.showMessage('필수값입니다.');

            return false;
          }
          // 필드 삭제
          const idx = _.findIndex($scope.selectedList, ['header', v]);

          _.pullAt($scope.selectedList, idx);
          _.pullAt($scope.renameList, idx);
        });
      }
    };

    /**
     * 항목명 변경 (모달)
     */
    /*$scope.rename = function() {
      if($scope.renameColumn.length === 0) {
        commonSVC.showMessage("수정할 항목을 선택해주세요.");
      } else if($scope.renameColumn.length > 1) {
        commonSVC.showMessage("1개의 항목만 선택해주세요.");
      } else {
        var idx = _.findIndex($scope.renameList, ['key', $scope.renameColumn[0]]);
        var data = [{ data: $scope.renameList[idx].header }];

        var modal = commonSVC.openModal("", data, 'renameListCtrl', 'views/settings/delivery/renameList.html');
        modal.result.then(function (result){
          if(result.re === "success") {
            $scope.renameList[idx].header = result.newdata;
          } else {
            commonSVC.showToaster("error", "실패", "항목명 변경 실패");
          }
        });
      }
    };*/

    /**
     * 순서 변경
     */
    $scope.change_seq = function(calcul, position) {
      if ($scope.deselectedColumns.length === 0) {
        return;
      }

      const idx = _.findIndex($scope.selectedList, ['header', $scope.deselectedColumns[0]]);

      if (idx == 0 && calcul === 'up' || idx == $scope.selectedList.length - 1 && calcul === 'down') {
        return false;
      }

      const tmp = $scope.selectedList[idx];

      if (position === 'end') {
        _.pullAt($scope.selectedList, idx);
        _.pullAt($scope.renameList, idx);
        if (calcul === 'down') {
          $scope.selectedList.push(tmp);
          $scope.renameList.push(tmp);
        } else {
          $scope.selectedList.unshift(tmp);
          $scope.renameList.unshift(tmp);
        }
      } else {
        const sign = calcul === 'up' ? idx - 1 : idx + 1;

        $scope.selectedList[idx] = $scope.selectedList[sign];
        $scope.selectedList[sign] = tmp;
        $scope.renameList[idx] = $scope.renameList[sign];
        $scope.renameList[sign] = tmp;
      }
    };

    $scope.renameClick = function() {
      $scope.template.rename_yn = !$scope.template.rename_yn;
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // 2018-04-06 Daniel
    // 재고관리제외버전 추가
    stockExceptionSVC.scopeExcept('excel_template', $scope);
  });
