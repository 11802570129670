/**
 *  angular loader
 *  2016-06-10 권윤학
 */
(function (window, document) {
  'use strict';

  var isObject = angular.isObject,
    isFunction = angular.isFunction,
    isArray = angular.isArray,
    isString = angular.isString,
    forEach = angular.forEach,
    loadingClass = 'init-loading',
    errorClass = 'init-loading-error',
    bodyElement,
    $q;

  function addLoadingClass () {
    bodyElement.addClass(loadingClass);
  }

  function removeBodyClasses () {
    bodyElement.removeClass(loadingClass);
    bodyElement.removeClass(errorClass);
  }

  function addErrorClass () {
    removeBodyClasses();
    bodyElement.addClass(errorClass);
  }

  function isPromise (value) {
    return isObject(value) && isFunction(value.then);
  }

  function checkConfig (config) {
    if (!isObject(config)) {
      throw new Error('init은 반드시 object 형식으로 입력해주셔야합니다.');
    }
    if (!isString(config.module)) {
      throw new Error('\'config.module\' 모듈명은 string 타입으로 입력해주세요');
    }
    if (config.resolve && config.moduleResolves) {
      throw new Error('init의 \'resolve\' 혹은 \'moduleResolves\' 둘중 하나만 입력해주세요');
    }
    if (config.resolve) {
      if (!isObject(config.resolve)) {
        throw new Error('\'config.resolve\' 반드시 object여야 합니다.');
      }
    }
    if (config.bootstrapConfig) {
      if (!isObject(config.bootstrapConfig)) {
        throw new Error('\'config.bootstrapConfig\' 반드시 object여야 합니다..');
      }
    }
    if (config.moduleResolves) {
      if (!isArray(config.moduleResolves)) {
        throw new Error('\'config.moduleResolves\' 반드시 array여야 합니다.');
      }
    }

    forEach(config.moduleResolves, function (moduleResolve) {
      if (!moduleResolve.module) {
        throw new Error('A \'moduleResolve\' 안에  \'module\' 값이 있어야합니다.');
      }

      if (!isObject(moduleResolve.resolve)) {
        throw new Error('\'moduleResolve.resolve\' 반드시 object여야합니다.');
      }
    });

    if (angular.isDefined(config.onError) && !isFunction(config.onError)) {
      throw new Error('\'config.onError\'은 함수객체를 넘겨주세요.');
    }
  }
  function provideRootElement (modules, element) {
    element = angular.element(element);
    modules.unshift(['$provide', function($provide) {
      $provide.value('$rootElement', element);
    }]);
  }

  function createInjector (injectorModules, element) {
    var modules = ['ng'];
    if (isString(injectorModules)) {
      modules.push(injectorModules);
    } else if (isArray(injectorModules)) {
      modules = modules.concat(injectorModules);
    }
    provideRootElement(modules, element);
    return angular.injector(modules, element);
  }

  function doBootstrap (element, module, bootstrapConfig) {
    var deferred = $q.defer();

    angular.element(document).ready(function () {
      angular.bootstrap(element, [module], bootstrapConfig);
      removeBodyClasses();

      deferred.resolve(true);
    });

    return deferred.promise;
  }

  function init (configParam) {
    var config = configParam || {},
      element = config.element,
      module = config.module,
      injectorModules = config.injectorModules || [],
      injector,
      promises = [],
      constants = [],
      bootstrapConfig = config.bootstrapConfig;

    bodyElement = angular.element(document.body);

    addLoadingClass();
    checkConfig(config);
    injector = createInjector(injectorModules, element);
    $q = injector.get('$q');

    function callResolveFn (resolveFunction, constantName, moduleName) {
      var result;

      constants.push({
        name: constantName,
        moduleName: moduleName || module
      });

      if (!isFunction(resolveFunction) && !isArray(resolveFunction)) {
        throw new Error('Resolve \'' + constantName + '\' 형식이 잘못되었습니다.');
      }

      result = injector.instantiate(resolveFunction);

      if (isPromise(result)) {
        promises.push(result);
      } else {
        throw new Error('Resolve 함수 \'' + constantName + '\'의 리턴을 promise 객체로 변경해주세요.');
      }
    }

    function handleResults (results) {
      forEach(results, function (value, index) {
        var result = value && value.data ? value.data : value,
          moduleName = constants[index].moduleName,
          constantName = constants[index].name;

        angular.module(moduleName).constant(constantName, result);
      });

      return doBootstrap(element, module, bootstrapConfig);
    }

    function handleError (error) {
      addErrorClass();
      if (isFunction(config.onError)) {
        config.onError(error);
      }
    }

    if (config.moduleResolves) {
      forEach(config.moduleResolves, function (moduleResolve, index) {
        forEach(moduleResolve.resolve, function (resolveFunction, constantName) {
          callResolveFn(resolveFunction, constantName, config.moduleResolves[index].module);
        });
      });
    } else {
      forEach(config.resolve, function (resolveFunction, constantName) {
        callResolveFn(resolveFunction, constantName);
      });
    }

    return $q.all(promises).then(handleResults, handleError);
  }

  var angularInitLoader = {
    init: init
  };

  // if(typeof define === 'function' && define.amd) {
  //   define([], angularInitLoader);
  // } else if(typeof module === 'object' && module.exports) {
  //   module.exports = angularInitLoader;
  // } else {
    window.angularInitLoader = angularInitLoader;
  // }
})(window, document);