'use strict';

angular.module('gmpApp')
  .controller('CategoryMainCtrl', function ($scope, $state, localStorageService, commonSVC, errorSVC, categoryModel, shopAccountModel, $timeout, $rootScope, $compile) {

    $scope.cdata = {};
    $scope.data = [];
    $scope.ori_data = [];
    $scope.p_id = '';
    $scope.pattern = '';
    $scope.tick = 1;      // 카테고리 분류 순번
    $scope.categoryLoading = true;
    $scope.secondCategoryLoadingCnt = 50;

    $scope.viewData = [];						// 노출되는 카테고리 리스트

    let scrollLength = 0;
    let nodes = [];

    const CATEGORY_LOCK_INFO_KEY = `category_node_lock_info_${$scope.user_profile.m_no}`;
    const nodeLockInfo = localStorageService.get(CATEGORY_LOCK_INFO_KEY) || [];

    $scope.categoryEditName = '';

    // $scope.availableFields = ['title'];
    $scope.supportedFields = ['title', 'id'];

    // 하위 스코프에도 바인딩 적용하기 위해 여기에서 정의 실제 사용은 CategoryInfoDetailCtrl 컨트롤러 (settings/category/cateInfoDetail.js)
    $scope.categoryData = {};
    $scope.treeOpenYn = !localStorageService.get('collapseAll');
    $scope.placeholder = '카테고리 분류명으로 검색해 보세요.';
    $scope.noResults = '검색 결과가 없습니다.';
    $scope.changedNode = null;
    $scope.showHidden = false;
    $scope.lockChangeOrder = false;

    // 카테고리 분류 리스트 조회
    categoryModel.categoryListTree({ search_keyword: '' }, function (state, cateData) {
      if (state === 'error') {
        commonSVC.showToaster('error', '', '카테고리 조회에 실패 했습니다.');
      } else {
        // 이동잠금여부 세팅
        cateData.forEach(cate => {
          cate.lock = nodeLockInfo.includes(cate.id);
          if (cateData.children) {
            cateData.children.forEach(cateChild => {
              cateChild.lock = nodeLockInfo.includes(cateChild.id);
            });
          }
        });
        $scope.categoryData.parentList = cateData;
      }
    });

    //tree 옵션 설정
    $scope.treeOptions = {
      accept: function(sourceNodeScope, destNodesScope) {
        //분류 하위로 분류 들어가지못하게 제한
        if (sourceNodeScope.$modelValue.depth == 1 && destNodesScope.$parent.$element[0].firstElementChild.id != '') {
          return false;
        } else {
          return true;
        }
      },
      removed: function() {
      },
      toggle: function(e, data) {
        $scope.data[data.$index].imgflag = !$scope.data[data.$index].imgflag;
        $scope.data[data.$index].collapsed = !$scope.data[data.$index].collapsed;

        $scope.treeOpenYn = $scope.viewData.some(data => data.imgflag);
        setLeftHeight();

      },

      //카테고리 이동시 드랍되기전 이벤트
      beforeDrop: function(event) {
        // 이전 이동 작업이 끝나기 전에 이동 시 잔상이 남는 경우가 있어 조건 처리 추가
        if ($scope.lockChangeOrder) {
          return false;
        }
        // 아래 값들이 null 혹은 undefined 인 상태에서 데이터가 들어가서 생기는 오류를
        // 객체 선언을 통해서 해결함.
        // 2017-12-11 박현도
        // 2018-02-20 Daniel 미분류안생기도록 막음
        if (!event.dest.nodesScope.$nodeScope && event.source.nodeScope.$modelValue.depth != 1) {
          commonSVC.showMessage('분류 하위로만 이동가능합니다.');

          return false;

          // event.dest.nodesScope.$nodeScope = {};
          // event.dest.nodesScope.$nodeScope.$modelValue = event.source.nodeScope.$modelValue;
        }

        // if(event.source.nodeScope.$modelValue.depth != 1) { //분류 이동은 체크하지 않는다
        if (event.dest.nodesScope.$nodeScope && event.source.nodeScope.$modelValue.parents_id != event.dest.nodesScope.$nodeScope.$modelValue.id) { //동일분류내의 카테고리 이동이 아닐시
          if (!event.dest.nodesScope.$parent.$modelValue) {
            event.dest.nodesScope.$parent.$modelValue = event.source.nodeScope.$modelValue;
          }
          if (event.dest.nodesScope.$parent.$modelValue.nodes) {
            // 값이 null 혹은 undefined 가 아닌 경우는 분류 하위에 기존 카테고리 들이 존재하는 경우임.
            // 이 때, 분류 하위에 기존 카테고리들을 검사해서 새로 투입되는 카테고리와 중복검사를 실시함.
            // 2017-12-11 박현도
            _.forEach(event.dest.nodesScope.$parent.$modelValue.nodes, function(category) {
              if (category.title === event.source.nodeScope.$modelValue.title) {
                commonSVC.showToaster('error', '', '중복된 이름입니다.');
                // 중복되는 값 발견 시 카테고리 새로고침.
                $scope.loadCategory();
              }
            });
          }
        }

      },

      //카테고리 이동 시 드랍됬을때 백엔드랑 통신
      dropped: function(event) {
        if (event.dest.nodesScope.$nodeScope && event.source.nodeScope.$modelValue.parents_id != event.dest.nodesScope.$nodeScope.$modelValue.id) { //동일분류내의 카테고리 이동이 아닐시
          // 위에 beforeDrop 에서 빈 분류 혹은 미분류 값에 데이터 할당이 이뤄졌으므로
          // dropped 내부에서는 null 값 인지 검사가 필요 없음.
          // 들어갈 분류 내 기존 카테고리와의 중복검사만 실시함.
          // 2017-12-11 박현도
          _.forEach(event.dest.nodesScope.$parent.$modelValue.nodes, function(category) {
            if (category.title === event.source.nodeScope.$modelValue.title) {
              commonSVC.showToaster('error', '', '중복된 이름입니다.');
              $scope.loadCategory();
            }
          });
        }

        // //클릭시 이벤트 발생되는거처럼
        $timeout(function () {
          changeCateOrder(event);
        }, 100);
      }
    };

    // 카테고리 오픈
    $scope.open = (node) => {
      const id = node.id, // 카테고리 id
            parents_id = node.parents_id, // 분류 id
            title = encodeURIComponent(encodeURIComponent(node.title)), // 카테고리명 인코딩
            divisionName = node.parents_title || $(`#${node.parents_id}`).attr('name'), // 분류명
            parents_name = encodeURIComponent(encodeURIComponent(divisionName)), // 분류명 인코딩
            hscode = node.hscode, // hscode
            bookmark = node.bookmark, // 즐겨찾기
            p_cate_cd = node.p_cate_cd; // 표준카테고리코드

      // 2018-02-23 Daniel 열려있는 카테고리 분류아이디
      $scope.p_id = String(parents_id);

      if (node.depth == 2) {
        // 선택된 카테고리 표시
        if ($('#defaultView').css('display') == 'none') {
          $('.nontree-active').removeClass('tree-active');
        }

        $('.tree-active').removeClass('tree-active');
        $(`#${node.id}`).addClass('tree-active');

        // view변경
        $('#detailView').css('display', 'block');
        $('#defaultView').css('display', 'none');
        // $stateParam으로 data를 넘기면서 변경
        $state.go('main.settings_category.categoryDetailPanel', { id, parents_id, title, parents_name, hscode, bookmark, p_cate_cd });
      } else {
        return false;
      }
    };

    // 분류 추가
    $scope.divisionView = function() {
      const templatePath = 'views/settings/category/modals/categoryClassAdd.html',
            resolve = {
            };
      const modal = commonSVC.openModal('md', resolve, 'CategoryClassAddCtrl', templatePath, false, true, false);

      modal.result.then(function() {
        $timeout(function () {
          $scope.loadCategory();
        }, 500);
      });
    };

    // 카테고리 추가
    $scope.categoryView = async (id) => {
      const params = {
        search_keyword: $scope.pattern || '',
      };

      const { data: { results: oriData } } = await categoryModel.categoryListTree(params);
      const templatePath = 'views/settings/category/modals/categoryAdd.html',
            resolve = {
              data: {
                list: oriData,
                id: id || ''
              }
            };
      const modal = commonSVC.openModal('lg', resolve, 'CategoryAddCtrl', templatePath, false, true, false);

      modal.result.then(function(node) {
        if (node) {
          $timeout(function () {
            $scope.loadCategory(node.id);
            if (node.state === 'success') {
              $scope.open(node);
            }
          }, 300);
        }
      });
    };

    // 카테고리 일괄수정
    $scope.cateApplyShopId = function() {
      const templatePath = 'views/settings/category/modals/cateApplyShopId.html';

      const modal = commonSVC.openModal('lg', {}, 'CateApplyShopIdCtrl', templatePath, false, true);

      modal.result.then(function(node) {
        $scope.loadCategory(node.id);
        if (node.state === 'success') {
          $scope.open(node);
        }
      });
    };

    // 버튼 항상 노출되도록 처리됨으로 인해 해당부분 주석 2018-01-15 rony
    // 이동버튼 오버시 노출 2018-02-19 Daniel

    //리스트에 마우스 오버시 버튼 보여주기
    // $scope.mouseOver = function(node) {
    //   $(`#Button_${node.id}`).css('display', 'inline-block');
    // };

    //리스트에 마우스 리브시 버튼 가려주기
    // $scope.mouseLeave = function(node) {
    //   $(`#Button_${node.id}`).css('display', 'none');
    // };

    /**
     * 카테고리/분류 삭제
     */
    $scope.delete = async (code, type, event) => {
      let textType, warningTxt;

      if (event) {
        event.stopPropagation();
      }
      $('.dropdown-menu').hide(100);

      if (type === 1) {
        textType = '분류';
        warningTxt = '분류 삭제 시, 분류에 포함된 카테고리까지 전부 삭제됩니다.';
      } else {
        textType = '카테고리';
        warningTxt = '카테고리에 설정된 모든 정보가 삭제됩니다.';
      }

      // 삭제시 온라인상품관리 상태가 판매중지, 반려인상태를 제외하고 삭제불가 처리
      commonSVC.showConfirm(`${textType} 삭제`, `${warningTxt} \n삭제된 카테고리 정보는 복구되지 않으므로 주의바랍니다.`, function (confirm) {
        if (confirm) {
          const sendData = {
            id: code,
            depth: type
          };

          categoryModel.categoryDel(sendData, function (state, data) {
            if (state == 'error') {
              let errMsg = errorSVC.getError('prod', data.data.error);

              if (!errMsg) {
                errMsg = `알 수 없는 이유로 ${textType} 삭제에 실패했습니다. 삭제에 실패한 ${textType} 이름과 함께 본사에 문의해주시기 바랍니다.`;
              }

              commonSVC.showToaster('error', '실패', errMsg);
            } else {
              $scope.$parent.data = [];
              commonSVC.showToaster('success', '성공', `${textType} 삭제에 성공하였습니다.`);
              $('#detailView').css('display', 'none');
              $('#defaultView').css('display', 'block');
              $timeout(function() {
                $scope.loadCategory();
              }, 300);
            }
          });
        }
      });
    };

    /**
     * 이동 잠그기/열기
     */
    $scope.lockDrag = (node, event) => {
      if (event) {
        event.stopPropagation();
      }
      $('.dropdown-menu').hide(100);

      node.lock = !node.lock;

      if (node.lock) {
        nodeLockInfo.push(parseInt(node.id));
      } else if (nodeLockInfo.includes(parseInt(node.id))) {
        nodeLockInfo.splice(nodeLockInfo.findIndex(id => id === parseInt(node.id)), 1);
      }

      // 로컬스토리지 업데이트
      localStorageService.set(CATEGORY_LOCK_INFO_KEY, nodeLockInfo);
    };

    /**
     * 이름 바꾸기
     */
    $scope.updateTitle = (cate, event) => {
      event.stopPropagation();
      $('.dropdown-menu').hide(100);

      cate.isTitleEdit = true;
      $timeout(() => {
        document.getElementsByClassName('cateTitleInput')[0].focus();
      });
    };

    /**
     * 카테고리/분류 추가
     */
    $scope.addCategory = _.throttle(async (id, depth, event) => {
      try {
        if (event) {
          event.stopPropagation();
        }
        $('.dropdown-menu').hide(100);

        const res = await categoryModel.categoryAdd([{ parents_id: id, name: '' }]);

        if (depth === 2) {
          const node = {
            id: String(res.data.results.id),
            depth,
            list_title: $scope.pattern ? make_search_highlight($scope.pattern, res.data.results.sol_cate_name) : res.data.results.sol_cate_name,
            title: res.data.results.sol_cate_name,
            parents_id: String(res.data.results.pa_sol_cate_no),
            hscode: res.data.results.hscode,
            bookmark: 0
          };

          const idx = $scope.viewData.findIndex(pa_node => pa_node.id === node.parents_id);

          $scope.viewData[idx].nodes.unshift(node);

          $timeout(() => {
            $(`#${node.id}`).addClass('tree-active');
          }, 300);

          $scope.open(node);
        } else {
          $timeout(() => {
            $scope.loadCategory();
          }, 200);
        }

        return false;
      } catch (err) {
        commonSVC.showToaster('error', '실패', `카테고리 추가 실패 (${err.message || err.data.error})`);

        return false;
      }
    }, 800, { trailing: false });

    /**
     * 카테고리 복사
     */
    $scope.categoryCopy = (id, depth) => {
      $('.dropdown-menu').hide(100);

      categoryModel.categoryCopy({ sol_cate_no: id, depth })
        .then(result => {
          if (result.status === 200) {
            commonSVC.showToaster('success', '', '카테고리 복사되었습니다.');
            $timeout(function() {
              $scope.loadCategory();
            }, 300);
          } else {
            commonSVC.showToaster('error', '', '카테고리 복사에 실패했습니다.');
          }
        })
        .catch(function() {
          commonSVC.showToaster('error', '', '카테고리 복사에 실패했습니다.');
        });
    };

    //모두닫기
    $scope.collapseAll = function() {
      $scope.data.forEach(function(data) {
        data.imgflag = false;
        data.collapsed = true;
      });

      $scope.$broadcast('angular-ui-tree:collapse-all');
      $scope.treeOpenYn = !$scope.treeOpenYn;
      localStorageService.set('collapseAll', true);
    };

    //모두열기
    $scope.expandAll = function() {
      $scope.data.forEach(function(data) {
        data.imgflag = true;
        data.collapsed = false;
      });

      $scope.$broadcast('angular-ui-tree:expand-all');
      $scope.treeOpenYn = !$scope.treeOpenYn;
      localStorageService.set('collapseAll', false);
    };

    //카테고리명 검색
    $scope.searchDo = function () {
      $scope.pattern = $('#searchCate').val();
      $scope.viewData = [];
      nodes = [];

      $scope.tick = 1;
      $scope.loadCategory();
      setLeftHeight();
    };

    //카테고리명 검색 초기화
    $scope.refresh = function() {
      $('#searchCate').val('');
      $scope.searchDo();
    };

    /**
     * 엑셀 업로드 모달창
     */
    $scope.excelUpload = () => {
      commonSVC.openModal('lg', {}, 'CategoryExcelUploadCtrl', 'views/settings/category/modals/excel_upload.html');
    };

    // 카테고리 엑셀다운 필드
    const excelFieldList = [
      { header: '카테고리코드', key: 'sol_cate_no', select: true },
      { header: '분류', key: 'pa_sol_cate_name', select: true },
      { header: '카테고리명', key: 'sol_cate_name', select: true },
    ];

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = async (type) => {
      let count = 0;
      const cate_id = [],
            params = {
              search_keyword: $scope.pattern || ''
            },
            cats = [];

      await categoryModel.categoryListTree(params, async (state, data) => {
        if (state == 'error') {
          commonSVC.showToaster('error', '', '카테고리 조회에 실패 했습니다.');
        } else {
          for (let i = 0; i < data.length; i++) {
            if (!data[i].parents_id) {
              cats.push({
                id: String(data[i].id),
                depth: '2',
                list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].name) : data[i].name,
                title: data[i].name,
                parents_id: data[i].parents_id,
                hscode: data[i].hscode,
                bookmark: data[i].bookmark,
                lock: data[i].lock,
                oriTitle: data[i].name,
                collapsed: !$scope.treeOpenYn
              });
            } else {
              cats.push({
                name: `${i}_1depth`,
                id: String(data[i].id),
                depth: '1',
                list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].name) : data[i].name,
                title: data[i].name,
                nodes: [],
                imgflag: $scope.treeOpenYn,
                parents_id: data[i].parents_id,
                p_cate_cd: data[i].p_cate_cd,
                lock: data[i].lock,
                oriTitle: data[i].name,
                collapsed: !$scope.treeOpenYn
              });
              if (data[i].children[0] != null || undefined) {
                //파일 오픈 이미지 변경
                cats[i].imgflag = $scope.treeOpenYn;
                for (let j = 0; j < data[i].children.length; j++) {
                  cats[i].nodes.push({
                    id: String(data[i].children[j].id),
                    depth: '2',
                    list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].children[j].name) : data[i].children[j].name,
                    title: data[i].children[j].name,
                    parents_id: data[i].children[j].parents_id,
                    hscode: data[i].children[j].hscode,
                    bookmark: data[i].children[j].bookmark,
                    p_cate_cd: data[i].children[j].p_cate_cd,
                    lock: data[i].children[j].lock,
                    oriTitle: data[i].children[j].name
                  });
                }
              }
            }
          } // end for

          if ($scope.user_profile.sol_no !== 3436) {
          // 분류를 뺀 카테고리 개수만 셈
            _.each(cats, function(cate) {
              cate_id.push(cate.id);
              if (cate.depth === '1') {
                count = count + cate.nodes.length;
                _.forEach(cate.nodes, (sub_cate) => {
                  cate_id.push(sub_cate.id);
                });
              } // 분류안의 카테고리 개수
              else {
                count = count + 1;
              }
            });
          } else {
            _.each(cats, function(cate, idx) {
              cate_id.push(cate.id);
              if (cate.depth === '1') {
                count = count + nodes[idx].length;
                _.forEach(nodes[idx], (sub_cate) => {
                  cate_id.push(sub_cate.id);
                });
              } // 분류안의 카테고리 개수
              else {
                count = count + 1;
              }
            });
          }

          const resolve = {};

          resolve.data = {
            type: type,
            cateId: cate_id,
            excelFieldList: angular.copy(excelFieldList),
            title: '카테고리',
            url: '/app/product/category/excel-down',
            count: count,
            isAll: true
          };

          const modal = commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

          modal.result.then(function () {
          });
        }
      });
    };

    $scope.$on('categoryDel', function() {
      $scope.p_id = '';
    });

    // 2020-07-23 Boris
    // 검색한 단어부분만 하이라이트 하는 처리
    // 소문자 대문자도 전부 검색하기 위해 따로 생성
    const make_search_highlight = (pattern, text) => {
      const plen = pattern.length;
      let rt_str = '';
      let idx = 0;

      if (plen > text.length) {
        return text;
      }

      while (idx < text.length) {
        const substr_text = text.substr(idx, plen);

        if (idx > text.length - plen) {
          rt_str += text.substr(idx);

          break;
        }

        if (substr_text.toLowerCase() === pattern.toLowerCase()) {
          rt_str += `<span class="text-bold text-pink">${substr_text}</span>`;
          idx += plen;
        } else {
          rt_str += text[idx];
          idx += 1;
        }
      }

      return rt_str;
    };

    /***
     * 정보 불러오기
     * */
    $scope.loadCategory = async (id) => {
      const cats = [];
      const params = {
        search_keyword: $scope.pattern || '',
        ...(!$scope.pattern && { hide_yn: $scope.showHidden })
      };

      $scope.viewData = [];

      await new Promise((resolve) => {
        categoryModel.categoryListTree(params, async (state, data) => {
          // 이동잠금여부 세팅
          data.forEach(cate => {
            cate.lock = nodeLockInfo.includes(cate.id);
            if (cate.children) {
              cate.children.forEach(cateChild => {
                cateChild.lock = nodeLockInfo.includes(cateChild.id);
              });
            }
          });

          // 검색어로 일부 데이터만 나오는경우 분류리스트 업데이트X
          if (!params.search_keyword) {
            $scope.categoryData.parentList = data;
          }

          $scope.ori_data = data;
          $scope.data = [];
          if (state == 'error') {
            commonSVC.showToaster('error', '', '카테고리 조회에 실패 했습니다.');

            resolve(false);
          } else {
            // $scope.treedata = data;
            for (let i = 0; i < data.length; i++) {
              if (!data[i].parents_id) {
                cats.push({
                  id: String(data[i].id),
                  // 'id': "category_"+ i,
                  depth: '2',
                  list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].name) : data[i].name,
                  title: data[i].name,
                  parents_id: data[i].parents_id,
                  hscode: data[i].hscode,
                  bookmark: data[i].bookmark,
                  lock: data[i].lock,
                  oriTitle: data[i].name,
                  collapsed: !$scope.treeOpenYn
                });
              } else {
                cats.push({
                  name: `${i}_1depth`,
                  id: String(data[i].id),
                  depth: '1',
                  list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].name) : data[i].name,
                  title: data[i].name,
                  nodes: [],
                  imgflag: $scope.treeOpenYn,
                  parents_id: data[i].parents_id,
                  p_cate_cd: data[i].p_cate_cd,
                  lock: data[i].lock,
                  oriTitle: data[i].name,
                  collapsed: !$scope.treeOpenYn,
                  hide_yn: data[i].hide_yn
                });
                if (data[i].children[0] != null || undefined) {
                  //파일 오픈 이미지 변경
                  cats[i].imgflag = $scope.treeOpenYn;
                  for (let j = 0; j < data[i].children.length; j++) {
                    cats[i].nodes.push({
                      // 'id': i + "_2depth_"+j,
                      // 'id': data[i].id + "_2depth_"+j,
                      id: String(data[i].children[j].id),
                      depth: '2',
                      list_title: $scope.pattern ? make_search_highlight($scope.pattern, data[i].children[j].name) : data[i].children[j].name,
                      title: data[i].children[j].name,
                      parents_id: data[i].children[j].parents_id,
                      hscode: data[i].children[j].hscode,
                      bookmark: data[i].children[j].bookmark,
                      p_cate_cd: data[i].children[j].p_cate_cd,
                      lock: data[i].children[j].lock,
                      oriTitle: data[i].children[j].name
                    });
                  }
                }
              }
            } // end for

            // 배열에 다 넣고 한번에 $scope 에 연결 - matthew 튜닝 (앵귤러 이벤트 발생 최소화)
            $scope.data = cats;

            //카테고리 조회후 뷰 데이터 등록
            await $scope.loadMore();

            /**
             * 2020-06-04 Lucas
             * 특정 업체 뷰 데이터 처리
             */
            if ($scope.user_profile.sol_no === 3436) {
              $scope.viewData = $scope.data;

              for (const data of $scope.viewData) {
                data.imgflag = false;
                nodes.push(data.nodes);
                delete data.nodes;
              }
            }

            $scope.getNodes = (e) => {
              if ($scope.user_profile.sol_no !== 3436) {
                return;
              }
              const findKey = e.target.innerText.split('\n')[0].trim();

              for (const idx in $scope.viewData) {
                if ($scope.viewData[idx].title === findKey) {
                  const Item = $(`ul.tree-outbox.angular-ui-tree-node:eq(${idx}) ol`);

                  $scope.viewData[idx].nodes = nodes[idx];
                  $scope.viewData[idx].imgflag = !$scope.viewData[idx].imgflag;
                  if (Item.hasClass('hidden')) {
                    Item.removeClass('hidden');
                  } else {
                    Item.addClass('hidden');
                  }
                }
              }
            };

            /**
             * 2018-02-19 Daniel
             * 카테고리 추가후 바로 선택될수있도록
             */
            if (id) {
              $timeout(function () {
                $(`#${id}`).addClass('tree-active');
              }, 100);
            }

            /**
             * 2018-02-26 Daniel
             * 카테고리 삭제시 p_id 초기화
             */
            if ($('#defaultView').css('display') === 'block') {
              $scope.p_id = '';
            }

            $('.placeholder-bg').hide();

            resolve(true);
          }
        });
      });
    };

    /**
     * 2차 분류 더보기
     */
    $scope.loadSubCategoryMore = async (id) => {
      const subCate = $scope.viewData.find(subCate => id === subCate.id);

      subCate.nodes = subCate.nodes.concat(subCate.originNodes.splice($scope.secondCategoryLoadingCnt, $scope.secondCategoryLoadingCnt));
    };

    // 스크롤 로딩
    $scope.loadMore = async () => {
      // 뷰티덕스 업체는 무한 스크롤 방식이 아닌, 처음에 다불러오는 처리를 합니다.
      if ($scope.user_profile.sol_no === 3436) {
        return;
      }

      if ($scope.viewData.length < $scope.data.length) {
        const addCate = _.slice($scope.data, $scope.viewData.length, $scope.viewData.length + 10);

        addCate.forEach(cate => {
          cate.imgflag = $scope.treeOpenYn;
          cate.collapsed = !$scope.treeOpenYn;

          // 2차분류 더보기 처리위해 추가
          cate.originNodes = cate.originNodes || cate.nodes;
          cate.nodes = cate.nodes?.length ? cate.nodes.slice(0, $scope.secondCategoryLoadingCnt) : [];
          cate.viewCnt = cate.nodes.length;
        });
        $scope.viewData = $scope.viewData.concat(addCate);

        // 순차 로딩
        const updateTick = async () => {
          if ($scope.viewData[$scope.tick - 1] && !$scope.viewData[$scope.tick - 1].tick) {
            $scope.viewData[$scope.tick - 1].tick = 0;
          }

          if ($scope.viewData[$scope.tick - 1] && $scope.viewData[$scope.tick - 1].tick < $scope.viewData[$scope.tick - 1].nodes.length) {
            $scope.viewData[$scope.tick - 1].tick += 50;

            await requestAnimationFrame(updateTick);
          } else if ($scope.tick < $scope.viewData.length) {
            $scope.tick += 1;

            await requestAnimationFrame(updateTick);
          }

          $scope.$digest();
        };

        await requestAnimationFrame(updateTick);
      }
    };

    $scope.loadCategory();

    // 레프트 영역 자동 높이 조절
    const setLeftHeight = () => {
      $timeout(function () {
        const sideHeight = window.innerHeight - $('#navbarHeight').outerHeight() - $('#category-content1').outerHeight() - $('#category-content2').outerHeight() - $('#carouselBanner').outerHeight();

        // 스크롤 해서 추가 생성되는 부분 량 (초기 로딩 시 1페이지 플러스 각 분류와 하위카테고리 합계가 10개 인 사이즈)
        scrollLength = parseInt(sideHeight / 40) + 10;

        $('#listSidebar').css('height', sideHeight - 38);
      });
    };

    // 최초 로딩 시 길이 계산을 위해 추가
    setLeftHeight();

    $(window).on('resize', function () {
      setLeftHeight();
    });

    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.settings_category') {
        window.dispatchEvent(new Event('resize'));
      }
    });

    /**
     * 우클릭 메뉴
     */
    $(document).on('contextmenu', event => {
      if ($(event.target).parents('.sidebar-category').length && !$(event.target).parents('.dropdown-menu').length) {
        event.preventDefault();
        const el = $(`#cateDropdown${$(event.target).attr('id') || $(event.target).parent().attr('id')}`).finish().toggle(100),
              parent = $(event.target).parents('.sidebar-category').eq(0);
        $timeout(() => {
          let { offsetX, offsetY } = event;
          const { x: targetX, y: targetY } = event.target.getBoundingClientRect();

          if (!$(event.target).hasClass('tree-node-content')) {
            offsetX += targetX - event.target.parentElement.getBoundingClientRect().x;
            offsetY += targetY - event.target.parentElement.getBoundingClientRect().y;
          }

          const top = targetY + el.height() > parent.height() ? offsetY - (targetY + el.height() - parent.height()) : offsetY,
                left = offsetX + el.width() > parent.width() ? parent.width() - el.width() : offsetX;

          el.css({ position: 'absolute', top: `${top}px`, left: `${left}px` });
        });
      }
    });

    $(document).on('mousedown', event => {
      if (!($(event.target).parents('.tree-node .dropdown-menu').length || $(event.target).parents('#cateDropdownSub').length)) {
        $('.tree-node .dropdown-menu').hide(100);
        $('#cateDropdownSub').hide(100);

        $scope.changedNode = null;
      }
    });

    // 카테고리 이름 변경
    $scope.editCategoryName = (index, event) => {
      // 트리 열림 닫힘 방지
      event.stopPropagation();

      $(`#categoryNameText${index}`).addClass('hide');
      $(`#categoryTitleEditInput${index}`).removeClass('hide');

    };

    /**
      * 카테고리 이름 변경 저장
      */
    $scope.saveEdit = cate => {
      if (!cate.title) {
        commonSVC.showMessage('실패', '카테고리 이름을 입력해주세요.');

        return false;
      }
      if (cate.oriTitle === cate.title) {
        cate.isTitleEdit = false;

        return false;
      }
      categoryModel.categoryNameUpdate({ categoryId: cate.id, reviseName: cate.title, depth: 1 }, (state, data) => {
        if (state == 'error') {
          let errMsg = errorSVC.getError('prod', data.data.error);

          if (!errMsg) {
            errMsg = '이름 변경에 실패하였습니다.';
          }

          commonSVC.showToaster('error', '', errMsg);
        } else {
          commonSVC.showToaster('success', '성공', '이름 변경에 성공하였습니다.');
          $timeout(() => {
            $scope.loadCategory();
            $rootScope.$broadcast('cateNameChange', { newTitle: cate.title });
          }, 200);
          cate.isTitleEdit = false;
        }
      });
    };

    /**
     * 북마크
     */
    $scope.bookmark = function (node, event) {
      event.stopPropagation();
      $('.dropdown-menu').hide(100);

      const param = {
        id: node.id,
        bookmark: !node.bookmark
      };

      categoryModel.CategorybookmarkEdit(param, function (state) {
        if (state != 'success') {
          commonSVC.showToaster('error', '실패', `즐겨찾기 ${param.bookmark ? '등록' : '해제'}에 실패하였습니다.`);
        } else {
          commonSVC.showToaster('success', '성공', `즐겨찾기 ${param.bookmark ? '등록' : '해제'}에 성공하였습니다.`);

          $timeout(() => {
            $scope.loadCategory();
          }, 300);
        }
      });
    };

    $scope.loadCategoryMore = async () => {
      await $scope.loadMore();
      $scope.treeOpenYn = $scope.viewData.some(data => data.imgflag);

      $timeout(() => {});
    };

    $scope.openSearchCate = (node, event) => {
      event.stopPropagation();
      $scope.changedNode = node;

      const el = $('#cateDropdownSub').finish(), parent = $(event.target).parents(`#cateDropdown${node.id}`);

      $timeout(() => {
        el.css({ display: 'block', position: 'fixed', top: `${event.target.parentElement.getBoundingClientRect().y - ($('#cateDropdownSub').children('.select2').children('.selection').hasClass('hidden') ? 10 : 35)}px`, left: `${event.target.parentElement.getBoundingClientRect().x + parent[0].offsetWidth}px` });
        $('#cateDropdownSubSelect').select2('open');
        $('#cateDropdownSub').children('.select2').children('.selection').addClass('hidden');
        $('input.select2-search__field').prop('placeholder', '카테고리 분류명으로 검색해 보세요.');
      }, 200);

    };

    /**
     * 카테고리 분류 변경
     */
    $scope.changeParentCate = (p_id) => {
      if (p_id) {
        if (String($scope.changedNode.parents_id) === p_id) {
          commonSVC.showMessage('기존 분류로는 이동 불가능합니다.');

          return false;
        }
        const targetNode = angular.copy($scope.changedNode);
        const sourceNode = ($scope.data.find(cate => cate.id === String(targetNode.parents_id)));
        sourceNode.nodes = sourceNode.nodes.filter(node => node.id !== targetNode.id);
        const destNode = ($scope.data.find(cate => cate.id === String(p_id)));
        targetNode.parents_id = Number(p_id);
        destNode.nodes = [targetNode, ...destNode.nodes];

        changeCateOrder();
        $('#cateDropdownSub').hide(100);
        $scope.changedNode = null;
        $scope.open(targetNode);
      }
    };

    /**
     * 카테고리 분류 숨김 / 숨김 취소
     */
    $scope.hideCategory = async (pa_id, hide_yn, event) => {
      event.stopPropagation();
      $('.dropdown-menu').hide(100);

      // 카테고리 선택 후 숨김 처리 시 분류가 변경되어 선택 해제
      // view변경
      $('#detailView').css('display', 'none');
      $('#defaultView').css('display', 'block');
      // $stateParam으로 data를 넘기면서 변경
      $state.go('main.settings_category.categoryDetailPanel', { id: null });
      try {
        await categoryModel.hideCategory({ pa_id, hide_yn });
        commonSVC.showToaster('success', '성공', `카테고리 ${hide_yn ? '숨김' : '숨김 취소'} 성공했습니다.`);
        $scope.loadCategory();
      } catch (err) {
        commonSVC.showToaster('error', '실패', `카테고리 ${hide_yn ? '숨김' : '숨김 취소'} 실패했습니다.`);
      }
    };

    /**
     * 숨긴 분류 출력
     */
    $scope.changeShowHidden = () => {
      $scope.showHidden = !$scope.showHidden;

      // 카테고리 선택 후 출력 유형 변경 시 분류가 변경되어 선택 해제
      $('#detailView').css('display', 'none');
      $('#defaultView').css('display', 'block');
      // $stateParam으로 data를 넘기면서 변경
      $state.go('main.settings_category.categoryDetailPanel', { id: null });
      $scope.loadCategory();
    };

    const changeCateOrder = (event) => {
      if (event) {
        $scope.lockChangeOrder = true;
      }
      const updata = [];
      const cateData = angular.copy($scope.data).filter((cate, index, list) => index === list.findIndex(l => l.id === cate.id));

      //분류 업데이트
      _.each(cateData, function (v, k) {
        v.rno = k + 1;
        const param = {
          rno: v.rno,
          id: v.id,
          parents_id: v.parents_id
        };

        updata.push(param);

        v.nodes = v.nodes.filter((cate, index) => index === v.nodes.findIndex(l => l.id === cate.id));

        //카테고리 업데이트
        _.each(v.nodes, function (v2, k2) {
          v2.rno = k2 + 1001;
          const param = {
            rno: v2.rno,
            id: v2.id,
            parents_id: v.id
          };

          updata.push(param);
        });

        v.name = `${k}_1depth`;
      });

      $scope.data = cateData;
      $scope.viewData = _.slice($scope.data, 0, $scope.viewData.length);
      $timeout(() => {}); // 위치 이동 후 잔상이 남는 경우가 있어 timeout 처리 추가

      categoryModel.categoryOrder(updata, function (state, data) {
        $scope.lockChangeOrder = false;
        if (state == 'error') {
          if (data.data.error) {
            commonSVC.showToaster('error', '', '중복된 이름입니다.');
          } else {
            commonSVC.showToaster('error', '', '카테고리 이동에 실패하였습니다.');
          }
          $timeout(function () {
            $scope.loadCategory();
          }, 500);
        } else {
          const param = {
            id: event.source.nodeScope.$modelValue.id,
            parents_id: event.dest.nodesScope.$parent.$modelValue?.id,
            parents_name: event.dest.nodesScope.$parent.$modelValue?.title,
            title: event.source.nodeScope.$modelValue.title,
            hscode: event.source.nodeScope.$modelValue.hscode,
            bookmark: event.source.nodeScope.$modelValue.bookmark,
            p_cate_cd: event.source.nodeScope.$modelValue.p_cate_cd,
          };

          if (param.parents_id) {
            $state.go('main.settings_category.categoryDetailPanel', { id: param.id, parents_id: param.parents_id, title: param.title, parents_name: param.parents_name, hscode: param.hscode, bookmark: param.bookmark, p_cate_cd: param.p_cate_cd });
          }

          // view변경
          $('#detailView').css('display', 'block');
          $('#defaultView').css('display', 'none');
        }
      });
    };
  });

