'use strict';

angular.module('gmpApp')
  .controller('CalculateCollectCtrl', function ($scope, $rootScope, $uibModalInstance, commonSVC, workSVC, errorSVC, $q, $timeout) {

    //------------------------
    // 변수 설정
    //------------------------
    // 사용중인 쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'ScrapPayBack' });

    $scope.selectedAll = false; // 전체체크
    // 채널 리스트
    $scope.channel_list = channelList.filter(o => o.shop_type !== 'ORD_M');

    // 마지막 선택값 기억.2019-04-08 rony
    $scope.saveMalls = JSON.parse(localStorage.getItem('scrapCalculateMalls'));
    if (!$scope.saveMalls) {
      $scope.saveMalls = {
        use_yn: false,
        malls: []
      };
    }

    let mastchMalls;

    _.each($scope.channel_list, function(m) {
      mastchMalls = [];
      if ($scope.saveMalls && $scope.saveMalls.use_yn) {
        mastchMalls = _.filter($scope.saveMalls.malls, function(o) {
          return o.shop_cd === m.shop_cd && o.shop_id === m.shop_id;
        });
      }

      if (mastchMalls.length) {
        m.selected = mastchMalls[0].checked;
      } else {
        m.selected = true;
      }
    });

    // 전체사이트 여부 체크.
    const unSelectedOrder = _.filter($scope.channel_list, function(o) {
      return o.selected === false;
    });

    $scope.selectedAll = !unSelectedOrder.length;

    // $scope.calculateDisabled = false;

    //------------------------
    // 함수
    //------------------------
    /**
     * 취소
     */
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 정산 수집 버튼 클릭 시
     */
    $scope.calculateCollect = function() {

      const idx = _.findIndex($scope.channel_list, { selected: true });

      if (idx < 0) {
        // $scope.calculateDisabled = false;
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };
      const promiseArr = [];

      $scope.saveMalls.malls = [];
      angular.forEach($scope.channel_list, function(v) {
        if (v.selected == true) {
          // 작업 추가
          const params = {
            site_code: v.shop_cd,
            site_id: v.shop_id
          };
          const anHttpPromise = workSVC.addWork('ScrapPayBack', params)
            .then(function(result) {
              if (result.status === 200) {
                reCnt.success++;
              } else {
                reCnt.error++;
                reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
                reCnt.errReason.push(result.data.error || '');
              }

              return result;
            })
            .catch(function(err) {
              reCnt.error++;
              reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
              reCnt.errReason.push('');

              return err;
            });

          promiseArr.push(anHttpPromise);
        }

        $scope.saveMalls.malls.push({ shop_cd: v.shop_cd, shop_id: v.shop_id, checked: v.selected });
      });
      // 선택 사이트 정보 로컬스토리지 저장.
      localStorage.setItem('scrapCalculateMalls', JSON.stringify($scope.saveMalls));

      $q.all(promiseArr)
        .then(async re => {
          if (!reCnt.success) {
            // $scope.calculateDisabled = false;

            throw re;
          } else {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
            $scope.cancel();
          }
        })
        .catch(function(err) {
          // $scope.calculateDisabled = false;
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
          $timeout(function () { $scope.waitBtnDisableFlag = false; });
        });
    };

    /**
     * 체크박스 선택시
     */
    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.channel_list, function (list) {
          list.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.channel_list[type].selected)) {
          $scope.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.channel_list) {
            if (!$scope.channel_list[i].selected) {
              flag = false;

              return;
            }
          }
          $scope.selectedAll = flag;
        }
      }
    };

  });
