'use strict';

angular.module('gmpApp')
  .controller('EtcDownExcelCtrl', function ($scope, data, $uibModalInstance, commonSVC, gettext, gettextCatalog, $log) {
    $scope.count_type = true;
    $scope.list_count = data.code.length;
    $scope.all_count = data.count;

    $scope.ok = function () {
      if ($scope.count_type && $scope.list_count < 1) {
        commonSVC.showMessage(gettextCatalog.getString('선택된 데이터가 없습니다.'));

        return false;
      }

      const vdata = {
        code: data.code,
        type: $scope.count_type,
        where: data.where
      };

      if (data.read_type) {
        vdata.read_type = data.read_type;
      }

      const url = data.url;

      $log.debug(url);
      $log.debug(vdata);

      commonSVC.sendUrl('POST', url, vdata)
        .then(function(res) {
          window.open(res.data.url, '', '');
          $scope.cancel();
        })
        .catch(function() {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('엑셀 다운로드에 실패하였습니다.'));
        });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };

  });
