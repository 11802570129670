'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentFulfillmentOrderListCtrl',
    function (// common
      $state, $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog,
      // info
      settings, userInfo,
      // SVC
      commonSVC, shipmentSVC, onlineProductSVC, columnSVC,
      // Model
      shipmentModel, commonModel, columnModel,
      // List
      warehouseList, systemList, supplierList, gridInfo, memoTemplateList
    ) {
      $scope.deliveryList = $rootScope.deliveryInfoList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];

      const fulfillmentChannelList = $rootScope.useChannelList({ func: shop => ['A001', 'A006', 'A077'].includes(shop.pa_shop_cd) && shop.etc1 === 'Y' });

      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;

      $scope.loadDataTable = gridInfo;

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(fulfillmentChannelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      $scope.totalCount = 0;
      $scope.countList = { total: 0, new: 0, shipping: 0, completed: 0, decision: 0, claimOrd: 0 }; // 우측 아이콘 카운트
      $scope.selectCount = 'total';
      $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';
      $scope.loadShopData = {
        domestic: false,
        global: !$rootScope.accessGlobalYN
      };

      $scope.access = {
        global: $rootScope.accessGlobalYN
      };

      let orderList = {};
      let prodList = {};      // SKU상품 정보
      let addProdList = {};   // 추가구매옵션정보
      let ordCntList = {};    // 주문카운트리스트

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.fulfillment);
      supplierList.data.result.unshift({ supp_no: 'null', supp_name: '매입처없음' });

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return ['건별출고수량', 'SKU코드', 'SKU상품명', '매입처', '바코드', '총출고수량'].indexOf(f.header) === -1;
        });
      }

      // 대시보드에서 넘어오는 경우 설정이 제대로 안되는 경우가 있음
      if ($rootScope.side_search.page === 'fulfillment' && $rootScope.side_search.search) {
        $scope.selectedShopType = $rootScope.side_search.selectedShopType;
      }

      const domesticSearchTemplate = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          shopType: '',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          status: ['__ALL__'],
          recent_month: 1,
          page: 'fulfillment',
          delivery_vendor: '',        // 배송처
          // to_country_code: '',     // 수령자 국가
          gift_prod_use_yn: '',       // 규칙사은품 사용여부
          gift_prod_name: '',         // 규칙적용사은품
          multi_search_word: '',      // 멀티서치워드,
          multi_type: 'shop_sale_no', // 멀티서치 타입
          delay_day: systemList.data.departure_delay_standard || 1, //출고지연
          // excel_down_yn: '',
          memo_yn: '', // 메모여부
          multi_shop_id: '',
          all_yn: 1 // 전체주문조회 여부
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectCount: 0,       // 검색전체 건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('운송장번호'), value: 'invoice_no' },
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'ord_confirm_time' },
            { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
            { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
            { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' },
            { label: gettextCatalog.getString('반품/교환접수요청일'), value: 'return_wdate' },
            { label: gettextCatalog.getString('배송 완료일'), value: 'ship_com_time' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('운송장 번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' },
            { label: gettextCatalog.getString('배송처'), value: 'depot' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(fulfillmentChannelList),
            item_key: 'shop_name',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 글로벌 쇼핑몰 여부 확인
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('계정 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(fulfillmentChannelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 클레임주문 상태
            title: gettextCatalog.getString('클레임 주문 상태'),
            search_name: 'ord_status',
            item_list: [
              { ord_status: '취소요청' },
              { ord_status: '취소완료' },
              { ord_status: '반품요청' },
              { ord_status: '반품진행' },
              { ord_status: '반품회수완료' },
              { ord_status: '반품완료' },
              { ord_status: '교환요청' },
              { ord_status: '교환진행' },
              { ord_status: '교환회수완료' },
              { ord_status: '교환완료' },
              { ord_status: '맞교환요청' },
              { ord_status: '맞교환완료' },
              { ord_status: '주문재확인' },
            ],
            item_key: 'ord_status',
            item_value: 'ord_status',
            select_value: ''
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: ''
          },
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(fulfillmentChannelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: '',
            filter: function(option) {
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X') && !option.shop_cd.startsWith('X');
            }
          },
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'multi_deliver_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            search_word: ''
          }
        ]
      };

      let domesticSearch = angular.copy(domesticSearchTemplate);
      function setSearch(search) {
        $scope.searchData = search.searchData;
        $scope.searchForm = search.searchForm;
        search.searchDetail = $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchDetailMulti = search.searchDetailMulti;
        $scope.searchDetailMultiSelect = search.searchDetailMultiSelect;
      }

      function resetSearch() {
        const showCount = $scope.searchData?.showCount;
        const search = domesticSearch = angular.copy(domesticSearchTemplate);

        if (showCount) {
          search.searchData.showCount = showCount;
        }
      }

      resetSearch();
      setSearch(domesticSearch);

      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="picon-get"></i><span> ${gettextCatalog.getString('주문 수집')}</span>`,
            small_label: '주문수집',
            test_id: 'btn-order-collect',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.integrated+write'],
            action: function() {
              $scope.scrapOrderConfirmList();
            }
          },
          {
            label: `<i class="icon-sync worksvc-spinner-orderSync order"></i><span> ${gettextCatalog.getString('주문 동기화')}</span>`,
            small_label: '주문동기화',
            test_id: 'btn-order-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.integrated+write'],
            action: function() {
              $scope.orderSync();
            }
          }
        ],
        table_states_top: [
          {
            label: '전체',
            test_id: 'btn-state-total',
            icon_class: 'pui-storefront',
            count_name: 'total'
          },
          {
            label: '신규주문',
            test_id: 'btn-state-new',
            icon_class: 'pui-add_shopping_cartsvg',
            count_name: 'new'
          },
          {
            label: '배송중',
            test_id: 'btn-state-shipping',
            icon_class: 'pui-local_shipping',
            count_name: 'shipping'
          },
          {
            label: '배송완료',
            test_id: 'btn-state-completed',
            icon_class: 'pui-package',
            count_name: 'completed'
          },
          {
            label: '구매결정',
            test_id: 'btn-state-decision',
            icon_class: 'pui-assignment_turned',
            count_name: 'decision'
          },
          {
            label: '클레임주문',
            test_id: 'btn-state-claim',
            icon_class: 'picon-claim',
            count_name: 'claimOrd'
          },
        ],
        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            test_id: 'drop-work',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                test_id: 'btn-order-copy',
                action: function () {
                  $scope.copyOrder();
                },
                perm_only: ['order.order+write']
              },
              {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                action: function () {
                  $scope.deleteOrder();
                },
                perm_only: ['order.order+admin']
              },
              {
                label: `${gettextCatalog.getString('판매금액 복구')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)" tooltip-placement="right">`,
                menu_tooltip: `판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)`,
                action: function () {
                  $scope.restoreSupplyPrice();
                },
                perm_only: ['order.unstoring+write'],
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
              {
                label: gettextCatalog.getString('SMS 발송'),
                test_id: 'btn-post-sms',
                action: function () {
                  $scope.sendModal('sms');
                },
                perm_only: ['order.order+admin'],
                ngIfFunc: function () {
                  return $scope.selectedShopType === 'global' ? 'n' : 'y';
                }
              }
            ],
            ngIfFunc: function () {
              return $scope.selectedShopType === 'global' ? 'n' : 'y';
            }
          },
          {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            test_id: 'drop-excel',
            add_class: 'btn-default',
            item_list: [
              {
                label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
                menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
                test_id: 'btn-excel-intergrate',
                action: function () {
                  $scope.downloadIntegratedExcel();
                },
                perm_only: ['order.unstoring+write']
              }
            ]
          },
          {
            label: gettextCatalog.getString('판매금액 관리'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.supplyPriceManager();
            },
            perm_only: ['order.order+write']
          },
        ]
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      // 관리자 접속인경우 주문상태변경 기능 적용.
      if ($rootScope.adminMode) {
        $scope.customContext = [{
          isSubMenu: true,
          line: true,
          subMemuName: '상태 수동변경(DEV)',
          contextList: [
            {
              label: gettextCatalog.getString('신규주문으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('신규주문');
              }
            },
            {
              label: gettextCatalog.getString('주문보류로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('주문보류');
              }
            },
            {
              label: gettextCatalog.getString('출고대기로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('출고대기');
              }
            },
            {
              label: gettextCatalog.getString('출고보류로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('출고보류');
              }
            },
            {
              label: gettextCatalog.getString('운송장출력 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('운송장출력');
              }
            }, {
              label: gettextCatalog.getString('출고완료로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('출고완료');
              }
            }, {
              label: gettextCatalog.getString('배송중으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('배송중');
              }
            }, {
              label: gettextCatalog.getString('배송완료으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('배송완료');
              }
            }, {
              label: gettextCatalog.getString('구매결정 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('구매결정');
              }
            }, {
              label: gettextCatalog.getString('취소요청으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('취소요청');
              }
            }, {
              label: gettextCatalog.getString('취소완료으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('취소완료');
              }
            }, {
              label: gettextCatalog.getString('반품요청으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('반품요청');
              }
            }, {
              label: gettextCatalog.getString('반품완료으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('반품완료');
              }
            }, {
              label: gettextCatalog.getString('교환요청으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('교환요청');
              }
            }, {
              label: gettextCatalog.getString('교환완료으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('교환완료');
              }
            }, {
              label: gettextCatalog.getString('주문재확인으로 상태변경(DEV)'),
              action: function() {
                $scope.userChangeStatus('주문재확인');
              }
            }
          ]
        }];
      }

      // 이노서브 더존 양식 다운 메뉴 추가
      if ([1143, 1].includes($rootScope.user_profile.sol_no)) {
        const excelAction = $scope.searchBtn.table_actions.find(({ label }) => label.includes('엑셀'));

        excelAction.item_list.splice(3, 0, {
          label: '더존 양식 다운',
          isSubMenu: true,
          item_list: [
            {
              label: gettextCatalog.getString('전체 주문 다운로드'),
              action: function() {
                $scope.excelDown('all', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            },
            {
              label: gettextCatalog.getString('선택 주문 다운로드'),
              action: function() {
                $scope.excelDown('select', true);
              },
              ngIfFunc: () => {
                return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
              }
            }
          ]
        });
      }

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      /* 데이터 테이블 */
      $scope.domestic = {};
      $scope.domestic.methods = {};
      $scope.domestic.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: ['order_tel', 'order_htel', 'ord_time', 'pay_time', 'ord_status_mdate', 'ship_com_time', 'shop_ord_no_real'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
          requestWillAction: function(data) {
            $scope.searchForm.shopType = 'domestic';

            if (userInfo.user.auth_type === '배송처') {
              commonSVC.showMessage('배송처 사용자의 경우 사용 할 수 없습니다.');
            }
            if ($rootScope.memo_etc_search.page === 'fulfillment' && $rootScope.memo_etc_search.search) {
              otherPageCalled('memo_etc', false);
            }
            if ($rootScope.order_search.page === 'fulfillment' && $rootScope.order_search.search) {
              otherPageCalled('order_search', false);
            }
            if ($rootScope.side_search.page === 'fulfillment' && $rootScope.side_search.search) {
              otherPageCalled('side_search', false);
            }
            // 작업관리에서 넘어오는경우 2019-02-20 rony
            if ($rootScope.work_search.page === 'fulfillment' && $rootScope.work_search.search) {
              otherPageCalled('work', false);
            }

            if ($scope.searchForm.ord_status) {
              $scope.selectCount = 'claimOrd';

              $scope.searchForm.status = [$scope.searchForm.ord_status];
            }

            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            // SKU상품정보 처리
            prodList = {};
            addProdList = {};
            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) {
                  addProdList[subRow.uniq] = [];
                }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) {
                  prodList[subRow.uniq] = [];
                }

                prodList[subRow.uniq].push(subRow);
              }
            });

            // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
            const timer = setInterval(async () => {
              if ($scope.selectedShopType !== 'domestic' || $scope.loadShopData.global) {
                $scope.searchData.totalCount = result.recordsTotal;
                $scope.domesticTotalCount = result.recordsTotalCount;
                $scope.totalCount = result.recordsTotalCount;
                orderList = result.results;

                columnModel.setOld(_.cloneDeep(result.results), 'fulfillment');
                if ($scope.searchForm.status[0] === '__ALL__') {
                  ordCntList = result.statusCount;
                } else {
                  ordCntList[$scope.searchForm.status[0]] = [{
                    ord_status: $scope.searchForm.status[0],
                    cnt: result.results.length
                  }];
                }

                init(result.statusCount);

                // 추가 컬럼 숫자 변경
                columnSVC.addColIntSet('all', result.results);

                $scope.loadShopData.domestic = true;
                clearInterval(timer);
                $timeout(() => {});
              }
            }, 100);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 100,
            template: function(row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
            <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
            }
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
              ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
              ${shop_info[1]}
            </span>`;
              }

              return img;
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 280
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 280
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
                return (
                  `<span>
              <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
              <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${
                  row.shop_cd
                  }','${
                    row.shop_id
                  }','${
                    sale_no
                  }')"></i></span>`
                );
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '온라인 상품명',
            width: 300,
            notCompile: true,
            template: function(row) {
              //이미지+상품명
              const exchangeSpan =
            row.exchange_ord_yn == 1 || row.exchange_yn == 1
              ? '<span class="label label-default bg-blue">교환주문</span> '
              : '';
              let giftSpan = '';
              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
              }              // 클레임 주문에 대한 사본주문은 플래그 노출
              const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return (
                  `<span class=''>${
                    exchangeSpan
                  }${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}<img src='${
                    img
                  }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                    filteredShopSaleName
                  }</span>`
                );
              } else {
                return `${exchangeSpan}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {

              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'shop_add_opt_name',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function(row) {
              // "추가구매옵션1=2;추가구매옵션2=3"
              let return_value = '';
              const retVals = [];

              if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

                _(addProdList[row.uniq])
                  .groupBy('ord_opt_seq')
                  .map(arr => {
                    retVals.push(
                      `${arr[0].ord_opt_name}-${['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(row.ord_status) > -1 && !$rootScope.osse_sol ? '0' : arr[0].opt_sale_cnt}개`
                    );
                  })
                  .value();
                return_value = retVals.join(', ');
              }

              return return_value;
            }
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'shop_cost_price',
            title: '원가',
            tooltip: $rootScope.userProfileCheck('sol_stock', 1) ? '주문에 매칭된 SKU상품 혹은 온라인상품에 입력된 원가 정보입니다. (입력된 원가*출고수량*주문수량)' : '해당 온라인상품에 입력된 원가 정보입니다. (입력된 원가*주문수량)',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_cost_price, '', 0);
            }
          },
          {
            key: 'shop_supply_price',
            title: '공급가',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_supply_price, '', 0);
            }
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_addr1',
            title: '주소',
            width: 300,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.to_addr1;

              if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value += row.to_addr2;
              }

              return return_value;
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 150
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 120
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
              const carr_no = row.carr_no;

              // 택배사는 선택되어있는데 운송장번호는 없는경우가 있으므로 조건 추가. 2018-11-08 rony
              if (
                carr_no &&
            invoice_no &&
            $rootScope.possibeTrackingView.includes(carr_no)
              ) {
                return (
                  `<span>${
                    invoice_no
                  }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                    carr_no
                  }','${
                    invoice_no.replace(/-/gi, '')
                  }')"></i></span>`
                );
              } else {
                return row.invoice_no;
              }
            }
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_status_mdate',
            title: '상태변경일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ship_com_time',
            title: '배송완료일',
            tooltip: `[주문 동기화] 기능 또는 직접 상태를 변경하여 주문의 상태가 “배송완료”로 변경된 일시입니다.
실제로 배송이 완료된 시점과 다를 수 있으니 참고용으로 활용해 주세요.`,
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
        ],
        initShowCount: function(showCount) {
          setTimeout(() => {
            domesticSearch.searchData.showCount = showCount;
          }, 100);
        }
      };

      // 초기 탭 그리드 세팅
      $scope.grid = $scope[$scope.selectedShopType];

      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) {
        addColumnSet();
      }
      resetSearch();
      setSearch(domesticSearch);

      // LG custom -> sub user 인경우 노출항목 추가.
      // if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      // columnSVC.addColumns_LG($scope.grid.options);
      // }

      async function addColumnSet() {
        const domesticParams = {
          gridOption: $scope.domestic.options,
          readOnly: false,
          addcolType: 'all',
          search_key_items: domesticSearchTemplate.searchData.search_key_items
        };

        columnSVC.columnsSet(domesticParams);

      }

      /**
   * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
   */
      $scope.$on('OnColumnChange', function(event, data) {
        _.each(data, function(row) {
          $compile(row)($scope);
        });
      });

      /**
   * 검색
   */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
   * 사이드바 집계 클릭시
   */
      $scope.$on('sidebarSearch_fulfillment_list', function() {
        let tabIdx = 1;
        $timeout(() => {
          if ($rootScope.side_search.selectedShopType === 'domestic') {
            tabIdx = 1;
          } else {
            tabIdx = 2;
          }

          $(`a[href='#content-tabs-${tabIdx}']`).trigger('click');
          $scope.searchDo(true, true);
        });
      });

      /**
   * 검색 초기화
   */
      $scope.resetDo = function() {
        resetSearch();
        setSearch(domesticSearch);
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
   * 데이터테이블 pageLength 조절
   */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
   * 상세페이지 보여주기
   */
      $scope.showDetail = function(uniq) {
        const resolve = {
          data: {
            fromPage: domesticSearchTemplate.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/fulfillment_detail.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      $scope.showSetDetail = function (setNo) {
        const resolve = {
          data: { from: '수정', set_no: setNo }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      // 다중 매칭된 SKU 조회 모달
      $scope.showMultiSKUDetail = function (uniq) {
        const resolve = {
          data: { prodList: prodList[uniq] }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      // btn event..
      /**
   * 카운팅에 맞는 검색 결과
   */
      $scope.countSearch = function(type) {
        // if($scope.countList[type] !== 0) {
        const sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;

        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchForm.sdate = sdate;
        $scope.searchForm.edate = edate;

        switch (type) {
          case 'total':
            $scope.searchForm.status = ['__ALL__'];
            break;
          case 'new':
            $scope.searchForm.status = ['신규주문'];
            break;
          case 'shipping':
            $scope.searchForm.status = ['배송중'];
            break;
          case 'completed':
            $scope.searchForm.status = ['배송완료'];
            break;
          case 'decision':
            $scope.searchForm.status = ['구매결정'];
            break;
          case 'claimOrd':
            $scope.searchForm.status = ['취소요청', '취소완료', '반품요청', '반품진행', '반품회수완료', '반품완료', '교환요청', '교환진행', '교환회수완료', '교환완료', '주문재확인', '맞교환요청', '맞교환완료'];
            // $scope.searchForm.status = ['취소요청', '취소완료', '반품요청', '반품회수완료', '반품완료', '교환요청', '교환완료', '주문재확인'];
            break;
        }

        $scope.searchDo(true, true);
        $scope.selectCount = type;
        // }
      };

      /**
   * 발주전 주문수집
   */
      $scope.scrapOrderConfirmList = function() {
        // 주문수집 권한 체크 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.scrapOrder', userInfo.permission)) {
          commonSVC.openModal('md', { data: { work_type: 'scrap' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
        }
      };

      /**
   * 주문 동기화
   */
      $scope.orderSync = function() {
        //  sync2: GMP (sync: EBAY)
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
   * 엑셀 다운 모달창
   * */
      $scope.excelDown = (type, isDouzone) => {

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: $scope.excelFieldList,
          title: '전체주문',
          url: '/app/order/excelDown',
          searchForm: { ...$scope.pageData, shopType: $scope.selectedShopType },
          page: 'fulfillment',
          visibleTable: visibleTable,
          progress: true
        };

        // 더존양식 여부
        if (isDouzone) {
          resolve.data.isDouzone = true;
        }

        if (type === 'all') {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;

          if (resolve.data.count === 0) {
            commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 주문이 없습니다.'));

            return false;
          }
        } else {
          const uniqList = $scope.grid.methods.selectedData('uniq');

          if (uniqList.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          } else {
            resolve.data.uniq = uniqList;
            resolve.data.count = uniqList.length;
            resolve.data.isAll = false;
          }
        }

        const modal = commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            // 엑셀 다운로드 후 새로고침 안함 (https://playautogmpproject.atlassian.net/browse/GMPKR-5462)
            // 2018-10-24 rony
            // $scope.searchDo(false);
          }
        });
      };

      /**
   * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
   */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
   * 문의관리에서 주문번호를 눌렀을때
   */
      $scope.$on('customerSearch_fulfillment', function() {
        $scope.searchData.search_key_name = '주문번호';
        $scope.searchDo(true, true);
      });

      /**
   * 주문 상태 강제변경
   * */
      $scope.userChangeStatus = function (status) {
        //유니코드로 마지막 글자의 받침 여부 확인
        const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

        let msg = `주문상태를 ${status}${isConsonant ? '으로' : '로'} 강제 변경 하시겠습니까?`;

        if (status === '취소완료') {
          msg += '\n취소완료로 변경시 재고처리로 인해 다른상태로의 변경이 불가능합니다.';
        }

        commonSVC.showConfirm('주문상태 변경', msg, function (re) {
          if (re) {
            shipmentModel.setStatus({ status: status, uniqList: $scope.grid.methods.selectedData('uniq'), bundle_codes: $scope.grid.methods.selectedData('bundle_no') }, function (state, res) {
              if (state == 'success' && res === 'success') {
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                $scope.searchDo(false);
              } else {
                commonSVC.showToaster('error', gettextCatalog.getString('실패'), res.data.error.includes('BIGINT UNSIGNED value is out of range in') ? gettextCatalog.getString('차감할 재고가 부족합니다. 매칭된 SKU 재고수량을 확인해주세요.') : gettextCatalog.getString('주문 상태변경에 실패하였습니다'));
              }
            });
          }
        });
      };

      /**
       * 주문복사
       * */
      $scope.copyOrder = function() {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          selectList: selectList,
          selectedShopType: $scope.selectedShopType
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
            commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
          }
        });
      };

      /**
       * 주문삭제
       * */
      $scope.deleteOrder = function(uniq) {

        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          uniqList: param,
          fromPage: 'order'
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderDelete', 'views/order/shipment/modals/order_delete.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 판매금액 관리 매니저
       */
      $scope.supplyPriceManager = () => {
        const modal = commonSVC.openModal('full', { data: { uniqList: $scope.grid.methods.selectedData('uniq'), shop_id_view_type: systemList.data.shop_id_view_type } }, 'SupplyPriceManagerCtrl', 'views/order/shipment/modals/supply_price_manager.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 공급가정리매니저 규칙 적용복구
       */
      $scope.restoreSupplyPrice = async () => {
        const selectList = $scope.grid.methods.selectedData('bundle_no');

        if (!selectList.length) {
          commonSVC.showMessageHtml('선택된 주문이 없습니다.', '<ul style="margin-top:20px;"><li>복구할 주문을 선택하신 후 다시 진행해 주세요.</li></ul>');

          return false;
        }

        const confirm = await commonSVC.showConfirm('판매금액을 복구 하시겠습니까?',
          '· 판매금액 복구는 규칙을 적용 이 전의 상태로 되돌려 놓습니다.\n'
      + '· 판매금액 복구는 규칙이 적용된 주문에만 적용이 가능하며,\n'
      + '  특정항목만 복구는 불가합니다.(ex. 공급가만 복구 불가)\n'
      + '· 복구된 주문은 다시 규칙을 적용할 수 있으나, 단순히 복구 이 전의 상태로\n'
      + '  되돌려 놓는것은 불가능하니 적용 전에 충분히 고려해 주세요.\n'
      + '· 판매금액 관리 규칙 내 조건이 삭제되었거나 규칙자체가 삭제된 주문은 복구되지 않으며, 이런 경우 주문 [상세]에서 직접 수정할 수 있습니다.');

        if (confirm) {
          try {
            const re = await shipmentModel.restoreSupplyPriceRule({ bundleNoList: [], uniqNoList: $scope.grid.methods.selectedData('uniq') });

            if (re.data.results === 'success') {
              $scope.searchDo(true, true);
              commonSVC.showToaster('success', '', '데이터 복구가 완료되었습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          } catch (err) {
            if (err.data.messages[0] === 'no restore data') {
              commonSVC.showToaster('error', '', '복구 가능한 데이터가 없습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          }
        }
      };

      /**
   * 사이드바에서 검색했을 시에
   */
      $scope.$on('sidebarSearch', function() {
        // 검색을 전체검색으로 고정 2018-04-25 rony
        $scope.countSearch('total');
        // $scope.searchDo();
      });

      /**
   * 테이블 컬럼 사이즈 확인
   */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_fulfillment_list') {
          const $wrapper = $('#fulfillment_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      /**
   * 다른 페이지에서 멀티검색으로 넘어왔을떄 셋팅.
   * 2019-02-22 rony
   * @param {호출된 페이지 (type: staging)} page
   * @param {이미 페이지가 열려있는 상태애서 호출되는지의 여부 (type: boolen)} aleady_open
   */
      function otherPageCalled(page, aleady_open) {
        // 작업관리 호출
        if (page === 'work') {
          // watchCollection 으로 갱신되기 때문에 select 값을 새로 채워주어야 한다.
          domesticSearch.searchDetail[0].item_list = commonSVC.getSiteList(fulfillmentChannelList);
          domesticSearch.searchForm.shop_cd = $rootScope.work_search.select_shop_cd || '';
          domesticSearch.searchForm.shop_id = $rootScope.work_search.select_shop_id || '';
          domesticSearch.searchForm.multi_type = 'shop_ord_no';
          domesticSearch.searchForm.multi_search_word = $rootScope.work_search.multi_search_cds.join('\n');

          domesticSearch.searchDetail[0].select_value = domesticSearch.searchForm.shop_cd;
          domesticSearch.searchDetail[1].select_value = domesticSearch.searchForm.shop_id;
          $scope.selectShopType('domestic');

          $scope.detailSearchFlag = true;
          $rootScope.work_search.search = false;

          // 해당값은 사용하지 않는것으로 확인되어 주석.
          // $scope.searchDetailMultiSelect[0].select_value = `${$rootScope.work_search.select_shop_cd}|${$rootScope.work_search.select_shop_id}`;
        }
        // order_search 호출
        else if (page === 'order_search') {
          $scope.searchForm.search_word = $rootScope.order_search.input;
          $scope.searchForm.search_key = $rootScope.order_search.key;
          $scope.searchData.search_key_name = $rootScope.order_search.name;
          $scope.searchForm.status = ['__ALL__'];
          $rootScope.order_search.search = false;

          // 국내 & 해외 주문건 모두 검색

          if ($rootScope.accessGlobalYN) {
            const otherShopType = $scope.selectedShopType === 'domestic' ? 'global' : 'domestic';

            $scope.grid = $scope[otherShopType];
            $scope.searchDo(true, true);

            $scope.grid = $scope[$scope.selectedShopType];
            $scope.checkNoneOrd = true;
          }
        }
        // side_search 호출
        else if (page === 'side_search') {
          $scope.searchForm.date_type = $rootScope.side_search.date_type;
          $scope.searchForm.sdate = $rootScope.side_search.sdate;
          $scope.searchForm.edate = $rootScope.side_search.edate;
          $scope.selectCount = $rootScope.side_search.selectCount;
          $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
          $scope.searchForm.status = $rootScope.side_search.status;
          $rootScope.side_search.search = false;
        }

        // 메모관리 호출
        else if (page === 'memo_etc') {
          const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

          $scope.searchForm = {
            ...$scope.searchForm,
            date_type,
            sdate,
            edate,
            search_key,
            search_word,
            search_type: 'exact'
          };
          $rootScope.memo_etc_search.search = false;
        }

        if (page !== 'work' && aleady_open) {
          $scope.searchDo(true, true);
        }
      }

      // SMS, EMAIL 모달
      $scope.sendModal = async (type) => {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const t = angular.copy($scope.pageData);

        delete t.start;
        t.length = 1000000;

        const totalRows = (await shipmentModel.listSms(t)).data.results;

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };

      /**
   * 통합 엑셀 다운로드
   */
      $scope.downloadIntegratedExcel = function () {
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const selectedData = $scope.grid.methods.selectedData('all');
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData,
          page: 'fulfillment',
          visibleTable: visibleTable,
          isGlobal: $scope.selectedShopType === 'global'
        };

        if (!selectedData.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';
        } else {
          const uniq = $scope.grid.methods.selectedData('uniq');

          resolve.data.type = 'select';
          resolve.data.uniq = uniq;
          resolve.data.count = uniq.length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
      };

      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      /**
   * 국내, 해외 선택
   */
      $scope.selectShopType = (shopType) => {
        // 각 탭 입력폼 저장

        setSearch(domesticSearch);

        $scope.selectedShopType = shopType;
        $scope.grid = $scope[$scope.selectedShopType];
        $scope.countSearch('total');
      };

      // 리스트와 별개로 초기에 세팅되어야하는 값
      function init(data) {
        $scope.selectRowUniqList = [];
        if ($scope.searchForm.status[0] !== '__ALL__') {
          delete data['전체'];
        }

        if (!['결제완료', '__ALL__'].includes($scope.searchForm.status[0])) {
          delete data['출고지연'];
        }

        if (!['취소요청', '__ALL__'].includes($scope.searchForm.status[0])) {
          delete data['클레임_전체'];
        }

        const cntList = {
          ...ordCntList,
          ...data,
        };

        const needUpdate = Object.keys(data).map((key) => {
          return !ordCntList[key] || (data[key][0].cnt > ordCntList[key][0].cnt) ? 1 : 0;
        }).some(Boolean);
        if (needUpdate) {
          ordCntList = cntList;
        }

        $scope.countList = {
          total: cntList['전체'] ? cntList['전체'][0].cnt : 0,
          new: cntList['신규주문'] ? cntList['신규주문'][0].cnt : 0,
          shipping: cntList['배송중'] ? cntList['배송중'][0].cnt : 0,
          completed: cntList['배송완료'] ? cntList['배송완료'][0].cnt : 0,
          decision: cntList['구매결정'] ? cntList['구매결정'][0].cnt : 0,
          claimOrd: cntList['클레임_전체'] ? cntList['클레임_전체'][0].cnt : 0
        };
      }

      // TODO
      // const setSearchTemplate = (siteList) => {

      //   domesticSearchTemplate.searchDetail[0].item_list = commonSVC.getSiteList(angular.copy(siteList || []));
      //   domesticSearchTemplate.searchDetail[1].item_list = commonSVC.getSiteIdList(angular.copy(siteList || []));

      // };
      // 초기 선택이 안된 탭에도 검색기간 초기화 적용
      setTimeout(() => {
        const destSearchForm = domesticSearch.searchForm;
        destSearchForm.search_key = $scope.searchForm.search_key || $scope.searchData.search_key_items[0].value;
        destSearchForm.change_sdate = false;
        destSearchForm.change_edate = false;
        destSearchForm.selectDate = angular.copy($scope.searchForm.selectDate);
      });

    });
