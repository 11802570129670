
'use strict';

/**
 * 2018-04-02 harry
 * 72TIME
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B579-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B579categorySVC, commonSVC, errorSVC, settings, siteInfo) {

    $scope.CateList = angular.copy(B579categorySVC.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(B579categorySVC.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(B579categorySVC.category_data);  // 카테고리 데이터

    const CateAction = angular.copy(B579categorySVC.category_action);      // 카테고리 액션 이름

    // requestPA 파라미터
    const userInfo = {};

    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    const matchDetailDataLoad = async (addInfo) => {
      if (!addInfo.category_code) {
        loading('0', true);

        return false;
      }

      $scope.CateData = addInfo;

      const category_codes = addInfo.category_code.split('_');

      // 카테고리 자동로드
      $timeout(async() => {
        try {
          await setCateSub(0, category_codes[0]);
          await setCateSub(1, category_codes[1]);
          await setCateSub(2, category_codes[2]);
        } catch (err) {
          if (category_codes.length !== addInfo.category_names.split('>').length) {
            commonSVC.showMessage('카테고리를 다시 선택해 주세요. 상품 정보 수정 후\n쇼핑몰로 전송 시 카테고리 정보는 반영되지 않습니다.');
          } else {
            commonSVC.showMessage('로딩실패');
          }
        }
      });

      loading('0', true);
    };

    function init() {
      // 로딩중
      loading('0', false); // 카테고리 로딩중

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;
          userInfo.shop_id = $scope.userInfo.shop_id;
          userInfo.shop_pwd = $scope.userInfo.shop_pwd;
          userInfo.pa_shop_cd = $scope.userInfo.shop_cd;

          // 카테고리 로딩
          return commonSVC.requestPA(userInfo, siteInfo.B579.domain, CateAction['0'], { parent: '0', depth: 1 }, commonModel, shopAccountModel);
        })
        .then(function (res) {
          const data = res.data.data.items;

          $scope.CateOpt[0] = data.length > 0 ? data : [];

          if (data.length > 0) {

            if ($scope.matchingData.shop_cate_no_add_info) {
              if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
                matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
              } else {
                matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
              }
            } else if (!$scope.matchingData.sol_cate_shop_add_info) {
              matchDetailDataLoad(angular.copy(B579categorySVC.category_data));
            } else {
              matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
            }
          } else {
            loading('0', true);

            return false;
          }
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
          loading('0', true); // 표준카테고리 로딩완료

          return false;
        });
    }

    init();

    /**
     * 카테고리 선택 이벤트
     */
    $scope.cateSelect = function(index) {
      const cateType = $scope.CateList[index]; // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index);        // 카테고리 이름 설정

      // 마지막 분류이거나, 하위분류없을시
      if (index === '3' || cateCd === 'noSub' || !cateCd) {

        return false;
      }

      loading('0', false);
      clearOpt(index);

      setCateSub(index, cateCd)
        .then(function() {
          cateCd = 0;
          loading('0', true);
        })
        .catch(function() {
          cateCd = 0;
          loading('0', true);
          commonSVC.showMessage('로딩실패');
        });
    };

    /**
     * 카테고리 이름
     */
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') {
        return false;
      }

      const nameArr = [];
      const codeArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const selectedCate = _.find($scope.CateOpt[i], { code: $scope.CateData[$scope.CateList[i]] });

        if (!selectedCate.code) {
          break;
        }
        nameArr.push(selectedCate.name);
        codeArr.push(selectedCate.code);
      }
      $scope.CateData.category_names = nameArr.join(' > ');
      $scope.CateData.category_code = codeArr.join('_');
      $scope.CateData.key_cate = `^${codeArr.join('>')}__${nameArr.join(' > ')}`;
    }

    /**
     * 옵션 초기화
     */
    function clearOpt(index) {
      const start = parseInt(index) + 1;

      for (let i = start; i < 3; i++) {
        const key = $scope.CateList[i];

        $scope.CateOpt[i] = [];
        $scope.CateData[key] = '';
      }
    }

    /**
     * 하위 카테고리 가져오기
     */
    function setCateSub(index, cateCd) {
      index = index * 1;
      const action = CateAction[index + 1];

      return commonSVC.requestPA(userInfo, siteInfo.B579.domain, action, { parent: cateCd, depth: index + 2 }, commonModel, shopAccountModel)
        .then(function(res) {
          const data = res.data.data.items;

          $scope.CateOpt[index + 1] = data || [];
        });
    }

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      const cateCd = [];

      _.forEach($scope.CateList, function(v) {
        const value = $scope.CateData[v];

        if (value === 'noSub') {
          return false;
        }
        if (!value) {
          return false;
        }
        cateCd.push(value);
      });

      if (cateCd.length === 0) {
        commonSVC.showMessage('실패', '한개 이상의 카테고리를 선택해주세요');

        return false;
      }

      let flag = false;

      for (let i = 0; i < 3; i++) {
        if (!$scope.CateData[$scope.CateList[i]] && Object.keys($scope.CateOpt[i]).length) {
          flag = true;

          break;
        }
      }

      if (flag) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B579',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

  });
