import angular from 'angular';

export type ENVIRONMENT = 'debug' | 'dev' | 'staging' | 'pstaging' | 'master';

export type GMP_ENVIRONMENT_VARIABLES = {
  EBAY_API_URL: string;
  EBAY_CLIENT_ID: string;
  EBAY_RECT_URI: string;
};

export type TOKEN_ENVIRONMENT_VARIABLES = {
  EBAY_API_URL: string;
  EBAY_CLIENT_ID: string;
  EBAY_RECT_URI: string;
  LAZADA_TOKEN_URL: string;
};

export type ENVIRONMENT_VARIABLES = {
  ENV: ENVIRONMENT;
  API_URL: string;
  PA20_API_URL: string;
  ADMIN_API_URL: string;
  WWW_URL: string;
  WWW_API_URL: string;
  APP_URL: string;
  PLAYAPI_URL: string;
  SOCKET_URL: string;
  WAKEUP_URL: string;
  WAKEUP_API_URL: string;
  CHROME_APP_ID: string;
  PUSHER_API_KEY: string;
  GMP: GMP_ENVIRONMENT_VARIABLES;
  TOKEN_API: TOKEN_ENVIRONMENT_VARIABLES;
  BUILD_ID: string;
}

angular
  .module('environment', [])
  .factory('environment', function (): ENVIRONMENT_VARIABLES {
    const variables = ENVS as ENVIRONMENT_VARIABLES;

    return {
      ENV: variables.ENV,
      API_URL: variables.API_URL,
      APP_URL: variables.APP_URL,
      PLAYAPI_URL: variables.PLAYAPI_URL,
      SOCKET_URL: variables.SOCKET_URL,
      WAKEUP_URL: variables.WAKEUP_URL,
      WAKEUP_API_URL: variables.WAKEUP_API_URL,
      CHROME_APP_ID: variables.CHROME_APP_ID,
      PUSHER_API_KEY: variables.PUSHER_API_KEY,
      TOKEN_API: variables.TOKEN_API,
      GMP: variables.GMP,
      PA20_API_URL: variables.PA20_API_URL,
      ADMIN_API_URL: variables.ADMIN_API_URL,
      WWW_URL: variables.WWW_URL,
      WWW_API_URL: variables.WWW_API_URL,
      BUILD_ID: '0'
    };
  });