'use strict';

angular.module('gmpApp')
  .service('warehouseSVC', function (gettextCatalog, $rootScope) {
    return {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        use_yn: '',
        type: ''
      },

      defaultVdata: {
        depot_no: null,
        use_yn: true,
        depot_name: '',
        ebaydepot_yn: 0,
        ebaydepot_id: '',
        ebaydepot_pwd: '',
        charge_name: '',
        charge_tel: '',
        charge_email: '',
        zipcd: '',
        addr1: '',
        addr2: '',
        ctry_cd: 'kr',
        city: '',
        depot_memo: '',
        depot_sett_period: $rootScope.osse_sol ? 2 : null  // OSSE인 경우에만 1개월 디폴트 설정 나머지 업체는 NULL
      },

      // 혜수님 요청으로 검색값 수정 2018-04-10 rony
      // https://playautogmpproject.atlassian.net/browse/GMPKR-2798
      searchData: {
        all_checked: false,        // 전체선택 여부
        showDateSearch: false,   //상세검색 날짜 검색 여부
        totalCount: 0,             // 검색전체 건수
        selectCount: 0,            // 선택한 건수 <- 작업예정
        search_key_items: [        // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          // {label:gettextCatalog.getString('순번, 배송처 코드'), value:['number','code']},
          { label: gettextCatalog.getString('배송처 코드'), value: 'depot_no' },
          { label: gettextCatalog.getString('배송처명'), value: 'depot_name' },
          { label: gettextCatalog.getString('담당자'), value: 'charge_name,charge_name2' },
          { label: gettextCatalog.getString('주소'), value: 'addr1,addr2' }
          // {label:gettextCatalog.getString('수령자 우편번호, 주소'), value:['zip_code','address1','address2','city']},
          // {label:gettextCatalog.getString('수령자 연락처'), value:['tel']},
        ]
      },

      ctry_name: {
        kr: gettextCatalog.getString('한국'),
        us: gettextCatalog.getString('미국'),
        hk: gettextCatalog.getString('홍콩'),
        cn: gettextCatalog.getString('중국'),
        jp: gettextCatalog.getString('일본'),
        sg: gettextCatalog.getString('싱가포르'),
        au: gettextCatalog.getString('호주'),
        gb: gettextCatalog.getString('영국'),
        de: gettextCatalog.getString('독일'),
        fr: gettextCatalog.getString('프랑스'),
        ca: gettextCatalog.getString('캐나다'),
      },

      // 창고위치 리스트 (해외)
      location_list: [
        { code: 'kr', name: gettextCatalog.getString('한국') },
        { code: 'us', name: gettextCatalog.getString('미국') },
        { code: 'hk', name: gettextCatalog.getString('홍콩') },
        { code: 'cn', name: gettextCatalog.getString('중국') },
        { code: 'jp', name: gettextCatalog.getString('일본') },
        { code: 'sg', name: gettextCatalog.getString('싱가포르') },
        { code: 'au', name: gettextCatalog.getString('호주') },
        { code: 'gb', name: gettextCatalog.getString('영국') },
        { code: 'de', name: gettextCatalog.getString('독일') },
        { code: 'fr', name: gettextCatalog.getString('프랑스') },
        { code: 'ca', name: gettextCatalog.getString('캐나다') },
      ],

      // 로그 처리 필드
      log_field_list: {
        use_yn: gettextCatalog.getString('사용여부'),
        type: gettextCatalog.getString('구분'),
        warehouse_name: gettextCatalog.getString('창고명'),
        zip_code: gettextCatalog.getString('창고위치(우편번호)'),
        street_addressing: gettextCatalog.getString('창고위치(도로명주소)'),
        foreign_location: gettextCatalog.getString('창고위치(해외)'),
        foreign_location_detail: gettextCatalog.getString('"창고위치상세(해외)"'),
        charge_name: gettextCatalog.getString('담당자'),
        tel: gettextCatalog.getString('연락처'),
        email: gettextCatalog.getString('이메일'),
        memo: gettextCatalog.getString('메모')
      },

      // 엑셀 모달창 필드 리스트
      excelFieldList: [
        { header: '배송처코드', key: 'depot_no', select: true },
        { header: '배송처명', key: 'depot_name', select: true },
        { header: '주소', key: 'distance', select: true },
        { header: '담당자', key: 'charge_name', select: true },
        { header: '직통번호', key: 'charge_tel', select: true },
        { header: '메모', key: 'depot_memo', select: true },
        { header: '기본 배송처', key: 'default_warehouse', select: true },
        { header: '사용여부', key: 'use_yn', select: true },
        ($rootScope.osse_sol && { header: '지급 주기', key: 'depot_sett_period', select: true })
      ]
    };
  });
