'use strict';

angular.module('gmpApp')
  .controller('noticeCtrl', function ($scope, $uibModalInstance, $location, $anchorScroll, $timeout, data) {
    const { notices } = data;

    let page = 1;
    const lastPage = notices.length;

    $scope.isFirstPage = false;
    $scope.isLastPage = false;
    $scope.isAllPageView = false;
    $scope.isNeverView = false;

    $scope.maxModalWidth = notices.reduce((acc, cur) => cur.popup_width > acc ? cur.popup_width : acc, 600);
    $scope.maxModalHeight = notices.reduce((acc, cur) => cur.popup_height > acc ? cur.popup_height : acc, 500);

    $scope.next = () => {
      setPage(page + 1);
    };
    $scope.prev = () => {
      setPage(page - 1);
    };

    $scope.close = function () {
      const neverViewNoticeNos = $scope.isNeverView ? notices.map(({ noti_no }) => noti_no) : [];

      $uibModalInstance.close(neverViewNoticeNos);
    };

    const getIndexByPage = () => page - 1;

    const setPage = (newPage) => {
      page = newPage;

      $scope.pageLocation = `(${page}/${lastPage})`;
      $scope.currentNotice = notices[getIndexByPage()];

      $scope.isFirstPage = !getIndexByPage();
      $scope.isLastPage = page === lastPage;
      $scope.isAllPageView = $scope.isAllPageView || $scope.isLastPage;

      $location.hash('notice');
      $anchorScroll();

      $timeout(() => {
        $('.modal-notice .modal-body').css('height', `calc(100% - 80px - ${$('.modal-notice .modal-header').outerHeight()}px)`);
      });
    };

    const init = () => {
      setPage(page);

      notices.forEach(notice => {
        notice.files = notice.file.split('#').filter(fileString => fileString).map(JSON.parse);
      });
    };

    init();
  });
