/**
 *  c3Chart 디렉티브
 *  http://c3js.org/
 *  17-03-28 ally
 */
'use strict';

angular.module('gmpApp')
  .directive('c3Chart',
    function () {
      return {
        restrict: 'A',
        scope: {
          chartData: '=',
          chartAxis: '=',
          chartOption: '=?',
          chartEvent: '='
        },
        link: function ($scope, elem) {

          let chart;
          const config = {
            bindto: $(elem)[0],
            data: $scope.chartData,
            axis: $scope.chartAxis
          };

          // 차트 그려줌
          $scope.chartEvent.draw = function() {
            if ($(elem).children().length === 0 && $scope.chartOption) {
              // config 에 option들 조합
              _.assign(config, $scope.chartOption);
            }

            chart = c3.generate(config);
          };

          // 차트를 새로 그리지않고 columns 추가
          $scope.chartEvent.addColumns = function(columnArray) {
            chart.load({
              columns: columnArray
            });
          };

          // 차트를 새로 그리지않고 columns 삭제
          $scope.chartEvent.removeColumns = function(columnArray) {
            chart.unload({
              columns: columnArray
            });
          };

          // 차트 resize
          $scope.chartEvent.resize = function() {
            if (chart) {
              chart.resize();
            }
          };

        }
      };
    });
