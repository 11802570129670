'use strict';

angular.module('gmpApp')
  .directive('leftContentLog', function (logHistorySVC, $timeout, $sce) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentLog.html',
      link: function ($scope) {
        $scope.$parent.tabLog = function (initData, data) {
          $scope.logList = initData.accordionList;
          getLog(initData.uniqs, 'all', data, initData.sol_no);
        };

        $scope.$parent.tabLogInit = function () {
          $scope.logList = [];
        };

        $scope.page = $scope.$parent.el_id.split('_grid')[0];

        /**
         * 로그 불러오기
         **/
        function getLog(uniq, mode, data, sol_no) {
          const params = {
            location: data.location,
            number: uniq,
            sol_no
          };

          logHistorySVC.getLog(data.location, params, function(state, data) {
            const logList = state == 'success' && data.length ? data : [];

            if (mode === 'one') {
              const idx = _.findIndex($scope.logList, { uniq: uniq });

              $scope.logList[idx].list = [];
            }

            _(logList)
              .map(function (row) {
                let uniq = row.log_code;

                switch (params.location) {
                  case 'user': {
                    uniq = row.m_no;
                    break;
                  }
                }

                row.logContent = row.note ? `${row.content ? `${row.content}, ` : ''}${row.note}` : row.content;

                const idx = _.findIndex($scope.logList, { uniq });

                $scope.logList[idx].list.push(row);
              })
              .value();

            $timeout(function() {
              // 선택한 주문의 메모열어줌
              $(`#log-${$scope.page}-accordion${$scope.select_uniq}`).collapse();
            });
          });
        }

        /**
         * HTML 문자열 변환
         */
        $scope.trustHTML = (p0) => $sce.trustAsHtml(p0);
      }
    };
  });
