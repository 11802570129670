'use strict';

angular.module('gmpApp')
  .controller('AltalkSendGroupDetailCtrl', function(
    $scope, settings, commonSVC, data, $rootScope, $uibModalInstance
  ) {
    $scope.canAdd = false; // 회원 추가 유효성 체크 변수
    const search = {
      searchForm: {},
      searchData: {
        totalCount: 0,
        showCount: 25,
        selectCount: 0
      }
    };

    const custgroup_no = data.uniq;
    $scope.user = {
      name: '',
      htel: '',
      memo: ''
    };
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };
    $scope.grid.searchData = angular.copy(search.searchData);
    $scope.grid.searchForm = angular.copy(search.searchForm);
    $scope.grid.searchDetail = '';
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      gridHeight: 400,
      initPagingSize: 25,
      defaultSortingColumns: ['wdate'],
      alignCenterColumns: ['wdate'],
      alignRightColumns: [],
      alignLeftColumns: ['user_name', 'phone_num', 'memo'],
      notSortingColumns: ['user_name', 'phone_num', 'memo'],
      notVisibleColumns: [],
      notMovingColumns: [],
      selectOptions: {
        checkbox: true,
        multiSelect: true
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/atalk/group-detail`,
        requestWillAction: function (d) {
          const data = angular.merge({ custgroup_no }, d, $scope.searchForm, $scope.searchData);

          return data;
        },
        requestDidAction: function (result) {
          $scope.group = result.groupInfo;
          $scope.searchData.totalCount = result.recordsTotal;

          return result.result;
        }
      },
      columns: [
        {
          key: 'user_name',
          title: '회원명',
          width: 150
        },
        {
          key: 'phone_num',
          title: '전화번호',
          width: 150,
        },
        {
          key: 'memo',
          title: '메모',
          width: 200
        },
        {
          key: 'wdate',
          title: '추가일시',
          width: 150,
          filter: 'dateValid'
        }
      ]
    };

    /**
     * 발송 그룹 회원 추가 엑셀 업로드
     */
    $scope.excelUpload = () => {
      commonSVC.openModal('', { data: { custgroup_no } }, 'AltalkSendGroupExcelUploadCtrl', 'views/alimtalk/modals/send_group_excel_upload.html');

    };

    /**
     * ui-grid 노출 건수 설정
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 회원 추가
     */
    $scope.addUser = _.debounce(async () => {
      try {
        if (!$scope.user.name || !$scope.user.htel) {
          return commonSVC.showMessage('회원명과 전화번호는 필수 입력값입니다.');
        }

        const result = await commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/atalk/group/mem`, { user: [$scope.user], custgroup_no });

        if (result.data.results === 'success') {
          commonSVC.showToaster('success', '성공', '회원 추가 성공하였습니다.');
          $scope.user = {
            name: '',
            htel: '',
            memo: ''
          };
          $scope.canAdd = false;
        }
      } catch (err) {
        if (err.data.messages[0].includes('Duplicate')) {
          commonSVC.showMessage('중복된 회원이 있습니다.', '선택하신 그룹에 이미 동일 휴대폰 번호가 존재합니다.\n이미 추가된 회원의 경우 추가가 불가하며 확인 후 추가해 주세요.');
        } else {
          commonSVC.showMessage('회원 추가 실패하였습니다.');
        }
      }

      $scope.grid.methods.reloadData(function() {}, true);
    }, 1000);

    /**
     * 회원 추가 유효성 처리 함수
     */
    $scope.validityCheck = () => {
      // 전화번호가 잘못된 형식인지 체크
      const regex = /^([0-9]{3})-?([0-9]{4})-?([0-9]{4})$/;

      if ($scope.user.name && $scope.user.htel) {
        $scope.canAdd = true;
        if (!regex.test($scope.user.htel)) {
          $scope.canAdd = false;
        } else {
          $scope.user.htel = $scope.user.htel.replace(/^([0-9]{3})([0-9]{4})([0-9]{4})/, '$1-$2-$3');
        }
      } else {
        $scope.canAdd = false;
      }
    };

    /**
     * 그룹 삭제
     */
    $scope.deleteMem = async () => {
      const selectedMemList = $scope.grid.methods.selectedData().length > 0 ? $scope.grid.methods.selectedData().map(v => v.cust_seq) : [];
      // text: '추가한 그룹 인원도 모두 삭제되며 복구되지 않습니다.<div><span style="color: rgb(7,57,141);">삭제 전 해당 그룹으로 등록한 알림톡 발송 예약건은 발송</span>됩니다.</div>발송을 희망하지 않을 경우 전체발송내역 > 상세에서 삭제해 주세요.',
      const deleteMem = await commonSVC.showConfirmCustom({
        title: `선택한 ${selectedMemList.length}명의 회원을 삭제하시겠습니까?`,
        text: '삭제 시 복구되지 않습니다.<div><span style="color: rgb(7,57,141);">삭제 전 알림톡 예약 발송 등록했을 경우 삭제하더라도 알림톡 발송</span>됩니다.</div>발송을 희망하지 않을 경우 전체발송내역 > 상세에서 삭제해 주세요.',
        html: true,
        showCancelButton: true,
        confirmButtonText: '확인'
      });
      if (deleteMem) {
        try {
          const result = await commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/atalk/group/mem`, { userList: selectedMemList, custgroup_no });
          if (result.data.results === 'success') {
            commonSVC.showToaster('success', '성공', '삭제에 성공하였습니다.');
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '삭제에 실패하였습니다.');
        }

        $scope.grid.methods.reloadData(function () {}, true);
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.close('success');
    };
  });