'use strict';

/**
 * 바보사랑
 * 2018-04-04 rony
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B051-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B051categorySVC, errorSVC, commonSVC, settings, siteInfo) {
    const svc = B051categorySVC;

    $scope.CateList = angular.copy(svc.category_list);  // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);    // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);  // 카테고리 데이터

    $scope.dispCtg_list = angular.copy(svc.dispCtg_list);
    $scope.dispCtg_opt = angular.copy(svc.dispCtg_opt);
    $scope.dispCtg = angular.copy(svc.dispCtg);

    /**
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 하위카테고리 선택
     * @param { } index
     */
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd, 'cate');

      if (index === '4' || cateCd === 'noSub' || !cateCd) {
        return false;
      } // 마지막 분류이거나, 하위분류없을시

      loading('0', false);

      clearOpt(index, 'cate');

      setCateSub(index, cateCd, 'etc.scrap_category')
        .then(function() {
          loading('0', true);
          cateCd = 0;
        })
        .catch(function() {
          cateCd = 0;
          commonSVC.showMessage('로딩실패');
          loading('0', true);
        });
    };

    /**
     * 하위 전시카테고리 선택
     */
    $scope.dispCateSelect = function(index) {
      const cateType = $scope.dispCtg_list[index]; // 선택 카테고리 타입
      let cateCd = $scope.dispCtg[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd, 'dispCate');

      // 마지막 분류이거나, 하위분류없을시
      if (index === '3' || cateCd === 'noSub' || !cateCd) {
        return false;
      }

      loading('1', false);

      clearOpt(index, 'dispCate');

      setCateSub(index, cateCd, 'etc.scrap_disp_category')
        .then(function() {
          loading('1', true);
          cateCd = 0;
        })
        .catch(function() {
          cateCd = 0;
          commonSVC.showMessage('로딩실패');
          loading('1', true);
        });
    };

    /**
     * 카테고리 이름 설정
     */
    function cateSetting(index, cateCd, type) {
      if (cateCd === 'noSub') {
        return false;
      }

      let name = '';
      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        // 선택 카테고리 이름
        if (type == 'cate') {
          name = $(`#CatMenu_${i}`).find('option:selected').text();
        } else {
          name = $(`#DispCatMenu_${i}`).find('option:selected').text();
        }

        if (!name) {
          break;
        }
        nameArr.push(name);
      }

      if (type == 'cate') {
        $scope.CateData.category_names = nameArr.join('>');
      } else {
        $scope.dispCtg.dispCtgName = nameArr.join('-');
      }
    }

    /**
     * 하위 카테고리 가져오기
     */
    const setCateSub = async (index, cateCd, action) => {
      index = index * 1;
      const checkCodes = cateCd.split('-');

      const params = {
        level: index + 2,
        code1: checkCodes[0],
        code2: checkCodes[1] || '',
        code3: checkCodes[2] || '',
        code4: checkCodes[3] || ''
      };

      const res = await commonSVC.requestPA($scope.userInfo, siteInfo.B051.domain, action, params, commonModel, shopAccountModel);

      const data = res.data.data.items;

      $timeout(() => {
        if (action == 'etc.scrap_category') {
          $scope.CateOpt[index + 1] = data.map(o => {
            o.name = o[`name${index + 2}`];

            return o;
          });
        } else {
          $scope.dispCtg_opt[index + 1] = data.map(o => {
            o.name = o[`name${index + 2}`];

            return o;
          });
        }
      });

    };

    /**
     * 옵션 초기화
     */
    function clearOpt(index, type) {
      const start = parseInt(index) + 1;

      if (type == 'cate') {
        for (let i = start; i < 5; i++) {
          const key = $scope.CateList[i];

          $scope.CateOpt[i] = {};
          $scope.CateData[key] = '';
        }
      } else {
        for (let i = start; i < 4; i++) {
          const key = $scope.dispCtg_list[i];

          $scope.dispCtg_opt[i] = {};
          $scope.dispCtg[key] = '';
        }
      }
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      // 표준 카테고리
      let cateCd = $scope.CateData.key_dcate;

      if ($scope.CateData.key_ecate != '' && $scope.CateData.key_ecate != 'noSub') {
        cateCd = $scope.CateData.key_ecate;
      }
      if (cateCd == '') {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }
      $scope.CateData.detailData.key_cateNm = $scope.CateData.category_names.replace(/>/g, '-');
      $scope.CateData.detailData.key_cateCd = cateCd;
      $scope.CateData.category_code = cateCd;

      // 전시카테고리
      const dispCateCd = $scope.dispCtg.key_dispDcate;

      if (dispCateCd == '') {
        commonSVC.showMessage('실패', '전시 카테고리를 끝까지 선택해 주십시오.');

        return false;
      }
      $scope.CateData.detailData.key_viewCd = dispCateCd;
      $scope.CateData.detailData.key_viewNm = $scope.dispCtg.dispCtgName;

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateCd;
      // 카테고리 리스트 저장
      // $scope.CateData.detailData.Cate = $scope.Cate;

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B051',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);
            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    // 초기화
    function init() {
      // 로딩중
      loading('0', false); // 표준카테고리 로딩중
      loading('1', false); // 전시카테고리 로딩중

      // 카테고리정보
      let category = [];
      let dispCategory = [];

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(async (res) => {
          $scope.userInfo = res.data.results;

          const cate = await commonSVC.requestPA($scope.userInfo, siteInfo.B051.domain, 'etc.scrap_category', {}, commonModel, shopAccountModel);

          category = cate.data.data.items;

          // 전시카테고리 조회
          const dispCate = await commonSVC.requestPA($scope.userInfo, siteInfo.B051.domain, 'etc.scrap_disp_category', {}, commonModel, shopAccountModel);

          dispCategory = dispCate.data.data.items;

          if (!category) {
            commonSVC.showMessage('카테고리 로딩실패');
            loading('0', true);

            return false;
          } else if (!dispCategory) {
            commonSVC.showMessage('전시 카테고리 로딩실패');
            loading('1', true);

            return false;
          } else {
            $scope.CateOpt[0] = _.filter(category, function(o) {
              return o.name = o.name1;
            });          // 표준카테고리
            $scope.dispCtg_opt[0] = _.filter(dispCategory, function(o) {
              return o.name = o.name1;
            });  // 전시카테고리

            if ($scope.matchingData.shop_cate_no_add_info) {
              if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
                matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
              } else {
                matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
              }
            } else if (!$scope.matchingData.sol_cate_shop_add_info) {
              matchDetailDataLoad(angular.copy(B051categorySVC.category_data));
            } else {
              matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
            }
          }
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        });
    }

    const matchDetailDataLoad = async (addInfo) => {
      loading('0', false);
      loading('1', false);

      $scope.CateData.detailData = addInfo;

      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;
      $scope.Cate = addInfo.Cate;

      if (!$scope.CateData.category_code) {
        loading('0', true);
        loading('1', true);

        return false;
      }

      // 카테고리
      const splitCd = addInfo.category_code.split('-');

      await $timeout(async () => {
        try {
          const keys = ['key_lcate', 'key_mcate', 'key_scate', 'key_dcate'];

          for (let idx in keys) {
            if (idx > splitCd.length - 1) {
              break;
            }

            idx = parseInt(idx);

            $scope.CateData[keys[idx]] = splitCd.slice(0, idx + 1).join('-');

            await setCateSub(idx, $scope.CateData[keys[idx]], 'etc.scrap_category');
          }

          if (splitCd.length === 5) {
            $scope.CateData.key_ecate = splitCd.join('-');
          }

          loading('0', true);
        } catch (err) {
          commonSVC.showMessage(`카테고리 로딩실패${err}`);
        }

        try {

          if (!addInfo.key_viewCd) {
            loading('1', true);

            return false;
          }

          const splitDisCd = addInfo.key_viewCd.split('-');
          const keys = ['key_dispLcate', 'key_dispMcate', 'key_dispScate'];

          for (let idx in keys) {
            if ((!$scope.CateData.category_names && idx > 0) || idx > splitDisCd.length - 1) {
              break;
            }

            idx = parseInt(idx);

            $scope.dispCtg[keys[idx]] = splitDisCd.slice(0, idx + 1).join('-');
            await setCateSub(idx, $scope.dispCtg[keys[idx]], 'etc.scrap_disp_category');
          }

          if (splitDisCd.length === 4) {
            $scope.dispCtg.key_dispDcate = splitDisCd.join('-');
          }

          loading('1', true);
        } catch (err) {
          commonSVC.showMessage(`전시 카테고리 로딩실패${err}`);
        }
      });

      loading('0', true);
      loading('1', true);
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    init();
  });
