'use strict';

angular.module('gmpApp')
  .controller('EtcSubAuthCtrl', function ($scope, $uibModalInstance, commonSVC, commonModel, data, detectBrowser) {
    let timer;
    const INIT_LIMIT_TIME_SMS = 60 * 5,
          INIT_LIMIT_TIME_EMAIL = 60 * 15; // 메일 수신 지연되는 경우가 있어 15분으로 설정

    $scope.sendData = data.sendData;

    $scope.isNotExistHtel = !$scope.sendData.htel;

    if (!$scope.isNotExistHtel) {
      const htelUnit = $scope.sendData.htel.match(/(\d{3})(\d{2,3})(\d{1})(\d{4})/);

      $scope.maskedHtel = htelUnit ? `${htelUnit[1]}-${htelUnit[2].replace(/./g, '*')}${htelUnit[3]}-${htelUnit[4]}` : '***';
    }

    function init () {
      $scope.subAuthData = {
        key: '',
        isVerify: false,
        limitTime: $scope.sendTypeValuer(INIT_LIMIT_TIME_SMS, INIT_LIMIT_TIME_EMAIL),
        limitString: moment(1000 * $scope.sendTypeValuer(INIT_LIMIT_TIME_SMS, INIT_LIMIT_TIME_EMAIL)).format('mm:ss'),
        error: $scope.isNotExistHtel ? '저장된 휴대폰 번호가 없어 이메일로 전송되었습나다.' : ''
      };

      clearInterval(timer);

      timer = setInterval(() => {
        $scope.subAuthData.limitString = moment(1000 * --$scope.subAuthData.limitTime).format('mm:ss');

        if (!$scope.subAuthData.limitTime) {
          clearInterval(timer);

          $scope.subAuthData.error = '인증 시간이 만료되었습니다. 재전송 버튼을 눌러주세요.';
        }

        $scope.$apply();
      }, 1000);
    }

    $scope.sendTypeValuer = (smsData, emailData) => {
      return $scope.sendData.sendType === 'sms' ? smsData : emailData;
    };

    // 전송
    $scope.sendSubAuth = () => {
      commonModel.sendSubAuth($scope.sendData)
        .then(() => {
          init();

          commonSVC.showMessage('성공', `2차 인증에 필요한 인증번호가 ${$scope.sendTypeValuer('SMS', '이메일')}로 발송되었습니다.`);
        })
        .catch(() => {
          $scope.subAuthData.error = '인증 번호 재전송에 실패했습니다.';
        });
    };

    // 다른 타입 전송
    $scope.sendOtherTypeSubAuth = () => {
      $scope.sendData = {
        ...$scope.sendData,
        sendType: $scope.sendTypeValuer('email', 'sms'),
        to: $scope.sendTypeValuer($scope.sendData.email, $scope.sendData.htel)
      };

      $scope.sendSubAuth();
    };

    // 확인
    $scope.verifySubAuth = () => {
      const params = {
        ...$scope.sendData,
        key: $scope.subAuthData.key,
        ...({ ...detectBrowser })
      };

      if (params.key.length !== 6) {
        $scope.subAuthData.error = '6자리 숫자로 입력하세요.';

        return;
      }

      commonModel.verifySubAuth(params)
        .then(() => {
          $scope.subAuthData.isVerify = true;

          clearInterval(timer);
          $uibModalInstance.close('success');
        })
        .catch(() => {
          $scope.subAuthData.error = '유효하지 않은 인증번호 입니다.';
        });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss();
    };

    init();
  });
