'use strict';

angular.module('gmpApp')
  /**
   * 공급가 관리 규칙 추가 모달 -> 판매금액 관리 규칙 추가 모달
   * 2020-03-04 Amelia -> 2024-01-05 Felix
   */
  .controller('SupplyPriceRuleAddCtrl', function ($scope, $rootScope, data, $timeout, $uibModalInstance, userInfo,
    commonSVC,
    warehouseList,
    onlineProductModel, shipmentModel, productModel, categoryModel, systemModel) {
    const channelListArr = $rootScope.use_channel_list.filter(channel => !channel.pa_shop_cd.startsWith('X'));
    let needRefresh = false, // 모달 종료시 새로고침 필요 여부
        dupCheckYn = false, // 규칙 중복체크 완료됐는지 여부
        oriRuleType = '', // '수정'일때 원본 규칙타입
        oriSiteList = []; // '수정'일때 원본 적용범위(ID리스트)

    let isSearchWordPasted = false; // 검색어가 복사붙여넣기 되어 들어왔는지 여부

    // 판매금액 계산 규칙 수식 변수명
    $scope.formulaKeywords = ['실결제금액', '금액', '할인금액', '원가', '공급가', '배송비', '수량'];
    $scope.notChange = false;
    $scope.type = data.type;
    $scope.addColList = angular.copy(data.addColList);
    $scope.targetChange = false;
    $scope.shop_cds = [{ shop_id: '', shop_cd: 'A000', pa_shop_cd: 'A000', shop_name: '직접입력' }, ...(_.uniqBy(channelListArr, 'shop_cd')).map(v => ({ shop_id: v.shop_id, shop_cd: v.shop_cd, pa_shop_cd: v.pa_shop_cd, shop_name: v.shop_name })), ...(_.uniqBy(userInfo.user.manualOrderShops.filter(({ shop_cd }) => shop_cd.startsWith('U')).map(v => ({ shop_cd: v.shop_cd, shop_id: v.shop_id, pa_shop_cd: v.pa_shop_cd, shop_name: v.shop_name })), 'shop_cd'))];
    $scope.shop_ids = channelListArr.filter(shop => shop.shop_cd === $scope.shop_cds[0].shop_cd);
    $scope.shop_id_view_type = data.shop_id_view_type;
    // 우측 상품코드 테이블 관련 flag
    $scope.olProdFlag = {
      allOlProdSelected: false, // 쇼핑몰 전체상품 적용여부
    };

    // 중복규칙 온라인상품
    $scope.dupOlProds = {
      sol_shop: [],
      ol_shop: []
    };

    $scope.addData = {
      supply_price_rule_no: null,
      shop_cd: '',
      site_list: [],
      rule_type: 'sol_shop',
      apply_type: '수동',
      supply_rate_type: 'formula',
      fee_rate: 0,
      formula: '',
      olProds: [],
      formula_target: ''      // 공급가, 실결제금액, 할인금액, 커스텀 필드(addcol테이블 내 input_type이 int인 컬럼)
    };

    $scope.addOlProdDatas = [];

    $scope.changeTarget = () => {
      if ($scope.targetChange) {
        $scope.dupOlProds.sol_shop = []; // 중복 규칙 상품 리스트
        $scope.dupOlProds.ol_shop = [];

        dupCheckYn = false;
      }
    };

    $scope.editCheck = () => {
      $timeout(() => {
        if ($scope.originData.apply_type === $scope.addData.apply_type && $scope.originData?.formula === $scope.addData?.formula && $scope.originData.fee_rate === $scope.addData.fee_rate) {
          $scope.notChange = false;
        } else {
          $scope.notChange = true;
        }
      });
    };

    // $viewContentLoaded : Emitted every time the ngView content is reloaded.
    // select2 : (쇼핑몰 상품 등록 > 쇼핑몰 계정 선택) 형태의 특수한 select box
    // select2를 로딩이 끝난 뒤에 설정해줌
    $scope.$watch('$viewContentLoaded', function () {
      $timeout(function () {
        $('.select-fixed-multiple').select2({
          minimumResultsForSearch: Infinity,
          width: '100%',
          placeholder: '적용할 쇼핑몰의 계정 선택',
          closeOnSelect: false
        });

        // 쇼핑몰 선택시
        $('.reg-channel-select').on('select2:select', function (e) {
          const selected = $(this).val(),
                site_list = [];

          // 선택된 데이터
          const lastSelected = e.params.data.id;
          $scope.notChange = true;
          $scope.searchWord = ''; // 상품코드 검색 키워드
          dupCheckYn = false;

          $scope.dupOlProds.sol_shop = []; // 중복 규칙 상품 리스트
          $scope.dupOlProds.ol_shop = [];
          $scope.addData.olProds = [];
          // '쇼핑몰 계정 전체 적용(ALL)' 선택했을경우
          if (lastSelected === 'ALL') {
            // 'ALL'만 남기고 선택해제
            $(this).val('ALL').trigger('change');
            $(this).select2('close');

            $scope.addData.site_list = ['ALL'];

            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }

            return;
          }

          // '쇼핑몰 계정 전체 적용(ALL)' 선택되어있는 상태에서 다른 아이디 클릭했을 경우
          if (lastSelected !== 'ALL' && selected[0] === 'ALL') {
            // 'ALL' 선택해제
            selected.splice(0, 1);
            $(this).val(selected).trigger('change');
            $(this).select2('close');

            const shopId = $scope.shop_ids[parseInt(lastSelected)].shop_id;

            // 선택된 상품코드 중 선택된 계정 상품코드만 남김
            $scope.addData.olProds = $scope.addData.olProds.filter(({ shop_id }) => shop_id === shopId) || [];
          }

          for (const s of selected) {
            const idx = parseInt(s),
                  row = $scope.shop_ids[idx];

            site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id });
          }

          $scope.addData.site_list = site_list;
          $scope.addData.olProds = [];
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        });

        // 쇼핑몰 선택해제시
        $('.reg-channel-select').on('select2:unselect', function (e) {
          const selected = $(this).val(),
                site_list = [];
          $(this).val(selected).trigger('change');
          $('.select2-search__field').click();
          $timeout(() => {});
          $scope.searchWord = ''; // 상품코드 검색 키워드
          dupCheckYn = false;
          $scope.notChange = true;
          $scope.dupOlProds.sol_shop = []; // 중복 규칙 상품 리스트
          $scope.dupOlProds.ol_shop = [];
          $scope.addData.olProds = [];
          if (!selected.length) {

            $scope.addData.olProds = [];
            $scope.addData.site_list = [];

            $scope.$apply();

            return;
          }

          const lastSelected = e.params.data.id,
                shopId = $scope.shop_ids[parseInt(lastSelected)].shop_id;

          // 선택된 상품코드 중 선택해제된 계정 상품코드는 걸러냄
          $scope.addData.olProds = $scope.addData.olProds.filter(({ shop_id }) => shop_id !== shopId) || [];

          for (const s of selected) {
            const idx = parseInt(s),
                  row = $scope.shop_ids[idx];

            site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id });
          }

          $scope.addData.site_list = site_list;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        });

        $('.olProdSearchBox').on('paste', function(e) {
          const el = $(this);
          const prevTxt = el.val();

          const selectionStart = this.selectionStart;
          const selectionEnd = this.selectionEnd;

          setTimeout(function() {
            // 붙여넣기 한 텍스트 추출
            const pastedTxt = el.val().replace(prevTxt, '');

            if (selectionEnd - selectionStart !== 0) {
              $scope.searchWord = (prevTxt + pastedTxt).slice(selectionStart + selectionEnd);
            } else {
              $scope.searchWord = prevTxt + pastedTxt;
            }
            el.val($scope.searchWord);

            isSearchWordPasted = true;
          }, 0);
        });
      }, 0);
    });

    function init () {
      $timeout(async () => {
        $scope.olProdFlag.allOlProdSelected = true; // 쇼핑몰 상품코드 미입력 시 전체 상품 규칙 반영 - 초기값 true로 설정

        if (data.type === 'edit') {
          $scope.notChange = false;
          const re = await shipmentModel.supplyPriceRuleDetail(data.supply_price_rule_no);
          $timeout(() => {});
          if (!re.error_code) {
            const { base, rule, dup } = re.data.results;

            $scope.addData.supply_price_rule_no = base.supply_price_rule_no;
            $scope.addData.apply_type = base.apply_type;
            $scope.addData.rule_type = base.rule_type;
            $scope.addData.shop_cd = rule[0].shop_cd;
            $scope.addData.formula_target = base.formula_target;
            $scope.shop_ids = channelListArr.filter(shop => shop.shop_cd === $scope.addData.shop_cd);
            $scope.searchWord = base.searchWord;
            $scope.addData.olProds = base.searchWord ? base.searchWord.split('\n') : [];
            const rule_shop_list = rule.map(r => r.shop_id),
                  site_list = $scope.shop_ids.reduce((acc, cur, i) => {
                    if (rule_shop_list.includes(cur.shop_id)) {
                      acc.push({ ...cur, idx: i });
                    }

                    return acc;
                  }, []);

            $scope.addData.site_list = site_list.length ? site_list : ['ALL'];
            if (base.formula_target === 'shop_supply_price' && !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(base.formula)) {
              $scope.addData.supply_rate_type = 'rate';
              $scope.addData.fee_rate = base.formula;
            } else {
              $scope.addData.supply_rate_type = 'formula';
              $scope.addData.formula = base.formula;
            }
            oriSiteList = $scope.addData.site_list;
            oriRuleType = $scope.addData.rule_type;
            $timeout(() => {});
            if (base.rule_type === 'sol_shop') {
              $scope.dupOlProds.ol_shop = dup.reduce((acc, cur) => {
                if (cur.dup_rule_no) {
                  acc.push({
                    ol_shop_no: cur.ol_shop_no,
                    shop_sale_no: cur.shop_sale_no,
                    shop_cd: cur.shop_cd,
                    shop_id: cur.shop_id,
                    dup_rule_no: cur.dup_rule_no, // 중복 규칙코드
                    dup_type: '제외', // 중복 방지방식
                    dup_rule_type: 'ol_shop',
                  });
                }

                return acc;
              }, []);

              $scope.olProdFlag.allOlProdSelected = true;
            }

            if (base.rule_type === 'ol_shop') {
              $scope.addData.olProds = rule;
            }

            $timeout(() => {
              $('.reg-channel-select').val(site_list.length ? site_list.map(({ idx }) => idx) : 'ALL').trigger('change');
            });
          } else {
            commonSVC.showToaster('error', '', '판매금액 관리 규칙 조회 실패');
          }
        }
      });

      $timeout(() => {
        $scope.originData = angular.copy($scope.addData);
        $scope.originSearchWord = angular.copy($scope.searchWord);
      });
    }

    init();

    /**
     * 쇼핑몰 변경
     */
    $scope.shopCdSelected = () => {
      valueInit($scope.addData.shop_cd);
    };

    /**
     * 값 초기화
     */
    function valueInit(shop_cd) {
      $timeout(() => {
        $scope.addData.shop_cd = shop_cd;
        $scope.shop_ids = channelListArr.filter(shop => shop.shop_cd === $scope.addData.shop_cd);
        $scope.addData.site_list = [];
        $scope.addData.olProds = [];
        $scope.addData.rule_type = 'sol_shop';
        $scope.addData.fee_rate = 0;
        $scope.addData.formula = '';
        $scope.addData.formula_target = '';
        $scope.olProdList = [];
        $scope.targetChange = false;
        $('.reg-channel-select').val([]).trigger('change');

        $scope.olProdFlag.showShadowBox = true; // 우측 상품코드 검색 필드 shadow box 노출여부
        $scope.olProdFlag.showOlSearchList = false; // 상품코드 검색 결과 리스트 노출여부
        $scope.olProdFlag.searchWordClicked = false; // 우측 상품코드 검색어 필드 클릭여부
        $scope.olProdFlag.allOlProdSelected = true; // 쇼핑몰 상품코드 미입력 시 전체 상품 규칙 반영 - 초기값 true로 설정

        $scope.searchWord = ''; // 상품코드 검색 키워드

        $scope.dupOlProds.sol_shop = []; // 중복 규칙 상품 리스트
        $scope.dupOlProds.ol_shop = [];

        dupCheckYn = false;
      });
    }

    /**
     * 쇼핑몰계정 변경
     */
    $scope.shopIdSelected = () => {
      // 선택한 상품리스트 초기화
      $scope.addData.olProds = [];

      // 재검색
      $scope.olProdSearch();

      dupCheckYn = false;
    };

    /**
     * 쇼핑몰 상품 검색
     */
    $scope.olProdSearch = () => {
      $timeout(() => {
        const searchWords = $scope.searchWord ? $scope.searchWord.split('\n').map(word => word.trim()).filter(Boolean) : [];
        if (searchWords.length) {
          $scope.addData.olProds = searchWords;
          $scope.addData.rule_type = 'ol_shop';
        } else {
          $scope.olProdList = [];
          $scope.addData.olProds = [];
        }

        // 변경사항 없으면 수정 모달 규칙저장 비활성화
        if ($scope.originData.apply_type === $scope.addData.apply_type && $scope.originData?.formula === $scope.addData?.formula && $scope.originData.fee_rate === $scope.addData.fee_rate) {
          if ($scope.originSearchWord) {
            if ($scope.originSearchWord.split('\n') === searchWords) {
              $scope.notChange = false;
            } else {
              $scope.notChange = true;
            }
          }
        } else {
          $scope.notChange = true;
        }
      });
    };

    /**
     * 검색결과 필터
     */
    $scope.olProdSearchFilter = (c) => {
      return !$scope.addData.olProds.map((prod) => prod.ol_shop_no).includes(c.ol_shop_no);
    };

    /**
     * 규칙에 상품추가
     */
    $scope.addOlProd = (prod) => {
      $scope.olProdFlag.allOlProdSelected = false;

      $scope.addData.olProds.push(prod);
      $scope.addData.rule_type = 'ol_shop';

      const selectedOlNos = $scope.addData.olProds.map((prod) => prod.ol_shop_no);

      $scope.olProdFlag.showOlSearchList = !!$scope.olProdList.find(({ ol_shop_no }) => !selectedOlNos.includes(ol_shop_no));

      dupCheckYn = false;
      $scope.dupOlProds.ol_shop = [];

      // 추가시 리스트 하단으로 자동 스크롤
      $timeout(() => {
        $('.olProd').scrollTop($('.olProd')[0].scrollHeight);
      }, 100);
    };

    /**
     * 규칙에서 상품제거
     */
    $scope.removeOlProd = (prod) => {
      $scope.addData.olProds.splice($scope.addData.olProds.findIndex(p => p.shop_sale_no === prod.shop_sale_no), 1);

      if (!$scope.addData.olProds.length) {
        $scope.addData.rule_type = 'sol_shop';
      }

      const selectedOlNos = $scope.addData.olProds.map((prod) => prod.ol_shop_no);

      $scope.olProdFlag.showOlSearchList = !!$scope.olProdList.find(({ ol_shop_no }) => !selectedOlNos.includes(ol_shop_no));

      if ($scope.dupOlProds.ol_shop.length && $scope.dupOlProds.ol_shop.findIndex(d => d.ol_shop_no === prod.ol_shop_no)) {
        $scope.dupOlProds.ol_shop.splice($scope.dupOlProds.ol_shop.findIndex(d => d.ol_shop_no === prod.ol_shop_no), 1);
      }
    };

    /**
     * 온라인상품 상세 열기
     */
    $scope.openOlProd = (shop_cd, shop_id, ol_shop_no) => {
      if (!ol_shop_no) {
        commonSVC.showMessage('존재하지 않는 상품입니다.');

        return false;
      }

      const resolve = {
        data: {
          shop_cd,
          shop_id,
          ol_shop_no,
          warehouseList: warehouseList.data.result || []
        }
      };

      resolve.prodDetail = () => {
        return onlineProductModel.detail(shop_cd, shop_id, resolve.data.ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = () => {
        return productModel.getMadein();
      };
      // 카테고리 리스트 조회
      resolve.categoryList = () => {
        return categoryModel.categorySelectList();
      };
      // 환경설정 셋팃값
      resolve.systemList = () => {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');
    };

    /**
     * 상품코드 검색필드 dim부분 클릭
     */
    $scope.shadowBoxClick = () => {
      $scope.olProdFlag.showShadowBox = false;

      // 계정 중 가장 첫번째에 있는거 자동선택 & 리스트오픈
      $('#shopList').val('0').trigger('change');
      $('#shopList').select2('open');
      $scope.addData.site_list = [{ shop_cd: $scope.shop_ids[0].shop_cd, shop_id: $scope.shop_ids[0].shop_id }];
    };

    /**
     * 중복 방지 방식 일괄 적용 (적용/제외)
     */
    $scope.batchDupType = (dup_type) => {
      $scope.dupOlProds.sol_shop.forEach(prod => prod.dup_type = dup_type);
      $scope.dupOlProds.ol_shop.forEach(prod => prod.dup_type = dup_type);
    };

    /**
     * 중복검사 데이터 만들기
     */
    function setDupData(params) {
      params.dupOlProds = angular.copy($scope.dupOlProds);
      // --------------상품별적용일 경우--------------
      if (params.rule_type === 'ol_shop') {
        const filterKeys = {
          shop_ids: [],
          ol_shop_nos: []
        }; // 제외할 리스트

        // 중복규칙 '제외' 필터링
        for (const key in params.dupOlProds) {
          params.dupOlProds[key] = params.dupOlProds[key].filter(({ dup_type, shop_id, ol_shop_no }) => {
            if (dup_type === '제외') {
              filterKeys[ol_shop_no ? 'ol_shop_nos' : 'shop_ids'].push(ol_shop_no || shop_id);

              return false;
            } else {
              return true;
            }
          });
        }

        // 선택된 상품 리스트 '제외' 필터링
        params.olProds = params.olProds.filter(({ ol_shop_no, shop_id }) => !filterKeys.shop_ids.includes(shop_id) && !filterKeys.ol_shop_nos.includes(ol_shop_no));

        // 중복규칙에 남은 값이 없으면 리턴
        if (!params.olProds.length) {
          commonSVC.showMessage('유효한 규칙이 없습니다.');

          return false;
        }
      } else {
        // --------------쇼핑몰별적용일 경우--------------
        const isShopAll = $scope.addData.site_list[0] === 'ALL'; // 쇼핑몰 전체 적용인지

        if (isShopAll) {
          params.site_list = $scope.shop_ids.map(({ shop_id }) => shop_id);
        }
        if (params.shop_cd === 'A000') {
          params.site_list = ['ALL'];
        }

        // 중복쇼핑몰/ID가 있는경우
        if (params.dupOlProds.sol_shop.length) {
          // 중복규칙이 쇼핑몰이고 제외를 선택한경우 리턴
          if (params.dupOlProds.sol_shop[0].shop_id && params.dupOlProds.sol_shop[0].dup_type === '제외') {
            commonSVC.showMessage('유효한 규칙이 없습니다.');

            return false;
          }

          //
          const filterIds = params.dupOlProds.sol_shop.filter(({ dup_type }) => dup_type === '제외').map(({ shop_id }) => shop_id);
          params.site_list = params.site_list.filter(site => !filterIds.includes(site));
          params.dupOlProds.sol_shop = params.dupOlProds.sol_shop.filter(({ dup_type }) => dup_type !== '제외');

          // 쇼핑몰/ID가 모두 제외된 경우 리턴
          if (!params.site_list.length && !params.dupOlProds.sol_shop[0]?.shop_cd.startsWith('U')) {
            commonSVC.showMessage('유효한 규칙이 없습니다.');

            return false;
          }

          // 중복규칙이 쇼핑몰이고 중복규칙에서 제거시킬때 쇼핑몰 -> ID(나머지ID들)로 변경해야함
          if (params.dupOlProds.sol_shop[0] && !params.dupOlProds.sol_shop[0].shop_id) {
            params.dupOlProds.sol_shop[0].site_list = $scope.shop_ids.map(({ shop_id }) => shop_id).filter((shop_id) => !params.site_list.includes(shop_id));
            params.dupOlProds.sol_shop[0].needTran = !!params.dupOlProds.sol_shop[0].site_list.length;
          }

          // 중복 상품코드 중 '제외'선택된 ID 상품 제거
          params.dupOlProds.ol_shop = params.dupOlProds.ol_shop.filter(({ shop_id }) => !filterIds.includes(shop_id));
        }

        // 쇼핑몰 전체 적용인데 사이트아이디 다 들어가있으면 'ALL' 보내줌
        if (isShopAll && params.site_list.length === $scope.shop_ids.length) {
          params.site_list = ['ALL'];
        }
      }

      return true;
    }

    /**
     * 규칙 저장
     */
    $scope.save = async () => {
      // 상품코드 미입력 시 전체 상품코드
      if (!$scope.addData.olProds.length) {
        $scope.addData.rule_type = 'ol_shop';
      } else {
        $scope.addOlProdDatas = $scope.addData.site_list.map(ch => {
          return $scope.addData.olProds.map(ol => ({ shop_cd: ch.shop_cd, shop_id: ch.shop_id, shop_sale_no: typeof ol === 'string' ? ol : ol.shop_sale_no }));
        }).flat();

      }
      // 수식일 경우 validation 진행
      if ($scope.addData.supply_rate_type === 'formula') {
        if (!$scope.addData.formula) {
          commonSVC.showMessage('수식을 입력해주십시오.');

          return false;
        }

        try {
          // 수식기호 임의로 1로 바꿈
          eval($scope.addData.formula.replace(new RegExp($scope.formulaKeywords.join('|'), 'gi'), () => 1));
        } catch (err) {
          commonSVC.showMessage('수식이 올바르지 않습니다.');

          return false;
        }
      } else {
        if (!$scope.addData.fee_rate.split('.')[1]) {
          $scope.addData.fee_rate = `${$scope.addData.fee_rate.split('.')[0]}.00`;
        }
      }

      if ($scope.addData.shop_cd === 'A000') {
        $scope.addOlProdDatas = $scope.addOlProdDatas.map(prod => ({ ...prod, shop_cd: 'A000', shop_id: '' }));
      }

      const params = {
        shop_cd: $scope.addData.shop_cd,
        site_list: $scope.addData.site_list.filter(({ shop_id }) => shop_id).map(({ shop_id }) => shop_id),
        apply_type: $scope.addData.apply_type,
        formula: $scope.addData.supply_rate_type === 'rate' ? $scope.addData.fee_rate : $scope.addData.formula,
        olProds: $scope.addOlProdDatas,
        formula_target: $scope.addData.formula_target
      };

      if (!params.olProds.length) {
        $scope.addData.rule_type = 'sol_shop';
      }
      params.rule_type = $scope.addData.rule_type;

      if (data.supply_price_rule_no) {
        params.supply_price_rule_no = data.supply_price_rule_no;
      }

      // ---------------------------중복체크전--------------------------- //
      if (!dupCheckYn) {
        if (data.type === 'edit') {
          setDupData(params);
          // 규칙 적용범위(ID) 변경사항 있는지
          params.isSiteChg = !(params.site_list.length === oriSiteList.length
                              && params.site_list.every((s, i) => {
                                return (typeof s === 'object' ? s.shop_id : s) === (typeof oriSiteList[i] === 'object' ? oriSiteList[i].shop_id : oriSiteList[i]);
                              }));
          params.isRuleTypeChg = oriRuleType === 'sol_shop' && params.rule_type === 'ol_shop';
        }

        try {
          const re = await shipmentModel.checkDupSupplyPriceRule(params);

          if (re.data) {
            const result = re.data.results;

            if (Array.isArray(result)) { // 중복규칙이 있을경우(result가 array로 들어옴)
              for (const rule of result) {
                $scope.targetChange = true;

                if ($scope.addData.rule_type === 'ol_shop') {
                  for (const prod of $scope.addOlProdDatas) {
                    const isSameOlShop = rule.rule_type === 'ol_shop' && rule.shop_sale_no === prod.shop_sale_no && rule.shop_cd === prod.shop_cd && rule.shop_id === prod.shop_id,
                          isSameSolShop = rule.rule_type === 'sol_shop' && (!rule.shop_id || rule.shop_id === prod.shop_id),
                          isInOlShopExptNo = rule.shop_sale_no_except && rule.shop_sale_no_except.includes(prod.shop_sale_no), // 중복인 기존규칙에서 예외로 등록된 상품인지
                          isInDupSolShop = $scope.dupOlProds.sol_shop.map(({ shop_sale_no }) => shop_sale_no).includes(prod.shop_sale_no);

                    // 새규칙의 ol_shop_no와 일치하거나 / 기존규칙이 쇼핑몰/ID 적용이면서 예외상품에 새규칙 ol_shop_no가 포함되지 않는 경우
                    if (isSameOlShop || (isSameSolShop && !isInOlShopExptNo && !isInDupSolShop)) {
                      $scope.dupOlProds.ol_shop.push({
                        shop_sale_no: rule.shop_sale_no || prod.shop_sale_no,
                        shop_cd: $scope.addData.shop_cd,
                        shop_id: $scope.addData.shop_cd === 'A000' ? '직접입력' : rule.shop_id,
                        dup_rule_no: rule.supply_price_rule_no, // 중복 규칙코드
                        dup_type: '제외', // 중복 방지방식
                        dup_rule_type: rule.rule_type,
                        shop_name: rule.shop_name
                      });
                    }
                  }
                } else {
                  $scope.dupOlProds[rule.rule_type].push({
                    shop_sale_no: rule.shop_sale_no,
                    shop_cd: rule.shop_cd || rule.os_shop_cd,
                    shop_id: rule.shop_cd === 'A000' ? '직접입력' : rule.shop_id || rule.os_shop_id,
                    dup_rule_no: rule.supply_price_rule_no, // 중복 규칙코드
                    dup_type: '제외', // 중복 방지방식
                    dup_rule_type: rule.rule_type,
                    shop_name: rule.shop_name,
                    ol_shop_no_except: rule.ol_shop_no_except
                  });
                }
              }

              dupCheckYn = true;
            } else { // 중복규칙 없을 경우
              valueInit();
              commonSVC.showToaster('success', '성공', `판매금액 관리 규칙 ${data.type === 'edit' ? '수정' : '등록'} 성공`);
              needRefresh = true;
              $uibModalInstance.close('success');
            }
          } else {
            commonSVC.showToaster('error', '', '중복규칙 조회 실패');
          }
        } catch (err) {
          commonSVC.showToaster('error', '', '중복규칙 조회 실패');
        }
      } else {
        // ---------------------------중복체크후--------------------------- //
        const rslt = setDupData(params);
        if (!rslt) {
          return false;
        }

        // 규칙 등록
        try {
          const re = await shipmentModel[data.type !== 'edit' ? 'addSupplyPriceRule' : 'editSupplyPriceRule'](params);
          if (re.data.results === 'success') {
            commonSVC.showToaster('success', '', `판매금액 관리 규칙 ${data.type === 'edit' ? '수정' : '등록'} 성공`);
            needRefresh = true;
            valueInit();

            return $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '', `판매금액 관리 규칙 ${data.type === 'edit' ? '수정' : '등록'} 실패`);
          }
        } catch (err) {
          commonSVC.showToaster('error', '', `판매금액 관리 규칙 ${data.type === 'edit' ? '수정' : '등록'} 실패`);
        }
      }
    };

    /**
     * 취소
     */
    $scope.cancel = () => {
      $uibModalInstance.close(needRefresh ? 'success' : 'cancel');
    };
  });
