'use strict';

import io from 'socket.io-client';

angular.module('gmpApp')
  .factory('socketIo', function ($rootScope, userInfo, localStorageService, settings, commonSVC, MessageSVC, commonModel, $state, webNotification, gettextCatalog, detectBrowser) {
    const m_no = userInfo.user.m_no;

    return {
      socketConnect: () => {
        try {
          if (userInfo != {}) {
            const socket = io.connect(`${settings.socketUrl}/plto2`, { transports: ['websocket'] });

            socket.on('connect', () => {
              socket.emit('login', { userInfo, detectBrowser });
            });

            socket.on('disconnect', () => {
              console.log(`socket client disconnect...(ws-${m_no}-${socket.id})`);
            });

            return socket;
          }
        } catch (err) {
          console.log(err);
        }

      },
      init: (socket) => {

        // 백오피스에서 결제 완료 처리시 토큰 갱신
        socket.on('resetToken', () => {
          commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
            localStorageService.set('token', `${'Token' + ' '}${result}`);

            commonSVC.showMessageHtml('토큰 정보가 갱신되어 새로고침 됩니다.', '', function() {
              location.reload();
            });
          });
        });

        // 엑셀 작업 진행률
        socket.on('progress_bar', (data) => {
          if (data.m_no === m_no) {
            $rootScope.$broadcast('progress_bar', data.progress);
            $rootScope.$apply();
          }
        });

        // 엑셀 다운로드 완료시 푸시 알람
        socket.on('excel_down_complete', (data) => {
          if (data.m_no === m_no) {
            $rootScope.$broadcast('excel_down_complete', data.excel_url);
          }
        });

        // 비밀번호 변경 시 해당 사용자 로그아웃 처리
        socket.on('edit_user_passwrod', (data) => {
          if (data.m_no === m_no) {
            $rootScope.cleanUpConnection();

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>비밀번호가 변경되어 로그아웃 처리됩니다.</span>", '', function() {
              $rootScope.moveRoot();
            });
          }
        });

        // 사용자 비활성 되는경우 해당 사용자 로그아웃 처리 2020-08-19
        socket.on('logout_disabled_user', (data) => {
          if (data.m_no === m_no) {
            $rootScope.cleanUpConnection();

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>사용자가 비활성되어 로그아웃 처리됩니다.</span>", '', function() {
              $rootScope.moveRoot();
            });
          }
        });

        // 사용자 삭제 되는경우 해당 사용자 로그아웃 처리
        socket.on('logout_deleted_user', (data) => {
          if (data.m_no === m_no) {
            $rootScope.cleanUpConnection();

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>사용자가 삭제되어 로그아웃 처리됩니다.</span>", '', function() {
              $rootScope.moveRoot();
            });
          }
        });

        // 사용자 비활성 되는경우 해당 사용자 로그아웃 처리 2020-08-19
        socket.on('updateToken', () => {
          commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
            localStorageService.set('token', `${'Token' + ' '}${result}`);
          });

          commonSVC.showMessageHtml('토큰 정보가 갱신되어 새로고침 됩니다.', '', function() {
            location.reload();
          });

        });

        // 작업카운트 10% 미만 알림
        socket.on('check_sol_charge', (data) => {
          if (data.m_no === m_no) {
            if ((!data.orderCheck || !data.productCheck) && $rootScope.user_profile.auth_type !== '배송처' && ![1051, 1057].includes($rootScope.user_profile.aff_no)) {
              $rootScope.workCount_exceeded = true;

              if (!$rootScope.showWorkCountModal.foreverCloseYn && !$rootScope.showWorkCountModal.closeYn) {
                $rootScope.showWorkCountModal.closeYn = true;
                commonSVC.openModal('md', {
                  data: {
                    orderCheck: data.orderCheck,
                    productCheck: data.productCheck
                  }
                }, 'WorkCountAlarmModalCtrl', 'views/common/workCountAlarmModal.html');
              }
            }

            // 신성통상 (7278) 주문카운트가 5만건, 3만건, 1만건 이하로 남았을때마다 알림창
            if ([7278].includes(userInfo.user.sol_no)) {
              const under50Thousand = typeof localStorage.getItem('under50Thousand') === 'string' ? localStorage.getItem('under50Thousand') : 'false';
              const under30Thousand = typeof localStorage.getItem('under30Thousand') === 'string' ? localStorage.getItem('under30Thousand') : 'false';
              const under10Thousand = typeof localStorage.getItem('under10Thousand') === 'string' ? localStorage.getItem('under10Thousand') : 'false';

              let count = '';
              if (under50Thousand === 'false' && data.under50Thousand) {
                count = '5만건';
                localStorage.setItem('under50Thousand', 'true');
              }
              if (under30Thousand === 'false' && data.under30Thousand) {
                count = '3만건';
                localStorage.setItem('under30Thousand', 'true');
                localStorage.setItem('under50Thousand', 'false');

              }
              if (under10Thousand === 'false' && data.under10Thousand) {
                count = '1만건';
                localStorage.setItem('under10Thousand', 'true');
                localStorage.setItem('under30Thousand', 'false');
                localStorage.setItem('under50Thousand', 'false');
              }

              if (count.length) {
                commonSVC.showMessage(`주문 카운트가 ${count} 이하입니다.`, '');
              }
            }
          }
        });

        // 중복 로그인 유저 로그아웃 처리
        socket.on('logout', (data) => {
          if (data.m_no === m_no && !$rootScope.adminMode) {
            // 확인 버튼 클릭하기전에 새로고침 할수도 있으니 토큰 먼저 지움
            localStorageService.remove('token');
            localStorageService.remove('wakeUpToken');
            localStorageService.remove('startWakeUpYN');
            localStorageService.remove('mas_contract_yn');
            socket.emit('logout', userInfo);

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>동일 아이디로 다른 PC에서 접속하여 현재 PC에서 로그아웃 됩니다.</span>", '', function () {
              $rootScope.logout();
            });
          }
        });

        // 대리점 연동 설정/해제
        socket.on('sol_logout', (data) => {
          if (data.sol_no === userInfo.user.sol_no) {
            // 확인 버튼 클릭하기전에 새로고침 할수도 있으니 토큰 먼저 지움
            localStorageService.remove('token');
            localStorageService.remove('wakeUpToken');
            localStorageService.remove('startWakeUpYN');
            localStorageService.remove('mas_contract_yn');
            socket.emit('logout', userInfo);

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>솔루션 권한 변경으로 로그아웃 됩니다..</span>", '', function () {
              $rootScope.logout();
            });
          }
        });

        // 사용자 권한 변경시
        socket.on('edit_user_permission', (data) => {
          if (data.m_no === m_no) {
            $rootScope.cleanUpConnection();

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>사용자 권한에 변경 사항이 발생하여 로그아웃 처리됩니다.</span>", '', function () {
              $rootScope.moveRoot();
            });

            return false;
          }
        });

        // 전체 로그아웃
        socket.on('all_logout', () => {
          if (userInfo.user.sol_no !== 1 && (!$rootScope.adminMode || userInfo.user.sol_sub_type === 'QA')) {
            localStorageService.remove('token');
            localStorageService.remove('wakeUpToken');
            localStorageService.remove('startWakeUpYN');
            localStorageService.remove('mas_contract_yn');
            socket.emit('logout', userInfo);

            commonSVC.showMessageHtml("<span style='font-size: 19px;'>솔루션 점검으로 인해 강제 로그아웃 됩니다.<br>이용에 불편을 드리게 되어 대단히 죄송합니다.</span>", '', function () {
              $rootScope.logout();
            });

            return false;
          }
        });

        // 백오피스 실시간 공지 알림
        socket.on('new_notice', (data) => {
          if (data.add_new_notice == 'y') {
            // 메세지 새로고침
            MessageSVC.refreshMessages();

            return false;
          }

          // 알림여부
          let notice_flag_company = false;
          let notice_flag_user = false;

          // 특정 쇼핑몰 사용시 알림
          if (data.expose_type == 'shopmem' || data.expose_type == 'shop') {
            const particular = JSON.parse(data.particular) || [];

            _.each(particular, function (v) {
              const idx = _.findIndex($rootScope.use_channel_list, { pa_shop_cd: v });

              if (idx >= 0) {
                notice_flag_company = true;

                return false;
              }
            });
          }

          // 특정 솔루션에만 알림
          // 2018-08-02 chris 공지사항은 솔루션 확인을 마스터 회원의 회원번호로 하고있어 user_m_no로 변경
          if (data.expose_type == 'shopmem' || data.expose_type == 'mem') {
            const particular_user = JSON.parse(data.particular_user) || [];
            const idx2 = _.findIndex(particular_user, function (o) {
              return o == userInfo.user.user_m_no.toString();
            });

            if (idx2 >= 0) {
              notice_flag_user = true;
            }
          }

          let notice_flag = false;

          // 특정업체 & 특정쇼핑몰 사용자 알림인경우
          if (data.expose_type == 'shopmem' && (notice_flag_company === true && notice_flag_user === true)) {
            notice_flag = true;
          } else if (data.expose_type == 'shop' && notice_flag_company === true) {
            notice_flag = true;
          } else if (data.expose_type == 'mem' && notice_flag_user === true) {
            notice_flag = true;
          } else if (data.expose_type == 'all') {
            notice_flag = true;
          }

          // 실시간 공지 바탕화면 알림에서 팝업으로 변경
          if (notice_flag === true) {

            // 실시간 알림인 경우 알림에 뱃지 노출안되도록 읽음 처리함
            localStorage.setItem(`notice_read${data.noti_no}`, 'y');

            // 메세지 새로고침
            MessageSVC.refreshMessages();

            // 실시간 팝업 오픈
            const nori_data = [{
              real_time: true,
              noti_no: data.noti_no,
              popup_width: data.popup_width,
              popup_height: data.popup_width,
              title: data.msg_title,
              wdate: data.wdate,
              content: data.msg_content,
              file: data.attach_file
            }];

            $rootScope.showNoticeNow(nori_data);
          }
        });

        // 실시간 메모 추가 / 완료 알림
        socket.on('new_memo', (data) => {
          if (data.push_mem.includes($rootScope.user_profile.m_no)) {
            $rootScope.pusher.new_memos_cnt++;
          }
        });

        // 자동품절 알림 2019-08-14 Alvin
        socket.on('auto_soldout_msg', (data) => {
          if (data.m_no === m_no) {
            if (data.sku_list.length) {
              const sku = data.sku_list.join(', ');

              commonSVC.showMessageHtml('<span style="font-size: 19px;">상품 자동 품절 처리</span>', `<span style='font-size: 15px;'>매칭한 SKU상품 (<span class="text-primary">${sku}</span>)의 재고가 부족하여 해당 SKU상품이 연동된 온라인 상품을 쇼핑몰에 자동으로 품절 전송 합니다.</span>`);
            }
          }
        });

        // 작업관리 작업 갱신
        socket.on('new_job', (data) => {
          switch (data) {
            case 'enqueue':
              if (typeof $rootScope.update_work_row == 'function') {
                $rootScope.update_work_list();
              }
              break;

            default:
              if (typeof $rootScope.update_work_row == 'function') {
                $rootScope.update_work_row(data);
              }
              break;
          }
        });

        // pa5 작업결과 푸시
        socket.on('complete_pa5_work', (data) => {
          const job = {
            id: data.job_id,
            progress: 100,
            state: data.state,
            state_msg: data.state_msg,
            updated_at: data.updated_at,
            started_at: data.started_at
          };

          // 작업 상황이 전달됨 (상태변경, progress, state_msg)
          if (typeof $rootScope.update_work_row === 'function') {
            $rootScope.update_work_row(job);
          }
        });

        // 재고 일괄수정 완료시
        socket.on('update_stock', (data) => {
          if (data.m_no === m_no) {
            const params = {
              jobId: data.jobId,
              alloc_type: data.alloc_type,
              from: 'excel_update_stock'
            };

            commonSVC.openModal('full', { data: params }, 'StockAllocationChannelCtrl', 'views/stock/inventory/stock_allocation_channel.html');
          }
        });

        // 특정 핸들링에 의한 인터셉터 로직
        socket.on('show_toast', _.debounce((data) => {
          const { type, title, message, m_no: socketMnos } = data;

          // showToaster의 경우 debounce를 통해 이벤트를 그루핑하고 있어서, 마지막 체크포인트에 추가하도록 setTimeout 비동기 콜백 사용함
          if (socketMnos === m_no) {
            setTimeout(() => {
              commonSVC.showToaster(type, title, message);
            }, 500);
          }
        }, 1000));

        // pusher newMessage 이동
        socket.on('addMessage', (data) => {
          // 2017-01-20 MatthewKim 메세지 올때마다 새로고침 하던 방식에서 현재 리스트에 추가하는 방식으로 변경
          // 2018-01-05 Daniel 리스트 추가하는 방식은 msg_no을 전달받지 못해서 읽기처리가 불가능해 새로고침으로 다시 변경
          // 작업완료알림은 노출안되기 때문에 새로고침 안함
          if (data.msg_title !== '작업완료') {

            // refreshMessages 처리가 돌때마다 디비에서 안읽은갯수조회를 돌리는데 재고부족 알림같은경우는 한번에 많이 호출되면 몇백개도 호출될수 있기 때문에
            // unread_cnt 를 증가처리하는로직으로 변경. 2020-03-24 rony
            // __master.refreshMessages();
            $rootScope.pusher.unread_cnt = $rootScope.pusher.unread_cnt + data.unread;

            // 타임라인 창이 열려있으면 메세지 리스트 다시 불러옴
            if (MessageSVC.isTimeLineState()) {
              // 메세지 모두 읽음처리
              MessageSVC.readAllMessages(true);
              MessageSVC.getMessageList();
            }
          } else if (data.msg_content.job_type === 'ScrapOrder') {

            if (/[0-9]+건 성공/.exec(data.msg_content.message)) {
              const newOrdCnt = +(/[0-9]+건 성공/.exec(data.msg_content.message)[0].replace(/[^0-9]/g, ''));

              if (data.msg_content.is_global) {
                $rootScope.pusher.unread_globalOrd_cnt = $rootScope.pusher.unread_globalOrd_cnt + newOrdCnt;
              } else {
                $rootScope.pusher.unread_localOrd_cnt = $rootScope.pusher.unread_localOrd_cnt + newOrdCnt;
              }
            }
          }

          // 2017-01-20 MatthewKim 현재 타임라인 화면이어도 알리지 않음
          // 2022-09-26 rony 모바일, IE는 바탕환면 알림 제외 추가
          if (
            !MessageSVC.isTimeLineState() &&
            !(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|crosswalk|webview|trident|msie/i.test(navigator.userAgent.toLowerCase())) &&
            (data.to_m_no == m_no || data.to_m_no == '__ALL__')
          ) {
            // 바탕화면 알림
            webNotification.showNotification(gettextCatalog.getString(data.msg_title), {
              body: data.msg_content.message,
              icon: '/assets/images/playauto/favicon.ico',
              autoClose: 4000
            });
          }
        });

        // 자동 세팅 작업 종료 시 처리
        socket.on('auto_setting_result', _.debounce((data) => {
          const { worklog_sol_id, m_no: socketMnos, isJobDelete } = data;

          if (socketMnos === m_no) {
            localStorage.setItem(`isOnlineListLoading_${userInfo.user.sol_no}`, false);
            $rootScope.isOnlineProdLoading = false;

            localStorageService.remove(`autoSettingTotal_${userInfo.user.sol_no}`);
            localStorageService.remove(`autoSettingSuccess_${userInfo.user.sol_no}`);
            localStorageService.remove(`loadingPercent_${userInfo.user.sol_no}`);

            if (!isJobDelete) {
              commonSVC.openModal('md', {
                data: {
                  worklog_sol_id
                }
              }, 'AutoSettingResultCtrl', 'views/home/modals/autoSettingResult.html');
            }
          }
        }, 1000));

        // 자동 세팅 작업 중 상품 로딩바 갱신
        socket.on('auto_setting_loading', _.debounce((data) => {
          const { total, success, m_no: socketMnos, workProgress } = data;

          if (socketMnos === m_no) {
            $rootScope.autoSettingTotal = total;
            localStorageService.set(`autoSettingTotal_${userInfo.user.sol_no}`, total);
            $rootScope.autoSettingSuccess = success;
            localStorageService.set(`autoSettingSuccess_${userInfo.user.sol_no}`, success);
            $rootScope.loadingPercent = (parseInt($rootScope.autoSettingSuccess / $rootScope.autoSettingTotal * 60) || 0) + workProgress;
            localStorageService.set(`loadingPercent_${userInfo.user.sol_no}`, $rootScope.loadingPercent);
          }
        }));
      }
    };
  });