'use strict';

/**
 * 주소관리 모달
 */
angular.module('gmpApp')
  .controller('DomesticAddressSettingModalController', function ($rootScope, $scope, data, commonSVC, $uibModalInstance, shipmentModel) {

    $scope.countryList = $rootScope.countryList;
    $scope.originalAddressList = [];
    $scope.addressList = [];

    const address_template = {
      template_no: '',
      template_name: '',
      ctry_cd: 'KR',
      tel: '',
      zipcd: '',
      state: '',
      city: '',
      addr1: '',
      addr2: ''
    };

    const required_input_list = [
      'template_name', 'ctry_cd', 'tel', 'zipcd', 'addr1'
    ];

    $scope.data = { ...address_template };

    function getAddressTemplateList() {
      shipmentModel.addressTemplateList().then(data => {
        $scope.originalAddressList = [{ ...address_template }, ...data.data.result];
        $scope.addressList = angular.copy($scope.originalAddressList);
      }).catch(() => {
        commonSVC.showToaster('error', '실패', '국내 송장 발행 주소 리스트 조회에 실패했습니다.');
      });
    }

    getAddressTemplateList();

    $scope.close = () => {
      $uibModalInstance.close();
    };

    $scope.toggleAccordion = function() {
      $scope.accordion_open = !$scope.accordion_open;
    };

    $scope.getCtryName = function(ctry_cd) {
      return $scope.countryList.find(ctry => ctry.ctry_cd.toUpperCase() === ctry_cd.toUpperCase())?.ctry_name || '';
    };

    $scope.onSelectionChange = function(template_no) {
      $scope.addressList = angular.copy($scope.originalAddressList);
      $scope.data = $scope.addressList.find(template => template.template_no == template_no);
    };

    $scope.formatAddressOption = function(address) {
      if (!address.template_no) {
        return '주소를 선택해 주세요.';
      }

      return `${address.template_name} / ${$scope.getCtryName(address.ctry_cd)} / ${address.tel} / (${address.zipcd}) / ${address.addr1} ${address.addr2 || ''}`;
    };

    /**
     * 신규 주소 작성 버튼 클릭 시 폼을 비워주는 함수
     */
    $scope.clearForm = function() {
      $scope.data = { ...address_template };
    };

    /**
     * 주소 추가 액션 처리 함수
     */
    $scope.addAddress = function() {
      if (!validateInput()) {
        return;
      }
      shipmentModel.addressTemplateSave({ ...$scope.data, template_no: $scope.data.template_no || 0 }).then(response => {
        if (response?.data === 'success') {
          commonSVC.showToaster('success', null, '주소 등록에 성공하였습니다.');
          setTimeout(() => getAddressTemplateList(), 500);
        } else if (response) {
          commonSVC.showToaster('error', null, '주소 등록에 실패하였습니다.');
        }
      }).catch(() => { commonSVC.showToaster('error', null, '주소 등록에 실패하였습니다.'); });
    };

    /**
     * 주소 수정 액션 처리 함수
     */
    $scope.updateAddress = function() {
      if (!validateInput()) {
        return;
      }
      commonSVC.showConfirm('주소를 수정하시겠습니까?').then(confirm => {
        if (confirm) {
          return shipmentModel.addressTemplateSave({ ...$scope.data, template_no: $scope.data.template_no || 0 });
        } else {
          return;
        }
      }).then(response => {
        if (response?.data === 'success') {
          commonSVC.showToaster('success', null, '주소 수정에 성공하였습니다.');
          setTimeout(() => getAddressTemplateList(), 500);
        } else if (response) {
          commonSVC.showToaster('error', null, '주소 수정에 실패하였습니다.');
        }
      }).catch(() => { commonSVC.showToaster('error', null, '주소 수정에 실패하였습니다.'); });
    };

    /**
     * 주소 삭제 액션 처리 함수
     */
    $scope.deleteAddress = function() {
      commonSVC.showConfirm('주소를 삭제하시겠습니까?').then(confirm => {
        if (confirm) {
          return shipmentModel.addressTemplateDelete({ template_no: $scope.data.template_no });
        } else {
          return;
        }
      }).then(response => {
        if (response?.data === 'success') {
          commonSVC.showToaster('success', null, '주소 삭제에 성공하였습니다.');
          $scope.clearForm();
          setTimeout(() => getAddressTemplateList(), 500);
        } else if (response) {
          commonSVC.showToaster('error', null, '주소 삭제에 실패하였습니다.');
        }
      }).catch(() => { commonSVC.showToaster('error', null, '주소 삭제에 실패하였습니다.'); });
    };

    function validateInput() {
      for (const key of required_input_list) {
        if (!$scope.data[key]) {
          return false;
        }
      }

      return true;
    }
  });
