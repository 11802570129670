'use strict';

angular.module('gmpApp')
  .controller('allocHelpCtrl', function ($scope, $uibModalInstance, data) {
    $scope.isChannel = data.isChannel;

    /**
     * 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
