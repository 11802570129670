'use strict';
angular.module('gmpApp')
  .controller('setFormTemplateAddCtrl', function ($state, $scope, $rootScope, $compile, $stateParams, shopAccountModel, systemList, $timeout) {
    $scope.now = {}; //기존 열려있는 템플릿 화면 비교
    if ($state.current.name != 'main.setForm_template_add.channelSub') {
      $rootScope.initView = true;  //초기화면
    }

    $scope.foreignCountry = [];  // 해외마켓

    const channelList = angular.copy($rootScope.useChannelList({ site_action: 'SyncProdState', func: shop => !!shop.prod_use_yn }));

    $scope.use_engines = _.filter(channelList, function (v) {
      v.type = 'add';
      v.flag = true;
      if (v.ctry_cd != 'KR') {
        $scope.foreignCountry.push(v.ctry_cd);
      }

      // 별칭 설정적용
      v.shop_id_text = v.shop_id;
      if (systemList.data.shop_id_view_type == 'nickname') {
        v.shop_id_text = v.seller_nick || v.shop_id;
      }

      // 상품, 주문 모두 지원하는 쇼핑몰 필터링
      return v.shop_type === 'ALL' && v.pa_shop_cd !== 'A076';
    });

    $scope.searchText = '';

    $scope.filterFunction = function(element) {
      if ($scope.searchText == '') {
        return true;
      }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.searchText})`, 'i'));
    };

    //검색 초기화
    $scope.resetDo = function() {
      $scope.searchText = '';
    };

    //템플릿 사이트 선택.
    $scope.channelSub = _.throttle(function(mall) {

      if ($scope.now.shop_id !== mall.shop_id || $scope.now.shop_cd !== mall.shop_cd) {
        $rootScope.initView = false;
        $scope.now.shop_id = mall.shop_id;
        $scope.now.shop_cd = mall.shop_cd;
        $scope.now.pa_shop_cd = mall.pa_shop_cd;
        mall.type2 = $stateParams.type1;

        $state.go('main.setForm_template_add.channelSub', mall);
      }
    }, 500);

    // 레프트 영역 자동 높이 조절
    function setLeftHeight() {
      $timeout(function () {
        const sideHeight = window.innerHeight - $('#navbarHeight').outerHeight() - $('#set-form-content1').outerHeight();

        $('#listSetFormSidebar').css('height', sideHeight);
      });
    }

    $scope.$on('$viewContentLoaded', function() {
      setLeftHeight();
    });

    $(window).on('resize', function () {
      setLeftHeight();
    });

    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.setForm_template_add') {
        window.dispatchEvent(new Event('resize'));
      }
    });
  });
