
'use strict';

/**
 * 이제너두 카테고리
 * 2018-04-03 rony
 */
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-B603-Ctrl', function ($scope, $rootScope, $compile, $ocLazyLoad, $timeout, $stateParams, categoryModel, templateModel, shopAccountModel, commonModel, B603categorySVC, commonSVC, errorSVC, settings, siteInfo) {
    const svc = B603categorySVC;

    $scope.CateList = angular.copy(svc.category_list);      // 카테고리 리스트
    $scope.CateOpt = angular.copy(svc.category_opt);        // 카테고리 리스트 옵션
    $scope.CateData = angular.copy(svc.category_data);      // 카테고리 데이터

    $scope.dispCtg_list = angular.copy(svc.dispCtg_list);   // 카테고리 리스트 옵션
    $scope.dispCtg_opt = angular.copy(svc.dispCtg_opt);     // 전시카테고리 리스트 옵션
    $scope.dispCtg = angular.copy(svc.dispCtg);             // 전시카테고리 데이터

    $scope.Cate = {};                                       // 추가한 카테고리

    $scope.lastCate = false;                                // 카테고리 마지막 뎁스 선택 여부
    $scope.lastDispCate = false;                            // 전시 카테고리 마지막 뎁스 선택 여부

    let large_spct_no = '';
    let medium_spct_no = '';
    let small_spct_no = '';

    let cat_no_1 = '';
    let cat_no_2 = '';
    let cat_no_3 = '';
    let cat_no_4 = '';

    /**
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    // 카테고리 선택 이벤트
    $scope.cateSelect = function (index) {
      const cateType = $scope.CateList[index];      // 선택 카테고리 타입
      let cateCd = $scope.CateData[cateType];     // 선택 카테고리 코드

      cateSetting(index, cateCd);

      if (index === '3' || cateCd === 'noSub' || !cateCd) {
        $scope.lastCate = true;

        return false;
      } // 마지막 분류이거나, 하위분류없을시

      loading('0', false);

      clearOpt(index, 'cate');

      setCateSub(index, cateCd, 'cate')
        .then(function() {
          loading('0', true);
          cateCd = 0;
        })
        .catch(function() {
          cateCd = 0;
          commonSVC.showMessage('로딩실패');
          loading('0', true);
        });
    };

    /**
     * 하위 전시카테고리 선택
     */
    $scope.dispCateSelect = function(index) {

      const cateType = $scope.dispCtg_list[index]; // 선택 카테고리 타입
      let cateCd = $scope.dispCtg[cateType];     // 선택 카테고리 코드
      // cateSetting(index, cateCd, "dispCate");

      // 마지막 분류이거나, 하위분류없을시
      if (index === '4' || cateCd === 'noSub' || !cateCd) {
        return false;
      }

      loading('1', false);

      clearOpt(index, 'disp');

      setCateSub(index, cateCd, 'disp')
        .then(function() {
          loading('1', true);
          cateCd = 0;
        })
        .catch(function() {
          cateCd = 0;
          commonSVC.showMessage('로딩실패');
          loading('1', true);
        });
    };

    // 카테고리 이름
    function cateSetting(index, cateCd) {
      if (cateCd === 'noSub') { return false; }

      const nameArr = [];

      index = parseInt(index) + 1;

      for (let i = 0; i < index; i++) {
        const name = $(`#CatMenu_${i}`).find('option:selected').text(); // 선택 카테고리 이름

        if (!name) { break; }

        nameArr.push(name);
      }

      $scope.CateData.category_names = nameArr.join(' > ');
    }

    // 하위 카테고리 가져오기
    function setCateSub(index, cateCd, type) {
      index = index * 1;
      let params = {};
      let action = '';

      if (type == 'cate') {
        if (index == 0) {
          large_spct_no = cateCd;
          action = 'etc.category_medium';
          params = {
            large_spct_no: large_spct_no,
            medium_spct_no: '',
            small_spct_no: ''
          };
        } else if (index == 1) {
          medium_spct_no = cateCd.substr(3, 6);
          action = 'etc.category_small';
          params = {
            large_spct_no: large_spct_no,
            medium_spct_no: medium_spct_no,
            small_spct_no: ''
          };
        } else if (index == 2) {
          small_spct_no = cateCd.substr(6, 9);
          action = 'etc.category_detail';
          params = {
            large_spct_no: large_spct_no,
            medium_spct_no: medium_spct_no,
            small_spct_no: small_spct_no
          };
        }
      } else {
        if (index == 0) {
          cat_no_1 = cateCd;
          action = 'etc.category_display_2';
          params = {
            cat_no_1: cat_no_1
          };
        } else if (index == 1) {
          cat_no_2 = cateCd.substr(3, 6);
          action = 'etc.category_display_3';
          params = {
            cat_no_1: cat_no_1,
            cat_no_2: cat_no_2
          };
        } else if (index == 2) {
          cat_no_3 = cateCd.substr(6, 9);
          action = 'etc.category_display_4';
          params = {
            cat_no_1: cat_no_1,
            cat_no_2: cat_no_2,
            cat_no_3: cat_no_3
          };
        } else if (index == 3) {
          cat_no_4 = cateCd.substr(9, 12);
          action = 'etc.category_display_5';
          params = {
            cat_no_1: cat_no_1,
            cat_no_2: cat_no_2,
            cat_no_3: cat_no_3,
            cat_no_4: cat_no_4,
          };
        }
      }

      if (!action) {
        return;
      }

      return commonSVC.requestPA($scope.userInfo, siteInfo.B603.domain, action, params, commonModel, shopAccountModel)
        .then(function(res) {
          const data = res.data.data.items;

          if (type == 'cate') {
            $scope.lastCate = !data.length;

            $scope.CateOpt[index + 1] = data || [];
          } else {
            $scope.lastDispCate = !data.length;
            $scope.dispCtg_opt[index + 1] = data || [];
          }
        });
    }

    // 옵션 초기화
    function clearOpt(index, type) {
      const start = parseInt(index) + 1;
      let key = '';

      if (type == 'cate') {
        for (let i = start; i < 4; i++) {
          key = $scope.CateList[i];
          $scope.CateOpt[i] = {};
          $scope.CateData[key] = '';
        }
      } else {
        for (let i = start; i < 4; i++) {
          key = $scope.dispCtg_list[i];
          $scope.dispCtg_opt[i] = {};
          $scope.dispCtg[key] = '';
        }
      }

    }

    function detailCate() {

      // 전시카테고리 설정.
      let value = $('#DispCatMenu_4').find('option:selected').val();
      let text = $('#DispCatMenu_4').find('option:selected').text();

      for (let idx = 3; idx >= 1; idx--) {
        if (value == 'noSub' || !text) {
          value = $(`#DispCatMenu_${idx}`).find('option:selected').val();
          text = $(`#DispCatMenu_${idx}`).find('option:selected').text();
        } else {
          break;
        }
      }

      if (value !== 'nosub' && !text) {
        commonSVC.showMessage('최종분류까지 선택해주세요');

        return false;
      }

      let name;
      const nameArr = [];
      let selectedName;

      for (let k = 0; k < 5; k++) {
        selectedName = $(`#DispCatMenu_${k}`).find('option:selected').text();
        if (selectedName == '하위 카테고리가 없습니다' || selectedName == '') { break; }
        nameArr.push(selectedName);
      }

      name = nameArr.join(' > ');
      // 전시카테고리
      $scope.CateData.detailData.key_dispCtg_Nm = name;
      $scope.CateData.detailData.key_dispCtg_Cd = value;

      // var cnt = 1;

      // // 카테고리 저장
      // _.each($scope.Cate, function (v, k) {
      //   var cntType = cnt === 1 ? "" : cnt;

      //   $scope.CateData.detailData["key_cateNm" + cntType] = v;
      //   $scope.CateData.detailData["key_cateCd" + cntType] = k;

      //   cnt++;
      // });
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      let value = '';
      let cateCd = $scope.CateData.key_dcate;

      _.each($scope.CateList, function(v) {
        if ($scope.CateData[v] == 'noSub' || $scope.CateData[v] == '') {
          cateCd = value;
        } else {
          value = $scope.CateData[v];
        }
      });

      if (!$scope.lastCate) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      }

      if (!$scope.lastDispCate) {
        commonSVC.showMessage('실패', '전시 카테고리를 끝까지 선택해 주십시오.');

        return false;
      }

      // if($scope.dispCtg.dispCtgSelectList.length < 1){
      //   commonSVC.showMessage("전시카테고리를 하나 이상 선택해 주십시오.");
      //   return false;
      // }

      detailCate();

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.CateData.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = cateCd;
      // $scope.CateData.detailData.dispCtgSelectList = $scope.dispCtg.dispCtgSelectList;      // 선택된 전시카테고리

      // 카테고리
      $scope.CateData.detailData.key_spctNm = $scope.CateData.detailData.category_names;
      $scope.CateData.detailData.key_spctNo = $scope.CateData.detailData.category_code;

      if ($scope.form1.$valid) {
        const param = {
          site_code: 'B603',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.category_code,
          category_names: $scope.CateData.category_names,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',
          //국내/해외
          type: 0
        };

        // DispCtgList(param.detailData);

        // 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;
              $scope.returnAct(param);
            } catch (err) {
              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    /**
     * 전시카테고리 선택이벤트
     */
    $scope.setDispCtgList = function() {

      if ($scope.dispCtg.dispCtgSelectList.length > 5) {
        commonSVC.showMessage('전시카테고리는 5개까지 선택 가능합니다.');

        return false;
      }
      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;

      const target = $('#DispCatMenu_4').find('option:selected');
      const listGroup = $('.list-group');

      let value = target.val();
      let text = target.text();

      for (let idx = 3; idx >= 1; idx--) {
        if (value == 'noSub' || !text) {
          value = $(`#DispCatMenu_${idx}`).find('option:selected').val();
          text = $(`#DispCatMenu_${idx}`).find('option:selected').text();
        } else {
          break;
        }
      }

      if (value !== 'nosub' && !text) {
        commonSVC.showMessage('최종분류까지 선택해주세요');

        return false;
      }

      let thisVal;

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        thisVal = dispCtgSelectList[i];

        if (thisVal == value) {
          commonSVC.showMessage('이미 추가된 전시카테고리 입니다');

          return false;
        }
      }

      let name;
      const nameArr = [];
      let selectedName;

      for (let k = 0; k < 5; k++) {
        selectedName = $(`#DispCatMenu_${k}`).find('option:selected').text();
        // 세분류 없을시 이름에 넣지않음
        if (selectedName == '하위 카테고리가 없습니다' || selectedName == '') { break; }
        nameArr.push(selectedName);
      }
      name = nameArr.join(' > ');

      dispCtgSelectList.push(value);
      const aElement = angular.element(`<li class="list-group-item" data-dispCtgCd="${value}"><span class="dispCtgNm">${name}</span> <span class="dispCtgSelectRemoveBtn badge" style="background-color:#999 !important;cursor:pointer">X</span></li>`);

      listGroup.append(aElement);
      $compile(aElement)($scope);
    };

    /**
     * 전시카테고리 삭제
     */
    $(document).on('click', '.dispCtgSelectRemoveBtn', function(e) {
      const dispCtgSelectList = $scope.dispCtg.dispCtgSelectList;
      const dispCtgSelectListLen = dispCtgSelectList.length;

      const item = $(e.target).parent();
      const value = item.attr('data-dispCtgCd');

      for (let i = 0; i < dispCtgSelectListLen; i++) {
        const thisVal = dispCtgSelectList[i];

        if (thisVal == value) { dispCtgSelectList.splice(i, 1); }
      }

      item.remove();
    });

    // /**
    //  * 전시카테고리 저장
    //  */
    // function DispCtgList(param) {
    //   const disp = [];

    //   $('.list-group .list-group-item').each(function(e) {
    //     const name = $(this).find('.dispCtgNm').text();
    //     const code = $(this).attr('data-dispctgcd');

    //     param[`key_dispCtg_Nm${e}`] = name;
    //     param[`key_dispCtg_Cd${e}`] = code;

    //     disp.push({ name: name, code: code });
    //   });

    //   param.disp = disp;
    // }

    // 초기화
    function init() {

      // 로딩중
      loading('0', false); // 표준카테고리 로딩중
      loading('1', false); // 전시카테고리 로딩중

      // 카테고리정보
      let category = [];
      let dispCategory = [];

      // 쇼핑몰 계정 정보 가져오기
      const shop_cd = $scope.shop_cd;
      const shop_id = $scope.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          return commonSVC.requestPA($scope.userInfo, siteInfo.B603.domain, 'etc.category_large', {}, commonModel, shopAccountModel);
        })
        .then(function (res) {
          category = res.data.data.items;

          // 전시카테고리 조회
          return commonSVC.requestPA($scope.userInfo, siteInfo.B603.domain, 'etc.category_display_1', {}, commonModel, shopAccountModel);
        })
        .then(function (res) {
          dispCategory = res.data.data.items;

          if (category.length < 1) {
            commonSVC.showMessage('로딩실패');
            loading('0', true);

            return false;
          } else if (dispCategory.length < 1) {
            commonSVC.showMessage('전시 카테고리 로딩실패');
            loading('1', true);

            return false;
          } else {
            $scope.CateOpt[0] = category;
            $scope.dispCtg_opt[0] = dispCategory;
          }

          /**
           * 2018-03-09 roy
           * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
           */
          if ($scope.matchingData.shop_cate_no_add_info) {
            if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
              matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
            } else {
              matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
            }
          } else if (!$scope.matchingData.sol_cate_shop_add_info) {
            matchDetailDataLoad(angular.copy(B603categorySVC.category_data));
          } else {
            matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
          }
        })
        .catch(function () {
          commonSVC.showMessage('로딩실패');
        });
    }

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(type, complete) {
      if (complete) {
        $(`.box-loading.loading${type}`).addClass('hidden'); //로딩중
      } else {
        $(`.box-loading.loading${type}`).removeClass('hidden'); // 로딩완료
      }
    }

    const matchDetailDataLoad = async (addInfo) => {
      if (!addInfo.category_code) {
        loading('0', true);
        loading('1', true);

        return false;
      }

      // 카테고리 정보 셋팅
      $scope.CateData.key_lcate = addInfo.category_code.substr(0, 3);
      await setCateSub(0, $scope.CateData.key_lcate, 'cate');

      $scope.CateData.key_mcate = addInfo.category_code.substr(0, 6);
      await setCateSub(1, $scope.CateData.key_mcate, 'cate');

      if (addInfo.category_code.length > 6) {
        $scope.CateData.key_scate = addInfo.category_code.substr(0, 9);
        await setCateSub(2, $scope.CateData.key_scate, 'cate');
      }
      if (addInfo.category_code.length > 9) {
        $scope.CateData.key_dcate = addInfo.category_code.substr(0, 12);
        await setCateSub(3, $scope.CateData.key_dcate, 'cate');
      }

      // 전시카테고리 정보 셋팅.
      if (!_.isUndefined(addInfo.key_dispCtg_Cd)) {
        $scope.dispCtg.key_dispLcate = addInfo.key_dispCtg_Cd.substr(0, 3);
        await setCateSub(0, $scope.dispCtg.key_dispLcate, 'disp');

        $scope.dispCtg.key_dispMcate = addInfo.key_dispCtg_Cd.substr(0, 6);
        await setCateSub(1, $scope.dispCtg.key_dispMcate, 'disp');

        if (addInfo.key_dispCtg_Cd.length > 6) {
          $scope.dispCtg.key_dispScate = addInfo.key_dispCtg_Cd.substr(0, 9);
          await setCateSub(2, $scope.dispCtg.key_dispScate, 'disp');
        }
        if (addInfo.key_dispCtg_Cd.length > 9) {
          $scope.dispCtg.key_dispDcate = addInfo.key_dispCtg_Cd.substr(0, 12);
          await setCateSub(3, $scope.dispCtg.key_dispDcate, 'disp');
        }
        if (addInfo.key_dispCtg_Cd.length > 12) {
          $scope.dispCtg.key_dispFcate = addInfo.key_dispCtg_Cd.substr(0, 15);
          await setCateSub(4, $scope.dispCtg.key_dispFcate, 'disp');
        }
      }

      // 기본카테고리 정보
      $scope.CateData.category_code = addInfo.category_code;
      $scope.CateData.category_names = addInfo.category_names;
      $scope.lastCate = true;

      loading('0', true);
      loading('1', true);
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
