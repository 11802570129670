'use strict';

/**
 *  join해서 출력하는 필터
 *  {{ arr | join:', '}}
 *  arr1, arr2, arr3
 *  controller에서는
 *  $filter('join')(arr, ", ");
 *  2016-07-18 권윤학
 */
angular.module('gmpApp')
  .filter('join', function() {
    return function(array, separator) {

      if (!array) {
        return '';
      }

      let str = '';

      _(array).forEachRight(function(val, key) {
        if (_.isNull(val) || _.isUndefined(val) || val.trim() === '') {
          array.splice(key, 1);
        }
      });

      if (array.length > 0) {
        str = array.join(separator);
      }

      return str;
    };
  });