/**
 * 리스팅 로딩 placeholder
 *
 * 2018-03-05 : 매튜
 */
'use strict';

angular.module('gmpApp')
  .directive('placeholderBg', [function () {
    return {
      restrict: 'E',
      //replace: true,
      templateUrl: 'views/tpls/placeholderBg.html',
    };
  }]
  );

