'use strict';

angular.module('gmpApp')
  .factory('A083categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_Tctg',
        1: 'key_Lctg',
        2: 'key_Mctg',
        3: 'key_Sctg',
        4: 'key_Dctg'
      },

      //카테고리 data
      category_data: {
        key_Tctg: '',
        key_Lctg: '',
        key_Mctg: '',
        key_Sctg: '',
        key_Dctg: '',
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        php_SiteNo: [],
      },

      // 전시카테고리 (메인매장)
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: [],
        siteNo: {
          6004: '신세계몰',
          6005: 'SSG.COM몰',
          // 6002: '트레이더스몰',
          // 6003: '분스몰',
          // 6200: 'TV 쇼핑몰'
        }
      },

      // 전시카테고리 (전문매장)
      spDispCtg: {
        dispCtgType: '0',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: [],
        siteNo: {
          6004: '신세계몰',
          6005: 'SSG.COM몰',
          // 6002: '트레이더스몰',
          // 6003: '분스몰',
          // 6200: 'TV 쇼핑몰'
        }
      },

      site_list: [
        // { name : "이마트몰", value: "6001" },
        //{ name : "S.I.VILLAGE", value: "6300" },
        { name: 'TV쇼핑몰', value: '6200' },
        { name: '트레이더스몰', value: '6002' },
        { name: '부츠몰', value: '6003' }
        // { name : "신세계몰", value: "6004" },
        // { name : "신세계백화점몰", value: "6009" },
      ]
    };
  });
