/**
 * 상품집계 트리
 */

'use strict';

angular.module('gmpApp')
  .directive('leftContentOlCateTree', function (commonSVC, onlineProductModel) {
    return {
      restrict: 'EA',
      templateUrl: 'views/tpls/leftContentOlCateTree.html',
      link: function ($scope) {
        let olCateListGroup = {};

        $scope.datas = {
          treeData: [],
          expandedNodes: [],
          selectedNode: {}
        };

        // 마스터상품 임의분류 관리모달
        $scope.setOnlineCategory = function () {
          const resolve = {
            olCateList: function () {
              return onlineProductModel.olCateList();
            },
            data: {
              masterProds: []
            }
          };

          const modal = commonSVC.openModal('xg', resolve, 'OnlineCategoryCtrl', 'views/online/product/modals/online_category.html');

          modal.result.finally(function() {
            $scope.searchFn.leftContentsTabChange(4);
          });
        };

        // 집계 갱신용 데이터 받아오기
        $scope.$on('olListReset', function () {
          $scope.datas.selectedNode = {};
        });

        // 집계 갱신용 데이터 받아오기
        $scope.$on('olTreeUdateEvent', function (event, data) {
          if (data.act === 'olTreeUdate') {
            init(data.onlineCateResults);
          }
        });

        /**
         * 선택이벤트
         */
        $scope.olCateSelect = function (olCate, selectYn) {
          if (selectYn) {
            $scope.searchForm.ol_cate_no = olCate.searchOlCates;

            $scope.searchDo(true);
          }
        };

        // 트리 세팅
        function setOlCateTree(olCateList, parentOlCate) {
          let children = parentOlCate.children,
              count = parentOlCate.count,
              searchOlCates = [];

          _.forEach(olCateList, function (childOlCate) {
            const cate = {
              label: childOlCate.ol_cate_name,
              count: childOlCate.accum_cnt,
              children: [],
              ol_cate_no: childOlCate.ol_cate_no,
              searchOlCates: [childOlCate.ol_cate_no]
            };

            // 미분류 카운팅 위해서 계산
            count -= childOlCate.accum_cnt;

            // 재귀로 한번에 다돌림
            // 하위 분류의 번호를 모아서 리턴해줌
            const searchOlCatesResult = setOlCateTree(olCateListGroup[childOlCate.ol_cate_no], cate);

            // 검색용 데이터
            // 상위를 눌렀을때 하위 분류 모두 포함하여 검색하기 위해 하위를 모아서 상위로 리턴 함
            cate.searchOlCates = cate.searchOlCates.concat(searchOlCatesResult);
            searchOlCates = searchOlCates.concat(cate.searchOlCates);

            children.push(cate);
          });

          // 미분류 카운팅 계산 끝난후 처리
          // 미분류 카운팅 있을때만 넣음
          // 분류는 무조건 출력
          if (olCateList && olCateList.length) {
            children.unshift({
              label: '분류 미지정',
              count: count,
              children: [],
              ol_cate_no: parentOlCate.ol_cate_no,
              // 분류자체가 지정안된 상품은 null로 보냄
              searchOlCates: [parentOlCate.ol_cate_no || 'null']
            });
          }

          return searchOlCates;
        }

        // 초기화
        function init(results) {
          const olCateList = results.list,
                totalCount = results.count;

          // 트리 데이터
          $scope.datas.treeData = [
            {
              label: '전체 분류',
              count: totalCount,
              children: [],
              ol_cate_no: null,
              searchOlCates: []
            }
          ];

          // 부모 번호로 그룹핑
          olCateListGroup = _.groupBy(olCateList, 'pa_ol_cate_no');

          // 트리 세팅
          setOlCateTree(olCateListGroup.null, $scope.datas.treeData[0]);
          $scope.datas.expandedNodes = [$scope.datas.treeData[0]];
        }
      }
    };
  });
