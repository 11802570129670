'use strict';

/**
 * 주문 기본설정 모달
 */
angular.module('gmpApp')
  .controller('DomesticPrintSettingModalController', function ($rootScope, $scope, data, commonSVC, $uibModalInstance, shipmentModel) {

    $scope.accordion_open = [];

    $scope.addrTemplateList = data.addrTemplateList;
    $scope.matchShopAddrList = data.matchShopAddrList.map(shop => {

      if (shop.pa_shop_cd === 'X099') {
        shop.pickup_account = data.channelList.find(ch => ch.pa_shop_cd === shop.pa_shop_cd && shop.shop_id === ch.shop_id);
        shop.shopee_channel_list = data.channelList.filter(item => item.pa_shop_cd === 'X099' && item.shop_cd !== 'X099');
        shop.pickup_account_original = shop.pickup_account;
      }

      $scope.accordion_open.push(true);

      return shop;
    });

    $scope.close = function() {
      $uibModalInstance.close('success');
    };

    $scope.onSubmit = (data) => {
      // 받는사람의 경우 필수값이므로 validation 처리
      if (!data.to_name.trim()) {
        data.ref.to_name.emitInputError();

        return;
      }

      const saveParams = {
        pickup_from_location_no: data.pickup_from_location_no,
        pickup_to_location_no: data.pickup_to_location_no,
        shipcenter_ship_type: data.shipcenter_ship_type,
        to_name: data.to_name,
        pa_shop_cd: data.pa_shop_cd,
        ...(data.pa_shop_cd === 'X099' && {
          shop_cd: data.pickup_account.shop_cd,
          shop_id: data.pickup_account.shop_id
        })
      };

      shipmentModel.saveDomesticAddressSetting(saveParams).then((data) => {
        if (data.data === 'success') {
          commonSVC.showToaster('success', null, '기본정보 저장에 성공하였습니다.');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', null, '기본정보 저장에 실패하였습니다.');
        }
      }).catch(() => {
        commonSVC.showToaster('error', null, '기본정보 저장에 실패하였습니다.');
      });
    };

    $scope.toggleAccordion = function(index) {
      $scope.accordion_open[index] = !$scope.accordion_open[index];
    };

    $scope.editPickupID = function(shop) {
      commonSVC.showConfirm('픽업코드 발급계정을 변경하시겠습니까?', '· 계정 변경 시 이전에 출력했던 픽업라벨 재출력이 불가합니다.\n · 변경 후 [저장]을 해주셔야 변경 내용이 반영됩니다.').then(confirm => {
        if (!confirm) {
          shop.pickup_account = shop.pickup_account_original;
        }
      });
    };

    $scope.openAddressSettingModal = function() {
      const resolve = {
        data: {
          addrTemplateList: $scope.addrTemplateList
        }
      };
      const modal = commonSVC.openModal('xl', resolve, 'DomesticAddressSettingModalController', 'views/order/shipment/modals/domesticAddressSetting.html');

      modal.result
        .then(() => {
          shipmentModel.addressTemplateList().then(data => {
            $scope.addrTemplateList = data.data.result;
          });
        }).catch(() => { commonSVC.showToaster('error', '실패', '국내 송장 발행 주소 리스트 조회에 실패했습니다.'); });
    };
  });