'use strict';

angular.module('gmpApp')
  .factory('B394categorySVC', function () {

    return {
      // 출고지
      spVendCtg: {
        vendCtgLoad: false,
        searchValue: '',
        vendCtgSelect: null,
      },
      // 표준상품군
      spBaseCtg: {
        baseCtgLoad: false,
        searchValue: '',
        baseCtgSelect: null,
      },
      // 전시카테고리
      spDispCtg: {
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      },
      //카테고리 data
      category_data: {
        // 카테고리 이름,코드
        key_category_names: '',

        key_category_code: '',
        // 전시카테고리 코드
        key_display_codes: '',
        // 츨고지 코드
        key_vend_codes: '',
        // 품번
        key_pumbun_no: '',
        key_default_display_code: '',
      },
    };
  });