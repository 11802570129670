'use strict';

/**
 * 사은품 규칙 출고수량 컨트롤러
 */
angular.module('gmpApp')
  .controller('giftUsedCntListCtrl', function ($scope, $uibModalInstance, data, settings, $rootScope, commonSVC, shipmentModel, gettextCatalog) {

    $scope.type = data.type;
    $scope.usedCntList = data.usedCntList.data.results;
    $scope.totalCnt = $scope.usedCntList.reduce((acc, cur) => {
      return acc += $scope.type === 'limit' ? +cur.gift_used_cnt_limit || 0 : +cur.gift_used_cnt;
    }, 0);

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });