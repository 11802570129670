/**
 * 공지사항 목록 컨트롤러
 * 2017-10-25 Pada
 */

'use strict';

angular.module('gmpApp')
  .controller('NoticeListCtrl', function (
    $scope, settings, $rootScope, $compile, $timeout, $state, gettextCatalog, systemList,
    commonSVC, noticeSVC,
    commonModel, noticeModel
  ) {

    //정렬 데이터
    let order_data;
    // 데이터테이블 정렬 초기화
    let init_order = true;
    let total_count = 0;
    $scope.gridHeightNoCalc = true;

    if ($rootScope.noticeListShop) {
      $scope.currentTap = 'shop';
      $rootScope.noticeListShop = false;
    } else {
      $scope.currentTap = 'system';
    }

    if ($rootScope.noticeCurrentTap) {
      $scope.currentTap = $rootScope.noticeCurrentTap;
    }

    $scope.sub_notice = false;
    $scope.user_type = '';

    if ($rootScope.user_profile.sol_ser.includes('super_user') || $rootScope.user_profile.pa_sol_no) {
      $scope.sub_notice = true;
      $scope.user_type = $rootScope.user_profile.sol_ser.includes('super_user') ? 'super' : 'sub';
    }

    /**
   * 서치바 관련 변수
   */
    // 검색영역 폼값 정리
    $scope.searchForm = angular.copy(noticeSVC.searchForm);
    // 검색영역 데이터
    $scope.searchData = angular.copy(noticeSVC.searchData);
    $scope.searchData.totalCount = 0;       // 검색전체 건수
    $scope.searchData.selectCount = 0;      // 선택한 건수 <- 작업예정
    $scope.searchData.showCount = 25;       // 한 페이지에 보이는 건수

    $scope.searchBtn = {
      actions_right: []
    };

    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    // 값이 있는경우에만 설정기한 사용하도록 함. 기본은 솔루션 신청일. 2018-03-07 rony
    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    // 슈퍼계정만 등록
    if ($rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) {
      $scope.searchBtn.actions_right.push(
        {
          label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('공지등록')}</span>`,
          small_label: '공지등록',
          btn_type: 'button',
          add_class: 'col-xs-12',
          action: () => {
            $scope.addNoti();
          }
        }
      );
    }

    $scope.searchFn = {
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      }
    };

    // 데이터 건수 변경
    $scope.searchFn.changeLen = function (count) {
      $scope.changeCount(count);
    };

    /**
    * 데이터테이블 pageLength 조절
    * @param number
    */
    $scope.changeCount = function (number) {
      $scope.searchData.showCount = number;

      switch ($scope.currentTap) {
        case 'system':
          $scope.system.methods.length(number);
          break;

        case 'shop':
          $scope.shop.methods.length(number);
          break;

        case 'sub':
          $scope.sub.methods.length(number);
          break;
      }
    };

    /**
   * 검색
   */
    $scope.searchDo = function (refresh = true, noDelay) {
      switch ($scope.currentTap) {
        case 'system':
          $scope.system.methods.reloadData(null, refresh, noDelay);
          break;

        case 'shop':
          $scope.shop.methods.reloadData(null, refresh, noDelay);
          break;

        case 'sub':
          $scope.sub.methods.reloadData(null, refresh, noDelay);
          break;
      }
    };

    /**
   * 검색 초기화
   */
    $scope.resetDo = () => {
      $scope.searchForm = angular.copy(noticeSVC.searchForm);
      // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
      // 값이 있는경우에만 설정기한 사용하도록 함. 기본은 솔루션 신청일. 2018-03-07 rony
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }
      $scope.searchDo(true, true);
    };

    /**
   * 탭 변경
   */
    $scope.selectTab = function (tapType) {
      $scope.currentTap = tapType;
      $scope.searchDo(true, true);
    };

    /**
   * 검색 구분 선택
   */
    $scope.selectSearchKey = function (value, key) {
      $scope.searchForm.search_key = value;
      $scope.searchData.search_key_name = key;
    };

    /**
     * 대리점 공지 등록
     */
    $scope.addNoti = async () => {
      const resolve = {
        subUserList: () => {
          return commonModel.subUserList();
        },
        data: {
          type: 'add'
        }
      };
      const modal = await commonSVC.openModal('lg', resolve, 'AddNotiController', 'views/notice/modals/addNoti.html', undefined, undefined, false);

      modal.result
        .then((res) => {
          if (res === 'success') {
            $scope.resetDo();
          }
        });
    };

    $scope.editNoti = async (noti_no) => {
      const notidata = await noticeModel.detail({ noti_no });

      const resolve = {
        subUserList: () => {
          return commonModel.subUserList();
        },
        data: {
          ...notidata.data.results.row[0],
          type: 'edit',
          user: notidata.data.results.user
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'AddNotiController', 'views/notice/modals/addNoti.html', undefined, undefined, false);

      modal.result
        .then((res) => {
          if (res === 'success') {
            $scope.resetDo();
          }
        });
    };

    $scope.deleteNoti = async (noti_no) => {
      const confirm = await commonSVC.showConfirm('공지 삭제 안내', '공지 삭제 시 삭제된 정보는 복구되지 않습니다.\n해당 공지를 삭제하시겠습니까?');

      if (confirm) {
        const result = await noticeModel.delete({ noti_no });

        if (result.data?.results === 'success') {
          commonSVC.showToaster('success', '성공', '공지사항이 삭제었습니다.');

          $scope.resetDo();
        } else {
          commonSVC.showToaster('error', '실패', '공지사항 삭제에 실패하였습니다.');
        }
      }

    };

    function requestDidAction(json, gridName) {
      total_count = json.recordsTotal;
      $scope.searchData.totalCount = total_count;
      $scope.rowTotalCount = json.rowsTotal;
      $scope[gridName].methods.setShowCountByGridPageSize();
      document.querySelectorAll('.ui-grid-viewport .ui-grid-row').forEach(element => element.classList.remove('top-expose'));
      $timeout(() => {
        for (let i = 0; i < json.results.length; i++) {
          const row = json.results[i];
          if (row.top_expose_yn === 1) {
            document.querySelector(`.ui-grid-viewport .ui-grid-row:nth-child(${i + 1})`).classList.add('top-expose');
          } else {
            break;
          }
        }
      }, 200);

      return json.results;
    }

    /**
   * datatable 설정
   */
    $scope.system = {};
    $scope.system.methods = {};
    $scope.system.options = {
      modal: false,
      pinningColumns: [],
      alignCenterColumns: ['noti_no', 'noti_type', 'wdate'],
      alignLeftColumns: ['title'],
      defaultSortingColumns: ['noti_no'],
      notSortingColumns: ['noti_no', 'noti_type', 'title', 'wdate'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      initPagingSize: 25,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/notice/system`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);
          const orderby = `${$scope.system.options.defaultSortingColumns[0]} desc`;

          if (init_order == true) {
            order_data = d.order;
            init_order = false;
          }
          data.orderby = orderby;

          return JSON.stringify(data);
        },
        requestDidAction: json => requestDidAction(json, 'system')
      },
      columns: [
        {
          key: 'noti_no',
          title: '공지 번호',
          width: 190,
        },
        {
          key: 'noti_type',
          title: '공지 타입',
          width: 190,
        },
        {
          key: 'title',
          title: '공지 제목',
          width: 556,
          template: function (row) {
            return `<span style='cursor:pointer' ng-click='grid.appScope.goDetail("system", ${row.noti_no})'>${row.title}</span>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일시',
          width: 375,
        },
      ]
    };

    /**
     * 문의상세
     */
    $scope.goDetail = function (type, noti_no) {
      $rootScope.noti_no = noti_no;
      $rootScope.noticeType = type;
      $rootScope.$emit('$closeTab', 'tab_notice_detail');
      $timeout(function () {
        $state.go('main.notice_detail', { noticeType: type, noti_no: noti_no });
      }, 100);
    };

    /**
     * datatable 설정
     */
    $scope.shop = {};
    $scope.shop.methods = {};
    $scope.shop.options = {
      modal: false,
      pinningColumns: [],
      alignCenterColumns: ['noti_no', 'shop_name', 'wdate'],
      alignLeftColumns: ['title'],
      defaultSortingColumns: ['noti_no'],
      notSortingColumns: ['noti_no', 'shop_name', 'title', 'wdate'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      initPagingSize: 25,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/notice/shop`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);
          const orderby = `${$scope.shop.options.defaultSortingColumns[0]} desc`;

          if (init_order == true) {
            order_data = d.order;
            init_order = false;
          }
          data.orderby = orderby;

          return JSON.stringify(data);
        },
        requestDidAction: json => requestDidAction(json, 'shop')
      },
      columns: [
        { key: 'noti_no', title: '공지 번호', width: 190 }, //공지번호
        {
          key: 'shop_name', title: '쇼핑몰', width: 190, notCompile: true
        }, //쇼핑몰
        {
          key: 'title', title: '공지 제목', width: 556, template: function (row) {
            return `<span style='cursor:pointer;' ng-click='grid.appScope.goDetail("shop", ${row.noti_no})'>${row.title}</span>`;
          }
        }, //공지제목
        { key: 'wdate', title: '쇼핑몰 수집일시', width: 375 }
      ]
    };

    /**
     * datatable 설정 (sub_notice_grid)
     */
    $scope.sub = {};
    $scope.sub.methods = {};
    // const extra_columns = $scope.user_type === 'super' ? ['expose_yn', 'setting'] : [];
    $scope.sub.options = {
      modal: false,
      pinningColumns: [],
      alignCenterColumns: ['noti_no', 'noti_type', 'wdate'],
      alignLeftColumns: ['title'],
      defaultSortingColumns: ['noti_no'],
      notSortingColumns: ['noti_no', 'noti_type', 'title', 'wdate'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      initPagingSize: 25,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/notice/sub/list`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);
          const orderby = `${$scope.sub.options.defaultSortingColumns[0]} desc`;

          if (init_order == true) {
            order_data = d.order;
            init_order = false;
          }
          data.orderby = orderby;
          data.user_type = $scope.user_type;

          return JSON.stringify(data);
        },
        requestDidAction: json => requestDidAction(json, 'sub')
      },
      columns: [
        { key: 'noti_no', title: '공지 번호', width: 190 }, //공지번호
        { key: 'noti_type', title: '공지 타입', width: 190 }, //쇼핑몰
        {
          key: 'title', title: '공지 제목', width: 556, template: function (row) {
            return `<span style='cursor:pointer;' ng-click='grid.appScope.goDetail("sub", ${row.noti_no})'>${row.title}</span>`;
          }
        }, //공지제목
        { key: 'wdate', title: '등록일', width: 375 },
      ]
    };

    if ($scope.user_type === 'super') {
      $scope.sub.options.alignCenterColumns = [...$scope.sub.options.alignCenterColumns, 'expose_yn', 'setting'];
      $scope.sub.options.notSortingColumns = [...$scope.sub.options.notSortingColumns, 'expose_yn', 'setting'];
      $scope.sub.options.columns.push(
        {
          key: 'expose_yn', width: 80, notCompile: true, template: function (row) {
            const linkIcon = row.expose_yn === 1
              ? '<i class="icon-primitive-dot text-success-600 cursor-pointer"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';

            return linkIcon;
          }
        }
      );
      $scope.sub.options.columns.push(
        {
          key: 'setting', width: 140,
          template: function (row) {
            return `
                <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.editNoti(${row.noti_no})">수정</button>
                <button class="btn btn-default btn-xxs" ng-click="grid.appScope.deleteNoti(${row.noti_no})">삭제</button>
              `;
          }
        }
      );
    }
  });
