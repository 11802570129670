'use strict';

angular.module('gmpApp')
  .service('noticeModel', function (settings, commonSVC) {
    const noticePA20Url = `${settings.pa20ApiUrl}/app/notice/sub`;

    return {
      add: (data, next) => {
        return commonSVC.sendUrlFile('POST', noticePA20Url, data, next);
      },

      edit: (data, next) => {
        return commonSVC.sendUrlFile('PATCH', noticePA20Url, data, next);
      },

      detail: (data, next) => {
        return commonSVC.sendUrl('GET', noticePA20Url + `/${data.noti_no}`, {}, next);
      },

      delete: (data, next) => {
        return commonSVC.sendUrl('DELETE', noticePA20Url, data, next);
      },
    };
  });
