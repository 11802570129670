'use strict';

/**
 * 2018-04-19 ally
 * Hmall
 */
angular.module('gmpApp')
  .factory('A131categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_lcate',
        1: 'key_mcate',
        2: 'key_scate',
        3: 'key_dcate'
      },

      //카테고리 배열
      CtgArr: [
        { type: 'l_std_ctg', result: {} }, // 대분류
        { type: 'm_std_ctg', result: {} }, // 중분류
        { type: 's_std_ctg', result: {} }, // 소분류
        { type: 'dtl_std_ctg', result: {} } // 세분류
      ],

      // 상품고시분류 ID
      notice_class: {

      },

      //카테고리 data
      category_data: {
        // 표준분류
        key_lcate: '',
        key_mcate: '',
        key_scate: '',
        key_dcate: '',

        //전시카테고리
        key_dispLcate: '',
        key_dispMcate: '',
        key_dispScate: '',
        key_dispDcate: '',
        key_dispFcate: '',

        // MD
        key_buyer_id: '',
        key_buyerNm: '',

        // 카테고리 이름,코드
        category_names: '',
        category_code: '',

        set_all_shop: false, //상품적용여부
        // 전시카테고리 이름, 코드
        display_data: [],

        detailData: {}
      },

      //전시카테고리 repeat data
      dispCtg_list: {
        0: 'key_dispLcate',
        1: 'key_dispMcate',
        2: 'key_dispScate',
        3: 'key_dispDcate',
        4: 'key_dispFcate',
      },

      // 전시카테고리 배열
      dispCtgArr: [
        { type: 'f_disp_ctg', result: {}, last: false }, // 최상위분류
        { type: 'l_disp_ctg', result: {} }, // 대분류
        { type: 'm_disp_ctg', result: {} }, // 중분류
        { type: 's_disp_ctg', result: {} }, // 소분류
        { type: 'dtl_disp_ctg', result: {} }, // 세분류
        { result: {} }
      ],

      // 전시카테고리
      dispCtg: {
        // 전시카테고리
        key_dispLcate: '',
        key_dispMcate: '',
        key_dispScate: '',
        key_dispDcate: '',
        key_dispFcate: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      },

      //MD
      MD: {

      }
    };
  });
