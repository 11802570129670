'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentEbaydepotSendDelivRequestCtrl', function ($scope, $uibModalInstance, $q, ebaydepotAccounts, data, commonSVC, workSVC) {
    ebaydepotAccounts = ebaydepotAccounts.data.results;

    const selected = data.selected,             // 선택주문
          possibleCount = data.possibleCount,   // 전송/수정 가능한 갯수
          searchData = data.searchData;         // 검색데이터

    $scope.isAll = !selected.length;          // 선택주문 없으면 전체
    $scope.count = $scope.isAll ? possibleCount : selected.length;

    // 1초에 한번씩만 실행되도록 쓰로틀 추가.
    $scope.submit = _.throttle(function () {
      let addWorkProms = [],
          success_cnt = 0,
          error_cnt = 0,
          impossible_cnt = 0,
          work_cnt = 0,
          duplicate_yn = false,
          accounts;

      if ($scope.isAll) {
        accounts = ebaydepotAccounts;
      } else {
        accounts = [];

        const ordsGroupByDepotNo = _.groupBy(selected, 'depot_no');

        _.each(ordsGroupByDepotNo, function(ords, depotNo) {
          const account = _.find(ebaydepotAccounts, { depot_no: parseInt(depotNo) });

          account.ords = ords;

          accounts.push(account);
        });
      }

      _.each(accounts, function (account) {
        const param = {
          site: {
            site_code: 'ESMP',
            site_name: 'G마켓 물류',
            site_id: account.ebaydepot_id,
            site_pwd: account.ebaydepot_pwd,
            playapi_runner_os: 'AWS',
            uuid: account.uuid
          },
          data: {
            isAll: $scope.isAll,
            depotNo: account.depot_no,
            searchData: searchData,
            uniqs: _.map(account.ords, 'uniq')
          }
        };

        addWorkProms.push(workSVC.addEbaydepotWork('SendDelivRequestAPI', param, true)
          .then(function (result) {
            if (result.status === 200 && result.data.result === 'success') {
              work_cnt = work_cnt + result.data.workCnt;
              success_cnt++;
            } else {
              error_cnt++;
            }
          })
          .catch(function (err) {
            if (err.data.messages[0] === 'duplicate work' && !duplicate_yn) {
              duplicate_yn = true;
            }

            if (err.data.messages[0] !== 'orders not exist') {
              error_cnt++;
            } else {
              impossible_cnt++;
            }
          })
        );
      });

      $q.all(addWorkProms)
        .then(function () {
          if (success_cnt === 0 && error_cnt === 0 && impossible_cnt > 0 && !duplicate_yn) {
            commonSVC.showToaster('error', '작업등록 실패', '발송요청 작업이 가능한 주문이 없습니다.\n리스트를 한번 새로고침후 작업을 진행해주시기 바랍니다.');
          } else {
            let cnt = selected ? selected.length : possibleCount,
                resMsg = '';

            if (cnt !== work_cnt && work_cnt) {
              resMsg += `\n작업이 가능한 ${work_cnt}건의 주문만 작업이 실행됩니다.\n작업완료후 리스트 새로고침을 해주시기 바랍니다.`;
            }
            if (duplicate_yn) {
              resMsg += '\n이미 등록한 작업이 존재하므로 등록이 취소 되었습니다.';
            }
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${success_cnt}, 실패 : ${error_cnt}${resMsg}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          }

          $uibModalInstance.close();
        });
    }, 1000);

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
