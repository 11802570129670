'use strict';

angular.module('gmpApp')
  .controller('AltalkAutoRegDetailCtrl', function(
    $scope, commonSVC, data, $timeout, settings, $rootScope, $uibModalInstance, userInfo, atalkModel
  ) {
    $scope.uniq = data.uniq;
    async function init () {
      $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1);                      // 예약 시 초기화
      $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(num => `${num === 0 ? '00' : num}`); // 예약 분 초기화

      // 알림톡 자동발송 등록 Task 상세조회(부모 컨트롤러에게 task_id 전달받음)
      $scope.atalk = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/autoTaskDetail`, { task_id: data.uniq, sol_no: $rootScope.user_profile.sol_no })).data?.result[0];
      $scope.tabActive = 0;

      // 알림톡 자동발송 발송 시간 처리(즉시 OR 예약)
      if (!$scope.atalk.auto_send_time) {  // 즉시인 경우
        $scope.atalk.ampm = '0';           // 예약 버튼 클릭 시 오전으로 초기화
      } else {
        if (+$scope.atalk.auto_send_time.split(':')[0] - 12 > 0 || +$scope.atalk.auto_send_time.split(':')[0] === 0) {  // 예약 - 오후 시간인 경우
          $scope.atalk.ampm = '1';
          $scope.atalk.selectedHour = `${+$scope.atalk.auto_send_time.split(':')[0] === 0 ? '12' : +$scope.atalk.auto_send_time.split(':')[0] - 12}`;
          $scope.atalk.selectedMin = $scope.atalk.auto_send_time.split(':')[1];
        } else { // 예약 - 오전 시간인 경우
          $scope.atalk.ampm = '0';
          $scope.atalk.selectedHour = +$scope.atalk.auto_send_time.split(':')[0] - 12 === 0 ? '12' : `${+$scope.atalk.auto_send_time.split(':')[0]}`;
          $scope.atalk.selectedMin = $scope.atalk.auto_send_time.split(':')[1];
        }
      }
      // 발송 대상 영역 - 묶음 주문 대표정보 발송 체크값 존재하지 않을 시 false로 초기화
      if (!Object.prototype.hasOwnProperty.call($scope.atalk.send_target_criteria, 'send_type_bundle_info')) {
        $scope.atalk.send_target_criteria.send_type_bundle_info = false;
      }
      $scope.send_type = $scope.atalk.send_target_criteria?.sol_action === '신규주문 수집 시' ? 'scrap_order' : 'order_status';
      $scope.send_time = !$scope.atalk.auto_send_time ? 'immediately' : 'reserved';

      // 플러스ID 리스트 조회
      $scope.plusIdList = (await atalkModel.accountList({ name: $rootScope.user_profile.sol_no })).data?.result;

      if ($scope.plusIdList.length) {
        $scope.plusIdCheck = true;
        $scope.plusIdList = $scope.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
        $scope.atalk.plus_friend_id = $scope.plusIdList[0].id;
      } else {
        $scope.plusIdCheck = false;
      }
      // 알림톡 자동 발송 상세에서 해당 자동 발송 등록의 템플릿 정보 조회
      $scope.templateDetail = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateDetail`, { template_no: $scope.atalk.atalk_template_id })).data.result;
      $scope.add_info_yn = $scope.templateDetail.template_extra && $scope.templateDetail.template_extra !== 'null';
      if ($scope.templateDetail.template_extra && $scope.templateDetail.template_extra !== 'null') {
        document.getElementById('preview_add_box').innerHTML = $scope.templateDetail.template_extra?.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      }

      // SMS 대체 여부
      $scope.sms_send_yn = !$scope.atalk.alt_send_yn ? '1' : '0';

      // 템플릿 탭 (제공 - 0, 개인 - 1) 기본값 제공 템플릿으로 초기화
      $scope.template_tab = 0;

      // 템플릿 리스트 영역 펼침 여부
      $scope.isTemplateListOpen = false;

      // 수정 가능여부 체크값
      $scope.reqCheck = false;

      // 상세 조회 시 해당 자동발송의 등록된 템플릿 미리보기 처리
      document.getElementById('preview_box').innerHTML = $scope.templateDetail.content.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      if ($scope.templateDetail.buttons) {
        $scope.templateDetail.buttons = JSON.parse(`[${$scope.templateDetail.buttons}]`);
        $scope.use_add_channel_yn = $scope.templateDetail.buttons.some(btn => btn.type === 'AC');
      } else {
        $scope.templateDetail.buttons = [];
      }

      // 이미지 강조 유형인 경우 이미지 미리보기 처리
      if ($scope.templateDetail.template_img_name && $scope.templateDetail.template_emphasize_type === 'IMAGE') {
        const preview = document.getElementById('emphasis_img');
        const currentStyle = preview.getAttribute('style') || '';
        preview.setAttribute('style', `${currentStyle} background-image: url('${$scope.templateDetail.template_img_name}'); background-size: 100%;`);
      }

      $scope.originData = angular.merge(angular.copy($scope.atalk), { template: angular.copy($scope.templateDetail) });
      $timeout(() => {});
    }

    init();

    /**
     * 수정 버튼 활성화 처리
     */
    $scope.reqCheckF = (type) => {
      if ($scope.atalk.plus_friend_id && $scope.atalk.send_title && $scope.templateDetail.name && (($scope.send_type === 'scrap_order') || ($scope.send_type === 'order_status' && $scope.atalk.send_target_criteria.sol_action)) && (($scope.send_time === 'immediately') || ($scope.send_time === 'reserved' && $scope.atalk.selectedHour && $scope.atalk.selectedMin)) && $scope.sms_send_yn) {
        $scope.reqCheck = true;
      } else {
        $scope.reqCheck = false;
      }

      if (type === 'sol_action') {
        // 발송 대상 변경 시 묶음 주문 대표 정보 여부 초기화
        $scope.atalk.send_target_criteria.send_type_bundle_info = false;
      }

      if (type === 'send_type') {
        $scope.atalk.send_target_criteria.sol_action = '';
      }
    };

    /**
     * 템플릿 리스트 조회
     */
    $scope.clickTemplate = async () => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.atalk.plus_friend_id.toString(), status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.basicTemplateList = (templateList.data?.results)?.filter(r => r.type === 'G');  // 제공 템플릿
      $scope.templateList = (templateList.data?.results)?.filter(r => r.type !== 'G');   // 개인 템플릿

      $scope.isTemplateListOpen = !$scope.isTemplateListOpen;

      $timeout(() => {});
    };

    /**
     * 템플릿 선택
     */
    $scope.setTemplate = async (row) => {
      $scope.isTemplateListOpen = false;
      const template = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateDetail`, { template_no: row.id })).data?.result;
      $scope.templateDetail = template;
      document.getElementById('preview_add_box').innerHTML = '';

      // 부가정보 여부
      if ($scope.templateDetail.template_extra && $scope.templateDetail.template_extra !== 'null') {
        document.getElementById('preview_add_box').innerHTML = $scope.templateDetail.template_extra?.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      }

      // 템플릿 변경 시 해당 템플릿에 맞게 미리보기 변경 처리 추가
      document.getElementById('preview_box').innerHTML = $scope.templateDetail.content.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');

      if ($scope.templateDetail.template_img_name && $scope.templateDetail.template_emphasize_type === 'IMAGE') {
        const preview = document.getElementById('emphasis_img');
        const currentStyle = preview.getAttribute('style') || '';
        preview.setAttribute('style', `${currentStyle} background-image: url('${$scope.templateDetail.template_img_name}'); background-size: 100%;`);
      }

      // 채널 추가 버튼 여부
      if ($scope.templateDetail.buttons) {
        $scope.templateDetail.buttons = JSON.parse(`[${$scope.templateDetail.buttons}]`);
        $scope.use_add_channel_yn = $scope.templateDetail.buttons.some(btn => btn.type === 'AC');
      } else {
        $scope.templateDetail.buttons = [];
        $scope.use_add_channel_yn = false;
      }

      if ($scope.atalk.plus_friend_id && $scope.atalk.send_title && $scope.templateDetail.name && $scope.send_type && $scope.send_time && $scope.sms_send_yn) {
        $scope.reqCheck = true;
      }

      $timeout(() => {});
    };

    /**
     * 알림톡 충전하기 버튼 함수
     */
    $scope.chargeAtalkCnt = function() {
      if (!(userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.pay?.use_yn)) {
        return commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\n PLAYAUTO 2.0 관리자에게 문의해보세요!');
      }
      $rootScope.openPayReq();
      $uibModalInstance.dismiss('cancel');
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
     * 템플릿 추가 버튼 - 템플릿 생성 모달로 이동
     */
    $scope.addTemplate = () => {
      commonSVC.openModal('x3g', { data: { plusIdList: $scope.plusIdList } }, 'AltalkTemplateCtrl', 'views/alimtalk/modals/altalk_template_add.html');
    };

    $scope.update = async () => {
      // 예약인 경우 선택한 시간에 맞게 시간 데이터 정제
      const selectHour = $scope.atalk.ampm === '1' ? (parseInt($scope.atalk.selectedHour) !== 12 ? parseInt($scope.atalk.selectedHour) + 12 : parseInt($scope.atalk.selectedHour)) : (parseInt($scope.atalk.selectedHour) === 12 ? parseInt($scope.atalk.selectedHour) + 12 : $scope.atalk.selectedHour);
      const changeTime = $scope.send_time === 'reserved' ? `${selectHour === 24 ? '00' : selectHour < 10 ? `0${selectHour}` : selectHour}:${$scope.atalk.selectedMin}:00` : null;

      // 수정 사항 로그 기록 배열
      const log_contents = [];
      if ($scope.send_type === 'scrap_order') {
        $scope.atalk.send_target_criteria.sol_action = '신규주문 수집 시';
      }

      if ($scope.send_time === 'reserved') {
        $scope.atalk.auto_send_time = `${selectHour === 24 ? 0 : selectHour}:${$scope.atalk.selectedMin}:00`;
      } else {
        $scope.atalk.auto_send_time = '';
      }

      if ($scope.originData.send_title !== $scope.atalk.send_title) {
        log_contents.push(`발송명: ${$scope.originData.send_title} -> ${$scope.atalk.send_title}`);
      }
      if ($scope.originData.plus_friend_name !== $scope.atalk.plus_friend_name) {
        log_contents.push(`플러스 친구 ID: ${$scope.originData.plus_friend_name} -> ${$scope.atalk.plus_friend_name}`);
      }
      if ($scope.originData.template.id !== $scope.templateDetail.id) {
        log_contents.push(`템플릿: ${$scope.originData.template?.name} -> ${$scope.templateDetail.name}`);
      }
      if ($scope.originData.send_target_criteria?.sol_action !== $scope.atalk.send_target_criteria?.sol_action) {
        log_contents.push(`발송 대상: ${$scope.originData.send_target_criteria?.sol_action} -> ${$scope.atalk.send_target_criteria?.sol_action}`);
      }
      if ($scope.originData.auto_send_time !== changeTime) {
        log_contents.push(`발송 시간: ${$scope.originData.auto_send_time || '즉시'} -> ${changeTime || '즉시'}`);
      }
      if (!$scope.originData.alt_send_yn !== !!(+$scope.sms_send_yn)) {
        log_contents.push(`SMS 대체 발송: ${$scope.originData.alt_send_yn ? '발송' : '발송안함'} -> ${$scope.sms_send_yn === '0' ? '발송' : '발송안함'}`);
      }
      if ($scope.originData.send_target_criteria?.send_type_bundle_info !== $scope.atalk.send_target_criteria?.send_type_bundle_info) {
        log_contents.push(`발송대상: 묶음주문 대표정보 발송 ${$scope.originData.send_target_criteria?.send_type_bundle_info ? '체크' : '해제'} -> ${$scope.atalk.send_target_criteria?.send_type_bundle_info ? '체크' : '해제'}`);
      }

      const params = {
        task_id: $scope.atalk.id,
        name: $rootScope.user_profile.user_name,
        m_no: $rootScope.user_profile.m_no,
        title: $scope.atalk.send_title,
        template: $scope.templateDetail,
        sendTimeDetail: $scope.atalk.auto_send_time ? $scope.atalk.auto_send_time : 'now',
        send_target_criteria: $scope.atalk.send_target_criteria,
        use_yn: $scope.atalk.use_yn,
        sol_no: $rootScope.user_profile.sol_no,
        sms_send_yn: $scope.sms_send_yn !== '1',
        send_plus_friend_id: $scope.atalk.plus_friend_id,
        log_contents
      };

      // 자동 발송 등록 모달에 표시될 메시지
      const sendInfoMsg = `${$scope.atalk.send_target_criteria.sol_action} ${$scope.atalk.send_time === 'scheduled' ? `${$scope.atalk.book_send_time.toString()}에` : '즉시'} ${$scope.templateDetail.name} 발송됩니다.`;

      const modal = commonSVC.openModal('lg', { data: { params, sendInfoMsg } }, 'AltalkAutoSendUpdateModalCtrl', 'views/alimtalk/modals/altalk_auto_update.html');

      modal.result.then((re) => {
        if (re === 'success') {
          $uibModalInstance.close('success');
        }
      });
    };

    /**
     * 템플릿 검색 함수
     */
    $scope.searchAltalkTemplate = async (templateTitle) => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.atalk.plus_friend_id.toString(), search_key: 'template_name', search_word: templateTitle, status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.templateList = (templateList.data?.results).filter(r => r.type !== 'G'); // 개인 템플릿
      $scope.basicTemplateList = (templateList.data?.results).filter(r => r.type === 'G');  // 제공 템플릿

      $timeout(() => {});
    };
  });