'use strict';

angular.module('gmpApp').controller('SystemGetAutoOrder', function ($scope, $uibModalInstance, commonSVC, data, shopAccountModel) {
  $scope.selectedAll = false;
  $scope.mall = [];
  //$socpe.updateInfos = {};
  let select_shops_cnt = 0;         // 선택한 쇼핑몰 갯수

  // 사용중인 쇼핑몰, 아이디 가져오기
  if (data.channelList.length) {
    $scope.mall = commonSVC.getSiteIdList(data.channelList);
    _.each($scope.mall, function (m) {
      // 자동주문수집사이트만 체크
      if (m.ord_auto_collect_yn) {
        m.selected = true;
      } else {
        m.selected = false;
      }
    });
  }

  $scope.checkboxClick = function(type) {
    if (type === 'all') {
      angular.forEach($scope.mall, function (mall) {
        mall.selected = $scope.selectedAll;
      });
    } else {
      if ($scope.selectedAll && !($scope.mall[type].selected)) {
        $scope.selectedAll = false;
      } else {
        let flag = true;

        for (const i in $scope.mall) {
          if (!$scope.mall[i].selected) {
            flag = false;

            return;
          }
        }
        $scope.selectedAll = flag;
      }
    }
  };

  // 저장
  $scope.ok = function () {
    const idx = _.findIndex($scope.mall, { selected: true });

    if (idx < 0) {
      commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

      return false;
    }

    // 선택된 정보 담기.
    let params = [];
    const sendParams = [];

    angular.forEach($scope.mall, function(v) {
      if (v.selected == true) {
        // 작업 추가
        params = {
          shop_cd: v.shop_cd,
          shop_id: v.shop_id
        };
        sendParams.push(params);
        select_shops_cnt++;
      }
    });

    const sendData = {
      data: sendParams
    };

    shopAccountModel.setAutoScrap(sendData, function (state, data) {
      if (state == 'success') {

        commonSVC.showToaster('success', '', '자동수집 쇼핑몰 정보가 수정되었습니다.');

        const return_value = {
          select_cnt: select_shops_cnt
        };

        $uibModalInstance.close(return_value);
      } else {
        //$scope.submitDisabled = false;
        commonSVC.showToaster('error', '', data);
      }
    });
  };

  /**
   * 찯 닫기
   */
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

});
