'use strict';

angular.module('gmpApp')
  .controller('ConfigsMasterEditCtrl', function ($scope, $uibModalInstance, userInfo, commonModel, userModel, commonSVC, logHistorySVC, gettextCatalog) {
    const HTEL_AUTH_TYPE = 'htel';
    const SECOND = 1000;
    const FIVE_MINUTE = SECOND * 60 * 5;

    $scope.RESEND_TOOLTIP = '인증번호 미수신으로 재전송 하거나 휴대폰번호를 다시 수정하여\n인증번호 재발송이 가능합니다.';

    $scope.userGrade = userInfo.user.user_grade == '마스터' ? '마스터' : userInfo.user.auth_type;

    // 셀렉트 값 변경시 초기화
    function resetMode() {
      $scope.userName = false;
      $scope.passEdit = false;
      $scope.userTel = false;
      $scope.userHtel = false;
      $scope.twofactorType = false;
      $scope.department = false;
      $scope.agreeMail = false;
      $scope.agreeSms = false;
    }

    //비밀번호 초기값 변수
    $scope.password = {
      old: '',
      new: '',
      confirm: ''
    };

    let timer;

    function userHtelInit() {
      clearInterval(timer);

      // 휴대폰 번호 변경 및 인증 여부
      $scope.userHtelAuthData = {
        isEdit: !$scope.userData.user_htel,
        isSend: false,
        isVerify: false,
        limitTime: FIVE_MINUTE,
        limitString: '',
        htel: $scope.userData.user_htel,
        key: '',
        error: ''
      };
    }

    //항목 열고 닫기 flag
    $scope.flag_change = function(mode) {
      // 초기화
      init();
      $scope[mode] = !$scope[mode];
    };

    const logField = {
      user_name: '이름',
      user_tel: '직통번호',
      user_htel: '핸드폰',
      twofactor_type: '2차 인증 수단',
      department: '소속',
      opt_in_mail: 'Email 수신 동의여부',
      opt_in_sms: 'SMS 수신 동의여부',
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 비밀번호 변경
     */
    $scope.regist = async function () {
      //비밀번호 정규식
      const regExp = /^(?=.*[a-zA-Z])(?=.*[,.<>/?~()_[\]{}|;:!@#$%^&*+=-])(?=.*[0-9]).{8,}$/;
      const validPassword = regExp.test($scope.password.new);

      if (!validPassword) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호 규칙에 맞게 다시 설정해 주세요.'));

        return false;
      }

      if ($scope.password.old == '' || $scope.password.new == '' || $scope.password.confirm == '') {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('비밀번호를 다시 확인해주세요.'));

        return false;
      }

      if (!$scope.password.old || !$scope.password.new || !$scope.password.confirm) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('변경 사항이 없습니다.'));

        return false;
      }

      if ($scope.password.new !== $scope.password.confirm) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('새 비밀번호와 비밀번호 확인에 입력된 내용이 다릅니다. 다시 확인해 주세요.'));
        $scope.password.old = '';
        $scope.password.new = '';
        $scope.password.confirm = '';

        return false;
      } else {
        const resolve = {
          m_no: userInfo.user.m_no,
          old_password: $scope.password.old,
          new_password: $scope.password.new
        };

        const confirm = await commonSVC.showConfirm('비밀번호 변경 유의사항', '비밀번호를 변경하시면 모든 환경에서 로그아웃됩니다. 변경하시겠습니까?\n(홈페이지, 플토 글로벌 솔루션에 동시 접속 중인 경우 모두 로그아웃됩니다.)');

        if (!confirm) {
          return;
        }

        userModel.setPassword(resolve, function (state, result) {
          if (result == 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('비밀번호가 수정 되었습니다.'));
            $scope.passEdit = false;
            $scope.password.old = '';
            $scope.password.new = '';
            $scope.password.confirm = '';
          } else {
            if (result.data.message == 'same_password') {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('기존 비밀번호와 동일합니다. 새 비밀번호를 입력하시기 바랍니다.'));
              $scope.passEdit = true;
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('기존 비밀번호가 틀립니다. 다시 확인해 주세요.'));
              $scope.passEdit = true;
            }
          }
        });
      }

    };

    // 휴대폰 번호 변경 인증번호 전송
    $scope.sendHtelAuth = () => {
      const htel = $scope.userHtelAuthData.htel;

      if (!(10 <= htel.length && htel.length <= 16)) {
        commonSVC.showMessage('실패', '휴대폰번호 형식에 맞게 입력해 주세요.\n(10~16자리 숫자만 입력할 수 있습니다.)');

        return;
      }

      const params = {
        authType: HTEL_AUTH_TYPE,
        sendType: 'sms',
        email: userInfo.user.email,
        to: htel
      };

      commonModel.sendSubAuth(params)
        .then(() => {
          $scope.userHtelAuthData.isSend = true;
          $scope.userHtelAuthData.key = '';
          $scope.userHtelAuthData.error = '';
          $scope.userHtelAuthData.limitTime = FIVE_MINUTE;
          $scope.userHtelAuthData.limitString = moment($scope.userHtelAuthData.limitTime).format('mm:ss');

          clearInterval(timer);

          timer = setInterval(() => {
            $scope.userHtelAuthData.limitTime = $scope.userHtelAuthData.limitTime - SECOND;
            $scope.userHtelAuthData.limitString = moment($scope.userHtelAuthData.limitTime).format('mm:ss');

            if (!$scope.userHtelAuthData.limitTime) {
              clearInterval(timer);

              $scope.userHtelAuthData.error = '인증 유효시간이 초과되었습니다. 인증번호를 재전송 하시어 휴대폰 인증을 완료하세요.';
            }

            $scope.$apply();
          }, 1000);

          commonSVC.showMessage('성공', '인증번호가 발송되었습니다.\n휴대폰에 수신된 인증번호를 확인해주세요.');
        });
    };

    // 휴대폰 번호 변경 인증번호 인증
    $scope.verifyHtelAuth = () => {
      const key = $scope.userHtelAuthData.key;

      if (key.length !== 6) {
        $scope.userHtelAuthData.error = '6자리 숫자로 입력하세요.';

        return;
      }

      const params = {
        authType: HTEL_AUTH_TYPE,
        email: userInfo.user.email,
        to: $scope.userHtelAuthData.htel,
        key
      };

      commonModel.verifySubAuth(params)
        .then(() => {
          $scope.userHtelAuthData.isVerify = true;
          $scope.userHtelAuthData.error = '';

          clearInterval(timer);

          commonSVC.showMessage('성공', '휴대폰 인증이 완료되었습니다.\n번호 저장 버튼을 눌러 변경하신 정보를 저장해주세요.');
        })
        .catch(() => {
          $scope.userHtelAuthData.error = '유효하지 않은 인증번호 입니다.';
        });
    };

    /**
     * 사용자 정보 수정
     */
    $scope.onSubmit = function(updateField) {
      const log_content = logHistorySVC.compareValue(logField, userModel.getOld(), $scope.userData);

      if (!log_content) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('변경된 내용이 없습니다.'));

        return false;
      }

      // 단일항목만 수정
      $scope.userData.updateFields = [updateField];

      userModel.update($scope.userData, log_content, function (state) {
        const today = moment().format('YYYY년 MM월 DD일');

        if (state == 'success') {
          if (['email_yn', 'sms_yn'].includes(updateField)) {
            const subscribe_yn = $scope.userData[`opt_in_${updateField === 'email_yn' ? 'mail' : 'sms'}`] ? '동의' : '거부';

            commonSVC.showToaster('success', '처리결과 안내', gettextCatalog.getString(`플레이오토의 마케팅 정보를 ${today} ${updateField === 'email_yn' ? 'E-mail' : 'SMS'} 수신 ${subscribe_yn}하셨고, 이에 따라 수신${subscribe_yn} 처리가 완료되었습니다.`));
          } else {
            commonSVC.showToaster('success', '', gettextCatalog.getString('사용자 정보가 수정 되었습니다.'));
          }
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '', gettextCatalog.getString('사용자 정보 수정이 실패하였습니다.'));
          $scope.cancel();
        }
      });
    };

    function init() {
      // 사용자 정보 불러오기
      userModel.load(userInfo.user.m_no, function(state, result) {
        if (state != 'success') {
          commonSVC.showMessage(gettextCatalog.getString('존재하지 않는 사용자 정보입니다.'));
          $scope.cancel();
        } else {
          $scope.userData = result;
          userModel.setOld(_.cloneDeep($scope.userData));

          userHtelInit();
        }
      });

      // 탭열기시 다른 모든 탭 닫기 상태로 변경
      resetMode();
    }

    init();

  });
