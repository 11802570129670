/**
 * Created by william on 2017-07-13.
 */
'use strict';

angular.module('gmpApp')
  .factory('A006categorySVC', function () {

    return {

      //카테고리 data
      category_data: {
        key_cate_attr_sel: '1', //필수 속성 등록여부
        key_ShopCateUseYn: 'N', //shop카테고리 등록여부
        plusShopCateData: [],
        product: '', //상품군
        certification: '0', //인증유형 선택
        set_all_shop: false, //상품적용여부
        cert_type: '', //인증유형
        key_cate_attr_list: []
      },

      cert_type: [
        { name: '국가통합인증(KC마크)', value: 'cert_01' },
        { name: '공산품 안전인증(KPS)', value: 'cert_02' },
        { name: '전기용품안전인증(KCC)', value: 'cert_03' },
        { name: '공산품 자율안전확인(KPS)', value: 'cert_04' },
        { name: '전기용품자율안전확인(KCC)', value: 'cert_05' },
        { name: '방송통신기기 인증(MIC)', value: 'cert_06' },
        { name: '친환경인증-유기농산물', value: 'cert_07' },
        { name: '친환경인증-무농약농산물', value: 'cert_08' },
        { name: '친환경인증-저농약농산물', value: 'cert_09' },
        { name: '우수농산물(GAP인증))', value: 'cert_10' },
        { name: '친환경인증-유기축산물인증', value: 'cert_11' },
        { name: '친환경인증-무항생제축산물인증', value: 'cert_12' },
        { name: '도축증명서', value: 'cert_13' },
        { name: 'HACCP(위해요소중점관리우수축산물)', value: 'cert_14' },
        { name: '농산물이력추적', value: 'cert_15' },
        { name: '친환경수산물', value: 'cert_16' },
        { name: '수산물 품질인증', value: 'cert_17' },
        { name: '수산특산물 품질인증', value: 'cert_18' },
        { name: '가공식품 표준화인증 (KS)', value: 'cert_19' },
        { name: '유기가공식품인증', value: 'cert_20' },
        { name: '등급판정확인서', value: 'cert_21' },
        { name: '우수건강식품제조기준(GMP)인증', value: 'cert_22' },
        { name: '건강기능식품인증', value: 'cert_23' },
        { name: '의료기기 광고사전심의', value: 'cert_24' },
        { name: '건강기능식품 광고사전심의', value: 'cert_25' },
        { name: '원산지증명', value: 'cert_26' },
        { name: '해외안전인증서류', value: 'cert_27' },
        { name: '수입신고필증', value: 'cert_28' },
        { name: '형식승인', value: 'cert_29' },
        { name: '형식검정 / 형식등록', value: 'cert_30' },
        { name: '전자파적합등록', value: 'cert_31' },
        { name: '공산품 어린이 보호포장(KPS)', value: 'cert_32' },
        { name: '전통식품품질 인증', value: 'cert_33' },
        { name: '생활용품 공급자적합성', value: 'cert_34' },
        { name: '전기용품 공급자적합성', value: 'cert_35' },
        { name: '방송통신기기 적합등록', value: 'cert_36' },
        { name: '방송통신기기 잠정인증', value: 'cert_37' },
        { name: '수산전통식품품질인증', value: 'cert_38' },
        { name: '친환경수산가공품품질인증', value: 'cert_39' },
        { name: '어린이제품 안전인증', value: 'cert_40' },
        { name: '어린이제품 안전확인', value: 'cert_41' },
        { name: '어린이제품 공급자적합성확인', value: 'cert_42' },
        { name: '위해우려제품 자가검사번호', value: 'cert_43' },
      ],
      // /**
      //  * 배송템플릿 data
      //  */
      // shipment_template_data : {
      //   site_code:"",
      //   site_name:"",
      //   site_id:"",
      //   type:"",
      //   key_send_addr_list:"",// 출하지 select 리스트
      //   key_send_name:"",//출하지정보 출하지명
      //   key_send_addr:"",//출하지정보 출하지 주소
      //   key_send_jeju:"",//출하지정보 부가정보 첫번째 인풋
      //   key_send_other:"",//출하지정보 부가정보 두번째 인풋
      //   key_send_tel:"",//출하지정보 일반
      //   key_send_htel:"",//출하지정보 휴대폰
      //   key_selDelivery:"1",// 배송방법 radio
      //   key_IacDeliveryCOMP:"", //배송방법 택배사선택 select
      //   key_chkAdditionalShippingPickup:"",//방문수령
      //   key_groupStorePickupFavor:"1",//방문수령 혜택없음
      //   key_txtStorePickupCashFavorCost:"",//금액환불 input
      //   key_txtStorePickupGiftFavorBrief:"",//사은품증정input
      //   key_visit_addr:"",//수령지 주소
      //   key_chkAdditionalShippingPost:"", //추가배송방법 우편 체크
      //   key_chkAdditionalShippingQuick:"", //추가배송방법 퀵서비스 체크
      //   key_rbtnShippingPost:"Free",//추가배송방법 radio
      //   key_txtQuickServiceCompanyName:"",//배송지역선택 텍스트박스
      //   key_txtQuickServiceCompanyPhone:"",//추가배송방법 연락처
      //   key_txtShippingPostCost:"",//선결제
      //   key_deliv_fee_type:"0",//배송비선택 select
      //   php_select_count_deliv:[],
      //   php_select_countfee_deliv:[],
      //   key_ucPrePayTransportFee:"",//선결제시 배송비
      //   key_DeliveryFeeTemplateNo:"",//묶음배송비 하위 select
      //   key_rdoEachDeliveryFeeType:"",//배송비선택 무료유료라디오
      //   key_fixe_deliv_fee:"",//유료 금액
      //   key_EachDeliveryFeeConditionFreeAmnt1:"",//조건부무료 앞
      //   key_EachDeliveryFeeConditionFreeAmnt2:"",//조건부무료 뒤
      //   key_rdoConditionType:"1",//수량별 차등
      //   key_txtASInfo:"",//a/s정보
      //   key_deliv_addr_list:"",//반품 배송지
      //   key_deliv_name:"",//반품배송지명
      //   key_deliv_addr1:"",//반품배송지주소
      //   key_sxMobileTel1:"",//반품 연락처(휴대)
      //   key_ddlHomeTel1:"",//반품 연락처(일반)
      //   key_HomeDelivery:"",//반품지정택배
      //   key_txtReturnFee:""//반품/교환 배송비
      //
      // },

      /**
       * 템플릿 data
       */
      etc_template_data: {
        //기본설정
        site_code: '',
        site_name: '',
        site_id: '',
        type: '',
        name: '',
        // key_rdoMethod:"1",//판매방식
        key_selTerms: '15', //판매기간
        key_rbtnlPreSale: '0', //예약판매
        key_txtPreSaleShippingDate: '', //배송시작일
        key_rdoProdType: 'N', //물품상태
        key_groupNewStatus: 'radStatusUsedItem', //중고상품 / 전시리퍼
        key_txtUsedMonth: '', //사용개월수
        // key_custom_cate_use:"",//카테고리
        // key_cateAttrUse:"",//속성카테고리
        // key_PhoneFreeUrl:"",//가입시 신청서주소
        key_ShopCateUseYn: 'N', //shop 카테고리 등록여부
        // key_chkIsbnCodeNull:"", //ISBN 등록여부
        key_BuyLimit: '1', // 최대구매허용수량
        // key_BuyLimitQty1:"",
        // key_BuyLimitDay:"",
        // key_BuyLimitQty2:"",
        key_AdultProduct: 'False', //청소년 구매불가
        // key_CustCategoryNo:"",// 판매자카테고리
        key_rdoOriginProductType: '3', //원산지분류
        key_IsMultipleOrigin: 'False', //복수원산지
        // key_txtCapacityVolume:"",//용량/규격 input
        // key_sltSellingUnit:"",//용량/규격 단위선택
        // key_chkIsMultipleSellUnitCode:"",//용량/규격 체크박스
        // key_txtECouponPeriod:"",//쿠폰유효기간
        key_UseSellerNicknameIac: '1', //판매자 닉네임
        // key_catalog_chk:"",//모델명/브랜드 모델명등록안함
        // key_brand_chk:"N",// 모델명/브랜드 브랜드 등록안함
        // key_groupIsBrandShop:"N",//브랜드관 노출선택
        // ddlBrandShopLCate:"",//브랜드관 카테고리 대
        // ddlBrandShopMCate:"",//브랜드관 카테고리 중
        // ddlBrandShopSCate:"",//브랜드관 카테고리 소
        // key_bs_WordNm:"",//브랜드관 브랜드명
        // key_ChangeOptYn:"N",//필수옵션 수정여부
        // key_ChangeAddOptYn:"Y",//추가구매옵션 수정여부
        // key_twice_price_del:"1",//옵션가 기준 제외등록 여부
        // key_twice_count_del:"",//옵션개수 제외등록
        key_stock_YN: 'N', //옵션 재고수량 관리
        // key_displayYN:"N",
        key_rec_opt_use: 'N', //추천 옵션 사용여부
        key_order_type_sort: '1', //옵션값 노출 방식
        key_SellerDiscount: '2', //판매자부담 할인
        key_SellerDiscountType: '1', //
        key_SellerDiscountAmt: '',
        key_chkSellerDiscountPeriodUsed: '', //기간설정사용
        key_SYIStep3_IsPcs: 'True', //포털가격비교 사이트 등록
        key_SYIStep3_IacPcsCoupon: 'True', //가격비교사이트 쿠폰 적용 여부
        key_SYIStep3_IacDiscountAgreement: 'True', //옥션/지마켓 비용 즉시할인적용
        key_SYIStep3_IacBuyerBenefit_IsUsed: '2', //특별할인
        key_SYIStep3_IacBuyerBenefit_StartDate: '', //적용기간 시작날짜
        key_SYIStep3_IacBuyerBenefit_EndDate: '', //적용기간 종료날짜
        key_SYIStep3_IacBuyerBenefit_IsMemberDiscount: '', //우수회원 체크
        key_SYIStep3_IacBuyerBenefit_MemberDiscountPrice: '', //할인금액
        key_SYIStep3_IacBuyerBenefit_IsBulkDiscount: '', //복수구매할인 주문수량 여부
        key_SYIStep3_IacBuyerBenefit_BulkDiscountQty: '2', //복수구매할인 주문수량
        key_SYIStep3_IacBuyerBenefit_BulkDiscountPrice: '', //복수구매할인 금액
        key_SYIStep3_IacSellerPoint_IsUsed: '2', //판매자 지급 스마일캐시 사용여부
        key_selIacSellerPointInput: '', //판매자 지급 스마일캐시 적립률
        key_SYIStep3_IacDonation_IsUsed: '2', //기부 여부 선택
        key_SYIStep3_IacDonation_StartDate: '',
        key_SYIStep3_IacDonation_EndDate: '',
        // key_SYIStep3_IacChance_IsUsed:"2",//찬스혜택
        key_SYIStep3_IacDonation_DonationPrice: '',
        // key_SYIStep3_IacChance_StartDate:"",
        // key_SYIStep3_IacChance_EndDate:"",
        key_plus_img: 'N', //추가이미지등록
        // key_auto_resize:"Y",//이미지 리사이징
        // key_resize_Size:"400",//이미지 리사이징 적용함 사이즈
        // key_newContent:"N",//상세설명 설정영역
        key_editYN: 'err', // 중복등록 오류 표시
        //상품정보고시 추가입력
        // key_ref_use_YN:"N",//일괄적용설정
        // key_title_group:"N",
        // key_title_use_YN:"Y",//타이틀 사용여부
        // key_deliv_day:"",//주문후 예상배송시간
        // b64_noti_item_value:"",//기타 특이사항
        //머리말/꼬리말
        // key_name_print_method:"",//상품명 출력방법
        // key_pname_add_str:"",//상품명 추가글귀(앞)
        // key_pname_add_str2:"",//상품명 추가글귀(뒤)
        // b64_content1:"",//상품상세설명 앞부분 추가 내용
        // b64_content2:"",//상품상세설명 뒷부분 추가 내용
        // key_content2_postion:"1",//상품상세설명 뒷부분 출력위치
        //부가서비스
        key_Premium_use: '', //프리미엄
        key_Focus_use: '', //주목점수
        key_NameBold_use: '', //상품명 굵게
        key_Attention_use: '', //시선집중
        key_PhotoFocus_use: '', //포토포커스
        key_PremiumPlus_use: '', //프리미엄플러스
        //배송
        key_send_addr_list: '', // 출하지 select 리스트
        key_send_name: '', //출하지정보 출하지명
        key_send_addr: '', //출하지정보 출하지 주소
        key_send_jeju: '', //출하지정보 부가정보 첫번째 인풋
        key_send_other: '', //출하지정보 부가정보 두번째 인풋
        key_send_tel: '', //출하지정보 일반
        key_send_htel: '', //출하지정보 휴대폰
        key_selDelivery: '1', // 배송방법 radio
        key_IacDeliveryCOMP: '', //배송방법 택배사선택 select
        key_chkAdditionalShippingPickup: '', //방문수령
        // key_groupStorePickupFavor:"1",//방문수령 혜택없음
        key_txtStorePickupCashFavorCost: '', //금액환불 input
        key_txtStorePickupGiftFavorBrief: '', //사은품증정input
        key_visit_addr: '', //수령지 주소
        key_chkAdditionalShippingPost: '', //추가배송방법 우편 체크
        key_chkAdditionalShippingQuick: '', //추가배송방법 퀵서비스 체크
        key_rbtnShippingPost: 'Free', //추가배송방법 radio
        key_txtQuickServiceCompanyName: '', //배송지역선택 텍스트박스
        key_txtQuickServiceCompanyPhone: '', //추가배송방법 연락처
        key_txtShippingPostCost: '', //선결제
        key_deliv_fee_type: '0', //배송비선택 select
        php_select_count_deliv: [],
        php_select_countfee_deliv: [],
        key_ucPrePayTransportFee: '', //선결제시 배송비
        key_DeliveryFeeTemplateNo: '', //묶음배송비 하위 select
        key_rdoEachDeliveryFeeType: '1', //배송비선택 무료유료라디오
        key_fixe_deliv_fee: '', //유료 금액
        key_EachDeliveryFeeConditionFreeAmnt1: '', //조건부무료 앞
        key_EachDeliveryFeeConditionFreeAmnt2: '', //조건부무료 뒤
        key_rdoConditionType: '1', //수량별 차등
        // key_txtASInfo:"",//a/s정보
        key_deliv_addr_list: '', //반품 배송지
        key_deliv_name: '', //반품배송지명
        key_deliv_addr1: '', //반품배송지주소
        key_sxMobileTel1: '', //반품 연락처(휴대)
        key_ddlHomeTel1: '', //반품 연락처(일반)
        key_HomeDelivery: '', //반품지정택배
        key_txtReturnFee: ''//반품/교환 배송비
      }
    };
  });
