'use strict';

/**
 * Created by ally on 2018. 5. 16..
 */

angular.module('gmpApp')
  .controller('OrderBundlePrintCtrl', function ($uibModalInstance, $scope, data, $rootScope, settings, gettextCatalog, commonSVC, shipmentModel, memoModel) {
    $scope.isBundle = data.isBundle;
    $scope.ordCount = data.ordCount;
    $scope.bundle = $scope.isBundle ? { type: 'user', ship_cost_type: '1', ship_cost: '0' } : { type: 'user' };
    $scope.sol_stock = $rootScope.user_profile.sol_stock;
    $scope.one_page = false;

    $scope.oriFormSetting = {};

    $scope.print = async (autoPrintFlag) => {
      const { results } = (await shipmentModel.ordPrintFormDetail($scope.grid.methods.selectedData('template_no')[0])).data;

      const resolve = {
        selectList: function() {
          return shipmentModel.load({ uniq: data.uniqList });
        },
        memoList: function() {
          return memoModel.list({ uniq: data.uniqList, memo_code: 'uniq', memo_type: 'ord' });
        },
        bundle: function() {
          return $scope.bundle;
        },
        orderby: function() {
          return data.orderby;
        },
        type: function() {
          return $scope.grid.methods.selectedData('etc2')[0] || 'custom';
        },
        one_page: function() {
          return $scope.one_page;
        },
        selectData: function() {
          return results;
        },
        autoPrintFlag: function() {
          return !!autoPrintFlag;
        },
        ordCount: function() {
          return $scope.ordCount;
        }
      };

      commonSVC.openModal('lg', resolve, 'OrderPrintCtrl', 'views/order/shipment/modals/order_print.html');
    };

    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchForm: {
        search_word: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,      // 선택 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
        showCount: 25
      }
    };

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);
    $scope.searchBtn = {
      table_actions: [
        {
          label: gettextCatalog.getString('삭제'),
          add_class: 'btn-default',
          disable: () => {
            return !$scope.searchData.selectCount || $scope.grid.methods.selectedData('all').find(form => !form.custom_yn);
          },
          action: () => {
            $scope.deleteForm();
          },
        },
        {
          label: gettextCatalog.getString('수정'),
          add_class: 'btn-default',
          disable: () => {
            return !$scope.searchData.selectCount || $scope.grid.methods.selectedData('all').find(form => !form.custom_yn);
          },
          action: () => {
            $scope.editForm('custom');
          },
        },
        {
          label: gettextCatalog.getString('복사'),
          add_class: 'btn-default',
          disable: () => {
            return !$scope.searchData.selectCount || $scope.grid.methods.selectedData('all').find(form => !form.custom_yn);
          },
          action: () => {
            $scope.copyForm();
          },
        },
        {
          label: gettextCatalog.getString('추가'),
          add_class: 'btn-default',
          action: () => {
            $scope.addForm();
          },
        }
      ]
    };

    $scope.addForm = async () => {
      const data = {
        type: 'add',
        bundle: $scope.bundle,
        selectList: function() {
          return shipmentModel.load({ uniq: $scope.grid.methods.selectedData('uniq') });
        }
      };
      const modal = await commonSVC.openModal('full', { filedList: {}, data }, 'addOrdPrintFormCtrl', 'views/order/shipment/modals/order_bundle_print_add.html');

      if (await modal.result === 'success') {
        commonSVC.showToaster('success', '성공', '주문서 인쇄 양식 추가 성공');
        $scope.searchDo();
      }
    };

    $scope.editForm = async (type, template_no) => {
      if (type === 'custom') {
        const data = {
          type: 'edit',
          bundle: $scope.bundle,
          template_no: $scope.grid.methods.selectedData('template_no')[0],
          selectList: function() {
            return shipmentModel.load({ uniq: $scope.grid.methods.selectedData('uniq') });
          }
        };
        const modal = await commonSVC.openModal('full', { data }, 'addOrdPrintFormCtrl', 'views/order/shipment/modals/order_bundle_print_add.html');

        if (await modal.result === 'success') {
          commonSVC.showToaster('success', '성공', '주문서 인쇄 양식 수정 성공');
          $scope.searchDo();
        }
      } else {
        //
      }
    };

    $scope.copyForm = async () => {
      const result = await commonSVC.showConfirmCustom({ title: '주문서 양식을 복사하시겠습니까?', confirmButtonText: '복사' });

      if (result) {
        try {
          await shipmentModel.copyOrdPrintForm({ templateNo: $scope.grid.methods.selectedData('template_no')[0] });

          commonSVC.showToaster('success', '성공', '주문서 인쇄 양식 복사 성공');
          $scope.searchDo();
        } catch (err) {
          commonSVC.showToaster('error', '실패', '주문서 인쇄 양식 복사 실패');
        }
      }
    };

    $scope.deleteForm = async () => {
      const result = await commonSVC.showConfirmCustom({ title: '주문서 양식을 삭제하시겠습니까?', text: '삭제된 양식은 복구가 불가능합니다.', confirmButtonText: '삭제' });

      if (result) {
        try {
          await shipmentModel.deleteOrdPrintForm($scope.grid.methods.selectedData('template_no')[0]);

          commonSVC.showToaster('success', '성공', '주문서 인쇄 양식 삭제 성공');
          $scope.searchDo();
        } catch (err) {
          commonSVC.showToaster('error', '실패', '주문서 인쇄 양식 삭제 실패');
        }
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      gridHeight: 450,
      selectOptions: {
        checkbox: false,
        enableRowSelection: true,
        multiSelect: false
      },
      noAutoResize: true,
      pinningColumns: [],
      alignCenterColumns: ['custom_yn', 'template_name', 'mdate', 'bookmark_yn'],
      notResizingColumns: ['mdate'],
      enableRowSelection: true,
      notMovingColumns: 'ALL',
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['custom_yn', 'template_name', 'bookmark_yn'],
      alignRightColumns: [],
      notVisibleColumns: [],
      initPagingSize: 25,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/print-forms`,
        requestWillAction: (data) => {
          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: result => {
          $scope.oriFormSetting = result.results.filter(f => !f.custom_yn);

          return result.results;
        }
      },
      rowHeight: 47,
      columns: [
        {
          key: 'custom_yn',
          title: '구분',
          width: 40,
          template: (row) => {
            return row.custom_yn ? '사용자 양식' : '기본 양식';
          }
        },
        {
          key: 'template_name',
          title: '양식명',
          width: 120,
          filter: 'dateValid',
          class: ['overflow-hidden'],
          template: (row) => {
            return !$scope.sol_stock && row.template_name.includes('미사용') ? '패킹리스트' : row.template_name;
          }
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 80,
          class: ['overflow-visible', 'multiSelectUl'],
          template: (row) => {
            return row.custom_yn ? moment(new Date(row.mdate)).format('YYYY-MM-DD HH:mm:ss') : `<div ng-click="grid.appScope.toggleRowSelection(row)"><select id="form_multi_select_${row.etc2}" ng-model="template_data" ng-init="grid.appScope.setMultiSelect('${row.etc2}')" multiple="multiple"></select></div>`;
          }
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 40,
          template: (row) => {
            return `<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeBookmark('${row.template_no}', '${row.template_name}', ${row.bookmark_yn})"></pa-ui-switch>`;
          }
        }
      ]
    };

    /**
     * 사용자 설정 별로 기본 양식 멀티 셀렉트 설정
     */
    $scope.setMultiSelect = (type) => {
      const form = $scope.oriFormSetting.find(f => f.etc2 === type);
      const template_data = JSON.parse(form.template_data);

      const optionGroup = [];

      Object.keys(template_data).forEach(type => {
        const opt = Object.keys(template_data[type]).map(field => {

          return {
            label: template_data[type][field].title,
            title: template_data[type][field].title,
            value: template_data[type][field].value,
            selected: template_data[type][field].selected,
            tooltip: template_data[type][field].tooltip || ''
          };
        });

        optionGroup.push({ label: type === 'ord' ? '주문 상세' : '상품 내역', children: opt });
      });

      $(`#form_multi_select_${form.etc2}`).multiselect({
        includeSelectAllOption: false,
        buttonWidth: '100%',
        visibleInput: false,
        onChange: async (option, checked, ori_form = form) => {

          // 기존 설정된 필드 설정에서 수정하면 seleted가 리셋돼서 수동으로 selected 설정해줌
          const checkedList = [];
          $(option).closest('select').next().find('ul.multiselect-container li.active input').each((idx, item) => { checkedList.push($(item).val()); });

          if (checkedList.length !== $(`#form_multi_select_${ori_form.etc2} option`).filter(':selected').length) {
            $(`#form_multi_select_${ori_form.etc2} option`).each((idx, item) => {
              if (checkedList.includes(item.value)) {
                $(item).prop('selected', true);
              }
            });
          }

          const selectedField = $(`#form_multi_select_${ori_form.etc2}`).val();
          const update_data = JSON.parse(ori_form.template_data);

          Object.keys(update_data).forEach(type => {
            update_data[type].forEach(field => {
              field.selected = selectedField.includes(field.value);
            });
          });

          try {
            await shipmentModel.editOrdPrintForm({ template_no: ori_form.template_no, template_data: JSON.stringify(update_data) });

            commonSVC.showToaster('success', '', '기본 주문서 인쇄 양식 설정 성공');
          } catch (error) {
            commonSVC.showToaster('error', '', '기본 주문서 인쇄 양식 설정 실패');

            return false;
          }
        }
      });

      $(`#form_multi_select_${form.etc2}`).multiselect('dataprovider', optionGroup);

      // 멀티셀렉 ul open으로 인해 스크롤바 발생시 멀티셀렉 요소에 맞춰 스크롤 이동할 수 있도록 옵저버 설정
      const target = $('#orderFormList .multiSelectUl .btn-group');

      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const classList = mutation.target.classList;

            if (classList.contains('open')) {
              const scrollBar = document.getElementById('orderFormList').querySelector('.ui-grid-viewport');
              const location = mutation.target.getBoundingClientRect().y - scrollBar.getBoundingClientRect().y;

              if (scrollBar) {
                scrollBar.scrollTo({ top: location, behavior: 'smooth' });

                scrollBar.addEventListener('scroll', function(event) {
                  event.preventDefault();
                  event.stopPropagation();
                }, { passive: false });
              }
            }
          }
        });
      });

      $(target).each(function() {
        observer.observe(this, { attributes: true });
      });
    };

    $scope.searchFn = {
      searchDo: () => {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: () => {
        $scope.grid.methods.length($scope.searchData.showCount);
      }
    };

    /**
		 * 테이블 검색
		 */
    $scope.searchDo = (refresh = true, noDelay) => {
      $scope.grid.methods.reloadData(null, refresh, noDelay);
    };

    /**
		 * 테이블 검색 초기화
		 */
    $scope.resetDo = () => {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDo(true, true);
    };

    /**
     * 즐겨찾기 여부 변경
     */
    $scope.changeBookmark = async (template_no, template_name, bookmark_yn) => {
      try {
        if (template_no) {
          await shipmentModel.editOrdPrintForm({ template_no: template_no, bookmark_yn: !bookmark_yn });
        } else {
          await shipmentModel.addOrdPrintForm({ template_name: template_name, template_data: '' });
        }

        commonSVC.showToaster('success', '', '주문서 인쇄 양식 즐겨찾기 수정 성공');
        $scope.searchDo();
      } catch (error) {
        commonSVC.showToaster('error', '', '주문서 인쇄 양식 즐겨찾기 수정 실패');

        return false;
      }
    };

    /**
     * A4용지 1장당 주문 1건 출력 체크 박스 노출
     */
    $scope.onePageFlag = () => {
      return $scope.ordCount !== 1 && $scope.grid.methods.selectedData('etc2')[0] !== 'packing_stock_sku';
    };

    /**
     * 셀렉박스 드롭다운 클릭시 row 선택 해제되지 않도록 처리
     */
    $scope.toggleRowSelection = function(row) {
      setTimeout(() => {
        $scope.grid.methods.setSelectedRow(row.entity, true);
      });
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });