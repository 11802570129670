'use strict';

/**
 * Created by ally on 2018-04-07..
 */

angular.module('gmpApp')
  .controller('orderMappingProdCtrl', function ($scope, $uibModalInstance, data) {
    $scope.data = data;
    $scope.submitData = { isOnly: true, ruleModify: false };

    //매칭
    $scope.submit = function() {
      $uibModalInstance.close($scope.submitData);
    };

    //닫기
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
