'use strict';

/**
 * 2018-05-04 chris
 * 티몬 특가
 */
angular.module('gmpApp')
  .factory('B851categorySVC', function () {

    return {
      //카테고리 repeat data
      category_list: {
        0: 'key_lcate',
        1: 'key_mcate',
        2: 'key_scate',
        3: 'key_dcate'
      },

      // 옵션 repeat data
      category_opt: [
        {},
        {},
        {},
        {}
      ],

      //카테고리 data
      category_data: {
        // 표준분류
        key_lcate: '',
        key_mcate: '',
        key_scate: '',
        key_dcate: '',

        // 카테고리 이름,코드
        category_names: '',
        category_code: '',
        set_all_shop: false, //상품적용여부
        detailData: {}
      }
    };
  });
