'use strict';

angular.module('gmpApp')
  .factory('PolicySVC', function (commonSVC, $rootScope) {
    const policy = {
      // LG대리점 약관
      LG: `
        <div class="infoArea text-left p-20 mb-20" style="max-height: 700px; overflow: auto;">
          <p class="strongT">LG전자 이용약관</p>
          <div class="policyBox">
            <h3>서비스 지원 동의서(LG전자)</h3>
            <div style="margin-top: 20px;">
              <span style="font-weight: bold;">LG전자 주식회사</span>(이하 ‘LG전자’)는 <span style="font-weight: bold;">주식회사 플레이오토</span>의 
              <span style="font-weight: bold;">쇼핑몰 통합솔루션 서비스</span>(이하‘본 서비스’)를 귀사에 무상으로 지원하고자 합니다. 
              이에 귀사는 하기 내용을 확인하시고 충분히 고민하신 후 자유로운 의사에 따라 LG전자의 본 서비스 지원에 동의하여 주시기 바립니다.
              본 동의서에 동의하시는 경우에만 본 서비스를 이용하실 수 있으며 동의를 하지 않았다는 이유로 어떠한 불이익도 귀사에 제공되지 않습니다.
            </div>
            <div style="text-align: center; margin-top: 30px;">- 하 기 –</div>
            <h4>1. 쇼핑몰 통합솔루션 서비스</h4>
            <div>
              본 서비스는 귀사가 쇼핑몰에서의 판매활동을 통합적으로 관리·처리할 수 있도록 상품등록, 주문관리, 클레임처리 등을 할 수 있는 서비스로
              자세한 소개 및 정보는 홈페이지(www.plto.com)를 참고하시기 바랍니다.
            </div>
            <h4>2. 지원의 취지 및 효과</h4>
            <div>
              LG전자는 본 서비스를 귀사에 지원함으로써, 주문 및 송장 정보 전달을 자동화하여 단순 반복 업무를 축소하고 주문 누락을 방지하는 등 업무를 효율화하고자 합니다.
              또한, 마켓을 통합한 배송 정보를 관리하고 마켓 통합적으로 고객의 문의나 불만을 관리함으로써 고객에 대한 응대력을 높이고 고객 만족도를 증진시키고자 합니다.
            </div>
            <h4>3. 서비스 이용 대가</h4>
            <div>
              PLAYAUTO 통합회원 이용약관 제17조에 불구하고 본 서비스에 대하여 귀사에서 부담해야할 대가는 없습니다.
            </div>
            <h4>4. 기타</h4>
            <div>
              “PLAYAUTO 통합회원 이용약관”과 본 동의서 내용이 충돌하는 경우 본 동의서 내용이 우선하며, 나머지 서비스 이용에 대한 권리ㆍ의무 및 책임사항,
              기타 사항은 “PLAYAUTO 통합회원 이용약관”에 따릅니다.
            </div>
            <h4>※ 동의서 추가 내용</h4>
            <h4>5. 판매 및 고객 Q&A 데이터 수집·이용</h4>
            <div>
              LG전자는 귀사에 대한 수익 개선 등 효과적인 경영지원, 귀사와 고객에게 더 나은 서비스 제공, LG전자의 쿠폰비* 검증을 위하여
              판매데이터(주문사이트, 주문상태, 주문/결제완료일, 쇼핑몰주문번호, 상품명, 모델번호, 판매수량, 판매금액, 물류/택배사, 운송장번호, 배송소요일, 희망배송 지연일)와
              고객 Q&A데이터(문의 유형, 문의일자, 사이트, 제목, 내용, 답변)를 수집·이용합니다.
            </div>
            <div style="margin-top: 10px;">
              *쿠폰비 : LG전자가 제품 판매 활성화를 위해 판매량이 기반하여 오픈마켓에 지급하는 할인 비용
            </div>
          </div>
        </div>`,

      // 위수탁계약 동의
      contract: `
        <div class="infoArea text-left p-20 mb-20" style="max-height: 700px; overflow: auto;">
          <p class="strongT" style="text-align: center;">개인정보처리 위수탁 계약</p>
          <div class="policyBox">
            <div class="pt-20">
              “이용자” (이하 “위탁자”라 한다)과 주식회사 플레이오토(이하 “수탁자”라 한다)는 “위탁자”가 쇼핑몰 주문 처리 시 
              수집한 “주문의 고객정보”(이하 “주문정보”라 한다)를 저장 및 관리 업무를 “수탁자”에게 위탁함에 있어 다음과 같은 내용으로 본 업무위탁계약을 체결한다.
            </div>
            <h4>제 1 조 [목적]</h4>
            <div>
              이 계약은 “수탁자”가 제공하는 쇼핑몰 통합 관리 솔루션을 이용해 “위탁자”가 수집한 주문정보의 저장 및 관리 위탁 업무를 수행함에 있어 
              「개인정보 보호법」에 따라 양 당사자간의 고객 개인정보 보호와 관련된 사항과 책임을 정함을 그 목적으로 한다.
            </div>
            <h4>제 2 조 [용어의 정의]</h4>
            <div>
              본 계약에서 별도로 정의되지 아니한 용어는 「개인정보 보호법」, 같은 법 시행령 및 시행규칙, 「개인정보의 안전성 확보조치 기준」(개인정보 보호위원회 고시 제2020-2호) 
              및 「표준 개인정보 보호지침」(개인정보 보호위원회 고시 제2020-1호)에서 정의된 바에 따른다.
            </div>
            <div class="pl20 pt10">
              <span>①</span>쇼핑몰 통합관리솔루션 : “수탁자”이 “위탁자”의 쇼핑몰 주문관리를 위해 제공하는 서비스.<br />
              <span>②</span>주문정보 : “위탁자”가 주문 처리를 위해 쇼핑몰에서 제공받은 고객정보 (주문자명, 주문자연락처, 수취인명, 수취인 주소 등)<br />
            </div>
            <h4>제 3 조 [위탁업무의 목적 및 범위]</h4>
            <div>
              “수탁자”는 계약이 정하는 바에 따라 위탁계약 업무수행 목적으로 다음과 같은 개인정보 처리 업무를 수행한다.
            </div>
            <div class="pl20 pt10">
              <span>①</span>“수탁자”의 일반 수탁업무 목적은 “위탁자”가 “쇼핑몰 통합관리솔루션” 주문관리 기능을 이용함에 있어 원활한 서비스를 제공하기 위함에 그 목적이 있다.<br />
              <span>②</span>“수탁자”는 “위탁자”가 주문관리를 위해 수집한 주문정보를 저장 및 관리하며, “위탁자”가 안전하게 주문정보를 관리할 수 있도록 관리적, 기술적 조치를 제공한다.<br />
            </div>
            <h4>제 4 조 [위탁업무 기간]</h4>
            <div>
              이 계약서에 의한 개인정보 처리업무의 기간은 “위탁자”가 “수탁자”의 통합관리솔루션 이용기간과 동일하게 적용되며, 서비스 탈퇴일 또는 이용종료일까지 효력이 유지됩니다.
            </div>
            <h4>제 5 조 [재 위탁 제한]</h4>
            <div class="pl20">
              <span>①</span>“수탁자”는 “위탁자”의 사전 승낙을 얻은 경우를 제외하고 “위탁자” 와의 계약상의 권리와 의무의 전부 또는 일부를 제3자에게 양도하거나 재 위탁할 수 없다.<br />
              <span>②</span>“수탁자”는 다른 제3의 회사와 수탁계약을 할 경우에는 “수탁자”는 해당 사실을 계약 체결 7일 이전에 “위탁자”에게 통보하고 협의하여야 한다.<br />
            </div>
            <h4>제 6 조 [개인정보의 안전성 확보조치]</h4>
            <div>
              “수탁자”는 「개인정보 보호법」 제23조제2항 및 제24조 제3항 및 제29조, 같은 법 시행령 제21조 및 제30조, 「개인정보의 안전성 확보조치 기준」
              (개인정보 보호위원회 고시 제2020-2호)에 따라 개인정보의 안전성 확보에 필요한 기술적, 관리적 조치를 취하여야 한다.
            </div>
            <h4>제 7 조 [개인정보의 처리제한]</h4>
            <div class="pl20">
              <span>①</span>“수탁자”는 계약기간은 물론 계약 종료 후에도 위탁업무 수행 목적 범위를 넘어 개인정보를 이용하거나 이를 제3자에게 제공 또는 누설하여 서는 안 된다.<br />
              <span>②</span>“위탁자”는 목적에 따른 사용이 완료되거나 기타 보유가 불필요해진 주문정보는 “을”이 제공하는 쇼핑몰 통합관리 솔루션의 개인정보 처리 기능을 이용하여 직접 파기 및 관리하여야 한다.<br />
              <span>③</span>“수탁자”는 계약이 해지되거나 또는 계약기간이 만료된 경우 위탁업무와 관련하여 보유하고 있는 개인정보를 「개인정보 보호법 시행령」 제16조 및 「개인정보의 안전성 확보조치 기준」(개인정보 보호위원회 고시 제2020-2호)에 따라 즉시 파기하거나 “위탁자”에게 반납하여야 한다.<br />
              <span>④</span>제3항에 따라 “수탁자”가 개인정보를 파기한 경우 지체없이 “위탁자”에게 그 결과를 통보하여야 한다.<br />
            </div>
            <h4>제 8 조 [수탁자에 대한 관리·감독 등]</h4>
            <div class="pl20">
              <span>①</span>“위탁자”는 “수탁자”에 대하여 다음 각 호의 사항을 감독할 수 있으며, “수탁자”는 특별한 사유가 없는 한 이에 응하여야 한다.<br />
              1. 개인정보의 처리 현황 <br />
              2. 개인정보의 접근 또는 접속기록 <br />
              3. 개인정보 접근 또는 접속 대상자 <br />
              4. 목적 외 이용, 제공 및 재 위탁 금지 준수여부 <br />
              5. 암호화 등 안전성 확보조치 이행여부 <br />
              6. 그 밖에 개인정보의 보호를 위하여 필요한 사항<br />
              <span>②</span>“수탁자”는 수탁업무의 처리와 관련하여 주문정보에 접근할 수 있는 직원을 필요 최소한의 범위로 제한, 관리하여 고객정보 침해 사고가 발생하지 않도록 최선의 노력을 다하여야 한다.<br />
              <span>③</span>“수탁자”는 위탁자의 개인정보 처리 방침을 준수하여야 하며, 원활한 고객정보 처리와 보호를 위하여 개인정보 보호담당자를 지정하여 “위탁자”에게 통지하여야 한다.<br />
              <span>④</span>“수탁자”는 개인정보를 보호를 위하여 “수탁자”의 직원에 대한 관리, 감독과 교육을 실시하여야 하며, “위탁자”의 요청이 있는 경우 교육 결과를 “위탁자”에게 제출한다.<br />
            </div>
            <h4>제 9 조 [정보주체 권리보장]</h4>
            <div>
              “수탁자”은 정보주체의 개인정보 열람, 정정·삭제, 처리 정지 요청 등에 대응하기 위한 연락처 등 민원 창구를 마련해야 한다.
            </div>
            <h4>제 10 조 [개인정보의 파기]</h4>
            <div>
              “수탁자”은 제4항의 위탁업무기간이 종료되면 특별한 사유가 없는 한 지체 없이 개인정보를 파기하고 이를 “위탁자”에게 통지해야 한다.
            </div>
            <h4>제 11 조 [손해배상]</h4>
            <div class="pl20">
              <span>①</span>“수탁자” 또는 “수탁자”의 임직원 기타 “수탁자”의 수탁자가 이 계약에 의하여 위탁 또는 재위탁 받은 업무를 수행함에 있어 이 계약에 따른 의무를 위반하거나 “수탁자” 또는 “수탁자”의 임직원 기타 “수탁자”의 수탁자의 귀책사유로 인하여 이 계약이 해지되어 “위탁자” 또는 개인정보주체 기타 제3자에게 손해가 발생한 경우 “수탁자”는 그 손해를 배상하여야 한다.<br />
              <span>②</span>제1항과 관련하여 개인정보주체 기타 제3자에게 발생한 손해에 대하여 “위탁자”가 전부 또는 일부를 배상한 때에는 “위탁자”은 이를 “수탁자”에게 구상할 수 있다. 단, “위탁자” 가 제7조 2항의 개인정보 안전성 확보조치를 태만히 함으로 인하여 발생한 “위탁자” 또는 개인정보주체 기타 제3자의 손해에 대하여 “수탁자”는 그 손해를 배상할 책임이 없다.<br />
            </div>
          </div>
        </div>`
    };

    return {
      agreeTerms: async (type, next) => {
        const opt = {
          width: 900,
          title: '',
          text: policy[type],
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn-primary',
            cancelButton: 'btn-grey mr-20'
          }
        };

        const { isConfirmed } = await commonSVC.showConfirmCustomSwal2(opt);

        if (isConfirmed) {
          next();

          // 동의를 받은 후 새로고침
          location.reload();
        } else {
          swal({
            title: '약관에 미동의시 이용이 불가능합니다.',
            text: '',
            confirmButtonColor: '#5C90D2',
            confirmButtonText: '확인',
            allowEscapeKey: false
          }, $rootScope.logout);

          return false;
        }
      }
    };
  });
