'use strict';

angular.module('gmpApp')
  .controller('CategoryClassAddCtrl', function ($scope, $uibModalInstance, commonSVC, categoryModel, $rootScope) {
    $scope.cateData = {
      categoryClassName: ''
    };

    /**
     * 등록시
     */
    $scope.onSubmit = function () {
      /**
         * 2018-03-05 Daniel
         * 분류명, 카테고리명 . 만있는경우를 막음
         */
      if ($scope.cateData.categoryClassName.replace(/\./gi, '') === '') {
        commonSVC.showMessage('유효하지 않은 분류명 입니다');

        return false;
      }

      const param = [{
        parents_id: 0,
        name: $scope.cateData.categoryClassName,
        depth: null
      }];

      categoryModel.categoryAdd(param)
        .then(function () {
          $uibModalInstance.close('success');
          commonSVC.showToaster('success', '', '분류 추가에 성공했습니다.');
        })
        // 실패처리
        .catch(function (data) {
          const error = data.data.error;

          if (error === 'ER_DUP_ENTRY1') {
            commonSVC.showToaster('error', '', '중복된 분류명 입니다.');
          } else {
            commonSVC.showToaster('error', '', '분류 추가에 실패 했습니다.');
          }

          $uibModalInstance.close('failed');
        });
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 모든 모달 닫기 명령이 들어온경우 처리.
     */
    $scope.$on('openedModalClose', function () {
      $uibModalInstance.dismiss('cancel');
    });
  });
