'use strict';

angular.module('gmpApp')
  .factory('A537categorySVC', function () {

    return {
      category_data: {
        category_names: '',
        category_code: '',
        detailData: {
          display_categories: [],
        }
      },
      dispCtg: {
        dispCtgType: '',
        dispCtgLoad: false,
        searchValue: '',
        dispCtgSelect: '',
        dispCtgSelectList: []
      }
    };
  });
