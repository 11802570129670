'use strict';

angular.module('gmpApp').controller('OrderShipmentSendInvoiceCtrl', function ($scope, $uibModalInstance, commonSVC, workSVC, errorSVC, data, $q) {

  // 전체 체크박스 값
  $scope.selectedAll = true;
  $scope.sendOrderCnt = 0;      // 전송 주문 카운트 추가 2018-11-23 rony

  // 쇼핑몰리스트
  $scope.shopList = _.map(data.shopList, function (shop) {
    shop.selected = true;
    $scope.sendOrderCnt = $scope.sendOrderCnt + shop.uniqs.split(',').length;

    return shop;
  });

  $(document).ready(function () {
    var select = $('select#order_option');

    select.change(function () {
      var select_name = $(this).children('option:selected').text();

      $(this).siblings('label').text(select_name);
    });

    var fileTarget = $('.filebox .upload-hidden');

    fileTarget.on('change', function () {
      let filename;
      if (window.FileReader) {
        filename = $(this)[0].files[0].name;
      } else {
        filename = $(this).val().split('/').pop().split('\\').pop();
      }

      $(this).siblings('.upload-name').val(filename);
    });
  });
  // 체크박스 처리
  $scope.checkboxClick = function (type) {
    // 헤드 체크박스 클릭시
    if (type === 'all') {
      $scope.sendOrderCnt = 0;
      _.forEach($scope.shopList, function (shop) {
        shop.selected = $scope.selectedAll;
        if (shop.selected) {
          $scope.sendOrderCnt = $scope.sendOrderCnt + shop.uniqs.split(',').length;
        }
      });
    }
    // 바디 체크박스 클릭시
    else {
      if ($scope.selectedAll && !($scope.shopList[type].selected)) {
        $scope.selectedAll = false;
        $scope.sendOrderCnt = $scope.sendOrderCnt - $scope.shopList[type].uniqs.split(',').length;
      } else {
        $scope.selectedAll = !!_.find($scope.shopList, { selected: true });
        $scope.sendOrderCnt = $scope.sendOrderCnt + $scope.shopList[type].uniqs.split(',').length;
      }
    }
  };

  // 수집
  $scope.ok = function () {
    const selectedShops = _.filter($scope.shopList, { selected: true });

    if (!selectedShops.length) {
      commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

      return false;
    }

    const reCnt = {
      success: 0,
      error: 0,
      errSite: [],
      errReason: []
    };

    const promiseArr = [];

    _.forEach(selectedShops, function (shop) {
      const params = {
        site_code: shop.shop_cd,
        site_id: shop.shop_id,
        uniqs: shop.uniqs.split(','),
        list: shop.uniqs.split(',')
      };

      const anHttpPromise = workSVC.addWork('SendDelivNo', params)
        .then(function(result) {
          if (result.status == 200) {
            reCnt.success++;
          } else {
            reCnt.error++;
            reCnt.errSite.push(`${shop.shop_name}(${shop.shop_id})`);
            reCnt.errReason.push(result.data.error || '');
          }

          return result;
        })
        .catch(function(err) {
          reCnt.error++;
          reCnt.errSite.push(`${shop.shop_name}(${shop.shop_id})`);
          reCnt.errReason.push('');

          return err;
        })
        .finally(function () {
          $uibModalInstance.close();
        });

      promiseArr.push(anHttpPromise);
    });

    $q.all(promiseArr)
      .then(async re => {
        if (!reCnt.success) {
          throw re;
        } else {
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
        }
      })
      .catch(function(err) {
        const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
        commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
      });
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

});
