'use strict';

angular.module('gmpApp')
  .controller('multiSkuOrderProdList', function ($rootScope, $scope, settings, commonSVC, logHistorySVC, $uibModalInstance, data, $compile, $timeout, onlineProductSVC, productSVC, productModel, supplierModel, warehouseModel, systemModel, userInfo) {

    $scope.prodList = [];
    $scope.total_pack_unit = 0;
    $scope.title = '';

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));
    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(rowIndex, show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        productModel.detail({ sku_code: sku_cd }, function(re, data) {
          $scope.prodList[rowIndex].prod_name = data.results.prod_name;
          $scope.prodList[rowIndex].sale_price = data.results.product_price;
          $scope.prodList[rowIndex].real_stock = data.results.real_stock;
          $scope.prodList[rowIndex].safe_stock = data.results.safe_stock;
          $scope.prodList[rowIndex].depot_no = Number(data.results.delivery_vendor);
          $scope.prodList[rowIndex].depot_name = data.results.depot_name;
        });
      });
    };

    // 모달 닫기
    // 세트상품 생성 모달을 닫은 후 처리가 필요하여 close 로 변경 2018-05-17 rony
    $scope.close = function () {
      // $uibModalInstance.dismiss('cancel');
      $uibModalInstance.close();
    };

    // 테이블 리사이즈
    const setResizable = () => {
      setTimeout(() => {
        $('#viewSetInfo').colResizable({
          liveDrag: true,
        });
      }, 100);
    };
    setResizable();

    const init = () => {
      $scope.prodList = angular.copy(data.prodList || []);
      $scope.isSet = !!data.set_cd;

      $scope.total_pack_unit = $scope.prodList.reduce((acc, cur) => acc + +cur.pack_unit, 0) * (data.isMappingRule ? 1 : $scope.prodList[0].opt_sale_cnt);
      $scope.title = $scope.isSet ? `${data.set_cd} ${data.set_name}` : `${data.prodList[0].sku_cd} 외 ${$scope.prodList.length - 1}건`;
      $scope.isMappingRule = !!data.isMappingRule;

      $scope.prodList.forEach(prod => {
        if (!data.isMappingRule) {
          if (prod.attri) {
            prod.attri = prod.attri.includes('=') ? prod.attri.split(',').map(p => p.split('=')[1]).join() : prod.attri.split('_').join();
          }
          prod.prod_name += prod.attri ? `_${ prod.attri}` : '';
        }
      });

      // 세트는 세트내 SKU 순서대로 출력해야함
      if (!$scope.isSet) {
        $scope.prodList.sort((p1, p2) => {
          if (p1.prod_name < p2.prod_name) {
            return -1;
          }
          if (p1.prod_name > p2.prod_name) {
            return 1;
          }

          return 0;
        });
      }
    };

    init();
  });
