'use strict';

angular.module('gmpApp')
  .service('userModel', function (settings, commonSVC, $rootScope) {

    let _oldData = {};

    return {

      setOld: function (old) {
        _oldData = old;
      },

      getOld: function () {
        return _oldData;
      },

      /**
       * 사용자 정보 로드
       * @param user_id
       * @param next
       */
      load: function (m_no, next) {
        const url = `${settings.pa20ApiUrl}/app/users/${m_no}`;

        if (next) {
          commonSVC.sendUrl('GET', url, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, {});
        }

      },

      /**
       * 사용자 정보 수정
       * @param log_content
       * @param next
       */
      update: function (data, log_content, next) {
        const param = data;

        param.log_content = log_content;
        const url = `${settings.pa20ApiUrl}/app/users/`;

        if (next) {
          commonSVC.sendUrl('PATCH', url, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('PATCH', url, data);
        }

      },

      /**
       * 사용자 비밀번호 수정
       * @param log_content
       * @param next
       */
      setPassword: function (data, next) {
        const url = `${settings.pa20ApiUrl}/app/users/password`;

        commonSVC.sendUrl('PATCH', url, data, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 사용자 정보 등록
       * @param self
       * @param next
       */
      insert: function (self, next) {
        const url = `${settings.pa20ApiUrl}/app/users/add`;

        commonSVC.sendUrl('POST', url, self, function (state, data) {
          next(state, data);
        });
      },

      /**
       * 사용자 상태 변경 (삭제 | 활성화 | 비활성화)
       * @param user_id
       * @param next
       */
      changeStatus: function (params) {
        const url = `${settings.pa20ApiUrl}/app/users/change-status`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 2018-03-21 Daniel
       * sol_ser 조회
       */
      getOrderSmsAutoSetting: function (param) {
        const url = `${settings.pa20ApiUrl}/app/order/sms/auto-send-configs`;

        return commonSVC.sendUrl('GET', url, param);
      },

      /**
       * 개별설정 요금 조회
       * 2019-01-17 Amelia
       */
      getIndvPayAmtAndCntLimit: function (data, next) {
        if (next) {
          commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/indiv-charge-count`, {}, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/pay/indiv-charge-count`, {});
        }
      },

      /**
       * 사업자번호 조회
       * 2021-06-10 Tim
       */
      checkBizNo: function (data) {
        const param = {
          bizNo: data
        };
        const url = `${settings.pa20ApiUrl}/app/company/checkBizNo`;

        return commonSVC.sendUrl('GET', url, param);
      },

      subUserStatusSet: (param) => {
        const url = `${settings.pa20ApiUrl}/app/users/sub-user-status`;

        return commonSVC.sendUrl('PATCH', url, param);
      },

      subUserDetail: (param) => {
        const url = `${settings.pa20ApiUrl}/app/users/sub-user-detail/${param.sol_no}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      release: (param) => {
        const url = `${settings.pa20ApiUrl}/app/users/release`;

        return commonSVC.sendUrl('PATCH', url, param);
      },

      /**
       * 웨이크업용 2.0 토큰 발급
       */
      getWakeupToken: params => {
        const url = `${settings.pa20ApiUrl}/app/common/wakeup-token-authorized`;

        return commonSVC.sendUrl('POST', url, params);
      }
    };

  });
