'use strict';

angular.module('gmpApp')
  .controller('orderReportCtrl', function ($scope, $rootScope, $state, data, $uibModalInstance, commonSVC, commonModel, userInfo, systemModel, localStorageService, reportUser, shopAccountModel) {
    $scope.isExistingUser = false;

    $scope.isSummary = data.isSummary;
    $scope.checked = {
      htel: true,
      email: true,
      subAuth: true,
      required: true
    };

    const oriData = {
      hour: '',
      minute: '',
      htel: '',
      email: '',
    };

    $scope.confirm = async () => {
      if (!$scope.channelListCount) {
        commonSVC.showMessageHtml(
          '', `<!-- 쇼핑몰 없는경우 처리 -->
              <div class="text-center text-bold" style="font-size: 16px;">
                <img src="../../../assets/images/modal/icon_error.svg"/>
                <div class="pt-30">
                  앗!<br/>등록된 쇼핑몰이 없어요!
                </div>
                <div class="pt-10">
                  쇼핑몰 등록 후 다시 신청 부탁드립니다.
                </div>
              </div>`, () => {
            $state.go('main.settings_shop_account');
            $uibModalInstance.close('success');
          }, { allowEscapeKey: false, customClass: 'order-report-confirm', confirmButtonText: '쇼핑몰 등록하기' });

        return false;
      }

      // 기존 사용자의 경우 추가 확인 모달이 떠야해서 구분값 추가
      let checkConfirm = !((oriData.hour && oriData.minute) && ($scope.report.time.hour !== oriData.hour || $scope.report.time.minute !== oriData.minute));

      // 필수 동의 확인 + 인증 확인
      $scope.checked.required = $scope.agreeCheck;
      // $scope.report.htel.is_use = $scope.report.htel.is_use && $scope.checked.htel;
      // $scope.report.email.is_use = $scope.report.email.is_use && $scope.checked.email;
      $scope.checked.subAuth = ($scope.report.htel.is_use || $scope.report.email.is_use)
        && (!$scope.report.htel.is_use || $scope.checked.htel)
        && (!$scope.report.email.is_use || $scope.checked.email);

      if (!$scope.agreeCheck || !$scope.checked.subAuth) {
        return false;
      }

      // 기존 주문 자동화 사용자의 경우 변경안내 모달 출력
      if (!checkConfirm) {
        const modal = await commonSVC.openModal('sm', { data: { }, addClass: 'order-report-confirm' }, 'reportExistingUserCtrl', 'views/home/modals/reportExistingUser.html', false, false, false);

        await modal.result.then(async (res) => {
          if (res === 'success') {
            checkConfirm = true;
          }
        });
      }

      if (checkConfirm) {
        try {
          await systemModel.addReportUser({ report: $scope.report });

          localStorageService.set('orderReportNotShow', true);

          commonSVC.showMessageHtml(
            '', `<!-- 리포트 신청 완료 -->
            <div class="text-center text-bold" style="font-size: 16px;">
              <img src="../../../assets/images/modal/icon_complete.svg" style="padding-top: 40px;"/>
              <div class="pt-30">
              ${$scope.isExistingUser ? '리포트 신청정보 변경이 완료되었어요!' : '리포트 신청이 완료되었어요!'}
              </div>
            </div>`, () => {
              $uibModalInstance.close('success');
            }, { allowEscapeKey: false, customClass: 'order-report-confirm' });
        } catch (err) {
          commonSVC.showMessageHtml(
            '', `<!-- 리포트 신청 실패 -->
              <div class="text-center text-bold" style="font-size: 16px;">
                <img src="../../../assets/images/modal/icon_error.svg"/>
                <div class="pt-30">
                  일시적인 문제로 인해 리포트 신청에 실패했어요
                </div>
                <div class="pt-10">
                  잠시 후 재시도 부탁드려요.
                </div>
              </div>`, '', { allowEscapeKey: false, customClass: 'order-report-confirm' });

          return false;
        }
      }
    };
    /**
     * 창 닫기
     */
    $scope.close = async () => {

      if (!$scope.isSummary) {
        localStorageService.set('orderReportNotShow', $('#notShowCheck')[0].checked);
      }

      $uibModalInstance.close('cancel');
    };

    $scope.sendSubAuth = async(type) => {
      if (type === 'email') {
        const regx = new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]*$/i);

        //이메일 형식이 아닐경우 검사 X
        if (!regx.exec($scope.report.email.send_to)) {
          commonSVC.showMessageHtml(
            '', ` <!-- 이메일 형식 체크 -->
            <div class="text-center text-bold" style="font-size: 16px;">
              <div class="pt-30">
              잘못된 형식의 이메일입니다.
              </div>
            </div>`, '', { allowEscapeKey: false, customClass: 'order-report-confirm' });
          // commonSVC.showMessage('잘못된 형식의 이메일입니다.', false, false, 'order-report-confirm');

          return false;
        }
      }

      // 2차 인증 전송 정보
      const sendData = {
        authType: 'check',
        htel: $scope.report.htel.send_to,
        email: $scope.report.email.send_to,
        ...(type === 'htel' ? { sendType: 'sms', to: $scope.report.htel.send_to } : { sendType: 'email', to: $scope.report.email.send_to }),
      };

      await commonModel.sendSubAuth(sendData);

      const modal = await commonSVC.openModal('sm', { data: { sendData }, addClass: 'order-report-confirm' }, 'EtcSubAuthCtrl', 'views/home/modals/subAuthOrderReport.html', false, false, false);
      modal.result.then(() => {
        oriData[type] = $scope.report[type].send_to;
        $scope.checked[type] = true;
      });
    };

    $scope.rejectReport = async () => {
      const modal = await commonSVC.openModal('sm', { data: { }, addClass: 'order-report-confirm' }, 'rejectReportCtrl', 'views/home/modals/rejectReport.html', false, false, false);

      await modal.result.then(() => {
        commonSVC.showMessageHtml(
          '', `<!-- 수신거부처리 완료 -->
          <div class="text-center text-bold" style="font-size: 16px;">
            <img src="../../../assets/images/modal/icon_complete.svg" style="padding-top: 40px;"/>
            <div class="pt-30">
              수신거부처리가 완료되었어요. <br/>
              언제든지 다시 신청해주세요!
            </div>
          </div>`, '', { allowEscapeKey: false, customClass: 'order-report-confirm' });
      });
      $uibModalInstance.close('success');
    };

    $scope.sendToChange = (type) => {

      $scope.checked[type] = oriData[type] === $scope.report[type].send_to;

    };

    const init = async () => {
      $scope.channelListCount = $rootScope.useChannelList({ site_action: 'ScrapOrder' }).length;

      oriData.hour = reportUser.hour;
      oriData.minute = reportUser.minute;
      oriData.htel = reportUser.htel || userInfo.user.htel;
      oriData.email = reportUser.email || userInfo.user.email;

      $scope.isExistingUser = reportUser.htel || reportUser.email;
      $scope.report = {
        htel: {
          is_use: $scope.isExistingUser ? !!reportUser.htel : true,
          send_to: oriData.htel
        },
        email: {
          is_use: !!reportUser.email,
          send_to: oriData.email
        },
        time: {
          hour: reportUser.hour || '06',
          minute: reportUser.minute || '00'
        }
      };
    };
    init();
  });