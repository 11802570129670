/**
 * Created by harry on 2017. 8. 8..
 */
'use strict';

angular.module('gmpApp')
  .controller('claimInvoiceTemplateCtrl', function ($scope, $rootScope, userInfo, data, commonSVC, $uibModalInstance, deliveryModel) {
    $scope.delivery = data.delivery ? angular.copy(data.delivery) : {};
    $scope.delivery.carr_no += ''; // 문자열로 처리
    $scope.parent = data.parent;
    $scope.original_template;

    /**
     * 초기화 함수
     */
    function init() {
      $scope.original_template = $scope.parent.selectedTemp;
      $scope.selectedTemp =
        {
          carr_no: $scope.delivery.carr_no,
          carr_id: $scope.delivery.carr_id,
          carr_id_sub: $scope.delivery.carr_id_sub,
          sol_no: $scope.delivery.sol_no,
          ... data.template
        };
      $scope.isEdit = true;

      $scope.today = moment().format('YYYY-MM-DD');
    }

    init();

    /**
     * 폼에 작성한 내용 백엔드에 전송해서 저장
     */
    $scope.submit = function () {
      if ($scope.deliveryTemplateForm.$valid) {
        deliveryModel.UpdateClaimDeliveryTemplate({ template: $scope.selectedTemp }, state => {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '택배사 양식 수정 성공');
            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', '택배사 양식 수정 실패');
          }
        });
      }
    };

    $scope.cancel = function() {
      $scope.parent.selectedTemp = $scope.original_template;
      $uibModalInstance.dismiss('cancel');
    };

    $scope.showAlert = function() {
      commonSVC.showMessage('필수값을 모두 입력하여 주십시오.');
    };
  });