'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentOrderSyncCtrl', function ($scope, $rootScope, $uibModalInstance, commonSVC, workSVC, errorSVC, $q) {

    // 사용중인 쇼핑몰, 아이디 가져오기
    $scope.mall = angular.copy($rootScope.useChannelList({ site_action: 'SyncOrderState' }));

    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.mall, function (mall) {
          mall.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.mall[type].selected)) {
          $scope.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.mall) {
            if (!$scope.mall[i].selected) {
              flag = false;

              return;
            }
          }
          $scope.selectedAll = flag;
        }
      }
    };

    $scope.ok = function () {

      const idx = _.findIndex($scope.mall, { selected: true });

      if (idx < 0) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

        return false;
      }

      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      const promiseArr = [];

      angular.forEach($scope.mall, function(v) {
        if (v.selected == true) {
        // 작업 추가
          const params = {
            site_code: v.site_code,
            site_id: v.site_id
          };
          const anHttpPromise = workSVC.addWork('SyncOrderState', params)
            .then(function(result) {
              if (result.status == 200) {
                reCnt.success++;
              } else {
                reCnt.error++;
                reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
                reCnt.errReason.push(result.data.error || '');
              }

              return result;
            })
            .catch(function(err) {
              reCnt.error++;
              reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
              reCnt.errReason.push('');

              return err;
            });

          promiseArr.push(anHttpPromise);
        }
      });

      $q.all(promiseArr)
        .then(async re => {
          if (!reCnt.success) {
            throw re;
          } else {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
            $scope.cancel();
          }
        })
        .catch(function(err) {
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);

          return err;
        });

    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
