'use strict';

angular.module('gmpApp')
  .controller('AutoSettingResultCtrl', function ($scope, $rootScope, $state, $uibModalInstance, data, commonSVC, settings) {

    $scope.selectedJobType = 'prod';
    $scope.jobName = '상품';

    const init = async() => {
      const re = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/work/sol-work-result/${data.worklog_sol_id}`, {});

      $scope.result = re.data;
    };

    /**
     * 작업 타입 선택
     */
    $scope.selectJobType = (jobType) => {
      $scope.selectedJobType = jobType;
      $scope.jobName = jobType === 'prod' ? '상품' : jobType === 'order' ? '주문' : '문의';

    };

    init();

    /**
     * 설정 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };

    // 상품 가져오기 탭으로 이동
    $scope.openScrapProd = () => {
      $state.go('main.prodScrap_match_list');

      $uibModalInstance.dismiss('cancel');
    };

    $scope.openQuickOnlineProdAdd = () => {
      $rootScope.quickOnlineProdAdd();

      $uibModalInstance.dismiss('cancel');
    };

    $scope.openQuickCollect = () => {
      $rootScope.quickCollect(true);

      $uibModalInstance.dismiss('cancel');
    };
  });