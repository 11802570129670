'use strict';

angular.module('gmpApp')
  .service('atalkModel', function (settings, commonSVC) {
    return {
      templateList: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, data, next);
      },
      sendAtalk: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/sendAtalk`, data, next);
      },
      templateAdd: (data, isFile) => {
        if (isFile) {
          return commonSVC.sendUrlFile('POST', `${settings.adminApiUrl}/api/marketing/atalk/addTemplate`, data);
        } else {
          return commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/addTemplate`, data);
        }
      },
      deleteAccount: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/deleteAccount`, data);
      },
      accountList: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/accountList`, data);
      },
      list: (data, next) => {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/memos`, data, next);
      },
    };
  });
