'use strict';

angular.module('gmpApp').controller('OrderShipmentGetOrderCtrl', function ($scope, $rootScope, $timeout, $state, data, $uibModalInstance, commonSVC, workSVC, errorSVC, $q, SettingsService, siteInfo, userInfo, systemModel, shopAccountModel, shipmentModel, settings) {

  $scope.work_type = data.work_type;
  $scope.selectedAll = true;

  $scope.someObject = {};
  $scope.someObject.confirm_doit = false;
  $scope.mall = [];
  $scope.isSelectShop = data.work_type === 'select_shop';
  $scope.selectedShopType = 'all';
  $scope.mallCnt = {
    all: 0,
    domestic: 0,
    global: 0
  };
  $scope.isDisabled = $scope.isSelectShop && !data.selected.length;

  SettingsService.getShopInfo()  // 2018-10-30 Jacob 쇼핑몰 계정 추가후 바로 작업시 siteinfo 갱신이 되지않는 문제 수정
    .then(function (re) {        // https://playautogmpproject.atlassian.net/browse/GMPKR-5757
      siteInfo = re.data;
    });

  const saveMallType = $scope.work_type === 'scrap' ? 'scrapOrderMalls' : 'syncOrderMalls';

  // 마지막 선택값 기억.2019-04-08 rony
  $scope.saveMalls = JSON.parse(localStorage.getItem(saveMallType));
  if (!$scope.saveMalls) {
    $scope.saveMalls = {
      use_yn: false,
      malls: []
    };
  }

  /**
   * 쇼핑몰 타입 선택 (국내, 해외)
   */
  $scope.selectShopType = (shopType) => {

    if (shopType === 'all') {
      $scope.mall.forEach(shop => {
        shop.isShow = true;
      });
    } else if (shopType === 'global') {
      $scope.mall.forEach(shop => {
        shop.isShow = shop.pa_shop_cd.startsWith('X');
      });
    } else {
      $scope.mall.forEach(shop => {
        shop.isShow = !shop.pa_shop_cd?.startsWith('X');
      });
    }

    // 활성화된 탭 전체사이트 여부 체크.
    const unSelectedOrder = _.filter($scope.mall, function(o) {
      return o.isShow && o.selected === false;
    });
    $scope.selectedAll = !unSelectedOrder.length;
  };

  $scope.checkboxClick = function(type) {
    if (type === 'all') {
      angular.forEach($scope.mall, function (shop) {
        if (shop.isShow) {
          shop.selected = $scope.selectedAll;
        }
      });
    } else {
      if ($scope.selectedAll && !($scope.mall[type].selected)) {
        $scope.selectedAll = false;
      } else {
        $scope.selectedAll = !$scope.mall.some(mall => !mall.selected);
      }
    }
    $scope.isDisabled = !$scope.mall.some(mall => mall.selected);

    // 선택된 쇼핑몰 갯수 계산
    $scope.calcSelectedShops();
  };

  /**
   * 체크박스 선택된 쇼핑몰 갯수
   */
  $scope.calcSelectedShops = () => {
    $scope.mallCnt.all = $scope.mall.filter(shop => shop.selected).length;
    $scope.mallCnt.domestic = $scope.mall.filter(shop => !shop.pa_shop_cd?.startsWith('X') && shop.selected).length;
    $scope.mallCnt.global = $scope.mall.filter(shop => shop.pa_shop_cd.startsWith('X') && shop.selected).length;
  };

  $scope.setErrorMsg = ({ content, list }) => list.length ? `${list[0]}${list.length <= 1 ? '' : ` 외 ${list.length - 1}건`} : ${content} \n` : '';

  // 수집
  $scope.ok = async function (isShipped = false) {
    const idx = _.findIndex($scope.mall, { selected: true });

    if (idx < 0) {
      commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

      $timeout(() => {
        $scope.waitBtnDisableFlag = false;
      });

      return false;
    }

    const re_cnt = { success: 0, error: 0 };
    const promiseArr = [];

    // 선택값을 담기위해 몰정보 초기화.
    $scope.saveMalls.malls = [];
    let selectedMallList = [];

    if ($scope.selectedShopType === 'all') {
      selectedMallList = $scope.mall;
    } else if ($scope.selectedShopType === 'global') {
      selectedMallList = $scope.mall.filter(shop => shop.pa_shop_cd?.startsWith('X'));
    } else {
      selectedMallList = $scope.mall.filter(shop => !shop.pa_shop_cd?.startsWith('X'));
    }

    angular.forEach(selectedMallList, function(v) {
      if (v.selected == true) {
        // 작업 추가
        const params = {
          site_code: v.shop_cd,
          site_id: v.shop_id,
          notify: false
        };

        re_cnt.errSite = [];
        re_cnt.errReason = [];

        if ($scope.work_type === 'scrap') {
          if (isShipped) {
            setScrapShipped(v, params, re_cnt, promiseArr);
          } else {
            setScrap(v, params, re_cnt, promiseArr);
          }
        } else {
          setSync(v, params, re_cnt, promiseArr);
        }
      }
    });
    // 선택 사이트 정보 로컬스토리지 저장.
    $scope.mall.forEach(mall => {
      $scope.saveMalls.malls.push({ shop_cd: mall.shop_cd, shop_id: mall.shop_id, checked: mall.selected });

    });
    localStorage.setItem(saveMallType, JSON.stringify($scope.saveMalls));

    $q.all(promiseArr)
      .then(async (re) => {
        if (!re_cnt.success) {
          throw re;
        } else {
          if ($scope.work_type === 'sync2') {
            // 마지막 동기화 시간 없데이트
            await commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/order/last-sync-time`, {});
          }

          if (re_cnt.errSite) {
            const errMsg = errorSVC.getErrorMsg(re_cnt.errSite, re_cnt.errReason, 'work');
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${re_cnt.success}, 실패 : ${re_cnt.error} \n${re_cnt.error > 0 ? errMsg : ''}\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          } else {
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${re_cnt.success}, 실패 : ${re_cnt.error}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          }

          $scope.cancel();
        }
      })
      .catch(function(err) {
        const reason = _.map(err, (e) => e.data.error).join();

        if (reason.indexOf('none_charge') > -1) {
          commonSVC.showToaster('error', '실패', '과금정보가 없습니다.관리자에게 문의주세요.');
        } else if (reason.indexOf('none_ebay_token') > -1) {
          commonSVC.showToaster('error', '실패', 'G마켓 물류 토큰 갱신에 실패하였습니다.');
        } else if (reason.indexOf('Duplicate Error') > -1) {
          commonSVC.showToaster('error', '실패', '이미 등록한 작업이 존재하므로 등록이 취소 되었습니다.');
        } else if (reason.includes('none uuid') || reason.includes('channel_account not found')) {
          commonSVC.showToaster('error', '실패', errorSVC.getError('work', 'none uuid'));
        } else if (reason.startsWith('BLOCK_JOB:')) {
          commonSVC.showToaster('error', '실패', err[0].data.error.replace('BLOCK_JOB: ', ''));
        } else {
          commonSVC.showToaster('error', '실패', '작업 등록에 실패하였습니다.');
        }

        $timeout(function () { $scope.waitBtnDisableFlag = false; });
        $scope.cancel();

        return false;
      });
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.check = function () {
    const shop_cd = _.compact(_.map($scope.mall, function (m) {

      if (m.selected) {
        return {
          shop_cd: m.shop_cd,
          shop_id: m.shop_id
        };
      } else {
        return false;
      }
    }));

    if (shop_cd.length === 0) {
      commonSVC.showMessage('실패', '쇼핑몰을 선택해주세요.');

      return false;
    }

    $uibModalInstance.close(shop_cd);
  };

  function setScrap(v, params, cnt, promiseArr) {
    if ($scope.someObject.confirm_doit) {
      const anHttpPromise = workSVC.addWork('ScrapOrderAndConfirmDoit', params)
        .then(function(result) {
          if (result.status == 200) {
            cnt.success++;
          } else if ((typeof result.data.error) !== 'string' || !result.data.error.includes('not_supported_act')) {
            cnt.error++;
            cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
            cnt.errReason.push(result.data.error || '');
          }

          return result;
        })
        .then(function(res) {
          // 발주확인을 지원하지않는경우 바로 주문수집 작업돌도록 처리 2018-07-05 rony
          if ((typeof res.data.error) === 'string' && res.data.error.includes('not_supported_act')) {
            return workSVC.addWork('ScrapOrder', params)
              .then(function(result) {
                if (result.status == 200) {
                  cnt.success++;
                } else {
                  cnt.error++;
                  cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
                  cnt.errReason.push(result.data.error);
                }

                return result;
              })
              .catch(function(err) {
                cnt.error++;
                cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
                cnt.errReason.push('');

                return err;
              });
          } else {
            return res;
          }
        })
        .then(function(res) {
          return res;
        })
        .catch(function(err) {
          cnt.error++;
          cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
          cnt.errReason.push('');

          return err;
        });

      promiseArr.push(anHttpPromise);
    } else {
      // 2018-03-27 chris 설정정보 디비에서 불러오는걸로 수정 shop.confirm_scrap
      if (siteInfo[v.shop_cd].confirm_scrap === 1 || v.shop_cd === 'A077') {
        //2017-11-28 ally 주문수집시 발주전 주문수집도 자동으로 걸리는 쇼핑몰만
        const anHttpPromise = workSVC.addWork('ScrapOrderConfirmList', params)
          .then(function(result) {
            if (result.status == 200) {
              cnt.success++;
            } else if ((typeof result.data.error) !== 'string' || !result.data.error.includes('not_supported_act')) {
              // 카카오톡 스토어 발주확인전 주문수집 지원하지 않아서 지원하지 않는 쇼핑몰일때는 카운트 처리 x
              cnt.error++;
              cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
              cnt.errReason.push(result.data.error || '');
            }

            return result;
          })
          .catch(function(err) {
            cnt.error++;
            cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
            cnt.errReason.push('');

            return err;
          });

        promiseArr.push(anHttpPromise);
      }

      const anHttpPromise2 = workSVC.addWork('ScrapOrder', params)
        .then(function(result) {
          if (result.status == 200) {
            cnt.success++;
          } else {
            cnt.error++;
            cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
            cnt.errReason.push(result.data.error);
          }

          return result;
        })
        .catch(function(err) {
          cnt.error++;
          cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
          cnt.errReason.push('');

          return err;
        });

      promiseArr.push(anHttpPromise2);
    }
  }

  function setScrapShipped(v, params, cnt, promiseArr) {
    const anHttpPromise = workSVC.addWork('ScrapOrderShippedList', params)
      .then(function(result) {
        if (result.status == 200) {
          cnt.success++;
        } else if ((typeof result.data.error) !== 'string' || !result.data.error.includes('not_supported_act')) {
          cnt.error++;
          cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
          cnt.errReason.push(result.data.error || '');
        }

        return result;
      })
      .catch(function(err) {
        cnt.error++;
        cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
        cnt.errReason.push('');

        return err;
      });

    promiseArr.push(anHttpPromise);
  }

  function setSync(v, params, cnt, promiseArr) {
    const anHttpPromise = workSVC.addWork('SyncOrderState', params)
      .then(function(result) {
        if (result.status == 200) {
          cnt.success++;
        } else {
          cnt.error++;
          cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
          cnt.errReason.push(result.data.error || '');
        }

        return result;
      })
      .catch(function(err) {
        cnt.error++;
        cnt.errSite.push(`${v.shop_name}(${params.site_id})`);
        cnt.errReason.push('');

        return err;
      });

    promiseArr.push(anHttpPromise);
  }

  /**
   * 모든 모달 닫기 명령이 들어온경우 처리.
   */
  $scope.$on('openedModalClose', function () {
    $uibModalInstance.dismiss('cancel');
  });

  function init() {
    // 사용중인 쇼핑몰, 아이디 가져오기
    const channelList = angular.copy($rootScope.useChannelList({ site_action: ['scrap', 'select_shop'].includes($scope.work_type) ? 'ScrapOrder' : 'SyncOrderState' }));

    // STEP 1. 조회된 쇼핑몰 정보 담기.
    $scope.mall = commonSVC.getSiteIdList(channelList);

    let mastchMalls;

    if (!$scope.isSelectShop) {
      // STEP 2. 마지막 선택값 기억 사용시 체크되었던 사이트 선택.
      _.each($scope.mall, function (m) {
        mastchMalls = [];
        if ($scope.saveMalls && $scope.saveMalls.use_yn) {
          mastchMalls = _.filter($scope.saveMalls.malls, function(o) {
            return o.shop_cd === m.shop_cd && o.shop_id === m.shop_id;
          });
        }

        if (mastchMalls.length) {
          m.selected = mastchMalls[0].checked;
        } else {
          m.selected = true;
        }
      });
    } else {
      _.forEach($scope.mall, function (m) {

        const isSelected = !!(_.filter(data.selected, function (selected) {
          return selected.shop_cd === m.shop_cd && selected.shop_id === m.shop_id;
        })).length;

        m.selected = isSelected;
      });
    }

    // STEP 3. 전체사이트 여부 체크.
    const unSelectedOrder = _.filter($scope.mall, function(o) {
      return o.selected === false;
    });
    $scope.mall.forEach(mall => mall.isShow = true);

    $scope.selectedAll = !unSelectedOrder.length;

    // 선택된 쇼핑몰 갯수 계산
    $scope.calcSelectedShops();
  }
  init();
});
