/**
 * 리스트 검색 영역
 *
 * 2017-03-09 chris
 */

'use strict';

angular.module('gmpApp')

  .directive('listSearch', function (commonSVC, userInfo, $timeout) {

    return {
      restrict: 'EA',
      scope: {
        searchFn: '=',
        searchData: '=',
        searchForm: '=',
        searchDetail: '=',
        searchDetailMulti: '=', // 2018-08-29 gargamel 쇼핑몰 멀티검색을 위해 추가
        // 2019-03-05 멀티검색 제외시킴 (단일검색에서 겹쳐서). Amelia
        // searchDetailMultiSelect: '=', // 상세검색 필드 중 멀티검색으로 검색어 입력되는 목록 (가능항목: 쇼핑몰, 배송처, 주문상태)
        searchBtn: '=',
        dtInstance: '=',          // 2017-12-29 chris 데이터테이블 이벤트 처리위해 추가
        dtInstance2: '=',         // 2017-12-29 chris 데이터테이블 이벤트 처리위해 추가
        isDetailShow: '=?',       // 2017-03-10 MatthewKim 상세 보기 모드 여부
        detailSearchFlag: '=?'    // 상세검색열기 인자로 받음 2019-02-14 rony
      },
      templateUrl: 'views/tpls/listSearch.html',
      link: function (scope, elem) {

        const el_id = $(elem).attr('id');
        scope.placeholder = $(elem).attr('placeholder');
        // scope.isMultiSearch = false; // 멀티검색 flag
        // scope.multiSearchIdx = 0; // 어떤 멀티검색 키워드 사용하는지 잡아줄 idx

        // view에서 filter될 text들 미리 번역 시켜놓는다
        // gettext("전체선택");gettext("선택해제");

        // 상세 보기 모드 여부 2017-03-10 MatthewKim
        if (!scope.isDetailShow) {
          scope.isDetailShow = false;
        }

        // 온라인 상품리스트에서 템플릿은 쇼핑몰 계정 선택해야만 활성화되게 수정
        // if (el_id == 'online_prodlist_grid_searchbar') {
        //   scope.$watchCollection('searchForm.shop_id', function (nv) {
        //     if (nv) {
        //       $('#searchDetailDiv').find('#search-detail-template_no').prop('disabled', false);
        //     } else {
        //       $('#searchDetailDiv').find('#search-detail-template_no').prop('disabled', true);
        //     }
        //   });
        // }

        scope.$watchCollection('searchDetail', function () {
          $timeout(function () {
            // 2018-12-24 chris 상세검색 select에 검색 기능 추가
            if (_.includes(['online_prodlist_grid_searchbar'], el_id) && $('#searchDetailDiv').find('.select-search').length > 0) {
              // 일반 select와 스타일 맞춤
              $('#searchDetailDiv').find('.select-search').attr('data-container-css-class', 'select2-custom input-xs mr-5 mb-5');

              // select2 검색기능 추가
              $('#searchDetailDiv').find('.select-search').select2({
                width: $('.select-search').css('width') || 169,
                dropdownAutoWidth: true
              });

              // 템플릿은 일단 비활성화
              // $('#searchDetailDiv').find('#search-detail-template_no').prop('disabled', true);

              $('#searchDetailDiv').find('.select2-selection__rendered').each(function (idx, el) {
                $(el).parent().attr('data-placement', 'top');
                $(el).parent().attr('data-popup', 'tooltip');

                $timeout(function () {
                  if (el.title == '템플릿 전체') {
                    $(el).parent().attr('data-original-title', '쇼핑몰, 계정 선택 후 검색가능');
                  } else {
                    $(el).parent().attr('data-original-title', el.title);
                  }
                  $(el).attr('title', '');
                });
              });
              $('[data-popup="tooltip"]').tooltip();
            }
          });
        });

        /**
         * 상세검색 여부
         * @type {boolean}
         */
        scope.detailSearch = function() {
          scope.detailSearchFlag = scope.detailSearchFlag !== true;
          // 2017-12-29 chris 데이터 테이블 레이아웃 재계산
          if (scope.dtInstance && scope.dtInstance.calcLayout) {
            scope.dtInstance.calcLayout();
          }
          if (scope.dtInstance2 && scope.dtInstance2.calcLayout) {
            scope.dtInstance2.calcLayout();
          }
        };

        /**
           * 전체 선택/ 해제
            */
        scope.selectAllChecked = function() {
          scope.searchData.all_checked = !scope.searchData.all_checked;
        };

        /**
           * 검색 구분 선택
           */
        scope.selectSearchKey = function (value, key) {
          scope.searchForm.search_key = value;
          scope.searchData.search_key_name = key;
        };

        /**
           * 멀티검색 구분 변경
           */
        scope.changeMultiType = function() {
          if (scope.searchForm.multi_type === 'shop') {
            scope.searchForm.multi_search_word = [];
          }	else {
            scope.searchForm.multi_search_word = '';
          }
        };

        /**
           * 멀티검색 쇼핑몰 제거
           */
        scope.removeMultiShop = function(index) {
          scope.searchForm.multi_search_word.splice(index, 1);
        };

        // * @param reSearch // 해당 파라미터 사용 안해서 제거
        /**
					 * 조건 검색
					 * @param key
					 * @param value
					 * @param isMultiSearch
           */
        scope.selectSearchForm = function (key, value, item_list) {
          if (key === 'multi_shop_id' && value && value != '' && scope.searchForm.multi_search_word.filter(function(o) {
            return o == value;
          }).length == 0) {

            const selShop = value.split('|');

            const shop_name = item_list.filter(function(o) {
              return o.shop_cd == selShop[0];
            })[0].shop_name;

            scope.searchForm.multi_search_word.push({ code: selShop[0], id: selShop[1], shop_name: shop_name });

            // 2018-09-09 Jacob 상세검색 멀티검색에서 리로드하도록 수정
            // 2018-09-19 Daniel 검색 눌렀을때만 검색되도록 수정
            // scope.searchFn.searchDo();
            return;
          }

          scope.searchForm[key] = value;

          // 2018-10-15 chris 검색버튼 눌렀을때 검색되도록 변경
          // if (reSearch !== false ) {
          //   scope.searchFn.searchDo();
          // }

          // 2017-06-16 chris 상세검색 셀렉트로 변경되면서 주석
          // var idx = _.findIndex(scope.searchDetail, ['search_name', key]);console.log(idx);
          // scope.searchDetail[idx].select_value = value;
          // 2017-04-24 chris 선택한 값 텍스트 노출 처리
          // var select_text = $('.detailSearchDiv:eq('+idx+')').find('#'+scope.searchDetail[idx].search_name+value).text().trim() || scope.searchDetail[idx].sub_title;
          // scope.searchDetail[idx].select_text = select_text;
        };

        /**
           * 날짜 부분 눌렀을 때 input trigger
           * @param event
           * @param calssName
           */
        scope.triggerDate = function (event, calssName) {
          $(event.target.parentNode).find(`.${calssName}`).trigger('click');
        };

        /**
           * 날짜 선택 버튼
           * @param event
           * @param calssName
           */
        scope.date_change = function(data) {
          if (data == 'TODAY') {
            scope.searchForm.sdate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === '3DAYS') {
            scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 3), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === 'WEEK') {
            scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === '1MONTH') {
            scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === '3MONTH') {
            scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === '6MONTH') {
            scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          } else if (data === 'ALL') {
            scope.searchForm.sdate = commonSVC.getDate(new Date('2001-01-01'), 'yyyy-MM-dd');
            scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
          }
          // scope.searchFn.searchDo();
        };

        /**
           * 리스트 출력 함수
           * @param event
           * @param calssName
           */
        scope.showList = function(row, list) {
          // 쇼핑몰 아이디는 별칭과 같이 표기하도록 처리
          return list[row.item_key] + (row.item_key == 'shop_id' && list.seller_nick != '' ? (`(${list.seller_nick})`) : '');
        };
      }
    };
  }

  );
