'use strict';

// 신세계백화점(SSG)
angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A085-Ctrl', function ($scope, $timeout, commonSVC, A085categorySVC, errorSVC, categoryModel, commonModel) {
    $scope.sel_category = [];
    $scope.cate = [];
    $scope.totalCate = []; // 전체 카테고리
    $scope.disp_cate = [];
    $scope.CateList = angular.copy(A085categorySVC.category_list);
    $scope.CateData = angular.copy(A085categorySVC.category_data);
    $scope.cert_type = angular.copy(A085categorySVC.cert_type);
    $scope.dispCtg = angular.copy(A085categorySVC.dispCtg);
    $scope.max_depth = 5;
    $scope.category_names = ''; //노출될 카테고리 분류
    $scope.savedDispCateList = []; // 다중카테고리 저장하는 배열
    $scope.selectDispLimit = -1; // 카테고리 선택 제한 개수(-1은 무한대)

    for (let i = 0; i < $scope.max_depth; i += 1) {
      $scope.sel_category.push('');
      $scope.cate.push([]);
      $scope.totalCate.push([]);
    }

    $scope.pa5_job_data = {
      get_categories: {
        load: false,
        data: []
      },
    };

    let save_flag = false;    //카테고리 선택여부

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        $scope.pa5_job_data[job_name].data = [];
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, {}, $scope.matchingData.site_code, $scope.matchingData.site_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const matchDetailDataLoad = async (shop_cate_info) => {
      // string 형태로 들어오면 에러가 나기 때문에 경우에 따라 파싱
      if (shop_cate_info && typeof shop_cate_info === 'string') {
        $scope.CateData.detailData = JSON.parse(shop_cate_info);
      } else {
        $scope.CateData.detailData = shop_cate_info;
      }

      await setCategory($scope.CateData.detailData.category_code);

      //전시카테고리
      if ($scope.CateData.detailData.display_categories) {
        for (let idx = 0; idx < $scope.CateData.detailData.display_categories.length; idx++) {
          const dispCategory = $scope.CateData.detailData.display_categories[idx];
          const res = await categoryModel.getScrapParentCategory({
            shop_cd: 'A085',
            std_ol_yn: 0,
            shop_cate_see_type: '전시',
            shop_cate_see_cd: dispCategory.code
          });

          if (res.data.result.length === 1) {
            $scope.savedDispCateList.push({
              ...dispCategory,
              name: res.data.result[0].shop_cate_see_detail.shop_cate_see_name_detail
            });

            $scope.$applyAsync();
          }

        }
      }

    };

    $scope.select = async (idx) => {
      const cate = $scope.sel_category[idx];

      if (!cate) {
        return false;
      }

      const category_names = [];

      for (let i = 0; i <= idx; i++) {
        category_names.push($scope.sel_category[i].shop_cate_see_name);
      }

      $scope.category_names = category_names.join(' > ');

      save_flag = cate.end_yn;

      if (!save_flag) {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.totalCate[idx + 1].map((childCate) => {
              if (childCate.parent_code === cate.shop_cate_see_cd) {
                $scope.cate[idx + 1].push(childCate);
              }
            });
            $scope.loading = true;
            resolve();
          });
        });

        return true;
      } else {
        // 하위 카테고리 초기화;
        for (let i = idx + 1; i < $scope.max_depth; i++) {
          $scope.cate[i] = [];
          $scope.sel_category[i] = '';
        }

        await new Promise(resolve => {
          $timeout(() => {
            $scope.loading = true;
            resolve();
          });
        });

        return false;
      }
    };

    const setCategory = async (code) => {
      if (!code) {
        return false;
      }

      code = code.split('_');

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd.toString() === code[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';

        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx) : false;

        idx++;
      }
    };

    /**
     * 전시 카테고리 삭제
     */
    $scope.removeSelectedDispCate = siteNo => {
      const index = $scope.savedDispCateList.findIndex(({ site_no }) => site_no === siteNo);

      $scope.savedDispCateList.splice(index, 1);
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {
      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 전시 카테고리 검색
     */
    $scope.setDispCtg = async (type, dispType) => {
      const dispCtg = $scope[dispType];

      if (!$scope.CateData.detailData) {
        commonSVC.showMessage('로딩중 입니다');
        dispCtg.dispCtgType = '';

        return false;
      }

      $scope.dispCtg.dispCtgLoad = true;
      $scope.dispCtg.searchResultList = $scope.disp_cate[0].filter(({ shop_cate_see_detail: { shop_cate_see_name_detail } }) => shop_cate_see_name_detail.includes(dispCtg.searchValue));

      if (!$scope.dispCtg.searchResultList.length) {
        commonSVC.showMessage('조회된 카테고리가 없습니다');
      }

      // 검색 액션 처리됐음을 보여주기 위해 로딩바 강제 0.1초 노출
      $timeout(() => {
        $scope.dispCtg.dispCtgLoad = false;
      }, 100);
    };

    /**
     * 전시카테고리 선택이벤트
     */
    $scope.setDispCtgList = function() {
      if (!$scope.dispCtg.dispCtgSelect?.etc?.site_no) {
        return false;
      }

      // 기선택된 전시카테고리와 동일한 사이트를 선택했는지 확인
      if ($scope.savedDispCateList.some(({ site_no }) => parseInt(site_no) === $scope.dispCtg.dispCtgSelect.etc.site_no)) {
        commonSVC.showMessage('전시카테고리는 판매 사이트별로 1개씩만 등록할 수 있습니다');

        return false;
      }

      $scope.savedDispCateList.push({
        code: $scope.dispCtg.dispCtgSelect.shop_cate_see_cd,
        name: $scope.dispCtg.dispCtgSelect.shop_cate_see_name,
        site_no: $scope.dispCtg.dispCtgSelect.etc.site_no
      });
    };

    /**
     * 로딩이미지
     * @param complete
     */
    function loading(complete) {
      if (complete) {
        $('#spinner').addClass('hidden'); //로딩중
      } else {
        $('#spinner').removeClass('hidden'); // 로딩완료
      }
    }

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {
      $scope.check = _.isUndefined(check) ? { set_all_shop: false } : check;
      $scope.shopInfoSave(param);
    });

    /**
     * 전시카테고리 저장
     */
    function DispCtgList(param) {
      const dispTypeArr = ['dispCtg'];

      dispTypeArr.forEach(dispType => {
        const savedList = $scope[`saved${dispType.replace('dispCtg', 'DispCate')}List`];

        param[dispType] = savedList;
        if (dispType === 'dispCtg') {
          param.display_categories = savedList;
        }
      });
    }

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      let category_code = '';

      for (let i = 0; i < $scope.max_depth; i++) {
        // 초기화
        $scope.CateData.detailData[`cate${i}`] = '';
        if ($scope.sel_category[i].shop_cate_see_cd) {
          const pre = $scope.CateData.detailData[`cate${i - 1}`] || '';

          category_code = $scope.CateData.detailData[`cate${i}`] = pre ? `${pre}_${$scope.sel_category[i].shop_cate_see_cd}` : $scope.sel_category[i].shop_cate_see_cd;
        }
      }

      //category_names 초기화
      $scope.CateData.detailData.category_names = $scope.category_names;
      //category_code 초기화
      $scope.CateData.detailData.category_code = category_code;
      // 세분류
      $scope.CateData.detailData.key_stdct_Cd = $scope.CateData.detailData.key_Dctg;

      const param = {
        site_code: $scope.matchingData.site_code,
        site_id: $scope.matchingData.site_id,
        category_code: category_code,
        category_codes: 'test',
        category_names: $scope.category_names,
        categoryId: $scope.matchingData.categoryId,
        cateAttr: $scope.CateData.cateAttr,
        detailData: { ...$scope.CateData.detailData },
        set_all_shop: $scope.check ? $scope.check.set_all_shop : false,
        country: 'kr',
        //국내/해외
        type: 0
      };

      DispCtgList(param.detailData);

      if (!$scope.form1.$valid) {
        $timeout(() => {
          commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');
        }, 300);

        return;
      }

      // 신세계백화점(SSG) 카테고리 선택을 안한 경우 에러 처리
      if (!param.detailData.dispCtg.some(({ site_no }) => parseInt(site_no) === 6009)) {
        $timeout(() => {
          commonSVC.showMessage('실패', '신세계백화점(SSG) 전시카테고리를 추가하여 주시기 바랍니다.');
        }, 300);

        return false;
      }

      if ((event && event.type === 'online') || $scope.matchingData.isOnlineAdd === true) {
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else {
          $timeout(() => {
            commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.')
              .then(async (confirm) => {
                if (confirm) {
                  /**
                   * 2018-03-09 Roy
                   * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
                   */

                  try {
                    const res = await categoryModel.shopInfoDetailEdit(param);

                    $scope.loading = true;
                    commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                    param.shop_cate_no = res.data.shop_cate_no;

                    $scope.returnAct(param);
                  } catch (err) {
                    $scope.loading = true;

                    commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
                  }
                } else {
                  return;
                }
              });
          }, 300);
        }
      } else {
        $timeout(() => {
          param.isApply = true;

          $scope.applyCate(param);
        });
      }
    };

    /**
     * 초기화 함수
     */
    const init = async () => {
      loading(false); // 로딩중

      const res_disp = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A085', std_ol_yn: 0, shop_cate_see_type: '전시', shop_cate_see_cd: null }); // 전시카테고리 조회
      const cateDupCheck = [[], [], [], []]; // 카테고리 중복 확인용 배열

      // 카테고리 조회
      await run_pa5_jobs('get_categories', '카테고리', {}, data => {
        for (const cate of data) {
          if (!cate.is_end) {
            // 중복 카테고리 제외
            if (cateDupCheck[cate.depth - 1].includes(cate.code)) {
              continue;
            } else {
              cateDupCheck[cate.depth - 1].push(cate.code);
            }
          }
          $scope.totalCate[cate.depth - 1].push({
            $$hashkey: cate.$$hashkey,
            end_yn: cate.is_end,
            shop_cate_see_cd: cate.code,
            shop_cate_see_depth: cate.depth,
            shop_cate_see_name: cate.name,
            shop_cate_see_type: cate.type,
            parent_code: cate.parent_code,
            etc: cate.etc
          });
        }

        return data;
      });

      $timeout(async () => {
        $scope.cate[0] = $scope.totalCate[0];
        $scope.disp_cate[0] = res_disp.data.result;

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A085categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }

        loading(true);
      });

    };

    init();
  });
