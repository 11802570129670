/**
 * 2018-10-04 Daniel
 * 머리말/꼬리말 컨펌모달
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineProductAddcontentConfirmCtrl', function ($scope, $uibModalInstance, $timeout, commonSVC, data) {
    $scope.type = data.type;
    $scope.title = data.title;

    $scope.editYn = false;

    $scope.target = data.target ? '' : 'ALL';
    $scope.targetChecked = { 상세설명: true, 상품명: true };

    $scope.targetClick = function (target, $event) {
      $timeout(function () {
        if (!$scope.targetChecked[target == '상세설명' ? '상품명' : '상세설명'] && !$event.target.checked) {
          commonSVC.showMessage('해제할 템플릿 종류는 하나이상 선택해야 합니다.');
          $scope.targetChecked[target] = !$event.target.checked;
          $event.stopPropagation();
        } else {
          $scope.targetChecked[target] = $event.target.checked;
        }
      }, 0);
    };

    $scope.apply = function () {
      $scope.target = $scope.targetChecked.상세설명 && $scope.targetChecked.상품명 ? 'ALL' : $scope.targetChecked.상세설명 ? '상세설명' : '상품명';

      $uibModalInstance.close({ editYn: $scope.editYn, target: data.target || $scope.target });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });
