
/*
2017-12-13 Pada 쇼핑몰 상품 등록 이미지
기본이미지 -> 쇼핑몰별 개별 이미지 적용 모달 컨
*/

'use strict';
angular.module('gmpApp').controller('olImgModal', function($scope, data,
  $uibModalInstance
) {
  function init () {
    $scope.img_data = data.file;
  }
  init();

  $scope.ok = function () {
    $uibModalInstance.close('success');
  };
  $scope.cancle = function () {
    $uibModalInstance.dismiss();
  };
});