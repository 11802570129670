'use strict';

angular.module('gmpApp')
  .service('memoModel', function (settings, commonSVC) {
    return {
      addMemoList: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/memos/multiple`, data, next);
      },
      add: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/memos`, data, next);
      },
      edit: (data, next) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/memos`, data, next);
      },
      end: (data, next) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/memos/status`, data, next);
      },
      delete: (data, next) => {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/memos`, data, next);
      },
      list: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/memos/list`, data, next);
      },
      addTemplate: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/memos/templates`, data, next);
      },
      editTemplate: (data, next) => {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/memos/templates`, data, next);
      },
      deleteTemplate: (data, next) => {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/memos/templates`, data, next);
      },
      getTemplateList: (data, next) => {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/memos/get-templates`, data, next);
      },
    };
  });
