(function () {
  'use strict';

  angular.module('gmpApp')
    .controller('OrderCustomerInquiryAgencyListCtrl', OrderCustomerInquiryAgencyListCtrl);

  function OrderCustomerInquiryAgencyListCtrl ($scope, $filter, subChannelList, subUserList, csSVC, $timeout, systemList, onlineProductSVC, commonSVC, settings, gettextCatalog, $rootScope) {

    // 별칭정보 담기
    const seller_nick_info = [];
    let inquiryList = [];

    _.forEach(subChannelList.data.results, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });
    $scope.templateList = [];
    $scope.selectedTemplate = '0';
    $scope.countList = { total: 0 };
    $scope.selectCount = 'total';
    $scope.selectedInq = {};
    $scope.noReply = false;
    $scope.contentPlaceholder = '답변내용';
    let selectedAllRowList = []; // 테이블에서 선택된 리스트

    // 대리점(특약점) 목록
    const agencyList = subUserList.data.map(o => {
      return {
        sub_sol_no: o.sol_no,
        company_name: o.company_name
      };
    });

    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      multi_search_word: '', 		//멀티서치워드
      multi_type: 'shop_sale_no',  //멀티서치 타입
      multi_shop_id: ''
    };

    const searchData = {
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
      search_key_items: [  // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('문의자명'), value: 'inq_name' },
        { label: gettextCatalog.getString('문의제목'), value: 'inq_title' },
        { label: gettextCatalog.getString('내용'), value: 'inq_content' },
        { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' }

      ],
      search_date_type: [
        { label: gettextCatalog.getString('수집일'), value: 'wdate' },
        { label: gettextCatalog.getString('전송일'), value: 'send_time' }
      ],
      search_multi_items: [
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' }
      ]
    };

    /**
     * 서치바 관련 변수
     */
    $scope.searchData = angular.copy(searchData);
    $scope.searchForm = angular.copy(searchForm);

    // 상세검색 데이터
    const channelListArr = subChannelList.data.results || [];     // 판매채널 리스트
    const searchDetail = [
      {
        title: gettextCatalog.getString('대리점(특약점) 선택'),
        search_name: 'sub_sol_no',
        item_list: angular.copy(agencyList),
        item_key: 'company_name',
        item_value: 'sub_sol_no',
        select_value: ''
      },
      {
        // 쇼핑몰 선택
        title: gettextCatalog.getString('쇼핑몰 선택'),
        search_name: 'shop_cd',
        item_list: angular.copy(_.unionBy(channelListArr, 'shop_cd')),
        item_key: 'shop_name',
        item_value: 'shop_cd',
        select_value: '',
        add_class: 'select-search'
      },
      {
        // 계정 선택
        title: gettextCatalog.getString('계정 선택'),
        search_name: 'shop_id',
        item_list: angular.copy(channelListArr),
        item_key: 'shop_id',
        item_value: 'shop_id',
        select_value: '',
        add_class: 'select-search',
        filter: function(option) {
          return option.shop_cd == $scope.searchForm.shop_cd;
        }
      },
      {
        title: gettextCatalog.getString('문의상태 선택'),
        search_name: 'inq_status',
        item_list: [
          { inq_status: '신규문의' },
          { inq_status: '답변작성완료' },
          { inq_status: '답변전송완료' },
          { inq_status: '확인완료' }
        ],
        item_key: 'inq_status',
        item_value: 'inq_status'
      },
      {
        title: gettextCatalog.getString('문의유형 선택'),
        search_name: 'inq_type',
        item_list: [
          { inq_type: '상품문의' },
          { inq_type: '긴급메세지' },
          { inq_type: '상품평' }
        ],
        item_key: 'inq_type',
        item_value: 'inq_type'
      }
    ];

    const searchDetailMulti = [
      {
        title: gettextCatalog.getString('계정 선택'),
        search_name: 'multi_shop_id',
        item_list: angular.copy(channelListArr).sort(function(prev, curr) {
          return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
        }),
        item_key: 'shop_id',
        item_value: 'shop_id',
        search_word: ''
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      },
      getInquiryList: function() {
        return inquiryList;
      }
    };

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      // 검색 영역 왼쪽 상위 버튼
      actions_left_top: [],
      // 검색 영역 왼쪽 버튼
      actions_left: [],
      // 검색 영역 오른쪽 버튼
      actions_right: [],
      table_actions: []
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'inq_name', 'inq_type', 'inq_status', 'company_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      gridHeight: 420,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/inquiries/agency`,
        requestWillAction: function(data) {
          if ($rootScope.side_search.page === 'inquiry' && $rootScope.side_search.search) {
            $scope.resetDo(true);

            $scope.searchDetail[0].select_value = $rootScope.side_search.sub_sol_no || '';
            $scope.searchDetail[3].select_value = $rootScope.side_search.inq_status || '';
            $scope.searchDetail[4].select_value = $rootScope.side_search.inq_type || '';

            $scope.searchForm.sub_sol_no = $rootScope.side_search.sub_sol_no || '';
            $scope.searchForm.inq_type = $rootScope.side_search.inq_type || '';
            $scope.searchForm.inq_status = $rootScope.side_search.inq_status || '';

            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $rootScope.side_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          inquiryList = result.results;
          $scope.countList.total = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'company_name',
          title: '대리점(특약점)',
          width: 100
        },
        {
          key: 'inq_status',
          title: '문의 상태',
          width: 90,
          template: function(row) {
            return $filter('statusColorInq')(row.inq_status);
          }
        },
        {
          key: 'inq_type',
          title: '문의 유형',
          width: 90
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '<div class="text-center">직접입력</div>';

            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(
                row.shop_name.shop_id_view_type,
                seller_nick_info,
                row.shop_cd,
                row.shop_id
              );

              img =
                `<span uib-tooltip='${row.shop_name}(${shop_info[0]})` +
                `' tooltip-placement='right'><img src='/assets/images/sitelogo/${
                  row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd
                }.png' style='width: 50px;'>${shop_info[1]}</span>`;
            }

            return img;
          }
        },
        {
          key: 'wdate',
          title: '문의수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'send_time',
          title: '답변전송일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'inq_name',
          title: '문의자명',
          width: 120,
          template: function(row) {
            return row.inq_name || row.inq_id;
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 120,
          template: function(row) {
            const sale_no = row.shop_sale_no || '';
            const shop_cd = row.shop_cd;
            const shop_id = row.shop_id;
            let sale_no_link = '';

            if (
              shop_cd == 'B378' &&
              row.shop_sale_no_sub &&
              row.shop_sale_no_sub.length === 10
            ) {
              sale_no_link = `${sale_no}?vendorItemId=${
                row.shop_sale_no_sub
              }`;
            } else {
              sale_no_link = sale_no;
            }

            if (
              sale_no &&
              !_.includes(onlineProductSVC.impossibeDetailView, shop_cd) &&
              sale_no != '-'
            ) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}', ${row.sol_no})"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        // 쇼핑몰 주문번호
        {
          key: 'shop_ord_no',
          title: '주문번호',
          width: 120
        },
        {
          key: 'inq_title',
          title: '문의제목',
          width: 220
        }
      ]
    };

    /*********** 함수 목록 ***********/

    /**
     * 조회
     */
    $scope.searchDo = function (refresh, noDelay) {
      if ($scope.searchForm.inq_type) {
        switch ($scope.searchForm.inq_type) {
          case '상품문의':
            $scope.selectCount = 'cs';
            break;
          case '긴급메세지':
            $scope.selectCount = 'emergency';
            break;
          case '상품평':
            $scope.selectCount = 'review';
            break;
          default:
            $scope.selectCount = 'total';
            break;
        }
      } else {
        $scope.selectCount = 'total';
      }

      $('#btn-confirm').attr('disabled', false).html('확인 처리(일괄)');
      $('#btn-answer').attr('disabled', false).html('답변 작성(일괄)');
      $('#btn-send').attr('disabled', false).html('답변 전송(일괄)');
      $('#btn-delete').attr('disabled', true).html('삭제');

      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);

    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = (noSearch = false) => {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.selectCount = 'total';

      if (!noSearch) {
        $scope.searchDo(true, true);
      }
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 상세보기시 선택한 데이터 보여주기
     * */
    $scope.$on('OnSelectChangeBefore', function(event, data) {
      $scope.selectedInq = angular.copy(data.selectData);

      //답변내용 문구
      $scope.contentPlaceholder = csSVC.csContentPlaceholderFn(data, $scope.selectedInq.inq_type);

      switch ($scope.selectedInq.inq_type) {
        case '상품문의':
          $scope.noReply = !$scope.selectedInq.en_sendCS_yn;
          break;
        case '상품평':
          $scope.noReply = !$scope.selectedInq.en_sendRCS_yn;
          break;
        case '긴급메세지':
          $scope.noReply = !$scope.selectedInq.en_sendECS_yn;
          break;
        default:
          break;
      }

      // 답변제목이 없는경우 "re : 문의제목" 으로 노출
      if (!$scope.selectedInq.ans_title) {
        $scope.selectedInq.ans_title = `Re: ${$scope.selectedInq.inq_title}`;
      }

      $scope.selectedTemplate = '0';
    });

    /**
     * 테이블 컬럼 사이즈 확인
     * */
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.order_customer_inquiry_list') {
        const $wrapper = $('#customer_inquiry_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    // 해당 버튼에 리스트 카운트 출력
    function drawBtn (list) {
      const confirmCnt = list.filter(inq => inq.inq_status !== '확인완료'
      && ((inq.inq_type === '상품문의' && !inq.en_sendCS_yn)
        || (inq.inq_type === '상품평' && !inq.en_sendRCS_yn)
        || (inq.inq_type === '긴급메세지' && !inq.en_sendECS_yn))).length;

      const answerCnt = list.filter(inq => !['답변전송완료', '확인완료', '답변작성완료'].includes(inq.inq_status)
        && !((inq.shop_cd == 'A112' || inq.shop_cd == 'A027') && inq.inq_type == '상품평')
        && ((inq.inq_type === '상품문의' && inq.en_sendCS_yn)
        || (inq.inq_type === '상품평' && inq.en_sendRCS_yn)
        || (inq.inq_type === '긴급메세지' && inq.en_sendECS_yn))).length;

      const sendCnt = list.filter(inq => inq.inq_status === '답변작성완료').length;
      const deleteCnt = list.length;

      if (!list.length) {
        $('#btn-confirm').attr('disabled', false).html('확인 처리(일괄)');
        $('#btn-answer').attr('disabled', false).html('답변 작성(일괄)');
        $('#btn-send').attr('disabled', false).html('답변 전송(일괄)');
        $('#btn-delete').attr('disabled', true).html('삭제');
      } else {
        !confirmCnt ? $('#btn-confirm').attr('disabled', true).html('확인 처리 (0)') : $('#btn-confirm').attr('disabled', false).html(`확인 처리 (${confirmCnt})`);
        !answerCnt ? $('#btn-answer').attr('disabled', true).html('답변 작성 (0)') : $('#btn-answer').attr('disabled', false).html(`답변 작성 (${answerCnt})`);
        !sendCnt ? $('#btn-send').attr('disabled', true).html('답변 전송 (0)') : $('#btn-send').attr('disabled', false).html(`답변 전송 (${sendCnt})`);
        $('#btn-delete').attr('disabled', false).html(`삭제(${deleteCnt})`);
      }
    }

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      selectedAllRowList = data;

      drawBtn(selectedAllRowList);
    }, 200));

    /**
     * 사이드바 집계 클릭시
     */
    $scope.$on('sidebarSearch_inquiry', function() {
      $scope.searchDo(true, true);
    });
  } // END function
})();
