'use strict';

angular.module('gmpApp')
  .controller('ConfigsSystemCtrl', function ($scope, commonSVC, systemModel, $rootScope, settings, systemList, systemSVC, gettextCatalog, userInfo, $timeout, deliveryInfoList, deliveryInfoListAll) {
    // 상품 관련 항목 숨김처리
    // 보여야 할시 system_KR.html 에서 prod_item_hide를 다 지우던가 이걸 true로 바꾸면 보임
    // 솔루션 타입이 TEST, QA 일때만 보이도록 처리
    $scope.prod_item_hide = ['TEST', 'QA'].includes(userInfo.user.sol_sub_type);
    // 총괄관리자(마스터포함) 관리자만 수정 가능하도록
    $scope.editable = userInfo.user.auth_type.includes('관리자');
    $scope.isSmile = $rootScope.userProfileCheck('sol_ser', 'smile', 'like');

    // 스케쥴러 시간반복 설정 여부
    $scope.viewScheduler_batch = !!$rootScope.userProfileCheck('sol_ser', 'scheduler_batch', 'like');

    // 주문자동수집 가능쇼핑몰
    const channelList = $rootScope.useChannelList({ site_action: 'ScrapOrder' });
    $scope.channelListCount = channelList.length;

    const ordAutoWorkChannel = channelList.map(o => ({ shop_cd: o.shop_cd, shop_id: o.shop_id }));

    $scope.reportCheck = {
      use_yn: false,
      day: false,
      time: false,
      work: false,
      shop: false
    };

    const ori_ord_auto_work_time = {
      hour: '',
      minute: ''
    };
    // 권한 체크
    if (userInfo.permission.config) {
      $scope.perm = {
        order: userInfo.permission.config.roles.order,
        question: userInfo.permission.config.roles.question,
        prod: userInfo.permission.config.roles.prod,
        onlineProd: userInfo.permission.config.roles.onlineProd,
      };
    } else { // 이전 유저들을 위한 호환성
      $scope.perm = {
        order: true,
        question: true,
        prod: true,
        onlineProd: true,
      };
    }

    //셀렉트 값 변경시 초기값 변경해줄 변수
    $scope.ori_opt_order_date = '';
    $scope.ori_work_count = '';
    $scope.ori_system_lock_time = '';
    $scope.ori_end_wait_change = '';
    $scope.ori_customer_inquiry_auto = '';
    $scope.ori_order_count = '';
    $scope.temp_lang = settings.current_lang.lang;
    $scope.temp_font = settings.current_font[settings.current_lang.lang].code;
    $scope.test = window.location.host.indexOf('pstaging') !== -1 || window.location.host.indexOf('localhost') !== -1;
    $scope.ord_auto_work_yn = 'N';
    $scope.ord_auto_work = {
      work_act: [],
      shop_info: ordAutoWorkChannel,
      hour: '00',
      minute: '00',
      weekday_no: [],
      apply_batch_time: false,
      batch_time: '3'
    };
    $scope.ord_auto_work_setting = '';
    $scope.carrList = [];

    // 묶음배송 기준
    $scope.set_type = {
      to_name: true,
      to_zipcd: true,
      to_addr1: true,
      depot_no: false,
      shop_id: false,
      to_htel: false,
      date: false,
      date_type: 'ord_time',
      ship_method: false,
      order_name: false,
      shop_ship_no: false,
      ship_hope_time: false,
    };
    // 고정 묶음배송 기준
    const set_type_default = {
      shop_cd: true
    };

    $scope.delivery_text = '';

    $scope.systemData = {};

    init();

    //항목 열고 닫기 flag
    $scope.flag_change = function (data, flag) {
      init();
      $('.system-form .form-control').valid();
      $scope[data] = flag;
    };

    //사용 택배사 설정 Y/N 변경
    $scope.carrSettingChange = (check) => {
      $scope.systemData.use_carr_list = check === 'Y' ? $('.carrier-select').val() : [];
      $scope.use_carrier_setting_yn = check;
    };

    $scope.deliverySetChange = (value) => {
      if (value === '무료배송') {
        $scope.systemData.delivery_price = 0;
      }
    };

    //시스템 수정
    $scope.onSubmit = async (item) => {
      if ($scope.form1.$valid) {
        $scope.submitDisabled = true;

        let param = {};

        param[item] = $scope.systemData[item];

        // 주문 DB 자동삭제 설정
        if (item === 'order_count' && param[item] === '30') {
          if (!await commonSVC.showConfirm('주문 DB 자동삭제 기간을 30일로 설정하시겠습니까?', '구매이력이 30일 이상인 고객응대에 문제가 될수 있습니다.')) {
            return false;
          }
        }

        //묶음배송기준
        if (item === 'delivery_bundle_standard') {
          $scope.systemData.set_type = $scope.set_type;
          let string = '';
          let index = 0;

          angular.forEach({ ...$scope.systemData.set_type, ...set_type_default }, function (value, key) {
            index++;
            if (value == true) {
              if (index != 1) {
                string += ',';
              }

              if (key === 'date') {
                key = $scope.systemData.set_type.date_type;
              }
              string += key;
            }
          });
          $scope.systemData.set_type = string;
          param = {
            set_type: string,
            pre_ord_bundle_yn: $scope.systemData.pre_ord_bundle_yn.toString(),
            prod_bundle_avail_use_yn: $scope.systemData.prod_bundle_avail_use_yn
          };
        }

        // //주문 자동화 (스케쥴러)
        if (item === 'use_ord_auto_work') {
          $scope.ord_auto_work.work_act = [];
          Object.keys($scope.work_act).forEach (act => {
            if ($scope.work_act[act]) {
              $scope.ord_auto_work.work_act.push(act);
              if (act === 'ScrapOrder') {
                $scope.ord_auto_work.work_act.push('ScrapOrderConfirmList');
              } else if (act === 'ScrapCS') {
                $scope.ord_auto_work.work_act.push(...['ScrapEmergencyCS', 'ScrapReview']);
              }
            }
          });

          $scope.ord_auto_work.time = `${$scope.ord_auto_work.hour}:${$scope.ord_auto_work.minute}:00`;
          $scope.ord_auto_work.ord_auto_work_yn = $scope.ord_auto_work_yn;
          $scope.ord_auto_work.apply_batch_time = !$scope.viewScheduler_batch ? false : $scope.ord_auto_work.apply_batch_time;

          if ($scope.systemData.report_yn) {
            $scope.ord_auto_work.reject_report = $scope.reportCheck.use_yn ||
              (!($scope.work_act.ScrapOrder || $scope.work_act.ScrapOrderAndConfirmDoit) || !$scope.work_act.SyncOrderState);
            $scope.ord_auto_work.change_time_report = $scope.reportCheck.time;

            if ($scope.ord_auto_work_yn === 'N') {
              $scope.reportCheck = {
                use_yn: false,
                day: false,
                time: false,
                work: false,
                shop: false
              };
            }
          }
          param = { ord_auto_work: $scope.ord_auto_work };
        }

        //솔루션 자동 잠금 값 변경
        if (item == 'system_lock_time') {
          localStorage.setItem('ls.system_lock_time', $scope.systemData.system_lock_time);
        }

        //재고 부족 상품 발생시 알림
        if (item == 'out_stock_notice') {
          localStorage.setItem('ls.out_stock_notice', $scope.systemData.out_stock_notice);
        }

        //데이터 수집기간
        if (item === 'data_collection_searchdate') {
          param = { order_collection_searchdate: $scope.systemData.order_collection_searchdate,
            sett_collection_searchdate: $scope.systemData.sett_collection_searchdate,
            inq_collection_searchdate: $scope.systemData.inq_collection_searchdate };
        }

        //자동수집 값 변경시
        if (item === 'opt_order_date') {
          if ($scope.systemData.opt_order_date === 'n') {
            param.ord_auto_collect_ship_yn = 'n';
          } else {
            param.ord_auto_collect_ship_yn = $scope.systemData.ord_auto_collect_ship_yn;
            param.ord_auto_collect_shop = $scope.systemData.ord_auto_collect_shop;
          }
        }

        //출고지연 주문 기준
        if (item === 'departure_delay_standard') {
          param.delivery_delay_criteria = $scope.systemData.delivery_delay_criteria;
        }

        //배송지연 사유 기본입력
        if (item === 'delivery_delay_YN') {
          param.delivery_delay_reason = $scope.systemData.delivery_delay_reason;
        }

        //안전재고 기본입력
        if (item === 'safe_stock_default_YN') {
          param.safe_stock_default = $scope.systemData.safe_stock_default;
        }

        //상품수집기간 기본입력
        if (item === 'prodScrap_date_YN') {
          param.prod_scrap_date = $scope.systemData.prod_scrap_date;
        }

        //주문 리스트 원가 입력 기본 설정
        if (item === 'ord_cost_price_yn') {
          param.ord_cost_price_yn = $scope.systemData.ord_cost_price_yn;
        }

        //자동 품절 처리
        if (item === 'soldout_auto') {
          if ($scope.systemData.soldout_auto === 'N') {
            $scope.systemData.soldout_auto_prod = 'N';
          }
          param.soldout_auto_prod = $scope.systemData.soldout_auto_prod;
        }

        //자동 수정 처리
        if (item === 'prod_edit_auto_yn') {
          if ($scope.systemData.prod_edit_auto_yn === 'N') {
            $scope.systemData.prod_edit_auto_all_yn = 'N';
          }
          param.prod_edit_auto_all_yn = $scope.systemData.prod_edit_auto_all_yn;
        }

        //자동 발송예정일 전송 처리 2018-12-31 Jacob
        if (item === 'delivery_auto_yn') {
          if ($scope.systemData.delivery_auto_yn === 'Y') {
            param.delivery_auto_yn = 'Y';
            param.delivery_auto_date = $scope.systemData.delivery_auto_date;
            param.delivery_auto_delay_reason = $scope.systemData.delivery_auto_delay_reason;
          } else {
            param.delivery_auto_yn = 'N';
          }
        }

        // 배송방법 일괄 설정
        if (item === 'delivery_setting') {
          if ($scope.systemData.delivery_set_yn === 'Y' && !$scope.systemData.delivery_set) {
            commonSVC.showMessage(gettextCatalog.getString('배송방법을 선택해 주세요.'));

            return;
          } else if ($scope.systemData.delivery_set_yn === 'N') {
            param.delivery_set_yn = $scope.systemData.delivery_set_yn;
            param.delivery_set = '';
            param.delivery_price = 0;
          } else {
            param.delivery_set_yn = $scope.systemData.delivery_set_yn;
            param.delivery_set = $scope.systemData.delivery_set;
            param.delivery_price = $scope.systemData.delivery_price || 0;
          }
        }

        // api 대행사 설정
        if (item === 'use_agency' && param.use_agency === 'Y') {
          if (!$scope.systemData.agency_api_key) {
            commonSVC.showMessage('API 대행사를 선택해주세요.');

            return false;
          } else {
            param.use_agency = $scope.systemData.agency_api_key;
          }
        }

        // 사용 택배사 설정
        if (item === 'use_carrier_setting') {
          let use_carr_list, use_carr_change;

          if ($scope.use_carrier_setting_yn === 'N') {
            use_carr_list = '';
          } else {
            if (!$scope.systemData.use_carr_list.length) {
              commonSVC.showMessage(gettextCatalog.getString('사용 택배사를 선택해주세요.'));

              return false;
            } else if ($scope.systemData.use_carr_list.length > 10) {
              commonSVC.showMessage(gettextCatalog.getString('사용 택배사는 10개 이하로 설정 가능합니다.'));

              return false;
            } else {
              const use_carr_no = $scope.systemData.use_carr_list.map(name => {
                for (let i = 0; i < $scope.carrList.length; i++) {
                  if ($scope.carrList[i].carr_name === name) {
                    return $scope.carrList[i].carr_no;
                  }
                }
              });

              //sol_config_carr DB INSERT 쿼리에 들어갈 String
              use_carr_list = `(${userInfo.user.sol_no}, ${use_carr_no.join(`), (${userInfo.user.sol_no}, `)})`;
              //로그용 데이터
              use_carr_change = angular.copy($scope.systemData.use_carr_list);
            }
          }

          param = {
            use_carr_list: use_carr_list,
            use_carr_change: use_carr_change
          };

          systemModel.editUseCarr({ list: param }, (result) => {
            if (result === 'success') {
              commonSVC.showToaster('success', '', gettextCatalog.getString('변경사항이 저장되었습니다.'));
              $rootScope.deliveryInfoList = deliveryInfoList;
              $scope[item] = false;
            } else {
              $scope.submitDisabled = false;
              commonSVC.showToaster('error', '', gettextCatalog.getString('변경사항 저장에 실패하였습니다.'));
            }
          });

        } else {
          systemModel.set({ change_val: param }, function (result) {
            if (result === 'success') {
              commonSVC.showToaster('success', '', gettextCatalog.getString('변경사항이 저장되었습니다.'));
              $scope[item] = false;

              // 리스트 기본조회기간 변경시 브라우저 새로고침
              if (['system_lock_time', 'search_date', 'prodScrap_date_YN'].includes(item)) {
                commonSVC.showConfirm('변경 완료', '변경된 설정값으로 적용하려면 새로고침이 필요합니다. 새로고침하시겠습니까?', function (state) {
                  if (state) {
                    location.reload();
                  }
                });
              } else if (item === 'use_ord_auto_work') {
                $scope.setOrdAutoInfo();
              }

              setTypeName();

            } else {
              $scope.submitDisabled = false;
              commonSVC.showToaster('error', '', gettextCatalog.getString('변경사항 저장에 실패하였습니다.'));
            }
          });
        }
      } else {
        $('.system-form .form-control').valid();
        commonSVC.showMessage('수정 실패', '필수값을 모두 입력해주시기 바랍니다');
      }
    };

    $scope.initial = function() {
      commonSVC.showConfirm('초기화 하시겠습니까?', '', function (confirm) {
        if (confirm) {
          $scope.changeFont('default');
          $scope.systemData = angular.copy(systemSVC.defaultSetting);
          $scope.systemData.ord_auto_work = {
            ord_auto_work_yn: 'N'
          };

          systemModel.set({ change_val: $scope.systemData, isAll: true }, function (result) {
            if (result === 'success') {
              commonSVC.showToaster('success', '', gettextCatalog.getString('변경사항이 저장되었습니다.'));
              // 주문자동화 초기화
              $scope.ord_auto_work = {
                work_act: [],
                shop_info: ordAutoWorkChannel,
                hour: '00',
                minute: '00',
                weekday_no: [],
                apply_batch_time: false,
                batch_time: '3'
              };
              $scope.use_carrier_setting_yn = 'N';
              $scope.systemData.use_carr_list = [],
              $scope.ord_auto_work_setting = '';
              $scope.ord_auto_work_yn = 'N';
              _.forEach($scope.set_type, function(val, set_name) {
                if (val === true) {
                  $scope.set_type[set_name] = false;
                }
              });
              _.forEach($scope.systemData.set_type.split(','), function (set) {
                if ($scope.set_type[set] !== undefined) {
                  $scope.set_type[set] = true;
                } else {
                  if (set === 'ord_time' || set === 'wdate') {
                    $scope.set_type.date = true;
                    $scope.set_type.date_type = set;
                  } else {
                    $scope.set_type[set] = false;
                  }
                }
              });
              setTypeName();

              $('.carrier-select').val([]).trigger('change');
            } else {
              $scope.submitDisabled = false;
              commonSVC.showToaster('error', '', gettextCatalog.getString('변경사항 저장에 실패하였습니다.'));
            }
          });
        }
      });
    };

    /**
     * 자동수집 체크된 쇼핑몰카운트.
     * 2017-09-20 서상권
     */

    $scope.view_select_shops_cnt = channelList.filter(m => m.ord_auto_collect_yn).length;

    /**
     * 자동주문수집 사이트선택 모달 오픈
     * 2017-09-15 서상권
     */
    $scope.autoScrapSelect = function () {
      const modal = commonSVC.openModal('lg', { data: { channelList } }, 'SystemGetAutoOrder', 'views/configs/modals/system_get_auto_order.html');

      modal.result.then(function (re) {
        // 자동수집 쇼핑몰 갯수 갱신.
        $scope.view_select_shops_cnt = re.select_cnt;
        if ($scope.view_select_shops_cnt == $scope.total_user_shops_cnt) {
          $scope.systemData.ord_auto_collect_shop = 'Y';
        }
      });
    };

    /**
     * 초기화
     * */
    async function init() {
      // 탭열기시 다른 모든 탭 닫기 상태로 변경
      $scope.invoice_auto = false;
      $scope.workAutoDelete = false;
      $scope.order_count = false;
      $scope.customer_inquiry_auto = false;
      $scope.search_date = false;
      $scope.orderDelete = false;
      $scope.work_complate_alert = false;
      $scope.languageSetting = false;
      $scope.fontSetting = false;
      $scope.curr_cd = false;
      $scope.convert_ctry_price = false;
      $scope.mark_number_base = false;
      $scope.scrap_prod_info = false;
      $scope.label_price = false;
      $scope.shop_sale_name_cut_yn = false;
      $scope.end_wait_change = false;//상품 종료대기 전환일자
      $scope.order_collection_searchdate = false;//주문수집 검색 기간
      $scope.delivery_bundle_standard = false;//묶음배송기준
      $scope.delivery_delay_YN = false;
      $scope.safe_stock_default_YN = false;
      $scope.soldout_stock_standard = false;//품절임박 상품 기준
      $scope.departure_delay_standard = false;//출고지연 주문 알림
      $scope.out_stock_notice = false; //재고 부족 상품 발생 시 알림
      $scope.customer_inquiry_auto = false;//자동 문의 수집
      $scope.answer_auto = false; //자동 답변 전송
      $scope.inq_ans_over = false; // 답변 전송 등록
      $scope.inq_ans_send_yn = false; // 답변저장시 전송작업 등록
      $scope.soldout_auto = false; //자동 품절 처리
      $scope.soldout_auto_prod = false; //자동 품절 처리(옵션 부분 품절 미지원 시 상품 품절)
      $scope.prod_edit_auto_yn = false; //자동 수정 처리(SKU상품)
      $scope.prod_edit_auto_all_yn = false; //자동 수정 처리(옵션 부분 수정 미지원 시 상품 수정)
      $scope.auto_map_yn = false;//SKU상품 자동매칭 처리
      $scope.privacy_encrypt = false; //개인정보 암호화
      $scope.work_delete_auto = false;//누적작업자동삭제
      $scope.excel_download_passwordYN = false; //엑셀다운로드 패스워드 설정
      $scope.opt_order_sync = false;
      $scope.prod_auto_set_yn = false; //대표 SKU상품 정보 자동세팅
      $scope.img_resize = false;//이미지 리사이징
      $scope.setting_init = false;//설정 초기화
      $scope.prod_info_set = false;//대표 SKU상품 정보 자동 세팅
      $scope.main_use_mode = false;//주 사용 모드
      $scope.shop_id_view_type = false;//쇼핑몰 계정 표기 방식
      $scope.data_collection_searchdate = false;//데이터 수집 검색 기간
      $scope.prodScrap_date_YN = false;//상품수집 기간
      $scope.ord_cost_price_yn = false;
      $scope.delivery_auto_yn = false;
      $scope.use_ord_auto_work = false;
      $scope.use_carrier_setting = false;

      $scope.delivery_setting = false; // 배송방법 설정

      $scope.ord_prod_map_ol_yn = false; // 쇼핑몰상품 SKU상품 자동 매칭

      $scope.opt_order_date = false;//자동주문수집
      $scope.system_lock_time = false;//자동잠금
      $scope.use_agency = false;       // api 사용 설정

      // 리스트 셋팅
      $scope.opt_order_date_list = systemSVC.opt_order_date_list;
      $scope.work_count = systemSVC.work_count;
      $scope.order_count_list = systemSVC.order_count_list;
      $scope.search_date_list = systemSVC.search_date;
      $scope.end_wait_change_list = systemSVC.end_wait_change_list;
      $scope.order_collection_searchdate_list = systemSVC.order_collection_searchdate_list;
      $scope.inq_collection_searchdate_list = systemSVC.inq_collection_searchdate_list;
      $scope.sett_collection_searchdate_list = systemSVC.sett_collection_searchdate_list;
      $scope.opt_order_sync_list = systemSVC.opt_order_sync_list;
      $scope.privacy_encrypt_list = systemSVC.privacy_encrypt_list;
      $scope.work_delete_auto_list = systemSVC.work_delete_auto_list;
      $scope.customer_inquiry_auto_list = systemSVC.customer_inquiry_auto_list;
      $scope.delivery_delay_criteria_list = systemSVC.delivery_delay_criteria_list;
      $scope.language = systemSVC.language;
      $scope.system_lock_time_list = systemSVC.system_lock_time_list;
      $scope.ord_auto_collect_ship_yn = systemSVC.ord_auto_collect_ship_yn;       // 자동 배송준비중 처리 옵션
      $scope.ord_auto_collect_shop = systemSVC.ord_auto_collect_shop;             // 전체쇼핑몰 자동수집 여부

      systemList = await systemModel.load();
      $scope.systemData = systemList.data;

      if (systemList.status == 200) {
        // API 대행사 선택시 사용되는 값
        // 롯데글로벌로지스 대행사는 해댕 대행사로 설정이 된경우에만 노출
        if (!$rootScope.adminMode && $scope.systemData.use_agency !== '906c0DzZug6pFQmr6MhOu9dBO79E0soJaB94KZxH') {
          $scope.systemData.agencys = $scope.systemData.agencys.filter(agency => agency.api_key !== '906c0DzZug6pFQmr6MhOu9dBO79E0soJaB94KZxH');
        }

        $scope.systemData.agency_api_key = $scope.systemData.use_agency;
        $scope.systemData.use_agency = $scope.systemData.agency_api_key ? 'Y' : 'N';

        if ($scope.systemData.set_type != '' || undefined) {
          _.forEach($scope.set_type, function(val, set_name) {
            if (val === true) {
              $scope.set_type[set_name] = false;
            }
          });
          //묶음배송기준
          _.forEach($scope.systemData.set_type.split(','), function (set) {
            if ($scope.set_type[set] !== undefined) {
              $scope.set_type[set] = true;
            } else {
              if (set === 'ord_time' || set === 'wdate') {
                $scope.set_type.date = true;
                $scope.set_type.date_type = set;
              } else {
                $scope.set_type[set] = false;
              }
            }
          });
          setTypeName();
        }

        try {
          $scope.carrList = deliveryInfoListAll;
          $scope.use_carrier_setting_yn = $scope.systemData.use_carr_list ? 'Y' : 'N';

          const use_carr_list = !$scope.systemData.use_carr_list ? [] : $scope.systemData.use_carr_list.split(',').map(num => {
            for (let i = 0; i < $scope.carrList.length; i++) {
              if ($scope.carrList[i].carr_no == num) {  //carr_no와 num의 type이 달라 '===' 대신 '=='
                return $scope.carrList[i].carr_name;
              }
            }
          });

          $timeout(function () {
            $('.select-fixed-multiple').select2({
              minimumResultsForSearch: Infinity,
              width: '100%',
              placeholder: '택배사 검색',
              language: {
                noResults: function() {
                  return '택배사 없음';
                }
              },
            });

            $('input.select2-search__field').css('width', 461.328);
            $('.carrier-select').change(function () {
              $scope.systemData.use_carr_list = $(this).val() || [];

              if (!$scope.$$phase && !$scope.$root.$$phase) {
                $scope.$apply();
              }
            });
          });

          $('#carriers').val(use_carr_list).trigger('change');
        } catch (err) {
          commonSVC.showMessage('실패', '택배사 로드 실패');
        }

        if ($scope.systemData.system_lock_time === '') {
          $scope.systemData.system_lock_time = '3';
        }
        if ($scope.systemData.search_date === '') {
          $scope.systemData.search_date = '365';
        }
        if (!$scope.systemData.img_resize) {
          $scope.systemData.img_resize = 'N';
        }

        $scope.systemData.delivery_auto_date = $scope.systemData.delivery_auto_date.toString();
        $scope.ori_end_wait_change = $scope.systemData.end_wait_change;
        $scope.ori_opt_order_date = $scope.systemData.opt_order_date;
        $scope.ori_opt_order_sync = $scope.systemData.opt_order_sync;
        $scope.ori_system_lock_time = $scope.systemData.system_lock_time;
        $scope.ori_search_date = $scope.systemData.search_date;
        $scope.ori_customer_inquiry_auto = $scope.systemData.customer_inquiry_auto;
        $scope.ori_order_count = $scope.systemData.order_count;
        $scope.ord_auto_work = $scope.systemData.ord_auto_work || $scope.ord_auto_work;
        $scope.ord_auto_work.apply_batch_time = !$scope.viewScheduler_batch ? false : $scope.ord_auto_work.apply_batch_time;
        $scope.ord_auto_work.batch_time = $scope.ord_auto_work.batch_time ? $scope.ord_auto_work.batch_time.toString() : '3';
        $scope.ord_auto_work_yn = $scope.systemData.ord_auto_work ? 'Y' : 'N';
        $scope.systemData.prod_scrap_date = String($scope.systemData.prod_scrap_date);

        // 주문매출리포트 사용자의 시간변경 시 경고 문구출력은 위해 기존값 저장
        ori_ord_auto_work_time.hour = $scope.ord_auto_work.hour;
        ori_ord_auto_work_time.minute = $scope.ord_auto_work.minute;
        $scope.reportCheck.time = false;

        // 주문자동화 쇼핑몰 리스트 수 확인
        $scope.reportCheck.shop = $scope.ord_auto_work.shop_info.length !== ordAutoWorkChannel.length;

        // G마켓물류는 SKU 합포장 여부를 무조건 사용함으로 함.
        if ($scope.isSmile) {
          $scope.systemData.prod_bundle_avail_use_yn = 'Y';
        }

        $scope.setOrdAutoInfo();
      } else {
        commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
      }

    }

    /**
     * 2018-06-18 ally 묶음기준 설정
     * */
    function setTypeName() {
      const mapName = {
        to_name: '수령자명',
        to_zipcd: '우편번호',
        to_addr1: '주소',
        shop_id: '쇼핑몰(계정)',
        to_htel: '수령자 휴대폰',
        depot_no: '배송처',
        ord_time: '주문일',
        wdate: '주문수집일',
        ship_method: '배송방법',
        order_name: '주문자',
        shop_ship_no: '배송번호',
        ship_hope_time: '희망배송일',
      };

      const tmpName = [];

      _.forEach($scope.set_type, function(value, key) {
        if (value) {
          let name = mapName[key];

          if (key === 'date') {
            name = mapName[$scope.set_type.date_type];
          } else if (key === 'date_type') {
            name = '';
          }

          tmpName.push(name);
        }
      });

      $scope.delivery_text = _.filter(tmpName).join(' + ');
    }

    $scope.checkTime = function (type) {
      if (type === 'hour') {
        if (!$scope.ord_auto_work.hour) {
          commonSVC.showMessage('실패', '시간을 입력해주세요.');
          $scope.ord_auto_work.hour = '00';
        } else if (parseInt($scope.ord_auto_work.hour) > 23) {
          commonSVC.showMessage('실패', '시간은 00시 부터 23시까지 입력할 수 있습니다.');
          $scope.ord_auto_work.hour = '23';
        } else if (parseInt($scope.ord_auto_work.hour) < 10) {
          $scope.ord_auto_work.hour = `0${parseInt($scope.ord_auto_work.hour)}`;
        }

      } else {
        if (!$scope.ord_auto_work.minute) {
          commonSVC.showMessage('실패', '분을 입력해주세요');
          $scope.ord_auto_work.minute = '00';
        } else if (parseInt($scope.ord_auto_work.minute) > 50) {
          commonSVC.showMessage('실패', '분은 00분 부터 50분까지 10분 단위로 입력할 수 있습니다.');
          $scope.ord_auto_work.minute = '50';
        } else if (parseInt($scope.ord_auto_work.minute) < 10 && parseInt($scope.ord_auto_work.minute) > 0) {
          commonSVC.showMessage('실패', '분은 00분 부터 50분까지 10분 단위로 입력할 수 있습니다.');
          $scope.ord_auto_work.minute = '00';
        } else if (parseInt($scope.ord_auto_work.minute) === 0) {
          $scope.ord_auto_work.minute = '00';
        }
      }

      if ($scope.systemData.report_yn) {
        $scope.reportCheck.time = ori_ord_auto_work_time.hour !== $scope.ord_auto_work.hour || ori_ord_auto_work_time.minute !== $scope.ord_auto_work.minute;

      }

    };

    $scope.changeTime = function (type, dir) {
      if (type === 'hour') {
        let hour = parseInt($scope.ord_auto_work.hour);

        if (dir === 'up') {
          if (hour !== 23) {
            $scope.ord_auto_work.hour = (++hour < 10 ? `0${hour.toString()}` : hour.toString());
          } else {
            $scope.ord_auto_work.hour = '00';
          }
        } else {
          if (hour !== 0) {
            $scope.ord_auto_work.hour = (--hour < 10 ? `0${hour.toString()}` : hour.toString());
          } else {
            $scope.ord_auto_work.hour = '23';
          }
        }
      } else {
        const minute = parseInt($scope.ord_auto_work.minute);

        if (dir === 'up') {
          if (minute !== 50) {
            $scope.ord_auto_work.minute = minute + 10;
          } else {
            $scope.ord_auto_work.minute = '00';
          }
        } else {
          if (minute !== 0) {
            $scope.ord_auto_work.minute = minute === 10 ? '00' : minute - 10;
          } else {
            $scope.ord_auto_work.minute = '50';
          }
        }
        $scope.ord_auto_work.minute = $scope.ord_auto_work.minute.toString();
      }

      if ($scope.systemData.report_yn) {
        $scope.reportCheck.time = ori_ord_auto_work_time.hour !== $scope.ord_auto_work.hour || ori_ord_auto_work_time.minute !== $scope.ord_auto_work.minute;
      }
    };

    /**
     * 주문 수집 형식 변경
     */
    $scope.changeScrapOrderType = (type) => {
      $scope.work_act[type] = !$scope.work_act[type];

      if (['ScrapOrderAndConfirmDoit', 'ScrapOrder'].includes(type)) {
        const target = type === 'ScrapOrder' ? 'ScrapOrderAndConfirmDoit' : 'ScrapOrder';

        if ($scope.work_act[type] && $scope.work_act[target]) {
          $scope.work_act[target] = false;
        }
      }

      $scope.checkDisabledOrdAutoSave();
    };

    /**
     * 주문자동화 관련 버튼 선택 처리
     */

    $scope.changeOrderWork = function (type, data) {
      let index = -1;

      if (type === 'shop_info') {
        if (data) {
          $scope.ord_auto_work.shop_info = ordAutoWorkChannel;
          if ($scope.systemData.report_yn) {
            $scope.reportCheck.shop = false;
          }
        } else {
          // 쇼핑몰 선택 (동기화 모달 사용)
          const modal = commonSVC.openModal('md', { data: { work_type: 'select_shop', selected: $scope.ord_auto_work.shop_info.length !== ordAutoWorkChannel.length ? $scope.ord_auto_work[type] : [] } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');

          modal.result.then(function (shop) {
            $scope.ord_auto_work[type] = shop;
            if ($scope.systemData.report_yn) {
              $scope.reportCheck.shop = $scope.ord_auto_work.shop_info.length !== ordAutoWorkChannel.length;
            }
          });
        }
      } else if (type === 'weekday_no') {
        // 작업요일 선택

        index = $scope.ord_auto_work[type].indexOf(data);

        if (index > -1) {
          $scope.ord_auto_work[type].splice(index, 1);
        } else {
          $scope.ord_auto_work[type].push(data);
        }
      } else if (type === 'notice_yn') {
        $scope.ord_auto_work[type] = data;
      } else if (type === 'use') {
        $scope.ord_auto_work_yn = data ? 'Y' : 'N';
        if (!data) {
          $scope.ord_auto_work = {
            work_act: [],
            shop_info: ordAutoWorkChannel,
            hour: '00',
            minute: '00',
            notice: false,
            weekday_no: []
          };

          $scope.work_act = {
            ScrapOrder: false,
            ScrapOrderAndConfirmDoit: false,
            SyncOrderState: false,
            SendDelivNo: false,
            ScrapCS: false,
          };
        }
      }

      if ($scope.systemData.report_yn) {
        $scope.reportCheck.day = $scope.ord_auto_work.weekday_no.length !== 7;
        $scope.reportCheck.use_yn = $scope.ord_auto_work_yn === 'N';
      }

      $scope.checkDisabledOrdAutoSave();
    };

    $scope.setOrdAutoInfo = function () {
      if ($scope.ord_auto_work) {
        const weekdays = ['월', '화', '수', '목', '금', '토', '일'];
        const action = {
          ScrapOrder: '주문수집 (주문확인 X)',
          ScrapOrderAndConfirmDoit: '주문수집 (주문확인 O)',
          SyncOrderState: '주문동기화',
          SendDelivNo: '배송정보 전송',
          ScrapCS: '문의 수집'
        };

        const weekday_no = $scope.ord_auto_work.weekday_no.sort(function (a, b) { return a - b; });
        const weekdayArr = [];

        if (weekday_no.length === 7) {
          $scope.ord_auto_work_setting = '매일 ';
        } else {
          _.forEach(weekday_no, function (no) {
            weekdayArr.push(weekdays[no]);
          });

          $scope.ord_auto_work_setting = `${weekdayArr.join(', ')} `;
        }

        $scope.ord_auto_work_setting += `${$scope.ord_auto_work.hour}:${$scope.ord_auto_work.minute} `;
        $scope.ord_auto_work_setting += Object.keys(action).filter(o => $scope.ord_auto_work.work_act.includes(o)).map(key => action[key]).join(', ');

        if ($scope.ord_auto_work.apply_batch_time) {
          $scope.ord_auto_work_setting += ` (${$scope.ord_auto_work.batch_time}시간마다 반복작업 설정)`;
        }
      }

      // 주문 자동화 작업리스트 초기화
      $scope.work_act = {
        ScrapOrder: $scope.ord_auto_work.work_act.includes('ScrapOrderConfirmList') && !$scope.ord_auto_work.work_act.includes('ScrapOrderAndConfirmDoit'),
        ScrapOrderAndConfirmDoit: $scope.ord_auto_work.work_act.includes('ScrapOrderAndConfirmDoit'),
        SyncOrderState: $scope.ord_auto_work.work_act.includes('SyncOrderState'),
        SendDelivNo: $scope.ord_auto_work.work_act.includes('SendDelivNo'),
        ScrapCS: $scope.ord_auto_work.work_act.includes('ScrapCS')
      };

      $scope.checkDisabledOrdAutoSave();
    };

    $scope.checkDisabledOrdAutoSave = () => {
      $scope.disabledOrdAutoSave = !($scope.ord_auto_work_yn === 'N' || (Object.keys($scope.work_act).some(act => $scope.work_act[act]) && $scope.ord_auto_work.weekday_no.length));
    };
  });
