'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-A092-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonSVC, commonModel) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.attribute_data = [];
    $scope.pa5_job_data = {
      get_attributes: {
        load: false,
        data: []
      }
    };

    const cate_code = _.last(data.data.cate_cd.split('_'));

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
          $('.box-loading').addClass('hidden'); //로딩중
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    const init = async () => {
      try {
        // 상품속성 조회하여 세팅
        await run_pa5_jobs('get_attributes', '상품속성', { code: cate_code }, (data) => {
          const option_data = data.attributes;
          option_data.forEach(attr => {
            const opt_data = attr.option_data;
            const index_ove = opt_data.findIndex(item => item.value === '-1');
            if (index_ove !== -1) {
              const item = opt_data.splice(index_ove, 1)[0];
              opt_data.push(item);
            }

            return opt_data;
          });

          $scope.row.set_info.key_attribute = $scope.row.set_info.key_attribute ? $scope.row.set_info.key_attribute : {};

          const attributes = option_data;

          // 저장된 속성이 있을경우 카테고리를 변경했는지 아닌지를 비교하기 위함
          const old_attributes = Object.keys($scope.row.set_info.key_attribute).sort();
          const new_attributes = attributes.map(attr => `key_${attr.id.replace('-', '')}`).sort();

          // 기존 속성 데이터와 신규 속성 데이터 비교(귀찮으니 string으로 바꿔서 같은지 비교...)
          const attribute_compare = JSON.stringify(new_attributes) !== JSON.stringify(old_attributes);

          if (attribute_compare) {
            $scope.row.set_info.key_attribute = {};
          }

          $scope.attributes = attributes?.filter(attr => {
            const required = attr.rule_data.find(rule => rule.requiredRule === 'true')?.requiredRule ?? 'false';
            if (['p-1', 'p-2', 'p-3'].includes(attr.id)) {
              $scope.row.set_info.key_attribute[`key_${attr.id.replace('-', '')}`] = required;
            } else {
              if (!$scope.row.set_info.key_attribute[`key_${attr.id.replace('-', '')}`]) {
                const value = attr.type === 'multiCheck' ? [] : '';
                $scope.row.set_info.key_attribute[`key_${attr.id.replace('-', '')}`] = {
                  key: `key_${attr.id.replace('-', '')}`,
                  required,
                  id: attr.id,
                  name: attr.name,
                  type: attr.type,
                  value,
                  text_value: attr.text_value || '',
                };
              }

              // 멀티체크 타입이면 인덱스에 맞게 데이터 정리해야함
              if (attr.type === 'multiCheck') {
                const option_value = $scope.row.set_info.key_attribute[`key_${attr.id.replace('-', '')}`].value.filter(Boolean);
                const value = [];
                const option_value_length = attr.option_data.length;

                for (let i = 0; i < option_value_length; i++) {
                  value.push(null);
                }

                attr.option_data.forEach((opt_val, index) => {
                  if (option_value.includes(+opt_val.value)) {
                    value[index] = +opt_val.value;
                  }
                });

                $scope.row.set_info.key_attribute[`key_${attr.id.replace('-', '')}`].value = value;
              }
            }

            return attr.id.length > 3;
          }) ?? [];

          $scope.attributes = $scope.attributes.map(attr => attr = {
            ...attr,
            key: `key_${attr.id.replace('-', '')}`,
          });

          return data;
        });
      } catch (e) {
        commonSVC.showMessage('실패', '상품속성 정보 로드를 실패했습니다.');
      }
    };

    init();

    // 가격 단위
    $scope.priceUnit = [
      { code: '26', name: 'Acre/Acres' },
      { code: '27', name: 'Ampere/Amperes' },
      { code: '1', name: 'Bag/Bags' },
      { code: '19', name: 'Barrel/Barrels' },
      { code: '91', name: 'Blade/Blades' },
      { code: '28', name: 'Box/Boxes' },
      { code: '18', name: 'Bushel/Bushels' },
      { code: '90', name: 'Carat/Carats' },
      { code: '29', name: 'Carton/Cartons' },
      { code: '30', name: 'Case/Cases' },
      { code: '31', name: 'Centimeter/Centimeters' },
      { code: '32', name: 'Chain/Chains' },
      { code: '92', name: 'Combo/Combos' },
      { code: '33', name: 'Cubic Centimeter/Cubic Centimeters' },
      { code: '34', name: 'Cubic Foot/Cubic Feet' },
      { code: '35', name: 'Cubic Inch/Cubic Inches' },
      { code: '13', name: 'Cubic Meter/Cubic Meters' },
      { code: '36', name: 'Cubic Yard/Cubic Yards' },
      { code: '37', name: 'Degrees Celsius' },
      { code: '38', name: 'Degrees Fahrenheit' },
      { code: '14', name: 'Dozen/Dozens' },
      { code: '39', name: 'Dram/Drams' },
      { code: '40', name: 'Fluid Ounce/Fluid Ounces' },
      { code: '41', name: 'Foot/Feet' },
      { code: '88', name: 'Forty-Foot Container ' },
      { code: '42', name: 'Furlong/Furlongs' },
      { code: '15', name: 'Gallon/Gallons' },
      { code: '43', name: 'Gill/Gills' },
      { code: '44', name: 'Grain/Grains' },
      { code: '17', name: 'Gram/Grams' },
      { code: '87', name: 'Gross' },
      { code: '45', name: 'Hectare/Hectares' },
      { code: '46', name: 'Hertz' },
      { code: '47', name: 'Inch/Inches' },
      { code: '48', name: 'Kiloampere/Kiloamperes' },
      { code: '16', name: 'Kilogram/Kilograms' },
      { code: '49', name: 'Kilohertz' },
      { code: '10', name: 'Kilometer/Kilometers' },
      { code: '50', name: 'Kiloohm/Kiloohms' },
      { code: '51', name: 'Kilovolt/Kilovolts' },
      { code: '52', name: 'Kilowatt/Kilowatts' },
      { code: '22', name: 'Liter/Liters' },
      { code: '9', name: 'Long Ton/Long Tons' },
      { code: '53', name: 'Megahertz' },
      { code: '8', name: 'Meter/Meters' },
      { code: '7', name: 'Metric Ton/Metric Tons' },
      { code: '54', name: 'Mile/Miles' },
      { code: '55', name: 'Milliampere/Milliamperes' },
      { code: '24', name: 'Milligram/Milligrams' },
      { code: '56', name: 'Millihertz' },
      { code: '57', name: 'Milliliter/Milliliters' },
      { code: '58', name: 'Millimeter/Millimeters' },
      { code: '59', name: 'Milliohm/Milliohms' },
      { code: '60', name: 'Millivolt/Millivolts' },
      { code: '61', name: 'Milliwatt/Milliwatts' },
      { code: '62', name: 'Nautical Mile/Nautical Miles' },
      { code: '63', name: 'Ohm/Ohms' },
      { code: '6', name: 'Ounce/Ounces' },
      { code: '21', name: 'Pack/Packs' },
      { code: '5', name: 'Pair/Pairs' },
      { code: '86', name: 'Pallet/Pallets' },
      { code: '64', name: 'Parcel/Parcels' },
      { code: '65', name: 'Perch/Perches' },
      { code: '4', name: 'Piece/Pieces' },
      { code: '66', name: 'Pint/Pints' },
      { code: '85', name: 'Plant/Plants' },
      { code: '67', name: 'Pole/Poles' },
      { code: '3', name: 'Pound/Pounds' },
      { code: '68', name: 'Quart/Quarts' },
      { code: '69', name: 'Quarter/Quarters' },
      { code: '70', name: 'Rod/Rods' },
      { code: '71', name: 'Roll/Rolls' },
      { code: '20', name: 'Set/Sets' },
      { code: '89', name: 'Sheet/Sheets' },
      { code: '2', name: 'Short Ton/Short Tons' },
      { code: '72', name: 'Square Centimeter/Square Centimeters' },
      { code: '73', name: 'Square Foot/Square Feet' },
      { code: '74', name: 'Square Inch/Square Inches' },
      { code: '12', name: 'Square Meter/Square Meters' },
      { code: '75', name: 'Square Mile/Square Miles' },
      { code: '76', name: 'Square Yard/Square Yards' },
      { code: '77', name: 'Stone/Stones' },
      { code: '84', name: 'Strand/Strands' },
      { code: '11', name: 'Ton/Tons' },
      { code: '78', name: 'Tonne/Tonnes' },
      { code: '79', name: 'Tray/Trays' },
      { code: '83', name: 'Twenty-Foot Container' },
      { code: '25', name: 'Unit/Units' },
      { code: '80', name: 'Volt/Volts' },
      { code: '81', name: 'Watt/Watts' },
      { code: '82', name: 'Wp' },
      { code: '23', name: 'Yard/Yards' },
    ];

    $scope.priceUnit_name = $scope.priceUnit.find(unit => unit.code.toString() === $scope.row.set_info.key_priceUnit?.toString())?.name || 'Unit';
    $scope.priceUnit_setting = () => $scope.priceUnit_name = $scope.priceUnit.find(unit => unit.code.toString() === $scope.row.set_info.key_priceUnit.toString())?.name || 'Unit';

    // FOB 가격
    $scope.unitType = [
      { name: 'USD', code: '1' },
      { name: 'GBP', code: '5' },
      { name: 'RMB', code: '2' },
      { name: 'EUR', code: '3' },
      { name: 'AUD', code: '7' },
      { name: 'CAD', code: '8' },
      { name: 'CHF', code: '9' },
      { name: 'JPY', code: '6' },
      { name: 'HKD', code: '12' },
      { name: 'NZD', code: '13' },
      { name: 'SGD', code: '14' },
      { name: 'NTD', code: '11' },
      { name: 'Other', code: '4' },
    ];

    // Quantity price(수량 가격) 항목
    $scope.ladderPriceList = $scope.row.set_info.key_ladderPrice?.length ? $scope.row.set_info.key_ladderPrice : [{
      MOQ: '',
      FOB_price: '',
    }];

    $scope.addLadderPrice = () => {
      if ($scope.ladderPriceList.length > 3) {
        commonSVC.showToaster('error', '실패', 'FOB 가격은 최대 4개까지 설정이 가능합니다.');
      } else {
        $scope.ladderPriceList.push({
          MOQ: '',
          FOB_price: '',
        });
      }
    };

    $scope.removeLadderPrice = (p0) => {
      $scope.ladderPriceList.splice(p0, 1);
    };

    $scope.productLightCustomList = $scope.row.set_info.key_customizedServices?.length ? $scope.row.set_info.key_customizedServices : [
      {
        used: false,
        key: 'Customized logo',
        value: '',
      },
      {
        used: false,
        key: 'Customized packaging',
        value: '',
      },
      {
        used: false,
        key: 'Graphic customization',
        value: '',
      },
    ];

    $scope.addProductLightCustomList = () => {
      if ($scope.productLightCustomList.length > 7) {
        commonSVC.showToaster('error', '실패', '최대 5개까지 설정 할 수 있습니다.');
      } else {
        $scope.productLightCustomList.push({
          used: false,
          key: '',
          value: '',
        });
      }
    };

    $scope.productLightCustomList.map(data => data.key);

    $scope.removeProductLightCustomList = (p0) => {
      $scope.productLightCustomList.splice(p0, 1);
    };

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      if ($scope.row.set_info.key_unit_type.toString() !== '4') {
        $scope.row.set_info.key_unit_type_value === $scope.unitType.find(type => type.code.toString() === $scope.row.set_info.key_unit_type.toString())?.name || '';
      }

      // FOB 가격 유효성 검사
      const ladder_price_length = $scope.ladderPriceList.length;
      if (ladder_price_length > 1) {
        for (let i = 0; i < ladder_price_length; i++) {
          if (!$scope.ladderPriceList[i].MOQ) {
            commonSVC.showToaster('error', '실패', 'Quantity price항목에 입력되지 않은 MOQ값이 있습니다.');

            return false;
          }

          if (!$scope.ladderPriceList[i].FOB_price) {
            commonSVC.showToaster('error', '실패', 'Quantity price항목에 입력되지 않은 FOB_price 있습니다.');

            return false;
          }

          if ($scope.ladderPriceList[i].FOB_price <= 0) {
            commonSVC.showToaster('error', '실패', 'Quantity price항목의 FOB_price는 0보다 커야합니다.');

            return false;
          }

          if ($scope.ladderPriceList[i].MOQ > $scope.ladderPriceList[i + 1]?.MOQ) {
            commonSVC.showToaster('error', '실패', 'ladderPrice의 MOQ는 나중에 설정한 값이 더 커야합니다.');

            return false;
          }

          if ($scope.ladderPriceList[i].FOB_price < $scope.ladderPriceList[i + 1]?.FOB_price) {
            commonSVC.showToaster('error', '실패', 'Quantity price(수량 가격)의 FOB_price값을 확인해 주시길 바랍니다.');

            return false;
          }
        }
      }

      if ($scope.row.set_info.key_scPrice === '3') {
        if ($scope.row.set_info.key_range_min >= $scope.row.set_info.key_range_max) {
          commonSVC.showToaster('error', '실패', 'FOB금액 설정시 최대금액을 최소금액보다 크게 설정해주세요.');

          return false;
        }
      } else {
        $scope.row.set_info.key_range_min === null;
        $scope.row.set_info.key_range_max === null;
      }

      $scope.row.set_info.key_ladderPrice = $scope.ladderPriceList;

      if ($scope.row.set_info.key_productLightCustom === '1' && $scope.productLightCustomList.filter(list => list.used || list.used === 'true').length === 0) {
        commonSVC.showToaster('error', '실패', 'Customization content를 사용할경우 productLightCustom를 설정해야합니다.');

        return false;
      }

      $scope.row.set_info.key_customizedServices = $scope.productLightCustomList;

      const used_customized_services = $scope.row.set_info.key_customizedServices.filter(list => list.used);
      if ($scope.row.set_info.key_productLightCustom === '2') {
        $scope.row.set_info.key_customizedServices = [];
      }

      if (used_customized_services.length > 5) {
        commonSVC.showToaster('error', '실패', '최대 5개까지 설정 할 수 있습니다.');

        return false;
      }

      if (used_customized_services.length !== [...new Set(used_customized_services.map(custom => custom.key))].length) {
        commonSVC.showToaster('error', '실패', 'Customized Services에 중복되는 항목이 존재합니다.');

        return false;
      }

      let is_setting_customized_services = true;
      used_customized_services.forEach(data => {
        if (!data.key || !data.value) {
          is_setting_customized_services = false;
        }
      });

      if (!is_setting_customized_services) {
        commonSVC.showToaster('error', '실패', '설정한 customizedServices중 입력되지 않은 값이 있습니다.');

        return false;
      }

      let attribute_requried = false;
      let not_entered_item = '';
      if ($scope.row.set_info.key_attribute) {
        Object.entries($scope.row.set_info.key_attribute).forEach(([, value]) => {
          if (value.required === 'true' && !attribute_requried) {
            if (value.type === 'multiCheck') {
              if (!value.value.filter(Boolean).length) {
                attribute_requried = true;
                not_entered_item = value.name;
              }
            } else {
              if (!value.value) {
                attribute_requried = true;
                not_entered_item = value.name;
              }
            }
          }
        });
      }

      if (attribute_requried) {
        commonSVC.showToaster('error', '실패', `상품의 속성 정보 중 필수 항목인 ${not_entered_item}항목이 입력되지 않았습니다.`);

        return false;
      }

      if ($scope.row.set_info.key_attribute) {
        Object.entries($scope.row.set_info.key_attribute).forEach(([key, value]) => {
          if (value.type === 'multiCheck') {
            $scope.row.set_info.key_attribute[key].value = value.value.filter(Boolean);
          }
        });
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function (event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });
