'use strict';

angular.module('gmpApp')
  .service('summarySVC', function (gettextCatalog, commonSVC) {
    /**
     * 2020-03-10 Lucas
     * 통계 서비스
     */

    return {
      /**
       * 엑셀 모달창 필드 리스트
       */
      excelFieldList: {
        total: [
          { header: '기준일자', key: 'curdate', select: true },
          { header: '판매금액', key: 'sales', select: true },
          { header: '판매건수', key: 'ord_cnt', select: true },
          { header: '판매수량', key: 'sale_cnt', select: true },
          { header: '취소금액', key: 'cancel_sales', select: true },
          { header: '취소건수', key: 'cancel_sale_cnt', select: true },
          { header: '취소수량', key: 'cancel_ord_cnt', select: true },
          { header: '반품금액', key: 'return_sales', select: true },
          { header: '반품건수', key: 'return_sale_cnt', select: true },
          { header: '반품수량', key: 'return_ord_cnt', select: true },
          { header: '공급가', key: 'shop_supply_price', select: true },
          { header: '원가', key: 'shop_cost_price', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '배송건수', key: 'ship_cnt', select: true }
        ],
        prod: [
          { header: '기준일자', key: 'curdate', select: true },
          { header: '카테고리명', key: 'sol_cate_name', select: true },
          { header: '상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '판매자 관리코드', key: 'c_sale_cd', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '쇼핑몰(계정)', key: 'shop_nameid', select: true },
          { header: '판매금액', key: 'sales', select: true },
          { header: '판매건수', key: 'ord_cnt', select: true },
          { header: '판매수량', key: 'sale_cnt', select: true },
          { header: '취소금액', key: 'cancel_sales', select: true },
          { header: '취소건수', key: 'cancel_ord_cnt', select: true },
          { header: '취소수량', key: 'cancel_sale_cnt', select: true },
          { header: '반품금액', key: 'return_sales', select: true },
          { header: '반품건수', key: 'return_ord_cnt', select: true },
          { header: '반품수량', key: 'return_sale_cnt', select: true },
          { header: '공급가', key: 'shop_supply_price', select: true },
          { header: '원가', key: 'shop_cost_price', select: true },
        ],
        shop: [
          { header: '기준일자', key: 'curdate', select: true },
          { header: '쇼핑몰(계정)', key: 'shop_nameid', select: true },
          { header: '판매금액', key: 'sales', select: true },
          { header: '판매건수', key: 'ord_cnt', select: true },
          { header: '판매수량', key: 'sale_cnt', select: true },
          { header: '취소금액', key: 'cancel_sales', select: true },
          { header: '취소건수', key: 'cancel_ord_cnt', select: true },
          { header: '취소수량', key: 'cancel_sale_cnt', select: true },
          { header: '반품금액', key: 'return_sales', select: true },
          { header: '반품건수', key: 'return_ord_cnt', select: true },
          { header: '반품수량', key: 'return_sale_cnt', select: true },
          { header: '공급가', key: 'shop_supply_price', select: true },
          { header: '원가', key: 'shop_cost_price', select: true },
        ],
        base: [
          { header: '기준일자', key: 'curdate', select: true },
          { header: 'SKU상품명', key: 'prod_name', select: true },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: '출고수량', key: 'out_cnt', select: true },
          { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
          { header: '쇼핑몰(계정)', key: 'shop_nameid', select: true },
          { header: '판매금액', key: 'sale_price', select: true },
          { header: '판매건수', key: 'ord_cnt', select: true },
          { header: '판매수량', key: 'sale_cnt', select: true },
          { header: '취소금액', key: 'cancel_sales', select: true },
          { header: '취소건수', key: 'cancel_ord_cnt', select: true },
          { header: '취소수량', key: 'cancel_sale_cnt', select: true },
          { header: '반품금액', key: 'return_sales', select: true },
          { header: '반품건수', key: 'return_ord_cnt', select: true },
          { header: '반품수량', key: 'return_sale_cnt', select: true },
          { header: '판매가', key: 'sales', select: true },
          { header: '원가', key: 'cost_price', select: true },
          { header: '공급가', key: 'supply_price', select: true },
          { header: '배송처', key: 'depot_name', select: true },
        ]
      },
      searchData: {
        search_hide: true,
        detailSearchFlag: true,
        all_checked: false,  // 전체선택 여부
        totalCount: 0,       // 검색전체 건수
        selectCount: 0,      // 선택한 건수
        showCount: 100,
        view: 'Y',
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: '__ALL__' },
          { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
          { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
          { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
          { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
          { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
          { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
          { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
          { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
          { label: gettextCatalog.getString('메모'), value: 'memo' },
          { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
        ],
        search_date_type: [
          { label: gettextCatalog.getString('주문일'), value: 'ord_time' }, //wdate
          { label: gettextCatalog.getString('주문수집일'), value: 'wdate' }
        ],
      },
      searchForm: {
        search_key: '__ALL__',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        date_type_sub: {
          summary: 'wdate',
          sales: 'wdate',
          top: 'wdate',
        },
        date_unit: 'day',
        date_unit_sub: {
          shop: '',
          prod: ''
        },
        sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        date_sub: {
          summary: {
            sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
            edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd')
          },
          sales: {
            sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
            edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd')
          },
          top: {
            sdate: commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd'),
            edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd')
          },
        },
        pageFrom: 'summary',
        delivery_vendor: '',
        search_val: '',
        shop_info: []
      },
      chartData: {
        title: {
          position: 'top-center',
          padding: {
            top: 20,
            right: 20,
            bottom: 40,
            left: 50
          }
        },
        size: {
          height: 400
        },
        padding: {
          right: 20,
          bottom: 20
        },
        categoryAxis: {
          type: 'category',
          tick: {
            multiline: true,
            multilineMax: 2,
            width: 120
          }
        },
        transition: {
          duration: 1500
        },
      }
    };
  });
