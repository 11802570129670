/**
 * 포장 형태 추가 / 수정 모달
 */

'use strict';

angular.module('gmpApp')
  .controller('editProdPkgBoxCtrl', function ($rootScope, $scope, $uibModalInstance, settings, $q, $timeout, data, allPkgBoxList, commonSVC, productModel) {
    $scope.type = data.type;
    $scope.allPkgBoxList = allPkgBoxList.data.results;

    let originPkgName = '';

    if ($scope.type === 'add') {
      $scope.pkgBoxData = {
        pkg_name: '',
        max_ea_cnt: ''
      };
    } else {
      $scope.pkgBoxData = angular.copy(data.pkgBoxData);

      originPkgName = data.pkgBoxData.pkg_name;
    }

    $scope.submit = async function () {
      $scope.waitBtnDisableFlag = true;

      if (!$scope.pkgBoxData.max_ea_cnt || !$scope.pkgBoxData.pkg_name) {
        $scope.maxEaCntCheck = !$scope.pkgBoxData.max_ea_cnt;
        $scope.pkgNameCheck = !$scope.pkgBoxData.pkg_name;

        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if ($scope.allPkgBoxList.some(pkg => ($scope.pkgBoxData.pkg_name !== originPkgName) && (pkg.pkg_name === $scope.pkgBoxData.pkg_name))) {
        commonSVC.showMessage('실패', '동일한 포장 형태가 존재합니다.');

        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      const params = {
        pkg_name: $scope.pkgBoxData.pkg_name,
        max_ea_cnt: +$scope.pkgBoxData.max_ea_cnt,
        ...($rootScope.osse_sol && {
          box_width: $scope.box_width || '99999',
          box_height: $scope.box_height || '99999',
          box_depth: $scope.box_depth || '99999',
          max_weight: $scope.max_weight || null
        })
      };

      if ($scope.type === 'edit') {
        commonSVC.showMessageHtml('포장 형태 및 합포장 가능 최대 수량을 수정할 경우 연동된 SKU상품의 값도 변경됩니다. 포장 형태의 값을 수정하시겠습니까?', '', async (isConfirm) => {
          if (!isConfirm) {
            $timeout(function () { $scope.waitBtnDisableFlag = false; });

            return;
          }
          params.pkg_no = $scope.pkgBoxData.pkg_no;
          params.box_no = $scope.pkgBoxData.box_no;

          productModel.editProdPkgBox(params, function (state) {
            if (state === 'success') {
              commonSVC.showToaster('success', '성공', '포장 형태 수정에 성공하였습니다.');

              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '실패', '포장 형태 수정에 실패하였습니다.');

              $timeout(function () { $scope.waitBtnDisableFlag = false; });
            }

          });
        }, { confirmButtonText: '수정', showCancelButton: true });
      } else {
        productModel.addProdPkgBox(params, function (state) {
          if (state === 'success') {
            commonSVC.showToaster('success', '성공', '포장 형태 추가에 성공하였습니다.');

            $uibModalInstance.close('success');
          } else {
            commonSVC.showToaster('error', '실패', '포장 형태 추가에 실패하였습니다.');

            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          }
        });
      }

    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
