/*
2017-12-13 Pada 쇼핑몰 상품 등록 이미지
기본이미지 -> 쇼핑몰별 개별 이미지 적용 모달 컨
*/

'use strict';
angular.module('gmpApp').controller('olImgListModal', function($scope, data,
  $uibModalInstance
) {
  function init () {
    $scope.img_data = {};
    $scope.olImgObjects = data.file;
  }

  init();
  /**
   * Data 선택
   */
  let imgs = [];
  let select = false;

  $scope.selectImg = function (v, e) {
    const target = $(e.currentTarget); // 클릭한 대상

    if (!select) {
      if (!_.isUndefined(data.name)) {
        // 하나만 클릭 되도록
        if ($('div .selected').length < 1) {
          target.css('border', '1px solid red');
          target.addClass('selected');
          imgs.push(v);
        }
      } else {
        // 등록
        target.css('border', '1px solid red');
        imgs.push(v);
      }
    } else {
      const index = _.findIndex(imgs, v);

      if (index > -1) {
        imgs.splice(index, 1); // 중복값 제거
      }
      target.css('border', 'none');
      target.removeClass('selected');
    }

    imgs = _.uniq(imgs); // SET 처리
    imgs = _.sortBy(imgs, [function(o) { // 배열 순서 정리
      return o.name;
    }]);
    select = !select;
    $scope.img_data = imgs;
  };

  $scope.ok = function () {
    let result = []; // 내려보낼 결과변수
    // # 선택한 파일을 정리해서 내보내야한다.
    // ## 선택한 파일 기본이미지, 추가이미지1, 추가이미지2 ...
    // ### (단일선택 + 다중선택)(등록 + 수정) = 단일*등록 + 단일*수정 + 다중*등록 + 다중*수정
    // #### 분기 처리
    const isMulti = !!_.isUndefined(data.name); // 등록인지 수정인지 판단하는 변수

    if ($scope.img_data.length > 1 && isMulti) { // 다중*등록
      _.forEach($scope.img_data, function(v) {
        result.push(v);
      });
    } else if ($scope.img_data.length < 2 && isMulti) { // 단일*등록
      result = $scope.img_data;
      //# 단일 추가등록
      //## 분기처리
      if (data.bFile[0].files) {
        let index = 0;

        _.forEach(data.bFile, function(v) {
          if (v.files) {
            index++;
          }
        });
        data.bFile[index].files = result[0].files;
        result = data.bFile;
      }
    } else if ($scope.img_data.length < 2 && !isMulti) { // 단일*수정
      result = $scope.img_data;
    } else { // 다중*수정은 존재하지 않음

    }
    $uibModalInstance.close(result);
  };
  $scope.cancle = function () {
    $uibModalInstance.close();
  };
});
