'use strict';

angular.module('gmpApp')
  .controller('AltalkAutoSendRegisterCtrl', function (
    $scope, $uibModalInstance, data, commonSVC, workSVC, userInfo, $timeout, atalkModel,
    $rootScope, settings) {

    function init () {
      $timeout(async () => {
        $scope.HOUR = Array.from({ length: 12 }, (_, index) => index + 1);
        $scope.MIN = Array.from({ length: 60 }, (_, index) => index).filter(v => v % 10 === 0).map(num => `${num === 0 ? '00' : num}`);
        $scope.altalk = {
          send_time: 'immediately',
          ampm: '0',
          sms_send_yn: '0',
          selectedHour: '',
          selectedMin: '',
          send_type: 'scrap_order',
          altalk_send_date: moment().format('YYYY-MM-DD'),
          send_type_bundle_info: true
        };
        $scope.reqCheck = false;
        $scope.template = {};
        $scope.data = angular.copy(data);
        if ($scope.data.plusIdList.length) {
          $scope.data.plusIdList = $scope.data.plusIdList.map(account => ({ ...account, name: `@${account.name}` }));
          $scope.plusIdCheck = true;
          $scope.template.plus_friend_id = $scope.data.plusIdList[0].id;
        } else {
          $scope.plusIdCheck = false;
        }

        $scope.template_tab = 0;
        $scope.isTemplateListOpen = false;

        $timeout(() => {});
      });
    }

    init();

    /**
     * 수정 버튼 활성화 처리
     */
    $scope.reqCheckF = () => {
      if ($scope.template && $scope.altalk.reg_name && $scope.selectTemplate && (($scope.altalk.send_type === 'scrap_order') || ($scope.altalk.send_type === 'order_status' && $scope.altalk.order_status)) && (($scope.altalk.send_time === 'scheduled' && $scope.altalk.selectedHour && $scope.altalk.selectedMin) || $scope.altalk.send_time === 'immediately')) {
        $scope.reqCheck = true;
      } else {
        $scope.reqCheck = false;
      }
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };

    /**
     * 템플릿 검색 함수
     */
    $scope.searchAltalkTemplate = async (templateTitle) => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.template.plus_friend_id.toString(), search_key: 'template_name', search_word: templateTitle, status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.templateList = (templateList.data?.results).filter(r => r.type !== 'G'); // 개인 템플릿
      $scope.basicTemplateList = (templateList.data?.results).filter(r => r.type === 'G');  // 제공 템플릿

      $timeout(() => {});
    };

    /**
     * 템플릿 선택 함수
     */
    $scope.clickTemplate = async () => {
      const templateList = await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateList`, { talk_id: $scope.template.plus_friend_id.toString(), status: true, orderby: 'a.created_at desc', sol_no: $rootScope.user_profile.sol_no, exposure_yn: true });
      $scope.basicTemplateList = (templateList.data?.results)?.filter(r => r.type === 'G');  // 제공 템플릿
      $scope.templateList = (templateList.data?.results)?.filter(r => r.type !== 'G');   // 개인 템플릿

      $scope.isTemplateListOpen = !$scope.isTemplateListOpen;

      $timeout(() => {});
    };

    $scope.setTemplate = async (row) => {
      $scope.isTemplateListOpen = false;

      $scope.isTemplateListOpen = false;
      const template = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/templateDetail`, { template_no: row.id })).data?.result;
      $scope.selectTemplate = template;
      document.getElementById('preview_add_box').innerHTML = '';

      // 부가정보 여부
      if ($scope.selectTemplate.template_extra && $scope.selectTemplate.template_extra !== 'null') {
        document.getElementById('preview_add_box').innerHTML = $scope.selectTemplate.template_extra?.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');
      }

      // 불러온 템플릿에 버튼이 있는 경우
      if ($scope.selectTemplate.buttons) {
        $scope.selectTemplate.buttons = JSON.parse(`[${$scope.selectTemplate.buttons}]`);

        // 템플릿 내 채널추가 버튼 여부
        $scope.use_add_channel_yn = $scope.selectTemplate.buttons.some(btn => btn.type === 'AC');
      }

      document.getElementById('preview_box').innerHTML = $scope.selectTemplate.content.split(/\r\n|\r|\n/).map(c => c.length > 0 ? `<p>${c}</p>` : '<br>').join('');

      if ($scope.selectTemplate.template_img_name && $scope.selectTemplate.template_emphasize_type === 'IMAGE') {
        const preview = document.getElementById('emphasis_img');
        const currentStyle = preview.getAttribute('style') || '';
        preview.setAttribute('style', `${currentStyle} background-image: url('${$scope.selectTemplate.template_img_name}'); background-size: 100%;`);
      }

      $scope.reqCheckF();

      $timeout(() => {});
    };
    /**
     * 알림톡 충전하기 버튼 함수
     */
    $scope.chargeAtalkCnt = function() {
      if (!(userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.pay?.use_yn)) {
        return commonSVC.showToaster('error', '앗! 사용 권한이 없네요.', '요청하신 기능은 사용 권한이 필요합니다.\n PLAYAUTO 2.0 관리자에게 문의해보세요!');
      }
      $rootScope.openPayReq();
      $uibModalInstance.dismiss('cancel');
    };

    $scope.addTemplate = () => {
      commonSVC.openModal('x3g', { data: { plusIdList: $scope.data.plusIdList } }, 'AltalkTemplateCtrl', 'views/alimtalk/modals/altalk_template_add.html');
    };

    /**
     * 알림톡 발신프로필 연동 모달로 이동
     */
    $scope.addProfile = () => {
      const modal = commonSVC.openModal('lg', {}, 'AltalkLinkCtrl', 'views/alimtalk/modals/link.html');

      modal.result.then(async (re) => {
        if (re === 'success') {
          $scope.data.plusIdList = (await atalkModel.accountList({ name: $rootScope.user_profile.sol_no })).data?.result;
          $timeout(() => {});
        }
      });
    };

    $scope.onSubmit = (type) => {
      if (type === 'test') {
        $scope.altalk.send_time = 'immediately';
      }
      const selectHour = $scope.altalk.ampm === '1' ? (parseInt($scope.altalk.selectedHour) !== 12 ? parseInt($scope.altalk.selectedHour) + 12 : parseInt($scope.altalk.selectedHour)) : (parseInt($scope.altalk.selectedHour) === 12 ? parseInt($scope.altalk.selectedHour) + 12 : $scope.altalk.selectedHour);
      const resolve = { data: { type, template: $scope.selectTemplate, altalk: $scope.altalk } };
      if (type !== 'test') {
        $scope.altalk.book_send_time = `${selectHour}:${$scope.altalk.selectedMin}:00`;
        const sendInfoMsg = `${$scope.altalk.send_type === 'order_status' ? $scope.altalk.order_status : '신규주문 수집 시'} ${$scope.altalk.send_time === 'scheduled' ? `${$scope.altalk.book_send_time.toString()}에` : '즉시'} ${$scope.selectTemplate.name} 발송됩니다.`;
        resolve.data.sendInfoMsg = sendInfoMsg;
      }

      const modal = commonSVC.openModal('lg', resolve, 'AltalkAutoSendRegisterModalCtrl', 'views/alimtalk/modals/altalk_auto_register.html');

      if (type !== 'test') {
        modal.result.then((re) => {
          if (re === 'success') {
            $uibModalInstance.close('success');
          }
        });
      }
    };

  });