/**
 * Created by ally on 2017. 2. 9..
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderCustomerInquiryAnswerTempListCtrl', function ($scope, $uibModalInstance, $compile, commonSVC, settings, customerInquiryModel, csSVC, gettextCatalog, $location, $anchorScroll, $timeout) {

    $scope.searchData = {};
    $scope.searchForm = { inq_type: '' };
    $scope.templateType = angular.copy(csSVC.templateType);
    $scope.templateFormType = angular.copy(csSVC.templateType);
    $scope.templateFormType[0].label = gettextCatalog.getString('선택');

    const templateForm = {
      template_no: '',
      inq_type: '',
      template_name: '',
      template_data: ''
    };

    $scope.templateData = angular.copy(templateForm);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['inq_type', 'mdate'],
      alignRightColumns: [],
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['template_name'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/inquiries/answerTemplate/list`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          return result.results;
        }
      },
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      columns: [
        {
          key: 'inq_type',
          width: 150,
          title: '구분'
        },
        {
          key: 'template_name',
          width: 539,
          title: '제목'
        },
        {
          key: 'mdate',
          width: 150,
          title: '수정일',
          filter: 'dateValid'
        }
      ]
    };

    /********* 함수 목록 *********/

    /**
     * 템플릿 추가
     * */
    $scope.addTemplate = function () {
      $scope.resetDo();
      goToForm();
    };

    /**
     * 검색
     * */
    $scope.searchDo = function(refresh = true, noDelay) {
      $scope.grid.methods.reloadData(function () {}, refresh, noDelay);
    };

    /**
     * 하단 Form 초기화
     * */
    $scope.resetDo = function() {
      $scope.templateData = angular.copy(templateForm);
    };

    /**
     * 취소
     */
    $scope.close = function() { // 취소시 상세보기에서 템플릿 리스트 불렀을때 다시 액션을 주기위해 close로 함
      $uibModalInstance.close();
    };

    $scope.saveDo = function() {
      const msg = $scope.templateData.template_no ? '수정' : '등록';
      const saveAction = $scope.templateData.template_no ? customerInquiryModel.editAnswerTemplate : customerInquiryModel.addAnswerTemplate;

      commonSVC.showConfirm(`${msg}하시겠습니까?`, '', function() {
        saveAction($scope.templateData).then(function (data) {
          if (data.data === 'success') {
            commonSVC.showToaster('success', '성공', `템플릿 ${msg}에 성공하였습니다.`);
          } else {
            throw new Error(`템플릿 ${msg}에 실패하였습니다.`);
          }
          $scope.searchDo();
          $scope.resetDo();
          goToTop();
        }).catch(function (error) {
          commonSVC.showToaster('error', '실패', error.message);
        });
      });
    };

    $scope.deleteDo = function() {
      const number = $scope.templateData.template_no;

      if (!number) {
        commonSVC.showMessage('삭제할 템플릿을 선택해주세요.');

        return false;
      } else {
        commonSVC.showConfirm('삭제하시겠습니까?', '', function() {
          customerInquiryModel.delAnswerTemplate({ template_no: number }).then(function (data) {
            if (data.data === 'success') {
              commonSVC.showToaster('success', '성공', '템플릿 삭제에 성공하였습니다.');
            } else {
              throw new Error('템플릿 삭제에 실패하였습니다.');
            }
            $scope.searchDo();
            $scope.resetDo();
          }).catch(function (error) {
            commonSVC.showToaster('error', '실패', error.message);
          });
        });
      }
    };

    /**
     * row 선택시
     */
    $scope.$on('OnSelectChangeBefore', (event, data) => {
      $scope.templateData = {
        template_no: data.selectData.template_no,
        inq_type: data.selectData.inq_type,
        template_name: data.selectData.template_name,
        template_data: data.selectData.template_data
      };

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
      goToForm();
    });

    /**
     * 하단 폼으로 스크롤 이동
     * */
    function goToForm() {
      $location.hash('ansTempForm');
      $anchorScroll();
    }

    /**
     * 최상단으로 스크롤 이동
     * */
    function goToTop() {
      $location.hash('ansTempTop');
      $anchorScroll();
    }

  });
