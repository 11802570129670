'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A012-Ctrl', function ($scope, $rootScope, $compile, categoryModel, templateModel, $stateParams, A012categorySVC,
    siteInfo, commonSVC, errorSVC, commonModel, shopAccountModel, $timeout) {

    $scope.cert_type = angular.copy(A012categorySVC.cert_type);
    $scope.category_code = '';
    $scope.cateList = [];
    $scope.require_check = {};
    $scope.display_check = {};
    $scope.check = {};
    $scope.CateData = angular.copy(A012categorySVC.category_data);
    $scope.CateData.detailData = {};

    let shop_pw = '';
    let save_flag1 = '';  //아이템형 전시카테고리 플래그
    let save_flag2 = '';  //브랜드형 전시카테고리 플래그
    let selcetrowFlag = ''; //카테고리 변경 플래그
    const cateNmArr = ['DispLrgNm', 'DispMidNm', 'DispSmlNm', 'DispThnNm', 'DispTthnNm'];

    init();

    //초기화
    function init() {
      //쇼핑몰패스워드 가져오기
      shopAccountModel.load({ shop_cd: 'A012', shop_id: $scope.matchingData.site_id })
        .then(function(re) {
          shop_pw = re.data.results.shop_pwd;

          /**
           * 2018-03-09 roy
           * 온라인 상품 수정시 수정 되었던 데이터 있는 경우 해당 데이터로 로드
           */

          // 상품페이지
          if ($scope.matchingData.shop_cate_no_add_info) {
            if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
              matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info, true));
            } else {
              matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info, true);
            }
          } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
            matchDetailDataLoad(angular.copy(A012categorySVC.category_data));
          } else {
            matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info, true);
          }
        })
        .catch(function() {
          commonSVC.showMessage('데이터 로드 실패');
        });

    }

    const matchDetailDataLoad = (detailData, init) => {
      $scope.CateData.detailData = detailData;

      const userInfo = {
        shop_id: $scope.matchingData.site_id,
        shop_pwd: shop_pw,
      };

      commonSVC.requestPA(userInfo, siteInfo.A012.domain, 'get_md', '', commonModel, shopAccountModel)
        .then(function(res) {
          if (res.data.data.status == 200) {
            $scope.mdName = res.data.data.items;

            if (!init) {
              $('#spinner').addClass('hidden');

              return false;
            } else {
              //md select
              $scope.mdLselect(init).then($scope.mdMselect(true));
            }
          }
        })
        .then(() => {
          if (init) {
            $timeout(function() {
              $scope.cateSelect();
              //전시 카테고리 등록
              $scope.cateRegist('init');
            }, 100);
          }
        });
    };

    //md명 셀렉트
    $scope.mdLselect = function(init) {

      if ($scope.CateData.detailData.key_mdCode != '') {
        $('#spinner').removeClass('hidden');

        const userInfo = {
          shop_id: $scope.matchingData.site_id,
          shop_pwd: shop_pw,
        };

        const addData = {
          md_id: $scope.CateData.detailData.key_mdCode, //MD ID
          type: '' // MD상품군일 경우 빈값, 상품군 하위데이터일경우 sub 입력
        };

        // 펼치기 해서 열때 셀렉트를 하는데 세번째값 초기화 시켜서 조건 추가.
        if (!init) {
          $scope.CateData.detailData.SuperGroupName = null;
          $scope.CateData.detailData.key_MDCode_prod = null;
        }

        return commonSVC.requestPA(userInfo, siteInfo.A012.domain, 'get_md_prod', addData, commonModel, shopAccountModel)
          .then(function(res) {
            if (res.data.data.status == 200) {
              $scope.GroupName = res.data.data.items;
            }

            $('#spinner').addClass('hidden');
          });

      } else {
        return false;
      }
    };

    //md 상품군 셀렉트
    $scope.mdMselect = (init) => {
      if ($scope.CateData.detailData.test != '') {
        $('#spinner').removeClass('hidden');

        const userInfo = {
          shop_id: $scope.matchingData.site_id,
          shop_pwd: shop_pw,
        };

        const addData = {
          md_id: $scope.CateData.detailData.key_mdCode, //MD ID
          type: 'sub', // MD상품군일 경우 빈값, 상품군 하위데이터일경우 sub 입력
          SuperGroupName: $scope.CateData.detailData.SuperGroupName //MD상품군 명칭
        };

        if (!init) {
          $scope.CateData.detailData.key_MDCode_prod = null;
        }

        return commonSVC.requestPA(userInfo, siteInfo.A012.domain, 'get_md_prod', addData, commonModel, shopAccountModel)
          .then(function(res) {
            if (res.data.data.status == 200) {
              $scope.md_gsgr_no = res.data.data.items;
            }
            $('#spinner').addClass('hidden');
          });
      } else {
        return false;
      }
    };

    $scope.test = function() {

      const userInfo = {
        shop_id: $scope.matchingData.site_id,
        shop_pwd: shop_pw,
      };

      const addData = {
        md_id: $scope.CateData.detailData.key_mdCode, //MD ID
        type: 'sub', // MD상품군일 경우 빈값, 상품군 하위데이터일경우 sub 입력
        SuperGroupName: $scope.CateData.detailData.SuperGroupName //MD상품군 명칭
      };

      commonSVC.requestPA(userInfo, siteInfo.A012.domain, 'get_md', addData, commonModel, shopAccountModel)
        .then(function(res) {
          if (res.data.data.status == 200) {
            angular.forEach(res.data.data.items, function(value) {
              if (value.MDCode == $scope.CateData.detailData.key_mdCode) {
                $scope.CateData.detailData.key_mrgn_rt = value.NormalSellFee;
              }
            });
          }
          $('#spinner').addClass('hidden');
        });
    };

    //전시카테고리 조회
    $scope.cateSelect = function() {

      $scope.test();
      const userInfo = {
        shop_id: $scope.matchingData.site_id,
        shop_pwd: shop_pw,
      };

      const addData = {
        md_gsgr_no: $scope.CateData.detailData.key_MDCode_prod // MD 상품군 번호
      };

      commonSVC.requestPA(userInfo, siteInfo.A012.domain, 'get_cate', addData, commonModel, shopAccountModel)
        .then(function(res) {
          if (res.data.data.status == 200) {
          // 전시카테고리 노출 순서 변경 ( 아이템형이 10, 12번을 사용하고 브랜드형이 전부 사용하므로 인덱스 순서를 맞추기위해서는 10, 12, 11 로 노출되어야 함 )
          // 기존 : 10 > 11 > 12
          // 변경 : 10 > 12 > 11
          // $scope.cateList = res.data.data.items;
            $scope.cateList = [];
            const loopArr = ['10', '12', '11'];

            for (let idx = 0; idx < loopArr.length; idx++) {
              _.forEach(res.data.data.items, function(o) {
                if (o.disp_tp_cd == loopArr[idx]) {
                  $scope.cateList.push(o);
                }
              });
            }

            // var displayList = [];
            angular.forEach($scope.cateList, function(value, key) {
            //아이템형 전시 카테고리
              if ((value.disp_tp_cd == '10' || value.disp_tp_cd == '12') && value.DispNo == $scope.CateData.detailData.key_cv0) {
                $scope.require_check[key] = true;
              }

              // 브랜드형 전시카테고리가 $scope.cateList 와 같으므로 그냥 $scope.cateList 쓰면 됨.
              // 브랜드형 전시 카테고리 리스트 생성
              // if(value.disp_tp_cd == "10" || value.disp_tp_cd == "11" || value.disp_tp_cd =="12"){
              //   displayList.push(value);
              // }
              //브랜드형 전시 카테고리
              if (value.DispNo == $scope.CateData.detailData.key_cv1 || value.DispNo == $scope.CateData.detailData.key_cv2 || value.DispNo == $scope.CateData.detailData.key_cv3 || value.DispNo == $scope.CateData.detailData.key_cv4 || value.DispNo == $scope.CateData.detailData.key_cv5) {
                $timeout(function() {
                //체크박스 체크
                  $scope.display_check[key] = true;
                }, 200);
              }
            });

            // angular.forEach(displayList, function(value, key) {
            //   //브랜드형 전시 카테고리
            //   if(value.DispNo == $scope.CateData.detailData.key_cv1 || value.DispNo == $scope.CateData.detailData.key_cv2 || value.DispNo == $scope.CateData.detailData.key_cv3 || value.DispNo == $scope.CateData.detailData.key_cv4 || value.DispNo == $scope.CateData.detailData.key_cv5){
            //     $timeout(function() {
            //       //체크박스 체크
            //       $scope.display_check[key] = true;
            //     }, 200);
            //   }
            // });
          }

          $('#spinner').addClass('hidden');
        });
    };

    //표준, 전시카테고리 선택
    $scope.selcetrow = function(type, index) {
      selcetrowFlag = true;   //카테고리변경 플래그
      if (type == 'require') {
        $scope.require_check = {};
        $scope.require_check[index] = true;
      } else if (type == 'display') {
        if ($(`#display_check_${index}`).prop('checked') == false) {
          $(`#display_check_${index}`).prop('checked', true);
          $scope.display_check[index] = true;
        } else {
          $scope.display_check[index] = false;
          $(`#display_check_${index}`).prop('checked', false);
          $scope.display_check[index] = false;
        }
      }
    };

    //카테고리 선택 변경시
    $scope.cateChange = function() {
      selcetrowFlag = true;   //카테고리변경 플래그
    };

    //전시카테고리 등록
    $scope.cateRegist = function(type) {
      const test = [];

      if (type == 'init') {
        //선택한 카테고리 보여주기
        $scope.cateviewFlag = true;
        selcetrowFlag = false;

        angular.forEach($scope.require_check, function (k, v) {
          if (k == true) {
            $scope.CateData.detailData.key_ct0 = $scope.cateList[v].ShopPos; //라디오
            $scope.CateData.detailData.radio = $scope.cateList[v].DispNo; //라디오
            save_flag1 = true;
          }
        });

        const cateList = [];

        angular.forEach($scope.display_check, function (k, v) {
          if (k == true) {
            angular.forEach($scope.cateList, function(value, key) {
              // if(value.disp_tp_cd == "10" || value.disp_tp_cd == "11" || value.disp_tp_cd == "12"){
              test.push($scope.cateList[key]);
              // }
            });
            cateList.push($scope.cateList[v].ShopPos);
          }
        });

        if (cateList.length > 5) {
          alert('전시카테고리는 5개까지만 등록 가능합니다.');

          return false;
        } else {
          $scope.CateData.detailData.select = [];

          let count = 1;

          angular.forEach($scope.display_check, function (k, v) {
            if (k == true) {
              $scope.CateData.detailData[`key_ct${count}`] = test.ShopPos; //셀렉트
              $scope.CateData.detailData.select.push(test[v].DispNo); //셀렉트
              count++;
              save_flag2 = true;
            }
          });
        }
      } else {
        if (!Object.keys($scope.require_check).length) {
          commonSVC.showToaster('error', '실패', '아이템형 전시 카테고리를 선택해주세요');

          return false;
        }

        //선택한 카테고리 보여주기
        $scope.cateviewFlag = true;
        selcetrowFlag = false;

        angular.forEach($scope.require_check, function (k, v) {
          if (k == true) {
            // $scope.CateData.detailData.key_ct0 = $scope.cateList[v].ShopPos; //라디오
            $scope.CateData.detailData.key_ct0 = cateNmArr.map((d) => $scope.cateList[v][d]).filter(n => n).join(' > ');
            $scope.CateData.detailData.key_cv0 = $scope.cateList[v].DispNo;
            $scope.CateData.detailData.radio = $scope.cateList[v].DispNo; //라디오
            save_flag1 = true;
          }
        });

        const cateList = [];

        angular.forEach($scope.display_check, function (k, v) {
          if (k == true) {
            angular.forEach($scope.cateList, function(value, key) {
              // if(value.disp_tp_cd == "10" || value.disp_tp_cd == "11" || value.disp_tp_cd == "12"){
              test.push($scope.cateList[key]);
              // }
            });
            cateList.push($scope.cateList[v].ShopPos);
          }
        });

        if (cateList.length > 5) {
          alert('전시카테고리는 5개까지만 등록 가능합니다.')
          ;

          return false;
        } else {
          $scope.CateData.detailData.key_cv1 = '';
          $scope.CateData.detailData.key_cv2 = '';
          $scope.CateData.detailData.key_cv3 = '';
          $scope.CateData.detailData.key_cv4 = '';
          $scope.CateData.detailData.key_cv5 = '';
          $scope.CateData.detailData.select = [];

          $scope.CateData.detailData.key_ct1 = '';
          $scope.CateData.detailData.key_ct2 = '';
          $scope.CateData.detailData.key_ct3 = '';
          $scope.CateData.detailData.key_ct4 = '';
          $scope.CateData.detailData.key_ct5 = '';

          let count = 1;

          angular.forEach($scope.display_check, function (k, v) {
            if (k == true) {
              $scope.CateData.detailData[`key_ct${count}`] = cateNmArr.map((d) => test[v][d]).filter(n => n).join(' > ');
              $scope.CateData.detailData.select.push(test[v].DispNo); //셀렉트
              $scope.CateData.detailData[`key_cv${count}`] = test[v].DispNo; //셀렉트
              count++;
              save_flag2 = true;
            }
          });
        }
      }
    };

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function (event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      if (selcetrowFlag == true) {
        commonSVC.showMessage('실패', '전시카테고리가 변경되었습니다. 전시카테고리 등록을 해주세요.');

        return false;
      }

      if (save_flag1 != true) {
        commonSVC.showMessage('실패', '전시카테고리를 등록해주세요');

        return false;
      }
      if ($scope.CateData.detailData.key_ct0 == '') {
        commonSVC.showMessage('실패', '전시카테고리를 등록해주세요');

        return false;
      }
      //key_mdName 세팅
      angular.forEach($scope.mdName, function (k) {
        if (k.MDCode == $scope.CateData.detailData.key_mdCode) {
          $scope.CateData.detailData.key_mdName = k.MDName;
        }
      });
      //key_select_MD 세팅
      $scope.CateData.detailData.key_select_MD = $scope.CateData.detailData.key_mdCode;
      $scope.CateData.detailData.category_names = $scope.CateData.detailData.key_ct0;

      //전시카테고리
      $scope.CateData.detailData.disp = [];

      angular.forEach($scope.CateData.detailData, function (k, v) {
        if (v.indexOf('key_ct') != -1) {
          if (v == 'key_ct1' && $scope.CateData.detailData.key_ct1 != '') {
            $scope.CateData.detailData.disp.push({
              name: $scope.CateData.detailData.key_ct1,
              code: $scope.CateData.detailData.select[0]
            });
          } else if (v == 'key_ct2' && $scope.CateData.detailData.key_ct2 != '') {
            $scope.CateData.detailData.disp.push({
              name: $scope.CateData.detailData.key_ct2,
              code: $scope.CateData.detailData.select[1]
            });
          } else if (v == 'key_ct3' && $scope.CateData.detailData.key_ct3 != '') {
            $scope.CateData.detailData.disp.push({
              name: $scope.CateData.detailData.key_ct3,
              code: $scope.CateData.detailData.select[2]
            });
          } else if (v == 'key_ct4' && $scope.CateData.detailData.key_ct4 != '') {
            $scope.CateData.detailData.disp.push({
              name: $scope.CateData.detailData.key_ct4,
              code: $scope.CateData.detailData.select[3]
            });
          } else if (v == 'key_ct5' && $scope.CateData.detailData.key_ct5 != '') {
            $scope.CateData.detailData.disp.push({
              name: $scope.CateData.detailData.key_ct5,
              code: $scope.CateData.detailData.select[4]
            });
          }
        }
      });

      if ($scope.form1.$valid) {

        const param = {
          site_code: 'A012',
          site_id: $scope.matchingData.site_id,
          category_code: $scope.CateData.detailData.radio,
          category_codes: 'test',
          category_names: $scope.CateData.detailData.key_ct0,
          categoryId: $scope.matchingData.categoryId,
          detailData: { ...$scope.CateData.detailData },
          set_all_shop: $scope.check.set_all_shop,
          country: 'kr',    //국내/해외
          type: 0
        };

        /**
         * 2018-03-09 Roy
         * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
         */
        if (event && event.type === 'online') {
          $scope.returnAct(param);
        } else if ($scope.matchingData.isOnlineAdd === true) {
          await $timeout(async () => {
            try {
              const res = await categoryModel.shopInfoDetailEdit(param);

              $scope.loading = true;
              commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

              param.shop_cate_no = res.data.shop_cate_no;

              $scope.returnAct(param);

            } catch (err) {
              $scope.loading = true;

              commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
            }
          });
        } else {
          $timeout(() => {
            param.isApply = true;

            $scope.applyCate(param);
          });
        }
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    $scope.filterFunction = function(cds) {
      return function(item) {
        return _.includes(cds, item.disp_tp_cd);
      };
    };

  });
