'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-P028-Ctrl', function ($scope, $rootScope, data, systemList, templateList, $timeout, $uibModalInstance, shopAccountModel, onlineProductModel, commonModel, commonSVC) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.cate_cd = data.data.cate_cd.replace(/ /gi, '');
    $scope.filterDatas = { // 속성 필터 리스트
      site: [],
      uda: [],
      customer: []
    };
    $scope.selFilter = angular.copy($scope.filterDatas); // 선택, 입력한 속성 필터 리스트 (사이트 속성은 속성명만 저장, 속성값은 필터 리스트에 저장)

    // disabled된 항목 있는지 여부
    let hasDisabled = false;

    $timeout(() => {
      hasDisabled = !!$('div[ng-include] input[ng-model][ng-disabled], div[ng-include] select[ng-model][ng-disabled], div[ng-include] button[ng-model][ng-disabled]')
        .filter((idx, selector) => $(selector).attr('disabled') === 'disabled').length;
    }, 500);

    $scope.pa5_job_data = {
      get_site_properties: {
        load: false,
        job_name: '사이트 속성',
        data: []
      },
      get_uda_properties: {
        load: false,
        job_name: 'UDA 속성',
        data: []
      },
      get_customer_properties: {
        load: false,
        job_name: '고객 관점 속성',
        data: []
      }
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, type, temp_saver) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = temp_saver ? temp_saver(re.data.results[0].data) : re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;
          // 선택되어 있는 속성 체크 처리
          if (type === 'customer') {
            $scope.selFilter[type].forEach((data) => {
              $scope.row.set_info.key_customerProperty?.forEach(value => {
                if (data.arrItemAttrId === value.arrItemAttrId) {
                  data.arrItemAttrVal = value.arrItemAttrVal;
                  if (data.hasOwnProperty('arrItemAttrItmId')) {
                    data.arrItemAttrItmId = value.arrItemAttrItmId;
                  }
                }
              });
            });
          } else {
            $scope.selFilter[type].forEach((data) => {
              $scope.row.set_info.key_siteUdaProperty?.forEach(value => {
                if (data.arrItemAttrId === value.arrItemAttrId) {
                  data.arrItemAttrVal = value.arrItemAttrVal;
                }
              });
            });
          }

          $timeout(() => {});
        } else {
          $('.box-loading').addClass('hidden'); //로딩중
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    /**
     * 이미지파일 원본보기 모달.
     * @param {이미지파일} img
     */
    $scope.imgModal = function (imgUrl) {
      const img = {
        code: 'set_info_img',
        files: imgUrl,
        name: '추가항목이미지',
      };
      const resolve = {
        data: {
          file: img
        }
      };
      commonSVC.openModal('lg', resolve, 'olImgModal', 'views/online/product/olProdImg.html');
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, name) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              $scope.row.set_info[name] = v.url;
            }
          });
        });
    };

    $scope.delShopImg = function (name) {
      $scope.row.set_info[name] = '';
    };

    $scope.prodInfoDesc = (type) => {
      const flag = type + '_flag';
      if ($scope.row.set_info[flag] === 'ref') {
        $scope.row.set_info[type] = '[상세설명참조]';
      } else {
        $scope.row.set_info[type] = '';
      }
    };

    const init = async () => {
      try {
        for (const key of Object.keys($scope.pa5_job_data)) {

          const searchKey = key.split('_')[1];

          // 어드민에서 복수 접속 허용하지 않아 순차적으로 조회해야 됨
          await run_pa5_jobs(key, $scope.pa5_job_data[key].job_name, { ...(['uda', 'customer'].includes(searchKey) && { id: $scope.cate_cd }) }, searchKey, (data) => {
            if (searchKey === 'uda') {
              $scope.filterDatas[searchKey] = _.groupBy(data, `${searchKey}_attribute_name`);
              $scope.selFilter[searchKey] = Object.keys($scope.filterDatas[searchKey]).map(k => ({
                arrItemAttrId: $scope.filterDatas[searchKey][k][0][`${searchKey}_attribute_id`],
                arrInputSpCd: '2',
                arrItemAttrVal: ''
              }));

            } else if (searchKey === 'site') {
              $scope.row.set_info.key_siteUdaProperty?.forEach(value => {
                data.forEach(item => {
                  if (item.site_attribute_id === value.arrItemAttrId && item.site_item_id === value.arrItemAttrVal) {
                    if (!$scope.selFilter.site.includes(item.site_attribute_name)) {
                      $scope.selFilter.site.push(item.site_attribute_name);
                    }
                    item.checked = true;
                  }
                });
              });
              $scope.filterDatas[searchKey] = _.groupBy(data, `${searchKey}_attribute_name`);
            } else {
              $scope.filterDatas[searchKey] = _.groupBy(data, `${searchKey}_item_name`);
              $scope.selFilter[searchKey] = Object.keys($scope.filterDatas[searchKey]).map(k => ({
                arrItemAttrId: $scope.filterDatas[searchKey][k][0][`${searchKey}_item_id`],
                arrInputSpCd: $scope.filterDatas[searchKey][k].length > 1 ? '1' : '2', // length가 있을 경우 selecbox, 아닐 경우 textbox
                arrItemAttrVal: $scope.filterDatas[searchKey][k].length > 1 ? 'Y' : '',
                ...($scope.filterDatas[searchKey][k].length > 1 && { arrItemAttrItmId: `${$scope.filterDatas[searchKey][k][0][`${searchKey}_item_id`]}@@00` }),
                ...($scope.filterDatas[searchKey][k][0].customer_parent_attribute_name && {
                  arrItemParentName: $scope.filterDatas[searchKey][k][0].customer_parent_attribute_name,
                  arrItemParentId: $scope.filterDatas[searchKey][k][0].customer_parent_attribute_id
                })
              }));
            }

            return data;
          });
        }

        if (!$scope.selFilter.site.length) {
          $scope.selFilter.site = [''];
        }

        $('.box-loading').addClass('hidden'); //로딩중

      } catch (e) {
        $('.box-loading').addClass('hidden'); //로딩중

        commonSVC.showMessage('실패', '속성 정보 로드를 실패했습니다.');
      }

    };

    init();

    // 부모 속성 여부에 따라 필드 활성화 처리
    $scope.disabledCheck = (row, key) => {
      const parentName = row.customer_parent_attribute_name;

      if (!parentName) {
        return false;
      }
      const parentItem = $scope.filterDatas[key][parentName][0];

      return !$scope.selFilter[key].some(selected => selected.arrItemAttrItmId === `${parentItem.customer_item_id}@@${row.customer_parent_attribute_id}`);
    };

    // 리스트 추가
    $scope.clickEvent = (index, type, form) => {
      if (type === 'del') {
        if ($scope.selFilter[form].length > 1) {
          $scope.selFilter[form].splice(index, 1);
        } else {
          $scope.selFilter[form] = [];
        }
      } else {
        $scope.selFilter[form].push('');
      }
    };
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.ok = async type => {
      const checkedSiteProp = [];

      Object.keys($scope.filterDatas.site).forEach(k => {
        if ($scope.selFilter.site.includes(k)) {
          $scope.filterDatas.site[k].forEach(item => {
            if (item.checked) {
              checkedSiteProp.push({
                arrItemAttrId: item.site_attribute_id,
                arrInputSpCd: '1',
                arrItemAttrVal: item.site_item_id
              });
            }
          });
        }
      });
      const checkRequiredUDA = $scope.selFilter.uda.some(item => !item.arrItemAttrVal);

      if (checkRequiredUDA) {
        commonSVC.showMessage('실패', 'UDA 속성을 모두 입력해주세요.');

        return;
      }

      // 고객 관점 속성 필수 체크
      const checkRequiredCustomer = $scope.selFilter.customer.find(item => {
        // 부모 속성이 존재할 경우 해당 부모 속성을 선택했으나 하위 속성이 없을 경우
        if (item.arrItemParentName) {
          const parentItem = $scope.filterDatas.customer[item.arrItemParentName][0];

          if ($scope.selFilter.customer.some(selected => selected.arrItemAttrItmId === `${parentItem.customer_item_id}@@${item.arrItemParentId}`)) {
            return item.arrInputSpCd === '2' && !item.arrItemAttrVal || item.arrInputSpCd === '1' && item.arrItemAttrItmId.split('@@')[1] === '00';
          }
        } else {
          if (item.arrInputSpCd === '2' && !item.arrItemAttrVal || item.arrInputSpCd === '1' && item.arrItemAttrItmId.split('@@')[1] === '00') {
            return true;
          }
        }
      });

      if (checkRequiredCustomer) {
        commonSVC.showMessage('실패', '고객 관점 속성을 모두 입력해주세요.');

        return;
      }

      $scope.row.set_info.key_customerProperty = $scope.selFilter.customer.map(item => {
        delete item.arrItemParentId;
        delete item.arrItemParentName;

        return item;
      });
      $scope.row.set_info.key_siteUdaProperty = [...checkedSiteProp, ...$scope.selFilter.uda];

      if (!$scope.row.set_info.key_itemShortNm?.length) {
        commonSVC.showMessage('실패', '상품단축명을 입력해주세요.');

        return;
      }

      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        if (hasDisabled) {
          $scope.row.set_info_template_no = '';
          commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

          return false;
        }

        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType,
          hasDisabled
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
          hasDisabled
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  });