'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A001-Ctrl', function ($scope, $q, $timeout, commonSVC, A001categorySVC, commonModel, categoryModel, shopAccountModel, siteInfo, errorSVC) {
    let userInfo;
    $scope.save_flag = false;      //카테고리 선택여부
    $scope.esm_save_flag = false;  //ESM 카테고리 선택 여부
    let cate_data = {};  // 카테고리(전체)

    $scope.cate = [[], [], [], []];
    $scope.esm_cate = [[], [], [], [], []];  // ESM 카테고리

    // select option 에 빈 값 들어가는 문제 처리
    $scope.sel_category = ['', '', '', ''];

    $scope.loading = true;
    $scope.category_names = '';
    $scope.ESM_CatMenu = ['', '', '', '', ''];
    $scope.plusShopUse;     // Shop 카테고리 사용여부
    $scope.plusShopCate = [
      {
        key: 'lCate',
        value: null,
        list: []
      },
      {
        key: 'mCate',
        value: null,
        list: []
      },
      {
        key: 'sCate',
        value: null,
        list: []
      }
    ];

    $scope.select = async (idx, init_yn) => {
      try {
        const cate = $scope.sel_category[idx];

        if (!cate) {
          $scope.loading = true;

          return false;
        }

        $scope.loading = false;

        // 마지막 카테고리인지 여부
        $scope.save_flag = cate.end_yn;

        if (!$scope.save_flag) {
          // 선택시 자식 카테고리 초기화
          for (let i = idx + 1; i <= 3; i++) {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          }

          await $timeout(() => {
            $scope.cate[idx + 1] = cate_data[`cate${idx + 1}`].filter(c => c.pa_shop_cate_see_cd === cate.shop_cate_see_cd);

            if (!init_yn) {
              $scope.loading = true;
            }
          });

          return true;
        } else {
          // 선택시 자식 카테고리 초기화
          for (let i = idx + 1; i <= 3; i++) {
            $scope.cate[i] = [];
            $scope.sel_category[i] = '';
          }

          const category_names = $scope.sel_category.filter(cate => cate.shop_cd).reduce((cate_name, { shop_cate_see_name }) => (
            !cate_name ? shop_cate_see_name : `${cate_name} > ${shop_cate_see_name}`
          ), '');

          $scope.category_names = category_names;

          // 속성있는 카테고리가 없어서 일단 삭제.
          // await getCateAttr(category_str);

          // 플러스샵 가져오기는 Y일때만;
          if ($scope.CateData.detailData.key_ShopCateUseYn === 'Y') {
            await $scope.checkPlusShop();
          }

          await $timeout(() => {
            $scope.loading = true;
          });

          return false;
        }
      } catch (e) {
        // 에러발생시 로딩 처리
        await $timeout(() => {
          $scope.loading = true;
        });
      }
    };

    // ESM카테고리 선택
    // 카테고리 선택시 ESM카테고리 선택 함수 undefined 처리
    $scope.esm_cate_select = async function(cate, init_yn) {
      if (!cate) {

        return false;
      }

      const cateNames = [];

      for (let i = 0; i < cate.shop_cate_see_depth; i++) {
        if ($scope.ESM_CatMenu[i]) {
          cateNames.push($scope.ESM_CatMenu[i].shop_cate_see_name);
        }
      }

      $scope.catename_esm = cateNames.join(' > ');

      // 선택시 일반 카테고리 초기화
      for (let i = 0; i <= 3; i++) {
        $scope.cate[i] = [];
        $scope.sel_category[i] = '';
        $scope.save_flag = false;
      }

      for (let i = cate.shop_cate_see_depth; i < 5; i++) {
        //초기화
        $scope.esm_cate[i] = [];
      }
      if (cate.end_yn) {
        $scope.CateData.detailData.catecode_esm = cate.shop_cate_see_cd;
        $scope.esm_save_flag = true;

        await getEsmDetail(init_yn);

        return false;
      } else {

        $scope.loading = false;
        $scope.esm_save_flag = false;

        const res = await categoryModel.getScrapCategoryByDepth(cate);
        await $timeout(() => {
          $scope.esm_cate[cate.shop_cate_see_depth] = res.data.result;

          $scope.CateData.detailData.catecode_esm = '';

          $scope.loading = true;
        });

        return true;
      }
    };

    /**
       * 2017-07-27 chris
       * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
       */
    $scope.$on('shopInfoSave', async (event, param, check) => {
      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      const etc = $scope.sel_category.find(cate => cate.end_yn).etc;

      //옵션없음
      if (!etc.options || !etc.options.length) {
        param.isChange = true;
      }
      //옵션있음
      else {
        let opt_size = false;
        let opt_color = false;

        opt_size = !!etc.options.find(opt => opt.ObjOptClaseName === '색상');
        opt_color = !!etc.options.find(opt => opt.ObjOptClaseName === '사이즈');

        param.opt_size = opt_size;
        param.opt_color = opt_color;

        if (opt_size != param.esm_opt_size || opt_color != param.esm_opt_color) {
          param.isChange = true;
        }
      }

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {

      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', async function (confirm) {
          if (confirm) {
            // timeout 없이 저장하면 shopInfoSave에서 showMessage함수가 작동안해서 추가함 2020-01-15 Alvin
            $timeout(() => {
              $scope.shopInfoSave(event);
            }, 500);
          }
        });
      }
    }, 300);

    /**
       * 쇼핑몰별 매칭정보 저장
       **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }
      $scope.loading = false;

      //카테고리 선택여부
      if (!$scope.esm_save_flag) {
        $scope.loading = true;
        commonSVC.showMessage('실패', 'ESM 카테고리를 설정해 주세요.');

        return false;
      }

      if ($scope.save_flag == false) {
        $scope.loading = true;

        commonSVC.showMessage('실패', '카테고리를 설정해 주세요.');

        return false;
      } else {
        //카테고리 속성 필수여부
        if ($scope.CateData.detailData.key_cateAttrUse == 'Y') {
          if ($scope.CateData.detailData.cateAttr == undefined || $scope.CateData.detailData.cateAttr == {}) {
            $scope.loading = true;
            commonSVC.showMessage('실패', '카테고리 속성을 설정해주시기 바랍니다.');

            return false;
          }
        }

        for (let i = 0; i < 4; i++) {
          if ($scope.sel_category[i]) {
            const cateCd = $scope.sel_category[i].shop_cate_see_cd.substring(0, (i + 1) * 2);

            $scope.CateData.detailData[`cate${i}`] = cateCd.substring(0, (i + 1) * 2).replace(/[0-9]{2}/g, e => `${e}_`).replace(/_$/, '');
          } else {
            $scope.CateData.detailData[`cate${i}`] = '';
          }
        }

        if ($scope.CateData.detailData.catecode_esm) {
          for (let i = 0; i < 5; i++) {
            if ($scope.ESM_CatMenu[i] && $scope.ESM_CatMenu[i].shop_cate_see_name) {
              $scope.CateData.detailData[`esm_cate${i}`] = `${$scope.ESM_CatMenu[i - 1] ? `${$scope.ESM_CatMenu[i - 1].shop_cate_see_name}_` : ''}${$scope.ESM_CatMenu[i].shop_cate_see_name}`;
            } else {
              $scope.CateData.detailData[`esm_cate${i}`] = '';
            }
          }
        } else {
          $scope.CateData.detailData.catename_esm = '';
        }

        //카테고리 depth별 저장될 category_code
        const category_code = $scope.CateData.detailData.cate3 || $scope.CateData.detailData.cate2 || $scope.CateData.detailData.cate1 || $scope.CateData.detailData.cate0;

        //category_names 초기화
        $scope.CateData.detailData.category_names = $scope.category_names;
        //category_code 초기화
        $scope.CateData.detailData.category_code = category_code;

        const selectEsmLength = $scope.catename_esm.split(' > ').length;

        for (let i = 0; i <= 4; i++) {
          if ($scope.esm_cate[i].length && selectEsmLength < i) {
            $scope.loading = true;
            commonSVC.showMessage('실패', 'ESM 카테고리를 설정해 주세요.');

            return false;
          }
        }

        if ($scope.form1.$valid) {
          $scope.CateData.detailData.catename_esm = $scope.CateData.detailData.catecode_esm ? $scope.catename_esm : '';
          /**
             * 2018-03-09 Roy
             * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
             */

          _.forEach($scope.plusShopCate, function (cate, i) {
            const cateKey = `key_ShopCateCd${i + 1}`;

            if (cate.value && $scope.CateData.detailData.key_ShopCateUseYn === 'Y') {
              $scope.CateData.detailData[cateKey] = cate.value;
            } else {
              delete $scope.CateData.detailData[cateKey];
            }
          });

          const param = {
            site_code: 'A001',
            site_id: $scope.matchingData.site_id,
            category_code: category_code,
            category_codes: 'test',
            category_names: $scope.category_names,
            categoryId: $scope.matchingData.categoryId,
            cateAttr: { ...$scope.CateData.cateAttr },
            detailData: { ...$scope.CateData.detailData },
            set_all_shop: $scope.check.set_all_shop,
            country: 'kr',
            //국내/해외
            type: 0
          };

          if (event) {
            param.opt_size = event.opt_size;
            param.opt_color = event.opt_color;
            param.isChange = event.isChange;
          }

          if (event && event.type === 'online') {
            $scope.returnAct(param);
          } else if ($scope.matchingData.isOnlineAdd === true) {
            await $timeout(async () => {
              try {
                const res = await categoryModel.shopInfoDetailEdit(param);

                $scope.loading = true;
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                param.shop_cate_no = res.data.shop_cate_no;

                $scope.returnAct(param);
              } catch (err) {
                $scope.loading = true;

                commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
              }
            });
          } else {
            $timeout(() => {
              param.isApply = true;

              $scope.applyCate(param);
            });
          }
        }
      }
    };

    //초기화
    const init = async () => {
      if (!$scope.matchingData) {
        $scope.matchingData = $scope.$parent.matchingData;
      }

      let res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A001', std_ol_yn: 1, shop_cate_see_type: '일반', shop_cate_see_cd: null });

      await $timeout(async () => {
        $scope.esm_cate[0] = res.data.result;

        res = await shopAccountModel.load({ shop_cd: 'A001', shop_id: $scope.matchingData.site_id });

        if (res.status !== 200) {
          throw '쇼핑몰 계정 확인 실패';
        }

        userInfo = {
          shop_id: res.data.results.shop_id,
          shop_pwd: res.data.results.shop_pwd,
          select_etc: '2'
        };

        $scope.loading = false;
        $scope.CateData = {};
        $scope.category_names = ''; //노출될 카테고리 분류
        $scope.requireAttr = ''; //카테고리 속성 노출 안되게

        // 상품페이지
        if ($scope.matchingData.shop_cate_no_add_info) {
          if (typeof $scope.matchingData.shop_cate_no_add_info === 'string') {
            await matchDetailDataLoad(JSON.parse($scope.matchingData.shop_cate_no_add_info));
          } else {
            await matchDetailDataLoad($scope.matchingData.shop_cate_no_add_info);
          }
        } else if (!$scope.matchingData.sol_cate_shop_add_info) { // 매칭된 카테고리가 없을 경우 return
          await matchDetailDataLoad(angular.copy(A001categorySVC.category_data));
        } else {
          await matchDetailDataLoad($scope.matchingData.sol_cate_shop_add_info);
        }
      });

      await $timeout(() => {
        $scope.loading = true;
      });
    };

    /**
   * 2018-03-09 Roy
   * 상품 등록, 수정 구분을 사용을 위해 함수로 뺌
   * 상품 수정의 경우에 솔루션 카테고리 정보를 수정하지 말아야함
   */
    const matchDetailDataLoad = async (addInfo) => {
      $scope.CateData.detailData = angular.copy(addInfo);

      if (!$scope.CateData.detailData.category_code) {
        return false;
      }

      // 어디서부턴가 catename_esm 가 sol_cate_shop_add_info 혹은 shop_cate_no_add_info에 들어오고 있지 않음. 그래서 없으면 일단 초기화로 보이게 수정
      if (!$scope.CateData.detailData.catename_esm?.length) {
        return false;
      }

      const esmDepth = $scope.CateData.detailData.catename_esm.split('>').length;
      const res = await categoryModel.getScrapCategoryByDepth({ shop_cd: 'A001', std_ol_yn: 1, shop_cate_see_type: '일반', shop_cate_see_depth: esmDepth });

      const matchEsmCateCode = (res.data.result.find(cate => cate.shop_cate_see_cd === $scope.CateData.detailData.catecode_esm))?.shop_cate_see_detail.shop_cate_see_cd_detail;
      const esmCateCode = matchEsmCateCode?.split('_') || [];
      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.ESM_CatMenu[idx] = $scope.esm_cate[idx].find(cate => (
          cate.shop_cate_see_cd === esmCateCode[idx] && (cate.shop_cate_see_depth === idx + 1)
        )) || '';

        // 선택처리
        hasNext = $scope.ESM_CatMenu[idx] ? await $scope.esm_cate_select($scope.ESM_CatMenu[idx], true) : false;

        if (!hasNext) {
          $timeout(() => {
            $scope.loading = true;
          });
        }

        idx++;
      }
    };

    /**
     * ESM 카테고리에 매칭되는 일반 카테고리 조회
     * @param {*} init
     */
    const getEsmDetail = async (init) => {
      // 일반 카테고리 불러오기
      const shop_cate_see_cd = $scope.ESM_CatMenu.find(cate => cate.end_yn).shop_cate_see_cd;

      const params = {
        shop_cd: 'A001',
        shop_cate_see_cd
      };

      const res = await categoryModel.categorySelectListESM(params);

      await $timeout(() => {
        cate_data = res.data.result;
        $scope.cate[0] = cate_data.cate0;
      });

      if (init) {
        await setCategory($scope.CateData.detailData.category_code);

      } else {
        // init 이 아닌 경우 초기화 처리
        for (let i = 1; i < 4; i++) {

          $scope.sel_category[i] = '';

          $scope.cate[i] = [];
        }
      }
    };

    const getPlusShopDetail = () => {
      const plusCatePromise = [];

      for (let i = 0; i < 3; i++) {
        const cateCd = $scope.CateData.detailData[`key_ShopCateCd${i + 1}`];

        $scope.plusShopCate[i].value = cateCd;

        if (cateCd && i < 2) {
          plusCatePromise.push(plusShopCateLoad(i, cateCd));
        }
      }

      return $q.all(plusCatePromise);
    };

    /**
     * shop 카테고리 로딩
     *
     * @param {int} index - 카테고리 타입 인덱스
     * @param {string} cateCd - 카테고리 코드
     */
    const plusShopCateLoad = (index, cateCd) => {
      return commonSVC.requestPA({ ...userInfo, pa_shop_cd: 'A001' }, siteInfo.A001.domain, 'etc.get_shop_category', { parent_code: cateCd }, commonModel, shopAccountModel)
        .then(function (res) {
          if (res.status !== 200) {
            throw 'Shop 카테고리 로딩실패';
          }

          $scope.plusShopCate[(index + 1)].list = res.data.data.item.category_list;
        })
        .catch(function (err) {
          commonSVC.showMessage('오류', err);
        });
    };

    /**
     * Shop 카테고리 선택
     *
     * @param {int} index - 카테고리 타입 인덱스
     */
    $scope.plusShopCateSelect = function (index) {
      const cate = $scope.plusShopCate[index];

      if (!cate.value || index === _.findLastIndex($scope.plusShopCate)) {
        return false;
      }

      $scope.loading = false;

      const plusShopCateLength = $scope.plusShopCate.length;

      for (let i = (index + 1); i < plusShopCateLength; i++) {
        const childCate = $scope.plusShopCate[i];

        childCate.value = null;
        childCate.list = [];
      }

      plusShopCateLoad(index, cate.value)
        .finally(function () {
          $scope.loading = true;
        });
    };

    // 계정확인으로 변경
    $scope.checkPlusShop = async () => {
      $scope.loading = false;
      const res = await commonSVC.requestPA({ ...userInfo, pa_shop_cd: 'A001' }, siteInfo.A001.domain, 'etc.get_shop_category', {}, commonModel, shopAccountModel);

      $scope.plusShopUse = res.data.data.item.shop_use;
      $scope.plusShopCate[0].list = res.data.data.item.category_list;

      await getPlusShopDetail();
      $scope.loading = true;
    };

    const setCategory = async (code) => {
      let codes = code.replace(/[^0-9_]/g, '').split('_');

      // '21_86_01'와 같은 형태일 경우 처리
      if (codes[0].length < 8) {
        // 8길이에 맞춰주기
        codes = codes.map((_, index) => codes.slice(0, index + 1).join('').padEnd(8, '0'));
      }

      let hasNext = true;
      let idx = 0;

      while (hasNext) {
        // 선택카테고리 모델 처리.
        $scope.sel_category[idx] = $scope.cate[idx].find(cate => (
          cate.shop_cate_see_cd === codes[idx] && cate.shop_cate_see_depth === idx + 1
        )) || '';
        // 선택처리
        hasNext = $scope.sel_category[idx] ? await $scope.select(idx, true) : false;

        if (!hasNext) {
          $timeout(() => {
            $scope.loading = true;
          });
        }

        idx++;
      }
    };

    $scope.$on('category_selection', async (e, data) => {
      if (data.site_code === $scope.matchingData.site_code && data.site_id === $scope.matchingData.site_id) {
        matchDetailDataLoad({ ...data.detailData });
      }
    });

    init();
  });
