'use strict';

angular.module('gmpApp')
  .controller('reallocHelpCtrl', function ($scope, $uibModalInstance) {
    /**
     * 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
