/**
 * Created by ally on 2017. 6. 22..
 */

'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentExcelUploadCtrl', function ($scope, $uibModalInstance, data, commonSVC, workSVC) {
    $scope.mode = data.mode; // 등록: 'add', 수정: 'edit'

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
		 * 엑셀 업로드
		 * */
    $scope.upload = function () {

      if (!Array.isArray($scope.fileData)) {
        const params = { mode: $scope.mode };

        workSVC.addExcelWork($scope.mode === 'add' ? 'UploadOrderExcel' : 'EditOrderExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');

        return false;
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    resetInput();
  })
  .controller('shopOrderShipmentExcelUploadCtrl', function ($scope, $uibModalInstance, $timeout, commonSVC, workSVC, userInfo) {
    $scope.param = {
      shop: '',
    };

    $scope.mall = {};
    // 사용중인 쇼핑몰, 아이디 가져오기
    $scope.mall = userInfo.user.manualOrderShops;

    $scope.selectedMall = '';
    $scope.data = {
      addRuleYn: false // 매칭규칙 생성 여부
    };

    /**
     * 2019-12-09 Boris
     * 파일 선택, 드래그 앤 드랍 이벤트
     */

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = [];
    };

    /**
     * 엑셀 양식 추가/수정 (직접입력 쇼핑몰)
     */
    $scope.setExcelField = shop => {
      const resolve = {
        data: {
          mode: shop.excel_field_cdn ? 'edit' : 'add',
          shop_cd: shop.shop_cd,
          shop_id: shop.shop_id,
          shop_name: shop.shop_name,
          uniq_criteria: shop.uniq_criteria,
          excel_field_cdn: shop.excel_field_cdn,
        }
      };
      const modal = commonSVC.openModal('xg', resolve, 'CustomShopExcelTemplateCtrl', 'views/order/shipment/modals/custom_shop_excel_template.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(true);
        }
      });
    };

    $scope.fileSelect = function (files) {
      // 노출용 파일명 세팅, 파일 개수 제한
      resetInput();
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    /**
		 * 엑셀 업로드
		 * */
    $scope.upload = function () {
      if (!$scope.selectedMall) {
        commonSVC.showMessage('쇼핑몰을 선택하세요.');

        return false;
      }

      if (Array.isArray($scope.fileData) && !$scope.fileData.length) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');

        return false;
      }

      if (!$scope.selectedMall.excel_field_cdn) {
        commonSVC.showMessage('[엑셀 양식 추가] 버튼을 눌러 양식을 추가해주세요.');

        return false;
      }

      const params = {
        shop: `${$scope.selectedMall.shop_cd}|${$scope.selectedMall.shop_id}|${$scope.selectedMall.shop_name}`,
        addRuleYn: $scope.data.addRuleYn
      };

      workSVC.addExcelWork('UploadShopOrderExcel', params, $scope.fileData)
        .finally(function () {
          $uibModalInstance.close();
        });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    resetInput();
  });
