'use strict';

angular.module('gmpApp')
  .controller('reportExistingUserCtrl', function ($scope, $uibModalInstance) {

    // 확인
    $scope.ok = () => {

      $uibModalInstance.close('success');

    };

    $scope.cancel = () => {
      $uibModalInstance.close();
    };

  });
