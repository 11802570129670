'use strict';

angular.module('gmpApp')
  .factory('orderPrintFormSVC', function () {
    return {
      forms: [
        { template_name: '주문서(사용자)', type: 'user', bookmark_yn: true },
        { template_name: '패킹리스트 - 재고 사용 (주문별)', type: 'packing_stock', bookmark_yn: true },
        { template_name: '패킹리스트 - 재고 사용 (SKU별)', type: 'packing_stock_sku', bookmark_yn: true },
        { template_name: '패킹리스트 - 재고 미사용', type: 'packing_no_stock', bookmark_yn: true },
        { template_name: 'SKU별 패킹리스트', type: 'packing_sku', bookmark_yn: true }
      ],
      fieldList: {
        ord: [
          { title: '주문일', item_cd: 'O00101', value: 'ord_time', col: '3', select: true, mod_item: '', data: '2023-00-00 00:00:00' },
          { title: '구매쇼핑몰', item_cd: 'O00102', value: 'shop_name', col: '3', select: true, mod_item: '', data: '스마트스토어(playauto)' },
          { title: '주문번호', item_cd: 'O00103', value: 'shop_ord_no', col: '6', select: true, mod_item: '', data: '20230000000000' },
          { title: '주문자(ID)', item_cd: 'O00104', value: 'order_name', col: '2', select: true, mod_item: '', data: '플레이오토(playauto)' },
          { title: '주문자 전화번호', item_cd: 'O00105', value: 'order_tel', col: '2', select: true, mod_item: '', data: '02-1234-5678' },
          { title: '주문자 핸드폰번호', item_cd: 'O00106', value: 'order_htel', col: '2', select: false, mod_item: '', data: '010-1234-5678' },
          { title: '수령자', item_cd: 'O00108', value: 'to_name', col: '2', select: false, mod_item: '', data: '플레이오토' },
          { title: '수령자 전화번호', item_cd: 'O00109', value: 'to_tel', col: '2', select: false, mod_item: '', data: '02-1234-5678' },
          { title: '수령자 핸드폰번호', item_cd: 'O00110', value: 'to_htel', col: '2', select: false, mod_item: '', data: '010-1234-5678' },
          { title: '배송지', item_cd: 'O00107', value: 'to_addr', col: '6', select: true, mod_item: '', data: '(08390)서울특별시 구로구 디지털로26길 123 (구로동) 14층 플레이오토' },
          { title: '배송방법', item_cd: 'O00111', value: 'ship_method', col: '2', select: false, mod_item: '', data: '무료배송' },
          { title: '배송메세지', item_cd: 'O00112', value: 'ship_msg', col: '3', select: false, mod_item: '', data: '문 앞에 부탁드립니다.' },
          { title: '배송비', item_cd: 'O00121', value: 'deli_cost', col: '2', select: false, mod_name: '', data: '3000' },
          { title: '배송처명', item_cd: 'O00113', value: 'depot_name', col: '2', select: false, mod_item: '', data: '플레이오토' },
          { title: '인쇄일자', item_cd: 'O00114', value: 'print_date', col: '3', select: false, mod_item: '', data: '2023-00-00 00:00:00' },
          { title: '작업자', item_cd: 'O00115', value: 'user_name', col: '2', select: false, mod_item: '', data: '김플토' },
          { title: '택배사', item_cd: 'O00116', value: 'carr_name', col: '2', select: false, mod_item: '', data: '플토택배' },
          { title: '송장번호', item_cd: 'O00117', value: 'invoice_no', col: '6', select: false, mod_item: '', data: '202300000000' },
          { title: '출고일', item_cd: 'O00118', value: 'out_order_time', col: '3', select: false, mod_item: '', data: '2023-00-00 00:00:00' },
          { title: '묶음번호', item_cd: 'O00119', value: 'bundle_no', col: '4', select: false, mod_item: '', data: '1234567890' },
          { title: '기타메세지', item_cd: 'O00120', value: 'notice_msg', col: '6', select: false, mod_item: '', data: '20230000' }
        ],
        ol_shop: [
          { title: '순번', item_cd: 'O00201', value: 'index', text: 'center', select: false, mod_item: '', multi: true, data: 1, multi_data: 2 },
          { title: '상품 대표 이미지', item_cd: 'O00202', value: 'sale_img', text: 'center', select: true, mod_item: '', multi: false, data: '/assets/images/upload.png' },
          { title: 'SKU 상품 이미지', item_cd: 'O00216', value: 'prod_img', text: 'center', select: false, mod_item: '', multi: true, no_stock: true, data: '/assets/images/upload.png' },
          { title: '쇼핑몰 상품명', item_cd: 'O00203', value: 'shop_sale_name', style: 'center', text: 'left', select: true, mod_item: '', multi: false, data: '플레이오토 로고 텀블러' },
          { title: 'SKU상품명', item_cd: 'O00204', value: 'prod_name', text: 'left', select: true, mod_item: '', multi: true, no_stock: true, data: '플레이오토 로고 텀블러_500ml', multi_data: '플레이오토 로고 텀블러_700ml' },
          { title: '옵션', item_cd: 'O00208', value: 'shop_opt_name', text: 'left', select: false, mod_item: '', multi: true, data: '1set' },
          { title: '수량', item_cd: 'O00210', value: 'sale_cnt', text: 'center', select: true, mod_item: '', multi: false, data: 1 },
          { title: '속성', item_cd: 'O00209', value: 'attri', text: 'left', select: false, mod_item: '', multi: true, no_stock: true, data: '파랑', multi_data: '노랑' },
          { title: '금액', item_cd: 'O00211', value: 'sales', text: 'right', select: true, mod_item: '', multi: false, data: '8,000원' },
          { title: '공급가', item_cd: 'O00219', value: 'shop_supply_price', text: 'right', select: false, mod_item: '', multi: false, data: '8,000원' },
          { title: '주문 총 수량', item_cd: 'O00213', value: 'all_sale_cnt', text: 'center', select: false, mod_item: '', multi: false, total: true, data: 1, multi_data: 2 },
          { title: '주문 총 금액', item_cd: 'O00214', value: 'total_price', text: 'right', select: false, mod_item: '', multi: false, total: true, data: '8,000원', multi_data: '17,000원' },
          { title: '판매자관리코드', item_cd: 'O00206', value: 'c_sale_cd', text: 'left', select: false, mod_item: '', multi: false, data: 'm2023000000' },
          { title: '재고관리코드', item_cd: 'O00207', value: 'stock_cd', text: 'left', select: false, mod_item: '', multi: true, no_stock: true, data: 'playauto1234', multi_data: 'playauto6789' },
          { title: 'SKU코드', item_cd: 'O00205', value: 'sku_cd', text: 'left', select: false, mod_item: '', multi: true, no_stock: true, data: 'S123AB456789C', multi_data: 'S456DE123456F' },
          { title: '건별 출고수량', item_cd: 'O00212', value: 'pack_unit', text: 'center', select: false, mod_item: '', multi: true, no_stock: true, data: 1 },
          { title: '총 출고수량', item_cd: 'O00215', value: 'total_cnt', text: 'center', select: false, mod_item: '', multi: true, no_stock: true, data: 2 },
          { title: '건별 출고수량 합계', item_cd: 'O00217', value: 'all_pack_unit', text: 'center', select: false, mod_item: '', multi: false, no_stock: true, total: true, data: 1 },
          { title: '총 출고수량 합계', item_cd: 'O00218', value: 'all_total_cnt', text: 'center', select: false, mod_item: '', multi: false, no_stock: true, total: true, data: 2 },
          { title: '메모', item_cd: 'O00220', value: 'memo', text: 'center', select: false, mod_item: '', multi: false, data: '메모입력3' }
        ]
      }
    };
  });