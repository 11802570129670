/**
 * 배송지연처리
 * Created by ally on 2017. 6. 20..
 */
'use strict';

angular.module('gmpApp')
  .controller('delayDeliveryCtrl', function ($scope, settings, data, systemList, workSVC, commonSVC, shipmentModel, $uibModalInstance, shipmentSVC) {
    $scope.delayDate = moment().format('YYYY-MM-DD');
    $scope.selectList = data.selectList || [];
    $scope.fromPage = data.fromPage;
    $scope.delayReason = systemList.data.delivery_delay_reason;
    $scope.delayCodes = shipmentSVC.delayCodes;
    $scope.selected_deleay_code = '01';

    $scope.save = function() {
      if ($scope.form1.$valid) {
        const params = {
          numbers: _.map(_.filter($scope.selectList, { ship_delay_yn: 0 }), 'uniq'),
          delay_code: $scope.selected_deleay_code,
          delay_reason: $scope.delayReason,
          delay_date: $scope.delayDate
        };

        const action = $scope.fromPage === 'payment' ? 'DELAYED_ORDER_PAYMENT' : 'DELAYED_ORDER';

        // 작업 추가
        workSVC.addWorkSelect(action, params);

        $uibModalInstance.close('success');
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  });
