'use strict';

angular.module('gmpApp')
  .controller('pkgBoxLinkageSkuCtrl', function ($rootScope, $scope, data, $uibModalInstance, commonSVC, settings, productModel, systemModel, userInfo, supplierModel, warehouseModel) {

    const pkg_no = data.pkg_no;

    $scope.searchData = {};
    $scope.searchData.tab = 'sku';
    $scope.searchData.showCount = 25;

    $scope.cancel = function () {
      $uibModalInstance.close();
    };

    $scope.skuDetail = function(sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function () {

        //리스트 유지한채 데이터 업데이트
        $scope.sku.methods.reloadData(function() {}, false);

      });
    };

    // SKU 상품 탭
    $scope.sku = {};
    $scope.sku.methods = {};
    $scope.sku.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'sku_cd', 'prod_name', 'stock_cnt_real', 'stock_cnt_safe', 'pkg_name', 'max_ea_cnt', 'in_unit_cnt', 'sale_price', 'stock_status', 'depot_name'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      multiSelect: false,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/pkgbox/linkage`,
        requestWillAction: function (d) {
          const data = angular.merge({ pkg_no }, d);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '상세보기',
          width: 10,
          className: 'tool text-center',
          template: function (row) { //도구
            return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.skuDetail('${row.sku_cd}',${row.prod_no})">상세</button>`;
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 150
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 200,
        },
        {
          key: 'stock_cnt_real',
          title: '실재고',
          width: 40,
        },
        {
          key: 'stock_cnt_safe',
          title: '안전재고',
          width: 40,
        },
        {
          key: 'pkg_name',
          title: '포장형태',
          width: 40,
        },
        {
          key: 'max_ea_cnt',
          title: '합포장 가능 최대 수량',
          width: 80,
        },
        {
          key: 'in_unit_cnt',
          title: '낱본수량',
          width: 40,
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 40,
        },
        {
          key: 'stock_status',
          title: '상태',
          width: 40,
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 40,
        },
      ]
    };
  });