/**
 * 리스트 푸터 영역 (선택한자료를.. 드랍버튼과 페이지네이션)
 *
 * 2017-03-14 MatthewKim
 */

'use strict';

angular.module('gmpApp')

  .directive('listFooter', function($rootScope) {

    return {
      restrict: 'EA',
      scope: {
        searchData: '=',
        searchBtn: '=',
        searchFn: '=',
        isDetailShow: '=?', // 2017-03-10 MatthewKim 상세 보기 모드 여부
      },
      templateUrl: 'views/tpls/listFooter.html',
      link: function(scope) {

        // 상세 보기 모드 여부 2017-03-10 MatthewKim
        if (!scope.isDetailShow) {
          scope.isDetailShow = false;
        }

        /**
           * 전체 선택/ 해제
            */
        scope.selectAllChecked = function() {
          scope.searchData.all_checked = !scope.searchData.all_checked;
        };

      }
    };
  }

  );
