'use strict';

angular.module('gmpApp')
  .controller('AltalkAutoSendRegisterModalCtrl', function(
    $scope, $rootScope, data, commonSVC, settings, $timeout, $uibModalInstance, atalkModel
  ) {
    const init = async () => {
      // 대체 문구 추출 위한 정규표현식
      const regex = /#\{([^}]+)\}/g;

      // DB에서 문구 변수 데이터 조회(2.0은 type이 주문인 데이터만 조회)
      const vars = (await commonSVC.sendUrl('POST', `${settings.adminApiUrl}/api/marketing/atalk/variableList`)).data.result.filter(v => v.type === '주문');

      // 발송타입 (test, auto, direct)
      $scope.type = data.type;

      // 발송 모달 타이틀
      $scope.title = data.type === 'direct' ? '개별 발송' : (data.type === 'test' ? '테스트 발송' : '자동 발송 등록');

      // 변수 리스트 (해당 변수 기반으로 발송 모달에서 대체 문구 input 생성)
      $scope.variableList = data.template.content.match(regex) ? vars.filter(v => Array.from(new Set((data.template.content.match(regex).map(v => v.match(/#\{([^}]+)\}/)[1])).filter(Boolean))).includes(v.content)) : [];

      // 대체 문구 변수 생성
      $scope.alternative_phrases = {};

      $scope.variableList.forEach(v => {
        $scope.alternative_phrases[v.name] = '';
      });

      // 등록할 자동발송 또는 개별 및 테스트 발송 데이터
      $scope.template_info = angular.copy(data);

      // 잔여 카운트
      const re = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/common/additional-service-cnt`);
      $scope.remainCnt = re.data?.atalkCnt.remain < 0 ? 0 : re.data?.atalkCnt.remain;

      $timeout(() => {});
    };

    init();

    $scope.close = () => {
      $uibModalInstance.close();
    };

    $scope.checkPayAmt = (event, type) => {
      if (type === 'pay_amt') {
        const charCode = event.which || event.keyCode;

        if ((charCode < 48 || charCode > 57) && charCode !== 8) {
          event.preventDefault();
        }
      }
    };

    $scope.payAmtComma = (type) => {
      if (type === 'pay_amt') {
        const newPayAmt = $scope.alternative_phrases[type].replace(/,/g, '');
        $scope.alternative_phrases[type] = newPayAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    };

    /**
     * 알림톡 발송 및 자동 발송 등록
     */
    $scope.register = async () => {
      $scope.wait = true;
      // 알림톡 발송 시 스피너 추가
      $('#spinner').removeClass('hidden');
      try {
        // 알림톡 content내 변수 존재 시 대체문구 넣는 처리
        $scope.variableList.forEach((v) => {
          v.replace_txt = $scope.alternative_phrases[v.name];

          if (v.name === 'pay_amt') {
            v.replace_txt = $scope.alternative_phrases[v.name].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }

        });

        // 변수 데이터도 함께 넘겨줘야해서 파라미터에 해당 데이터 추가
        $scope.template_info.template.varList = $scope.variableList;

        // 개별 또는 테스트 발송 시 발송 대상 변수
        const receive_list = $scope.type === 'test' ? $scope.send_user_list.split(',').map(user => ({ htel: user })) : $scope.template_info.altalk.receive_list;
        if ($scope.type === 'test' && $scope.remainCnt < receive_list.length) {
          return commonSVC.showMessage('발송 실패', '카운트 부족으로 충전 후 발송 가능합니다.');
        }
        // 자동 발송 등록인 경우 발송 트리거 (주문수집, 주문상태변경) 변수 저장 처리
        const sol_action = $scope.template_info.altalk.send_type === 'scrap_order' ? '신규주문 수집 시' : $scope.template_info.altalk.order_status;
        // 자동 발송 등록의 경우 트리거, 묶음주문 대표정보 발송 여부 데이터 처리
        const send_target_criteria = { sol_action, send_target: 'action', send_type_bundle_info: $scope.template_info.altalk.send_type_bundle_info };
        const data = {
          sol_no: $rootScope.user_profile.sol_no,
          name: $rootScope.user_profile.user_name,
          m_no: $rootScope.user_profile.m_no,
          ...($scope.type === 'direct' && { m_no: $rootScope.user_profile.m_no }),
          send_type: $scope.template_info.altalk.send_type === 'group' ? 'direct' : $scope.type,  // 발송 대상 타입 group은 개별 발송에만 존재
          send_time: $scope.template_info.altalk.send_time,  // 발송 시간
          title: $scope.template_info.altalk.reg_name,       // 발송명
          sendTimeDetail: $scope.template_info.altalk.send_time === 'immediately' ? 'now' : $scope.template_info.altalk.book_send_time || 'now', // 발송 시간 (즉시, 예약 - 시간)
          plus_friend_id: $scope.template_info.template.plus_friend_id, // 발송 플러스친구 ID
          receive_list: receive_list,                                   // 발송 대상
          template: $scope.template_info.template,                      // 발송 및 자동 발송등록 템플릿
          ...($scope.type === 'auto' && { send_target_criteria }),      // send_target_criteria 데이터는 자동 발송인 경우에만 필요
          ...($scope.type !== 'test' && { send_target: receive_list, sms_send_yn: $scope.template_info.altalk.sms_send_yn !== '1' })   // SMS 대체여부
        };

        if (($scope.alternative_phrases && Object.values($scope.alternative_phrases).some(v => !v))) {
          let alternativeChkMsg = '변수 대체 문구를 모두 입력해 주세요.';
          if ($scope.type === 'test' && !$scope.send_user_list) {
            alternativeChkMsg = '휴대폰 번호, 변수 대체 문구를 모두 입력해 주세요.';
          }

          $scope.wait = false;
          $('#spinner').addClass('hidden');

          return commonSVC.showMessage(alternativeChkMsg);
        }

        const result = await atalkModel.sendAtalk(data);
        const successToastMsg = {
          test: '테스트 발송 요청에 성공하였습니다.\n알림톡 발송 관리 > 상세에서 결과를 확인\n하실 수 있습니다.',
          auto: '자동 발송 등록에 성공하였습니다.',
          direct: '알림톡 발송 성공\n알림톡 발송 관리 > 상세에서 결과를 확인\n하실 수 있습니다.'
        };
        const confirmTitle = $scope.type === 'test' ? '테스트 발송 완료' : '발송 완료';
        const confirmContent = '발송 건수에 따라 최대 10분까지 소요될 수 있습니다.\n전체 발송 리스트 탭에서 발송 결과를 확인해주세요.';
        if (result.data.result === 'success') {
          $('#spinner').addClass('hidden');
          commonSVC.showMessage(confirmTitle, confirmContent, (isConfirm) => {
            if (isConfirm) {

              commonSVC.showToaster('success', '성공', successToastMsg[$scope.type]);
              $uibModalInstance.close('success');

              return;
            }
          });
        }
      } catch (err) {
        $scope.wait = false;
        // 알림톡 발송 시 스피너 해제
        $('#spinner').addClass('hidden');
        const errData = {
          auto: '자동 발송 등록',
          direct: '개별 발송',
          test: '테스트 발송'
        };

        if (err.data.error.includes('Duplicate Task')) {
          return commonSVC.showMessage('자동 발송 등록 실패', `동일 발송명 또는 동일 템플릿과 발송대상으로 등록된 자동 발송 건이 있습니다.\n발송명: ${err.data.error.split(':')[1]}건을 확인해 주세요.`);
        }

        return commonSVC.showMessage(`${errData[$scope.type]} 실패`, '관리자에게 문의 바랍니다.');
      }
    };
  });