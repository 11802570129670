
/**
 * 2018-01-24  yellow_id Email
 * Daniel
 */
'use strict';
angular.module('gmpApp')
  .controller('ConfigsSmsTemplate', function ($rootScope, $scope, $uibModalInstance, $timeout, $compile, commonSVC, settings, gettextCatalog) {
    /**
     * 서치바 관련 변수
     */
    // 검색영역 폼값 정리
    $scope.searchForm = {
      date_type: 'wdate'
    };
    // 검색영역 데이터
    $scope.searchData = {
      search_key_items: [],
      selectCount: 0,
      totalCount: 0
    };
    $scope.tab = 0;
    $scope.sol_no = $rootScope.user_profile.sol_no;
    const searchDetail = [];

    $scope.searchDetail = angular.copy(searchDetail);

    $scope.searchFn = {
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.changeTab = (tab) => {
      $scope.tab = tab;
    };

    /**
   * datatable 설정
   */

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'template_no', 'template_name', 'wdate'],
      alignRightColumns: [],
      defaultSortingColumns: ['template_no'],
      notSortingColumns: ['widget', 'template_no', 'template_data'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/smsTemplate/list`,
        requestWillAction: function (data) {
          data.start ||= 0;

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.total;

          return result.results;
        }
      },
      selectOptions: {
        multiSelect: false
      },
      rowHeight: 33,
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return `
            <div style="padding-top: 2px;">
              <button
              type="button"
              class="btn btn-default btn-xxs mr-5"
              ng-click="grid.appScope.showTemplateEdit('${row.template_no}')"
              >
                수정
              </button>
              <button
                type="button"
                class="btn btn-default btn-xxs mr-5"
                ng-click="grid.appScope.deleteTemplate('${row.template_no}','${row.template_name}')"
              >
                삭제
              </button>
            </div>
            `;
          }
        },
        {
          key: 'template_no',
          title: '템플릿 코드',
          width: 80
        },
        {
          key: 'template_name',
          title: '템플릿 명',
          width: 300
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 300
        }
      ]
    };

    /**
     * 신규추가.
     */
    $scope.add = function() {
      const modal = commonSVC.openModal('md', {}, 'ConfigsSmsTemplateAdd', 'views/alimtalk/modals/sms_template_add.html');

      modal.result.then(function () {
        $scope.grid.methods.reloadData(function () {}, true);
      });
    };

    /**
     * 템플릿 수정 페이지
     */
    $scope.showTemplateEdit = function(templateNo) {
      const resolve = {
        data: function() {
          return {
            templateNo: templateNo
          };
        }
      };
      const modal = commonSVC.openModal('md', resolve, 'ConfigsSmsTemplateEdit', 'views/alimtalk/modals/sms_template_edit.html');

      modal.result.then(function () {
        $scope.grid.methods.reloadData(function () {}, true);
      });
    };

    /**
     * 삭제
     */
    $scope.deleteTemplate = function(templateNo, templateName) {
      // const params = $scope.grid.methods.selectedData('template_no');
      const params = {
        template_no: templateNo,
        template_name: templateName
      };
      if (!params.template_no) {
        commonSVC.showMessage(gettextCatalog.getString('선택된 템플릿이 없습니다.'));

        return false;
      }

      commonSVC.showConfirmHtml('선택한 템플릿을 삭제 하시겠습니까?', '', function () {
        commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/order/smsTemplate`, params, function (state, re) {
          if (re.results[0] === 'SUCCESS') {
            commonSVC.showToaster('success', '성공', '선택하신 템플릿이 삭제되었습니다.', '');
            $scope.grid.methods.reloadData(function () {}, false);
          } else {
            commonSVC.showToaster('error', '실패', '템플릿 삭제에 실패하였습니다.', '');
          }
        });
      });
    };

    /**
     * 선택
     */
    $scope.onSubmit = function() {
      const data = $scope.grid.methods.selectedData('all');

      if (data.length === 0) {
        commonSVC.showMessage('템플릿을 선택해주십시오.');

        return false;
      } else if (data.length > 1) {
        commonSVC.showMessage('한개의 템플릿만 선택해 주십시오.');

        return false;
      }

      $uibModalInstance.close(data[0]);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss();
    };
  });
