'use strict';

angular.module('gmpApp')
  .controller('CategoryShopInfoDetail-A008-Ctrl', function ($scope, $ocLazyLoad, $timeout, commonSVC, errorSVC, A008categorySVC, categoryModel, openMarketCateSVC) {
    // 카테고리 정보 www 정보로 불러오는걸로 수정. 2018-02-19 rony
    $ocLazyLoad.load({ cache: false, files: [`${window.location.protocol}//www.playauto.co.kr/omp_app/js/category/cate_A008_utf8.js`] })
      .then(function() {
        openMarketCateSVC.setCategoryList(document.all('CatMenu_0'), document.all('CatMenu_1'), document.all('CatMenu_2'), document.all('CatMenu_3'));
        openMarketCateSVC.resetLargeCategory();
        openMarketCateSVC.setInitCategory('0', '0', '0', '0');

        $scope.CateData = angular.copy(A008categorySVC.category_data);
        $scope.cert_type = angular.copy(A008categorySVC.cert_type);
        $scope.category_names = ''; //노출될 카테고리 분류
        $scope.requireAttr = ''; //카테고리 속성 노출 안되게

        init();
      });

    let save_flag = false;    //카테고리 선택여부

    $scope.select1 = function () {
      $('.cateAttrList').html('');
      $scope.category_names = $('#CatMenu_0 option:selected').text();
      openMarketCateSVC.SelectLargeInterpark($('#CatMenu_0').val());
    };

    $scope.select2 = function () {
      if ($('#CatMenu_1').val() != null) {
        $('.cateAttrList').html('');
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
        openMarketCateSVC.resetSmallCategory($('#CatMenu_1').val());
        // resetDetailCategory();
      } else {
        return false;
      }
    };

    $scope.select3 = function () {
      if ($('#CatMenu_2').val() != null) {
        $scope.category_names = $('#CatMenu_0 option:selected').text();
        $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
        $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
        openMarketCateSVC.SelectSmall($('#CatMenu_2').val());

        //로딩이미지
        $('#spinner').removeClass('hidden');

        $('#spinner').addClass('hidden');

        // 세분류가 없는경우에만 save_flag=true 줌. 2018-03-14 rony
        if ($('#CatMenu_3 option').length < 2) {
          save_flag = true;
        }
      } else {
        return false;
      }
    };

    $scope.select4 = function () {
      if ($('#CatMenu_3').val() != '0_0_0_0' && $('#CatMenu_3').val() != null) {
        if ($('#CatMenu_3').val() != '0_0_0_0' && $('#CatMenu_3').val() != null) {
          $scope.category_names = $('#CatMenu_0 option:selected').text();
          $scope.category_names += ` > ${$('#CatMenu_1 option:selected').text()}`;
          $scope.category_names += ` > ${$('#CatMenu_2 option:selected').text()}`;
          $scope.category_names += ` > ${$('#CatMenu_3 option:selected').text()}`;
        }
        save_flag = true;
      } else {
        return false;
      }
    };

    openMarketCateSVC.SelectDetail(4);

    /**
     * 2017-07-27 chris
     * 온라인 채널에서 카테고리 매칭정보 수정시 저장버튼 클릭 이벤트 받아옴
     */
    $scope.$on('shopInfoSave', function(event, param, check) {

      check = _.isUndefined(check) ? { set_all_shop: false } : check;

      $scope.check = check;
      $scope.shopInfoSave(param);
    });

    $scope.shopInfoSaveConfirm = _.debounce((event) => {
      if (_.isUndefined($scope.check)) {
        $scope.check = {
          set_all_shop: false
        };
      }

      if (!$scope.matchingData.isOnlineAdd) {
        $scope.shopInfoSave(event);
      } else {
        commonSVC.showConfirm('주의', '해당 카테고리가 연동된 쇼핑몰 카테고리를 모두 수정합니다.\n(추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 상세 > 개별 카테고리가 지정된 상품은 제외되며, 저장되어 있는 옵션은 그대로 유지됩니다.)\n\n카테고리를 수정하시겠습니까? \n\n*이미 쇼핑몰에 등록된 상품은 수정대기 → 상품전송 시 카테고리는 수정하지 않습니다.', function (confirm) {
          if (confirm) {
            $scope.shopInfoSave(event);
          }
        });
      }
    }, 300);

    /**
     * 쇼핑몰별 매칭정보 저장
     **/
    $scope.shopInfoSave = async (event) => {

      if (!$scope.form1.$valid) {
        for (let i = 0; i < $scope.form1.$error.required.length; i++) {
          $scope.form1.$error.required[i].$$element.emitInputError();
        }
      }

      //카테고리 마지막 분류까지 선택여부
      if (save_flag == false) {
        commonSVC.showMessage('실패', '최하위 카테고리까지 모두 선택해 주세요.');

        return false;
      } else {
        $scope.CateData.detailData.cate0 = $('#CatMenu_0 option:selected').val();
        $scope.CateData.detailData.cate1 = $('#CatMenu_1 option:selected').val();
        $scope.CateData.detailData.cate2 = $('#CatMenu_2 option:selected').val();
        $scope.CateData.detailData.cate3 = $('#CatMenu_3 option:selected').val();

        $scope.CatMenu_name_0 = $('#CatMenu_0 option:selected').text();
        $scope.CatMenu_name_1 = $('#CatMenu_1 option:selected').text();
        $scope.CatMenu_name_2 = $('#CatMenu_2 option:selected').text();
        $scope.CatMenu_name_3 = $('#CatMenu_3 option:selected').text();

        //카테고리 depth별 저장될 category_code
        let category_code = '';

        if ($scope.CateData.detailData.cate3 != undefined && $scope.CateData.detailData.cate3 != '0_0_0_0') {
          category_code = $scope.CateData.detailData.cate3;
        } else if ($scope.CateData.detailData.cate3 == '0_0_0_0') {
          category_code = $scope.CateData.detailData.cate2;
        } else if ($scope.CateData.detailData.cate3 == undefined) {
          if ($scope.CateData.detailData.cate2 == '0_0_0' || $scope.CateData.detailData.cate2 == undefined) {
            category_code = $scope.CateData.detailData.cate1;
            if ($scope.CateData.detailData.cate1 == undefined) {
              category_code = $scope.CateData.detailData.cate0;
            }
          } else {
            category_code = $scope.CateData.detailData.cate2;
          }
        }

        //detailData에 수정된 데이터를 넣기위해
        //category_names 초기화
        $scope.CateData.detailData.category_names = $scope.category_names;
        //category_code 초기화
        $scope.CateData.detailData.category_code = category_code;

        if ($scope.form1.$valid) {
          const param = {
            // "hscode": $stateParams.hscode,
            site_code: $scope.matchingData.site_code,
            site_id: $scope.matchingData.site_id,
            category_code: category_code,
            category_codes: 'test',
            category_names: $scope.category_names,
            categoryId: $scope.matchingData.categoryId,
            detailData: $scope.CateData.detailData,
            set_all_shop: $scope.check.set_all_shop,
            country: 'kr',
            //국내/해외
            type: 0
          };

          /**
           * 2018-03-09 Roy
           * 온라인 상품 수정시 솔루션 카테고리 정보 수정하지 않도록 변경
           */
          if (event && event.type === 'online') {
            $scope.returnAct(param);
          } else {
            await $timeout(async () => {
              try {
                const res = await categoryModel.shopInfoDetailEdit(param);

                $scope.loading = true;
                commonSVC.showToaster('success', '성공', '카테고리 정보 수정에 성공하였습니다.');

                if ($scope.matchingData.isOnlineAdd === true) {
                  param.shop_cate_no = res.data.shop_cate_no;
                  $scope.returnAct(param);
                } else {
                  $scope.infoLoad();
                }
              } catch (err) {
                $scope.loading = true;

                commonSVC.showToaster('error', '실패', errorSVC.getError('prod', err.data.error));
              }
            });
          }
        }
      }
    };

    // function matchDetailDataLoad(shop_add_info) {
    //   $scope.CateData.detailData = shop_add_info;
    //   var code = $scope.CateData.detailData.cate2.split('_');

    //   $timeout(function() {
    //     $('#CatMenu_0 option[value=' + code[0] + ']').attr('selected', 'selected');
    //     $scope.select1();

    //     $('#CatMenu_1 option[value=' + code[0] + '_' + code[1] + ']').attr('selected', 'selected');
    //     $scope.select2();

    //     $('#CatMenu_2 option[value=' + code[0] + '_' + code[1] + '_' + code[2] + ']').attr('selected', 'selected');
    //     $scope.select3();

    //     $('#CatMenu_3 option[value=' + code[0] + '_' + code[1] + '_' + code[2] + '_' + code[3] + ']').attr('selected', 'selected');
    //     $scope.select4();
    //   }, 500);
    // }

    function init() {
      const param = {
        categoryId: $scope.matchingData.categoryId,
        shop_cd: $scope.matchingData.site_code,
        shop_id: $scope.matchingData.site_id
      };

      $scope.CateData.detailData = angular.copy(A008categorySVC.category_data);

      if (!$scope.matchingData.categoryId) { return; }

      categoryModel.shopInfoDetailDataLoad(param, function (state, result) {
        if (state == 'success') {
          if (result.length) {
            $scope.CateData = result[0];
            $scope.CateData.detailData = $scope.CateData.sol_cate_shop_add_info;
            $scope.category_names = result[0].category_names;

            // 세분류까지 있는경우 세분류 까지 표기되도록 수정 2018-03-14 rony
            let code = result[0].detailData.cate2.split('_');

            if (!_.isUndefined(result[0].detailData.cate3)) {
              code = result[0].detailData.cate3.split('_');
            }

            $timeout(function() {
              $(`#CatMenu_0 option[value=${code[0]}]`).attr('selected', 'selected');
              $scope.select1();

              $(`#CatMenu_1 option[value=${code[0]}_${code[1]}]`).attr('selected', 'selected');
              $scope.select2();

              $(`#CatMenu_2 option[value=${code[0]}_${code[1]}_${code[2]}]`).attr('selected', 'selected');
              $scope.select3();

              $(`#CatMenu_3 option[value=${code[0]}_${code[1]}_${code[2]}_${code[3]}]`).attr('selected', 'selected');
              $scope.select4();
            }, 500);
          }
        } else {
          commonSVC.showMessage('데이터 로드 실패');
        }
      });
    }
  });
