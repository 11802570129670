'use strict';

angular.module('gmpApp')
  .controller('LogExcelHistoryCtrl', function (
    // common
    $rootScope, $scope, $compile, $timeout, gettextCatalog,
    // info
    settings,
    // SVC
    logExcelSVC, commonSVC,
    // List
    systemList
  ) {
    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        search_type: 'partial',
        date_type: 'wdate',
        sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        shop_cd: '',
        shop_id: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,       // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('작업자'), value: 'wm_name' },
          { label: gettextCatalog.getString('접속IP'), value: 'ip' },
        ],
        search_date_type: [
          { label: gettextCatalog.getString('날짜'), value: 'wdate' }
        ],
      },
      searchDetail: [
      ]
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchDetail = angular.copy(search.searchDetail);

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        // 엑셀다운로드
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체엑셀 다운로드'),
              action: function () {
                excelDown('all');
              },
              perm_only: ['order.order']
            }, {
              label: gettextCatalog.getString('선택엑셀 다운로드'),
              action: function () {
                excelDown('select');
              },
              perm_only: ['order.order']
            }
          ]
        }
      ],
      actions_right: []
    };

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: [],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'], //3
      notSortingColumns: ['no'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/history/orderExcel`,
        requestWillAction: function (d) {
          const data = angular.merge({}, d, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'no',
          title: '작업번호',
          width: 80
        },
        {
          key: 'wdate',
          title: '날짜',
          width: 80
        },
        {
          key: 'note',
          title: '상세',
          width: 20
        },
        {
          key: 'wm_name',
          title: '작성자',
          width: 80
        },
        {
          key: 'ip',
          title: '접속IP',
          width: 80
        },
        {
          key: 'reason',
          title: '사유',
          width: 80
        },
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $rootScope.work_no = null;
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'total';
      $rootScope.work_no = null;
      $scope.searchDo(true, true);
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 엑셀다운
    function excelDown(type) {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(logExcelSVC.excelFieldList),
        title: '엑셀 다운로드 내역',
        url: '/app/history/orderExcel/excelDown',
        searchForm: $scope.pageData,
        page: 'excel_history'
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    }
  });
