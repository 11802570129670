'use strict';

angular.module('gmpApp')
  .service('summaryModel', function (settings, commonSVC) {
    return {
      /**
       * 통계 그래프 데이터 조회
       */

      getData: (data, next) => {
        if (next) {
          commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/summaries`, data, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/summaries`, data);
        }
      },
      byteCheck: (str) => {
        if (!str) {
          return 0;
        }

        const strLen = str.length;
        let byte = 0;

        for (let i = 0; i < strLen; i++) {
          // 한글 2byte로 처리됨
          byte += (str.charCodeAt(i) >> 7 ? 2 : 1);
        }

        return byte;
      },

      /**
      * 중복 데이터 분류 함수
      * Lucas 200316
      * @param {Array} deps 분류할 중복 데이터 리스트 - [ 치킨, 무, 당근, 양파, 무, 무 ]
      * @returns {Array} 분류된 데이터 리스트 - [ 치킨, 무, 당근, 양파, 무(1), 무(2) ]
      */
      classificationDuplicate: (deps) => {
        const dep_nameList = deps.map(d => d.prod_name);
        const res = dep_nameList.reduce((t, a) => {
          t[a] = (t[a] || 0) + 1;

          return t;
        }, {});

        return deps.map((d) => {
          if (res[d.prod_name] > 1) {
            res[d.prod_name] = res[d.prod_name] - 1;
            d.prod_name = `${d.prod_name}(${res[d.prod_name]})`;
          }

          return d;
        });
      },
    };
  });
