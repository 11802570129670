'use strict';

angular.module('gmpApp')
  .controller('olProdShopDetail-B616-Ctrl', function ($scope, $rootScope, $timeout, commonModel, commonSVC, shopAccountModel, onlineProductModel, onlineProdSVC, data, systemList, templateList, $uibModalInstance) {
    $scope.row = _.cloneDeep(data.shop_data);
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;
    $scope.data = _.cloneDeep(data.data);
    $scope.detailType = _.cloneDeep(data.data.detailType);
    $scope.openTemplate = _.cloneDeep(data.openTemplate);

    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.pa5_job_data = {
      get_additional_info_list: {
        load: false,
        data: []
      },
    };

    // pa5 작업 호출
    const run_pa5_jobs = async (job_name, job_kor_name, push_data) => {
      try {
        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (!re.data.has_error) {
          $scope.pa5_job_data[job_name].load = true;

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }

          return re.data.results[0].data;
        } else {
          commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name} 정보 로딩에 실패하였습니다.`);
      }
    };

    $scope.ok = async type => {
      $scope.row.set_info.key_weight = $scope.row.set_info.key_useWeightYn === 'N' ? '' : $scope.row.set_info.key_weight;
      $scope.row.set_info.key_summary_desc = $scope.row.set_info.key_use_summary_desc === 'N' ? '' : $scope.row.set_info.key_summary_desc;
      $scope.row.set_info.key_simple_desc = $scope.row.set_info.key_use_simple_desc === 'N' ? '' : $scope.row.set_info.key_simple_desc;
      if ($scope.row.saveAsTemplateYn) {
        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      if (type === 'template') {
        $scope.apply();
      } else {
        $uibModalInstance.close($scope.row);
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.mileage_type = onlineProdSVC.B616.mileage_type;

    $scope.trends = [];
    $scope.suppliers = [];
    $scope.manufacturers = [];
    $scope.brands = [];
    $scope.classifications = [];

    let makerInfo;

    $scope.getAdditionalInfoList = async () => {
      $scope.row.set_info.key_additional_title_list = await run_pa5_jobs('get_additional_info_list', '추가 항목 조회', {});
    };

    // 쇼핑몰 계정 정보 가져오기
    function init() {
      const param = { shop_cd: '', shop_id: '' };

      _.forEach($scope.data.site_list, function(v) {
        if (v.idx == $scope.openTemplate || v.template_no == $scope.data.template_no) {
          param.shop_cd = v.shop_cd;
          param.shop_id = v.shop_id;
        }
      });

      $scope.getAdditionalInfoList();
      // 제작정보 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          requestPA('product.makerinfo.scrap')
            .then(function(res) {
              makerInfo = res.data;

              $scope._search('trends');
              $scope._search('suppliers');
              $scope._search('classifications');
            });
        });
    }

    $scope._search = function(type) {
      if (type == 'trends') {
        $scope.trends = [];
        makerInfo.data.sale_trends.forEach(function(trend) {
          if (!$scope.trendsSearchText || trend['트렌드명오름차순 정렬'].indexOf($scope.trendsSearchText.trim()) != -1) {
            $scope.trends.push({
              code: trend['트렌드코드'],
              name: trend['트렌드명오름차순 정렬']
            });
          }
        });

      } else if (type == 'suppliers') {
        $scope.sale_suppliers = [];
        $scope.suppliers = [];

        makerInfo.data.sale_suppliers.forEach(function(sale_suppliers) {
          if (!$scope.suppliersSearchText || sale_suppliers['공급사명오름차순 정렬'].indexOf($scope.suppliersSearchText.trim()) != -1) {
            $scope.suppliers.push({
              code: sale_suppliers['공급사코드'],
              name: sale_suppliers['공급사명오름차순 정렬']
            });
          }
        });

      } else if (type == 'manufacturers') {
        $scope.manufacturers = [];
        makerInfo.data.sale_manufacturers.forEach(function(manufacturers) {
          if (!$scope.manufacturersSearchText || manufacturers.manufacturer_name.indexOf($scope.manufacturersSearchText.trim()) != -1) {
            $scope.manufacturers.push({
              code: manufacturers.manufacturer_code,
              name: manufacturers.manufacturer_name
            });
          }
        });

      } else if (type == 'brands') {
        $scope.brands = [];
        makerInfo.data.sale_brands.forEach(function(brand) {
          if (!$scope.brandsSearchText || brand.brand_name.indexOf($scope.brandsSearchText.trim()) != -1) {
            $scope.brands.push({
              code: brand.brand_code,
              name: brand.brand_name
            });
          }
        });
      } else if (type == 'classifications') {
        $scope.classifications = [];
        makerInfo.data.sale_classifications.forEach(function (classification) {
          if (!$scope.classificationsSearchText || classification['자체분류명오름차순 정렬'].indexOf($scope.classificationsSearchText.trim()) != -1) {
            $scope.classifications.push({
              code: classification['자체분류 코드'],
              name: classification['자체분류명오름차순 정렬']
            });
          }
        });
      }
    };

    $scope.select = function(type) {
      const sel_data = $(`#${type}_code option:selected`);

      switch (type) {
        case 'trend':
          $scope.row.set_info.key_trend_code = sel_data.val();
          $scope.row.set_info.key_trend_name = sel_data.text();
          break;
        case 'supp':
          $scope.row.set_info.key_supp_code = sel_data.val();
          $scope.row.set_info.key_supp_name = sel_data.text();
          break;
        /*case 'mnf':
          $scope.row.set_info.key_mnf_code = sel_data.val();
          $scope.row.set_info.key_mnf_name = sel_data.text();
          break;
        case 'brand':
          $scope.row.set_info.key_brand_code = sel_data.val();
          $scope.row.set_info.key_brand_name = sel_data.text();
          break;*/
      }
    };

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      return commonSVC.requestPA($scope.userInfo, 'cafe24.com', action, addData, commonModel, shopAccountModel);
    }

    init();
    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }
    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
  }
  );
