/**
 * 2018-09-05 Daniel
 * 머리말/꼬리말 적용상품 리스트
 */

'use strict';

angular.module('gmpApp')
  .controller('OnlineProductAddcontentApplyProdsCtrl', function ($scope, $uibModalInstance, $timeout, $compile, $filter, commonSVC, onlineProductModel, productModel, categoryModel, systemModel, data, settings, $rootScope) {

    const warehouseList = data.warehouseList;

    $scope.addcontent = data.addcontent;

    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchForm: {
        addcontentNo: $scope.addcontent.addcontent_no,
        page: 'order'
      },
      searchData: {
        all_checked: false,                                                         // 전체선택 여부
        selectCount: 0,                                                             // 검색전체 건수
        totalCount: 0,                                                              // 검색전체 건수
        showDetailSearchArea: false
      }
    };

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: [
        'ol_shop_no', 'wdate', 'shop_sale_no', 'shop_name', 'sale_status'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: [
        'ol_shop_no', 'wdate', 'shop_sale_no', 'shop_name', 'shop_sale_name', 'sale_status'
      ],
      notResizingColumns: ['ol_shop_no'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/list-of-applied-from-add-content`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;
          result.results = result.rows;

          return result.results;
        }
      },
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      columns: [
        {
          key: 'ol_shop_no',
          title: '상세보기',
          width: 40,
          template: function (row) {
            const location = 'KR';

            return `
              <button
                class="btn btn-default btn-xxs mr-5"
                ng-click="grid.appScope.onlineProductDetail('${row.shop_cd}', '${row.shop_id}', ${row.ol_shop_no}, '${location}')"
                uib-tooltip="{{'상세보기'|translate}}"
                tooltip-placement="right">
                상세
              </button>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 100
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 60,
          template: function (row) {
            const sale_no = row.shop_sale_no || '';
            const shop_cd = row.shop_cd;
            const shop_id = row.shop_id;
            let sale_no_link = '';

            if (shop_cd == 'B378') {
              if (row.shop_sale_no_sub && row.shop_sale_no_sub.length === 10) {
                sale_no_link = `${sale_no}?vendorItemId=${row.shop_sale_no_sub}`;
              } else {
                sale_no_link = row.shop_sale_no_sub;
              }
            } else {
              sale_no_link = sale_no;
            }

            return row.shop_sale_no
              ? `${row.shop_sale_no}
                <i  class="picon-link2
                    text-grey ml-5 cursor-pointer"
                    ng-click="grid.appScope.shopDetailView('${shop_cd}', '${shop_id}', '${sale_no_link}')">
                </i></span>`
              : '-';
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 80,
          template: function (row) {
            return `${row.shop_id}(${row.shop_id})`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 150,
          filter: 'whiteSpace'
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 50,
          template: function (row) {
            return $filter('statusColorOl')(row.sale_status);
          }
        }
      ]
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function (number) {
      $scope.searchData.showCount = number;
      $scope.grid.methods.length(number);
    };

    /**
     * 상세
     */
    $scope.onlineProductDetail = function (shop_cd, shop_id, ol_shop_no, location) {
      const resolve = {
        data: {
          shop_cd: shop_cd,
          shop_id: shop_id,
          ol_shop_no: ol_shop_no,
          country: location,
          warehouseList: warehouseList
        },
        prodDetail: onlineProductModel.detail(shop_cd, shop_id, ol_shop_no),
        madeinList: productModel.getMadein(),
        systemList: systemModel.load()
      };

      commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');
    };

    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  });
